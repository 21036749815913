import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMPANY_ACTION_LIST, COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createTeam = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/hrmsTeam/saveOrUpdate`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })

        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: props.pageNumber || 1,
          pageSize: props.pageSize || 25,
        }
        props.closeDrawer && props.closeDrawer();
        props.getTeam(payload);
        getTotalTeamCount(payload);
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || "")] || 'Some error occurred'
        })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}


export const getTeam = (payload) => {
  return dispatch => {
    return axios.get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/hrmsTeam/getTeamByPage?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}`)

      .then(response => {
        const data = {
          pageNo: payload.pageNumber,
          list: response.data
        }
        dispatch({ type: COMPANY_ACTION_LIST.TEAM_LIST_PAGE, data });
      })
      .catch(err => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = COMPANY_ACTION_LIST.TEAM_LIST_PAGE;
        }
      })
  }
}

export const getTotalTeamCount = (payload, history) => {
  return dispatch => {
    // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/hrmsTeam/getTeamCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMPANY_ACTION_LIST.TEAM_LIST_PAGE_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = COMPANY_ACTION_LIST.TEAM_LIST_PAGE_COUNT;
        }
      })
  }
}
export const deleteTeam = (payload, props) => {
  return dispatch => {
    // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/hrmsTeam/delete`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: props.pageNumer || 0,
          pageSize: props.pageSize || 25,
        }
        props.getTeam && props.getTeam(payload);
        props.getTotalTeamCount && props.getTotalTeamCount(payload);

      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || "")] || 'Some error occurred'
        })
      })
  }
}
export const getTeamByDepartmentId = (payload) => {
  return dispatch => {
    return axios.get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/hrmsTeam/getTeamByDepartmentId?relationshipId=${payload.relationshipId}&departmentId=${payload.departmentId || []}`)

      .then(response => {
    
        dispatch({ type: COMPANY_ACTION_LIST.TEAM_BY_DEPARTMENTID, data:response.data });
      })
      .catch(err => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = COMPANY_ACTION_LIST.TEAM_BY_DEPARTMENTID;
        }
      })
  }
}

export const resetTeamByDepartment = (payload) => {
  return dispatch => {
        dispatch({ type: COMPANY_ACTION_LIST.TEAM_BY_DEPARTMENTID, data:[] });
  }
}


export const resetTeamByDepartmentId = () => {
  return dispatch => {
        dispatch({ type: COMPANY_ACTION_LIST.TEAM_BY_DEPARTMENTID, data:[] });

  }
}