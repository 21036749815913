import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContactCompo from '../../../components/modal/modalBody/common/Contact';
import { fetchSalutation, addSalutation, deleteSalutation, showModal, hideModal, pushModalToStack, popModalFromStack, fetchCountries } from '../../../actions/commonActions';
import { createContact } from './action';
import { fetchDataIfNeeded } from '../../../utils';
import ContactDrawer from '../../../components/drawer/Contact';
import { fetchAllContacts } from '../../supplier/Listing/ShowConact/action';
import { fetchSupplierContacts } from '../../supplier/detail/action';

class ContactContainer extends Component {

  constructor(props){
    super(props);
    this.state = {
      countryCallingCode: null,
      countryId: 0
    }
}
 
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId
    };
    fetchDataIfNeeded('fetchSalutation', 'salutations', this.props, payload);
    fetchDataIfNeeded('fetchCountries', 'countries', this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.countries !== this.state.countries && nextProps.countries && !this.state.countryCallingCode) {
      let obj = nextProps.countries.find(country => (country.countryName === (this.props.companyInfo).countryName)) || {};
      this.setState({ countryCallingCode: obj.countryCallingCode || '', countryId: obj.countryId});
    }
  }

  render() {
    return (
      <ContactDrawer updateState={(data) => { this.setState(data) }} {...this.props} {...this.state} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    salutations: state.common.salutations,
    countries: state.common.countries,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createContact,
  fetchSalutation,
  addSalutation, 
  deleteSalutation,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  fetchCountries,
  fetchAllContacts,
  fetchSupplierContacts
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);
