import React, { useMemo } from "react";
import { Card, Button, Col, Row, Tooltip, Skeleton } from "antd";
import envConfig from "../../../config/environmentConfig";
import AuthNetIcon from "../../../assets/images/authorizenet-logo.png";
// import RazorpayIcon from "../../../assets/images/razorpay-logo.png";
import { MODAL_TYPE, MODULE_CODED_VALUES } from "../../../static/constants";
// import PaypalIcon from '../../../assets/images/paypal-logo.png';
// import NeonIcon from '../../../assets/images/neon-logo.png';
import StripeIcon from "../../../assets/images/stripe-logo.png";
import CardpointeIcon from "../../../assets/images/cardpointe.png";
//import { usePlaidLink } from 'react-plaid-link';
import "./index.scss";
import { FormattedMessage } from "react-intl";
//import PaymentIntegration from '../../../components/modal/modalBody/common/paymentIntegrate/paymentIntegration';
import CardConnectAddNew from "./cardConnectIntegrate.js";
import StripeAddNew from "./stripeIntegrate.js";
import payIcon1 from "../../../assets/images/icon/razorPayIcon.png";
import payIcon2 from "../../../assets/images/icon/authNetIcon.png";
import payIcon3 from "../../../assets/images/icon/stripeIcon.png";
import payIcon4 from "../../../assets/images/icon/cardPointIcon.png";
import { getPermissionData } from "../../../utils/index.js";

const PaymentComponent = (props) => {

  const {stripeConfigData, StripeGatewayList=[]} = props;
  const primaryPerm = (props.permissions && props.permissions.primary) || [];

  const paymentPermission = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.INTEGRATIONS_PAYMENT )
  },[])

  const addItemConfirmationModal = (payload, text) => {
    const { showModal } = props;
    const labelName = <FormattedMessage id="customName.text" />;
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    payload.userDefinedGatewayName = text;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.updateName(payload);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const editName = (props, payload, modalType) => {
    const formData = { maxLength: 50 };
    const { showModal } = props;
    const data = {
      title: <FormattedMessage id="customName.text" />,
      formData,
      hideFooter: false,
      modalData: {
        modalType,
        data: { ...payload, formData },
        selectedValue: payload.userDefinedGatewayName,
      },
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          addItemConfirmationModal(payload, formData.textBoxValue, modalType);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      },
    };
    showModal(data);
  };





  return (
    <div className="container">
      <Row>
          <Col span={24}>
            <div className="header">
              <h2>Payment Gateways</h2>
              <p>Manage your company payment gateways</p>
            </div>
          </Col>
      </Row>
      
      <Row>
        <Col xs={24} sm={24} md={24} lg={20} xl={12} xxl={8}>

          <div className="paymentOptionArea">
            <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
        {/* Authorize.net card */}
            {props.AuthorizeNetGatewayList?.length ?
              props.AuthorizeNetGatewayList.map((authrizeConfigData, i) => {
                return (
                  <Card
                    key={"auth-" + i}
                    className="accounting-market-place integs"
                    title="">
                    <div className="nameArea">
                      <img src={payIcon2} alt="" />
                      <div>
                        <h2>Authorize.net</h2>
                        <div class="cardName">
                        {authrizeConfigData.userDefinedGatewayName ? (
                          authrizeConfigData.userDefinedGatewayName
                        ) : (
                          <FormattedMessage id="authorize.net.logo" />
                        )}
                        </div>
                      </div>
                    </div>
                    <div className="buttonArea">
                      <div className="activeBtn">Active</div>
                      {paymentPermission.update ? <Button
                          className="ant-btn-primary editButton"
                          title="Edit"
                          onClick={() => {
                            editName(
                              props,
                              authrizeConfigData,
                              MODAL_TYPE.CUSTOM_NAME
                            );
                          }}
                        >
                          Edit
                        </Button>:"" }
                    {/* {authrizeConfigData.alpidePaymentGatewayId && paymentPermission.delete ? (
                      <Button
                        className="ant-btn-danger"
                        onClick={() => {
                          const modalData = {
                            modalBody: (
                              <FormattedMessage
                                id="disconnect.confirmation"
                                defaultMessage=""
                              />
                            ),
                            handleSubmit: () => {
                              props.disconnectConfiguration(
                                authrizeConfigData,
                                props
                              );
                            },
                          };
                          props.pushModalToStack(modalData);
                        }}
                      >
                        Disconnect{" "}
                      </Button>
                    ) : 
                    
                    (
                      <Button
                        className="ant-btn-primary"
                        onClick={() => {
                          //props.getStripeAccountOnboardingLink();
                          window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_authorise/onboarding?callback_uri=${envConfig.CALLBACK_URI_AUTH_NET}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}`;
                        }}
                      >
                        Connect
                      </Button>
                    )} */}
                      {authrizeConfigData.alpidePaymentGatewayId && paymentPermission.delete ? (
                        <Button
                          className="ant-btn-danger"
                          onClick={() => {
                            const modalData = {
                              modalBody: (
                                <FormattedMessage
                                  id="disconnect.confirmation"
                                  defaultMessage=""
                                />
                              ),
                              handleSubmit: () => {
                                props.disconnectConfiguration(authrizeConfigData, props);
                              },
                            };
                            props.pushModalToStack(modalData);
                          }}
                        >
                          Disconnect
                        </Button>
                      ) : (
                        paymentPermission.create && (
                          <Button
                            className="ant-btn-primary"
                            onClick={() => {
                              window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_authorise/onboarding?callback_uri=${envConfig.CALLBACK_URI_AUTH_NET}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}`;
                            }}
                          >
                            Connect
                          </Button>
                        )
                      )}

                    </div>
                    
                    
                    {/* <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                        <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                        <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p> */}
                    {/* <div className="border-footer mt10 mb20"></div> */}
                    
                  </Card>
                );
              })
              :
              ''
            }

            <div className="paymentOption" title="">
              <div className="nameArea">
                <img src={payIcon2} alt="" />
                <h2>Authorize.net</h2>
              </div>
              <div className="buttonArea">
              {paymentPermission.create ? <Button
                  className="cnant-btn ant-btn-primary"
                  onClick={() => {
                    //props.getStripeAccountOnboardingLink();
                    window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_authorise/onboarding?callback_uri=${window.location.href}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}`;
                  }}
                >
                  Connect
                </Button>:"" }
              </div>
              {/* <h2><i class="fa fa-plus"></i></h2> */}
              {/* <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
          <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
          <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p> */}
              {/* <div className="border-footer mt10 mb20"></div> */}
            </div>

          {/* stripe card */}
          {StripeGatewayList?.length ? StripeGatewayList.map((stripeConfigData, ind)=>{
            return <div className="paymentOption" title="" key={`stripe-${ind}`}>
              <div className="nameArea">
                <img src={payIcon3} alt="" />
                <div>
                  <h2>Stripe</h2>
                  <div className="cardName">
                    {stripeConfigData && stripeConfigData.alpidePaymentGatewayId && stripeConfigData.userDefinedGatewayName ? 
                      stripeConfigData.userDefinedGatewayName
                      : 
                      ""  
                    }
                  </div>
                </div>
                {stripeConfigData.alpidePaymentGatewayId ? (stripeConfigData?.webhookId ? 
                  <Tooltip placement="top" title="A webhook has been successfully set up on your Stripe account. Please ensure that it remains active, as it is essential for the proper functioning of the payment integration." trigger="hover">
                    <i className="fi fi-rr-link-alt"/>
                  </Tooltip>
                  :
                  <Tooltip placement="top" title="An error occurred during the Stripe integration process, which may have affected the successful completion of the payment. Please verify if the payment was processed correctly." trigger="hover">
                    <i className="fi fi-rr-link-alt required"/>
                  </Tooltip>
                  )
                  :
                  ""
                }
              </div>
              <div className="buttonArea">
                {stripeConfigData?.alpidePaymentGatewayId ? 
                  <div className="activeBtn">Active</div>
                  : 
                  ""
                }
                {stripeConfigData?.alpidePaymentGatewayId && paymentPermission.update? 
                  <Button
                    className="ant-btn-primary editButton"
                    title="Edit"
                    onClick={() => {
                      editName(
                        props,
                        stripeConfigData,
                        MODAL_TYPE.CUSTOM_NAME
                      );
                    }}
                  >
                    Edit
                  </Button>
                  :
                  ""
                }
                {props.stripeConfigData?.alpidePaymentGatewayId && paymentPermission.delete ? 
                  <Button
                    className="ant-btn-danger"
                    onClick={() => {
                      const modalData = {
                        modalBody: (
                          <FormattedMessage
                            id="disconnect.confirmation"
                            defaultMessage=""
                          />
                        ),
                        handleSubmit: () => {
                          props.disconnectConfiguration(
                            props.stripeConfigData,
                            props
                          );
                        },
                      };
                      props.pushModalToStack(modalData);
                    }}
                  >
                    Disconnect
                  </Button>
                  : 
                  !props.stripeConfigData?.alpidePaymentGatewayId && paymentPermission.create ?  <Button
                    type="primary"
                    onClick={() => {
                      //props.getStripeAccountOnboardingLink();
                      props.pushModalToStack({
                        modalBody: <StripeAddNew {...props} />,
                        title: (
                          <FormattedMessage
                            id="stripe.logo"
                            defaultMessage=""
                          />
                        ),
                        width: "40%",
                        hideFooter: true,
                      });
                    }}
                  >
                    Connect
                  </Button>:""
                }
              </div>
            </div>
            })
            :
            ""
          }

            <div className="paymentOption" title="">
              <div className="nameArea">
                <img src={payIcon3} alt="" />
                <div>
                  <h2>Stripe</h2>
                </div>
              </div>
              <div className="buttonArea">
                { paymentPermission.create ? <Button
                    type="primary"
                    onClick={() => {
                      props.pushModalToStack({
                        modalBody: <StripeAddNew {...props} />,
                        title: (
                          <FormattedMessage
                            id="stripe.logo"
                            defaultMessage=""
                          />
                        ),
                        width: "40%",
                        hideFooter: true,
                      });
                    }}
                  >
                    Connect
                  </Button>:""
                }
              </div>
            </div>

                  {/* <div className="paymentOption" title="">
                    <div className="nameArea">
                      <img src={payIcon3} alt="" />
                      <h2>Stripe</h2>
                    </div>
                    <div className="buttonArea">
                      {props.StripeOnboardingUrl ? 
                        <Button
                          type="primary"
                          loading={props.saveLoading}
                          onClick={() => {
                            window.open(props.StripeOnboardingUrl, '_blank');
                          }}
                        >
                          Onboard
                        </Button>
                        :
                        ''             
                      }
                      {props.stripeConfigData?.accountId ? 
                        <Button
                          className="ant-btn-danger"
                          onClick={() => {
                            const modalData = {
                              modalBody: (
                                <FormattedMessage
                                  id="disconnect.confirmation"
                                  defaultMessage=""
                                />
                              ),
                              handleSubmit: () => {
                                props.disconnectConfiguration(
                                  props.stripeConfigData,
                                  props
                                );
                              },
                            };
                            props.pushModalToStack(modalData);
                          }}
                        >
                          Disconnect{" "}
                        </Button>
                        : 
                        <Button
                          type="primary"
                          loading={props.saveLoading}
                          onClick={() => {
                            props.createConnectedAccount(props);
                          }}
                        >
                          Connect
                        </Button>
                      }

                    </div>
                  </div> */}

                  {/* CardPointe cards */}
                  {props.cardPointGatewayList?.length ?
                    props.cardPointGatewayList.map((cardPointConfigData, i) => {
                      return (
                        <div className="paymentOption" title="">
                          <div className="nameArea">
                            <img src={payIcon4} alt="" />
                            <div>
                              <h2>Cardpoint</h2>
                              <div className="cardName">
                                {cardPointConfigData?.alpidePaymentGatewayId && cardPointConfigData?.userDefinedGatewayName ? (
                                  cardPointConfigData.userDefinedGatewayName
                                ) : (
                                  <FormattedMessage id="authorize.net.logo" />
                                )}
                              </div>
                            </div>
                            
                          </div>
                          <div className="buttonArea">
                            {cardPointConfigData &&
                            cardPointConfigData.alpidePaymentGatewayId ? (
                              <div className="activeBtn">Active</div>
                            ) : (
                              ""
                            )}
                         {paymentPermission.update ?  <Button
                              className="ant-btn-primary editButton"
                              title="Edit"
                              onClick={() => {
                                editName(
                                  props,
                                  cardPointConfigData,
                                  MODAL_TYPE.CUSTOM_NAME
                                );
                              }}
                            >
                              Edit
                            </Button>:""}

                            {cardPointConfigData &&
                            cardPointConfigData.alpidePaymentGatewayId && paymentPermission.delete ? (
                              <Button
                                className="ant-btn-danger"
                                onClick={() => {
                                  const modalData = {
                                    modalBody: (
                                      <FormattedMessage
                                        id="disconnect.confirmation"
                                        defaultMessage=""
                                      />
                                    ),
                                    handleSubmit: () => {
                                      props.disconnectConfiguration(
                                        cardPointConfigData,
                                        props
                                      );
                                    },
                                  };
                                  props.pushModalToStack(modalData);
                                }}
                              >
                                Disconnect{" "}
                              </Button>
                            ) : (
                              paymentPermission.create && <Button
                                className="ant-btn-primary"
                                onClick={() => {
                                  //props.getStripeAccountOnboardingLink();
                                  props.pushModalToStack({
                                    modalBody: <CardConnectAddNew {...props} />,
                                    title: (
                                      <FormattedMessage
                                        id="cardConnect.logo"
                                        defaultMessage=""
                                      />
                                    ),
                                    width: "30%",
                                    hideFooter: true,
                                  });
                                }}
                              >
                                Connect
                              </Button>
                            )}
                          </div>

                        </div>
                      );
                    }) : ''}
                  <div className="paymentOption" title="">
                    <div className="nameArea">
                      <img src={payIcon4} alt="" />
                      <h2>Cardpoint</h2>
                    </div>
                    <div className="buttonArea">
                      {props.cardConnectConfigData?.alpidePaymentGatewayId && paymentPermission.delete ? (
                        <Button
                          style={{ marginLeft: "80px" }}
                          className="ant-btn-danger"
                          onClick={() => {
                            const modalData = {
                              modalBody: (
                                <FormattedMessage
                                  id="disconnect.confirmation"
                                  defaultMessage=""
                                />
                              ),
                              handleSubmit: () => {
                                props.disconnectConfiguration(
                                  props.cardConnectConfigData,
                                  props
                                );
                              },
                            };
                            props.pushModalToStack(modalData);
                          }}
                        >
                          Disconnect{" "}
                        </Button>
                      ) : (
                       paymentPermission.create && <Button
                          style={{ marginLeft: "0px" }}
                          className="ant-btn-primary"
                          onClick={() => {
                            //props.getStripeAccountOnboardingLink();
                            props.pushModalToStack({
                              modalBody: <CardConnectAddNew {...props} />,
                              title: (
                                <FormattedMessage
                                  id="cardConnect.logo"
                                  defaultMessage=""
                                />
                              ),
                              width: "30%",
                              hideFooter: true,
                            });
                          }}
                        >
                          Connect
                        </Button>
                      )}
                    </div>
                    {/* <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p> */}

                    {/* <div className="border-footer mt10 mb20"></div> */}
                  </div>

                  {/* Razorpay cards */}
                  {props.RazorpayGatewayList?.length ? props.RazorpayGatewayList.map((razorpayConfigData, i) => {
                      return (
                        <div className="paymentOption" title="" key={"razorpay_inte"+i}>
                          <div className="nameArea">
                            <img src={payIcon1} alt="" />
                            <div>
                              <h2>Razorpay</h2>
                              <div class="cardName">
                                  {razorpayConfigData.userDefinedGatewayName ? (
                                  razorpayConfigData.userDefinedGatewayName
                                    ) : (
                                      <FormattedMessage id="razorpay.logo" />
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="buttonArea">
                            <div className="activeBtn">Active</div>
                            {paymentPermission.update ? <Button className="editButton"
                              onClick={() => {
                                editName(
                                  props,
                                  razorpayConfigData,
                                  MODAL_TYPE.CUSTOM_NAME
                                );
                              }}
                            >
                              Edit
                            </Button>:""}
                              {razorpayConfigData.alpidePaymentGatewayId && paymentPermission.delete? (
                              <Button
                                className="ant-btn-danger"
                                onClick={() => {
                                  const modalData = {
                                    modalBody: (
                                      <FormattedMessage
                                        id="disconnect.confirmation"
                                        defaultMessage=""
                                      />
                                    ),
                                    handleSubmit: () => {
                                      props.disconnectConfiguration(
                                        razorpayConfigData,
                                        props
                                      );
                                    },
                                  };
                                  props.pushModalToStack(modalData);
                                }}
                              >
                                Disconnect{" "}
                              </Button>
                            ) : (
                            paymentPermission.create &&  <Button
                                style={{ marginLeft: "0px" }}
                                className="ant-btn-primary"
                                onClick={() => {
                                  window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_authorise/onboarding?callback_uri=${envConfig.CALLBACK_URI_AUTH_NET}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}`;
                                }}
                              >
                                Connect
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    }) : ''}

                  <div className="paymentOption" title="">
                    <div className="nameArea">
                      <img src={payIcon1} alt="razorpay" />
                      <h2>Razorpay</h2>
                    </div>
                    <div className="buttonArea">
                     {paymentPermission.create ? <Button
                        style={{ marginLeft: "0px" }}
                        className="ant-btn-primary"
                        onClick={() => {
                          props.generateRazorpayAuthLink({
                            relationshipId: props.companyInfo?.relationshipId,
                          });
                          //  window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_authorise/onboarding?callback_uri=${window.location.href}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}`;
                        }}
                      >
                        Connect
                      </Button>:""}
                    </div>
                  </div>
            </Skeleton>
            </div>
          </Col>
      </Row>
      
    </div>
  );
};

export default PaymentComponent;