import React, { Fragment, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import { Skeleton, Pagination, Popover, Tooltip, Empty } from 'antd';
import { fetchPaginationDataIfNeeded, camelize, getMomentDateForUIReadOnly, sortTable } from '../../../../utils'
import * as find from 'lodash.find';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';

import './index.scss';
//import PageBreadcrumb from '../../../PageBreadcrumb';
import PRDetail from '../../../../containers/modal/modalBody/supplier/PRDetail';
import RFQDetail from '../../../../containers/modal/modalBody/supplier/RFQDetail';
import RFQSplitDetail from '../../../../containers/modal/modalBody/supplier/RFQSplitDetail';
import BiddingSuppliersPopup from '../../../../containers/modal/modalBody/supplier/BiddingSuppliersPopup';
import { CONSTANTS, ICONS, MODAL_TYPE } from '../../../../static/constants';
import { getLabelName } from '../../../../components/modal/modalBody/customerInfo';
import { AddAndRemoveSupplierListingColumn } from "../../../general/AddAndRemoveSupplierListingColumn";
// import { Dropdown } from '../../../general/Dropdown';
// import { TextBox } from '../../../general/TextBox';
import { FilterOutlined, InsertRowRightOutlined } from '@ant-design/icons';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
// const { RangePicker } = DatePicker;
// const { Panel } = Collapse;


const RFQListingComp = (props) => {
    const { rfqList, companyInfo, rfqCount, pageNumber, updateState, pageSize, dir=0 } = props;

    const [childOnList, setChildOn] = useState([]);

    const _getRFQList = (dataList) => {
        dataList && dataList.forEach((dt, dtIndex) => {
            dt.key = dt.rfqNumber;
            if (dt.rfqChildMasterList && dt.rfqChildMasterList.length)
                dt.children = dt.rfqChildMasterList;
                dt.children && dt.children.forEach((dt2) => {
                    dt2.rfqNumber = dt2.rfqChildNumber;
                    //dt2.rfqChildMasterId = dt2.rfqChildMasterId
                    dt2.customerName = dt.customerName;
                    dt2.key = dt2.rfqChildMasterId;
                });
        });

        return dataList || [];
    }

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchAllRFQ', 'rfqList', props, payload,true);
    }
    const handlePageSizeChange = (pageNumber, pageSize) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchAllRFQ(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    const _isColumnToHide = (columnName) => {
        return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }

    const updateStatusConfirmationModal = (props, payload, modalType) => {
        const labelName = "New Status";
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${payload.status} to ${payload.text}` }}
                defaultMessage={` `}
            />
            {labelName} ?
        </span>;

        const modalData = {
            modalBody,
            handleSubmit: (formData) => {
                const statusObj = find(props.rfqStatusList, { rfqStatusId: payload.statusId }) || {};
                props.updateRfqStatus({
                    status: formData.textBoxValue,
                    relationshipId: props.companyInfo.relationshipId,
                    customerId: payload.customerId,
                    rfqChildMasterId: payload.rfqChildMasterId,
                    rfqMasterId: payload.rfqMasterId,
                    statusId: payload.statusId,
                    statusColor: statusObj.statusColor,
                }, props)
                props.hideModal();
            },
        };
        props.showModal(modalData);
    }

    const addItemConfirmationModal = (props, text, modalType, payloadObj) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;
        let payload = { text: text, relationshipId: companyInfo.relationshipId }

        switch (modalType) {
            case MODAL_TYPE.RFQ_STATUS: {
                addFunc = props.addRFQStatus;
                payload.statusColor = ' new';
                break;
            }
            case MODAL_TYPE.RFQ_PRICE_CODES: {
                addFunc = props.addRFQPriceCodes;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: (data) => {
                addFunc(payload, props);
                props.hideModal();
                payloadObj.formData.status = payload.text;
                handleUpdateStatus(props, payloadObj.formData, MODAL_TYPE.UPDATE_STATUS)
            },
        };
        showModal(modalData);
    }

    const handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, hideModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType, payload);
        } else {
            const data = {
                title: <FormattedMessage id='addItem.text.rfqStatus' defaultMessage='' />,
                formData,
                isStack: true,
                onCancel: (type) => {
                    if (type !== "update_status") {
                        handleUpdateStatus(props, payload.formData, MODAL_TYPE.UPDATE_STATUS)
                    } else {
                        hideModal();
                    }
                },
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, modalType, payload);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.RFQ_STATUS: {
                deleteFunc = props.deleteRFQStatus;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }, props);
                props.hideModal();
                payload.formData = {};
                handleUpdateStatus(props, payload.formData, MODAL_TYPE.UPDATE_STATUS)
            },
        };
        showModal(modalData);
    }

    const handleUpdateStatus = (props, payload, modalType) => {
        const data = {
            title: <FormattedMessage id='modal.txDetails.common.updateStatus' defaultMessage='' />,
            modalData: {
                modalType,
                data: payload,
                isDropDown: true,
                list: props.rfqStatusList,
                valueKeyName: 'statusName',
                optionKeyName: 'rfqStatusId',
                selectedValue: payload.status,
                canAddNew: true,
                canDelete: true,
                deleteClickHandler: (payloadObj) => {
                    deleteClickHandler(props, MODAL_TYPE.RFQ_STATUS, payloadObj);
                },
                onAddNewClick: (payloadObj) => {
                    payloadObj.formData = {
                        textBoxValue: payloadObj.textEntered,
                        submittedOnce: false,
                        itemPayload: payload
                    }
                    handleNewItemAddition(props, payloadObj, MODAL_TYPE.RFQ_STATUS)
                }
            },
            handleSubmit: (formData = {}) => {
                if (formData.textBoxValue && formData.textBoxValue.length) {
                    updateStatusConfirmationModal(props, {
                        rfqMasterId: payload.rfqMasterId,
                        customerId: payload.customerId,
                        text: formData.textBoxValue,
                        rfqChildMasterId: payload.rfqChildMasterId,
                        statusId: Number(formData.optionId),
                        statusColor: formData.statusColor,
                        status: payload.status,
                    }, modalType);
                }
            }
        };
        props.showModal(data);
    }

    // const isAllChildSplitted = (data) => {
    //     const totalRfqProducts = (data.rfqDetailsList || []).length;
    //     let totalChildProducts = 0;
    //     (data.rfqChildMasterList || []).forEach((splitElement) => {
    //         totalChildProducts = totalChildProducts + splitElement.rfqChildDetailList.length;
    //     });

    //     if (totalChildProducts !== 0 && totalChildProducts === totalRfqProducts) return true;
    //     if (data.status.toLowerCase() !== TX_STATUS.OPEN.toLowerCase()) return true;
    //     return false;
    // }

    // const getsupplierIdArr = (param)=>{
    //     const suppliers = [];
    //     param.rfqBiddingSuppliersList.forEach((e)=>{
    //         suppliers.push(e.supplierId);
    //     });
    //     return suppliers;
        
    // }

    // const getActionMenuPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedModules = {
    //         firstSection: {
    //             splitRFQ: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.RFQ, PERMISSION_VALUES.CREATE),
    //             updateRFQ: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.RFQ, PERMISSION_VALUES.UPDATE),
    //             updateRFQStatus: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.RFQ, PERMISSION_VALUES.UPDATE),
    //             deleteRFQ: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_ORDER, PERMISSION_VALUES.DELETE) 
    //         }
    //     };
    //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
    //         if (permittedModules.firstSection[moduleName] === false) {
    //             delete permittedModules.firstSection[moduleName];
    //         }
    //     })
    //     return permittedModules;
    // }

    // const openAuditDrawer = (props, data) => {
    //     props.updateState({
    //         title: <div className="drawer-title">
    //             Audit Trail
    //         </div>,
    //         auditDrawerVisible: true,
    //         deletApiUrl: 'deleteAuditTrailRFQParent',
    //         masterKey: 'rfqMasterId',
    //         masterKeyValue: data.rfqMasterId,
    //         apiUrl: 'saveAuditTrailRFQParent',
    //         supplierId: data.supplierId,
    //         trailList: data.auditTrailRFQParentList || data.auditTrailRFQChildList || [],
    //         updateTrailListInTransaction: (list) =>{
    //             data.auditTrailRFQParentList = data.auditTrailRFQParentList && list;
    //             data.auditTrailRFQChildList = data.auditTrailRFQChildList && list;
    //         }
    //     });
    // }

    // const actionMenu = (val, param, permittedModules) => {
    //     return (
    //         <Menu className="cell-action-dropdown">
    //             {/* <Menu.Item key="1" onClick={() => {
    //                 openAuditDrawer(props, param);
    //             }}>
    //                 <i className="fa fa-history" />
    //                  View Audit Trail
    //                 </Menu.Item> */}
    //             {
    //                 (param.rfqDetailsList || []).length > 1 && !param.rfqChildMasterId ? (permittedModules.firstSection.splitRFQ

    //                     ? (
    //                         <Menu.Item disabled={isAllChildSplitted(param)} key="2" value="edit">
    //                             <i className="fa fa-credit-card" />
    //                             {
    //                                 isAllChildSplitted(param)
    //                                     ? "Split RFQ"
    //                                     : (param.rfqBiddingSuppliersList.length ? <Link onClick={() => {
    //                                         if (param.rfqBiddingSuppliersList.length) {
    //                                             const modalBody = <span>
    //                                                 There {param.rfqBiddingSuppliersList.length === 1 ? "is" : "are"} {param.rfqBiddingSuppliersList.length} {param.rfqBiddingSuppliersList.length === 1 ? "supplier" : "suppliers"} assigned to RFQ, splitting this RFQ will remove all Suppliers. Do you want to contiue?
    //                                                  </span>;

    //                                             const modalData = {
    //                                                 modalBody,
    //                                                 footer: {
    //                                                     cancel: {
    //                                                         intlId: "no",
    //                                                         defaultMessage: ""
    //                                                     },
    //                                                     submit: {
    //                                                         intlId: "yes",
    //                                                         defaultMessage: ""
    //                                                     }
    //                                                 },
    //                                                 handleSubmit: (formData) => {
    //                                                     props.hideModal();
    //                                                     props.history.push("/admin/rfq-split?customerId=" + param.customerId + "&rfqMasterId=" + param.rfqMasterId + "&isAllSupplier=1");
    //                                                 },
    //                                             };
    //                                             props.showModal(modalData);
    //                                         }
    //                                     }}>Split RFQ</Link> :

    //                                         <Link
    //                                             to={{
    //                                                 pathname: "/admin/rfq-split",
    //                                                 state: {
    //                                                     customerId: param.customerId,
    //                                                     rfqMasterId: param.rfqMasterId
    //                                                 }
    //                                             }
    //                                             }>Split RFQ</Link>

    //                                     )}
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />) : ""
    //             }
    //             {
    //                 permittedModules.firstSection.updateRFQ
    //                     ? (
    //                         <Menu.Item key="3" value="edit">
    //                             <i className="fa fa-pencil" />
    //                             <Link to={
    //                                 {
    //                                     pathname: "/admin/update-rfq",
    //                                     state: {
    //                                         customerId: param.customerId,
    //                                         rfqMasterId: param.rfqMasterId,
    //                                         supplierIdArr: getsupplierIdArr(param),
    //                                         update: true
    //                                     }
    //                                 }
    //                             }>Update RFQ</Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }

    //             {permittedModules.firstSection.splitRFQ && !param.rfqChildMasterId && <Menu.Item key="4" value="edit">
    //                 <i className="fa fa-pencil" />
    //                 <Link to={
    //                     {
    //                         pathname: "/admin/clone-rfq",
    //                         state: {
    //                             operationType: "clone",
    //                             customerId: param.customerId,
    //                             rfqMasterId: param.rfqMasterId,
    //                             supplierIdArr: getsupplierIdArr(param),
    //                             clone: true
    //                         }
    //                     }
    //                 }>Clone RFQ</Link>
    //             </Menu.Item>}
    //             {
    //                 permittedModules.firstSection.updateRFQStatus
    //                     ? (
    //                         <Menu.Item key="5" value="edit" onClick={() => {
    //                             handleUpdateStatus(props, {
    //                                 submittedOnce: false,
    //                                 customerId: param.customerId,
    //                                 rfqMasterId: param.rfqMasterId,
    //                                 rfqChildMasterId: param.rfqChildMasterId,
    //                                 status: param.status
    //                             }, MODAL_TYPE.UPDATE_STATUS)
    //                         }}>
    //                             <i className="fa fa-pencil" />
    //                             Update RFQ Status
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }

    //            {permittedModules.deleteRFQ &&  <Menu.Item key="6">
    //                 <i className="fa fa-trash" />
    //                 <Link onClick={() => {
    //                     props.showModal({
    //                         modalBody: "Are you sure you want to delete this transaction?",
    //                         handleSubmit: () => {
    //                             if (param.rfqChildMasterId) {
    //                                 props.deleteChildRFQ({
    //                                     supplierId: param.supplierId,
    //                                     rfqMasterId: param.rfqMasterId,
    //                                     rfqChildMasterId: param.rfqChildMasterId,
    //                                     relationshipId: param.relationshipId
    //                                 }, props)
    //                             } else {
    //                                 props.deleteRFQ({
    //                                     supplierId: param.supplierId,
    //                                     rfqMasterId: param.rfqMasterId,
    //                                     rfqChildMasterId: param.rfqChildMasterId,
    //                                     relationshipId: param.relationshipId
    //                                 }, props)
    //                             }

    //                         }
    //                     })

    //                 }}>Delete RFQ</Link>
    //             </Menu.Item>}
    //         </Menu >
    //     );
    // }

    const openRFQBiddingSuppliers = (data) => {
        const selectedRfqMaster = find(rfqListData, { rfqMasterId: data.rfqMasterId });
        data.customerName = selectedRfqMaster.customerName;
        props.pushModalToStack({
            modalBody: <BiddingSuppliersPopup  {...props} rfqDataForBidding={data} />,
            title:
                <>
                    <div>Bidding Supplier</div>
                    <div style={{ 'fontSize': 'x-small' }}>{data.supplierRFQNumber}</div>
                </>,
            hideFooter: true,
            width: '85%',
            wrapClassName: 'bidding-supplier-popup back-blur'
        })
    };

    const openRFQDetails = (data) => {
        const rfqPayload = {
            supplierId: data.supplierId,
            rfqMasterId: data.rfqMasterId,
            rfqChildMasterId: data.rfqChildMasterId
        }
        props.pushModalToStack({
            modalBody: data.rfqChildMasterId ? <RFQSplitDetail {...props} rfqPayload={rfqPayload} /> : <RFQDetail {...props} rfqPayload={rfqPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const openPRDetails = (data) => {
        const prPayload = {
            supplierId: data.supplierId,
            prMasterId: data.purchaseRequestMasterId
        }
        props.pushModalToStack({
            modalBody: <PRDetail {...props} prPayload={prPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const getQuantityOnPO = (data) => {
        return <div className="cursor-pointer circles-ling">
        {(data.statusPO === 'Converted' || data.statusPO === 'converted') ? <i className="fa fa-circle"/> : (data.statusPO === 'converted.partially' ? <i className="fa fa-adjust"></i> : <i className="fa fa-circle-o" ></i>)}
    </div>;
        // <div onClick={() => {
        // }}><Progress className={data.totalQuantityOnPO >= data.totalQuantity ? "complete" : "incomplete"} format={() => {
        //     return `${data.totalQuantityOnPO}/${data.totalQuantity}`
        // }} percent={(data.totalQuantityOnPO / data.totalQuantity) * 100} /></div>;
    }

    const getResponseCount = (biddingSuppliers) => {
        let count = 0;
        (biddingSuppliers || []).forEach((bidData) => {
            if (bidData.supplierResponse) {
                count++;
            }
        });
        return count ? <div className="">{count} {count > 1 ? 'Responses' : 'Response'}</div> : '';
    }

   // const permittedModules = getActionMenuPermissionData();
    // const tempColumnDefs = [
    //     {
    //         title: <FormattedMessage id='common.RFQ.Number' defaultMessage='' />,
    //         key: "rfqNumber",
    //         dataIndex: 'rfqNumber',
    //         render: (rfqNumber, data) => <span className="cursor-pointer" onClick={() => {
    //             openRFQDetails(data)
    //         }}>{rfqNumber}</span>,
    //         width: 200
    //     },
    //     {
    //         title: <FormattedMessage id='customer.salesQuote.listing.header.rfqExpirationDate' defaultMessage='' />,
    //         key: "rfqExpirationDate",
    //         dataIndex: 'rfqExpirationDate',
    //         render: (rfqExpirationDate) => <div>
    //              <Tooltip placement="topLeft" title={(rfqExpirationDate && getMomentDateForUIReadOnly({date: rfqExpirationDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {rfqExpirationDate ? getMomentDateForUIReadOnly({date: rfqExpirationDate, format: format}) : ""}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         title: <FormattedMessage id='customer.salesQuote.form.rfqDate.label' defaultMessage='' />,
    //         key: "rfqDate",
    //         dataIndex: 'rfqDate',
    //         render: (rfqDate) => <div>
    //              <Tooltip placement="topLeft" title={(rfqDate && getMomentDateForUIReadOnly({date: rfqDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             { getMomentDateForUIReadOnly({date: rfqDate, format: format})}
    //             </Tooltip>
    //         </div>,
    //         width: 150
    //     },
    //     {
    //         title: <FormattedMessage id='supplier' defaultMessage='' />,
    //         dataIndex: 'rfqBiddingSuppliersList',
    //         key: "rfqBiddingSuppliersList",
    //         render: (rfqBiddingSuppliersList, data) => <div style={{ textAlign: 'center' }}>
    //             {rfqBiddingSuppliersList && !(data.rfqChildMasterList || []).length ?
    //                 <div>
    //                     <div className="cursor-pointer" onClick={() => { openRFQBiddingSuppliers(data) }}>
    //                         {rfqBiddingSuppliersList.length}
    //                     </div>
    //                     {getResponseCount(rfqBiddingSuppliersList)} 
    //                 </div>
    //                 : <div>
    //                     {(rfqBiddingSuppliersList || [] ).length}
    //                 </div>}
    //         </div>,
    //     },
    //     // {
    //     //     title: 'Customer',
    //     //     dataIndex: 'customerName',
    //     //     key: 'customerName'
    //     // },
    //     {
    //         title: <FormattedMessage id='supplier.rfq.listing.header.customerRFQNumber' defaultMessage='' />,
    //         dataIndex: 'customerRFQNumber',
    //         key: 'customerRFQNumber'
    //     },
    //     {
    //         title: <FormattedMessage id='customer.salesQuote.listing.header.priority' defaultMessage='' />,
    //         dataIndex: 'orderPriority',
    //         key: "orderPriority"
    //     },
    //     {
    //         title: <FormattedMessage id='supplier.rfq.form.gridHeader.salesPerson' defaultMessage='' />,
    //         dataIndex: 'salesPersonFullName',
    //         key: "salesPersonFullName"
    //     },
    //     {
    //         title: <FormattedMessage id='common.PR.Number' defaultMessage='' />,
    //         key: "purchaseRequestNumber",
    //         dataIndex: 'purchaseRequestNumber',
    //         render: (purchaseRequestNumber, data) => <div className="cursor-pointer" onClick={() => {
    //             openPRDetails(data)
    //         }}>{purchaseRequestNumber}</div>,
    //     },
    //     {
    //         title: <FormattedMessage id='supplier.rfq.listing.header.prDate' defaultMessage='' />,
    //         key: "purchaseRequestDate",
    //         dataIndex: 'purchaseRequestDate',
    //         render: (purchaseRequestDate) => <div>
    //              <Tooltip placement="topLeft" title={(purchaseRequestDate && getMomentDateForUIReadOnly({date: purchaseRequestDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {purchaseRequestDate ? getMomentDateForUIReadOnly({date: purchaseRequestDate, format: format}) : ""}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         title: <FormattedMessage id='supplier.rfq.listing.header.prExpirationDate' defaultMessage='' />,
    //         key: "prExpirationDate",
    //         dataIndex: 'prExpirationDate',
    //         render: (prExpirationDate) => <div>
    //              <Tooltip placement="topLeft" title={(prExpirationDate && getMomentDateForUIReadOnly({date: prExpirationDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {prExpirationDate ? getMomentDateForUIReadOnly({date: prExpirationDate, format: format}) : ""}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         title: <FormattedMessage id='supplier.rfq.listing.header.requestingDepartment' defaultMessage='' />,
    //         key: "requestingDepartment",
    //         dataIndex: 'requestingDepartment',
    //     },
    //     {
    //         title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
    //         key: "projectName",
    //         dataIndex: 'projectName'
    //     },
    //     {
    //         title: <FormattedMessage id='customer.customerExpense.listing.header.refNumber' defaultMessage='' />,
    //         key: "refNumber",
    //         dataIndex: 'refNumber'
    //     },

    //     {
    //         title: <FormattedMessage id='customer.salesQuote.listing.header.remarksInternal' defaultMessage='' />,
    //         key: "remarksInternal",
    //         render: (value) => <div>
    //             {ReactHtmlParser(value)}
    //         </div>,
    //         dataIndex: 'remarksInternal'
    //     },
    //     {
    //         title: <FormattedMessage id='customer.salesQuote.listing.header.remarksCustomer' defaultMessage='' />,
    //         key: "remarksSupplier",
    //         render: (value) => <div>
    //             {ReactHtmlParser(value)}
    //         </div>,
    //         dataIndex: 'remarksSupplier'
    //     },
    //     // {
    //     //     title: 'Purchase Type',
    //     //     key: "purchaseType",
    //     //     dataIndex: 'purchaseType'
    //     // },
    //     {
    //         title: <FormattedMessage id='supplier.purchaseRequest.listing.header.qtyOnPO' defaultMessage='' />,
    //         key: "totalQuantityOnPO",
    //         dataIndex: 'totalQuantityOnPO',
    //         render: function (totalQuantityOnPO, row) {
    //             return getQuantityOnPO(row);
    //         },
    //     },
    //     {
    //         title: <FormattedMessage id='common.status' defaultMessage='' />,
    //         key: "userStatus",
    //         dataIndex: 'status',
    //         render: (status, row) => {
    //             return (status === 'Rejected' && row.rejectionReason) ?
    //                 <div>
    //                     <Tooltip placement="topLeft" title={row.rejectionReason}>
    //                         <span className={["status-code", (camelize(row.status || "")), " " + row.statusColor].join(' ')}><FormattedMessage id={status} defaultMessage={status} /></span>
    //                     </Tooltip>
    //                 </div> : 
    //                 <div>{status ? <span className={["status-code", (camelize(row.status || "")), " " + row.statusColor].join(' ')}><FormattedMessage id={status} defaultMessage={status} /></span>:''}</div>
    //         }
    //     },
    //     {
    //         title: <FormattedMessage id='Invoice Status' defaultMessage='' />,
    //         key: "invStatus",
    //         dataIndex: 'invStatus',
    //     },
    // ];

    // const columnDefs = tempColumnDefs.filter(col => { return (props.txColumns.indexOf(col.key) > -1 || props.txMandatoryColumns.indexOf(col.key) > -1) })

    // if (Object.keys(permittedModules.firstSection).length) {
    //     columnDefs.push({
    //         title: <div className="cursor-pointer">
    //             <Popover
    //                 content={<AddAndRemoveSupplierListingColumn {...props} />}
    //                 title={<div className="text-center">Add/Remove </div>}
    //                 placement="left"
    //                 trigger="click"
    //             >
    //                 <i className='fa fa-bars' />
    //             </Popover>
    //         </div>,
    //         key: "action",
    //         dataIndex: 'action',
    //         render: (param, row) => {
    //             return <Dropdown overlay={actionMenu(param, row, permittedModules)} trigger={['click']}>
    //                 <Button className="action-btn small">
    //                     <i className="fa fa-ellipsis-v" />
    //                 </Button>
    //             </Dropdown>
    //         }
    //     });
    // }

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const onExpand = (expanded, record) => {
    //     if (expanded) {
    //         const expandKeys = [].concat(props.expandedRowKeys);
    //         expandKeys.push(record.rfqNumber)
    //         props.updateState({
    //             expandedRowKeys: expandKeys
    //         });
    //     } else {
    //         const filteredExpandedRows = props.expandedRowKeys.filter((element) => {
    //             return element !== record.rfqNumber
    //         });
    //         props.updateState({
    //             expandedRowKeys: filteredExpandedRows
    //         });
    //     }

    // }

    const rfqListData = _getRFQList(rfqList[pageNumber]);

    const openLongtText = (title, Text)=>{
        props.showModal({
            title: title,
            modalBody: <div style={{maxHeight: '550px', overflow: 'auto'}}>{ReactHtmlParser(Text)}</div>,
            hideFooter: true,
            width: '50%'
        })
    }

    const sortColumn = (e) => {
        sortTable(document.getElementById('rfq-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
    }
  
    return (
        <>
            <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} active>
                    
                    <div className="left-actions">
                    <div className="table-heading">
                        <FormattedMessage id='purchase.rfq.table.heading' defaultMessage='' />
                    </div>
                    {/* {
                        
                        props.rfqCount ? <> */}
                        <div className="vertical-sep" />
                        <div>{props.rfqCount}</div>
                        {/* </>:''
                    } */}
                    </div>
                    <div className="right-actions">
                    <button className="action-btn" onClick={() => {
                        props.updateState({
                            rfqFilterDrawerVisible: true
                        })
                    }}>
                        <FilterOutlined onClick={()=>{
                                
                            }} /> &nbsp; <FormattedMessage id='filter.text' defaultMessage='' />
                    </button>
                            <Popover
                                content={<AddAndRemoveSupplierListingColumn {...props} />}
                                title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' />  </div>}
                                placement="leftTop"
                                trigger="click"
                                arrow="right"
                                // visible = {props.showColPop}
                            >
                                <button onClick={()=>{props.updateState({showColPop: !props.showColPop})}}>
                                    <InsertRowRightOutlined className='mr5'/> Columns
                                </button>
                            </Popover>
                        
                    </div>
                </Skeleton>
            </div>

            <Skeleton loading={props.listLoading} active paragraph={{ rows: 13 }}>
                <div className='table-container'>
                    <table id='rfq-table'>
                        <thead>
                            <tr key={'t-head'}>
                                <th><FormattedMessage id='serial.no' /></th>
                                {!_isColumnToHide('rfqNumber') ? <th onClick={sortColumn}><FormattedMessage id='common.RFQ.Number' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('rfqBiddingSuppliersList') ? <th onClick={sortColumn}><FormattedMessage id='supplier' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('rfqDate') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.form.rfqDate.label' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('rfqExpirationDate') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.rfqExpirationDate' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('customerRFQNumber') ? <th onClick={sortColumn}><FormattedMessage id='supplier.rfq.listing.header.customerRFQNumber' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('orderPriority') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.priority' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('salesPersonFullName') ? <th onClick={sortColumn}><FormattedMessage id='supplier.rfq.form.gridHeader.salesPerson' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('purchaseRequestNumber') ? <th onClick={sortColumn}><FormattedMessage id='common.PR.Number' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('purchaseRequestDate') ? <th onClick={sortColumn}><FormattedMessage id='supplier.rfq.listing.header.prDate' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('prExpirationDate') ? <th onClick={sortColumn}><FormattedMessage id='supplier.rfq.listing.header.prExpirationDate' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('requestingDepartment') ? <th onClick={sortColumn}><FormattedMessage id='supplier.rfq.listing.header.requestingDepartment' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}  
                                {!_isColumnToHide('projectName') ? <th onClick={sortColumn}>Project <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('refNumber') ? <th onClick={sortColumn}><FormattedMessage id='customer.customerExpense.listing.header.refNumber' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('remarksInternal') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.remarksInternal' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('remarksSupplier') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.remarksCustomer' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('totalQuantityOnPO') ? <th onClick={sortColumn}><FormattedMessage id='supplier.purchaseRequest.listing.header.qtyOnPO' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('userStatus') ? <th onClick={sortColumn}><FormattedMessage id='common.status' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                                {!_isColumnToHide('invStatus') ? <th onClick={sortColumn}><FormattedMessage id='Invoice Status' defaultMessage='' /> <i className={ICONS.SORT} /></th>: ''}
                            </tr>
                        </thead>
                        <tbody>
                            {(rfqListData || []).length ? rfqListData.map((rowData, index)=>{
                                return <>
                                    <tr key={`rfq`+index}>
                                    <td>
                                        <span style={{width: '20px', display: 'inline-block'}}>{(pageNumber-1)*pageSize+(index+1)}</span>
                                        {
                                            rowData.rfqChildMasterList?.length ?
                                            
                                                childOnList.includes(rowData.rfqMasterId) ? 
                                                    <span className='cursor-pointer' onClick={()=>{
                                                            childOnList.splice(childOnList.indexOf(rowData.rfqMasterId), 1);
                                                            setChildOn(childOnList);
                                                        }
                                                    }>
                                                        <MinusSquareOutlined />
                                                    </span>
                                                    :
                                                    <span className='cursor-pointer' onClick={()=>{
                                                        setChildOn([...childOnList, rowData.rfqMasterId])
                                                    }
                                                    }>
                                                        <PlusSquareOutlined />
                                                    </span>
                                            
                                            
                                            :
                                            ''
                                        }
                                        </td> 
                                        {!_isColumnToHide('rfqNumber') ? <td>{<div className="cursor-pointer" onClick={()=>openRFQDetails(rowData)}>{rowData.rfqNumber}</div>}</td>: ''}
                                        {!_isColumnToHide('rfqBiddingSuppliersList') ? <td>
                                            {!rowData.rfqChildMasterList?.length ?
                                                <>
                                                    <div className="cursor-pointer" onClick={() => { openRFQBiddingSuppliers(rowData) }}>
                                                        {rowData.rfqBiddingSuppliersList.length}
                                                    </div>
                                                    {getResponseCount(rowData.rfqBiddingSuppliersList)} 
                                                </>
                                                :
                                                rowData.rfqBiddingSuppliersList?.length
                                            }
                                        </td>: ''}
                                        {!_isColumnToHide('rfqDate') ? <td>
                                            <Tooltip placement="topLeft" title={(rowData.rfqDate && getMomentDateForUIReadOnly({date: rowData.rfqDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
                                                { getMomentDateForUIReadOnly({date: rowData.rfqDate, format: format})}
                                            </Tooltip>
                                        </td>: ''}
                                        {!_isColumnToHide('rfqExpirationDate') ? <td>
                                            <Tooltip placement="topLeft" title={(rowData.rfqExpirationDate && getMomentDateForUIReadOnly({date: rowData.rfqExpirationDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
                                                {rowData.rfqExpirationDate ? getMomentDateForUIReadOnly({date: rowData.rfqExpirationDate, format: format}) : ""}
                                            </Tooltip>
                                        </td>: ''}
                                    
                                        {!_isColumnToHide('customerRFQNumber') ? <td>
                                            {rowData.customerRFQNumber || <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('orderPriority') ? <td>
                                            {rowData.orderPriority || <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('salesPersonFullName') ? <td>
                                            {rowData.slaesPersonFullName || <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('purchaseRequestNumber') ? <td>
                                            <div className='cursor-pointer' onClick={()=>{openPRDetails(rowData)}}>{rowData.purchaseRequestNumber || <div className="empty-data"></div>}</div>
                                        </td>: ''}
                                        {!_isColumnToHide('purchaseRequestDate') ? <td>
                                            <Tooltip placement="topLeft" title={(rowData.purchaseRequestDate && getMomentDateForUIReadOnly({date: rowData.purchaseRequestDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
                                                {rowData.purchaseRequestDate ? getMomentDateForUIReadOnly({date: rowData.purchaseRequestDate, format: format}) : <div className="empty-data"></div>}
                                            </Tooltip>
                                        </td>: ''}
                                        {!_isColumnToHide('prExpirationDate') ? <td>
                                            <Tooltip placement="topLeft" title={(rowData.prExpirationDate && getMomentDateForUIReadOnly({date: rowData.prExpirationDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
                                                {rowData.prExpirationDate ? getMomentDateForUIReadOnly({date: rowData.prExpirationDate, format: format}) : <div className="empty-data"></div>}
                                            </Tooltip>
                                        </td>: ''}
                                        {!_isColumnToHide('requestingDepartment') ? <td>{rowData.requestingDepartment}</td>: ''}  
                                        {!_isColumnToHide('projectName') ? <td>{rowData.projectName || <div className="empty-data"></div>}</td>: ''}
                                        {!_isColumnToHide('refNumber') ? <td>{rowData.refNumber || <div className="empty-data"></div>}</td>: ''}
                                        {!_isColumnToHide('remarksInternal') ? <td>
                                            {rowData.remarksInternal ? <p className="short-text">{ReactHtmlParser(rowData.remarksInternal)}</p> : <div className="empty-data"></div>}
                                            {rowData.remarksInternal ? <span className="more-details-btn"  onClick={()=>{openLongtText('Remarks', rowData.remarksInternal)}}>show more</span> : ''}
                                        </td>: ''}
                                        {!_isColumnToHide('remarksSupplier') ? <td>
                                            {rowData.remarksSupplier ? <p className="short-text">{ReactHtmlParser(rowData.remarksSupplier)}</p> : <div className="empty-data"></div>}
                                            {rowData.remarksSupplier ? <span className="more-details-btn"  onClick={()=>{openLongtText('Remarks', rowData.remarksSupplier)}}>show more</span> : ''}
                                        </td>: ''}
                                        {!_isColumnToHide('totalQuantityOnPO') ? <td>{getQuantityOnPO(rowData)}</td>: ''}
                                        {!_isColumnToHide('userStatus') ? <td>
                                        {
                                            (rowData.status === 'Rejected' && rowData.rejectionReason) ?
                                                <Tooltip placement="topLeft" title={rowData.rejectionReason}>
                                                    <div className={["status-code", (camelize(rowData.status || "")), " " + rowData.statusColor].join(' ')}><FormattedMessage id={rowData.status} defaultMessage={rowData.status} /></div>
                                                </Tooltip> : 
                                                <div>{rowData.status ? <div className={["status-code", (camelize(rowData.status || "")), " " + rowData.statusColor].join(' ')}><FormattedMessage id={rowData.status} defaultMessage={rowData.status} /></div>:<div className="empty-data"></div>}</div>
                                        }
                                        </td>: ''}
                                        {!_isColumnToHide('invStatus') ? <td>
                                            {rowData.invStatus || <div className="empty-data"></div>}
                                        </td>: ''}
                                    </tr>

                                    {rowData.children?.length && childOnList.includes(rowData.rfqMasterId) ? 
                                        rowData.children.map((childRowData, i)=>{
                                            return <tr key={i}>
                                                <td>
                                                    <span className='child-sr'>{index+1 + '.' + Number(i+1)}</span> &nbsp;
                                                </td>
                                                {!_isColumnToHide('rfqNumber') ? <td>{<div className="cursor-pointer" onClick={()=>openRFQDetails(childRowData)}>{childRowData.rfqNumber}</div>}</td>: ''}
                                                {!_isColumnToHide('rfqBiddingSuppliersList') ? <td>
                                                    {childRowData.rfqBiddingSuppliersList && !(childRowData.rfqChildMasterList || []).length ?
                                                        <>
                                                            <div className="cursor-pointer" onClick={() => { openRFQBiddingSuppliers(childRowData) }}>
                                                                {childRowData.rfqBiddingSuppliersList.length}
                                                            </div>
                                                            {getResponseCount(childRowData.rfqBiddingSuppliersList)} 
                                                        </>
                                                        : 
                                                        childRowData.rfqBiddingSuppliersList?.length
                                                    }
                                                </td>: ''}
                                                {!_isColumnToHide('rfqExpirationDate') ? <td>
                                                    {childRowData.rfqExpirationDate ? getMomentDateForUIReadOnly({date: childRowData.rfqExpirationDate, format: format}) : ""}
                                                </td>: ''}
                                                {!_isColumnToHide('rfqDate') ? <td>
                                                    { getMomentDateForUIReadOnly({date: childRowData.rfqDate, format: format})}
                                                </td>: ''}
                                                {!_isColumnToHide('customerRFQNumber') ? <td>
                                                    {childRowData.customerRFQNumber}
                                                </td>: ''}
                                                {!_isColumnToHide('orderPriority') ? <td>
                                                    {childRowData.orderPriority}
                                                </td>: ''}
                                                {!_isColumnToHide('salesPersonFullName') ? <td>
                                                    {childRowData.slaesPersonFullName}
                                                </td>: ''}
                                                {!_isColumnToHide('purchaseRequestNumber') ? <td>
                                                    <div className='cursor-pointer' onClick={()=>{openPRDetails(childRowData)}}>{childRowData.purchaseRequestNumber || <div className="empty-data"></div>}</div>
                                                </td>: ''}
                                                {!_isColumnToHide('purchaseRequestDate') ? <td>
                                                    <Tooltip placement="topLeft" title={(childRowData.purchaseRequestDate && getMomentDateForUIReadOnly({date: childRowData.purchaseRequestDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
                                                        {childRowData.purchaseRequestDate ? getMomentDateForUIReadOnly({date: childRowData.purchaseRequestDate, format: format}) : ""}
                                                    </Tooltip>
                                                </td>: ''}
                                                {!_isColumnToHide('prExpirationDate') ? <td>
                                                    <Tooltip placement="topLeft" title={(childRowData.prExpirationDate && getMomentDateForUIReadOnly({date: childRowData.prExpirationDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
                                                        {childRowData.prExpirationDate ? getMomentDateForUIReadOnly({date: childRowData.prExpirationDate, format: format}) : ""}
                                                    </Tooltip>
                                                </td>: ''}
                                                {!_isColumnToHide('requestingDepartment') ? <td>{childRowData.requestingDepartment || <div className="empty-data"></div>}</td>: ''}  
                                                {!_isColumnToHide('projectName') ? <td>{childRowData.projectName || <div className="empty-data"></div>}</td>: ''}
                                                {!_isColumnToHide('refNumber') ? <td>{childRowData.refNumber || <div className="empty-data"></div>}</td>: ''}
                                                {!_isColumnToHide('remarksInternal') ? <td>
                                                    {childRowData.remarksInternal ? <p className="short-text">{ReactHtmlParser(childRowData.remarksInternal || <div className="empty-data"></div>)}</p> : ''}
                                                    {childRowData.remarksInternal ? <span className="more-details-btn"  onClick={()=>{openLongtText('Remarks', childRowData.remarksInternal)}}>show more</span> : ''}
                                                </td>: ''}
                                                {!_isColumnToHide('remarksSupplier') ? <td>
                                                    {childRowData.remarksSupplier ? <p className="short-text">{ReactHtmlParser(childRowData.remarksSupplier || <div className="empty-data"></div>)}</p> : ''}
                                                    {childRowData.remarksSupplier ? <span className="more-details-btn"  onClick={()=>{openLongtText('Remarks', childRowData.remarksSupplier)}}>show more</span> : ''}
                                                </td>: ''}
                                                {!_isColumnToHide('totalQuantityOnPO') ? <td>{getQuantityOnPO(childRowData)}</td>: ''}
                                                {!_isColumnToHide('userStatus') ? <td>
                                                {
                                                    (childRowData.status === 'Rejected' && childRowData.rejectionReason) ?
                                                        <Tooltip placement="topLeft" title={childRowData.rejectionReason}>
                                                            <div className={["status-code", (camelize(childRowData.status || "")), " " + childRowData.statusColor].join(' ')}><FormattedMessage id={childRowData.status} defaultMessage={childRowData.status} /></div>
                                                        </Tooltip> : 
                                                        <div>{childRowData.status ? <div className={["status-code", (camelize(childRowData.status || "")), " " + childRowData.statusColor].join(' ')}><FormattedMessage id={childRowData.status} defaultMessage={childRowData.status} /></div>:''}</div>
                                                }
                                                </td>: ''}
                                                {!_isColumnToHide('invStatus') ? <td>
                                                    {childRowData.invStatus}
                                                </td>: ''}
                                            </tr>
                                        })
                                        :
                                ""
                                    }
                                </>
                            })
                        :

                        <tr key="empty-data-box">
                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                            <Empty />
                        </td>
                    </tr>
                        }

                        </tbody>
                    </table>
                </div>

                    {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham rfq-listing-data" style={agGridStyle} >
                        <div className="cursor-pointer">

                                <div className="clearfix"></div>
                            </div>
                            <Table pagination={false} onExpand={onExpand} expandedRowKeys={props.expandedRowKeys || []} columns={columnDefs} dataSource={rfqListData || []} className="customer-transactions-table" />
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={rfqList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                getRowHeight={getRowHeight}
                                onGridSizeChanged={onFirstDataRendered}
                            >
                            </AgGridReact>
                        </div>
                    </div> */}
            </Skeleton>

            <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>
                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={rfqCount}
                            showTotal={(total) => {
                                return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
                            }}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize, props);
                                props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize);
                            }}
                        />
                    </div>
                </Skeleton>
            </div>
        </>
    );
};

export default injectIntl(RFQListingComp);
