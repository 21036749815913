import React, { useRef } from 'react';
import './index.scss';
import { Form } from '@ant-design/compatible';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import '@ant-design/compatible/assets/index.css';
import { Skeleton, Tabs, Tooltip } from 'antd';

const {TabPane} = Tabs;

const DocumentNumberComponent = (props) => {
    const gridApiRef = useRef(null); // Use useRef for gridApi reference

    const columnDefs = [
        {
            headerComponentFramework: () => (
                <FormattedMessage id='modal.settings.documentnumber.transaction' defaultMessage='' />
            ),
            width: 167,
            field: 'label',
            resizable: true,
        },
        {
            headerComponentFramework: () => (
                <div>Prefix</div>
            ),
            field: 'txNamePefix',
            editable: true,
            resizable: true,
            width: 200,
            cellEditor: 'customTextEditor',
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
            cellEditorParams: (params) => ({
                value: params.node.data.txNamePefix || "",
                onChange: (value) => {
                    params.node.data.txNamePefix = value;
                },
            }),
        },
        {
            headerComponentFramework: () => (
                <FormattedMessage id='modal.settings.documentnumber.numberExample' defaultMessage='' />
            ),
            width: 150,
            field: 'numberExample',
            resizable: true,
        },
        {
            headerComponentFramework: () => (
                <div>
                    <FormattedMessage id='modal.settings.documentnumber.nextSequence' defaultMessage='' />
                    <Tooltip
                        placement="top"
                        title={<FormattedMessage id='modal.settings.documentnumber.nextSequence.tooltip' defaultMessage='Your next document will be generated with the sequence you specify' />}
                        trigger="click"
                    >
                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                    </Tooltip>
                </div>
            ),
            field: 'number',
            editable: true,
            resizable: true,
            width: 200,
            cellEditor: 'customNumberEditor',
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
            cellRendererFramework: (params) => (
                <div>{Number(params.node.data.number || 0)}</div>
            ),
        },
        {
            headerComponentFramework: () => (
                <FormattedMessage id='modal.settings.documentnumber.action' defaultMessage='' />
            ),
            resizable: true,
            field: 'action',
            lockPosition: true,
            pinned: 'right',
            width: 100,
            cellRendererFramework: (params) => (
                <div className="table-cell-action">
                    {params.node.data.isToEdit ? (
                        <>
                            <Link onClick={() => handleSave(params)}>
                                <i className="fa fa-save" />
                            </Link>
                            <Link onClick={() => handleCancel(params)}>
                                <i className="fa fa-ban" />
                            </Link>
                        </>
                    ) : (
                        <Link onClick={() => handleEdit(params)}>
                            <i className="fa fa-edit" />
                        </Link>
                    )}
                </div>
            ),
        },
    ];

    const handleSave = (params) => {
        const rowNode = getRowAtIndex(params.node.rowIndex);
        if (rowNode) {
            rowNode.data.relationshipId = (props.companyInfo || {}).relationshipId;
            saveDocumentNumber(
                {
                    txName: params.data.txName,
                    txType: params.data.txType,
                    txNamePrefix: params.data.txNamePefix,
                    number: params.data.number,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                },
                props
            );
            rowNode.data.isToEdit = false;
            gridApiRef.current.refreshCells({ force: true });
        }
    };

    const handleCancel = (params) => {
        const rowNode = getRowAtIndex(params.node.rowIndex);
        if (rowNode) {
            gridApiRef.current.stopEditing(true);
            rowNode.data.isToEdit = false;
            gridApiRef.current.refreshCells({ force: true });
        }
    };

    const handleEdit = (params) => {
        if (gridApiRef.current?.forEachNode) {
            gridApiRef.current.forEachNode((rowNode, index) => {
                const rowNodeData = getRowAtIndex(index);
                if (rowNodeData) rowNodeData.data.isToEdit = false;
            });
        }
        const rowNode = getRowAtIndex(params.node.rowIndex);
        if (rowNode) {
            rowNode.data.isToEdit = true;
            gridApiRef.current.refreshCells({ columns: ["action"], force: true });
            params.api.startEditingCell({ rowIndex: params.node.rowIndex });
        }
    };

    const getRowAtIndex = (index) => {
        return gridApiRef.current?.getDisplayedRowAtIndex(index);
    };

    const saveDocumentNumber = (payload, props) => {
        const modalData = {
            modalBody: "Are you sure you want to Submit?",
            handleSubmit: () => {
                if(props.activeSequenceTab==='sales_sequence'){
                    props.saveSalesDocumentNumber(payload, props);
                }
                if(props.activeSequenceTab==='purchase_sequence'){
                    props.savePurchaseDocumentNumber(payload, props);
                }
                props.popModalFromStack();
            },
        };
        props.pushModalToStack(modalData);
    };

    const getRowHeight = () => 35;

    const onGridReady = (params) => {
        gridApiRef.current = params.api; // Assign API instance to ref
        params.api.sizeColumnsToFit();
    };

    return (
        <div>
            <div className="modal-dialog">
                <div className="modal-content full-width modify-width">
                    
                    <div className="inner-content">
                    <Tabs
                        activeKey={props.activeSequenceTab || 'sales_sequence'}
                        type="line"
                        onChange={(val)=>{
                            props.updateState({activeSequenceTab: val})
                        }}
                    >
                        <TabPane tab={"Sales Sequence"} key="sales_sequence">
                            <Skeleton loading={props.listLoading} paragraph={{ rows: 7 }} active>
                                <Form className="pr20" layout="vertical" hideRequiredMark>
                                    <Form.Item>
                                        <CustomAgGrid
                                            onGridReady={onGridReady}
                                            columnDefs={columnDefs}
                                            rowData={props.tabSalesDocumentOptionsList || []}
                                            getRowHeight={getRowHeight}
                                            gridStyle={{
                                                width: '100%',
                                                height: '100%',
                                                marginBottom: '10px',
                                            }}
                                        />
                                    </Form.Item>
                                </Form>
                            </Skeleton>
                        </TabPane>
                        <TabPane tab={"Purchase Sequence"} key="purchase_sequence">
                            <Skeleton loading={props.listLoading} paragraph={{ rows: 7 }} active>
                                <Form className="pr20" layout="vertical" hideRequiredMark>
                                    <Form.Item>
                                        <CustomAgGrid
                                            onGridReady={onGridReady}
                                            columnDefs={columnDefs}
                                            rowData={props.tabPurchaseDocumentOptionsList || []}
                                            getRowHeight={getRowHeight}
                                            gridStyle={{
                                                width: '100%',
                                                height: '100%',
                                                marginBottom: '10px',
                                            }}
                                        />
                                    </Form.Item>
                                </Form>
                            </Skeleton>
                        </TabPane>
 
                    </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentNumberComponent;
