import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST, STUDENT_REGISTRATION_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const saveRegistrationFormSetting = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
   
    return axios
      .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/saveFormSetting`, payload)
      .then(res => {
        if(!props || !props.defaultSchoolForm){ dispatch({ type: COMMON_ACTIONS.DEFAULT_SCHOOL_FORM, data: res.data.registrationFormSettingId });}
        showToasterMessage({ messageType: 'success', description: lang[res.data.message || 'saved.successfully'] });
        props.popModalFromStack();
       // props.fetchRegistrationFormSettingList({...payload, pageSize: 100, pageNumber: 0})
        // (props.getAllMembershipForm && payload.registrationType === 'membership') && props.getAllMembershipForm({...payload, pageSize: 100, pageNumber: 0});
        (props.getAllStudentForm && payload.registrationType === 'student') && props.getAllStudentForm({...payload, pageSize: 100, pageNumber: 0})
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } 
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const fetchRegistrationFormSetting = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      // dispatch({ type: SETTING_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING });
      return axios
        .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/getFormSetting?relationshipId=${payload.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          }else{
            err.actionToCall = SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING;
          }
        })
    }
}

export const fetchAvailablePaymentGateway =  (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      //.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/getAuthNetData?relationshipId=${payload.relationshipId}`)
      .get(`${config.PAYMENT_BASE_URL}${config.API_VERSION}/${config.rootContextPayment}/paymentGateway/getAllPaymentGateways?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res;
        dispatch({type: STUDENT_REGISTRATION_ACTION_LIST.AVAILABLE_PAYMENT_GATEWAY, data})
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.RELATIONSHIP_DATA;
        }
      })
  }
}

export const saveAsDraftRegistrationForm = (props) => {
  const payload = {
    registrationFormSettingDraftId: props.registrationFormSettingDraftId,
    registrationFormSettingData: JSON.stringify(_getRegistrationFormSettingData(props)),
    relationshipId: props.companyInfo.relationshipId,
    createdByUserId: props.userInfo.userId,
    version: props.draftVersion || 0
  }
  return dispatch => {
   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/saveFormSettingAsDraft`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: lang[res.data.message || 'saved.successfully'] });
        props.popModalFromStack();

        const payload2 = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
          relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId
        }
        props.fetchRegistrationFormSettingDraftList(payload2);
        props.countSchoolFormSettingDraft(payload2);

      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
  }
}



export const resetRegistrationFormSetting = () => {
  return dispatch => {
    dispatch({ type: SETTING_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING });
  }
}


const _getRegistrationFormSettingData = (props) =>{
  const draftData = {
    departmentId: props.departmentId,
    formShortName: props.formShortName,
    formType: props.formType,
    formHeading: props.formHeading,
    schoolSessionId: props.schoolSessionId,
    selectedSessionName: props.selectedSessionName,
    subHeading: props.subHeading,
    dobType: props.dobType,
    addressDisplay: props.addressDisplay,
    educationHistory: props.educationHistory,
    educationHistoryRequired: props.educationHistoryRequired,
    familyDoctorNameDisplay: props.familyDoctorNameDisplay,
    familyDoctorNameRequired: props.familyDoctorNameRequired,
    emergencyContactDisplay: props.emergencyContactDisplay,
    emergencyContactRequired: props.emergencyContactRequired,
    askAddTeacher: props.askAddTeacher,
    askAddTeacherRequired: props.askAddTeacherRequired,
    askAddVolunteer: props.askAddVolunteer,
    askAddVolunteerRequired: props.askAddVolunteerRequired,
    askOrgMember: props.askOrgMember,
    askOrgMemberRequired: props.askOrgMemberRequired,
    askFinanacialAssistance: props.askFinanacialAssistance,
    askFinanacialAssistanceRequired: props.askFinanacialAssistanceRequired,
    fatherFirstNameDisplay: props.fatherFirstNameDisplay,
    fatherInfoRequired: props.fatherInfoRequired,
    motherFirstNameDisplay: props.motherFirstNameDisplay,
    motherInfoRequired: props.motherInfoRequired,
    collectOnlinePayment: props.collectOnlinePayment,
    classFees: props.classFees,
    selectedPaymentGateway: props.selectedPaymentGateway,
    discountRuleList: props.discountRuleList,
    isAllowParentPaymentPlan: props.isAllowParentPaymentPlan,
    paymentPlan: props.paymentPlan,
    reminderList: props.reminderList,
    selectedLedger: props.selectedLedger,
    ledgerAccountIdInvoice: props.ledgerAccountIdInvoice,
    invoiceItemDescription: props.invoiceItemDescription,
    docName: props.docName,
    documentNameId: props.documentNameId,
    paymentTerm: props.paymentTerm,
    questionList: props.questionList,
    isFormActive: props.isFormActive,
    isPublishParentPortal: props.isPublishParentPortal,
    inactiveMessage: props.inactiveMessage,
    formDescription: props.formDescription,
    footerMessage: props.footerMessage,
    successMessage: props.successMessage,
    isFormSetting: props.isFormSetting,
    isSendEmail: props.isSendEmail,
    emailDetailDrawerVisible: props.emailDetailDrawerVisible,
    docName: props.docName,
    emailContext: props.emailContext,
  };
  return draftData
}