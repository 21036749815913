import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { E_STORE } from "../../../../../static/constants";

import { COMMON_ACTIONS } from "../../../../../static/constants";


export const getPolicyPageData = (payload)=>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_header_page/getPolicyPage?relationshipId=${payload.relationshipId}`
      )
          .then(response => {
              dispatch({ type: E_STORE.POLICY_PAGE_DATA, data: (response.data || {}) });
          })
          .catch(err => {
              dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
  }
  }