import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const saveDocumentNumber = (params,props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let payload = {
      relationshipId: params.relationshipId,
      txId: Number(params.number || 0),
      txName: params.txName,
      txNamePrefix:params.txNamePrefix
    };
   
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/sequence/updateTxId`, payload)
      .then(res => {
        const data = {
          data: Number(params.number || 0),
          txType: params.txType
        }
        dispatch({ type: SETTING_ACTION_LIST.DOCUMNET_NUMBERS, data });
        props.getDocumentNumber({ relationshipId: props.companyInfo.relationshipId });
        props.getDocumentPrefix({ relationshipId: props.companyInfo.relationshipId });
        showToasterMessage({ messageType: 'success', description: res.data.message || 'Data Saved successfully.' })
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
  }
}

export const getDocumentNumber = (props) => {

  return dispatch => {
    let payload = {
      relationshipId: props.relationshipId
    };
   
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/sequence/getDocSequence?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SETTING_ACTION_LIST.DOCUMNET_NUMBERS, data: res.data });
      })
      .catch(err => {
       showToasterMessage({ description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
  }
}

export const getDocumentPrefix = (props) => {

  return dispatch => {
    let payload = {
      relationshipId: props.relationshipId
    };
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/sequence/getDocPrefix?relationshipId=${payload.relationshipId}`)
      .then(res => {
      
        dispatch({ type: SETTING_ACTION_LIST.DOCUMNET_PREFIXS, data: res.data });
      })
      .catch(err => {
      })
  }
}


// new service code

export const saveSalesDocumentNumber = (params,props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    let payload = {
      relationshipId: params.relationshipId,
      txId: Number(params.number || 0),
      txName: params.txName,
      txNamePrefix:params.txNamePrefix
    };
   
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/sequence/updateTxId`, payload)
      .then(res => {
        const data = {
          data: Number(params.number || 0),
          txType: params.txType
        }
        dispatch({ type: SETTING_ACTION_LIST.SALES_DOCUMENT_NUMBERS, data });
        props.getSalesDocumentNumber({ relationshipId: props.companyInfo.relationshipId });
        props.getSalesDocumentPrefix({ relationshipId: props.companyInfo.relationshipId });
        showToasterMessage({ messageType: 'success', description: res.data.message || 'Data Saved successfully.' })
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const getSalesDocumentNumber = (props) => {

  return dispatch => {
    let payload = {
      relationshipId: props.relationshipId
    };
   
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/sequence/getDocSequence?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SETTING_ACTION_LIST.SALES_DOCUMENT_NUMBERS, data: res.data });
      })
      .catch(err => {
       showToasterMessage({ description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const getSalesDocumentPrefix = (props) => {

  return dispatch => {
    let payload = {
      relationshipId: props.relationshipId
    };
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/sequence/getDocPrefix?relationshipId=${payload.relationshipId}`)
      .then(res => {
      
        dispatch({ type: SETTING_ACTION_LIST.SALES_DOCUMENT_PREFIXS, data: res.data });
      })
      .catch(err => {
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}





export const savePurchaseDocumentNumber = (params,props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    let payload = {
      relationshipId: params.relationshipId,
      txId: Number(params.number || 0),
      txName: params.txName,
      txNamePrefix:params.txNamePrefix
    };
   
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/sequence/updateTxId`, payload)
      .then(res => {
        const data = {
          data: Number(params.number || 0),
          txType: params.txType
        }
        dispatch({ type: SETTING_ACTION_LIST.PURCHASE_DOCUMENT_NUMBERS, data });
        props.getPurchaseDocumentNumber({ relationshipId: props.companyInfo.relationshipId });
        props.getPurchaseDocumentPrefix({ relationshipId: props.companyInfo.relationshipId });
        showToasterMessage({ messageType: 'success', description: res.data.message || 'Data Saved successfully.' })
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const getPurchaseDocumentNumber = (props) => {

  return dispatch => {
    let payload = {
      relationshipId: props.relationshipId
    };
   
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/sequence/getDocSequence?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SETTING_ACTION_LIST.PURCHASE_DOCUMENT_NUMBERS, data: res.data });
      })
      .catch(err => {
       showToasterMessage({ description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const getPurchaseDocumentPrefix = (props) => {

  return dispatch => {
    let payload = {
      relationshipId: props.relationshipId
    };
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/sequence/getDocPrefix?relationshipId=${payload.relationshipId}`)
      .then(res => {
      
        dispatch({ type: SETTING_ACTION_LIST.PURCHASE_DOCUMENT_PREFIXS, data: res.data });
      })
      .catch(err => {
      })

      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}