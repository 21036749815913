import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


  export const saveOrUpdateStudentClassRef = (requestObj, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/studentClassRef/saveOrUpdate`,
          requestObj,
        )
        .then(res => {
          showToasterMessage({ description: (res || {}).message || 'Saved successfully.', messageType: 'success' });
          if (props.getRegisteredMemberByFormId) {
            const payload = {
              relationshipId: props.companyInfo.relationshipId,
              pageNumber: 1,
              pageSize: 200,
              // formId: props.studentFormList[0].registrationFormSettingId,
              formId: 0,
              relationshipEmployeeId: props.userInfo.relationshipEmployeeId,
            };

            // props.getRegisteredMemberByFormId(payload);
            // props.getRegisteredMemberCount(payload);
            props.getSchoolStudents(payload);
          }
        })
        .catch(err => {
          showToasterMessage({ description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        })
    }
  }