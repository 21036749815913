import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import { downloadFileFromBucket } from './../../../actions/downloadFileAction';
import CommunicationCompo from '../../../components/schoolManagement/Communication';
import {getCustomerCommunication, getCustomerCommunicationCount, resetPaginatedData, getCustomerEmailCommunication, getSupplierEmailCommunication } from './action';
import CommunicationReviewComp from '../../drawer/communication';
import EmailComponent from '../../../containers/drawer/recurringInvoiceEmail';

class Communication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
      customerInfo: {},
      activeTab: "customer_emails"
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const customerInfo = this.props.customerInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1, pageSize: 25,
      customerId: customerInfo.customerId
    };
    // this.props.getCustomerCommunication(payload);
    // this.props.getCustomerCommunicationCount(payload);
    this.props.getCustomerEmailCommunication(payload);
  }

  render() {
    return <>
      {this.state.emailDetailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      {this.state.communicationReviewDrawerVisible && <CommunicationReviewComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      <CommunicationCompo  {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    </>
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    communicationList: state.school.schoolCommunicationList,
    communicationCount: state.school.schoolCommunicationCount,
    listLoading: state.common.listLoading,
    customerEmailCommunications: state.communicationReducer.customerEmailCommunications,
    supplierEmailCommunications: state.communicationReducer.supplierEmailCommunications
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getCustomerCommunication, 
      getCustomerCommunicationCount, 
      resetPaginatedData,
      downloadFileFromBucket,
      getCustomerEmailCommunication,
      getSupplierEmailCommunication
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Communication);
