import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS

} from '../../../static/constants';
import cloneDeep from 'lodash/cloneDeep';
import {fetchSuppliers, getSupplierCount, resetPaginatedData } from '../../supplier/Listing/action';
import {  showToasterMessage, formatDateForBackend } from '../../../utils';
import { fetchSupplierSummary } from '../../../actions/commonActions';


export const createOrUpdateSupplier = (payload) => {
    const requestObj = getSupplierRequestObj(payload);
    var formData = new FormData();
    if(payload.fileToUpload){
      formData.append("file", payload.fileToUpload);
    }
    formData.append('supplier', JSON.stringify(requestObj, true));
    formData.append('bucketName' ,payload.companyInfo?.bucketName)

    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
      return axios
        .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/suppliers/saveOrUpdateSupplier`,
        formData,{
          headers: {
            'content-type': 'multipart/form-data'
        }
        }
        )
        .then(res => {
          payload.updateState({ supplier: {supplierDrawerVisible: false} });
          let data = {
            relationshipId: payload.companyInfo.relationshipId || 0,
            pageSize: 100,
            pageNumber: 1,
          }
          dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
          resetPaginatedData()(dispatch);
          fetchSuppliers(data)(dispatch);
          fetchSupplierSummary(data)(dispatch);
          getSupplierCount(data)(dispatch);
          showToasterMessage({ messageType: "success", description: "Supplier created successfully" });
        })
        .catch(err => {
          payload.updateState({ supplier: {supplierDrawerVisible: true, isclone: true, supplierData : {}} });
         showToasterMessage({ description: ((err.response || {}).data) })

        })
    }
  }

const getSupplierRequestObj = (payload) => {
  let relationshipId = payload.companyInfo.relationshipId || 0;
  let industry = (payload.industries.find(ind => (ind.businessIndustryId === payload.businessIndustryId))) || {};
  let paymentTerm = (payload.paymentTerms.find(ind => (ind.paymentTermId === payload.paymentTermId))) || {};
  let supplierSource = (payload.supplierSources.find(ind => (ind.supplierSourceId === payload.supplierSourceId))) || {};
  let supplierType = (payload.businessTypes.find(supplierType => (supplierType.businessTypeId === payload.supplierTypeId))) || {};
  let contactCopy = cloneDeep(payload.contact);
  contactCopy.relationshipId = relationshipId;
  contactCopy.isPrimaryContact = 1;
  contactCopy.contactType = "Supplier";
  let taxIdentificationsList = [];
  
  (payload.supplierTaxIdentificationsList || []).length && payload.supplierTaxIdentificationsList.forEach((data, index) => {
    if (data && data.taxIdentificationType && data.taxIdentificationNumber) {
      taxIdentificationsList.push({
        relationshipId: relationshipId,
        supplierId: payload.isUpdate ? payload.supplierId : null,
        taxIdentificationType: data.taxIdentificationType,
        taxIdentificationNumber: data.taxIdentificationNumber,
        populateOnTransaction: data.populateOnTransaction ? 1 : 0,
        supplierTaxIdentificationId:data.supplierTaxIdentificationId || null
      })
    }
  })
  let locationList = [];
  if(payload.isUpdate){
    locationList = payload.supplierData.boLocationList;
  }else{
  let billingCountry = (payload.countries.find(country => (country.countryName === payload.billingAddress.countryName))) || {};
  let shippingCountry = (payload.countries.find(country => (country.countryName === payload.shippingAddress.countryName))) || {};
  let billingAddress = cloneDeep(payload.billingAddress);
  let shippingAddress = cloneDeep(payload.shippingAddress);
  billingAddress.relationshipId = relationshipId;
  billingAddress.countryId = billingCountry.countryId;
  billingAddress.isDefault = 1;
  billingAddress.locationType = 'BillingAddress';
  shippingAddress.relationshipId = relationshipId;
  shippingAddress.countryId = shippingCountry.countryId;
  shippingAddress.isDefault = 1;
  shippingAddress.locationType = 'ShippingAddress';
  billingAddress.streetAddress1 && locationList.push(billingAddress);
  shippingAddress.streetAddress1 && locationList.push(shippingAddress);
  }

  return {
    supplierId: payload.isUpdate ? payload.supplierId : undefined,
    isActive: payload.isActive || 1,
    relationshipSince: payload.relationshipSince && formatDateForBackend(payload.relationshipSince),
    supplierStoreName: payload.supplierStoreName,
    supplierEmail: payload.supplierEmail,
    supplierPhone: payload.supplierPhone,
    industryId: industry.businessIndustryId,
    supplierTypeId: supplierType.businessTypeId,
    supplierSourceId: supplierSource.supplierSourceId,
    paymentTermId: paymentTerm.paymentTermId,
    shippingAddressSameAsBilling: payload.shippingAddressSameAsBilling,
    gstMechanism: payload.gstMechanism,
    gstNumber: payload.gstNumber,
    panNumber: payload.panNumber,
    locatedInSez: payload.locatedInSez ? 1 : 0,
    boContactList: payload.isUpdate ? payload.supplierData.boContactList : (contactCopy.firstName ? [{...(contactCopy || {}), countryCallingCode: payload?.companyInfo?.countryCallingCode}] : []),
    boLocationList: locationList,
    dateCreated: payload.isUpdate ? payload.supplierData.dateCreated : undefined,
    webSite: payload.webSite,
    // createdByUserId: 37,
    // dateUpdated: getCurrentDateForBackend(new Date()),
    relationshipId: relationshipId,
    supplierTaxIdentificationsList: taxIdentificationsList,
    industryName:payload.industryName,
    debitLimit:payload.debitLimit,
    version: payload.version
  }
}


export const isDuplicateSupplier = (payload, props) => {
  return dispatch => {
      return axios
        .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/suppliers/isDuplicateSupplier?relationshipId=${payload.relationshipId}&supplierName=${payload.supplierName}`)
        .then(res => {
          const { data } = res
          if(data){
            showToasterMessage({messageType: 'error',  description: 'Duplicate Supplier Exists!' })
            props.updateDrawerState({isDuplicateStoreName: true, supplierStoreName: props.preSupplierStoreName})
          }
        })
        .catch((err) => {
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: 'Some error occurred' })
          }
        })
    }
}