import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const deleteCrmLeadSource = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadSource/delete`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 0,
            pageSize: 25,
          }
          props.getAllLeadSources(payload);
        })
        .catch((err = {}) => {
            showToasterMessage({
                description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
        })
    }
  }