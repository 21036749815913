import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
//import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip, Popover } from "antd";
import {
  fetchPaginationDataIfNeeded,
  getMomentDateForUIReadOnly,
  fixedDecimalAndFormateNumber,
  sortTable,
} from "../../../../utils";
import SODetail from "../../../../containers/modal/modalBody/customer/AmendSoDetails";
import SQDetail from "../../../../containers/modal/modalBody/customer/SQDetail";
// import RFQDetail from '../../../../containers/modal/modalBody/supplier/RFQDetail';
// import RFQSplitDetail from '../../../../containers/modal/modalBody/supplier/RFQSplitDetail';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
//import PageBreadcrumb from '../../../PageBreadcrumb';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants'
import InvoiceInformation from "./../../../../containers/modal/modalBody/customer/InvoiceInformation";
import PackageInformation from "./../../../../containers/modal/modalBody/customer/PackageInformation";
import ShipmentInformation from "./../../../../containers/modal/modalBody/customer/ShipmentInformation";
import { CONSTANTS } from "../../../../static/constants";
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
// import { Dropdown } from '../../../general/Dropdown';
// import { TextBox } from '../../../general/TextBox';
// import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
// import find from 'lodash.find';
import { FilterOutlined, InsertRowRightOutlined } from "@ant-design/icons";
import {
  AmazonLogo,
  CareemLogo,
  CarrefourLogo,
  DesertCartLogo,
  DubaiStoreLogo,
  GoogleMerchantLogo,
  JuztBuyLogo,
  NoonFoodLogo,
  NoonLogo,
  TalabatLogo,
  TradelingLogo,
  ShopifyLogo,
  EbayLogo,
  MeeshoLogo,
} from "../../../../assets/images";
import CustomerProfile from "../../../../containers/modal/modalBody/customer/Profile";
import { TextBox } from "../../../general/TextBox";

// import { Switch } from 'react-router-dom';

// const { RangePicker } = DatePicker;
// const { Panel } = Collapse;
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const AmendSalesOrderListingComp = (props) => {
  const {
    aSoList,
    updateState,
    companyInfo,
    amendSalesOrderCount,
    pageNumber,
    pageSize,
    dir = 0,
  } = props;

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("fetchAmendSalesOrders", "aSoList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchAmendSalesOrders(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const getInvoicedQuantity = (data) => {
    return (
      <div
        className="cursor-pointer circles-ling"
        style={{ cursor: data.status === "cancelled" ? "not-allowed" : "pointer" }}
        onClick={() => {
          if (data.status === "Pending Approval" || data.status === "Rejected" || data.status === "cancelled")
            return;
          props.showModal({
            title: (
              <div>
                <div className="ant-modal-title">Invoice Information</div>

                <div
                  className="ant-modal-subtitle light-txt"
                  style={{ fontSize: "14px" }}
                >
                  {data.customerName}
                </div>
              </div>
            ),
            modalBody: <InvoiceInformation soData={data} {...props} />,
            width: 900,
            hideFooter: true,
          });
        }}
      >
        {/* //<Progress className={data.statusInvoice === 'fulfilled' ? "complete" : "incomplete"} format={() => {
        //     return ''
        // }} percent={data.statusInvoice === 'fulfilled' ? 100 : 33} /></div>; */}
        {data.statusInvoice === "fulfilled" ? (
          <i className="fa fa-circle"></i>
        ) : data.statusInvoice === "partially.fulfilled" ? (
          <i className="fa fa-adjust"></i>
        ) : (
          <i className="fa fa-circle-o"></i>
        )}
      </div>
    );
  };

  const getShippedQuantity = (data) => {
    return (
      <div
        className="cursor-pointer circles-ling"
        style={{ cursor: data.status === "cancelled" ? "not-allowed" : "pointer" }}

        onClick={() => {
          if (data.status === "Pending Approval" || data.status === "Rejected")
            return;
          props.showModal({
            title: (
              <div>
                <div className="ant-modal-title">Shipment Information</div>
                <div
                  className="ant-modal-subtitle light-txt"
                  style={{ fontSize: "14px" }}
                >
                  {data.customerName}
                </div>
              </div>
            ),
            modalBody: <ShipmentInformation soData={data} {...props} />,
            width: 900,
            hideFooter: true,
            wrapClassName: "shipment-information-modal",
          });
        }}
      >
   
        {data.statusShipment === "fulfilled" ? (
          <i className="fa fa-circle"></i>
        ) : data.statusShipment === "partially.fulfilled" ? (
          <i className="fa fa-adjust"></i>
        ) : (
          <i className="fa fa-circle-o"></i>
        )}
      </div>
    );
  };

  const getQuantityPacked = (data) => {
    return (
      <div
        className="cursor-pointer circles-ling"
        style={{ cursor: data.status === "cancelled" ? "not-allowed" : "pointer" }}

        onClick={() => {
          if (data.status === "Pending Approval" || data.status === "Rejected")
            return;
          props.showModal({
            title: (
              <div>
                <div className="ant-modal-title">Package Information</div>
                <div
                  className="ant-modal-subtitle light-txt"
                  style={{ fontSize: "14px" }}
                >
                  {data.customerName}
                </div>
              </div>
            ),
            modalBody: <PackageInformation soData={data} {...props} />,
            width: 900,
            hideFooter: true,
          });
        }}
      >
      
        {data.statusPackage === "fulfilled" ? (
          <i className="fa fa-circle"></i>
        ) : data.statusPackage === "partially.fulfilled" ? (
          <i className="fa fa-adjust"></i>
        ) : (
          <i className="fa fa-circle-o"></i>
        )}
      </div>
    );
  };

  const getQuantityOnPR = (data) => {
    return (
      <div className="circles-ling" 
      style={{ cursor: data.status === "cancelled" ? "not-allowed" : "pointer" }}
      
      >
        {data.statusPR === "converted" ? (
          <i className="fa fa-circle"></i>
        ) : data.statusPR === "converted.partially" ? (
          <i className="fa fa-adjust"></i>
        ) : (
          <i className="fa fa-circle-o"></i>
        )}
      </div>
    );
  };

  const openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      amendSalesOrderMasterId: data.amendSalesOrderMasterId,
    };
    props.pushModalToStack({
      modalBody: <SODetail {...props} soPayload={soPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const openSQDetails = (data) => {
    const sqPayload = {
      customerId: data.customerId,
      salesQuotationMasterId: data.salesQuotationMasterId,
    };
    props.pushModalToStack({
      modalBody: <SQDetail {...props} sqPayload={sqPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

 

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  const fetchSourceLogo = (salesChannel,defName) => {
    switch (salesChannel) {
      case "amazon":
        return (
          <img
            src={AmazonLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "careem":
        return (
          <img
            src={CareemLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "carrefour":
        return (
          <img
            src={CarrefourLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "desertcart":
        return (
          <img
            src={DesertCartLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "dubaistore":
        return (
          <img
            src={DubaiStoreLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "googlemerchant":
        return (
          <img
            src={GoogleMerchantLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "juztbuy":
        return (
          <img
            src={JuztBuyLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "noonfood":
        return (
          <img
            src={NoonFoodLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "noon":
        return (
          <img
            src={NoonLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "talabat":
        return (
          <img
            src={TalabatLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "tradeling":
        return (
          <img
            src={TradelingLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "shopify":
        return (
          <img
            src={ShopifyLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "ebay":
        return (
          <img
            src={EbayLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "meesho":
        return (
          <img
            src={MeeshoLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      default:
        return defName ||<div className="empty-data"></div> ;
    }
  };

 
  const sortColumn = (e) => {
    sortTable(
      document.getElementById("sales-order-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };

  return (
    <Fragment>
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="sales.salesOrder.table.heading"
                defaultMessage=""
              />
            </div>
            {props.amendSalesOrderCount ? (
              <>
                <div className="vertical-sep" />
                <div>{props.amendSalesOrderCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">
          <TextBox 
                        // className='new-fed1'
                        // style={{ marginTop: '', width: '140px', marginRight: '5px', float:'left'}}
                        className=''
                        placeholder='SO #'
                        type='text'
                        maxLength={15}
                        value={props.soNum}
                        onChange={(e) => {
                       if(e.target.value?.length > 3){  
                         const payload = {
                            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                            filters: {
                                customerId: props.selectedCustomerId || 0,
                                soNumber:e.target.value || null,
                        
                            }
                        }
                         
                        props.fetchSalesOrdersByFilter(payload);
                        props.getSalesOrdersCountByFilter(payload);} else if(e.target.value.length === 0){
                          const payload = {
                            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                            filters: {
                                customerId: props.selectedCustomerId || 0,
                                soNumber:e.target.value || null,
                        
                            }
                            
                        } 
                        props.fetchSalesOrdersByFilter(payload);
                        props.getSalesOrdersCountByFilter(payload);
                         };
                            
                        }}
                    />
            <button
              onClick={() => {
                props.updateState({
                  salesOrderFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined onClick={() => {}} /> &nbsp;{" "}
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>

            <Popover
              content={<AddAndRemoveCustomerListingColumn {...props} />}
              title={
                <div className="text-center">
                  <FormattedMessage id="common.addRemove" defaultMessage="" />{" "}
                </div>
              }
              placement="leftTop"
              trigger="click"
              arrow="right"
              // visible={props.showColPop}
            >
              <button
                onClick={() => {
                  props.updateState({ showColPop: !props.showColPop });
                }}
              >
                <InsertRowRightOutlined className='mr5'/>
                Columns
              </button>
            </Popover>
          </div>
          </Skeleton>
        </div>

        <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>
          <table id="sales-order-table">
            <thead>
              <tr>
                <th>S. No.</th>
                {!_isColumnToHide("soNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.soNo"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                <th>Order #</th>
                <th>Source</th>
                {!_isColumnToHide("salesOrderMasterId") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.orderNo"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("soSourceName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.salesChannel"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.customer"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("quotationNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.quoteNumber"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("salesOrderTotalAmount") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.amount"
                      defaultMessage="Amount"
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("userStatus") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="common.status" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("status") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.systemStatus"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("referenceNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.refNumber"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("salesOrderDate") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.date"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQtyInvoiced") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.qtyInvoiced"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQtyPacked") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.qtyPacked"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQtyShipped") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.qtyShipped"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQtyOnPR") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.qtyOnPR"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("deliveryDate") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.deliveryDate"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("statusPRMessage") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="prStatus.text" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerInquiryNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customerSalesEnquiryNumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerRFQNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customerRfqNumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerPONumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customerPONumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("paymentTermName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.paymentTerm"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("project") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="common.project" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("priority") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.listing.totalOrder.header.priority"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("salesPersonFullName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="Sales Person" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {aSoList && aSoList[pageNumber]
                ? aSoList[pageNumber].map((rowData, index) => (
                    <tr key={index}>
                      <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>
                      {!_isColumnToHide("soNumber") ? (
                        <td className="one-line-view">
                          <div
                            className="table-link-text"
                            onClick={() => {
                              openSoDetails(rowData);
                            }}
                          >
                            {rowData.asoNumber||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      <td>{rowData.customerPreOrderMasterId ?rowData.customerPreOrderMasterId :<div className="empty-data"></div>}</td>
                      <td>{rowData.source ?rowData.source:<div className="empty-data"></div>}</td>
                      {!_isColumnToHide("salesOrderMasterId") ? (
                        <td>
                          <div
                            className="table-link-text"
                            onClick={() => {
                              openSoDetails(rowData);
                            }}
                          >
                            {rowData.salesOrderMasterId||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("soSourceName") ? (
                        <td>
                          <div className="text-center">
                            {rowData.soSourceName
                              ? fetchSourceLogo(
                                  rowData.soSourceName?.toLowerCase(),rowData.soSourceName
                                )
                              : <div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("customerName") ? (
                        <td className="one-line-view">
                          <div className="table-link-text">
                            <div
                              // onClick={() => {
                              //     props.history.push("customers/details", {
                              //         customerId: rowData.customerId,
                              //         customerName: rowData.customerName,
                              //     })

                              onClick={() => {
                                props.pushModalToStack({
                                  modalBody: (
                                    <CustomerProfile
                                      {...props}
                                      customerId={rowData.customerId}
                                      //   customerName={rowData.customerName}
                                      //   customerDetails={rowData}
                                    />
                                  ),
                                  width: "100%",
                                  hideTitle: true,
                                  hideFooter: true,
                                  wrapClassName: "new-transaction-wrapper",
                                });
                              }}
                            >
                              {rowData.customerName||<div className="empty-data"></div>}
                            </div>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("quotationNumber") ? (
                        <td>
                          <div
                            className="table-link-text"
                            onClick={() => {
                              openSQDetails(rowData);
                            }}
                          >
                            {rowData.quotationNumber||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("salesOrderTotalAmount") ? (
                        <td className="one-line-view">
                          {rowData.salesOrderTotalAmount &&
                          rowData.salesOrderTotalAmount !== "-" ? (
                            <span>
                              {" "}
                              {rowData.foreignCurrencyIcon ? (
                                <i className={rowData.foreignCurrencyIcon}></i>
                              ) : rowData.foreignCurrency ? (
                                <span>{rowData.foreignCurrency}</span>
                              ) : props.companyInfo.currencyIcon ? (
                                <i
                                  className={props.companyInfo.currencyIcon}
                                ></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}
                              {fixedDecimalAndFormateNumber(
                                rowData.salesOrderTotalAmount
                              )}{" "}
                            </span>
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("userStatus") ? (
                        <td>
                          {rowData.userStatus ? (
                            <div
                              className="status-code"
                              style={{ background: rowData.userStatusColor }}
                            >
                              {rowData.userStatus || <div className="empty-data"></div>}
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("status") ? (
                        <td className="one-line-view">
                          {rowData.status === "Rejected" &&
                          rowData.rejectionReason ? (
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={rowData.rejectionReason}
                              >
                                <div
                                  className={[
                                    "status-code",
                                    rowData.status?.toLowerCase(),
                                  ]?.join(" ")}
                                >
                                  {rowData.status ?<FormattedMessage id={rowData.status} defaultMessage=""/>:"" }
                                </div>
                              </Tooltip>
                            </div>
                          ) : (
                            <div>
                              {rowData.status ? (
                                <div
                                  className={`status-code ${rowData.status?.toLowerCase().replace(".", "")}`}
                                >
                                   {rowData.status ?<FormattedMessage id={rowData.status} defaultMessage=""/>:"" }
                                </div>
                              ) : (
                                <div className="empty-data"></div>
                              )}
                            </div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("referenceNumber") ? (
                        <td>{rowData.referenceNumber||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("salesOrderDate") ? (
                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.salesOrderDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.salesOrderDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {rowData.salesOrderDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.salesOrderDate,
                                  format: CONSTANTS.DISPLAY_DATE_FORMAT,
                                })}
                            </Tooltip>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                    {!_isColumnToHide("totalQtyInvoiced") ? (
                     
                          <td >
                           
                            {getInvoicedQuantity(rowData) || <div className="empty-data"></div>}
                            
                             
                            </td>
                        
                      
                    ) : (
                      ""
                    )}

                      {!_isColumnToHide("totalQtyPacked") ? (
                        <td>{getQuantityPacked(rowData)||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("totalQtyShipped") ? (
                        <td>{getShippedQuantity(rowData)||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("totalQtyOnPR") ? (
                        <td>{getQuantityOnPR(rowData)||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("deliveryDate") ? (
                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.deliveryDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.deliveryDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {rowData.deliveryDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.deliveryDate,
                                  format: CONSTANTS.DISPLAY_DATE_FORMAT,
                                })}
                            </Tooltip>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("statusPRMessage") ? (
                        <td>{rowData.statusPRMessage || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("customerInquiryNumber") ? (
                        <td>{rowData.customerInquiryNumber || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("customerRFQNumber") ? (
                        <td>{rowData.customerRFQNumber || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("customerPONumber") ? (
                        <td>{rowData.customerPONumber || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("paymentTermName") ? (
                        <td>{rowData.paymentTermName || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("project") ? (
                        <td className="one-line-view">
                          {rowData.projectMasterId ? (
                            <span>
                              {
                                rowData.projectName}
                            </span>
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("priority") ? (
                        <td>{rowData.orderPriority || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("salesPersonFullName") ? (
                        <td>
                          {rowData.salesPersonFullName ? (
                            <span>{rowData.salesPersonFullName}</span>
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
          </Skeleton>
        </div>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={amendSalesOrderCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}

            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize);
            }}
          />
        </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(AmendSalesOrderListingComp);
