import React, { useMemo } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer } from 'antd';
import { Timeline } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { CustomButton } from '../../general/CustomButton';
import { ErrorMsg } from '../../general/ErrorMessage';
import { formatDateForBackend, getLocalDateFromUtcStr, getPermissionData } from '../../../utils';
import TextArea from 'antd/lib/input/TextArea';
import { MODULE_CODED_VALUES } from '../../../static/constants';

function AuditTimeLineDrawer(props) {
    const {permissions} = props;
    const primaryPerm = (permissions && permissions.primary) || [];
  const permissionData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SALES_INQUIRY);
  },[])
    const closeDrawer = () => {
        props.updateState({
            auditDrawerVisible: false
        })
    }

    const format = "DD MMM YYYY HH:mm:ss";
    return (
        <Drawer
            title={props.title}
            width={720}
            zIndex={1}
            maskClosable={false}
            destroyOnClose={true}
            onClose={closeDrawer}
            className="custom-supplier-drawer audit-trail-drawer"
            visible={props.auditDrawerVisible}
            footer={
                <>
        
        
        
               {
                props.isCommentVisible ? (<div className="footer-btn-group mt15" style={{ display:"flex",justifyContent:"space-between" }}>
                <CustomButton
                    onClick={() => {
                        props.updateState({
                            isCommentVisible: false
                        })
                    }}
                    defaultMessage=''
                    type='default'
                    htmlType='submit'
                    intlId='common.cancel'
                />
                <CustomButton
                    onClick={() => {
                        props.updateState({ submittedOnce: true });
                        if (!props.comment || props.comment.length < 1) { return; }
                        const modalBody = <span>
                            Are you sure you want to Submit?
                            </span>;
                        const modalData = {
                            modalBody,
                            handleSubmit: () => {
                                const payload = {};
                                payload[props.masterKey] = props.masterKeyValue;
                                payload.apiUrl = props.apiUrl;
                                payload.changeDescription = props.comment + " created by " + ((props.userInfo || {}).firstName || "") + " " + ((props.userInfo || {}).lastName || "");
                                payload.customerId = props.customerId;
                                payload.supplierId = props.supplierId;
                                payload.relationshipId = (props.companyInfo || {}).relationshipId;
                                payload.isSystemGenerated = 0;
                                payload.auditTrailDate = formatDateForBackend(new Date());
                                payload.createdByEmpId = ((props.companyInfo || {}).relationshipEmployees || {}).relationshipEmployeeId;
                                props.createAuditTrail(payload, props);
                            },
                        };
                        props.showModal(modalData);
                    }}
                    type='primary'
                    htmlType='submit' 
                    intlId='forgot.btn.addComment'
                    defaultMessage=''
                /> 
                
            </div>):''
               }
        
        
        
                </>
              }
        >
            <Timeline position={'left'} mode={'left'}>
                {(props.trailList||[]).map((data, index) => {
                    
                    return <Timeline.Item key={index} dot={<i onClick={() => {
                        if (!data.isSystemGenerated) {
                            const modalBody = <span>
                                Are you sure you want to delete trail?
                                </span>;
                            const modalData = {
                                modalBody,
                                handleSubmit: () => {
                                    props.deleteAuditTrail({ ...data, deletApiUrl: props.deletApiUrl }, props);
                                },
                            };
                            props.showModal(modalData);
                        }
                    }} onMouseLeave={() => {
                        data.isHover = false;
                        props.updateState({ isHover: false })
                    }} onMouseOver={() => {
                        data.isHover = true;
                        props.updateState({ isHover: true })
                    }} className={(!data.isSystemGenerated && data.isHover) ? "fa fa-trash delete" : (data.isSystemGenerated ? "ant-timeline-item-head ant-timeline-item-head-green" : "ant-timeline-item-head ant-timeline-item-head-blue")} />} color={data.isSystemGenerated ? "green" : "blue"} label={getLocalDateFromUtcStr({ date: data.auditTrailDate, format })}>{ReactHtmlParser(data.changeDescription)}</Timeline.Item>
                })}
                {/* <Timeline.Item label={moment('2015-09-01 09:12:11').format(format)}>Solve initial network problems</Timeline.Item>
                <Timeline.Item>Technical testing</Timeline.Item>
                <Timeline.Item label={moment('2015-09-01 09:12:11').format(format)}>Network problems being solved</Timeline.Item> */}
            </Timeline>
            <hr />
            <br />

            {!props.isCommentVisible ? <div className='cursor-pointer' style={{ marginLeft: "45%" }} onClick={() => {
                props.updateState({
                    isCommentVisible: true
                })
            }}><FormattedMessage id='addAuditTrail.text' defaultMessage='' /></div> : null}

            {props.isCommentVisible ? <div>
                <TextArea value={props.comment} className="audit-trail-textarea" rows={4} onChange={(e) => {
                    props.updateState({ comment: e.target.value });
                }} placeholder={props.intl.formatMessage(
                    { id: 'addAuditTrail.placeholder', defaultMessage: '' }
                )}>


                </TextArea>
                {/* <CKEditor
                    type="inline"
                    key={`${"comment"}`}
                    className="audit-trail-textarea"
                    data={props.comment}
                    onInit={editor => {
                        this.editor=editor
                        props.updateState({ editor });
                    }}
                    onChange={(event) => {
                        const data = event.editor.getData();
                        props.updateState({ comment: data });
                    }}
                    config={{ ...CUSTOM_CONFIGURATION_COMMENT, placeholder: <FormattedMessage id='addAuditTrail.placeholder' defaultMessage='' /> }}
                /> */}

                <ErrorMsg
                    validator={() => { return !props.submittedOnce || props.comment }}
                    message={<FormattedMessage id='common.Audit.trail.required.' defaultMessage='' />} />

              
            </div> : null}
        </Drawer>
    );
}


export default injectIntl(AuditTimeLineDrawer);
