import { Col, DatePicker, Drawer, Row } from "antd";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown";
import { CONSTANTS, MODAL_TYPE, MODULE_CODED_VALUES } from "../../../../static/constants";
import { getLabelName } from "../../../modal/modalBody/customerInfo";
import moment from "moment-timezone";

import { getCurrentDateForBackend } from "../../../../utils";
import { CategoryModal } from "../../../modal/modalBody/subProjectComp";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const LeadCompaignDrawer = (props) => {
  const rowGutter = [24, 16];


  const closeDrawer = () => {
    props.updateState({
      leadCompaignDrawer: false,
    });
  };
  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody =
      <FormattedMessage
        id='deleteItem.confirmation'
        defaultMessage=''
        values={{ value: payload.text }}
      />;

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.CRM_CAMPAIGN_TYPE: {
        deleteFunc = props.deleteCampaignType;
        break;
      }
      case MODAL_TYPE.CRM_CAMPAIGN_STATUS: {
        deleteFunc = props.deleteCampaignStatus
        break;

      }
      default: {
        deleteFunc = () => { }
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }, props);
        props.hideModal();
      },
    };
    showModal(modalData);
  }

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, hideModal } = props;

    if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            hideModal();
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        }
      };
      showModal(data);
    }
  }

  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = <span>
      <FormattedMessage
        id='addItem.confirmation'
        values={{ value: `${text} to ` }}
        defaultMessage=''
      />
      {labelName} ?
    </span>;

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.CRM_CAMPAIGN_TYPE: {
        addFunc = props.createCrmCampaignType;
        break;
      }
      case MODAL_TYPE.CRM_CAMPAIGN_STATUS: {
        addFunc = props.createCrmCampainStatus;
        break;
      }

      default: {
        addFunc = () => { }
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId }, props)
        props.hideModal();
      },
    };
    showModal(modalData);
  }

  const getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.CRM_CAMPAIGN_TYPE: {
        title = "Campaign Type";
        break;
      }
      case MODAL_TYPE.CRM_CAMPAIGN_STATUS: {
        title = "Campaign Status";
        break;
      }

      default:
        title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
    }

    return title;
  }
  const handlePopUp = () => {
    const modalData = {
        title: "Confirmation",
        modalBody: <CategoryModal {...props} />,
        width: 500,
        handleSubmit: () => {
        },
    };
    props.showModal(modalData);
}


  return (
    <Drawer
      title={<FormattedMessage id={props.leadCompaignId ? "drawer.campaign.edit" : "drawer.campaign.create"} />}
      width={620}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.leadCompaignDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={()=>{
              
              closeDrawer()}}
          />

          <CustomButton
            intlId="button.save.label"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              props.updateDrawerState({ submitted: true })
              if (!props.campaignName && !props.startDate && !props.campaignCode && !props.leadSourceName && !props.campaignStatusName) {
                return
              }
              const modalData = {
                title: (
                  <FormattedMessage
                    id="drawer.inventory.confirmation.title"
                    defaultMessage=""
                  />
                ),
                modalBody:  <Fragment>
                <p>
                Are you sure you want to create?
                </p>
              </Fragment>,
                handleSubmit: () => {
                  const payload = {
                    relationshipId: props.companyInfo.relationshipId,
                    campaignId: props.isUpdate ? props.campaignId : 0,
                    relationshipId: props.companyInfo?.relationshipId,
                    campaignName: props.campaignName,
                    startDate: getCurrentDateForBackend(props.startDate),
                    endDate:props.endDate?   getCurrentDateForBackend(props.endDate):null,
                    campaignTypeName: props.campaignTypeName,
                    campaignTypeId: props.campaignTypeId,
                    campaignStatusId: props.campaignStatusId,
                    campaignStatusName: props.campaignStatusName,
                    createdByUser: props.userInfo?.firstName,
                    createdByUserId: props.userInfo?.userId,
                    isActive: 1,
                    campaignCode: props.campaignCode,
                    leadSourceName: props.leadSourceName,
                    subSource: props.subSource,
                    utmSource: props.utmSource,
                    utmCompaign: props.utmCompaign,
                    utmMedium: props.utmMedium,
                    utmContent: props.utmContent,
                    utmTerm: props.utmTerm,
                    projectMasterId:props.projectMasterId
                    // crmLeadCampaignProjectList:props.selectedProjectList?.length ? props.selectedProjectList?.map((item)=>{return {projectId:item,relationshipId: props.companyInfo.relationshipId}}):[]
                  }
                  props.createCrmLeadCampaign(payload, props);
                  closeDrawer();
                  props.popModalFromStack();

                },
              };
              props.pushModalToStack(modalData);
              
            }}
            key={1}
          />
        </>
      }
    >
      <Row gutter={rowGutter}>
    

        <Col span={12}>
          <div className="i-label">Campaign Name</div>
          <TextBox
            placeholder="Campaign Name"
            type="text"
            maxLength={20}
            value={props.campaignName}
            onChange={(e) => {
              props.updateDrawerState({ campaignName: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.campaignName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Campaign Code</div>
          <TextBox
            placeholder="Campaign Code"
            type="text"
            maxLength={20}
            value={props.campaignCode}
            onChange={(e) => {
              props.updateDrawerState({ campaignCode: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.campaignCode }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

       <Col span={12}>
         <div className="i-lable">
          Project
         </div>
        

<>
                                 
            <div onClick={() => handlePopUp()}>
                <TextBox
                    value={
                        props?.projectName || ""
                    }
                    placeholder={'Select Project'}
                />
            </div>
            <div>
                <ErrorMsg
                    validator={() => {
                        return !props.submittedOnce || !!props?.projectName;
                    }}
                    message={"Parent Name is reqiured"}
                />
            </div>
        </>

       </Col>
        <Col span={12}>
          <div className='i-label'>
            Campaign Type
          </div>
          <Dropdown
            items={(props.crmCampaignType || [])}
            valueKeyName='campaignTypeName'
            value={props.campaignTypeName}
            optionKeyName='campaignTypeId'
            onSelect={
              (campaignTypeId, obj) => {
                props.updateDrawerState({
                  campaignTypeId,
                  campaignTypeName: obj.name
                });
              }}
              moduleName = {MODULE_CODED_VALUES.LEAD_FROM_SETTING}
            canAddNew={true}
            canDelete={true}
            deleteClickHandler={(payload) => {
              deleteClickHandler(props, MODAL_TYPE.CRM_CAMPAIGN_TYPE, payload);
            }}
            onAddNewClick={(payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false
              }
              handleNewItemAddition(props, payload, MODAL_TYPE.CRM_CAMPAIGN_TYPE)
            }}
            placeholder={<FormattedMessage id='common.select.placeholder' />}

            allowClear={true}
            onClear={() => {
              props.updateDrawerState({ supplierTypeId: undefined, supplierType: undefined })
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.campaignTypeName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            Source
          </div>
          <Dropdown
            items={(props.crmActiveSource || [])}
            valueKeyName='leadSourceName'
            value={props.leadSourceName}
            optionKeyName='crmLeadSourceId'
            onSelect={
              (crmLeadSourceId, obj) => {
                props.updateDrawerState({
                  crmLeadSourceId,
                  leadSourceName: obj.name
                });
              }}
            placeholder={<FormattedMessage id='common.select.placeholder' />}

          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.leadSourceName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Sub Source</div>
          <TextBox
            placeholder="Sub Source"
            type="text"
            maxLength={20}
            value={props.subSource}
            onChange={(e) => {
              props.updateDrawerState({ subSource: e.target.value });
            }}
          />

        </Col>
        <Col span={12}>
          <div className="i-label">UTM Source</div>
          <TextBox
            placeholder="UTM Source"
            type="text"
            maxLength={20}
            value={props.utmSource}
            onChange={(e) => {
              props.updateDrawerState({ utmSource: e.target.value });
            }}
          />

        </Col>
        <Col span={12}>
          <div className="i-label">UTM Campaign</div>
          <TextBox
            placeholder="UTM Campaign"
            type="text"
            maxLength={20}
            value={props.utmCompaign}
            onChange={(e) => {
              props.updateDrawerState({ utmCompaign: e.target.value });
            }}
          />

        </Col>
        <Col span={12}>
          <div className="i-label">UTM Medium</div>
          <TextBox
            placeholder="UTM Medium"
            type="text"
            maxLength={20}
            value={props.utmMedium}
            onChange={(e) => {
              props.updateDrawerState({ utmMedium: e.target.value });
            }}
          />

        </Col>

        <Col span={12}>
          <div className="i-label">UTM Content</div>
          <TextBox
            placeholder="UTM Content"
            type="text"
            maxLength={20}
            value={props.utmContent}
            onChange={(e) => {
              props.updateDrawerState({ utmContent: e.target.value });
            }}
          />

        </Col>

        <Col span={12}>
          <div className="i-label">UTM Term</div>
          <TextBox
            placeholder="UTM Term"
            type="text"
            maxLength={20}
            value={props.utmTerm}
            onChange={(e) => {
              props.updateDrawerState({ utmTerm: e.target.value });
            }}
          />


        </Col>

        <Col span={12}>
          <div className='i-label'>
            Status
          </div>
          <Dropdown
            items={(props.crmCampaignStatusList || [])}
            valueKeyName='campaignStatusName'
            value={props.campaignStatusName}
            optionKeyName='campaignStatusId'
            onSelect={
              (campaignStatusId, obj) => {
                props.updateDrawerState({
                  campaignStatusId,
                  campaignStatusName: obj.name
                });
              }}
              moduleName = {MODULE_CODED_VALUES.LEAD_FROM_SETTING}
            canAddNew={true}
            canDelete={true}
            deleteClickHandler={(payload) => {
              deleteClickHandler(props, MODAL_TYPE.CRM_CAMPAIGN_STATUS, payload);
            }}
            onAddNewClick={(payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false
              }
              handleNewItemAddition(props, payload, MODAL_TYPE.CRM_CAMPAIGN_STATUS)
            }}
            placeholder={<FormattedMessage id='common.select.placeholder' />}

            allowClear={true}
            onClear={() => {
              props.updateDrawerState({ supplierTypeId: undefined, campaignStatusName: undefined })
            }}
          />

          <ErrorMsg
            validator={() => { return !props.submitted || props.campaignStatusName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>



        <Col span={12}>
          <div className="i-label">Start Date</div>
          <DatePicker
            allowClear={false}
            format={format}
            defaultValue={props.startDate ? moment(new Date(props.startDate), format) : null}
            onChange={(selectedDate) => {
              props.updateState({
                startDate: selectedDate
              })
            }} />
          <ErrorMsg
            validator={() => { return !props.submitted || props.startDate }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">End Date</div>
          <DatePicker
            allowClear={false}
            format={format}

            defaultValue={props.endDate ? moment(new Date(props.endDate), format) : null}
            onChange={(selectedDate) => {
              props.updateState({
                endDate: selectedDate
              })
            }} />
        </Col>

      </Row>
    </Drawer>
  );
};

export default LeadCompaignDrawer;
