import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST, RFQ_ACTION_LIST } from '../../../static/constants'
import { showToasterMessage, formatDateForBackend} from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchRelationshipDataByRID = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/clientRelationship/getRelationshipByRID?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SETTING_ACTION_LIST.RELATIONSHIP_DATA, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.RELATIONSHIP_DATA;
        }
      })
  }
}


export const fetchRelationshipByDomainName = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/getRelationshipId?name=${payload.domainName}`)
      .then(res => {
        const { data } = res
        const load = { relationshipId: data.relationshipId }
        props.fetchRelationshipDataByRID(load);
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const fetchRfqData = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/rfqRest/getRFQsByRFQId?rfqMasterId=${payload.rfqMasterId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: RFQ_ACTION_LIST.RFQ_DETAIL, data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const resetRfqData = (payload) => {
  return dispatch => {
    dispatch({ type: RFQ_ACTION_LIST.RESET_RFQ_DETAIL });
  }
}


export const submitSupplierResponse = (payloadObject) => {
  const props = payloadObject.props;
  const supplierResponse = _getSupplierResponse(payloadObject);
  let formData={
    rfqMasterId: props.rfqMasterId,
    relationshipId: props.relationshipId,
    supplierId: (props.biddingSupplier || {}).supplierId,
    rfqBiddingSuppliersId: (props.biddingSupplier || {}).rfqBiddingSuppliersId,
    supplierResponse: JSON.stringify(supplierResponse)
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/rfqBiddingSupplier/updateSupplierBidding`, formData)
      .then(res => {
        // showToasterMessage({ messageType: 'success', description: "Submitted Successfully"});
        props.updateState({submitSuccess: true});
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' });
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

const _getSupplierResponse = (payload) => {
  const props = payload.props;
 // const state = payload.state;
  const gridObject = payload.gridObject;
 
  const companyInfo = props.companyInfo || {};
 // const userInfo = props.userInfo || {};
 // const financialYear = getFinancialYear(companyInfo);
  //const oldPayload = props.update ? props.oldPayload || {} : {};
  let supplierResponse = {
    rfqDetailsList: [],
   supplierComment: props.supplierComment
  };

  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;

    if(!data.itemId && !data.description && !data.qty && !data.rate){
      return
    }
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    //if(!itemId) return;
    let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
    if(data.productObj && !data.productObj.hasVariant && !variantId){
      variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId: data.inventoryItemVariantId;
    }
    let product = {};
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }
    let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);
    const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
    itemLevelAmount = itemLevelAmount - discountOnItem;
    let taxOnItem = (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2);
    supplierResponse.rfqDetailsList.push({
      rfqDetailsId: props.operationType === 'clone' ? 0 : data.rfqDetailsId,
      rfqMasterId: props.operationType === 'clone' ? 0 : props.rfqMasterId,
      relationshipId: companyInfo && companyInfo.relationshipId,
      version: props.operationType === 'clone'? 0 : data.version,
      quantity: Number(data.qty),
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      productTaxId: data.productTaxId,
      txDate: formatDateForBackend(props.invoiceDate),
      itemName: product.itemName,
      itemId: product.itemId,
      description: data.description,
      rowHeight: data.scrollHeight,
      hsnCode: data.hsnCode,
      itemVariantId: variantId,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      inquiryMasterId: props.inquiryMasterId,
      purchaseRequestMasterId: props.purchaseRequestMasterId || data.purchaseRequestMasterId,
      purchaseRequestNumber: props.prNumber || data.prNumber,
      parentDetailsId: data.parentDetailsId,
      anItemPurchasePrice: Number(data.rate),
      discountAmount: discountOnItem,
      discountPercent: data.discount,
      taxAmount: taxOnItem,
      taxPercent: data.tax,
    });
  });
  return supplierResponse;
}
