import React, { useMemo,useState } from "react";
import { Dropdown } from "../general/Dropdown";
import './index.scss';
import { LIST_CONSTANTS, MODULE_CODED_VALUES } from "../../static/constants";
import SchoolDashbaord from "../../containers/schoolManagement/schoolDashbaord";
import ERPDashboard from "../../containers/Dashboard/index";
import CRMDashboard from "../../containers/CRMDashboard";
import { capitalizeFirstLetter, getGreetingFromTime, getPermissionData } from "../../utils";
import ErpDashboard from "../../containers/erpDashboard";
import CloudErpDashboard from "../../containers/Dashboard";

const Dashboard = (props) => {
  const primaryPerm = (props.permissions || {}).primary || [];
  const schoolPermission = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SCHOOL_DASHBOARD);
  },[])

  const erpPermission = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.ERP_DASHBOARD);
  },[])

  const crmPermission = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CRM_CUSTOMER);
  },[])

  const [selectedDashboard, setDashboard] = useState(() => {
    if (erpPermission?.read) return "cloud_erp";
    if (schoolPermission?.read) return "education";
    if (crmPermission?.read) return "crm";
    return ""; 
  });

    return <>
    <div className="common_dashboard">

      <div className="dashboard_selection">
        <div className="dashboard-heading">
        {`${getGreetingFromTime(new Date())} ${capitalizeFirstLetter(props.companyInfo.relationshipEmployees?.firstName || '')}`}
          {/* Education Dashboard */}
          </div>
        <div className="dd-down">
          Select Dashboard
          <Dropdown
            items={[
              schoolPermission.read ? { name: "Education", value: "education" } : null,
              erpPermission.read ? { name: "ERP", value: "erp" } : null,
              crmPermission.read ? { name: "CRM", value: "crm" } : null,
              erpPermission.read ? { name: "Cloud ERP", value: "cloud_erp" } : null

            ].filter(Boolean) }
            valueKeyName='name'
            optionKeyName='value'
            className="dp-custom"
            value={selectedDashboard || ""}
            onChange={(val) => {
              setDashboard(val)
            }}
          />
        </div>

      </div>
      <div>
      {
          selectedDashboard === "cloud_erp" && erpPermission.read?
          <CloudErpDashboard {...props}/>
          : ""}
        {
          selectedDashboard === "education" && schoolPermission.read?
          <SchoolDashbaord {...props}/>
          : ""}
        {
          selectedDashboard === "erp" && erpPermission.read ?
            <ErpDashboard {...props}/> : ""}
        {
          selectedDashboard === "crm" && crmPermission.read?
          <CRMDashboard {...props}/> : ""}
      </div>
    </div>


  </>
}
export default Dashboard;
