import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReturnOrderComp from '../../../../../components/modal/modalBody/eStore/ReturnOrder';
import { pushModalToStack, showModal, popModalFromStack, hideModal } from '../../../../../actions/commonActions';
import { fetchCategory, getItemByCategory,clearItemByCategory } from '../../../../drawer/inventory/action';
import { fetchProducts } from '../../../../product/Listing/action'; 
import { saveReturnSettings, saveReturnSetting, getReturnSettings,clearReturnSettings } from './action';

class ReturnOrder extends Component {
    constructor(props) {
        super(props);
        const linkProps = (this.props.location && this.props.location.state) || {};

        this.state = {
            replaceSameAsReturn: 0,
            init: false,
            isHeaderMenuLoaded: false,
            returnSetting: {
                isActive: 0,
                returnDuration: 30,
                returnReason: [{}],
                type: "Return",
                displayMessage: "Return not applicable on this Product/Category as per the Return Policy",
                notReturnableCategoryRefs: props.productDetails.inventoryItemCategoryRefs,
                notReturnableInventoryItemRefs: [],
                returnDurationType:"Days"

            },
            replaceSetting: {
                isActive: 0,
                returnDuration: 30,
                returnReason: [{}],
                type: "Replace",
                displayMessage: "Replacement not applicable on this Product/Category as per the Policy",
                notReturnableCategoryRefs: props.productDetails.inventoryItemCategoryRefs,
                notReturnableInventoryItemRefs: [],
                returnDurationType:"Days"
            },
            cancelSetting: {
                isActive: 0,
                returnDuration: 30,
                returnReason: [{}],
                type: "Replace",
                displayMessage: "Cancellation not applicable on this Product/Category as per Cancellation Policy",
                notReturnableCategoryRefs: props.productDetails.inventoryItemCategoryRefs,
                notReturnableInventoryItemRefs: [],
                returnDurationType:"Days"
            },
            // activeTabKey: '1',
            activeTab: linkProps.activeTab,
            activeTabKey: "1",

        }
    }

    componentDidMount() {
       this.props.clearReturnSettings();
       this.props.clearItemByCategory(this.props);
        const companyInfo = this.props.userInfo || {};
        const linkProps = (this.props.location && this.props.location.state) || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            type: this.props.type,
            init: false,
        };
        this.props.fetchCategory(payload);
        this.props.fetchProducts(payload);
        this.props.getReturnSettings({ ...payload, type: "Return" });


    }
// return 
    // [{"reason": "Quality Issue", "available": 1}, {"reason": "Item Issue", "available": 1}]
// replce
    //  [{"reason": "Item Mismatch", "available": 1}, {"reason": "Size Issue", "available": 1}]
//  cancel 
    // [{"reason": "By Mistake Ordered", "available": 1}, {"reason": "Found Better Deal", "available": 1}]   
    componentWillReceiveProps(nextProps) {
        const { returnDetails } = nextProps;
        const { activeTabKey, init } = this.state;
    
        if (!this.state.isHeaderMenuLoaded && Object.keys(returnDetails ||{})?.length) {
            const commonSetting = {
                version: returnDetails.version,
                ecomReturnSettingId: returnDetails.ecomReturnSettingId,
                isActive: returnDetails.isActive,
                returnDuration: returnDetails.returnDuration,
                returnDurationType:returnDetails.returnDurationType,
                returnReason: JSON.parse(returnDetails.returnReasons || "[]")?.length ? JSON.parse(returnDetails.returnReasons || "[]"):this.getReasonsByType(returnDetails.type) ,
                displayMessage: returnDetails.displayMessage,
                notReturnableCategoryRefs: returnDetails.notReturnableCategoryRefs || [],
                notReturnableInventoryItemRefs: returnDetails.notReturnableInventoryItemRefs?.map(data => data.itemId),
                isOnlyByRequest:returnDetails.isOnlyByRequest,
                createReplaceOrder:returnDetails.createReplaceOrder,
            };
    
            if (activeTabKey === "1") {
                this.setState({
                    isHeaderMenuLoaded: true,
                    version: commonSetting.version,
                    ecomReturnSettingId: commonSetting.ecomReturnSettingId,
                    returnSetting: commonSetting,
                    init: false,
                });
            } else if (activeTabKey === "2") {
                this.setState({
                    isHeaderMenuLoaded: true,
                    version: commonSetting.version,
                    ecomReturnSettingId: commonSetting.ecomReturnSettingId,
                    replaceSetting: commonSetting,
                    init: false,
                });
            } else if (activeTabKey === "3") {
                this.setState({
                    isHeaderMenuLoaded: true,
                    version: commonSetting.version,
                    ecomReturnSettingId: commonSetting.ecomReturnSettingId,
                    cancelSetting: commonSetting,
                    init: false,
                });
            }
    
            const payload = {
                relationshipId: nextProps.companyInfo.relationshipId,
                pageNumber: 0,
                pageSize: 25,
                categoryIds: commonSetting.notReturnableCategoryRefs.map(data => data.inventoryItemCategoryId),
            };
            nextProps.clearItemByCategory();
            nextProps.getItemByCategory(payload, this.state);
        }
    
        if (init) {
            if ((nextProps.returnProductData.content || []).length  && activeTabKey === "1") {
                const preSelectedItem = nextProps.returnProductData.content.map(item => item.itemId);
                this.setState(prevState => ({
                    returnSetting: {
                        ...prevState.returnSetting,
                        notReturnableInventoryItemRefs: [...preSelectedItem],
                    },
                    init: false,
                }));
            }
            if ((nextProps.replaceProductData.content || []).length && activeTabKey === "2") {
                const preSelectedItem = nextProps.replaceProductData.content.map(item => item.itemId);
                this.setState(prevState => ({
                    replaceSetting: {
                        ...prevState.replaceSetting,
                        notReturnableInventoryItemRefs: [...preSelectedItem],
                    },
                    init: false,
                }));
            }
            if ((nextProps.cancelProductData.content || []).length && activeTabKey === "3") {
                const preSelectedItem = nextProps.cancelProductData?.content.map(item => item.itemId);
                this.setState(prevState => ({
                    cancelSetting: {
                        ...prevState.cancelSetting,
                        notReturnableInventoryItemRefs: [...preSelectedItem],
                    },
                    init: false,
                }));
            }
        }
    }
    
  getReasonsByType(type) {
        switch (type) {
            case 'Return':
                return [
                    { reason: "Quality Issue", available: 0 },
                    { reason: "Item Issue", available: 0 }
                ];
            case 'Replace':
                return [
                    { reason: "Item Mismatch", available: 0 },
                    { reason: "Size Issue", available: 0 }
                ];
            case 'Cancel':
                return [
                    { reason: "By Mistake Ordered", available: 0 },
                    { reason: "Found Better Deal", available: 0 }
                ];
            default:
                return []; // Return an empty array in stringified format
        }
    }



    render() {
        return <>
            <ReturnOrderComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} updateDrawerState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        userInfo: state.common.userInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        storeData: state.common.store_values,
        companyInfo: state.common.companyInfo,
        productCategories: state.inventory.productCategories,
        products: state.product.products[1],
        productDetails: state.product.productDetails,
        returnProductData: state.inventory.returnProductData,
        replaceProductData: state.inventory.replaceProductData,
        cancelProductData: state.inventory.cancelProductData,
        returnDetails: state.return.returnDetails,
        permissions: state.auth.permissions,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    pushModalToStack,
    fetchCategory,
    fetchProducts,
    showModal,
    popModalFromStack,
    hideModal,
    getItemByCategory,
    saveReturnSettings,
    saveReturnSetting,
    getReturnSettings,clearReturnSettings,clearItemByCategory


}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReturnOrder);