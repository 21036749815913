

import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, BUSINESS_EXPENSE_ACTION_LIST, EXPENSE_TYPE, TX_STATUS, ACCOUNTING_ENTRY_TYPE } from '../../../../static/constants';
import { showToasterMessage, getCurrentDateForBackend, getFinancialYear } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createRelationshipExpense = (payload, openExpenseDetails) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        const props = payload.props;
        const expenseData = _getExpenseData(payload);
        let formData = new FormData();
        if (payload.fileToUpload) {
            formData.append("file", payload.fileToUpload);
        }
        formData.append('expenseData', JSON.stringify(expenseData, true));
        return axios
            .post(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/relationshipExpense/createRelationshipExpense`, formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
            .then(res => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                const fetchPayload = {
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    pageNumber: 0,
                    pageSize: 100
                }
                props.fetchBusinessExpenses(fetchPayload);
                props.getBusinessExpensesCount(fetchPayload);
                openExpenseDetails(res.data);
                props.history.push("/admin/business-expense-listing")
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
                } else {
                    err.actionToCall = BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_LIST;
                    err.data = {
                        pageNo: payload.pageNumber,
                        list: []
                    };
                }
            })
    }
}


const _getExpenseData = (payloadObj) => {
    const props = payloadObj.props;
    const state = payloadObj.state;
    const agGridObj = payloadObj.gridObject;
    const companyInfo = props.companyInfo || {};
    const userInfo = props.userInfo || {};
    const financialYear = getFinancialYear(companyInfo);
    const payload = {
        relationshipId: (props.companyInfo || {}).relationshipId,
        txType: EXPENSE_TYPE.TYPE_EXPENSE,
        costCenterId: props.costCenterId,
        remarksInternal: props.remarksInternal,
        expenseDate: getCurrentDateForBackend(props.expenseDate),
        refNumber: props.refNumber,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdByUserId: userInfo.userId,
        subTotal: state.total,
        expenseTotalAmount: state.total,
        status: TX_STATUS.UNPAID,
        relationshipName: companyInfo.storeName,
        fyStartDate: getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: getCurrentDateForBackend(financialYear.fyEndDate),
        costCenterName: props.costCenterName,
        relationshipTxExpenseList: [],
        relationshipExpenseDetails: [],
        relationshipExpenseCOATxList: [],
        projectName: props.projectName,
        projectMasterId: props.projectMasterId,
        projectNumber: props.projectNumber,
        isbankReco: props.isbankReco,
        expenseMasterId: props.isUpdate ? props.expenseMasterId : 0,
        version: props.isUpdate ? props.version : 0,
        expenseId: props.isUpdate ? props.expenseId : 0,
    }

    agGridObj.forEachNode((rowNode, index) => {
        const relationshipExpenseDetails = {
            expenseAmount: rowNode.data.expenseAmount,
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdByUserId: userInfo.userId,
            description: rowNode.data.description,
            expenseTypeId: rowNode.data.chartOfAccountDetailsId,
            expenseTypeName: rowNode.data.ledgerAccountName,
            expenseDetailsId: props.isUpdate ? rowNode.data.expenseDetailsId : 0,
            expenseMasterId: props.isUpdate ? props.expenseMasterId : 0,
            relationshipCOATxExpense:  [],
            version: rowNode.data.version,
            relationshipId: companyInfo.relationshipId,
        };

        relationshipExpenseDetails.relationshipCOATxExpense.push({
            relationshipId: companyInfo.relationshipId,
            relationshipName: companyInfo.storeName,
            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdBy: userInfo.userId,
            amount: Number(state.total || 0),
            txDate: getCurrentDateForBackend(new Date()),
            txType: EXPENSE_TYPE.TYPE_EXPENSE,
            accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
            ledgerAccountId: rowNode.data.ledgerAccountDetailsId,
            ledgerAccountName: rowNode.data.ledgerName 
        });

        relationshipExpenseDetails.relationshipCOATxExpense.push({
            relationshipId: companyInfo.relationshipId,
            relationshipName: companyInfo.storeName,
            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdBy: userInfo.userId,
            amount: Number(state.total || 0),
            txDate: getCurrentDateForBackend(new Date()),
            txType: EXPENSE_TYPE.TYPE_EXPENSE,
            accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
            ledgerAccountId: rowNode.data.chartOfAccountDetailsId
        });

        const relationshipTxExpenseList = {
            txAmount: rowNode.data.expenseAmount,
            txType: EXPENSE_TYPE.TYPE_EXPENSE,
            txDate: getCurrentDateForBackend(new Date()),
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdByUserId: userInfo.userId
        }
        payload.relationshipTxExpenseList.push(relationshipTxExpenseList);
        payload.relationshipExpenseDetails.push(relationshipExpenseDetails);
    });

    return payload;
}

export const getNextTxNumber = (payload) => {
    let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
    return dispatch => {
      dispatch({ type: BUSINESS_EXPENSE_ACTION_LIST.NEXT_EXPENSE_NUMBER_RESET });
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/relationshipExpense/getNextExpenseNumber?date=${date}&relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: BUSINESS_EXPENSE_ACTION_LIST.NEXT_EXPENSE_NUMBER, data: res.data });
        })
        .catch((err) => {
          if (!err.__isRetryRequest) {
          } else {
            err.actionToCall = BUSINESS_EXPENSE_ACTION_LIST.NEXT_EXPENSE_NUMBER;
          }
        })
    }
  }
