  import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { fetchDataIfNeeded } from '../../../utils';
import DetailProjectAddComp from '../../../../components/drawer/Project/project';
import { pushModalToStack,popModalFromStack } from './../../../../actions/commonActions';
import { showModal, hideModal } from './../../../../actions/commonActions';
import { downloadFileFromBucket } from './../../../../actions/downloadFileAction';
import { createOrUpdateProject } from '../../../modal/modalBody/common/Project/action';
import { getAllProject } from '../../../company/Project/action';
import { fetchProjects } from '../../../company/Project/action';
import { getTeam,getTeamByDepartmentId ,resetTeamByDepartment} from '../../../company/EmployeeMeta/Team/action';
import { getTeamMembersByTeamName ,resetTeamDataGetByName} from '../../../modal/modalBody/leads/createLead/action';
import { fetchAllDepartments } from './../../../../actions/commonActions';
import { getAllProjectWithSubProject } from '../../../company/Project/action';
class ProjectAddDrawer extends Component {
  constructor(props) {
    super(props);
    const needToinit = Object.keys(props.projectData || {})?.length;
    const isParenttheir = Object.keys(props.parentItem || {})?.length;
    const  data = needToinit ? props.projectData : {}
    this.state = {
      detailProjectDrawerVisible: props.detailProjectDrawerVisible, 
      projectName:needToinit?data.projectName:"",
      projectDescription:needToinit?data.projectDescription:"",
      projectMasterId:needToinit?data.projectMasterId:null,
      needToinit,
      dataInitilize:false,
      isParenttheir,
      edit:false
    }
  }
  

  componentDidMount() {
    const companyInfo = (this.props.companyInfo || {})
    const payload= {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 50,   
    }
    this.props.resetTeamByDepartment();
    this.props.resetTeamDataGetByName();
    this.props.fetchProjects(payload);
    this.props.getTeam({...payload,pageSize:100});
    this.props.fetchAllDepartments(payload);
    this.props.getAllProjectWithSubProject(payload);
  
  }

  componentWillReceiveProps(props){

    if(!this.state.dataInitilize && this.state.needToinit >0 && props.teamList[1]?.length){

      const temp  = this.props.projectData?.crmLeadTeamAssignedList?.map((item)=>item.teamName);
      const temp2  = this.props.projectData?.crmLeadTeamAssignedList?.map((item)=>item.teamId);
      const l = props.teamList[1]?.filter((item)=>temp2?.includes(item.teamId));
      const val  = l?.map((item)=>item.departmentId);
      const selectedDepartment = l.map((i)=>{return{departmentId:i.departmentId,relationshipId:this.props.companyInfo?.relationshipId}})
    
      const payload = {
        relationshipId: this.props.companyInfo?.relationshipId,
        teamNames: temp?.join(",")
    };

    const payload2 = {
      relationshipId: this.props.companyInfo?.relationshipId,
      departmentId: val?.join(",")
  };

  this.props.getTeamByDepartmentId(payload2);
    this.props.getTeamMembersByTeamName(payload);
      this.setState({
        edit:true,
        selectedDepartment:selectedDepartment ,
        selectedDepartmentId: val,
        parentId:this.props.projectData?.parentId?.projectMasterId,
        addAsSubcategory:this.state.isParenttheir ? true:false,
        parentData:this.state.isParenttheir ? props.parentItem:{},
        crmLeadTeamAssignedList:this.props.projectData?.crmLeadTeamAssignedList,
        crmLeadEmpAssignedList:this.props.projectData?.crmLeadEmpAssignedList,
        dataInitilize:true,
        subProjectList:this.props.projectData?.subProjectList,
        version:this.props.projectData.version
      });
    }
    
  }

  render() {
    return <div>
       <DetailProjectAddComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    detailProjectList: state.project.projectsDetails,
    teamList: state.employee.teamList,
    relationshipId: state.common.companyInfo.relationshipId,
    employeeByTeamName:state.crm.employeeByTeamName,
    departments: state.common.departmentList,
    teamBydepartmentId: state.employee.teamBydepartmentId,
    saveLoadinng: state.common.saveLoadinng
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal, 
  hideModal,
  downloadFileFromBucket,
  pushModalToStack,popModalFromStack,
  createOrUpdateProject,
  getAllProject,
  fetchProjects,resetTeamByDepartment,
  getTeam,getTeamMembersByTeamName,fetchAllDepartments,getTeamByDepartmentId,getAllProjectWithSubProject,resetTeamDataGetByName,getTeamByDepartmentId
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddDrawer);
