import React, { Fragment } from 'react';
import './../../../../assets/stylesheets/common/invoice.scss';
// import PageBreadcrumb from "../../../PageBreadcrumb";
import CustomerHeaderAction from "./headerAction";
import CustomerInvoiceDetails from "./invoiceDetails";
import CustomerProductDetails from "./productDetails";
// import { FormattedMessage } from 'react-intl';
import { Divider } from 'antd';

const SalesInvoiceComponent = (props) => {
    // const breadCrumbList = [
    //     {
    //         name: <FormattedMessage id='common.sales' defaultMessage='' />,
    //     },
    //     {
    //         name:  <FormattedMessage id='common.proformaInvoice' defaultMessage='' />,
    //     }
    // ];

    return (
        <Fragment>

            <div className="txn-header">
                <CustomerHeaderAction {...props} />
            </div>
            <div className="txn-body">
                <div className="txn-details-container">
                    <CustomerInvoiceDetails
                        paymentTerms={props.paymentTerms}
                        {...props}
                    />
                </div>
                <Divider style={{ margin: "0 0 10px 0px" }} />
                {props.selectedSalesOrder.length ? 
                    <div className="product-details-container">
                        <CustomerProductDetails {...props} />
                    </div> 
                : 
                    <div className="product-details-container">
                        <CustomerProductDetails {...props} />
                    </div>
                }
            </div>
            {/* <div className="invoice-header">
                <CustomerHeaderAction {...props} />
            </div>

            <div className="invoice-details-container">
                <CustomerInvoiceDetails paymentTerms={props.paymentTerms} {...props}/>
            </div>

            { false&& props.selectedSalesOrder.length ? <div className="split-rfq-details-container">
                <CustomerProductDetails {...props} />
            </div>: <div className="invoice-details-container">
                <CustomerProductDetails {...props} />
            </div>} */}
        </Fragment>
    )
};

export default SalesInvoiceComponent;
