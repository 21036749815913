import React from "react";
import { Form, Col, Row, DatePicker, Tooltip,Skeleton } from "antd";
import "@ant-design/compatible/assets/index.css";
import { injectIntl, FormattedMessage } from "react-intl";
import { TextBox } from "../../../general/TextBox";
import { Dropdown } from "../../../general/Dropdown";
import { CustomButton } from "../../../general/CustomButton";
import { ErrorMsg } from "../../../general/ErrorMessage";
import LoadingIcon from "../../../../assets/images/loading.gif";
import { CONSTANTS } from "../../../../static/constants";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";
import { getMomentDateForUI, fixedDecimalNumber } from "../../../../utils";
//import CKEditor from "../../../general/CustomCKEditor/ckeditor";
//import { CUSTOM_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";

const PayInvoice = (props) => {
  const {
    intl,
    customerName,
    customers,
    invoiceData,
    invoiceMasterId,
    paymentModeDetails,
    paymentModeName,
    amount,
    invoiceAmount,
    invoiceAmountDue,
    remarks,
    unpaidInvoiceList,
    paymentModeList,
    cashEquivalentLedgers,
    updateState,
    createCustomerPayment,
    hideModal,
  } = props;

  return (
    <div className="header-action-container">
        <Skeleton loading={props.detailLoading} active paragraph={{ rows: 12 }}>

      <Form>
        <div
          className="information"
          style={{ maxHeight: "510px", height: "510px" }}
        >
          <div className="pl30">
            <Row>
              <Col span={6}>
                <Tooltip placement="bottom">
                  <span className="">
                    <FormattedMessage
                      id="modal.customer.payInvoice.paymentNumber"
                      defaultMessage=""
                    />
                  </span>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={intl.formatMessage({
                    id: "tooltip.generatePaymentNumber",
                    defaultMessage: "",
                  })}
                  trigger="click"
                >
                  <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                </Tooltip>
              </Col>

              <Col span={16}>
                {props.nextTxNumber ? (
                  <span>{getFormatedNextTxNumber(props)}</span>
                ) : (
                  <img
                    src={LoadingIcon}
                    alt="Invoice"
                    style={{
                      height: "74px",
                      marginTop: "-25px",
                      marginBottom: "-25px",
                    }}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.selectCustomer"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
                <Dropdown
                  className={
                    props.submittedOnce && !props.customerName
                      ? "input-text-error"
                      : ""
                  }
                  items={customers[1] || []}
                  valueKeyName="companyName"
                  optionKeyName="customerId"
                  value={
                    (customers[1] || []).length
                      ? customerName || props.customerId
                      : undefined
                  }
                  placeholder={intl.formatMessage({
                    id: "modal.customer.payInvoice.selectCustomer.placeholder",
                    defaultMessage: "",
                  })}
                  onSelect={(optionValue) => {
                    let customer = (customers[1] || []).find(
                      (customer) => customer.customerId === optionValue
                    );
                    updateState({
                      customerName: customer.companyName,
                      customerId: optionValue,
                    });
                    resetInvoiceDetail(props);
                    props.fetchUnpaidInvoices({
                      customerId: optionValue,
                      relationshipId: (props.companyInfo || {}).relationshipId,
                      pageSize: 100,
                      pageNumber: 1,
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    updateState({
                      customerName: undefined,
                      customerId: undefined,
                    });
                    resetInvoiceDetail(props);
                  }}
                />
                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || !!props.customerName;
                  }}
                  message={
                    <FormattedMessage
                      id="modal.customer.payInvoice.customerValidation"
                      defaultMessage=""
                    />
                  }
                />
              </Col>
            </Row>

            {/* <Row>
                            <Col span={8}>
                                <FormattedMessage id='modal.customer.payInvoice.customerName' defaultMessage='' />
                            </Col>
                            <Col span={16}>
                                <div><strong>{customerName}</strong></div>
                            </Col>
                        </Row> */}

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.paymentDate"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
                <DatePicker
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder={intl.formatMessage({
                    id: "modal.customer.payInvoice.paymentDate.placeholder",
                    defaultMessage: "",
                  })}
                  key={`${getMomentDateForUI({
                    date: new Date(props.paymentDate),
                    format: CONSTANTS.DISPLAY_DATE_FORMAT,
                  })}`}
                  defaultValue={getMomentDateForUI({
                    date: new Date(props.paymentDate),
                    format: CONSTANTS.DISPLAY_DATE_FORMAT,
                  })}
                  onChange={(selectedDate) => {
                    updateState({
                      paymentDate: selectedDate,
                    });
                    // props.getNextTxNumber({ date: selectedDate, relationshipId: props.companyInfo.relationshipId });
                  }}
                  className={
                    props.submittedOnce && !props.paymentDate
                      ? "input-text-error"
                      : ""
                  }
                />
                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || !!props.paymentDate;
                  }}
                  message={
                    <FormattedMessage
                      id="modal.customer.payInvoice.paymentDateValidation"
                      defaultMessage=""
                    />
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.invoiceNumber"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
                <Dropdown
                  items={unpaidInvoiceList[1] || []}
                  valueKeyName="invoiceNumber"
                  optionKeyName="invoiceMasterId"
                  valueKeyName3="invoiceDueAmount"
                  currencyCode={(props.companyInfo || {}).currencyCode + " "}
                  value={
                    props.invoiceNumber
                      ? `${props.invoiceNumber} (Bal. ${
                          props.companyInfo.currencyCode + " "
                        } ${fixedDecimalNumber(invoiceAmountDue)})` ||
                        invoiceMasterId
                      : undefined
                  }
                  placeholder={intl.formatMessage({
                    id: "modal.customer.payInvoice.invoiceNumber.placeholder",
                    defaultMessage: "",
                  })}
                  className={
                    props.submittedOnce && !props.invoiceNumber
                      ? "input-text-error"
                      : ""
                  }
                  onSelect={(optionValue) => {
                    let selectedInvoice =
                      (unpaidInvoiceList[1] || []).find(
                        (inv) => inv.invoiceMasterId === optionValue
                      ) || {};
                    updateState({
                      invoiceMasterId: optionValue,
                      invoiceNumber: selectedInvoice.invoiceNumber,
                      invoiceAmount: selectedInvoice.invoiceTotalAmount,
                      amount: Number(
                        selectedInvoice.invoiceTotalAmount -
                          selectedInvoice.totalPayment
                      ).toFixed(2),
                      invoiceAmountDue: fixedDecimalNumber(
                        selectedInvoice.invoiceTotalAmount -
                          selectedInvoice.totalPayment
                      ),
                      invoiceData: selectedInvoice,
                      salesQuotationMasterId:
                        selectedInvoice.salesQuotationMasterId,
                      quotationNumber: selectedInvoice.quotationNumber,
                      rfqNumber: selectedInvoice.rfqNumber,
                      rfqMasterId: selectedInvoice.rfqMasterId,
                      reference: selectedInvoice.referenceNumber,
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    resetInvoiceDetail(props);
                  }}
                />
                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || !!props.invoiceNumber;
                  }}
                  message={
                    <FormattedMessage
                      id="modal.customer.payInvoice.invoiceValidation"
                      defaultMessage=""
                    />
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.paymentMode"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
                <Dropdown
                  items={paymentModeList || []}
                  className={
                    props.submittedOnce && !props.paymentModeId
                      ? "input-text-error"
                      : ""
                  }
                  valueKeyName="paymentModeName"
                  optionKeyName="paymentModeId"
                  value={
                    paymentModeList.length
                      ? props.paymentModeName || props.paymentModeId
                      : undefined
                  }
                  placeholder={intl.formatMessage({
                    id: "modal.customer.payInvoice.paymentMode.placeholder",
                    defaultMessage: "",
                  })}
                  onSelect={(optionValue) => {
                    let selectedPaymentMode =
                      (paymentModeList || []).find(
                        (pay) => pay.paymentModeId === optionValue
                      ) || {};
                    updateState({
                      paymentModeId: optionValue,
                      paymentModeName: selectedPaymentMode.paymentModeName,
                      chartOfAccountDetailsId:
                        selectedPaymentMode.paymentModeName === "Cash"
                          ? props.cashInHandLedger.ledgerAccountId
                          : 0,
                      ledgerAccountName:
                        selectedPaymentMode.paymentModeName === "Cash"
                          ? props.cashInHandLedger.txType
                          : "",
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    updateState({
                      paymentModeId: undefined,
                      paymentModeName: undefined,
                    });
                  }}
                />
                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || !!props.paymentModeId;
                  }}
                  message={
                    <FormattedMessage
                      id="modal.payInvoice.paymentModeValidation"
                      defaultMessage=""
                    />
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.paymentModeDetails"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
                <TextBox
                  type="text"
                  value={paymentModeDetails}
                  onChange={(e) => {
                    updateState({
                      paymentModeDetails: e.target.value,
                    });
                  }}
                  placeholder={intl.formatMessage({
                    id: "modal.customer.payInvoice.paymentModeDetails.placeholder",
                    defaultMessage: "",
                  })}
                />
              </Col>
            </Row>
            {paymentModeName && paymentModeName === "Cash" && (
              <Row>
                <Col span={6}>
                  <FormattedMessage id="cashAccount.text" defaultMessage="" />
                </Col>
                <Col span={16}>
                  <TextBox
                    type="text"
                    value={props.ledgerAccountName}
                    disabled={true}
                  />
                </Col>
              </Row>
            )}

            {paymentModeName && paymentModeName !== "Cash" && (
              <Row>
                <Col span={6}>
                  <FormattedMessage
                    id="modal.customer.payInvoice.bankAccount"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="top"
                    title={props.intl.formatMessage({
                      id: "cashEquivalents.ledgers",
                    })}
                    trigger="click"
                  >
                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                  </Tooltip>
                </Col>
                <Col span={16}>
                  <Dropdown
                    style={{ width: "94%" }}
                    className={
                      props.submittedOnce && !props.chartOfAccountDetailsId
                        ? "input-text-error"
                        : ""
                    }
                    items={cashEquivalentLedgers || []}
                    valueKeyName="ledgerAccountName"
                    optionKeyName="chartOfAccountDetailsId"
                    value={
                      cashEquivalentLedgers.length
                        ? props.ledgerAccountName ||
                          props.chartOfAccountDetailsId
                        : undefined
                    }
                    placeholder={intl.formatMessage({
                      id: "modal.customer.payInvoice.bankAccount.placeholder",
                      defaultMessage: "",
                    })}
                    onSelect={(optionValue) => {
                      let selectedAcc =
                        (cashEquivalentLedgers || []).find(
                          (acc) => acc.chartOfAccountDetailsId === optionValue
                        ) || {};
                      updateState({
                        chartOfAccountDetailsId: optionValue,
                        ledgerAccountName: selectedAcc.ledgerAccountName,
                      });
                    }}
                    allowClear={true}
                    onClear={() => {
                      updateState({
                        chartOfAccountDetailsId: undefined,
                        ledgerAccountName: undefined,
                      });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return (
                        !props.submittedOnce || !!props.chartOfAccountDetailsId
                      );
                    }}
                    message={
                      <FormattedMessage
                        id="modal.payInvoice.ledgerValidation"
                        defaultMessage=""
                      />
                    }
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.amount"
                  defaultMessage=""
                />
              </Col>
              <Col span={16} className="input-currency-control">
                <span className="currency-tag" style={{ top: 0 }}>
                  {props.companyInfo.currencyIcon ? (
                    <i className={props.companyInfo.currencyIcon}></i>
                  ) : (
                    props.companyInfo.currencyCode + " "
                  )}
                </span>
                <TextBox
                  type="number"
                  className={
                    props.submittedOnce &&
                    (props.amount > invoiceAmountDue ||
                      props.amount <= 0 ||
                      !props.amount)
                      ? "input-text-error"
                      : ""
                  }
                  value={amount}
                  onChange={(e) => {
                    updateState({
                      amount: e.target.value,
                    });
                  }}
                  placeholder={intl.formatMessage({
                    id: "modal.customer.payInvoice.placeholder",
                    defaultMessage: "",
                  })}
                />
                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || !!amount;
                  }}
                  message={
                    <FormattedMessage
                      id="modal.customer.payInvoice.amountValidation"
                      defaultMessage=""
                    />
                  }
                />
                <ErrorMsg
                  validator={() => {
                    return (
                      !props.submittedOnce ||
                      !amount ||
                      !(amount > Number(props.invoiceAmountDue || 0))
                    );
                  }}
                  message={
                    <FormattedMessage
                      id="modal.customer.payInvoice.amountOverValidation"
                      defaultMessage=""
                    />
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.invoiceAmount"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
                <div>
                  {props.companyInfo.currencyIcon ? (
                    <i className={props.companyInfo.currencyIcon}></i>
                  ) : (
                    props.companyInfo.currencyCode + " "
                  )}
                  {invoiceAmount
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.invoiceAmountDue"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
                <span>
                  {props.companyInfo.currencyIcon ? (
                    <i className={props.companyInfo.currencyIcon}></i>
                  ) : (
                    props.companyInfo.currencyCode + " "
                  )}
                  {(invoiceAmountDue - amount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </Col>
            </Row>

            <Row>
              <Col span={6} className="detail-column">
                <div>
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.refNumber.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={16}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "supplier.purchaseRequest.form.refNumber.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  value={props.reference}
                  onChange={(e) => {
                    updateState({
                      reference: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.remarks"
                  defaultMessage=""
                />
              </Col>
              {!(props.isRemarksSet)?
              <Col span={16}>
              <TextBox
                  placeholder={'Enter Remark'}
                  type="text"
                  value={props.remarks}
                  onChange={(e) => {
                    updateState({
                      remarks: e.target.value,
                    });
                  }}
                />
              </Col>:""}
               <Col span={16}>
                {props.isRemarksSet && (
                  <CKEditor
                    type="inline"
                    className="description-input additional-padding"
                    key={`${"remarks"}`}
                    data={remarks}
                    editor={props.editor}
                    onInit={(editor) => {
                      updateState({
                        editor,
                      });
                    }}
                    onChange={(event) => {
                      const data = event.editor.getData();
                      updateState({
                        remarks: data,
                      });
                    }}
                    config={CUSTOM_CONFIGURATION}
                  />
                )}
              </Col>
            </Row>
            {invoiceData && invoiceData.projectNumber && (
              <Row>
                <Col span={6}>
                  <FormattedMessage
                    id="modal.customer.payInvoice.project"
                    defaultMessage=""
                  />
                </Col>
                <Col span={16}>
                  <span>
                    {invoiceData.projectNumber +
                      " (" +
                      invoiceData.projectName +
                      ")"}
                  </span>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Form>
      <div className="form-footer">
        <CustomButton
          intlId="confirmation.cancel"
          defaultMessage=""
          type="default"
          key={0}
          style={{ marginRight: 8 }}
          onClick={() => {
            props.pushModalToStack({
              modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
              footer:{submit:{intlId:"button.close.label"}},
              handleSubmit: () => {
                props.hideModal();
              }
            })
          }}
        />
        <CustomButton
          intlId="confirmation.submit"
          defaultMessage=""
          htmlType="submit"
          key={1}
          onClick={() => {
            props.updateState({ submittedOnce: true });
            if (
              Number(props.amount) >
              Number(props.invoiceAmountDue || 0).toFixed(2)
            ) {
              return;
            }
            if (
              !props.customerName ||
              !props.paymentDate ||
              !props.invoiceNumber ||
              !props.paymentModeId ||
              !props.chartOfAccountDetailsId ||
              Number(props.amount) <= 0
            ) {
              return;
            }

            const modalData = {
              modalBody: (
                <FormattedMessage
                  id="modal.customer.payInvoice.saveConfirmation"
                  defaultMessage=""
                />
              ),
              handleSubmit: () => {
                createCustomerPayment(props);
                props.popModalFromStack();
              },
            };
            props.pushModalToStack(modalData);
          }}
        />
      </div>
      </Skeleton>
    </div>
  );
};

const resetInvoiceDetail = (props) => {
  props.updateState({
    invoiceMasterId: undefined,
    invoiceNumber: undefined,
    invoiceAmount: 0,
    amount: 0,
    invoiceAmountDue: 0,
    invoiceData: undefined,
    salesQuotationMasterId: undefined,
    quotationNumber: undefined,
    rfqNumber: undefined,
    rfqMasterId: undefined,
    reference: undefined,
  });
};

const getFormatedNextTxNumber = (props) => {
  // const { customerId } = props;
  // if (!customerId) {
  //     return <span>{props.nextTxNumber}</span>;
  // }
  // return <span>{props.nextTxNumber.replace('PAY-00', 'PAY-' + (customerId < 10 ? ('0' + customerId) : customerId))}</span>
  return <span>{props.nextTxNumber}</span>;
};

export default injectIntl(PayInvoice);
