import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OpenOrdersComponent from '../../../../../components/modal/modalBody/customer/OpenOrders';
import { fetchOpenOrders, fetchOpenOrdersCount, resetPaginatedData } from './action';
import { popModalFromStack } from './../../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from  '../../../../../utils';

class OpenOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
          pageSize: 25,
          pageNumber: 1,
          searchedText: '',
          sortStates:[]
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            customerId: this.props.customerData && this.props.customerData.customerId,
            pageNumber: 1,
            pageSize: 25
        }
        // fetchPaginationDataIfNeeded('fetchOpenOrders', 'openOrderList', this.props, payload, true);
        // fetchDataIfNeeded('fetchOpenOrdersCount', 'openOrderListCount', this.props, payload, true);
        this.props.fetchOpenOrders(payload, {companyInfo});
        this.props.fetchOpenOrdersCount(payload, {companyInfo});

    }

    render() {
        return <OpenOrdersComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        openOrderListCount: state.customer.openOrderListCount,
        openOrderList: state.customer.openOrderList,
        modalLoading:state.common.modalLoading

    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchOpenOrders,
    popModalFromStack,
    fetchOpenOrdersCount,
    resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OpenOrders);