import axios from "axios";
import config from "../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../utils";
import { COMMON_ACTIONS ,PRODUCT_ACTION_LIST} from "../../../../static/constants";

const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createUnboxingVideo = (payload) => {
  const formData = new FormData();
  formData.append('unboxing',JSON.stringify(payload||{}));
  return dispatch => {
   

    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/unboxing/createUnboxing`,formData
    )
      .then(response => {
       showToasterMessage({
          messageType: "success",
          description: "Updated Successfully.",
        });

      })
      .catch(err => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      })
  }
}
export const deleteUnboxingVideo = (payload)=>{
  let formData = new FormData();
  formData.append("relationshipId", payload.relationshipId);
  formData.append('bucketName', payload.bucketName);
  formData.append('objectKey', payload.awsKeyName);
  formData.append('folderName', payload.folderName);
  formData.append('unboxingId', payload.unboxingId);
  return dispatch => {
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/unboxing/deleteUnboxingVideo`,  formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    )
        .then(response => {
          // const payload1 = {
          //   relationshipId: (props.companyInfo || {}).relationshipId,
          //   feedbackId: (props.feedbackData || {})?.feedbackId

          // }
          // props.getReviewData(payload1)
          showToasterMessage({
            description: (response || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
         
        })
        .catch(err => {
          showToasterMessage({
              messageType: "error",
              description: "Some error occured",
            });
          //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
  }
}

