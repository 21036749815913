import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../../utils";
const { lang } = require("../../../../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const saveOrUpdateSetup = (payload, props) => {
    return (dispatch) => {
        return axios
            .post( `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/m2d/saveOrUpdate`, payload )
            .then((response) => {
                if (props) {
                    props.updateHeaderState({
                        accSetupDrawerVisible: false
                    });
                    props.getText2DonateConfig && props.getText2DonateConfig({relationshipId: props.companyInfo.relationshipId, source: 'sms'});
                    props.getText2DonateConfig && props.getText2DonateConfig({relationshipId: props.companyInfo.relationshipId, source: 'whatsapp'});
                }
                showToasterMessage({ messageType: 'success', description: 'Successfully Saved!' });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || "")] ||
                            "Some error occurred",
                    });
                }
            });
    };
};





