import React from "react";
import "../../schoolManagement/StudentProfile/index.scss";
import { Button, Skeleton, Tabs } from "antd";
import {
  getInitialsFromString,
  capitalizeFirstLetter,
} from "../../../../../utils";
import "./style.scss";
import Information from "./SideComp/Information";
// import Activities from "./MainComp/Activities";
// import Addresses from "../../../customer/details/address";
// import Communication from "../../../customer/details/communications";
// import TaxDetails from "../../../customer/details/taxIdentification";
// import Ledger from "../../../customer/details/ledgerTransactions";
// import Sales from "./MainComp/Sales";
// import Product from "./MainComp/Product";
// import Organization from "./MainComp/Organization";
import Contact from "./SideComp/Contact";
import CustomerDetailsComp from "../../../../../containers/customer/detail/index";
import * as debounce from "lodash.debounce";
import { Dropdown } from "../../../../general/Dropdown";
import { ICONS, MODAL_TYPE } from "../../../../../static/constants";
import {

  EditOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import ShowAddress from "../../../../../containers/customer/Listing/ShowAddress";

const { TabPane } = Tabs;
const CustomerProfileComp = (props) => {
  const { searchedByText, updateModalState, searchedCustomers } = props;
  let customerDetailObj = props.customer || {};
  const customerObj = {
    ...customerDetailObj
  };
  const getAddressInfoFragment = (obj) => {
    return (
      <>
        {obj.streetAddress1 ? (
          <span className="billing-address">{obj.streetAddress1}</span>
        ) : (
          ""
        )}
        {obj.streetAddress2 ? (
          <span className="billing-address"> {obj.streetAddress2}</span>
        ) : (
          ""
        )}
        {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""}${obj.stateName ? ", " + obj.stateName : ""
              }${obj.zipCode ? ", " + obj.zipCode : ""}`}
          </div>
        ) : (
          ""
        )}
        {obj.countryName ? (
          <div
            className="billing-address"
            style={{ textTransform: "uppercase" }}
          >
            {`${capitalizeFirstLetter(obj.countryName) || ""}`}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const onSearchedInputChange = debounce((searchedByText, props) => {
    handleSearchedText(searchedByText, props);
  }, 100);

  const handleSearchedText = (searchedByText, props) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: props.pageSize,
      searchedText: searchedByText,
      companyInfo: props.companyInfo,
    };
    if (searchedByText.length) {
      if (searchedByText.length >= 3) {
        props.resetPaginatedData();
        props.getCustomerCount(payload);
        props.fetchCustomers(payload);
      }
    } else {
      props.resetPaginatedData();
      props.getCustomerCount(payload);
      props.fetchCustomers(payload);
    }
  };

 
  return (
    <>
      <div className="txn-header">
        <div className="left-itms">
          <ul>
            <li className="title">Customer Profile</li>
          </ul>
        </div>
        <div className="right-itms">
          <Dropdown
            style={{ width: "250px" }}
            // items={searchedCustomers || []}
            items={(searchedCustomers || []).sort((a, b) => a.companyName?.localeCompare(b.companyName))}
            placeholder="Search customer by name"
            valueKeyName="companyName"
            optionKeyName="customerId"
            value={searchedByText || customerObj.companyName}
            onSearch={(searchedByText) => {
              updateModalState({ searchedByText, pageNumber: 1 });
              onSearchedInputChange(searchedByText, props);
              handleSearchedText(searchedByText, props);
            }}
            onSelect={(optionValue, option) => {
              updateModalState({ searchedByText: optionValue });
              updateModalState({
                customerId: option.value,
                customerDetails: (searchedCustomers || []).find((obj) => {
                  return obj.customerId === Number(option.key);
                }),
              });
              const payload = {
                customerId: option.value,
                relationshipId: props.companyInfo.relationshipId,
                pageNumber: 1,
                pageSize: 1000,
              };
              props.fetchCustomerDetailsByCustomerId(payload);
              props.getCustomerActivitiesTrack(payload);
              props.updateState({activeTabKey: '1'});
            }}
          />
        {/* <Search
               placeholder="Enter 3 characters customer name"
               value={searchedByText}
               allowClear
               onChange={e => {
                  updateModalState({ searchedByText: e.target.value, pageNumber: 1 });
                  onSearchedInputChange(e.target.value, props);
               }}
               onSearch={(searchedByText) => {
                  updateModalState({ searchedByText, pageNumber: 1 });
                  onSearchedInputChange(searchedByText, props);
                  handleSearchedText(searchedByText, props);
               }}
         /> */}
        </div>
      </div>

      <div className="profile-container">
        <div className="sidenav">
          <div className="sidenav-top">
            <div className="top-view">
              <div className="view1">
                <div className="name-icon">
                  {customerDetailObj.companyName
                    ? getInitialsFromString(customerDetailObj.companyName)
                    : "USER"}
                </div>
                <div>
                  <div className="profile-name">{customerObj.companyName}</div>
                  <div>
                 
                    {props.primaryAddress?.emailAddress ||
                      ""}

                 {/* {find(customerObj.boLocationList, {
        isDefault: 1,
        locationType: "BillingAddress",
      }) || {}? customerObj.primaryAddress?.emailAddress : ""} */}

                      {/* {(customerObj.boLocationList || []).find(obj => obj.locationType === "BillingAddress")?.emailAddress} */}
                  </div>
                </div>
              </div>

            
                {/* <div style={{marginLeft:"15px"}}>
                  {(Object.keys(customerObj.primaryAddress || {}) || []).length
                    ? getAddressInfoFragment(customerObj.primaryAddress)
                    :
                    <>
                    <div style={{display:"flex",flexDirection:"row",gap:"14px"}}>
                    <div className="grey-box"></div>
                   
                      <Button className="edit-btn-rf" type="primary" ghost style={{color:"#1890ff"}}
                  
                    onClick={() => {
                      const modalData = {
                      title: (
                          <React.Fragment>
                          <div className="ant-modal-title">
                              <FormattedMessage
                              id="signup.tab.address"
                              defaultMessage=""
                              />
                              <div style={{ "font-size": "12px" }}>
                              {" "}
                              {props.customerName || ""}
                              </div>
                          </div>
                          </React.Fragment>
                      ),
                      modalBody: (
                          <ShowAddress
                          
                          {...props}
                          customerId={props.customerId}
                          />
                      ),
                      width: 1100,
                      hideFooter: true,
                      };
                      props.pushModalToStack(modalData);
                  }}
                      >
                      <EditOutlined style={{color:"#1890ff"}}  />
                      <span style={{color:"#1890ff"}} >Add</span>
                      </Button>
                    </div>
                    

                    <div className="grey-box" style={{width:"150px"}}></div>
                    </>    
                    }
                </div> */}
             
             <div className="addressBar">
                <div className="addressIcon"><i className="fi fi-rs-marker" /></div>
                {(Object.keys(props.primaryAddress || {}) || []).length ? (
                  <>
                  <div className="address-data">
                    <div>{getAddressInfoFragment(props.primaryAddress)}</div>
                    {
                      
                    }
                    <Button
                      className="edit-btn-rf"
                      type="primary"
                      ghost
                      style={{ color: "#1890ff" }}
                      onClick={() => {
                        const modalData = {
                          title: (
                            <React.Fragment>
                              <div className="ant-modal-title">
                                <FormattedMessage
                                  id="signup.tab.address"
                                  defaultMessage=""
                                />
                                <div style={{ fontSize: "12px" }}>
                                  {" "}
                                  {props.customerName || ""}
                                </div>
                              </div>
                            </React.Fragment>
                          ),
                          modalBody: (
                            <ShowAddress {...props} customerId={props.customerId} />
                          ),
                          width: 1100,
                          hideFooter: true,
                        };
                        props.pushModalToStack(modalData);
                      }}
                    >
                      <EditOutlined style={{ color: "#1890ff" }} />
                      <span style={{ color: "#1890ff" }}>Edit</span>
                    </Button>
                    </div>
                  </>
                ) : (
     
                    <div className="address-data">
                      <div>
                        <div className="grey-box"></div>
                        <div className="grey-box"></div>
                      </div>
                      
                    <Button
                      className="edit-btn-rf"
                      type="primary"
                      ghost
                      style={{ color: "#1890ff" }}
                      onClick={() => {
                        const modalData = {
                          title: (
                            <React.Fragment>
                              <div className="ant-modal-title">
                                <FormattedMessage
                                  id="signup.tab.address"
                                  defaultMessage=""
                                />
                                <div style={{ fontSize: "12px" }}>
                                  {" "}
                                  {props.customerName || ""}
                                </div>
                              </div>
                            </React.Fragment>
                          ),
                          modalBody: (
                            <ShowAddress {...props} customerId={props.customerId} />
                          ),
                          width: 1100,
                          hideFooter: true,
                        };
                        props.pushModalToStack(modalData);
                      }}
                    >
                      <EditOutlined style={{ color: "#1890ff" }} />
                      <span style={{ color: "#1890ff" }}>Add</span>
                    </Button>
                  
                    </div>
                      
              
                )}

            </div>
              <div className="status-label label_only">
                {capitalizeFirstLetter(customerObj.customerType)}
              </div>
            </div>

          </div>

          <div className="sidenav-bottom">
            <div className="tabs-info">
              <Tabs type="line" className="custom-tab-st" animated={false}>
                <TabPane tab="Information" key="1">
                  <Information {...props} {...customerObj} {...props.primaryAddress} {...props.primaryContact}/>
                </TabPane>
                <TabPane tab="Contact" key="2">
                  <Contact {...props.primaryContact} />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>

        <div className="leftnav">
          <CustomerDetailsComp {...props} />
        </div>
      </div>
    </>
  );
};
export default CustomerProfileComp;
