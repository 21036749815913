import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import ReactHtmlParser from "react-html-parser";
import { Skeleton, Pagination, Popover, Tooltip,Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  getMomentDateForUIReadOnly,
  sortTable,
} from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./index.scss";
import ShipmentDetail from "../../../../containers/modal/modalBody/customer/ShipmentDetail";
//import ShippingInvoiceDetail from '../../../../containers/modal/modalBody/customer/ShippingInvoiceDetail';
//import CertificateOfConformity from '../../../../containers/modal/modalBody/customer/CertificateOfConformity';
import { CONSTANTS, MODULE_CODED_VALUES } from "../../../../static/constants";
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
import { FilterOutlined, InsertRowRightOutlined } from "@ant-design/icons";
import ShipmentPop from "../../../../containers/customer/salesOrder/shipment/index";
import CustomerProfile from "../../../../containers/modal/modalBody/customer/Profile";
import PageHeader from "../../../Common/pageHeader";
import TrackingDetailsModal from "../../../modal/modalBody/customer/ShipmentDetail/trackingDetailsModal";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const ShipmentListingComp = (props) => {
  const {
    shipmentList,
    updateState,
    companyInfo,
    shipmentCount,
    pageNumber,
    pageSize,
    dir = 0,
   
  } = props;
  
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchAllShipment",
      "shipmentList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchAllShipment(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // }

  const openDeliveryNotesDetails = (data) => {
    const shipmentPayload = {
      customerId: data.customerId,
      shipmentMasterId: data.shipmentMasterId,
    };
    props.pushModalToStack({
      modalBody: (
        <ShipmentDetail
          {...props}
          shipmentData={data}
          shipmentPayload={shipmentPayload}
        />
      ),
      width: "100%", keyboard:false,
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
      handleSubmit: () => {},
    });
  };

  // const saveColumnIndex = (columnState) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data);
  // }

  // const saveColumnWidth = (columnState, width) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data, props);
  // }

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //     if (event.column) {
  //         moveEvent = event;
  //     }
  //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
  //         saveColumnIndex(moveEvent.columnApi.getColumnState());
  //     }
  // }

  // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //     event.api.resetRowHeights();
  //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
  //         //event.api.resetRowHeights();
  //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
  //             props.gridApi.sizeColumnsToFit();
  //             setTimeout(() => {
  //                 const columnArray = event.columnApi.getColumnState();
  //                 saveColumnWidth(columnArray);
  //             }, 1000);
  //         } else {
  //             saveColumnWidth(event.columnApi.getColumnState());
  //         }
  //     }
  // }

  const getQuantityShipped = (data) => {
    let quantity = 0;
    data.customerSOShipmentDetailsList &&
      data.customerSOShipmentDetailsList.forEach((e) => {
        return (quantity += e.quantityPacked);
      });
    return quantity;
  };

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  // const openShippingInvoiceDetails = (data) => {
  //     const shipmentPayload = {
  //         customerId: data.customerId,
  //         shipmentMasterId: data.shipmentMasterId
  //     }
  //     props.pushModalToStack({
  //         modalBody: <ShippingInvoiceDetail {...props} shipmentData={data} shipmentPayload={shipmentPayload} />,
  //         width: '100%',
  //         hideFooter: true,
  //         wrapClassName: 'modal-custom-detail',
  //         handleSubmit: () => {
  //         }
  //     });
  // }

  // const openCertifcateOfConformity = (data) => {
  //     const shipmentPayload = {
  //         customerId: data.customerId,
  //         shipmentMasterId: data.shipmentMasterId
  //     }
  //     props.pushModalToStack({
  //         modalBody: <CertificateOfConformity {...props} shipmentData={data} shipmentPayload={shipmentPayload} />,
  //         width: '100%',
  //         hideFooter: true,
  //         wrapClassName: 'modal-custom-detail',
  //         handleSubmit: () => {
  //         }
  //     });
  // }

  // const updateActualDeliveryDate = (props, shipmentData) => {
  //     let payload = {formData: { textBoxValue : (shipmentData.actualDeliveryDate ? new Date(shipmentData.actualDeliveryDate) : new Date())}};
  //     const formData = payload.formData;
  //     const { showModal, companyInfo } = props;
  //     formData.relationshipId = companyInfo.relationshipId;
  //     const data = {
  //       title: <FormattedMessage id='addItem.text.actualDeliveryDate' defaultMessage='' />,
  //       formData,
  //       modalData: {
  //         modalType: MODAL_TYPE.ACTUAL_DELIVERY_DATE,
  //         data: payload,

  //       },
  //         handleSubmit: (formData = {}) => {
  //             data.formData.submittedOnce = true;
  //             props.hideModal(data);
  //             props.updateActualDeliveryDate({
  //                 actualDeliveryDate: getCurrentDateForBackend(formData.textBoxValue),
  //                 relationshipId: (props.companyInfo || {}).relationshipId,
  //                 shipmentMasterId: shipmentData.shipmentMasterId
  //             }, props);
  //         }
  //     };
  //     showModal(data);
  //   }

  //   const updateShipment = (props, shipmentData) => {
  //     props.history.push({
  //         pathname: '/admin/so/shipment',
  //         state: {
  //             customerId: shipmentData.customerId,
  //             soMasterId: (shipmentData || {}).salesOrderMasterId,
  //             shipmentMasterId: shipmentData.shipmentMasterId,
  //             update: true
  //         }
  //     })
  // }

  // const addItemConfirmationModal = (props, text, txData) => {
  //     const { showModal, companyInfo } = props;
  //     const labelName = <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />;
  //     const modalBody = <span>
  //         <FormattedMessage
  //             id='setItem.confirmation'
  //             values={{ value: `${text} to ` }}
  //             defaultMessage={``}
  //         />
  //         {labelName} ?
  //   </span>;

  //     let payload = {
  //         userStatus: text,
  //         relationshipId: companyInfo.relationshipId,
  //         shipmentMasterId: txData.shipmentMasterId,
  //         customerId: txData.customerId
  //     };

  //     const modalData = {
  //         modalBody,
  //         handleSubmit: () => {
  //             props.updateUserStatus(payload, props);
  //             props.hideModal();
  //             props.popModalFromStack();
  //         },
  //     };
  //     showModal(modalData);
  // }

  // const updateUserStatus = (props, txData) => {
  //     let payload = { formData: {} };
  //     const formData = payload.formData;
  //     const { pushModalToStack } = props;

  //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
  //         addItemConfirmationModal(props, formData.textBoxValue, txData);
  //     } else {
  //         const data = {
  //             title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
  //             formData,
  //             hideFooter: true,
  //             modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SHIPMENT },
  //             handleSubmit: (formData = {}) => {
  //                 if (formData.textBoxValue && formData.textBoxValue.length) {
  //                     addItemConfirmationModal(props, formData.textBoxValue, txData);
  //                 } else {
  //                     data.formData.submittedOnce = true;
  //                     pushModalToStack(data);
  //                 }
  //             }
  //         };
  //         pushModalToStack(data);
  //     }
  // }

  // const actionMenu = (param) => {
  //     return (
  //         <Menu className="cell-action-dropdown">

  //             {param.data.status === "delivered" ? <Menu.Item key="1" onClick={() => {
  //                 props.showModal({
  //                     modalBody: "Are you sure you want to change the status?",
  //                     handleSubmit: () => {
  //                         props.markAsDelivered({
  //                             relationshipId: (props.companyInfo || {}).relationshipId,
  //                             shipmentMasterId: param.data.shipmentMasterId,
  //                             status: "shipped",
  //                             lableColor: 'shipped',
  //                             customerId: param.data.customerId,
  //                             createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
  //                         }, props)
  //                     }
  //                 })
  //             }}>Mark As Shipped
  //                     </Menu.Item>
  //                 : <Menu.Item key="2" onClick={() => {
  //                     props.showModal({
  //                         modalBody: "Are you sure you want to change the status?",
  //                         handleSubmit: () => {
  //                             props.markAsDelivered({
  //                                 relationshipId: (props.companyInfo || {}).relationshipId,
  //                                 shipmentMasterId: param.data.shipmentMasterId,
  //                                 status: "delivered",
  //                                 lableColor: 'delivered',
  //                                 customerId: param.data.customerId,
  //                                 createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
  //                             }, props)
  //                         }
  //                     })
  //                 }}> Mark As Delivered
  //             </Menu.Item>
  //             }
  //             <Menu.Item key="3" onClick={() => {
  //                    openShippingInvoiceDetails(param.data);
  //                 }}> Shipping Invoice
  //             </Menu.Item>
  //             <Menu.Item key="4" onClick={() => {
  //                    openCertifcateOfConformity(param.data);
  //                 }}> Certifcate of Conformity
  //             </Menu.Item>
  //             <Menu.Item key="5" onClick={() => {
  //                    updateActualDeliveryDate(props, param.data);
  //                 }}> Actual Delivery Date
  //             </Menu.Item>
  //             <Menu.Item key="6" onClick={() => {
  //                    updateShipment(props, param.data);
  //                 }}> Update Shipment
  //             </Menu.Item>
  //             <Menu.Item key="7">
  //                 <i className="fa fa-trash" />
  //                 <Link onClick={() => {
  //                     props.showModal({
  //                         modalBody: "Are you sure you want to delete this transaction?",
  //                         handleSubmit: () => {
  //                             props.deleteShipment({
  //                                 shipmentMasterId: param.data.shipmentMasterId,
  //                                 customerId: param.data.customerId,
  //                                 relationshipId: param.data.relationshipId
  //                             }, props)
  //                         }
  //                     })

  //                 }}>Delete Shipment</Link>
  //             </Menu.Item>
  //             <Menu.Item key="8" onClick={() => {
  //                 updateUserStatus(props, param.data);
  //             }}>
  //                 <i className="fa fa-pencil" />
  //                  Update User Status
  //                 </Menu.Item>
  //         </Menu >
  //     );
  // }

  // const columnDefs = [
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.shipmentNo' defaultMessage='' />;
  //         },
  //         field: "shipmentNumber",
  //         hide: _isColumnToHide('shipmentNumber'),
  //         colId: 'shipmentNumber_1',
  //         minWidth: 170,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         rowDrag: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openDeliveryNotesDetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='modal.reports.agedDebtors.customerName' defaultMessage='' />;
  //         },
  //         field: "customerName",
  //         hide: _isColumnToHide('customerName'),
  //         colId: 'customerName_1',
  //         cellRendererFramework: (link)=>{
  //             return <div className="cursor-pointer" >
  //             <div onClick={() => {
  //                 props.history.push("customers/details", {
  //                     customerId: link.data.customerId,
  //                     customerName: link.data.customerName,
  //                 })
  //             }}>{link.data.customerName}</div>
  //         </div>
  //         },
  //         minWidth: 100,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.qtyShipped' defaultMessage='' />;
  //         },
  //         hide: _isColumnToHide('qtyShipped'),
  //         colId: 'qtyShipped_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div>
  //             {getQuantityShipped(link.data)}
  //         </div>,
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='customer.shipment.listing.header.totalPackage' defaultMessage='' />;
  //     //     },
  //     //     hide: _isColumnToHide('totalQtyShipped'),
  //     //     colId: 'totalQtyShipped_1',
  //     //     minWidth: 50,
  //     //     maxWidth: 400,
  //     //     suppressMovable: true,
  //     //     resizable: true,
  //     //     cellRendererFramework: (link) => <div>
  //     //         { link.data.totalPackagesCount }
  //     //     </div>,
  //     // },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.carrierName' defaultMessage='' />;
  //         },
  //         field: "carrierName",
  //         hide: _isColumnToHide('carrierName'),
  //         colId: 'carrierName_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.trackingNumber' defaultMessage='' />;
  //         },
  //         field: "trackingNumber",
  //         hide: _isColumnToHide('trackingNumber'),
  //         colId: 'trackingNumber_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             window.open(link.data.trackingUrlEP, '_blocked');
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.shipmentDate' defaultMessage='' />;
  //         },
  //         field: "shipmentDate",
  //         hide: _isColumnToHide('shipmentDate'),
  //         colId: 'shipmentDate_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div>
  //              <Tooltip placement="topLeft" title={(link.data.shipmentDate && getMomentDateForUIReadOnly({date: link.data.shipmentDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
  //             {getMomentDateForUIReadOnly({date: new Date(link.data.shipmentDate), format})}
  //             </Tooltip>
  //         </div>,
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.expectedDeliveryDate' defaultMessage='' />;
  //         },
  //         field: "expectedDeliveryDate",
  //         hide: _isColumnToHide('expectedDeliveryDate'),
  //         colId: 'expectedDeliveryDate_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div>
  //              <Tooltip placement="topLeft" title={(link.data.shipmentDate && getMomentDateForUIReadOnly({date: link.data.shipmentDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
  //             {getMomentDateForUIReadOnly({date: new Date(link.data.expectedDeliveryDate), format})}
  //             </Tooltip>
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.systemStatus' defaultMessage='' />;
  //         },
  //         field: "status",
  //         hide: _isColumnToHide('status'),
  //         colId: 'status_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (params) => {
  //             return <div> <span className={["status-code", (params.value || '').toLowerCase()].join(' ')}><FormattedMessage id={params.value || "default"} defaultMessage={params.value} /></span> </div>
  //         },
  //         resizable: true,
  //         cellClass: 'status-cell payment',
  //         cellStyle: function (props){
  //             if(props.statusColor){
  //                 return {backgroundColor: props.statusColor}
  //             }
  //         }

  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='supplier.purchaseOrder.listing.header.stockUpdate' defaultMessage='' />;
  //     //     },
  //     //     field: "isStockUpdated",
  //     //     colId: "isStockUpdated_1",
  //     //     minWidth: 70,
  //     //     maxWidth: 400,
  //     //     suppressMovable: true,
  //     //     cellRendererFramework: (params) =>{
  //     //         return params.node.data.isStockUpdated ? <img src={checkIcon}  style={{ 'height': '15px', 'width': '15px' }} alt="Stock Updated"/> : <img src={crossIcon}  style={{ 'height': '10px', 'width': '10px' }} alt="Stock Not Updated"/>;
  //     //     },
  //     //     hide: _isColumnToHide('isStockUpdated'),
  //     //     resizable: true,
  //     // },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerSalesEnquiryNumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerInquiryNumber',
  //         hide: _isColumnToHide('customerInquiryNumber'),
  //         colId: "customerInquiryNumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerInquiryNumber || '-'}
  //         </div>,
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerRfqNumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerRFQNumber',
  //         hide: _isColumnToHide('customerRFQNumber'),
  //         colId: "customerRFQNumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerRFQNumber || '-'}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerPONumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerPONumber',
  //         hide: _isColumnToHide('customerPONumber'),
  //         colId: "customerPONumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerPONumber || '-'}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.actualDeliveyDate' defaultMessage='' />;
  //         },
  //         field: "actualDeliveryDate",
  //         hide: _isColumnToHide('actualDeliveryDate'),
  //         colId: 'actualDeliveryDate_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div>
  //             { link.data.actualDeliveryDate && getMomentDateForUIReadOnly({date: new Date(link.data.actualDeliveryDate), format})}
  //         </div>,
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.comments' defaultMessage='' />;
  //         },
  //         field: "comments",
  //         hide: _isColumnToHide('comments'),
  //         colId: 'comments_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.consigneeName' defaultMessage='' />;
  //         },
  //         field: "consigneeName",
  //         hide: _isColumnToHide('consigneeName'),
  //         colId: 'consigneeName_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.customerRemarks' defaultMessage='' />;
  //         },
  //         field: "remarksCustomer",
  //         hide: _isColumnToHide('remarksCustomer'),
  //         colId: 'remarksCustomer_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div>
  //             { ReactHtmlParser(link.data.remarksCustomer)}
  //         </div>
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.freightType' defaultMessage='' />;
  //         },
  //         field: "freightType",
  //         hide: _isColumnToHide('freightType'),
  //         colId: 'freightType_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.status' defaultMessage='' />;
  //         },
  //         field: "userStatus",
  //         hide: _isColumnToHide('userStatus'),
  //         colId: "userStatus_1",
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.value? <span className="status-code" style={{background: link.data.userStatusColor}}>{link.value || ''}</span>:''}
  //             </div>
  //         },
  //         resizable: true,
  //         cellStyle: function (props){
  //             if(props.statusColor){
  //                 return {backgroundColor: props.statusColor}
  //             }
  //         }
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.refNumber' defaultMessage='' />;
  //         },
  //         field: "referenceNumber",
  //         colId: "referenceNumber_1",
  //         hide: _isColumnToHide('referenceNumber'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.shipment.listing.header.shippingLabel' defaultMessage='' />;
  //         },
  //         field: "shippingLabel",
  //         colId: "shippingLabel_1",
  //         hide: _isColumnToHide('shippingLabel'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             window.open(link.data.labelUrlEP, '_blocked');
  //         }}><FormattedMessage id='customer.shipment.listing.rowValue.viewLabel' defaultMessage='' /></div>,
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <div className="cursor-pointer">
  //     //             <Popover
  //     //                 content={<AddAndRemoveCustomerListingColumn {...props} />}
  //     //                 title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
  //     //                 placement="left"
  //     //                 trigger="click"
  //     //             >
  //     //                 <i className='fa fa-bars' />
  //     //             </Popover>
  //     //         </div>;
  //     //     },
  //     //     cellRendererFramework: (param) => {
  //     //         return <Dropdown overlay={actionMenu(param)} trigger={['click']}>
  //     //             <Button className="action-btn small">
  //     //                 <i className="fa fa-ellipsis-v" />
  //     //             </Button>
  //     //         </Dropdown>
  //     //     },
  //     //     resizable: true,
  //     //     colId: 'action_1',
  //     //     field: 'action',
  //     //     pinned: 'right',
  //     //     suppressMovable: true,
  //     //     lockPosition:true,
  //     //     minWidth: 52,
  //     //     maxWidth: 52,
  //     // }
  // ];

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  const breadCrumbList = [
    {
      name: "Sales",
    },
    {
      name: "Shipment",
    },
  ];

  // const onGridReady = (params) => {
  //    // gridApi = params.api;
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi
  //     });
  //     onDataRendered(params);
  // }

  // const onModelUpdated = (params) => {
  //     if ((shipmentList[pageNumber] || []).length && !props.isRowHeightSet) {
  //         setTimeout(() => {
  //             params.api.resetRowHeights();
  //         }, 1000)
  //         props.updateState({
  //             isRowHeightSet: true
  //         })
  //     }
  //     if (props.tableDataReRendered) {
  //         //params.api.resetRowHeights();
  //         props.updateState({
  //             tableDataReRendered: false
  //         })
  //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.SHIPMENT_LISTING;

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     }
  // }

  // const onDataRendered = (params) => {
  //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length? props.txColumnSetting: params.columnApi.getColumnState();
  //     if (Object.keys(txColumnSetting).length) {
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.SHIPMENT_LISTING;

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.width = 52;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     } else {
  //         params.api.sizeColumnsToFit();
  //     }
  // }
  const sortColumn = (e) => {
    sortTable(
      document.getElementById("shipment-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };
  const openNewShipment = () => {
    props.updateState({ type: "new" });
    setTimeout(() => {}, 2000);
    props.pushModalToStack({
      modalBody: <ShipmentPop {...props} type="new" />,
      width: "100%",
      hideTitle: true,
      keyboard:false,
      hideFooter: true,
      wrapClassName: "new-transaction-wrapper",
      onCancel: () => {
        props.showModal({
          modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
          footer:{submit:{intlId:"button.close.label"}},
          handleSubmit: () => {
            props.popModalFromStack();
          }
        })
      }
    });
  };

  const openTrackingStatus = () =>{
    props.pushModalToStack({
        modalBody: <TrackingDetailsModal {...props} />,
        width: '60%',
        hideTitle: true,
        keyboard:false,
        hideFooter: true,
    })
  }



  return (
    <Fragment>
   
      <PageHeader {...props}
        pageName="heading.sales.shipment.head"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openNewShipment}
        moduleCode={ MODULE_CODED_VALUES.SALES_ORDER_SHIPMENT }
      />
      <div className="view-container">
        {/* <Row className="search-container" type='flex' justify='end'>
                    <Col span={12}>
                    </Col>
                    <Col className='align-end' span={12}>
                        <div className="action-dropdown-btn">
                            <button onClick={() => {
                                props.history.push(
                                    {
                                        pathname: "/admin/so/shipment",
                                        state: {
                                            type: "new"
                                        }
                                    }
                                    );
                                }
                            }>
                                <i className="fa fa-user" />New Shipment
                            </button>
                        </div>
                    </Col>
                </Row>
                <div className='pb5'></div> */}

   
          {/* <Collapse>
                        <Panel header="Apply Filter" key="1"  style={{ width : '100%' }}>
                        <div className='qute-sect1'>
                            <Dropdown
                            className='drop-fileds1'
                                // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}
                                items={props.customers[pageNumber] || []}
                                valueKeyName='companyName'
                                optionKeyName='customerId'
                                value={props.selectedCustomerName}
                                onSearch={(searchedText) => {
                                    props.fetchCustomers({
                                        isCompact: true,
                                        searchedText: searchedText,
                                        pageNumber: 1, 
                                        hideSpinner: true,
                                        pageSize: 100,
                                        relationshipId: (props.companyInfo || {}).relationshipId
                                    })
                                }}
                                placeholder={props.intl.formatMessage({
                                    id: 'customer.salesInvoice.form.customer.placeholder', defaultMessage: ''
                                })}

                                onSelect={
                                    (customerId, option) => {
                                        updateState({
                                            selectedCustomerId: option.value,
                                            selectedCustomerName: option.name,
                                        })
                                    }
                                }
                                allowClear={true}
                                onClear={() => {
                                    updateState({
                                        selectedCustomerId: undefined,
                                        selectedCustomerName: undefined,
                                    })
                                }}
                            />
                            <TextBox 
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                                className='drop-fileds'
                                placeholder='Shipment #'
                                type='text'
                                maxLength={15}
                                value={props.shipmentNum}
                                onChange={(e) => {
                                    props.updateState({ shipmentNum: e.target.value });
                                }}
                            />
                            <RangePicker
                                // style={{ marginTop: '-20px', width: '300px', marginRight: '5px' }}
                                className='drop-fileds2'
                                allowEmpty={true}
                                format={format}
                                placeholder={['Start date', 'End date']}
                                value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                                onChange={(dates) => {
                                    if (dates) {
                                        props.updateState({ startDate: dates[0], endDate: dates[1] });
                                    } else {
                                        props.updateState({ startDate: null, endDate: null });
                                    }
                                }}
                            />
                            <Dropdown
                                items={props.userStatus || []}
                                valueKeyName='statusName'
                                optionKeyName={'shipmentStatusId'}
                                statusColorKey='statusColor'
                                // style={{ marginTop: '-25px', width: '160px', marginRight: '5px' }}
                                className='drop-fileds1'
                                value={props.selectedUserStatusName}
                                placeholder={props.intl.formatMessage({ id: 'common.status' })}
                                onSelect={(selectedValue, option) => {
                                    updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                                }}
                            />

                            <Dropdown
                                // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}
                                className='drop-fileds1'
                                items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partiallyFullfilled' }, { name: 'Fullfilled', value: 'fullFilled' }]}
                                valueKeyName='name'
                                optionKeyName='value'
                                value={props.selectedStatusName}
                                placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                                }}
                            />
                            <div className='cler'></div>
                            </div>
                            <div className='cler'></div>
                            <div className='qute-sect1'>
                            
                            <TextBox 
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                                className='drop-fileds'
                                placeholder='Enter Ref #'
                                type='text'
                                maxLength={15}
                                value={props.refNum}
                                onChange={(e) => {
                                    props.updateState({ refNum: e.target.value });
                                }}
                            />

                            <TextBox 
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                                className='drop-fileds'
                                placeholder={props.intl.formatMessage({ id: 'filter.placeholder.trackingNumber' })}
                                type='text'
                                maxLength={15}
                                value={props.trackingNum}
                                onChange={(e) => {
                                    props.updateState({ trackingNum: e.target.value });
                                }}
                            />

    <div className='cler'></div>
                            </div>
                            <div className='cler'></div>
                            <div className='qute-sect1'>
                            <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                                if(!props.selectedCustomerId && !props.shipmentNum && !props.startDate && !props.endDate && !props.selectedUserStatus && !props.selectedStatus && !props.refNum && !props.trackingNum){
                                    return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                                }
                                const payload = {
                                    companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                    filters: {
                                        customerId: props.selectedCustomerId || 0,
                                        shipmentNumber: props.shipmentNum || null,
                                        startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                                        endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                                        userStatus: props.selectedUserStatusName || null,
                                        status: props.selectedStatus || null,
                                        reference: props.refNum || null,
                                        trackingNumber: props.trackingNum || null,
                                    }
                                };
                                props.fetchAllShipmentByFilter(payload);
                                props.getShipmentCountByFilter(payload);

                            }}>
                                {<FilterOutlined />} Apply Filter
                            </button>
                            <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                                props.updateState({
                                    selectedCustomerId: null, selectedCustomerName: null,
                                    shipmentNum: null,
                                    startDate:null, endDate:null,
                                    selectedUserStatusName: null, selectedUserStatus: null,
                                    selectedStatus:null, selectedStatusName: null,
                                    refNum: null,
                                    trackingNum: null,
                                });
                                const payload = {
                                    companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                    filters: {}
                                };
                                props.fetchAllShipment(payload);
                                props.getShipmentCount(payload);
                                }}>
                                    {<UndoOutlined />} Clear Filter
                            </button>
                            </div>
                        </Panel>
                    </Collapse> */}

          <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} active>

            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="sales.shipment.table.heading"
                  defaultMessage=""
                />
              </div>
              {shipmentCount ? (
                <>
                  <div className="vertical-sep" />
                  <div>{shipmentCount}</div>
                </>
              ) : 0}
            </div>
            <div className="right-actions">
              <button
                onClick={() => {
                  props.updateState({
                    shipmentFilterDrawerVisible: true,
                  });
                }}
              >
                <FilterOutlined onClick={() => {}} /> &nbsp;{" "}
                <FormattedMessage id="filter.text" defaultMessage="" />{" "}
              </button>
              <Popover
                content={<AddAndRemoveCustomerListingColumn {...props} />}
                title={<div className="text-center">Add/Remove</div>}
                placement="left"
                trigger="click"
                arrow="right"
                // visible={props.showColPop}
              >
                <button
                  onClick={() => {
                    props.updateState({ showColPop: !props.showColPop });
                  }}
                >
                  <InsertRowRightOutlined className='mr5'/>
                 Columns
                </button>
              </Popover>
            </div>
            </Skeleton>
          </div>

          {/* {props.txColumns.length ? <div>
                        <div className="cursor-pointer">
                            <Tooltip placement='left' title={props.intl.formatMessage({ id: 'tooltip.addRemoveColumn.msg' })} trigger='hover'>
                                <span className="top-button">
                                    <Popover
                                        content={<AddAndRemoveCustomerListingColumn {...props} />}
                                        title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                                        placement="left"
                                        trigger="click"
                                    >
                                        <i className='fa fa-bars' />
                                    </Popover>
                                </span>
                            </Tooltip>
                        </div>
                        <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            rowData={shipmentList[pageNumber] || []}
                            rowDragManaged={true}
                            defaultColDef={{
                                flex: 1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            // getRowHeight={getRowHeight}
                            onModelUpdated={onModelUpdated}
                            onColumnResized={onColumnResized}
                            onDragStopped={() => {
                                onColumnMoved({}, true);
                            }}
                            //onColumnMoved={onColumnMoved}
                        >
                        </AgGridReact>
                    </div> : null} */}
          <Skeleton loading={props.listLoading} paragraph={{ rows: 14 }} active>

          <div className="table-container">
            <table id="shipment-table">
              <thead>
                <tr>
                  <th>S. No.</th>
                  {!_isColumnToHide("shipmentNumber") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.shipmentNo"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("customerName") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="modal.reports.agedDebtors.customerName"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("qtyShipped") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.qtyShipped"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("carrierName") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.carrierName"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("trackingNumber") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.trackingNumber"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("trackingNumber") ? (
                    <th onClick={sortColumn}>
                      Shipment Status
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("shipmentDate") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.shipmentDate"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("expectedDeliveryDate") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.expectedDeliveryDate"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("userStatus") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage id="common.status" defaultMessage="" />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("status") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.systemStatus"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("customerInquiryNumber") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customerSalesEnquiryNumber.text"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("customerRFQNumber") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customerRfqNumber.text"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("customerPONumber") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customerPONumber.text"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("actualDeliveryDate") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.actualDeliveyDate"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("comments") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.comments"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("consigneeName") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.consigneeName"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("remarksCustomer") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.customerRemarks"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("freightType") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.freightType"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("referenceNumber") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.salesOrder.listing.header.refNumber"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("shippingLabel") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.header.shippingLabel"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                    {!_isColumnToHide("trackShipment") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="customer.shipment.listing.rowValue.trackingCode"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {shipmentList && shipmentList[pageNumber]
                  ? shipmentList[pageNumber].map((rowData, index) => (
                      <tr key={index}>
                        <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>
                        {!_isColumnToHide("shipmentNumber") ? (
                          <td>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                openDeliveryNotesDetails(rowData);
                              }}
                            >
                              {rowData.shipmentNumber||<div className="empty-data"></div>}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("customerName") ? (
                          <td>
                            <div className="cursor-pointer">
                              <div
                                // onClick={() => {
                                //     props.history.push("customers/details", {
                                //         customerId: rowData.customerId,
                                //         customerName: rowData.customerName,
                                //     })
                                onClick={() => {
                                  props.pushModalToStack({
                                    modalBody: (
                                      <CustomerProfile
                                        {...props}
                                        customerId={rowData.customerId}
                                        //   customerName={rowData.customerName}
                                        //   customerDetails={rowData}
                                      />
                                    ),
                                    width: "100%",
                                    hideTitle: true,
                                    hideFooter: true,
                                    wrapClassName: "new-transaction-wrapper",
                                  });
                                }}
                              >
                                {rowData.customerName||<div className="empty-data"></div>}
                              </div>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("qtyShipped") ? (
                          <td>{getQuantityShipped(rowData)||<div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("carrierName") ? (
                          <td>{rowData.carrierName || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("trackingNumber") ? (
                          <td>
                            {rowData.trackingNumber ? <div
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(rowData.trackingUrlEP, "_blocked");
                              }}
                            >
                              {rowData.trackingNumber}
                            </div>
                            :
                            (rowData.shiprocketOrderId ? <div
                              className="cursor-pointer"
                              onClick={() => {
                                props.getTrackingByOrderId({
                                  relationshipId: rowData?.rid,
                                  orderId: rowData?.shiprocketOrderId
                                });
                                openTrackingStatus();
                              }}
                            >
                              {rowData.shiprocketOrderId}
                            </div>
                              :
                            <div className="empty-data"></div>)
                            }
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("trackingNumber") ? (
                          <td>
                            {rowData.shiprocketShipmentStatus||<div className="empty-data"></div>}
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("shipmentDate") ? (
                          <td>
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={
                                  rowData.shipmentDate &&
                                  getMomentDateForUIReadOnly({
                                    date: rowData.shipmentDate,
                                    format:
                                      CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                  })
                                }
                              >
                                {getMomentDateForUIReadOnly({
                                  date: new Date(rowData.shipmentDate),
                                  format,
                                })}
                              </Tooltip>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("expectedDeliveryDate") ? (
                          <td>
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={
                                  rowData.shipmentDate &&
                                  getMomentDateForUIReadOnly({
                                    date: rowData.shipmentDate,
                                    format:
                                      CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                  })
                                }
                              >
                                {getMomentDateForUIReadOnly({
                                  date: new Date(rowData.expectedDeliveryDate),
                                  format,
                                })}
                              </Tooltip>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("userStatus") ? (
                          <td>
                            <div>
                              {rowData.userStatus ? (
                                <div
                                  className="status-code"
                                  style={{
                                    background: rowData.userStatusColor,
                                  }}
                                >
                                  {rowData.userStatus || <div className="empty-data"></div>}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("status") ? (
                          <td>
                            <div>
                              {" "}
                              {
                                rowData.status ?(<div
                                  className={[
                                    "status-code",
                                    (rowData.status || "").toLowerCase(),
                                  ].join(" ")}
                                >
                                  <FormattedMessage
                                    id={rowData.status || "default"}
                                    defaultMessage={rowData.status}
                                  />
                                </div>):(<div className="empty-data"></div>)
                              }
                              {" "}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("customerInquiryNumber") ? (
                          <td>{rowData.customerInquiryNumber || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("customerRFQNumber") ? (
                          <td>{rowData.customerRFQNumber || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("customerPONumber") ? (
                          <td>{rowData.customerPONumber ||<div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("actualDeliveryDate") ? (
                          <td>
                            {rowData.actualDeliveryDate ?
                              getMomentDateForUIReadOnly({
                                date: new Date(rowData.actualDeliveryDate),
                                format,
                              }):(<div className="empty-data"></div>)}
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("comments") ? (
                          <td>{rowData.comments || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("consigneeName") ? (
                          <td>{rowData.consigneeName || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("remarksCustomer") ? (
                          <td>
                            <div>
                              {
                             
                              rowData.remarksCustomer ? (ReactHtmlParser(rowData.remarksCustomer)):(<div className="empty-data"></div>)
                              }
                              
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("freightType") ? (
                          <td>{rowData.freightType || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("referenceNumber") ? (
                          <td>{rowData.referenceNumber || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("shippingLabel") ? (
                          <td>
                            {rowData.labelUrlEP ? (
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  window.open(rowData.labelUrlEP, "_blocked");
                                }}
                              >
                                <FormattedMessage
                                  id="customer.shipment.listing.rowValue.viewLabel"
                                  defaultMessage=""
                                />
                              </div>
                            ) : (
                              <div style={{ color: "red" }}>Not Genrated</div>
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                         {!_isColumnToHide("trackShipment") ? (
                          <td>
                            {rowData.trackingUrlEP ? (
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  window.open(rowData.trackingUrlEP, "_blocked");
                                }}
                              >
                                { rowData.trackingCode ||
                                <FormattedMessage
                                  id="customer.shipment.listing.rowValue.trackShipment"
                                  defaultMessage=""
                                /> }
                              </div>
                            ) : (
                              <div className="empty-data"></div>
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))
                  :  <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                      <Empty />
                  </td>
              </tr>}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={shipmentCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
          </Skeleton>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(ShipmentListingComp);
