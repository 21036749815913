import React, { Fragment } from "react";

import { Button, Divider, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import PageHeader from "../../Common/pageHeader";
import Subscription from "./subscription.js";
import Billing from "./billing.js";
import Payment from "./paymentMethod.js"
import "../style.scss"

const { TabPane } = Tabs;
function onTabChange(key, props) {
    const payload = {
      companyInfo: props.companyInfo,
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: props.pageNumber,
      pageSize: props.pageSize,
    };
  
    switch (key) {
      case "My Subscriptions":
        props.updateState({ activeTab: "My Subscriptions", activeTabKey: "My Subscriptions" });
        break;
      case "Billing History":
        props.updateState({ activeTab: "Billing History", activeTabKey: "Billing History" });
       
        break;
        case "Payment Method":
            props.updateState({ activeTab: "Payment Method", activeTabKey: "Payment Method" });
           
            break;
       
    
      default:
        break;
    }
  }

const SubscriptionComp = (props)=> {
    const breadCrumbList = [
        {
          name: <FormattedMessage id='sidebar.menuItem.dashboard' defaultMessage='' />,
        },
        {
          name: <FormattedMessage id='account.myprofile.breadcrumb.account' defaultMessage='' />,
        }
    ];
    return (
        <Fragment>
         
       
         <div className="view-container">
              <Tabs
                activeKey={props.activeTabKey || "My Subscriptions"} onChange={(key) => { onTabChange(key, props); }}
                type="line"
                animated={false}
              >
                <TabPane tab={<FormattedMessage id='sidebar.menuItem.account.subscription' />} key="My Subscriptions">
                  <Subscription {...props} />
                </TabPane>
                <TabPane tab={<FormattedMessage id='account.company.subscriptions.billing' />} key="Billing History">
                  <Billing  {...props} />
                </TabPane>
                <TabPane tab={<FormattedMessage id='account.company.subscriptions.payment' />} key="Payment Method">
                  <Payment  {...props} />
                </TabPane>
              </Tabs>
            </div>
        </Fragment>
      );
}
export default SubscriptionComp;