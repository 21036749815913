import React from "react";
import { Row, Col, Tooltip, InputNumber, Button } from "antd";
import { TextBox } from "../../../../general/TextBox";
import { FormattedMessage } from "react-intl";
//import { Dropdown } from "../../../../general/Dropdown";
import { showToasterMessage, validateEmail } from "../../../../../utils";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { DropdownRef } from "../../../../general/Dropdown";


const Company = (props) => {

  const {permissionsData} = props;
  // const updateBusinessData = (props) => {
  //   const modalData = {
  //     modalBody: "Are you sure you want to save?",
  //     handleSubmit: () => {
  //       props.updateBusinessData(props);
  //     },
  //   };
  //   props.pushModalToStack(modalData);
  // };

  const updateSubdamain = (props) => {
    if (props.isSubdomainExist) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({ id: "url.not available" }),
      });
    }
    const modalData = {
      modalBody: "Are you sure you want to save?",
      handleSubmit: () => {
        let payload = {
          alpideSubdomainId: props.subdomain.alpideSubdomainId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          subdomainName: props.subdomainName,
        };
        props.saveClientURL(payload);
        props.updateBusinessData(props);
      },
    };
    props.pushModalToStack(modalData);
  };
  const rowGutter = [24, 16];

  return (

    <div className="popup-tabs">

      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage
              id="modal.settings.companyInfo.company.companyName"
              defaultMessage=""
            />
          </div>
          <TextBox
            maxLength={100}
            countStyle={{ top: "40px" }}
            className="textbox-pd"
            placeholder={props.intl.formatMessage(
              { id: "modal.settings.companyInfo.company.organization.placeholder" }
            )}
            value={props.companyInfoData.storeName}
            onChange={(e) => {
              props.updateState({
                companyInfoData: {
                  ...props.companyInfoData,
                  storeName: e.target.value,
                },
              });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-lable">
            <FormattedMessage
              id="modal.settings.companyInfo.company.mobileNumber"
              defaultMessage=""
            />
          </div>
          <div className="d-flex">
            <DropdownRef
              style={{ width: "25%" }}
              items={
                (props.countries || []).filter((el) => {
                  return el.countryCallingCode != null;
                }) || []
              }
              optionKeyName="countryId"
              valueKeyName="countryCallingCode"
              defaultValue={props.countryCallingCode}
              className="currency-tags"
              onSelect={(selectedValue, option) => {
                const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);

                if (selectedCountry) {
                  props.updateState({
                    countryCallingCode: selectedCountry.countryCallingCode,
                    // contact: { ...contact, countryCallingCode: selectedCountry.countryCallingCode },
                  });
                }

              }}
            />
            <TextBox
              value={props.companyInfoData.phoneNumber}
              placeholder={props.intl.formatMessage({
                id: "modal.settings.companyInfo.company.mobileNumber.placeholder",
                defaultMessage: "",
              })}
              type="number"
              maxLength={10}
              style={{ width: "367px" }}
              countStyle={{ top: "38px" }}
              onChange={(e) => {
                props.updateState({
                  companyInfoData: {
                    ...props.companyInfoData,
                    phoneNumber: e.target.value,
                  },
                });
              }}
            />

          </div>
        </Col>

        {/* <Col span={1}>
                        <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                            updateBusinessData(props);
                        }}><button className="save-but" style={{ color: '#107360', 'cursor': 'pointer' }}><i className="fa fa-floppy-o"></i></button> </div>
                    </Col> */}
      </Row>

      {/* {
                    props.companyInfo.countryName === "India"  ? <div>
                        <Row>
                    <Col span={8} className="text-right">
                       <span > GSTIN</span>
                  </Col>
                  <Col span={5}>
                        <Input  value={props.companyInfoData.gstNumber} onChange={(e)=>{
                            props.updateState({
                                companyInfoData : {
                                    ...props.companyInfoData, gstNumber: e.target.value
                                }
                            })
                        }}/>
                    </Col>

                      <Col span={1}>
                        <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                            updateBusinessData(props);
                        }}><button className="save-but" style={{color:'#107360', 'cursor': 'pointer'}}><i className="fa fa-floppy-o"></i></button> </div>
                    </Col>
                </Row>


                <Row>
                    <Col span={8} className="text-right">
                       <span > PAN</span>
                  </Col>
                  <Col span={5}>
                  <Input  value={props.companyInfoData.panNumber} onChange={(e)=>{
                            props.updateState({
                                companyInfoData : {
                                    ...props.companyInfoData, panNumber: e.target.value
                                }
                            })
                        }}/>
                    </Col>

                      <Col span={1}>
                        <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                            updateBusinessData(props);
                        }}><button className="save-but" style={{color:'#107360', 'cursor': 'pointer'}}><i className="fa fa-floppy-o"></i></button> </div>
                    </Col>
                </Row>
                    </div> : <Row>
                    <Col span={8} className="text-right">
                       <span > VAT Number</span>
                  </Col>
                            <Col span={5}>
                                <Input value={props.companyInfoData.vatNumber} onChange={(e) => {
                                    props.updateState({
                                        companyInfoData: {
                                            ...props.companyInfoData, vatNumber: e.target.value
                                        }
                                    })
                                }} />
                            </Col>

                              <Col span={1}>
                        <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                            updateBusinessData(props);
                        }}><button className="save-but" style={{color:'#107360', 'cursor': 'pointer'}}><i className="fa fa-floppy-o"></i></button> </div>
                    </Col>
                </Row>
                } */}
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-lable">
            {" "}
            <FormattedMessage id="email" />{" "}
            <Tooltip
              placement="top"
              title={props.intl.formatMessage({
                id: "company.email.helpMessage",
              })}
              trigger="click"
            >
              {" "}
              <i className="fa fa-question-circle-o pl5 cursor-pointer" />{" "}
            </Tooltip>
          </div>
          <TextBox
            maxLength={50}
            countStyle={{ top: "38px" }}
            className="textbox-pd"
            value={props.companyInfoData.email}
            placeholder={props.intl.formatMessage(
              { id: "modal.settings.companyInfo.company.email.placeholder" }
            )}
            onChange={(e) => {
              props.updateState({
                companyInfoData: {
                  ...props.companyInfoData,
                  email: e.target.value,
                },
              });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-lable">
            <FormattedMessage
              id="modal.settings.companyInfo.company.faxNumber"
              defaultMessage=""
            />{" "}
          </div>
          <TextBox
            value={props.companyInfoData.faxNumber}
            placeholder={props.intl.formatMessage({
              id: "modal.settings.companyInfo.company.faxNumber.placeholder",
              defaultMessage: "",
            })}
            maxLength={15}
            countStyle={{ top: "38px" }}
            onChange={(e) => {
              props.updateState({
                companyInfoData: {
                  ...props.companyInfoData,
                  faxNumber: e.target.value,
                },
              });
            }}
          />
        </Col>

        {/* <Col span={1}>
                        <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                            updateBusinessData(props);
                        }}><button className="save-but" style={{ color: '#107360', 'cursor': 'pointer' }}><i className="fa fa-floppy-o"></i></button> </div>
                    </Col> */}
      </Row>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-lable">
            {" "}
            <FormattedMessage
              id="modal.settings.companyInfo.company.website"
              defaultMessage=""
            />
          </div>
          <TextBox
            maxLength={45}
            countStyle={{ top: "38px" }}
            value={props.companyInfoData.website}
            placeholder={props.intl.formatMessage({
              id: "modal.settings.companyInfo.company.website.placeholder",
              defaultMessage: "",
            })}
            onChange={(e) => {
              props.updateState({
                companyInfoData: {
                  ...props.companyInfoData,
                  website: e.target.value,
                },
              });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-lable">
            {" "}
            <FormattedMessage
              id="modal.settings.companyInfo.company.decimalDigitsLength"
              defaultMessage=""
            />
            <Tooltip
              placement="top"
              title={props.intl.formatMessage({
                id: "modal.settings.companyInfo.company.decimalDigitsLengthPrice.helpMessage",
              })}
              trigger="click"
            >
              <i className="fa fa-question-circle-o pl5 cursor-pointer" />
            </Tooltip>
          </div>
          <InputNumber
            style={{ width: "100%" }}
            min={2}
            max={6}
            type="number"
            defaultValue={2}
            value={props.companyInfoData.decimalDigitsLengthPrice || 0}
            onChange={(value) => {
              props.updateState({
                companyInfoData: {
                  ...props.companyInfoData,
                  decimalDigitsLengthPrice: value,
                },
              });
            }}
          />
        </Col>

        {/*  <Col span={1}>
                        <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                            if(props.companyInfoData.email && !validateEmail(props.companyInfoData.email)){
                                return showToasterMessage({messageType: 'error', description: "Please enter correct email"});
                              }
                              updateBusinessData(props);
                        }}><button className="save-but" style={{ color: '#107360', 'cursor': 'pointer' }}><i className="fa fa-floppy-o"></i></button> </div>
                    </Col> */}
      </Row>

      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-lable">
            {" "}
            <FormattedMessage
              id="modal.settings.companyInfo.company.decimalDigitsLengthQty"
              defaultMessage=""
            />
            <Tooltip
              placement="top"
              title={props.intl.formatMessage({
                id: "modal.settings.companyInfo.company.decimalDigitsLengthQty.helpMessage",
              })}
              trigger="click"
            >
              <i className="fa fa-question-circle-o pl5 cursor-pointer" />
            </Tooltip>
          </div>
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            max={6}
            defaultValue={0}
            type="number"
            value={props.companyInfoData.decimalDigitsLengthQty || 0}
            onChange={(value) => {
              props.updateState({
                companyInfoData: {
                  ...props.companyInfoData,
                  decimalDigitsLengthQty: value,
                },
              });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-lable">
            {" "}
            <FormattedMessage id="fromEmail.text" />
            <Tooltip
              placement="top"
              title={props.intl.formatMessage({ id: "fromEmail.helpMessage" })}
              trigger="click"
            >
              <i className="fa fa-question-circle-o pl5 cursor-pointer" />
            </Tooltip>
          </div>
          <TextBox
            maxLength={50}
            countStyle={{ top: "38px" }}
            value={props.companyInfoData.fromEmail}
            placeholder={props.intl.formatMessage(
              { id: "modal.settings.companyInfo.company.email.placeholder" }
            )}
            onChange={(e) => {
              props.updateState({
                companyInfoData: {
                  ...props.companyInfoData,
                  fromEmail: e.target.value,
                },
              });
            }}
          />
        </Col>

        {/*<Col span={1}>
                        <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                            updateBusinessData(props);
                        }}><button className="save-but" style={{ color: '#107360', 'cursor': 'pointer' }}><i className="fa fa-floppy-o"></i></button> </div>
                    </Col> */}
      </Row>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-lable">
            {" "}
            <FormattedMessage id="company.b2b.url" />

            <Tooltip
              placement="top"
              title={props.intl.formatMessage({
                id: "company.b2b.url.helpMessage",
              })}
              trigger="click"
            >
              <i className="fa fa-question-circle-o pl5 cursor-pointer" />
            </Tooltip>
          </div>
          <TextBox
            maxLength={50}
            countStyle={{ top: "38px" }}
            value={props.subdomainName}
            placeholder={props.intl.formatMessage({
              id: "modal.settings.companyInfo.company.online.placeholder",
              defaultMessage: "",
            })}
            onBlur={() => {
              props.subdomainName
                ? props.checkClientURLExist({ name: props.subdomainName })
                : props.resetCheckClientURLExist();
            }}
            onChange={(e) => {
              props.updateState({ subdomainName: e.target.value });
            }}
          />
          {props.subdomainName ? (
            <div>
              <b>
                {" "}
                https://{props.subdomainName}.
                {window.location.host === "app.alifapp.com"
                  ? "alifapp.com"
                  : "alpide.com"}{" "}
              </b>
            </div>

          ) : (
            ""
          )}
        </Col>
        <Col span={8} className="text-right"></Col>
        <Col span={10}>
          <ErrorMsg
            validator={() => {
              return !props.isSubdomainExist;
            }}
            message={props.intl.formatMessage({ id: "url.not available" })}
          />
        </Col>

        {/* <Col span={1}>
                        <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                            updateBusinessData(props);
                        }}><button className="save-but" style={{ color: '#107360', 'cursor': 'pointer' }}><i className="fa fa-floppy-o"></i></button> </div>
                    </Col> */}
      </Row>

      <div className="popup-btn" style={{ justifyContent: "end" }}>
        {/* <div></div> */}
        <Button className="ant-btn ant-btn-primary"
       disabled={!(permissionsData.update)}

          onClick={() => {
            if (
              props.companyInfoData.email &&
              !validateEmail(props.companyInfoData.email) &&
              props.companyInfoData.fromEmail &&
              !validateEmail(props.companyInfoData.fromEmail)
            ) {
              return showToasterMessage({
                messageType: "error",
                description: "Please enter correct email",
              });
            } else {
              // updateBusinessData(props);
              updateSubdamain(props);
              // props.saveFromEmail(props);
            }
          }}

        >
          Submit
        </Button>
      </div>
    </div>

//       {/* <Col span={1}>
//                         <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
//                             updateBusinessData(props);
//                         }}><button className="save-but" style={{color:'#107360', 'cursor': 'pointer'}}><i className="fa fa-floppy-o"></i></button> </div>
//                     </Col> */}

//   {/* <Col span={1}>
//                         <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
//                             updateBusinessData(props);
//                         }}><button className="save-but" style={{color:'#107360', 'cursor': 'pointer'}}><i className="fa fa-floppy-o"></i></button> </div>
//                     </Col> */}

//   {/* {(props.allPaymentGatewayConfig && props.allPaymentGatewayConfig.length) ?
//                     <Row>
//                         <Col span={8} className="text-right">
//                             <span > <FormattedMessage id='default.paymentGateway' defaultMessage='' /> </span>
//                         </Col>
//                         <Col span={5}>
//                             <Select
//                                 style={{width: '100%'}}
//                                 value={props.defaultPaymentGatewayName}
//                                 optionFilterProp='name'
//                                 onSelect={(selectedId, obj) => {
//                                     props.updateState({
//                                         companyInfoData: {
//                                             ...props.companyInfoData,
//                                             defaultPaymentGatewayId: obj.key,
//                                             defaultPaymentGatewayName: obj.value
//                                         }
//                                     })
//                                 }}
//                                 allowClear={true}
//                                 onClear={() => {
//                                     props.updateState({
//                                         companyInfoData: {
//                                             ...props.companyInfoData,
//                                             defaultPaymentGatewayId: undefined,
//                                             defaultPaymentGatewayName: undefined,
//                                         }
//                                     })
//                                 }}

//                             >
//                                 {(props.allPaymentGatewayConfig || []).map((gatway) => {
//                                     return (
//                                         <Option key={gatway.relationshipConfigId} value={gatway.gatewayName}>
//                                             <FormattedMessage id={gatway.gatewayName} />
//                                         </Option>
//                                     )
//                                 })
//                                 }
//                             </Select>
//                             {/* <Tooltip placement="top" title={props.intl.formatMessage({ id: 'tooltip.onlinePaymnet.help', defaultMessage: ''}) }trigger="click">
//                                             <i className="fa fa-question-circle-o pl5 cursor-pointer" />
//                                         </Tooltip>  


//                         </Col>

//                         <Col span={1}>
//                             <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
//                                 updateBusinessData(props);
//                             }}><button className="save-but" style={{ color: '#107360', 'cursor': 'pointer' }}><i className="fa fa-floppy-o"></i></button> </div>
//                         </Col>
//                     </Row> : ''
// } */}

//   {/*  <Col span={1}>
//                         <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
//                             if(props.companyInfoData.fromEmail && !validateEmail(props.companyInfoData.fromEmail)){
//                                 return showToasterMessage({messageType: 'error', description: "Please enter correct email"});
//                               }
//                               props.saveFromEmail(props);
//                         }}><button className="save-but" style={{ color: '#107360', 'cursor': 'pointer' }}><i className="fa fa-floppy-o"></i></button> </div>
//                     </Col> */}

//   {/* <Col span={1}>
//                         <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
//                             updateSubdamain(props);
//                         }}><button className="save-but" style={{ color: '#107360', 'cursor': 'pointer' }}><i className="fa fa-floppy-o"></i></button> </div>
//                     </Col> */}

//   {/* <div className="">
//         <div></div>
//         <button
//           onClick={() => {
//             if (
//               props.companyInfoData.email &&
//               !validateEmail(props.companyInfoData.email) &&
//               props.companyInfoData.fromEmail &&
//               !validateEmail(props.companyInfoData.fromEmail)
//             ) {
//               return showToasterMessage({
//                 messageType: "error",
//                 description: "Please enter correct email",
//               });
//             } else {
//               updateBusinessData(props);
//               updateSubdamain(props);
//               // props.saveFromEmail(props);
//             }
//           }}
//           className="ant-btn ant-btn-primary up"
//         >
//           <span>Save</span>
//         </button>{" "}
//       </div> */}
 
  );
};

export default Company;
