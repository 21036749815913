import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils';
import { COMMON_ACTIONS, CREDIT_MEMO_ACTION_LIST, LEDGER_TYPE, ACCOUNTING_ENTRY_TYPE, TX_STATUS } from "../../../../static/constants";
import { resetPaginatedData, getCreditMemoCount } from '../Listing/action';
import * as filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchCostCenter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/costCenter/getCostCenter?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CREDIT_MEMO_ACTION_LIST.COST_CENTER_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = CREDIT_MEMO_ACTION_LIST.COST_CENTER_LIST;
        }
      })
  }
}

export const createCreditMemo = (payload, props2) => {
  let FY = getFinancialYear(props2.companyInfo);
  return dispatch => {
    const props = payload.props;
    const creditMemoData = _getCreditMemoData(payload);
    let formData = new FormData();
    if (props.fileList && props.fileList.length) {
      props.fileList.forEach((file) => {
        formData.append('files', file, file.name);
      })
    }
    formData.append('creditMemo', JSON.stringify(creditMemoData, true));

    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
        // TODO need to review and remove
        dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/creditMemo/createCreditMemo`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        props.openCreditMemoDetails(res.data);
        resetPaginatedData()(dispatch);
        showToasterMessage({ messageType: 'success', description: lang['saved.successfully'] });
        getCreditMemoCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        // props.history.push('/admin/credit-memo-list');
        const payload2 = {
          relationshipId:  props2.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 100,
          startDate : FY.fyStartDate ,
          endDate : FY.fyEndDate,
        }
         props.fetchCreditMemos(payload2)
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {

        }
      }).finally(() => {
            // TODO need to review and remove
    dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER_STOP });
        dispatch({
          type: COMMON_ACTIONS.HIDE_SAVE_LOADER,
        });
      })
  }
}

const _getCreditMemoData = (payloadObj) => {
  const props = payloadObj.props;
  const state = payloadObj.state;
  const agGridObj = payloadObj.agGridObj;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const financialYear = getFinancialYear(companyInfo);
  const isPlaceOfSupplySameAsRelationship = payloadObj.isPlaceOfSupplySameAsRelationship;
  const oldPayload = props.update ? props.oldPayload || {} : {};
  const payload = {
    ...oldPayload,
    version: props.update ? props.version : 0,
    creditMemoMasterId: props.update ? props.creditMemoMasterId : 0,
    customerName: props.customer && props.customer.companyName,
    relationshipId: props.companyInfo.relationshipId,
    relationshipName: props.companyInfo.storeName,
    customerId: props.customer && props.customer.customerId,
    creditMemoDate: formatDateForBackend(props.creditMemoDate),
    creditMemoExpDate: formatDateForBackend(props.creditMemoExpDate),
    footer: props.footer,
    remarksInternal: props.remarksInternal,
    remarksCustomer: props.remarksCustomer,
    status: TX_STATUS.ACTIVE,
    creditMemoTotalAmount: Number(state.total),
    subTotal: Number(state.subtotalWithoutDiscount),
    boLocationCreditMemoList: [],
    costCenters: [],
    customerCreditMemoDetailsList: [],
    customerCreditMemoCOATxList: [],
    documentName: props.docName,
    reference: props.reference,
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    currencyCode: companyInfo.currencyCode,
    docDetailList: [],
    isUpdateInventory: props.isUpdateInventory ? 1: 0
  }

  payload.customerCreditMemoCOATxList.push({
    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo.storeName,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    customerId: props.customer && props.customer.customerId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdBy: userInfo.userId,
    amount: Number(state.subtotalWithoutDiscount || 0),
    txDate: formatDateForBackend(new Date()),
    txType: LEDGER_TYPE.TYPE_SALES_RETURNS,
    accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
    ledgerAccountId: props.salesLedgerId,
    customerName: props.customer && props.customer.companyName
  });

  payload.customerCreditMemoCOATxList.push({
    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo.storeName,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    customerId: props.customer && props.customer.customerId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdBy: userInfo.userId,
    amount: Number(state.subtotalWithoutDiscount || 0),
    txDate: formatDateForBackend(new Date()),
    txType: LEDGER_TYPE.TYPE_SALES_RETURNS,
    accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
    ledgerAccountId: props.salesDebtorLedgerId,
    customerName: props.customer && props.customer.companyName
  });

  if (state.rootDiscountAmount) {
    payload.customerCreditMemoCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      customerId: props.customer && props.customer.customerId,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: Number(state.rootDiscountAmount || 0),
      amountPercent: Number(state.rootDiscountPercent || 0),
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_DISCOUNT,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
      ledgerAccountId: props.salesDiscountLedgerId,
      customerName: props.customer && props.customer.companyName
    });

    payload.customerCreditMemoCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      customerId: props.customer && props.customer.customerId,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: Number(state.rootDiscountAmount || 0),
      amountPercent: Number(state.rootDiscountPercent || 0),
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_DISCOUNT,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
      ledgerAccountId: props.discountDebtorLedgerId,
      customerName: props.customer && props.customer.companyName
    });
  }

  if (state.expenseAmount) {
    payload.customerCreditMemoCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      customerId: props.customer && props.customer.customerId,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: Number(state.expenseAmount || 0),
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_EXPENSE,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
      ledgerAccountId: props.expenseLedgerId,
      customerName: props.customer && props.customer.companyName
    });

    payload.customerCreditMemoCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      customerId: props.customer && props.customer.customerId,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: Number(state.expenseAmount || 0),
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_EXPENSE,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
      ledgerAccountId: props.expenseDebtorLedgerId,
      customerName: props.customer && props.customer.companyName
    });
  }

  if (props.billingAddress && props.billingAddress.streetAddress1) {
    if (!props.update) {
      delete props.billingAddress.locationCreditMemoId;
      //props.billingAddress.dateCreated = getCurrentDateForBackend(new Date());
     // props.billingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      props.billingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    payload.boLocationCreditMemoList.push(props.billingAddress);
  } if (props.shippingAddress && props.shippingAddress.streetAddress1) {
    if (!props.update) {
      delete props.shippingAddress.locationCreditMemoId;
     // props.shippingAddress.dateCreated = getCurrentDateForBackend(new Date());
      //props.shippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      props.shippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    payload.boLocationCreditMemoList.push(props.shippingAddress);
  } if (props.relationshipBillingAddress && props.relationshipBillingAddress.streetAddress1) {
    if (!props.update) {
      delete props.relationshipBillingAddress.locationCreditMemoId;
     // props.relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
     // props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    payload.boLocationCreditMemoList.push(props.relationshipBillingAddress);
  }

  agGridObj.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    if (!data.itemId && !data.description && !data.qty && !data.rate) {
      return
    }
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    // if (!itemId) return;
    let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
    if (data.productObj && !data.productObj.hasVariant && !variantId) {
      variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId : data.inventoryItemVariantId;
    }
    let product = {};
    let itemLevelCoaTxList = [];
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }
    let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);

    if (data.discount) {
      const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
      itemLevelAmount = itemLevelAmount - discountOnItem;
      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: Number(discountOnItem || 0),
        amountPercent: Number(data.discount || 0),
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: props.salesDiscountLedgerId,
        customerName: props.customer && props.customer.companyName
      });

      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
       // dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: Number(discountOnItem || 0),
        amountPercent: Number(data.discount || 0),
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        ledgerAccountId: props.discountDebtorLedgerId,
        customerName: props.customer && props.customer.companyName
      })
    }

    if (data.tax) {
      if (companyInfo.countryName !== 'India') {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: data.taxName,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.salesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
         // dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: data.taxName,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.taxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });
      }
      else if (isPlaceOfSupplySameAsRelationship) {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
         // dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: Number((itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) : 0).toFixed(2)),
          amountPercent: Number((data.tax) || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.cgstSalesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: Number((itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) : 0).toFixed(2)),
          amountPercent: Number((data.tax) || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.cgstTaxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });


        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
         // dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: Number((itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) : 0).toFixed(2)),
          amountPercent: Number((data.tax) || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.sgstSalesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
         // dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: Number((itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) : 0).toFixed(2)),
          amountPercent: Number((data.tax) || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.sgstTaxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });

      } else {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
         // dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: Number((itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2)),
          amountPercent: Number(data.tax),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.igstSalesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: Number((itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2)),
          amountPercent: Number(data.tax),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.igstTaxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });
      }
    }

    payload.customerCreditMemoDetailsList.push({
      version: props.update ? data.version : 0,
      relationshipId: companyInfo && companyInfo.relationshipId,
      customerId: props.customer && props.customer.customerId,
      quantity: Number(data.qty),
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      basePrice: Number(data.basePrice),
      specialDiscount: Number(data.specialDiscount),
      txDate: formatDateForBackend(props.salesOrderDate),
      salesOrderDate: formatDateForBackend(props.salesOrderDate),
      discountPercentage: Number(data.discount),
      itemName: product.itemName,
      itemId: product.itemId,
      anItemSalePrice: Number(data.rate),
      description: data.description,
      rowHeight: data.scrollHeight,
      hsnCode: data.hsnCode,
      inventoryItemVariantId: variantId,
      customerCreditMemoCOATxList: itemLevelCoaTxList,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3
    });
  });

  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact }
    // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
    // additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    // additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.customer) {
    additionalInfo.customerId = props.customer && props.customer.customerId;
    // additionalInfo.gstNumber = props.customer.gstNumber;
    // additionalInfo.panNumber = props.customer.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
  }
  if (props.selectedCustomerTaxIdentifications && props.selectedCustomerTaxIdentifications.length) {
    let selectedCustomerTaxIdentifications = filter(props.customer.customerTaxIdentificationsList, (taxObj) => {
      return props.selectedCustomerTaxIdentifications.indexOf(taxObj.customerTaxIdentificationId) > -1;
    });
    additionalInfo.taxIdentifications = JSON.stringify(selectedCustomerTaxIdentifications);
  }
  if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
    let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
      return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
    });
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
  }
  if (!props.update) delete additionalInfo.additionalInfoId;
  payload.additionalInfoList = [additionalInfo];
  return payload;
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: CREDIT_MEMO_ACTION_LIST.NEXT_CREDIT_MEMO_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });

    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/creditMemo/getNextCreditMemoNumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CREDIT_MEMO_ACTION_LIST.NEXT_CREDIT_MEMO_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = CREDIT_MEMO_ACTION_LIST.NEXT_CREDIT_MEMO_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}