import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createCrmLeadCampaign = (payload,props)=>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadCampaign/saveOrUpdateCampaign`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 0,
          pageSize: 25,
        }
        props.fetchCrmLeadCampaign(payload);
      })
      .catch((err = {}) => {
          showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
      })
  }
}




export const createCrmCampaignType = (value, props) => {

    const payload = {
        campaignTypeName:value.text,
        userCreated:"",
        relationshipId:value.relationshipId
    }
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/campaign/saveOrUpdateCampaignType`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Created successfully.',
            messageType: 'success'
          })
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 0,
            pageSize: 25,
          }
          props.fetchCrmCampaignType(payload);
        })
        .catch((err = {}) => {
            showToasterMessage({
                description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
        })
    }
}

export const fetchCrmCampaignType = (payload, ) => {
    return dispatch => {
      return axios
        .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/campaign/getAllCampaignTypes?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: CRM_ACTION_LIST.LEAD_CAMPAIGN_TYPE_LIST, data: res.data });
        })
        .catch(err => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
        })
  }
}

export const fetchCrmActiveSource = (payload, ) => {
    return dispatch => {
      return axios
        .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadSource/getAllActiveLeadSources?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: CRM_ACTION_LIST.LEAD_ACTIVE_SOURCE, data: res.data });
        })
        .catch(err => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
        })
  }
}

export const fetchCrmCampainStatus = (payload, ) => {
    return dispatch => {
      return axios
        .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/campaign/getAllCampaignStatus?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: CRM_ACTION_LIST.LEAD_CAMPAIGN_STATUS, data: res.data });
        })
        .catch(err => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
        })
  }
}


export const createCrmCampainStatus = (value, props) => {
    const payload ={
        campaignStatusName:value.text,
        userCreated:"",
        relationshipId:value.relationshipId

    }
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/campaign/saveOrUpdateCampaignStatus`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Created successfully.',
            messageType: 'success'
          })
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 0,
            pageSize: 25,
          }
          props.fetchCrmCampainStatus(payload);
        })
        .catch((err = {}) => {
            showToasterMessage({
                description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
        })
    }
}


export const deleteCampaignType = (values, props) => {

    const  payload = {campaignTypeId:values.id,relationshipId:values.relationshipId};
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/campaign/deleteCampaignType`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 0,
            pageSize: 25,
          }
          props.fetchCrmCampaignType(payload);
        })
        .catch((err = {}) => {
            showToasterMessage({
                description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
        })
    }
}

export const deleteCampaignStatus = (values, props) => {
    const  payload = {campaignStatusId:values.id,relationshipId:values.relationshipId};

    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/campaign/deleteCampaignStatus`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 0,
            pageSize: 25,
          }
          props.fetchCrmCampainStatus(payload);
        })
        .catch((err = {}) => {
            showToasterMessage({
                description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
        })
    }
}
