import axios from "axios";
import config from "../../../../config/environmentConfig";
import { COMPANY_ACTION_LIST, COMMON_ACTIONS } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const fetchAllEmployeesByPage = (payload, props) => {
    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(
          `${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/relEmployee/getEmployees?relationshipId=${payload.relationshipId}${payload.searchedText ? `&searchedText=${payload.searchedText}` : '' }&pageNumber=${(payload.pageNumber || 1)-1}&pageSize=${payload.pageSize || 25}`
        )
        .then((res) => {
            const data = {
                list: res.data,
                // pageNumber: props?.pageNumber || 1
                pageNo:payload?.pageNumber
            }
            dispatch({ type: COMPANY_ACTION_LIST.EMPLOYEES_LIST, data });
        })
        .catch((err) => {
            showToasterMessage({
                messageType: "error",
                description: lang[((err.response || {}).data || "")] || "Some error occurred get",
            });
        })
        .finally(() => {
          dispatch({
            type: COMMON_ACTIONS.HIDE_LIST_LOADER,
          });
        })
    };
  };

  export const getAllEmployeesCount = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
        return axios 
        // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/relEmployee/countAllEmployees?relationshipId=${payload.relationshipId}&searchedText=${payload.searchedText}`)      
        .get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/relEmployee/countAllEmployees?relationshipId=${payload.relationshipId}&searchedText=${payload.searchedText}`)
        .then(res => {
            dispatch({ type: COMPANY_ACTION_LIST.EMPLOYEE_COUNT, data: res.data });
        })
        .catch((err) => {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred count' })
        })
        .finally(()=>{
            dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
        })
    }
}


export const fetchAllEmployees = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/relEmployee/getAllEmployee?relationshipId=${payload.relationshipId}&searchedText=${payload.searchedText}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.EMPLOYEES_LIST, data: res.data });
      })
      .catch((err) => {
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.EMPLOYEES_LIST;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};

export const deleteEmployee = (payload, props) => {

  return (dispatch) => {
    return axios
      .post(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/relEmployee/deleteEmployee`, payload)
      .then((res) => {
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
        props.fetchAllEmployeesByPage && props.fetchAllEmployeesByPage(payload);
        props.getAllEmployeesCount && props.getAllEmployeesCount(payload);
      })
      .catch((err = {}) => {
        showToasterMessage({
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      })
    }
};