import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, INVENTORY_ACTION_LIST } from '../../../../../static/constants';
import {  showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const deleteWarehouse = (payload) => {
    const requestObj = {
        warehouseMasterId: payload.id,
      relationshipId: payload.relationshipId,
    }
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/warehouseMaster/deleteWarehouse`, requestObj)
        .then(res => {
          dispatch({ type: INVENTORY_ACTION_LIST.WAREHOUSE_LIST_DELETION, data: { id: payload.id } })
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
            err.actionToCall = INVENTORY_ACTION_LIST.WAREHOUSE_LIST_DELETION;
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
          }
        })
    }
  }