import React, { useMemo } from "react";
// import { Link } from 'react-router-dom';
import { Skeleton, Input, Menu,Empty } from 'antd';
import * as debounce from 'lodash.debounce';
import { injectIntl, FormattedMessage } from 'react-intl';
import PageBreadcrumb from "../../PageBreadcrumb";
// import { CustomAgGrid } from "../../general/CustomAgGrid";
import { MODULE_CODED_VALUES, PERMISSION_VALUES, MODAL_TYPE, ICONS } from '../../../static/constants';
import { checkACLPermission, sortTable,getPermissionData } from '../../../utils';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../static/cssClassConstants'
import { MoreOutlined,} from '@ant-design/icons';
import DropdownAction from "antd/lib/dropdown";
import PageHeader from "../../Common/pageHeader";

const { Search } = Input;

// let gridApi = {};

// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

// const onFirstDataRendered = (params) => {
//     params.api.sizeColumnsToFit();
// }
// const getRowHeight = (params) => {
//     return 30;
// }

const TaxComp = (props) => {
    const { permissions, taxes, dir=0, updateState } = props;
    const primaryPerm = (permissions && permissions.primary) || [];

    const permissionsData = useMemo(()=>{
      return getPermissionData(primaryPerm, MODULE_CODED_VALUES.TAXES )
    },[])

    // const getActionPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedAction = {
    //         create: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.TAXES, PERMISSION_VALUES.CREATE),
    //         update: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.TAXES, PERMISSION_VALUES.UPDATE),
    //         delete: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.TAXES, PERMISSION_VALUES.DELETE)
    //     };
    //     Object.keys(permittedAction).forEach((operation) => {
    //         if (permittedAction[operation] === false) {
    //             delete permittedAction[operation];
    //         }
    //     })
    //     return permittedAction;
    // }

    // const permittedAction = getActionPermissionData();
    // const actionMenu = (param, permittedAction) => (
    //     <Menu className="cell-action-dropdown">
    //         {
    //             permittedAction.update && (
    //                 <Menu.Item key="0">
    //                     <i className="fa fa-pencil" />
    //                     <Link>Update Tax</Link>
    //                 </Menu.Item>
    //             )
    //         }
    //         {
    //             permittedAction.delete && (
    //                 <Menu.Item key="1">
    //                     <i className="fa fa-trash" />
    //                     <Link>Delete Tax</Link>
    //                 </Menu.Item>
    //             )
    //         }
    //     </Menu >
    // );

    const handleNewTaxAddition = (props, payload) => {
        const { showModal, companyInfo } = props;
        const formData = payload || {};
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.newTax' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.NEW_TAX,
                data: { formData: formData },

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    // const onGridReady = (params) => {
    //     gridApi = params.api;
    //     params.api.sizeColumnsToFit();
    // }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.txDate',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Tax Name</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "taxName",
    //         editable: true,
    //         resizable: true,
    //         rowDrag: true,
    //         cellEditor: 'customTextEditor',
    //         cellEditorParams: (obj) => {
    //             return {
    //                 onChange: (value) => {
    //                     obj.node.setDataValue(obj.colDef.field, value);
    //                 }
    //             }
    //         }
    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <Tooltip placement="top" title={props.intl.formatMessage(
    //     //             {
    //     //               id: 'tooltip.description',
    //     //               defaultMessage: ''
    //     //             }
    //     //           )}>
    //     //             <div className='table-header-column-container'>
    //     //                 <span className='table-header-column'> Description</span>
    //     //             </div>
    //     //         </Tooltip>;
    //     //     },
    //     //     field: "description",
    //     //     sortable: true
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.rate',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Rate</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "taxPercent",
    //         editable: true,
    //         resizable: true,
    //         cellEditor: 'customNumberEditor',
    //         cellEditorParams: (obj) => {
    //             return {
    //                 maxValue: 100,
    //                 onChange: (value) => {
    //                     obj.node.setDataValue(obj.colDef.field, value);
    //                 }
    //             }
    //         },
    //     cellRendererFramework: (params) => <div> {fixedDecimalNumber(params.data.taxPercent)}</div>,
    //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //     }, 
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <Tooltip placement="top" title={props.intl.formatMessage(
    //     //             {
    //     //               id: 'tooltip.soTax',
    //     //               defaultMessage: ''
    //     //             }
    //     //           )}>
    //     //             <div className='table-header-column-container'>
    //     //                 <span className='table-header-column'> Sales Output Tax</span>
    //     //             </div>
    //     //         </Tooltip>;
    //     //     },
    //     //     field: "salesOutputLedgerAccountId",
    //     //     resizable: true,
    //     // }, {
    //     //     headerComponentFramework: () => {
    //     //         return <Tooltip placement="top" title={props.intl.formatMessage(
    //     //             {
    //     //               id: 'tooltip.poTax',
    //     //               defaultMessage: ''
    //     //             }
    //     //           )}>
    //     //             <div className='table-header-column-container'>
    //     //                 <span className='table-header-column'> Purchase Input Tax</span>
    //     //             </div>
    //     //         </Tooltip>;
    //     //     },
    //     //     field: "purchaseInputLedgerAccountId",
    //     //     resizable: true,
    //     // }
    // ]


    // if (permittedAction.update || permittedAction.delete) {
    //     columnDefs.push({
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.header.action' defaultMessage='' />;
    //         },
    //         field: "action",
    //         // cellRendererFramework: (param) => {
    //         //     return <Dropdown overlay={actionMenu(param, permittedAction)} trigger={['click']}>
    //         //         <Button className="action-btn small">
    //         //             <i className="fa fa-ellipsis-v" />
    //         //         </Button>
    //         //     </Dropdown>
    //         // },
    //         resizable: true,
    //         pinned: 'right',
    //         width: 180,
    //         cellRendererFramework: (params) => <div>
    //                 {(params.data.origination !== 'System') ? <div>
    //                     <div>
    //                         {params.node.data.isToEdit &&
    //                             <div className="table-cell-action">
    //                                 {permittedAction.update && <Link onClick={() => {
    //                                     gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.relationshipId = (props.companyInfo || {}).relationshipId;
    //                                     gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //                                     params.api.stopEditing(true);
    //                                     updateTax(props, gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data);
    //                                     gridApi.refreshCells({ force: true });

    //                                 }} >
    //                                     <i className="fa fa-save" />
    //                                 </Link>}
    //                                 <Link onClick={() => {
    //                                     params.api.stopEditing(true);
    //                                     gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //                                     gridApi.refreshCells({ force: true });
    //                                 }} >
    //                                     <i className="fa fa-ban" />
    //                                 </Link>
    //                             </div>
    //                         }

    //                         {!params.node.data.isToEdit &&
    //                             <div className="table-cell-action" >
    //                                 {permittedAction.update && <Link onClick={() => {
    //                                     // gridApi.forEachNode((rowNode, index) => {
    //                                     //     gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
    //                                     // });
    //                                     // gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
    //                                     // gridApi.refreshCells({ columns: ["action"], force: true });
    //                                     // var startEditingParams = {
    //                                     //     rowIndex: params.node.rowIndex,
    //                                     //     colKey: params.column.getId(),
    //                                     // };
    //                                     // params.api.startEditingCell(startEditingParams);
    //                                     handleNewTaxAddition(props, params.node.data);
    //                                 }}>
    //                                     <i className="fa fa-edit" />
    //                                 </Link>}
    //                                 {permittedAction.delete && <Link onClick={() => {
    //                                     deleteTax(props, gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data);
    //                                 }}>
    //                                     <i className="fa fa-remove" />
    //                                 </Link>}
    //                             </div>}
    //                     </div>
    //                 </div>
    //                     : <div></div>
    //                 }
    //             </div>
    //     });
    // }

    const onSearchedInputChange = debounce((searchedText, props) => {
        // handleSearchedText(searchedText, props)
    }, 2000);

    // const handleSearchedText = (searchedText, props) => {
    //     gridApi.setQuickFilter(searchedText || '');
    // }

    const breadCrumbList = [
        {
            name: 'Finance'
        },
        {
            name: 'Taxes'
        }
    ];
    // const columnDef = [
    //     {
    //         title: 'Tax Name',
    //         dataIndex: 'taxName',
    //         key: 'taxName',
    //     },
    //     {
    //         title: 'Rate',
    //         dataIndex: 'taxPercent',
    //         key: 'taxPercent',
    //     },
    //     {
    //         title: 'Actions',
    //         dataIndex: 'actions',
    //         key: 'actions',
    //         width: '20%',
    //         render: (text, record, index) => (
    //             <div className="table-cell-action">
    //                 <Link onClick={()=>{
    //                     handleNewTaxAddition(props, record)
    //                 }}>
    //                     <i className="fa fa-edit" />
    //                 </Link>
    //                 <Link onClick={()=>{
    //                     deleteTax(props, record)
    //                 }}>
    //                     <i className="fa fa-remove" />
    //                 </Link>
    //             </div>
    //           )
    //     },
    // ]


    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
    
              <Menu.Item key="0" value="title">
                Actions
              </Menu.Item>
    
              <Menu.Divider />
    
             {permissionsData.update && <Menu.Item key="1" value="edit" onClick={()=>{
                    handleNewTaxAddition(props, e);
                }}
              >
                <i className={ICONS.EDIT} />
                Edit
              </Menu.Item>}
    
              {permissionsData.delete && <Menu.Item key="2" onClick={()=>
                {
                    deleteTax(props, e);
                }
              }>
                <i className={ICONS.DELETE} />
                Delete
              </Menu.Item>}
    
            </Menu >
        )
      };

      const sortColumn = (e) => {
        sortTable(document.getElementById('taxes-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
        }


    return (
      <>
      
        <PageHeader {...props}
                pageName="heading.finance.taxes"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={(payload) => {handleNewTaxAddition(props, payload)}}
                moduleCode={MODULE_CODED_VALUES.TAXES}
            />
        <div className="view-container">
        
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="finance.taxes.table.heading"
                    defaultMessage=""
                  />
                </div>
                {/* {pageSize ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{pageSize}</div>
                  </>
                ) : (
                  ""
                )} */}
              </div>
              <div className="right-actions">
                <Search
                  placeholder="Search"
                  value={props.searchedText}
                  onChange={(e) => {
                    props.updateState({ searchedText: e.target.value });
                    onSearchedInputChange(e.target.value, props);
                  }}
                  onSearch={(searchedText) => {
                    props.updateState({ searchedText });
                    onSearchedInputChange(searchedText, props);
                  }}
                />
              </div>
            </div>
            {/* <div className='agGridWrapper'>
                    <div className="ag-theme-balham" style={agGridStyle} >
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={taxes || []}
                            rowDragManaged={true}
                            onGridReady={onGridReady}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            getRowHeight={getRowHeight}
                        />
                    </div>
                </div> */}
            <div className="table-container">
            <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>
              <table id="taxes-table">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="serial.no" />
                    </th>
                    <th onClick={sortColumn}>Tax Name</th>
                    <th onClick={sortColumn}>Rate</th>
                    {permissionsData.update || permissionsData.delete ? <th width="6%">Actions</th>: "" }
                  </tr>
                </thead>
                <tbody>
                  {taxes && (taxes || [])?.length
                    ? taxes.map((rowData, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{rowData.taxName || "-"}</td>
                            <td>{rowData.taxPercent || "-"}</td>
                          {permissionsData.update || permissionsData.delete ?  <td style={{paddingLeft: '50px'}}>
                                <DropdownAction
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                                >
                                <MoreOutlined />
                                </DropdownAction>
                            </td>:""}
                          </tr>
                        );
                      })
                    : <tr key="empty-data-box">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                        <Empty />
                    </td>
                </tr>}
                </tbody>
              </table>
              </Skeleton>
            </div>
            <div className="footer">
              <div className="f-left"></div>
              <div className="f-right"></div>
            </div>
          
        </div>
      </>
    );
};


const deleteTax = (props, record) => {
    props.showModal({
        modalBody: <FormattedMessage id='common.delete.confirmation' defaultMessage='' />,
        handleSubmit: () => {
            const payload = {
                relationshipId: props.companyInfo.relationshipId,
                id: record.taxSingleRateId
            };
            props.deleteTax(payload);
        }
    })
}

// const updateTax = (props, record) => {
//     const payload = {
//         ...record,
//         relationshipId: (props.companyInfo || {}).relationshipId,
//         taxPercent: record.taxPercent,
//         dateCreated: getCurrentDateForBackend(new Date())
//       }

//       if(props.companyInfo && (props.companyInfo || {}).countryName !== 'India'){
//         payload.taxType = 'VAT';
//       }

//       const modalData = {
//         modalBody: 'Are you sure you want to create tax ?',
//         handleSubmit: () => {
//           props.saveSingleTaxRate(payload, props);
//           props.hideModal();
//         }
//       };
//       props.showModal(modalData);

// }

export default injectIntl(TaxComp);