import React, { useState } from 'react'
import {Button,Input} from 'antd';
import {Row ,Collapse,Upload,Card} from 'antd';
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";

import storeDef from "../../../../assets/images/store-def.jpg"
import CKEditor from '../../../general/CustomCKEditor/ckeditor';
import { CUSTOM_CONFIGURATION_FORM ,CUSTOM_CONFIGURATION_FULL_PAGE} from '../../../../static/ckEditorConfigConstants';
import { TextArea } from '../../../general/TextAreaComponent';
import { getAWSFileURL } from '../../../../utils';
const { Panel } = Collapse;
const Enquiry = (props) => {
    const [fileList ,setFileList] = useState([])
    const state = (props.history?.location?.state) ?(props.history?.location?.state):props.pageData;
    const image =state?getAWSFileURL(state.socialSharingImage ,'inventory-u'):null;
    const initialValue = {
      "pageTitle" : state?state.pageTitle:'',
      "pageLink" :  state?state.pageLink:null,
      "pageContent" : state?state.pageContent:"",
      "titleTag" : state?state.titleTag:'',
      "metaDescriptionTag" : state?state.metaDescriptionTag:'',
        "socialSharingImage" : image,
        "relationshipId": props.relationshipId,
        "isPublished":state?state.isPublished:0,
        "isPolicy": state ? state?.isPolicy : 0,
  }
    const [values ,setValues] = useState(initialValue)
   
  
  const uploadData =(val)=>{
    if(val==="published"){
        values.isPublished =1;
    }
    else if(val==="draft"){
        values.isPublished =0;
    }
      const payload ={
        file:fileList[0],
        ecomHeaderPage:values,
        action:"post",
      }
      props.postPagePolicyData(payload,props)
  }
  const updateData =()=>{
      const payload ={
           file:fileList[0]?fileList[0]:null,
        ecomHeaderPage:{...values ,ecomHeaderPageId:state.ecomHeaderPageId,dateUpdated:state.dateUpdated,dateCreated:state.dateCreated},
       
      action:"update",
        
      }
      props.postPagePolicyData(payload)
  }
  const handleInputChange = (field, value) => {
    setValues((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  return (
    <div className='page-container'>
      <div className='main-header' >
      <div className='page-header' style={{top:`${props.pageTitle===("View page"||"Add new page")&&'0px'}`}}>
      <div className="left-itms">
      <p>{props.pageTitle}</p>
           
          
        </div>
        <div className="right-itms">
        <div>
        <Button className="ant-btn-primary" onClick={()=>props.buttonOne==="Update"?updateData():uploadData("published")}>{props.buttonOne}</Button>
                 
               
            <Button className='grey-button' onClick={()=>uploadData("draft")}>{props.buttonTwo}</Button>
            
           </div>
         
        </div>
      </div>
      </div>
    
      <div className='page-body'>
      <div className='page-view-container'>
      <h4>{props.pageTitle}</h4>
      <form>
        <label>Page Title</label>
        <Input type="text" name="text" disabled={props.pageTitle === "View page"?true:false} placeholder='Search Pages' value={values.pageTitle}  onChange={(e) => handleInputChange('pageTitle', e.target.value)}
/>
      {/* <Search placeholder ="Enter page title"/> */}
      </form>
      <div className='page-link'>
      <div>Page link <a href={`${props.storeValues?.websiteUrl}${(values.pageTitle).toLowerCase().replaceAll(" ","-").trim()}`}>{`${props.storeValues?.websiteUrl}${(values.pageTitle).toLowerCase().replaceAll(" ","-").trim()}`}</a> </div>
      <div><Button>Edit</Button></div>
      </div>
      
   </div>
   <div className='page-view-container'>
     <div>  
        <CKEditor data={values.pageContent}
                                                key={`${"emailBody"}`}
                                                // onInit={editor => {
                                                //     this.editor = editor;
                                                // }}
                                                // value={state.pageContent}
                                                disabled={props.pageTitle === "View page"?true:false}
                                                onChange={(event) => {
                                                    const data = event.editor.getData();
                                                    setValues({...values,pageContent:data})
                                                }}
                                                config={{ ...CUSTOM_CONFIGURATION_FULL_PAGE, placeholder: 'New Page Description' }}/>
      </div>
   </div>
   
   <div className='page-view-container'>
   
   <div>
    <div className='panel-data'>
      <label>Title Tag</label>
      <Input type='text' placeholder='Enter title tag' disabled={props.pageTitle === "View page"?true:false} value={values.titleTag} onChange={(e) => handleInputChange('titleTag', e.target.value)}/>
      <a href='#'>Generate Title</a>
    </div>
    <div className='panel-data'>
      <label>Meta Description Tag</label>
    
      <TextArea placeholder="Enter Description" rows="12" disabled={props.pageTitle === "View page"?true:false} value={values.metaDescriptionTag} style={{ width: '100%' }} onChange={(e) => handleInputChange('metaDescriptionTag', e.target.value)}/>
      <a href='#'>Generate Meta Description</a>

    </div>
    <div className='panel-data'>
      <label>Social sharing image preview</label>
      <div className="upload-profile-img">
        <div className='upload'>

        {fileList.length>0||image ? (
                  <Card className="card-img-prev">
                    <img src={image?image:URL.createObjectURL(fileList[0])} alt="Preview"  width='200px'/>
                    <Button className="close-btn-pre" onClick={()=>setFileList([])}>
                      <CloseOutlined />
                    </Button>
                  </Card>
                ) : (
                  <Card
                    className="card-img-prev"
                    cover={<img src={storeDef}  alt="Placeholder" />}
                  />
                )}
                <Upload
                  fileList={fileList}
                  disabled={props.pageTitle === "View page"?true:false}
                  name="file"
                   className='image-upload'
                  // onChange={handleChange}
                  beforeUpload={(file) => {
                    // Allow only a single file upload, you can adjust this as needed
                    setFileList([file]);
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />} >+Add Image</Button>
                </Upload>
        </div>
        <div>
         <h3>{initialValue.pageTitle}</h3>
         <p>{`${props.storeValues?.websiteUrl}${values.pageTitle.toLowerCase().replaceAll(" ","-").trim()}`}</p>
         <p>{initialValue.metaDescriptionTag}</p>
        </div>
                
              </div>
    
    </div>
   </div>
  
      </div>
      </div>
   
      
    </div>
  )
}

export default Enquiry