import React, { Component } from 'react';
import AllLeads from "../../../components/leads/AllLeads";
import { pushModalToStack, popModalFromStack, showModal, hideModal, fetchAllEmployees, fetchProjects } from "../../../actions/commonActions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAllLeadList, getAllLeadListCount, deleteLead, saveOrUpdateNotes, getAllLeadNotes, changeLeadStatus, saveOrUpdateReminder,
   changeCrmLeadEmpAssignedList, getAllLeadReminder, saveOrUpdateLeadStatus, deleteCrmNotes, deleteLeadReminder  } from './action';
import { getAllLeadStatus, getAllLeadSources} from '../../modal/modalBody/leads/createLead/action';
import { fetchCrmLeadFormList } from '../../../containers/leads/LeadForm/action';
import AllLeadsFilter from '../../../components/leads/AllLeads/AllLeadsFilter';
import { saveCallLogs,resetCrmLogsdata } from '../../modal/modalBody/leads/profile/action';
import { checkACLPermission, formatDateForBackendPlain } from '../../../utils';
import { MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../static/constants';

class AllLeadsListComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      pageSize: 25,
      errMsg: false,
      isMyLeads:false
    }

  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    }

    this.props.fetchAllLeadList(payload);
    this.props.getAllLeadListCount(payload);
    this.props.fetchAllEmployees(payload);
    this.props.getAllLeadNotes(payload);
    this.props.getAllLeadStatus(payload);
    this.props.getAllLeadReminder(payload);
    this.props.fetchCrmLeadFormList(payload);
    this.props.fetchProjects(payload);
    this.props.getAllLeadSources(payload);



  }
componentWillReceiveProps(props){
  const { permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = {
    allLead:checkACLPermission(
      primaryPerm,
      MODULE_CODED_VALUES.ALL_LEADS,
      PERMISSION_VALUES.CREATE
    )
    // cn add more like this and handel all the tab
  }
if(
  !permissionsData.allLead
){

  this.setState({isMyLeads:true})
}
  if(Object.keys(props.currCallLogData)?.length && this.state.endTime){
    const payload = {
      ...props.currCallLogData,endTime:formatDateForBackendPlain(this.state.endTime)
    }

    props.saveCallLogs(payload)
    this.setState({
      currCallLogData:{},
      endTime:null
    })
    props.resetCrmLogsdata();

  }
  let allLeadsAssigned = []
  if (this.state.isMyLeads) {
    allLeadsAssigned = props.allLeadList[props.pageNumber || 1]?.filter((obj) =>
      obj.crmLeadEmpAssignedList
        .map((obj) => obj.relEmpId)
        .includes(props.userInfo?.relationshipEmployeeId)
    );
  } else {
    allLeadsAssigned = props.allLeadList[props.pageNumber || 1];
  }
  this.setState({ allLeadsAssigned: allLeadsAssigned })
}

  render() {
    return <>
      <AllLeads  {...this.state} {...this.props}  updateState={(data) => { this.setState(data) }} updateStateLayer={(data) => { this.setState(data) }}/>
      {this.state?.allLeadsFilterDrawerVisible && <AllLeadsFilter  {...this.props} {...this.state} updateState={(data) => { this.setState(data) }}  />}
    </> 
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    allLeadList: state.crm.allLeadList,
    allLeadListCount: state.crm.allLeadListCount,
    leadAssignList: state.common.employees,
    allLeadNotesList: state.crm.crmLeadNotesList,
    allLeadReminderList: state.crm.crmLeadReminderList,
    crmLeadStatusList: state.crm.crmLeadStatusList,
    crmLeadFormList: state.crm.crmLeadFormList,
    projectList: state.common.projectList,
    crmLeadSourceList: state.crm.crmLeadSourceList,
    currCallLogData:state.crm.currCallLogData,
    permissions: state.auth.permissions,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading

    
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  fetchAllLeadList,
  getAllLeadListCount,
  deleteLead,
  fetchAllEmployees,
  saveOrUpdateNotes,
  getAllLeadNotes,
  getAllLeadStatus, 
  changeLeadStatus,
  saveOrUpdateReminder,
  changeCrmLeadEmpAssignedList,
  getAllLeadReminder,
  saveOrUpdateLeadStatus,
  fetchCrmLeadFormList,
  deleteCrmNotes,
  deleteLeadReminder,
  fetchProjects,
  getAllLeadSources,saveCallLogs,resetCrmLogsdata
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllLeadsListComp);