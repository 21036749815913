import React from "react";

import { Button, Col, Divider, Row, Skeleton, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol, getMomentDateForUIReadOnly } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";

import { CONSTANTS } from "../../../static/constants";
const {TabPane} = Tabs;



function Billing(props) {
 
    const {billingHistory=[]} = props;
   
    const values="";
    return (
        <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   
    
    <div>
    <div className="account-heading">
    <p className="heading">Billing History</p>
    <p className="sub-heading">
    Access a detailed record of all past invoices, payments, and billing adjustments for your account.   </p>

    </div>
    <div className="account-information">
    
    <Skeleton loading={props.listLoading} paragraph={{ rows: 7 }} active>
                <div className='table-container'>
                    <table id='coupons-table'>
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage id="serial.no" />
                            </th>
                            <th >
                                <FormattedMessage id="sidebar.menuItem.products" defaultMessage="" />
                            </th>
                            
                            <th>
                                <FormattedMessage id="accounting.dayBook.referenceNumber" defaultMessage="" />
                            </th>
                            <th>Due Amount</th>
                            <th>
                                Paid Amount
                            </th>
                           
                            
                            <th>Status</th>
                            <th>
                                <FormattedMessage id="company.attendanceManagement.date" defaultMessage="" />
                            </th>
                           <th>SubTotal</th>
                            <th>
                                <FormattedMessage id="customer.salesQuote.listing.header.amount" defaultMessage="" />
                            </th>
                            <th>
                            <FormattedMessage id="accounts.subscription.billing.invoice" defaultMessage="" />
                            </th>
                        </tr>
                    </thead>
                        <tbody>
                            {
                             (billingHistory||[])?.length ?  (billingHistory||[])?.map((item ,i)=>{
                                    return(
<tr>
                            <td>{i+1}</td>
                            <td>{"CLOUD ERP"}</td>
                            <td>Reff</td>
                           
                            
                            
                            <td>{item?.amount_due ? getCurrencySymbol(item?.currency_code) : ''}{item?.amount_due}</td>
                            <td>{item?.amount_paid ? getCurrencySymbol(item?.currency_code) : ''}{item?.amount_paid}</td>
                            <td>{item?.status}</td>
                            <td>{ getMomentDateForUIReadOnly({ date: item?.paid_at, format: CONSTANTS.DISPLAY_DATE_FORMAT })}</td>
                            <td>{item?.sub_total ? getCurrencySymbol(item?.currency_code) : ''}{item?.sub_total}</td>
                            <td>{item?.total ? getCurrencySymbol(item?.currency_code) : ''}{item?.total}</td>
                            <td><Button type="primary" onClick={()=>{
                                props.downloadBillingInvoice({invoiceId: Number(item?.id)})
                            }}>Download Invoice</Button></td>
                           </tr>
                                    )
                                }):""
                            }
                           
                        </tbody>
                    </table>
                </div>
            </Skeleton>


    </div>
   
    </div>
   </div>
   

   
   


</div>
        </div>
        
    )
}
export default Billing;