import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DepartmentComp from "../../../../components/drawer/schoolManagement/department";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { saveDepartment } from './action';
import { getDepartmentList, getDepartmentCount } from "../../../schoolManagement/department/action";

class DepartmentDrawer extends Component {
  constructor(props) {
    super(props);
    let deptData = props.deptData || {};
    this.state = {
      deptName: deptData.deptName,
      deptCode: deptData.deptCode,
      deptId: deptData.deptId,
      version: deptData.version,
    };
  }

  render() {
    return (
      <DepartmentComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    allEmployee: state.common.employees,
    saveLoading: state.common.saveLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushModalToStack,
      popModalFromStack,
      saveDepartment,
      getDepartmentList, 
      getDepartmentCount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentDrawer);
