import React, { useState } from "react";
import { Modal, Upload, Button, Input, message, Divider } from "antd";
import { Dropdown } from "../../general/Dropdown";
import { TextBox } from "../../general/TextBox";
import CategorySingleSelectComp from "./categorySingleSelectPopUp";
import { FormattedMessage } from "react-intl";

const UploadImageModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const {
    onSave,
    takeLink,
    allPageListData,
    products,
    productCategories,
    selectedType,
    takeDescription,
    takeTitel,
  } = props;

  const handleFileChange = ({ file }) => {
    if (!file) return;
    setFile(file);
  };

  const resetState = () => {
    setFile(null);
    setLink("");
    setTitle("");
    setDescription("");
  };

  const handleSave = () => {
    if (!file) {
      message.warning("Please upload an image before saving.");
      return;
    }
    onSave({ file, link, description, title });
    setModalOpen(false);
    resetState();
  };

  const handleCancel = () => {
    setModalOpen(false);
    resetState();
  };

  const handleParentCategory = () => {
    props.pushModalToStack({
      modalBody: (
        <CategorySingleSelectComp
          {...props}
          callBack={(data) => {
            setLink(data);
          }}
        />
      ),
      title: (
        <FormattedMessage
          id="drawer.inventory.select.category.placeholder"
          defaultMessage=""
        />
      ),
      width: "40%",
      hideTitle: false,
      hideFooter: true,
      wrapClassName: "cat-modal-custom",
    });
  };

  const renderLinkSection = () => (
    <div style={{ marginTop: 16 }}>
      <label style={{ fontWeight: "bold", display: "block", marginBottom: 8 }}>
        Link Banner To (Optional)
      </label>
      <Dropdown
        items={["Pages", "Product", "Category", "Link"]}
        value={selectedType || ""}
        onSelect={(selectedValue) => {
          setLink("");
          props.updateModalState({ selectedType: selectedValue });
        }}
      />
      <div style={{ marginTop: 16 }}>
        {selectedType === "Pages" && (
          <Dropdown
            items={allPageListData || []}
            valueKeyName="pageTitle"
            optionKeyName="ecomHeaderPageId"
            value={link?.pageTitle || ""}
            onSelect={(selectedValue) => {
              const val = allPageListData.find(
                (i) => i.ecomHeaderPageId === selectedValue
              );
              setLink(val);
            }}
          />
        )}
        {selectedType === "Product" && (
          <Dropdown
            items={products || []}
            valueKeyName="itemName"
            optionKeyName="itemId"
            value={link?.itemName || ""}
            onSelect={(selectedValue) => {
              const val = products.find((i) => i.itemId === selectedValue);
              setLink(val);
            }}
          />
        )}
        {selectedType === "Category" && (
          <div onClick={handleParentCategory} style={{ marginTop: 8 }}>
            <TextBox
              value={link?.categoryName || ""}
              placeholder="Select Category"
            />
          </div>
        )}
        {selectedType === "Link" && (
          <Input
            placeholder="Enter Link"
            value={link || ""}
            onChange={(e) => setLink(e.target.value)}
            style={{ marginTop: 8 }}
          />
        )}
      </div>
    </div>
  );

  const renderInputSection = (label, placeholder, value, onChange) => (
    <div style={{ marginTop: 16 }}>
      <label style={{ fontWeight: "bold", display: "block", marginBottom: 8 }}>
        {label}
      </label>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={{ width: "100%" }}
      />
    </div>
  );

  return (
    <div>
      <Button type="primary" onClick={() => setModalOpen(true)}>
        Upload Image
      </Button>

      <Modal
        visible={modalOpen}
        title="Upload Image"
        width={500}
        centered
        onOk={handleSave}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <div className="modal-banner" style={{ textAlign: "center" }}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            beforeUpload={(file) => {
              handleFileChange({ file });
              return false; // Prevent automatic upload
            }}
          >
            {file ? (
              <img
                src={URL.createObjectURL(file)}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              <div>
                <Button>Upload Image</Button>
                <div style={{ fontSize: "12px", marginTop: 8 }}>
                  Recommended size (360px * 324px)
                </div>
              </div>
            )}
          </Upload>
        </div>

        <Divider style={{ margin: "16px 0" }} />

        {takeLink && renderLinkSection()}
        {takeTitel &&
          renderInputSection("Title", "Enter Title", title, (e) =>
            setTitle(e.target.value)
          )}
        {takeDescription &&
          renderInputSection("Description", "Enter Description", description, (e) =>
            setDescription(e.target.value)
          )}
      </Modal>
    </div>
  );
};

export default UploadImageModal;
