import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CourseTermComp from "../../../../components/drawer/schoolManagement/courseTerm";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { saveCourseTerm } from '../../../schoolManagement/courseTerm/action';

class CourseTermDrawer extends Component {
  constructor(props) {
    super(props);
    let termData = props.termData || {};
    this.state = {
      termName: termData.termName,
      termCode: termData.termCode,
      isCertificateAwarded: termData.isCertificateAwarded,
      certificateName: termData.certificateName,
      certificateMessage: termData.certificateMessage,
      courseTermId: termData.courseTermId,
      version: termData.version,
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <CourseTermComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    saveLoading: state.common.saveLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveCourseTerm,
      pushModalToStack,
      popModalFromStack,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CourseTermDrawer);
