import React, { useMemo } from 'react';

import '@ant-design/compatible/assets/index.css';
import { injectIntl ,FormattedMessage} from 'react-intl';
import { Button} from 'antd';
import { Dropdown } from '../../../../general/Dropdown';
import { CONSTANTS, MODULE_CODED_VALUES } from '../../../../../static/constants';
import LoadingIcon from '../../../../../assets/images/loading.gif';
import { DetailDropdown } from '../../../../general/DetailDropdown';
import { getPermissionData } from '../../../../../utils';

const HeaderAction = (props) => {
    const {
        intl,
        customers,
        pageNumber,
        customerName,
        permissions
    } = props;
    const primaryPerm = (permissions && permissions.primary) || [];
  
    const customerPermissions = useMemo(()=>{
      return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CUSTOMERS);
    },[])
    const getFormatedNextTxNumber = (props) => {
        return <span>{props.salesEnquiryNumber}</span>  
    }
    return (
      <>
        <div className="left-itms">
          <ul>
            <li className="title">
              <FormattedMessage id="heading.sales.salesInquiry" />
            </li>
            <li className="sep" />
            <li>
              <FormattedMessage
                id="customer.salesOrder.form.Inquiry.label"
                defaultMessage=""
              />{" "}
              &nbsp; - &nbsp;
              {props.salesEnquiryNumber ? (
                
                <span>{getFormatedNextTxNumber(props)}</span>
              ) : (
                <img
                  src={LoadingIcon}
                  alt="Invoice"
                  style={{
                    height: "74px",
                    "margin-top": "-25px",
                    "margin-bottom": "-25px",
                  }}
                />
              )}
            </li>
          </ul>
        </div>
        <div className="right-itms">
          {!props.isRFQFromPr ? (
            <DetailDropdown
              items={(customers[pageNumber] || []).sort((a, b) => (a.companyName||'').localeCompare(b.companyName||''))}
              valueKeyName="companyName"
              style={{ width: "200px" }}
              optionKeyName="customerId"
              value={customerName}
              moduleName={MODULE_CODED_VALUES.CUSTOMERS}
              canAddNew={true}
              canDelete={true}
              deleteClickHandler={(payload) => {
                props.deleteCustomers({
                  groupCustomerIds: [payload.id],
                  relationshipId: (props.companyInfo || {}).relationshipId,
                });
              }}
              onAddNewClick={(payload) => {
                props.updateHeaderState({
                  customer: {
                    customerDrawerVisible: true,
                    customerName: payload.textEntered,
                  },
                });
              }}
              placeholder={intl.formatMessage({
                id: "supplier.rfq.form.customer.placeholder",
                defaultMessage: "",
              })}
              onSearch={(searchedText) => {
                props.fetchCustomers({
                  searchedText: searchedText,
                  pageNumber: 1,
                  hideSpinner: true,
                  pageSize: 100,
                  companyInfo:(props || {}).companyInfo,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                });
              }}
              onSelect={(customerId) => {
                props.resetCustomerDetail();
                props.resetAllContacts();
                props.fetchCustomerDetailsByCustomerId({
                  customerId,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                  stopLoder:true
                });
                props.fetchAddresses({
                  customerId,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                  locationType: CONSTANTS.SHIPPING_ADDRESS,
                });
                props.fetchAddresses({
                  customerId,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                  locationType: CONSTANTS.BILLING_ADDRESS,
                });
                props.fetchAllContacts({
                  customerId,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                });
                props.updateState({
                  selectedCustomerTaxIdentifications:[],
                  customer:{},
                  boContactList:[]
                });
              }}
            />
          ) : (
            ""
          )}
          <Button onClick={() => {
                props.updateDrawerState({
                    settingSalesDrawerVisible: true
                })
            }} >
            <i class="fa fa-cog" aria-hidden="true"></i>
            </Button>
          {/* <Button  onClick={() => {
                    props.updateState({
                        ledgerDrawerVisible: true
                    })
                }} >
                <i className='fa fa-book fa-lg'> </i>
                </Button> */}

          {/* <div className="currency-converter-modal">
                    <span className="ant-btn ant-btn-icon-only icon" onClick={() => setShowCurrencyPopup(!showCurrencyPopup)}>
                        <i className="fa fa-exchange" />
                    </span>

                    {showCurrencyPopup &&
                        <div className="content">
                        <Row className="mb10">
                            <Col span={12} className="text-right">
                                Currency
                            </Col>

                            <Col span={12}>
                                <div className="form-icon-group">
                                    <i className="fa fa-dollar"/>
                                    <Dropdown
                                        style={{width: '100%'}}
                                        valueKeyName='dateRange'
                                        optionKeyName='dateRangeId'
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12} className="text-right">
                                Exchange Rate
                            </Col>

                            <Col span={12}>
                                <div className="form-icon-group">
                                    <i className="fa fa-rupee"/>
                                    <Input />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    }
                </div> */}
        </div>
      </>
    );
};

export default injectIntl(HeaderAction);
