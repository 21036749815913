import axios from 'axios';
import reduxStore from './store';
import config from './config/environmentConfig';
import { fetchFromLocalStorage, addToLocalStorage, deleteFromLocalStorage } from  './utils';
import { LOCAL_STORAGE_KEYS, COMMON_ACTIONS,  } from  './static/constants';
let callsQueue = []; 
let numberOfAjaxCAllPending= 0;

export const axiosInterceptorConfig = (history) =>{
const { dispatch } = reduxStore;
  /**
   * intercept request
   */
  axios.interceptors.request.use( async(config) => {
    //let serverCallUrl = new URL(config.url);
    
    numberOfAjaxCAllPending++;
   
     const accessToken = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
     const relationshipData = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) && JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA))
    
    if(((relationshipData || {}).defaultCompany || {}).isCompanySetupRequired && accessToken){
      addToLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_BACKUP, accessToken);
      deleteFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      let baseUrl = "";
      history.push(baseUrl+'/app/company-setup');
      window.location.reload();
    }
    // if(accessToken){
    //   config.headers['Authorization'] = 'Bearer '+ accessToken;
    // }
     return config;

  }, function (error) {
    return Promise.reject(error)
  });


  const getRefreshToken = () =>{
    return axios({
      method: 'POST',
      url:  `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/user/getRefreshToken?refreshToken=${fetchFromLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)}`,
      headers: {}
  });
  }

  let isRefreshingToken = false;

  /**
   * intercepts response
   */
  axios.interceptors.response.use((response)=>{
    numberOfAjaxCAllPending--;
    
    if(numberOfAjaxCAllPending === 0){
      dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
    }
    return response;
  }, function (err) {
    numberOfAjaxCAllPending--;
    if(numberOfAjaxCAllPending === 0){
      dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
    }
  return new Promise(function (resolve, reject) {
      if (err.response === undefined && err.config && !err.__isRetryRequest) {
          err.__isRetryRequest = true;
          callsQueue.push(err);
          if(!isRefreshingToken){
            isRefreshingToken=true;
            getRefreshToken().then((res) => {
              isRefreshingToken = false;
              if(res.data.response){
              let tokenInfo = JSON.parse(res.data.response);
              addToLocalStorage(LOCAL_STORAGE_KEYS.EXPIRE_TIME, tokenInfo.expires_in);
              addToLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, tokenInfo.access_token);
              addToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, tokenInfo.refresh_token);
              // callsQueue.forEach((errObj, index) => {
              //   errObj.__isRetryRequest = false;
              //   axios(errObj.config)
              //   .then((res) =>{
              //         if(errObj.actionToCall){
              //           let data = res.data;
              //           if(errObj.data){
              //             data = errObj.data;
              //             data.list = res.data;
              //           }
              //           dispatch({ type:  errObj.actionToCall, data: data});
              //         }
              //         //empty callsqueue
              //         if(index === callsQueue.length-1) {
              //           callsQueue = [];
              //         }
              //     }, reject);
              // });
              }else{
                // clearLocalStorage();
                // history.push('/');
                // window.location.reload();
                reject(new Error());   
              }
            }).catch((e) => {
              // clearLocalStorage();
              // history.push('/');
              // window.location.reload();
              reject(e); 
            })
          }
      }
      throw err;
  });
});

}