import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';

import { CONSTANTS } from '../../../static/constants';
import { fetchAllSubscriptions , fetchPackagePlanbyCostId ,fetchBillingHistory, cancelSubscribedPlan, downloadBillingInvoice, fetchCustomerPaymentAddressInfo} from './action';
import { fetchDataIfNeeded } from "../../../utils";
import SubscriptionComp from '../../../components/Account/Subscription';
import { getAlpideProducts } from '../../PricingSetup/action';
import { getSubscribedProduct } from '../../B2B/PlanPricing/action';
class MySubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      activeTabKey:"My Subscriptions",
      planKey:"Personal", 
    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo||{};
    const payload={
      relationshipId: companyInfo.relationshipId,
      planCode: Number(companyInfo?.planCode)
    }
    this.props.getAlpideProducts();
    this.props.getSubscribedProduct(payload);
    this.props.fetchAllSubscriptions(payload);
    this.props.fetchPackagePlanbyCostId(payload)
    this.props.fetchBillingHistory(payload)
    this.props.fetchCustomerPaymentAddressInfo(payload);
  }

  componentWillReceiveProps(props){
  }

  render() {
    return (
      <SubscriptionComp  {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    );
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    alpideProducts: state.common.alpideProducts,
    listLoading: state.common.listLoading,
    subscribedProduct: state.common.subscribedProduct,
    subscribedPlans: state.profile.allSubscriptions,
    packagePlan: state.profile.packagePlan,
    billingHistory: state.profile.billingHistory,
    downloadInvoice: state.profile.downloadInvoice,
    paymentAddressInfo: state.profile.paymentAddressData,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  getSubscribedProduct,
  getAlpideProducts,
  fetchAllSubscriptions,
  fetchPackagePlanbyCostId,
  fetchBillingHistory,
  cancelSubscribedPlan,
  downloadBillingInvoice,
  fetchCustomerPaymentAddressInfo
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MySubscription);
