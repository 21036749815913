import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../../utils";
import { COMMON_ACTIONS } from "../../../../../static/constants";


export const loginSettingSave = (payload,props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_setting/saveLoginSetting`,
        payload
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "Login Setting save Succssfully.",
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      props.fetchE_StoreData(payload)
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};
