import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_ACTION_LIST, CONSTANTS } from '../../../../static/constants';
import { getPaths, showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAddresses = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/boLocationRest/getAddressByCustomerIdAndLocationTpe?relationshipId=${payload.relationshipId}&locationType=${payload.locationType}&customerId=${payload.customerId}`)
      .then(res => {
        if (payload.locationType === CONSTANTS.BILLING_ADDRESS) {
          dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES, data: res.data });
        } else {
          dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES, data: res.data });
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          if (payload.locationType === CONSTANTS.BILLING_ADDRESS) {
            err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES;
          } else {
            err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES;
          }
        }
      })
  }
}

export const deleteAddress = (payload) => {
  const path = getPaths(payload);

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${path.API_BASE_URL}${config.API_VERSION}/${path.rootContext}/boLocationRest/deleteAddress`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Address deleted successfully' });
        axios
          .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/boLocationRest/getAddressByCustomerIdAndLocationTpe?relationshipId=${payload.relationshipId}&locationType=${payload.locationType}&customerId=${payload.customerId}`)
          .then(res => {
            if (payload.locationType === CONSTANTS.BILLING_ADDRESS) {
              dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES, data: res.data });
            } else {
              dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES, data: res.data });
            }
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data)] || 'Some error occurred' })
        } else {
          axios
            .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/boLocationRest/getAddressByCustomerIdAndLocationTpe?relationshipId=${payload.relationshipId}&locationType=${payload.locationType}&customerId=${payload.customerId}`)
            .then(res => {
              if (payload.locationType === CONSTANTS.BILLING_ADDRESS) {
                dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES, data: res.data });
              } else {
                dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES, data: res.data });
              }
            });
        }
      })
  }
}

export const createUpdateAddress = (payload, props) => {
  const paths = getPaths(payload);

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${paths.API_BASE_URL}${config.API_VERSION}/${paths.rootContext}/boLocationRest/createAddress`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Address saved successfully' });
        props.getRelationshipAddress({relationshipId: (res.data.relationshipId || (props.companyInfo || {}).relationshipId)});       
        axios
          .get(`${paths.API_BASE_URL}${config.API_VERSION}/${paths.rootContext}/boLocationRest/getAddressByCustomerIdAndLocationTpe?relationshipId=${payload.relationshipId}&locationType=${payload.locationType}&customerId=${payload.customerId}`)
          .then(res => {
            if (payload.locationType === CONSTANTS.BILLING_ADDRESS) {
              dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES, data: res.data });
            } else {
              dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES, data: res.data });
            }
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          axios
            .get(`${paths.API_BASE_URL}${config.API_VERSION}/${paths.rootContext}/boLocationRest/getAddressByCustomerIdAndLocationTpe?relationshipId=${payload.relationshipId}&locationType=${payload.locationType}&customerId=${payload.customerId}`)
            .then(res => {
              if (payload.locationType === CONSTANTS.BILLING_ADDRESS) {
                dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES, data: res.data });
              } else {
                dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES, data: res.data });
              }
            });
        }
      })
  }
}

export const addAddressRow = (locationType, key) => {
  return dispatch => {
    if (locationType === CONSTANTS.BILLING_ADDRESS) {
      dispatch({ type: CUSTOMER_ACTION_LIST.ADD_BILLING_ADDRESS, data: key });
    } else {
      dispatch({ type: CUSTOMER_ACTION_LIST.ADD_SHIPPING_ADDRESS, data: key });
    }
  }
}