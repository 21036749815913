import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddCountryComponent from "../../../../../../components/modal/modalBody/settings/taxes/AddCountry";
//import { getAllCountries, saveMarketplaceTaxCountry, getCountryState, saveUpdateMarketplaceStateTax } from "./action"
import { fetchDataIfNeeded } from "../../../../../../utils";
import { getAllCountries } from '../../../../../settings/taxes/action';
import { fetchCountries } from '../../../../../../actions/commonActions';

class AddCountry extends Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        const payload = { relationshipId: (this.props.companyInfo || {}).relationshipId };
        // fetchDataIfNeeded("getAllCountries", "countries", this.props, payload);
        this.props.fetchCountries();
    }

    render() {
        return (
            <AddCountryComponent {...this.state} {...this.props} updateState={(data) => {
                this.setState(data);
            }} />
        )
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        // countries: state.taxSettingReducer.countries,
        countries: state.common.countries,
        countryStates: state.taxSettingReducer.countryStates,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getAllCountries,
    fetchCountries
    // saveMarketplaceTaxCountry,
    // getCountryState,
    // saveUpdateMarketplaceStateTax
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddCountry);
