import React from 'react';
import './../../../../assets/stylesheets/common/invoice.scss';
// import PageBreadcrumb from "../../../PageBreadcrumb";
import HeaderAction from './Header';
import EnquiryDetails from "./EnquiryDetails";
import ProductDetails from "./ProductDetails";
// import { FormattedMessage } from 'react-intl';
import { Divider } from 'antd';

const SalesEnquiryComponent = (props) => {

  // const breadCrumbList = [
  //   {
  //     name: <FormattedMessage id='common.sales' defaultMessage='' />,
  // }, 
  // {
  //     name:  <FormattedMessage id='breadcrum.inquiry.text' defaultMessage='' />,
  // }
  // ];

  return (<>
  
   
      <div className="txn-header">
        <HeaderAction {...props} />
      </div>
<div className='txn-body'>

      <div className="txn-details-container">
        <EnquiryDetails {...props} />
      </div>
      <Divider style={{margin: '0 0 10px 0px'}}/>
      <div className="product-details-container">
        <ProductDetails {...props} />
      </div>
</div>
 
  </>
  );
}


export default SalesEnquiryComponent;