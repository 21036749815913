import React, { Fragment, useMemo } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Skeleton, Tooltip, Popover, Switch, Pagination} from 'antd';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, getPermissionData, sortTable } from '../../../../utils';
import { CONSTANTS, CONSTANTS_TRANSACTIONS, MODAL_TYPE, MODULE_CODED_VALUES,ICONS } from "../../../../static/constants";
import InquiryDetail from '../../../../containers/modal/modalBody/customer/InquiryDetail';

const DeadInquiry = (props) => {
  const {
    enquiryList,
    updateState,
    companyInfo,
    inquiryDraftCount,
    pageNumber,
    pageSize,
    dir = 0,
    enquiryCount,
    permissions
  } = props;

  const primaryPerm = (permissions && permissions.primary) || [];
  const salesInquiryPermissions = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SALES_INQUIRY);
  },[])
  const _isColumnToHide = (columnName) => {
    return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
}
const sortColumn = (e) => {
  sortTable(document.getElementById('inquiry-table'), e.target.cellIndex, 1 - dir);
  updateState({ dir: 1 - dir })
}
const openInquiryDetails = (data) => {
  const inquiryPayload = {
      customerId: data.customerId,
      inquiryMasterId: data.inquiryMasterId,
  }
  props.pushModalToStack({
      modalBody: <InquiryDetail {...props} inquiryPayload={inquiryPayload} />,
      width: '100%',
      hideFooter: true,
      hideTitle:true,
      wrapClassName: 'modal-custom-detail'
  })
}
const updateUserStatus = (props, txData) => {
  let payload = { formData: {} };
  const formData = payload.formData;
  payload.txData = txData;
  const { pushModalToStack } = props;

  if (
    formData.textBoxValue &&
    formData.textBoxValue.length &&
    !payload.existsInData
  ) {
    updateUserStatusConfirmationModal(props, formData.textBoxValue, txData);
  } else {
    const data = {
      title: (
        <FormattedMessage id="addItem.text.userStatus" defaultMessage="" />
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.USER_STATUS,
        data: payload,
        transactionName: CONSTANTS_TRANSACTIONS.INQUIRY,
      },
     
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          updateUserStatusConfirmationModal(
            props,
            formData.textBoxValue,
            formData.statusColor,
            txData
          );
        } else {
          data.formData.submittedOnce = true;
          pushModalToStack(data);
        }
      },
    };
    pushModalToStack(data);
  }
};
const updateUserStatusConfirmationModal = (props, text, color, txData) => {
  const { showModal, companyInfo } = props;
 
  const modalBody = (
    <span>
      <FormattedMessage
        id="setItem.confirmation"
        defaultMessage={``}
      />
      
    </span>
  );

  let payload = {
    userStatus: text,
    userStatusColor: color,
    relationshipId: companyInfo.relationshipId,
    inquiryMasterId: txData.inquiryMasterId,
    customerId: txData.customerId,
  };

  const modalData = {
    modalBody,
    handleSubmit: () => {
      props.updateUserStatus(payload, props);
      props.hideModal();
      props.popModalFromStack();
    },
  };
  showModal(modalData);
};
const getQuantityOnPR = (data) => {
  return <div className='circles-ling'>
      {data.statusPR === 'converted' ? <i className="fa fa-circle"></i> : (data.statusPR === 'converted.partially' ? <i className="fa fa-adjust"></i> : <i className="fa fa-circle-o" ></i>)}
  </div>;
}

const getQuantityOnQuote = (data) => {
  return <div className='circles-ling'>
      {data.statusQuote === 'converted' ? <i className="fa fa-circle"></i> : (data.statusQuote === 'converted.partially' ? <i className="fa fa-adjust"></i> : <i className="fa fa-circle-o" ></i>)}
  </div>;
}
const showTotal = (total) => {
  return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
};

const loadPageData = (pageNumber, pageSize) => {
  const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
  fetchPaginationDataIfNeeded('fetchSalesEnquiry', 'enquiryList', props, {...payload,isActive:0});
}

const handlePageSizeChange = (pageNumber, pageSize, props) => {
  const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
  props.resetPaginatedData();
  props.fetchSalesEnquiry({...payload,isActive:0});
  props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
}

const handleSortColumn = (index) => {
        
  const currentSortDirection = props.sortStates[index] || "none";
  const nextSortDirection =
    currentSortDirection === "none" ? "asc" :
    currentSortDirection === "asc" ? "desc" : "none";

  // Update sorting state using props.updateState
  props.updateState({
    sortStates: {
      // ...props.sortStates,
      [index]: nextSortDirection,
    },
  });

  // Call your sorting function
  const direction = nextSortDirection === "desc" ? 1 : 0;
  sortTable(document.getElementById("inquiryInactive-table"), index, direction);
};
const getSortIcon = (index) => {
  const sortState = props.sortStates[index];
  if (sortState === "asc") return <i className={ICONS.SORT_UP} />;
  if (sortState === "desc") return <i className={ICONS.SORT_DOWN} />;
  return props.hoveredColumn === index ? <i className={ICONS.SORT} /> : null;
};

  return (
    <Fragment>
    
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="sales.deadInquiry.heading"
                defaultMessage=""
              />
            </div>
            {
              enquiryCount ? <>
                  <div className="vertical-sep" />
                  <div>{enquiryCount}</div>
              </> : ''
            }
          </div>
      
        </div>

        <div className='table-container'>
                <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>

                    <table id="inquiryInactive-table">
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                {!_isColumnToHide('inquiryNumber') ? 
                                <th>
                                  <div
                                    onClick={() => handleSortColumn(1)}
                                    onMouseEnter={() => props.updateState({ hoveredColumn: 1 })}
                                    onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                    style={{display:"flex",width:"72px", alignItems:"center", justifyContent:"space-between", cursor:"pointer"}}>
                                      <FormattedMessage id='inquiryNumber.text' defaultMessage='Inquiry #' />
                                    {getSortIcon(1)}
                                  </div>
                                </th> : ''}
                                {!_isColumnToHide('customerName') ? 
                                  <th>
                                    <div
                                              onClick={() => handleSortColumn(2)}
                                              onMouseEnter={() => props.updateState({ hoveredColumn: 2 })}
                                              onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                              style={{display:"flex",width:"74px", alignItems:"center", justifyContent:"space-between"}}>
                                                <FormattedMessage id='common.customer.text' defaultMessage='Customer' /> {getSortIcon(2)}
                                              </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('expirationDate') ? 
                                  <th>
                                    <div
                                      onClick={() => handleSortColumn(3)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 3 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                      style={{display:"flex",width:"108px", alignItems:"center", justifyContent:"space-between"}}>

                                        <FormattedMessage id='expirationDate.text' defaultMessage='Expiration Date' /> {getSortIcon(3)}
                                    </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('inquiryDate') ? 
                                  <th>
                                    <div
                                      onClick={() => handleSortColumn(4)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 4 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })} 
                                      style={{display:"flex",width:"98px", alignItems:"center", justifyContent:"space-between"}}>

                                        <FormattedMessage id='common.dateCreated' defaultMessage='Inquiry Date' /> {getSortIcon(4)}
                                    </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('priority') ? 
                                  <th>
                                    <div
                                            onClick={() => handleSortColumn(5)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 5 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })} 
                                            style={{display:"flex",width:"60px", alignItems:"center", justifyContent:"space-between"}}>
                                              <FormattedMessage id='common.priority' defaultMessage='Priority' />  {getSortIcon(5)}
                                            </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('isQuoteConverted') ? 
                                  <th>
                                    <div 
                                            onClick={() => handleSortColumn(6)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 6 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            style={{display:"flex",width:"98px", alignItems:"center", justifyContent:"space-between"}}> 
                                              <FormattedMessage id='quoteConverted.text' defaultMessage='Quote Converted' /> {getSortIcon(6)}
                                            </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('salesPersonFullName') ? 
                                  <th>
                                    <div 
                                      onClick={() => handleSortColumn(7)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 7 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })} 
                                      style={{display:"flex",width:"90px", alignItems:"center", justifyContent:"space-between"}}>
                                        <FormattedMessage id='salesPerson.text' defaultMessage='Sales Person' /> {getSortIcon(7)}
                                    </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('systemStatus') ? 
                                   <th>
                                    <div
                                      onClick={() => handleSortColumn(8)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 8 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                      style={{display:"flex",width:"102px", alignItems:"center", justifyContent:"space-between"}}>
                                        <FormattedMessage id='customer.salesOrder.listing.header.systemStatus' defaultMessage='' /> {getSortIcon(8)}
                                    </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('userStatus') ? 
                                  <th>
                                    <div 
                                      onClick={() => handleSortColumn(9)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 9 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })} 
                                      style={{display:"flex",width:"88px", alignItems:"center", justifyContent:"space-between"}}> 
                                        <FormattedMessage id='userStatus.txt' defaultMessage='' /> {getSortIcon(9)}
                                    </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('referenceNumber') ? 
                                  <th>
                                    <div
                                      onClick={() => handleSortColumn(10)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 10 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })} 
                                      style={{display:"flex",width:"48px", alignItems:"center", justifyContent:"space-between"}}>  
                                        <FormattedMessage id='customer.salesOrder.listing.header.refNumber' defaultMessage='' /> {getSortIcon(10)}
                                    </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('customerInquiryNumber') ? 
                                  <th>
                                    <div
                                      onClick={() => handleSortColumn(11)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 11 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })} 
                                      style={{display:"flex",width:"84px", alignItems:"center", justifyContent:"space-between"}}>
                                        <FormattedMessage id='common.customer.inquiryNumber' defaultMessage='' />  {getSortIcon(11)}
                                    </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('totalQuantityOnPR') ? 
                                  <th>
                                    <div
                                      onClick={() => handleSortColumn(12)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 12 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })} 
                                      style={{display:"flex",width:"84px", alignItems:"center", justifyContent:"space-between"}}>  
                                      <FormattedMessage id='customer.salesOrder.listing.header.qtyOnPR' defaultMessage='' />  {getSortIcon(12)}
                                    </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('totalQuantityOnQuote') ? 
                                  <th>
                                    <div
                                      onClick={() => handleSortColumn(13)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 13 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })} 
                                      style={{display:"flex",width:"94px", alignItems:"center", justifyContent:"space-between"}}>  
                                      <FormattedMessage id='customer.salesOrder.listing.header.qtyOnQuote' defaultMessage='' /> {getSortIcon(13)}
                                    </div>
                                  </th> 
                                : ''}
                                {!_isColumnToHide('statusPRMessage') ? 
                                  <th>
                                    <div 
                                      onClick={() => handleSortColumn(14)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 14 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })} 
                                      style={{display:"flex",width:"98px", alignItems:"center", justifyContent:"space-between"}}>
                                      <FormattedMessage id='prStatus.text' defaultMessage='' /> {getSortIcon(14)}
                                    </div>
                                  </th> 
                                : ''}

                                {!_isColumnToHide('project') ? 
                                  <th>
                                    <div 
                                      onClick={() => handleSortColumn(15)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 15 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                      style={{display:"flex",width:"58px", alignItems:"center", justifyContent:"space-between"}}>
                                      <FormattedMessage id='common.project' defaultMessage='' /> {getSortIcon(15)}
                                    </div>
                                  </th> 
                                : ''}
                                {
                                  <th>
                                    <div
                                      onClick={() => handleSortColumn(16)}
                                      onMouseEnter={() => props.updateState({ hoveredColumn: 16 })}
                                      onMouseLeave={() => props.updateState({ hoveredColumn: null })}  
                                      style={{display:"flex",width:"54px", alignItems:"center", justifyContent:"space-between"}}>
                                      <FormattedMessage id='common.status' defaultMessage='' /> {getSortIcon(16)}
                                    </div>
                                  </th>
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {enquiryList && enquiryList[pageNumber] ?
                                enquiryList[pageNumber].map((rowData, index) => (
                                    <tr key={index}>
                                        <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>
                                        {!_isColumnToHide('inquiryNumber') ? <td><div className="table-link-text" onClick={() => { openInquiryDetails(rowData) }}>{rowData.inquiryNumber}</div></td> : ''}

                                        {!_isColumnToHide('customerName') ? <td className='one-line-view'>
                                            <div className="table-link-text" >
                                                <div className="link"
                                                    //    onClick={() => {
                                                    //   openCustomerProfile(rowData, props);
                                                    // }}
                                                    onClick={() => {
                                                        props.pushModalToStack({

                                                            modalBody: <CustomerProfile {...props}
                                                                customerId={rowData.customerId}
                                                            //   customerName={rowData.customerName}
                                                            //   customerDetails={rowData}
                                                            />,
                                                            width: '100%',
                                                            hideTitle: true,
                                                            hideFooter: true,
                                                            wrapClassName: "new-transaction-wrapper",
                                                        })
                                                    }}
                                                >
                                                    {rowData.customerName}
                                                </div>
                                            </div>
                                            {/* <div onClick={() => {
                                                          props.pushModalToStack({  
                                                           
                                                            modalBody: <CustomerDetails {...props}
                                                            customerId= {rowData.customerId}
                                                            customerName={rowData.customerName}
                                                        />,
                                                        width: '100%',
                                                        hideTitle: true,
                                                        hideFooter: true,
                                                        wrapClassName: "new-transaction-wrapper",
                                                        })
                                                       }}>{rowData.customerName}</div>
                                                    */}
                                        </td> : ''
                                        }

                                        {!_isColumnToHide('expirationDate') ? <td>
                                            <div>
                                                <Tooltip placement="topLeft" title={(rowData.inquiryExpirationDate && getMomentDateForUIReadOnly({ date: rowData.inquiryExpirationDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                    {(rowData.inquiryExpirationDate ? getMomentDateForUIReadOnly({ date: rowData.inquiryExpirationDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : <div className="empty-data"></div>)}
                                                </Tooltip>
                                            </div>
                                        </td> :
                                            ""
                                        }

                                        {!_isColumnToHide('inquiryDate') ? <td>
                                            <div>
                                                <Tooltip placement="topLeft" title={(rowData.inquiryDate && getMomentDateForUIReadOnly({ date: rowData.inquiryDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                    {(rowData.inquiryDate && getMomentDateForUIReadOnly({ date: rowData.inquiryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                </Tooltip>
                                            </div>
                                        </td> : ''
                                        }

                                        {!_isColumnToHide('priority') ? <td>{rowData.priority ? rowData.priority : <div className="empty-data"></div>}</td> : ""

                                        }

                                        {!_isColumnToHide('isQuoteConverted') ? <td>
                                            {rowData.isQuoteConverted && rowData.isQuoteConverted !== '-' ? <span> {rowData.foreignCurrencyIcon ? <i className={rowData.foreignCurrencyIcon}></i> : (rowData.foreignCurrency ? <span>{rowData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}  {(rowData.isQuoteConverted || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : <div className="empty-data"></div>}</td> : ''
                                        }
                                        {!_isColumnToHide('salesPersonFullName') ? <td>{rowData.salesPersonFullName ? rowData.salesPersonFullName : <div className="empty-data"></div>}</td> : ""}
                                        {!_isColumnToHide('systemStatus') ? <td className='line-view'>
                                            <div>
                                                <div className={["status-code", rowData.status.toLowerCase()].join(' ')}>
                                                    {rowData.status ? <FormattedMessage id={rowData.status} defaultMessage={rowData.status} />
                                                        : <div className="empty-data"></div>}</div>
                                            </div></td> : ""
                                        }
                                        {!_isColumnToHide('userStatus') ? <td className='line-view'>
                                            {/* <div>
                                                {rowData.userStatus ? <span className="status-code cursor-pointer" style={{ background: rowData.userStatusColor }}
                                                onClick={() => {
                                                    updateUserStatus(props, rowData);
                                                  } }
                                                >{rowData.userStatus || <div className="empty-data"></div>}</span> : <div className="empty-data"></div>}
                                            </div> */}

                                                {rowData.userStatus ? (
                                                    <div
                                                        className="status-code cursor-pointer"
                                                        style={{ background: rowData.userStatusColor }}
                                                        onClick={() => {
                                                            updateUserStatus(props, rowData);
                                                        }}
                                                    >
                                                        {rowData.userStatus}
                                                    </div>
                                                ) : (
                                                    <div className="empty-data cursor-pointer" onClick={() => {
                                                      updateUserStatus(props, rowData);
                                                  }}>
                                                        
                                                    </div>
                                                )}

                                        </td> : ""}
                                        {!_isColumnToHide('referenceNumber') ? <td>{rowData.refNumber ? rowData.refNumber : <div className="empty-data"></div>}</td> : ""}
                                        {!_isColumnToHide('customerInquiryNumber') ? <td>{rowData.customerInquiryNumber ? rowData.customerInquiryNumber : <div className="empty-data"></div>}</td> : ""}
                                        {!_isColumnToHide('totalQuantityOnPR') ? <td>{getQuantityOnPR(rowData)}</td> : ""}
                                        {!_isColumnToHide('totalQuantityOnQuote') ? <td>{getQuantityOnQuote(rowData)}</td> : ""}
                                        {!_isColumnToHide('statusPRMessage') ? <td>{rowData.statusPRMessage ? rowData.statusPRMessage : <div className="empty-data"></div>}</td> : ""}
                                        {!_isColumnToHide('project') ? <td>
                                            {rowData.projectName ? <span>{rowData.projectName}</span> :  <div className="empty-data"></div>}
                                        </td> : ""}
                                        <td>
                                            <div className="status-toggle">
                                            <Switch
                                                    checked={rowData.isActive === 1 ? true : false}
                                                       disabled={!(salesInquiryPermissions?.update)}
                                                    onClick={(checked, e) => {
                                                        props.updateStatus({
                                                            inquiryMasterId: rowData.inquiryMasterId,
                                                            relationshipId: rowData.relationshipId,
                                                            isActive: checked ? 1 : 0
                                                        }, props)

                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )) : ""
                            }
                        </tbody>
                    </table>
                    </Skeleton>
                </div>
                <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={enquiryCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props)
                            }}
                        />
                    </div>
                </Skeleton>
            </div>
     
      
    </Fragment>
  );
};

export default injectIntl(DeadInquiry);
