import React, { Component } from "react";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, DatePicker, Tooltip, Skeleton } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "../../../general/Dropdown";
import * as find from "lodash.find";
import moment from "moment-timezone";
import * as filter from "lodash.filter";
import { QuestionCircleTwoTone, EditOutlined } from "@ant-design/icons";

import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
  ICONS,
} from "../../../../static/constants";
import { AG_GRID_CONSTANTS } from "../../../../static/agGridConstants";
import { addDaysToDate } from "../../../../utils";
import { getLabelName } from "../../../modal/modalBody/customerInfo";
import { showToasterMessage } from "../../../../utils";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
class StockTransitDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: "default",
      billingAddress: this.defaultBillingAddress,
    };
  }

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value });
  };

  getDueDateChange = () => {
    const supplierObj = this.props.supplierPaymentTerm || {};
    const selectedterm = this.props.paymentTerms.length
      ? find(this.props.paymentTerms, {
          paymentTermId: supplierObj.paymentTermId || 0,
        }) || {}
      : {};
    if (selectedterm.numberOfDays) {
      const updatedgitDueDate = addDaysToDate(
        this.props.gitDate || new Date(),
        selectedterm.numberOfDays
      );
      return updatedgitDueDate;
    }
    return this.props.invoiceDueDate || new Date();
  };

  handleNewTaxIdentificationAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (taxIdentification) {
      if (props.supplier) {
        let list = props.supplier.supplierTaxIdentificationsList || [];
        list.push(taxIdentification);
        props.updateState({
          supplier: { ...props.supplier, supplierTaxIdentificationsList: list },
        });
      }
    };
    const data = {
      title: (
        <FormattedMessage
          id="addItem.text.taxIdentification"
          defaultMessage=""
        />
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    if (
      !formData ||
      (modalType === MODAL_TYPE.BILLING_ADDRESS && !formData.supplierId)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please Select Supplier",
      });
    }
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
      payload.formData = {
        addressType: "relationship",
        locationType: "RelationshipBillingAddress",
      };
    }
    payload.formData.callback = function (address) {
      props.hideModal(data);
      if (
        address.locationType === "BillingAddress" ||
        address.locationType === "ShippingAddress"
      ) {
        props.fetchAddresses({
          supplierId: address.supplierId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          locationType: address.locationType,
        });
      } else {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (contact) {
      props.hideModal(data);
      if (contact.supplierId) {
        props.fetchAllContacts({
          supplierId: contact.supplierId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const data = {
      title: (
        <div>
          <FormattedMessage id="addItem.text.contact" defaultMessage="" />
          <div style={{ "font-size": "12px" }}>
            {" "}
            {formData.supplierName || ""}
          </div>
        </div>
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.project" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PROJECT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewPaymentTermAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, hideModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
      ),
      formData,
      hideFooter: true,
      modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
      handleSubmit: (formData = {}) => {
        if (
          formData.termName &&
          formData.termName.length &&
          formData.days !== 0 &&
          formData.days
        ) {
          hideModal();
          formData.relationshipId = (props.companyInfo || {}).relationshipId;
          props.addPaymentTerm(formData);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };

    switch (modalType) {
      case MODAL_TYPE.SO_SOURCE: {
        addFunc = props.addSalesOrderSources;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        addFunc = props.addDocumentName;
        payload.txType =
          CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.STOCK_TRANSFER];
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        title = (
          <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.supplier || {}).name} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.relationshipBillingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.companyInfo || {}).storeName} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.relationshipShippingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.companyInfo || {}).storeName} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        title = (
          <FormattedMessage id="addItem.text.documentName" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;
    let callback = function (data) {
      if (modalType === MODAL_TYPE.CONTACT && props.supplier.supplierId) {
        props.fetchAllContacts({
          supplierId: props.supplier.supplierId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
      if (
        modalType === MODAL_TYPE.BILLING_ADDRESS &&
        props.supplier.supplierId
      ) {
        props.fetchAddresses({
          supplierId: props.supplier.supplierId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          locationType: "BillingAddress",
        });
      }
      if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        deleteFunc = props.deletePaymentTerm;
        break;
      }
      case MODAL_TYPE.PROJECT: {
        deleteFunc = props.deleteProject;
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
        deleteFunc = props.deleteAddress;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        deleteFunc = props.deleteDocumentName;
        break;
      }
      case MODAL_TYPE.CONTACT: {
        deleteFunc = props.deleteContact;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        deleteFunc = props.deleteRelationshipTaxIdentifications;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER: {
        deleteFunc = props.deleteSupplierTaxIdentifications;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          callback: callback,
        });
        if (
          modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER &&
          props.supplier &&
          props.supplier.supplierTaxIdentificationsList
        ) {
          let list = (
            props.supplier.supplierTaxIdentificationsList || []
          ).filter((x) => x.supplierTaxIdentificationId !== payload.id);
          props.updateState({
            supplier: {
              ...props.supplier,
              supplierTaxIdentificationsList: list,
            },
          });
        }
        if (
          modalType === MODAL_TYPE.DOCUMENT_NAME &&
          payload.id === this.props.documentNameId
        ) {
          const selectedObj =
            find(this.props.documentList || [], {
              txType:
                CONSTANTS_TRANSACTIONS_KEY[
                  CONSTANTS_TRANSACTIONS.STOCK_TRANSFER
                ],
              isDefault: 1,
            }) || {};
          this.props.updateState({
            documentNameId: selectedObj.documentNameId,
            docName: selectedObj.docName,
          });
        }
      },
    };
    showModal(modalData);
  };
  render() {
    const { size } = this.state;
    const { supplier = {} } = this.props;

    const supplierPaymentTerm = this.props.supplierPaymentTerm || {};
    const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
    if (rowNode) {
      rowNode.data["customerInquiryNumber"] = this.props.customerInquiryNumber;
      rowNode.data["customerPONumber"] = this.props.customerPONumber;
      rowNode.data["customerRFQNumber"] = this.props.customerRFQNumber;
      rowNode.data[CONSTANTS.SHIPPING_ADDRESS] =
        (this.props.relationshipShippingAddress || {}).streetAddress1 || "";
      rowNode.data[CONSTANTS.SHIPPING_ADDRESS] =
        (this.props.relationshipShippingAddress || {}).streetAddress1 || "";
      rowNode.data[AG_GRID_CONSTANTS.COMMON.PLACE_OF_SUPPLY_FIELD] =
        this.props.placeOfSupply || "";
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD
      ] = supplierPaymentTerm.termName || "";
      rowNode.data["reference"] = this.props.reference;
      rowNode.data[AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT] =
        this.props.projectName;
      rowNode.data["supplierInvoiceNumber"] = this.props.supplierInvoiceNumber;
      rowNode.data["supplierPONumber"] = this.props.supplierPONumber;
      rowNode.data["supplierQuoteNumber"] = this.props.supplierQuoteNumber;
      this.gridApi.refreshCells({ force: true });
      this.gridApi.sizeColumnsToFit();
    }

    // const columnDefs = [
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.gridHeader.placeOfSupply"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: AG_GRID_CONSTANTS.COMMON.PLACE_OF_SUPPLY_FIELD,
    //     resizable: true,
    //     hide: (this.props.companyInfo || {}).countryName !== "India",
    //     editable: true,
    //     cellEditor: "customDropDownEditor",
    //     //pinned: 'left',
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.states || [],
    //         optionKeyName: "stateId",
    //         valueKeyName: "stateName",
    //         onSelect: (selectedValue) => {
    //           this.props.updateState({
    //             placeOfSupply: selectedValue,
    //           });
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //         },
    //         // allowClear: true,
    //         // onClear: () => {
    //         //     //obj.node.setDataValue(obj.colDef.field, undefined);
    //         //     this.props.updateState({
    //         //         placeOfSupply: undefined
    //         //     })
    //         // },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="modal.txDetails.inquiry.customerInquiryNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "customerInquiryNumber",
    //     hide: this.props.customerInquiryNumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage id="supplierPONumber.text" defaultMessage="" />
    //         </div>
    //       );
    //     },
    //     field: "supplierPONumber",
    //     hide: this.props.supplierPONumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage id="customerPONumber.text" defaultMessage="" />
    //         </div>
    //       );
    //     },
    //     field: "customerPONumber",
    //     hide: this.props.customerPONumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.rfq.form.gridHeader.customerRFQNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "customerRFQNumber",
    //     hide: this.props.customerRFQNumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.purchaseOrder.form.gridHeader.supplierInvoiceNumber"
    //             defaultMessage=""
    //           />
    //           <Tooltip
    //             placement="top"
    //             title={
    //               <FormattedMessage
    //                 id="common.You.can.enter.Supplier.Invoice.Number"
    //                 defaultMessage=""
    //               />
    //             }
    //             trigger="click"
    //           >
    //             <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //     field: "supplierInvoiceNumber",
    //     resizable: true,
    //     editable: true,
    //     cellEditor: "customTextEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         maxLength: 20,
    //         onChange: (val) => {
    //           this.props.updateState({
    //             supplierInvoiceNumber: val,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.rfq.form.gridHeader.supplierQuoteNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "supplierQuoteNumber",
    //     editable: true,
    //     cellEditor: "customTextEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         maxLength: 20,
    //         onChange: (val) => {
    //           this.props.updateState({
    //             supplierQuoteNumber: val,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.purchaseinvoice.form.gridHeader.refNumber"
    //             defaultMessage=""
    //           />
    //           <Tooltip
    //             placement="top"
    //             title={
    //               <FormattedMessage
    //                 id="tooltip.referenceNumberAssign"
    //                 defaultMessage=""
    //               />
    //             }
    //             trigger="click"
    //           >
    //             <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //     field: "reference",
    //     editable: true,
    //     cellEditor: "customTextEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         maxLength: 20,
    //         onChange: (val) => {
    //           this.props.updateState({
    //             reference: val,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.gridHeader.shippingAddress"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: CONSTANTS.SHIPPING_ADDRESS,
    //     editable: true,
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         value: (this.props.relationshipShippingAddress || {})
    //           .streetAddress1,
    //         items: this.props.allRelationshipShippingAddress || [],
    //         optionKeyName: "locationId",
    //         valueKeyName: "streetAddress1",
    //         addressLineKey1: "streetAddress2",
    //         addressLineKey2: "cityName",
    //         addressLineKey3: "stateName",
    //         addressLineKey4: "zipCode",
    //         isFavouriteKeyName: "isDefault",
    //         optionClassAddress: "custom-address-dropdown",
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(
    //             this.props,
    //             MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS,
    //             payload
    //           );
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
    //             addressType: "relationship",
    //           };
    //           this.handleNewAddressAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS
    //           );
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //           const relationshipShippingAddress = find(
    //             this.props.allRelationshipShippingAddress || [],
    //             { locationId: Number(optionObj.key) }
    //           );
    //           this.props.updateState({
    //             relationshipShippingAddress,
    //             placeOfSupply: (relationshipShippingAddress || {}).stateName,
    //           });
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             relationshipShippingAddress: undefined,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.gridHeader.paymentTerms"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field:
    //       AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD,
    //     editable: true,
    //     //pinned: 'right',
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.paymentTerms,
    //         optionKeyName: "paymentTermId",
    //         valueKeyName: "termName",
    //         isLockKeyName: "isSystemGenerated",
    //         //isFavouriteKeyName: 'isDefault',
    //         canAddNew: true,
    //         canDelete: true,
    //         // permissions: this.props.permissions,
    //         // moduleName: MODULE_CODED_VALUES.PAYMENT_TERM,
    //         // operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(
    //             this.props,
    //             MODAL_TYPE.PAYMENT_TERM,
    //             payload
    //           );
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             termName: payload.textEntered,
    //             days: null,
    //             description: "",
    //             isDefaultPayment: false,
    //             submittedOnce: false,
    //           };
    //           this.handleNewPaymentTermAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.PAYMENT_TERM
    //           );
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //           const supplierPaymentTerm = find(this.props.paymentTerms, {
    //             paymentTermId: Number(optionObj.key),
    //           });
    //           let invoiceDueDate = addDaysToDate(
    //             this.props.gitDate || new Date(),
    //             supplierPaymentTerm.numberOfDays
    //           );
    //           this.props.updateState({
    //             supplierPaymentTerm,
    //             invoiceDueDate,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.projectDropdown.label"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT,
    //     editable: true,
    //     //pinned: 'right',
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.projectList,
    //         valueKeyName: "projectName",
    //         value: this.props.projectName,
    //         optionKeyName: "projectMasterId",
    //         projectIdKey: "projectMasterId",
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             projectName: payload.projectName,
    //           };
    //           this.handleNewProjectAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.PROJECT
    //           );
    //         },
    //         onSelect: (selectedValue, option) => {
    //           const selectedProject =
    //             find(this.props.projectList, {
    //               projectMasterId: Number(option.key),
    //             }) || {};
    //           this.props.updateState({
    //             projectMasterId: selectedProject.projectMasterId,
    //             projectName: selectedProject.projectName,
    //             projectNumber: selectedProject.projectNumber,
    //           });
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             projectMasterId: undefined,
    //             projectName: undefined,
    //             projectNumber: undefined,
    //           });
    //         },
    //       };
    //     },
    //   },
    // ];

    // const onGridReady = (params) => {
    //   this.gridApi = params.api;
    //   this.gridApi.sizeColumnsToFit();
    //   this.gridColumnApi = params.columnApi;
    // };
    const newWarehouseAddition = (warehouseData) => {
      const formData = warehouseData || {};
      formData.relationshipId = this.props.companyInfo.relationshipId;
      const payload = { formData: formData };
      const data = {
        title: (
          <FormattedMessage id="addItem.text.warehouse" defaultMessage="" />
        ),
        formData,
        hideFooter: true,
        modalData: {
          modalType: MODAL_TYPE.WAREHOUSE,
          data: payload,
        },
        handleSubmit: (formData = {}) => {
          data.formData.submittedOnce = true;
          this.props.showModal(data);
        },
      };
      this.props.showModal(data);
    };
    return (
      <>
        <Row>
          <Col span={5}>
            <Skeleton loading={this.props.isLoading} paragraph={{ rows: 5 }} active>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Organization Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.name"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="deatils-field-value">
                  {(this.props.companyInfo || {}).storeName}
                </div>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.adress"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.companyBillingAddress",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <span>
                  {!this.props.addressHide &&
                  (this.props.relationshipBillingAddress || {})
                    .streetAddress1 ? (
                    <>
                      <div className="detail-address-value">
                        <div style={{ height: "96px", paddingTop: "10px" }}>
                          {getAddressInfoFragment(
                            this.props.relationshipBillingAddress || {},
                            {},
                            this.props.companyInfo || {}
                          )}
                        </div>
                        <div
                          onClick={() => {
                            this.props.updateState({
                              addressHide: true,
                            });
                          }}
                        >
                          <i className={ICONS.EDIT_P} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <Dropdown
                      style={{ width: "100%" }}
                      value={(this.props.relationshipBillingAddress || {}).streetAddress1}
                      items={this.props.allRelationshipBillingAddress}
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      isFavouriteKeyName="isDefault"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({
                          relationshipBillingAddress: undefined,
                        });
                      }}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS
                        );
                      }}
                      onSelect={(locationId) => {
                        this.props.updateState({
                          relationshipBillingAddress:
                            this.props.allRelationshipBillingAddress.find(
                              (x) => x.locationId === locationId
                            ),
                          addressHide: false,
                        });
                      }}
                    />
                  )}
                </span>
              </Col>
            </Row>
            </Skeleton>
          </Col>
          <Col span={1}></Col>
          <Col span={5}>
            <Skeleton loading={this.props.isLoading} paragraph={{ rows: 5 }} active>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Source Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  Warehouse<span className="required">*</span>
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={(this.props.allWarehouses || []).filter(obj=>obj.warehouseMasterId !== this.props.selectedDestinationWarehouse)}
                  optionKeyName="warehouseMasterId"
                  canAddNew={true}
                  // valueKeyName='warehouseMasterId'
                  valueKeyName="warehouseName"
                  placeholder="Select Warehouse"
                  addressLineKey1="streetAddress1"
                  showAddressString={true}
                  value={this.props.destinationWareHouseName1}

                  onAddNewClick={(payload) => {
                    newWarehouseAddition();
                  }}
                  onSelect={(wareHouse, options) => {
                    if(this.props.selectedSourceWarehouse){

                      let gridApi = this.props.gridApi;
                    let self = this;
                    this.props.gridApi.forEachNode(function (rowNode, index) {
                      gridApi.updateRowData({ remove: [rowNode.data] });
                      if (gridApi.rowRenderer.lastRenderedRow < 0) {
                        gridApi.updateRowData({ add: [{}] });
                      }
                      // gridApi?.calculateSubtotal();
                    })
                    }
                    this.props.updateState({
                      destinationWareHouseName1 :options.name,
                      selectedSourceWarehouse: options.value,
                      sourceWHAddress:
                        find(this.props.allWarehouses, {
                          warehouseMasterId: options.value,
                        })?.locationList[0] || {},
                      // selectedDestinationWarehouse:""
                      
                              
                    });
                    this.props.fetchProducts({relationshipId: this.props.companyInfo.relationshipId,
                      warehouseMasterId: options.value,
                      pageNumber: 1,
                      pageSize: 100});
                  }}
                />
              </Col>

              <Col span={24} className="text-right">
                <div className="details-heading-subheading">
                  Address<span className="required">*</span>
                </div>
              </Col>
              <Col span={24}>
                <div style={{ height: "83px" }} className="mt10">
                  {this.props.selectedSourceWarehouse
                    ? Object.keys(this.props.sourceWHAddress || {}).length
                      ? getAddressInfoFragment(this.props.sourceWHAddress)
                      : "No address exist for this warehouse"
                    : "Please select warehouse first"}
                </div>
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage id="contact" defaultMessage="" />
                  <Tooltip
                    placement="right"
                    title={
                      <FormattedMessage
                        id="common.supplier.contact"
                        defaultMessage=""
                      />
                    }
                    trigger="click"
                  >
                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                {/* contact name of the warehouse source information  */}
                <div>
                  {this.props.companyInfo?.relationshipEmployees?.firstName ||
                    ""}{" "}
                  {this.props.companyInfo?.relationshipEmployees?.lastName ||
                    ""}
                </div>
              </Col>
            </Row>
            </Skeleton>
          </Col>
          <Col span={1}></Col>
          <Col span={5}>
            <Skeleton loading={this.props.isLoading} paragraph={{ rows: 5 }} active>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Destination Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  Warehouse<span className="required">*</span>
                </div>
              </Col>
              <Col span={24}>
                
                <Dropdown
                  style={{ width: "100%" }}
                  items={(this.props.allWarehouses || []).filter(obj=>obj.warehouseMasterId !== this.props.selectedSourceWarehouse)}
                  optionKeyName="warehouseMasterId"
                  // valueKeyName='warehouseMasterId'
                  valueKeyName="warehouseName"
                  addressLineKey1="streetAddress1"
                  showAddressString={true}
                  placeholder="Select Destination Warehouse"
                  value={this.props.destinationWareHouseName}

                  onSelect={(wareHouse, options) => {
                    this.props.updateState({
                      destinationWareHouseName :options.name,
                      selectedDestinationWarehouse: options.value,
                      destinationWHAddress:
                        find(this.props.allWarehouses, {
                          warehouseMasterId: options.value,
                        })?.locationList[0] || {},
                    });
                  }}
                  canAddNew={true}
                  onAddNewClick={() => {
                    newWarehouseAddition();
                  }}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage id="signup.tab.address" defaultMessage="" />
                  <span className="required">*</span>
                </div>
              </Col>
              <Col span={24}>
                <div style={{ height: "83px" }} className="mt10">
                  {this.props.selectedDestinationWarehouse
                    ? Object.keys(this.props.destinationWHAddress || {}).length
                      ? getAddressInfoFragment(this.props.destinationWHAddress)
                      : "No address exist for this warehouse"
                    : "Please select warehouse first"}
                </div>
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage id="contact" defaultMessage="" />
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  value={
                    ((this.props.selectedContact || {}).firstName || "") +
                    " " +
                    ((this.props.selectedContact || {}).lastName || "")
                  }
                  optionKeyName="relationshipEmployeeId"
                  items={this.props.allEmployee}
                  valueKeyName="firstName"
                  lastNameKey="lastName"
                  placeholder="Select Contact Name"
                  isFavouriteKeyName="isPrimaryContact"
                  canAddNew={true}
                  canDelete={true}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({ selectedContact: undefined });
                  }}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.CONTACT,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      firstName: payload.textEntered,
                      submittedOnce: false,
                      supplierId: (this.props.supplier || {}).id,
                      pageNumber: this.props.pageNumber || 1,
                      supplierName: supplier.name,
                    };
                    this.handleNewContactAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.CONTACT
                    );
                  }}
                  onSelect={(contactId) => {
                    let contact =
                      find(this.props.allEmployee, {
                        relationshipEmployeeId: contactId,
                      }) || {};
                    this.props.updateState({
                      selectedContact: contact,
                    });
                  }}
                />
              </Col>
            </Row>
            </Skeleton>
          </Col>
          <Col span={1}></Col>
          <Col span={6} className="order-value-info">
            <Skeleton loading={this.props.isLoading} paragraph={{ rows: 5 }} active>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Stock Information
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">Date Created</div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  defaultValue={moment()}
                  value={this.props.selectedDate}
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      gitDate: selectedDate,
                    });
                  }}
                  size={size}
                  disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  Expiration Date
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  size={size}
                  disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      expiryDate: selectedDate,
                    });
                  }}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesOrder.form.documentDropdown.label"
                    defaultMessage=""
                  />

                  <Tooltip
                    placement="right"
                    title={
                      <FormattedMessage
                        id="documentTitle.tooltip"
                        defaultMessage=""
                      />
                    }
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={filter(this.props.documentList || [], (document) => {
                    return (
                      document.txType ===
                      CONSTANTS_TRANSACTIONS_KEY[
                        CONSTANTS_TRANSACTIONS.STOCK_TRANSFER
                      ]
                    );
                  })}
                  placeholder="Document name"
                  value={this.props.docName}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.DOCUMENT_NAME,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                      // maxLength: 30,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.DOCUMENT_NAME
                    );
                  }}
                  onSelect={(selectedValue) => {
                    const selectedObj = find(this.props.documentList || [], {
                      documentNameId: Number(selectedValue),
                    });
                    this.props.updateState({
                      documentNameId: selectedValue,
                      docName: selectedObj.docName,
                    });
                  }}
                  valueKeyName="docName"
                  optionKeyName="documentNameId"
                  isLockKeyName="isSystemGenerated"
                />
              </Col>
            </Row>
            </Skeleton>
          </Col>
        </Row>
      </>
    );
  }
}

const getAddressInfoFragment = (obj) => {
  if (!obj || !Object.keys(obj).length) {
    return "";
  }

  const fragmentObj = (
    <>
      {obj.streetAddress1 ? (
        <div className="billing-address">{obj.streetAddress1}</div>
      ) : (
        ""
      )}
      {obj.streetAddress2 ? (
        <div className="billing-address">{obj.streetAddress2}</div>
      ) : (
        ""
      )}
      {obj.cityName ? (
        <div className="billing-address">
          {`${obj.cityName || ""} ${obj.stateName ? "," + obj.stateName : ""} ${
            obj.zipCode ? "," + obj.zipCode : ""
          }`}
        </div>
      ) : (
        ""
      )}
    </>
  );

  return fragmentObj;
};

export default injectIntl(StockTransitDetail);
