import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesInvoiceProformaDetailComponent from '../../../../../components/modal/modalBody/customer/SalesInvoiceProformaDetail';
import {  approveInvoice, uploadStamp, deleteStamp, canInvoiceBeGenerated } from './action';
//import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF,  downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
//import { fetchSalesInvoices, getSalesInvoiceCount, deleteSalesInvoice } from '../../../../customer/invoice/Listing/action';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';
import { popModalFromStack , pushModalToStack } from '../../../../../actions/commonActions';
class SalesInvoiceDetail extends Component {

    constructor(props){
        super();
        this.state= {
            salesInvoiceDetail: props.proformaInvoiceData,
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
       //const companyInfo = this.props.companyInfo || {};
       // const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.invoicePayload.customerId, invoiceMasterId: this.props.invoicePayload.invoiceMasterId };
      //  fetchDataIfNeeded('fetchSalesInvoiceDetail', 'salesInvoiceDetail', this.props, payload, true);

    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <SalesInvoiceProformaDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
      //  salesInvoiceDetail: state.salesInvoice.salesInvoiceDetail,
        userInfo: state.common.userInfo,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    downloadFileFromBucket,
    generatePDF, printPDF,
    approveInvoice,
    uploadStamp, 
    deleteStamp,
    canInvoiceBeGenerated,popModalFromStack , pushModalToStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceDetail);