import axios from 'axios';
import config from '../../../config/environmentConfig';
import { LEDGER_ACTION_LIST, COMMON_ACTIONS } from '../../../static/constants';
import { getFinancialYear, showToasterMessage, getFullYear, getPreviousYearDate , getCurrentDateForBackend} from "../../../utils";
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchAllLedgerAccounts = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/getAllLedgerAccounts?relationshipId=${payload.relationshipId}&startDate=${payload.startDate || ''}&endDate=${payload.endDate || ''}&pageSize=500&pageNumber=0`)
      .then(res => {
        if(res?.status === 200){
        dispatch({ type: LEDGER_ACTION_LIST.ALL_LEDGER_ACCOUNTS, data: res.data });
      }})
      .catch((err) => {

      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}


export const fetchAllPrevFYLedgerAccounts = (payload) => {

  let FY = getFinancialYear(payload.companyInfo);
  let fyStartDate = getPreviousYearDate(FY.fyStartDate);
  let fyEndDate = getPreviousYearDate(FY.fyEndDate);
  return dispatch => {
    return axios
      .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/getAllLedgerAccounts?relationshipId=${payload.relationshipId}&startDate=${fyStartDate ? getCurrentDateForBackend(fyStartDate) : ''}&endDate=${fyEndDate ? getCurrentDateForBackend(fyEndDate) : ''}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: LEDGER_ACTION_LIST.ALL_PREV_FY_LEDGER_ACCOUNTS, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = LEDGER_ACTION_LIST.ALL_PREV_FY_LEDGER_ACCOUNTS;
        }
      })
  }
}


export const updateLedger = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/updateRefLedgerAccount`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: ((res || {}).data || "") || 'Balance Updated Successfully.' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const updateOpeningBalance = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/updateLedgerAccount`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: ((res || {}).data || "") || 'Balance Updated Successfully.' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}



export const fetchAllLedgerAccountGroup = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountMaster/getChartOfAccountsMaster?relationshipId=${payload.relationshipId}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: LEDGER_ACTION_LIST.ALL_LEDGER_ACCOUNT_GROUPS, data: res.data });      
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = LEDGER_ACTION_LIST.ALL_LEDGER_ACCOUNT_GROUPS;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}




export const fetchAllCategories = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/coaCategory/getAllCOACategories?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: LEDGER_ACTION_LIST.ALL_CATEGORIES, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = LEDGER_ACTION_LIST.ALL_CATEGORIES;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}


export const fetchAllCategoryGroups = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/coaCategoryGroup/getAllCOACategoryGroups?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: LEDGER_ACTION_LIST.ALL_CATEGORY_GROUP, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = LEDGER_ACTION_LIST.ALL_CATEGORY_GROUP;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const deleteLedgerAccount = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/deleteLedgerAccount`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: (res.data || "") || 'Deleted successfully' });
        props.fetchAllLedgerAccounts({ relationshipId: props.companyInfo.relationshipId})
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' });
      })
  }
}