import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  popModalFromStack, pushModalToStack, showModal, hideModal
} from '../../../../actions/commonActions';
import { downloadFileFromBucket } from '../../../../actions/downloadFileAction';
import { CONSTANTS, LEDGER_TYPE } from '../../../../static/constants';
import QuantityDrawerComp from '../../../../components/drawer/inventory/quantityDrawer';
import { fetchSupplierSummary } from '../../../../actions/commonActions';
import { fetchDataIfNeeded, getMomentDateForUI } from '../../../../utils';
import { createStockQuantity } from './action';
import { fetchInventoryItemDetails,resetProductDetail } from '../../../product/detail/action';
import LedgerAccountsDrawer from '../../finance/ledgers';
import find from 'lodash.find';

class QuantityDrawer extends Component {
  constructor(props) {
    super(props);
    
    const supplier = find(props.suppliers[props.pageNumber] || [], { supplierId: props.data?.supplierId });
    const creditorAccount = (props.allLedgerAccounts || []).find((item) => (
       item.ledgerAccountName === LEDGER_TYPE.TX_TYPE_CREDITORS
    ));
    const debitorAccount = (props.allLedgerAccounts || []).find((item) => (
     item.ledgerAccountName === LEDGER_TYPE.TX_TYPE_OPENING_STOCK
    ));
 
    this.state = {
      current: 0,
      ...props.categoryData,
      isLoading: true,
      pageSize: 100,
      pageNumber: 1,
      hasVariant:  0,
      selectedSupplierName: props.isUpdate ? supplier : {},
      productQuantity:  0,
      purchasedPrice: 0,
      selectedCreditLedger: creditorAccount,
      selectedDebitorLedger: debitorAccount,
      selectedProduct:  {},
     
      variantList:  [],
      selectedVariantProduct:{},
      purchaseOrderDate: getMomentDateForUI({
        date: new Date(),
        format: CONSTANTS.BACKEND_FORMAT,
      }),
      openingQuantityDetail:{}
    };
  }
     componentDidMount(){
      const companyInfo = this.props.companyInfo || {};
      const { isUpdate, data = {} } = this.props;
      
      const payload = { relationshipId: companyInfo.relationshipId, itemId: (data?.openingQuantityDetailList||[])[0]?.itemId, pageNumber:1, pageSize: 100};
      this.props.resetProductDetail();
      if (isUpdate && data) {
        fetchDataIfNeeded('fetchInventoryItemDetails', 'productDetails', this.props, payload, true);
      }
     }



     componentWillReceiveProps(props){
          if(this.state.isLoading && props.isUpdate && Object.keys(props.productDetails||{}).length){
            const product = props.productDetails;
            
               

               const itemDetails = (props.data.openingQuantityDetailList||[])[0] || {};
               const ledegerInfo = (itemDetails.inventoryItemOpeningQuantityCOATxList||[]);
               const creditLedegerInfo = (ledegerInfo).find((item)=>item.accountingEntry === "Credit");
               const debitLedgerInfo =  (ledegerInfo).find((item)=>item.accountingEntry === "Debit");
              let selectedVariantProduct = (product?.inventoryItemVariantList||[]).find((val)=>val.variantId === itemDetails?.inventoryItemVariantId)
    
              

          
            this.setState({
              selectedProduct: product,
              hasVariant: product?.hasVariant,
              variantList: product.inventoryItemVariantList||[],
              selectedVariantProduct: selectedVariantProduct,
              purchasedPrice: itemDetails.anItemPurchasePrice,
             
              productQuantity: itemDetails.quantity,
              qtyBeforeUpdate: itemDetails.quantity,
              purchaseOrderDate:getMomentDateForUI({
                date: props.data?.poDate ,
                format: CONSTANTS.BACKEND_FORMAT,
              }),
             
              selectedCreditLedger: creditLedegerInfo,
              selectedDebitorLedger: debitLedgerInfo,
              isLoading: false,
              openingQuantityDetail: itemDetails

            })


           
          }
     }
  render() {
  
    return (
      <>
        
        <QuantityDrawerComp {...this.state} {...this.props} updateDrawerState={(data) => this.setState(data)} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  companyInfo: state.common.companyInfo,
  suppliers: state.supplier.suppliers,
  productDetails: state.product.productDetails,
}
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  downloadFileFromBucket,
  popModalFromStack, pushModalToStack, showModal, hideModal,
  fetchSupplierSummary,
  createStockQuantity,
  fetchInventoryItemDetails,resetProductDetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuantityDrawer);
