import React, { Fragment } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, DatePicker, Tooltip, Radio,Skeleton } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import * as filter from "lodash.filter";
import * as find from "lodash.find";
import { Dropdown } from "../../../../general/Dropdown";
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
// import config from '../../../../../config/environmentConfig';
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { QuestionCircleTwoTone, EditOutlined } from "@ant-design/icons";
import { TextBox } from "../../../../general/TextBox";
import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  ICONS,
} from "../../../../../static/constants";
import { AG_GRID_CONSTANTS } from "../../../../../static/agGridConstants";
import { addDaysToDate, getMomentDateForUI, getMomentLoclDateForUIReadOnly } from "../../../../../utils";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { showToasterMessage } from "../../../../../utils";
// import LoadingIcon from '../../../../../assets/images/loading.gif';
// import { LogoComponent } from '../../../../general/LogoComponent';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class CustomerInvoiceDetails extends React.Component {
  constructor(props) {
    super();
    this.state = {
      size: 0,
      isSORadio: props.isSORadio,
      isShipment: props.isShipmentRadio,
      invoiceType:"Regular Invoice",
    };
  }

  componentWillReceiveProps(props) {
    if((props.documentType||[])?.length){
      if ((props.documentType||[]).every(item => item === true)) {
        this.setState({
          invoiceType:'Regular Invoice'
        })
      
      } else if ((props.documentType||[]).every(item => item === false)) {
        this.setState({
          invoiceType:'Bill of Supply'
        })
     
      } else {
        this.setState({
          invoiceType:'Bill of Supply comes with Tax Invoice'
        })
       
      }
    }
    this.setState({
      isSORadio: props.isSORadio,
      isShipment: props.isShipmentRadio,
    });

  }

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value });
  };

  getDueDateChange = () => {
    let selectedterm = this.props.customerPaymentTerm || {};
    if (selectedterm.numberOfDays) {
      const updatedInvoiceDueDate = addDaysToDate(
        this.props.invoiceDate || new Date(),
        selectedterm.numberOfDays
      );
      // this.props.updateState({
      //     invoiceDueDate: updatedInvoiceDueDate
      // });
      return updatedInvoiceDueDate;
    }
    return new Date();
  };

  handleNewTaxIdentificationAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (taxIdentification) {
      if (props.customer && props.customer.customerTaxIdentificationsList) {
        let list = props.customer.customerTaxIdentificationsList || [];
        list.push(taxIdentification);
        props.updateState({
          customer: { ...props.customer, customerTaxIdentificationsList: list },
        });
      }
    };
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    if (
      modalType !== MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS &&
      (!formData || !formData.customerId)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please Select Customer",
      });
    }
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
      payload.formData = {
        addressType: "relationship",
        locationType: "RelationshipBillingAddress",
      };
    }
    payload.formData.callback = function (address) {
      props.hideModal(data);
      if (
        address.locationType === "BillingAddress" ||
        address.locationType === "ShippingAddress"
      ) {
        props.fetchAddresses({
          customerId: address.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          locationType: address.locationType,
        });
      } else {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      keyboard:false,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact) => {
      props.hideModal(data);
      if (contact.customerId) {
        props.fetchAllContacts({
          customerId: contact.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const data = {
      title: (
        <div>
          <FormattedMessage id="addItem.text.contact" defaultMessage="" />
          <div style={{ "font-size": "12px" }}>
            {" "}
            {formData.customerName || ""}
          </div>
        </div>
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.project" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PROJECT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewPaymentTermAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, hideModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
      ),
      formData,
      hideFooter: true,
      modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
      handleSubmit: (formData = {}) => {
        if (
          formData.termName &&
          formData.termName.length &&
          formData.days !== 0 &&
          formData.days
        ) {
          hideModal();
          formData.relationshipId = (props.companyInfo || {}).relationshipId;
          props.addPaymentTerm(formData);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };
    switch (modalType) {
      case MODAL_TYPE.SO_SOURCE: {
        addFunc = props.addSalesOrderSources;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        addFunc = props.addDocumentName;
        payload.txType =
          CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE];
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        title = (
          <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.SO_SOURCE: {
        title = (
          <FormattedMessage id="addItem.text.soSource" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{this.props.customerName || ""} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.SHIPPING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.shippingAddress"
              defaultMessage=""
            />
            <h6>{this.props.customerName || ""} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.companyInfo || {}).storeName} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        title = (
          <FormattedMessage id="addItem.text.documentName" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.taxIdentification"
              defaultMessage=""
            />
          </div>
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;
    let callback = function (data) {
      if (modalType === MODAL_TYPE.CONTACT && props.customer.customerId) {
        props.fetchAllContacts({
          customerId: props.customer.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
      if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        deleteFunc = props.deletePaymentTerm;
        break;
      }
      case MODAL_TYPE.SO_SOURCE: {
        deleteFunc = props.deleteSalesOrderSources;
        break;
      }
      case MODAL_TYPE.PROJECT: {
        deleteFunc = props.deleteProject;
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS:
      case MODAL_TYPE.SHIPPING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        deleteFunc = props.deleteAddress;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        deleteFunc = props.deleteDocumentName;
        break;
      }
      case MODAL_TYPE.CONTACT: {
        deleteFunc = props.deleteContact;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        deleteFunc = props.deleteRelationshipTaxIdentifications;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER: {
        deleteFunc = props.deleteCustomerTaxIdentifications;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          customerId: (props.customer || {}).customerId,
          callback,
        });
        if (
          modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER &&
          props.customer &&
          props.customer.customerTaxIdentificationsList
        ) {
          let list = (
            props.customer.customerTaxIdentificationsList || []
          ).filter((x) => x.customerTaxIdentificationId !== payload.id);
          props.updateState({
            customer: {
              ...props.customer,
              customerTaxIdentificationsList: list,
            },
          });
        }
        if (
          modalType === MODAL_TYPE.DOCUMENT_NAME &&
          payload.id === this.props.documentNameId
        ) {
          const selectedObj =
            find(this.props.documentList || [], {
              txType:
                CONSTANTS_TRANSACTIONS_KEY[
                  CONSTANTS_TRANSACTIONS.SALES_INVOICE
                ],
              isDefault: 1,
            }) || {};
          this.props.updateState({
            documentNameId: selectedObj.documentNameId,
            docName: selectedObj.docName,
          });
        }
      },
    };
    showModal(modalData);
  };

  getInvoiceData = (props, soData) => {
    props.fetchSalesOrderSOId({
      customerId: soData.customerId,
      salesOrderMasterId: soData.salesOrderMasterId,
      relationshipId: soData.relationshipId,
    });
  };

  getInvoiceDataFromShipment = (props, shipmentData) => {
    props.fetchShipmentByShipmentId({
      customerId: shipmentData.customerId,
      shipmentMasterId: shipmentData.shipmentMasterId,
      relationshipId: shipmentData.relationshipId,
    });
  };

  

  render() {
    const { size } = this.state;
    const relationshipBillingAddress =
      this.props.relationshipBillingAddress || {};
    const customerShippingAddress = this.props.customerShippingAddress || {};
    const customerBillingAddress = this.props.customerBillingAddress || {};
    const customerPaymentTerm = this.props.customerPaymentTerm || {};
    const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
    if (rowNode) {
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_SHIPPING_ADDRESS_FIELD
      ] = customerShippingAddress.streetAddress1 || "";
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS_FIELD
      ] = customerBillingAddress.streetAddress1 || "";
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PLACE_OF_SUPPLY_FIELD
      ] = this.props.placeOfSupply || "";
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD
      ] = customerPaymentTerm.termName || "";
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.CUSTOMER_PO_FIELD
      ] = this.props.customerPo || "";
      rowNode.data["ref"] = this.props.customerRef || "";
      rowNode.data.soNumber = this.props.soNumber;
      rowNode.data[AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT] =
        this.props.projectName || "";
      this.gridApi.refreshCells({ force: true });
      this.gridApi.sizeColumnsToFit();
    }

    return (
      <Fragment>
        <Row>
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
                <Col span={24} className="details-heading">
                Organization Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.name"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="deatils-field-value">
                  {(this.props.companyInfo || {}).storeName}
                </div>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.adress"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.companyBillingAddress",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <span>
                  {!this.props.addressHide &&
                  this.props.relationshipBillingAddress ? (
                    <>
                      <div className="detail-address-value">
                        <div style={{ height: "96px", paddingTop: "10px" }}>
                          {getAddressInfoFragment(
                            this.props.relationshipBillingAddress || {},
                            {},
                            this.props.companyInfo
                          )}
                        </div>
                        <div>
                          <EditOutlined
                            onClick={() => {
                              this.props.updateState({
                                addressHide: true,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <Dropdown
                      style={{ width: "100%" }}
                      items={this.props.allRelationshipBillingAddress || []}
                      value={relationshipBillingAddress.streetAddress1}
                      onSelect={(value) => {
                        const selectedRelationshipAddress = find(
                          this.props.allRelationshipBillingAddress,
                          { locationId: Number(value) }
                        );
                        this.props.updateState({
                          relationshipBillingAddress:
                            selectedRelationshipAddress,
                          addressHide: false,
                        });
                      }}
                      canAddNew={true}
                      canDelete={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS
                        );
                      }}
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({
                          relationshipBillingAddress: undefined,
                        });
                      }}
                    />
                  )}
                </span>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.form.taxIdentification.label"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.taIdentification",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <MultipleSelectDropdown
                  style={{ width: "100%" }}
                  items={this.props.allRelationshipTaxIdentificatins || []}
                  placeholder="Tax Identification(s)"
                  mode="multiple"
                  onDeSelect={(value) => {
                    let { selectedRelationshipTaxIdentifications } = this.props;
                    selectedRelationshipTaxIdentifications = filter(
                      selectedRelationshipTaxIdentifications,
                      (taxId) => {
                        return taxId !== value;
                      }
                    );
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications,
                    });
                  }}
                  valueKeyName="taxIdentificationType"
                  valueKeyName2="taxIdentificationNumber"
                  selectedValue={
                    (this.props.allRelationshipTaxIdentificatins || []).length
                      ? this.props.selectedRelationshipTaxIdentifications || []
                      : []
                  }
                  optionKeyName="relationshipTaxIdentificationId"
                  onSelect={(relationshipTaxIdentificationId) => {
                    let selectedRelationshipTaxIdentifications =
                      this.props.selectedRelationshipTaxIdentifications || [];
                     
                     
                    if (
                      selectedRelationshipTaxIdentifications.indexOf(
                        relationshipTaxIdentificationId
                      ) === -1
                    ) {
                      selectedRelationshipTaxIdentifications.push(
                        relationshipTaxIdentificationId
                      );
                    } else {
                      selectedRelationshipTaxIdentifications =
                        selectedRelationshipTaxIdentifications.filter((el) => {
                          return el !== selectedRelationshipTaxIdentifications;
                        });
                    }
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications,
                    });

                  }}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      taxIdentificationNumber: payload.textEntered,
                      submittedOnce: false,
                    };
                    this.handleNewTaxIdentificationAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.NEW_TAX_IDENTIFICATION
                    );
                  }}
                />
              </Col>
            </Row>
            </Skeleton>
          </Col>

          <Col span={1}></Col>

          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Customer Information
              </Col>

              {this.props.customerName ? (
                <>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="customer.salesOrder.form.customer.label"
                        defaultMessage=""
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="deatils-field-value">
                      {this.props.customerName}
                    </div>
                  </Col>

                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage id="contact" defaultMessage="" />
                      <Tooltip
                        placement="right"
                        title={this.props.intl.formatMessage({
                          id: "tooltip.customerContact",
                          defaultMessage: "",
                        })}
                        trigger="click"
                      >
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Dropdown
                      style={{ width: "100%" }}
                      value={
                        ((this.props.selectedContact || {}).firstName || "") +
                        " " +
                        ((this.props.selectedContact || {}).lastName || "")
                      }
                      optionKeyName="contactId"
                      items={this.props.boContactList}
                      valueKeyName="firstName"
                      lastNameKey="lastName"
                      isFavouriteKeyName="isPrimaryContact"
                      placeholder="Please select contact name"
                      moduleName={MODULE_CODED_VALUES.CUSTOMER_CONTACTS}
                      canAddNew={true}
                      canDelete={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.CONTACT,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          firstName: payload.textEntered,
                          submittedOnce: false,
                          customerId: (this.props.customer || {}).customerId,
                          pageNumber: this.props.pageNumber || 1,
                          customerName: this.props.customerName,
                        };
                        this.handleNewContactAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.CONTACT
                        );
                      }}
                      onSelect={(contactId) => {
                        let contact =
                          find(this.props.boContactList || [], {
                            contactId: +contactId,
                          }) || {};
                        this.props.updateState({
                          selectedContact: contact,
                        });
                      }}
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({
                          selectedContact: undefined,
                        });
                      }}
                    />

                    {getAddressInfoFragment(
                      {},
                      this.props.selectedContact || {},
                      this.props.customer
                    )}
                  </Col>

                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="customer.form.taxIdentification.label"
                        defaultMessage=""
                      />
                      <Tooltip
                        placement="right"
                        title={this.props.intl.formatMessage({
                          id: "taxIdentification.tooltip",
                          defaultMessage: "",
                        })}
                        trigger="click"
                      >
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </div>
                  </Col>

                  <Col span={24}>
                    <MultipleSelectDropdown
                      style={{ width: "100%" }}
                      items={ 
                        this.props.customer.customerTaxIdentificationsList || []
                      }
                      placeholder="Tax Identification(s)"
                      mode="multiple"
                      onDeSelect={(value) => {
                        let { selectedCustomerTaxIdentifications } = this.props;
                        selectedCustomerTaxIdentifications = filter(
                          selectedCustomerTaxIdentifications,
                          (taxId) => {
                            return taxId !== value;
                          }
                        );
                        this.props.updateState({
                          selectedCustomerTaxIdentifications,
                        });
                      }}
                      valueKeyName="taxIdentificationType"
                      valueKeyName2="taxIdentificationNumber"
                      selectedValue={
                        (
                          this.props.customer.customerTaxIdentificationsList ||
                          []
                        ).length
                          ? this.props.selectedCustomerTaxIdentifications || []
                          : []
                      }
                      optionKeyName="customerTaxIdentificationId"
                      onSelect={(customerTaxIdentificationId) => {
                        let selectedCustomerTaxIdentifications =
                          this.props.selectedCustomerTaxIdentifications || [];
                        if (
                          selectedCustomerTaxIdentifications.indexOf(
                            customerTaxIdentificationId
                          ) === -1
                        ) {
                          selectedCustomerTaxIdentifications.push(
                            customerTaxIdentificationId
                          );
                        } else {
                          selectedCustomerTaxIdentifications =
                            selectedCustomerTaxIdentifications.filter((el) => {
                              return el !== customerTaxIdentificationId;
                            });
                        }
                        this.props.updateState({
                          selectedCustomerTaxIdentifications,
                        });
                      }}
                      canAddNew={true}
                      canDelete={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          firstName: payload.textEntered,
                          submittedOnce: false,
                          customerId: (this.props.customer || {}).customerId,
                          pageNumber: this.props.pageNumber || 1,
                          customerName: this.props.customerName,
                        };
                        this.handleNewTaxIdentificationAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.NEW_TAX_IDENTIFICATION
                        );
                      }}
                    />
                  </Col>

                  <>
                    {this.props.customerName && !this.props.isCashSales ? (
                      <Form.Item>
                        {false && (
                          <Row>
                            <Col span={9} className="text-right">
                              <FormattedMessage
                                id="customer.salesInvoice.form.loadFromShipment.label"
                                defaultMessage=""
                              />
                            </Col>
                            <Col span={15}>
                              <Row className="pt10">
                                <Col span={3}>
                                  {
                                    /* <Checkbox checked={(this.props.isShipmentConversion === 1)} onChange={(e) => {
                                                                this.props.updateState({
                                                                    isShipmentConversion: e.target.checked ? 1 : 0,
                                                                    tableDataReRendered: true
                                                                });
                                                                if (e.target.checked) {
                                                                    this.props.fetchShipmentForInvoice({
                                                                        relationshipId: (this.props.companyInfo || {}).relationshipId,
                                                                        customerId: this.props.customer.customerId
                                                                    });
                                                                    const selectedSalesOrder = this.props.selectedSalesOrder;
                                                                    this.props.updateState({
                                                                        selectedSalesOrder: [],
                                                                        customerSalesOrderDetailsList: [],
                                                                        preservedSalesOrder: selectedSalesOrder,
                                                                        isShipment: true
                                                                    });
                                                                } else {
                                                                    let detailList = []
                                                                    this.props.preservedSalesOrder.forEach((e) => {
                                                                        detailList = detailList.concat(e.customerSalesOrderDetailsList);
                                                                    })
                                                                    this.props.updateState({
                                                                        selectedSalesOrder: this.props.preservedSalesOrder || [],
                                                                        customerSalesOrderDetailsList: detailList,
                                                                        preservedSalesOrder: [],
                                                                        isShipment: false
                                                                    });
                                                                }
                                                            }}></Checkbox> */
                                    <div className="input-currency-control">
                                      <span className="currency-tag">
                                        <Radio
                                          style={{
                                            "margin-top": "3px",
                                            paddingTop: "1px",
                                            marginLeft: "5px",
                                          }}
                                          checked={this.state.isShipmentRadio}
                                          onChange={(e) => {
                                            this.props.updateState({
                                              isShipmentConversion: e.target
                                                .checked
                                                ? 1
                                                : 0,
                                              tableDataReRendered: true,
                                            });
                                            if (e.target.checked) {
                                              this.props.fetchShipmentForInvoice(
                                                {
                                                  relationshipId: (
                                                    this.props.companyInfo || {}
                                                  ).relationshipId,
                                                  customerId:
                                                    this.props.customer
                                                      .customerId,
                                                }
                                              );
                                              const selectedSalesOrder =
                                                this.props.selectedSalesOrder;
                                              this.props.updateState({
                                                selectedSalesOrder: [],
                                                customerSalesOrderDetailsList:
                                                  [],
                                                preservedSalesOrder:
                                                  selectedSalesOrder,
                                                isShipment: true,
                                              });
                                            } else {
                                              let detailList = [];
                                              this.props.preservedSalesOrder.forEach(
                                                (e) => {
                                                  detailList =
                                                    detailList.concat(
                                                      e.customerSalesOrderDetailsList
                                                    );
                                                }
                                              );
                                              this.props.updateState({
                                                selectedSalesOrder:
                                                  this.props
                                                    .preservedSalesOrder || [],
                                                customerSalesOrderDetailsList:
                                                  detailList,
                                                selectedSalesOrderIds: [],
                                                preservedSalesOrder: [],
                                                isShipment: false,
                                              });
                                            }
                                            this.setState({
                                              isSORadio: false,
                                              isShipmentRadio: true,
                                            });

                                            this.props.updateState({
                                              isSORadio: false,
                                              isShipmentRadio: true,
                                              selectedSalesOrder: [],
                                              selectedSalesOrderIds: [],
                                            });
                                          }}
                                        />
                                      </span>
                                    </div>
                                  }
                                </Col>
                                <Col span={14} className="new-search">
                                  {" "}
                                  {
                                    <span>
                                      <span>
                                        <Dropdown
                                          style={{
                                            width: "100%",
                                            marginTop: -18,
                                            marginLeft: -1,
                                            top: -3,
                                          }}
                                          items={
                                            this.state.isShipmentRadio
                                              ? this.props.soShipmentList
                                              : []
                                          }
                                          valueKeyName="shipmentNumber"
                                          optionKeyName="shipmentMasterId"
                                          customerName="customerName"
                                          dateKeyName2="shipmentDate"
                                          optionClassAddress="custom-address-dropdown"
                                          placeholder="Please select shipment"
                                          mode={"multiple"}
                                          onSelect={(selectedValue) => {
                                            let shipmentData =
                                              find(this.props.soShipmentList, {
                                                shipmentMasterId: selectedValue,
                                              }) || {};
                                            this.getInvoiceDataFromShipment(
                                              this.props,
                                              shipmentData
                                            );
                                            this.props.updateState({
                                              tableDataReRendered: true,
                                            });
                                          }}
                                          onDeSelect={(value) => {
                                            let {
                                              selectedSalesOrder,
                                              customerSalesOrderDetailsList,
                                            } = this.props;
                                            selectedSalesOrder = filter(
                                              selectedSalesOrder,
                                              (obj) => {
                                                return (
                                                  Number(
                                                    obj.shipmentMasterId
                                                  ) !== Number(value)
                                                );
                                              }
                                            );
                                            customerSalesOrderDetailsList =
                                              filter(
                                                customerSalesOrderDetailsList,
                                                (soData) => {
                                                  return (
                                                    Number(
                                                      soData.shipmentMasterId
                                                    ) !== Number(value)
                                                  );
                                                }
                                              );

                                            this.props.updateState({
                                              customerSalesOrderDetailsList,
                                              selectedSalesOrder,
                                            });
                                          }}
                                        />
                                      </span>
                                    </span>
                                  }{" "}
                                </Col>
                                <Col span={1}>
                                  <Tooltip
                                    placement="right"
                                    title={this.props.intl.formatMessage({
                                      id: "tooltip.enableToCreateShipment",
                                      defaultMessage: "",
                                    })}
                                    trigger="click"
                                  >
                                    <div style={{ marginTop: "-4px" }}>
                                      <i className="fa fa-question-circle-o  cursor-pointer" />
                                    </div>
                                  </Tooltip>
                                </Col>
                              </Row>
                            </Col>

                            <Col span={9} className="text-right pt5">
                              <FormattedMessage
                                id="customer.salesInvoice.form.loadFromSo.label"
                                defaultMessage=""
                              />
                            </Col>
                            <Col span={15}>
                              <Row className="pt10">
                                <Col span={3}>
                                  {
                                    /* <Checkbox checked={(this.props.isShipmentConversion === 1)} onChange={(e) => {
                                                                this.props.updateState({
                                                                    isShipmentConversion: e.target.checked ? 1 : 0,
                                                                    tableDataReRendered: true
                                                                });
                                                                if (e.target.checked) {
                                                                    this.props.fetchShipmentForInvoice({
                                                                        relationshipId: (this.props.companyInfo || {}).relationshipId,
                                                                        customerId: this.props.customer.customerId
                                                                    });
                                                                    const selectedSalesOrder = this.props.selectedSalesOrder;
                                                                    this.props.updateState({
                                                                        selectedSalesOrder: [],
                                                                        customerSalesOrderDetailsList: [],
                                                                        preservedSalesOrder: selectedSalesOrder,
                                                                        isShipment: true
                                                                    });
                                                                } else {
                                                                    let detailList = []
                                                                    this.props.preservedSalesOrder.forEach((e) => {
                                                                        detailList = detailList.concat(e.customerSalesOrderDetailsList);
                                                                    })
                                                                    this.props.updateState({
                                                                        selectedSalesOrder: this.props.preservedSalesOrder || [],
                                                                        customerSalesOrderDetailsList: detailList,
                                                                        preservedSalesOrder: [],
                                                                        isShipment: false
                                                                    });
                                                                }
                                                            }}></Checkbox> */
                                    <div className="input-currency-control">
                                      <span className="currency-tag">
                                        <Radio
                                          style={{
                                            "margin-top": "3px",
                                            paddingTop: "1px",
                                            marginLeft: "5px",
                                          }}
                                          checked={this.state.isSORadio}
                                          onChange={(e) => {
                                            this.props.updateState({
                                              isShipmentConversion: e.target
                                                .checked
                                                ? 1
                                                : 0,
                                              tableDataReRendered: true,
                                            });
                                            if (e.target.checked) {
                                              this.props.fetchShipmentForInvoice(
                                                {
                                                  relationshipId: (
                                                    this.props.companyInfo || {}
                                                  ).relationshipId,
                                                  customerId:
                                                    this.props.customer
                                                      .customerId,
                                                }
                                              );
                                              const selectedSalesOrder =
                                                this.props.selectedSalesOrder;
                                              this.props.updateState({
                                                selectedSalesOrder: [],
                                                customerSalesOrderDetailsList:
                                                  [],
                                                preservedSalesOrder:
                                                  selectedSalesOrder,
                                                isShipment: true,
                                              });
                                            } else {
                                              let detailList = [];
                                              this.props.preservedSalesOrder.forEach(
                                                (e) => {
                                                  detailList =
                                                    detailList.concat(
                                                      e.customerSalesOrderDetailsList
                                                    );
                                                }
                                              );
                                              this.props.updateState({
                                                selectedSalesOrder:
                                                  this.props
                                                    .preservedSalesOrder || [],
                                                customerSalesOrderDetailsList:
                                                  detailList,
                                                preservedSalesOrder: [],
                                                isShipment: false,
                                              });
                                            }

                                            this.setState({
                                              isSORadio: true,
                                              isShipmentRadio: false,
                                            });
                                            this.props.updateState({
                                              isSORadio: true,
                                              isShipmentRadio: false,
                                            });
                                          }}
                                        />
                                      </span>
                                    </div>
                                  }
                                </Col>
                                <Col span={14} className="new-search">
                                  {" "}
                                  {
                                    <span>
                                      <MultipleSelectDropdown
                                        style={{
                                          width: "100%",
                                          marginTop: -18,
                                          marginLeft: -1,
                                          top: -4,
                                        }}
                                        items={
                                          this.state.isSORadio
                                            ? this.props.soUnfulfilledList
                                            : []
                                        }
                                        selectedValue={
                                          this.state.isSORadio
                                            ? this.props.selectedSalesOrderIds
                                            : []
                                        }
                                        valueKeyName="soNumber"
                                        dateKeyName2="salesOrderDate"
                                        customerName="customerName"
                                        optionClassAddress="custom-address-dropdown"
                                        optionKeyName="salesOrderMasterId"
                                        placeholder="Please select sales order"
                                        mode={"multiple"}
                                        onSelect={(selectedValue) => {
                                          let soData =
                                            find(this.props.soUnfulfilledList, {
                                              salesOrderMasterId: selectedValue,
                                            }) || {};
                                          this.getInvoiceData(
                                            this.props,
                                            soData
                                          );
                                          this.props.updateState({
                                            tableDataReRendered: true,
                                          });
                                        }}
                                        onDeSelect={(value) => {
                                          let {
                                            customerSalesOrderDetailsList,
                                            selectedSalesOrder,
                                          } = this.props;
                                          customerSalesOrderDetailsList =
                                            filter(
                                              customerSalesOrderDetailsList,
                                              (soData) => {
                                                return (
                                                  Number(
                                                    soData.salesOrderMasterId
                                                  ) !== Number(value)
                                                );
                                              }
                                            );
                                          selectedSalesOrder = filter(
                                            selectedSalesOrder,
                                            (soData) => {
                                              return (
                                                Number(
                                                  soData.salesOrderMasterId
                                                ) !== Number(value)
                                              );
                                            }
                                          );
                                          let isSoConversion =
                                            selectedSalesOrder.length > 0
                                              ? true
                                              : false;

                                          this.props.updateState({
                                            tableDataReRendered: true,
                                            customerSalesOrderDetailsList,
                                            isSoConversion,
                                            selectedSalesOrder,
                                          });
                                        }}
                                      />
                                    </span>
                                  }{" "}
                                </Col>
                                <Col span={1}>
                                  <Tooltip
                                    placement="right"
                                    title={this.props.intl.formatMessage({
                                      id: "tooltip.enableToCreateShipment",
                                      defaultMessage: "",
                                    })}
                                    trigger="click"
                                  >
                                    <div style={{ marginTop: "-4px" }}>
                                      <i className="fa fa-question-circle-o  cursor-pointer" />
                                    </div>
                                  </Tooltip>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </Form.Item>
                    ) : (
                      ""
                    )}
                  </>
                  <Row>
                    <Col span={9} />
                    <Col span={15}></Col>
                  </Row>
                </>
              ) : (
                "Please select customer for details"
              )}
            </Row>
            </Skeleton>
          </Col>

          <Col span={1}></Col>

          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Delivery Information
              </Col>
              { (this.props.companyInfo || {}).countryName ==="India" ?
              <><Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="customer.salesInvoice.form.gridHeader.placeOfSupply"
                        defaultMessage="" />
                    </div>
                  </Col><Col span={24}>
                      <Dropdown
                        lastColumnCell={false}
                        items={this.props.states || []}
                        optionKeyName="stateId"
                        valueKeyName="stateName"
                        // canAddNew: true,
                        // canDelete: true,
                        value={this.props.placeOfSupply}
                        onSelect={(selectedValue, option) => {
                          this.props.updateState({
                            placeOfSupply: option.name,
                          });
                        } } />
                    </Col></>
              :""}
              
              

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesInvoice.form.transportData.label"
                    defaultMessage=""
                  />
                </div>
                {/* <Tooltip placement="bottom" title={this.props.intl.formatMessage(
                                                {
                                                    id: 'tooltip.qualityChekSetting',
                                                    defaultMessage: ''
                                                })
                                            }>
                                                <span className="tooltip-title">
                                                    <FormattedMessage id='customer.salesInvoice.form.transportData.label' defaultMessage='' />
                                                </span>
                                            </Tooltip> */}
              </Col>
              {/* <Col span={24}>
                <div
                  className="cursor-pointer deatils-field-value"
                  onClick={() => {
                    this.props.updateState({
                      title: (
                        <div className="drawer-title">
                          <FormattedMessage
                            id="customer.salesInvoice.form.transportData.label"
                            defaultMessage=""
                          />
                        </div>
                      ),
                      transportDataDrawerVisible: true,
                      addTransportDataToSO: function (transportData) {
                        this.updateState({
                          ...transportData,
                        });
                      },
                    });
                  }}
                >
                  <FormattedMessage
                    id="forgot.btn.addTransportData"
                    defaultMessage=""
                  />
                </div>
              </Col> */}
                <Col span={24}>
                {this.props.nameOfTransport ? 
                  <Row>
                    <Col span={22}>
                      Name - {this.props.nameOfTransport}
                    </Col>
                    <Col span={2}>
                      <i className={ICONS.EDIT_P} onClick={() => {
                    this.props.updateState({
                      title: (
                        <div className="drawer-title">
                          <FormattedMessage
                            id="customer.salesInvoice.form.transportData.label"
                            defaultMessage=""
                          />
                        </div>
                      ),
                      transportDataDrawerVisible: true,
                      addTransportDataToSO: function (transportData) {
                        this.updateState({
                          ...transportData,
                        });
                      },
                    });
                  }}/>
                    </Col>

                    {!!this.props.vehicleNumber && 
                      <Col span={22}>
                        Vehicle Number - {this.props.vehicleNumber}
                      </Col>
                    }
                    {!!this.props.freightType && 
                      <Col span={22}>
                        Freight Type - {this.props.freightType}
                      </Col>
                    }
                    {!! this.props.Consignee &&
                      <Col span={22}>
                        Consignee - {this.props.Consignee}
                      </Col>
                    }
                    {!!this.props.station && 
                      <Col span={22}>
                        Station - {this.props.station}
                      </Col>
                    }
                  </Row>
                  :
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      this.props.updateState({
                        title: (
                          <div className="drawer-title">
                            <FormattedMessage
                              id="customer.salesInvoice.form.transportData.label"
                              defaultMessage=""
                            />
                          </div>
                        ),
                        transportDataDrawerVisible: true,
                        addTransportDataToSO: function (transportData) {
                          this.updateState({
                            ...transportData,
                          });
                        },
                      });
                    }}
                  >
                    <FormattedMessage
                      id="forgot.btn.addTransportData"
                      defaultMessage=""
                    />
                  </div>
                }
              </Col>
             {/* { this.props.isCashSales  ? <Col span={24}>
              <Dropdown
                  items={this.props.paymentModeList || []}
                  className={
                    this.props.submittedOnce && !this.props.paymentModeId
                      ? "input-text-error"
                      : ""
                  }
                  valueKeyName="paymentModeName"
                  optionKeyName="paymentModeId"
                  value={
                    this.props.paymentModeList.length
                      ? this.props.paymentModeName || this.props.paymentModeId
                      : undefined
                  }
                  placeholder={this.props.intl.formatMessage({
                    id: "modal.customer.payInvoice.paymentMode.placeholder",
                    defaultMessage: "",
                  })}
                  onSelect={(optionValue) => {
                    let selectedPaymentMode =
                      (this.props.paymentModeList || []).find(
                        (pay) => pay.paymentModeId === optionValue
                      ) || {};
                      this.props.updateState({
                      paymentModeId: optionValue,
                      paymentModeName: selectedPaymentMode.paymentModeName,
                      chartOfAccountDetailsIdOfPayment:
                        selectedPaymentMode.paymentModeName === "Cash"
                          ? this.props.cashInHandLedger.ledgerAccountId
                          : 0,
                      ledgerAccountNameOfPayment:
                        selectedPaymentMode.paymentModeName === "Cash"
                          ? this.props.cashInHandLedger.txType
                          : "",
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      paymentModeId: undefined,
                      paymentModeName: undefined,
                    });
                  }}
                />
              </Col> : ''} */}
            </Row>
            </Skeleton>
          </Col>

          <Col span={1}></Col>

          <Col span={6} className="order-value-info">
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Invoice Information
              </Col>

              {/* <Col span={12} className='text-right'>
                                            <Tooltip placement="bottom" title={this.props.intl.formatMessage(
                                                {
                                                    id: 'tooltip.generateSI',
                                                    defaultMessage: ''
                                                })
                                            }>
                                                <span className="tooltip-title">
                                                    <FormattedMessage
                                                        id='customer.salesInvoice.form.invoiceNumber.label'
                                                        defaultMessage='' />
                                                </span>
                                            </Tooltip>
                                        </Col> */}
              {/* <Col span={12} style={{ 'textAlign': 'left' }}>
                                            {this.props.nextTxNumber ?
                                                <span>{getFormatedNextTxNumber(this.props)}</span>
                                                :
                                                <img src={LoadingIcon} alt="Invoice" style={{
                                                    'height': '74px',
                                                    'marginTop': '-25px',
                                                    'marginBottom': '-25px'
                                                }} />
                                            }   
                                        </Col> */}

              {this.props.isSoConversionToInvoice ? (
                <div className="project-dropdown">
                  <Row>
                    <Col span={12} className="text-right">
                      <span className="tooltip-title">
                        <FormattedMessage
                          id="customer.salesInvoice.form.soNumber.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <span>{this.props.soNumber}</span>
                    </Col>
                  </Row>
                </div>
              ) : null}

              {this.props.isShipmentConversion &&
              this.props.isShipmentConversion ? (
                <div className="project-dropdown">
                  <Row>
                    <Col span={12} className="text-right">
                      <span className="tooltip-title">
                        <FormattedMessage
                          id="modal.txDetails.shipment.shipmentNumber"
                          defaultMessage=""
                        />
                      </span>
                    </Col>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <span>{this.props.shipmentNumber}</span>
                    </Col>
                  </Row>
                </div>
              ) : null}

              {/* {(((this.props.selectedSalesOrderIds || []).length || this.props.isShipmentConversion || this.props.invoiceMasterId) && !this.props.isUpdate)? <div className="project-dropdown">
                                    <Row>
                                        <Col span={12} className='text-right'>

                                        </Col>
                                        <Col span={12} style={{ 'textAlign': 'left' }}>
                                            <Checkbox checked={this.state.isToShowAllSOShipment} onChange={(e) => {
                                                this.setState({
                                                    isToShowAllSOShipment: e.target.checked
                                                });

                                            }}><FormattedMessage id='showAllList.text' defaultMessage='' /></Checkbox>

                                        </Col>
                                    </Row>
                                </div>: null} */}

              {this.state.isToShowAllSOShipment &&
              this.props.isShipmentConversion ? (
                <div className="project-dropdown">
                  <Row>
                    <Col span={12} className="text-right"></Col>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Dropdown
                        style={{
                          width: "100%",
                          marginTop: -18,
                          marginLeft: -1,
                          top: -3,
                        }}
                        items={
                          this.state.isShipmentRadio
                            ? this.props.soShipmentList
                            : []
                        }
                        valueKeyName="shipmentNumber"
                        optionKeyName="shipmentMasterId"
                        customerName="customerName"
                        dateKeyName2="shipmentDate"
                        optionClassAddress="custom-address-dropdown"
                        placeholder="Please select shipment"
                        mode={"multiple"}
                        onSelect={(selectedValue) => {
                          let shipmentData =
                            find(this.props.soShipmentList, {
                              shipmentMasterId: selectedValue,
                            }) || {};
                          this.getInvoiceDataFromShipment(
                            this.props,
                            shipmentData
                          );
                          this.props.updateState({
                            tableDataReRendered: true,
                          });
                        }}
                        onDeSelect={(value) => {
                          let {
                            selectedSalesOrder,
                            customerSalesOrderDetailsList,
                          } = this.props;
                          selectedSalesOrder = filter(
                            selectedSalesOrder,
                            (obj) => {
                              return (
                                Number(obj.shipmentMasterId) !== Number(value)
                              );
                            }
                          );
                          customerSalesOrderDetailsList = filter(
                            customerSalesOrderDetailsList,
                            (soData) => {
                              return (
                                Number(soData.shipmentMasterId) !==
                                Number(value)
                              );
                            }
                          );

                          this.props.updateState({
                            customerSalesOrderDetailsList,
                            selectedSalesOrder,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}

              {this.state.isToShowAllSOShipment &&
              (this.props.selectedSalesOrderIds || []).length ? (
                <div className="project-dropdown">
                  <Row>
                    <Col span={12} className="text-right"></Col>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <MultipleSelectDropdown
                        style={{
                          width: "100%",
                          marginTop: -18,
                          marginLeft: -1,
                          top: -4,
                        }}
                        items={this.props.soUnfulfilledList}
                        selectedValue={this.props.selectedSalesOrderIds}
                        valueKeyName="soNumber"
                        dateKeyName2="salesOrderDate"
                        customerName="customerName"
                        optionClassAddress="custom-address-dropdown"
                        optionKeyName="salesOrderMasterId"
                        placeholder="Please select sales order"
                        mode={"multiple"}
                        onSelect={(selectedValue) => {
                          let soData =
                            find(this.props.soUnfulfilledList, {
                              salesOrderMasterId: selectedValue,
                            }) || {};
                          this.getInvoiceData(this.props, soData);
                          this.props.updateState({
                            tableDataReRendered: true,
                          });
                        }}
                        onDeSelect={(value) => {
                          let {
                            customerSalesOrderDetailsList,
                            selectedSalesOrder,
                          } = this.props;
                          customerSalesOrderDetailsList = filter(
                            customerSalesOrderDetailsList,
                            (soData) => {
                              return (
                                Number(soData.salesOrderMasterId) !==
                                Number(value)
                              );
                            }
                          );
                          selectedSalesOrder = filter(
                            selectedSalesOrder,
                            (soData) => {
                              return (
                                Number(soData.salesOrderMasterId) !==
                                Number(value)
                              );
                            }
                          );
                          let isSoConversion =
                            selectedSalesOrder.length > 0 ? true : false;

                          this.props.updateState({
                            tableDataReRendered: true,
                            customerSalesOrderDetailsList,
                            isSoConversion,
                            selectedSalesOrder,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}

              <Col span={24}>
                <div className="title" style={{ textAlign: "left" }}>
                  <FormattedMessage
                    id="customer.salesInvoice.form.invoiceDate.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="value">
                  <DatePicker
                    allowClear={false}
                    format={format}
                    key={`${
                      this.props.invoiceDate
                        ? getMomentDateForUI({
                            date: new Date(this.props.invoiceDate),
                            format,
                          })
                        : getMomentDateForUI({ date: new Date(), format })
                    }`}
                    defaultValue={
                      this.props.invoiceDate
                        ? getMomentDateForUI({
                            date: new Date(this.props.invoiceDate),
                            format,
                          })
                        : getMomentDateForUI({ date: new Date(), format })
                    }
                    onChange={(selectedDate) => {
                      let selectedterm = this.props.customerPaymentTerm || {};
                      const updatedInvoiceDueDate = addDaysToDate(
                        selectedDate || new Date(),
                        selectedterm.numberOfDays
                      );
                      this.props.updateState({
                        paymentTermDays: selectedterm.numberOfDays,
                        invoiceDate: selectedDate,
                        invoiceDueDate: updatedInvoiceDueDate,
                      });
                      // this.props.getNextTxNumber({
                      //     date: selectedDate,
                      //     relationshipId: (this.props.companyInfo || {}).relationshipId
                      // });
                    }}
                    size={size}
                  />
                </div>
              </Col>

              <Col span={24}>
              <div className="title" style={{ textAlign: "left" }}>
                  <FormattedMessage
                    id="customer.salesInvoice.form.gridHeader.paymentTerms"
                    defaultMessage=""
                  />
                </div>
              </Col>

              <Col span={24}>
                <Dropdown
                  placeholder={this.props.intl.formatMessage({
                    id: "customer.salesInvoice.form.gridHeader.paymentTerms",
                    defaultMessage: "",
                  })}
                  lastColumnCell={false}
                  items={this.props.paymentTerms}
                  optionKeyName="paymentTermId"
                  valueKeyName="termName"
                  isLockKeyName="isSystemGenerated"
                  //isFavouriteKeyName: 'isDefault',
                  canAddNew={true}
                  value={(this.props.customerPaymentTerm || {}).termName}
                  canDelete={true}
                  permissions={this.props.permissions}
                  //moduleName: MODULE_CODED_VALUES.PAYMENT_TERM,
                  //operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.PAYMENT_TERM,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      termName: payload.textEntered,
                      days: null,
                      description: "",
                      isDefaultPayment: false,
                      submittedOnce: false,
                    };
                    this.handleNewPaymentTermAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.PAYMENT_TERM
                    );
                  }}
                  onSelect={(selectedValue, optionObj) => {
                    //obj.node.setDataValue(obj.colDef.field, selectedValue)
                    const customerPaymentTerm = find(this.props.paymentTerms, {
                      paymentTermId: Number(optionObj.key),
                    });
                    const updatedInvoiceDueDate = addDaysToDate(
                      this.props.invoiceDate || new Date(),
                      customerPaymentTerm.numberOfDays
                    );
                    this.props.updateState({
                      customerPaymentTerm,
                      invoiceDueDate: updatedInvoiceDueDate,
                    });
                  }}
                />
              </Col>
              <Col span={24}>
                <span className="title" style={{ textAlign: "left" }}>
                  <FormattedMessage
                    id="customer.salesInvoice.form.dueDate.label"
                    defaultMessage=""
                  /><Tooltip
                  placement="right"
                  title="Invoice due date depends on the invoice date and payment terms."
                >
                  <QuestionCircleTwoTone />
                </Tooltip>
                </span> 
                <span className="ml20"> {getMomentLoclDateForUIReadOnly({ date: this.props.invoiceDueDate || new Date(), format  }) }</span>
              </Col>
            
              {/* <Col span={12}>
                <DatePicker
                  format={format}
                  disabled={true}
                  key={`${getMomentDateForUI({
                    date: this.props.invoiceDueDate,
                    format,
                  })}`}
                  defaultValue={getMomentDateForUI({
                    date: this.props.invoiceDueDate,
                    format,
                  })}
                  size={size}
                /> 
              </Col>*/}

              <Col span={24}>
                <FormattedMessage
                  id="customer.salesOrder.form.documentDropdown.label"
                  defaultMessage=""
                />
                <Tooltip
                  placement="right"
                  title={this.props.intl.formatMessage({
                    id: "tooltip.documentAppearInPdf",
                    defaultMessage: "",
                  })}
                >
                  <QuestionCircleTwoTone />
                </Tooltip>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={filter(this.props.documentList || [], (document) => {
                    return (
                      document.txType ===
                      CONSTANTS_TRANSACTIONS_KEY[
                        CONSTANTS_TRANSACTIONS.SALES_INVOICE
                      ]
                    );
                  })}
                  placeholder="Document name"
                  value={this.props.docName && this.props.documentNameId}
                  moduleName={MODULE_CODED_VALUES.SALES_INVOICE}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.DOCUMENT_NAME,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                      maxLength: 30,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.DOCUMENT_NAME
                    );
                  }}
                  onSelect={(selectedValue) => {
                    const selectedObj = find(this.props.documentList || [], {
                      documentNameId: Number(selectedValue),
                    });
                    this.props.updateState({
                      documentNameId: selectedValue,
                      docName: selectedObj.docName,
                    });
                  }}
                  valueKeyName="docName"
                  optionKeyName="documentNameId"
                  isLockKeyName="isSystemGenerated"
                  // allowClear={true}
                  // onClear={() => {
                  //     this.props.updateState({
                  //         documentNameId: undefined,
                  //         docName: undefined
                  //     })
                  // }}
                />
              </Col>

              <Col span={24}>
              <div className="i-label">Document Type</div>
           <TextBox

           disabled={true}
            value={this.state.invoiceType}
           onChange={(event)=>{
          
                    //  props.updateState({
                    //   invoiceNumber:event.target.value
                    //  })
           }}
           placeholder="Document Type"/>
              </Col>
            </Row>
            </Skeleton>
          </Col>
        </Row>

        {/* <Row>
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        disableFocusOnClickOutside={true}
                        rowData={[{}]}
                        gridStyle={{
                            width: '100%',
                            height: '100px'
                        }} />
                </Row> */}
        <Row>
        <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 2 }} active>

          <Col span={24}>
            <div className="txn-table">
              <table>
                <thead>
                  <tr>
                    {this.props.isManualInvoiceNumber?<th>
                     Customer Invoice #
                    </th>:""}
                    <th>
                      <FormattedMessage
                        id="customer.salesInvoice.form.gridHeader.customerPO"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="customer.salesInvoice.form.gridHeader.refNumber"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="customer.salesInvoice.form.gridHeader.billingAddress"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="customer.salesInvoice.form.gridHeader.shippingAddress"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="customer.salesInvoice.form.projectDropdown.label"
                        defaultMessage=""
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                   {this.props.isManualInvoiceNumber ? <td>
                      <TextBox
                        value={this.props.manualInvoiceNumber}
                        onBlur={()=>{ 
                          if(this.props.manualInvoiceNumber?.length &&  this.props.customerinvoiceNoReplica !== this.props.manualInvoiceNumber && this.props.customer){
                            this.props.checkManualInvoiceNumber(this.props,this.props.manualInvoiceNumber,this.props.customer?.customerId)
                          }
                        }}
                        onChange={(val) => {
                          this.props.updateState({
                            manualInvoiceNumber: val.target.value,
                          });
                        }}
                      />
                    </td>:""}
                    <td>
                      <TextBox
                        value={this.props.customerPo}
                        onBlur={()=>{ 
                          if(this.props.customerPo?.length &&  this.props.customerInvoicePoReplica !== this.props.customerPo && this.props.customer){
                            this.props.checkCustomerPoNumber(this.props,this.props.customerPo,this.props.customer?.customerId)
                          }
                        }}
                        onChange={(val) => {
                          this.props.updateState({
                            customerPo: val.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <TextBox
                        value={this.props.customerRef}
                        onChange={(val) => {
                          this.props.updateState({
                            customerRef: val.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown
                        lastColumnCell={false}
                        items={this.props.allBillingAddress}
                        value={
                          (this.props.customerBillingAddress || {})
                            .streetAddress1
                        }
                        optionKeyName="locationId"
                        valueKeyName="streetAddress1"
                        addressLineKey1="streetAddress2"
                        addressLineKey2="cityName"
                        addressLineKey3="stateName"
                        addressLineKey4="zipCode"
                        isFavouriteKeyName="isDefault"
                        optionClassAddress="custom-address-dropdown"

                        canAddNew={true}
                        canDelete={true}
                        permissions={this.props.permissions}
                        moduleName={
                          MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS
                        }
                        operations={[
                          PERMISSION_VALUES.CREATE,
                          PERMISSION_VALUES.DELETE,
                        ]}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.BILLING_ADDRESS,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            locationType: CONSTANTS.BILLING_ADDRESS,
                            customerId: (this.props.customer || {}).customerId,
                            pageNumber: this.props.pageNumber,
                          };
                          this.handleNewAddressAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.BILLING_ADDRESS
                          );
                        }}
                        onSelect={(selectedValue, optionObj) => {
                          const customerBillingAddress = find(
                            this.props.allBillingAddress,
                            { locationId: Number(optionObj.key) }
                          );
                          this.props.updateState({
                            customerBillingAddress,
                            placeOfSupply: customerBillingAddress.stateName,
                          });
                          //obj.node.setDataValue(obj.colDef.field, selectedValue)
                        }}
                        allowClear={true}
                        onClear={() => {
                          //obj.node.setDataValue(obj.colDef.field, undefined);
                          this.props.updateState({
                            customerBillingAddress: undefined,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown
                        lastColumnCell={false}
                        items={this.props.allShippingAddress}
                        value={
                          (this.props.customerShippingAddress || {})
                            .streetAddress1
                        }
                        optionKeyName="locationId"
                        valueKeyName="streetAddress1"
                        addressLineKey1="streetAddress2"
                        addressLineKey2="cityName"
                        addressLineKey3="stateName"
                        addressLineKey4="zipCode"
                        isFavouriteKeyName="isDefault"
                        optionClassAddress="custom-address-dropdown"
                        permissions={this.props.permissions}
                        moduleName={MODULE_CODED_VALUES.CUSTOMER_ADDRESS}
                        operations={[
                          PERMISSION_VALUES.CREATE,
                          PERMISSION_VALUES.DELETE,
                        ]}
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.SHIPPING_ADDRESS,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            locationType: CONSTANTS.SHIPPING_ADDRESS,
                            customerId: (this.props.customer || {}).customerId,
                            pageNumber: this.props.pageNumber,
                          };
                          this.handleNewAddressAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.SHIPPING_ADDRESS
                          );
                        }}
                        onSelect={(selectedValue, optionObj) => {
                          const customerShippingAddress = find(
                            this.props.allShippingAddress,
                            { locationId: Number(optionObj.key) }
                          );
                          this.props.updateState({
                            customerShippingAddress,
                            // placeOfSupply: customerShippingAddress.stateName,
                          });
                          //obj.node.setDataValue(obj.colDef.field, selectedValue)
                        }}
                        allowClear={true}
                        onClear={() => {
                          //obj.node.setDataValue(obj.colDef.field, undefined);
                          this.props.updateState({
                            customerShippingAddress: undefined,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown
                        lastColumnCell={false}
                        items={this.props.projectList}
                        valueKeyName="projectName"
                        value={this.props.projectName}
                        optionKeyName="projectMasterId"
                        projectIdKey="projectMasterId"
                        moduleName={MODULE_CODED_VALUES.PROJECT}
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.PROJECT,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            projectName: payload.projectName,
                          };
                          this.handleNewProjectAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.PROJECT
                          );
                        }}
                        onSelect={(selectedValue, option) => {
                          const selectedProject =
                            find(this.props.projectList, {
                              projectMasterId: Number(option.key),
                            }) || {};
                          this.props.updateState({
                            projectMasterId: selectedProject.projectMasterId,
                            projectName: selectedProject.projectName,
                            projectNumber: selectedProject.projectNumber,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          //obj.node.setDataValue(obj.colDef.field, undefined);
                          this.props.updateState({
                            projectMasterId: undefined,
                            projectName: undefined,
                            projectNumber: undefined,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          </Skeleton>
        </Row>
      </Fragment>
    );
  }
}

const getAddressInfoFragment = (obj, customerContactObj, customerObj) => {
  // if (!obj || !Object.keys(obj).length) {
  //     return '';
  // }

  let contactName = "";
  //if (customerContactObj.salutationName) contactName += customerContactObj.salutationName + ' ';
  //if (customerContactObj.firstName) contactName += customerContactObj.firstName;
  //if (customerContactObj.middleName) contactName += ' ' + customerContactObj.middleName;
  //if (customerContactObj.lastName) contactName += ' ' + customerContactObj.lastName;
  const fragmentObj = (
    <Fragment>
    <div className="billing-address first-caps">
    {obj.streetAddress1 || obj.streetAddress2 ? (    
        <div>
          {obj.streetAddress1} {obj.streetAddress2}{", "}
        </div>    
    ) : ""}
    {obj.cityName || obj.stateName || obj.zipCode ? (      
        <div>
          {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
        </div>      
    ) : ""}
    {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
  </div>
      {customerContactObj.firstName ? (
        <div className="billing-address">{contactName}</div>
      ) : (
        ""
      )}
      {customerContactObj.workPhone ? (
        <div className="billing-address">
          <FormattedMessage
            id="customer.salesOrder.form.phone.label"
            defaultMessage=""
          />{" "}
          - {customerContactObj.workPhone}
        </div>
      ) : (
        ""
      )}
      {customerContactObj.email ? (
        <div className="billing-address">
          <FormattedMessage
            id="customer.salesOrder.form.email.label"
            defaultMessage=""
          />{" "}
          - {customerContactObj.email}
        </div>
      ) : (
        ""
      )}
      {/* {
            customerObj.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.gstIn.label' defaultMessage='' /> - {customerObj.gstNumber}
                </div> :
                ''
        }
        {
            customerObj.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.pan.label' defaultMessage='' /> - {customerObj.panNumber}
                </div> :
                ''
        } */}
    </Fragment>
  );

  return fragmentObj;
};

// const getFormatedNextTxNumber = (props) => {
//     // const { customer } = props;
//     // if (!customer || !customer.customerId) {
//     //     return <span>{props.nextTxNumber}</span>;
//     // }
//     // return <span>{props.nextTxNumber.replace('INV-00', 'INV-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
//     return <span>{props.nextTxNumber}</span>;
// }

export default injectIntl(CustomerInvoiceDetails);
