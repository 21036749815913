import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, PROJECT_ACTION_LIST } from '../../../static/constants';
//import {  formatDateForBackend } from '../../../utils';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getAllProject = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PROJECT_BASE_URL}${config.API_VERSION}/${config.rootContextProject}/projectMaster/getProjectDetails?relationshipId=${payload.relationshipId}&startDate=${payload.startDate}&endDate=${payload.endDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
         dispatch({
          type: PROJECT_ACTION_LIST.PROJECT_LIST,
          data: data
        });
      })
      .catch((err = {}) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

// export const getProjectsCount = (payload) =>{
//   return dispatch => {
//     return axios
//       .get(`${config.PLAID_BASE_URL}${config.rootContextPlaid}/plaid/countTransactionInAlpide?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//          dispatch({
//           type: PROJECT_ACTION_LIST.PROJECT_COUNT,
//           data: res.data
//         });
//       })
//       .catch((err = {}) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
//         } else {
//           err.actionToCall = PROJECT_ACTION_LIST.TRANSACTION_COUNT_IN_ALPIDE;
//         }
//       })
//   }
// }

export const resetProjectList = () => {
  return dispatch => {
    dispatch({ type: PROJECT_ACTION_LIST.RESET_PROJECT_LIST });
  }
}

export const fetchProjects = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.PROJECT_BASE_URL}${config.API_VERSION}/${config.rootContextProject}/projectMaster/getAllProjects?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PROJECT_ACTION_LIST.PROJECT_DETAIL_LIST, data: data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {})] || 'Some error occurred' })
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};



export const getProjectById = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.PROJECT_BASE_URL}${config.API_VERSION}/${config.rootContextProject}/projectMaster/getProjectById?relationshipId=${payload.relationshipId}&projectMasterId=${payload.projectMasterId || 0}`
      )
      .then((res) => {
        dispatch({ type: PROJECT_ACTION_LIST.PROJECT_BY_ID, data: res.data });
      })
      .catch((err) => {
        if (err.__isRetryRequest) {
          err.actionToCall = PROJECT_ACTION_LIST.PROJECT_BY_ID;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};


export const resetProjectById = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
        dispatch({ type: PROJECT_ACTION_LIST.PROJECT_BY_ID, data: {} });
  };
};


export const getAllProjectWithSubProject = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PROJECT_BASE_URL}${config.API_VERSION}/${config.rootContextProject}/projectMaster/getAllProjectsWithSubProject?relationshipId=${payload.relationshipId}`)
      .then(res => {
         dispatch({
          type: PROJECT_ACTION_LIST.PROJECT_WITH_SUBPROJECT,
          data: res.data
        });
      })
      .catch((err = {}) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}