import React, { Fragment } from "react";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, DatePicker, Input, Tooltip, InputNumber, Skeleton } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import { Dropdown } from "../../../general/Dropdown";
import { CONSTANTS, LIST_CONSTANTS, MODAL_TYPE, MODULE_CODED_VALUES } from "../../../../static/constants";
import { showToasterMessage, getMomentDateForUI } from "../../../../utils";
import { QuestionCircleTwoTone, EditOutlined } from "@ant-design/icons";

let companyInfo = {};
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class PackageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 0,
    };
    companyInfo = props.companyInfo;
  }

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{newTextObj.formData.customerName || ""} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.SHIPPING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.shippingAddress"
              defaultMessage=""
            />
            <h6>{newTextObj.formData.customerName || ""} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.companyInfo || {}).storeName} </h6>
          </div>
        );
        break;
      }

      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    if (
      modalType !== MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS &&
      (!formData || !formData.customerId)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please Select Customer",
      });
    }
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (address) {
      props.fetchAddresses({
        customerId: formData.customerId,
        relationshipId: companyInfo.relationshipId,
        locationType: modalType.replace(" ", ""),
      });
      props.hideModal();
      // if (props.customerDetail && props.customerDetail.boLocationList) {
      //   let addresses = props.customerDetail.boLocationList || [];
      //   addresses.push(address)
      //   props.updateState({ customerDetail: { ...props.customerDetail, boLocationList: addresses } })
      // }
    };
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      keyboard:false,
      modalData: {
        modalType: modalType,
        data: payload
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    if (!formData || !formData.customerId) {
      return showToasterMessage({
        messageType: "error",
        description: "Please Select Customer",
      });
    }
    formData.callback = (contact) => {
      props.hideModal(data);
      if (contact.customerId) {
        props.fetchAllContacts({
          customerId: contact.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <div>
          <FormattedMessage id="addItem.text.contact" defaultMessage="" />
          <div style={{ "font-size": "12px" }}>
            {" "}
            {formData.customerName || ""}
          </div>
        </div>
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;
    let callback = function (data) {
      if (
        modalType === MODAL_TYPE.CONTACT &&
        (props.customerDetail || {}).customerId
      ) {
        props.fetchAllContacts({
          customerId: props.customerDetail.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
      if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };

    switch (modalType) {
      case MODAL_TYPE.BILLING_ADDRESS:
      case MODAL_TYPE.SHIPPING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        deleteFunc = props.deleteAddress;
        break;
      }
      case MODAL_TYPE.CONTACT: {
        deleteFunc = props.deleteContact;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          customerId: props.customerDetail.customerId,
          callback,
        });
      },
    };
    showModal(modalData);
  };

  render() {
    const { size } = this.state;
    const filteredList = (this.props.soList || []).filter(item => item.status !== 'cancelled');
    const allShippingAddress =
      this.props.allShippingAddress ||
      ((this.props.customerDetail || {}).boLocationList
        ? filter(this.props.customerDetail.boLocationList, {
            locationType: CONSTANTS.SHIPPING_ADDRESS,
          })
        : []);
    const allBillingAddress =
      this.props.allBillingAddress ||
      ((this.props.customerDetail || {}).boLocationList
        ? filter(this.props.customerDetail.boLocationList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
          })
        : []);
    // const relationshipBillingAddress = (this.props.soPackageData || {}).boLocationSOList ? find(this.props.soPackageData.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) : {};
    // const billingAddress = (this.props.soPackageData || {}).boLocationSOList ? find(this.props.soPackageData.boLocationSOList, {locationType: CONSTANTS.BILLING_ADDRESS}): {};
    // const shippingAddress = (this.props.soPackageData || {}).boLocationSOList ? find(this.props.soPackageData.boLocationSOList, {locationType: CONSTANTS.SHIPPING_ADDRESS}): {};
    return (
      <Fragment>
        <Row>
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Organization Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.name"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="deatils-field-value">
                  {companyInfo.storeName}
                </div>
              </Col>
              {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.adress"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.companyBillingAddress",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <span>
                  {!this.props.addressHide &&
                  this.props.relationshipBillingAddress ? (
                    <>
                      <div className="detail-address-value">
                        <div style={{ height: "96px", paddingTop: "10px" }}>
                          {getAddressInfoFragment(
                            this.props.relationshipBillingAddress || {},
                            {},
                            this.props.companyInfo,
                            this.props
                          )}
                        </div>
                        <div
                          onClick={() => {
                            this.props.updateState({
                              addressHide: true,
                            });
                          }}
                        >
                          <EditOutlined />
                        </div>
                      </div>
                    </>
                  ) : (
                    <Dropdown
                      style={{ width: "100%" }}
                      items={this.props.companyInfo.boLocationList}
                      value={
                        (this.props.relationshipBillingAddress || {})
                          .streetAddress1
                      }
                      moduleName={MODULE_CODED_VALUES.SETTINGS_COMPANY_INFORMATION}
                      canAddNew={true}
                      canDelete={true}
                      visible={false}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS
                        );
                      }}
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                      onSelect={(value) => {
                        const selectedRelationshipAddress = find(
                          this.props.companyInfo.boLocationList,
                          { locationId: Number(value) }
                        );
                        this.props.updateState({
                          placeOfSupply: selectedRelationshipAddress.stateName,
                          relationshipBillingAddress:
                            selectedRelationshipAddress,
                          addressHide: false,
                        });
                      }}
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({
                          relationshipBillingAddress: undefined,
                        });
                      }}
                    />
                  )}
                </span>
              </Col>

              {/* <div className='phone'>
                  <div className='address-gst'>
                    {getAddressInfoFragment(relationshipBillingAddress)}
                  </div>
                </div> */}
            </Row>
            </Skeleton>
          </Col>

          <Col span={1}></Col>

          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Customer Information
              </Col>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="customer.salesOrder.form.customer.label"
                        defaultMessage=""
                      />
                    </div>
                  </Col>
                  <Col span={24}>

                  {this.props.packageType !== 'new' && this.props.packageType !== 'update' ? <div className="text-left">
                    {(this.props.soPackageData || {}).customerName ||(this.props.amendSoInvoiceData || {}).customerName  || ''}
                  </div>
                    :
                    <Dropdown
                      style={{ width: "100%" }}
                      items={(this.props.customers[this.props.pageNumber] || []).sort((a, b) => (a.companyName||'').localeCompare(b.companyName||''))}
                      valueKeyName='companyName'
                      optionKeyName='customerId'
                  moduleName={MODULE_CODED_VALUES.CUSTOMERS}
                      
                      value={this.props.customerName}
                      canAddNew={true}
                      canDelete={true}
                      onSearch={(searchedText) => {
                        this.props.fetchCustomers({
                          isCompact: true,
                          searchedText: searchedText,
                          pageNumber: 1,
                          hideSpinner: true,
                          pageSize: 100,
                          relationshipId: (this.props.companyInfo || {}).relationshipId
                        })
                      }}
                      placeholder={this.props.intl.formatMessage({
                        id: 'customer.salesOrder.form.customer.placeholder', defaultMessage: ''
                      })}
                      onSelect={
                        (customerId, data) => {
                          this.props.resetAllContacts();
                          this.props.resetCustomerDetail();
                          this.props.updateState({
                            customerId,
                            addressInti:false,
                            customerName: data.name,
                            shippingAddress: null,
                            billingAddress: null,
                            boContactList:[],
                            selectedContact:{}
                          });
                          this.props.fetchCustomerDetailsByCustomerId({
                            customerId,
                            relationshipId: (this.props.companyInfo || {}).relationshipId
                        });
                          this.props.fetchAddresses({customerId, relationshipId: (this.props.companyInfo || {}).relationshipId, locationType: CONSTANTS.SHIPPING_ADDRESS});
                          this.props.fetchAddresses({customerId, relationshipId: (this.props.companyInfo || {}).relationshipId, locationType: CONSTANTS.BILLING_ADDRESS});
                          this.props.fetchUnpackedSalesOrders({
                            customerId,
                            relationshipId: (this.props.companyInfo || {}).relationshipId
                          });
                          this.props.fetchAllContacts({ customerId, relationshipId: (this.props.companyInfo || {}).relationshipId });
                        }
                      }
                      deleteClickHandler={(payload) => {
                        this.props.deleteCustomers({ groupCustomerIds: [payload.id], relationshipId: (this.props.companyInfo || {}).relationshipId })
                      }}
                      onAddNewClick={(payload) => {
                        this.props.updateHeaderState({
                          customer: {
                            customerDrawerVisible: true,
                            customerName: payload.textEntered,
                          }
                        })
                      }}
                    />
  }
                    {/* <div className="deatils-field-value">
                      {this.props.customerName
                        ? this.props.customerName
                        : this.props.soPackageData.customerName}
                    </div> */}
                  </Col>

                  {this.props.customerName ||
              this.props.soPackageData.customerName || this.props.amendSoInvoiceData.customerName ? 
                  <>
                  
                  <Col span={24}>
                    <div className="details-heading-subheading">Contact</div>
                  </Col>
                  <Col span={24}>
                    <Dropdown
                      style={{ width: "100%" }}
                      value={
                        ((this.props.selectedContact || {}).firstName || "") +
                        " " +
                        ((this.props.selectedContact || {}).middleName || "") +
                        " " +
                        ((this.props.selectedContact || {}).lastName || "")
                      }
                      items={this.props.boContactList}
                      valueKeyName="firstName"
                      lastNameKey="lastName"
                      optionKeyName="contactId"
                      isFavouriteKeyName="isPrimaryContact"
                      placeholder="Please select contact name"
                  moduleName={MODULE_CODED_VALUES.CUSTOMER_CONTACTS}

                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.CONTACT,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          firstName: payload.textEntered,
                          submittedOnce: false,
                          customerId: (this.props.customerDetail || {})
                            .customerId,
                          pageNumber: this.props.pageNumber || 1,
                          customerName:
                            (this.props.customerDetail || {}).companyName || "",
                        };
                        this.handleNewContactAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.CONTACT
                        );
                      }}
                      onClear={() => {
                        this.props.updateState({
                          selectedContact: undefined,
                        });
                      }}
                      onSelect={(contactId) => {
                        let contact =
                          find(this.props.boContactList || [], {
                            contactId: +contactId,
                          }) || {};
                        this.props.updateState({
                          selectedContact: contact,
                        });
                      }}
                    />
                  </Col>

                  <Col span={24}>
                    <div className="details-heading-subheading">Bill To</div>
                  </Col>
                  <Col span={24}>
                    <Dropdown
                      style={{ width: "100%" }}
                      items={allBillingAddress || []}
                      value={(this.props.billingAddress || {}).streetAddress1}
                      placeholder="Billing Address"
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                  moduleName={MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS}

                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.BILLING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.BILLING_ADDRESS,
                          customerId: (this.props.customerDetail || {})
                            .customerId,
                          pageNumber: this.props.pageNumber,
                          customerName:
                            (this.props.customerDetail || {}).companyName || "",
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.BILLING_ADDRESS
                        );
                      }}
                      onClear={() => {
                        this.props.updateState({
                          billingAddress: undefined,
                        });
                      }}
                      onSelect={(selectedValue, optionObj) => {
                        this.props.updateState({
                          billingAddress: (allBillingAddress || []).find(
                            (x) => +x.locationId === +optionObj.key
                          ),
                        });
                      }}
                    />
                  </Col>

                  <Col span={24}>
                    <div className="details-heading-subheading">Ship To</div>
                  </Col>
                  <Col span={24}>
                    <Dropdown
                      style={{ width: "100%" }}
                      items={allShippingAddress || []}
                      value={(this.props.shippingAddress || {}).streetAddress1}
                      placeholder="Shipping Address"
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                  moduleName={MODULE_CODED_VALUES.CUSTOMER_ADDRESS}

                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.SHIPPING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.SHIPPING_ADDRESS,
                          customerId: (this.props.customerDetail || {})
                            .customerId,
                          pageNumber: this.props.pageNumber,
                          customerName:
                            (this.props.customerDetail || {}).companyName || "",
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.SHIPPING_ADDRESS
                        );
                      }}
                      onClear={() => {
                        this.props.updateState({
                          billingAddress: undefined,
                        });
                      }}
                      onSelect={(selectedValue, optionObj) => {
                        this.props.updateState({
                          shippingAddress: (allShippingAddress || []).find(
                            (x) => +x.locationId === +optionObj.key
                          ),
                        });
                      }}
                    />
                  </Col>
                  </>
           
              
               : 
              (
                "Please select customer for details"
              )}
            </Row>
            </Skeleton>
          </Col>

          <Col span={1}></Col>

          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Package Dimensions
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage id="dimensions.new.text" />
                </div>
              </Col>
              <Col span={24}>
                <Input.Group compact>
                  <span className="package_dimension_unit">
                    <InputNumber
                      className="measure_input"
                      placeholder="Length"
                      value={this.props.length}
                      onChange={(num) => {
                        this.props.updateState({ length: num });
                      }}
                    />
                    
                    {/* <i className="fi fi-br-cross" /> */}

                    <InputNumber
                      className="measure_input"
                      placeholder="Width"
                      value={this.props.width}
                      onChange={(num) => {
                        this.props.updateState({ width: num });
                      }}
                    />

                    {/* <i className="fi fi-br-cross" /> */}

                    <InputNumber
                      className="measure_input"
                      placeholder="Height"
                      value={this.props.height}
                      onChange={(num) => {
                        this.props.updateState({ height: num });
                      }}
                    />

                    <Dropdown
                      placeholder="Select" 
                      className="measure_unit_select"
                      value={this.props.dimensionUnit}
                      items={LIST_CONSTANTS.DIMENSION_UNITS}
                      valueKeyName="label"
                      optionKeyName="value"
                      onSelect={(unitVal)=>{
                        this.props.updateState({
                          dimensionUnit: unitVal,
                        });
                      }}
                    />
                  </span>

                </Input.Group>
              </Col>

              <Col span={24} className="text-right">
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="package.packageDetail.weight"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <InputNumber
                  className="measure_input"
                  style={{width: '80%', maxWidth: 'calc(100% - 100px)', marginLeft: '-2px'}}
                  placeholder="Weight"
                  value={this.props.weight}
                  onChange={(num) => {
                    this.props.updateState({
                      weight: num
                    });
                  }}
                />
                <Dropdown
                  className="measure_unit_select"
                  style={{width: '20%', minWidth: '100px'}}
                  placeholder="Select" 
                  value={this.props.weightUnit}
                  items={LIST_CONSTANTS.WEIGHT_UNITS}
                  valueKeyName="label"
                  optionKeyName="value"
                  onSelect={(unitVal)=>{
                    this.props.updateState({
                      weightUnit: unitVal
                    });
                  }}
                />
              </Col>
            </Row>
          </Skeleton>
          </Col>

          <Col span={1}></Col>

          <Col span={6} className="order-value-info">
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              {/* <Col span={10}  >
                      <div className="title ">
                        <FormattedMessage id='customer.package.listing.header.packageNo' defaultMessage='' />
                      </div>
                    </Col> */}
              {/* <Col span={14} >
                      <div className="text-left">
                      {this.props.nextTxNumber ?
                        <span>{getFormatedNextTxNumber(this.props)}</span>
                        //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
                        :
                        <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'margin-top': '-25px', 'margin-bottom': '-25px' }} />
                      }
                      </div>
                      </Col> */}

              <Col span={24} className="details-heading">
                Package Information
              </Col>
              <Col span={24}>
                <div className="title">
                  <FormattedMessage
                    id="package.packageDetail.soNo"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                {this.props.packageType !== "new" &&
                this.props.packageType !== "update" ? (
                  <div className="text-left">
                    {(this.props.soPackageData || {}).soNumber ||(this.props.amendSoInvoiceData || {}).asoNumber || ""}
                  </div>
                ) : (
                  <Dropdown
                    disabled={!this.props.customerId ? true : false}
                    className="text-left"
                    style={{ width: "100%", height: '32px', overFlowWrap: "break-wordow" }}
                    items={filteredList}
                    value={this.props.soNumber || this.props.salesOrderMasterId}
                    placeholder="Select Sales Order"
                    valueKeyName="soNumber"
                    // salesOrderAmountKey="salesOrderTotalAmount"
                    // currencyCodeKey="currencyCode"
                    // addressLineKey1="customerName"
                    optionClassAddress="custom-address-dropdown"
                    optionKeyName="salesOrderMasterId"
                    onSelect={(selectedValue) => {
                      const data = find(filteredList, {
                        salesOrderMasterId: selectedValue,
                      });
                      this.props.fetchSoDataForPackage({
                        soMasterId: data.salesOrderMasterId,
                        customerId: data.customerId,
                        salesOrderMasterId: data.salesOrderMasterId,
                        relationshipId: data.relationshipId,
                      });
                      this.props.fetchCustomerDetailsByCustomerId({
                        customerId: data.customerId,
                        relationshipId: data.relationshipId,
                      });
                    }}
                  />
                )}
              </Col>

              {this.props?.soPackageData?.referenceNumber && (
                  <>
                    <Col span={24}>
                      <div className="details-heading-subheading">
                        <FormattedMessage
                          id="package.packageDetail.refNo"
                          defaultMessage=""
                        />
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="deatils-field-value">
                        {(this.props.soPackageData || {}).referenceNumber || ""}
                      </div>
                    </Col>
                  </>
                )}
              {this.props.soPackageData &&
                this.props.soPackageData.incotermName && (
                  <>
                    <Col span={24}>
                      <div className="details-heading-subheading">
                        <FormattedMessage
                          id="package.packageDetail.incoterm"
                          defaultMessage=""
                        />
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="deatils-field-value">
                        {(this.props.soPackageData || {}).incotermName || ""}
                      </div>
                    </Col>
                  </>
                )}
              {this.props.soPackageData &&
                this.props.soPackageData.customerPONumber && (
                  <>
                    <Col span={24}>
                      <div className="title">
                        <FormattedMessage
                          id="package.packageDetail.poNo"
                          defaultMessage=""
                        />
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="text-left">
                        {(this.props.soPackageData || {}).customerPONumber ||
                          ""}
                      </div>
                    </Col>
                  </>
                )}
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="package.packageDetail.packedBy"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={(this.props.employees || []).sort((a, b) => (a.fullName||'').localeCompare(b.fullName||''))}
                  value={this.props.packageBy}
                  placeholder="Packed By"
                  valueKeyName="fullName"
                  optionKeyName="fullName"
                  onSelect={(selectedValue) => {
                    this.props.updateState({ packageBy: selectedValue });
                  }}
                />
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="salesOrder.creation.date"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  style={{ width: "100%" }}
                  allowClear={false}
                  format={format}
                  key={`${
                    this.props.packageDate
                      ? getMomentDateForUI({
                          date: new Date(this.props.packageDate),
                          format,
                        })
                      : getMomentDateForUI({ date: new Date(), format })
                  }`}
                  defaultValue={
                    this.props.packageDate
                      ? getMomentDateForUI({
                          date: new Date(this.props.packageDate),
                          format,
                        })
                      : getMomentDateForUI({ date: new Date(), format })
                  }
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      packageDate: selectedDate,
                    });
                  }}
                  size={size}
                />
              </Col>

              {this.props.soData && (
                <>
                  <Col span={24}>
                    <div className="title">
                      <FormattedMessage
                        id="customer.salesOrder.form.deliveryDate.label"
                        defaultMessage=""
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="value">
                      <DatePicker
                        allowClear={false}
                        format={format}
                        key={`${
                          this.props.deliveryDate
                            ? getMomentDateForUI({
                                date: new Date(this.props.deliveryDate),
                                format,
                              })
                            : getMomentDateForUI({ date: new Date(), format })
                        }`}
                        defaultValue={
                          this.props.deliveryDate
                            ? getMomentDateForUI({
                                date: new Date(this.props.deliveryDate),
                                format,
                              })
                            : getMomentDateForUI({ date: new Date(), format })
                        }
                        onChange={(selectedDate) => {
                          this.props.updateState({
                            deliveryDate: selectedDate,
                          });
                        }}
                        size={size}
                      />
                    </div>
                  </Col>
                </>
              )}
              {this.props.soData &&
              this.props.soData.qualityCheckList &&
              this.props.soData.qualityCheckList.length ? (
                <>
                  <>
                    <Col span={24}>
                      <div className="title">
                        <Tooltip
                          placement="bottom"
                          title={this.props.intl.formatMessage({
                            id: "tooltip.qualityCheckList",
                            defaultMessage: "",
                          })}
                        >
                          <span className="tooltip-title">
                            <FormattedMessage
                              id="customer.salesOrder.form.qualityCheck.label"
                              defaultMessage=""
                            />
                          </span>
                        </Tooltip>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          this.props.updateState({
                            title: (
                              <div className="drawer-title">Quality Check</div>
                            ),
                            qualityCheckDrawerVisible: true,
                            qualityCheckAction: "check",
                            addQualityCheckToProps: function (
                              qualityCheckList
                            ) {
                              this.updateState({
                                qualityCheckList: qualityCheckList,
                              });
                            },
                          });
                        }}
                      >
                        {!this.props.qualityCheckList
                          ? 0
                          : this.props.qualityCheckList.length}
                      </div>
                    </Col>
                  </>
                </>
              ) : (
                ""
              )}
            </Row>
            </Skeleton>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const getAddressInfoFragment = (
  obj,
  customerContactObj,
  customerObj,
  props
) => {
  // if (!obj || !Object.keys(obj).length) {
  //     return '';
  // }

  let contactName = "";
  if (customerContactObj.salutationName)
    contactName += customerContactObj.salutationName + " ";
  if (customerContactObj.firstName) contactName += customerContactObj.firstName;
  if (customerContactObj.middleName)
    contactName += " " + customerContactObj.middleName;
  if (customerContactObj.lastName)
    contactName += " " + customerContactObj.lastName;
  const fragmentObj = (
    <Fragment>
     <div className="billing-address first-caps">
    {obj.streetAddress1 || obj.streetAddress2 ? (    
        <div>
          {obj.streetAddress1} {obj.streetAddress2}{", "}
        </div>    
    ) : ""}
    {obj.cityName || obj.stateName || obj.zipCode ? (      
        <div>
          {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
        </div>      
    ) : ""}
    {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
  </div>
      {customerContactObj.firstName ? (
        <div className="billing-address">{contactName}</div>
      ) : (
        ""
      )}
      {customerContactObj.workPhone ? (
        <div className="billing-address">
          <FormattedMessage
            id="customer.salesOrder.form.phone.label"
            defaultMessage=""
          />{" "}
          - {customerContactObj.workPhone}
        </div>
      ) : (
        ""
      )}
      {customerContactObj.email ? (
        <div className="billing-address">
          <FormattedMessage
            id="customer.salesOrder.form.email.label"
            defaultMessage=""
          />{" "}
          - {customerContactObj.email}
        </div>
      ) : (
        ""
      )}

    </Fragment>
  );

  return fragmentObj;
};

export default injectIntl(PackageDetail);
