import axios from "axios";
import config from "../../../config/environmentConfig";
import { COMMUNICATION_ACTION_LIST, COMMON_ACTIONS } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const getSubAccount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextTwilio
                }/account/getSubaccount?relationshipId=${payload.relationshipId}`
            )
            .then((res) => {

                dispatch({
                    type: COMMUNICATION_ACTION_LIST.SUB_ACCOUNT,
                    data: res.data,
                });
                //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || "")] ||
                            "Some error occurred",
                    });
                }
            })
            .finally(()=> {
                cb && cb(true);
             });
    };
};

export const createSubAccount = (payload, props) => {
    return dispatch => {
        return axios
            .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextTwilio}/account/createSubaccount`, payload)
            .then(res => {
                // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                // props.getAllDG && props.getAllDG(props);
                getSubAccount({ relationshipId: payload.relationshipId })(dispatch);
                showToasterMessage({ messageType: 'success', description: 'Account successfully Created' });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
                } else {
                    // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
                }
            })
    }
}


export const getText2DonateConfig = (payload) => {
    return (dispatch) => {
        return axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/m2d/getText2DonateConfig?relationshipId=${payload.relationshipId}&source=${payload.source}`)
            .then((res) => {
                dispatch({
                    type: payload.source === 'sms' ? COMMUNICATION_ACTION_LIST.ACCOUNT_CONFIG_PH : COMMUNICATION_ACTION_LIST.ACCOUNT_CONFIG_WHATS_APP,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || "")] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const updatePhoneNumber = (payload, props) => {
    return dispatch => {
        return axios
            .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextTwilio}/account/updatePhoneNumber`, payload)
            .then(res => {
                getSubAccount({ relationshipId: payload.relationshipId })(dispatch);
                showToasterMessage({ messageType: 'success', description: 'Account successfully Created' });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
                } else {
                    // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
                }
            })
    }
}