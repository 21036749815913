import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createLeadTask = (payload ,props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/lead/saveLeadsTask`, payload)
      .then(res => {
        if(props.fetchLeadTasks){
          props.fetchLeadTasks({
            relationshipId: props.companyInfo.relationshipId,
            crmLeadId: payload.leadId,
          });
        }
        showToasterMessage({ messageType: 'success', description: 'Task saved successfully' });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
  }
}

