import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchAllQualityChecklists = (payload, history) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/qualityChecklist/getAllQualityChecklists?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.QUALITY_CHECK_NAME, data: res.data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          } else {
            err.actionToCall = COMMON_ACTIONS.QUALITY_CHECK_NAME;
          }
        })
    }
  }
  
  export const addQualityChecklists = (payload) => {
    const requestObj = {
      ...payload,
      //dateCreated: getCurrentDateForBackend(new Date()),
    }
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/qualityChecklist/createQualityChecklist`, requestObj)
        .then(res => {
        dispatch({ type: COMMON_ACTIONS.QUALITY_CHECK_NAME_ADDITION, data: res.data })
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: (res || {}).message || 'Created successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
            err.actionToCall = COMMON_ACTIONS.QUALITY_CHECK_NAME_ADDITION;
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
          }
        })
    }
  }
  
  export const deleteQualityChecklists = (payload) => {
      const requestObj = {
          qualityChecklistId: payload.id,
          relationshipId: payload.relationshipId,
      }
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/qualityChecklist/deleteQualityChecklist`, requestObj)
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.QUALITY_CHECK_NAME_DELETION, data: { id: payload.id } })
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
            err.actionToCall = COMMON_ACTIONS.QUALITY_CHECK_NAME_DELETION;
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
          }
        })
    }
  }

  export const deleteQualityChecklistFromSO = (payload) => {
    const requestObj = {
      relationshipId: payload.relationshipId,
      qualityChecklistSOId: payload.qualityChecklistSOId,
      customerId: payload.customerId,
      salesOrderMasterId: payload.salesOrderMasterId,
    }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/qualityChecklistTransaction/deleteQualityChecklistFromSO`, requestObj)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
        }
      })
  }
}