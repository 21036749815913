import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { COMMON_ACTIONS, CONSTANTS } from "../../../../../static/constants";
import {
  fixedDecimalNumber,
  getMomentDateForUI,
  getMomentDateForUIReadOnly,
  showToasterMessage,
} from "../../../../../utils";
import { getStateCode } from "../../../../../components/general/stateCode";

import find from "lodash.find";
import filter from "lodash.filter";
import { format } from "crypto-js";
import { message } from "antd";
const { lang } = require("../../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");




export const uploadGSTR1FilingDetails = (payload) => {

 
    const { masterIdList, details } = getGstrFilingDetails(payload);
    const payloadValue = {
      request: {
        invoices: details,
      },
      ct: "INVOICE",
      invoiceMasterId: masterIdList,
      userGstin: "33ABCDE9876A1ZE",
      relationshipId: payload?.companyInfo?.relationshipId,
    };

    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/alpide/gstr1/uploadRegularInvoices`,
          payloadValue
        )
        .then((res) => {
        
          

          if(res.status === 200 &&(res?.data?.status === 200 || res?.data?.status === 409)){
           
            if(res?.data?.status === 409){
             
              const modalData = {
                title: "Confirmation",
              hideFooter: false,
                modalBody:"Summary GSTR-1 has already been created. To continue please discard the summary.",
                width: 500,
                footer:{
                  submit:{
                    intlId: "Discard Summary"
                  }
                },
                handleSubmit: ()=>{
                  
                  
                  
                 
                    payload.discardSummaryDraft({gstin: "33ABCDE9876A1ZE" , fp: payload.selectedDate},payload )
                  
                 
                }
              };
  
              payload.showModal(modalData)
            }
            else{

              if(payload?.validatedGstr1){
                showToasterMessage({
                  messageType: "success",
                  description: "Error/Warning has been fixed successfully",
                })
              }
              else{
                showToasterMessage({
                  messageType: "success",
                  description: "Invoice uploaded successfully",
                })
              }
             

            
              payload.fetchValidatedGstDetails({date: payload.selectedDate});
            payload.updateState({activeTabKey: "validated"})
  
            }
            // payload.hideModal()
            
            }
            else{
              showToasterMessage({
                messageType: "error",
                description: res?.data?.message,
              })
            }
          
         
          
        })
        .catch((err) => {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }).finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
        });
    };
  
   
};




export const deleteValidatedInvoice = (payload,props) => {
  const invoiceDate = getMomentDateForUIReadOnly({date: payload.date , format:"MM-YYYY"})
  const val = {
    "fp":invoiceDate.replace('-',''),
    "ft":"GSTR1",
    "gstin":"33ABCDE9876A1ZE",
    "idList": payload?.idList,

    "sectionType":"Regular Transactions"}

    
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/alpide-gst/api/v1/gstr1/deleteGSTR1Invoice`,
        val
      )
      .then((res) => {
        // if(res.status === 200 && (res.data.status === 200 || res.data.status === 409)){
        //   if(res.data.status === 200){
        //     if(!props.validatedGstr1){
        //       showToasterMessage({
        //         messageType: "success",
        //         description: res.data.message,
        //       })
        //       props.fetchValidatedGstDetails({date: payload.date});
        //     }
          
        //   }
        //   else{
        //     const modalData = {
        //       title: "Confirmation",
        //     hideFooter: false,
        //       modalBody:"Summary GSTR-1 has already been created. To delete please discard the summary.",
        //       width: 500,
        //       footer:{
        //         submit:{
        //           intlId: "Discard Summary"
        //         }
        //       },
        //       handleSubmit: ()=>{
                
                
                
               
        //           props.discardSummaryDraft({gstin: payload.gstin , fp: payload.date},props )
                
               
        //       }
        //     };

        //     props.showModal(modalData)
        //   }
        
        // }
        
      })
      .catch((err) => {
        // showToasterMessage({
        //   messageType: "error",
        //   description:
        //     lang[((err.response || {}).data || "")] ||
        //     "Some error occurred",
        // });
      });
  };
};
 
export const deleteInvoiceList = (payload ,props)=>{
  const invoiceDate = getMomentDateForUIReadOnly({date: payload.date , format:"MM-YYYY"})
   const val =  {
    "invoiceMasterId" : [payload?.masterId],
    "relationshipId" :  payload?.relationshipId,
    "request" :
    {
      "fp":invoiceDate.replace('-',''),
      "ft":"GSTR1",
      "gstin":"33ABCDE9876A1ZE",
      "idList": payload?.idList,
  
      "sectionType":"Regular Transactions"}
    }
 

    
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/alpide/gstr1/deleteGSTR1Invoice `,
        val
      )
      .then((res) => {
        if(res.status === 200 && (res.data.status === 200 || res.data.status === 409)){
          if(res.data.data){
            
              showToasterMessage({
                messageType: "success",
                description: res.data.message,
              })
              props.fetchValidatedGstDetails({date: payload.date});
           
          
          }
          else{
            const modalData = {
              title: "Confirmation",
            hideFooter: false,
              modalBody:"Summary GSTR-1 has already been created. To delete please discard the summary.",
              width: 500,
              footer:{
                submit:{
                  intlId: "Discard Summary"
                }
              },
              handleSubmit: ()=>{
                
                
                
               
                  props.discardSummaryDraft({gstin: payload.gstin , fp: payload.date},props )
                
               
              }
            };

            props.showModal(modalData)
          }
        
        }
        
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      });
  };
}


const getDiscountAmount = (row, toReturn) => {
  if (!row || !row.discountAmount) return;
  if (row?.discountAmount) {
    return toReturn === "amount"
      ? row?.discountAmount
      : "(" + row?.discountPercentage + "%)";
  }
};



const calculateTaxRateList = (rowdata) => {
  let subtotal = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let itemLevelTaxes = [];

  let totalAmountOfColumn = 0;
  if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
    let taxObj = {
      subtotal: 0,
      taxPercent: 0,
      taxAmount: 0,
    };
    totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
    let discount = getDiscountAmount(rowdata, "amount");
    if (discount) {
      totalAmountOfColumn = totalAmountOfColumn - discount;
      totalDiscount = totalDiscount + discount;
    }

    let tax1 =
      rowdata.customerInvoiceCOATxList.find((tx) => tx.txType === "tax") || {};
    taxObj.taxPercent = tax1.amountPercent;
    taxObj.taxAmount = tax1.amount;
    taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
    totalTax = totalTax + tax1.amount;
    taxObj.taxName = tax1.taxName;

    if (taxObj.taxPercent) {
      taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
      const availableTaxObj = find(itemLevelTaxes, {
        taxPercent: taxObj.taxPercent,
      });

      if (availableTaxObj) {
        itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
          if (ele.taxPercent === taxObj.taxPercent) {
            ele.subtotal = ele.subtotal + taxObj.subtotal;
            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
          }
          return true;
        });
      } else {
        taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
        itemLevelTaxes.push(taxObj);
      }
    }
    subtotal = subtotal + totalAmountOfColumn;
  }
  return itemLevelTaxes;
};
const getGstrFilingDetails = (payload) => {


  
  
  const date = getMomentDateForUIReadOnly({
    date: new Date(),
    format: "MM-YYYY",
  });

  const dateval = (date || "").split("-");
   if(payload.validatedGstr1){
    payload.updateState({uploadedGSTList: []})
       
   }

  const selectedItems = payload?.eInvoiceListing?.customerInvoiceMasterList?.content ||[]
  const masterIdList = ((selectedItems || [])?.length ? selectedItems||[] : [payload?.salesInvoiceDetail]||[])?.map(
    (item, i) => item?.invoiceMasterId
  );
  const details = ((selectedItems || [])?.length ? selectedItems||[] : [payload?.salesInvoiceDetail]||[])?.map((item) => {
    const relationshipAddress = find((item?.boLocationSalesInvoiceList||[]), { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (item.placeOfSupply && relationshipAddress.stateName === item.placeOfSupply) || false;
    
    const itemList = item?.customerInvoiceDetailsList?.map((val, i) => {
      const itemLevelTaxes = calculateTaxRateList(val);
      return {
        num: `000${i + 1}`,
        sval: val?.quantityToInvoice * val?.basePrice,
        ty: "G",
        hsnSc: val?.hsnCode ?  val?.hsnCode : "40169400",
        desc: val?.description ||"INVOICE SAMPLE",
        uqc: val?.uomName ? val?.uomName :"OTH",
        qty: val?.quantityToInvoice,
        
        txval: itemLevelTaxes?.length ?  itemLevelTaxes[0]?.subtotal :0,
        irt:
          !isPlaceOfSupplySameAsRelationship &&
          itemLevelTaxes[0]?.taxName === "IGST"
            ? itemLevelTaxes[0]?.taxPercent
            : 0,
        iamt:
          !isPlaceOfSupplySameAsRelationship &&
          itemLevelTaxes[0]?.taxName === "IGST"
            ? itemLevelTaxes[0]?.taxAmount
            : 0,
        crt:
          isPlaceOfSupplySameAsRelationship &&
          itemLevelTaxes[0]?.taxName === "CGST"
            ? itemLevelTaxes[0]?.taxPercent
            : 0,
        camt:
          isPlaceOfSupplySameAsRelationship &&
          itemLevelTaxes[0]?.taxName === "CGST"
            ? itemLevelTaxes[0]?.taxAmount
            : 0,
        srt:
          isPlaceOfSupplySameAsRelationship &&
          itemLevelTaxes[0]?.taxName === "CGST"
            ? itemLevelTaxes[0]?.taxPercent
            : 0,
        samt:
          isPlaceOfSupplySameAsRelationship &&
          itemLevelTaxes[0]?.taxName === "CGST"
            ? itemLevelTaxes[0]?.taxAmount
            : 0,
        csrt: 0,
        csamt: 0,
        txp: "T",
        disc: val?.discountAmount,
        adval: "0.00",
        rt: val?.ratePercent,
      };
    });
    let dashIndex = (item?.invoiceNumber||"").indexOf('-'); // Find the position of the dash

let number = (item?.invoiceNumber||"").substring(dashIndex + 1)
    return {
      invTyp: item?.eInvoiceType || "B2C",
      splyTy: isPlaceOfSupplySameAsRelationship ? "INTRA" : "INTER",
      dst: "O",
      refnum: item?.referenceNumber,
      pdt:getMomentDateForUIReadOnly({date: item?.invoiceDate ,format: "DD-MM-YYYY"}) ,
      ctpy: "R",
      ctin: "36ABCDR2923M2ZL",
      cname: item?.customerName,
      ntNum: null,
      ntDt: null,
      inum: `${number}/${item?.invoiceMasterId}/${item?.customerId}`,
      idt: getMomentDateForUIReadOnly({date: item?.invoiceDate ,format: "DD-MM-YYYY"}),
      val: item?.invoiceTotalAmount,
      pos: getStateCode(item?.placeOfSupply || "Arunachal Pradesh"),
      rchrg: "N",
      fy: dateval[1],
      dty: "RI",
      rsn: "sales return",
      pgst: "N",
      prs: "N",
      odnum: "000",
      gen2: "24",
      gen7: "GJ01",
      gen8: "T986888",
      gen10: "0011111000",
      gen11: "20171123051826",
      gen12: "3172397707",
      gen13: "DUMMY/CTIN",
      itemDetails: itemList,
      gstin: "33ABCDE9876A1ZE",
      fp: dateval[0]+dateval[1],
      ft: "GSTR1",
    };
  });

  return { masterIdList, details };
};
