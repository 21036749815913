import axios from "axios";
import config from "../../../../../config/environmentConfig"

import { showToasterMessage } from "../../../../../utils";
import { COMMON_ACTIONS } from "../../../../../static/constants";

export const postDeliveryCharges = (payload,props) => {
  return (dispatch) => {
       

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  
    return axios
      .post(
        `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_setting/delivery-charges`,
        {
            relationshipId: payload.relationshipId,
            deliveryChargesInfo:JSON.stringify(payload.deliveryChargesInfo),
            version: payload.version,
          }
      )
      .then((res) => {
        // const pay ={
        //   relationshipId: payload.ecomSettingSave?.relationshipId,
        // }
        // props.fetchE_StoreData(pay)
        showToasterMessage({
          messageType: "success",
          description: " data save successfully.",
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          messageType: "fail",
          description: " Error occured",
        });
      });
  };
};
