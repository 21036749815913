import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import {  Skeleton, Pagination,  Popover, Menu } from "antd";

import {
  fetchPaginationDataIfNeeded,
  showToasterMessage,
  getMomentDateForUIReadOnly,
  exportExcel,

 
} from "../../../../utils";

import DropdownAction from "antd/lib/dropdown";
import EwayBillDetail from "../../../../containers/modal/modalBody/customer/EwayBillDetail";



import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  FilterOutlined,
  InsertRowRightOutlined,
 
 
  FileExcelOutlined,
  DownloadOutlined,
 
} from "@ant-design/icons";


import "./index.scss";

import { CONSTANTS } from "../../../../static/constants";
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";






const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const EWayBillListingComp = (props) => {
 
  const {
    einvoiceList,
   
    updateState,
    companyInfo,
    salesInvoiceCount,
    pageNumber,
    selectedItems,
   
  } = props;

  
  

  if (!selectedItems) {
    props.updateState({ selectedItems: [] });
  }


  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      filters: {
        customerId: props.selectedCustomerId,
        status: props.selectedStatus,
      },
    };
    fetchPaginationDataIfNeeded(
      "fetchSalesInvoices",
      "salesInvoiceList",
      props,
      payload
    );
  };
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      filters: {
        customerId: props.selectedCustomerId,
        status: props.selectedStatus,
      },
    };
    props.resetPaginatedData();
    props.fetchSalesInvoices(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };
  

  const exportExcelData = (selectedInvoiceList, props) => {
    const dataList = (selectedInvoiceList || []).map((item) => {
      return {
        "Invoice #": item.invoiceNumber || "",
        "Invoice Date": getMomentDateForUIReadOnly({
          date: new Date(item.invoiceDate),
          format,
        }),
        "Invoice Due Date": getMomentDateForUIReadOnly({
          date: new Date(item.invoiceDueDate),
          format,
        }),
        "Customer Name": item.customerName || "",
        "Invoice Amount": Number(item.invoiceTotalAmount || 0).toFixed(2),
        "Paid Amount": Number(item.totalPaymentReceived || 0).toFixed(2),
        "Due Amount": Number(
          item.invoiceTotalAmount -
            (item.totalPaymentReceived + item.creditApplied)
        ).toFixed(2),
        "Credit Applied": Number(item.creditApplied).toFixed(2),
        "System Status": item.status,
        "Payment Term": item.paymentTermName || "",
        "Project Name": item.projectName || "",
        "Past Due":
          item.status === "void" || item.status === "paid"
            ? "-"
            : item.dueDays < 0
            ? Math.abs(item.dueDays || 0) + " Days"
            : "Not yet due",
      };
    });
    exportExcel(dataList, "Invoices");
  };

 

 

  const openEInvoiceDetails = (data) => {

    const obj = {...props , einvoiceData:data}
    const invoicePayload = {
      customerId: data?.customerId,
      invoiceMasterId: data.salesInvoiceMasterId,
    };
    props.pushModalToStack({
      modalBody: (
        <EwayBillDetail {...obj} data={data} invoicePayload={invoicePayload} />
      ),
      width: "100%",
      hideFooter: true,
      keyboard: true,
      wrapClassName: "modal-custom-detail",
    });
  };
 

 

 


  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="excel"
          onClick={() => {
            const invoiceList = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         invoiceList.push(obj.data);
            //     }
            // });
            selectedItems.forEach((obj) => {
              invoiceList.push(obj);
            });

            if (invoiceList.length) {
              exportExcelData(invoiceList);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <FileExcelOutlined />
          Excel File
        </Menu.Item>

       
      </Menu>
    );
  };

  return (
    <Fragment>
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="e-Invoice List"
                defaultMessage=""
              />
            </div>
            {salesInvoiceCount ? (
              <>
                <div className="vertical-sep" />
                <div>{salesInvoiceCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          
          <div className="right-actions">
            <button
              onClick={() => {
                props.updateState({
                  salesInvoiceFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined onClick={() => {}} /> &nbsp;{" "}
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>

            <Popover
              content={<AddAndRemoveCustomerListingColumn {...props} />}
              title={<div className="text-center">Add/Remove</div>}
              placement="leftTop"
              trigger="click"
              arrow="right"
              // visible={props.showColPop}
            >
              <button
                onClick={() => {
                  props.updateState({ showColPop: !props.showColPop });
                }}
              >
                <InsertRowRightOutlined />
                &nbsp; Columns
              </button>
            </Popover>

            {/* <button className="action-btn"> */}
            <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
              <span>
                <DownloadOutlined /> &nbsp;{" "}
                <FormattedMessage
                  id="common.action.dropdown.downloadAs"
                  defaultMessage=""
                />
              </span>
            </DropdownAction>
            {/* </button> */}
          </div>
          </Skeleton>
        </div>
      
          <Skeleton loading={props.listLoading} paragraph={{ rows: 12 }} active>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  
                    S.No
                </th>
                <th>
               Invoice #
                  
                </th>
                <th>
              Document #
                </th>
                <th>EWB Number</th>
                <th>
               EWB Date
                </th>
                <th>EWB Valid Till</th>
                <th>
                Acknowledgment #
                </th>
                <th>
                 Acknowledegment Date
                </th>
           
              
              </tr>
            </thead>
            <tbody>
              {einvoiceList && einvoiceList[pageNumber]
                ? einvoiceList[pageNumber].map((item, i) => {
                   if(item?.irn){
                    return (
                      <tr key={i}>
                        <td>{i+1}</td>
                        <td>
                        {
                    item.invoiceNumber||<div className="empty-data-box"/>
                   }
                </td>
               
                <td className="one-line-view">
                            <div
                              className="table-link-text"
                              onClick={() => {
                                openEInvoiceDetails(item);
                              }}
                            >
                              {item.documentNumber||<div className="empty-data-box"/>}
                            </div>
                          </td>
               
               
                <td>
                {
                    item?.ewbNo||<div className="empty-data-box"/>
                   }
                </td>
                <td>
                    {
                      getMomentDateForUIReadOnly({ date: item?.ewbDate, format: CONSTANTS.DISPLAY_DATE_FORMAT })  || <div className="empty-data-box"/>
                    }
                </td>
                <td>
                {
                    getMomentDateForUIReadOnly({ date: item?.ewbValidTill, format: CONSTANTS.DISPLAY_DATE_FORMAT })    || <div className="empty-data-box"/>
                    }
                </td>
                        <td>
                        {
                    item.acknowledgeNumber||<div className="empty-data-box"/>
                   }
                </td>
                <td>
                {
                  (item.acknowledgeDate ? getMomentDateForUIReadOnly({ date: item.acknowledgeDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : <div className="empty-data-box"/>)
                   
                   }
                </td>
              
               
                      </tr>
                    );
                   }
                   
                  })
                : <tr>No Data</tr>}
            </tbody>
          </table>
        </div>

        {/* </div> : null} */}
      </Skeleton>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={salesInvoiceCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(EWayBillListingComp);
