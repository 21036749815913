 const state_code_mapping = {
    "01": "Jammu and Kashmir",
    "02": "Himachal Pradesh",
    "03": "Punjab",
    "04": "Chandigarh",
    "05": "Uttarakhand",
    "06": "Haryana",
    "07": "Delhi",
    "08": "Rajasthan",
    "09": "Uttar Pradesh",
    "10": "Bihar",
    "11": "Sikkim",
    "12": "Arunachal Pradesh",
    "13": "Nagaland",
    "14": "Manipur",
    "15": "Mizoram",
    "16": "Tripura",
    "17": "Meghalaya",
    "18": "Assam",
    "19": "West Bengal",
    "20": "Jharkhand",
    "21": "Odisha",
    "22": "Chhattisgarh",
    "23": "Madhya Pradesh",
    "24": "Gujarat",
    "25": "Daman and Diu",
    "26": "Dadra and Nagar Haveli",
    "27": "Maharashtra",
    "28": "Andhra Pradesh",
    "29": "Karnataka",
    "30": "Goa",
    "31": "Lakshadweep",
    "32": "Kerala",
    "33": "Tamil Nadu",
    "34": "Puducherry",
    "35": "Andaman and Nicobar Islands",
    "36": "Telangana",
    "37": "Andhra Pradesh (New)"
}





const pinCodeRanges = {
    "Jammu and Kashmir": [180001, 194404],
    "Himachal Pradesh": [171001, 177601],
    "Punjab": [140001, 160104],
    "Chandigarh": [160001, 160036],
    "Uttarakhand": [246001, 263676],
    "Haryana": [121001, 136156],
    "Delhi": [110001, 110096],
    "Rajasthan": [302001, 345034],
    "Uttar Pradesh": [201001, 285223],
    "Bihar": [800001, 855117],
    "Sikkim": [737101, 737139],
    "Arunachal Pradesh": [791001, 792131],
    "Nagaland": [797001, 798627],
    "Manipur": [795001, 795159],
    "Mizoram": [796001, 796891],
    "Tripura": [799001, 799290],
    "Meghalaya": [793001, 794115],
    "Assam": [781001, 788931],
    "West Bengal": [700001, 743711],
    "Jharkhand": [814001, 835325],
    "Odisha": [751001, 770076],
    "Chhattisgarh": [490001, 497778],
    "Madhya Pradesh": [452001, 488448],
    "Gujarat": [360001, 396580],
    "Daman and Diu": [362520, 396220],
    "Dadra and Nagar Haveli": [396193, 396240],
    "Maharashtra": [400001, 444806],
    "Andhra Pradesh (Old)": [500001, 535593],
    "Karnataka": [560001, 591346],
    "Goa": [403001, 403806],
    "Lakshadweep": [682551, 682559],
    "Kerala": [670001, 695615],
    "Tamil Nadu": [600001, 643253],
    "Puducherry": [605001, 609607],
    "Andaman and Nicobar Islands": [744101, 744304],
    "Telangana": [500001, 509410],
    "Andhra Pradesh (New)": [515001, 533464]
};


export const checkCodeRanges = (gstin, pincode ,isStateName)=>{
    let stateName;
    if(isStateName){
          stateName = gstin;
    }
    else{
        const stateCode = (gstin||"").slice(0, 2);
         stateName = state_code_mapping[stateCode];
    }
   
    // if (!stateName) {
    //     return `Invalid state code in GSTIN: ${stateCode}`;
    // }


//  state name = gujrat
    if (pinCodeRanges.hasOwnProperty(stateName)) {
        const [minPin, maxPin] = pinCodeRanges[stateName];
        if (minPin <= parseInt(pincode) && parseInt(pincode) <= maxPin) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }

}
export const getStateCode = (stateName) => {
    for (const [code, name] of Object.entries(state_code_mapping)) {
      if ((name||"").toLowerCase() === (stateName||"").toLowerCase()) {
        return code;
      }
    }
    return null; // or return a message indicating that the state was not found
  };
