import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
//import * as find from 'lodash.find';
import { Menu, Dropdown, Skeleton, Button, Empty } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import {
  CONSTANTS,
  ICONS,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../../static/constants";
import LogCommunication from "../../../../containers/customer/Listing/LogCommunication";
// import { AgGridReact } from 'ag-grid-react';
import {
  base64Toblob,
  checkACLPermission,
  getAWSFileURL,
  getMomentDateForUIReadOnly,
  getPermissionData,
  sortTable,
} from "../../../../utils";
// import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { EmptyBox } from "../../../general/EmptyBox";
import { EmptyTableData } from "../../../general/EmptyTableData";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
class Communication extends Component {

  constructor(props){
    super(props)
  }
  state = {
    buttonDropDown: false,
  };

  toggleDropdown = () => {
    const { buttonDropDown } = this.state;
    this.setState({
      buttonDropDown: !buttonDropDown,
    });
  };

  render() {
    const commuincationList = this.props.commuincationList || [];
    const { permissions } = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const customerCommunicationPermission =  getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CUSTOMER_COMMUNICATION);
    
    const deleteCommunication = (payload) => {
      const modalBody = (
        <FormattedMessage id="common.delete.confirmation" defaultMessage="" />
      );
      const modalData = {
        modalBody,
        handleSubmit: () => {
          this.props.deleteCommunication(payload);
          this.props.hideModal();
        },
      };
      this.props.showModal(modalData);
    };
    // const { buttonDropDown } = this.state;
    // const { toggleDropdown } = this;

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const onFirstDataRendered = (params) => {
    //     params.api.sizeColumnsToFit();
    // }
    // const getRowHeight = (params) => {
    //     return 30;
    // }

    // const updateCommunication = (payload) => {

    //     const modalBody =
    //         <FormattedMessage
    //             id='save.confirmation'
    //             defaultMessage=''
    //         />;
    //     const modalData = {
    //         modalBody,
    //         handleSubmit: () => { this.props.createUpdateCommunication(payload) },
    //     };
    //     this.props.showModal(modalData);
    // }

    // const columnDefs = [
    //     // {
    //     //     headerName: "#",
    //     //     resizable: true,
    //     //     rowDrag: true,
    //     //     cellRendererFramework: function (link) {
    //     //         return <div>
    //     //             {link.rowIndex + 1}
    //     //         </div>
    //     //     }
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.type' defaultMessage="" />;
    //         },
    //         field: "communicationMode",
    //         resizable: true,
    //         editable: true,
    //         cellEditor: 'customTextEditor',
    //         width: 100,
    //         cellRendererFramework: (link) => <div>
    //             {(link.data.origination !== 'System') ? <div>
    //                 {link.data.communicationMode}
    //             </div> : <div className="cursor-pointer" onClick={() => {
    //                 this.props.updateState({ communicationReviewDrawerVisible: true, communicationDetail: link.data });
    //             }}>
    //                     {link.data.communicationMode}
    //                 </div>}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.description' defaultMessage="" />;
    //         },
    //         field: "description",
    //         resizable: true,
    //         width: 350,
    //         cellRendererFramework: (params) => {
    //             setTimeout(() => {
    //                 if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
    //                     params.eParentOfValue.style.height = 'inherit';
    //                     params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
    //                     params.node.data.maxHeight = params.reactContainer.scrollHeight;
    //                     params.node.setRowHeight(params.reactContainer.scrollHeight);
    //                     params.api && params.api.onRowHeightChanged();
    //                 }
    //             }, 200)
    //             return ReactHtmlParser(params.data.description)
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='communicationTo.text' defaultMessage="" />;
    //         },
    //         field: "communiatedTo",
    //         resizable: true,
    //         editable: true,
    //         cellEditor: 'customTextEditor',
    //         width: 200,
    //         cellRendererFramework: (params) => {
    //             setTimeout(() => {
    //                 if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
    //                     params.eParentOfValue.style.height = 'inherit';
    //                     params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
    //                     params.node.data.maxHeight = params.reactContainer.scrollHeight;
    //                     params.node.setRowHeight(params.reactContainer.scrollHeight);
    //                     params.api && params.api.onRowHeightChanged();
    //                 }
    //             }, 200)
    //             return <span>{params.data.communiatedTo}</span>
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='transaction.text' defaultMessage="" />;
    //         },
    //         field: "transactionType",
    //         resizable: true,
    //         width: 120,
    //         cellRendererFramework: (link) => <div>
    //             { link.data.transactionType ? <FormattedMessage id={link.data.transactionType} /> : ''}
    //             </div>
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.date.text' defaultMessage="" />;
    //         },
    //         field: "communicationDate",
    //         resizable: true,
    //         width: 130,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.communicationDate ? getMomentDateForUIReadOnly({date: new Date(link.data.communicationDate), format}) : '-'}
    //         </div>,

    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='origination.text' defaultMessage="" />;
    //         },
    //         resizable: true,
    //         field: "origination",
    //         width: 100,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='communicatedBy.text' defaultMessage="" />;
    //         },
    //         resizable: true,
    //         field: 'relationshipEmployeeName',
    //         editable: true,
    //         cellEditor: 'customDropDownEditor',
    //         width: 150,
    //         cellEditorParams: (obj) => {
    //             return {
    //                 lastColumnCell: false,
    //                 items: this.props.allEmployee,
    //                 optionKeyName: 'relationshipEmployeeId',
    //                 valueKeyName: 'fullName',
    //                 onSelect: (selectedValue, params) => {
    //                     //const employee = find((this.props.allEmployee || []), { relationshipEmployeeId: Number(params.key || 0) }) || {};
    //                     //obj.node.setDataValue(obj.colDef.field, employee.fullName);
    //                 },
    //             }
    //         },
    //     },
    //     {
    //         field: 'action', lockPosition: true, pinned: 'right',
    //         width: 100,
    //         cellRendererFramework: (params) => <div>
    //             {(params.data.origination !== 'System') ? <div>
    //                 <div>
    //                     {params.node.data.isToEdit &&
    //                         <div className="table-cell-action">
    //                             <span className='cursor-pointer' onClick={() => {
    //                                 updateCommunication(params.data);
    //                                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //                                 this.gridApi.refreshCells({ force: true });
    //                             }} >
    //                                 <i className="fa fa-save" />
    //                             </span>
    //                             <span className='cursor-pointer' onClick={() => {
    //                                 params.api.stopEditing(true);
    //                                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //                                 this.gridApi.refreshCells({ force: true });
    //                             }} >
    //                                 <i className="fa fa-ban" />
    //                             </span>
    //                         </div>
    //                     }

    //                     {!params.node.data.isToEdit &&
    //                         <span className="table-cell-action" >
    //                             {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_COMMUNICATION, PERMISSION_VALUES.UPDATE) && <span className='cursor-pointer' onClick={() => {
    //                                 const modalData = {
    //                                     title: <React.Fragment>
    //                                         <div className='modal-title'>
    //                                             <FormattedMessage id='newCommunication.text' defaultMessage="" />
    //                                         </div>
    //                                         <div className="modal-sub-title">
    //                                             {this.props.customerName}
    //                                         </div>
    //                                     </React.Fragment>,
    //                                     modalBody: <LogCommunication
    //                                         customerId={this.props.customerId}
    //                                         customerName={this.props.customerName}
    //                                         communicationData={params.node.data}
    //                                         isUpdate={true}
    //                                     />,
    //                                     width: 500,
    //                                     hideFooter: true,
    //                                     wrapClassName: 'log-communication-modal',
    //                                 };
    //                                 this.props.pushModalToStack(modalData);
    //                             }}>
    //                                 <i className="fa fa-edit" />
    //                             </span>}
    //                             {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_COMMUNICATION, PERMISSION_VALUES.DELETE) && <span className='cursor-pointer' onClick={() => deleteCommunication(params.data)}>
    //                                 <i className="fa fa-remove" />
    //                             </span>}
    //                         </span>}
    //                 </div>
    //             </div>
    //                 : <div><i className="fa fa-lock" /></div>
    //             }
    //         </div>
    //     }
    // ]

    const handleCreateUpdateCommunication = (payload) => {
      const modalData = {
        title: (
          <React.Fragment>
            <div className="modal-title">
              {payload.communicationMode ? (
                <FormattedMessage
                  id="updateCommunication.text"
                  defaultMessage=""
                />
              ) : (
                <FormattedMessage
                  id="newCommunication.text"
                  defaultMessage=""
                />
              )}
            </div>
            <div className="modal-sub-title">{this.props.customerName}</div>
          </React.Fragment>
        ),
        modalBody: (
          <LogCommunication
            customerId={this.props.customerId}
            customerName={this.props.customerName}
            formData={payload}
          />
        ),
        width: 500,
        hideFooter: true,
        wrapClassName: "log-communication-modal",
      };
      this.props.pushModalToStack(modalData);
    };

    // const columnDef =[
    //     {
    //         title: <FormattedMessage id='common.type' defaultMessage="" />,
    //         index: 'communicationMode',
    //         key: 'communicationMode',
    //         render: (text, record, index)=>(
    //             (record.origination !== 'System') ? <div>
    //                 {record.communicationMode}
    //             </div>: <Link onClick={()=>{
    //
    //                 this.props.updateState({ communicationReviewDrawerVisible: true, communicationDetail: record });
    //             }}>
    //                 {record.communicationMode}
    //             </Link>
    //         )
    //     },
    //     {
    //         title: <FormattedMessage id='common.description' defaultMessage="" />,
    //         index: 'description',
    //         key: 'description',
    //         width: '300px',
    //         render: (text, record, index) => (
    //             record.description
    //         )
    //     },
    //     {
    //         title: <FormattedMessage id='communicationTo.text' defaultMessage="" />,
    //         index: 'communiatedTo',
    //         key: 'communiatedTo',
    //         render: (text, record, index) =>(
    //             <div>{record.communiatedTo}</div>
    //         )
    //     },
    //     {
    //         title: <FormattedMessage id='transaction.text' defaultMessage="" />,
    //         index: 'transactionType',
    //         key: 'transactionType',
    //         render: (text, record, index)=><div>
    //             { record.transactionType ? <FormattedMessage id={record.transactionType} /> : ''}
    //         </div>
    //     },
    //     {
    //         title: <FormattedMessage id='common.date.text' defaultMessage="" />,
    //         index: 'communicationDate',
    //         key: 'communicationDate',
    //         render: (text, record, index)=>(
    //             record.communicationDate ? getMomentDateForUIReadOnly({date: new Date(record.communicationDate), format}) : '-'
    //         )
    //     },
    //     {
    //         title: <FormattedMessage id='origination.text' defaultMessage="" />,
    //         index: 'origination',
    //         key: 'origination',
    //         render: (text, record, index)=><div>
    //             {record.origination}
    //         </div>
    //     },
    //     {
    //         title: <FormattedMessage id='communicatedBy.text' defaultMessage="" />,
    //         index: 'relationshipEmployeeName',
    //         key: 'relationshipEmployeeName',
    //         render: (text, record, index)=><div>
    //             {record.relationshipEmployeeName}
    //         </div>
    //     },
    //     {
    //         title: <FormattedMessage id='customer.listing.header.action' defaultMessage="" />,
    //         index: 'action',
    //         key: 'action',
    //         width: '120px',
    //         render: (text, record, index) => <div>
    //             {record.origination !== 'System' ? <div className="table-cell-action">
    //                 <span className='cursor-pointer' onClick={() => handleCreateUpdateCommunication(record)}>
    //                     <i className="fa fa-edit" />
    //                 </span>
    //                 <span className='cursor-pointer' onClick={() => deleteCommunication(record)}>
    //                     <i className="fa fa-remove" />
    //                 </span>
    //             </div>:
    //                 <div><i className="fa fa-lock" /></div>
    //             }
    //         </div>
    //     },
    // ]

    // const onGridReady = (params) => {
    //     this.gridApi = params.api;
    // }
    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Action
          </Menu.Item>

          <Menu.Divider />
          <Menu.Item
              key="1"
              value="email"
              onClick={() => {
                const payload = {
                  to: Array.isArray(e.communiatedTo) ? e.communiatedTo : [e.communiatedTo],
                  subject: e.emailSubject,
                  message: e.emailMessage,
                  createdByEmpId: e.relationshipEmployeeId,
                  cc: e.emailCC || [],
                  bcc: e.emailBCC || [],
                  customerId: e.customerId,
                  relationshipId: e.relationshipId,
                  customerCommunicationTxId:e.customerCommunicationTxId,
               
                }
                const image = e.soAwsKeys.map((val)=>{
                  return getAWSFileURL(val.awsKey,val.bucketName)
                  
                }
              )
                let formData = new FormData();
                formData.append('payload', JSON.stringify(payload));
                if (image.length) {
                  const fileData = base64Toblob(image)
                  formData.append('files', fileData);
                }
                this.props.sendEmail(this.props,formData);
              }}
            >
              <i className={ICONS.EDIT} /> Resend Email
            </Menu.Item>
          {(customerCommunicationPermission?.update && e.origination !== "System") ? (
            <Menu.Item
              key="2"
              value="edit"
              onClick={() => {
                handleCreateUpdateCommunication(e);
              }}
            >
              <i className={ICONS.EDIT} /> Edit
            </Menu.Item>
          ) : (
            <div>
              <i className="fa fa-lock" />
            </div>
          )}

          {(customerCommunicationPermission?.delete && e.origination !== "System") ? (
            <Menu.Item
              key="3"
              onClick={() => {
                deleteCommunication(e);
              }}
            >
              <i className={ICONS.DELETE} /> Delete
            </Menu.Item>
          ) : (
            <div>
              <i className="fa fa-lock" />
            </div>
          )}
        </Menu>
      );
    };

    const handleSortColumn = (index) => {
        
      const currentSortDirection = this.props.sortStates[index] || "none";
      const nextSortDirection =
        currentSortDirection === "none" ? "asc" :
        currentSortDirection === "asc" ? "desc" : "none";
    
      // Update sorting state using props.updateState
      this.props.updateState({
        sortStates: {
          // ...props.sortStates,
          [index]: nextSortDirection,
        },
      });
    
      // Call your sorting function
      const direction = nextSortDirection === "desc" ? 1 : 0;
      sortTable(document.getElementById("communicationList-table"), index, direction);
    };
    const getSortIcon = (index) => {
        const sortState = this.props.sortStates[index];
        if (sortState === "asc") return <i className={ICONS.SORT_UP} />;
        if (sortState === "desc") return <i className={ICONS.SORT_DOWN} />;
        return (
          <i
            className={`${ICONS.SORT} ${
              this.props.hoveredColumn === index ? "opopacity1" : "opopacity0"
            } `}
          />
        );
    };

    return (
      <Fragment>
        
        {false ?
          <div className="view-top-bar">
            <div className="top-view-left">
              <div className="page-heading">Communication</div>
             
            </div>
            <div className="top-view-right">
              {checkACLPermission(
                primaryPerm,
                MODULE_CODED_VALUES.CUSTOMER_COMMUNICATION,
                PERMISSION_VALUES.CREATE
              ) && (
                  <button
                    className="create-action-btn"
                    onClick={() => {
                      handleCreateUpdateCommunication({});
                    }}
                  >
                    <i className="fa fa-address-book" />
                    <FormattedMessage
                      id="newCommunication.text"
                      defaultMessage=""
                    />
                  </button>
                )}
              {/* <span className={buttonDropDown ? "arrow-down active" : "arrow-down"} onClick={toggleDropdown}>
                                <i className="fa fa-angle-down" />
                            </span>

                            <ul className="dropdown">
                                <li
                                    className="option-list"
                                    onClick={() => {
                                        const modalData = {
                                            title: <React.Fragment>
                                                <div className='modal-title'>
                                                    <FormattedMessage id='newCommunication.text' defaultMessage="" />
                                                </div>
                                                <div className="modal-sub-title">
                                                    {this.props.customerName}
                                                </div>
                                            </React.Fragment>,
                                            modalBody: <LogCommunication
                                                customerId={this.props.customerId}
                                                customerName={this.props.customerName}
                                            />,
                                            width: 500,
                                            hideFooter: true,
                                            wrapClassName: 'log-communication-modal',
                                        };
                                        this.props.pushModalToStack(modalData);
                                    }}
                                >
                                    <i className="fa fa-plus" />
                                    <FormattedMessage id='newCommunication.text' defaultMessage="" />
                                </li>
                                <li className="option-list">
                                    <i className="fa fa-file-pdf-o" />
                                    <FormattedMessage id='common.downloadPdf' defaultMessage="" />
                                </li>
                                <li className="option-list">
                                    <i className="fa fa-file-excel-o" />
                                    <FormattedMessage id='common.downloadPdf' defaultMessage="" />
                                </li>
                                <li className="option-list">
                                    <i className="fa fa-envelope" />
                                    <FormattedMessage id='email.text' defaultMessage="" />
                                </li>
                                </ul> */}
            </div>
          </div>
          :
          ''
        }

        {/* <div className='agGridWrapper'>
                    <div className="ag-theme-balham" style={agGridStyle} >
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={commuincationList || []}
                            rowDragManaged={true}
                            suppressClickEdit={true}
                            noSingleClickEdit={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            getRowHeight={getRowHeight}
                            editType={'fullRow'}
                            onGridReady={onGridReady}
                        >
                        </AgGridReact>
                    </div>
                </div> */}
        {/* <Table dataSource={commuincationList} columns={columnDef} style={{ width: '100%' }} /> */}
        <div className="view-container">
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                Communication List
              </div>
              {
                (commuincationList || []).length ? <>
                  <div className="vertical-sep" />
                  <div>{commuincationList?.length}</div>
                </> : ''
              }
            </div>


            <div className="right-actions">
              { customerCommunicationPermission.create ?
                  <Button
                    className="create-action-btn"
                    style={{backgroundColor:"#1cb961", color: '#ffffff'}}
                    onClick={() => {
                      handleCreateUpdateCommunication({});
                    }}
                  >
                    <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
                  </Button>
                  :
                  ''
              }
            </div>
          </div>


          {/* <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">Communication List</div>

                <div className="vertical-sep" />
                <div>{commuincationList?.length}</div>
              </div>
              <div className="right-actions">
                {
                    checkACLPermission(
                        primaryPerm,
                        MODULE_CODED_VALUES.CUSTOMER_COMMUNICATION,
                        PERMISSION_VALUES.CREATE
                        ) ? 
                            <Button 
                                className="create-action-btn" 
                                style={{backgroundColor:"#1cb961"}}
                                onClick={()=>{
                                    handleCreateUpdateCommunication({});
                                }}
                            >
                                <i className={ICONS.ADD} style={{margin: '4px 5px 0px 0px'}}/> <FormattedMessage id="button.create.label" defaultMessage="" />
                            </Button>
                        :
                        ''
                }
              </div>
            </div> */}
          <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 16 }}>

            <div className="table-container table-h-profile">
              <table id="communicationList-table">
                <thead>
                  <tr>
                    <th>
                      <div
                        onClick={() => handleSortColumn(1)}
                        onMouseEnter={() => this.props.updateState({ hoveredColumn: 1 })}
                        onMouseLeave={() => this.props.updateState({ hoveredColumn: null })}
                        className="sortTable-Container justify-start">
                        <FormattedMessage id="common.type" defaultMessage="" />
                        {getSortIcon(1)}
                      </div>
                    </th>
                    <th>
                      <div
                        onClick={() => handleSortColumn(2)}
                        onMouseEnter={() => this.props.updateState({ hoveredColumn: 2 })}
                        onMouseLeave={() => this.props.updateState({ hoveredColumn: null })}
                        className="sortTable-Container justify-start">
                        <FormattedMessage
                          id="common.description"
                          defaultMessage=""
                        />
                        {getSortIcon(2)}
                      </div>
                    </th>
                    <th>
                      <div
                        onClick={() => handleSortColumn(3)}
                        onMouseEnter={() => this.props.updateState({ hoveredColumn: 3 })}
                        onMouseLeave={() => this.props.updateState({ hoveredColumn: null })}
                        className="sortTable-Container justify-start">
                        <FormattedMessage
                        id="communicationTo.text"
                        defaultMessage=""
                      />
                        {getSortIcon(3)}
                      </div>
                    </th>
                    <th>
                    < div
                        onClick={() => handleSortColumn(4)}
                        onMouseEnter={() => this.props.updateState({ hoveredColumn: 4 })}
                        onMouseLeave={() => this.props.updateState({ hoveredColumn: null })}
                        className="sortTable-Container justify-start">
                        <FormattedMessage
                        id="transaction.text"
                        defaultMessage=""
                      />
                        {getSortIcon(4)}
                      </div>
                    </th>
                    <th>
                      <div
                        onClick={() => handleSortColumn(5)}
                        onMouseEnter={() => this.props.updateState({ hoveredColumn: 5 })}
                        onMouseLeave={() => this.props.updateState({ hoveredColumn: null })}
                        className="sortTable-Container justify-start">
                        <FormattedMessage
                        id="common.date.text"
                        defaultMessage=""
                      />
                        {getSortIcon(5)}
                      </div>
                    </th>
                    <th>
                      <div
                        onClick={() => handleSortColumn(6)}
                        onMouseEnter={() => this.props.updateState({ hoveredColumn: 6 })}
                        onMouseLeave={() => this.props.updateState({ hoveredColumn: null })}
                        className="sortTable-Container justify-start">
                        <FormattedMessage
                          id="origination.text"
                          defaultMessage=""
                        />
                        {getSortIcon(6)}
                      </div>
                    </th>
                    <th>
                      <div
                        onClick={() => handleSortColumn(7)}
                        onMouseEnter={() => this.props.updateState({ hoveredColumn: 7 })}
                        onMouseLeave={() => this.props.updateState({ hoveredColumn: null })}
                        className="sortTable-Container justify-start">
                        <FormattedMessage id="common.type" defaultMessage="" />
                        {getSortIcon(7)}
                      </div>
                      
                    </th>
                    {
                      (customerCommunicationPermission?.update || customerCommunicationPermission?.delete) ?  <th className='text-center'>
                      <FormattedMessage
                        id="customer.listing.header.action"
                        defaultMessage=""
                      />
                    </th>: ""
                    }
                   
                  </tr>
                </thead>
                <tbody>
                  {(commuincationList || []).length > 0
                    ? commuincationList?.map((rowData, index) => (
                      <tr key={index}>
                        {/* <td>
                          {rowData.origination !== "System" ? (
                            <div>{rowData.communicationMode}</div>
                          ) : (
                            <Link
                              onClick={() => {
                                this.props.updateState({
                                  communicationReviewDrawerVisible: true,
                                  communicationDetail: rowData,
                                });
                              }}
                            >
                              {rowData.communicationMode}
                            </Link>
                          )}
                        </td> */}
                          <td>                         
                            <div
                            className="link line-view"
                              onClick={() => {
                                this.props.updateState({
                                  communicationReviewDrawerVisible: true,
                                  communicationDetail: rowData,
                                });
                              }}
                            >
                              {rowData.communicationMode}
                            </div>
                       
                        </td>
                        <td>{rowData.description ? rowData.description : <EmptyBox boxSize={"small"} />}
                        </td>
                        <td>{rowData.communiatedTo ? rowData.communiatedTo : <EmptyBox boxSize={"small"} />}</td>
                        <td>{rowData.transactionType ? rowData.transactionType : <EmptyBox boxSize={"small"} />}</td>
                        <td>
                          {rowData.communicationDate
                            ? getMomentDateForUIReadOnly({
                              date: new Date(rowData.communicationDate),
                              format,
                            })
                            : <EmptyBox boxSize={"small"} />}
                        </td>
                        <td>
                          {rowData.origination ? rowData.origination : <EmptyBox boxSize={"small"} />}
                        </td>
                        <td>{rowData.relationshipEmployeeName ? rowData.relationshipEmployeeName : <EmptyBox boxSize={"small"} />}</td>
                      {
                        (customerCommunicationPermission?.update || customerCommunicationPermission?.delete) ?  <td className='text-center'>
                        <div className="action-icon">
                          {rowData.origination !== "System" ?
                            <Dropdown
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <MoreOutlined />
                            </Dropdown>
                            :
                            <i className="fi fi-rr-lock" />

                          }
                        </div>
                        
                      </td>: ""
                      }
                       
                      </tr>
                    ))
                    :
                    <tr key="empty-data">
                      <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                        <EmptyTableData />
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </Skeleton>
          <div className="footer">
            <div className="f-left"></div>
            <div className="f-right"></div>
          </div>
        </div>
      </Fragment>);
  }
}

export default Communication;
