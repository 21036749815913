import React, { Fragment ,useEffect,useState} from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col,Skeleton } from "antd";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import ReactHtmlParser from "react-html-parser";
import config from "../../../../../config/environmentConfig";
import { CONSTANTS } from "../../../../../static/constants";
import {
  base64Toblob,
  getAWSFileURL,
  fixedDecimalNumber,
  getMomentDateForUIReadOnly,
  numberToWordsWithDecimals,
  fixedDecimalQty,
} from "../../../../../utils";
import { ImageCropper } from "../../../../../components/general/ImageCropper";
import { CustomUploader } from "../../../../../components/general/CustomUploader";
// import { LogoComponent } from '../../../../general/LogoComponent';
import { AWSFileTable } from "../../../../general/AWSFileTable";

const PODetails = (props) => {
  const { poData, companyInfo } = props;
  const { supplierPurchaseOrderDetailList } = poData;
  const relationshipAddress =
    find(poData.boLocationPOList, {
      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
    }) || {};
  const billingAddress =
    find(poData.boLocationPOList, {
      locationType: CONSTANTS.BILLING_ADDRESS,
    }) || {};
  const relShippingAddress =
    find(poData.boLocationPOList, {
      locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
    }) || {};
  let isPlaceOfSupplySameAsRelationship = false;
  isPlaceOfSupplySameAsRelationship =
    (poData.placeOfSupply &&
      billingAddress.stateName === poData.placeOfSupply) ||
    false;
  let itemLevelTaxes = [];
  if (supplierPurchaseOrderDetailList) {
    itemLevelTaxes = calculateTaxRateList(
      supplierPurchaseOrderDetailList,
      isPlaceOfSupplySameAsRelationship
    );
  }
  let isColumnEmpty = {
    product: true,
    service: true,
    variant: true,
    description: true,
    qty: true,
    uomName: true,
    rate: true,
    hsn: true,
    discount: true,
    tax: true,
    specialDiscount: true,
  };
  if (poData) {
    isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, poData, props);
  }

  const customProps = {
    type: CONSTANTS.TYPE_IMAGE,
    imgUrl:
      getAWSFileURL(
        (poData || {}).stampAwsKey,
        config.BUCKET_NAME.BO_PURCHASE_ORDER
      ) || "",
    isSignature: true,
    height: 60,
    width: 60,
    name: "file",
    action: null,
    buttonName: "Upload Customer PO",
    onChange: (file) => {
      if (file[0]?.originFileObj) {
        var reader = new FileReader();

        reader.onload = function (e) {
          props.showModal({
            title: (
              <Fragment>
                <div className="ant-modal-title">Crop Image</div>
              </Fragment>
            ),
            //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
            modalBody: (
              <ImageCropper
                onClick={(e) => { }}
                handleSubmit={(newUrl) => {
                  props.uploadStamp({
                    supplierId: poData.supplierId,
                    poMasterId: poData.poMasterId,
                    rid: poData.relationshipId,
                    objectKey: poData.stampAwsKey,
                    bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                    file: base64Toblob(newUrl),
                  });
                }}
                {...props}
                url={e.target.result}
              />
            ),
            width: 800,
            hideFooter: true,
            wrapClassName: "Upload-profile-modal",
          });
        };

        reader.readAsDataURL(file[0]?.originFileObj); // convert to base64 string
      }
    },
  };
  const [showComp, setShowComp] = useState(false);
    useEffect(()=>{
      const delay = setTimeout(()=>{
        setShowComp(true);
      }, 400);
      return ()=> clearTimeout(delay);
    }, []);
    const getContactNameFragment = (additionInfo) => {
      if (!additionInfo) return <Fragment />;
      let contactName = "";
      if (additionInfo.salutationName)
        contactName += additionInfo.salutationName + " ";
      if (additionInfo.firstName) contactName += additionInfo.firstName;
      if (additionInfo.middleName) contactName += " " + additionInfo.middleName;
      if (additionInfo.lastName) contactName += " " + additionInfo.lastName;
      const fragmentObj = (
        <Fragment>
          {contactName && contactName.length ? (
            <div className="title">{contactName}</div>
          ) : (
            ""
          )}
          <div>
            {additionInfo?.emailAddress || ''}
          </div>
          <div>
            {additionInfo?.workPhone || additionInfo?.cellPhone || ''}
          </div>
        </Fragment>
      );
      return fragmentObj;
    };
  return (
    <div className={"custom-modal show"}>
      <i className="close-icon">
        <svg
          width="17"
          height="17"
          onClick={() => {
            props.updateState &&
              props.updateState({
                isTableRendered: false,
              });
            props.popModalFromStack();
          }}
        >
          <title>
            <FormattedMessage id="button.close.label" defaultMessage="" />
          </title>
          <path
            fill="#67757d"
            fillRule="evenodd"
            d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"
          />
        </svg>
      </i>
      <div className="modal-dialog txn-modal-dialog">
        <div className="modal-content full-width vertical-view-data">
         

          <div className="modal-header"></div>
          {showComp ? 
          <div id="so-readonly" className="inner-content">
            <div className="brand-logo">
             </div>
            <div className="doc-title">
              {poData.documentName || "Purchase Order"}
            </div>
            <Row>
              <Col span={16}>
              <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                <div className="read-only-content pt20">
                 
                  <div className="title lh-adjust pb5">
                    {props.companyInfo.storeName}
                  </div>
                  <div>
                    {getAddressInfoFragment(relationshipAddress, companyInfo)}
                    <div>
                      {getTaxIdentificationsFragment(
                        ((poData.additionalInfoList || [])[0] || {})
                          .relationshipTaxIdentifications
                      )}
                    </div>
                  </div>
                </div>
                <div className="read-only-content pt10">
                  <div className="title lh-adjust underline-text pb5">
                    <FormattedMessage
                      id="modal.txDetails.common.shipTo"
                      defaultMessage=""
                    />
                  </div>
                  
                  <div>{getAddressInfoFragment(relShippingAddress, {})}</div>
                </div>
                </Skeleton>
              </Col>
              <Col span={4}>
              <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                <div className="read-only-content pt20">
                  <div className="pb5">
                    <div className="title">
                      <FormattedMessage
                        id="modal.txDetails.purchaseOrder.poNumber"
                        defaultMessage=""
                      />
                    </div>

                    <div>{poData.poNumber}</div>
                  </div>

                  <div className="pb5">
                    <div className="title">
                      <FormattedMessage
                        id="common.dateCreated"
                        defaultMessage=""
                      />
                    </div>

                    <div>
                      {poData.poDate &&
                        getMomentDateForUIReadOnly({
                          date: poData.poDate,
                          format: CONSTANTS.DISPLAY_DATE_FORMAT,
                        })}
                    </div>
                  </div>
                  {poData.poDueDate && (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.purchaseOrder.poDueDate"
                          defaultMessage=""
                        />
                      </div>

                      <div>
                        {poData.poDueDate &&
                          getMomentDateForUIReadOnly({
                            date: poData.poDueDate,
                            format: CONSTANTS.DISPLAY_DATE_FORMAT,
                          })}
                      </div>
                    </div>
                  )}

                  {poData.expectedDeliveryDate && (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.purchaseOrder.expectedDelivery"
                          defaultMessage=""
                        />
                      </div>

                      <div>
                        {poData.expectedDeliveryDate &&
                          getMomentDateForUIReadOnly({
                            date: poData.expectedDeliveryDate,
                            format: CONSTANTS.DISPLAY_DATE_FORMAT,
                          })}
                      </div>
                    </div>
                  )}

                  {!!poData.isMultiCurrency && (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.foreignCurrency"
                          defaultMessage=""
                        />
                      </div>

                      <div>{poData.foreignCurrency}</div>
                    </div>
                  )}

                  {!!poData.isMultiCurrency && (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.exchangeRate"
                          defaultMessage=""
                        />
                      </div>

                      <div>{poData.exchangeRate}</div>
                    </div>
                  )}
                  {poData.projectMasterId ? (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.project"
                          defaultMessage=""
                        />
                      </div>
                      <div>
                        <abbr>
                          {poData.projectName}
                        </abbr>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {poData.isDropShipping ? (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.dropshipOrder"
                          defaultMessage=""
                        />
                      </div>
                      <div>
                        <abbr>
                          <FormattedMessage id="yes" defaultMessage="" />{" "}
                        </abbr>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {poData.rfqNumber ? (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.purchaseOrder.rfqNumber"
                          defaultMessage=""
                        />
                      </div>
                      <div>
                        <abbr>{poData.rfqNumber}</abbr>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {poData.customerId ? (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="common.customer.text"
                          defaultMessage=""
                        />
                      </div>
                      <div>
                        <abbr>{poData.customerName}</abbr>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                </Skeleton>
              </Col>
              <Col span={4}>
                  <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                    <div className="d-inline-flex w-100">
                      <div className="read-only-content pt20 pl10">
                        <div className=" lh-adjust pb5">
                          <div>
                            <div>
                              <div className="title">
                                {poData.supplierName}
                              </div>
                              <div>
                                {getAddressInfoFragment(
                                  billingAddress,
                                  poData.additionalInfoList
                                    ? poData.additionalInfoList[0] || {}
                                    : {}
                                )}
                                <div> {poData.additionalInfoList
                                  ? (poData.additionalInfoList[0] || {})?.supplierEmail
                                  : ""}</div>
                                <div>
                                  {poData.additionalInfoList
                                    ? (poData.additionalInfoList[0] || {})?.supplierPhone
                                    : ""}
                                </div>
                                {getTaxIdentificationsFragment(
                                  ((poData.additionalInfoList || [])[0] || {})
                                    .taxIdentifications
                                )}
                              </div>



                            </div>

                          </div>
                          <div className="pb5">
                            {getContactNameFragment(
                              poData.additionalInfoList
                                ? poData.additionalInfoList[0] || {}
                                : {}
                            )}
                          </div>

                        </div>

                      </div>
                    </div>
                  </Skeleton>
                </Col>
            </Row>
            <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>
            <div className="modal-table-container vertical-space">
              <table>
                <thead>
                  <tr>
                    {poData.supplierQuoteNumber && (
                      <th>
                        <FormattedMessage
                          id="modal.txDetails.purchaseOrder.supplierQuoteNumber"
                          defaultMessage=""
                        />
                      </th>
                    )}
                    <th>
                      <FormattedMessage
                        id="modal.txDetails.common.refNumber"
                        defaultMessage=""
                      />
                    </th>
                    {props.companyInfo.countryName === "India" && (
                      <th>
                        <FormattedMessage
                          id="modal.txDetails.common.placeOfSupply"
                          defaultMessage=""
                        />
                      </th>
                    )}
                    {/* <th>Shipping Address</th> */}
                    <th>
                      <FormattedMessage
                        id="modal.txDetails.common.paymentTerm"
                        defaultMessage=""
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {poData.supplierQuoteNumber && (
                      <th>{poData.supplierQuoteNumber || "-"}</th>
                    )}
                    <td>{poData.reference || "-"}</td>
                    {props.companyInfo.countryName === "India" && (
                      <td>{poData.placeOfSupply || "-"}</td>
                    )}
                    {/* <td>{relShippingAddress ?
                                            ((relShippingAddress.streetAddress1 || '') + ' ' + (relShippingAddress.streetAddress2 || ''))
                                            : '-'}
                                        </td> */}
                    <td>{poData.paymentTermName || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div className="modal-table-container mb15">
              <table className="transaction-details-table">
                <thead>
                  <tr>
                    <th className="cell-size-50">#</th>
                    {(!isColumnEmpty.product || !isColumnEmpty.description) && (
                      <th>
                        {!isColumnEmpty.product && (
                          <span>
                            {" "}
                            <FormattedMessage
                              id="modal.txDetails.common.product"
                              defaultMessage=""
                            />
                          </span>
                        )}
                        {!isColumnEmpty.product &&
                          !isColumnEmpty.description && <span> & </span>}
                        {!isColumnEmpty.description && (
                          <span>
                            {" "}
                            <FormattedMessage
                              id="modal.txDetails.common.description"
                              defaultMessage=""
                            />
                          </span>
                        )}
                      </th>
                    )}
                    {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                    {!isColumnEmpty.qty && (
                      <th className="text-center">
                        <FormattedMessage
                          id="modal.txDetails.common.qty"
                          defaultMessage=""
                        />
                      </th>
                    )}
                    {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                    {!isColumnEmpty.specialDiscount && (
                      <th className="right-text">
                        <FormattedMessage
                          id="modal.txDetails.common.basePrice"
                          defaultMessage=""
                        />
                      </th>
                    )}
                    {!isColumnEmpty.specialDiscount && (
                      <th>
                        <FormattedMessage
                          id="modal.txDetails.common.specialDiscount"
                          defaultMessage=""
                        />
                      </th>
                    )}
                    {!isColumnEmpty.rate && (
                      <th>
                        <FormattedMessage
                          id="modal.txDetails.common.rate"
                          defaultMessage=""
                        />
                      </th>
                    )}
                    {!isColumnEmpty.hsn && (
                      <th>
                        <FormattedMessage
                          id="modal.txDetails.common.hsn"
                          defaultMessage=""
                        />
                      </th>
                    )}
                    {!isColumnEmpty.discount && (
                      <th>
                        <FormattedMessage
                          id="modal.txDetails.common.discount"
                          defaultMessage=""
                        />
                      </th>
                    )}
                    {!isColumnEmpty.tax && (
                      <th>
                        <FormattedMessage
                          id="modal.txDetails.common.tax"
                          defaultMessage=""
                        />
                      </th>
                    )}
                    <th>
                      <FormattedMessage
                        id="modal.txDetails.common.netAmount"
                        defaultMessage=""
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(supplierPurchaseOrderDetailList || []).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td className="cell-size-50">{i + 1}</td>
                        {(!isColumnEmpty.product ||
                          !isColumnEmpty.description) && (
                            <td>
                              <strong>{getVariantFragment(item)}</strong>
                              {!isColumnEmpty.description && (
                                <div className="description-cell">
                                  <pre>
                                    {ReactHtmlParser(item.description || "-")}
                                  </pre>
                                </div>
                              )}
                            </td>
                          )}
                        {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                        {!isColumnEmpty.qty && (
                          <td className="text-center">
                            {fixedDecimalQty(item.quantity) +
                              (item.uomName ? " " + item.uomName : "")}
                          </td>
                        )}
                        {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                        {!isColumnEmpty.specialDiscount && (
                          <td>{fixedDecimalNumber(item.basePrice) || "-"}</td>
                        )}
                        {!isColumnEmpty.specialDiscount && (
                          <td>{getSpecialDiscount(item, "amountPercent")}</td>
                        )}
                        {!isColumnEmpty.rate && (
                          <td>
                            {fixedDecimalNumber(item.anItemPurchasePrice) ||
                              "-"}
                          </td>
                        )}
                        {!isColumnEmpty.hsn && <td>{item.hsnCode || "-"}</td>}
                        {!isColumnEmpty.discount && (
                          <td>
                            {getTxTypeData(item, "discount", "amount")}{" "}
                            {getTxTypeData(item, "discount", "amountPercent")}
                          </td>
                        )}
                        {!isColumnEmpty.tax && (
                          <td>
                            {getTotalTaxAmount(item, isPlaceOfSupplySameAsRelationship)}{" "}
                            {getTotalTaxPercent(item, "amountPercent", isPlaceOfSupplySameAsRelationship)}
                          </td>
                        )}
                        <td>{getNetAmount(item, isPlaceOfSupplySameAsRelationship) || "-"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              
              <Col span={24} className="">
                        {poData.isMultiCurrency ? (
                          <span>
                            {" "}
                            {poData.foreignCurrencyIcon ? (
                              <i className={poData.foreignCurrencyIcon}></i>
                            ) : (
                              poData.foreignCurrency
                            )}{" "}
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {props.companyInfo.currencyIcon ? (
                              <i className={props.companyInfo.currencyIcon}></i>
                            ) : (
                              props.companyInfo.currencyCode + " "
                            )}{" "}
                          </span>
                        )}{" "}
                        {(poData.totalPOAmount &&
                          numberToWordsWithDecimals(fixedDecimalNumber(poData.totalPOAmount))) ||""}
                      </Col>
            </div>

            <Row>
              <Col span={9}>
                {poData.docDetailList && poData.docDetailList.length ? (
                  <Row>
                    <Col span={24} className="title">
                      <FormattedMessage
                        id="modal.txDetails.common.attachments"
                        defaultMessage=""
                      />
                    </Col>
                    <Col span={22}>
                      <AWSFileTable
                        {...props}
                        docDetailList={poData.docDetailList}
                        fetchTxDetail={(data) => {
                          props.fetchPurchasedOrderDetail({
                            supplierId: poData.supplierId,
                            poMasterId: poData.poMasterId,
                            relationshipId: poData.relationshipId,
                          });
                        }}
                      />
                      {/* {
                                            poData.docDetailList.map((doc) => {
                                                return <span className={"cursor-pointer"} onClick={() => {
                                                    props.downloadFileFromBucket({
                                                        bucketName: doc.bucketName,
                                                        awsKeyName: doc.awsKeyName,
                                                        fileName: doc.fileName
                                                    })
                                                }}>{doc.fileName} &nbsp;</span>
                                            })
                                        } */}
                    </Col>
                  </Row>
                ) : null}
                <br />
                
                {poData.shippingInstructions && (
                  <Row className="read-only-content">
                    <Col span={7} className="title">
                      <FormattedMessage
                        id="modal.txDetails.common.shippingInstructions"
                        defaultMessage=""
                      />
                    </Col>
                    <Col span={17}>
                      {ReactHtmlParser(poData.shippingInstructions)}
                    </Col>
                  </Row>
                )}

                {poData.remarksInternal && (
                  <><Row className="read-only-content">
                      <Col span={7} className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.internalRemarks"
                          defaultMessage="" />
                      </Col>
                    </Row>
                    <Row gutter={[16,16]}>

                    <Col span={17}>
                        {ReactHtmlParser(poData.remarksInternal)}
                      </Col>
                    </Row>
                      </>
                )}

                {poData.remarksSupplier && (
                  <><Row className="read-only-content">
                      <Col span={7} className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.supplierRemarks"
                          defaultMessage="" />
                      </Col>
                    </Row><Col span={17}>
                        {ReactHtmlParser(poData.remarksSupplier)}
                      </Col></>
                )}
              </Col>

              <Col offset={7} span={8}>
                <div className="bill-summary mt5">
                  <Row>
                    <Col span={14}>
                      <div className="right-txt">
                        <FormattedMessage
                          id="modal.txDetails.common.subTotal"
                          defaultMessage=""
                        />
                      </div>
                    </Col>

                    <Col span={10} className="text-right ">
                      {poData.isMultiCurrency ? (
                        <span>
                          {" "}
                          {poData.foreignCurrencyIcon ? (
                            <i className={poData.foreignCurrencyIcon}></i>
                          ) : (
                            poData.foreignCurrency
                          )}{" "}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          {props.companyInfo.currencyIcon ? (
                            <i className={props.companyInfo.currencyIcon}></i>
                          ) : (
                            props.companyInfo.currencyCode + " "
                          )}{" "}
                        </span>
                      )}{" "}
                      {getSubTotalOrTaxOrDiscountAmount(poData, "SubTotal")}
                    </Col>
                  </Row>

                  <div className="pt10">
                    <Row>
                      <Col span={14}>
                        <div className="right-txt">
                          <FormattedMessage
                            id="modal.txDetails.common.discount"
                            defaultMessage=""
                          />
                        </div>
                      </Col>

                      <Col span={10} className="text-right ">
                        {poData.isMultiCurrency ? (
                          <span>
                            {" "}
                            {poData.foreignCurrencyIcon ? (
                              <i className={poData.foreignCurrencyIcon}></i>
                            ) : (
                              poData.foreignCurrency
                            )}{" "}
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {props.companyInfo.currencyIcon ? (
                              <i className={props.companyInfo.currencyIcon}></i>
                            ) : (
                              props.companyInfo.currencyCode + " "
                            )}{" "}
                          </span>
                        )}{" "}
                        {getSubTotalOrTaxOrDiscountAmount(poData, "Discount")}
                      </Col>
                    </Row>
                  </div>
                  {poData.supplierPOCOATxList &&
                      poData.supplierPOCOATxList.length &&
                      filter(poData.supplierPOCOATxList || [], {
                        txType: "expense",
                        accountingEntry: "debit",
                      }).map((expenseObject, i) => {
                        return (
                          <div key={"exp" + i}>
                            <Row className="pt10">
                              <Col span={14}>
                                <div className="right-txt">
                                  <FormattedMessage
                                    id="modal.txDetails.common.expense"
                                    defaultMessage=""
                                  />
                                  ({expenseObject.ledgerAccountName || ""})
                                </div>
                              </Col>
                              <Col span={10} className="text-right">
                                {poData.isMultiCurrency ? (
                                  <span>
                                    {" "}
                                    {poData.foreignCurrencyIcon ? (
                                      <i
                                        className={
                                          poData.foreignCurrencyIcon
                                        }
                                      ></i>
                                    ) : (
                                      poData.foreignCurrency
                                    )}{" "}
                                  </span>
                                ) : (
                                  <span>
                                    {" "}
                                    {props.companyInfo.currencyIcon ? (
                                      <i
                                        className={props.companyInfo.currencyIcon}
                                      ></i>
                                    ) : (
                                      props.companyInfo.currencyCode + " "
                                    )}{" "}
                                  </span>
                                )}
                                {fixedDecimalNumber(expenseObject.amount)}
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  {getTxTypeData(poData, "expense", "amount") ? (
                    <div className="pt10">
                      <Row>
                        <Col span={14}>
                          <div className="right-txt">
                          <FormattedMessage
                                id="total.text"
                                defaultMessage=""
                              />{" "}
                            <FormattedMessage
                              id="modal.txDetails.common.expense"
                              defaultMessage=""
                            />
                          </div>
                        </Col>

                        <Col span={10} className="text-right ">
                          {poData.isMultiCurrency ? (
                            <span>
                              {" "}
                              {poData.foreignCurrencyIcon ? (
                                <i className={poData.foreignCurrencyIcon}></i>
                              ) : (
                                poData.foreignCurrency
                              )}{" "}
                            </span>
                          ) : (
                            <span>
                              {" "}
                              {props.companyInfo.currencyIcon ? (
                                <i
                                  className={props.companyInfo.currencyIcon}
                                ></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}{" "}
                            </span>
                          )}
                          {/* {getTxTypeData(poData, "expense", "amount")} */}
                          {getSubTotalOrTaxOrDiscountAmount(
                              poData,
                              "expense"
                            )}
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}
                  {itemLevelTaxes &&
                    itemLevelTaxes.length !== 0 &&
                    itemLevelTaxes.map((taxObj, i) => {
                      return props.companyInfo.countryName === "India" ? (
                        isPlaceOfSupplySameAsRelationship ? (
                          <div key={i}>
                            <Row className="pt10">
                              <Col span={14}>
                                <div className="right-txt">
                                  CGST @{" "}
                                  {taxObj.taxPercent}%
                                  on {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                </div>
                              </Col>
                              <Col span={10} className="text-right ">
                                {poData.isMultiCurrency ? (
                                  <span>
                                    {" "}
                                    {poData.foreignCurrencyIcon ? (
                                      <i
                                        className={poData.foreignCurrencyIcon}
                                      ></i>
                                    ) : (
                                      poData.foreignCurrency
                                    )}{" "}
                                  </span>
                                ) : (
                                  <span>
                                    {" "}
                                    {props.companyInfo.currencyIcon ? (
                                      <i
                                        className={
                                          props.companyInfo.currencyIcon
                                        }
                                      ></i>
                                    ) : (
                                      props.companyInfo.currencyCode + " "
                                    )}{" "}
                                  </span>
                                )}
                                {fixedDecimalNumber(taxObj.taxAmount)}
                              </Col>
                            </Row>
                            <Row className="pt10">
                              <Col span={14}>
                                <div className="right-txt">
                                  SGST @{" "}
                                  {fixedDecimalNumber(taxObj.taxPercent)}%
                                  on {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                </div>
                              </Col>
                              <Col span={10} className="text-right ">
                                {poData.isMultiCurrency ? (
                                  <span>
                                    {" "}
                                    {poData.foreignCurrencyIcon ? (
                                      <i
                                        className={poData.foreignCurrencyIcon}
                                      ></i>
                                    ) : (
                                      poData.foreignCurrency
                                    )}{" "}
                                  </span>
                                ) : (
                                  <span>
                                    {" "}
                                    {props.companyInfo.currencyIcon ? (
                                      <i
                                        className={
                                          props.companyInfo.currencyIcon
                                        }
                                      ></i>
                                    ) : (
                                      props.companyInfo.currencyCode + " "
                                    )}{" "}
                                  </span>
                                )}
                                {fixedDecimalNumber(taxObj.taxAmount)}
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <div key={i}>
                            <Row className="pt10">
                              <Col span={14}>
                                <div className="right-txt">
                                  {taxObj.taxName} @{" "}
                                  {fixedDecimalNumber(taxObj.taxPercent)}% on{" "}
                                  {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                </div>
                              </Col>
                              <Col span={10} className="text-right ">
                                {poData.isMultiCurrency ? (
                                  <span>
                                    {" "}
                                    {poData.foreignCurrencyIcon ? (
                                      <i
                                        className={poData.foreignCurrencyIcon}
                                      ></i>
                                    ) : (
                                      poData.foreignCurrency
                                    )}{" "}
                                  </span>
                                ) : (
                                  <span>
                                    {" "}
                                    {props.companyInfo.currencyIcon ? (
                                      <i
                                        className={
                                          props.companyInfo.currencyIcon
                                        }
                                      ></i>
                                    ) : (
                                      props.companyInfo.currencyCode + " "
                                    )}{" "}
                                  </span>
                                )}
                                {fixedDecimalNumber(taxObj.taxAmount)}
                              </Col>
                            </Row>
                          </div>
                        )
                      ) : (
                        <div key={i}>
                          <Row className="pt10">
                            <Col span={14}>
                              <div className="right-txt">
                                {taxObj.taxName} @{" "}
                                {fixedDecimalNumber(taxObj.taxPercent)}% on{" "}
                                {fixedDecimalNumber(taxObj.subtotal)}{" "}
                              </div>
                            </Col>
                            <Col span={10} className="text-right ">
                              {poData.isMultiCurrency ? (
                                <span>
                                  {" "}
                                  {poData.foreignCurrencyIcon ? (
                                    <i
                                      className={poData.foreignCurrencyIcon}
                                    ></i>
                                  ) : (
                                    poData.foreignCurrency
                                  )}{" "}
                                </span>
                              ) : (
                                <span>
                                  {" "}
                                  {props.companyInfo.currencyIcon ? (
                                    <i
                                      className={props.companyInfo.currencyIcon}
                                    ></i>
                                  ) : (
                                    props.companyInfo.currencyCode + " "
                                  )}{" "}
                                </span>
                              )}
                              {fixedDecimalNumber(taxObj.taxAmount)}
                            </Col>
                           
                            
                          </Row>
                        </div>
                      );
                    })}

                  <div className="pt10">
                    <Row>
                      <Col span={14}>
                        <div className="right-txt bold">
                          <FormattedMessage
                            id="modal.txDetails.common.grandTotal"
                            defaultMessage=""
                          />
                        </div>
                      </Col>

                      <Col span={10} className="text-right ">
                        {poData.isMultiCurrency ? (
                          <span>
                            {" "}
                            {poData.foreignCurrencyIcon ? (
                              <i className={poData.foreignCurrencyIcon}></i>
                            ) : (
                              poData.foreignCurrency
                            )}{" "}
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {props.companyInfo.currencyIcon ? (
                              <i className={props.companyInfo.currencyIcon}></i>
                            ) : (
                              props.companyInfo.currencyCode + " "
                            )}{" "}
                          </span>
                        )}{" "}
                        {(poData.totalPOAmount &&
                          fixedDecimalNumber(poData.totalPOAmount)) ||
                          ""}
                      </Col>
                    </Row>
                  </div>
                </div>

                <div
                  className="upload-link new-pic"
                  title={props.intl.formatMessage({
                    id: "uploadSignature.tooltip",
                  })}
                  style={{ width: "50px", height: "50px" }}
                >
                  <CustomUploader {...customProps} />
                  {poData.stampAwsKey && (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        props.deleteStamp(
                          {
                            supplierId: poData.supplierId,
                            poMasterId: poData.poMasterId,
                            relationshipId: poData.relationshipId,
                            objectKey: poData.stampAwsKey,
                            folderName: poData?.folderName,
                            bucketName: props?.customerInfo?.bucketName,
                          },
                          props
                        );
                      }}
                    >
                      {" "}
                      <FormattedMessage
                        id="modal.txDetails.common.deleteStamp"
                        defaultMessage=""
                      />{" "}
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <div className="contact-details">
              {poData.footer && (
                <Fragment>{ReactHtmlParser(poData.footer)}</Fragment>
              )}
            </div>
            </Skeleton>
          </div>
          :""}
        </div>
      </div>
    </div>
  );
};

const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
  let total = 0;
  data &&
    data.supplierPurchaseOrderDetailList &&
    data.supplierPurchaseOrderDetailList.forEach(function (d) {
      if (type === "SubTotal") {
        total = total + d.anItemPurchasePrice * d.quantity;
      }
      if (type === "Discount") {
        let discountObject = d.supplierPOCOATxList.find(
          (tx) => tx.txType === "discount"
        );
        if (discountObject) {
          total = total + parseFloat(discountObject.amount);
        }
      }
    });
    if (type === "expense") {
      data &&
        data.supplierPOCOATxList &&
        data.supplierPOCOATxList.forEach(function (discountObject) {
          if (
            discountObject.txType === "expense" &&
            discountObject.accountingEntry === "debit"
          ) {
            total = total + parseFloat(discountObject.amount);
          }
        });
    }
  return fixedDecimalNumber(total);
};

const getTxTypeData = (row, type, toReturn) => {
  if (!row || !row.supplierPOCOATxList) return;
  let data = row.supplierPOCOATxList.find((tx) => tx.txType === type);
  if (data) {
    if (data[toReturn] && toReturn === "amountPercent") {
      if (type === "VAT") {
        return "VAT(" + fixedDecimalNumber(data[toReturn]) + "%)";
      }
      return "(" + data[toReturn] + "%)";
    } else if (data[toReturn] && toReturn === "amount") {
      return fixedDecimalNumber(data[toReturn]);
    } else {
      return "";
    }
  } else if (!data && toReturn === "amount") {
    return "";
  } else {
    return "-";
  }
};
const getTotalTaxAmount = (row, isPlaceOfSupplySameAsRelationship) => {
  let tax1 = getTxTypeData(row, "tax", "amount", true) || 0;
  // let tax2 = getTxTypeData(row, 'sgst', 'amount', true) || 0;
  // let tax3 = getTxTypeData(row, 'igst', 'amount', true) || 0;
  // let tax4 = getTxTypeData(row, 'vat', 'amount', true) || 0;
  let totalTax = Number(tax1);
  return isPlaceOfSupplySameAsRelationship ? fixedDecimalNumber(totalTax * 2) : fixedDecimalNumber(totalTax);
};

const getTotalTaxPercent = (row, toReturn, isPlaceOfSupplySameAsRelationship) => {
  let tax1 = row.supplierPOCOATxList.find((tx) => tx.txType === "tax") || {};
  //let tax2 = row.supplierPOCOATxList.find(tx => (tx.txType === 'sgst')) || {};
  //let tax3 = row.supplierPOCOATxList.find(tx => (tx.txType === 'igst')) || {};
  // let tax4 = row.supplierPOCOATxList.find(tx => (tx.txType === 'vat')) || {};
  let totalPercent = Number(tax1.amountPercent || 0);

  if (toReturn === "amountPercent") {
    return isPlaceOfSupplySameAsRelationship ? totalPercent ? "(" + totalPercent * 2 + "%)" : "" : totalPercent ? "(" + totalPercent + "%)" : "";
  } else {
    return isPlaceOfSupplySameAsRelationship ? totalPercent * 2 : totalPercent;
  }
};

const getNetAmount = (row, isPlaceOfSupplySameAsRelationship) => {
  let tax = getTotalTaxAmount(row) || 0;
  let taxValue = isPlaceOfSupplySameAsRelationship ? tax * 2 : tax

  let discount = getTxTypeData(row, "discount", "amount", true) || 0;
  let netAmountItem =
    Number(row.quantity * row.anItemPurchasePrice) +
    Number(taxValue) -
    Number(discount);
  return fixedDecimalNumber(netAmountItem);
};

const calculateTaxRateList = (
  supplierPurchaseOrderDetailList,
  isPlaceOfSupplySameAsRelationship
) => {
  let subtotal = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let itemLevelTaxes = [];
  supplierPurchaseOrderDetailList.forEach(function (rowdata, index) {
    let totalAmountOfColumn = 0;
    if (rowdata.quantity && rowdata.anItemPurchasePrice) {
      let taxObj = {
        subtotal: 0,
        taxPercent: 0,
        taxAmount: 0,
      };
      totalAmountOfColumn = rowdata.quantity * rowdata.anItemPurchasePrice;
      let discount = getTxTypeData(rowdata, "discount", "amount");
      if (discount) {
        totalAmountOfColumn = totalAmountOfColumn - discount;
        totalDiscount = totalDiscount + discount;
      }

      let tax1 =
        rowdata.supplierPOCOATxList.find((tx) => tx.txType === "tax") || {};
      taxObj.taxPercent = Number(tax1.amountPercent);
      taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount));
      totalTax = totalTax + taxObj.taxAmount;
      taxObj.taxName = tax1.taxName;
      //let tax2 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'sgst'));
      //let tax3 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'igst'));
      // let tax4 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'vat'));
      //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
      //       taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
      //       taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
      //       totalTax = totalTax + taxObj.taxAmount;
      //   }
      //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
      //       taxObj.taxPercent = Number(tax3.amountPercent);
      //       taxObj.taxAmount = Number(tax3.amount);
      //       totalTax = totalTax + taxObj.taxAmount;
      //   }
      //   else if (tax4) {
      //     taxObj.taxPercent = Number(tax4.amountPercent);
      //     taxObj.taxAmount = Number(tax4.amount);
      //     totalTax = totalTax + taxObj.taxAmount;
      // }
      if (taxObj.taxPercent) {
        taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
        const availableTaxObj = find(itemLevelTaxes, {
          taxPercent: taxObj.taxPercent,
        });
        if (availableTaxObj) {
          itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
            if (ele.taxPercent === taxObj.taxPercent) {
              ele.subtotal = ele.subtotal + taxObj.subtotal;
              ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
            }
            return true;
          });
        } else {
          taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
          itemLevelTaxes.push(taxObj);
        }
      }
      subtotal = subtotal + totalAmountOfColumn;
    }
  });

  return itemLevelTaxes;
};

const updateColumnEmptyCheck = (isColumnEmpty, poData, props) => {
  if (!poData) return isColumnEmpty;
  for (let i in poData.supplierPurchaseOrderDetailList) {
    let row = poData.supplierPurchaseOrderDetailList[i];
    if (row.itemName) {
      isColumnEmpty.product = false;
    }
    if (row.serviceName) {
      isColumnEmpty.service = false;
    }
    if (row.itemVariantName) {
      isColumnEmpty.variant = false;
    }
    if (row.description) {
      isColumnEmpty.description = false;
    }
    if (row.quantity) {
      isColumnEmpty.qty = false;
    }
    if (row.uomName) {
      isColumnEmpty.uomName = false;
    }
    if (row.anItemPurchasePrice) {
      isColumnEmpty.rate = false;
    }
    if (row.specialDiscount) {
      isColumnEmpty.specialDiscount = false;
    }
    if (row.hsnCode && props.companyInfo.countryName === "India") {
      isColumnEmpty.hsn = false;
    }
    if (!isTxTypeEmpty(row, "discount")) {
      isColumnEmpty.discount = false;
    }
    if (!isTxTypeEmpty(row, "tax")) {
      isColumnEmpty.tax = false;
    }
    //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
    //if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
    //if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
    if (row.documentDetails && row.documentDetails.length) {
      isColumnEmpty.documentDetails = false;
    }
  }
  return isColumnEmpty;
};

const isTxTypeEmpty = (row, type) => {
  if (!row) return true;
  let data = row.supplierPOCOATxList.find((tx) => tx.txType === type);
  if (data && data.amount) {
    return false;
  } else {
    return true;
  }
};

const getSpecialDiscount = (row, toReturn) => {
  if (!row) return;
  let specialDiscount = row.basePrice - row.anItemPurchasePrice;
  let percent,
    amount = 0;
  if (specialDiscount && row.basePrice) {
    if (toReturn === "amountPercent") {
      percent = fixedDecimalNumber((specialDiscount * 100) / row.basePrice);
      amount = fixedDecimalNumber(specialDiscount);
      return amount + " (" + percent + ")%";
    } else if (toReturn === "amount") {
      return fixedDecimalNumber(specialDiscount);
    }
  } else if (!row && toReturn === "amount") {
    return 0;
  } else {
    return "-";
  }
};

const getAddressInfoFragment = (obj, additionInfo) => {
  const fragmentObj = (
    <Fragment>
   <div className="billing-address first-caps">
            {obj.streetAddress1 || obj.streetAddress2 ? (

                <div>
                    {obj.streetAddress1} {obj.streetAddress2}{", "}
                </div>

            ) : ""}
            {obj.cityName || obj.stateName || obj.zipCode ? (

                <div>
                    {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                </div>

            ) : ""}
             {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
        </div>
      {additionInfo.workPhone ? (
        <div className="billing-address">
          <FormattedMessage id="phone" defaultMessage="" /> -{" "}
          {additionInfo.workPhone}
        </div>
      ) : (
        ""
      )}
      {additionInfo.email ? (
        <div className="billing-address">
          <FormattedMessage id="email" defaultMessage="" /> -{" "}
          {additionInfo.email}
        </div>
      ) : (
        ""
      )}
      {/* {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        } */}
    </Fragment>
  );
  return fragmentObj;
};

const getTaxIdentificationsFragment = (taxIdentifications) => {
  if (!taxIdentifications) return <Fragment />;
  let taxIdentificationsList = [];
  if (taxIdentifications) {
    taxIdentificationsList = JSON.parse(taxIdentifications);
  }
  const fragmentObj = (
    <Fragment>
      {taxIdentificationsList.map((obj, i) => {
        return (
          <div className="billing-address">
            {obj.taxIdentificationType || ""} - {obj.taxIdentificationNumber}
          </div>
        );
      })}
    </Fragment>
  );

  return fragmentObj;
};

// const getContactNameFragment = (additionInfo) => {
//   if (!additionInfo) return <Fragment />;
//   let contactName = "";
//   if (additionInfo.salutationName)
//     contactName += additionInfo.salutationName + " ";
//   if (additionInfo.firstName) contactName += additionInfo.firstName;
//   if (additionInfo.middleName) contactName += " " + additionInfo.middleName;
//   if (additionInfo.lastName) contactName += " " + additionInfo.lastName;
//   const fragmentObj = (
//     <Fragment>
//       {contactName && contactName.length ? (
//         <div className="billing-address">{contactName}</div>
//       ) : (
//         ""
//       )}
//     </Fragment>
//   );
//   return fragmentObj;
// };

const getVariantFragment = (item) => {
  let itemName = null;
  if (item.itemName) {
    itemName = item.itemName;
  }
  if (item.itemVariantName) {
    itemName = itemName + " (" + item.itemVariantName + ")";
  }
  return itemName ? itemName : "";
};
export default PODetails;
