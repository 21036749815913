import axios from "axios";
import config from "../../../../config/environmentConfig";
import { FUNDRAISING_ACTION_LIST } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getTeamMaster = (payload) => {
    return (dispatch) => {
      return axios
        .get(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/teamMaster/getTeamMasterByCampaignId?relationshipId=${payload.relationshipId}&campaignId=${payload.fundraisingCampaignId}`
        )
        .then((res) => {
          dispatch({
            type: FUNDRAISING_ACTION_LIST.TEAM_MASTER_LIST,
            data: res.data,
          });
        })
        .catch((err) => {
          if (!err.__isRetryRequest) {
            showToasterMessage({
              messageType: "error",
              description:
                lang[((err.response || {}).data || "")] ||
                "Some error occurred",
            });
          }
        });
    };
  };




export const deleteTeamMaster = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/teamMaster/delete?teamMasterId=${payload.teamMasterId}`, payload
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description:
            lang[res.message] ||
            "Team deleted successfully",
        });
        props.getTeamMaster(payload);
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const deleteTeamMember = (payload, props) => {
  return dispatch => {
      return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/teamMember/delete?teamMemberId=${payload.teamMasterId}`, payload)
          .then(res => {
              showToasterMessage({ messageType: 'success', description: lang[res.message] || 'Member deleted successfully.' });
              props.getTeamMaster(props.campaignData);
          })
          .catch((err) => {
              showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          })
  }
}