
import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, INVENTORY_ACTION_LIST } from '../../../../static/constants';
import {  showToasterMessage } from '../../../../utils';

 
 
 export const fetchOpeningQuantityList = (payload) => {
    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/openingQuantity/getAllOpeningQuantity?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${(payload.pageNumber||1)-1}`
        )
        .then((res) => {
          dispatch({
            type: INVENTORY_ACTION_LIST.OPENING_QUANTITY,
            data: {
           pageNo: payload.pageNumber,
           list: res.data
            },
          });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          } else {
            
          }
        });
    };
  };


   
 export const fetchOpeningQuantityCount = (payload) => {
    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/openingQuantity/getTotalOpeningQuantity?relationshipId=${payload.relationshipId}`
        )
        .then((res) => {
          dispatch({
            type: INVENTORY_ACTION_LIST.OPENING_PRODUCT_QUANTITY_COUNT,
            data: res.data,
          });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          } else {
            
          }
        });
    };
  };

export const resetData =()=>{
  return (dispatch) => {
    dispatch({ type: INVENTORY_ACTION_LIST.OPENING_QUANTITY,
      data: {
     pageNo: 0,
     list: []
      }});
  }
}

  export const deleteStockQuantity = (payload) => {

  
   
    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/openingQuantity/createOpeningQuantity`, payload)
        .then((res) => {
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            messageType: "error",
            description:
             
              "Some error occurred",
          });
        }).finally(() => {
          dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
        })
    };
  
  };