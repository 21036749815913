import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { fetchCategory } from "../../drawer/inventory/action";
import { fetchProducts } from "../../product/Listing/action";
import { getPageAllListData } from "../pages/action";
import UploadImageModal from "../../../components/eStore/CommonComponents/UploadImageModal";
class ImageUploadComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100
    };
    const payloadValue ={
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 100,
      isPublish:1,
     
    }
    this.props.fetchCategory(payload);
    this.props.fetchProducts(payload);
    this.props.getPageAllListData(payloadValue)
 
  }
 

  render() {
    return (
        <UploadImageModal
          {...this.props}
          {...this.state}
          updateModalState={(data) => {
            this.setState(data);
          }}
        />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    productCategories: state.inventory.productCategories,
    products: state.product.products,
    allPageListData: state.eStore.allPageListData,
    storeValues: state.common.store_values,
    permissions: state.auth.permissions,

  }; 
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  fetchCategory,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchProducts,
  getPageAllListData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadComp);
