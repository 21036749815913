import axios from "axios";
import config from "../../../config/environmentConfig";
import { showToasterMessage } from "../../../utils";
import { COMMON_ACTIONS } from "../../../static/constants";
import { fetchE_StoreData } from "../../../actions/commonActions";
export const updateOrCreateEcomSettings = (payload,props) => {
  return (dispatch) => {
    let formData =  new FormData();
    if (payload?.file?.originFileObj) {
        formData.append('file', payload?.file?.originFileObj ,payload?.file?.originFileObj?.name);
      
    }
    const value = {...payload?.ecomSettingSave ,bucketName:props.companyInfo?.bucketName,relationshipId:props.companyInfo.relationshipId};
    // const stringify = JSON.stringify(value)
    const ecomSetting =  new Blob([JSON.stringify(value)], { 
      type: 'application/json'
    })
    formData.append('ecomSetting',ecomSetting);     

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  
    return axios
      .post(
        `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_setting/save`,formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
      .then((res) => {
        const pay ={
          relationshipId: payload.ecomSettingSave?.relationshipId,
        }
        props.fetchE_StoreData(pay)
        showToasterMessage({
          messageType: "success",
          description: " data save successfully.",
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          messageType: "error",
          description: " Error occured",
        });
      });
  };
};


export const updateHomePageSettings = (payload,props) => {
  return (dispatch) => {
    // const stringify = JSON.stringify(value)
 

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  
    return axios
      .post(
        `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_setting/homePageSetting`,payload
      )
      .then((res) => {
        const pay ={
          relationshipId: payload?.relationshipId,
        }
        props.fetchE_StoreData(pay);
        showToasterMessage({
          messageType: "success",
          description: " data save successfully.",
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          messageType: "fail",
          description: " Error occured",
        });
      });
  };
};


export const updateAnalytics = (payload,props,type) => {
  return (dispatch) => {
    // const stringify = JSON.stringify(value)
 

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  
    return axios
      .post(
        `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_setting/saveAnalytics`,payload
      )
      .then((res) => {
        const pay ={
          relationshipId: payload?.relationshipId,
        }
        props.fetchE_StoreData(pay);
        showToasterMessage({
          messageType: "success",
          description: `${type?type:""} data save successfully.`,
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          messageType: "fail",
          description: " Error occured",
        });
      });
  };
};


export const updatePopUpSetting = (payload,props,type) => {
  return (dispatch) => {
    // const stringify = JSON.stringify(value)
 

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  
    return axios
      .post(
        `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_setting/savePopUpSetting`,payload
      )
      .then((res) => {
        const pay ={
          relationshipId: payload?.relationshipId,
        }
        props.fetchE_StoreData(pay);
        showToasterMessage({
          messageType: "success",
          description: `${type?type:""} data save successfully.`,
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          messageType: "fail",
          description: " Error occured",
        });
      });
  };
};
