import React, { Component, Fragment } from "react";
// import { Link } from 'react-router-dom';
import { Menu ,Empty} from "antd";
import { FormattedMessage } from "react-intl";
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { CONSTANTS, ICONS, MODAL_TYPE } from "../../../../../static/constants";
import {
  PlusOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";
import './index.scss';
import { getUserDefinedAddressName } from "../../../../../utils";
class Address extends Component {
  render() {

    const {permissionsData} = this.props;
    const alladdressList = this.props.companyInfoData.boLocationList || [];
    const addressList = alladdressList.filter((ele) => {
      return (
        ele.locationType === CONSTANTS.RELATIONSHIP_BILLING_ADDRESS ||
        ele.locationType === CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS
      );
    });

    const handleNewAddressAddition = (address) => {
      let payload = {};
      let formData = {};
      payload.formData = {
        isUpdate: true,
        ...address,
        addressType: "relationship",
      };
      const { pushModalToStack, popModalFromStack, companyInfo } = this.props;
      formData.relationshipId = companyInfo.relationshipId;
      payload.formData.callback = (data) => {
        this.props.popModalFromStack();
        this.props.getRelationshipAddress({
          relationshipId: data.relationshipId,
        });
      };

      payload.formData.onCancel = ()=>{
        // this.props.popModalFromStack();
          this.props.pushModalToStack({
              modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
              footer:{submit:{intlId:"button.close.label"}},
              handleSubmit: () => {
                this.props.popModalFromStack();
              }
            })
    }
      const data = {
        // title: <FormattedMessage id='addItem.text.billingAddress' defaultMessage='Billing Address' />,
        title: address.cityName ? (
          "Edit Address"
        ) : (
          <FormattedMessage
            id="addItem.text.billingAddress"
            defaultMessage="Create Address"
          />
        ),

        formData,
        hideFooter: true,
        modalData: {
          modalType: MODAL_TYPE.BILLING_ADDRESS,
          data: payload,
        },
        handleSubmit: (formData = {}) => {
          data.formData.submittedOnce = true;
          popModalFromStack(data);
        },
        onCancel: () => {
          this.props.pushModalToStack({
            modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
            footer:{submit:{intlId:"button.close.label"}},
            handleSubmit: () => {
              this.props.popModalFromStack();
            }
          })
        }
      };
      // this.props.showModal(data);
      pushModalToStack(data);

    };

   
    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Action
          </Menu.Item>

          <Menu.Divider />
          {
            permissionsData.update ?  <Menu.Item
            key="1"
            onClick={() => {
              handleNewAddressAddition(e);
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>: ""
          }
         
           {
            permissionsData.delete ?  <Menu.Item
            key="2"
            onClick={() => {
              const modalData = {
                modalBody: (
                  <FormattedMessage
                    id="customer.address.delete.confirmation"
                    defaultMessage=""
                  />
                ),
                handleSubmit: () => {
                  this.props.deleteLocation(e, {
                    callback: () => {
                      this.props.getRelationshipAddress({
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                      });
                    },
                  });
                },
              };
              this.props.pushModalToStack(modalData);
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>: ""
           }
         
        </Menu>
      );
    };
    return (
      <Fragment>
        <div className="view-top-bar">
          <div className="top-view-left"></div>
          <div className="top-view-right">
            {
              permissionsData.create ?  <button
              className="create-action-btn"
              onClick={() => {
                handleNewAddressAddition({
                  relationshipId: (this.props.companyInfo || {}).relationshipId,
                  locationType: "RelationshipBillingAddress",
                });
              }}
            >
              <PlusOutlined />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>: ""
            }
           
          </div>
        </div>

        <div className="addressTable">
          <table>
            <thead>
              <tr>
                <th style={{width:"5%"}}>
                  <FormattedMessage id="serial.no" defaultMessage="" />
                </th>
                <th style={{width:"5%"}}>
                  <FormattedMessage id="address.primary" defaultMessage="" />
                </th>
                <th style={{width:"5%"}}>
                  <FormattedMessage
                    id="address.addressType"
                    defaultMessage=""
                  />
                </th>
                <th style={{width:"30%"}}>
                  {getUserDefinedAddressName("street1",this.props)}
                </th>
                <th style={{width:"20%"}}>
                  {getUserDefinedAddressName("street2",this.props)}
                </th>
                <th style={{width:"10%"}}>
                  {getUserDefinedAddressName("city",this.props)}
                </th>
                <th style={{width:"30%"}}>
                  {getUserDefinedAddressName("country",this.props)}
                </th>
                <th style={{width:"10%"}}>
                  {getUserDefinedAddressName("state",this.props)}
                </th>
                <th style={{width:"5%"}}>
                  {getUserDefinedAddressName("zip",this.props)}
                </th>
                {
                  (permissionsData.update || permissionsData.delete) ?  
                  <th style={{width:"5%"}}>
                  <FormattedMessage id="Action" defaultMessage="" />
                </th>: ""
                }
                
              </tr>
            </thead>
            <tbody>
              {(addressList || []).length ? 
                addressList.map((rowData, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>

                      <td>{rowData.isDefault ? "Yes" : "No"}</td>
                      <td>
                        <div>
                          {rowData.locationType ===
                          "RelationshipBillingAddress"
                            ? "Billing"
                            : "Shipping"}
                        </div>
                      </td>
                      <td>{rowData.streetAddress1}</td>
                      <td>{rowData.streetAddress2}</td>
                      <td>{rowData.cityName}</td>
                      <td>{rowData.countryName}</td>
                      <td>{rowData.stateName}</td>
                      <td>{rowData.zipCode}</td>
                      {
                        (permissionsData.update || permissionsData.delete)  ? <td className="text-center">
                        <DropdownAction
                          overlay={actionMenu(rowData)}
                          trigger={["click"]}
                          overlayClassName="action-listing-dropdown"
                        >
                          <i className={ICONS.MORE_P} />
                        </DropdownAction>
                        
                      </td>: ""
                      }
                      
                    </tr>
                  );
                })
              : 
              <tr key="empty-data-box">
              <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                  <Empty />
              </td>
          </tr>
              }
            </tbody>
          </table>
        </div>
        {/* <div className="bank-info">
              <div className="ag-theme-balham" style={agGridStyle} >
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={addressList || []}
                            rowDragManaged={true}
                            defaultColDef={{
                                flex:1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            suppressClickEdit={true}
                            noSingleClickEdit={true}
                            disableFocusOnClickOutside={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            //getRowHeight={getRowHeight}
                            editType={'fullRow'}
                            onGridReady={onGridReady}
                        >
                        </CustomAgGrid>
                    </div> */}
                      {/* <Button className="add-new-record" onClick={
                                    () => {
                                        handleNewAddressAddition({ relationshipId: (this.props.companyInfo || {}).relationshipId, locationType: 'RelationshipBillingAddress' })
                                    }
                                }>Add New Adddress </Button> 
          </div>*/}
      </Fragment>
    );
  }
}

export default Address;
