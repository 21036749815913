import React from "react";
import { FormattedMessage } from 'react-intl';
import { Row, Col, Button } from 'antd'
import { Dropdown } from "../../../../general/Dropdown";
import { CONSTANTS } from "../../../../../static/constants";


const AboutCompany = (props) => {
    let { intl ,permissionsData } = props;

    return (
        <>
            <div className="popup-tabs">
                <div>
                    <Row gutter={[24,16]}>
                    
                        <Col span={12}>
                        <span className="i-lable" > <FormattedMessage id='modal.settings.companyInfo.aboutCompany.companySize' defaultMessage='' /></span>
                            <Dropdown
                                items={CONSTANTS.COMPANY_SIZE_LIST}
                                placeholder={intl.formatMessage(
                                    { id: 'modal.settings.companyInfo.aboutCompany.companySize.placeholder', defaultMessage: ''  }
                                )}
                                value= {props.companyInfoData.companySize}
                                onSelect={(val)=>{
                                    props.updateState({
                                        companyInfoData: {
                                        ...props.companyInfoData, companySize: val
                                    }
                                    });
                                }}
                            />
                        </Col>
                        
                        <Col span={12}>
                            <span className="i-lable"> <FormattedMessage id='modal.settings.companyInfo.aboutCompany.industry' defaultMessage='' /></span> 
                            <Dropdown
                                items={props.industries}
                                valueKeyName='industryName'
                                optionKeyName='businessIndustryId'
                                // disabled={props.companyInfoData.industryId}
                                value={(props.companyInfoData || {}).industryId}
                                onSelect={(id)=>{
                                    props.updateState({
                                        companyInfoData: {
                                            ...props.companyInfoData, industryId: id
                                        }
                                    })
                                }}
                                placeholder={intl.formatMessage(
                                    { id: 'modal.settings.companyInfo.aboutCompany.industry.placeholder', defaultMessage: ''  }
                                )}
                            />
                        </Col>

                        <Col span={12}>
                            <span className="i-lable" > <FormattedMessage id='modal.settings.companyInfo.aboutCompany.inventoryItemQty' defaultMessage='' /></span> 
                            <Dropdown
                                items={CONSTANTS.INVENTORY_ITEM_TY_LIST}
                                placeholder={intl.formatMessage(
                                    { id: 'modal.settings.companyInfo.aboutCompany.inventoryItemQty.placeholder', defaultMessage: ''  }
                                )}
                                value= {props.companyInfoData.approxInventoryItems}
                                onSelect={(val)=>{
                                    props.updateState({
                                        companyInfoData: {
                                            ...props.companyInfoData, approxInventoryItems: val
                                        }
                                    });
                                }}
                            />
                        </Col>
                    
                        <Col span={12}>
                            <span className="i-lable"> <FormattedMessage id='modal.settings.companyInfo.aboutCompany.averageMonthlyOrders' defaultMessage='' /> </span> 
                            <Dropdown
                                items={CONSTANTS.AVERAGE_MONTHLY_ORDER_LIST}
                                placeholder={intl.formatMessage(
                                    { id: 'modal.settings.companyInfo.aboutCompany.averageMonthlyOrders.placeholder', defaultMessage: ''  }
                                )}
                                value= {props.companyInfoData.avgMonthlySOorInvoices}
                                onSelect={(val)=>{
                                    props.updateState({
                                        companyInfoData: {
                                            ...props.companyInfoData, avgMonthlySOorInvoices: val
                                        }
                                    });
                                }}
                            />
                        </Col>

                        <Col span={12}>
                            <span><FormattedMessage id='modal.settings.companyInfo.aboutCompany.howDidYouHear' defaultMessage='' /></span> 
                            <Dropdown
                                items={CONSTANTS.SOURCE_OF_HEARING_LIST}
                                placeholder={intl.formatMessage(
                                    { id: 'modal.settings.companyInfo.aboutCompany.howDidYouHear.placeholder', defaultMessage: ''  }
                                )}
                                value= {props.companyInfoData.howDidYouHear}
                                onSelect={(val)=>{
                                    props.updateState({
                                        companyInfoData: {
                                            ...props.companyInfoData, howDidYouHear: val
                                        }
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="popup-btn">
                <Button 
                    type="primary"
                    disabled={!(permissionsData.update)}

                onClick={()=>{
                        props.updateBusinessData(props);
                    }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
                
                </div>
            </div>
        </>
    )
};

export default AboutCompany;