import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { CONSTANTS, TRANSACTION_STATUS } from "../../../../../static/constants";
import SODetail from "../../../../../containers/modal/modalBody/customer/SODetail";
import SalesInvoiceDetails from "../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail";
import InvoiceIcon from "../../../../../assets/images/invoice.jpg";
import { getMomentDateForUIReadOnly } from "../../../../../utils";
import StandardSalesInvoice from "../../../../../containers/customer/invoice/StandardSalesInvoice";

const InvoiceComponents = (props) => {
  const openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId,
    };
    props.pushModalToStack({
      modalBody: <SODetail {...props} soPayload={soPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
      handleSubmit: () => {},
    });
  };
  const openSalesInvoiceDetails = (data) => {
    const invoicePayload = {
      customerId: data.customerId,
      invoiceMasterId: data.invoiceMasterId,
    };
    props.pushModalToStack({
      modalBody: (
        <SalesInvoiceDetails {...props} invoicePayload={invoicePayload} />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const getQuantityInvoiced = (item) => {
    let quantity = 0;
    item.customerInvoiceDetailsList.map((e) => {
      if (e.salesOrderMasterId === props.soData.salesOrderMasterId) {
        return (quantity += e.quantityToInvoice);
      } else {
        return quantity;
      }
    });
    return quantity;
  };
  return (
    <Fragment>
      <div className="modal-table-container">
        <div className="table-heading">
          <FormattedMessage id="salesOrderDetails.text" defaultMessage="" />
        </div>
        <table>
          <thead>
            <tr>
              {/* <th><FormattedMessage id='salesOrderDetails.text' defaultMessage='' /></th> */}
              <th>SO #</th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.listing.header.date"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.form.gridHeader.qtyOrdered"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.listing.header.qtyInvoiced"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.form.gridHeader.qtyToInvoice"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage id="invoiceStatus.text" defaultMessage="" />
              </th>
              <th>
                <FormattedMessage id="invoiceStatus.text" defaultMessage="" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    openSoDetails(props.soDetail);
                  }}
                >
                  {props.soData?.soNumber}
                </div>
              </td>
              <td>
                {(props.soData.salesOrderDate || "") &&
                  getMomentDateForUIReadOnly({
                    date: props.soData.salesOrderDate,
                    format: CONSTANTS.DISPLAY_DATE_FORMAT,
                  })}
              </td>
              <td>{props.soDetail?.totalQtyOrdered}</td>
              <td>{props.soDetail?.totalQtyInvoiced}</td>
              <td>
                {props.soDetail?.totalQtyOrdered - props.soDetail?.totalQtyInvoiced}
              </td>
              <td>
                <div
                  className={[
                    "status-code",
                    props.soData?.status?.toLowerCase(),
                  ].join(" ")}
                >{props.soData.status ?
                  <FormattedMessage id={props.soData.status} defaultMessage=""/>
                :""}</div>
              </td>
              <td>
                {props.soData.statusInvoice !== TRANSACTION_STATUS.FULFILLED ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      props.hideModal();
                      props.pushModalToStack({
                        modalBody: (
                          <StandardSalesInvoice
                            {...props}
                            customerId={props.soData.customerId}
                            soMasterId={props.soData.salesOrderMasterId}
                          />
                        ),
                        width: "100%",
                        hideTitle: true,
                        hideFooter: true,
                        wrapClassName: "new-transaction-wrapper",
                      });
                    }}
                    // to={
                    //     {
                    //         pathname: '/admin/sales-invoice',
                    //         state: {
                    //             customerId: props.soDetail.customerId,
                    //             soMasterId: props.soDetail.salesOrderMasterId
                    //         }
                    //     }}
                  >
                    <FormattedMessage
                      id="createInvoice.text"
                      defaultMessage=""
                    />
                  </div>
                ) : (
                  <div className="status-code success">
                    <FormattedMessage
                      id={props.soData.statusInvoice}
                      defaultMessage=""
                    />
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="table-heading">
          {props.salesInvoiceListBySoId.length ? "Invoice Generated" : ""}
        </div>
        {props.salesInvoiceListBySoId.length ? (
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="serial.no" defaultMessage="" />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.invoiceNo"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.invoiceDate"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.form.gridHeader.qtyInvoiced"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage id="paymentStatus.text" defaultMessage="" />
                </th>
              </tr>
            </thead>
            <tbody>
              {props.salesInvoiceListBySoId.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          openSalesInvoiceDetails(item);
                        }}
                      >
                        {item.invoiceNumber}
                      </div>
                    </td>
                    <td>
                      {(item.invoiceDate || "") &&
                        getMomentDateForUIReadOnly({
                          date: item.invoiceDate,
                          format: CONSTANTS.DISPLAY_DATE_FORMAT,
                        })}
                    </td>
                    <td>{getQuantityInvoiced(item)}</td>
                    <td>
                      <div
                        className={[
                          "status-code",
                          item.status.toLowerCase(),
                        ].join(" ")}
                      >
                        <FormattedMessage id={item.status} defaultMessage="" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="create-data-container">
            <h4 className="progressbar-modal-heading">
              {" "}
              <FormattedMessage
                id="sales.invoice.doesnot.exist"
                defaultMessage=""
              />
              {/* <Link onClick={() => { props.hideModal(); }}
                                to={
                                    {
                                        pathname: '/admin/sales-invoice',
                                        state: {
                                            customerId: props.soData.customerId,
                                            soMasterId: props.soData.salesOrderMasterId
                                        }
                                    }}
                            >
                                <span> </span> <FormattedMessage id='clickhere' defaultMessage='' /> <span> </span>
                            </Link> */}
              <div
                className="cursor-pointer"
                onClick={() => {
                  props.hideModal();
                  props.pushModalToStack({
                    modalBody: (
                      <StandardSalesInvoice
                        {...props}
                        customerId={props.soData.customerId}
                        soMasterId={props.soData.salesOrderMasterId}
                      />
                    ),
                    width: "100%",
                    hideTitle: true,
                    hideFooter: true,
                    wrapClassName: "new-transaction-wrapper",
                  });
                }}
                // to={
                //     {
                //         pathname: '/admin/sales-invoice',
                //         state: {
                //             customerId: props.soData.customerId,
                //             soMasterId: props.soData.salesOrderMasterId
                //         }
                //     }}
              >
                <FormattedMessage id="createInvoice.text" defaultMessage="" />
              </div>
              <FormattedMessage
                id="sales.invoice.doesnot.exist2"
                defaultMessage=""
              />
            </h4>

            <img src={InvoiceIcon} alt="Invoice" height="275" />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default InvoiceComponents;
