import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Tabs } from "antd";
import './index.scss';
import PageHeader from "../../Common/pageHeader";
import CustomerEmails from "./customerEmails";
import SupplierEmails from "./supplierEmails";

const {TabPane} = Tabs;

const EmailCommunications = (props) => {

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.communication' defaultMessage='' />,
    }
  ];

  const handleTabChange = (tabClicked)=>{
    props.updateState({activeTab: tabClicked});
    const payload = {
      relationshipId: props.companyInfo?.relationshipId,
      pageNumber: 1, 
      pageSize: 25,
    };
    switch (tabClicked){
      case "customer_emails":{
        props.getCustomerEmailCommunication(payload);
        break;
      }
      case "supplier_emails":{
        props.getSupplierEmailCommunication(payload);
        break;
      }
    }
  }
  

  return (
    <>
      <PageHeader
        {...props} 
        pageName= "heading.schoolManagement.communication"
        breadCrumbList={breadCrumbList}
      />
      <div className="view-container">
        <Tabs
            animated={{ inkBar: true, tabPane: false }}
            tabBarGutter={16}
            onChange={handleTabChange}
            activeKey={props.activeTab}
        >
          <TabPane tab="Customer Emails" key="customer_emails">
            <CustomerEmails {...props} />
          </TabPane>

          <TabPane tab="Supplier Emails" key="supplier_emails">
            <SupplierEmails {...props} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default injectIntl(EmailCommunications);
