import React from 'react';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Upload, Row, Col } from 'antd';
import { injectIntl } from 'react-intl';
import './index.scss';
//import { MODAL_TYPE } from '../../../../../static/constants';
//import { Dropdown } from '../../../../general/Dropdown';
import config from '../../../../../config/environmentConfig';
//import { getLabelName } from '../../customerInfo';
import { showToasterMessage } from '../../../../../utils';
// import UploadIcon from "../../../../../assets/images/uploads.png";
const { Dragger } = Upload;

function ImportCustomer(props) {

    const { companyInfo } = props;
    const dummyRequest = async ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }
    const uploadProps = {
        name: 'file',
        multiple: false,
        customRequest: dummyRequest,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
                props.updateState({
                    fileToUpload: info.file.originFileObj
                })
            } else if (status === 'error') {
            }
        },
        onDrop(e) {
        },
    };

    return (
        <div className="template-stepbar-modal">
            {/* <Steps current={props.current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps> */}
            <Row>
                <Col span={11}>

                    <div style={{ textAlign: 'center', height:'initial', background:'initial' }} className='rem-bgs'>
                        {/*   <input accept='.xlsx, .xls, .csv' type="file" className="" onChange={(e) => {
                    props.updateState({
                        fileToUpload: e.target.files[0]
                    })
                }} /> {(props.fileToUpload || {}).name} */}

                        <Dragger {...uploadProps}>
                             <p className="ant-upload-drag-icon">
                                <UploadOutlined style={{  fontSize:'34px', color:'#7883b0' }}/>
                            </p>
                            {/* <img src={UploadIcon} alt='Upload'/> */}
                            <p className="ant-upload-text" style={{color:'#363b51'}}> Drag and Drop file </p>

                            <p className="ant-upload-text">or</p>
                            <p className="ant-upload-hint">
                           <div className='brawse-but'>  <Button type="primary" style={{ borderRadius:'0px', padding:'0px 50px', color:'#3157c2', border:'solid 0px #578dc8'}}  >
                                    Browse files
                                </Button>
                                </div>
                            </p>
                        </Dragger>
                    </div>
                   
                   
                    <div style={{ textAlign: 'center', paddingTop:'10px' }}>
                        {/* <div className="action-dropdown-btn download-template-btn"> */}
                        <div >
                            <a href={`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/downloadContactTemplate?relationshipId=${companyInfo.relationshipId}&countryName=${companyInfo.countryName}`}>
                                <DownloadOutlined style={{ color:'#000', fontSize:'20px', float:'left' }} />
                                <span className="ml5" style={{color:'#4e16ff', fontSize:'14px', float:'left'}}>Download Template</span> <span style={{color: 'rgba(0, 0, 0, 0.65)',float:'right'}}>Accepted file type Excel only</span>
                            </a>
                        </div>
                    </div>
                </Col>
                <Col span={1} >
                    <div style={{background:'#e4e4e4', width:'2px', height:'260px', float:'right'}}></div>
                </Col>
                <Col span={1} ></Col>
                <Col span={11}>
                    <div>
                        <div style={{ paddingTop:'10px '}}>
                        <p className="ant-upload-drag-icon" >
                           <b>Steps to Upload Contacts</b>
                        </p></div>
                        <div style={{ paddingTop:'10px '}}>
                        <p className="ant-upload-drag-icon" style={{ paddingTop:'15px'}}>
                           1- Download the template.
                        </p></div>
                        <div style={{ paddingTop:'10px '}}>
                        <p className="ant-upload-drag-icon" style={{ paddingTop:'10px'}}>
                           2- Update template with the contacts.
                        </p></div>
                        <div style={{ paddingTop:'10px '}}>
                        <p className="ant-upload-drag-icon" style={{ paddingTop:'10px'}}>
                           3- Browse or drag & drop excel file to upload.
                        </p></div>
                    </div>
                </Col>
            </Row>

            {/* <div className="steps-content">
                <div className="information-details">

                </div>
                {steps[props.current].content}
            </div> */}

            <div className="steps-action" style={{ borderTop:'solid 1px #e4e4e4', padding:'15px 20px 0px 0px', margin:'0px -25px'}}>
                {/* {props.current < steps.length - 1 && (

                    <Button type="primary" onClick={() => next(props)}>
                        Next
                        </Button>
                )}

                {
                    props.current > 0 && (
                        <Button style={{ marginRight: 8 }} onClick={() => prev()}>
                            Previous
                            </Button>
                    )}
                {props.current === steps.length - 1 && (

                    <Button type="primary" onClick={() => {
                        if (props.fileToUpload) {
                            props.importContact({ relationshipId: companyInfo.relationshipId, fileToUpload: props.fileToUpload }, props);
                        } else {
                            showToasterMessage({
                                messageType: 'error', description: 'Please select a file before importing'
                            });
                        }
                    }}>
                        Done
    </Button>
                )} */}
                <Button type="primary" onClick={() => {
                    if (props.fileToUpload) {
                        props.importContact({ relationshipId: companyInfo.relationshipId, fileToUpload: props.fileToUpload }, props);
                    } else {
                        showToasterMessage({
                            messageType: 'error', description: 'Please select a file before importing'
                        });
                    }
                }}>
                    Done
                </Button>
            </div>
        </div>
    );
}

export default injectIntl(ImportCustomer);
