import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, LANDING_COST_ACTION_LIST, CONSTANTS_TRANSACTIONS} from '../../../../static/constants';
import { showToasterMessage, getFinancialYear, getCurrentDateForBackend, formatDateForBackend } from '../../../../utils';
import * as filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchPurchaseInvoicesForLC = (payload, history) => {
    let FY = getFinancialYear(payload.companyInfo);
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierInvoice/getInvoiceSummary?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
        .then(res => {
          const data = res.data;
          dispatch({ type: LANDING_COST_ACTION_LIST.LC_PURCHASE_INVOICE_LIST, data});
        })
        .catch((err) => {
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          }else{
            err.actionToCall = LANDING_COST_ACTION_LIST.LC_PURCHASE_INVOICE_LIST;
            err.data = [];
          }
        }).finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    }
  }

  export const fetchGRNInvoicesForLC = (payload, history) => {
    let FY = getFinancialYear(payload.companyInfo);
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/inboundDelivery/getAllInboundDeliveries?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
        .then(res => {
          const data = res.data;
          dispatch({ type: LANDING_COST_ACTION_LIST.LC_GRN_INVOICE_LIST, data});
        })
        .catch((err) => {
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          }else{
            err.actionToCall = LANDING_COST_ACTION_LIST.LC_GRN_INVOICE_LIST;
            err.data = [];
          }
        })
    }
  }


  export const fetchLCPurchasedInvoiceDetail = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierInvoice/getSupplierInvoiceData?invoiceMasterId=${payload.invoiceMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: LANDING_COST_ACTION_LIST.PURCHASE_INVOICE_DETAIL, data });
          })
          .catch((err) => {
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
            }else{
              err.actionToCall = LANDING_COST_ACTION_LIST.PURCHASE_INVOICE_DETAIL;
            }
          })
      }
  }

  export const fetchLcInboundryDetail = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/inboundDelivery/getInboundDeliveryData?inboundDeliveryMasterId=${payload.inboundDeliveryMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: LANDING_COST_ACTION_LIST.GRN_INVOICE_DETAIL, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
            }else{
              err.actionToCall = LANDING_COST_ACTION_LIST.GRN_INVOICE_DETAIL;
            }
          })
      }
  }

  export const createLandedCost = (payloadObject) => {
    const props = payloadObject.props;
    const lcData = _getLCData(payloadObject);
    let formData = new FormData();
    if (props.fileList && props.fileList.length) {
      props.fileList.forEach((file) => {
        formData.append('files', file, file.name);
      })
    }
    formData.append('lcData', JSON.stringify(lcData, true));
  
    return dispatch => {
          // TODO need to review and remove
    dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER });
      dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
      return axios
        .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/landedCost/createLandedCost`, formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          //props.openLc(res.data);
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: LANDING_COST_ACTION_LIST.LC_PURCHASE_INVOICE_LIST, data:[] });
          showToasterMessage({ messageType: 'success', description: 'Landed cost created successfully' })
          props.popModalFromStack();
          const payload_data = {
            relationshipId: props.companyInfo.relationshipId,
            userId: (props.userInfo || {}).userId,
            pageNumber: 1,
            pageSize: 25
          };
      
          props.fetchLandedCostList(payload_data);

          props.history.push('/admin/landed-cost');
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: 'Some error occured' })
        }).finally(()=>{
              // TODO need to review and remove
    dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER_STOP });
          dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
        })
    }
  }
  
  const _getLCData = (payload) => {
    const props = payload.props;
    // const state = payload.state;
 
    const companyInfo = props.companyInfo || {};
    const userInfo = props.userInfo || {};
    const financialYear = getFinancialYear(companyInfo);
    const oldPayload = props.update ? props.oldPayload || {} : {};
    let poMaster = {
      ...oldPayload,
      lcMasterId: props.lcMasterId || '', 
      version: props.update ? props.version : 0,
      supplierId: props.supplier && props.supplier.supplierId,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      supplierName: props.supplier && props.supplier.supplierName,
      relationshipName: companyInfo && companyInfo.storeName,
      supplierGstNumber: props.supplier && props.supplier.gstNumber,
      supplierPanNumber: props.supplier && props.supplier.panNumber,
      placeOfSupply: props.placeOfSupply,
      lcDate: props.selectedDate && formatDateForBackend(props.selectedDate),
      lcDueDate: props.expiryDate && formatDateForBackend(props.expiryDate),
      relationshipId: companyInfo.relationshipId,
      createdByUserId: userInfo && userInfo.userId,
      createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
      supplierIDCOATxList: props.supplierIDCOATxList || [],
      landedCostDetailList: [],
      landedCostExpenseDetailList: props.lcSetupList || [],
      conversionPIToLCRef: [],
      landedCostCOATxList: [],
      documentName: props.docName || CONSTANTS_TRANSACTIONS.LANDED_COST,
      documentId: props.documentNameId,
      isPIConversion: props.selectedTxn === 'purchaseInvoice' ? 1 : 0,
      isIDConversion: props.selectedTxn === 'grn' ? 1 : 0,
      poMasterId: props.poMasterId,
      poNumber: props.poNumber,
      exchangeRate: props.exchangeRate,
      foreignCurrency: props.foreignCurrency,
      invoiceMasterId: props.selectedTxn === 'purchaseInvoice' ? props.selectedTxnId : '',
      inboundDeliveryMasterId: props.selectedTxn === 'grn' ? props.selectedTxnId : '',
    };
  
    
  
    if (props.relationshipBillingAddress && props.relationshipBillingAddress.streetAddress1) {
      if (!props.update) {
        delete props.relationshipBillingAddress.locationInboundDeliveryId;
         // props.relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
         // props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
        } else {
         // props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
        }
      poMaster.boLocationInboundDeliveryList.push(props.relationshipBillingAddress);
    }
  
    if (props.relationshipShippingAddress && props.relationshipShippingAddress.streetAddress1) {
      if (!props.update) {
        delete props.relationshipShippingAddress.locationInboundDeliveryId;
       // props.relationshipShippingAddress.dateCreated = getCurrentDateForBackend(new Date());
       // props.relationshipShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
      } else {
       // props.relationshipShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
      }
      poMaster.boLocationInboundDeliveryList.push(props.relationshipShippingAddress);
    }
  
    if (props.supplierBillingAddress && props.supplierBillingAddress.streetAddress1) {
      if (!props.update){
         delete props.supplierBillingAddress.locationInboundDeliveryId;
        // props.supplierBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
         //props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
       } else {
        // props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
       }
      poMaster.boLocationInboundDeliveryList.push(props.supplierBillingAddress);
    }
  
    props.lcItemList.forEach((data, index) => {
      if (!data.itemId && !data.description && !data.qty && !data.rate) {
        return
      }
      // const itemId = data.itemId;
      const variantId = data.inventoryItemVariantId;
      // if(!itemId) return;
      // let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
      // if (data.productObj && !data.productObj.hasVariant && !variantId) {
      //   variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId : data.inventoryItemVariantId;
      // }
     // let product = {};
      let itemLevelCoaTxList = [];
      // if (itemId) {
      //   product = data.productObj || {};
      // }
      // let variant = {};
      // if (variantId) {
      //   variant = data.variant || {};
      // }
      
      poMaster.landedCostDetailList.push({
        version: props.update ? data.version : 0,
        relationshipId: companyInfo && companyInfo.relationshipId,
        supplierId: props.supplier && props.supplier.supplierId,
        quantity: Number(data.quantity),
        qtyFromPO: Number(data.qtyFromPO),
        qtyBeforeUpdate: props.update ? Number(data.qtyBeforeUpdate) : 0,
        sNo: index + 1,
        sku: data.sku,
        uomName: data.uom,
        basePrice: Number(data.baseRate),
        specialDiscount: Number(data.specialDiscount),
        txDate: formatDateForBackend(props.invoiceDate),
        invoiceDate: formatDateForBackend(props.invoiceDate),
        discountPercentage: Number(data.discount),
        itemName: data.itemName,
        itemId: data.itemId,
        anItemPurchasePrice: Number(data.anItemPurchasePrice),
        description: data.description,
        rowHeight: data.scrollHeight,
        hsnCode: data.hsnCode,
        inventoryItemVariantId: variantId,
        supplierIDCOATxList: itemLevelCoaTxList,
        attributeId1: data.attributeId1,
        attributeId2: data.attributeId2,
        attributeId3: data.attributeId3,
        attributeName1: data.attributeName1,
        attributeName2: data.attributeName2,
        attributeName3: data.attributeName3,
        attributeValue1: data.attributeValue1,
        attributeValue2: data.attributeValue2,
        attributeValue3: data.attributeValue3,
        poMasterId: props.poMasterId,
        poNumber: props.poNumber,
        parentDetailsId: data.parentDetailsId,
        inboundDeliveryDetailsId: data.inboundDeliveryDetailsId,

        invoiceMasterId: data.invoiceMasterId,
        invoiceNumber: data.invoiceNumber,
        inboundDeliveryMasterId: data.inboundDeliveryMasterId,
        inboundDeliveryNumber: data.inboundDeliveryNumber,
        landedCost: data.landedCost || 0
      });
    });
  
    let additionalInfo = {};
  
    if (props.selectedContact && props.selectedContact.firstName) {
      additionalInfo = { ...props.selectedContact }
      // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
      //  additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
      //  additionalInfo.relationshipPanNumber = companyInfo.panNumber;
      additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
      additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
      additionalInfo.relationshipWebsite = companyInfo.website;
    }
    if (props.supplier) {
      additionalInfo.supplierId = props.supplier.supplierId;
      //  additionalInfo.gstNumber = props.supplier.gstNumber;
      //  additionalInfo.panNumber = props.supplier.panNumber;
    }
    if (Object.keys(additionalInfo).length) {
      additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
      delete additionalInfo.additionalInfoId;
    }
  
    if (props.selectedSupplierTaxIdentifications && props.selectedSupplierTaxIdentifications.length) {
      let selectedSupplierTaxIdentifications = filter(props.supplier.supplierTaxIdentificationsList, (taxObj) => {
        return props.selectedSupplierTaxIdentifications.indexOf(taxObj.supplierTaxIdentificationId) > -1;
      });
      additionalInfo.taxIdentifications = JSON.stringify(selectedSupplierTaxIdentifications);
    }
    if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
      let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
        return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
      });
      additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
    }
    if (!props.update) delete additionalInfo.additionalInfoId;
    poMaster.deliveryAdditionalInfoList = [additionalInfo];
  
    return poMaster;
  }