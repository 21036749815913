import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { STUDENT_REGISTRATION_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const getStudentRegistraionDetail = (payload, history) => {
  //let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegisteredMember?relationshipId=${payload.relationshipId}&studentRegistrationId=${payload.studentRegistrationId || 0}`)
      .then(res => {
        dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_DETAIL, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_DETAIL;
        }
      })
  }
}


export const resetStudentRegistrationDetail = () => {
  return dispatch => {
    dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.RESET_STUDENT_REGISTRATION_DETAIL });
  }
}
