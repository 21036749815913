import React, { Fragment, useState } from "react";
import { DownloadOutlined,InboxOutlined } from "@ant-design/icons";
import { Steps, Button, Row, Col, Modal, message } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";

import "./index.scss";
import { MODAL_TYPE } from "../../../../../static/constants";
import { Dropdown } from "../../../../general/Dropdown";
import config from "../../../../../config/environmentConfig";
import { getLabelName } from "../../customerInfo";
import { showToasterMessage } from "../../../../../utils";
import * as XLSX from "xlsx";
import Dragger from "antd/lib/upload/Dragger";
import frameImport from "../../../../../assets/images/Frame-import.png"
import successImg from "../../../../../assets/images/Framesuccess.png"
import failImg from "../../../../../assets/images/Groupfail.png"
const { Step } = Steps;

function ImportSupplier(props) {
  const {
    industries,
    businessTypes,
    paymentTerms,
    supplierSources,
    intl,
    companyInfo,
  } = props;
  const [dropdownValues, setDropdownValues] = useState([]);
  // const firstStep = () => {
  //   return (
  //     <div>
  //       <Row className="mb20">
  //         <Col span={8} className="text-right">
  //           <FormattedMessage id="drawer.customer.industry" defaultMessage="" />
  //         </Col>

  //         <Col span={12}>
  //           <Dropdown
  //             items={industries}
  //             valueKeyName="industryName"
  //             optionKeyName="businessIndustryId"
  //             canAddNew={true}
  //             canDelete={true}
  //             deleteClickHandler={(payload) => {
  //               deleteClickHandler(props, MODAL_TYPE.INDUSTRY, payload);
  //             }}
  //             onAddNewClick={(payload) => {
  //               payload.formData = {
  //                 textBoxValue: payload.textEntered,
  //                 submittedOnce: false,
  //               };
  //               handleNewItemAddition(props, payload, MODAL_TYPE.INDUSTRY);
  //             }}
  //             placeholder={intl.formatMessage({
  //               id: "modal.ImportSupplier.industry.placeholder",
  //               defaultMessage: "",
  //             })}
  //           />
  //         </Col>
  //       </Row>

  //       <Row className="mb20">
  //         <Col span={8} className="text-right">
  //           <FormattedMessage
  //             id="drawer.supplier.supplierType"
  //             defaultMessage=""
  //           />
  //         </Col>

  //         <Col span={12}>
  //           <Dropdown
  //             items={businessTypes || []}
  //             valueKeyName="businessType"
  //             optionKeyName="businessTypeId"
  //             canAddNew={true}
  //             canDelete={true}
  //             deleteClickHandler={(payload) => {
  //               deleteClickHandler(props, MODAL_TYPE.CUSTOMER_TYPE, payload);
  //             }}
  //             onAddNewClick={(payload) => {
  //               payload.formData = {
  //                 textBoxValue: payload.textEntered,
  //                 submittedOnce: false,
  //               };
  //               handleNewItemAddition(props, payload, MODAL_TYPE.CUSTOMER_TYPE);
  //             }}
  //             placeholder={intl.formatMessage({
  //               id: "modal.ImportSupplier.supplierType.placeholde",
  //               defaultMessage: "",
  //             })}
  //           />
  //         </Col>
  //       </Row>

  //       <Row className="mb20">
  //         <Col span={8} className="text-right">
  //           <FormattedMessage
  //             id="drawer.supplier.supplierSource"
  //             defaultMessage=""
  //           />
  //         </Col>

  //         <Col span={12}>
  //           <Dropdown
  //             items={supplierSources}
  //             valueKeyName="supplierSourceName"
  //             optionKeyName="supplierSourceId"
  //             placeholder={intl.formatMessage({
  //               id: "modal.ImportSupplier.supplierSources.placeholder",
  //               defaultMessage: "",
  //             })}
  //             canAddNew={true}
  //             canDelete={true}
  //             deleteClickHandler={(payload) => {
  //               deleteClickHandler(props, MODAL_TYPE.SU, payload);
  //             }}
  //             onAddNewClick={(payload) => {
  //               payload.formData = {
  //                 textBoxValue: payload.textEntered,
  //                 submittedOnce: false,
  //               };
  //               handleNewItemAddition(
  //                 props,
  //                 payload,
  //                 MODAL_TYPE.SUPPLIER_SOURCE
  //               );
  //             }}
  //           />
  //         </Col>
  //       </Row>

  //       <Row className="mb20">
  //         <Col span={8} className="text-right">
  //           <FormattedMessage
  //             id="drawer.supplier.paymentTerm"
  //             defaultMessage=""
  //           />
  //         </Col>

  //         <Col span={12}>
  //           <Dropdown
  //             items={paymentTerms}
  //             valueKeyName="termName"
  //             optionKeyName="paymentTermId"
  //             placeholder={intl.formatMessage({
  //               id: "modal.ImportSupplier.paymentTerm.placeholder",
  //               defaultMessage: "",
  //             })}
  //             canAddNew={true}
  //             canDelete={true}
  //             deleteClickHandler={(payload) => {
  //               deleteClickHandler(props, MODAL_TYPE.PAYMENT_TERM, payload);
  //             }}
  //             onAddNewClick={(payload) => {
  //               payload.formData = {
  //                 termName: payload.textEntered,
  //                 days: null,
  //                 description: "",
  //                 isDefaultPayment: false,
  //                 submittedOnce: false,
  //               };
  //               handleNewPaymentTermAddition(
  //                 props,
  //                 payload,
  //                 MODAL_TYPE.PAYMENT_TERM
  //               );
  //             }}
  //           />
  //         </Col>
  //       </Row>

        
  //     </div>
  //   );
  // };

  const secondStep = () => {
    const propss = {
      name: 'file',
      multiple: false,
      action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
      onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          handleFileInputChange(info?.file?.originFileObj)
        }
        if (status === 'done') {
          handleFileInputChange(info?.file?.originFileObj)

          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onDrop(e) {
        handleFileInputChange(e.dataTransfer.files)

      },
    };
    // const handleFileRead = (e) => {
    //   const content = e.target.result;
    //   const workbook = XLSX.read(content, { type: "binary" });

    //   const sheetName = workbook.SheetNames[0];
    //   const sheet = workbook.Sheets[sheetName];

    //   const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    //   props.updateState({
    //     fileHeader: jsonData[0],
    //   });
    //   const ans = jsonData[0]?.map((item) => {
    //     if (props.importSupplierHeader.includes(item)) {
    //       return item;
    //     }
    //   });
    //   setDropdownValues(ans);
    // };

    // const handleFileInputChange = (e) => {
    //   const file = e.target.files[0];
    //   props.updateState({
    //     fileToUpload: file,
    //   });
    //   if (file) {
    //     const reader = new FileReader();
    //     reader.onloadend = handleFileRead;
    //     reader.readAsBinaryString(file);
    //   }
    // };
    const handleFileRead = (e) => {
      const content = e.target.result;
      const workbook = XLSX.read(content, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      props.updateState({
        fileHeader: jsonData[0],
      });
      const ans = jsonData[0]?.map((item) => {
        if (props.headerValues.includes(item)) {
          return item;
        }
      });
      const val = ans.map((item,i)=>{
        if(item){
          return false
        }else{
          return true
        }
      })
      props.updateState({
          headerMandatoryValues:val
      })
      setDropdownValues(ans);
    };
    const handleFileInputChange = (e) => {     
      const file = e;
      props.updateState({
        fileToUpload: file,
      });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = handleFileRead;
        reader.readAsBinaryString(file);
      }
    };
    return (
      <>
      <div className='second-step'>
      <div className='secont-step-heding'>Upload File</div>

      <div className='second-step-content'>

                    <div className='right-item'>
                        <Dragger {...propss}>
                            <p className="ant-upload-drag-icon" style={{ fontSize: "24px" }}>
                                <InboxOutlined style={{ fontSize: "24px" }} />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                banned files.
                            </p>
                        </Dragger>
                     
                    </div>
                    <div className="left-item">
                        <div className='secont-step-heding' >Download Sample File</div>
                        <img src={frameImport} alt="" />
                        <p>Download a sample XLS template to see an example of the format required.</p>
                        <a href={`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/suppliers/downloadSupplierTemplate?relationshipId=${companyInfo.relationshipId}&countryName=${companyInfo.countryName}`}>
                            <i class="fi fi-rr-file-excel"></i>
                            <span className="ml5">Download Template</span>
                        </a>
                    </div>
                </div>
      </div>
      </>
    );
  };

  const thirdStep = () => {
    const handleChange = (index) => (selectedValue) => {
      const newDropdownValues = [...dropdownValues];
      newDropdownValues[index] = selectedValue;
      const val = [...props.headerMandatoryValues]
      val[index] = false
       props.updateState({
          headerMandatoryValues:val
       }) 
      setDropdownValues(newDropdownValues);
    };

    return (
      <>
      {!props.isuploded   ? <div className='third-step'
      
      >
        <div className='third-step-heading'>
                        <div>File Header</div>
                        <div>Mapping value</div>
                        <div>&nbsp;</div>
                    </div>
        <ul className='third-step-content' style={{listStyle:'none'}}>
       
            {props.fileHeader?.map((head, i) => (
              <li key={i}>
                  <div className='content-value' >{head}</div>
                                <div className='content-value'>
                  
                
                  <Dropdown
                    items={props.headerValues || []}
                    canDelete={true}
                    onSelect={(selectedValue) => {
                    handleChange(i)(selectedValue);
                   }}
                    value={dropdownValues[i] || ""}
                    placeholder={"Select Fields"}
                  />
                </div>
                <div className='content-value-error'>
                    {props.headerMandatoryValues[i] && props.submitOnce  ?"Please select the value":""}
               </div>
              </li>
            ))}
     
        </ul>
      </div>:<div>

        <button onClick={()=>{
          // props.updateState({
          //   isuploded:false
          // })
          props.downloadExelFile({errorDataList:props.resData?.ErrorRecords,relationshipId:props.companyInfo.relationshipId},props)
        }}>Download Error File</button>
        </div>}
      </>
    );
  };
  const fourthstep = () => {
    return (
        <>
            {props.isuploded && props.resData.SuccessRecords.length ?
                <div className='error-page'>
                    <div className='part-1'>We've successfully uploaded a specific number of records. For any data with errors, you can download and re-upload them to ensure accuracy.</div>
                    <div className='part-2'>
                        <div className='error-page-img'>
                            <img src={successImg} alt="" />
                        </div>
                        <div className='error-info'>
                            <div className='heading-count'>{props.resData?.SuccessRecords?.length}</div>
                            <p>
                                <b>Great news!</b> Out of the <b>{props.totaData - 1}</b>  entries you submitted, <b>{props.resData?.SuccessRecords?.length}</b> have been successfully uploaded. Congratulations on a job well done!
                            </p>
                        </div>
                    </div>
                   {props.resData.ErrorRecords.length? <div className='part-3'>
                        <div className='error-page-img'>
                            <img className='' src={failImg} alt="" />
                        </div>
                        <div className='error-info'>
                            <div className='heading-count'>{props.resData?.ErrorRecords?.length}</div>
                            <p>
                                Out of <b>{props.totaData - 1}</b>  entries, {props.resData?.ErrorRecords?.length} require your attention. No problem! We've compiled the details of these entries for you to review and correct. Simply download the file, make the necessary adjustments, and re upload.
                            </p>
                        </div>
                        <div className='error-record-btn'>
                            <button onClick={() => {
                                props.downloadExelFile({ errorDataList: props.resData?.ErrorRecords, relationshipId: props.companyInfo.relationshipId }, props)
                            }}>Download Error File</button>
                        </div>
                    </div>:""}

                </div> : <div className='error-page'>

                    <div className='part-3'>
                        <div className='error-page-img'>
                            <img className='' src={failImg} alt="" />
                        </div>
                        <div className='error-info'>
                            <div className='heading-count'>Opps!</div>
                            <p>
                                It seems that there was an issue with the entire dataset. No worries, though! Please download the file provided, make the necessary corrections, and re-upload it to ensure accurate data.
                            </p>
                        </div>
                        <div className='error-record-btn'>
                            <button onClick={() => {
                                props.downloadExelFile({ errorDataList: props.resData?.ErrorRecords, relationshipId: props.companyInfo.relationshipId }, props)
                            }}>Download Error File</button>
                        </div>
                    </div>

                </div>}
        </>
    )
}

const steps = [
  // {
  //   title: "Setup Data for Import",
  //   content: firstStep(),
  // },
  {
      title: "Upload File",
      description:"Upload Your Excel File",
      content: secondStep(),
  },
  {
      title: "Seamless Data Integration",
      description:"Map Your Fields",

      content: thirdStep(),
  },
  {
      title: "Review & Confirm",
      description:"Your Data, Your Way",

      content: fourthstep(),
  },
];

  const next = () => {
    const current = props.current + 1;
    props.updateState({ current });
  };

  const prev = () => {
    const current = props.current - 1;
    props.updateState({ current });
  };

  // const handleNewPaymentTermAddition = (props, payload) => {
  //   const formData = payload.formData;
  //   const { showModal, hideModal } = props;

  //   const data = {
  //     title: (
  //       <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
  //     ),
  //     formData,
  //     modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
  //     handleSubmit: (formData = {}) => {
  //       if (
  //         formData.termName &&
  //         formData.termName.length &&
  //         formData.days !== 0 &&
  //         formData.days
  //       ) {
  //         props.addPaymentTerm(formData);
  //         hideModal();
  //       } else {
  //         data.formData.submittedOnce = true;
  //         showModal(data);
  //       }
  //     },
  //   };
  //   showModal(data);
  // };

  // const handleNewItemAddition = (props, payload, modalType) => {
  //   const formData = payload.formData;
  //   const { showModal } = props;

  //   if (
  //     formData.textBoxValue &&
  //     formData.textBoxValue.length &&
  //     !payload.existsInData
  //   ) {
  //     addItemConfirmationModal(props, formData.textBoxValue, modalType);
  //   } else {
  //     const data = {
  //       title: getModalTitle(payload, modalType),
  //       formData,
  //       modalData: { modalType, data: payload },
  //       handleSubmit: (formData = {}) => {
  //         if (formData.textBoxValue && formData.textBoxValue.length) {
  //           addItemConfirmationModal(props, formData.textBoxValue, modalType);
  //         } else {
  //           data.formData.submittedOnce = true;
  //           showModal(data);
  //         }
  //       },
  //     };
  //     showModal(data);
  //   }
  // };

  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.SUPPLIER_TYPE: {
        addFunc = props.addBusinessType;
        break;
      }
      case MODAL_TYPE.SUPPLIER_SOURCE: {
        addFunc = props.addSupplierSource;
        break;
      }
      case MODAL_TYPE.INDUSTRY: {
        addFunc = props.addIndustry;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: (text) => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
      },
    };
    showModal(modalData);
  };

  const getModalTitle = (newTextObj, modalType) => {
    let title;

    if (newTextObj.existsInData) {
      switch (modalType) {
        case MODAL_TYPE.PAYMENT_TERM: {
          title = (
            <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
          );
          break;
        }
        case MODAL_TYPE.SUPPLIER_TYPE: {
          title = (
            <FormattedMessage
              id="addItem.text.supplierType"
              defaultMessage=""
            />
          );
          break;
        }
        case MODAL_TYPE.SUPPLIER_SOURCE: {
          title = (
            <FormattedMessage
              id="addItem.text.supplierSource"
              defaultMessage=""
            />
          );
          break;
        }
        case MODAL_TYPE.INDUSTRY: {
          title = (
            <FormattedMessage id="addItem.text.industry" defaultMessage="" />
          );
          break;
        }
        default:
          title = <Fragment />;
      }
    } else {
      title = (
        <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
      );
    }
    return title;
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        deleteFunc = props.deletePaymentTerm;
        break;
      }
      case MODAL_TYPE.SUPPLIER_TYPE: {
        deleteFunc = props.deleteBusinessType;
        break;
      }
      case MODAL_TYPE.SUPPLIER_SOURCE: {
        deleteFunc = props.deleteSupplierSource;
        break;
      }
      case MODAL_TYPE.INDUSTRY: {
        deleteFunc = props.deleteIndustry;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      width: "",
      handleSubmit: () => {
        deleteFunc(payload.id);
      },
    };
    props.pushModalToStack(modalData);
  };
  return (
    <div className="template-stepbar-modal">
      <Steps current={props.current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[props.current].content}</div>
      <div className="steps-action">
        {props.current > 0 && (
          <Button style={{ marginRight: 8 }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {props.current < steps.length - 1 && (
          <Button type="primary" onClick={() => {
            if (props.fileToUpload) {
              if (!(props.current === steps.length - 2)) {

                  next(props)
              }

              if (props.current === steps.length - 2) {
                if (!props.headerMandatoryValues.includes(true)) {
                  const resultObject = props.fileHeader?.reduce(
                    (acc, key, index) => {
                     
                      // acc[key] = dropdownValues[index];
                      acc[`${key}`?.trim()] = dropdownValues[index]
                      return acc;
                    },
                    {}
                  );
                  const callBack =()=>{
                  next(props)

                  }
                  const mapedData = {
                    relationshipId: companyInfo.relationshipId,
                    mapping: resultObject,
                  };
  
                  props.importSupplier(
                    {
                      mappedData: JSON.stringify(mapedData || ""),
                      fileToUpload: props.fileToUpload,
                    },
                    props,
                    callBack
                  );
                } else {
                  props.updateState({
                    submitOnce:true
                })
                }
              }

          } else {
              showToasterMessage({
                  messageType: "error",
                  description: "Please select a file",
              });
          }
            }}>
            Next
          </Button>
        )}
        {props.current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => {
            
            }}
          >
            Done
          </Button>
        )}
      </div>
    </div>
  );
}

export default injectIntl(ImportSupplier);
