import React from "react";
import { Button, Col, Row, Skeleton } from 'antd';
import './style.scss';

const LabelDownloadComponent = (props) => {

    const { shipmentLogisticsInfo={}} = props;
    
    return (
        <Skeleton loading={props.saveLoading} paragraph={{ rows: 5 }} active>
            <Row gutter={[24, 24]}>
                <Col span={8} offset={4} className="shipping_info_label">
                    Manifest
                </Col>
                <Col span={4} offset={2}>
                    {shipmentLogisticsInfo.manifestUrl ? 
                        <Button
                            type="primary"
                            onClick={()=>{
                                window.open(props.shippingManifestData?.manifest_url, '_blank').focus();
                            }}
                        >
                            Download Mainfest
                        </Button>
                        :
                        <>
                            <div className="required">Manifest not generated</div>
                            <Button
                                type="primary"
                                onClick={()=>{
                                    const payload = {
                                        relationshipId: shipmentLogisticsInfo.relationshipId,
                                        logisticsInfoId: shipmentLogisticsInfo.logisticsInfoId
                                    }
                                    props.generateManifest(payload, props);
                                    if(props.checkedValues?.length && props.checkedValues.includes("Label")){
                                        props.generateShippingLabel(payload, props);
                                    }
                                }}
                            >
                                Generate Mainfest
                            </Button>
                        </>
                    }
                </Col>

                <Col span={8} offset={4} className="shipping_info_label">
                    Shipping Label
                </Col>
                <Col span={4} offset={2}>
                    {
                        props.shippingLabelData?.labelUrl ? 
                            <Button
                                type="primary"
                                onClick={()=>{
                                    window.open(props.shippingLabelData?.label_url, '_blank').focus();
                                }}
                            >
                                Download Shipping Label
                            </Button>
                            :
                            <>
                                <div className="required">Label not generated</div>
                                <Button
                                    type="primary"
                                    onClick={()=>{
                                        const payload = {
                                            relationshipId: shipmentLogisticsInfo.relationshipId,
                                            logisticsInfoId: shipmentLogisticsInfo.logisticsInfoId
                                        }
                                        props.generateShippingLabel(payload, props);
                                    }}
                                >
                                    Generate Shipping Label
                                </Button>
                            </>
                    }
                </Col>
            </Row>
        </Skeleton>
    );
}

export default LabelDownloadComponent;