import React from "react";

const DownloadCSV = (props) => {
  const { data=[], headers } = props;
  
  const convertToCSV = (data) => {
    const rows = [];
    // Add headers first
    rows.push(headers.join(","));
    const allKeys = new Set(headers);
    // Add each row of data
    data.forEach(item => {
      const row = headers.map(header => {
        const keys = header.split('.');
        let value = item;
        keys.forEach(key => {
          value = value ? value[key] : '';
        });
        return value != null ? `"${value}"` : '';
      });
      rows.push(row.join(","));
    });

    return rows.join("\n");
  };

  const downloadCSVFile = (e) => {
    e.preventDefault(); // Prevent default link behavior
    const csvContent = convertToCSV(data);

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Lead.csv");

    // Trigger the download by clicking the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
     {props.isButton?"Download Error File": <a href="#" onClick={downloadCSVFile}>
        Download CSV
      </a>}
    </div>
  );
};

export default DownloadCSV;
