import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Menu, Skeleton, Empty, Pagination } from "antd";
import { CONSTANTS,ICONS } from "../../../../static/constants";
import { fixedDecimalNumber, fixedDecimalQty, getMomentDateForUIReadOnly, getUserDefinedName, sortTable } from "../../../../utils";
import { EmptyTableData } from "../../../general/EmptyTableData";
import { EmptyBox } from "../../../general/EmptyBox";
// import DropdownAction from "antd/lib/dropdown";


const Product = (props) => {

  const { pageNumber, pageSize, customerPurchaseItems, customerPurchaseItemsCount, updateState } = props;

  const handleSortColumn = (index) => {
        
    const currentSortDirection = props.sortStates[index] || "none";
    const nextSortDirection =
      currentSortDirection === "none" ? "asc" :
      currentSortDirection === "asc" ? "desc" : "none";
  
    // Update sorting state using props.updateState
    props.updateState({
      sortStates: {
        // ...props.sortStates,
        [index]: nextSortDirection,
      },
    });
  
    // Call your sorting function
    const direction = nextSortDirection === "desc" ? 1 : 0;
    sortTable(document.getElementById("taskList-table"), index, direction);
  };
  const getSortIcon = (index) => {
      const sortState = props.sortStates[index];
      if (sortState === "asc") return <i className={ICONS.SORT_UP} />;
      if (sortState === "desc") return <i className={ICONS.SORT_DOWN} />;
      return (
        <i
          className={`${ICONS.SORT} ${
            props.hoveredColumn === index ? "opopacity1" : "opopacity0"
          } `}
        />
      ); 
  };

  return (
    <>
       {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
          {getUserDefinedName('sidebar.menuItem.class',props)}
          </div>
         
        </div>
        <div className="top-view-right">
          <button className="create-action-btn" onClick={()=>{
            newSection();
          }}>
              <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div>  */}
      
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              {getUserDefinedName("Product")}
            </div>
                {customerPurchaseItemsCount ? 
                  <>
                    <div className="vertical-sep" />
                    <div>{customerPurchaseItemsCount}</div>
                  </>
                : 
                ""
                }
          </div> 
          <div className="right-actions">

          </div>
        </div>

        <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>
          <div className="table-container">
            <table id="customer-product-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage=""/>
                  </th>
                  <th>
                    <div
                      onClick={() => handleSortColumn(1)}
                      onMouseEnter={() => props.updateState({ hoveredColumn: 1 })}
                      onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                      className="sortTable-Container justify-start">
                      Product
                      {getSortIcon(1)}
                    </div>
                  </th>
                  {/* <th>
                    <div
                      onClick={() => handleSortColumn(2)}
                      onMouseEnter={() => props.updateState({ hoveredColumn: 2 })}
                      onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                      className="sortTable-Container justify-start">
                      Category
                      {getSortIcon(2)}
                    </div>
                  </th> */}
                  <th>
                    <div
                      onClick={() => handleSortColumn(5)}
                      onMouseEnter={() => props.updateState({ hoveredColumn: 5 })}
                      onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                      className="sortTable-Container justify-end">
                      Purchase Times
                      {getSortIcon(5)}
                    </div>
                  </th>
                  <th>
                    <div
                      onClick={() => handleSortColumn(3)}
                      onMouseEnter={() => props.updateState({ hoveredColumn: 3 })}
                      onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                      className="sortTable-Container justify-end">
                      Avg Sales Value
                      {getSortIcon(3)}
                    </div>
                  </th>
                  <th>
                    <div
                      onClick={() => handleSortColumn(4)}
                      onMouseEnter={() => props.updateState({ hoveredColumn: 4 })}
                      onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                      className="sortTable-Container justify-center">
                      Date
                      {getSortIcon(4)}
                    </div>
                  </th>
                  
                </tr>
              </thead>

              <tbody>
                {
                    (customerPurchaseItems[pageNumber || 1] || []).length ? customerPurchaseItems[pageNumber || 1].map((e, i) => {
                    return <tr key={i}>
                      <td>{(pageNumber-1)*pageSize+(i+1)}</td>

                      <td>{e.productName || <EmptyBox boxSize={"small"} />}</td>
                      {/* <td>{e.categoryName || <EmptyBox boxSize={"small"} />}</td> */}
                      <td  className="amount-TextRight">{fixedDecimalQty(e.totalQuantity) || <EmptyBox boxSize={"small"} />}</td>
                      <td className="amount-TextRight">
                        {" "}
                        {e.avgSalePrice ? (
                          props.companyInfo.currencyIcon ? (
                            <i
                              className={
                                props.companyInfo.currencyIcon
                              }
                            ></i>
                          ) : (
                            props.companyInfo.currencyCode + " "
                          )
                        ) : (
                          ""

                        )}
                        {fixedDecimalNumber(e.avgSalePrice) || <EmptyBox boxSize={"small"} />}
                      </td>
                      <td className="text-center">{ e.lastPurchaseDate ? getMomentDateForUIReadOnly({date: new Date(e.lastPurchaseDate)}) : <EmptyBox boxSize={"small"} />}</td>
                      
                    </tr>
                  })
                  :
                  <tr key="empty"style={{textAlign: 'center'}}>
                    <td colSpan={'100%'}>
                      <EmptyTableData />
                    </td>
                  </tr>
                }
              </tbody>

            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={customerPurchaseItemsCount}
                defaultPageSize={props.pageSize || 25}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  // loadPageData(pageNumber, pageSize);
                  updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  // handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </div>
        </div>
    </>
  );
};

export default injectIntl(Product);
