import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import {
  GST_MECHANISM_OPTIONS,
  MODAL_TYPE,
  ICONS,
} from "../../../static/constants";
//import { UploadOutlined } from '@ant-design/icons';
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Col, Row, Tabs, Checkbox, Tooltip } from "antd";
import "./supplierDrawer.scss";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import { ErrorMsg } from "../../general/ErrorMessage";
import { CustomAgGrid } from "../../general/CustomAgGrid";
import { showToasterMessage, validateEmail } from "../../../utils";
import { getLabelName } from "../../modal/modalBody/customerInfo";

const { TabPane } = Tabs;
//const fileList = [];
// const props2 = {
//     action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
//     listType: 'picture',
//     defaultFileList: [...fileList],
//     className: 'upload-list-inline',
// };

function SupplierTabs(props) {
  let {
    countries,
    updateDrawerState,
    fetchStates,
    allTaxIdentificatinType,
    billingAddress,
    shippingAddressSameAsBilling,
    shippingAddress,
    contact,
    gstMechanism,
    // gstNumber,
    //  panNumber,
    salutations,
    locatedInSez,
    states,
    intl,
    submittedOnce,
    invalidBilligStreet1Class,
    invalidBilligCityClass,
    invalidBilligStateClass,
    invalidShippingStreet1Class,
    invalidShippingCityClass,
    invalidShippingStateClass,
    isUpdate,
    isClone,
    supplierTaxIdentificationsList = [{}],
    gridApi = {},
  } = props;
  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        addFunc = props.addTaxIdentification;
        break;
      }
      case MODAL_TYPE.SALUTATION: {
        addFunc = props.addSalutation;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({
          text: text,
          relationshipId: companyInfo.relationshipId,
          callback: (data) => {
            allTaxIdentificatinType.unshift(data);
          },
        });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        title = (
          <FormattedMessage
            id="addItem.text.taxIdentification"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.SALUTATION: {
        title = (
          <FormattedMessage id="addItem.text.salutation" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        deleteFunc = props.deleteTaxIdentifications;
        break;
      }
      case MODAL_TYPE.SALUTATION: {
        deleteFunc = props.deleteSalutation;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          callback: (data) => {
            let allTaxes = props.allTaxIdentificatinType;
            allTaxes = allTaxes.filter((e) => {
              return e.taxIdentificationId !== data.id;
            });

            allTaxIdentificatinType = allTaxes;
          },
        });
        props.hideModal();
      },
    };
    showModal(modalData);
  };
  return (
    <Tabs type="line" >
      {(!isUpdate) ?
        <TabPane
          style={{ paddingTop: "20px" }}
          tab={intl.formatMessage({
            id: "signup.tab.address",
            defaultMessage: "",
          })}
          key="1"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <strong>
                    <FormattedMessage
                      id="drawer.supplier.billingAddress"
                      defaultMessage=""
                    />
                  </strong>
                </Col>

                <Col span={12}>
                  <Form.Item>
                    <TextBox
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.streetName.placeholder",
                        defaultMessage: "",
                      })}
                      value={billingAddress.streetAddress1}
                      maxLength={45}
                      type="text"
                      onChange={(e) => {
                        updateDrawerState({
                          billingAddress: {
                            ...billingAddress,
                            streetAddress1: e.target.value,
                          },
                          shippingAddress: shippingAddressSameAsBilling
                            ? {
                              ...billingAddress,
                              streetAddress1: e.target.value,
                            }
                            : { ...shippingAddress },
                        });
                      }}
                      className={invalidBilligStreet1Class}
                    />
                    <ErrorMsg
                      validator={() => {
                        return (
                          !(submittedOnce && invalidBilligStreet1Class) ||
                          !!billingAddress.streetAddress1
                        );
                      }}
                      message={intl.formatMessage({
                        id: "drawer.supplier.validation.billingStreet1Name",
                        defaultMessage: "",
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <TextBox
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.streetContd.placeholder",
                        defaultMessage: "",
                      })}
                      value={billingAddress.streetAddress2}
                      maxLength={45}
                      type="text"
                      onChange={(e) => {
                        updateDrawerState({
                          billingAddress: {
                            ...billingAddress,
                            streetAddress2: e.target.value,
                          },
                          shippingAddress: shippingAddressSameAsBilling
                            ? {
                              ...billingAddress,
                              streetAddress2: e.target.value,
                            }
                            : { ...shippingAddress },
                        });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item>
                    <TextBox
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.city.placeholder",
                        defaultMessage: "",
                      })}
                      type="text"
                      maxLength={25}
                      value={billingAddress.cityName}
                      onChange={(e) => {
                        updateDrawerState({
                          billingAddress: {
                            ...billingAddress,
                            cityName: e.target.value,
                          },
                          shippingAddress: shippingAddressSameAsBilling
                            ? { ...billingAddress, cityName: e.target.value }
                            : { ...shippingAddress },
                        });
                      }}
                      className={invalidBilligCityClass}
                    />
                    <ErrorMsg
                      validator={() => {
                        return (
                          !(submittedOnce && invalidBilligCityClass) ||
                          !!billingAddress.cityName
                        );
                      }}
                      message={intl.formatMessage({
                        id: "drawer.supplier.validation.billingCityName",
                        defaultMessage: "",
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Dropdown
                      items={countries}
                      valueKeyName="countryName"
                      optionKeyName="countryId"
                      // value={billingAddress.countryName}
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.country.placeholder",
                        defaultMessage: "",
                      })}
                      onSelect={(optionValue, elem) => {
                        updateDrawerState({
                          billingAddress: {
                            ...billingAddress,
                            stateName: null,
                            countryName: (
                              countries.find(
                                (x) => x.countryId === +elem.key
                              ) || {}
                            ).countryName,
                          },
                          shippingAddress: shippingAddressSameAsBilling
                            ? {
                              ...billingAddress,
                              countryName: (
                                countries.find(
                                  (x) => x.countryId === +elem.key
                                ) || {}
                              ).countryName,
                              stateName: null,
                            }
                            : {
                              ...shippingAddress,
                            },
                        });
                        fetchStates(elem.key);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item>
                    <TextBox
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.state.placeholder",
                        defaultMessage: "",
                      })}
                      type="text"
                      maxLength={45}
                      className={
                        billingAddress.countryName === "India" ||
                          billingAddress.countryName === "United States" ||
                          billingAddress.countryName === "United States (USA)"
                          ? " hide"
                          : ""
                      }
                      value={billingAddress.stateName}
                      onChange={(e) => {
                        updateDrawerState({
                          billingAddress: {
                            ...billingAddress,
                            stateName: e.target.value,
                          },
                          shippingAddress: shippingAddressSameAsBilling
                            ? {
                              ...billingAddress,
                              stateName: e.target.value,
                            }
                            : {
                              ...shippingAddress,
                            },
                        });
                      }}
                    />
                    <Dropdown
                      style={{ width: "100%" }}
                      items={states}
                      valueKeyName="stateName"
                      optionKeyName="stateId"
                      value={billingAddress.stateName}
                      className={
                        invalidBilligStateClass +
                        " " +
                        (billingAddress.countryName !== "India" &&
                          billingAddress.countryName !== "United States" &&
                          billingAddress.countryName !== "United States (USA)"
                          ? " hide"
                          : "")
                      }
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.state.placeholder",
                        defaultMessage: "",
                      })}
                      onSelect={(optionValue, elem) => {
                        updateDrawerState({
                          billingAddress: {
                            ...billingAddress,
                            stateName: elem.name,
                          },
                          shippingAddress: shippingAddressSameAsBilling
                            ? {
                              ...billingAddress,
                              stateName: elem.name,
                            }
                            : {
                              ...shippingAddress,
                            },
                        });
                      }}
                    />
                    <ErrorMsg
                      validator={() => {
                        return (
                          !(
                            submittedOnce &&
                            invalidBilligStateClass &&
                            billingAddress.countryName === "India"
                          ) || !!billingAddress.stateName
                        );
                      }}
                      message={intl.formatMessage({
                        id: "drawer.supplier.validation.billingStateName",
                        defaultMessage: "",
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <TextBox
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.zipCode.placeholder",
                        defaultMessage: "",
                      })}
                      type="text"
                      maxLength={25}
                      value={billingAddress.zipCode}
                      onChange={(e) => {
                        updateDrawerState({
                          billingAddress: {
                            ...billingAddress,
                            zipCode: e.target.value,
                          },
                          shippingAddress: shippingAddressSameAsBilling
                            ? { ...billingAddress, zipCode: e.target.value }
                            : { ...shippingAddress },
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Checkbox
                    // className="login-form-checkbox"
                    checked={shippingAddressSameAsBilling}
                    onClick={(e) => {
                      updateDrawerState({
                        shippingAddressSameAsBilling: e.target.checked ? 1 : 0,
                      });
                      if (e.target.checked) {
                        updateDrawerState({
                          shippingAddress: { ...billingAddress },
                        });
                      } else {
                        updateDrawerState({
                          shippingAddress: {
                            streetAddress1: undefined,
                            streetAddress2: undefined,
                            cityName: undefined,
                            stateName: undefined,
                            zipCode: undefined,
                            locationName: undefined,
                            countryName: undefined,
                          },
                        });
                      }
                    }}
                  >
                    <FormattedMessage
                      id="drawer.supplier.shippingAndBillingAddressSame"
                      defaultMessage=""
                    />
                  </Checkbox>
                </Col>

                <Col span={12}>
                  <Form.Item>
                    <TextBox
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.streetName.placeholder",
                        defaultMessage: "",
                      })}
                      value={shippingAddress.streetAddress1}
                      disabled={shippingAddressSameAsBilling ? true : false}
                      type="text"
                      maxLength={45}
                      onChange={(e) => {
                        updateDrawerState({
                          shippingAddress: {
                            ...shippingAddress,
                            streetAddress1: e.target.value,
                          },
                        });
                      }}
                      className={invalidShippingStreet1Class}
                    />
                    <ErrorMsg
                      validator={() => {
                        return (
                          !(submittedOnce && invalidShippingStreet1Class) ||
                          !!shippingAddress.streetAddress1 ||
                          shippingAddressSameAsBilling
                        );
                      }}
                      message={intl.formatMessage({
                        id: "drawer.supplier.validation.shippingStreet1Name",
                        defaultMessage: "",
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <TextBox
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.streetContd.placeholder",
                        defaultMessage: "",
                      })}
                      value={shippingAddress.streetAddress2}
                      maxLength={45}
                      disabled={shippingAddressSameAsBilling ? true : false}
                      type="text"
                      onChange={(e) => {
                        updateDrawerState({
                          shippingAddress: {
                            ...shippingAddress,
                            streetAddress2: e.target.value,
                          },
                        });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item>
                    <TextBox
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.city.placeholder",
                        defaultMessage: "",
                      })}
                      type="text"
                      maxLength={25}
                      value={shippingAddress.cityName}
                      disabled={shippingAddressSameAsBilling ? true : false}
                      onChange={(e) => {
                        updateDrawerState({
                          shippingAddress: {
                            ...shippingAddress,
                            cityName: e.target.value,
                          },
                        });
                      }}
                      className={invalidShippingCityClass}
                    />
                    <ErrorMsg
                      validator={() => {
                        return (
                          !(submittedOnce && invalidShippingCityClass) ||
                          !!shippingAddress.cityName ||
                          shippingAddressSameAsBilling
                        );
                      }}
                      message={intl.formatMessage({
                        id: "drawer.supplier.validation.shippingCityName",
                        defaultMessage: "",
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Dropdown
                      items={countries}
                      valueKeyName="countryName"
                      value={shippingAddress?.countryName}
                      disabled={shippingAddressSameAsBilling ? true : false}
                      optionKeyName="countryId"
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.country.placeholder",
                        defaultMessage: "",
                      })}
                      onSelect={(optionValue, elem) => {
                        updateDrawerState({
                          shippingAddress: {
                            ...shippingAddress,
                            stateName: null,
                            countryName: (
                              countries.find(
                                (x) => x.countryId === +elem.key
                              ) || {}
                            ).countryName,
                          },
                        });
                        fetchStates(elem.key);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item>
                    <TextBox
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.state.placeholder",
                        defaultMessage: "",
                      })}
                      type="text"
                      maxLength={45}
                      value={shippingAddress.stateName}
                      disabled={shippingAddressSameAsBilling ? true : false}
                      className={
                        shippingAddress.countryName === "India" ||
                          shippingAddress.countryName === "United States" ||
                          shippingAddress.countryName === "United States (USA)"
                          ? " hide"
                          : ""
                      }
                      onChange={(e) => {
                        updateDrawerState({
                          shippingAddress: {
                            ...shippingAddress,
                            stateName: e.target.value,
                          },
                        });
                      }}
                    />
                    <Dropdown
                      style={{ width: "100%" }}
                      items={states}
                      valueKeyName="stateName"
                      optionKeyName="stateId"
                      value={shippingAddress.stateName}
                      disabled={shippingAddressSameAsBilling ? true : false}
                      className={
                        invalidShippingStateClass +
                        " " +
                        (shippingAddress.countryName !== "India" &&
                          shippingAddress.countryName !== "United States" &&
                          shippingAddress.countryName !== "United States (USA)"
                          ? " hide"
                          : "")
                      }
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.state.placeholder",
                        defaultMessage: "",
                      })}
                      onSelect={(optionValue, elem) => {
                        updateDrawerState({
                          shippingAddress: {
                            ...shippingAddress,
                            stateName: elem.name,
                          },
                        });
                      }}
                    />
                    <ErrorMsg
                      validator={() => {
                        return (
                          !(
                            submittedOnce &&
                            invalidShippingStateClass &&
                            shippingAddress.countryName === "India"
                          ) ||
                          !!shippingAddress.stateName ||
                          shippingAddressSameAsBilling
                        );
                      }}
                      message={intl.formatMessage({
                        id: "drawer.supplier.validation.shippingStateName",
                        defaultMessage: "",
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <TextBox
                      placeholder={intl.formatMessage({
                        id: "drawer.supplier.address.zipCode.placeholder",
                        defaultMessage: "",
                      })}
                      type="text"
                      maxLength={25}
                      value={shippingAddress.zipCode}
                      disabled={shippingAddressSameAsBilling ? true : false}
                      onChange={(e) => {
                        updateDrawerState({
                          shippingAddress: {
                            ...shippingAddress,
                            zipCode: e.target.value,
                          },
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="billing-address-information">
            <strong>
              <FormattedMessage
                id="drawer.customer.address.note.text"
                defaultMessage=""
              />
              :
            </strong>
            <ul>
              <li>
                <FormattedMessage
                  id="drawer.customer.address.note.message1"
                  defaultMessage=""
                />
              </li>
              {/* <li><FormattedMessage id='drawer.customer.address.note.message2' defaultMessage='' /></li> */}
            </ul>
          </div>
        </TabPane>
        : ""}
      {(!isUpdate) ?
        <TabPane
          style={{ paddingTop: "20px" }}
          tab={'Contact'}
          key="2"
        >
          <Row  gutter={[16, 16]}>
            <Col span={12}>
              <div className='i-label'>
                First Name
              </div>
              <Row>
                <Col span={8}>
                  <Dropdown
                    items={salutations}
                    valueKeyName="titleName"
                    value={contact.salutationName}
                    canAddNew={true}
                    canDelete={true}
                    optionKeyName="salutationId"
                    deleteClickHandler={(payload) => {
                      deleteClickHandler(props, MODAL_TYPE.SALUTATION, payload);
                    }}
                    onAddNewClick={(payload) => {
                      payload.formData = {
                        textBoxValue: payload.textEntered,
                        submittedOnce: false,
                      };
                      handleNewItemAddition(
                        props,
                        payload,
                        MODAL_TYPE.SALUTATION
                      );
                    }}
                    onSelect={(salutationName, option) => {
                      updateDrawerState({
                        contact: { ...contact, salutationName: option.name },
                      });
                    }}
                    placeholder={intl.formatMessage({
                      id: "drawer.supplier.contact.title.placeholder",
                      defaultMessage: "",
                    })}
                    allowClear={true}
                    onClear={() => {
                      updateDrawerState({
                        contact: { ...contact, salutationName: undefined },
                      });
                    }}
                  />
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder={intl.formatMessage({
                      id: "drawer.supplier.contact.firstName.placeholder",
                      defaultMessage: "",
                    })}
                    type="text"
                    maxLength={25}
                    value={contact.firstName}
                    onChange={(e) => {
                      updateDrawerState({
                        contact: { ...contact, firstName: e.target.value },
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <div className='i-label'>
                  Last Name
              </div>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "drawer.supplier.contact.lastName.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={25}
                  value={contact.lastName}
                  onChange={(e) => {
                    updateDrawerState({
                      contact: { ...contact, lastName: e.target.value },
                    });
                  }}
                />
            </Col>

            <Col span={12}>
                <div className='i-label'>
                  Work Phone
                </div>
                <div style={{ display: "flex" }}>

                <DropdownRef
                    style={{ width: "21%" }}
                    items={
                      (props.countries || []).filter((el) => {
                        return el.countryCallingCode != null;
                      }) || []
                    }
                    optionKeyName="countryId"
                    valueKeyName="countryCallingCode"
                    defaultValue={props.countryCallingCode}
                    className="currency-tags"
                    onSelect={(selectedValue, option) => {
                      const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);

                      if (selectedCountry) {
                        updateDrawerState({
                          countryCallingCode: selectedCountry.countryCallingCode,
                          contact: { ...contact, countryCallingCode: selectedCountry.countryCallingCode },
                        });
                      }

                    }}
                  />
                <TextBox
                  style={{ height: "38px" }}
                  className="textbox-pd"
                  placeholder={intl.formatMessage({
                    id: "common.phone",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={10}
                  value={contact.workPhone}
                  // prefix={
                  //   props.companyInfo.countryCallingCode
                  //     ? "+" + props.companyInfo.countryCallingCode
                  //     : "-"
                  // }
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      updateDrawerState({
                        contact: { ...contact, workPhone: e.target.value },
                      });
                    }
                  }}
                />
                </div>
            </Col>

            <Col span={12}>
              <div className='i-label'>
                  Mobile Number
              </div>
              <div style={{ display: "flex" }}>
                <DropdownRef
                      style={{ width: "21%" }}
                      items={
                        (props.countries || []).filter((el) => {
                          return el.countryCallingCode != null;
                        }) || []
                      }
                      optionKeyName="countryId"
                      valueKeyName="countryCallingCode"
                      defaultValue={props.countryCallingCode}
                      className="currency-tags"
                      onSelect={(selectedValue, option) => {
                        const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);

                        if (selectedCountry) {
                          updateDrawerState({
                            countryCallingCode: selectedCountry.countryCallingCode,
                            contact: { ...contact, countryCallingCode: selectedCountry.countryCallingCode },
                          });
                        }

                      }}
                    />
                <TextBox
                   className="textbox-pd"
                  placeholder={intl.formatMessage({
                    id: "drawer.supplier.contact.mobile.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={10}
                  // prefix={
                  //   props.companyInfo.countryCallingCode
                  //     ? "+" + props.companyInfo.countryCallingCode
                  //     : "-"
                  // }
                  value={contact.cellPhone}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      updateDrawerState({
                        contact: { ...contact, cellPhone: e.target.value },
                      });
                    }
                  }}
                />
              </div>
            </Col>

            <Col span={12}>
            <div className='i-label'>
                                Email
                            </div>
              <TextBox
                placeholder={intl.formatMessage({
                  id: "drawer.customer.contact.email.placeholder",
                  defaultMessage: "",
                })}
                type="text"
                maxLength={254}
                className={props.invalidEmailClass}
                value={contact.emailAddress}
                onChange={(e) => {
                  updateDrawerState({
                    contact: { ...contact, emailAddress: e.target.value },
                    invalidEmailClass:
                      validateEmail(e.target.value) || !e.target.value
                        ? ""
                        : "input-text-error",
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return (
                    !props.submittedOnce ||
                    !!validateEmail(props.contact.emailAddress) ||
                    !props.contact.emailAddress
                  );
                }}
                message={intl.formatMessage({
                  id: "login.email.invalidEmail",
                  defaultMessage: "",
                })}
              />
            </Col>

            <Col span={12}>
            <div className='i-label'>
                                Fax Number
                            </div>
              <TextBox
                placeholder={intl.formatMessage({
                  id: "drawer.supplier.contact.faxNumber.placeholder",
                  defaultMessage: "",
                })}
                type="text"
                maxLength={15}
                value={contact.faxNumber}
                onChange={(e) => {
                  updateDrawerState({
                    contact: { ...contact, faxNumber: e.target.value },
                  });
                }}
              />
            </Col>
          </Row>

          <div className="billing-address-information">
            <strong>
              <FormattedMessage
                id="drawer.customer.address.note.text"
                defaultMessage=""
              />{" "}
              :
            </strong>
            <ul>
              <li>
                <FormattedMessage
                  id="drawer.supplier.contact.note.message1"
                  defaultMessage=""
                />
              </li>
            </ul>
          </div>
        </TabPane>
        :
        ""
      }

      <TabPane
        style={{ paddingTop: "20px" }}
        tab={intl.formatMessage({
          id: "addItem.text.taxIdentification",
          defaultMessage: "",
        })}
        key="3"
      >
        {(props.companyInfo || {}).countryName === "India" ? (
          <div>
            <Row gutter={16} style={{ marginBottom: "10px" }}>
              <Col span={6} style={{ display: "flex" }}>
                <label className="pull-right">
                  <FormattedMessage
                    id="drawer.supplier.taxInfo.gstMechanism"
                    defaultMessage=""
                  />
                </label>
              </Col>
              <Col span={10}>
                <Form.Item>
                  <Dropdown
                    items={GST_MECHANISM_OPTIONS}
                    value={gstMechanism}
                    onSelect={(gstMechanism) => {
                      updateDrawerState({
                        gstMechanism,
                      });
                    }}
                    placeholder={intl.formatMessage({
                      id: "drawer.supplier.taxInfo.gstMechanism.placeholder",
                      defaultMessage: "",
                    })}
                    allowClear={true}
                    onClear={() => {
                      updateDrawerState({ gstMechanism: undefined });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
       

            <Row gutter={16} style={{ marginBottom: "10px" }}>
              <Col span={6} style={{ display: "flex" }}>
                <label className="pull-right">
                  <FormattedMessage
                    id="drawer.supplier.taxInfo.sez"
                    defaultMessage=""
                  />
                </label>
              </Col>
              <Col span={10}>
                <Form.Item>
                  <Checkbox
                    className="login-form-checkbox"
                    checked={locatedInSez}
                    onClick={(e) => {
                      updateDrawerState({
                        locatedInSez: e.target.checked,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}

        <div
          className="table-container row-adder"
          style={{ height: 'unset', overflow: 'visible' }}
        >
          <table>
            <thead>
              <tr>
                <th width="30%">Tax Type</th>
                <th width="30%">Number</th>
                <th width="28%">Populate on Transaction</th>
                <th width="12%">
                  Action{" "}
                  {(supplierTaxIdentificationsList || []).length === 0 ? (
                    <i
                      className={ICONS.ADD}
                      onClick={() => {
                        updateDrawerState({
                          supplierTaxIdentificationsList: [
                            ...(supplierTaxIdentificationsList || []),
                            {},
                          ],
                        });
                      }}
                    />
                  ) : (
                    ""
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {(supplierTaxIdentificationsList || []).length
                ? supplierTaxIdentificationsList.map((rowData, i) => {
                    return (
                      <tr key={i}>
                        <td style={{overflow: 'visible'}}>
                          <Dropdown
                            items={(allTaxIdentificatinType || []).sort(
                              (a, b) => a.type.localeCompare(b.type)
                            )}
                            optionKeyName="taxIdentificationId"
                            valueKeyName="type"
                            placeholder="Select"
                            canAddNew={true}
                            canDelete={true}
                            value={rowData.taxIdentificationType}
                            onChange={(val, option) => {
                              
                              let updatedList = [
                                ...supplierTaxIdentificationsList,
                              ];
                              updatedList[i].taxIdentificationType =
                                option?.name;

                              props.updateDrawerState({
                                supplierTaxIdentificationsList: updatedList,
                              });
                            }}
                            deleteClickHandler={(payload) => {
                              deleteClickHandler(
                                props,
                                MODAL_TYPE.TAX_IDENTIFICATION,
                                payload
                              );
                            }}
                            onAddNewClick={(payload) => {
                              payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false,
                              };

                              handleNewItemAddition(
                                props,
                                payload,
                                MODAL_TYPE.TAX_IDENTIFICATION
                              );
                            }}
                          />
                        </td>

                        <td>
                          <TextBox
                            value={rowData.taxIdentificationNumber}
                            placeholder={"Enter"}
                            maxLength={25}
                            countStyle={{ display: "none" }}
                            onBlur={() => {
                              const callback = (val) => {
                                props.updateDrawerState({
                                  taxIdentificationNumberDublicate: val,
                                });
                              };
                              const payload = {
                                relationshipId:
                                  props.companyInfo.relationshipId,
                                taxIdentificationNumber:
                                  rowData.taxIdentificationNumber, // Ensure to use the current value
                              };
                              props.checktaxIdentificationNumber(
                                payload,
                                props,
                                "supplier",
                                callback
                              );
                            }}
                            onChange={(e) => {
                              let tempList = supplierTaxIdentificationsList;
                              let tempObj = tempList[i];
                              tempObj.taxIdentificationNumber =
                                e.target.value;
                              tempList[i] = tempObj;

                              const num = tempList.filter(
                                (item, index) => index !== i
                              );

                              if (
                                num.find(
                                  (item) =>
                                    item.taxIdentificationNumber ===
                                    e.target.value
                                )
                              ) {
                                showToasterMessage({
                                  messageType: "error",
                                  description: "Duplicate value",
                                });
                                props.updateDrawerState({
                                  duplicateNumber: true,
                                });
                              } else {
                                props.updateDrawerState({
                                  duplicateNumber: false,
                                });
                              }

                              props.updateDrawerState({
                                supplierTaxIdentificationsList: tempList,
                              });
                            }}
                          />
                        </td>

                        <td>
                          <Checkbox
                            className="ml15"
                            checked={rowData.populateOnTransaction == 1}
                            onChange={(event) => {
                              let tempList = supplierTaxIdentificationsList;
                              let tempObj = tempList[i];
                              tempObj.populateOnTransaction = event.target
                                .checked
                                ? 1
                                : 0;
                              tempList[i] = tempObj;
                              props.updateDrawerState({
                                supplierTaxIdentificationsList: tempList,
                              });
                            }}
                          />
                        </td>

                        {/* <td>
                          <button
                            onClick={() => {
                              props.api.updateRowData({
                                remove: [rowData],
                              });

                              let rowData = [];
                              props.api.forEachNode((node) =>
                                rowData.push(node.data)
                              );

                              props.updateDrawerState({
                                supplierTaxIdentificationsList: rowData,
                              });
                            }}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            Delete
                          </button>
                        </td> */}

                        <td>
                          <i
                            className={`${ICONS.DELETE} mr10`}
                            onClick={() => {
                              let tempList = supplierTaxIdentificationsList;
                              tempList.splice(i, 1);
                              updateDrawerState({
                                supplierTaxIdentificationsList: tempList,
                              });
                            }}
                          />
                          {supplierTaxIdentificationsList.length === i + 1 ? (
                            <i
                              className={ICONS.ADD}
                              onClick={() => {
                                updateDrawerState({
                                  supplierTaxIdentificationsList: [
                                    ...(supplierTaxIdentificationsList || []),
                                    {},
                                  ],
                                });
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        </div>
      </TabPane>
    </Tabs>
  );
}

export default injectIntl(SupplierTabs);
