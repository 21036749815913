import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesOrderListingComponent from '../../../../components/customer/salesOrder/Listing';
import SalesOrderFilterComponent from '../../../../components/customer/salesOrder/Listing/salesOrderFilter';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { showModal, pushModalToStack, hideModal, popModalFromStack, fetchUserPreferences, saveUserPrefrences,
   deleteAuditTrail, createAuditTrail } from '../../../../actions/commonActions';
import { fetchSalesOrders, getSalesOrderCount, resetPaginatedData, fetchSalesOrderDrafts, getSalesOrderDraftCount,
   resetDraftPaginatedData, updateQualityCheckList, updateUserStatus, deleteSalesOrder,fetchSalesOrdersByFilter,
    getSalesOrdersCountByFilter,fetchSalesOrdersOnlineOrders ,getSalesOrderOnlineCount, fetchCancelledSalesOrder,
     getCancelledSalesOrderCount, cancelSalesOrder,fetchAmendSalesOrders,cancelSalesOrderPartially} from './action';
import EmailComponent from '../../../../containers/drawer/email';
import * as find from 'lodash.find';
import AuditTimeLine from '../../../../components/drawer/AuditTimeLine';
import QualityCheckDrawer from '../../../../containers/drawer/qualityCheck';
import { fetchProjects, fetchAllEmployees } from '../../../../actions/commonActions';
import { fetchCustomers } from '../../Listing/action';
import { fetchAllStatus } from '../../../modal/modalBody/common/UserStatus/action';

class SalesOrderListing extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      activeTab: props.activeTab|| linkProps.activeTab,
      activeTabKey: props.activeTab === "Draft" ? '2' : '1', 
      txPreferences: { txType: "salesOrder", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['soNumber', 'customerName', 'salesOrderTotalAmount', 'salesOrderDate'],
      txAvailableColumn: {
        soNumber: true,
        customerName: true,
        quotationNumber: true,
        //rfqNumber: true,
        salesOrderTotalAmount: true,
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        status: true,
        referenceNumber: true,
        salesOrderDate: true,
        totalQtyInvoiced: true,
        totalQtyPacked: true,
        totalQtyShipped: true,
        totalQtyOnPR: true,
        deliveryDate: true,
        userStatus: true,
        paymentTermName: true,
        project: true,
        statusPRMessage: true,
        // salesOrderMasterId: true,
        priority: true,
        salesPersonFullName: true,
        soSourceName: true,
        onlineOrderNumber:true,
      },
      selectedAmountTerm: '='
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      userId: (this.props.userInfo || {}).userId,
      pageSize: 25,
      transactionName: 'Sales Order',
    };
    fetchPaginationDataIfNeeded('fetchSalesOrders', 'soList', this.props, payload);
    fetchDataIfNeeded('getSalesOrderCount', 'salesOrderCount', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    if (linkProps.activeTab === "Draft") {
      fetchDataIfNeeded('getSalesOrderDraftCount', 'soDraftCount', this.props, payload);
      fetchPaginationDataIfNeeded('fetchSalesOrderDrafts', 'soDraftList', this.props, payload);
    }
    if (linkProps.activeTab === "Online Order") {
     this.props.fetchSalesOrdersOnlineOrders(payload)
     this.props.getSalesOrderOnlineCount(payload)
    }
    // fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
    this.props.fetchProjects(payload);
    this.props.fetchAllEmployees(payload);
    this.props.fetchCustomers({...payload, isCompact: true});
    this.props.fetchAllStatus(payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "salesOrder", templateName: "standard" });
      if (txPreferences) {
        let txColumns = txPreferences.tablePreference ? (((JSON.parse(txPreferences.tablePreference)) || {}).columnList || []) : [];
        txColumns = txColumns.length ? txColumns: this.state.txMandatoryColumns;
        const txColumnSetting = txPreferences.tablePreference ? (((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {}) : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
        {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.qualityCheckDrawerVisible && <QualityCheckDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.salesOrderFilterDrawerVisible && <SalesOrderFilterComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        <SalesOrderListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    soList: state.so.soList,
    isSoListingLoading: state.so.isSoListingLoading,
    salesOrderCount: state.so.salesOrderCount,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    soDraftList: state.so.soDraftList,
    soDraftCount: state.so.soDraftCount,
    projectList: state.common.projectList,
    salesPersonList: state.common.employees,
    customers: state.customer.customers,
    userStatus: state.common.userStatus,
    soOnlineOrderCount:state.so.soOnlineOrderCount,
    soEcomList:state.so.soEcomList,
    listLoading: state.common.listLoading,
    headerLoading:state.common.headerLoading,
    cancelledSoList: state.so.cancelledSoList,
    cancelledSoCount: state.so.cancelledSoCount,
    aSoList: state.so.aSoList,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSalesOrders,
  getSalesOrderCount,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
  resetPaginatedData,
  fetchUserPreferences,
  saveUserPrefrences,
  deleteSalesOrder,
  fetchSalesOrderDrafts, 
  getSalesOrderDraftCount, 
  resetDraftPaginatedData,
  createAuditTrail,
  deleteAuditTrail,
  updateQualityCheckList,
  updateUserStatus,
  fetchProjects,
  fetchAllEmployees,
  fetchCustomers,
  fetchSalesOrdersByFilter,
  getSalesOrdersCountByFilter,
  fetchAllStatus,
  fetchSalesOrdersOnlineOrders,
  getSalesOrderOnlineCount,
  fetchCancelledSalesOrder,
  getCancelledSalesOrderCount,
  cancelSalesOrder,fetchAmendSalesOrders,cancelSalesOrderPartially
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderListing);
