import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SMS_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const assignGrade = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignmentResponse/updateGrade`, payload
      )
      .then(res => {
        showToasterMessage({ messageType: "success", description: lang[(res.data || "")] || "Saved succesfully" });
        props.history.replace({
          pathname: '/admin/school-management/assignment',
          state: {}
        });

        // if (props.getSchoolAssignment) {
        //   const payload = {
        //     relationshipId: props.companyInfo.relationshipId,
        //     relationshipEmployeeId: props.userInfo.relationshipEmployeeId,
        //     formId: props.defaultSchoolForm,
        //     pageNumber: 1,
        //     pageSize: 200,
        //   };
        //   props.getSchoolAssignment(payload);
        //   props.getSchoolAssignmentCount(payload);
        // }
        // props.popModalFromStack();

        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        if (lang[((err.response || {}).data || "")]) {
          showToasterMessage({ messageType: "error", description: lang[((err.response || {}).data || "")] || "Some error occurred." });
        } else {
          showToasterMessage({ messageType: "error", description: "Some error occurred." });
          props.popModalFromStack();
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const getAssignmentData= (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignment/getById?relationshipId=${payload.relationshipId}&assignmentId=${payload.assignmentId}`)
      .then(res => {
        dispatch({ type: SMS_ACTION_LIST.ASSIGNMENT_DATA, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SMS_ACTION_LIST.ASSIGNMENT_DATA;
        }
      })
  }
}

