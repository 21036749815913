import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import * as find from 'lodash.find';
// import * as filter from 'lodash.filter';
import {
  fetchCustomerDetailsByCustomerId,
  fetchSupplierSummary,
  fetchDocuments,
  addDocumentName,
  pushModalToStack,
  popModalFromStack,
} from "./../../../../actions/commonActions";
import { showToasterMessage } from "../../../../utils";
// import { GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import StandardLandedCostComp from "../../../../components/supplier/landedCost/StandardLandedCost";
import {
  fetchPurchaseInvoicesForLC,
  fetchGRNInvoicesForLC,
  fetchLCPurchasedInvoiceDetail,
  fetchLcInboundryDetail,
  createLandedCost,
} from "./action";
import { CONSTANTS_TRANSACTIONS, TRANSACTION_TYPES } from "../../../../static/constants";
import SettingPurchaseDrawer from "../../../drawer/supplier/settingPurchaseDrawer";


class StandardLandedCost extends Component {
  constructor(props) {
    super(props);
    // const companyInfo = props.companyInfo;
    // const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNameLandCost",
      viewName: "LandedCost",
      docName:"Landed Cost",
      tabDocumentOptions: 
      { txType: CONSTANTS_TRANSACTIONS.LANDED_COST, label: 'Landed Cost', numberExample: 'LC-1', numberInfo: 'LC-SupplierNumber-DDMMYY' },
      settingPurchaseDrawerVisible:false,

      lcItemList: props.isEditLc ? props.lcItemList : [],
      isDetailsUpdated: false,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    // const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 1000,
    };

    this.props.fetchSupplierSummary(payload);
    this.props.fetchDocuments(payload);

    if (this.props.selectedTxn === "purchaseInvoice") {
      this.props.fetchPurchaseInvoicesForLC({
        relationshipId: payload.relationshipId,
      });
    } else if (this.props.selectedTxn === "grn") {
      this.props.fetchGRNInvoicesForLC({
        relationshipId: payload.relationshipId,
      });
    } else {
    }
    // const getListBySupplier = (pList) => {
    //   let tempList = [];
    //   for (let i = 0; i < (pList || []).length; i++) {
    //     if (
    //       Number(pList[i].supplierId) === Number(this.props.selectedSupplierId)
    //     ) {
    //       tempList.push(pList[i]);
    //     }
    //   }
    //   return tempList;
    // };
    // if (this.props.selectedTxnId) {
    //   this.props.updateState({
    //     listoftx: getListBySupplier(this.props.lcPIList),
    //   });
    //   const payload = {
    //     relationshipId: this.props.companyInfo.relationshipId,
    //     invoiceMasterId: this.props.selectedTxnId,
    //     supplierId: this.props.selectedSupplierId,
    //   };
    //   this.props.fetchLCPurchasedInvoiceDetail(payload);
    // }
  }

  componentWillReceiveProps() {
    setTimeout(() => {
      if (
        !this.state.isDetailsUpdated &&
        this.state.selectedTxn === "purchaseInvoice" &&
        this.props.lcPurchaseDetail &&
        Object.keys(this.props.lcPurchaseDetail).length &&
        this.state.selectedTxnId
      ) {
        let itemList = [];
        let pDetailTemp =
          this.props.lcPurchaseDetail.supplierInvoiceDetailsList;
        for (let i = 0; i < pDetailTemp.length; i++) {
          itemList.push({
            parentDetailsId: pDetailTemp[i].invoiceDetailsId,
            invoiceMasterId: pDetailTemp[i].invoiceMasterId,
            itemName: pDetailTemp[i].itemName,
            itemId: pDetailTemp[i].itemId,
            description: pDetailTemp[i].description,
            uom: pDetailTemp[i].uomName,
            anItemPurchasePrice: Number(
              pDetailTemp[i].itemPurchasedPrice.toFixed(2)
            ),
            quantity: pDetailTemp[i].quantity,
            inventoryItemVariantId: pDetailTemp[i].inventoryItemVariantId,
            isVariant: pDetailTemp[i].isVariant,
            attributeId1: pDetailTemp[i].attributeId1,
            attributeId2: pDetailTemp[i].attributeId2,
            attributeId3: pDetailTemp[i].attributeId3,
            attributeName1: pDetailTemp[i].attributeName1,
            attributeName2: pDetailTemp[i].attributeName2,
            attributeName3: pDetailTemp[i].attributeName3,
            attributeValue1: pDetailTemp[i].attributeValue1,
            attributeValue2: pDetailTemp[i].attributeValue2,
            attributeValue3: pDetailTemp[i].attributeValue3,
            invoiceNumber: pDetailTemp[i].invoiceNumber,
            dateCreated: pDetailTemp[i].dateCreated,
            supplierId: pDetailTemp[i].supplierId,
            supplierName: pDetailTemp[i].supplierName,
            sku: pDetailTemp[i].sku,
            exchangeRate: "",
            rate: "",
            landedCost: "",
            actualRate: "",
            variantId: pDetailTemp[i].inventoryItemVariantId,
          });
        }
        this.setState({
          lcItemList: itemList,
          isDetailsUpdated: true,
        });
      } else if (
        !this.state.isDetailsUpdated &&
        this.state.selectedTxn === "grn" &&
        this.props.lcGRNDetail &&
        Object.keys(this.props.lcGRNDetail).length &&
        this.state.selectedTxnId
      ) {
        let itemList = [];
        let grnDetailTemp = this.props.lcGRNDetail.inboundDeliveryDetailsList;
        for (let i = 0; i < grnDetailTemp.length; i++) {
          itemList.push({
            parentId: grnDetailTemp[i].inboundDeliveryMasterId,
            parentDetailsId: grnDetailTemp[i].invoiceDetailsId,
            itemName: grnDetailTemp[i].itemName,
            itemId: grnDetailTemp[i].itemId,
            description: grnDetailTemp[i].description,
            uom: grnDetailTemp[i].uomName,
            anItemPurchasePrice: grnDetailTemp[i].anItemPurchasePrice,
            quantity: grnDetailTemp[i].qtyReceived,
            inventoryItemVariantId: grnDetailTemp[i].inventoryItemVariantId,
            isVariant: grnDetailTemp[i].isVariant,
            attributeId1: grnDetailTemp[i].attributeId1,
            attributeId2: grnDetailTemp[i].attributeId2,
            attributeId3: grnDetailTemp[i].attributeId3,
            attributeName1: grnDetailTemp[i].attributeName1,
            attributeName2: grnDetailTemp[i].attributeName2,
            attributeName3: grnDetailTemp[i].attributeName3,
            attributeValue1: grnDetailTemp[i].attributeValue1,
            attributeValue2: grnDetailTemp[i].attributeValue2,
            attributeValue3: grnDetailTemp[i].attributeValue3,
            invoiceNumber: grnDetailTemp[i].invoiceNumber,
            inboundDeliveryNumber: grnDetailTemp[i].inboundDeliveryNumber,
            inboundDeliveryMasterId: grnDetailTemp[i].inboundDeliveryMasterId,
            supplierId: grnDetailTemp[i].supplierId,
            supplierName: grnDetailTemp[i].supplierName,
            sku: grnDetailTemp[i].sku,
            exchangeRate: "",
            rate: "",
            landedCost: "",
            actualRate: "",
            variantId: grnDetailTemp[i].inventoryItemVariantId,
            version: grnDetailTemp[i].version,
          });
          this.setState({
            lcItemList: itemList,
            isDetailsUpdated: true,
          });
        }
      }
    }, 500);
  }

  render() {
    return (
      <div className="new-invoice-modal">
        {this.state.settingPurchaseDrawerVisible && 
          <SettingPurchaseDrawer
            {...this.state}
            {...this.props}
            docName={TRANSACTION_TYPES.LANDED_COST}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
            />
        }
        <StandardLandedCostComp
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          openSalesInvoiceDetails={this.openSalesInvoiceDetails}
          updateState={(data) => {
            this.setState(data);
          }}
          updateDrawerState={(data) => { this.setState(data) }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.customer.customers,
    companyInfo: state.common.companyInfo,
    suppliersList: state.supplier.supplierSummary,
    lcPIList: state.landingCost.lcPurchaseInvoiceList,
    lcGRNList: state.landingCost.lcGRNInvoiceList,
    lcPurchaseDetail: state.landingCost.lcPurchaseDetail,
    lcGRNDetail: state.landingCost.lcGRNDetail,
    documentList: state.common.documentList,
    listLoading:state.common.listLoading,
    saveLoading:state.common.saveLoading


  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCustomerDetailsByCustomerId,
      fetchSupplierSummary,
      fetchPurchaseInvoicesForLC,
      fetchGRNInvoicesForLC,
      fetchLCPurchasedInvoiceDetail,
      fetchLcInboundryDetail,
      createLandedCost,
      fetchDocuments,
      addDocumentName,
      showToasterMessage,
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StandardLandedCost);
