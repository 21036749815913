import React from 'react';
// import { AgGridReact } from 'ag-grid-react';
import { Pagination, Skeleton } from 'antd';
import {  FormattedMessage, injectIntl } from 'react-intl';

import { CONSTANTS, ICONS } from '../../../../../static/constants';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { fetchPaginationDataIfNeeded, fixedDecimalNumber, getMomentDateForUIReadOnly, sortTable } from '../../../../../utils';
import SalesInvoiceDetail from '../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';


const PaidInvoicesComponent = (props) => {
    const { paidInvoiceList, paidInvoiceListCount, companyInfo, updateState, customerData, pageNumber } = props;
    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const getRowHeight = () =>{
    //     return 28;
    // }

    // const onFirstDataRendered =(params)=>{
    //     params.api.sizeColumnsToFit();
    // }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        if(customerData && customerData.customerId){
            payload.customerId = customerData.customerId;
        }
        props.resetPaginatedData();
        props.fetchPaidInvoices(payload,props);
       props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }


    const showTotal = (total) => {
        return props.intl.formatMessage({id: 'pagination.text.total', defaultMessage: ''}) + ` ${ total } `+ props.intl.formatMessage({id: 'pagination.text.items', defaultMessage: ''});
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = {companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        if(customerData && customerData.customerId){
            payload.customerId = customerData.customerId;
        }
        props.fetchPaidInvoices(payload,props);
    }

    const openInvoiceDetails = (data) => {
        const invoicePayload = {
            customerId: data.customerId,
            invoiceMasterId: data.invoiceMasterId
        }
        props.pushModalToStack({
            modalBody: <SalesInvoiceDetail {...props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const handleSortColumn = (index) => {
        const currentSortDirection = props.sortStates[index] || "none";
        const nextSortDirection =
          currentSortDirection === "none" ? "asc" :
          currentSortDirection === "asc" ? "desc" : "none";
      
        props.updateState({
          sortStates: {
            [index]: nextSortDirection,
          },
        });
      
        const direction = nextSortDirection === "desc" ? 1 : 0;
        sortTable(document.getElementById("paid-Invoice-Table"), index, direction);
    };
    const getSortIcon = (index) => {
        const sortState = props.sortStates[index];
        if (sortState === "asc") return <i className={ICONS.SORT_UP} />;
        if (sortState === "desc") return <i className={ICONS.SORT_DOWN} />;
        return (
            <i
              className={`${ICONS.SORT} ${
                props.hoveredColumn === index ? "opopacity1" : "opopacity0"
              } `}
            />
          );
    };

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.paidInvoice.header.invoiceNumber' defaultMessage='' />;
    //         },
    //         field: "invoiceNumber",
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openInvoiceDetails(link.data)
    //         }}>{link.value}</div>,
    //         resizable: true,
    //     },
       
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.listing.paidInvoice.header.amount' defaultMessage='' /> </div>;
    //         },
    //         field: "invoiceTotalAmount",
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span>;
    //         },
    //         valueFormatter: (data) =>{
    //             data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): '-';
    //             return data.value;
    //         },
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.listing.paidInvoice.header.paymentAmount' defaultMessage='' /> </div>;
    //         },
    //         field: "paymentAmount",
    //         cellRendererFramework: (params)=>{
    //             let totalPayment = 0;
    //             if(params.data && params.data.customerPaymentList.length){
    //                 params.data.customerPaymentList.map((e)=>{
    //                      totalPayment+= e.paymentAmount;
    //                      return fixedDecimalNumber(totalPayment);
    //                 })

    //             }
    //             return <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(fixedDecimalNumber(totalPayment)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>;
    //         },
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.paidInvoice.header.invoiceDate' defaultMessage='' />;
    //         },
    //         field: "invoiceDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}>
    //                 {(link.value ? getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}) : '-')}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "status",
    //         cellRendererFramework: (params) =>{
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /></span> </div>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell'
    //     },
    // ];

    return (
        // <div className='agGridWrapper'>
        //             <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
        //                 <AgGridReact
        //                     columnDefs={columnDefs}
        //                     rowData={ paidInvoiceList[pageNumber] || []}
        //                     rowDragManaged={true}
        //                     domLayout={"autoHeight"}
        //                     animateRows={true}
        //                     onColumnResized ={(params)=>{
        //                         params.api.resetRowHeights();
        //                     }}
        //                     defaultColDef={{
        //                         flex:1,
        //                         autoHeight: true,
        //                         wrapText: true,
        //                     }}
        //                     //getRowHeight={getRowHeight}
        //                     onGridSizeChanged={onFirstDataRendered}
        //                 >
        //                 </AgGridReact>
        //             </div>


        //         </div>

        <>
        
                <Skeleton loading={props.modalLoading}   paragraph={{ rows: 8 }} active>
                    <div className="table-container">
                        <table id='paid-Invoice-Table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(1)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 1 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-start">
                                            <FormattedMessage id='customer.listing.unPaidInvoice.header.invoiceNumber' defaultMessage='' /> {getSortIcon(1)}
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(2)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 2 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-start">
                                            <FormattedMessage id="customer.listing.unPaidInvoice.header.invoiceDate" defaultMessage="" /> {getSortIcon(2)}
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(3)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 3 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-end">
                                            <FormattedMessage id="customer.listing.unPaidInvoice.header.amount" defaultMessage="" /> {getSortIcon(3)}
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(4)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 4 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-end">
                                            <FormattedMessage id='customer.listing.unPaidInvoice.header.paymentAmount' defaultMessage='' /> {getSortIcon(4)}
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(5)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 5 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-center">
                                            <FormattedMessage id='common.status' defaultMessage='' /> {getSortIcon(5)}
                                        </div>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {(paidInvoiceList[pageNumber] || []).length ? (paidInvoiceList[pageNumber] || []).map((rowData, i) => {
                                    return <tr key={i}>
                                                <td>
                                                    <div className="cursor-pointer" onClick={() => {
                                                    openInvoiceDetails(rowData)
                                                }}>{rowData.invoiceNumber}</div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {(rowData.invoiceDate && getMomentDateForUIReadOnly({ date: rowData.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='amount-TextRight'>
                                                        {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(rowData.invoiceTotalAmount)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='amount-TextRight'>
                                                        {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(fixedDecimalNumber(rowData.totalPaymentReceived)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                                                    </div>
                                                </td>
                                            
                                                <td>
                                                    <div>
                                                        {rowData.status ? 
                                                            <div className={`status-code ${rowData.statusColorForUICell}`}>
                                                            <FormattedMessage id={rowData.status || ''} defaultMessage="" />
                                                            </div> 
                                                        : 
                                                        <div class="empty-data"></div>}
                                                    </div>
                                                </td>
                                    </tr>
                                }) 
                                : 
                                'No data'
                                }

                            </tbody>
                        </table>
                    </div>
         

            {/* <div className="footer-tile-popup"> */}
                   </Skeleton>
        {!props.customerId && !props.customerData?.customerId? (

            <Pagination
                size="small"
                total={paidInvoiceListCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}  showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
            />
            ) : (
                null 
              )}
{/* </div> */}


        </>
    );
};

export default injectIntl(PaidInvoicesComponent);
