import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
import { SUPPLIER_ACTION_LIST } from '../../../static/constants';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchLandedCostSetupList = (payload, history) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/lcExpenseType/getAllExpenseTypes?relationshipId=${payload.relationshipId}`)
        .then(res => {
          const data = {
            pageNo: payload.pageNumber,
            list: res.data
          }
          dispatch({ type: SUPPLIER_ACTION_LIST.LANDED_COST_SETUP_LIST, data});
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          }else{
            err.actionToCall = SUPPLIER_ACTION_LIST.LANDED_COST_SETUP_LIST;
            err.data = {
              pageNo: payload.pageNumber,
              list: []
            };
          }
        }).finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    }
  }
  export const deleteLandedCostSetup = (payload, props) => {
    return dispatch => {
      return axios
        .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/lcExpenseType/deleteExpenseType`, payload)
        .then(res => {
          showToasterMessage({ messageType: 'success', description: 'Landed Cost deleted successfully' });
          props.fetchLandedCostSetupList({  relationshipId: payload.relationshipId });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          } else {
            // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          }
        })
    }
  }