import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddressDrawer from "../../../components/drawer/Address";
import {
  fetchCountries,
  fetchStates,
  resetStates,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
} from "../../../actions/commonActions";
import { createAddress } from "./action";
import { fetchSupplierAddresses } from "../../supplier/detail/action";

class NewAddressContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

    this.props.fetchCountries();
    if(this.props.formData?.countryId){
      this.props.fetchStates(this.props.formData?.countryId);
    } else {
      this.props.resetStates();
    }
  }
  componentWillReceiveProps(props){
    if(!props.formData?.countryName && props.countries?.length){
      let obj = props.countries.find(country => (country.countryName === (this.props.companyInfo).countryName)) || {};
      if ((obj.countryName === 'United States' || obj.countryName === 'United States (USA)' || obj.countryName === 'India') && !props.states.length) {
        this.props.fetchStates(obj.countryId);
       // fetchDataIfNeeded('fetchStates', 'states', this.props, obj.countryId);
      }
      this.setState({ countryName: obj.countryName || '', countryId: obj.countryId});
    }

  }

  render() {
    return (
      <AddressDrawer
        updateState={(data) => {
          this.setState(data);
        }}
        {...this.props}
        {...this.state}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    countries: state.common.countries,
    states: state.common.states,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAddress,
      fetchCountries,
      fetchStates,
      pushModalToStack,
      popModalFromStack,
      showModal,
      hideModal,
      resetStates,
      fetchSupplierAddresses
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewAddressContainer);
