import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Empty, Pagination, Skeleton } from "antd";
import { getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import { CONSTANTS, ICONS } from '../../../static/constants';
import './index.scss';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const CustomerEmails = (props) => {
  const { updateState, companyInfo, customerInfo, pageSize, dir=0, customerEmailCommunications } = props;

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
    };
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.getCustomerEmailCommunication(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
    };

    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.getCustomerEmailCommunication(payload);
  };
  
  const sortColumn = (e) => {
    sortTable(document.getElementById('communication-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }
  

  return (
    <>
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='schoolManagement.communication.table.heading' defaultMessage='' />
            </div>
            {
              pageSize ? <>
                <div className="vertical-sep" />
                <div>{customerEmailCommunications.totalElements}</div>
              </>:0
            }
          </div>
          <div className="right-actions">
          </div>
        </div>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 17 }} active>
          <div className="table-container with-tabs">
            <table id='communication-table'>
              <thead>
                <tr>
                  <th style={{width: '100px'}}>Type</th>
                  <th onClick={sortColumn}>Communicated From <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Communicated To <i className={ICONS.SORT} /></th>
                  <th style={{width:'50%'}}>Email Subject <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Communicated By <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Date <i className={ICONS.SORT} /></th>
                </tr>
              </thead>
              <tbody>
                {(customerEmailCommunications.content || []).length ? customerEmailCommunications.content.map((e, i) => {
                  return <tr key={'std' + i}>
                    <td>
                      <span className="table-link-text" onClick={() => {
                          props.updateState({ communicationReviewDrawerVisible: true, communicationDetail: e });
                        }}>{e.communicationMode || '-'}
                      </span>
                  </td>
                    <td>{e.communicatedFrom || '-'} </td>
                    <td>{e.communiatedTo || '-'} </td>
                    <td>{e.emailSubject || '-'}</td>
                    <td>{ e.relationshipEmployeeName === 'System Generated'? 'System':  e.relationshipEmployeeName || '-'}</td>
                    <td className="one-line-view">{e.communicationDate ? getMomentDateForUIReadOnly({ date: new Date(e.communicationDate), format }) : '-'}</td>
                  </tr>
                })
                :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              current={props.pageNumber || 1}
              total={customerEmailCommunications.totalElements}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(CustomerEmails);
