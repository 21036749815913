import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../static/constants'
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils';
import { TX_TYPE, TX_STATUS, PURCHASE_REQUEST_ACTION_LIST, INQUIRY_ACTION_LIST, SO_ACTION_LIST } from "../../../../static/constants";
import { resetPaginatedData, getPurchaseRequestsCount, resetDraftPaginatedData, getPurchaseRequestDraftCount } from '../Listing/action';
import filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');
export const createPurchaseRequest = (payloadObject) => {
  const props = payloadObject.props;
  const prData = _getPRData(payloadObject);
  let formData = new FormData();
  if (props.fileList && props.fileList.length) {
    props.fileList.forEach((file) => {
      formData.append('files', file, file.name);
    })
  }
  formData.append('prData', JSON.stringify(prData, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
        // TODO need to review and remove
        dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/createPurchaseRequest`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        resetPaginatedData()(dispatch);
        getPurchaseRequestsCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        resetDraftPaginatedData()(dispatch);
        props.popModalFromStack();
        getPurchaseRequestDraftCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        showToasterMessage({ messageType: 'success', description: 'Purchase request created successfully' })
        props.history.push('/admin/purchase-request-list');
        if (props.isInquiryConversion) {
          dispatch({ type: INQUIRY_ACTION_LIST.ENQUIRY_LIST_PAGE_RESET });
          props.history.push('/admin/sales-inquiry-list')
        }
        if (props.isSOConversion) {
          dispatch({ type: SO_ACTION_LIST.SO_LIST_PAGE_RESET });
          props.history.push('/admin/so-list');
        }
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          inquiryMasterId: props.inquiryMasterId,
          salesOrderMasterId: props.salesOrderMasterId || props.salesOrderMasterId,
          customerId: props.customerId || props.customerId,
          userId: (props.userInfo || {}).userId,
          prMasterId: props.prMasterId,
          pageNumber: 1,
          pageSize: 100
        };
        props.fetchPurchaseRequests(payload)
        props.openPRDetails(res.data);
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      }).finally(()=>{
            // TODO need to review and remove
    dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER_STOP });
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

const _getPRData = (payload) => {
  const props = payload.props;
  const state = payload.state;
  const gridObject = payload.gridObject;
  let prRequestedBy = props.userInfo.firstName || "";
  if (props.userInfo.middleName)
    prRequestedBy += " " + props.userInfo.middleName;
  if (props.userInfo.lastName)
    prRequestedBy += " " + props.userInfo.lastName;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const financialYear = getFinancialYear(companyInfo);
  const oldPayload = props.update ? props.oldPayload || {} : {};
  let prMaster = {
    ...oldPayload,
    version: props.update ? props.version : 0,
    purchaseRequestDraftId: props.purchaseRequestDraftId || 0,
    customerPONumber: props.customerPONumber,
    customerInquiryNumber: props.customerInquiryNumber,
    customerRFQNumber: props.customerRFQNumber,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    relationshipName: companyInfo && companyInfo.storeName,
    status: TX_STATUS.OPEN,
    supplierId: props.stateSupplierId,
    supplierName:props.supplierObj?.supplierStoreName,
    supplierEmail: props?.supplierObj && props?.supplierObj.supplierEmail,
    supplierPhone: props?.supplierObj && props?.supplierObj.supplierPhone,
    reason: props.reason,
    reference: props.reference,
    remarksEmployee: props.remarksEmployee,
    docDetailList: props.prEditData?.docDetailList,
    departmentName: props.department,
    prRequestedBy: prRequestedBy,
    prOwnerName: props.owner,
    boLocationPRList: [],
    paymentTermId: props.supplierPaymentTerm && props.supplierPaymentTerm.paymentTermId,
    paymentTermName: props.supplierPaymentTerm && props.supplierPaymentTerm.paymentTermName,
    paymentTermDays: props.paymentTermDays,
    emailAddress: userInfo.email,
    orderPriority: props.priority,
    emailIfRFQOrPOIsGenerated: props.emailIfRFQOrPOIsGenerated ? 1 : 0,
    purchaseRequestDate: props.purchaseRequestDate ? formatDateForBackend(props.purchaseRequestDate): formatDateForBackend(new Date()),
    prExpirationDate: props.prExpirationDate ? formatDateForBackend(props.prExpirationDate) : '',
    relationshipId: companyInfo.relationshipId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    footer: props.footer,
    txType: TX_TYPE.PR,
    purchaseRequestDetailsList: [],
    documentName: props.docName,
    isInquiryConversion: (props.isInquiryConversion && !props.isClone) ? 1 : 0,
    isSOConversion: (props.isSOConversion && !props.isClone) ? 1 : 0,
    purchaseRequestMasterId: props.update ? (props.purchaseRequestMasterId || 0) : 0,
    purchaseRequestNumber: props.update ? props.purchaseRequestNumber : null,
    projectName: props.projectName,
    projectMasterId: props.projectMasterId,
    projectNumber: props.projectNumber,
    totalPOAmount: state.total,
    customerId: props.customerId,
    customerName: props.customerName,
  };
  let additionalInfo = {};
  additionalInfo.supplierId = props.stateSupplierId;
  additionalInfo.supplierName =props.supplierObj?.supplierStoreName;
  if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
    let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
      return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
    });
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
  }
  if (!props.update) delete additionalInfo.additionalInfoId;
  prMaster.additionalInfoList = [additionalInfo];
  if (props.relationshipBillingAddress && props.relationshipBillingAddress.streetAddress1) {
    if (!props.update) {
      delete props.relationshipBillingAddress.locationPoId;
      delete props.relationshipBillingAddress.version;
      //props.supplierBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
      //props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      //props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    prMaster.boLocationPRList.push(props.relationshipBillingAddress);
  }
  if (props.selectedSupplierTaxIdentifications && props.selectedSupplierTaxIdentifications.length) {
    let selectedSupplierTaxIdentifications = filter(props.supplier.supplierTaxIdentificationsList, (taxObj) => {
      return props.selectedSupplierTaxIdentifications.indexOf(taxObj.supplierTaxIdentificationId) > -1;
    });
    additionalInfo.taxIdentifications = JSON.stringify(selectedSupplierTaxIdentifications);
  }
  if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
    let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
      return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
    });
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
  }
  if (!props.update) delete additionalInfo.additionalInfoId;
  prMaster.additionalInfoList = [additionalInfo];
  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;

    if(!data.itemId && !data.description && !data.qty && !data.rate){
      return
    }
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    //if(!itemId) return;
    let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
    if(data.productObj && !data.productObj.hasVariant && !variantId){
      variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId: data.inventoryItemVariantId;
    }
    let product = {};
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }
    let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);
    const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
    itemLevelAmount = itemLevelAmount - discountOnItem;
    let taxOnItem = (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2);
    prMaster.purchaseRequestDetailsList.push({
      version: props.update ? data.version : 0,
      relationshipId: companyInfo && companyInfo.relationshipId,
      quantity: Number(data.qty),
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      txDate: formatDateForBackend(props.invoiceDate),
      itemName: product.itemName,
      itemId: product.itemId,
      description: data.description,
      rowHeight: data.scrollHeight,
      hsnCode: data.hsnCode,
      inventoryItemVariantId: variantId,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      inquiryMasterId: props.inquiryMasterId || data.inquiryMasterId,
      salesOrderMasterId: props.salesOrderMasterId || data.salesOrderMasterId,
      soNumber: props.soNumber,
      parentDetailsId: data.parentDetailsId,
      qtyBeforeUpdate: Number(data.qtyBeforeUpdate || 0),
      purchaseRequestDetailsId: data.purchaseRequestDetailsId,
      purchaseRequestMasterId: props.update ? (props.purchaseRequestMasterId || 0) : 0,
      anItemPurchasePrice: Number(data.rate),
      discountAmount: discountOnItem,
      discountPercent: data.discount,
      taxAmount: taxOnItem,
      taxPercent: data.tax,
    });
  });

  return prMaster;
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.NEXT_PURCHASE_REQUEST_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/getNextPurchaseRequestNumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.NEXT_PURCHASE_REQUEST_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = PURCHASE_REQUEST_ACTION_LIST.NEXT_PURCHASE_REQUEST_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}

export const fetchInquiryDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/inquiry/getInquiryDataForPR?inquiryMasterId=${payload.inquiryMasterId}&relationshipId=${payload.relationshipId}`;
    return axios
      .get(url)
      .then(res => {
        const { data } = res
        dispatch({ type: INQUIRY_ACTION_LIST.ENQUIRY_DETAIL, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = INQUIRY_ACTION_LIST.ENQUIRY_DETAIL;
        }
      })
  }
}

export const resetEnquiryDetail = () => {
  return dispatch => {
    dispatch({ type: INQUIRY_ACTION_LIST.RESET_ENQUIRY_DETAIL });
  }
}

export const fetchSODetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getSalesOrderDataForPR?salesOrderMasterId=${payload.salesOrderMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.SO_PR_DATA, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = PURCHASE_REQUEST_ACTION_LIST.SO_PR_DATA;
        }
      })
  }
}


export const resetSODetails = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.RESET_SO_PR_DATA });
  }
}


export const createPurchaseRequestDraft = (payloadObject) => {
  const props = payloadObject.props;
  const prData = _getPRData(payloadObject);
  return dispatch => {
    const data = {
      purchaseRequestDraftId: props.purchaseRequestDraftId || 0,
      prData: JSON.stringify(prData),
      relationshipId: prData.relationshipId,
      version:props.versionOfDraft || 0,
    }

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/saveOrUpdateDraft`, data)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Purchase Request saved in draft' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.history.push({
          pathname: '/admin/purchase-request-list',
          state: { activeTab: 'Draft' }
        })

        resetPaginatedData()(dispatch);
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          inquiryMasterId: props.inquiryMasterId,
          salesOrderMasterId: props.salesOrderMasterId || props.salesOrderMasterId,
          customerId: props.customerId || props.customerId,
          userId: (props.userInfo || {}).userId,
          prMasterId: props.prMasterId,
          pageNumber: 1,
          pageSize: 100
        };
        props.fetchPurchaseRequestDrafts(payload);
        props.fetchPurchaseRequestDrafts(payload);
        getPurchaseRequestsCount(payload)(dispatch);
        resetDraftPaginatedData()(dispatch);
        props.popModalFromStack();
        getPurchaseRequestDraftCount(payload)(dispatch);
        
        props.fetchPurchaseRequests(payload)
        
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}


export const deleteDocument = (payload,props) => {
  return dispatch => {
    let formData = new FormData();
    formData.append("relationshipId", payload.relationshipId);
    formData.append('bucketName', props.companyInfo.bucketName);
    formData.append('objectKey', payload.awsKeyName);
    formData.append('folderName', payload.folderName);
    formData.append('purchaseRequestMasterId', payload.prMasterId);
   
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/deletePurchaseRequestDocument`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchPurchaseRequestDetail(props);
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}