import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DocumentNumberComponent from '../../../../../components/modal/modalBody/settings/DocumentNumber';
import { pushModalToStack } from '../../../../../actions/commonActions';
import { getSalesDocumentNumber, saveSalesDocumentNumber, getSalesDocumentPrefix, savePurchaseDocumentNumber, getPurchaseDocumentPrefix, getPurchaseDocumentNumber } from './action';
import { CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY } from '../../../../../static/constants';

class DocumentsNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // tabDocumentOptions: [
            //     { txType: CONSTANTS_TRANSACTIONS.INQUIRY, label: 'Sales Inquiry', numberExample: 'INQ-1', numberInfo: 'INQ-CustomerNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.SALES_QUOTE, label: 'Sales Quote', numberExample: 'QO-1', numberInfo: 'SQ-CustomerNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.SALES_ORDER, label: 'Sales Order', numberExample: 'SO-1', numberInfo: 'SO-CustomerNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.SALES_ORDER_ONLINE, label: 'Sales Order Online', numberExample: 'SOO-1', numberInfo: 'SOO-CustomerNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.AMEND_SALES_ORDER, label: 'Amend Sales Order', numberExample: 'ASO-1', numberInfo: 'ASO-CustomerNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.SALES_INVOICE, label: 'Sales Invoice', numberExample: 'INV-1', numberInfo: 'INV-CustomerNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.SALES_PAYMENT, label: 'Sales Payment', numberExample: 'CPAY-1', numberInfo: 'CPAY-CustomerNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.PACKAGE, label: 'Package', numberExample: 'PKG-1', numberInfo: 'CM-CustomerNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.SHIPMENT, label: 'Shipment', numberExample: 'SHP-1', numberInfo: 'CM-CustomerNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.CREDIT_MEMO, label: 'Credit Memo', numberExample: 'CM-1', numberInfo: 'CM-CustomerNumber-DDMMYY' },
               
            //     { txType: CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST, label: 'Purchase Request', numberExample: 'PR-1', numberInfo: 'PO-SupplierNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.RFQ, label: 'RFQ', numberExample: 'RFQ-1', numberInfo: 'RFQ-1' },
            //     { txType: CONSTANTS_TRANSACTIONS.PURCHASE_ORDER, label: 'Purchase Order', numberExample: 'PO-1', numberInfo: 'PO-SupplierNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY, label: 'Goods Receipt Note', numberExample: 'GRN-1', numberInfo: 'PO-SupplierNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE, label: 'Purchase Invoice', numberExample: 'INV-1', numberInfo: 'INV-SupplierNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.PURCHASE_PAYMENT, label: 'Supplier Payment', numberExample: 'SPAY-1', numberInfo: 'SPAY-SupplierNumber-DDMMYY' },
            //     { txType: CONSTANTS_TRANSACTIONS.DEBIT_MEMO, label: 'Debit Memo', numberExample: 'DM-1', numberInfo: 'DM-SupplierNumber-DDMMYY' },
            // ],
            tabSalesDocumentOptions: [
                { txType: CONSTANTS_TRANSACTIONS.INQUIRY, label: 'Sales Inquiry', numberExample: 'INQ-1', numberInfo: 'INQ-CustomerNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.SALES_QUOTE, label: 'Sales Quote', numberExample: 'QO-1', numberInfo: 'SQ-CustomerNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.SALES_ORDER, label: 'Sales Order', numberExample: 'SO-1', numberInfo: 'SO-CustomerNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.SALES_ORDER_ONLINE, label: 'Sales Order Online', numberExample: 'SOO-1', numberInfo: 'SOO-CustomerNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.AMEND_SALES_ORDER, label: 'Amend Sales Order', numberExample: 'ASO-1', numberInfo: 'ASO-CustomerNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.SALES_INVOICE, label: 'Sales Invoice', numberExample: 'INV-1', numberInfo: 'INV-CustomerNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.SALES_PAYMENT, label: 'Sales Payment', numberExample: 'CPAY-1', numberInfo: 'CPAY-CustomerNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.PACKAGE, label: 'Package', numberExample: 'PKG-1', numberInfo: 'CM-CustomerNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.SHIPMENT, label: 'Shipment', numberExample: 'SHP-1', numberInfo: 'CM-CustomerNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.CREDIT_MEMO, label: 'Credit Memo', numberExample: 'CM-1', numberInfo: 'CM-CustomerNumber-DDMMYY' },
            ],
            tabPurchaseDocumentOptions: [
                { txType: CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST, label: 'Purchase Request', numberExample: 'PR-1', numberInfo: 'PO-SupplierNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.RFQ, label: 'RFQ', numberExample: 'RFQ-1', numberInfo: 'RFQ-1' },
                { txType: CONSTANTS_TRANSACTIONS.PURCHASE_ORDER, label: 'Purchase Order', numberExample: 'PO-1', numberInfo: 'PO-SupplierNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY, label: 'Goods Receipt Note', numberExample: 'GRN-1', numberInfo: 'PO-SupplierNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE, label: 'Purchase Invoice', numberExample: 'INV-1', numberInfo: 'INV-SupplierNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.PURCHASE_PAYMENT, label: 'Supplier Payment', numberExample: 'SPAY-1', numberInfo: 'SPAY-SupplierNumber-DDMMYY' },
                { txType: CONSTANTS_TRANSACTIONS.DEBIT_MEMO, label: 'Debit Memo', numberExample: 'DM-1', numberInfo: 'DM-SupplierNumber-DDMMYY' },
            ],
            activeSequenceTab: 'sales_sequence'
        }
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        // this.state.tabDocumentOptions.forEach((ele, i) => {
        //     const isLast  = (i === this.state.tabDocumentOptions.length-1);
        //     this.props.getDocumentNumber({ relationshipId: companyInfo.relationshipId, txType: ele.txType, isLast });
        // });
        // this.props.getDocumentNumber({ relationshipId: companyInfo.relationshipId });
        // this.props.getDocumentPrefix({ relationshipId: companyInfo.relationshipId });

        this.props.getSalesDocumentNumber({ relationshipId: companyInfo.relationshipId });
        this.props.getSalesDocumentPrefix({ relationshipId: companyInfo.relationshipId });

        this.props.getPurchaseDocumentNumber({ relationshipId: companyInfo.relationshipId });
        this.props.getPurchaseDocumentPrefix({ relationshipId: companyInfo.relationshipId });

    }

    componentWillReceiveProps(props) {
            // if (props.documentNumbers && this.state.tabSalesDocumentOptions?.length && this.state.tabPurchaseDocumentOptions?.length && Object.keys(props.documentPrefix)?.length ) {
            //     let list = this.state.tabDocumentOptions.map(obj => {
            //         obj.number = props.documentNumbers[CONSTANTS_TRANSACTIONS_KEY[obj.txType]] || 1;
            //         obj.txName = CONSTANTS_TRANSACTIONS_KEY[obj.txType];
            //         obj.txNamePefix = props.documentPrefix[CONSTANTS_TRANSACTIONS_KEY[obj.txType]];
            //         return obj;
            //     });

            //     this.setState({
            //         tabDocumentOptionsList: list,
            //     });
            // }

            if (props.salesDocumentNumbers && this.state.tabSalesDocumentOptions?.length && Object.keys(props.salesDocumentPrefix || {})?.length ) {
 
                let salesList = this.state.tabSalesDocumentOptions.map(obj => {
                    obj.number = props.salesDocumentNumbers[CONSTANTS_TRANSACTIONS_KEY[obj.txType]] || 1;
                    obj.txName = CONSTANTS_TRANSACTIONS_KEY[obj.txType];
                    obj.txNamePefix = props.salesDocumentPrefix[CONSTANTS_TRANSACTIONS_KEY[obj.txType]];
                    return obj;
                });
                this.setState({
                    tabSalesDocumentOptionsList: salesList,
                });
            }

            if (props.purchaseDocumentNumbers && this.state.tabPurchaseDocumentOptions?.length && Object.keys(props.purchaseDocumentPrefix || {})?.length ) {

                let purchaseList = this.state.tabPurchaseDocumentOptions.map(obj => {
                    obj.number = props.purchaseDocumentNumbers[CONSTANTS_TRANSACTIONS_KEY[obj.txType]] || 1;
                    obj.txName = CONSTANTS_TRANSACTIONS_KEY[obj.txType];
                    obj.txNamePefix = props.purchaseDocumentPrefix[CONSTANTS_TRANSACTIONS_KEY[obj.txType]];
                    return obj;
                });
                this.setState({
                    tabPurchaseDocumentOptionsList: purchaseList
                });
            }
    }

    render() {
        return <DocumentNumberComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        permissions: state.auth.permissions,

        documentNumbers: state.settings.documentNumbers,
        documentPrefix: state.settings.documentPrefix,
        salesDocumentNumbers: state.settings.salesDocumentNumbers,
        salesDocumentPrefix: state.settings.salesDocumentPrefix,
        purchaseDocumentNumbers: state.settings.purchaseDocumentNumbers,
        purchaseDocumentPrefix: state.settings.purchaseDocumentPrefix,
        listLoading: state.common.listLoading,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    pushModalToStack,
    getSalesDocumentNumber,
    saveSalesDocumentNumber,
    getSalesDocumentPrefix,
    getPurchaseDocumentNumber,
    savePurchaseDocumentNumber,
    getPurchaseDocumentPrefix
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsNumber);