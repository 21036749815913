const configuration = {
    API_BASE_URL: 'http://localhost:8060',
    API_VERSION: '',
    AWS_BASE_URL:'http://localhost:8060',
    PDF_API_BASE_URL: 'http://localhost:8000/api/',
    INVENTORY_BASE_URL: 'http://localhost:8060',
    SALES_BASE_URL: 'http://localhost:8060',
    USERS_BASE_URL:'http://localhost:8060',
    ACCOUNTING_BASE_URL:'http://localhost:8060',
    PURCHASE_BASE_URL:"http://localhost:8060",
    CRM_BASE_URL:"http://localhost:8060",
    LOOKUP_BASE_URL:"http://localhost:8060",
    EDUCATION_BASE_URL: "http://localhost:8060",
    // EDUCATION_BASE_URL: "http://localhost:8060",
    LOGISTICS_BASE_URL: "http://localhost:8060",
    HRMS_BASE_URL: "https://service.alpidedev.com",
    ECOM_BASE_URL: "http://localhost:8060",
    COUPON_BASE_URL: "http://localhost:8060",
    KIOSK_BASE_URL: 'http://localhost:3001',
    COMMUNICATION_BASE_URL: "http://localhost:8060",
    PDF_API_VERSION: 'v1',
    REDIRECT_URI_FOR_AMAZON: 'https://uat.alpide.com/app/admin/sales-channels',
    rootContext: 'alpide',
    rootContextTwilio: 'alpide-tcom',
    rootContextInvoiceService: 'alpide-invoice',
    INVOICE_SERVICE_BASE_URL: 'http://localhost:8060',
    rootContextPaymentGateway: 'alpide-paypal',
    PAYMENT_GATEWAY_BASE_URL: 'http://localhost:8060',
    rootContextPlaid: 'alpide-plaid',
    rootContextMWS: 'alpide-mws',
    rootContextSMS: 'alpide-sms',
    rootContextBrevo:'brevo-sms-app',
    rootContextCC:'alpide-cc',
    rootContextAws:'aws',
    rootContextSupport:'support',
    rootContextRazorpay:'alpide-razorpay',
    rootContextStripe: 'alpide-stripe',
    rootContextHRMS: 'alpide',
    rootContextCMS: 'cms',
    rootContextInventory: 'inventory',
    rootContextPurchase: 'purchase',
    rootContextSales:'sales',
    rootContextUsers:'users',
    rootContextAccounting:'accounting',
    rootContextLookup:'lookup',
    rootContextCRM:'crm',
    rootContextEducation: 'education',
    rootContextLogistics: 'logistics',
    rootContextECOM: 'commerce', 
    rootContextCoupon: 'coupon',
    rootContextCommunication:'communication',
    rootContextProject:'project',
    PROJECT_BASE_URL: 'http://localhost:8060',
    API_VERSION_CMS:'api/v1',
    CMS_BASE_URL: 'http://localhost:8060',
    MWS_BASE_URL: 'http://localhost:8060',
    PLAID_BASE_URL: 'http://localhost:8060',
    COMMERCE_BASE_URL: 'http://localhost:8060',
    UI_BASE_URL: 'http://localhost:3000/app/',
    COUPON_BASE_URL: 'http://localhost:8081',
    COUPON_CONTEXT: 'alpide-comm',
    CALLBACK_URI_AUTH_NET: 'http://localhost:3000/app/admin/payment-integration',
    PAYMENT_SERVER_BASE_URL: 'https://uat-app.alpide.com',
    CALLBACK_URI_MARKTPLACE: 'http://localhost:3000/app/admin/sales-channels',
    CALLBACK_URI_MARKTPLACE_AMAZON: 'http://localhost:3000/app/admin/sales-channels-amazon',
    CALLBACK_URI_MARKTPLACE_EBAY: 'http://localhost:3000/app/admin/sales-channels-ebay',
    PAYMENT_FORM_ACTION: 'https://test.authorize.net/payment/payment',
    CC_TOKENIZER_URL: 'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html',
    SUPPORT_BASE_URL: 'http://localhost:8060',
    //  SSO_URL: 'http://acc.alpide.com:3000',
    WP_DOMAIN: 'https://alpide.lmpx.com',
    ROOT_DOMAIN: 'alpide.com',
    ENCRYPT_SECRET: 'Unl0ck!!!',
    CB_PUBLIC_KEY: 'test_9KdLB3laV1fzmNupMYMQyQRwUioTPYVL',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_51IdxxgL81zohSYdnTW0V88mWnfCIhqk8pgZdYgD9S2Xbut00JH58Ac7QHce64AWM0GUbKrcvlv6G5M3BHCp6dOGJ00Ja0AiJgF',
    BUCKET_NAME: {
        BO_RELATIONSHIP: 'relationship-u',
        BO_SALES_INQUIRY: "inquiry-u",
        BO_SALES_QUOTE: "sales-quote-u",
        BO_SALES_ORDER: 'sales-order-u',
        BO_CUSTOMER_INVOICE: "sales-invoice-u",
        BO_CREDIT_MEMO: "credit-memo-u",
        BO_PURCHASE_REQUEST: "purchase-request-u",
        BO_RFQ: "supplier-rfq-u",
        BO_PURCHASE_ORDER: "purchase-order-u",
        BO_INBOUND_DELIVERY: "inbound-delivery-u",
        BO_SUPPLIER_INVOICE: "purchase-invoice-u",
        BO_DEBIT_MEMO: "debit-memo-u",
        BO_SAMPLE_RESOURCES: "sample-resources-u",
    },

    ERP_THEMES:{
        101: "Vogue",
        129: "Dapper",
        156: "Matrikala"
    }
};
export default configuration;
