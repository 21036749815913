import axios from "axios";
import config from "../../../config/environmentConfig";
import { COMMON_ACTIONS, SMS_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

  export const getSchoolAssignment = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });

    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignment/getByClassId?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}&classId=${payload.classId || 0}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.ASSIGNMENT_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      });
  };
};

export const getSchoolAssignmentCount = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });

    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignment/countByClassId?relationshipId=${payload.relationshipId}&createdByEmpId=${payload.relationshipEmployeeId}&classId=${payload.classId || 0}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.ASSIGNMENT_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      });
  };
};


export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.ASSIGNMENT_LIST_PAGE_RESET });
  };
};

export const deleteAssignment = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignment/delete`, payload)
      .then(res => {
        // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // props.getFees && props.getFees(props);
        getSchoolAssignment({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: props.defaultSchoolForm}, )(dispatch);
        getSchoolAssignmentCount({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: props.defaultSchoolForm})(dispatch);
       showToasterMessage({ messageType: 'success', description: 'Assignment deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}

export const deleteAssignmentDoc = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignment/deleteDocument`, payload)
      .then(res => {
        getSchoolAssignment({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: props.defaultSchoolForm}, )(dispatch);
        getSchoolAssignmentCount({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: props.defaultSchoolForm})(dispatch);
       showToasterMessage({ messageType: 'success', description: 'Document deleted successfully' });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}