import axios from "axios";
import config from "../../../config/environmentConfig";
import { COMMON_ACTIONS, COMPANY_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getEmpAttendances = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/empAttendance/getRelationshipEmployeeAttendance?relationshipId=${payload.relationshipId}&startDate=${payload.startDate}&endDate=${payload.endDate}&empId=${payload.relationshipEmployeeId}`)
      .then((res) => {
        dispatch({
          type: COMPANY_ACTION_LIST.EMPLOYEE_ATTENDANCE_LIST,
          data: res.data,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  };
};



