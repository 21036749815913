import { Col, Row, Tooltip, Button, Divider, Skeleton } from "antd";
import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
//import { AgGridReact } from 'ag-grid-react';
import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { TRANSACTION_COLUMN_INDEX, MODULE_CODED_VALUES, PERMISSION_VALUES, MODAL_TYPE } from "../../../../static/constants";
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS,  } from '../../../../static/agGridConstants';
import find from 'lodash.find';
import ReactHtmlParser from 'react-html-parser';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import { fixedDecimalAndFormateNumber, showToasterMessage } from "../../../../utils";
import * as filter from 'lodash.filter';


class ProductDetails extends React.Component {

  constructor() {
    super();
    this.state = {
      sectionVisible: {
        discount: false,
        tax: false,
        expense: false
      },
      total: 0,
      subtotal: 0,
      subtotalWithoutDiscount: 0,
      totalDiscount: 0,
      expenseAmount: 0,
      totalTax: 0,
      itemLevelTaxes: [],
      rootDiscountPercent: 0,
      rootDiscountAmount: 0
    }
  }


  calculateSubtotal = () => {
    let total = 0;
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []
    let subtotalWithoutDiscount = 0;
    let rootDiscountAmount = 0;
    let self = this;

    if (!this.state.rootDiscountPercent && this.props.rootDiscountPercent) {
      this.setState({
        rootDiscountPercent: Number(this.props.rootDiscountPercent || 0),
        rootDiscountAmount: Number(this.props.rootDiscountAmount || 0),
        expenseAmount: Number(this.props.expenseAmount || 0)
      });
     }

    this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
      let stringifiedRowData = JSON.stringify(rowNode.data);
      let totalAmountOfColumn = 0;
      const rowdata = JSON.parse(stringifiedRowData);
      if (rowdata.qty && rowdata.rate) {
        let taxObj = {
          subtotal: 0,
          taxPercent: 0,
          taxAmount: 0
        }
        totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
        subtotalWithoutDiscount = Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
        if (rowdata.discount) {
          let discountAmount = ((Number(rowdata.discount || 0) * 0.01) * totalAmountOfColumn);
          totalAmountOfColumn = totalAmountOfColumn - discountAmount;
          totalDiscount = totalDiscount + discountAmount;
        }
        if (self.state.rootDiscountPercent) {
          let itemLevelDiscountAmount = (Number(self.state.rootDiscountPercent) * 0.01) * totalAmountOfColumn;
          totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
          totalDiscount = totalDiscount + itemLevelDiscountAmount
          rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
        }

        if (rowdata.tax) {
          taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
          taxObj.taxName = rowdata.taxName;
          let taxAmount = ((Number(rowdata.tax || 0) * 0.01) * totalAmountOfColumn);
          totalTax = totalTax + taxAmount;
        }
        if (taxObj.taxPercent) {
          taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
          const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
          if (availableTaxObj) {
            itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
              if (ele.taxPercent === taxObj.taxPercent) {
                ele.subtotal = Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                ele.taxAmount = (Number(ele.taxPercent || 0) * 0.01) * Number(ele.subtotal || 0);
              }
              return true;
            });
          } else {
            taxObj.taxAmount = ((Number(taxObj.taxPercent || 0) * 0.01) * Number(taxObj.subtotal || 0)).toFixed(2);
            itemLevelTaxes.push(taxObj);
          }
        }
        subtotal = Number(subtotal) + totalAmountOfColumn;
      }
    });

    total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;
    this.setState({
      total: Number(total || 0).toFixed(2),
      subtotal: Number(subtotal || 0).toFixed(2),
      totalDiscount: Number(totalDiscount || 0).toFixed(2),
      totalTax: Number(totalTax || 0).toFixed(2),
      subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
      itemLevelTaxes,
      rootDiscountAmount
    });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.props.updateState({
        gridApi: params.api,
        gridColumnApi: params.columnApi
    });
    if(this.props.isColumnFitTable && params.api){
        params.api.sizeColumnsToFit();
        this.props.updateState({
            isColumnFitTable: false
        })
    }
    // onDataRendered(params);
  }



  render(){

    // const { pageNumber } = this.props;

    const self = this;
  
    const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
      const gridColumnApi = tableGridColumnApi || this.props.gridColumnApi;
      event.api.resetRowHeights();
      if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
          //event.api.resetRowHeights();
          if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
              this.props.gridApi.sizeColumnsToFit();
              setTimeout(() => {
                  const columnArray = event.columnApi.getColumnState();
                  saveColumnWidth(columnArray);
              }, 1000);
          } else {
              saveColumnWidth(event.columnApi.getColumnState());
          }
      }
    }

    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.props.updateState({
          gridApi: params.api,
          gridColumnApi: params.columnApi
      });
      if(this.props.isColumnFitTable && params.api){
          params.api.sizeColumnsToFit();
          this.props.updateState({
              isColumnFitTable: false
          })
      }
      onDataRendered(params);
    }
  
    // const saveColumnIndex = (columnState) => {
    //   let data = this.props.txPreferences || {};
    //   let tablePreference = {};
    //   data.relationshipId = (this.props.companyInfo || {}).relationshipId;
    //   data.clientUserAccountId = (this.props.userInfo || {}).userId;
    //   if (data.tablePreference) {
    //       tablePreference = JSON.parse(data.tablePreference);
    //   }
    //   tablePreference.columnSetting = columnState;
    //   tablePreference.columnList = this.props.txColumns;
    //   data.tablePreference = JSON.stringify(tablePreference);
    //   data.preferenceId = this.props.preferenceId;
    //   data.gridPreference = this.props.gridPreference;
    //   this.props.saveUserPrefrences(data);
    // }
  
    const saveColumnWidth = (columnState, width) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      // this.props.saveUserPrefrences(data, this.props);
    }
  
    const _isColumnToHide = (columnName) => {
      return (this.props.txColumns.indexOf(columnName) < 0 && this.props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }
  

  
    const onDataRendered = (params) => {
      let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
      if (Object.keys(txColumnSetting).length) {
          const columnToBeSet = [];
          const indexObjArr = TRANSACTION_COLUMN_INDEX.PURCHASE_PAYMENT_LISTING
  
          indexObjArr.forEach((e) => {
              let colObj = find(txColumnSetting, { colId: e + '_1' });
              if (colObj) {
                columnToBeSet.push(colObj);
              }
          });
  
          txColumnSetting = txColumnSetting.filter((ele) => {
              let columnName = ele.colId.split("_")[0];
              return indexObjArr.indexOf(columnName) === -1;
          })
          txColumnSetting = columnToBeSet.concat(txColumnSetting);
          txColumnSetting.forEach((e) => {
              let columnName = e.colId.split("_")[0];
              if (columnName !== 'action') {
                  if (columnName === 'isChecked') {
                      e.hide = false;
                      e.pinned = 'left';
                  } else {
                      e.hide = _isColumnToHide(columnName);
                  }
              } if (columnName === 'action') {
                  e.hide = false;
                  e.width = 52;
                  e.pinned = 'right';
              }
          });
          params.columnApi.setColumnState(txColumnSetting);
          setTimeout(() => {
              if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
                  params.api.sizeColumnsToFit();
              }
          }, 500);
      } else {
          params.api.sizeColumnsToFit();
      }
    }
  
    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }
    const columnDefs = [
      {
        headerComponentFramework: () => {
          return "S.No.";
        },
        cellRendererFramework: (params) => {
          return params.node.rowIndex + 1
        },
        field: "sno",
        headerClass: "pointer-none",
        pinned: true,
        suppressMovable: true,
        lockPosition: true,
        colId: "sno_1",
        hide: false,
        minWidth: 50,
        maxWidth: 75,
        sortable: true,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.product' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
        resizable: true,
        editable: true,
        sortable: true,
        permissions: this.props.permissions,
        moduleName: MODULE_CODED_VALUES.PRODUCTS,
        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
        minWidth: 120,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PRODUCT),
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD] || {}).width,
        cellEditor: 'customTreeDropDownStock',
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (params.reactContainer && params.reactContainer.scrollHeight >= (params.node.data.maxHeight || 30)) {
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
              this.gridApi && this.gridApi.onRowHeightChanged();
            } else {
              params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
            }
          }, 500)
          return ReactHtmlParser(params.data.product)
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            options: this.props.products.filter(obj => obj.warehouseMasterId === this.props.selectedSourceWarehouse),
            onSearch: this.props.onSearch,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.PRODUCTS,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            onAddNewClick: () => {
              self.props.updateHeaderState({
                inventory: {
                  inventoryDrawerVisible: true
                }
              });
            },
            canAddNew: true,
            value: obj.node.data.selectedProductValue || [],
            selectedLabel: obj.node.data.product || "",
            allowClear: true,
            onSelect: (selectedValue) => {
              ////obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.selectedProductValue = obj.node.selectedProductValue;

              obj.node.data.productObj = null;
              obj.node.data.variant = null;
              const itemId = obj.node.data.selectedProductValue && obj.node.data.selectedProductValue[0];
              if (itemId) {
                const variantId = obj.data.selectedProductValue && obj.data.selectedProductValue.length > 1 ? obj.data.selectedProductValue[1] : 0;
                const itemVariantStockId = obj.data.selectedProductValue && obj.data.selectedProductValue.length === 3 ? obj.data.selectedProductValue[2] : 0;
                obj.node.data.productObj = find(this.props.products.filter(obj => obj.warehouseMasterId === this.props.selectedSourceWarehouse), { itemId: Number(itemId) }) || {};
                obj.node.data.variant = variantId ? find(obj.node.data.productObj.inventoryItemVariantList, { variantId: Number(variantId) }) : obj.node.data.productObj.inventoryItemVariantList[0];
                obj.node.data.inventoryItemVariantId = variantId;
                obj.node.data.itemVariantStockId = itemVariantStockId;
                if (obj.node.data.productObj.description) {
                  obj.node.data.description = obj.node.data.productObj.description;
                }
              }
              if (this.props.priceTypeToApply && obj.node.data.variant && !this.props.isDataToUpdate) {
                const rate = this.props.priceTypeToApply === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0] ? Number(obj.node.data.variant.retailPrice || 0).toFixed(2) : Number(obj.node.data.variant.wholesalePrice || 0).toFixed(2);
                obj.node.data.baseRate = Number(rate);
                obj.node.data.rate = Number(rate);
                ////obj.node.setDataValue('specialDiscount', 0);
                ////obj.node.setDataValue('baseRate', Number(rate));
                ////obj.node.setDataValue('rate', Number(rate));
                ////obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                obj.node.data.uom = obj.node.data.variant.uomName;
                if (obj.node.data.productObj.isPopulateTax && !_isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX)) {
                  const defaultItemHsnAndTax = obj.node.data.productObj.inventoryItemDefaultTaxList[0] || {};
                  obj.node.data.hsnCode = ((defaultItemHsnAndTax || {}).hsnCode);
                  let selectedTax = {};
                  if ((defaultItemHsnAndTax || {}).taxSingleRateId) {
                    selectedTax = find(this.props.taxes, { taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId }) || {};
                  } else {
                    selectedTax = find(this.props.taxes, { taxPercent: ((defaultItemHsnAndTax || {}).igstRate || (defaultItemHsnAndTax || {}).cgstRate || (defaultItemHsnAndTax || {}).globleTaxRate) }) || {};
                  }
                  obj.node.data.taxName = selectedTax.taxName;
                  obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                  obj.node.data.tax = selectedTax.taxPercent;
                  obj.node.data.taxId = selectedTax.taxSingleRateId;
                }
              }
              let isDuplicateItem = false;
              this.gridApi && this.gridApi.forEachNode((rowNode, index) => {
                const { data } = rowNode;
                if (data.product === selectedValue && index !== obj.node.rowIndex) {
                  isDuplicateItem = true;
                }
              });
              if (isDuplicateItem) {
                showToasterMessage({
                  messageType: 'warning', description: 'Duplicate product is selected'
                });
              }
  
              obj.node.data.isDuplicateItem = isDuplicateItem;
              setTimeout(() => {
                this.gridApi && this.gridApi.stopEditing();
              }, 100);
            }
          }
        },
        cellClassRules: {
          'ag-grid-cell-warning-boundary': function (obj) {
            let isDuplicateItem = false;
            self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
              const { data } = rowNode;
              if (data.inventoryItemVariantId && data.inventoryItemVariantId === obj.node.data.inventoryItemVariantId && obj.node.rowIndex !== index) {
                isDuplicateItem = true;
              }
            });
            return isDuplicateItem;
          }
        }
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.description' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
        cellClass: " ag-cell-description",
        colId: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD + "_1",
        editable: true,
        minWidth: 120,
  
        resizable: true,
        sortable: true,
        suppressMovable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DESCRIPTION),
        // cellEditor: 'CKEditorGrid', -- comment by c 
        cellEditorParams: (params) => {
          return {
            value: params.node.data.description || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              //params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true
              })
            }
          }
        },
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
              params.eParentOfValue.style.height = 'inherit';
              params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500)
          return ReactHtmlParser(params.data.description)
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (params.event.target.scrollHeight > (params.data.minHeight || 30)) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = 'inherit';
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = 'inherit';
              params.event.path[2].style.height = `${(params.node.data.minHeight || 30)}px`;
            }
            params.node.setRowHeight((params.node.data.minHeight || 30));
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        }
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.qty' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.QUANTITY + "_1",
        editable: true,// !this.props.isSQConvertions,
        sortable: true,
        minWidth: 80,
        maxWidth: 100,
        
        suppressMovable: true,
        resizable: true,
        cellEditor: 'customNumberEditor',
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
            return { 
              decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthQty,
                onChange: (value) => {
                    obj.node.data.qty = value;
                    obj.api.refreshCells();
                }
            }
        }
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.uom' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
        editable: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        maxWidth: 120,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
        colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
        cellEditor: 'customDropDownEditor',
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.uomList,
            optionKeyName: 'uomId',
            valueKeyName: 'uomName',
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false
              }
              this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM)
            },
            onSelect: (selectedValue, option) => {
              ////obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.uomName = selectedValue;
              obj.node.data.uomId = option.key;
            },
            allowClear: true,
            onClear: () => {
              ////obj.node.setDataValue(obj.colDef.field, undefined);
              this.props.updateState({
                uomName: undefined,
                uomId: undefined
              });
            },
          }
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.rate' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.RATE + "_1",
        editable: true,
        resizable: true,
        maxWidth: 150,
        minWidth: 100,
        sortable: true,
        cellEditor: 'customNumberEditor',
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthPrice,
            onChange: (value) => {
              obj.node.data.baseRate = value;
              obj.node.data.rate = value;
              obj.api.refreshCells();
            }
          }
        }, 
        valueGetter: (params) => {
          if (params.data.specialDiscount && params.data.rate) {
            params.data.rate = params.data.baseRate - ((params.data.specialDiscount * .01) * params.data.baseRate);
          }
          if (!params.data.specialDiscount && params.data.baseRate) {
            params.data.rate = params.data.baseRate;
          }
          if (this.props.priceListId && params.data.rate) {
            params.data.rate = params.data.baseRate - (this.props.priceListType.toLowerCase() === 'markdown' ? (((params.data.specialDiscount || 0) * .01) * params.data.baseRate) + (this.props.priceListPercentage * .01) * params.data.baseRate : (((params.data.specialDiscount || 0) * .01) * params.data.baseRate) - (this.props.priceListPercentage * .01) * params.data.baseRate);
          }
          return params.data.rate && Number(params.data.rate || 0).toFixed((this.props.companyInfo || {}).decimalDigitsLengthPrice || 0);
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
      },

      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.amount' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD + "_1",
        resizable: true,
        //minWidth: 120,
        maxWidth: 200,
        suppressMovable: true,
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount = ((params.data.discount * 0.01) * totalAmountOfColumn);
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
            if (params.data.tax) {
              totalAmountOfColumn = ((params.data.tax * 0.01) * totalAmountOfColumn) + totalAmountOfColumn;
            }
            params.data.amount = totalAmountOfColumn ? fixedDecimalAndFormateNumber(totalAmountOfColumn) : "";
            this.calculateSubtotal();
          }
          return totalAmountOfColumn ? fixedDecimalAndFormateNumber(totalAmountOfColumn) : "";
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  
      },
      {
        headerComponentFramework: () => {
          return <div><FormattedMessage id='customer.salesOrder.form.gridHeader.comment' defaultMessage='' />
            <Tooltip placement="top" title={this.props.intl.formatMessage(
              {
                id: 'tooltip.warehouseDetail',
                defaultMessage: ''
              }
            )} trigger="click">
              <i className="fa fa-question-circle-o pl5 cursor-pointer" />
            </Tooltip>
          </div>
        },
        field: AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD,
        editable: true,
        sortable: true,
        resizable: true,
        //minWidth: 115,
        //maxWidth: 130,
        suppressMovable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.COMMENT),
        colId: GRID_PREFERENCE_CONSTANTS.COMMENT + "_1",
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: (params) => {
          setTimeout(() => {
            if (params.node.data.scrollHeight) {
              document.querySelector("textarea.ag-text-area-input").style.height = 'inherit';
              document.querySelector("textarea.ag-text-area-input").style.height = params.node.data.scrollHeight;
            }
          }, 100);
          return {
            maxLength: '1000',
            cols: '20',
            rows: 2
          }
        },
        cellRendererFramework: (params) => {
          return <pre> {params.data.comment}</pre>
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
          params.event.target.style.height = 'inherit';
          params.event.target.style.height = `${params.event.target.scrollHeight}px`;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (params.event.target.scrollHeight > 54) {
            params.node.setRowHeight(params.event.target.scrollHeight - (params.event.target.scrollHeight * .1));
          } else {
            //params.node.setRowHeight(params.event.target.scrollHeight - 26);
          }
          this.gridApi && this.gridApi.onRowHeightChanged();
          return gridShouldDoNothing;
        }
      },
      {
        headerComponentFramework: () => {
          return <div>
            {/* <Popover
              // content={content}
              title={<div className="text-center">Add/Remove </div>}
              placement="left"
              trigger="click"
            >
              <i className='fa fa-bars' />
            </Popover> */}
          </div>;
        },
        field: 'action',
        colId: "action_1",
        pinned: 'right',
        minWidth: 50,
        maxWidth: 50,
        cellRenderer: 'customActionEditor',
        suppressNavigable: true,
        cellClass: this.props.isSQConvertions ? 'custom-readonly-cell' : "",
        cellRendererParams: (params) => {
          return {
            onClick: () => {
              this.gridApi.updateRowData({ remove: [this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data] });
              if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                this.gridApi.updateRowData({ add: [{}] });
              }
              this.calculateSubtotal();
            },
          }
        }
      }
    ];
  
    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };
  
    const onModelUpdated = (params) => {
      if (( []).length && !this.props.isRowHeightSet) {
          setTimeout(() => {
            params.api.resetRowHeights();
          }, 1000)
          this.props.updateState({
            isRowHeightSet: true
          })
      }
      if (this.props.tableDataReRendered) {
          //params.api.resetRowHeights();
          this.props.updateState({
              tableDataReRendered: false
          })
          let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
          const columnToBeSet = [];
          const indexObjArr = TRANSACTION_COLUMN_INDEX.PURCHASE_PAYMENT_LISTING;
  
          indexObjArr.forEach((e) => {
              let colObj = find(txColumnSetting, { colId: e + '_1' });
              if (colObj) {
                  columnToBeSet.push(colObj);
              }
          });
  
          txColumnSetting = txColumnSetting.filter((ele) => {
              let columnName = ele.colId.split("_")[0];
              return indexObjArr.indexOf(columnName) === -1;
          })
          txColumnSetting = columnToBeSet.concat(txColumnSetting);
          txColumnSetting.forEach((e) => {
              let columnName = e.colId.split("_")[0];
              if (columnName !== 'action') {
                  if (columnName === 'isChecked') {
                      e.hide = false;
                  } else {
                      e.hide = _isColumnToHide(columnName);
                  }
              } if (columnName === 'action') {
                  e.hide = false;
                  e.pinned = 'right';
              }
          });
          params.columnApi.setColumnState(txColumnSetting);
          setTimeout(() => {
              if (this.props.gridColumnApi && this.props.gridColumnApi.columnController.bodyWidth < this.props.gridColumnApi.columnController.scrollWidth) {
                params.api.sizeColumnsToFit();
              }
          }, 500);
      }
    }

    const saveStockTransfer = (props, state, agGridObj, isDraft) => {

      if(!props.selectedSourceWarehouse){
        return showToasterMessage({
          messageType: 'error',
          description: this.props.intl.formatMessage({id : 'inventrory.stockTransfer.sourceWH.missing', defaultMessage : ''})
        });
      }

      if(!Object.keys(props.sourceWHAddress || {}).length){
        return showToasterMessage({
          messageType: 'error',
          description: this.props.intl.formatMessage({id : 'inventrory.stockTransfer.sourceAddress.missing', defaultMessage : ''})
        });
      }

      if(!props.selectedDestinationWarehouse){
        return showToasterMessage({
          messageType: 'error',
          description: this.props.intl.formatMessage({id : 'inventrory.stockTransfer.destinationWH.missing', defaultMessage : ''})
        });
      }

      if(!Object.keys(props.destinationWHAddress || {}).length){
        return showToasterMessage({
          messageType: 'error',
          description: this.props.intl.formatMessage({id : 'inventrory.stockTransfer.destionationAddress.missing', defaultMessage : ''})
        });
      }

      if(!props.expiryDate){
        return showToasterMessage({
          messageType: 'error',
          description: this.props.intl.formatMessage({id : 'inventrory.stockTransfer.expiryDate.missing', defaultMessage : ''})
        });
      }
   
      let errorKey = '';

      self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
        let { data } = rowNode;
        if(index === 0 && (!data.product && !data.description)){
          errorKey = 'missingProduct.errorMsg';
        }else if(index === 0 && !(Number(data.qty || 0))){
          errorKey = 'missingQty.errorMsg';
        }else if(index === 0 && !(Number(data.rate || 0))){
          errorKey = 'missingRate.errorMsg';
        }
      });

      if (errorKey && !isDraft) {
        return showToasterMessage({
          messageType: 'error',
          description: this.props.intl.formatMessage({id : errorKey, defaultMessage : ''})
        });
      }


      const modalData = {
        modalBody: <FormattedMessage
          // id='customer.salesOrder.form.confirmation'
          id='Are you sure you want to Submit?'
          defaultMessage=''
        />,
        handleSubmit: () => {
          const payload = {
            props,
            state,
            fileToUpload: state.fileToUpload,
            agGridObj,
            isDraft: isDraft
          }
          if (isDraft) {
            props.createStockTransferDraft(payload);
          } else {
            props.createStockTransfer(payload);
          }
          props.hideModal();
        }
      };
      this.props.showModal(modalData);
    }
  
    return (
      <Skeleton loading={this.props.isLoading} paragraph={{ rows: 5 }} active>
  
        <div className='mb10'>
          {self.props.txColumns.length ? 
              <CustomAgGrid
                  onGridReady={onGridReady}
                  columnDefs={columnDefs}
                  rowData={ [{}]}
                  rowDragManaged={true}
                  domLayout={"autoHeight"}
                  onModelUpdated={onModelUpdated}
                  // onGridReady ={(params) => {
                  //     gridApi = params.api;
                  // }}
                  animateRows={true}
                  defaultColDef={{
                      flex: 1,
                      autoHeight: true,
                      wrapText: true,
                  }}
                  //getRowHeight={getRowHeight}
                  onColumnResized={onColumnResized}
                  // onDragStopped={() => {
                  //     onColumnMoved({}, true);
                  // }}
                  //onColumnMoved={onColumnMoved}
              >
              </CustomAgGrid>
          : null}

        </div>
  
        <div style={{ display: "flex", gap: "10px" }}>
          <Button className="grey-button"
            onClick={
              () => {
                  this.gridApi.updateRowData({ add: [{}] });
              }
            }
          >
            <FormattedMessage
              id="customer.salesInvoice.form.button.addLine"
              defaultMessage=""
            />
          </Button>
  
          <Button className="grey-button"
            onClick={()=>{
              this.props.showModal({
                modalBody: "Are you sure you want to clear?",
                handleSubmit: () => {
                  let gridApi = this.gridApi;
                  let self = this;
                  this.gridApi.forEachNode(function (rowNode, index) {
                    gridApi.updateRowData({ remove: [rowNode.data] });
                    if (gridApi.rowRenderer.lastRenderedRow < 0) {
                      gridApi.updateRowData({ add: [{}] });
                    }
                    self.calculateSubtotal();
                  })
                }
              })
            }}
          >
            <FormattedMessage
              id="customer.salesInvoice.form.button.clearAllLines"
              defaultMessage=""
            />
          </Button>
        </div>
        <div className="upload-btn-so">
         
        </div>
        <Divider style={{ margin: "10px 0px" }} />
        <Row>
          <Col span={12}>
            <div className="remarks-title">
              <FormattedMessage
                id="customer.salesInvoice.form.internalRemarks.label"
                defaultMessage=""
              />
            </div>
            <CKEditor type="inline" className="description-textarea" />
          </Col>
          <Col span={7} offset={5}>
          {/* <div className="product-amount-values">
            <Row >
                <Col span={15}>
                <FormattedMessage id='customer.salesOrder.form.subTotal.label' defaultMessage='' />
                </Col>
                <Col span={9}>
                {(self.props.companyInfo || {}).currencyIcon ? <i className={(self.props.companyInfo || {}).currencyIcon}></i> : (self.props.companyInfo || {}).currencyCode + " "}
                
                </Col>
            </Row>
  
            <Row>
                <Col span={15}>
                <span className="title"><FormattedMessage id='customer.salesOrder.form.totalAmount.label' defaultMessage='' /></span>
                </Col>
                <Col span={9}>
                {(self.props.companyInfo || {}).currencyIcon ? <i className={(self.props.companyInfo || {}).currencyIcon}></i> : (self.props.companyInfo || {}).currencyCode + " "}
              
                </Col>
            </Row>
        </div>    */}
           
          </Col>
        </Row>
        <div className="footer">
          <div className="left-fItems">
              <Button className="grey-button" onClick={() => {
                  // this.props.history.goBack();
                  self.props.popModalFromStack()
              }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
          </div>
          <div className="right-fItems">
            <Button className="grey-button" onClick={()=>{
              saveStockTransfer(this.props, this.state, this.gridApi, true);
            }}>
              <FormattedMessage id='button.draft.label' defaultMessage='' />
            </Button>
            <Button className="ant-btn-primary ml10" onClick={() => {
              saveStockTransfer(this.props, this.state, this.gridApi);
            }}>
              <FormattedMessage id='button.save.label' defaultMessage='' />
            </Button>
          </div>
        </div>
      </Skeleton>
    );
  }

};

export default injectIntl(ProductDetails);
