import React, { Fragment } from "react";
import { Row, Col, Button, Input, Popover, Tooltip, Divider,Skeleton } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import ReactHtmlParser from "react-html-parser";

import { Dropdown } from "../../../../general/Dropdown";
import { AddAndRemoveColumn } from "../../../../general/AddAndRemoveColumn";
import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../../static/agGridConstants";
import { AG_GRID_CLASS_CONSTANTS } from "../../../../../static/cssClassConstants";
import {
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  TRANSACTION_COLUMN_INDEX,
} from "../../../../../static/constants";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { showToasterMessage, fixedDecimalNumber, fixedDecimalAndFormateNumber } from "../../../../../utils";
import { CustomTableUpload } from "../../../../general/UploadTable";
import { CUSTOM_CONFIGURATION } from "../../../../../static/ckEditorConfigConstants";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { AWSFileTable } from "../../../../general/AWSFileTable";

class CustomerProductDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      sectionVisible: {
        discount: false,
        tax: false,
        expense: false,
      },
      total: 0,
      subtotal: 0,
      subtotalWithoutDiscount: 0,
      totalDiscount: 0,
      expenseAmount: 0,
      totalTax: 0,
      itemLevelTaxes: [],
      rootDiscountPercent: 0,
      rootDiscountAmount: 0,
    };
  }
 
  

  calculateSubtotal = () => {
    let total = 0;
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = [];
    let subtotalWithoutDiscount = 0;
    let rootDiscountAmount = 0;
    let self = this;
    this.gridApi &&
    this.gridApi.forEachNode(function (rowNode, index) {
        let stringifiedRowData = JSON.stringify(rowNode.data);
        let totalAmountOfColumn = 0;
        const rowdata = JSON.parse(stringifiedRowData);
        if (rowdata.qty && rowdata.rate) {
          let taxObj = {
            subtotal: 0,
            taxPercent: 0,
            taxAmount: 0,
          };
          totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
          subtotalWithoutDiscount =
            Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
          if (rowdata.discount) {
            let discountAmount =
              Number(rowdata.discount || 0) * 0.01 * totalAmountOfColumn;
            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            totalDiscount = totalDiscount + discountAmount;
          }

          if (self.state.rootDiscountPercent) {
            let itemLevelDiscountAmount =
              Number(self.state.rootDiscountPercent) *
              0.01 *
              totalAmountOfColumn;
            totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
            totalDiscount = totalDiscount + itemLevelDiscountAmount;
            rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
          }

          if (rowdata.tax) {
            taxObj.taxPercent = fixedDecimalNumber(Number(rowdata.tax || 0));
            taxObj.taxName = rowdata.taxName;
            let taxAmount =
              Number(rowdata.tax || 0) * 0.01 * totalAmountOfColumn;
            totalTax = totalTax + taxAmount;
          }
          if (taxObj.taxPercent) {
            taxObj.subtotal = fixedDecimalNumber(
              Number(totalAmountOfColumn || 0)
            );
            const availableTaxObj = find(itemLevelTaxes, {
              taxPercent: taxObj.taxPercent,
            });
            if (availableTaxObj) {
              itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                if (ele.taxPercent === taxObj.taxPercent) {
                  ele.subtotal =
                    Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                  ele.taxAmount =
                    Number(ele.taxPercent || 0) *
                    0.01 *
                    Number(ele.subtotal || 0);
                }
                return true;
              });
            } else {
              taxObj.taxAmount = fixedDecimalNumber(
                Number(taxObj.taxPercent || 0) *
                  0.01 *
                  Number(taxObj.subtotal || 0)
              );
              itemLevelTaxes.push(taxObj);
            }
          }
          subtotal = Number(subtotal) + totalAmountOfColumn;
        }
      });

    total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;

    this.setState({
      total: fixedDecimalNumber(Number(total || 0)),
      subtotal: fixedDecimalNumber(Number(subtotal || 0)),
      totalDiscount: fixedDecimalNumber(Number(totalDiscount || 0)),
      totalTax: fixedDecimalNumber(Number(totalTax || 0)),
      subtotalWithoutDiscount: fixedDecimalNumber(
        Number(subtotalWithoutDiscount || 0)
      ),
      itemLevelTaxes,
      rootDiscountAmount,
    });
  };

  handleNewTaxAddition = (props, payload) => {
    const formData = payload.formData;
    this.gridApi && this.gridApi.stopEditing();
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.newTax" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.NEW_TAX,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        this.gridApi && this.gridApi.stopEditing();
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id="addItem.text.uom" defaultMessage="" />;
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue?.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue?.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    this.gridApi && this.gridApi.stopEditing();
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        deleteFunc = props.deleteUOM;
        break;
      }
      case MODAL_TYPE.NEW_TAX: {
        deleteFunc = props.deleteTax;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };
 
  render() {
   
    let self = this;
    if (
      this.gridApi &&
      this.props.priceTypeToApply &&
      this.props.isToPriceTypeToApply
    ) {
      this.gridApi.forEachNode(function (rowNode, index) {
        if (rowNode.data.product) {
          let rate =
            self.props.priceTypeToApply ===
            AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0]
              ? fixedDecimalNumber(
                  Number(rowNode.data.variant.retailPrice || 0)
                )
              : fixedDecimalNumber(
                  Number(rowNode.data.variant?.wholesalePrice || 0)
                );
          rowNode.setData({ ...rowNode.data, rate, baseRate: rate });
        }
      });
      this.props.updateState({
        isToPriceTypeToApply: false,
      });
    }

    const _isColumnToHide = (columnName) => {
      // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
      //     return true;
      // }
      return this.props.txColumns.indexOf(columnName) < 0 &&
        this.props.txMandatoryColumns.indexOf(columnName) < 0
        ? true
        : false;
    };

    const saveColumnIndex = (columnState) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.tablePreference) {
        gridPreference = JSON.parse(data.tablePreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data);
    };

    const saveColumnWidth = (columnState, width) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data, this.props);
    };

    let moveEvent = {};
    const onColumnMoved = (event, isDragStopped) => {
      if (event.column) {
        moveEvent = event;
      }
      if (
        isDragStopped &&
        moveEvent.column &&
        moveEvent.column.colDef &&
        moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API
      ) {
        saveColumnIndex(moveEvent.columnApi.getColumnState());
      }
    };

    const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
      const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
      event.api.resetRowHeights();
      //const gridApi = tablegridApi || this.gridApi;
      if (
        event.type === "columnResized" &&
        event.source !== "sizeColumnsToFit" &&
        event.source !== "api" &&
        event.finished === true
      ) {
        //event.api.resetRowHeights();
        if (
          gridColumnApi &&
          gridColumnApi.columnController.bodyWidth <
            gridColumnApi.columnController.scrollWidth
        ) {
          this.gridApi.sizeColumnsToFit();
          // const columnArray = event.columnApi.getColumnState();
          // for (let i = (columnArray.length - 1); i >= 0; i--) {
          //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
          //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
          //         break;
          //     }
          // }
          // saveColumnWidth(columnArray);
          setTimeout(() => {
            const columnArray = event.columnApi.getColumnState();
            saveColumnWidth(columnArray);
          }, 1000);
        } else {
          saveColumnWidth(event.columnApi.getColumnState());
        }
      }
    };

    const onModelUpdated = (params) => {
      if (this.props.tableDataReRendered) {
        setTimeout(() => {
          this.calculateSubtotal();
        }, 1500);
        params.api.resetRowHeights();
        this.props.updateState({
          tableDataReRendered: false,
        });
        let actionIndex = 0;
        let amountIndex = 0;
        let txColumnSetting =
          this.props.txColumnSetting && this.props.txColumnSetting?.length
            ? this.props.txColumnSetting
            : params.columnApi.getColumnState();
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_QUOTE_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.lockPosition = true;
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "amount") {
            e.hide = false;
            amountIndex = index;
            e.lockPosition = true;
            e.pinned = "right";
          }
          if (columnName === "action") {
            actionIndex = index;
            e.width = 52;
            e.hide = false;
            e.pinned = "right";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        params.columnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
              this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      }
    };

    const onDataRendered = (params) => {
      let txColumnSetting =
        this.props.txColumnSetting && this.props.txColumnSetting?.length
          ? this.props.txColumnSetting
          : params.columnApi.getColumnState();
      let actionIndex = 0;
      let amountIndex = 0;
      if (Object.keys(txColumnSetting)?.length) {
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_QUOTE_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "action") {
            e.width = 52;
            actionIndex = index;
            e.hide = false;
            e.pinned = "right";
          }
          if (columnName === "amount") {
            e.hide = false;
            e.lockPosition = true;
            amountIndex = index;
            e.pinned = "right";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        this.gridColumnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
              this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      } else {
        this.gridApi.sizeColumnsToFit();
      }
    };

    const content = <AddAndRemoveColumn {...this.props} />;

    // const { txColumnSetting } = this.props;
    const { sectionVisible, itemLevelTaxes } = this.state;
    const isPlaceOfSupplySameAsRelationship =
      (this.props.relationshipBillingAddress || {}).stateName ===
      this.props.placeOfSupply;
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      onDataRendered(params);
    };

    const columnDefs = [
      {
        headerComponentFramework: () => {
          return "#";
        },
        cellRendererFramework: (params) => {
          return params.node.rowIndex + 1;
        },
        field: "sno",
        rowDrag: true,
        colId: "sno_1",
        minWidth: 70,
        maxWidth: 70,
        suppressMovable: true,
        lockPosition: true,
        sortable: true,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.product"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
        resizable: true,
        canAddNew: true,
        editable: !this.props.isRFQConversion,
        sortable: true,
        minWidth: 120,
        suppressMovable: true,
        permissions: this.props.permissions,
        moduleName: MODULE_CODED_VALUES.PRODUCTS,
        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PRODUCT),
        cellEditor: "customTreeDropDown",
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight >=
                (params.node.data.maxHeight || 30)
            ) {
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
              this.gridApi && this.gridApi.onRowHeightChanged();
            } else {
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
            }
          }, 500);
          return ReactHtmlParser(params.data.product);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            options: this.props.products,
            onSearch: this.props.onSearch,
            onAddNewClick: () => {
              self.props.updateHeaderState({
                inventory: {
                  inventoryDrawerVisible: true,
                },
              });
            },
            canAddNew: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.PRODUCTS,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            value: obj.node.data.selectedProductValue || [],
            selectedLabel: obj.node.data.product || "",
            allowClear: true,
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.selectedProductValue =
                obj.node.selectedProductValue;
              obj.node.data.productObj = null;
              obj.node.data.variant = null;
              const itemId =
                obj.node.data.selectedProductValue &&
                obj.node.data.selectedProductValue[0];
              if (itemId) {
                const variantId =
                  obj.data.selectedProductValue &&
                  obj.data.selectedProductValue?.length === 2
                    ? obj.data.selectedProductValue[1]
                    : 0;
                obj.node.data.productObj =
                  find(this.props.products, { itemId: Number(itemId) }) || {};
                obj.node.data.variant = variantId
                  ? find(obj.node.data.productObj.inventoryItemVariantList, {
                      variantId: Number(variantId),
                    })
                  : obj.node.data.productObj.inventoryItemVariantList[0];
                if (obj.node.data.productObj.shortDescription) {
                  obj.node.data.description =
                    obj.node.data.productObj.shortDescription;
                }
              }
              if (
                this.props.priceTypeToApply &&
                obj.node.data.variant &&
                !this.props.isDataToUpdate
              ) {
                const rate =
                  this.props.priceTypeToApply ===
                  AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD
                    .PRICE_TYPE_LIST[0]
                    ? fixedDecimalNumber(
                        Number(obj.node.data.variant.retailPrice || 0)
                      )
                    : fixedDecimalNumber(
                        Number(obj.node.data.variant?.wholesalePrice || 0)
                      );
                obj.node.data.baseRate = Number(rate);
                obj.node.data.rate = Number(rate);
                //obj.node.setDataValue('specialDiscount', 0);
                //obj.node.setDataValue('baseRate', Number(rate));
                //obj.node.setDataValue('rate', Number(rate));
                //obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                obj.node.data.uom = obj.node.data.variant.uomName;
                if (
                  obj.node.data.productObj.isPopulateTax &&
                  !_isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX)
                ) {
                  const defaultItemHsnAndTax =
                    obj.node.data.productObj.inventoryItemDefaultTaxList[0] ||
                    {};
                  obj.node.data.hsnCode = (defaultItemHsnAndTax || {}).hsnCode;
                  let selectedTax = {};
                  if (defaultItemHsnAndTax.taxSingleRateId) {
                    selectedTax =
                      find(this.props.taxes, {
                        taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId,
                      }) || {};
                  } else {
                    selectedTax =
                      find(this.props.taxes, {
                        taxPercent:
                          (defaultItemHsnAndTax || {}).igstRate ||
                          (defaultItemHsnAndTax || {}).cgstRate ||
                          (defaultItemHsnAndTax || {}).globleTaxRate,
                      }) || {};
                  }
                  obj.node.data.taxName = selectedTax.taxName;
                  obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                  obj.node.data.tax = selectedTax.taxPercent;
                  obj.node.data.taxId = selectedTax.taxSingleRateId;
                }
              }
              let isDuplicateItem = false;
              this.gridApi &&
                this.gridApi.forEachNode((rowNode, index) => {
                  const { data } = rowNode;
                  if (
                    data.product === selectedValue &&
                    index !== obj.node.rowIndex
                  ) {
                    isDuplicateItem = true;
                  }
                });
              if (isDuplicateItem) {
                showToasterMessage({
                  messageType: "warning",
                  description: "Duplicate product is selected",
                });
              }

              obj.node.data.isDuplicateItem = isDuplicateItem;
              obj.api.refreshCells();
              setTimeout(() => {
                this.gridApi && this.gridApi.stopEditing();
              }, 100);
              const payload = { relationshipId: this.props.companyInfo?.relationshipId};

              this.props.fetchProducts(payload)
            },
          };
        },
        cellClassRules: {
          "ag-grid-cell-warning-boundary": function (obj) {
            let isDuplicateItem = false;
            self.gridApi &&
              self.gridApi.forEachNode((rowNode, index) => {
                const { data } = rowNode;
                if (
                  data.product &&
                  data.product === obj.node.data.product &&
                  obj.node.rowIndex !== index
                ) {
                  isDuplicateItem = true;
                }
              });
            return isDuplicateItem;
          },
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.description"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
        cellClass: " ag-cell-description",
        colId: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD + "_1",
        editable: true,
        sortable: true,
        resizable: true,
        minWidth: 120,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DESCRIPTION),
        cellEditor: "CKEditorGrid",
        cellEditorParams: (params) => {
          return {
            value: params.node.data.description || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true,
              });
            },
          };
        },
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight > 30 &&
              params.reactContainer.scrollHeight >=
                (params.node.data.minHeight || 0)
            ) {
              params.eParentOfValue.style.height = "inherit";
              params.eParentOfValue.style.height =
                params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500);
          return ReactHtmlParser(params.data.description);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (
            params.event.target.scrollHeight > (params.data.minHeight || 30)
          ) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${
                params.node.data.minHeight || 30
              }px`;
            }
            params.node.setRowHeight(params.node.data.minHeight || 30);
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.qty"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.QUANTITY + "_1",
        editable: !this.props.isRFQConversion,
        minWidth: 100,
        maxWidth: 120,
        suppressMovable: true,
        sortable: true,
        resizable: true,
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength:
              (this.props.companyInfo || {}).decimalDigitsLengthQty || 2,
            onChange: (value) => {
              obj.node.data.qty = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.uom"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
        editable: true,
        resizable: true,
        sortable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.uomList,
            optionKeyName: "uomId",
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM);
            },
            valueKeyName: "uomName",
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.uomName = selectedValue;
            },
            allowClear: true,
            onClear: () => {
              //obj.node.setDataValue(obj.colDef.field, undefined);
              obj.node.data.uomName = undefined;
            },
          };
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.hsn"
              defaultMessage=""
            />
          );
        },
        field: "hsnCode",
        editable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        sortable: true,
        resizable: true,
        hide:
          _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HSN) ||
          (this.props.companyInfo || {}).countryName !== "India",
        colId: GRID_PREFERENCE_CONSTANTS.HSN + "_1",
        cellEditor: "customTextEditor",
        cellEditorParams: (obj) => {
          return {
            onChange: (value) => {
              obj.node.data.hsnCode = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              {" "}
              <span>MRP</span>
              <Tooltip
                placement="right"
                title={this.props.intl.formatMessage({
                  id: "tooltip.originalRate",
                  defaultMessage: "",
                })}
                trigger="click"
              >
                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
              </Tooltip>
            </div>
          );
        },
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.BASE_PRICE),
        colId: GRID_PREFERENCE_CONSTANTS.BASE_PRICE + "_1",
        resizable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        field: "baseRate",
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.rate"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.RATE + "_1",
        resizable: true,
        editable: true,
        minWidth: 110,
        maxWidth: 140,
        suppressMovable: true,
        sortable: true,
        cellEditor: "customNumberEditor",
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            onChange: (value) => {
              obj.node.data.baseRate = value;
              obj.node.data.rate = value;
              obj.api.refreshCells();
            },
          };
        },
        valueGetter: (params) => {
          if (
            params.data.specialDiscount &&
            params.data.rate &&
            Number(params.data.rate) !== 0
          ) {
            params.data.rate =
              params.data.baseRate -
              params.data.specialDiscount * 0.01 * params.data.baseRate;
          }
          if (!params.data.specialDiscount && params.data.baseRate) {
            params.data.rate = params.data.baseRate;
          }
          return (
            params.data.rate &&
            fixedDecimalNumber(Number(params.data.rate || 0))
          );
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.specialDiscount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
        editable: true,
        sortable: true,
        minWidth: 110,
        maxWidth: 130,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT),
        colId: GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT + "_1",
        resizable: true,
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
        
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            maxValue: 100,
            onChange: (value) => {
              obj.node.data.specialDiscount = value;
              obj.api.refreshCells();
            },
          };
          },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.discount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
        editable: true,
        sortable: true,
        minWidth: 110,
        maxWidth: 130,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DISCOUNT),
        colId: GRID_PREFERENCE_CONSTANTS.DISCOUNT + "_1",
        resizable: true,
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            maxValue: 100,
            onChange: (value) => {
              obj.node.data.discount = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.tax"
              defaultMessage=""
            />
          );
        },
        field: "taxApplied",
        editable: true,
        sortable: true,
        minWidth: 120,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX),
        colId: GRID_PREFERENCE_CONSTANTS.TAX + "_1",
        resizable: true,
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: true,
            items: this.props.taxes,
            optionKeyName: "taxSingleRateId",
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.TAXES,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                taxName: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewTaxAddition(
                this.props,
                payload,
                MODAL_TYPE.NEW_TAX
              );
            },
            valueKeyName: "taxNameDisplay",
            onSelect: (selectedValue, option) => {
              const selectedTax = find(this.props.taxes, {
                taxSingleRateId: Number(option.key),
              });
              obj.node.data.tax = selectedTax.taxPercent;
              obj.node.data.taxName = selectedTax.taxName;
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.taxId = selectedTax.taxSingleRateId;
              obj.api.refreshCells();
            },
            allowClear: true,
            onClear: () => {
              obj.node.data.tax = null;
              obj.node.data.taxId = null;
              obj.node.data.taxApplied = null;
              obj.node.data.taxName = null;
              this.calculateSubtotal();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesOrder.form.gridHeader.comment"
                defaultMessage=""
              />
              <Tooltip
                placement="top"
                title={this.props.intl.formatMessage({
                  id: "tooltip.warehouseDetail",
                  defaultMessage: "",
                })}
                trigger="click"
              >
                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
              </Tooltip>
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD,
        editable: true,
        sortable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.COMMENT),
        colId: GRID_PREFERENCE_CONSTANTS.COMMENT + "_1",
        resizable: true,
        cellEditor: "CKEditorGrid",
        cellEditorParams: (params) => {
          return {
            value: params.node.data.comment || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true,
              });
            },
          };
        },
        cellRendererFramework: (params) => {
          return ReactHtmlParser(params.data.comment);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (params.event.target.scrollHeight > 30) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${30}px`;
            }
            params.node.setRowHeight(30);
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesQuote.form.gridHeader.partNumber"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.PART_NUMBER + "_1",
        resizable: true,
        editable: true,
        sortable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PART_NUMBER),
        cellEditor: "customTextEditor",
      },

      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage id="common.materialNumber" defaultMessage="" />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.MATERIAL_NUMBER_FIELD,
        editable: true,
        sortable: true,
        //minWidth: 115,
        //maxWidth: 130,
        suppressMovable: true,
        resizable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER),
        colId: GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER + "_1",
        cellEditor: "customTextEditor",
      },

      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesQuote.form.gridHeader.stockNumber"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.STOCK_NUMBER_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER + "_1",
        editable: true,
        resizable: true,
        sortable: true,
        minWidth: 120,
        maxWidth: 140,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER),
        cellEditor: "customTextEditor",
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesQuote.form.gridHeader.origin"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD,
        editable: true,
        sortable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.ORIGIN),
        colId: GRID_PREFERENCE_CONSTANTS.ORIGIN + "_1",
        resizable: true,
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.countries,
            optionKeyName: "countryId",
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            valueKeyName: "countryName",
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.origin = selectedValue;
            },
            allowClear: true,
            onClear: () => {
              //obj.node.setDataValue(obj.colDef.field, undefined);
              obj.node.data.origin = undefined;
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesQuote.form.gridHeader.hsCode"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.HS_CODE_FIELD,
        editable: true,
        sortable: true,
        minWidth: 100,
        maxWidth: 120,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HS_CODE),
        colId: GRID_PREFERENCE_CONSTANTS.HS_CODE + "_1",
        resizable: true,
        cellEditor: "customTextEditor",
      },

      {
        headerComponentFramework: () => {
          return (
           "Taxable Amount"
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD_WITHOUTTAX,
        colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD_WITHOUTTAX + "_1",
        resizable: true,
        // pinned: "right",
        // lockPosition: true,
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0;
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount =params.data.discount * 0.01 * totalAmountOfColumn;
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
           
            this.calculateSubtotal();
          }
          return totalAmountOfColumn
            ? fixedDecimalAndFormateNumber(totalAmountOfColumn)
            : "";
        },
       
      },

      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.amount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD + "_1",
        resizable: true,
        lockPosition: true,
        pinned: "right",
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0;
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount =
                params.data.discount * 0.01 * totalAmountOfColumn;
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
            if (params.data.tax) {
              totalAmountOfColumn =
                params.data.tax * 0.01 * totalAmountOfColumn +
                totalAmountOfColumn;
            }
            this.calculateSubtotal();
          }
          return totalAmountOfColumn
            ? fixedDecimalNumber(totalAmountOfColumn)
            : "";
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <div className="cursor-pointer">
              <Popover
                content={content}
                trigger={"click"}
                title={<div style={{ marginLeft: 35 }}>Add/Remove </div>}
                placement="leftTop"
              >
                <i className="fa fa-bars" />
              </Popover>
            </div>
          );
        },
        minWidth: 50,
        maxWidth: 50,
        suppressMovable: true,
        colId: "action_1",
        field: "action",
        lockPosition: true,
        pinned: "right",
        cellRenderer: "customActionEditor",
        suppressNavigable: true,
        cellClass: this.props.isRFQConversion ? "custom-readonly-cell" : "",
        cellRendererParams: (params) => {
          return {
            onClick: () => {
              this.gridApi.updateRowData({
                remove: [
                  this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex)
                    .data,
                ],
              });
              if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                this.gridApi.updateRowData({ add: [{}] });
              }
              this.calculateSubtotal();
            },
          };
        },
      },
    ];

    const createQuote = (isDraft) => {
    
      let errorKey = "";

      if (!this.props.customer || !this.props.customer.customerId) {
        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: "missingCustomer.errorMsg",
            defaultMessage: "",
          }),
        });
      }

      self.gridApi &&
        self.gridApi.forEachNode((rowNode, index) => {
          let { data } = rowNode;

          if (index === 0 && !data.product && !data.description) {
            errorKey = "missingProduct.errorMsg";
          } else if (index === 0 && !Number(data.qty || 0)) {
            errorKey = "missingQty.errorMsg";
          } else if (index === 0 && !Number(data.rate || 0)) {
            errorKey = "missingRate.errorMsg";
          }
        });

      if (errorKey && !isDraft) {
        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: errorKey,
            defaultMessage: "",
          }),
        });
      }

      // this.gridApi.forEachNode((rowNode, index) => {
      //     let { data } = rowNode;
      //     if (index === 0 && (!(Number(data.rate || 0)) || !(Number(data.qty || 0)))) {
      //         isRequiredFiledMissing = true;
      //     } else if (!data.product && !(Number(data.qty || 0))) {
      //         data = {};
      //     } else if ((!data.product && !data.description) || !(Number(data.qty || 0))) {
      //         isRequiredFiledMissing = true;
      //     }
      // });

      // if ((isRequiredFiledMissing && !isDraft) || !(this.props.customer && this.props.customer.customerId)) {
      //     return showToasterMessage({
      //         messageType: 'error', description: 'Please select Customer, Product/Description, Quantity and Rate'
      //     });
      // }

      // if(!isDraft){

      //     // return showToasterMessage({
      //     //     messageType : 'error',
      //     //     description : errorMsg
      //     // })

      //     return showToasterMessage({messageType : 'error', description : this.props.intl.formatMessage({id : errorKey, defaultMessage : ''})})
      // }

      const modalData = {
        modalBody: (
          <FormattedMessage
            id="customer.salesQuote.form.confirmation"
            defaultMessage=""
          />
        ),
        handleSubmit: () => {
          const payload = {
            isPlaceOfSupplySameAsRelationship:
              (this.props.relationshipBillingAddress || {}).stateName ===
              this.props.placeOfSupply,
            props: this.props,
            gridObject: this.gridApi,
            fileToUpload: this.state.fileToUpload,
            state: this.state,
            isDraft: isDraft,
          };
          if (isDraft) {
            this.props.createSalesQuoteDraft(payload);
          } else {
            this.props.createSalesQuote(payload);
          }
          this.props.hideModal();
        },
      };
      this.props.showModal(modalData);
    };

    if (self.gridColumnApi && self.props.txColumnSetting) {
      Object.keys(self.props.txColumnSetting).forEach(function (key) {
        if (self && self.props.txColumnSetting[key].index) {
          self.gridColumnApi.moveColumn(
            key,
            self.props.txColumnSetting[key].index
          );
        }
      });
    }

    const getRowHeight = (params) => {
      const rowHeight = params.node.data.scrollHeight
        ? Number(params.node.data.scrollHeight.split("px")[0]) - 30
        : 30;
      return rowHeight < 30 ? 30 : rowHeight;
    };
  
    return (
      <Fragment>
        <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 7 }} active>

        <div className="transaction-table">
          {this.props.txColumns?.length ? (
            <CustomAgGrid
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              onModelUpdated={onModelUpdated}
              getRowHeight={getRowHeight}
              onColumnResized={onColumnResized}
              onDragStopped={() => {
                onColumnMoved({}, true);
              }}
              //onColumnMoved={onColumnMoved}
              rowData={this.props.salesQuoteDetails || [{}]}
              gridStyle={{
                width: "100%",
                height: "100%",
                marginBottom: "10px",
              }}
            />
          ) : null}
        </div>
        
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            className="grey-button"
            onClick={() => {
              this.gridApi.updateRowData({ add: [{}] });
            }}
          >
            <FormattedMessage
              id="customer.salesQuote.form.button.addLine"
              defaultMessage=""
            />
          </Button>
          <Button
            className="grey-button"
            onClick={() => {
              this.props.showModal({
                modalBody: "Are you sure you want to clear?",
                handleSubmit: () => {
                  let gridApi = this.gridApi;
                  let self = this;
                  this.gridApi.forEachNode(function (rowNode, index) {
                    gridApi.updateRowData({ remove: [rowNode.data] });
                    if (gridApi.rowRenderer.lastRenderedRow < 0) {
                      gridApi.updateRowData({ add: [{}] });
                    }
                    self.calculateSubtotal();
                  });
                },
              });
            }}
          >
            <FormattedMessage
              id="customer.salesQuote.form.button.clearAllLines"
              defaultMessage=""
            />
          </Button>
        </div>
        
        <div className="upload-btn-so">
          <CustomTableUpload
            fileList={this.props.fileList}
            onChange={(fileList) => {
              this.props.updateState({
                fileList,
              });
            }}
          />
        </div>
        {(this.props.docDetailList||[])?.length ?
                    <>


                        Previous Data
                      
                        <AWSFileTable
                        {...this.props}
                        docDetailList={this.props.docDetailList}
                        fetchTxDetail={(data) => {
                            const val = this.props.sqEditDetail
                            val.docDetailList = []
                            // this.props.updateState({
                            //     sqEditDetail: val
                            // })
                            this.props.fetchSQDetail({
                              ...data,
                              customerId: this.props.sqEditDetail.customerId,
                              salesQuotationMasterId:
                                this.props.sqEditDetail.salesQuotationMasterId,
                              relationshipId: this.props.sqEditDetail.relationshipId,
                            });
                        }}
                      />
                    </>
                    : ""}
        <Divider style={{ margin: "10px 0px" }} />

        <Row>
          <Col span={12}>

            <Row>
              <Col span={24}>
                <FormattedMessage
                  id="customer.salesQuote.form.internalRemarks.label"
                  defaultMessage=""
                />
              </Col>
              <Col span={24}>
                <CKEditor
                  type="inline"
                  className="description-textarea"
                  key={`${"internalRemarks"}`}
                  data={this.props.internalRemarks}
                  onInit={(editor) => {
                    this.editor = editor;
                  editor &&  editor.setData(this.props.internalRemarks || "");
                  }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                    this.props.updateState({ internalRemarks: data });
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION,
                    placeholder: "Enter internal remarks",
                  }}
                />
              </Col>
            </Row>
            <Col span={24}>
              <FormattedMessage
                id="customer.salesQuote.form.customerRemarks.label"
                defaultMessage=""
              />
            </Col>
            <Col span={24}>
              <CKEditor
                type="inline"
                className="description-textarea"
                key={`${"customerRemarks"}`}
                data={this.props.customerRemarks}
                onInit={(editor) => {
                  this.editor = editor;
                  editor &&   editor.setData(this.props.customerRemarks || "");
                }}
                onChange={(event) => {
                  const data = event.editor.getData();
                  this.props.updateState({ customerRemarks: data });
                }}
                config={{
                  ...CUSTOM_CONFIGURATION,
                  placeholder: "Enter customer remarks",
                }}
              />
            </Col>
          </Col>
          <Col span={12}>
            <div className="product-amount-details">
              <Row>
                {sectionVisible.discount ? (
                  <Fragment>
                    <Col span={15}>
                      <span className="label">
                        <FormattedMessage
                          id="customer.salesQuote.form.totalDiscount.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>

                    <Col span={9}>
                      <div className="input-group">
                        <div className="input-label-control">
                          <span className="mark">%</span>
                          <Input
                            type="number"
                            placeholder="0.00"
                            value={this.state.rootDiscountPercent || ""}
                            onChange={(e) => {
                              this.setState({
                                rootDiscountPercent: e.target.value,
                              });
                            }}
                            onBlur={(e) => {
                              if (this.state.subtotal) {
                                this.calculateSubtotal();
                              }
                            }}
                          />
                        </div>
                        <div className="input-label-control">
                          <span className="mark">
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                          </span>
                          <Input
                            disabled
                            placeholder="0.00"
                            type="number"
                            value={this.state.rootDiscountAmount || ""}
                            onChange={(e) => {
                              this.setState({
                                rootDiscountAmount: Number(e.target.value),
                              });
                            }}
                            onBlur={(e) => {
                              if (this.state.subtotal) {
                                this.calculateSubtotal();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Fragment>
                ) : (
                  ""
                )}
              </Row>

              {sectionVisible.tax ? (
                <Fragment>
                  <Row type="flex" justify="end">
                    <Col span={15}>
                      <FormattedMessage
                        id="customer.salesQuote.form.taxName.label"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={9}>
                      <Dropdown
                        items={this.props.taxes}
                        value={this.props.taxName}
                        onSelect={(selectedTax) => {
                          this.props.updateState({
                            taxName: selectedTax,
                          });
                        }}
                        valueKeyName="description"
                        optionKeyName="taxSingleRateId"
                      />
                    </Col>
                  </Row>

                  <Row type="flex" justify="end">
                    <Col span={15}>
                      <span className="label">
                        <FormattedMessage
                          id="customer.salesQuote.form.tax.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>

                    <Col span={9}>
                      <div className="input-group">
                        <div className="input-label-control">
                          <span className="mark">%</span>
                          <Input placeholder="0.00" />
                        </div>
                        <div className="input-label-control">
                          <span className="mark">
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                          </span>
                          <Input placeholder="0.00" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                ""
              )}

              {sectionVisible.expense ? (
                <Fragment>
                  <Row type="flex" className="amount-summary" justify="end">
                    <Col span={15}>
                      <FormattedMessage
                        id="customer.salesQuote.form.totalExpense.label"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={9}>
                      <Dropdown
                        items={this.props.expenseTypes}
                        valueKeyName="expenseType"
                        value={this.props.expenseId}
                        onSelect={(expenseId) => {
                          const selectedExpense = find(
                            this.props.expenseTypes,
                            { lkExpenseTypeId: Number(expenseId) }
                          );
                          this.props.updateState({
                            expenseId,
                            expensName: selectedExpense.expenseType,
                          });
                        }}
                        optionKeyName="lkExpenseTypeId"
                      />
                    </Col>
                  </Row>

                  <Row type="flex" justify="end">
                    <Col span={15}>
                      <span className="label">
                        <FormattedMessage
                          id="customer.salesQuote.form.totalExpenseAmount.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>

                    <Col span={9}>
                      <div className="input-group">
                        <div className="input-label-control">
                          <span className="mark">
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                          </span>
                          <Input
                            placeholder="0.00"
                            type="number"
                            onChange={(e) => {
                              this.setState(
                                {
                                  expenseAmount: Number(e.target.value),
                                },
                                () => {
                                  if (this.state.subtotal) {
                                    this.calculateSubtotal();
                                  }
                                }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                ""
              )}

              <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title">
                      <FormattedMessage
                        id="customer.salesQuote.form.subTotal.label"
                        defaultMessage=""
                      />
                    </span>
                  </Col>
                  <Col span={9}>
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {this.state.subtotalWithoutDiscount}
                  </Col>
                </Row>
              </div>

              {this.state.totalDiscount === "0.00" ||
              this.state.totalDiscount === 0 ? null : (
                <div className="amount-summary">
                  <Row>
                    <Col span={15}>
                      <span className="title">
                        <FormattedMessage
                          id="customer.salesQuote.form.totalDiscount.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>
                    <Col span={9}>
                      -
                      {(this.props.companyInfo || {}).currencyIcon ? (
                        <i
                          className={
                            (this.props.companyInfo || {}).currencyIcon
                          }
                        ></i>
                      ) : (
                        (this.props.companyInfo || {}).currencyCode + " "
                      )}
                      {this.state.totalDiscount}
                    </Col>
                  </Row>
                </div>
              )}

              {/* {
                                false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                                    <div className="w-50">
                                        <span className="title">Tax</span>
                                    </div>
                                    <div className="w-50-default">
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {this.state.totalTax}
                                    </div>
                                </div>
                            } */}

              {itemLevelTaxes?.length === 0
                ? null
                : itemLevelTaxes.map((taxObj, i) => {
                    if (isPlaceOfSupplySameAsRelationship) {
                    }
                    return (this.props.companyInfo || {}).countryName ===
                      "India" ? (
                      isPlaceOfSupplySameAsRelationship ? (
                        <div key={i}>
                          <div className="amount-summary">
                            <Row>
                              <Col span={15}>
                                <span className="title">
                                  CGST @ {taxObj.taxPercent / 2}% on{" "}
                                  {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                </span>
                              </Col>
                              <Col span={9}>
                                {(this.props.companyInfo || {}).currencyIcon ? (
                                  <i
                                    className={
                                      (this.props.companyInfo || {})
                                        .currencyIcon
                                    }
                                  ></i>
                                ) : (
                                  (this.props.companyInfo || {}).currencyCode +
                                  " "
                                )}
                                {/* {fixedDecimalNumber(
                                  Number(taxObj.taxAmount || 0) / 2
                                )} */}
                                {fixedDecimalNumber(Number(taxObj.taxAmount || 0) / 2)}
                              </Col>
                            </Row>
                          </div>

                          <div className="amount-summary">
                            <Row>
                              <Col span={15}>
                                <span className="title">
                                  SGST @{taxObj.taxPercent / 2}% on{" "}
                                  {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                </span>
                              </Col>
                              <Col span={9}>
                                {(this.props.companyInfo || {}).currencyIcon ? (
                                  <i
                                    className={
                                      (this.props.companyInfo || {})
                                        .currencyIcon
                                    }
                                  ></i>
                                ) : (
                                  (this.props.companyInfo || {}).currencyCode +
                                  " "
                                )}
                                {/* {fixedDecimalNumber(
                                  Number(taxObj.taxAmount || 0) / 2
                                )} */}
                                {fixedDecimalNumber(Number(taxObj.taxAmount || 0) / 2)}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        <div key={i}>
                          <div className="amount-summary">
                            <Row>
                              <Col span={15}>
                                <span className="title">
                                  IGST @ {taxObj.taxPercent}% on{" "}
                                  {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                </span>
                              </Col>
                              <Col span={9}>
                                {(this.props.companyInfo || {}).currencyIcon ? (
                                  <i
                                    className={
                                      (this.props.companyInfo || {})
                                        .currencyIcon
                                    }
                                  ></i>
                                ) : (
                                  (this.props.companyInfo || {}).currencyCode +
                                  " "
                                )}
                                {fixedDecimalNumber(
                                  Number(taxObj.taxAmount || 0)
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )
                    ) : (
                      <div key={i}>
                        <div className="amount-summary">
                          <Row>
                            <Col span={15}>
                              <span className="title">
                                {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                                {fixedDecimalNumber(taxObj.subtotal)}{" "}
                              </span>
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {fixedDecimalNumber(
                                Number(taxObj.taxAmount || 0)
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    );
                  })}

              <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title">
                      <FormattedMessage
                        id="customer.salesQuote.form.totalAmount.label"
                        defaultMessage=""
                      />
                    </span>
                  </Col>
                  <Col span={9}>
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {this.state.total}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        {/* <TextArea maxLength={500} value={this.props.customerRemarks || ''} className="description-textarea" rows={4} onChange={(e) => {
                            this.props.updateState({
                                customerRemarks: e.target.value
                            });
                        }} placeholder={intl.formatMessage(
                            { id: 'customer.salesQuote.form.customerRemarks.placeholder', defaultMessage: '' }
                        )}></TextArea> */}

        <CKEditor
          type="inline"
          className="add-footer-textarea"
          key={`${"footer"}`}
          data={this.props.footer}
          onInit={(editor) => {
            this.editor = editor;
            editor.setData(this.props.footer || "");
          }}
          onChange={(event) => {
            const data = event.editor.getData();
            this.props.updateState({ footer: data });
          }}
          config={{ ...CUSTOM_CONFIGURATION, placeholder: "Add Footer Here" }}
        />
<div className="pb40"></div>
        {/* <TextArea maxLength={500} value={this.props.footer || ''} className="add-footer-textarea" onChange={(e) => {
                    this.props.updateState({
                        footer: e.target.value
                    });
                }}
                    rows={1} placeholder="Add Footer Here"></TextArea> */}

        <div className="footer">
          <div className="left-fItems">
            <Button
              className="grey-button"
              
              onClick={() => {
                this.props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    this.props.popModalFromStack();
                  }
                })
              }}
            >
              <FormattedMessage id="button.cancel.label" defaultMessage="" />
            </Button>
          </div>
          <div className="right-fItems">
            {!this.props.update && (
              <Button
                className="grey-button"
                loading={this.props.saveLoading}
                disabled={this.props.saveLoading}
                onClick={() => {
                  createQuote(true);
                }}
              >
                <FormattedMessage id="button.draft.label" defaultMessage="" />
              </Button>
            )}
            <Button
              className="ant-btn-primary"
              loading={this.props.saveLoading}
              disabled={this.props.saveLoading}
              onClick={() => {
                createQuote();
              }}
            >
              <FormattedMessage id="button.save.label" defaultMessage="" />
            </Button>
          </div>
        </div>
        </Skeleton>

      </Fragment>
    );

  }
}

export default injectIntl(CustomerProductDetails);
