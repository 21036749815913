import React from "react";
import { Button, Col, DatePicker, Row, Skeleton } from 'antd';
import './style.scss';
import moment from "moment-timezone";
import { CONSTANTS } from "../../../../../static/constants";

const AWBAssignInfo = (props) => {

    const { shipmentLogisticsInfo={}, awbAssignedDetails={}} = props;

    const awbInfo = awbAssignedDetails?.awb_assign_status === 1 ? awbAssignedDetails?.response?.data : {};
    
    return (<>
        <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>
            {awbAssignedDetails?.awb_assign_status === 1 ? <>
                <Row gutter={[16, 16]}>
                    <Col span={8} offset={4} className="shipping_info_label">
                        Shiprocket Order Id
                    </Col>
                    <Col span={12}>
                        {awbInfo?.order_id || '-'}
                    </Col>
                    <Col span={8} offset={4} className="shipping_info_label">
                        AWB Code
                    </Col>
                    <Col span={12}>
                        {awbInfo?.awb_code || '-'}
                    </Col>

                    <Col span={8} offset={4} className="shipping_info_label">
                        Courier Name
                    </Col>
                    <Col span={12}>
                        {awbInfo?.transporter_name || '-'}
                    </Col>
                    <Col span={8} offset={4} className="shipping_info_label">
                        Courier Service Name
                    </Col>
                    <Col span={12}>
                        {awbInfo?.courier_name || '-'}
                    </Col>

                    <Col span={8} offset={4} className="shipping_info_label">
                        Shipment Charges
                    </Col>
                    <Col span={12}>
                        {awbInfo?.freight_charges || '-'}
                    </Col>

                    <Col span={8} offset={4} className="shipping_info_label">
                        COD
                    </Col>
                    <Col span={12}>
                        {awbInfo?.cod ? 'Yes' : 'No'}
                    </Col>
                    <Col span={8} offset={4} className="shipping_info_label">
                        AWB Status
                    </Col>
                    <Col span={12}>
                        {shipmentLogisticsInfo?.awbStatus || '-'}
                    </Col>
                </Row>
                <Row>
                    <Col span={8} offset={4} className="shipping_info_label">
                        Choose Pickup Date(optional)
                    </Col>
                    <Col span={12}>
                        <DatePicker
                            allowClear={true}
                            format={CONSTANTS.DISPLAY_DATE_FORMAT}
                            key={moment(new Date(), CONSTANTS.DISPLAY_DATE_FORMAT)}
                            defaultValue={props.selectedPickupDate ? moment(new Date(props.selectedPickupDate), CONSTANTS.DISPLAY_DATE_FORMAT) : null}
                            onChange={(selectedDate) => {
                                props.updateState({
                                    selectedPickupDate: selectedDate
                                })
                            }} 
                        />
                    </Col>
                </Row>
                </>
                :
                <div className="success_text">AWB already assigned {shipmentLogisticsInfo?.awbCode ? `having AWB Code - ${shipmentLogisticsInfo?.awbCode} with ${shipmentLogisticsInfo?.carrierName}-${shipmentLogisticsInfo.carrierService}, you can proceed to schedule pickup`: ''}</div>
            }
        </Skeleton>
        <div className="fot-fix" style={{ maxWidth: '100%' }}>
            <div className="text-center">
                <Button 
                    type="primary"
                    oading={props.saveLoading}
                    onClick={()=>{
                        props.updateState({currentStep: props.currentStep+1});
                        const payload = {
                            shipment_id: shipmentLogisticsInfo?.plateformShipmentId || 0,
                            relationshipId: shipmentLogisticsInfo?.relationshipId,
                            logisticsInfoId: shipmentLogisticsInfo?.logisticsInfoId,
                            pickup_date: props?.selectedPickupDate ? props?.selectedPickupDate.format(CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT) : ''
                        }
                        props.scheduleCourierPickup(payload, props)
                    }}
                > 
                    Schedule Pickup
                </Button>
            </div>
        </div>
    </>
    );
}

export default AWBAssignInfo;