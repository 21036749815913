import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Drawer, Row,Skeleton } from "antd";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
const JobRoleDrawer = (props) => {
  const closeDrawer = () => {
    props.updateState({
        jobRoleDrawer: false,
    });
  };
  const handleSubmit = () => {
    props.updateDrawerState({ submitted: true });
    if(!props.jobRoleName ||  !props.jobRoleCode || (props.jobRoleName.toLowerCase() === props.jobRoleCode.toLowerCase())){
      return;
    }
    const payload = {
      relationshipId: props.companyInfo.relationshipId,    
      jobRoleId:props.jobRoleId,          
      jobRoleName: props.jobRoleName,
      jobRoleCode: props.jobRoleCode,
      createdByUserId: props.createdByUserId,
      updatedByUserId: props.updatedByUserId,
      version: 0,
      status:1

  }
    
    const modalData = {     
      modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage=""/>,
      handleSubmit: () => {
        props.createJobRole(payload,props);
       closeDrawer();  },
    };
    props.pushModalToStack(modalData);
  }
  return (
    <Drawer
      title="Create New"
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.jobRoleDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              handleSubmit()
            }         
            }
            key={1}
          />
        </>
      }
    
    >
      <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

      <Row gutter={[16,16]}>
        <Col span={12}>
          <div className="i-label">Job Role Name
          <span className="required">*</span>
          
          </div>
          <TextBox
            placeholder="Job Role Name"
            type="text"
            maxLength={50}
            value={props.jobRoleName}
            onChange={(e) => {
              props.updateDrawerState({ jobRoleName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.jobRoleName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="i-label">Job Role Code
          <span className="required">*</span>
          </div>
          <TextBox
            placeholder="Job Role Code"
            type="text"
            maxLength={20}
            value={props.jobRoleCode}
            onChange={(e) => {
              props.updateDrawerState({ jobRoleCode: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.jobRoleCode }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>

        {props.jobRoleName && props.jobRoleCode && (props.jobRoleName?.toLowerCase() === props.jobRoleCode?.toLowerCase()) ? <Col span={24}>
          <div className="required">
            Job role name and job role code should be unique.
          </div>
        </Col> : ""}
       
      </Row>
      </Skeleton>
    </Drawer>
  );
};

export default JobRoleDrawer;
