import axios from "axios";
import {
  COMMON_ACTIONS,
  INVENTORY_ACTION_LIST,
} from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
import config from "../../../config/environmentConfig";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const fetchstockTransfer = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    let url = `${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/git/getAllGitList?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${(payload.pageNumber || 1) - 1}`;
    const {gitNumber, sourceWarehouse, destinationWarehouse, status} = payload;
      
    url = url + `${gitNumber ? `&gitNumber=${gitNumber}`:''}`
              + `${sourceWarehouse ? `&sourceWarehouse=${sourceWarehouse}`:''}`
              + `${destinationWarehouse ? `&destinationWarehouse=${destinationWarehouse}`:''}`
              + `${status ? `&status=${status}`:''}`

    return axios
      .get(url)
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data,
        };
        dispatch({ type: INVENTORY_ACTION_LIST.STOCK_TRANSFER, data });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  };
};

export const getStockTransferCount = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/git/getTotalGitCount?relationshipId=${payload.relationshipId}${payload.gitNumber ? `&gitNumber=${payload.gitNumber}`:''}`)
      .then((res) => {
        dispatch({ type: INVENTORY_ACTION_LIST.STOCK_TRANSFER_COUNT, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      })
  };
};


export const ApproveStockTransfer = (gitData, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/git/approveGIT`,
        gitData
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "Stock Transfer approved successfully",
        });
        props.fetchstockTransfer && props.fetchstockTransfer({
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: props.pageNumber,
          pageSize: props.pageSize
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      });
  };
};

export const DeleteStockTransfer = (gitData, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/git/deleteGIT`, gitData)
      .then((res) => {
        if(!res.data){
          throw new Error();
        }
        showToasterMessage({
          messageType: "success",
          description: "Stock Transfer approved successfully",
        });
        const payload={
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: props.pageNumber,
          pageSize: props.pageSize
        }
        props.fetchstockTransfer && props.fetchstockTransfer(payload);
        props.getStockTransferCount && props.getStockTransferCount(payload);
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      });
  };
};