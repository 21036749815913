import React, {Fragment} from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col,Skeleton} from 'antd'
import * as find from 'lodash.find';
import ReactHtmlParser from 'react-html-parser';
import { CONSTANTS } from '../../../../../static/constants';
// import { LogoComponent } from '../../../../general/LogoComponent';
import { getMomentDateForUIReadOnly } from '../../../../../utils';
import {AWSFileTable} from '../../../../general/AWSFileTable';


const ShipmentDetails = (props) => {
    const { shipmentData, companyInfo, deliveyNotesData} = props;
    // const relationshipAddress = find(companyInfo.boLocationList, { locationId: parseInt(shipmentData.billingLocationId) }) || {};
    // const shippingAddress = find(customerData.boLocationList, {locationId: parseInt(shipmentData.shippingLocationId)}) || {};

    const relationshipAddress = find((deliveyNotesData || {}).boLocationShipmentList, {locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS}) || {};
    const billingAddress = find((deliveyNotesData || {}).boLocationShipmentList, {locationType: CONSTANTS.BILLING_ADDRESS}) || {};
    const shippingAddress = find((deliveyNotesData || {}).boLocationShipmentList, {locationType: CONSTANTS.SHIPPING_ADDRESS}) || {};
    const additionalInfo = ((deliveyNotesData || {}).additionalInfoList || [])[0] || {}
   // let customerSOPackageDetailsList = [];

    // deliveyNotesData.forEach((e) => {
    //         e.customerSOPackageDetailsList.forEach((ele) => {
    //             ele.salesOrderNumber = e.salesOrderNumber;
    //             ele.packageNumber = e.packageNumber;
    //             ele.packageObj = ele;
    //         });
    //         customerSOPackageDetailsList = customerSOPackageDetailsList.concat(e.customerSOPackageDetailsList);
    //     });

    const getVariantFragment = (item) => {
        let itemName = null;
        if (item.itemName) {
            itemName = item.itemName;
        }
        if (item.itemVariantName) {
            itemName = itemName + ' (' + item.itemVariantName + ')'
        }
        return itemName ? itemName : '';
    }

    return (
        <div className={"custom-modal show"}>
            <i className="close-icon">
                <svg width="17" height="17" onClick={() => { props.popModalFromStack(); }}>
                    <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                    <path fill="#67757d" fillRule="evenodd"
                        d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
                </svg>
            </i>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="modal-header align-left">
                    {deliveyNotesData?.documentName ||'Shipment'}
                    </div>
                    <div className="inner-content">
                        <Row>
                            <Col span={5}>
                                <div className="d-inline-flex w-100">
                                    {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                                    {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()} /> */}
                                    
                                    <div className="read-only-content prl10">
                                        <div className="title lh-adjust pb5">
                                        <div>
                                        { shipmentData.salesOrderNumber?.includes("SOO") ?"":getContactNameFragment(additionalInfo)}

                                            {
                                                shipmentData.salesOrderNumber?.includes("SOO") ?
                                                <>
                                                {billingAddress?.fullName}
                                                {billingAddress?.emailAddress}
                                                {billingAddress?.cellPhone}
                                                </>:""
                                            }
                                              
                                            </div>
                                            {props.companyInfo.storeName}
                                        </div>
                                        {getAddressInfoFragment(relationshipAddress, companyInfo)}
                                    </div>
                                    
                                </div>
                            </Col>

                            <Col span={4} className="read-only-content pr20">

                                <div className="title">
                                    <div className=""><FormattedMessage id='modal.txDetails.common.billTo' defaultMessage='' /></div>
                                    {getContactNameFragment(additionalInfo)}
                                    {shipmentData.companyName}
                                </div>
                                <div>
                                    {getAddressInfoFragment(billingAddress, additionalInfo )}
                                </div>
                                
                            </Col>

                            <Col span={4}>

                              {shippingAddress.streetAddress1 ?  <div className="read-only-content pr20">
                                <div className="title"><FormattedMessage id='modal.txDetails.common.shipTo' defaultMessage='' /></div>
                                    <div className="title">
                                    <div>
                                              
                                              {shippingAddress?.fullName}
                                              {shippingAddress?.emailAddress}
                                              {shippingAddress?.cellPhone}
                                            </div>
                                        {shipmentData.companyName}
                                    </div>
                                    {getAddressInfoFragment(shippingAddress, {})}

                                </div> : '' }
                                
                            </Col>

                            <Col span={5} className="read-only-content">

                                <Row>
                                    <Col span={10} className="title text-right pr10">
                                    <FormattedMessage id='modal.txDetails.shipment.shipmentNumber' defaultMessage='' />
                                    </Col>
                                    <Col span={14}>
                                        {shipmentData.shipmentNumber || '-'}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={10} className="title text-right pr10">
                                    <FormattedMessage id='modal.txDetails.shipment.carrier' defaultMessage='' />
                                    </Col>
                                    <Col span={14}>
                                        {shipmentData.carrierName || '-'}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={10} className="title text-right pr10">
                                    <FormattedMessage id='modal.txDetails.shipment.trackingNumber' defaultMessage='' />
                                    </Col>
                                    <Col span={14}>
                                        {shipmentData.trackingNumber || '-'}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={10} className="title text-right pr10">
                                    <FormattedMessage id='modal.txDetails.shipment.referenceNumber' defaultMessage='' />
                                    </Col>
                                    <Col span={14}>
                                        {shipmentData.referenceNumber || '-'}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={10} className="title text-right pr10">
                                    <FormattedMessage id='modal.txDetails.shipment.freightType' defaultMessage='' /> 
                                    </Col>
                                    <Col span={14}>
                                        {shipmentData.freightType || '-'}
                                    </Col>
                                </Row>
                                
                            </Col>

                            <Col span={6} className="read-only-content">

                                <Row>
                                    <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.shipment.consignee' defaultMessage='' /> </Col>
                                    <Col span={10}>{shipmentData.consigneeName || '-'}</Col>
                                </Row>

                                <Row>
                                    <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.shipment.shipmentDate' defaultMessage='' /> </Col>
                                    <Col span={10}>
                                        {(shipmentData.shipmentDate && getMomentDateForUIReadOnly({date: shipmentData.shipmentDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.shipment.expectedDelivery' defaultMessage='' /></Col>
                                    <Col span={10}>
                                        {(shipmentData.expectedDeliveryDate && getMomentDateForUIReadOnly({date: shipmentData.expectedDeliveryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                    </Col>
                                </Row>
                                
                               {/* {((deliveyNotesData || []).length === 1) &&
                                    <div>
                                     {deliveyNotesData[0].packageNumber &&
                                        <Row>
                                            <Col span={14} className="title text-right">Package #</Col>
                                            <Col span={10}>
                                                {deliveyNotesData[0].packageNumber}
                                            </Col>
                                        </Row>
                                    }
                                    {deliveyNotesData[0].salesOrderNumber &&
                                        <Row>
                                            <Col span={14} className="title text-right">Sales Order #</Col>
                                            <Col span={10}>
                                                {deliveyNotesData[0].salesOrderNumber}
                                            </Col>
                                        </Row>
                                    } 
                                    {deliveyNotesData[0].customerPONumber &&
                                        <Row>
                                            <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.shipment.poNumber' defaultMessage='' /></Col>
                                            <Col span={10}>
                                                {deliveyNotesData[0].customerPONumber}
                                            </Col>
                                        </Row>
                                    }
                                    {soData.projectNumber &&
                                        <Row>
                                            <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.common.project' defaultMessage='' /></Col>
                                            <Col span={10}>
                                                {soData.projectNumber + ' (' + soData.projectName + ')'}
                                            </Col>
                                        </Row>
                                    }
                                    {deliveyNotesData[0].incotermName &&
                                        <Row>
                                            <Col span={14} className="title text-right"><FormattedMessage id='package.packageDetail.incoterm' defaultMessage='' /></Col>
                                            <Col span={10}>
                                                {deliveyNotesData[0].incotermName}
                                            </Col>
                                        </Row>
                                    }
                                    </div>*/}
                            </Col>
                        </Row>

                        <div className="modal-table-container">
                                        <table className="transaction-details-table">
                                            <thead>
                                                <tr>
                                                    <th className="cell-size-50">#</th>
                                                    <th>
                                                    <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' />
                                                        <span> & <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>
                                                    </th>
                                                    <th className="text-center"><FormattedMessage id='modal.txDetails.shipment.packageNumber' defaultMessage='' /></th>
                                                    <th className="text-center"><FormattedMessage id='modal.txDetails.shipment.soNumber' defaultMessage='' /></th>
                                                    <th className="text-center"><FormattedMessage id='modal.txDetails.common.uom' defaultMessage='' /></th>
                                                    <th className="text-center"><FormattedMessage id='modal.txDetails.shipment.qtyOrdered' defaultMessage='' /></th>
                                                    <th className="text-center"><FormattedMessage id='modal.txDetails.shipment.qtyPacked' defaultMessage='' /></th>
                                                    <th className="text-center"><FormattedMessage id='modal.txDetails.shipment.qtyShipped' defaultMessage='' /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(deliveyNotesData.customerSOShipmentDetailsList || []).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, j) => {
                                                    return (
                                                        <tr key={item.customerSOPackageDetailsId}>
                                                            <td className="cell-size-50">{j + 1}</td>
                                                            <td>
                                                            <strong>{getVariantFragment(item)}</strong>
                                                                <div className="description-cell">
                                                                    <pre>{getDescriptionFragment(deliveyNotesData, item, j+1)}</pre>
                                                                </div>
                                                            </td>
                                                            {/* <td>{ReactHtmlParser(item.description || '-')}</td> */}
                                                            <td className="text-center">{item.packageNumber || '-'}</td>
                                                            <td className="text-center">{item.salesOrderNumber || '-'}</td>
                                                            <td className="text-center">{item.uomName || '-'}</td>
                                                            <td className="text-center">{item.quantityOrdered || '-'}</td>
                                                            <td className="text-center">{item.quantityPacked || '-'}</td>
                                                            <td className="text-center">{item.quantity || '-'}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                        
                        
                        {/* {(deliveyNotesData || []).map((packageObj, i) => {
                            return (
                                <div>
                                <div>
                                    { ((deliveyNotesData || []).length > 1) ?
                                        <Row className="separator-border">
                                            <Col span={6}>
                                                <Row className="package-info">
                                                    <Col span={8}><strong>Package #</strong></Col>
                                                    <Col span={16}> <span className="pl10">{packageObj.packageNumber}</span></Col>
                                                </Row>
                                                <Row className="package-info">
                                                    <Col span={8}><strong>Sales Order #</strong></Col>
                                                    <Col span={16}> <span className="pl10">{packageObj.salesOrderNumber}</span></Col>
                                                </Row>
                                            </Col>
                                            <Col span={6} >
                                                {packageObj.customerPONumber &&
                                                    <Row className="package-info">
                                                        <Col span={8}><strong>PO #</strong></Col>
                                                        <Col span={16}> <span className="pl10">{packageObj.customerPONumber}</span></Col>
                                                    </Row>
                                                }
                                                {soData.projectNumber &&
                                                    <Row className="package-info">
                                                        <Col span={8}> <strong>Project</strong></Col>
                                                        <Col span={16}><span className="pl10"> {soData.projectNumber + ' (' + soData.projectName + ')'}</span></Col>
                                                    </Row>
                                                }
                                                 {packageObj.incotermName &&
                                                    <Row className="package-info">
                                                        <Col span={8}><strong>Incoterm</strong></Col>
                                                        <Col span={16}> <span className="pl10">{packageObj.incotermName}</span></Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row> : ''
                                    }

                                   
                                </div>
                                <br/>
                                </div>)
                        })} */}
                        <Row>
                            <Col span={12}>
                                {shipmentData.remarksInternal &&
                                <Col span={15} className="read-only-content pt10">
                                    <Row>
                                        <Col span={8} className="title">
                                        <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage='' />
                                        </Col>
                                        <Col span={16}>
                                            {ReactHtmlParser(shipmentData.remarksInternal)}
                                        </Col>
                                    </Row>
                                </Col>
                                }
                                {shipmentData.remarksCustomer &&
                                <Col span={15} className="read-only-content">
                                    <Row>
                                        <Col span={8} className="title">
                                        <FormattedMessage id='modal.txDetails.common.customerRemarks' defaultMessage='' />
                                        </Col>
                                        <Col span={16}>
                                            {ReactHtmlParser(shipmentData.remarksCustomer)}
                                        </Col>
                                    </Row>
                                </Col>
                                }
                            </Col>
                            </Row>
                            <Row className='mt10'>
                            <Col span={12}>
                                {deliveyNotesData.docDetailList && deliveyNotesData.docDetailList.length ?
                                    <Row>
                                        <Col span={24} className="title">
                                            <FormattedMessage id='modal.txDetails.common.attachments' defaultMessage='' />
                                        </Col>
                                        <Col span={24}>
                                            <AWSFileTable {...props} docDetailList={deliveyNotesData.docDetailList} fetchTxDetail={(data) => {
                                                props.fetchDeliveryNotesDetail({
                                                    relationshipId: companyInfo.relationshipId, 
                                                    customerId: deliveyNotesData.customerId, 
                                                    shipmentMasterId: deliveyNotesData.shipmentMasterId
                                                })
                                            }} />
                                            {/* <table style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>File Name</th>
                                                        <th>Size</th>
                                                        <th>Type</th>
                                                        <th>Upload By</th>
                                                        <th>Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {deliveyNotesData.docDetailList.map((doc, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{doc.fileName}</td>
                                                                <td>{doc.fileSize}</td>
                                                                <td>{doc.fileType}</td>
                                                                <td>{doc.createdByName || ''}</td>
                                                                <td>{doc.dateCreated ? getMomentDateForUIReadOnly({ date: doc.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</td>
                                                                <td><span className={"cursor-pointer"} onClick={() => {
                                                                    props.downloadFileFromBucket({
                                                                        bucketName: doc.bucketName,
                                                                        awsKeyName: doc.awsKeyName,
                                                                        fileName: doc.fileName
                                                                    })
                                                                }}>Download</span></td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table> */}
                                        </Col>
                                    </Row>
                                    : null
                                }
                            </Col>
                        </Row>
                            
                    </div>
                    
                </div>
            </div>
        </div>
    );

}

const getAddressInfoFragment = (obj, additionInfo) => {
    const fragmentObj = <Fragment>
       <div className="billing-address first-caps">
            {obj.streetAddress1 || obj.streetAddress2 ? (

                <div>
                    {obj.streetAddress1} {obj.streetAddress2}{", "}
                </div>

            ) : ""}
            {obj.cityName || obj.stateName || obj.zipCode ? (

                <div>
                    {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                </div>

            ) : ""}
             {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
        </div>
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                     <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                     <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                </div> :
                ''
        }
        {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        }

    </Fragment>

    return fragmentObj;
  }

const getContactNameFragment = (additionInfo) => {
    if (!additionInfo) return <Fragment />;
    let contactName = "";
    if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
    if (additionInfo.firstName) contactName += additionInfo.firstName;
    if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
    if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
    const fragmentObj = <Fragment>
        {
            (contactName && contactName.length)
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}
const getDescriptionFragment = (soDetail, item, index) => {
    if (!item) return <Fragment />;
    const fragmentObj = <Fragment>
        {
            item.materialNumber
                ? <div>
                    <span className="title"><FormattedMessage id='common.materialNumber' defaultMessage='' />:</span>{item.materialNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.stockNumber
                ? <div>
                    <span className="title"><FormattedMessage id='drawer.inventory.customerStockNo' defaultMessage='' />:  </span>{item.stockNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.partNumber
                ? <div>
                    <span className="title"> <FormattedMessage id='drawer.inventory.partNo' defaultMessage='' />: </span>{item.partNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.description
                ? <div>
                    {ReactHtmlParser(item.description || '')}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
         {
            item.origin
                ? <div>
                    <span className="title"> <FormattedMessage id='customer.salesOrder.form.gridHeader.origin' defaultMessage='' /> : </span>{item.origin}
                </div> :
                ''
        }
        {
            item.hsCode
                ? <div>
                    <span className="title"> <FormattedMessage id='customer.salesOrder.form.gridHeader.hsCode' defaultMessage='' /> : </span>{item.hsCode}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
         {
            item.comment
                ? <div>
                    <span className="title"> <FormattedMessage id='inventory.warehouse.myWarehouse.listing.header.warehouseName' defaultMessage='' />: </span>{item.comment}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            (soDetail.customerPONumber)
                ? <div> (
                    {soDetail.customerPONumber ? <span className="title"><FormattedMessage id='common.PO' defaultMessage='' />  {soDetail.customerPONumber} - </span> : ''}
                    {index ? <span className="title"> <FormattedMessage id='common.Line' defaultMessage='' /> {index}</span> : ''}
                )
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
  }

export default ShipmentDetails;
