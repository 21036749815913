import { MODULE_CODED_VALUES } from "../../static/constants";

const sideMasterMenu = [
  // {
  //   menuName: "sidebar.menuItem.dashboard",
  //   iconClassName: "fi fi-rr-dashboard",
  //   path: "/admin/dashboard",
  //   moduleCode: MODULE_CODED_VALUES.ERP_DASHBOARD,
  // },
  {
    menuName: "sidebar.menuItem.account",
    iconClassName: "fi fi-rr-user",
    path:"/admin/account/my-profile",
    moduleCode: [MODULE_CODED_VALUES.ACCOUNT_PROFILE, MODULE_CODED_VALUES.ACCOUNT_SETTINGS, MODULE_CODED_VALUES.ACCOUNT_TEAM_ROLE, MODULE_CODED_VALUES.ACCOUNT_SUBSCRIPTION],
  },
  // {
  //   menuName: "sidebar.menuItem.dashboard",
  //   iconClassName: "fi fi-rr-dashboard",
  //   path: "/admin/school-management/dashboard",
  //   moduleCode: MODULE_CODED_VALUES.SCHOOL_DASHBOARD,
  // },
  // {
  //   menuName: "sidebar.menuItem.dashboard",
  //   iconClassName: "fi fi-rr-dashboard",
  //   path: "/admin/crm-management/dashboard",
  //   moduleCode: MODULE_CODED_VALUES.CRM_CUSTOMER,
  // }, 
   
  {
    menuName: "sidebar.menuItem.dashboard",
    iconClassName: "fi fi-rr-dashboard",
    path: "/admin/dashboard",
    moduleCode: [MODULE_CODED_VALUES.SCHOOL_DASHBOARD, MODULE_CODED_VALUES.ERP_DASHBOARD,MODULE_CODED_VALUES.CRM_CUSTOMER],

    // operation: PERMISSION_VALUES.READ,
   },
  {
    menuName: "sidebar.menuItem.leads",
    iconClassName: "fi fi-rr-rectangle-list",
    childList: [
      {
        menuName: "sidebar.menuItems.LeadCenter",
        iconClassName: "fi fi-rr-street-view",
        childList: [
          {
            menuName: "sidebar.menuItems.Leads",
            path: "/admin/leads/leads",
            moduleCode: MODULE_CODED_VALUES.MY_LEADS,
          },
          // {
          //   menuName: "sidebar.menuItems.MyLeads",
          //   path: "/admin/leads/my-leads",
          //   moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
          // },
          // {
          //   menuName: "uploadImage.variant.label",
          //   path: "/admin/leads/all-leads",
          //   moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
          // },
          {
            menuName: "sidebar.menuItems.Reminder",
            path: "/admin/leads/reminder",
            moduleCode: MODULE_CODED_VALUES.MY_LEADS,
          },
          {
            menuName: "sidebar.menuItems.Opportunity",
            path: "/admin/leads/opportunity",
            moduleCode: MODULE_CODED_VALUES.CRM_OPPORTUNITY,
          },
          {
            menuName: "sidebar.menuItems.Lead.Tasks",
            path: "/admin/leads/lead-tasks",
            moduleCode: MODULE_CODED_VALUES.MY_LEADS,
          },
          {
            menuName: "sidebar.menuItems.Call.Logs",
            path: "/admin/leads/call-logs",
            moduleCode: MODULE_CODED_VALUES.MY_LEADS,
          },
          {
            menuName: "sidebar.menuItems.Lead.Notes",
            path: "/admin/leads/Lead-notes",
            moduleCode: MODULE_CODED_VALUES.MY_LEADS,
          },
          {
            menuName: "sidebar.menuItems.Lead.Transfer",
            path: "/admin/leads/lead-transfer",
            moduleCode: MODULE_CODED_VALUES.CRM_LEAD_TRANSFER,
          }
        ],
      },

      {
        menuName: "sidebar.menuItems.LeadSetting",
        iconClassName: "fi fi-rr-list-check",
        childList: [
          {
            menuName: "sidebar.menuItems.LeadForm",
            iconClassName: "fi fi-rr-form",
            moduleCode: MODULE_CODED_VALUES.LEAD_FROM_SETTING,
            path: "/admin/leads/lead-form",
          },
          {
            menuName: "sidebar.menuItems.FormMeta",
            iconClassName: "fi fi-rr-form",
            moduleCode: MODULE_CODED_VALUES.LEAD_FROM_SETTING,
            path: "/admin/leads/lead-meta",
          },
          {
            menuName: "sidebar.menuItems.Lead.Campaigns",
            iconClassName: "fi fi-rr-form",
            moduleCode: MODULE_CODED_VALUES.LEAD_FROM_SETTING,
            path: "/admin/leads/lead-campaigns",
          },
          {
            menuName: "sidebar.menuItems.Workflows",
            iconClassName: "fi fi-rr-form",
            moduleCode: MODULE_CODED_VALUES.LEAD_FROM_SETTING,
            path: "/admin/leads/lead-workflows",
          }
        ],
      },
    ],
  },

  // {
  //   menuName: "sidebar.menuItem.customer",
  //   iconClassName: "fi fi-br-users-alt",
  //   childList: [
  //     {
  //       menuName: "sidebar.menuItem.customerInformation",
  //       iconClassName: "fi fi-rr-id-card-clip-alt",
  //       childList: [
  //         {
  //           menuName: "sidebar.menuItem.customerProfile",
  //           iconClassName: "fi fi-rr-users-alt",
  //           path: "/admin/customers-list",
  //           moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
  //         }
  //       ],
  //     },
  //     // {
  //     //     menuName : 'sidebar.menuItem.taskManagement',
  //     //     iconClassName : 'fi fi-rr-note',
  //     //     moduleCode: MODULE_CODED_VALUES.SCHOOL_TEACHER,
  //     //     childList:[
  //     //         {
  //     //             menuName : 'All Task',
  //     //             path:'/admin/crm/task-management/all-task',
  //     //             moduleCode: MODULE_CODED_VALUES.SCHOOL_TEACHER,
  //     //         },
  //     //         {
  //     //             menuName : 'Pending Task',
  //     //             path:'/admin/crm/task-management/pending-task',
  //     //             moduleCode: MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
  //     //         },
  //     //         {
  //     //             menuName : 'Follow-up Tasks',
  //     //             path:'/admin/crm/task-management/follow-up',
  //     //             moduleCode: MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
  //     //         }
  //     //     ]
  //     // }
  //   ],
  // },

  {
    menuName: "sidebar.menuItem.school",
    iconClassName: "fi fi-rs-school",
    childList: [
      {
        menuName: "sidebar.menuItem.registrations",
        iconClassName: "fi fi-rr-money-check-edit",
        path: "/admin/school-management/form-registration-list",
        moduleCode: MODULE_CODED_VALUES.EDUCATION_FORM_REGISTRATION,
      },
      {
        menuName: "sidebar.menuItem.students",
        iconClassName: "fi fi-rr-id-card-clip-alt",
        childList: [
          {
            menuName: "sidebar.menuItem.studentsProfile",
            path: "/admin/school-management/student",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_STUDENT,
          },
          {
            menuName: "sidebar.menuItem.attendance",
            path: "/admin/school-management/attendance",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
          },
          // {
          //   menuName: "sidebar.menuItem.documents",
          //   rightIconClass: "",
          //   path: "/admin/document-list",
          //   moduleCode: MODULE_CODED_VALUES.SCHOOL_DOCUMENT,
          //   operation: PERMISSION_VALUES.READ,
          //   childList: [],
          // },
        ],
      },
      {
        menuName: "sidebar.menuItem.teachers",
        iconClassName: "fi fi-rr-chalkboard-user",
        childList: [
          {
            menuName: "sidebar.menuItem.teacherProfile",
            path: "/admin/school-management/teachers",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_TEACHER,
          },
          // {
          //   menuName: "sidebar.menuItem.teacherAttendance",
          //   rightIconClass: "",
          //   path: "/admin/school-management/teachers-attendance",
          //   moduleCode: MODULE_CODED_VALUES.SCHOOL_TEACHER_ATTENDANCE,
          //   operation: PERMISSION_VALUES.READ,
          //   childList: [],
          // }
        ],
      },
      // {
      //   menuName: "sidebar.menuItem.parents",
      //   iconClassName: "fi fi-rr-users",
      //   moduleCode: MODULE_CODED_VALUES.SCHOOL_PARENTS,
      //   childList: [
      //     {
      //       menuName: "sidebar.menuItem.parentsInfo",
      //       path: "/admin/school-management/parent",
      //       moduleCode: MODULE_CODED_VALUES.SCHOOL_PARENTS,
      //     },
      //   ],
      // },
      {
        menuName: "sidebar.menuItem.class",
        iconClassName: "fi fi-rr-backpack",
        childList: [
          {
            menuName: "sidebar.menuItem.classInfo",
            path: "/admin/school-management/class",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
          },
          {
            menuName: "sidebar.menuItem.section",
            path: "/admin/school-management/section",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
          },
        ],
      },
      {
        menuName: "sidebar.menuItem.curriculum",
        iconClassName: "fi fi-rs-e-learning",
        childList: [
          {
            menuName: "sidebar.menuItem.course",
            path: "/admin/school-management/course",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
          },
          {
            menuName: "sidebar.menuItem.subject",
            path: "/admin/school-management/subject",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
          },
          {
            menuName: "sidebar.menuItem.term",
            path: "/admin/school-management/course-term",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
          },
          {
            menuName: "sidebar.menuItem.program",
            path: "/admin/school-management/department",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
          }
        ],
      },
      {
        menuName: "sidebar.menuItem.assessment",
        iconClassName: "fi fi-rr-blog-pencil",
        childList: [
          {
            menuName: "sidebar.menuItem.assignment",
            path: "/admin/school-management/assignment",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS,
          },
        ],
      },
      {
        menuName: "sidebar.menuItem.feesManagement",
        iconClassName: "fi fi-rs-file-invoice-dollar",
        path: "/admin/school-management/invoice-list",
        moduleCode: MODULE_CODED_VALUES.SCHOOL_RECURRING_PAYMENTS,
      },
      {
        menuName: "sidebar.menuItem.timeTable",
        iconClassName: "fi fi-rr-calendar-star",
        path: "/admin/school-management/time-table",
        moduleCode: MODULE_CODED_VALUES.SCHOOL_TIME_TABLE,
      },
      {
        menuName: "sidebar.menuItem.forms",
        iconClassName: "fi fi-rs-form",
        path: "/admin/school-management/form-list",
        moduleCode: MODULE_CODED_VALUES.SCHOOL_FORM,
      },
    ],
  },

  {
    menuName: "sidebar.menuItem.sales",
    iconClassName: "fi fi-rr-basket-shopping-simple",
    childList: [
      {
        menuName: "sidebar.menuItem.allCustomer",
        iconClassName: "fi fi-rr-users-alt",
        path: "/admin/customers",
        moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
      },
      {
        menuName: "sidebar.menuItem.inquiry",
        iconClassName: "fi fi-rr-memo-circle-check",
        path: "/admin/sales-inquiry-list",
        moduleCode: MODULE_CODED_VALUES.SALES_INQUIRY,
      },
      {
        menuName: "sidebar.menuItem.salesQuote",
        iconClassName: "fi fi-sr-block-quote",
        path: "/admin/sq-list",
        moduleCode: MODULE_CODED_VALUES.SALES_QUOTE,
      },
      {
        menuName: "sidebar.menuItem.salesOrder",
        iconClassName: "fi fi-rr-basket-shopping-simple",
        path: "/admin/so-list",
        moduleCode: MODULE_CODED_VALUES.SALES_ORDER,
      },
      {
        menuName: "sidebar.menuItem.package",
        iconClassName: "fi fi-rs-box-open",
        path: "/admin/package-list",
        moduleCode: MODULE_CODED_VALUES.SALES_ORDER_PACKAGE,
      },
      {
        menuName: "sidebar.menuItem.shipment",
        iconClassName: "fi fi-rs-truck-side",
        path: "/admin/shipment-list",
        moduleCode: MODULE_CODED_VALUES.SALES_ORDER_SHIPMENT,
      },
      {
        menuName: "sidebar.menuItem.salesInvoice",
        iconClassName: "fi fi-rr-receipt",
        path: "/admin/sales-invoice-list",
        moduleCode: MODULE_CODED_VALUES.SALES_INVOICE,
      },
      {
        menuName: "sidebar.menuItem.payments",
        iconClassName: "fi fi-rr-credit-card",
        path: "/admin/customer-payment-list",
        moduleCode: MODULE_CODED_VALUES.CUSTOMER_PAYMENT,
      },
      {
        menuName: "sidebar.menuItem.creditMemo",
        iconClassName: "fi fi-rr-memo-pad",
        path: "/admin/credit-memo-list",
        moduleCode: MODULE_CODED_VALUES.CREDIT_MEMO,
      },
      {
        menuName: "sidebar.menuItem.couponManagement",
        iconClassName: "fi fi-rr-memo-pad",
        path: "/admin/coupon-management",
        moduleCode: MODULE_CODED_VALUES.COUPON_MANAGEMENT,
      },
      {
          menuName : 'sidebar.menuItem.salesSupport',
          iconClassName : 'fi fi-rr-life-ring',
          path: '/admin/sales-complaint-list',
          moduleCode: MODULE_CODED_VALUES.SALES_SUPPORT,
      },
      // {
      //     menuName : 'sidebar.menuItem.registeredMembers',
      //     iconClassName : 'fi fi-rr-book-user',
      //     path: '/admin/student-registration-list',
      //     moduleCode: MODULE_CODED_VALUES.REGISTERED_MEMBER,
      // }
    ],
  },

  {
    menuName: "sidebar.menuItem.purchase",
    iconClassName: "fi fi-rr-shopping-cart-add",
    childList: [
      {
        menuName: "sidebar.menuItem.suppliers",
        iconClassName: "fi fi-rr-shopping-cart-add",
        path: "/admin/suppliers",
        moduleCode: MODULE_CODED_VALUES.SUPPLIERS,
      },
      {
        menuName: "sidebar.menuItem.purchaseRequest",
        iconClassName: "fi fi-rs-file-invoice",
        path: "/admin/purchase-request-list",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_REQUEST,
      },
      {
        menuName: "sidebar.menuItem.RFQ",
        iconClassName: "fi fi-sr-block-quote",
        path: "/admin/rfq-list",
        moduleCode: MODULE_CODED_VALUES.RFQ,
      },
      {
        menuName: "sidebar.menuItem.purchaseOrder",
        iconClassName: "fi fi-rs-cart-arrow-down",
        path: "/admin/purchase-order-list",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_ORDER,
      },
      {
        menuName: "sidebar.menuItem.inboundDelivery",
        iconClassName: "fi fi-rs-note",
        path: "/admin/inbound-delivery-list",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_GRN,
      },
      {
        menuName: "sidebar.menuItem.purchaseInvoice",
        iconClassName: "fi fi-rr-receipt",
        path: "/admin/purchase-invoice-list",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_INVOICE,
      },

      {
        menuName: "sidebar.menuItem.purchasepayment",
        iconClassName: "fi fi-rr-credit-card",
        path: "/admin/supplier-payment-list",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_PAYMENT,
      },
      {
        menuName: "sidebar.menuItem.debitMemo",
        iconClassName: "fi fi-rr-memo-pad",
        path: "/admin/debit-memo-list",
        moduleCode: MODULE_CODED_VALUES.DEBIT_MEMO,
      },
      {
        menuName: "sidebar.menuItem.landedCostSetup",
        iconClassName: "fi fi-rr-sack-dollar",
        path: "/admin/landed-cost-setup",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_LANDED_COST,
      },
      {
        menuName: "sidebar.menuItem.landedCost",
        iconClassName: "fi fi-rr-api",
        path: "/admin/landed-cost",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_LANDED_COST,
      },
    ],
  },

  {
    menuName: "sidebar.menuItem.cloudDrive",
    iconClassName: "fi fi-rr-folder",
    path: "/admin/cloud-drive",
    moduleCode: MODULE_CODED_VALUES.CLOUD_DRIVE,
  },

  {
    menuName: "sidebar.menuItem.communication",
    iconClassName: "fi fi-rr-paper-plane",
    childList: [
      {
        menuName: "sidebar.menuItem.broadcast",
        iconClassName: "fi fi-rs-broadcast-tower",
        path: "/admin/school-management/broadcast-message",
        moduleCode: MODULE_CODED_VALUES.SCHOOL_BROADCAST_MESSAGE,
      },
      {
        menuName: "sidebar.menuItem.newsletter",
        iconClassName: "fi fi-rr-envelope-open-text",
        path: "/admin/news-letter-listing",
        moduleCode: MODULE_CODED_VALUES.NEWS_LETTER,
      },
      // {
      //   menuName: "sidebar.menuItem.events",
      //   iconClassName: "fi fi-rr-calendar-star",
      //   path: "/admin/events",
      //   moduleCode: MODULE_CODED_VALUES.EVENTS,
      // },
      {
        menuName: "sidebar.menuItem.contact",
        iconClassName: "fi fi-rr-phone-call",
        path: "/admin/communication/contact",
        moduleCode: MODULE_CODED_VALUES.ALL_CONTACT,
      },
      {
        menuName: "sidebar.menuItem.emailLogs",
        iconClassName: "fi fi-rr-envelope",
        path: "/admin/school-management/communication",
        moduleCode: MODULE_CODED_VALUES.COMMUNICATION_EMAIL_LOGS,
      },
      {
        menuName: "sidebar.menuItem.distributionGroup",
        iconClassName: "fi fi-rr-users-alt",
        path: "/admin/communication/dg",
        moduleCode: MODULE_CODED_VALUES.COMMUNICATION_DG_GROUP,
      },
      {
        menuName : 'sidebar.menuItem.communicationTemplate',
        iconClassName: "fi fi-rr-envelope",
        path:"/admin/communication/communication-template",
        moduleCode: MODULE_CODED_VALUES.COMMUNICATION_TEMPLATE_SETTING,
      }
      // {
      //     menuName : 'sidebar.menuItem.account',
      //     iconClassName : 'fi fi-rr-user-add',
      //     path: '/admin/account',
      //     moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
      // }
    ],
  },

  {
    menuName: "sidebar.menuItem.products",
    iconClassName: "fi fi-rr-box-open",
    childList: [
      {
        menuName: "sidebar.menuItem.products",
        iconClassName: "fi fi-rr-boxes",
        path: "/admin/products",
        moduleCode: MODULE_CODED_VALUES.PRODUCTS,
      },

      {
        menuName: "sidebar.menuItem.productPrice",
        iconClassName: "fi fi-rs-tags",
        path: "/admin/product-price",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_PRICE,
      },
      {
        menuName: "sidebar.menuItem.priceList",
        iconClassName: "fi fi-rr-file-invoice-dollar",
        path: "/admin/price-list",
        moduleCode: MODULE_CODED_VALUES.PRICE_LIST,
      },
      {
        menuName: "sidebar.menuItem.category",
        iconClassName: "fi fi-rr-apps",
        path: "/admin/extras/product-category",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_CATEGORY,
      },
      {
        menuName: "sidebar.menuItem.hsn",
        iconClassName: "fi fi-rs-cube",
        path: "/admin/show-HSN",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_HSN,
      },
      {
        menuName: "sidebar.menuItem.manufactures",
        iconClassName: "fi fi-rr-industry-windows",
        path: "/admin/extras/product-manufacturer",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_MANUFACTURER,
      },
      {
        menuName: "sidebar.menuItem.brands",
        iconClassName: "fi fi-rr-tags",
        path: "/admin/extras/product-brands",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_BRAND,
      },
      {
        menuName: "sidebar.menuItem.uom",
        iconClassName: "fi fi-rr-ruler-combined",
        path: "/admin/extras/UOM",
        moduleCode: MODULE_CODED_VALUES.UOM,
      },
      {
        menuName: "sidebar.menuItem.openingQuantity",
        iconClassName: "fi fi-rs-cube",
        path: "/admin/extras/opening-quantity",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_OPENING_QUANTITY,
      },
      {
        menuName: "sidebar.menuItem.rating",
        iconClassName: "fi fi-rr-star-comment-alt",
        path: "/admin/extras/review",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_RATING_REVIEW,  
      }
    ],
  },
  {
    menuName: "sidebar.menuItem.inventory",
    iconClassName: "fi fi-rr-boxes",
    childList: [
      {
        menuName: "sidebar.menuItem.myWarehouse",
        iconClassName: "fi fi-rr-warehouse-alt",
        path: "/admin/warehouse/my-warehouse",
        moduleCode: MODULE_CODED_VALUES.MY_WAREHOUSE,
      },

      {
        menuName: "sidebar.menuItem.stockSummary",
        iconClassName: "fi fi-rs-document-signed",
        path: "/admin/warehouse/stock-summary",
        moduleCode: MODULE_CODED_VALUES.STOCK_SUMMARY,
      },
      {
        menuName: "sidebar.menuItem.stockAdjustment",
        iconClassName: "fi fi-rr-objects-column",
        path: "/admin/warehouse/stock-adjustment",
        moduleCode: MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
      },
      {
        menuName: "sidebar.menuItem.inventoryValuation",
        iconClassName: "fi fi-rr-sack",
        path: "/admin/warehouse/all-inventory-valuation",
        moduleCode: MODULE_CODED_VALUES.INVENTORY_VALUATION,
      },
      {
        menuName: "sidebar.menuItem.agedStock",
        iconClassName: "fi fi-rr-time-fast",
        path: "/admin/warehouse/aged-stock",
        moduleCode: MODULE_CODED_VALUES.AGED_STOCK,
      },
      {
        menuName: "sidebar.menuItem.stock.InTransit",
        iconClassName: "fi fi-rr-truck-moving",
        path: "/admin/stock-intransit",
        moduleCode: MODULE_CODED_VALUES.STOCK_TRANSFER,
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.hrms",
    iconClassName: "fi fi-rr-users",
    childList: [
      {
        menuName: "sidebar.menuItem.employee",
        iconClassName: "fi fi-rr-comment-user",
        path: "/admin/employee-management/all-employee",
        moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE,
      },
      // {
      //   menuName: "sidebar.menuItem.orgHierarchy",
      //   iconClassName: "fi fi-rs-chart-tree",
      //   path: "/admin/company-hierarchy-list",
      //   moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
      // },
      {
        menuName: "sidebar.menuItem.project",
        iconClassName: "fi fi-rs-briefcase",
        path: "/admin/projects",
        moduleCode: MODULE_CODED_VALUES.PROJECT,
      },
      {
        menuName: "Employee Meta",
        iconClassName: "fi fi-rr-mode-portrait",
        path: "/admin/employee-meta",
        moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE,
      },

      {
        menuName: "sidebar.menuItems.employeeManagement",
        iconClassName: "fi fi-rr-comment-user",
        childList: [
          {
            menuName: "sidebar.menuItems.employee",
            path: "/admin/employee-management/all-employee",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE,
          },
          {
            menuName: "sidebar.menuItems.warning",
            path: "/admin/employee-management/warning",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE,
          },
          {
            menuName: "sidebar.menuItems.promotion",
            path: "/admin/employee-management/promotion",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE,
          },
          {
            menuName: "sidebar.menuItems.termination",
            path: "/admin/employee-management/termination",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE,
          },
         
        ],
      },

      {
        menuName: "Leave Management",
        iconClassName: "fi fi-rr-mode-portrait",
        childList: [
          {
            menuName: "Leave Meta",
            iconClassName: "fi fi-rr-mode-portrait",
            path: "/admin/leave-management/leave-meta",
            moduleCode: MODULE_CODED_VALUES.HRMS_LEAVE_MANAGEMENT,
          },
          {
            menuName: "Leave Management",
            iconClassName: "fi fi-rr-mode-portrait",
            path: "/admin/leave-management",
            moduleCode: MODULE_CODED_VALUES.HRMS_LEAVE_MANAGEMENT,
          },
        ]
      },
     
      {
        menuName: "Attendance Management",
        iconClassName: "fi fi-rr-comment-user",
        childList: [
          {
            menuName: "sidebar.menuItems.attendanceSummary",
            path: "/admin/attendance-management/attendance-summary",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
          },
          {
            menuName: "Attendance Report",
            path: "/admin/attendance-management/attendance-report",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
          },
          {
            menuName: "Attendance Meta",
            path: "/admin/attendance-management/attendance-meta",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
          },
          {
            menuName: "sidebar.menuItems.attendanceIp",
            path: "/admin/attendance-management/attendance-ip",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
          },
        ],
      },
      // {
      //   menuName: "Payroll Management",
      //   iconClassName: "fi fi-rr-money-check-edit",
      //   childList: [
      //     {
      //       menuName: "Payroll Meta",
      //       iconClassName: "fi fi-rr-file-invoice",
      //       path: "/admin/payroll-meta",
      //       moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT,
      //     },
      //     {
      //       menuName: "sidebar.menuItems.allowance",
      //       path: "/admin/payroll-management/allowance",
      //       moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT,
      //     },
      //     {
      //       menuName: "sidebar.menuItems.deduction",
      //       path: "/admin/payroll-management/deduction",
      //       moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT,
      //     },
      //     {
      //       menuName: "Pay Grade",
      //       path: "/admin/payroll-management/pay-grade",
      //       moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT,
      //     },
      //     {
      //       menuName: "sidebar.menuItems.monthlyPayGrade",
      //       path: "/admin/payroll-management/monthlyPayGrade",
      //       moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT,
      //     },
      //     {
      //       menuName: "sidebar.menuItems.hourlyPayGrade",
      //       path: "/admin/payroll-management/hourlyPayGrade",
      //       moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT,
      //     },
      //     {
      //       menuName: "sidebar.menuItems.salarySheet",
      //       path: "/admin/payroll-management/salarySheet",
      //       moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT,
      //     },
      //     {
      //       menuName: "sidebar.menuItems.paymentHistory",
      //       path: "/admin/payroll-management/paymentHistory",
      //       moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT,
      //     },
      //     {
      //       menuName: "sidebar.menuItems.myPayroll",
      //       path: "/admin/payroll-management/myPayroll",
      //       moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT,
      //     },
      //     {
      //       menuName: "sidebar.menuItems.bonus",
      //       path: "/admin/payroll-management/bonus",
      //       moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT,
      //     },
      //   ],
      // },
      {
        menuName: "Employee Service",
        iconClassName: "fi fi-rr-user-crown",
        childList: [
          {
            menuName: "Profile",
            iconClassName: "fi fi-rr-file-invoice",
            path: "/admin/employee-services/profile",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES,
          },
          {
            menuName: "Leave Requests",
            iconClassName: "fi fi-rr-file-invoice",
            path: "/admin/employee-services/leave-request",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES,
          },
          {
            menuName: "Expense Claims",
            iconClassName: "fi fi-rr-file-invoice",
            path: "/admin/employee-services/expense-claims",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES,
          },
          {
            menuName: "Performance Reviews",
            iconClassName: "fi fi-rr-file-invoice",
            path: "/admin/employee-services/performance-reviews",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES,
          },
          {
            menuName: "sidebar.menuItems.myAttendance",
            path: "/admin/employee-services/my-attendance",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES,
          },
        ],
      },
      // {
      //   menuName: "Training & Development",
      //   iconClassName: "fi fi-rr-chart-user",
      //   rightIconClass: "",
      //   path: "/admin/payroll-meta",
      //   moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_TRAINING,
      //   childList: [],
      // },
      // {
      //   menuName: "Asset Management",
      //   iconClassName: "fi fi-rs-computer-speaker",
      //   rightIconClass: "",
      //   path: "/admin/payroll-meta",
      //   moduleCode: MODULE_CODED_VALUES.HRMS_ASSET,
      //   childList: [],
      // },
    ],
  },

  {
    menuName: "sidebar.menuItem.fundraising",
    iconClassName: "fi fi-rr-piggy-bank",
    childList: [
      {
        menuName: "sidebar.menuItem.fundraising-campaign",
        iconClassName: "fi fi-rr-megaphone",
        path: "/admin/fundraising-campaign",
        moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
      },
      {
        menuName: "sidebar.menuItem.pledges",
        iconClassName: "fi fi-br-bookmark",
        path: "/admin/fundraising-pledges",
        moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
      },
      {
        menuName: "sidebar.menuItem.donations",
        iconClassName: "fi fi-rr-credit-card",
        path: "/admin/fundraising-donation",
        moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.accounting",
    iconClassName: "fi fi-rr-usd-circle",
    childList: [
      {
        menuName: "sidebar.menuItem.businessExpense",
        iconClassName: "fi fi-rs-wallet",
        path: "/admin/business-expense-listing",
        moduleCode: MODULE_CODED_VALUES.BUSINESS_EXPENSE,
      },
      {
        menuName: "sidebar.menuItem.banking",
        iconClassName: "fi fi-rr-bank",
        path: "/admin/banking",
        moduleCode: MODULE_CODED_VALUES.BANKING,
      },
      {
        menuName: "sidebar.menuItem.dayBook",
        iconClassName: "fi fi-rr-book-open-cover",
        path: "/admin/dayBook",
        moduleCode: MODULE_CODED_VALUES.BANKING_BOOKS,
      },
      {
        menuName: "sidebar.menuItem.cashBook",
        iconClassName: "fi fi-rs-hands-usd",
        path: "/admin/cashbook",
        moduleCode: MODULE_CODED_VALUES.BANKING_BOOKS,
      },
      {
        menuName: "sidebar.menuItem.bankBook",
        iconClassName: "fi fi-rs-file-invoice-dollar",
        path: "/admin/bankbook",
        moduleCode: MODULE_CODED_VALUES.BANKING_BOOKS,
      },
      {
        menuName: "sidebar.menuItem.journalReport",
        iconClassName: "fi fi-rr-book-open-cover",
        path: "/admin/journal-report",
        moduleCode: MODULE_CODED_VALUES.REPORTS,
      },
      {
        menuName: "sidebar.menuItem.billwiseDetail",
        iconClassName: "fi fi-rs-receipt",
        path: "/admin/bill-wise-detail",
        moduleCode: MODULE_CODED_VALUES.BANKING,
      },
      {
        menuName: "sidebar.menuItem.invoiceDetail",
        iconClassName: "fi fi-rs-file-invoice",
        path: "/admin/gst-filling",
        moduleCode: MODULE_CODED_VALUES.GST_FILLING,
      },
      {
        menuName: "sidebar.menuItem.leadgerAccounts",
        iconClassName: "fi fi-rs-book-font",
        path: "/admin/ledger-accounts",
        moduleCode: MODULE_CODED_VALUES.LEDGER_ACCOUNTS,
      },
      {
        menuName: "sidebar.menuItem.balanceSheet",
        iconClassName: "fi fi-rs-memo",
        path: "/admin/balance-sheet",
        moduleCode: MODULE_CODED_VALUES.BALANCE_SHEET,
      },
      {
        menuName: "sidebar.menuItem.profitLoss",
        iconClassName: "fi fi-rs-chart-histogram",
        path: "/admin/profit-loss",
        moduleCode: MODULE_CODED_VALUES.PROFIT_LOSS,
      },
      {
        menuName: "sidebar.menuItem.trialBalance",
        iconClassName: "fi fi-rr-donate",
        path: "/admin/trial-balance",
        moduleCode: MODULE_CODED_VALUES.TRIAL_BALANCE,
      },
      {
        menuName: "sidebar.menuItem.openingBalance",
        iconClassName: "fi fi-rr-hand-holding-usd",
        path: "/admin/opening-balance",
        moduleCode: MODULE_CODED_VALUES.OPENING_BALANCE,
      },
      {
        menuName: "sidebar.menuItem.journals",
        iconClassName: "fi fi-rr-notebook-alt",
        path: "/admin/show-journals",
        moduleCode: MODULE_CODED_VALUES.JOURNALS,
      },
      {
        menuName: "sidebar.menuItem.bankReconciliation",
        iconClassName: "fi fi-rr-shuffle",
        path: "/admin/bankReconciliation",
        moduleCode: MODULE_CODED_VALUES.BANK_RECONCILIATION,
      },
      {
        menuName: "sidebar.menuItem.taxes",
        iconClassName: "fi fi-ss-earnings",
        path: "/admin/show-taxes",
        moduleCode: MODULE_CODED_VALUES.TAXES,
      },

      {
        menuName: "sidebar.menuItem.financialYears",
        iconClassName: "fi fi-rr-business-time",
        path: "/admin/show-fy-years",
        moduleCode: MODULE_CODED_VALUES.FINANCIAL_YEARS,
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.reports",
    iconClassName: "fi fi-rr-file-medical-alt",
    childList: [
      {
        menuName: "sidebar.menuItem.financialReports",
        iconClassName: "fi fi-rr-file-invoice-dollar",
        path: "/admin/show-reports",
        moduleCode: MODULE_CODED_VALUES.FINANCIAL_REPORTS,
      },
      {
        menuName: "sidebar.menuItem.salesReports",
        iconClassName: "fi fi-rr-ticket",
        path: "/admin/salesReports",
        moduleCode: MODULE_CODED_VALUES.SALES_REPORTS,
      },
      {
        menuName: "sidebar.menuItem.purcahseReports",
        iconClassName: "fi fi-rr-ticket",
        path: "/admin/salesReports",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_REPORTS,
      },
      {
        menuName: "sidebar.menuItem.warehouseReports",
        iconClassName: "fi fi-rr-ticket",
        path: "/admin/salesReports",
        moduleCode: MODULE_CODED_VALUES.WAREHOUSE_REPORTS,
      },
      {
        menuName: "sidebar.menuItem.inventoryReports",
        iconClassName: "fi fi-rr-box-open-full",
        path: "/admin/inventoryReports",
        moduleCode: MODULE_CODED_VALUES.INVENTORY_REPORTS,
      },
      {
        menuName: "sidebar.menuItem.leadReports",
        iconClassName: "fi fi-rr-street-view",
        path: "/admin/leadReports",
        moduleCode: MODULE_CODED_VALUES.LEAD_REPORTS,
      },
      {
        menuName: "sidebar.menuItem.CRMReports",
        iconClassName: "fi fi-rr-users-medical",
        path: "/admin/show-reports",
        moduleCode: MODULE_CODED_VALUES.CRM_REPORTS,
      },
    ],
  },
  {

    menuName: "sidebar.menuItem.integrations",
    iconClassName: "fi fi-rr-folder",
    moduleCode: [MODULE_CODED_VALUES.INTEGRATIONS_PAYMENT, MODULE_CODED_VALUES.INTEGRATION_ACCOUNTING, MODULE_CODED_VALUES.INTEGRATION_MARKETPLACE, MODULE_CODED_VALUES.INTEGRATION_SHIPPING, MODULE_CODED_VALUES.INTEGRATION_EMAIL],
    path: "/admin/integrations",
    // childList: []
    // [
    //   {
    //     menuName: "sidebar.menuItem.marketplace",
    //     iconClassName: "fi fi-rs-shop",
    //     childList: [
    //       {
    //         menuName: "Amazon",
    //         iconClassName: "fi fi-brands-cc-amazon-pay",
    //         path: "/admin/sales-channels-amazon",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //       {
    //         menuName: "Ebay",
    //         iconClassName: "fi fi-brands-ebay",
    //         path: "/admin/sales-channels-ebay",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //       {
    //         menuName: "Walmart",
    //         iconClassName: "fi fi-brands-walmart",
    //         path: "/admin/sales-channels-walmart",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //       {
    //         menuName: "Shopify",
    //         iconClassName: "fi fi-brands-shopify",
    //         path: "/admin/sales-channels-shopify",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //       {
    //         menuName: "Carrefour",
    //         iconClassName: "fi fi-rr-shop",
    //         path: "/admin/sales-channels-carrefour",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //       {
    //         menuName: "Noon",
    //         iconClassName: "fi fi-bs-shop",
    //         path: "/admin/sales-channels-noon",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //     ],
    //   },
    //   {
    //     menuName: "sidebar.menuItem.food",
    //     iconClassName: "fi fi-rr-restaurant",
    //     childList: [
    //       {
    //         menuName: "Careem",
    //         iconClassName: "fi fi-rr-restaurant",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //       {
    //         menuName: "Talabat",
    //         iconClassName: "fi fi-rr-room-service",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //       {
    //         menuName: "Zomato",
    //         iconClassName: "fi fi-rr-utensils",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //       {
    //         menuName: "Delivero",
    //         iconClassName: "fi fi-rr-salad",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //       {
    //         menuName: "Hunger Station",
    //         iconClassName: "fi fi-rr-hamburger",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //       },
    //     ],
    //   }
    // ],
  },
  {
    menuName: "sidebar.menuItem.eStore",
    iconClassName: "fi fi-rr-computer",
    childList: [
      {
        menuName: "Appearance",
        iconClassName: "fi fi-rr-palette",
        path: "/admin/e-store/appearance",
        childList: [
          {
            menuName: "sidebar.menuItem.appearance.themes",
            path: "/admin/e-store/themes",
            moduleCode: MODULE_CODED_VALUES.E_STORE_APPEARANCE,
          },
          {
            menuName: "sidebar.menuItem.appearance.page",
            path: "/admin/e-store/pages",
            moduleCode: MODULE_CODED_VALUES.E_STORE_APPEARANCE,
          },
          {
            menuName: "sidebar.menuItem.appearance.menus",
            path: "/admin/e-store/menu",
            moduleCode: MODULE_CODED_VALUES.E_STORE_APPEARANCE,
          }
        ],
      },
      {
        menuName: "Blogs",
        iconClassName: "fi fi-rr-blog-pencil",
       
        childList: [
          {
            menuName: "sidebar.menuItem.blogs.blog",
            path: "/admin/e-store/blogs",
            moduleCode: MODULE_CODED_VALUES.E_STORE_BLOGS_MGMT,
          },
          {
            menuName: "sidebar.menuItem.blogs.category",
            path: "/admin/e-store/category",
            moduleCode: MODULE_CODED_VALUES.E_STORE_BLOGS_MGMT,
          },
        ],
     
      },
      {
        menuName: "Testimonials",
        iconClassName: "fi fi-rr-star-comment-alt",
        path: "/admin/testimonials",
        moduleCode: MODULE_CODED_VALUES.E_STORE_TESTIMONIALS,
      },
      {
        menuName: "Product Management",
        iconClassName: "fi fi-rr-box-open",
        path: "/admin/published-stock",
        moduleCode: MODULE_CODED_VALUES.E_STORE_PRODUCT_MGMT,
      },
      {
        menuName: "sidebar.menuItem.storeSettings",
        iconClassName: "fi fi-rs-gears",
        path: "/admin/e-store/store-settings",
        moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.emailMarketing",
    iconClassName: "fi fi-rs-envelopes",
    childList: [
      {
        menuName: "sidebar.menuHeading.email",
        iconClassName: "fi fi-rr-envelope-bulk",
        childList: [
          {
            menuName: "sidebar.subMenuItem.emailCampaigns",
            path: "/admin/email/email-campaigns",
            moduleCode: MODULE_CODED_VALUES.MARKETING_EMAIL,
          },
          {
            menuName: "sidebar.subMenuItem.emailTemplate",
            path: "/admin/email/email-template",
            moduleCode: MODULE_CODED_VALUES.MARKETING_EMAIL,
          },
        ],
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.settings",
    iconClassName: "fi fi-rr-settings",
    path: "/admin/show-settings",
    moduleCode: MODULE_CODED_VALUES.SETTINGS,
  }
];

export default sideMasterMenu;
