import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, DONATION_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const saveDonationSetting = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/donationSettings/saveDonationSettings`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: lang[res.data.message || 'saved.successfully'] });
        props.popModalFromStack();
       // props.fetchRegistrationFormSettingList({...payload, pageSize: 100, pageNumber: 0})
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } 
      })
  }
}

export const fetchDonationSetting = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: DONATION_ACTION_LIST.RESET_DONATION_SETTING });
    let source = 'kiosk' ;
    return axios
      .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/donationSettings/getDonationSettings?relationshipId=${payload.relationshipId}&source=${source}`)
      .then(res => {
        const { data } = res
        dispatch({ type: DONATION_ACTION_LIST.DONATION_SETTING, data })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = DONATION_ACTION_LIST.DONATION_SETTING;
        }
      })
    .finally(() => {
        dispatch({
           type: DONATION_ACTION_LIST.DONATION_SETTING_LOADING,
           data: { isDonationSettingLoaded: true },
        });
      })
  }
}

export const resetDonationSetting = () => {
  return dispatch => {
    dispatch({ type: DONATION_ACTION_LIST.RESET_DONATION_SETTING });
  }
}

export const resetKioskDonationSetting = () => {
  return dispatch => {
    dispatch({ type: DONATION_ACTION_LIST.RESET_DONATION_SETTING });
  }
}

export const resetDonationLoading = () => {
  return dispatch => {
    dispatch({ type: DONATION_ACTION_LIST.RESET_DONATION_LOADING });
  }
}