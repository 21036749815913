import { E_STORE } from "../static/constants";

const initialState = {
    allPageListData: [],
    pagePublishedData: [],
    policyPageData: {},
    ecomDomainData: {},
    couponList: { 1: [] },
    couponCount: 0,
    couponUsageList: {},
    couponUsageCount: 0,
    blogList: {
        1: [],
    },
    blogListCount: 0,
    categoryListCount: 0,
    blogCategoryList: {
        1: [],
    },
    testimonialList: {
        1: [],
    },
    testimonialListCount: 0,
    bannerSetting: {}

}

const PageListReducer = (state = initialState, action) => {
    switch (action.type) {
        case E_STORE.ALL_PAGE_LIST:
            return { ...state, allPageListData: action.data };
        case E_STORE.PUBLISHED_PAGE_DATA:
            return { ...state, pagePublishedData: action.data };
        case E_STORE.POLICY_PAGE_DATA:
            return { ...state, policyPageData: action.data };
        case E_STORE.ECOMSUBDOMAIN_ID:
            return { ...state, ecomDomainData: action.data };
        case E_STORE.GET_COUPON_LIST:
            return {
                ...state, couponList: {
                    ...state.couponList,
                    [action.data.pageNumber || 1]: action.data.list
                }
            };
        case E_STORE.GET_COUPON_COUNT:
            return { ...state, couponCount: action.data };
        case E_STORE.COUPON_USAGE_LIST:
            return {
                ...state, couponUsageList: {
                    ...state.couponUsageList,
                    [action.data.pageNumber || 1]: action.data.list
                }
            };
        case E_STORE.COUPON_USAGE_COUNT:
            return { ...state, couponUsageCount: action.data };
        case E_STORE.BLOG_LIST:
            return {
                ...state, blogList: { ...state.blogList, [action.data.pageNo || 1]: action.data.list },
            };
        case E_STORE.BLOG_COUNT:
            return {
                ...state, blogListCount: action.data,
            };
        case E_STORE.BLOG_CATEGORY_LIST:
            return {
                ...state, blogCategoryList: { ...state.blogCategoryList, [action.data.pageNo || 1]: action.data.list },
            };
        case E_STORE.CATEGORY_COUNT:
            return {
                ...state, categoryListCount: action.data,
            };
        case E_STORE.TESTIMONIAL_LIST:
            return {
                ...state, testimonialList: {
                    ...state.testimonialList,
                    [action.data.pageNumber || 1]: action.data.list
                }
            };
        case E_STORE.TESTIMONIAL_COUNT:
            return {
                ...state, testimonialListCount: action.data,
            };
        case E_STORE.BANNER_SETTING:
            return { ...state, bannerSetting: action.data };
        default:
            return state;
    }
};


export default PageListReducer;