import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../../utils";
import { COMMON_ACTIONS, COMMUNICATION_ACTION_LIST } from "../../../../../static/constants";
const { lang } = require("../../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");    
    
    
    export const fetchTotalSend = (payload, props) => {
        return (dispatch) => {
        
          return axios
            .post(
              `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextBrevo}/metrics/getEmailMetricsByStatuses?relationshipId=${payload.relationshipId}`,["sent"])
            .then((res) => {
           
              
                dispatch({ type: COMMUNICATION_ACTION_LIST.SEND_STATUS_DETAILS, data: res.data });
            })
            .catch((err) => {
              showToasterMessage({
                description:
                  lang[((err.response || {}).data || "")] ||
                  "Some error occurred",
              });
            });
        };
      };
  