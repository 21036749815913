import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const saveOrUpdateDocApproval = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/documentApproval/saveOrUpdateDocApproval`, payload)
      .then(res => {
       dispatch({ type: COMMON_ACTIONS.DOCUMENT_LIST_ADDITION, data: res.data });
        showToasterMessage({
          description: (res || {}).message || 'Saved successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
          showToasterMessage({
            description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const fetchAllApprovalSetting = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/documentApproval/getAllDocApprovals?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SETTING_ACTION_LIST.APPROVAL_SETTINGS, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || "")] || 'Some error occurred'
        })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}