import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, GSTR_FILLING_ACTIONS } from '../../../static/constants'
import { getMomentDateForUIReadOnly, showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchGSTFilingDeatils = (payload) => {
  const invoiceDate = payload.filters?.monthly ? getMomentDateForUIReadOnly({date: payload.filters?.monthly , format:"MM-YYYY"}) : null;
       
  const filters ={
       startDate: payload?.filters?.startDate,
       endDate:payload?.filters?.endDate,
       monthly: invoiceDate? (invoiceDate||"").replace('-','/'): null,
       quarter: payload?.filters?.quarter,
       quarterYear: payload?.filters?.quarterYear,
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/gstr1/geteInvoiceData?relationshipId=${payload.relationshipId}&pageNumber=${(payload.pageNumber-1)||0}&pageSize=${payload?.pageSize||100}&filters=${encodeURIComponent(JSON.stringify(filters || {}))}`)
      .then(res => {
      
       
        dispatch({ type: GSTR_FILLING_ACTIONS.E_INVOICE_LISTING, data: res?.data });
     
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      })
    }

}




export const fetchValidatedGstDetails = (payload) => {
   
   
   const invoiceDate = getMomentDateForUIReadOnly({date: payload.date , format:"MM-YYYY"})
  const val = {
    "cname": null,
    "ct": null,
    "ctin": null,
    "fp": `${(invoiceDate||"").replace('-','')}`,
    "fromDate": null,
    "ft": "GSTR1",
    "gstin": "33ABCDE9876A1ZE",
    "inum": null,
    "inumList": null,
    "onum": null,
    "status": null,
    "toDate": null,
    "hasErrors": null,
    "hasWarnings": null,
    "val": null,
    "dty": null,
    "gstinStatus": null,
    "branchCode": null,
    "invTyp": null,
    "dst": null,
    "divCode": null,
    "userName": null,
    "einvReconCategory": null,
    "uploadedOn": null,
    "suptyp": null
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/alpide-gst/api/v1/gstr1/uploadInvoicesList`,val)
      .then(res => {
         if(res.data.data){
          dispatch({ type: GSTR_FILLING_ACTIONS.VALIDATE_GSTR_INVOICE_LISTING, data: res?.data?.data?.response });

         }
         else{
          
          showToasterMessage({
            description: lang[(res || {}).message] || "Some Error Occured",
            messageType: "error",
          })
         }
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        dispatch({ type: GSTR_FILLING_ACTIONS.VALIDATE_GSTR_INVOICE_LISTING, data: {} });

      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      })
    }

}


export const fetchGstSummaryDetails = (payload) => {
  const invoiceDate = getMomentDateForUIReadOnly({date: payload.date , format:"MM-YYYY"})
 
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/alpide-gst/api/v1/gstr1/summary?fp=${(invoiceDate||"").replace('-','')}&gstin=33ABCDE9876A1ZE&ft=GSTR1`)
      .then(res => {
        if(res.status === 200 && res.data?.status === 200){
       
          dispatch({ type: GSTR_FILLING_ACTIONS.SUMMARY_GSTR_INVOICE_LISTING, data: res?.data?.data?.response });
              
        }
         else{
          
          throw new Error(res.data.message);
         }  
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        dispatch({ type: GSTR_FILLING_ACTIONS.SUMMARY_GSTR_INVOICE_LISTING, data: {} });
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      })
    }

}



export const createSummaryDraft =(payload,props)=>{
  const invoiceDate = getMomentDateForUIReadOnly({date: payload.fp , format:"MM-YYYY"})

  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/alpide/gstr1/createDraft?gstin=${payload?.gstin}&fp=${(invoiceDate||"").replace('-','')}&relationshipId=${props.companyInfo?.relationshipId}`,
        
      )
      .then((res) => {
        if( res.status === 200 && (res?.data?.status === 409 || res?.data?.status === 200)){
          if(res?.data?.status === 409){
            const modalData = {
              title: "Confirmation",
            hideFooter: false,
              modalBody:"Summary GSTR-1 has already been created. To continue please discard the summary.",
              width: 500,
              footer:{
                submit:{
                  intlId: "Discard Summary"
                }
              },
              handleSubmit: ()=>{
                
                
                
               
                  props.discardSummaryDraft({gstin: payload.gstin , fp: payload.fp},props )
                
               
              }
            };

            props.showModal(modalData)
          }
          else{
            props.updateState({activeTabKey: "summary"})
            props.fetchGstSummaryDetails({date: props.selectedDate})
            return showToasterMessage({
              messageType: "success",
              description: res?.data?.message,
            });

          }
          
          
        }
        else{
         return showToasterMessage({
            messageType: "error",
            description: res?.data?.message,
          });
        }
       
        
        //  if(res?.data?.status === 200){
          
        //  }
        //  else{
        //   showToasterMessage({
        //     messageType: "error",
        //     description: res?.data?.data,
        //   });
        //  }
       
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")]||"helooooooo"
           
        });
      });
  };
}


export const discardSummaryDraft = (payload,props) => {
   
  const invoiceDate = getMomentDateForUIReadOnly({date: payload.fp , format:"MM-YYYY"})
  let formData = new FormData();
  formData.append("gstin","33ABCDE9876A1ZE");
  formData.append("fp",(invoiceDate||"").replace('-',''));
  formData.append("relationshipId", "67");
  formData.append("formType", "GSTR1");

  // const formdata = new FormData();
  // formdata.append("gstin", "33ABCDE9876A1ZE");
  // formdata.append("fp", "072024");
  // formdata.append("relationshipId", "67");
  // formdata.append("formType", "GSTR1");
  
 return dispatch => {
  //  dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
  //  dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
   return axios
     .post(`${config.API_BASE_URL}${config.API_VERSION}/alpide/gstr1/discardDraft`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
     )
     .then(res => {

      
        if(res?.status === 200 && (res?.data.status === 409|| res?.data?.status === 200)){
       showToasterMessage({ messageType: 'success', description: res?.data?.message})
                   props.fetchGstSummaryDetails({date: props.selectedDate})
        }
        else{
          showToasterMessage({ messageType: 'error', description: res?.data?.message})

        }
      // if(res?.data?.status === 200){
      // }
      // else{
      //   showToasterMessage({ messageType: 'error', description: res?.data?.data?.message})
      // }
      //  dispatch({ type: GSTR_FILLING_ACTIONS.VALIDATE_GSTR_INVOICE_LISTING, data: res?.data?.data?.response });
    
       //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
     })
     .catch((err) => {
       showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
     })
    //  .finally(()=>{
    //    dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
    //    dispatch({
    //      type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
    //    });
    //  })
   }

}


export const saveDataToGSTIN =(payload)=>{
  const invoiceDate = getMomentDateForUIReadOnly({date: new Date() , format:"MM-YYYY"})
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/alpide-gst/api/v1/gstr1/saveToGstn?gstin=${payload?.gstin}&fp=${(invoiceDate||"").replace('-','')}&formType=${payload?.formType}&ipAdd=${"27.168.1.1"}`,
        
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: res?.data?.data?.message,
        });
       
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      });
  };
}

export const saveToGstinStatus =(payload)=>{
  const invoiceDate = getMomentDateForUIReadOnly({date: new Date() , format:"MM-YYYY"})
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/alpide-gst/api/v1/gstr1/saveToGstnStatus?gstin=${payload?.gstin}&fp=${(invoiceDate||"").replace('-','')}&formType=${payload?.formType}`,
        
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: `Summary Created Successfully`,
        });
       
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      });
  };
}