import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, DEBIT_MEMO_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchDebitMemos = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/debitMemo/getAllDebitMemos?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber|| 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: DEBIT_MEMO_ACTION_LIST.DEBIT_MEMO_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = DEBIT_MEMO_ACTION_LIST.DEBIT_MEMO_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}
export const getDebitMemoCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/debitMemo/getAllDebitMemosCount?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: DEBIT_MEMO_ACTION_LIST.DEBIT_MEMO_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = DEBIT_MEMO_ACTION_LIST.DEBIT_MEMO_COUNT;
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: DEBIT_MEMO_ACTION_LIST.DEBIT_MEMO_LIST_PAGE_RESET });
  }
}

export const deleteDebitMemo = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/debitMemo/deleteDebitMemo`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: (res.data || "") || 'Debit Memo has been deleted successfully' });
        props.fetchDebitMemos(props);
        props.getDebitMemoCount(props);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' });
      })
  }
}