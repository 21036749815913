import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
//import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Popover, Tooltip,Empty } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from '../../../../utils'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import SODetail from '../../../../containers/modal/modalBody/customer/SODetail';
import AmendSODetail from '../../../../containers/modal/modalBody/customer/AmendSoDetails';
import PackageDetail from '../../../../containers/modal/modalBody/customer/PackageDetail';
import { CONSTANTS } from '../../../../static/constants';
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
// import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
// import { TextBox } from '../../../general/TextBox';
// import { Dropdown } from '../../../general/Dropdown';
import { FilterOutlined, InsertRowRightOutlined } from '@ant-design/icons';
// import find from 'lodash.find';
// import PageBreadcrumb from "../../../PageBreadcrumb";
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
// const { RangePicker }  = DatePicker;

// const { Panel } = Collapse;

const SOPackageListingComp = (props) => {
    const { packageList, updateState, companyInfo, packageCount, pageNumber } = props;
    // const [collapseActiveKey, setCollapseActiveKey] = useState('');

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchAllPackage', 'packageList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchAllPackage(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    // const getRowHeight = (params) => {
    //     let height = 35;
    //     return height;
    // }

    const openPackageDetails = (data) => {
        const packagePayload = {
            customerId: data.customerId,
            salesOrderMasterId: data.salesOrderMasterId,
            soPackageMasterId: data.soPackageMasterId,
            amendSalesOrderMasterId:data.amendSalesOrderMasterId
        }
        props.pushModalToStack({
            modalBody: <PackageDetail {...props} packagePayload={packagePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail',
        });
    }

    const openSoDetails = (data) => {
        const soPayload = {
            customerId: data.customerId,
            salesOrderMasterId: data.salesOrderMasterId
        }
        props.pushModalToStack({
            modalBody: <SODetail {...props} soPayload={soPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const openAmendSoDetails = (data) => {
        const soPayload = {
            customerId: data.customerId,
            amendSalesOrderMasterId: data.amendSalesOrderMasterId
        }
        props.pushModalToStack({
            modalBody: <AmendSODetail {...props} soPayload={soPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }
    const _isColumnToHide = (columnName) => {
        return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }


    // const saveColumnIndex = (columnState) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data);
    // }

    // const saveColumnWidth = (columnState, width) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data, props);
    // }

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }

    // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
    //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
    //     event.api.resetRowHeights();
    //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
    //        // event.api.resetRowHeights();
    //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
    //             props.gridApi.sizeColumnsToFit();
    //             setTimeout(() => {
    //                 const columnArray = event.columnApi.getColumnState();
    //                 saveColumnWidth(columnArray);
    //             }, 1000);
    //         } else {
    //             saveColumnWidth(event.columnApi.getColumnState());
    //         }
    //     }
    // }

  


    // const getPackedQuantity = (item) => {
    //     let quantity = 0;
    //     item.customerSOPackageDetailsList.map((e) => {
    //         return quantity += e.quantityPacked;
    //     });

    //     return quantity;
    // }

    // const createShipment = (props, packageObj) => {
    //     if (props.qualityChecklistSetting && !props.qualityChecklistSetting.isGenerateShipmentWithoutQualityCheck && (packageObj.qualityChecklistPackageList && packageObj.qualityChecklistPackageList.length && packageObj.qualityChecklistStatus !== 'Completed')) {
    //         return showToasterMessage({ messageType: 'error', description: 'Shipment can not be generate without quality check' });
    //     }
    //     props.history.push({
    //         pathname: '/admin/so/shipment',
    //         state: {
    //             customerId: packageObj.customerId,
    //             soMasterId: packageObj.salesOrderMasterId,
    //         }

    //     })
    // }

    // const updatePackage = (props, packageObj) => {
    //     props.history.push({
    //         pathname: '/admin/so/package',
    //         state: {
    //             customerId: packageObj.customerId,
    //             soMasterId: packageObj.salesOrderMasterId,
    //             soPackageMasterId: packageObj.soPackageMasterId,
    //             update: true
    //         }
    //     })
    // }

    // const qualityCheckUpdated = () => {
    //     const payload = {
    //         companyInfo: companyInfo,
    //         relationshipId: companyInfo.relationshipId,
    //         pageNumber: props.pageNumber || 1,
    //         pageSize: props.pageSize || 25,
    //         packageStatus: props.packageStatus || 'all'
    //     };
    //     props.fetchAllPackage(payload);
    // }



    // const actionMenu = (param) => {
    //     return (
    //         <Menu className="cell-action-dropdown">
    //             {/* <Menu.Item key="1" onClick={() => {
    //                 openPackageDetails(param.data)
    //             }}>
    //                 View
    //             </Menu.Item> */}
    //             {(param.data.qualityChecklistPackageList && param.data.qualityChecklistPackageList.length) ?
    //                 <Menu.Item key="2" onClick={() => {
    //                     props.updateState({
    //                         title: <div className="drawer-title">Quality Check</div>,
    //                         qualityCheckList: param.data.qualityChecklistPackageList,
    //                         qualityChecklistStatus: param.data.qualityChecklistStatus,
    //                         qualityCheckAction: "check",
    //                         qualityCheckDrawerVisible: true,
    //                         packageObj: param.data,
    //                         addQualityCheckToProps: function (qualityCheckList, status) {
    //                             props.updateState({
    //                                 qualityCheckList: qualityCheckList
    //                             })
    //                             props.updatePackageQualityCheck({ ...param.data, qualityChecklistPackageList: qualityCheckList, qualityChecklistStatus: status }, props, qualityCheckUpdated);
    //                         }
    //                     })
    //                 }}>
    //                     {/* <i className="fa fa-check-square-o" /> */}
    //                     <FormattedMessage id='addItem.text.qualityCheck' defaultMessage='' /> 
    //                 </Menu.Item>
    //                 :
    //                 <Fragment />
    //             }
    //             {(param.data.status === "Not Shipped") ?
    //                 <Menu.Item key="3" onClick={() => {
    //                     createShipment(props, param.data);
    //                 }}>
    //                     <FormattedMessage id='createShipment.text' defaultMessage='' /> 
    //                 </Menu.Item>
    //                 :
    //                 <Fragment />
    //             }
    //             {(param.data.status === "Not Shipped") ?
    //                 <Menu.Item key="4" onClick={() => {
    //                     updatePackage(props, param.data);
    //                 }}>
    //                     <FormattedMessage id='updatePackage.text' defaultMessage='' /> 
    //                 </Menu.Item>
    //                 :
    //                 <Fragment />
    //             }
    //             <Menu.Item key="5" onClick={() => {
    //                 updateUserStatus(props, param.data);
    //             }}>
    //                 <i className="fa fa-pencil" />
    //                  Update User Status
    //                 </Menu.Item>
    //         </Menu >
    //     );
    // }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.package.listing.header.packageNo' defaultMessage='' />;
    //         },
    //         field: "packageNumber",
    //         colId: 'packageNumber_1',
    //         hide: _isColumnToHide('packageNumber'),
    //         suppressMovable: true,
    //         minWidth: 160,
    //         maxWidth: 400,
    //         rowDrag: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openPackageDetails(link.data)
    //         }}>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.package.listing.header.soNo' defaultMessage='' />;
    //         },
    //         field: "salesOrderNumber",
    //         hide: _isColumnToHide('soNumber'),
    //         colId: 'soNumber_1',
    //         minWidth: 100,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openSoDetails(link.data)
    //         }}>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.package.listing.header.customer' defaultMessage='' />;
    //         },
    //         field: "companyName",
    //         hide: _isColumnToHide('customerName'),
    //         colId: 'customerName_1',

    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" >
    //             <div onClick={() => {
    //                 props.history.push("customers/details", {
    //                     customerId: link.data.customerId,
    //                     customerName: link.data.companyName,
    //                 })
    //             }}>{link.data.companyName}</div>
    //         </div>,
    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='customer.package.listing.header.qtyPacked' defaultMessage='' />;
    //     //     },
    //     //     field: "qtyPacked",
    //     //     hide: _isColumnToHide('qtyPacked'),
    //     //     colId: 'qtyPacked_1',
    //     //     minWidth: 70,
    //     //     maxWidth: 400,
    //     //     suppressMovable: true,
    //     //     resizable: true,
    //     //     cellRendererFramework: (link) => <div>
    //     //         {getPackedQuantity(link.data)}
    //     //     </div>,
    //     // },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.package.listing.header.date' defaultMessage='' />;
    //         },
    //         field: "packageDate",
    //         hide: _isColumnToHide('packageDate'),
    //         colId: 'packageDate_1',
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //             <Tooltip placement="topLeft" title={(link.data.packageDate && getMomentDateForUIReadOnly({date: link.data.packageDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {getMomentDateForUIReadOnly({date: new Date(link.data.packageDate), format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.ref' defaultMessage='' />;
    //         },
    //         field: "referenceNumber",
    //         hide: _isColumnToHide('referenceNumber'),
    //         colId: "referenceNumber_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "userStatus",
    //         hide: _isColumnToHide('userStatus'),
    //         colId: "userStatus_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => {
    //             return <div>
    //               {link.value? <span className="status-code" style={{background: link.data.userStatusColor}}>{link.value || ''}</span> : ''}
    //             </div>
    //         },
    //         resizable: true,
    //         cellStyle: function (props){
    //             if(props.statusColor){
    //                 return {backgroundColor: props.statusColor}
    //             }
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.package.listing.header.systemStatus' defaultMessage='' />;
    //         },
    //         field: "status",
    //         hide: _isColumnToHide('status'),
    //         colId: 'status_1',
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (params) => {
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span> </div>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell payment', 
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customerSalesEnquiryNumber.text' defaultMessage='' /> ;
    //         },
    //         field: 'customerInquiryNumber',
    //         hide: _isColumnToHide('customerInquiryNumber'),
    //         colId: "customerInquiryNumber_1",
    //         resizable: true,
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.customerInquiryNumber || '-'}
    //         </div>,
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customerRfqNumber.text' defaultMessage='' /> ;
    //         },
    //         field: 'customerRFQNumber',
    //         hide: _isColumnToHide('customerRFQNumber'),
    //         colId: "customerRFQNumber_1",
    //         resizable: true,
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.customerRFQNumber || '-'}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customerPONumber.text' defaultMessage='' /> ;
    //         },
    //         field: 'customerPONumber',
    //         hide: _isColumnToHide('customerPONumber'),
    //         colId: "customerPONumber_1",
    //         resizable: true,
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.customerPONumber || '-'}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div>
    //                 <FormattedMessage id='customer.package.listing.header.qcStatus' defaultMessage='' />
    //                 <Tooltip placement="top" title={props.intl.formatMessage(
    //                     {
    //                         id: 'tooltip.qualityChecklist',
    //                         defaultMessage: ''
    //                     })
    //                 } trigger="click">
    //                     <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //                 </Tooltip>
    //             </div>
    //         },
    //         field: "qualityChecklistStatus",
    //         hide: _isColumnToHide('qualityChecklistStatus'),
    //         colId: 'qualityChecklistStatus_1',
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (params) => {
    //             return <span><FormattedMessage id={params.value} defaultMessage={params.value} /></span>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell payment'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.project' defaultMessage='' />;
    //         },
    //         field: "project",
    //         colId: "project_1",
    //         hide: _isColumnToHide('project'),
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 {link.data.projectMasterId ? <span>{link.data.projectMasterId + '-' +link.data.projectName }</span> : ''}
    //             </div>
    //         },
    //         resizable: true
    //     },

    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <div className="cursor-pointer">
    //     //             <Popover
    //     //                 content={<AddAndRemoveCustomerListingColumn {...props} />}
    //     //                 title={<div className="text-center">Add/Remove </div>}
    //     //                 placement="left"
    //     //                 trigger="click"
    //     //             >
    //     //                 <i className='fa fa-bars' />
    //     //             </Popover>
    //     //         </div>;
    //     //     },
    //     //     cellRendererFramework: (param) => {
    //     //         return <Dropdown overlay={actionMenu(param)} trigger={['click']}>
    //     //             <Button className="action-btn small">
    //     //                 <i className="fa fa-ellipsis-v" />
    //     //             </Button>
    //     //         </Dropdown>
    //     //     },
    //     //     resizable: true,
    //     //     colId: 'action_1',
    //     //     field: 'action',
    //     //     pinned: 'right',
    //     //     lockPosition: true,
    //     //     minWidth: 60,
    //     //     maxWidth: 60,
    //     //     suppressMovable: true,

    //     // }
    // ];

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });

    //     onDataRendered(params);
    // }


    // const onModelUpdated = (params) => {
    //     if((packageList[pageNumber] || []).length &&  !props.isRowHeightSet){
    //         setTimeout(()=>{
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
    //     if (props.tableDataReRendered) {
    //        // params.api.resetRowHeights();
    //         props.updateState({
    //             tableDataReRendered: false
    //         })
    //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.PACKAGE_LISTING;

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     }
    // }

    // const onDataRendered = (params) => {
    //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //     if (Object.keys(txColumnSetting).length) {
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.PACKAGE_LISTING;

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.width = 52;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     } else {
    //         params.api.sizeColumnsToFit();
    //     }
    // }


                // {/* <Collapse activeKey={collapseActiveKey} onChange  = {()=>{
                //     setCollapseActiveKey(collapseActiveKey ? '' : '1')
                // }}> */}
                // {/* {/* <Panel header="Apply Filter" key="1"  style={{ width : '100%' }}>
                //     <div className='qute-sect1'>
                //         <Dropdown
                //             // style={{ marginTop: '-20px', width: '180px' }}
                //             className='drop-fileds1'
                //             items={props.customers[pageNumber] || []}
                //             valueKeyName='companyName'
                //             optionKeyName='customerId'
                //             value={props.selectedCustomerName}
                //             onSearch={(searchedText) => {
                //                 props.fetchCustomers({
                //                     isCompact: true,
                //                     searchedText: searchedText,
                //                     pageNumber: 1, 
                //                     hideSpinner: true,
                //                     pageSize: 100,
                //                     relationshipId: (props.companyInfo || {}).relationshipId
                //                 })
                //             }}
                //             placeholder={props.intl.formatMessage({
                //                 id: 'customer.salesInvoice.form.customer.placeholder', defaultMessage: ''
                //             })}
                //             onSelect={
                //                 (customerId, option) => {
                //                     updateState({
                //                         selectedCustomerId: option.value,
                //                         selectedCustomerName: option.name,
                //                     })
                //                 }
                //             }
                //             allowClear={true}
                //             onClear={() => {
                //                 updateState({
                //                     selectedCustomerId: undefined,
                //                     selectedCustomerName: undefined,
                //                 })
                //             }}
                //         />
                //         <TextBox 
                //             // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                //             className='drop-fileds'
                //             placeholder='Package #'
                //             type='text'
                //             maxLength={15}
                //             value={props.packageNum}
                //             onChange={(e) => {
                //                 props.updateState({ packageNum: e.target.value });
                //             }}
                //         />
                //         <TextBox 
                //             // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                //             className='drop-fileds1'
                //             placeholder='Enter SO #'
                //             type='text'
                //             maxLength={15}
                //             value={props.soNum}
                //             onChange={(e) => {
                //                 props.updateState({ soNum: e.target.value });
                //             }}
                //         />

                //         <RangePicker
                //             // style={{ marginTop: '-20px', width: '300px' }}
                //             className='drop-fileds2'
                //             allowEmpty={true}
                //             format={format}
                //             placeholder={['Start date', 'End date']}
                //             value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                //             onChange={(dates) => {
                //                 if (dates) {
                //                     props.updateState({ startDate: dates[0], endDate: dates[1] });
                //                 } else {
                //                     props.updateState({ startDate: null, endDate: null });
                //                 }
                //             }}
                //         />
                //         <Dropdown
                //             items={props.userStatus || []}
                //             valueKeyName='statusName'
                //             optionKeyName={'packageStatusId'}
                //             statusColorKey='statusColor'
                //             // style={{ marginTop: '-25px', width: '160px' }}
                //             className='drop-fileds1'
                //             value={props.selectedUserStatusName}
                //             placeholder={props.intl.formatMessage({ id: 'common.status' })}
                //             onSelect={(selectedValue, option) => {
                //                 updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                //             }}
                //             allowClear={true}
                //             onClear={() => {
                //                 updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                //             }}
                //         />
                //         <div className='cler'></div>
                //         </div>
                //         <div className='cler'></div>
                //         <div className='qute-sect1'>
                //         <Dropdown
                //             // style={{ marginTop: '-20px', width: '140px' }}
                //             className='drop-fileds1'
                //             items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partially.fulfilled' }, { name: 'Fullfilled', value: 'fulfilled' }]}
                //             valueKeyName='name'
                //             optionKeyName='value'
                //             value={props.selectedStatusName}
                //             placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                //             onSelect={(status, option) => {
                //                 updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                //             }}
                //             allowClear={true}
                //             onClear={() => {
                //                 updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                //             }}
                //         />
                //         <TextBox 
                //             // style={{ marginTop: '-20px', width: '140px', marginRight: '5px'}}
                //             className='drop-fileds1'
                //             placeholder='Enter Ref #'
                //             type='text'
                //             maxLength={15}
                //             value={props.refNum}
                //             onChange={(e) => {
                //                 props.updateState({ refNum: e.target.value });
                //             }}
                //         />
                //          <Dropdown
                //             // style={{ marginTop: '-20px', width: '140px' }}
                //             className='drop-fileds1'
                //             items={props.allQualityChecksName}
                //             valueKeyName='checklistName'
                //             optionKeyName='qualityChecklistId'
                //             value={props.selectedQcName}
                //             placeholder='Quality Check'
                //             onSelect={(status, option) => {
                //                 updateState({ selectedQcName: option.name, selectedQcId: option.value })
                //             }}
                //             allowClear={true}
                //             onClear={() => {
                //                 updateState({ selectedQcId: undefined, selectedQcName: undefined })
                //             }}
                //         />

                //         <Dropdown
                //             // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}
                //             className='drop-fileds1'
                //             items={props.projectList}
                //             valueKeyName='projectName'
                //             optionKeyName='projectMasterId'
                //             projectIdKey='projectMasterId'
                //             value={props.selectedProjectName}
                //             placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                //             onSelect={(status, option) => {
                //                 updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                //             }}
                //             allowClear={true}
                //             onClear={() => {
                //                 updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                //             }}
                //         /> 
                //         {/* <div className='cler'></div> */}
                // {/* </div>  */}
                // {/* <div className='cler'></div>
                //         <div className='qute-sect1'>
                //         <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                //             if(!props.selectedCustomerId && !props.packageNum && !props.soNum && !props.startDate && !props.endDate && !props.selectedUserStatus && !props.selectedStatus && !props.refNum && !props.selectedQcName && !props.selectedProjectMasterId ){
                //                 return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                //             }
                //             const payload = {
                //                 companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                //                 filters: {
                //                     customerId: props.selectedCustomerId || 0,
                //                     packageNumber: props.packageNum || null,
                //                     soNumber: props.soNum || null,
                //                     startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                //                     endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                //                     userStatus: props.selectedUserStatusName || null,
                //                     status: props.selectedStatus || null,
                //                     reference: props.refNum || null,
                //                     qcId: props.selectedQcId || 0,
                //                     qcName: props.selectedQcName || null,
                //                     projectMasterId: props.selectedProjectMasterId || 0,
                //                 }
                //             };
                //             props.fetchAllPackageByFilter(payload);
                //             props.getAllPackageCountByFilter(payload);
                //         }}>
                //             {<FilterOutlined />} Apply Filter
                //         </button>
                //         <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                //             props.updateState({
                //                 selectedCustomerId: null, selectedCustomerName: null,
                //                 packageNum: null,
                //                 soNum: null,
                //                 startDate:null, endDate:null,
                //                 selectedUserStatusName:null,
                //                 selectedStatus:null, selectedStatusName: null,
                //                 refNum:null,
                //                 selectedQcName:null, selectedQcId: null,
                //                 projectMasterId: null, selectedProjectName: null,
                //             });
                //             const payload = {
                //                 companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                //                 filters: {}
                //             };
                //             props.fetchAllPackage(payload);
                //             props.getSOPackageCount(payload);
                //         }}>
                //             {<UndoOutlined />} Clear Filter
                //         </button>
                //         <div className='cler'></div>
                //         </div>
                //     </Panel>
                // </Collapse> */}
    return (
        <Fragment>


                    <div className="view-container-actions">
                    <Skeleton loading={props.headerLoading} paragraph={false} active>

                        <div className="left-actions">
                            <div className="table-heading">
                                <FormattedMessage id='sales.salesPackage.table.heading' defaultMessage='' />
                            </div>
                            {
                                props.pageSize ? <>
                                    <div className="vertical-sep" />
                                    <div>{props.packageCount}</div>
                                </> : ''
                            }
                        </div>
                        <div className="right-actions">
                            <button onClick={() => {
                                props.updateState({
                                    packageFilterDrawerVisible: true
                                })
                            }}>
                                <FilterOutlined onClick={() => {

                                }} /> &nbsp; <FormattedMessage id='filter.text' defaultMessage='' />
                            </button>
                            <Popover
                                content={<AddAndRemoveCustomerListingColumn {...props} />}
                                title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                                placement="leftTop"
                                trigger="click"
                                arrow="right"
                                // visible={props.showColPop}
                            >
                                <button onClick={() => { props.updateState({ showColPop: !props.showColPop }) }}>
                                    <InsertRowRightOutlined className='mr5'/> Columns
                                </button>
                            </Popover>

                        </div>
                        </Skeleton>
                    </div>

                    {/* {props.txColumns.length ? <div> */}
                    {/* <div className="cursor-pointer"> */}
                    {/* <Tooltip placement='left' title={props.intl.formatMessage({ id: 'tooltip.addRemoveColumn.msg' })} trigger='hover'> */}
                    {/* <span className="top-button"> */}
                    {/* <Popover
                                    content={<AddAndRemoveCustomerListingColumn {...props} />}
                                    title={<div className="text-center">Add/Remove </div>}
                                    placement="left"
                                    trigger="click"
                                >
                                    <i className='fa fa-bars' />
                                </Popover> */}
                    {/* </span> */}
                    {/* </Tooltip> */}
                    {/* <div className="clearfix"></div> */}
                    {/* </div> */}
                    {/* <AgGridReact
                        onGridReady={onGridReady}
                        columnDefs={columnDefs}
                        rowData={packageList[pageNumber] || []}
                        rowDragManaged={true}
                        defaultColDef={{
                            flex: 1,
                            autoHeight: true,
                            wrapText: true,
                        }}
                        domLayout={"autoHeight"}
                        animateRows={true}
                        //getRowHeight={getRowHeight}
                        onModelUpdated={onModelUpdated}
                        onColumnResized={onColumnResized}
                        onDragStopped={() => {
                            onColumnMoved({}, true);
                        }}
                        //onColumnMoved={onColumnMoved}
                    >
                    </AgGridReact> */}
                    {/* </div> : null} */}
                    <Skeleton loading={props.listLoading} paragraph={{ rows: 14 }} active>

                    <div className='table-container'>
                        <table id='all-package-list'>
                            <thead>
                                <tr>

                                    {!_isColumnToHide('packageNumber') ? <th><FormattedMessage id='customer.package.listing.header.packageNo' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('soNumber') ? <th><FormattedMessage id='customer.package.listing.header.soNo' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('soNumber') ? <th>Return/Replace #</th> : ''}
                                    {!_isColumnToHide('customerName') ? <th><FormattedMessage id='customer.package.listing.header.customer' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('packageDate') ? <th><FormattedMessage id='customer.package.listing.header.date' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('referenceNumber') ? <th><FormattedMessage id='common.ref' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('userStatus') ? <th><FormattedMessage id='common.status' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('status') ? <th><FormattedMessage id='customer.package.listing.header.systemStatus' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('customerInquiryNumber') ? <th><FormattedMessage id='customerSalesEnquiryNumber.text' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('customerRFQNumber') ? <th><FormattedMessage id='customerRfqNumber.text' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('customerPONumber') ? <th><FormattedMessage id='customerPONumber.text' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('qualityChecklistStatus') ? <th><FormattedMessage id='customer.package.listing.header.qcStatus' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('project') ? <th><FormattedMessage id='common.project' defaultMessage='' /></th> : ''}
                                </tr>
                            </thead>
                            <tbody>

                                {packageList && packageList[pageNumber] ?
                                    packageList[pageNumber].map((rowData, index) => (
                                        <tr key={index}>
                                            {!_isColumnToHide('packageNumber') ? <td className='one-line-view'>
                                                <div className="cursor-pointer" onClick={() => {
                                                    openPackageDetails(rowData)
                                                }}>{rowData.packageNumber||<div className="empty-data"></div>}</div>
                                            </td> : ''}
                                            {!_isColumnToHide('soNumber') ? <td className='one-line-view'>
                                                <div className="cursor-pointer" onClick={() => {
                                                    openSoDetails(rowData)
                                                }}>{rowData.salesOrderNumber||<div className="empty-data"></div>}</div>
                                            </td> : ''}

                                            {!_isColumnToHide('soNumber') ? <td className='one-line-view'>
                                                <div className="cursor-pointer" onClick={() => {
                                                    openAmendSoDetails(rowData)
                                                }}>{rowData.amendSalesOrderNumber||<div className="empty-data"></div>}</div>
                                            </td> : ''}
                                            {!_isColumnToHide('customerName') ? <td className='one-line-view'>
                                                <div className="cursor-pointer" >
                                                    <div 
                                                    // onClick={() => {
                                                    //     props.history.push("customers/details", {
                                                    //         customerId: rowData.customerId,
                                                    //         customerName: rowData.companyName,
                                                    //     })
                                                    onClick={() => {
                                                        props.pushModalToStack({  
                                                  
                                                          modalBody: <CustomerProfile {...props}
                                                          customerId= {rowData.customerId}
                                                        //   customerName={rowData.customerName}
                                                        //   customerDetails={rowData}
                                                      />,
                                                      width: '100%',
                                                      hideTitle: true,  
                                                      hideFooter: true,
                                                      wrapClassName: "new-transaction-wrapper",
                                                      })
                                                    }}>{rowData.companyName||<div className="empty-data"></div>}</div>
                                                </div>
                                            </td> : ''}
                                            {!_isColumnToHide('packageDate') ? <td>
                                                <div>
                                                    <Tooltip placement="topLeft" title={(rowData.packageDate && getMomentDateForUIReadOnly({ date: rowData.packageDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                        {getMomentDateForUIReadOnly({ date: new Date(rowData.packageDate), format })}
                                                    </Tooltip>
                                                </div>
                                            </td> : ''}
                                            {!_isColumnToHide('referenceNumber') ? <td>
                                                {rowData.referenceNumber || <div className="empty-data"></div>}
                                            </td> : ''}
                                            {!_isColumnToHide('userStatus') ? <td >
                                                {rowData.userStatus ? <div className="status-code line-view" style={{ background: rowData.userStatusColor }}>{rowData.userStatus  }</div> : <div className="empty-data"></div>}
                                            </td> : ''}
                                            {!_isColumnToHide('status') ? <td style={{padding: "10px 16px !important"}}>
                                                {<div className={["status-code line-view", rowData.status.toLowerCase()].join(' ')}><FormattedMessage id={rowData.status} defaultMessage={rowData.status} /></div>}
                                            </td> : ''}
                                            {!_isColumnToHide('customerInquiryNumber') ? <td>
                                                {rowData.customerInquiryNumber || <div className="empty-data"></div>}
                                            </td> : ''}
                                            {!_isColumnToHide('customerRFQNumber') ? <td>
                                                {rowData.customerRFQNumber || <div className="empty-data"></div>}
                                            </td> : ''}
                                            {!_isColumnToHide('customerPONumber') ? <td>
                                                {rowData.customerPONumber || <div className="empty-data"></div>}
                                            </td> : ''}
                                            {!_isColumnToHide('qualityChecklistStatus') ? <td className='one-line-view'>
                                                {<span><FormattedMessage id={rowData.qualityChecklistStatus} defaultMessage={rowData.qualityChecklistStatus} /></span>}
                                            </td> : ''}
                                            {!_isColumnToHide('project') ? <td>
                                                {rowData.projectName ? <span>{rowData.projectName}</span> : <div className="empty-data"></div>}
                                            </td> : ''}
                                        </tr>
                                    )) :  <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </Skeleton>
                <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={packageCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props)
                            }}
                        />
                    </div>
                    </Skeleton>
                </div>
           
        </Fragment>

    );
};

export default injectIntl(SOPackageListingComp);
