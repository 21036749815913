import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, PURCHASE_REQUEST_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchPurchaseRequests = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/getAllPurchaseRequests?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber|| 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=&orderPriority=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}
export const getPurchaseRequestsCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/countPurchaseRequest?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_COUNT, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
      .catch((err) => {
        
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
       
      })
  }
}

export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_LIST_PAGE_RESET });
  }
}


export const fetchPurchaseRequestDrafts = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/getAllDraftData?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
      .then(res => {
        let list = res.data.map(obj => {
          let rObj = {...obj};
          rObj.prData = JSON.parse(obj.prData);
          return rObj;
       })
        const data = {
          pageNo: payload.pageNumber,
          list: list
        }
        dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DRAFT_LIST, data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      
      })
  }
}

export const getPurchaseRequestDraftCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/getDraftCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DRAFT_COUNT, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DRAFT_COUNT;
        }
      })
  }
}
export const resetDraftPaginatedData = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DRAFT_LIST_PAGE_RESET });
  }
}

export const deletePurchaseRequest = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/deletePurchaseRequest `, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchPurchaseRequests(props);
        props.getPurchaseRequestsCount(props);
        showToasterMessage({ messageType: 'success', description: (res.data || "") || 'Purchase Request deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' });
      })
  }
}

export const updateUserStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/updateUserStatus`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'User status updated' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchPurchaseRequests(props);
        props.fetchPurchaseRequestDetail && props.fetchPurchaseRequestDetail(payload); 
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}

export const fetchPurchaseRequestsByFilter = (payload, history) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/getPurchaseRequestSummary?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber|| 1)-1}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}
export const getPurchaseRequestsCountByFilter = (payload, history) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/countPurchaseRequestSummary?relationshipId=${payload.relationshipId}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_COUNT;
        }
      })
  }
}