import { DONATION_ACTION_LIST } from '../static/constants';
const initialState = {
  donationSetting: {},
  kioskDonationSetting: {},
  donationSettingLoading: {
    isCampaignListLoaded: false,
    isDGLISTLoaded: false,
    isEventTemplateListLoaded: false,
    isMembershipFormListLoaded: false,
    isStudentFormListLoaded: false,
    isSponsorshipListLoaded: false,
    isDonationSettingLoaded: false
  }
}

const DonationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DONATION_ACTION_LIST.DONATION_SETTING:
      return { ...state, donationSetting: action.data };
    case DONATION_ACTION_LIST.RESET_DONATION_SETTING:
      return { ...state, donationSetting: initialState.donationSetting };
    case DONATION_ACTION_LIST.KIOSK_DONATION_SETTING:
      return { ...state, kioskDonationSetting: action.data };
    case DONATION_ACTION_LIST.DONATION_SETTING_LOADING:
      return { ...state, donationSettingLoading: { ...state.donationSettingLoading, ...action.data } };
    case DONATION_ACTION_LIST.RESET_DONATION_LOADING:
      return { ...state, donationSettingLoading: initialState.donationSettingLoading};
    default:
      return state;
  }
};

export default DonationReducer;