import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SALES_INVOICE_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../utils';
import { relativeTimeRounding } from 'moment-timezone';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchSalesInvoices = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getAllInvoices?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_LIST, data });
  
      })
      .catch((err) => {
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}


export const fetchProformaInvoices = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getAllProformaData?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) -1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SALES_INVOICE_ACTION_LIST.PROFORMA_INVOICE_LIST, data });
      })
      .catch((err) => {    
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })      
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}


export const getProformaInvoiceCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getProformaCount?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SALES_INVOICE_ACTION_LIST.PROFORMA_INVOICE_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = SALES_INVOICE_ACTION_LIST.PROFORMA_INVOICE_COUNT;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      })
  }
}

export const getSalesInvoiceCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getTotalInvoiceCount?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_COUNT, data: res.data });
       
      })
      .catch((err) => {
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_LIST_PAGE_RESET });
  }
}

export const postSalesInvoiceToXero = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}alpide-xero/xeroInvoice/postSalesInvoice?relationshipId=${payload.relationshipId}`, payload.invoiceList)
      .then(res => {
        showToasterMessage({
          messageType: "success",
          description: 'Invoice Uploaded to xero successfully.'
        })
        props.hideModal();
        props.fetchSalesInvoices({relationshipId: (props.companyInfo || {}).relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize});
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: ((err.response || {}).data || "") || 'Some error occurred'
        })
      })
  }
}

export const fetchSalesInvoiceDrafts = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getAllDraftData?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
      .then(res => {
        let list = res.data.map(obj => {
          let rObj = {...obj};
          rObj.invoiceData = JSON.parse(obj.invoiceData);
          return rObj;
       })
        const data = {
          pageNo: payload.pageNumber,
          list: list
        }
        dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DRAFT_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DRAFT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const getSalesInvoiceDraftCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getDraftCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DRAFT_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DRAFT_COUNT;
        }
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  }
}
export const resetDraftPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DRAFT_LIST_PAGE_RESET });
  }
}


export const deleteSalesInvoice = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/voidInvoice`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchSalesInvoices(props);
        props.getSalesInvoiceCount(props);
        props.fetchSalesOrders && props.fetchSalesOrders(props);
        showToasterMessage({ messageType: 'success', description: lang[(res.data || "") || 'invoice.voided.message' ] });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' });
      })
  }
}

export const updateUserStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/updateUserStatus`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: lang['saved.successfully'] })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchSalesInvoices(props);
        props.fetchSalesInvoiceDetail && props.fetchSalesInvoiceDetail(payload);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}

export const updateSystemStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/updateStatus`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: lang['saved.successfully'] })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchSalesInvoices(props);
        props.fetchSalesInvoiceDetail && props.fetchSalesInvoiceDetail(payload);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}

export const fetchRecurringInvoices = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVOICE_SERVICE_BASE_URL}/${config.rootContextInvoiceService}/recurringInvoice/getRecurringInvoices?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1 }&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
        }
      })
  }
}

export const resetRecurringPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_LIST_PAGE_RESET });
  }
}


export const getRecurringInvoiceCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVOICE_SERVICE_BASE_URL}/${config.rootContextInvoiceService}/recurringInvoice/countByRid?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_COUNT;
        }
      })
  }
}

export const deleteRecurringInvoices = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.INVOICE_SERVICE_BASE_URL}/${config.rootContextInvoiceService}/recurringInvoice/deleteInBatch`, payload.recurringInvoiceList)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: lang['common.delete.success'] });
        resetRecurringPaginatedData()(dispatch);
        getRecurringInvoiceCount(payload)(dispatch);
        fetchRecurringInvoices(payload)(dispatch);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}

export const inactiveRecurringInvoices = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.INVOICE_SERVICE_BASE_URL}/${config.rootContextInvoiceService}/recurringInvoice/updateIsInactive`, payload)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: lang['common.saved.success'] });
        resetRecurringPaginatedData()(dispatch);
        fetchRecurringInvoices(payload)(dispatch);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}

export const activeRecurringInvoices = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.INVOICE_SERVICE_BASE_URL}/${config.rootContextInvoiceService}/recurringInvoice/updateIsActive`, payload)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: lang['common.saved.success'] });
        resetRecurringPaginatedData()(dispatch);
        fetchRecurringInvoices(payload)(dispatch);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}


export const fetchInvoicesReminder = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/reminderSalesInvoice/getReminderSalesInvoices?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
        }
      })
  }
}

export const resetInvoiceReminderPaginationData = () => {
  return dispatch => {
    dispatch({ type: SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_LIST_PAGE_RESET });
  }
}


export const getInvoiceReminderCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/reminderSalesInvoice/countByRid?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_COUNT;
        }
      })
  }
}

export const deleteInvoiceReminder = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/reminderSalesInvoice/deleteInBatch`, payload.reminderSalesInvoiceList)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: lang['common.delete.success'] });
        resetInvoiceReminderPaginationData()(dispatch);
        getInvoiceReminderCount(payload)(dispatch);
        fetchInvoicesReminder(payload)(dispatch);
        (props && props.getInvoiceReminderSetupByInvoiceMasterId) && props.getInvoiceReminderSetupByInvoiceMasterId(payload);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}

export const updateIsActiveInvoicesReminder = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/reminderSalesInvoice/updateIsActive`, payload)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: lang['common.saved.success'] });
        resetInvoiceReminderPaginationData()(dispatch);
        fetchInvoicesReminder(payload)(dispatch);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}




export const getEInvoiceListingDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideIrn/getGeneratedIrnList?relationshipId=${payload.companyInfo?.relationshipId}
`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SALES_INVOICE_ACTION_LIST.E_INVOICE_LIST, data });
       
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          // showToasterMessage({
          //   description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          // })
        }
      })
  }
}

export const cashProformaPayment = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios.post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/proformaCashPayment?relationshipId=${payload.relationshipId}&proformaIds=${payload.proformaIds}`)
      .then(response => {
        showToasterMessage({ messageType: 'success', description: lang['common.saved.success'] });
        const payloadRes = {
          pageNumber: props.pageNumber,
          pageSize: props.pageSize,
          relationshipId: payload.relationshipId,
          companyInfo: props.companyInfo
        }
        props.fetchProformaInvoices(payloadRes);
        props.getProformaInvoiceCount(payloadRes);
        props.updateState({selectedProformaInvoices: []})
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}



