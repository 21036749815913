import React from 'react';
// import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton ,Empty} from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../../../../utils';
import { CONSTANTS } from '../../../../../../static/constants';
//import SODetail from '../../../../containers/modal/modalBody/customer/SODetail';
import SalesInvoiceDetails from '../../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
import CustomerPaymentDetails from '../../../../../../containers/modal/modalBody/customer/PaymentDetail';
import CreditMemoDetail from '../../../../../../containers/modal/modalBody/customer/CreditMemoDetail';
//import SQDetail from '../../../../containers/modal/modalBody/customer/SQDetail';
import PurchaseInvoiceDetail from '../../../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail';
import DebitMemoDetail from '../../../../../../containers/modal/modalBody/supplier/DebitMemoDetail';
import SupplierPaymentDetails from '../../../../../../containers/modal/modalBody/supplier/PaymentDetail';

//import { FilterOutlined } from '@ant-design/icons';


const LedgerAccountDetailComponent = (props) => {
    const {ledgerDetails, companyInfo,  pageNumber,pageSize } = props;
 //   const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    // const getRowHeight = () =>{
    //     return 28;
    // }

 

    // const handlePageSizeChange = (pageNumber, pageSize, props) => {
    //     const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, ledgerAccountId: props.ledgerAccountId };
    //     props.resetPaginatedData();
    //     payload.pageNumber = 0;
    //     fetchPaginationDataIfNeeded('fetchLedgerDetails', 'ledgerDetailList', props, payload, true);
    //     //props.updateState({ pageSize: pageSize, pageNumber: 0 });
    // }


    // const showTotal = (total) => {
    //     return props.intl.formatMessage({id: 'pagination.text.total', defaultMessage: ''}) + ` ${ total } `+ props.intl.formatMessage({id: 'pagination.text.items', defaultMessage: ''});
    // };

    // const loadPageData = (pageNumber, pageSize) => {
    //     const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, ledgerAccountId: props.ledgerAccountId };
    //     fetchPaginationDataIfNeeded('fetchLedgerDetails', 'ledgerDetailList', props, payload, true);
    // }

    const openDetailPage = (rowData)=>{
        if(rowData.txMasterId && rowData.customerId){
            const invoicePayload = {
                customerId: rowData.customerId,
                invoiceMasterId: rowData.txMasterId
            }

            props.pushModalToStack({
                modalBody: <SalesInvoiceDetails {...props} invoicePayload={invoicePayload} />,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            });
        }
        else if(rowData.txMasterId && rowData.customerId){
            const creditMemoPayload = {
                customerId: rowData.customerId,
                creditMemoMasterId: rowData.txMasterId
            }
            props.pushModalToStack({
                modalBody: <CreditMemoDetail {...props} creditMemoPayload={creditMemoPayload} />,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            })
        }
        else if(rowData.txMasterId && rowData.supplierId){
            const debitMemoPayload = {
                supplierId: rowData.supplierId,
                debitMemoMasterId: rowData.txMasterId
            }
            props.pushModalToStack({
                modalBody: <DebitMemoDetail {...props} debitMemoPayload={debitMemoPayload} />,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            })
        }else if( rowData.supplierId && rowData.txMasterId){
            const invoicePayload = {
                supplierId: rowData.supplierId,
                invoiceMasterId: rowData.txMasterId
            }
            props.pushModalToStack({
                modalBody: <PurchaseInvoiceDetail {...props} invoicePayload={invoicePayload} />,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            })
        }else if(rowData.txMasterId){
            const paymentPayload = {
                customerId: rowData.customerId,
                customerPaymentId: rowData.txMasterId
            }
            const modalData = {
                title: <FormattedMessage id='sidebar.salesPayment.popup.title'/>,
                modalBody: <CustomerPaymentDetails paymentPayload={paymentPayload} />,
                width: 500,
                hideFooter: true
            };
            props.pushModalToStack(modalData);
        }
        else if(rowData.txMasterId){
            const paymentPayload = {
                supplierId: rowData.supplierId,
                supplierPaymentId: rowData.txMasterId
            }
            const modalData = {
                title: <FormattedMessage id='sidebar.purchasePayment.popup.title'/>,
                modalBody: <SupplierPaymentDetails paymentPayload={paymentPayload} />,
                width: 500,
                hideFooter: true
            };
            props.pushModalToStack(modalData);
        }
    }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='transaction.text' defaultMessage='' />;
    //         },
    //         field: "txType",
    //         cellRendererFramework: (link)=>{
    //             return capitalizeFirstLetter(link.value)
    //         },
    //         rowDrag: true,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='transactionNumber.text'/>;
    //         },
    //         field: "txNumber",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Link onClick={
    //                     (e)=>{
    //                         openDetailPage(link);
    //                     }
    //                 }>{link.value}</Link>
    //             </div>
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='transactionDate.text' />;
    //         },
    //         field: "txDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
    //                 {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='amount.text' />   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</div>;
    //         },
    //         field: "amount",
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         resizable: true,
    //         cellRendererFramework: (link) => {
    //             return <div> {(Number(link.value || 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='tooltip.accountEntry' />;
    //         },
    //         cellRendererFramework: (link)=>{
    //             return capitalizeFirstLetter(link.value)
    //         },
    //         field: "accoutingEntry",
    //         resizable: true,
    //     }
    // ];

    return (
      <>
        <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 18 }} active>
                <table id="ledger-table">
                    <thead>
                        <tr>
                           
                            <th>
                            <FormattedMessage id="transaction.text" defaultMessage=" " />
                            </th>
                            <th>
                            <FormattedMessage id="transactionNumber.text" defaultMessage=" " />
                            </th>
                            <th>
                            <FormattedMessage id="modal.ledger.taxName" defaultMessage=" " />
                            </th>
                            <th>
                            <FormattedMessage id="transactionDate.text" defaultMessage=" " />
                            </th>
                            <th>
                            <FormattedMessage id="amount.text" defaultMessage=" " />
                            </th>                          
                            <th>
                            <FormattedMessage id="modal.ledger.accountingEntry" defaultMessage=" " />
                            </th>
                          
                        </tr>
                    </thead>
                    <tbody>

                        {(ledgerDetails?.[pageNumber] || []).length > 0
                            ? ledgerDetails[pageNumber]
                                // ?.sort((a, b) => (a.departmentId < b.departmentId ? 1 : -1))
                                .map((rowData, i) => {

                                    return (
                                        <tr key={i}>
                                           
                                            <td>{rowData.transactionName || <div className='empty-data'></div>}</td>
                                            <td>{<span className='cursor-pointer' onClick={ (e)=>{ openDetailPage(rowData); } }>{rowData.txNumber}</span> || <div className='empty-data'></div>}</td>
                                            <td>{rowData.taxName || <div className='empty-data'></div>}</td>
                                            <td>{getMomentDateForUIReadOnly({date:rowData.txDate,format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}) || "-"}</td>
                                            <td>{fixedDecimalNumber(rowData.txAmount) || <div className='empty-data'></div>}</td>
                                            <td>{rowData.accountingEntry || <div className='empty-data'></div>}</td>
                                            
                                        </tr>
                                    );

                                })
                                :
                                <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
        </Skeleton>
            </div>
          {/* <div className="footer">
          <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

              <div className="f-left"></div>
              <div className="f-right">
                  <Pagination
                      size="small"
                      total={props.departmentListCount}
                      showTotal={showTotal}
                      defaultPageSize={props.pageSize}
                      showSizeChanger
                      pageSizeOptions={[10, 25, 50, 100, 200]}
                      onChange={(pageNumber, pageSize) => {
                          loadPageData(pageNumber, pageSize);
                          props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
                      }}
                      onShowSizeChange={(pageNumber, pageSize) => {
                          handlePageSizeChange(pageNumber || 1, pageSize, props);
                      }}

                  />
              </div>
              </Skeleton>
          </div> */}
      </>
    );
};

export default injectIntl(LedgerAccountDetailComponent);
