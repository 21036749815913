import React from 'react';
// import { AgGridReact } from 'ag-grid-react';
import { Tooltip, Pagination, Skeleton } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS, ICONS } from '../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { fetchPaginationDataIfNeeded, fixedDecimalNumber, getCurrencySymbol, getMomentDateForUIReadOnly, sortTable } from '../../../../../utils';
import SalesInvoiceDetail from '../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';


const UnpaidInvoicesComponent = (props) => {
    const { unpaidInvoiceList, unpaidInvoiceListCount, companyInfo, updateState, customerData, pageNumber } = props;
    let totalPayment = 0;

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    // const getRowHeight = () =>{
    //     return 28;
    // }
  const currencyCode = props.companyInfo?.currencyCode
  const currencyIcon = props.companyInfo?.currencyIcon
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        if (customerData && customerData.customerId) {
            payload.customerId = customerData.customerId;
        }
        // props.resetPaginatedData();
        props.fetchUnpaidInvoices(payload, props);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }


    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        if (customerData && customerData.customerId) {
            payload.customerId = customerData.customerId;
        }
        props.fetchUnpaidInvoices(payload, props);
    }

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    const openInvoiceDetails = (data) => {
        const invoicePayload = {
            customerId: data.customerId,
            invoiceMasterId: data.invoiceMasterId
        }
        props.pushModalToStack({
            modalBody: <SalesInvoiceDetail {...props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const handleSortColumn = (index) => {
        const currentSortDirection = props.sortStates[index] || "none";
        const nextSortDirection =
          currentSortDirection === "none" ? "asc" :
          currentSortDirection === "asc" ? "desc" : "none";
      
        props.updateState({
          sortStates: {
            [index]: nextSortDirection,
          },
        });
      
        const direction = nextSortDirection === "desc" ? 1 : 0;
        sortTable(document.getElementById("unPaid-Invoice-Table"), index, direction);
    };
    const getSortIcon = (index) => {
        const sortState = props.sortStates[index];
        if (sortState === "asc") return <i className={ICONS.SORT_UP} />;
        if (sortState === "desc") return <i className={ICONS.SORT_DOWN} />;
        return (
            <i
              className={`${ICONS.SORT} ${
                props.hoveredColumn === index ? "opopacity1" : "opopacity0"
              } `}
            />
          );
    };

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.unPaidInvoice.header.invoiceNumber' defaultMessage='' />;
    //         },
    //         field: "invoiceNumber",
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openInvoiceDetails(link.data)
    //         }}>{link.value}</div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.unPaidInvoice.header.invoiceDate' defaultMessage='' />;
    //         },
    //         field: "invoiceDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
    //                     {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.listing.unPaidInvoice.header.amount' defaultMessage='' /> </div>;
    //         },
    //         field: "invoiceTotalAmount",
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span>;
    //         },
    //         valueFormatter: (data) => {
    //             data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    //             return data.value;
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.listing.unPaidInvoice.header.paymentAmount' defaultMessage='' /> </div>;
    //         },
    //         field: "paymentAmount",
    //         cellRendererFramework: (params) => {
    //             let totalPayment = 0;
    //             if (params.data && (params.data.customerPaymentList || []).length) {
    //                 params.data.customerPaymentList.map((e) => {
    //                     totalPayment += e.paymentAmount;
    //                     return fixedDecimalNumber(totalPayment)
    //                 })

    //             }
    //             return <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(fixedDecimalNumber(totalPayment)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span>;
    //         },
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.listing.unPaidInvoice.header.dueAmount' defaultMessage='' /> </div>;
    //         },
    //         field: "invoiceDueAmount",
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span>;
    //         },
    //         valueFormatter: (data) => {
    //             data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    //             return data.value;
    //         },
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },

    //     //{
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='customer.listing.unPaidInvoice.header.invoiceDueDate' defaultMessage='' />;
    //     //     },
    //     //     field: "invoiceDueDate",
    //     //     cellRendererFramework: (link) => {
    //     //         return <div>
    //     //             <Tooltip placement="topLeft" title={(link.value && moment(link.value).format(CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT))}>
    //     //             {(link.value && moment(link.value).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
    //     //             </Tooltip>
    //     //         </div>
    //     //     },
    //     //     resizable: true,
    //     // },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='customer.listing.unPaidInvoice.header.paymentTerm' defaultMessage=' />;
    //     //     },
    //     //     field: "paymentTermName",
    //     //     resizable: true,
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "status",
    //         cellRendererFramework: (params) => {
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /></span> </div>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell'
    //     }
    // ];

    return (
        // <div className='agGridWrapper'>
        //     <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
        //         <AgGridReact
        //             columnDefs={columnDefs}
        //             rowData={unpaidInvoiceList[pageNumber] || []}
        //             rowDragManaged={true}
        //             domLayout={"autoHeight"}
        //             animateRows={true}
        //             onColumnResized={(params) => {
        //                 params.api.resetRowHeights();
        //             }}
        //             defaultColDef={{
        //                 flex: 1,
        //                 autoHeight: true,
        //                 wrapText: true,
        //             }}
        //             //getRowHeight={getRowHeight}
        //             onGridSizeChanged={onFirstDataRendered}
        //         >
        //         </AgGridReact>
        //     </div>

        // </div>
        <>
            <Skeleton loading={props.modalLoading} paragraph={{ rows: 8 }} active>
                <div className="table-container">

                    <table id='unPaid-Invoice-Table'>
                        <thead>
                            <tr>
                                <th>
                                    <div
                                        onClick={() => handleSortColumn(1)}
                                        onMouseEnter={() => props.updateState({ hoveredColumn: 1 })}
                                        onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                        className="sortTable-Container justify-start">
                                        <FormattedMessage id='customer.listing.unPaidInvoice.header.invoiceNumber' defaultMessage='' /> {getSortIcon(1)}
                                    </div>
                                </th>
                                <th>
                                    <div
                                        onClick={() => handleSortColumn(2)}
                                        onMouseEnter={() => props.updateState({ hoveredColumn: 2 })}
                                        onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                        className="sortTable-Container justify-start">
                                        <FormattedMessage id="customer.listing.unPaidInvoice.header.invoiceDate" defaultMessage="" /> {getSortIcon(2)}
                                    </div>
                                </th>
                                <th width='15%'>
                                    <div
                                        onClick={() => handleSortColumn(3)}
                                        onMouseEnter={() => props.updateState({ hoveredColumn: 3 })}
                                        onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                        className="sortTable-Container justify-end">
                                        <FormattedMessage id="customer.listing.unPaidInvoice.header.amount" defaultMessage="" /> {getSortIcon(3)}
                                    </div>
                                </th>
                                <th>
                                    <div
                                        onClick={() => handleSortColumn(4)}
                                        onMouseEnter={() => props.updateState({ hoveredColumn: 4 })}
                                        onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                        className="sortTable-Container justify-end">
                                        <FormattedMessage id='customer.listing.unPaidInvoice.header.paymentAmount' defaultMessage='' /> {getSortIcon(4)}
                                    </div>
                                </th>
                                <th>
                                    <div
                                        onClick={() => handleSortColumn(5)}
                                        onMouseEnter={() => props.updateState({ hoveredColumn: 5 })}
                                        onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                        className="sortTable-Container justify-end">
                                        <FormattedMessage id='customer.listing.unPaidInvoice.header.dueAmount' defaultMessage='' /> {getSortIcon(5)}
                                    </div>
                                    
                                </th>
                                <th>
                                    <div
                                        onClick={() => handleSortColumn(6)}
                                        onMouseEnter={() => props.updateState({ hoveredColumn: 6 })}
                                        onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                        className="sortTable-Container justify-center">
                                        <FormattedMessage id='common.status' defaultMessage='' /> {getSortIcon(6)}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(unpaidInvoiceList[pageNumber] || []).length ? (unpaidInvoiceList[pageNumber] || []).map((rowData, i) => {
                                return <tr key={i}>
                                    <td>
                                        <div className="cursor-pointer" onClick={() => {
                                            openInvoiceDetails(rowData)
                                        }}>
                                            {rowData.invoiceNumber}
                                        </div>
                                    </td>
                                    <td>
                                        <div >
                                            {(rowData.invoiceDate && getMomentDateForUIReadOnly({ date: rowData.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                        </div>
                                    </td>

                                    <td>
                                        <div className='amount-TextRight'>
                                            {currencyIcon?    getCurrencySymbol(currencyCode) : currencyCode + " "}{fixedDecimalNumber(rowData.invoiceTotalAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                                        </div>
                                    </td>
                                    <td>
                                        <div className='amount-TextRight'> 
                                            {currencyIcon?    getCurrencySymbol(currencyCode) : currencyCode + " "}{(fixedDecimalNumber(rowData.totalPaymentReceived)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                                        </div>
                                    </td>
                                    <td>
                                        <div className='amount-TextRight'>
                                            {currencyIcon?    getCurrencySymbol(currencyCode) : currencyCode + " "}
                                            {(
                                                Number(rowData.invoiceTotalAmount || 0) - Number(rowData.totalPaymentReceived || 0)
                                            )
                                                .toFixed(2) // Ensure two decimal places
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                                        </div>
                                    </td>
                                    <td width='20%'>
                                        {rowData.status ? 
                                            <div className="status-code" style={{ background: rowData.statusColor }}>
                                                <FormattedMessage id={rowData.status || ''} defaultMessage="" />
                                            </div> 
                                        : <div class="empty-data"></div>}
                                    </td>
                                </tr>
                            }) : 'No data'}

                        </tbody>
                    </table>
                </div>
            </Skeleton>
            {!props.customerId && !props.customerData?.customerId ? (

                <div style={{ padding: "20px" }}>

                    <Pagination
                        size="small"
                        total={unpaidInvoiceListCount}
                        showTotal={showTotal}
                        defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber, pageSize, props)
                        }}
                    />
                </div>
            ) : (
                null
            )}
        </>
    );
};

export default injectIntl(UnpaidInvoicesComponent);
