import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Button, Row, Col } from 'antd';
import { MODAL_TYPE, PROJECT_TYPE } from '../../../static/constants';
import { CustomButton } from '../../general/CustomButton';
import { CustomAgGrid } from '../../general/CustomAgGrid';
import { Dropdown } from '../../general/Dropdown';
import { UploadQuality } from '../../general/UploadQuality';
import { TextBox } from '../../general/TextBox';
import { ErrorMsg } from '../../general/ErrorMessage';

class ProjectAddComp extends React.Component {

  render() {
    const self = this;
    const packageObj = this.props.packageObj || {};
    const closeDrawer = () => {
      this.props.updateState({
        projectDrawerVisible: false
      })
    }
    const handleAddProject = () => {

      this.props.updateDrawerState({
        submitted:true,
      })
      const payload = {
        projectDescription:this.props.projectDescription,
        projectName:this.props.projectName,
        relationshipId:this.props.companyInfo?.relationshipId,
        projectMasterId:this.props.projectMasterId,
        type:PROJECT_TYPE.FUNDRAISING
      }
      const modalData = {
        modalBody: "Are you sure you want to save?",
        handleSubmit: () => {
          if(this.props.projectDescription && this.props.projectName){

            closeDrawer();
            this.props.createOrUpdateProject(payload,this.props);
          }
        },
      };

      if(this.props.projectDescription && this.props.projectName){
        this.props.pushModalToStack(modalData); 
      }
    }
    const rowGutter = [24, 16];
    return (
      <Fragment>

        <Drawer
          title={'Create Project'}
          width={720}
          zIndex={1}
          className="custom-drawer"
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          onClose={closeDrawer}
          visible={this.props.projectDrawerVisible}
          footer={<>
           <CustomButton
              intlId='confirmation.cancel'
              defaultMessage=''
              type='default'
              key={0}
              style={{ marginRight: 8 }}
              onClick={closeDrawer}
            />
            <CustomButton
              onClick={() => {
                handleAddProject();
              }}
              loading = {this.props.saveLoading}
              type='primary'
              htmlType='submit'
              intlId='button.save.label'
              defaultMessage=''
            />
           </>}
         
        >
             <Row gutter={rowGutter}>


        <Col span={24}>
          <div className="i-label">Project Name</div>
          <TextBox
            placeholder="Project Name"
            type="text"
            maxLength={20}
            value={this.props.projectName}
            onChange={(e) => {
              this.props.updateDrawerState({ projectName: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !this.props.submitted || this.props.projectName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={24}>
          <div className="i-label">Short Description</div>
          <TextBox
            placeholder="Short Description"
            type="text"
            maxLength={40}
            value={this.props.projectDescription}
            onChange={(e) => {
              this.props.updateDrawerState({ projectDescription: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !this.props.submitted || this.props.projectDescription }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>



      </Row>
      
         
        </Drawer>
      </Fragment>
    );
  }
}

export default injectIntl(ProjectAddComp);
