import React, { Fragment, Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Drawer, Select, Row, Col } from "antd";
import { CustomButton } from "../../general/CustomButton";
import { Tooltip } from "antd";
import { DropdownRef } from "../../general/Dropdown";
import { TextBox } from "../../general/TextBox";
import { ICONS } from "../../../static/constants";
import { getUserDefinedAddressName } from "../../../utils";

function AddressDrawer(props) {
  const { Option } = Select;
  const { getFieldDecorator } = props.form;
  const closeDrawer = () => {
    props.updateState({
      address: {
        addressDrawer: false,
      },
    });
  };


  const _getLocationType = () => {
    if (props.formData?.addressType === "relationship") {
      return [
        {
          name: "Shipping Address",
          value: "RelationshipShippingAddress",
        },
        {
          name: "Billing Address",
          value: "RelationshipBillingAddress",
        },
      ];
    } else {
      return [
        {
          name: "Billing Address",
          value: "BillingAddress",
        },
        {
          name: "Shipping Address",
          value: "ShippingAddress",
        },
      ];
    }
  };
  const handleSubmit = (payload) => {
    if (!payload.submittedOnce) {
      payload.updateState({ submittedOnce: true});
    }
    // e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          locationName: values.locationName,
          streetAddress1: values.streetAddress1,
          version: props.formData?.version,
          streetAddress2: values.streetAddress2,
          cityName: values.cityName,
          locationId: props.formData?.locationId ? props.formData?.locationId : 0,
          countryId: values.countryId || props.formData?.countryId,
          countryName: values.countryName,
          stateName: values.stateName,
          zipCode: values.zipCode,
          customerId: props.formData?.customerId,
          supplierId:  props.formData?.supplierId,
          locationType: values.locationType || props.formData?.locationType,
          relationshipId: props.companyInfo.relationshipId,
          isDefault: values?.isDefault === "No" ? 0 : 1,

        };
        const modalData = {
          modalBody: `Are you sure you want to ${props.formData.isUpdate?"Save":"Submit"} ?`,
          handleSubmit: () => {
            props.createAddress(payload, (props || {}).formData, {...props, closeDrawer});
          },
        };
        props.pushModalToStack(modalData);
      }
    });
  };
  
  return (
    <Drawer
      title={props.formData?.isUpdate ? "Edit Address":"Create Address"}
      width={720}
      zIndex={1}
      maskClosable={false}
      keyboard={false}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props?.addressDrawer}
      footer={
        <>
          <CustomButton
            intlId="modal.address.button.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="button.save.label"
            defaultMessage=""
            htmlType="submit"
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </>
      }
    >
      <Form
        layout="vertical"
        onSubmit={handleSubmit}
        {...props}
        hideRequiredMark
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={
                <>
                  <span className="i-label">
                    <FormattedMessage
                      id="address.primary"
                      defaultMessage=""
                    />
                  </span>{" "}
                  <Tooltip
                    placement="bottom"
                    title={
                      <FormattedMessage
                        id="address.primary.message"
                        defaultMessage=""
                      />
                    }
                  >
                    <i className={`cursor-pointer ${ICONS.HELP} `} />
                  </Tooltip>
                </>
              }
              colon={false}
            >
              {getFieldDecorator("isDefault", {
                initialValue:props.formData.isUpdate ? props.formData?.isDefault === 1 ? "Yes" : "No" : props.formData?.isDefault,
              })(
                <Select
                  placeholder="Select"
                  onChange={(e) => {
                    props.form.setFieldsValue({
                      isDefault:  e,
                    });
                    props.updateState({
                      isDefault: e,
                    });
                  }}
                  disabled={props.formData?.isDefault === 1}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <>
                  <span className="i-label">
                    {<FormattedMessage id="address.type" defaultMessage="" />}
                    <span className="required">*</span>
                  </span>{" "}
                  <Tooltip placement="bottom" title="Select address type">
                    <i className={`cursor-pointer ${ICONS.HELP} mr5`} />
                  </Tooltip>
                </>
              }
              colon={false}
            >
              {getFieldDecorator("locationType", {
                initialValue: props.formData?.locationType || "",
                rules: [
                  {
                    required: true,
                    message: "Select Adress Type",
                  },
                ],
              })(
                <DropdownRef
                  items={_getLocationType()}
                  optionKeyName="value"
                  valueKeyName="name"
                  placeholder="Select"
                  onSelect={(selectedValue, option) => {
                    props.form.setFieldsValue({
                      locationType: selectedValue,
                    });
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={<span className="i-label">

                {getUserDefinedAddressName("street1",props)}
                <span className="required">*</span>
                </span>
              }
              colon={false}
            >
              {getFieldDecorator("streetAddress1", {
                initialValue: props.formData?.streetAddress1 || "",
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="address.validation.street"
                        defaultMessage=""
                      />
                    ),
                  },
                ],
              })(
                <TextBox
                  maxLength={50}
                  countStyle={{ top: "8px"}}
                  placeholder={getUserDefinedAddressName("street1",props)}
                />
              )}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<span className="i-label">
                  {getUserDefinedAddressName("street2",props)}
                </span>
              }
              colon={false}
            >
              {getFieldDecorator("streetAddress2", {
                initialValue: props.formData?.streetAddress2 || "",
              })(
                <TextBox
                  maxLength={50}
                  countStyle={{ top: "8px"}}
                  placeholder={getUserDefinedAddressName("street2",props)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={<span className="i-label">
                  {getUserDefinedAddressName("city",props)}
                </span>}
              colon={false}
            >
              {getFieldDecorator("cityName", {
                initialValue: props.formData?.cityName || "",
                rules: [
                  {
                    required: props.formData?.countryName === "India" ? true : false,
                    message: (
                      <FormattedMessage
                        id="address.validation.city"
                        defaultMessage=""
                      />
                    ),
                  },
                ],
              })(
                <TextBox
                  maxLength={30}
                  countStyle={{ top: "8px"}}
                  placeholder={getUserDefinedAddressName("city",props)}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className="i-label">
                  {getUserDefinedAddressName("country",props)}
                </span>
              }
              colon={false}
            >
              {getFieldDecorator("countryName", {
                initialValue: props.formData?.countryName || props.countryName,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="signup.validation.country"
                        defaultMessage=""
                      />
                    ),
                  },
                ],
              })(
                <DropdownRef
                  items={props.countries || []}
                  optionKeyName="countryId"
                  valueKeyName="countryName"
                  onSelect={(selectedValue, option) => {
                    props.updateState({ countryId: selectedValue });
                    props.form.setFieldsValue({
                      countryName: option.props.name,
                      countryId: Number(selectedValue),
                      stateName: "",
                      stateId:0
                    });
                    props.fetchStates(selectedValue);
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={
                <span className="i-label">
                  {getUserDefinedAddressName("country",props)}
                </span>
              }
              className="hide"
              colon={false}
            >
              {props.countries &&
                getFieldDecorator("countryId", {
                  initialValue: props.formData?.stateName,
                })(<span />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={
                <span className="i-label">
                  {getUserDefinedAddressName("state",props)}
                <span className="required">*</span>
                </span>
              }
              className="hide"
              colon={false}
            >
              {getFieldDecorator("stateName", {
                initialValue: props.formData?.stateName || "",
              })(<span />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            {props.form.getFieldValue("countryName") === "India" ||
            props.form.getFieldValue("countryName") === "United States" ||
            props.form.getFieldValue("countryName") ===
              "United States (USA)" ? (
              <Form.Item
                label={
                  <span className="i-label">
                    {getUserDefinedAddressName("state",props)}
                    <span className="required">*</span>
                  </span>
                }
                colon={false}
              >
                {getFieldDecorator("stateId", {
                  initialValue: props.formData?.stateName,
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="address.validation.state"
                          defaultMessage=""
                        />
                      ),
                    },
                  ],
                })(
                  <DropdownRef
                    items={props.states || []}
                    optionKeyName="stateId"
                    valueKeyName="stateName"
                    placeholder={getUserDefinedAddressName("state",props)}
                    onSelect={(selectedValue, option) => {
                      props.form.setFieldsValue({ stateId: selectedValue });
                      props.form.setFieldsValue({
                        stateName: option.props.name,
                      });
                    }}
                  />
                )}
              </Form.Item>
            ) : (
              <Form.Item
                label={
                  <span className="i-label">
                    {getUserDefinedAddressName("state",props)}
                  </span>
                }
                colon={false}
              >
                {getFieldDecorator("stateName", {
                  initialValue: props.formData?.stateName || "",
                  rules: [
                    {
                      required: false,
                      message: (
                        <FormattedMessage
                          id="address.validation.state"
                          defaultMessage=""
                        />
                      ),
                    },
                  ],
                })(
                  <TextBox
                    maxLength={45}
                    // placeholder={props.intl.formatMessage({ id: 'address.state', defaultMessage: '' })}
                    placeholder="Select"
                  />
                )}
              </Form.Item>
            )}
          </Col>

          <Col span={12}>
            <Form.Item
              label={<span className="i-label">
                {getUserDefinedAddressName("zip",props)}
              </span>}
              className="hide"
              colon={false}
            >
              {getFieldDecorator("zipCode", {
                initialValue: props.formData?.zipCode || "",
              })(<span />)}
            </Form.Item>
            <Form.Item
              label={<span className="i-label">
                {getUserDefinedAddressName("zip",props)}
                </span>}
              colon={false}
            >
              {getFieldDecorator("zipCode", {
                initialValue: props.formData?.zipCode || "",
              })(
                <TextBox
                  maxLength={15}
                  countStyle={{ top: "8px"}}
                  placeholder={getUserDefinedAddressName("zip",props)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Drawer>
  );
}
export default Form.create({ name: "New_address_component" })(
  injectIntl(AddressDrawer)
);
