import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, FUNDRAISING_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage, getFinancialYear} from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchDonationPayments = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPayment/getDonationPayments?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: FUNDRAISING_ACTION_LIST.DONATION_PAYMENT_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = FUNDRAISING_ACTION_LIST.DONATION_PAYMENT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}
export const getDonationPaymentsCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPayment/countDonationPayment?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: FUNDRAISING_ACTION_LIST.DONATION_PAYMENT_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = FUNDRAISING_ACTION_LIST.DONATION_PAYMENT_COUNT;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: FUNDRAISING_ACTION_LIST.DONATION_PAYMENT_LIST_PAGE_RESET });
  }
}