import React from "react";
import { Drawer, Row, Col, DatePicker, Input } from "antd";
import {
  getMomentDateForUI,
  getCurrentDateForBackend,
  showToasterMessage,
  fetchPaginationDataIfNeeded,
  fetchDataIfNeeded,
} from "../../../../utils";
import { CustomButton } from "../../../general/CustomButton";
import { Dropdown } from "../../../general/Dropdown";
import { CONSTANTS } from "../../../../static/constants";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { TextBox } from "../../../general/TextBox";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;

const SalesPaymentFilterComp = (props) => {
  const { updateState, companyInfo } = props;

  const closeDrawer = () => {
    props.updateState({
      salesPaymentFilterDrawerVisible: false,
    });
  };

  const applyFilter = () => {
    // if(!props.prNum && !props.selectedRequesterName && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedRefNum && !props.selectedProjectMasterId ){
        if (
            (props.startDate && !props.endDate) ||
            (!props.startDate && props.endDate)
          ) {
            return showToasterMessage({
              messageType: "error",
              description: props.intl.formatMessage({
                id: "invalid.dateRange.message",
              }),
            });
          }
          const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: 0,
            pageSize: props.pageSize,
            filters: {
              customerId: props.selectedCustomerId || 0,
              invoiceNumber: props.invoiceNumberFilter || null,
              projectName: props.selectedProjectName || null,
              startDate: props.startDate
                ? getCurrentDateForBackend(props.startDate)
                : null,
              endDate: props.endDate
                ? getCurrentDateForBackend(props.endDate)
                : null,
            },
          };
          fetchPaginationDataIfNeeded(
            "fetchCustomerPayments",
            "customerPaymentList",
            props,
            payload
          );
          fetchDataIfNeeded(
            "getCustomerPaymentsCount",
            "customerPaymentCount",
            props,
            payload
          );
          closeDrawer();
  };

  const clearFilter = () => {
    props.updateState({
      selectedCustomerId: null,
      invoiceNumberFilter:  null,
      selectedProjectName:  null,
        startDate:  null,
        endDate:null,
    });
    const payload = {
        companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize,
        filters: {}
    };
    fetchPaginationDataIfNeeded(
        "fetchCustomerPayments",
        "customerPaymentList",
        props,
        payload
      );
      fetchDataIfNeeded(
        "getCustomerPaymentsCount",
        "customerPaymentCount",
        props,
        payload
      );
      closeDrawer();
  };

  const rowGutter = [24, 16];

  return (
    <>
      <Drawer
        title="Filters"
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.salesPaymentFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={clearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={applyFilter}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
        <Col span={12}>
            <div className="i-label">Payment #</div>
           <TextBox placeholder="Payment #"/>
          </Col>
      
          <Col span={12}>
            <div className="i-label">Customer</div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}

              items={props.customers[props.pageNumber] || []}
              valueKeyName="companyName"
              optionKeyName="customerId"
              value={props.selectedCustomerName}
              onSearch={(searchedText) => {
                props.fetchCustomers({
                  isCompact: true,
                  searchedText: searchedText,
                  pageNumber: 1,
                  hideSpinner: true,
                  pageSize: 100,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                });
              }}
              placeholder={props.intl.formatMessage({
                id: "customer.salesInvoice.form.customer.placeholder",
                defaultMessage: "",
              })}
              onSelect={(customerId, option) => {
                updateState({
                  selectedCustomerId: option.value,
                  selectedCustomerName: option.name,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedCustomerId: undefined,
                  selectedCustomerName: undefined,
                });
              }}
            />
          </Col>
         
          <Col span={12}>
            <div className="i-label">Invoice #</div>
            <Input
                
                value={props.invoiceNumberFilter}
                placeholder={props.intl.formatMessage({
                  id: "modal.customer.payInvoice.invoiceNumber",
                })}
                onChange={(e) => {
                  updateState({ invoiceNumberFilter: e.target.value });
                }}
              />
          </Col>
        
         
              {/* <Col span={12}>
              <div className="i-label">Start Date</div>

              <DatePicker
                  allowClear={false}
                  format={format}
                  key='startDate'
                  placeholder='Start Date'
                  defaultValue={props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null}
                  onChange={(selectedDate) => {
                      props.updateState({ startDate: selectedDate })
                  }}
              />
              </Col> */}
             
     
        <Col span={12}>
                <div className="i-label">Payment Date</div>

                <RangePicker
              
                allowEmpty={true}
                format={format}
                placeholder={["Start date", "End date"]}
                value={[
                  props.startDate
                    ? getMomentDateForUI({
                        date: new Date(props.startDate),
                        format,
                      })
                    : null,
                  props.endDate
                    ? getMomentDateForUI({
                        date: new Date(props.endDate),
                        format,
                      })
                    : null,
                ]}
                onChange={(dates) => {
                  if (dates) {
                    props.updateState({
                      startDate: dates[0],
                      endDate: dates[1],
                    });
                  } else {
                    props.updateState({ startDate: null, endDate: null });
                  }
                }}
              />
              </Col>
      
              <Col span={12}>
                <div className="i-label">Projects</div> 
                <Dropdown
                  items={props.projectList}
                  valueKeyName='projectName'
                  optionKeyName='projectMasterId'
                  // projectIdKey='projectMasterId'
                  value={props.selectedProjectName}
                  placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                  onSelect={(status, option) => {
                      updateState({ selectedProjectName: option.name, 
                        selectedProjectMasterId: option.value 
                      })
                  }}
                  allowClear={true}
                  onClear={() => {
                      updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                  }}
                /> 
              </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default SalesPaymentFilterComp;
