import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Transfer, Skeleton } from 'antd';
//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { MultipleSelectDropdown } from '../../../general/MultipleSelectDropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
import * as filter from 'lodash.filter';
import { getUserDefinedName } from '../../../../utils/index';

function SchoolDrawer(props) {
  const closeDrawer = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addCourseDrawerVisible: false
      }
    })
  }

  const updateSubjectComp = (courseTermId) => {
    let termSubjects = props.termSubjects || {};
    if (!termSubjects[courseTermId] && props.subjectList && props.subjectList[1]) {
      let subjectList = [];
      (props.subjectList[1] || []).forEach(function (sub) {
        subjectList.push({
          key: (sub.subjectId),
          title: sub.name,
          subjectCode: sub.subjectCode,
          courseTermId: courseTermId
        });
      });
      termSubjects[courseTermId] = {
        mockData: subjectList,
        targetKeys: [],
        selectedKeys: []
      }
      props.updateState({ termSubjects })
    }
  }

  const deleteSubjectComp = (courseTermId) => {
    let termSubjects = props.termSubjects || {};
    delete termSubjects[courseTermId];
    props.updateState({ termSubjects })
  }


  const onChange = (nextTargetKeys, direction, moveKeys, courseTermId) => {
    let termSubjects = props.termSubjects || {};
    termSubjects[courseTermId] = {
      ...termSubjects[courseTermId],
      targetKeys: nextTargetKeys
    }
    props.updateState({
      termSubjects
    });
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys, courseTermId) => {
    let termSubjects = props.termSubjects || {};
    termSubjects[courseTermId] = {
      ...termSubjects[courseTermId],
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    }
    props.updateState({
      termSubjects
    });
  };

  const getCourseTermSubjectList = () => {
    let subjectList = [];
    (Object.keys(props.termSubjects || {})).forEach(function (courseTermId) {
      (props.termSubjects[courseTermId].targetKeys || []).forEach(function (subjectId) {
        subjectList.push({
          subjectId: subjectId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          courseTermId: courseTermId
        });
      });
    });
    return subjectList;
  }

  const getPrerequisiteCourseList = () => {
    let courseList = [];
    (props.prerequisiteCourseIds || []).forEach(function (courseId) {
      courseList.push({
        prerequisiteCourseId: courseId,
        relationshipId: (props.companyInfo || {}).relationshipId,
      });
    });
    return courseList;
  }

  const haveSubjectsInAllTerms = () => {
    // check if any term not have subjects and list down the keys of term which do not have subjects so that we can show specific error
    let flag = true;
    const {termSubjects = {}, emptyTermIdList = []} = props;
    for (const key in termSubjects) {
      if (termSubjects[key].targetKeys.length === 0) {
        if(flag){
          flag = false;
        }
        props.updateState({ emptyTermIdList : [...(emptyTermIdList || []), Number(key)] })
      }
    }
    return flag;
  }

  const handleSubmit = (props) => {
    let subjectList = getCourseTermSubjectList();
    
    if (haveSubjectsInAllTerms() && props.courseCode && props.courseName && props.selectedDeptId && (props.courseTermIds || []).length && (subjectList || []).length) {
      const payload = {
        courseCode: props.courseCode,
        courseName: props.courseName,
        relationshipId: (props.companyInfo || {}).relationshipId,
        createdByEmpId: (props.userInfo || {}).userId,
        deptId: props.selectedDeptId,
        // prerequisiteCourseRefList: getPrerequisiteCourseList(),
        prerequisiteCourseRefList: (props.prerequisiteCourseIds || []).map(courseId => ({prerequisiteCourseId: courseId, relationshipId: props.companyInfo?.relationshipId})),
        subjectList: subjectList,
        courseId: props.courseId,
        version: props.version,
      };

      props.pushModalToStack({
        modalBody: <FormattedMessage id={props.courseData?'schoolManagement.school.confirmation.edit':'schoolManagement.school.confirmation.create'} defaultMessage='' />,
        handleSubmit: () => {
          props.saveSchoolCourse(payload, props);
        }
      });

    } else {
      props.updateState({
        submittedOnce: true,
      })
    }
  }

  const newDepartment= (deptData) => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addDepartmentDrawerVisible: true,
        userInfo: props.userInfo,
        deptData: deptData
      },
    });
  };

  const newCourseTerm = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addCourseTermDrawerVisible: true,
      },
    });
  };



  return (
    <Drawer
      title=
          {getUserDefinedName(props.courseData
                ? "schoolManagement.course.edit"
                : "schoolManagement.course.new", props)}
      width={720}
      keyboard={false}
      zIndex={1}
      destroyOnClose={true}
      onClose={closeDrawer}
      maskClosable={false}
      className="custom-drawer"
      visible={props.schoolDrawers.addCourseDrawerVisible}
      afterVisibleChange={(visible) => {
        if (visible) {
          //props.initCalls();
        }
      }}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            loading={props.saveLoading}
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </>
      }
    >
        <Skeleton loading={props.listLoading} paragraph={{ rows: 20 }} active>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName("schoolManagement.course.name", props)}
            <span className="required">*</span>
          </div>
          <TextBox
            type="text"
            value={props.courseName || null}
            maxLength={50}
              countStyle={{top:"40px"}}
            onChange={(e) => {
              props.updateState({
                courseName: e.target.value,
              });
            }}
            placeholder={getUserDefinedName("schoolManagement.course.name", props)}
          />
          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || !!props.courseName;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName("schoolManagement.course.code", props)}
            <span className="required">*</span>
          </div>
          <TextBox
            type="text"
            value={props.courseCode || null}
            maxLength={20}
              countStyle={{top:"40px"}}
            onChange={(e) => {
              props.updateState({
                courseCode: e.target.value,
              });
            }}
            placeholder= {getUserDefinedName("schoolManagement.course.code", props)}

          />
          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || !!props.courseCode;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName("prerequisiteCourses.text", props)}
          </div>
          <MultipleSelectDropdown
            items={props.courseList[1] || []}
            placeholder = {getUserDefinedName("prerequisiteCourses.text", props)}
            mode="multiple"
            onDeSelect={(value) => {
              let { prerequisiteCourseIds = {} } = props;
              prerequisiteCourseIds = filter(
                prerequisiteCourseIds,
                (courseId) => {
                  return courseId !== value;
                }
              );
              props.updateState({ prerequisiteCourseIds });
            }}
            valueKeyName="courseName"
            optionKeyName="courseId"
            addressLineKey1="courseCode"
            selectedValue={props.prerequisiteCourseIds || []}
            onSelect={(optId) => {
              let prerequisiteCourseIds = props.prerequisiteCourseIds || [];
              if (prerequisiteCourseIds.indexOf(optId) === -1) {
                prerequisiteCourseIds.push(optId);
              } else {
                prerequisiteCourseIds = prerequisiteCourseIds.filter(
                  (el) => {
                    return el !== optId;
                  }
                );
              }
              props.updateState({
                prerequisiteCourseIds,
              });
            }}
          />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName("offeringDepartment.text", props)}
            <span className="required">*</span>
          </div>
          <Dropdown
            items={props.departmentList[1] || []}
            optionKeyName="deptId"
            placeholder={getUserDefinedName("offeringDepartment.text", props)}
            valueKeyName="deptName"
            value={props.selectedDeptId}
            onSelect={(selectedValue, option) => {
              props.updateState({ selectedDeptId: selectedValue });
            }}
            required={true}
            canAddNew={true}
            onAddNewClick={() => {
              newDepartment();
            }}
          />

          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || !!props.selectedDeptId;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={24}>
          <div className='i-label'>
            {getUserDefinedName("courseTerm.text", props)}
            <span className="required">*</span>
          </div>
          <MultipleSelectDropdown
            items={props.courseTermList[1] || []}
            placeholder={getUserDefinedName("courseTerm.text", props)}
            mode="multiple"
            onDeSelect={(value) => {
              let { courseTermIds = {} } = props;
              courseTermIds = filter(courseTermIds, (courseTermId) => {
                return courseTermId !== value;
              });
              deleteSubjectComp(value);
              props.updateState({ courseTermIds });
            }}
            valueKeyName="termName"
            optionKeyName="courseTermId"
            selectedValue={props.courseTermIds || []}
            onSelect={(optId) => {
              let courseTermIds = props.courseTermIds || [];
              if (courseTermIds.indexOf(optId) === -1) {
                updateSubjectComp(optId);
                courseTermIds.push(optId);
              } else {
                deleteSubjectComp(optId);
                courseTermIds = courseTermIds.filter((el) => {
                  return el !== optId;
                });
              }
              props.updateState({
                courseTermIds,
              });
            }}
            canAddNew={true}
            onAddNewClick={() => {
              newCourseTerm();
            }}
          />
          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || !!props.courseTermIds.length;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>
      </Row>

      {(props.courseTermIds || []).map((courseTermId, i) => {
        let courseTerm =
          (props.courseTermList[1] || []).find(
            (ind) => ind.courseTermId === courseTermId
          ) || {};

        return (

          <Row gutter={[16, 16]} key={`transfer ${i}` }>
            <Col span={24}>
              <div className='i-label'>
                {courseTerm.termName}
              </div>
              <Transfer
                showSearch
                listHeight={300}
                dataSource={props.termSubjects[courseTermId].mockData}
                titles={[getUserDefinedName('All Subjects', props), `${courseTerm.termName} ${getUserDefinedName('Subjects', props)}`]}
                targetKeys={props.termSubjects[courseTermId].targetKeys}
                selectedKeys={props.termSubjects[courseTermId].selectedKeys}
                onChange={(nextTargetKeys, direction, moveKeys) => {
                  onChange(
                    nextTargetKeys,
                    direction,
                    moveKeys,
                    courseTermId
                  );
                }}
                onSelectChange={(
                  sourceSelectedKeys,
                  targetSelectedKeys
                ) => {
                  onSelectChange(
                    sourceSelectedKeys,
                    targetSelectedKeys,
                    courseTermId
                  );
                }}
                render={(item) => item.title}
                listStyle={{ 
                  width: "calc(50% - 22px)",
                  border: !(props.termSubjects[courseTermId].targetKeys || []).length && props.submittedOnce ? `1px solid red` : '',
                  height: '250px'
                }}
              />
              <div>
                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || !(props.emptyTermIdList || []).includes(courseTerm.courseTermId);
                  }}
                  message={getUserDefinedName("requiredSubjects.missing.msg", props)}
                />
              </div>
            </Col>
          </Row>
         
        );
      })}
  </Skeleton>
    </Drawer>
  );
}

export default injectIntl(SchoolDrawer);
