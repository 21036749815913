import React, { useState } from 'react';
import config from '../../../../../config/environmentConfig';
import { injectIntl } from 'react-intl';
import { Steps, Button, Upload, Col, Row } from 'antd';
import { Dropdown } from '../../../../general/Dropdown';
import { InboxOutlined } from '@ant-design/icons';
import frameImport from "../../../../../assets/images/Frame-import.png";
import successImg from "../../../../../assets/images/Framesuccess.png";
import failImg from "../../../../../assets/images/Groupfail.png";
import { showToasterMessage } from '../../../../../utils';
import * as XLSX from "xlsx";
import DownloadCSV from '../../../../../utils/downloadCsv';
import { StatusCategoryModal } from '../../subStageComp';
import { SubSourceComp } from '../../subSourceComp';
import { TextBox } from '../../../../general/TextBox';
const { Dragger } = Upload;
const { Step } = Steps;

function ImportLeads(props) {
    const { companyInfo } = props;
    const [dropdownValues, setDropdownValues] = useState([]);



    const secondStep = () => {

        const propss = {
            name: 'file',
            multiple: false,
            accept: '.csv', // Restrict to CSV files
            beforeUpload(file) {
                // Check if the file type is CSV
                if (file.type !== 'text/csv') {
                    alert('You can only upload CSV files!');
                    return Upload.LIST_IGNORE; // Prevent the upload
                }
                
                handleFileInputChange(file)
                // Allow the file to be processed locally
                return false; // This prevents the upload from happening
            },
            onChange(info) {

                const { status } = info.file;
                if (status !== 'uploading') {
                    handleFileInputChange(info?.file)
                }
                if (status === 'done') {
                    handleFileInputChange(info?.file)
                } else if (status === 'error') {
                    handleFileInputChange(info?.file)
                }
            },
            onDrop(e) {
                Array.from(e.dataTransfer.files).forEach(file => {
                    if (file.type !== 'text/csv') {
                        alert('You can only drop CSV files!');
                    } else {
                        handleFileInputChange(file);
                    }
                });
            },
        };
        
        
        const handleFileRead = (e) => {
            const content = e.target.result;
            const workbook = XLSX.read(content, { type: "binary" });

            const sheetName = workbook?.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            props.updateState({
                fileHeader: jsonData[0],
                totaData: jsonData.length
            });
            const ans = jsonData[0]?.map((item) => {
                if (props.hederOfCsv?.includes(item)) {
                    return item;
                }
            });

            const val = ans.map((item, i) => {
                if (item) {
                    return false
                } else {
                    return true
                }
            })
            props.updateState({
                headerMandatoryValues: val
            })
            setDropdownValues(ans);
        };

        const handleFileInputChange = (e) => {


            const file = e;
            props.updateState({
                fileToUpload: file,
            });
            if (file) {
                const reader = new FileReader();
                reader.onloadend = handleFileRead;
                reader.readAsBinaryString(file);
            }
        };


        return (<>
        <Row gutter={[24,16]}>

            <Col span={12}>
                        <div className='i-label'>Select Name<span className='required'>*</span></div>
                        <Dropdown
                            items={props.crmLeadFormList.filter((obj) => obj.isActive)}
                            valueKeyName='formName'
                            optionKeyName='crmLeadFormSettingId'
                            value={props.crmLeadFormSettingId}
                            placeholder={'Select Form'}
                            onSelect={
                                (optionValue, option) => {
                                    const selectedLeadForm = props.crmLeadFormList.find((obj => obj.crmLeadFormSettingId === optionValue));
                                    let camp = (props.crmCampaignList[props.pageNumber || 1] || []).find((item) => item.campaignId === selectedLeadForm?.campaignId);

                                    props.getProjectById({ relationshipId: props.companyInfo.relationshipId, projectMasterId: camp?.projectMasterId })
                                   let hederOfCsv = JSON.parse(selectedLeadForm.formFieldsSetting||[])?.map(item=>item.label);
                                    props.updateState({
                                        formFieldsSetting: JSON.parse(selectedLeadForm.formFieldsSetting),
                                        formName: option.name,
                                        crmLeadFormSettingId: optionValue,
                                        selectedLeadForm,
                                        isWalkInLead: selectedLeadForm.isWalkInLead ? true : false,
                                        radius: selectedLeadForm.radius,
                                        getProjectdata: true,
                                        hederOfCsv
                                    });
                                }
                            }
                        />
                    </Col>
        </Row>
          { (props.formFieldsSetting || []).length ?   <div className='second-step custom-upload'>

                <div className='secont-step-heding'>Upload File</div>
                <div className='second-step-content upload-list'>

                    <div className='right-item'>
                        <Dragger className="custom-dragger" {...propss}
                        >
                            <p className="ant-upload-drag-icon" style={{ fontSize: "24px" }}>
                                <InboxOutlined style={{ fontSize: "24px" }} />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                banned files.
                            </p>
                        </Dragger>


                    </div>

                    <div className="left-item">
                        <div className='secont-step-heding' >Download Sample File</div>
                        <img src={frameImport} alt="" />
                        <p>Download a sample XLS template to see an example of the format required.</p>
                    <DownloadCSV headers={props.hederOfCsv || []} />
                    </div>

                </div>

            </div>:""}
        </>
        );
         }
       const thirdStep = () => {
        const handleChange = (index) => (selectedValue) => {
            const newDropdownValues = [...dropdownValues];
            newDropdownValues[index] = selectedValue;
            const val = [...props.headerMandatoryValues]
            val[index] = false
            props.updateState({
                headerMandatoryValues: val
            })
            setDropdownValues(newDropdownValues);


        };
        const handlePopUp = (callBack) => {
            const modalData = {
                title: "confirmation",
                modalBody: <StatusCategoryModal callBack={callBack} {...props} />,
                width: 500,
                handleSubmit: () => {
                },
            };
            props.showModal(modalData);
        }
    
        const handlePopUpSource = (callBack) => {
            const modalData = {
                title: "confirmation",
                modalBody: <SubSourceComp callBack={callBack} {...props} />,
                width: 500,
                handleSubmit: () => {
                },
            };
            props.showModal(modalData);
        }
        return (
            <>
                <div className='third-step'
                >
                    <div className='third-step-heading'>
                        <div>File Header</div>
                        <div>Mapping value</div>
                        <div>&nbsp;</div>
                    </div>
                    <ul className='third-step-content' style={{ listStyle: 'none' }}>

                        {props.fileHeader?.map((head, i) => (
                            <li key={i}>
                                <div className='content-value' >{head}</div>
                                <div className={`content-value`}>
                                    <Dropdown
                                        items={(props.hederOfCsv || [])}
                                        onSelect={(selectedValue) => {
                                            handleChange(i)(selectedValue);
                                        }}
                                        value={dropdownValues[i] || ""}
                                        placeholder={"Select Fields"}
                                    />
                                </div>

                                <div className='content-value-error'>
                 
                                </div>
                            </li>
                        ))}

                    </ul>
                </div>

                <div>
                <Col span={8}>
                        <div className='i-label'>Source<span className='required'>*</span></div>
                          <>
                            <div onClick={() => {
                            const callBack = (data) => {

                            props.updateState({
                                selectedLeadSource: { id: data.crmLeadSourceId, name: data.leadSourceName }
                            })
                            }
                            handlePopUpSource(callBack)
                            }}>
                            <TextBox
                                value={
                                    props.selectedLeadSource?.name || ""
                                }
                                placeholder={'Enter Parent Category'}
                            />
                            </div>

                            </>
                    </Col>

                    <Col span={8}>
                        <div className='i-label'>Status<span className='required'>*</span></div>
                      
                         <>

                            <div onClick={() => {
                            const callBack = (data) => {
                            props.updateState({
                                selectedLeadStatus: { id: data.leadStatusId, name: data.statusName }
                            });
                            }

                            handlePopUp(callBack);
                            }}>
                            <TextBox
                                value={
                                    props.selectedLeadStatus?.name || ""
                                }
                                placeholder={'Enter Parent Category'}
                            />
                            </div>

                            </>
                    </Col>
                </div>
            </>
        );
        };

    const fourthstep = () => {
        return (
            <>
                {props.isuploded && props.resData.SuccessRecords.length ?
                    <div className='error-page'>
                        <div className='part-1'>We've successfully uploaded a specific number of records. For any data with errors, you can download and re-upload them to ensure accuracy.</div>
                        <div className='part-2'>
                            <div className='error-page-img'>
                                <img src={successImg} alt="" />
                            </div>
                            <div className='error-info'>
                                <div className='heading-count'>{props.resData?.SuccessRecords?.length}</div>
                                <p>
                                    <b>Great news!</b> Out of the <b>{props.totaData - 1}</b>  entries you submitted, <b>{props.resData?.SuccessRecords?.length}</b> have been successfully uploaded. Congratulations on a job well done!
                                </p>
                            </div>
                        </div>
                        {props.resData.ErrorRecords.length ? <div className='part-3'>
                            <div className='error-page-img'>
                                <img className='' src={failImg} alt="" />
                            </div>
                            <div className='error-info'>
                                <div className='heading-count'>{props.resData?.ErrorRecords?.length}</div>
                                <p>
                                    Out of <b>{props.totaData - 1}</b>  entries, {props.resData?.ErrorRecords?.length} require your attention. No problem! We've compiled the details of these entries for you to review and correct. Simply download the file, make the necessary adjustments, and re upload.
                                </p>
                            </div>
                            <div className='error-record-btn'>
                                <button onClick={() => {
                                    // props.downloadExelFile({ errorDataList: props.resData?.ErrorRecords, relationshipId: props.companyInfo.relationshipId }, props)
                                }}>Download Error File</button>
                            </div>
                        </div> : ""}

                    </div> : <div className='error-page'>

                        <div className='part-3'>
                            <div className='error-page-img'>
                                <img className='' src={failImg} alt="" />
                            </div>
                            <div className='error-info'>
                                <div className='heading-count'>Opps!</div>
                                <p>
                                    It seems that there was an issue with the entire dataset. No worries, though! Please download the file provided, make the necessary corrections, and re-upload it to ensure accurate data.
                                </p>
                            </div>
                            <div className='error-record-btn'>
                                <button onClick={() => {
                     
                                    // props.downloadExelFile({ errorDataList: props.resData?.ErrorRecords, relationshipId: props.companyInfo.relationshipId }, props)
                                }}><DownloadCSV headers={props.errorHeader} data={props.detailsError} /></button>
                            </div>
                        </div>

                    </div>}
            </>
        )
    }
    const steps = [

        {
            title: "Upload File",
            description: "Upload Your Excel File",
            content: secondStep(),
        },
        {
            title: "Seamless Data Integration",
            description: "Map Your Fields",

            content: thirdStep(),
        },
        {
            title: "Review & Confirm",
            description: "Your Data, Your Way",

            content: fourthstep(),
        },
    ];


    const next = () => {
        const current = props.current + 1
        props.updateState({ current });
    }

    const prev = () => {
        const current = props.current - 1
        props.updateState({ current });
    }





    return (
        <div className="template-stepbar-modal">
            <Steps current={props.current}>
                {steps.map((item) => (
                    <Step key={item.title} title={item.title} description={item.description} />
                ))}
            </Steps>
            <div className="steps-content">{steps[props.current].content}</div>
            <div className="steps-action">
                {props.current > 0 && (props.current < steps.length - 1) && (
                    <Button style={{ marginRight: 8 }} onClick={() => prev()}>
                        Previous
                    </Button>
                )}
                {props.current < steps.length - 1 && (
                    <Button type="primary" onClick={() => {
                        if (props.fileToUpload) {
                            if (!(props.current === steps.length - 2)) {

                                next(props)
                            }

                            if (props.current === steps.length - 2) {
                                if (!props.headerMandatoryValues.includes(true)) {
                                    const resultObject = props.fileHeader?.reduce(
                                        (acc, key, index) => {
                                            acc[`${key}`?.trim()] = dropdownValues[index]
                                            return acc;
                                        },
                                        {}
                                    );
                                    const callback = () => {
                                        next(props)
                                    }
                                    const mapedData = {
                                        relationshipId: companyInfo.relationshipId,
                                        mapping: resultObject,
                                    };

                                    const lead = {
                                        relationshipId: props.companyInfo.relationshipId,
                                        leadSourceId: props.selectedLeadSource?.id,
                                        leadSourceName: props.selectedLeadSource?.name,
                                        leadStatusId: props.selectedLeadStatus?.id,
                                        statusName: props.selectedLeadStatus?.name,
                                        crmLeadDetailsList: props.formFieldsSetting,
                                        crmLeadEmpAssignedList: props.crmLeadEmpAssignedList,
                                        createdByEmpId: props.userInfo.relationshipEmployeeId,
                                        updatedByEmpId: props.userInfo.relationshipEmployeeId,
                                        crmLeadFormSettingId: props.crmLeadFormSettingId,
                                        formName: props.formName,
                                        crmLeadTeamAssignedList: props.crmLeadTeamAssignedList,
                            
                                    };

                                    props.importLeadFromCsv(
                                        {
                                            mappedData: JSON.stringify(mapedData || ""),
                                            fileToUpload: props.fileToUpload,
                                            lead:JSON.stringify(lead || {})
                                        },
                                        props,
                                        callback
                                    );


                                } else {
                                    props.updateState({
                                        submitOnce: true
                                    })
                                }
                            }

                        } else {
                            showToasterMessage({
                                messageType: "error",
                                description: "Please select a file",
                            });
                        }
                    }}>
                        Next
                    </Button>
                )}
                {props.current === steps.length - 1 && (
                    <Button
                        type="primary"
                        onClick={() => {
                            props.popModalFromStack()
                        }}
                    >
                        Done
                    </Button>
                )}
            </div>
        </div>
    );
}

export default injectIntl(ImportLeads);
