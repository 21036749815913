import React from "react";
import "./general.scss";

export const EmptyBox = ({ boxSize }) => {


  return (
    <>
      <div className={boxSize ? "emptyDataBox": "emptyData"}></div>
    </>
  );
};
