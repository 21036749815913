import axios from "axios";
import config from "../../../config/environmentConfig";
import { SMS_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getSchool = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation
        }/school/getSchools?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
        }&pageNumber=${(payload.pageNumber || 1) - 1}&createdByEmpId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.SCHOOL_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getSchoolCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/school/countSchool?relationshipId=${payload.relationshipId}&createdByEmpId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.SCHOOL_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const saveSchool = (payload) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/school/saveOrUpdate`,
        payload
      )
      .then((response) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteSchool = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/school/delete`, payload)
      .then(res => {
        // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // props.getFees && props.getFees(props);
        getSchool(props)(dispatch);
        //props.getFeeCount && props.getFeeCount(props);
       // showToasterMessage({ messageType: 'success', description: 'Term & Fee deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.SCHOOL_LIST_PAGE_RESET });
  };
};
