import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col } from 'antd';

//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
// import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { getUserDefinedName } from '../../../../utils';
function DepartmentDrawer(props) {
  const closeDrawer = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addDepartmentDrawerVisible: false,
        deptData: {}
      }
    })
  }

  const handleSubmit = (props) => {
    if (props.deptName && props.deptCode) {
      const payload = {
        relationshipId: (props.companyInfo || {}).relationshipId,
        createdByEmpId: (props.userInfo || {}).userId,
        deptName: props.deptName,
        deptCode: props.deptCode,
        deptId: props.deptId,
        version: props.version,
      };

      props.pushModalToStack({
        modalBody: <FormattedMessage id={props.deptData?'schoolManagement.school.confirmation.edit':'schoolManagement.school.confirmation.create'} defaultMessage='' /> ,
        handleSubmit: () => {
          props.saveDepartment(payload, props);
        }
      });

    } else {
      props.updateState({
        submittedOnce: true,
      })
    }
  }

  return (
    <Drawer
      title={
          getUserDefinedName(props.deptData
                ? "drawer.schoolManagement.department.heading.editDepartment"
                : "drawer.schoolManagement.department.heading.createDepartment", props)
      }
      width={720}
      keyboard={false}
      zIndex={1}
      destroyOnClose={true}
      onClose={closeDrawer}
      maskClosable={false}
      className="custom-drawer"
      visible={props.schoolDrawers.addDepartmentDrawerVisible}
      afterVisibleChange={(visible) => {
        if (visible) {
          //props.initCalls();
        }
      }}
      footer={
        <>
        <CustomButton
          intlId="confirmation.cancel"
          defaultMessage=""
          type="default"
          key={0}
          onClick={closeDrawer}
        />
        <CustomButton
          loading={props.saveLoading}
          intlId="confirmation.submit"
          defaultMessage=""
          htmlType="submit"
          onClick={() => handleSubmit(props)}
          key={1}
        />
        </>
      }
    >
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={[16, 4]}>
          <Col className="text-left" span={12}>
            <span className="">
              {/* <FormattedMessage
                id="modal.common.project.departmentName"
                defaultMessage=""
              /> */}
              {getUserDefinedName("modal.common.project.departmentName", props)}
            </span>
            <span className="required">*</span>
          </Col>
          <Col className="text-left" span={12}>
            <span className="">
              {/* <FormattedMessage id="departmentCode.text" defaultMessage="" /> */}
              {getUserDefinedName("departmentCode.text", props)}
            </span>
            <span className="required">*</span>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <TextBox
              // className={(props.submittedOnce && (props.duration <= 0 || !props.duration)) ? 'input-text-error' : ''}
              type="text"
              className="textbox-pd"
              maxLength={40}
              countStyle ={{top:"20px"}}
              value={props.deptName || null}
              onChange={(e) => {
                props.updateState({
                  deptName: e.target.value,
                });
              }}
              placeholder={getUserDefinedName("modal.common.project.departmentName", props)}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.deptName;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>
          <Col span={12}>
            <TextBox
              // className={(props.submittedOnce && (props.duration <= 0 || !props.duration)) ? 'input-text-error' : ''}
              type="text"
              maxLength={20}
              countStyle={{top:"20px"}}
              value={props.deptCode || null}
              onChange={(e) => {
                props.updateState({
                  deptCode: e.target.value,
                });
              }}
              placeholder={getUserDefinedName("departmentCode.text", props)}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.deptCode;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>
        </Row>
      </Form>
    </Drawer>

  );
}

export default injectIntl(DepartmentDrawer);
