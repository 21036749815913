import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmployeeComponent from '../../../components/company/Employee';
import { fetchAllEmployees, deleteEmployee, showModal, hideModal,  fetchUserPreferences, saveUserPrefrences,pushModalToStack,popModalFromStack } from '../../../actions/commonActions';
import find from 'lodash.find';
import { fetchDataIfNeeded } from '../../../utils';
import { fetchAllUsers,deleteUsers} from '../../settings/userManagement/action';


class EmployeeSetup extends Component {
  constructor(props) {
    super(props);
    this.state =  {
      pageSize: 25,
      pageNumber: 1,
      searchedText: '',
      isDataRendered: false,
      txPreferences: { txType: "employeeList", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['firstName', 'lastName', 'emailAddress', 'departmentName', 'jobTitleName', 'managerName', 'hierarchyName'],
      txAvailableColumn: {
        firstName: true,
        lastName: true,
        jobTitleName: true,
        managerName: true,
        hierarchyName: true,
        hierarchyLevel: true,
        workPhone: true,
        emailAddress: true,
        departmentName: true, 
        empType: true,
        jobRoleName: true,
    },
    };
  }

  componentDidMount(props) {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      userId: (this.props.userInfo || {}).userId
    }
    this.props.fetchAllEmployees(payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    const companyInfo = this.props.companyInfo || {};
    this.props.fetchAllUsers({relationshipId: companyInfo.relationshipId});
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "employeeList", templateName: "standard" }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
        let gridPreference = txPreferences.gridPreference
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting
        });
      }
      else{
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }
  }

  render() {
    return <EmployeeComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />;
  }

}

const mapStateToProps = (state) => {
  return {
    allEmployee : state.common.employees,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    listLoading: state.common.listLoading,
    allPreferences: state.common.allPreferences,
    allCompanyUsers: state.settings.allCompanyUsers,
    isAlifApp: state.common.isAlifApp,
    permissions: state.auth.permissions,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllEmployees,
  deleteEmployee, 
  showModal, 
  hideModal,
  fetchUserPreferences, 
  saveUserPrefrences,
  popModalFromStack,
  pushModalToStack,
  fetchAllUsers,
  deleteUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSetup);
