import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, BUSINESS_EXPENSE_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchBusinessExpenses = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/relationshipExpense/getRelationshipExpensesByRelationshipId?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}&startDate=2021-04-01%2000:00:00.0&endDate=2022-03-31%2023:59:59.0`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchBusinessExpensesAll = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/relationshipExpense/getAllRelationshipExpenses?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const getBusinessExpensesCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/relationshipExpense/getAllRelationshipExpenseCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_COUNT;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_LIST_PAGE_RESET });
  }
}