import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {showModal, pushModalToStack, popModalFromStack, hideModal} from '../../../../actions/commonActions';
import LeadWorkflowComp from '../../../../components/drawer/leads/leadWorkflow';
import {createLeadWorkFlow} from './action';
import {getTeam} from "../../../company/EmployeeMeta/Team/action"
import { getTeamMembersByTeamName } from '../../../modal/modalBody/leads/createLead/action';
import { getAllLeadStatus } from '../../../modal/modalBody/leads/createLead/action';
import { fetchCrmLeadCampaign } from '../../../leads/LeadCampaigns/action';
import { getAllWorkFlow } from '../../../leads/LeadWorkFlow/Lead/action';
class LeadWorkflowCreateDrawer extends Component {
    constructor(props) {
        super(props);
        const needToInit = Object.keys(props.data)?.length ? true :false;
        
        this.state = {
            // these are added to pre-populate the diffrent stages
            relationshipId: props.companyInfo.relationshipId,
            description: needToInit ?props.data?.description:"",
            workflowName: needToInit ?props.data?.workflowName:"",
            campaignId: needToInit ?props.data?.campaignId:0,
            campaignName: needToInit ?props.data?.campaignName:"",
            workflowMasterId: needToInit ?props.data?.workflowMasterId:0,
            captureStage:needToInit ?props.data?.crmWorkflowTriggerSet?.find((e)=>e.type === "captureStage"):{}, // single object for capture condition
            trackingStage:needToInit ?props.data?.crmWorkflowTriggerSet?.filter((e)=>e.type === "trackingStage"):[{type:"trackingStage",relationshipId:props.companyInfo.relationshipId}], //  multiple tracking condition can be added
            qualificationStage:needToInit ?props.data?.crmWorkflowTriggerSet?.find((e)=>e.type === "qualificationStage"):{}, // single object for approve condition
            conversionStage:needToInit ?props.data?.crmWorkflowTriggerSet?.filter((e)=>e.type === "conversionStage"):[{type:"conversionStage",relationshipId:props.companyInfo.relationshipId}], // multiple conversion condition can be added,
            listToadd:[`captureStage`,`trackingStage`,`qualificationStage`,`conversionStage`],// add this for the convience of payload
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 1000,
          }
        this.props.getTeam(payload);
        this.props.getAllLeadStatus(payload);
        this.props.fetchCrmLeadCampaign(payload);

    }
    componentWillReceiveProps(props){
        // TODO handel the edit condition for populate the diffrent stage data in the state

    }


    render() {
        return <LeadWorkflowComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        teamList: state.employee.teamList,
        relationshipId: state.common.companyInfo.relationshipId,
        employeeByTeamName:state.crm.employeeByTeamName,
        crmLeadStatusList: state.crm.crmLeadStatusList,
        crmCampaignList: state.crm.crmCampaignList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal, 
    pushModalToStack,
    popModalFromStack,getTeam
    ,getTeamMembersByTeamName
    ,getAllLeadStatus,createLeadWorkFlow,
    fetchCrmLeadCampaign,getAllWorkFlow,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadWorkflowCreateDrawer);