import React from "react";
import './details.scss';
import { Tabs } from 'antd';
import Communication from "./communications";
import Contact from "./contacts";
import Address from "./address";
import Tasks from "./tasks";
import TaxIdentification from "./taxIdentification";
import SalesLedger from "./ledgerTransactions/salesLedger";
import Invoice from "./ledgerTransactions/invoice";
import Organization from './organization';
import Product from './productSummary';
import Activities from "./activities";
import { FormattedMessage } from "react-intl";


const { TabPane } = Tabs;


function callTabApi(key, props) {
    let payload = props.payload;
    payload.filters = {
        customerId: props.customerId
    }
    props.updateState({activeTabKey: key})
    switch(key){
        case '1':
            if(props?.customer?.leadId){
                props.fetchLeadActivityTrack({...payload, pageSize: 1000, crmLeadId: props.customer.leadId || ''});
            }
            props.getCustomerActivitiesTrack(payload);
            break;

        case '2': //address
            props.fetchCustomerAddresses(payload);
            break;
        case '3': //Contacts
            props.fetchAllContacts(payload);
            break;

        case '4': //Communications
            props.fetchCustomerCommunications(payload);
            break;

        case '5': //Tax
            props.fetchCustomerDetailsByCustomerId(payload);
            break;

        case '6': //Tasks  -- activities renamed as tasks 
            props.fetchCustomerActivities(payload);
            break;

        case '7': //Ledger Summary
            props.fetchCustomerLedgerTxSummaryList(payload);
            break;

        case '8': //Sales
            props.fetchCustomerInvoiceList(payload);
            break;

        // case '9': // Sales
        //     props.fetchCustomerInvoiceList(payload);
        //     break;

        case '10': // Product
            props.getCustomerPurchaseItems(payload);
            props.fetchCustomerPurchaseItemsCount(payload);
            break;

        case '11': // Oranization
            break;

        default:
            break;
    }


}

const Details = (props) => {
    
    return (<>
    
            <div className="view-container" style={{margin: '10px 0px'}}>
                <Tabs 
                    type="line"
                    defaultActiveKey= '1'
                    activeKey={props.activeTabKey || "1"}
                    tabBarGutter={32}
                    onChange={(key) => {
                        callTabApi(key, props);
                    }} 
                    animated={false}
                >
                 
                    <TabPane tab="Activities" key="1">
                        <Activities {...props} />
                    </TabPane>

                    <TabPane tab="Tasks" key="6">
                        <Tasks {...props} />
                    </TabPane>

                    {/* <TabPane tab="Transactions" key="22">
                        <Transactions {...props} />
                    </TabPane> */}

                    <TabPane tab="Address" key="2">
                        <Address {...props} />
                    </TabPane>

                    <TabPane tab="Contacts" key="3">
                        <Contact {...props} />
                    </TabPane>

                    <TabPane tab="Communications" key="4">
                        <Communication {...props} />
                    </TabPane>

                    <TabPane tab={<FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />} key="5">
                        <TaxIdentification {...props} />
                    </TabPane>

                    {/* <TabPane tab={<FormattedMessage id='salesTransactions.text' defaultMessage='' />} key="7">
                        <LedgerTransactions {...props} />
                    </TabPane> */}

                    <TabPane tab={'Ledger Summary'} key="7">
                        <SalesLedger {...props} />
                    </TabPane>

                    <TabPane tab={'Sales'} key="8">
                        <Invoice {...props} />
                    </TabPane>

                    {/* <TabPane tab="Sales" key="9">
                        <Sales {...props} />
                    </TabPane> */}
                   
                    <TabPane tab={'Product'} key="10">
                        <Product {...props} />
                    </TabPane>
                    
                    {props.customerDetails?.customerType === 'business' ? 
                    
                        <TabPane tab={'Organization'} key="11">
                            <Organization {...props} />
                        </TabPane>
                        :
                        ''
                    }

                    {/* <TabPane tab="To-Do List" key="7">
                        <TodoList {...props} />
                    </TabPane> */}
                </Tabs>
               
            </div>
        </>
    )
};

export default Details;
