import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, TX_FILE_UPLOAD_ACTION } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const fetchItem = (payload, history) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    !payload.hideSpinner && dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
    // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/getCustomerSummary?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}&module=${payload.module}`)
    .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/getCustomerSummary?relationshipId=${payload.relationshipId}&pageSize=100&pageNumber=0${searchedTextQuery}`)
      .then(res => {
        const data = {
          pageNo: 0,
          list: res.data
        }
        dispatch({ type: TX_FILE_UPLOAD_ACTION.TRANSACTION_CUSTOMERS, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = TX_FILE_UPLOAD_ACTION.TRANSACTION_CUSTOMERS;
          err.data = {
            pageNo: 0,
            list: []
          };
        }
      })
  }
}