import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, INVENTORY_VALUATION_ACTION_LIST } from '../../../../static/constants';
import {  showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchAllInventoryValuationList = (payload, history) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios 
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getAllInventoryValuation?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 100}&pageNumber=${payload.pageNumber - 1 || 0 }&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = res.data
        dispatch({ type: INVENTORY_VALUATION_ACTION_LIST.ALL_INVENTORY_VALUATION_LIST, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = INVENTORY_VALUATION_ACTION_LIST.ALL_INVENTORY_VALUATION_LIST;
        }
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}


