import React, { useState } from "react";
import './index.scss';
import { Col, Divider, Result, Row, Skeleton, Tabs } from "antd";
import FeesIcon from "../../../assets/images/fees.png";
import RazorpayPaymentsComp from "../PaymentGateways/razorpay";
import StripePaymentsComp from "../PaymentGateways/Stripe";
import CardPointe from "../PaymentGateways/cardPointe";
import { getCurrencySymbol } from "../../../utils";

const {TabPane} = Tabs;


const ProformaPayment = (props) =>{
    const {proformaDetails={}, customerDetail, paymentGateways=[]} = props;

    if (!props.isListenerAdded) {
        window.addEventListener('message', function (event) {
            if (!event.data) return '';
            if (event.data && typeof event.data === 'string') {
                var token = JSON.parse(event.data);
                var mytoken = document.getElementById('mytoken') || {};
                mytoken.value = token?.message;
                props.updateState({
                    account: token.message,
                    expiry: token.expiry,
                    validationError: token.validationError,
                })
            }
        }, false);
        props.updateState({isListenerAdded: true});
    }

    const handleTabChange = (activeTab) =>{
        const payload = {
          relationshipId: props.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.updateState({
            activeTab: activeTab
        })
        switch(activeTab){
            case 'RazorPay':
                console.log("razorpayTab action")
                break;
            case 'Stripe':
                console.log("stripeTab action")
                break;
            case 'CardPointe':
                console.log("card_pointeTab action")
                break;
            case 'AuthorizeNet':
                console.log("authorize_netTab action")
                break;
            case 'Cash':
                console.log("cashTab action")
                break;
            default:
                console.log("no tab action");
        }
    }

    const postPaymentProcess = (resObj) =>{
        const paymentResponse = {
            razorpayPaymentId: resObj.razorpay_payment_id,
            razorpayOrderId: resObj.razorpay_order_id,
            razorpaySignature: resObj.razorpay_signature
        }
        props.processRazorpayPayment(paymentResponse, props);

    }

    return <>
        {
            props.saveLoading ? <div className='open_loader'>
                <div className="payment-loader">
                    <div className="pad">
                    <div className="chip"></div>
                    <div className="line line1 mb10"></div>
                    <div className="line line2"></div>
                </div>
                <div className="loader-text">
                    Please wait while we processing your request securely...
                </div>
                </div>

                {/* <Spin size="large" spinning={props.saveLoading || true} tip={'Please Wait...'} /> */}
            </div>
            :
            ''
        }
        <div className="pay_container"> 
            {!props.detailLoading && paymentGateways?.length > 0 ? 
                <>
                    <div className="pay_head">
                        Review and Pay
                    </div>
                    <div className="pay_details_m">
                        <div className="pay_details_l">
                            <Skeleton loading={props.listLoading} paragraph={{ rows: 10}} active>
                                {
                                    proformaDetails?.isInvoiceConverted ?  
                                        <Result
                                            status="success"
                                            title="Payment has already done for this invoice"
                                        />
                                        :
                                        <div className="pay_info">
                                            <div className="pay-tabs">
                                                {paymentGateways?.length > 1 ?
                                                    <Tabs
                                                        animated={{ inkBar: true, tabPane: false }}
                                                        tabBarGutter={16}
                                                        onChange={handleTabChange}
                                                        activeKey={props.activeTab}
                                                        // tabPosition="left"
                                                    >
                                                        {paymentGateways.includes("Razorpay") ? <TabPane tab="Razorpay" key="Razorpay">
                                                            <RazorpayPaymentsComp {...props} postPaymentProcess={postPaymentProcess} />
                                                        </TabPane> 
                                                        : ''}

                                                        {paymentGateways.includes("Stripe") ? <TabPane tab="Stripe" key="Stripe">
                                                            <StripePaymentsComp {...props} />
                                                        </TabPane>
                                                        :''}

                                                        {paymentGateways.includes("CardPointe") ? <TabPane tab={"Card Pointe"} key='card_pointe'>
                                                            <CardPointe {...props} />
                                                        </TabPane>
                                                        :''
                                                        }

                                                        {paymentGateways.includes("AuthorizeNet") ? <TabPane tab="Authorize.Net" key="authorize_net" disabled>
                                                            Authorise.Net Payments
                                                        </TabPane>
                                                        :''
                                                        }

                                                        {paymentGateways.includes("Cash") ? 
                                                            <TabPane tab="Cash" key="cash" disabled>
                                                                By Cash
                                                            </TabPane>
                                                            :
                                                            ''
                                                        }
                                                    </Tabs>
                                                    :
                                                    <>
                                                        {paymentGateways.includes("Razorpay") ? <RazorpayPaymentsComp {...props} postPaymentProcess={postPaymentProcess} />
                                                        : ''}
                                                        {paymentGateways.includes("Stripe") ? <StripePaymentsComp {...props} />
                                                        :''}
                                                    </>
                                                
                                                }
                                            </div>
                                        </div>
                                }
                            </Skeleton>
                        </div>

                        <div className="pay_details_r">
                            <div className="pay_details_inner">
                                <div className="inner_head">
                                    <img src={FeesIcon} alt="fees" height="50px" width="50px" /> <span className="b-text" style={{fontSize: '16px'}}>Payment Details</span>
                                </div>
                                <div className="inner_details">
                                    <div className="b-text pb-5">{proformaDetails?.invoiceData?.relationshipName || ''}</div>
                                    <Skeleton loading={props.listLoading} paragraph={{ rows: 8}} active>
                                        <Row gutter={[8, 8]}>
                                            <Col span={12}>
                                                <div className="b-text">Parent Name</div>
                                                <div className="light-txt">{props.customerDetail.companyName || ''}</div>
                                            </Col>
                                            <Col span={12}>
                                                <div className="b-text">Student Name</div>
                                                <div className="light-txt">{proformaDetails?.invoiceData?.customerInvoiceDetailsList?.[0]?.studentName || ''}</div>
                                            </Col>
                                        </Row>
                                        
                                        <Divider />

                                        <Row gutter={[8, 8]}>
                                            <Col span={19}>
                                                Total Amount
                                            </Col>
                                            <Col span={5}>
                                                {proformaDetails?.invoiceData?.invoiceTotalAmount ? getCurrencySymbol(customerDetail.currencyCode || proformaDetails?.invoiceData?.currencyCode) + proformaDetails?.invoiceData?.invoiceTotalAmount?.toFixed(2) : ''}
                                            </Col>
                                        </Row>
                                    </Skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <Skeleton loading={props.detailLoading} paragraph={{ rows: 8}} active>
                    <Result
                        status="500"
                        title="500"
                        subTitle="Sorry, something went wrong. Please contact admin"
                    />
                </Skeleton>
            }
        </div>
    </>
}

export default ProformaPayment;