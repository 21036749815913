import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { LEDGER_ACTION_LIST, COMMON_ACTIONS } from '../../../../static/constants';
import { getCurrentDateForBackend, showToasterMessage } from '../../../../utils';
import find from 'lodash.find';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchNextAccountNo = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/getNextLedgerAccountNumber?relationshipId=${payload.relationshipId}&chartOfAccountMaster=${payload.chartOfAccountMaster}`)
            .then(res => {
                dispatch({ type: LEDGER_ACTION_LIST.NEXT_LEDGER_ACCOUNT_NO, data: res.data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
                } else {
                    err.actionToCall = LEDGER_ACTION_LIST.NEXT_LEDGER_ACCOUNT_NO;
                }
            })
    }
}


export const createLedgerAccount = (props) => {
    const payload = _generateLedgerAccountPayload(props);
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/createLedgerAccount`, payload)
            .then(res => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                showToasterMessage({ messageType: 'success', description: ((res || {}).data || "") || 'Ledger Created Successfully' })
                props.updateState({
                    ledgerAccountName: undefined,
                    description: undefined,
                    ledgerAccountGroupId: undefined,
                    coaCategoryGroupId: undefined,
                    ledgerAccountNumber: undefined,
                    ledgerBalance: undefined,
                    ledgerAccountNumberBySystem: undefined,
                    chartOfAccountDetailsId:undefined,
                    data:undefined
                });
                props.fetchAllLedgerAccounts && props.fetchAllLedgerAccounts({ relationshipId: props.relationshipId })
            })
            .catch((err) => {
                showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}
// const getDefaultTaxList = (payload) => {
//     payload.defaultTaxObj.relationshipId = payload.relationshipId;
//     return [payload.defaultTaxObj]
// };

const _generateLedgerAccountPayload = (props) => {
    const payload = {
        version: props.version,
        ledgerAccountName: props.ledgerAccountName,
        description: props.description,
        coaCategoryId: props.coaCategoryId,
        ledgerAccountGroupId: props.ledgerAccountGroupId,
        coaCategoryGroupId: props.coaCategoryGroupId,
        accountNumber: props.ledgerAccountNumber,
        // accountingEntry: props.ledgerBalance,
        relationshipId: (props.companyInfo || {}).relationshipId,
        dateCreated: getCurrentDateForBackend(new Date()),
        inBuilt: 0,
        accountingEntry:find(props.allCategoryGroup,{chartOfAccountCategoryGroupId:props.coaCategoryGroupId})?.accountingEntry,
        isLedgerTaxable: props.isLedgerTaxable,
        chartOfAccountDetailsId:props.chartOfAccountDetailsId,
        // inventoryItemDefaultTaxList: getDefaultTaxList(props),
        taxSingleRateId: props.taxSingleRateId,
        taxSingleRatePercent: props.globleTaxRate
    }

    return payload;
}




export const createCategory = (props) => {
    const payload = _generateCategory(props);
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/coaCategory/createCOACategory`, payload)
            .then(res => {
                //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                props.updateState({
                    categoryName: undefined,
                    categoryDescription: undefined,
                    startingAccountNumber: undefined,
                    endingAccountNumber: undefined,
                    chartOfAccountCategoryGroupId: undefined,
                    categoryGroupName: undefined
                })
                showToasterMessage({ messageType: 'success', description: ((res || {}).data || "") || 'Category Created Successfully' })
            })
            .catch((err) => {
                showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
                //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}

const _generateCategory = (props) => {
    const payload = {
        categoryName: props.categoryName,
        categoryDesc: props.categoryDescription,
        coaCategoryGroupId: props.chartOfAccountCategoryGroupId,
        coaCategoryGroupName: props.categoryGroupName,
        minAccountNumber: props.startingAccountNumber,
        maxAccountNumber: props.endingAccountNumber,
        dateCreated: getCurrentDateForBackend(new Date()),
        relationshipId: (props.companyInfo || {}).relationshipId,
        inBuilt: 1
    }

    return payload;
}



export const createLedgerAccountGroup = (props) => {
    const payload = _generateLedgerAccountGroupPayload(props);
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountMaster/createAccountMaster`, payload)
            .then(res => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                showToasterMessage({ messageType: 'success', description: ((res || {}).data || "") || 'Ledger Group Created Successfully' })
            })
            .catch((err) => {
                showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}

const _generateLedgerAccountGroupPayload = (props) => {
    const payload = {
        ledgerAccountGroupName: props.ledgerAccountGroupName,
        coaCategoryId: props.chartOfAccountCategoryId,
        coaCategoryName: props.categoryName,
        coaCategoryGroupId: props.coaCategoryGroupId,
        relationshipId: props.relationshipId,
        description: props.ledgerAccountGroupDescription,
        dateCreated: getCurrentDateForBackend(new Date()),
        inBuilt: 1
    }

    return payload;
}