import { Row, Col, Radio, Input, Tooltip, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { showToasterMessage } from "../../../../../utils";

const TaxPreferenceForm = (props) => {
  // Single state object to manage preference (0, 1, 2) and message
//   const [formData, setFormData] = useState({
//     taxPreference: props.taxPreferedData?.taxPreference || 5, // Default to 0 (exclusive)
//     taxMessage: props.taxPreferedData?.taxMessage || ''
//   });

  // Handler for radio group change
  const handleTaxPreferenceChange = (e) => {
    const selectedValue = e.target.value;

    // Set new preference and reset message if changing
    const taxPreferedData = {
      taxPreference: selectedValue,
      taxMessage: ""
    };

    props.updateState({taxPreferedData});
  };

  // Handler for input change
  const handleInputChange = (e) => {
    const taxPreferedData = { ...props.taxPreferedData, taxMessage: e.target.value };
    props.updateState({taxPreferedData});
  };

  // Save button action
  const handleSave = () => {
    // You can perform the save action here, like making an API call or updating the store
    // props.saveTaxPreferences(formData);
    if(props.taxPreferedData.taxPreference > 2){
        return      showToasterMessage({
            messageType: "error",
            description: "Select Prefered Tax",
        
      })
    }
    
     props.setTaxPreference({
        relationshipId:props.relationshipId,
        taxPreference:JSON.stringify(props.taxPreferedData|| {})
     })
  };

  return (
    <div className="model-container">
      <Row gutter={16} align="middle">
        <Col span={24}>
          <p className="heading">Tax Preference</p>
          <Tooltip placement="right" arrow={false} title="Select how tax is applied to your orders">
            <InfoCircleOutlined />
          </Tooltip>
        </Col>
        <Col span={24}>
          <Radio.Group onChange={handleTaxPreferenceChange} value={props.taxPreferedData?.taxPreference}>
            <Radio value={2}>
            Price Exclusive of Tax
            </Radio>
            <Radio value={1}>
            Price Inclusive of Tax
            </Radio>
            <Radio value={0}>
            Price with Flat Rate
            </Radio>
          </Radio.Group>
        </Col>
        <Col>
        {props.taxPreferedData?.taxPreference === 2 && (
                <Input
                  style={{ marginLeft: 10 }}
                  placeholder="Message for Exclusive Tax"
                  value={props.taxPreferedData?.taxMessage}
                  onChange={handleInputChange}
                />
              )}
        {props.taxPreferedData?.taxPreference === 1 && (
                <Input
                style={{ marginLeft: 10 }}
                placeholder="Message for Inclusive Tax"
                value={props.taxPreferedData?.taxMessage}
                onChange={handleInputChange}
                />
               )}
        {props.taxPreferedData?.taxPreference === 0 && (
                <Input
                style={{ marginLeft: 10 }}
                placeholder="Message for No Tax"
                value={props.taxPreferedData?.taxMessage}
                onChange={handleInputChange}
                />
              )}
        </Col>
        <Col span={24} style={{ marginTop: '20px' }}>
          <Button type="primary" onClick={handleSave}>
            Save Tax Preference
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TaxPreferenceForm;
