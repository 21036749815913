import React from "react";
import "./general.scss";
import {Empty} from "antd";

export const EmptyTableData = ({ type = 0 }) => {

  return (
    <Empty />
  );
};
