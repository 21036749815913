import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Tooltip, Skeleton, Pagination, Checkbox, Popover, Menu, Empty } from 'antd';
import { fetchPaginationDataIfNeeded, showToasterMessage, getMomentDateForUIReadOnly, sortTable, exportExcel, fixedDecimalNumber } from '../../../../utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import PurchaseInvoiceDetail from '../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail';
import PODetail from '../../../../containers/modal/modalBody/supplier/PurchaseOrderDetail';
import RFQDetail from '../../../../containers/modal/modalBody/supplier/RFQDetail';
import RFQSplitDetail from '../../../../containers/modal/modalBody/supplier/RFQSplitDetail';
import { CONSTANTS, ICONS } from '../../../../static/constants';
import XeroInvoice from '../../../../containers/modal/modalBody/common/xero/Invoice';
import { AddAndRemoveSupplierListingColumn } from "../../../general/AddAndRemoveSupplierListingColumn";
import find from 'lodash.find';
import BusinessExpenseDetail from '../../../../containers/modal/modalBody/finance/BusinessExpense';
import DropdownAction from "antd/lib/dropdown";
import { FilterOutlined,DownloadOutlined,FileExcelOutlined, CloudUploadOutlined, InsertRowRightOutlined, DownOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import SupplierDetails from '../../../../containers/supplier/detail';
import PurchaseInvoicePaymentList from '../../../../containers/modal/modalBody/supplier/PurchaseInvoicePaymentList';


const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const PurchaseInvoiceListingComp = (props) => {
  const { purchaseInvoiceList, updateState, companyInfo, purchaseInvoiceCount, pageNumber, selectedItems = [], dir = 0 } = props;

  const showTotal = (total) => {
    return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
    fetchPaginationDataIfNeeded('fetchPurchaseInvoices', 'purchaseInvoiceList', props, payload);
  }

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
    props.resetPaginatedData();
    props.fetchPurchaseInvoices(payload);
  }

  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // }

  // const getActionMenuPermissionData = () => {
  //     const primaryPerm = permissions.primary || [];
  //     const permittedModules = {
  //         firstSection: {
  //             payInvoice: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PAY_INVOICE, PERMISSION_VALUES.CREATE),
  //             updatePurchaseInvoice: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_INVOICE, PERMISSION_VALUES.UPDATE),
  //             clonePurchaseInvoice: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_INVOICE, PERMISSION_VALUES.CREATE),
  //             deletePurchaseInvoice: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_INVOICE, PERMISSION_VALUES.DELETE)
  //         }
  //     };
  //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
  //         if (permittedModules.firstSection[moduleName] === false) {
  //             delete permittedModules.firstSection[moduleName];
  //         }
  //     })
  //     return permittedModules;
  // }

  // const openAuditDrawer = (props, data) => {
  //     props.updateState({
  //         title: <div className="drawer-title">
  //             Audit Trail
  //         </div>,
  //         auditDrawerVisible: true,
  //         deletApiUrl: 'deleteAuditTrailPurchaseInvoice',
  //         masterKey: 'invoiceMasterId',
  //         masterKeyValue: data.invoiceMasterId,
  //         apiUrl: 'saveAuditTrailPurchaseInvoice',
  //         supplierId: data.supplierId, 
  //         trailList: data.auditTrailPurchaseInvoiceList,
  //         updateTrailListInTransaction: (list) =>{
  //             data.auditTrailPurchaseInvoiceList = list;
  //         } 
  //     });
  // }

  // const addItemConfirmationModal = (props, text, txData) => {
  //     const { showModal, companyInfo } = props;
  //     const labelName = <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />;
  //     const modalBody = <span>
  //         <FormattedMessage
  //             id='setItem.confirmation'
  //             defaultMessage={``}
  //         />
  //         {labelName} ?
  //   </span>;

  //     let payload = {
  //         userStatus: text,
  //         relationshipId: companyInfo.relationshipId,
  //         invoiceMasterId: txData.invoiceMasterId,
  //         custosupplierIdmerId: txData.supplierId
  //     };

  //     const modalData = {
  //         modalBody,
  //         handleSubmit: () => {
  //             props.updateUserStatus(payload, props);
  //             props.hideModal();
  //             props.popModalFromStack();
  //         },
  //     };
  //     showModal(modalData);
  // }



  // const updateUserStatus = (props, txData) => {
  //     let payload = { formData: {} };
  //     const formData = payload.formData;
  //     const { pushModalToStack } = props;

  //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
  //         addItemConfirmationModal(props, formData.textBoxValue, txData);
  //     } else {
  //         const data = {
  //             title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
  //             formData,
  //             hideFooter: true,
  //             modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE },
  //             handleSubmit: (formData = {}) => {
  //                 if (formData.textBoxValue && formData.textBoxValue.length) {
  //                     addItemConfirmationModal(props, formData.textBoxValue, txData);
  //                 } else {
  //                     data.formData.submittedOnce = true;
  //                     pushModalToStack(data);
  //                 }
  //             }
  //         };
  //         pushModalToStack(data);
  //     }
  // }

  // const actionMenu = (param, permittedModules) => (
  //     <Menu className="cell-action-dropdown">
  //         {/* <Menu.Item key="8" onClick={() => {
  //             openAuditDrawer(props, param.data);
  //         }}>
  //             <i className="fa fa-history" />
  //                  View Audit Trail
  //                 </Menu.Item> */}
  //         {/* {
  //             (permittedModules.firstSection.payInvoice && param.data.status !== 'Pending Approval' && param.data.status !== 'Rejected' && param.data.status !== 'Void')
  //                 ? (
  //                     <Menu.Item key="5" value="edit" onClick={() => {
  //                         const modalData = {
  //                             title: 'Purchase Payment',
  //                             modalBody: <PayInvoice invoiceData={param.data} />,
  //                             width: 600,
  //                             hideFooter: true,
  //                             wrapClassName: 'supplier-payment-modal'
  //                         };
  //                         props.showModal(modalData);
  //                     }}>
  //                         <i className="fa fa-credit-card" />
  //                         <Link>Pay Invoice</Link>
  //                     </Menu.Item>
  //                 )
  //                 : <Fragment />
  //         } */}
  //         {
  //             (permittedModules.firstSection.updatePurchaseInvoice && param.data.status !== 'void')
  //                 ? (
  //                     <Menu.Item key="0">
  //                         <i className="fa fa-pencil" />
  //                         <Link
  //                             to={
  //                                 {
  //                                     pathname: '/admin/purchase-invoice-update',
  //                                     state: {
  //                                         supplierId: param.data.supplierId,
  //                                         invoiceMasterId: param.data.invoiceMasterId,
  //                                         update: true
  //                                     }
  //                                 }}
  //                         >Update Purchase Invoice</Link>
  //                     </Menu.Item>
  //                 )
  //                 : <Fragment />
  //         }


  //         {
  //             permittedModules.firstSection.clonePurchaseInvoice
  //                 ? (
  //                     <Menu.Item key="1">
  //                         <i className="fa fa-clone" />
  //                         <Link
  //                             to={
  //                                 {
  //                                     pathname: '/admin/purchase-invoice',
  //                                     state: {
  //                                         supplierId: param.data.supplierId,
  //                                         invoiceMasterId: param.data.invoiceMasterId,
  //                                         clone: true
  //                                     }
  //                                 }}
  //                         >Clone Purchase Invoice</Link>
  //                     </Menu.Item>
  //                 )
  //                 : <Fragment />
  //         }
  //         <Menu.Item key="2" onClick={() => {
  //             updateUserStatus(props, param.data);
  //         }}>
  //             <i className="fa fa-pencil" />
  //                  Update User Status
  //                 </Menu.Item>
  //     </Menu>
  // );
  
  const openInvoiceDetails = (data) => {
    if (data.expenseMasterId) {
      const expensePayload = {
        expenseMasterId: data.expenseMasterId
      }
      this.props.pushModalToStack({
        modalBody: <BusinessExpenseDetail {...this.props} expensePayload={expensePayload} />,
        width: '100%',
        hideFooter: true,
        wrapClassName: 'modal-custom-detail'
      })
    } else {
      const invoicePayload = {
        supplierId: data.supplierId,
        invoiceMasterId: data.invoiceMasterId
      }
      props.pushModalToStack({
        modalBody: <PurchaseInvoiceDetail {...props} invoicePayload={invoicePayload} />,
        width: '100%',
        hideFooter: true,
        wrapClassName: 'modal-custom-detail'
      })
    }
  }

  const openPODetails = (data) => {
    const poPayload = {
      supplierId: data.supplierId,
      poMasterId: data.poMasterId
    }
    props.pushModalToStack({
      modalBody: <PODetail {...props} poPayload={poPayload} />,
      width: '100%',
      hideFooter: true,
      wrapClassName: 'modal-custom-detail'
    })
  }

  const openRFQDetails = (data) => {
    const rfqPayload = {
      rfqMasterId: data.rfqMasterId,
      rfqChildMasterId: data.rfqChildMasterId
    }
    props.pushModalToStack({
      modalBody: data.rfqChildMasterId ? <RFQSplitDetail {...props} rfqPayload={rfqPayload} /> : <RFQDetail {...props} rfqPayload={rfqPayload} />,
      width: '100%',
      hideFooter: true,
      wrapClassName: 'modal-custom-detail'
    })
  }

  const _isColumnToHide = (columnName) => {
    return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
  }


  // const saveColumnIndex = (columnState) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data);
  // }

  // const saveColumnWidth = (columnState, width) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data, props);
  // }

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //     if (event.column) {
  //         moveEvent = event;
  //     }
  //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
  //         saveColumnIndex(moveEvent.columnApi.getColumnState());
  //     }
  // }

  // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //     event.api.resetRowHeights();
  //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
  //         //event.api.resetRowHeights();
  //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
  //             props.gridApi.sizeColumnsToFit();
  //             setTimeout(() => {
  //                 const columnArray = event.columnApi.getColumnState();
  //                 saveColumnWidth(columnArray);
  //             }, 1000);
  //         } else {
  //             saveColumnWidth(event.columnApi.getColumnState());
  //         }
  //     }
  // }



  // const permittedModules = getActionMenuPermissionData();
  // const columnDefs = [
  //     {
  //         field: 'isChecked',
  //         colId: 'isChecked_1',
  //         headerComponentFramework: () => <Fragment />,
  //         cellRendererFramework: (params) => {
  //             return <Checkbox
  //                 onClick={() => {
  //                     params.node.setDataValue('isChecked_1', !params.data.isChecked)
  //                 }}
  //                 checked={params.data.isChecked} />
  //         },
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 70,
  //         resizable: true
  //     },
  //     {
  //         field: 'isXeroUploaded',
  //         colId: 'isXeroUploaded_1',
  //         headerComponentFramework: () => <Tooltip trigger="click" placement="top" title={<FormattedMessage id='tooltip.isInvoiceUploadedToXero' defaultMessage='' />}>
  //             Xero <i className="fa fa-question-circle-o pl5 cursor-pointer" />
  //         </Tooltip>,
  //         cellRendererFramework: (params) => {
  //             return params.data.isXeroUploaded ? <img src={XeroIcon} alt="" style={{ height: "25px" }} /> : "";
  //         },
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 70,
  //         hide: _isColumnToHide('isXeroUploaded'),
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseInvoice.listing.header.invoiceNo' defaultMessage='' />;
  //         },
  //         field: "invoiceNumber",
  //         colId: 'invoiceNumber_1',
  //         hide: _isColumnToHide('invoiceNumber'),
  //         suppressMovable: true,
  //         minWidth: 80,
  //         maxWidth: 400,
  //         rowDrag: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openInvoiceDetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.supplierInvoiceNumber' defaultMessage='' />;
  //         },
  //         field: "supplierInvoiceNumber",
  //         colId: 'supplierInvoiceNumber_1',
  //         hide: _isColumnToHide('supplierInvoiceNumber'),
  //         suppressMovable: true,
  //         minWidth: 160,
  //         maxWidth: 400,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openInvoiceDetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseInvoice.listing.header.poNo' defaultMessage='' />;
  //         },
  //         field: "supplierPoNumber",
  //         colId: 'supplierPoNumber_1',
  //         hide: _isColumnToHide('supplierPoNumber'),
  //         suppressMovable: true,
  //         minWidth: 170,
  //         maxWidth: 400,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openPODetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.rfqNumber' defaultMessage='' />;
  //         },
  //         field: "rfqNumber",
  //         colId: 'rfqNumber_1',
  //         hide: _isColumnToHide('rfqNumber'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openRFQDetails(link.data)
  //         }}>{link.value}</div>,
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseInvoice.listing.header.supplier' defaultMessage='' />;
  //         },
  //         field: "supplierName",
  //         colId: 'supplierName_1',
  //         hide: _isColumnToHide('supplierName'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" >
  //             <div onClick={() => {
  //                 props.history.push('suppliers/details', {
  //                     supplierId: link.data.supplierId,
  //                     supplierName: link.data.supplierStoreName,
  //                     // supplier: data
  //                 })
  //             }}>{link.value}</div>
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='customer.salesInvoice.listing.header.isCashInvoice' defaultMessage='' />   </div>;
  //         },
  //         field: "isCashInvoice",
  //         colId: 'isCashInvoice_1',
  //         cellRendererFramework: (params) => {
  //             return params.node.data.isCashInvoice ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
  //         },
  //         hide: _isColumnToHide('isCashInvoice'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='supplier.purchaseInvoice.listing.header.invoiceAmount' defaultMessage='' />   </div>;
  //         },
  //         field: "invoiceTotalAmount",
  //         colId: 'invoiceTotalAmount_1',
  //         hide: _isColumnToHide('invoiceTotalAmount'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => {
  //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value} </span> : "-"
  //         },
  //         resizable: true,
  //         valueGetter: (link) => {
  //             // return link.data.invoiceTotalAmount ? Number(link.data.invoiceTotalAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
  //             return link.data.invoiceTotalAmount ? fixedDecimalAndFormateNumber(Number(link.data.invoiceTotalAmount)) : "-";
  //         },
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='supplier.purchaseInvoice.listing.header.paymentAmount' defaultMessage='' />  </div>;
  //         },
  //         field: "totalPaymentMade",
  //         colId: 'totalPaymentMade_1',
  //         hide: _isColumnToHide('totalPaymentMade'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => {
  //             return link.value && link.value !== '-' ?
  //                 <div>
  //                     {/* {link.data.totalNumberOfPayments} {link.data.totalNumberOfPayments === 1 ? 'Payment' : 'Payments'} of */}
  //                     <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value} </span>
  //                 </div>
  //                 : "-"
  //         },
  //         valueGetter: (link) => {
  //             // return link.data.totalPaymentMade ? Number(link.data.totalPaymentMade).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
  //             return link.data.totalPaymentMade ? fixedDecimalAndFormateNumber(Number(link.data.totalPaymentMade)) : "-";
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerSalesEnquiryNumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerInquiryNumber',
  //         hide: _isColumnToHide('customerInquiryNumber'),
  //         colId: "customerInquiryNumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerInquiryNumber || '-'}
  //         </div>,
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerRfqNumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerRFQNumber',
  //         hide: _isColumnToHide('customerRFQNumber'),
  //         colId: "customerRFQNumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerRFQNumber || '-'}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerPONumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerPONumber',
  //         hide: _isColumnToHide('customerPONumber'),
  //         colId: "customerPONumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerPONumber || '-'}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='debitApplied.text'/>   </div>;
  //         },
  //         field: "debitApplied",
  //         hide: _isColumnToHide('debitApplied'),
  //         colId: 'debitApplied_1',
  //         sortable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value} </span> : "-"
  //         },
  //         valueGetter: (link) => {
  //             return  link.data.debitApplied ? (Number(link.data.debitApplied || 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='supplier.purchaseInvoice.listing.header.dueAmount' defaultMessage='' />   </div>;
  //         },
  //         field: "invoiceDueAmount",
  //         colId: 'invoiceDueAmount_1',
  //         hide: _isColumnToHide('invoiceDueAmount'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => {
  //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value} </span> : "-"
  //         },
  //         valueGetter: (link) => {
  //             // return (link.data.invoiceTotalAmount - (link.data.totalPaymentMade+link.data.debitApplied)) ? Number(link.data.invoiceTotalAmount - (link.data.totalPaymentMade+link.data.debitApplied)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
  //             return (link.data.invoiceTotalAmount - (link.data.totalPaymentMade+link.data.debitApplied)) ? fixedDecimalAndFormateNumber(Number(link.data.invoiceTotalAmount - (link.data.totalPaymentMade+link.data.debitApplied))) : "-";
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseInvoice.listing.header.invoiceDate' defaultMessage='' />;
  //         },
  //         resizable: true,
  //         field: "invoiceDate",
  //         colId: 'invoiceDate_1',
  //         hide: _isColumnToHide('invoiceDate'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             <Tooltip placement="topLeft" title={(link.data.invoiceDate && getMomentDateForUIReadOnly({date: link.data.invoiceDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
  //             {getMomentDateForUIReadOnly({date: link.data.invoiceDate, format})}
  //             </Tooltip>
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseInvoice.listing.header.invoiceDueDate' defaultMessage='' />;
  //         },
  //         resizable: true,
  //         field: "invoiceDueDate",
  //         colId: 'invoiceDueDate_1',
  //         hide: _isColumnToHide('invoiceDueDate'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             <Tooltip placement="topLeft" title={(link.data.invoiceDueDate && getMomentDateForUIReadOnly({date: link.data.invoiceDueDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
  //             {getMomentDateForUIReadOnly({date: link.data.invoiceDueDate, format})}
  //             </Tooltip>
  //         </div>,
  //     },    
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='dueDays.text' defaultMessage='' />;
  //         },
  //         field: "dueDays",
  //         hide: _isColumnToHide('dueDays'),
  //         colId: 'dueDays_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (params) => {
  //             return (params.data.status === 'void' || params.data.status ==='paid') ?<div> - </div> : <div>{(params.value < 0) ?
  //                 <div><span className="status-code dueDays">{Math.abs(params.value || 0)} <FormattedMessage id='drawer.customer.days'  /></span>
  //                 </div> : <div><span className="status-code paid"><FormattedMessage id='notDue.text'  /></span></div>
  //                 } 
  //                 </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseInvoice.listing.header.paymentTerm' defaultMessage='' />;
  //         },
  //         field: "paymentTermName",
  //         colId: 'paymentTermName_1',
  //         hide: _isColumnToHide('paymentTermName'),
  //         width: (props.txColumnSetting['paymentTermName'] || {}).width,
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseInvoice.listing.header.systemStatus' defaultMessage='' />;
  //         },
  //         field: "status",
  //         colId: 'status_1',
  //         hide: _isColumnToHide('status'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         resizable: true,
  //         cellRendererFramework: (params) => {
  //             return (params.value === 'Rejected' && params.data.rejectionReason) ?
  //                 <div>
  //                     <Tooltip placement="topLeft" title={params.data.rejectionReason}>
  //                         <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span>
  //                     </Tooltip>
  //                 </div> :
  //                 <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span> </div>
  //         }
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.status' defaultMessage='' />;
  //         },
  //         field: "userStatus",
  //         hide: _isColumnToHide('userStatus'),
  //         colId: "userStatus_1",
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                  {link.value? <span className="status-code" style={{background: link.data.userStatusColor}}>{link.value || ''}</span> :''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.project' defaultMessage='' />;
  //         },
  //         field: "project",
  //         hide: _isColumnToHide('project'),
  //         colId: "project_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                   {link.data.projectMasterId ? <span>{link.data.projectMasterId + '-' +link.data.projectName }</span> : ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.refNumber' defaultMessage='' />;
  //         },
  //         field: "reference",
  //         colId: "reference_1",
  //         hide: _isColumnToHide('reference'),
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.data.referenceNumber || ''}
  //             </div>
  //         },
  //     },
  // ];

  // if (Object.keys(permittedModules.firstSection).length) {
  //     columnDefs.push({
  //         headerComponentFramework: () => {
  //             return <div className="cursor-pointer">
  //                 <Popover
  //                     content={<AddAndRemoveSupplierListingColumn  {...props} />}
  //                     title={<div className="text-center">Add/Remove </div>}
  //                     placement="left"
  //                     trigger="click"
  //                 >
  //                     <i className='fa fa-bars' />
  //                 </Popover>
  //             </div>;
  //         },
  //         cellRendererFramework: (param) => {
  //             return <Dropdown overlay={actionMenu(param, permittedModules)} trigger={['click']}>
  //                 <Button className="action-btn small">
  //                     <i className="fa fa-ellipsis-v" />
  //                 </Button>
  //             </Dropdown>
  //         },
  //         colId: 'action_1',
  //         amount: 'action',
  //         pinned: 'right',
  //         lockPosition: true,
  //         resizable: false,
  //         suppressMovable: true,
  //         minWidth: 52,
  //         maxWidth: 52,
  //     });
  // }

  // const onModelUpdated = (params) => {
  //     if ((purchaseInvoiceList[pageNumber] || []).length && !props.isRowHeightSet) {
  //         setTimeout(() => {
  //             params.api.resetRowHeights();
  //         }, 1000)
  //         props.updateState({
  //             isRowHeightSet: true
  //         })
  //     }
  //     if (props.tableDataReRendered) {
  //         //params.api.resetRowHeights();
  //         props.updateState({
  //             tableDataReRendered: false
  //         })
  //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.PI_LISTING

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     }
  // }

  // const onDataRendered = (params) => {
  //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //     if (Object.keys(txColumnSetting).length) {
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.PI_LISTING

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.width = 52;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     } else {
  //         params.api.sizeColumnsToFit();
  //     }
  // }


  // const onGridReady = (params) => {
  //     if (props.isColumnFitTable) {
  //         params.api.sizeColumnsToFit();
  //     }
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi,
  //     });
  //     onDataRendered(params);
  // }

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  // const breadCrumbList = [
  //     {
  //         name: 'Dashboard',
  //         link: 'dashboard'
  //     },
  //     {
  //         name: 'Supplier',
  //         link: 'suppliers'
  //     }, {
  //         name: 'Purchase Invoice',
  //     }, {
  //         name: 'List'
  //     }
  // ];
  
  const itemSelection = (item) => {
    let selectItem = find(selectedItems, { supplierId: Number(item.invoiceMasterId) });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(obj => obj.invoiceMasterId !== item.invoiceMasterId);
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  }
  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < purchaseInvoiceList[pageNumber].length; i++) {
      let item = purchaseInvoiceList[pageNumber][i];
      let selectItem = find(selectedItems, { supplierId: Number(item.invoiceMasterId) });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });

  }
  const isCheckedAll = () => {
    if (!purchaseInvoiceList || !purchaseInvoiceList[pageNumber] || purchaseInvoiceList[pageNumber].length === 0) {
      return false;
    }
    for (let i = 0; i < purchaseInvoiceList[pageNumber].length; i++) {
      let usr = purchaseInvoiceList[pageNumber][i];
      let selectItem = find(selectedItems, { invoiceMasterId: Number(usr.invoiceMasterId) });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  }

  const sortColumn = (e) => {
    sortTable(document.getElementById('purchase-invoice-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  const checkMenu = (e) => {
    return (

      <Menu className="row-action-dropdown">

        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="1" onClick={() => {
          selectAll(true);
        }
        }>
          <CheckCircleOutlined />
          Select all
        </Menu.Item>

        <Menu.Item key="2" onClick={() => {
          selectAll(false);
        }
        }>
          <CloseCircleOutlined />
          Unselect all
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            const invoiceList = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         invoiceList.push(obj.data);
            //     }
            // });
            selectedItems.forEach((obj) => {
              invoiceList.push(obj);
            });

            const modalData = {
              title: "Xero Invoice Upload",
              modalBody: <XeroInvoice type={"Supplier"} {...props} invoiceList={invoiceList} />,
              width: 1100,
              hideFooter: true,
            };
            let isXeroUploaded = false;
            invoiceList.forEach((data) => {
              if (data.isXeroUploaded) {
                isXeroUploaded = true;
              }
            });

            if (isXeroUploaded) {
              return showToasterMessage({ messageType: "error", description: <FormattedMessage id='xero.invoice.slected.message' defaultMessage='' /> })
            }

            if (invoiceList.length) {
              props.showModal(modalData);
            } else {
              showToasterMessage({
                messageType: "error",
                description: <FormattedMessage id='xero.invoice.slected.before.message' defaultMessage='' />,
              });
            }
          }}
        >
          <CloudUploadOutlined />
          Upload to Zero
        </Menu.Item>
      </Menu >
    )
  }
  const exportExcelData = (purchaseInvoiceList, props) => {
    const dataList = (purchaseInvoiceList || []).map((item) => {
      return {
        "Invoice #": item.invoiceNumber || "",
        "Supplier": item.supplierName || "",
        "Supplier Inv#": item.supplierInvoiceNumber || "",
        "Cash Invoice":item.isCashInvoice ? "YES":"NO",
        "Invoice Amount": Number(item.invoiceTotalAmount || 0).toFixed(2),
        "Paid": fixedDecimalNumber(item.totalPaymentMade || 0),
        "Due Amount": fixedDecimalNumber((item.invoiceTotalAmount - item.totalPaymentMade) || 0),

        "Date Created": getMomentDateForUIReadOnly({
          date: new Date(item.invoiceDate),
          format,
        }),
        "Due Date": getMomentDateForUIReadOnly({
          date: new Date(item.invoiceDueDate),
          format,
        }),
        // "Credit Applied": Number(item.creditApplied).toFixed(2),
        "Past Due":
          item.status === "void" || item.status === "paid"
            ? "-"
            : item.dueDays < 0
            ? Math.abs(item.dueDays || 0) + " Days"
            : "Not yet due",
        "Payment Term": item.paymentTermName || "",
        "System Status": item.status,
        "Status": item.userStatus,
        "Project": item.projectName || "",
      };
    });
    exportExcel(dataList, "Purchase Invoice");
  };
  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="excel"
          onClick={() => {
            const invoiceList = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         invoiceList.push(obj.data);
            //     }
            // });
            selectedItems.forEach((obj) => {
              invoiceList.push(obj);
            });

            if (invoiceList.length) {
              exportExcelData(invoiceList);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <FileExcelOutlined />
          Excel File
        </Menu.Item>

      
      </Menu>
    );
  };
  const openPurchaseInvoicePaymentList = (data) => {
    const invoicePayload = {
      supplierId: data.supplierId,
      invoiceMasterId: data.invoiceMasterId,
    };
    props.pushModalToStack({
      title: <div className='ant-modal-title'><FormattedMessage id='Payments' defaultMessage='' /></div>,
      modalBody: (
        <PurchaseInvoicePaymentList {...props} invoicePayload={invoicePayload} />
      ),
      width: 1200,
      hideFooter: true,
      maskClosable: true,
      innerHeight: 900,
      wrapClassName: "remark-modalms",
    });
  };

  return (
    <>
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="purchase.ivoice.table.heading"
                defaultMessage=""
              />
            </div>       
                <div className="vertical-sep" />
                <div>{props.purchaseInvoiceCount}</div>             
          </div>
          <div className="right-actions">
            <button
              className="action-btn"
              onClick={() => {
                props.updateState({
                  purchaseInvoiceFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined className='mr5' />
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>
              <Popover
                content={<AddAndRemoveSupplierListingColumn {...props} />}
                title={
                  <div className="text-center">
                    <FormattedMessage
                      id="common.addRemove"
                      defaultMessage=""
                    />{" "}
                  </div>
                }
                placement="leftTop"
                trigger="click"
                arrow="right"
                // visible={props.showColPop}
              >
                <button className='action-btn' onClick={() => { props.updateState({ showColPop: !props.showColPop }) }}>
                  <InsertRowRightOutlined className='mr5'/> Columns
                </button>
              </Popover>
              <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
              <span>
                <DownloadOutlined /> &nbsp;{" "}
                <FormattedMessage
                  id="common.action.dropdown.downloadAs"
                  defaultMessage=""
                />
              </span>
            </DropdownAction>
          </div>
          </Skeleton>
        </div>
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 13 }}>

        <div className="table-container">
          <table id="purchase-invoice-table">
            <thead>
              <tr>
                <th>
                  <div className="check-cell">
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                    <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                      <DownOutlined />
                    </DropdownAction>
                  </div>
                </th>
                {!_isColumnToHide("invoiceNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseInvoice.listing.header.invoiceNo"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("supplierInvoiceNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseOrder.form.gridHeader.supplierInvoiceNumber"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("supplierPoNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseInvoice.listing.header.poNo"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("rfqNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="common.rfqNumber"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("supplierName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseInvoice.listing.header.supplier"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("isCashInvoice") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.isCashInvoice"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("invoiceTotalAmount") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseInvoice.listing.header.invoiceAmount"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalPaymentMade") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseInvoice.listing.header.paymentAmount"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerInquiryNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customerSalesEnquiryNumber.text"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerRFQNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customerRfqNumber.text"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerPONumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customerPONumber.text"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("debitApplied") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="debitApplied.text"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("invoiceDueAmount") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseInvoice.listing.header.dueAmount"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("invoiceDate") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseInvoice.listing.header.invoiceDate"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("invoiceDueDate") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseInvoice.listing.header.invoiceDueDate"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("dueDays") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="dueDays.text" defaultMessage="" />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("paymentTermName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseInvoice.listing.header.paymentTerm"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("status") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseInvoice.listing.header.systemStatus"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("userStatus") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="common.status" defaultMessage="" />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("project") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="common.project" defaultMessage="" />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("reference") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseOrder.listing.header.refNumber"
                      defaultMessage=""
                    />
                    <i className={ICONS.SORT}></i>
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {(purchaseInvoiceList[pageNumber] || []).length ?
                purchaseInvoiceList[pageNumber].map((rowData, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Checkbox
                          onClick={() => {
                            itemSelection(rowData);
                          }}
                          checked={
                            find(selectedItems, {
                              invoiceMasterId: Number(
                                rowData.invoiceMasterId
                              ),
                            })
                              ? true
                              : false
                          }
                        />
                      </td>
                      {!_isColumnToHide("invoiceNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openInvoiceDetails(rowData);
                            }}
                          >
                            {rowData.invoiceNumber}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("supplierInvoiceNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openInvoiceDetails(rowData);
                            }}
                          >
                            {rowData.supplierInvoiceNumber || <div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("supplierPoNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openPODetails(rowData);
                            }}
                          >
                            {rowData.supplierPoNumber || <div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("rfqNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openRFQDetails(rowData);
                            }}
                          >
                            {rowData.rfqNumber || <div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("supplierName") ? (
                        <td>
                          <div className="cursor-pointer">
                            <div
                              onClick={() => {
                                props.pushModalToStack({  
                                 
                                  modalBody: <SupplierDetails {...props}
                                  supplierId= {rowData.supplierId}
                                  supplierName={rowData.supplierStoreName}
                                  
                              />,
                              width: '100%',
                              hideTitle: true,
                              hideFooter: true,
                              wrapClassName: "new-transaction-wrapper",
                              onCancel: () => {
                                const payload={
                                  relationshipId:props.companyInfo.relationshipId,
                                  pageSize:props.pageSize,
                                  pageNumber:props.pageNumber
                                }                           
                                  props.popModalFromStack();
                                  props.fetchPurchaseInvoices(payload);
                           
                              }
                              })
                             }}
                            >
                              {rowData.supplierName}
                            </div>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("isCashInvoice") ? (
                        <td>
                          {rowData.isCashInvoice ? (
                            // <img
                            //   src={checkIcon}
                            //   style={{ height: "15px", width: "15px" }}
                            //   alt="Invoice"
                            // />
                            "Yes"
                          ) : (
                            // <img
                            //   src={crossIcon}
                            //   style={{ height: "10px", width: "10px" }}
                            //   alt="Invoice"
                            // />
                            "No"
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("invoiceTotalAmount") ? (
                        <td>
                          {rowData.invoiceTotalAmount &&
                            rowData.invoiceTotalAmount !== "-" ? (
                            <span>
                              {" "}
                              {rowData.foreignCurrencyIcon ? (
                                <i
                                  className={rowData.foreignCurrencyIcon}
                                ></i>
                              ) : rowData.foreignCurrency ? (
                                <span>{rowData.foreignCurrency}</span>
                              ) : props.companyInfo.currencyIcon ? (
                                <i
                                  className={props.companyInfo.currencyIcon}
                                ></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}
                              {fixedDecimalNumber(rowData.invoiceTotalAmount || 0)}{" "}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("totalPaymentMade") ? (
                        <td>
                          {rowData.totalPaymentMade &&
                            rowData.totalPaymentMade !== "-" ? (
                            <div>
                              <span>
                                <span className="cursor-pointer" onClick={()=>{openPurchaseInvoicePaymentList(rowData)}}>{rowData.paymentCount}</span> {rowData.paymentCount > 1 ? 'Payments': 'Payment'} of
                                <br/>
                              <span>
                                {" "}
                                {rowData.foreignCurrencyIcon ? (
                                  <i
                                    className={rowData.foreignCurrencyIcon}
                                  ></i>
                                ) : rowData.foreignCurrency ? (
                                  <span>{rowData.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalNumber(rowData.totalPaymentMade || 0)}{" "}
                              </span>
                              </span>
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("customerInquiryNumber") ? (
                        <td>
                          <div>{rowData.customerInquiryNumber || <div className="empty-data"></div>}</div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("customerRFQNumber") ? (
                        <td>
                          <div>{rowData.customerRFQNumber || <div className="empty-data"></div>}</div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("customerPONumber") ? (
                        <td>
                          <div>{rowData.customerPONumber || <div className="empty-data"></div>}</div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("debitApplied") ? (
                        <td>
                          {rowData.debitApplied &&
                            rowData.debitApplied !== "-" ? (
                            <span>
                              {" "}
                              {rowData.foreignCurrencyIcon ? (
                                <i
                                  className={rowData.foreignCurrencyIcon}
                                ></i>
                              ) : rowData.foreignCurrency ? (
                                <span>{rowData.foreignCurrency}</span>
                              ) : props.companyInfo.currencyIcon ? (
                                <i
                                  className={props.companyInfo.currencyIcon}
                                ></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}
                              {fixedDecimalNumber(rowData.debitApplied || 0)}{" "}
                            </span>
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("invoiceDueAmount") ? (
                        <td>
                          {rowData.invoiceTotalAmount &&
                            rowData.invoiceTotalAmount !== "-" ? (
                            <span>
                              {" "}
                              {rowData.foreignCurrencyIcon ? (
                                <i
                                  className={rowData.foreignCurrencyIcon}
                                ></i>
                              ) : rowData.foreignCurrency ? (
                                <span>{rowData.foreignCurrency}</span>
                              ) : props.companyInfo.currencyIcon ? (
                                <i
                                  className={props.companyInfo.currencyIcon}
                                ></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}
                              {fixedDecimalNumber((rowData.invoiceTotalAmount - rowData.totalPaymentMade) || 0)}{" "}
                            </span>
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("invoiceDate") ? (
                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.invoiceDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.invoiceDate,
                                  format:
                                    CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {getMomentDateForUIReadOnly({
                                date: rowData.invoiceDate,
                                format,
                              })}
                            </Tooltip>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("invoiceDueDate") ? (
                        <td className='one-line-view'>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.invoiceDueDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.invoiceDueDate,
                                  format:
                                    CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {getMomentDateForUIReadOnly({
                                date: rowData.invoiceDueDate,
                                format,
                              })}
                            </Tooltip>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("dueDays") ? (
                        <td className='one-line-view'>
                          {rowData.status === "void" ||
                            rowData.status === "paid" ? (
                             <div className="empty-data"></div>
                          ) : (
                            <div>
                              {rowData.dueDays < 0 ? (
                                <div className="status-code dueDays">
                                    {Math.abs(rowData.dueDays || 0)}{" "}
                                    <FormattedMessage id="drawer.customer.days" />
                                </div>
                              ) : (
                                <div className="status-code paid">
                                    <FormattedMessage id="notDue.text" />
                                </div>
                              )}
                            </div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("paymentTermName") ? (
                        <td>{rowData.paymentTermName}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("status") ? (
                        <td>
                          {rowData.status === "Rejected" &&
                            rowData.rejectionReason ? (
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={rowData.rejectionReason}
                              >
                                <div
                                  className={[
                                    "status-code",
                                    rowData.status.toLowerCase(),
                                  ].join(" ")}
                                >
                                  <FormattedMessage
                                    id={rowData.status}
                                    defaultMessage={rowData.status}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          ) : (
                            <div>
                              {" "}
                              <div
                                className={[
                                  "status-code",
                                  rowData.status.toLowerCase(),
                                ].join(" ")}
                              >
                                <FormattedMessage
                                  id={rowData.status}
                                  defaultMessage={rowData.status}
                                />
                              </div>{" "}
                            </div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("userStatus") ? (
                        <td>
                          <div>
                            {rowData.userStatus ? (
                              <div
                                className="status-code"
                                style={{
                                  background: rowData.userStatusColor,
                                }}
                              >
                                {rowData.userStatus}
                              </div>
                            ) : (
                              <div className="empty-data"></div>
                            )}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("project") ? (
                        <td>
                          <div>
                            {rowData.projectName ? (
                              <span>
                                {rowData.projectName}
                              </span>
                            ) : (
                              <div className="empty-data"></div>
                            )}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("reference") ? (
                        <td>
                          <div>{rowData.referenceNumber || <div className="empty-data"></div>}</div>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                }):
                <tr key="empty-data">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
            </tbody>
          </table>
        </div>
        </Skeleton>
        {/* <div className="table-action-container">
                        <div className="d-inline-flex"> */}
        {/* <button className="all-checkbox-control">
                                    <Checkbox
                                        checked={isAllChecked}
                                        onChange={(e) => {
                                            toggleAllChecked(!isAllChecked);
                                            props.gridApi.forEachNode(obj => {
                                                obj.setDataValue('isChecked_1', e.target.checked);
                                            })
                                        }}
                                    />
                                </button> */}

        {/* <button className="action-btn" onClick={() => {
                                const invoiceList = [];
                                props.gridApi.forEachNode(obj => {
                                    if (obj.data.isChecked) {
                                        invoiceList.push(obj.data);
                                    }
                                });
                                const modalData = {
                                    title: 'Xero Invoice Upload',
                                    modalBody: <XeroInvoice type={"Supplier"} {...props} invoiceList={invoiceList} />,
                                    width: 1100,
                                    hideFooter: true,
                                };
                                let isXeroUploaded = false;
                                invoiceList.forEach((data) => {
                                    if (data.isXeroUploaded) {
                                        isXeroUploaded = true;
                                    }

                                });

                                if (isXeroUploaded) {
                                    return showToasterMessage({ messageType: "error", description: <FormattedMessage id='xero.invoice.slected.message' defaultMessage='' /> })
                                }

                                if (invoiceList.length) {
                                    props.showModal(modalData);
                                } else {
                                    showToasterMessage({ messageType: "error", description: <FormattedMessage id='xero.invoice.slected.before.message' defaultMessage='' /> })
                                }


                            }}>
                                <Tooltip placement="right" title={<FormattedMessage id='tooltip.uploadToXero' defaultMessage='' />}>
                                    <img src={XeroIcon} alt="" style={{ height: "20px" }} /> <i className="fa fa-upload" />
                                </Tooltip>
                            </button> */}

        {/* </div>
                    </div> */}
        {/* <div className='agGridWrapper'>
                        {props.txColumns.length ? <div className="ag-theme-balham" style={agGridStyle} >
                            <div className="cursor-pointer">
                                <span className="top-button">
                                    <Popover
                                        content={<AddAndRemoveSupplierListingColumn {...props} />}
                                        title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' />  </div>}
                                        placement="left"
                                        trigger="click"
                                    >
                                        <i className='fa fa-bars' />
                                    </Popover>
                                </span>
                                <div className="clearfix"></div>
                            </div>
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={purchaseInvoiceList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onGridReady={onGridReady}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                                onModelUpdated={onModelUpdated}
                                onColumnResized={onColumnResized}
                                onDragStopped={() => {
                                    onColumnMoved({}, true);
                                }}
                                //onColumnMoved={onColumnMoved}
                            >
                            </AgGridReact>
                        </div> : null}
                    </div> */}

      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={purchaseInvoiceCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </Skeleton>
      </div>
    </>
  );
}

export default injectIntl(PurchaseInvoiceListingComp);
