import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS ,CUSTOMER_ACTION_LIST} from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const getTotalOrderCountByCustomerId = (payload, history) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  
      return axios
        .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getTotalOrderCountByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
        .then(res => {
          dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_TOTAL_ORDER_COUNT_BY_CUSTOMER_ID, data: res.data });
  
        })
        .catch((err) => {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
         
        })
    }
  }