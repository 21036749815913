import React from 'react';
//import TabPage from './TabPage.js';
import './index.scss';
import { FormattedMessage } from 'react-intl';
// import { Form } from '@ant-design/compatible';
// import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import '@ant-design/compatible/assets/index.css';
// import { Dropdown } from '../../../../general/Dropdown';
import { MultipleSelectDropdown } from '../../../../general/MultipleSelectDropdown';
import * as filter from 'lodash.filter';
import { Switch, Row, Col, Skeleton } from 'antd';

const DocumentApprovalComponent = (props) => {
    // let gridApi = {};
    // const columnDef = [
    //     {
    //         title: <FormattedMessage id='modal.settings.documentApproval.transaction' defaultMessage='' />,
    //         index: 'approvalType',
    //         key: 'approvalType',
    //         width: '30%',
    //         render: (text, record, index) => (
    //             record.approvalType
    //         )
    //     },
    //     {
    //         title: <FormattedMessage id='modal.settings.documentApproval.approvalStatus' defaultMessage='' />,
    //         index: 'isApprovalRequired',
    //         key: 'isApprovalRequired',
    //         width: '70%',
    //         render: (text, record, index) => (
    //             <div>
    //                 {record.approvalType === 'sales-order'? <div style={{marginTop: '20px'}}>
    //                     <Row gutter={[8, 16]}>
    //                         <Col span={8}>Reviewed By</Col>
    //                         <Col span={16}>
    //                             <MultipleSelectDropdown
    //                                 style={{ width: '100%' }}
    //                                 items={props.employeeList || []}
    //                                 placeholder="Reviewed By"
    //                                 mode='multiple'
    //                                 onDeSelect={(value) => {
    //                                     let { selectedReviewersList } = props
    //                                         selectedReviewersList = filter(selectedReviewersList, (relationshipEmployeeId) => {
    //                                             return relationshipEmployeeId !== value;
    //                                         });
    //                                     props.updateState({ selectedReviewersList });
    //                                 }}
    //                                 valueKeyName='fullName'
    //                                 // valueKeyName2='relationshipEmployeeId'
    //                                 selectedValue={(props.employeeList || []).length ? (props.selectedReviewersList || []) : []}
    //                                 optionKeyName='relationshipEmployeeId'
    //                                 onSelect={(value) => {
    //                                     let selectedReviewersList = props.selectedReviewersList || [];
    //                                     if (selectedReviewersList.indexOf(value) === -1) {
    //                                         selectedReviewersList.push(value);
    //                                     } else {
    //                                         selectedReviewersList = selectedReviewersList.filter((el) => {
    //                                             return el !== value
    //                                     });
    //                                     }
    //                                     props.updateState({
    //                                         selectedReviewersList
    //                                     });
    //                                 }}
    //                                 // canAddNew={true}
    //                                 // onAddNewClick={(payload) => {
    //                                 //     payload.formData = {
    //                                 //     firstName: payload.textEntered,
    //                                 //     submittedOnce: false,
    //                                 //     customerId: (this.props.customer || {}).customerId,
    //                                 //     pageNumber: this.props.pageNumber || 1,
    //                                 //     customerName: this.props.customerName
    //                                 //     }
    //                                 //     this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
    //                                 // }}
    //                                 // canDelete={true}
    //                                 // deleteClickHandler={(payload) => {
    //                                 //     this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER, payload);
    //                                 // }}
    //                             />
             
    //                         </Col>
    //                     </Row>
    //                     <Row>
    //                         <Col span={8}>Approved By</Col>
    //                         <Col span={16}>
    //                             <MultipleSelectDropdown
    //                                 style={{ width: '100%' }}
    //                                 items={props.employeeList || []}
    //                                 placeholder="Reviewed By"
    //                                 mode='multiple'
    //                                 onDeSelect={(value) => {
    //                                     let { selectedApproversList } = props
    //                                         selectedApproversList = filter(selectedApproversList, (relationshipEmployeeId) => {
    //                                             return relationshipEmployeeId !== value;
    //                                         });
    //                                     props.updateState({ selectedApproversList });
    //                                 }}
    //                                 valueKeyName='fullName'
    //                                 // valueKeyName2='relationshipEmployeeId'
    //                                 selectedValue={(props.employeeList || []).length ? (props.selectedApproversList || []) : []}
    //                                 optionKeyName='relationshipEmployeeId'
    //                                 onSelect={(value) => {
    //                                     let selectedApproversList = props.selectedApproversList || [];
    //                                     if (selectedApproversList.indexOf(value) === -1) {
    //                                         selectedApproversList.push(value);
    //                                     } else {
    //                                         selectedApproversList = selectedApproversList.filter((el) => {
    //                                             return el !== value
    //                                     });
    //                                     }
    //                                     props.updateState({
    //                                         selectedApproversList
    //                                     });
    //                                 }}
    //                                 // canAddNew={true}
    //                                 // onAddNewClick={(payload) => {
    //                                 //     payload.formData = {
    //                                 //     firstName: payload.textEntered,
    //                                 //     submittedOnce: false,
    //                                 //     customerId: (this.props.customer || {}).customerId,
    //                                 //     pageNumber: this.props.pageNumber || 1,
    //                                 //     customerName: this.props.customerName
    //                                 //     }
    //                                 //     this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
    //                                 // }}
    //                                 // canDelete={true}
    //                                 // deleteClickHandler={(payload) => {
    //                                 //     this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER, payload);
    //                                 // }}
    //                             />
    //                         </Col>
    //                     </Row>
    //                 </div>:''
    //                 }
    //                 <Switch
    //                     checked={(record.isApprovalRequired === 1)}
    //                     onClick={(checked, e) => {
    //                         saveSetting(record, checked)
    //                     }} 
    //                 />
    //             </div>
    //         )
    //     },

    // ]
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return  <FormattedMessage id='modal.settings.documentApproval.transaction' defaultMessage='' />;
    //         },
    //         field: 'approvalType',
    //         resizable: true,
    //         cellRenderFramework: (params) => <div>
    //             <FormattedMessage id={params.data.approvalType} />
    //         </div>
    //     },
    //     {
    //         field: 'isApprovalRequired',
    //         resizable: true,
    //         cellRendererFramework: (params) => <div>
    //             {params.data.approvalType === 'sales-order'? <div style={{width: '270px', marginTop: '20px'}}>
    //                 <Row gutter={[8, 16]}>
    //                     <Col span={8}>Review By</Col>
    //                     <Col span={16}>
    //                         <MultipleSelectDropdown
    //                             style={{ width: '100%' }}
    //                             items={props.employeeList || []}
    //                             placeholder="Reviewed By"
    //                             mode='multiple'
    //                             onDeSelect={(value) => {
    //                                 let { selectedReviewersList } = props
    //                                     selectedReviewersList = filter(selectedReviewersList, (relationshipEmployeeId) => {
    //                                         return relationshipEmployeeId !== value;
    //                                     });
    //                                 props.updateState({ selectedReviewersList });
    //                             }}
    //                             valueKeyName='fullName'
    //                             // valueKeyName2='relationshipEmployeeId'
    //                             selectedValue={(props.employeeList || []).length ? (props.selectedReviewersList || []) : []}
    //                             optionKeyName='relationshipEmployeeId'
    //                             onSelect={(value) => {
    //                                 let selectedReviewersList = props.selectedReviewersList || [];
    //                                 if (selectedReviewersList.indexOf(value) === -1) {
    //                                     selectedReviewersList.push(value);
    //                                 } else {
    //                                     selectedReviewersList = selectedReviewersList.filter((el) => {
    //                                         return el !== value
    //                                 });
    //                                 }
    //                                props.updateState({
    //                                     selectedReviewersList
    //                                 });
    //                             }}
    //                             // canAddNew={true}
    //                             // onAddNewClick={(payload) => {
    //                             //     payload.formData = {
    //                             //     firstName: payload.textEntered,
    //                             //     submittedOnce: false,
    //                             //     customerId: (this.props.customer || {}).customerId,
    //                             //     pageNumber: this.props.pageNumber || 1,
    //                             //     customerName: this.props.customerName
    //                             //     }
    //                             //     this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
    //                             // }}
    //                             // canDelete={true}
    //                             // deleteClickHandler={(payload) => {
    //                             //     this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER, payload);
    //                             // }}
                                
    //                         />
    //                         {/* <Dropdown
    //                             className='drop-fileds'
    //                             items={props.employeeList}
    //                             valueKeyName='fullName'
    //                             optionKeyName='relationshipEmployeeId'
    //                             value={props.selectedReviewerName}
    //                             placeholder={props.intl.formatMessage({ id: 'setting.docApproval' })}
    //                             onSelect={(status, option) => {
    //                                 props.updateState({ selectedReviewerName: option.name, selectedReviewerId: option.value })
    //                             }}
    //                             allowClear={true}
    //                             onClear={() => {
    //                                 props.updateState({ selectedReviewerName: undefined, selectedReviewerId: undefined })
    //                             }}
    //                         /> */}
    //                     </Col>
    //                 </Row>
    //                 <Row>
    //                     <Col span={8}>Approved By</Col>
    //                     <Col span={16}>
    //                         <MultipleSelectDropdown
    //                             style={{ width: '100%' }}
    //                             items={props.employeeList || []}
    //                             placeholder="Reviewed By"
    //                             mode='multiple'
    //                             onDeSelect={(value) => {
    //                                 let { selectedReviewersList } = props
    //                                     selectedReviewersList = filter(selectedReviewersList, (relationshipEmployeeId) => {
    //                                         return relationshipEmployeeId !== value;
    //                                     });
    //                                 props.updateState({ selectedReviewersList });
    //                             }}
    //                             valueKeyName='fullName'
    //                             // valueKeyName2='relationshipEmployeeId'
    //                             selectedValue={(props.employeeList || []).length ? (props.selectedReviewersList || []) : []}
    //                             optionKeyName='relationshipEmployeeId'
    //                             onSelect={(value) => {
    //                                 let selectedReviewersList = props.selectedReviewersList || [];
    //                                 if (selectedReviewersList.indexOf(value) === -1) {
    //                                     selectedReviewersList.push(value);
    //                                 } else {
    //                                     selectedReviewersList = selectedReviewersList.filter((el) => {
    //                                         return el !== value
    //                                 });
    //                                 }
    //                                props.updateState({
    //                                     selectedReviewersList
    //                                 });
    //                             }}
    //                             // canAddNew={true}
    //                             // onAddNewClick={(payload) => {
    //                             //     payload.formData = {
    //                             //     firstName: payload.textEntered,
    //                             //     submittedOnce: false,
    //                             //     customerId: (this.props.customer || {}).customerId,
    //                             //     pageNumber: this.props.pageNumber || 1,
    //                             //     customerName: this.props.customerName
    //                             //     }
    //                             //     this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
    //                             // }}
    //                             // canDelete={true}
    //                             // deleteClickHandler={(payload) => {
    //                             //     this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER, payload);
    //                             // }}
                                
    //                         />

    //                         {/* <Dropdown
    //                             className={(props.submittedOnce && !props.paymentMode) ? 'input-text-error' : ''}
    //                             items={props.paymentModeList}
    //                             valueKeyName='paymentModeName'
    //                             optionKeyName='paymentModeId'
    //                             value={props.paymentModeId}
    //                             placeholder='Approved By'
    //                             onSelect={
    //                                 (optionValue, option) => {
    //                                     // props.updateState({
    //                                     //     paymentModeId: option.value,
    //                                     //     paymentMode: option.name,
    //                                     // })
    //                                 }
    //                             }
    //                         /> */}
    //                     </Col>
    //                 </Row>
    //             </div>:''
    //             }
    //             <Switch
    //                 checked={(params.data.isApprovalRequired === 1)}
    //                 onClick={(checked, e) => {
    //                     saveSettings(params, checked)
    //                 }} />
    //         </div>
    //     },
    // ]
    // const saveSettings = (params, checked) => {
    //     const modalData = {
    //         modalBody: "Are you sure you want to save?",
    //         handleSubmit: () => {
    //             params.node.data.isApprovalRequired = checked ? 1 : 0;
    //             gridApi.refreshCells({ force: true });
    //            let payload = {
    //             documentApprovalId: params.node.data.documentApprovalId,
    //             approvalType: params.node.data.approvalType,
    //             relationshipId: params.node.data.relationshipId,
    //             isApprovalRequired: params.node.data.isApprovalRequired
    //            }
    //             props.saveOrUpdateDocApproval(payload);
    //             //props.hideModal();
    //         }
    //     };
    //     props.pushModalToStack(modalData);

    // }
    const saveSetting = (record, checked) => {
        const modalData = {
            modalBody: "Are you sure you want to save?",
            handleSubmit: () => {
                record.isApprovalRequired = checked ? 1 : 0;
                let payload = {
                    documentApprovalId: record.documentApprovalId,
                    approvalType: record.approvalType,
                    relationshipId: record.relationshipId,
                    isApprovalRequired: record.isApprovalRequired,
                    reviewersList: props.selectedReviewersList || [],
                    approversList: props.selectedApproversList || [],
                    version: record.version,
                }
                props.saveOrUpdateDocApproval(payload);
            },
        };
        props.pushModalToStack(modalData);

    }
    // const getRowHeight = (params) => {
    //     if(params.data.approvalType==='sales-order'){
    //         return 125;
    //     }
    //     return 35;
    // }
    // const onGridReady = (params) => {
    //      gridApi = params.api;
    //     params.api.sizeColumnsToFit();
    // }
    // const dataList = props.docApprovalSettings
    return (
        <div>
            <div className="modal-dialog">
                <div className="modal-content full-width modify-width">
                    <div className="inner-content">
                        {/* <Form className="pr20" layout="vertical" hideRequiredMark>
                            <Form.Item>
                                <CustomAgGrid
                                    onGridReady={onGridReady}
                                        columnDefs={columnDefs}
                                        rowData={props.docApprovalSettings || []}
                                        getRowHeight={getRowHeight}
                                        gridStyle={{
                                            width: '100%',
                                            height: '100%',
                                        }} 
                                />
                            
                            </Form.Item>
                        </Form> */}
                        {/* <Table 
                            dataSource={props.docApprovalSettings}
                            columns={columnDef}
                            style={{ width: '100%' }}
                            rowKey= {props.docApprovalSettings.documentApprovalId}
                        /> */}
                        <Skeleton loading={props.listLoading} paragraph={{ rows: 7 }} active>
                            <table className='approval-table'>
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage id='modal.settings.documentApproval.transaction' defaultMessage='' />
                                        </th>
                                        <th>
                                            <FormattedMessage id='modal.settings.documentApproval.approvalStatus' defaultMessage='' />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(props.docApprovalSettings || []).map(record => (
                                        <tr key={record.documentApprovalId} style={{height: '30px'}}>
                                            <td>
                                                <FormattedMessage id={record.approvalType} />
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={(record.isApprovalRequired === 1)}
                                                    onClick={(checked, e) => {
                                                        saveSetting(record, checked)
                                                    }}
                                                    style={{marginBottom: '20px'}}
                                                />
                                                <Row gutter={[8, 16]}>
                                                    <Col span={8}>Reviewed By</Col>
                                                    <Col span={16}>
                                                        <MultipleSelectDropdown
                                                            style={{ width: '100%' }}
                                                            items={props.employeeList || []}
                                                            placeholder="Select Reviewers"
                                                            mode='multiple'
                                                            onDeSelect={(value) => {
                                                                let { selectedReviewersList } = props
                                                                    selectedReviewersList = filter(selectedReviewersList, (relationshipEmployeeId) => {
                                                                        return relationshipEmployeeId !== value;
                                                                    });
                                                                props.updateState({ selectedReviewersList });
                                                            }}
                                                            valueKeyName='fullName'
                                                            // valueKeyName2='relationshipEmployeeId'
                                                            selectedValue={(props.employeeList || []).length ? (props.selectedReviewersList || []) : []}
                                                            optionKeyName='relationshipEmployeeId'
                                                            onSelect={(value) => {
                                                                let selectedReviewersList = props.selectedReviewersList || [];
                                                                if (selectedReviewersList.indexOf(value) === -1) {
                                                                    selectedReviewersList.push(value);
                                                                } else {
                                                                    selectedReviewersList = selectedReviewersList.filter((el) => {
                                                                        return el !== value
                                                                });
                                                                }
                                                                props.updateState({
                                                                    selectedReviewersList
                                                                });
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8}>Approved By</Col>
                                                    <Col span={16}>
                                                        <MultipleSelectDropdown
                                                            style={{ width: '100%' }}
                                                            items={props.employeeList || []}
                                                            placeholder="Select Approvers"
                                                            mode='multiple'
                                                            onDeSelect={(value) => {
                                                                let { selectedApproversList } = props
                                                                    selectedApproversList = filter(selectedApproversList, (relationshipEmployeeId) => {
                                                                        return relationshipEmployeeId !== value;
                                                                    });
                                                                props.updateState({ selectedApproversList });
                                                            }}
                                                            valueKeyName='fullName'
                                                            // valueKeyName2='relationshipEmployeeId'
                                                            selectedValue={(props.employeeList || []).length ? (props.selectedApproversList || []) : []}
                                                            optionKeyName='relationshipEmployeeId'
                                                            onSelect={(value) => {
                                                                let selectedApproversList = props.selectedApproversList || [];
                                                                if (selectedApproversList.indexOf(value) === -1) {
                                                                    selectedApproversList.push(value);
                                                                } else {
                                                                    selectedApproversList = selectedApproversList.filter((el) => {
                                                                        return el !== value
                                                                });
                                                                }
                                                                props.updateState({
                                                                    selectedApproversList
                                                                });
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </Skeleton>
                    </div>
                </div>
            </div>
        </div>
    );

}


export default DocumentApprovalComponent;
