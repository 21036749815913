import React from 'react';
import { FormattedMessage } from 'react-intl';
import './index.scss';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Switch, Row, Col, Button, Skeleton } from 'antd';
import { getPermissionData } from '../../../../../utils';
import { MODULE_CODED_VALUES } from '../../../../../static/constants';

const QualityCheckComponent = (props) => {
    const {permissions} = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.QUALITY_CHECK);
    const saveSettings = (props) => {
        const modalData = {
            modalBody: "Are you sure you want to Submit?",
            handleSubmit: () => {
                let payload = {
                    ...props.qualityChecklistSetting,
                    relationshipId: props.companyInfo.relationshipId,
                    createdByEmpId: props.userInfo && props.userInfo.relationshipEmployeeId,
                }
                props.updateQualityChecklistSettings(payload);
                props.hideModal();
            }
        };
        props.pushModalToStack(modalData);

    }


    return (
        <div>
        <div className="modal-dialog">
            <div className="modal-content full-width modify-width">
                <Skeleton loading={props.detailLoading} paragraph={{ rows: 3 }} active>
                <div className="inner-content">
                        <Form layout="vertical" hideRequiredMark className="pr30">
                            <Form.Item>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Row>
                                                <Col span={14} className="text-right">
                                                    <FormattedMessage id='modal.settings.quilityCheck.qualityChecklistRequired' defaultMessage='' />
                                            </Col>
                                                <Col span={10}>
                                                    <Switch
                                                        checked={(props.qualityChecklistSetting.isQualityChecklistRequired === 1)}
                                                        onClick={(checked, e) => {
                                                            props.updateState({
                                                                qualityChecklistSetting: {
                                                                    ...props.qualityChecklistSetting,
                                                                    isQualityChecklistRequired: checked ? 1 : 0
                                                                }
                                                            })
                                                        }} />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Row>
                                                <Col span={14} className="text-right">
                                                    <FormattedMessage id='modal.settings.quilityCheck.generateShipmentWithoutQuilityCheck' defaultMessage='' />
                                            </Col>
                                                <Col span={10}>
                                                    <Switch
                                                        checked={(props.qualityChecklistSetting.isGenerateShipmentWithoutQualityCheck === 1)}
                                                        onClick={(checked, e) => {
                                                            props.updateState({
                                                                qualityChecklistSetting: {
                                                                    ...props.qualityChecklistSetting,
                                                                    isGenerateShipmentWithoutQualityCheck: checked ? 1 : 0
                                                                }
                                                            })
                                                        }} />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Form.Item>
                        </Form>
                    </div>
                </Skeleton>
                    <div className="button-group-action">
                        <div className="align-footer">
                            <Button onClick={() => {
                                props.hideModal();
                            }}
                                    type="default"
                                    className='back-white'
                            >
                                <FormattedMessage id='button.cancel.label' defaultMessage='' />
                            </Button>
                            <Button
                                type="primary"
                                // disabled={!(permissionsData.update)}
                                onClick={() => {
                                    saveSettings(props);
                                }}
                            >
                                <FormattedMessage id='button.save.label' defaultMessage='' />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}


export default QualityCheckComponent;
