import React, { useState } from 'react';
import { Button, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import './general.scss';
import { GRID_PREFERENCE_CONSTANTS } from '../../static/agGridConstants';

export function AddAndRemoveCustomerListingColumn(props) {
    const { txAvailableColumn = {}, txColumns = [], txMandatoryColumns = [] } = props;

    const [salesComplaintNumber, updateSalesComplaintNumber] = useState(txColumns.indexOf('salesComplaintNumber') > -1 || txMandatoryColumns.indexOf('salesComplaintNumber') > -1);
    const [inquiryNumber, updateInquiryNumber] = useState(txColumns.indexOf('inquiryNumber') > -1 || txMandatoryColumns.indexOf('inquiryNumber') > -1);
    const [assignedTo, updateAssignedTo] = useState(txColumns.indexOf('assignedTo') > -1 || txMandatoryColumns.indexOf('assignedTo') > -1);
    const [complaintDetails, updateComplaintDetails] = useState(txColumns.indexOf('complaintDetails') > -1 || txMandatoryColumns.indexOf('complaintDetails') > -1);
    const [complaintType, updateComplaintType] = useState(txColumns.indexOf('complaintType') > -1 || txMandatoryColumns.indexOf('complaintType') > -1);
    const [dueDate, updateDueDate] = useState(txColumns.indexOf('dueDate') > -1 || txMandatoryColumns.indexOf('dueDate') > -1);
    const [priority, updatePriority] = useState(txColumns.indexOf('priority') > -1 || txMandatoryColumns.indexOf('priority') > -1);
    const [complaintClosedDate, updateComplaintClosedDate] = useState(txColumns.indexOf('complaintClosedDate') > -1 || txMandatoryColumns.indexOf('complaintClosedDate') > -1);
    const [dateCreated, updateDateCreated] = useState(txColumns.indexOf('dateCreated') > -1 || txMandatoryColumns.indexOf('dateCreated') > -1);
    const [salesPersonFullName, updateSalesPersonFullName] = useState(txColumns.indexOf('salesPersonFullName') > -1 || txMandatoryColumns.indexOf('salesPersonFullName') > -1);
    const [customerInquiryNumber, updateCustomerInquiryNumber] = useState(txColumns.indexOf('customerInquiryNumber') > -1 || txMandatoryColumns.indexOf('customerInquiryNumber') > -1);
    const [customerPONumber, updateCustomerPONumber] = useState(txColumns.indexOf('customerPONumber') > -1 || txMandatoryColumns.indexOf('customerPONumber') > -1);
    const [customerRFQNumber, updateCustomerRFQ] = useState(txColumns.indexOf('customerRFQNumber') > -1 || txMandatoryColumns.indexOf('customerRFQNumber') > -1);
    const [irnNumber, updateIRNValue] = useState(txColumns.indexOf('irnNumber') > -1 || txMandatoryColumns.indexOf('irnNumber') > -1);
    const [eWayBillNo ,updateEwayBill] = useState(txColumns.indexOf('eWayBillNo') > -1|| txMandatoryColumns.indexOf('eWayBillNo') > -1)
    const [soNumber, updateSoNumber] = useState(txColumns.indexOf('soNumber') > -1 || txMandatoryColumns.indexOf('soNumber') > -1);
    const [customerName, updateCustomerName] = useState(txColumns.indexOf('customerName') > -1 || txMandatoryColumns.indexOf('customerName') > -1);
    const [salesOrderTotalAmount, updateSalesOrderTotalAmount] = useState(txColumns.indexOf('salesOrderTotalAmount') > -1 || txMandatoryColumns.indexOf('salesOrderTotalAmount') > -1);
    const [status, updateStatus] = useState(txColumns.indexOf('status') > -1 || txMandatoryColumns.indexOf('status') > -1);
    const [referenceNumber, updateReferenceNumber] = useState(txColumns.indexOf('referenceNumber') > -1 || txMandatoryColumns.indexOf('referenceNumber') > -1);
    const [salesOrderDate, updateSalesOrderDate] = useState(txColumns.indexOf('salesOrderDate') > -1 || txMandatoryColumns.indexOf('salesOrderDate') > -1);
    const [totalQtyInvoiced, updateTotalQtyInvoiced] = useState(txColumns.indexOf('totalQtyInvoiced') > -1 || txMandatoryColumns.indexOf('totalQtyInvoiced') > -1);
    const [totalQtyPacked, updateTotalQtyPacked] = useState(txColumns.indexOf('totalQtyPacked') > -1 || txMandatoryColumns.indexOf('totalQtyPacked') > -1);
    const [totalQtyShipped, updateTotalQtyShipped] = useState(txColumns.indexOf('totalQtyShipped') > -1 || txMandatoryColumns.indexOf('totalQtyShipped') > -1);
    const [totalQtyOnPR, updateTotalQtyOnPR] = useState(txColumns.indexOf('totalQtyOnPR') > -1 || txMandatoryColumns.indexOf('totalQtyOnPR') > -1);
    const [qtyOnSalesOrder, updateQtyOnSalesOrder] = useState(txColumns.indexOf('qtyOnSalesOrder') > -1 || txMandatoryColumns.indexOf('qtyOnSalesOrder') > -1);
    const [deliveryDate, updateDeliveryDate] = useState(txColumns.indexOf('deliveryDate') > -1 || txMandatoryColumns.indexOf('deliveryDate') > -1);
    const [systemStatus, updateSystemStatus] = useState(txColumns.indexOf('systemStatus') > -1 || txMandatoryColumns.indexOf('systemStatus') > -1);   
    const [userStatus, updateUserStatus] = useState(txColumns.indexOf('userStatus') > -1 || txMandatoryColumns.indexOf('userStatus') > -1);
    const [statusPRMessage, updateStatusPRMessage] = useState(txColumns.indexOf('statusPRMessage') > -1 || txMandatoryColumns.indexOf('statusPRMessage') > -1);

    const [customerType, updateCustomerType] = useState(txColumns.indexOf('customerType') > -1 || txMandatoryColumns.indexOf('customerType') > -1);
    const [companyName, updateCompanyName] = useState(txColumns.indexOf('companyName') > -1 || txMandatoryColumns.indexOf('companyName') > -1);
    const [contact, updateContact] = useState(txColumns.indexOf('contact') > -1 || txMandatoryColumns.indexOf('contact') > -1);
    const [totalAmountDue, updateTotalAmountDue] = useState(txColumns.indexOf('totalAmountDue') > -1 || txMandatoryColumns.indexOf('totalAmountDue') > -1);
    const [openedOrdersCount, updateOpenedOrdersCount] = useState(txColumns.indexOf('openedOrdersCount') > -1 || txMandatoryColumns.indexOf('openedOrdersCount') > -1);
    const [paidInvoicesCount, updatePaidInvoicesCount] = useState(txColumns.indexOf('paidInvoicesCount') > -1 || txMandatoryColumns.indexOf('paidInvoicesCount') > -1);
    const [unpaidInvoicesCount, updateUnpaidInvoicesCount] = useState(txColumns.indexOf('unpaidInvoicesCount') > -1 || txMandatoryColumns.indexOf('unpaidInvoicesCount') > -1);
    const [paymentTermName, updatePaymentTermName] = useState(txColumns.indexOf('paymentTermName') > -1 || txMandatoryColumns.indexOf('paymentTermName') > -1);

    const [isProformaConversion, updateIsProformaConversion] = useState(txColumns.indexOf('isProformaConversion') > -1 || txMandatoryColumns.indexOf('isProformaConversion') > -1);

    const [totalQuantityOnPR, updateTotalQuantityOnPR] = useState(txColumns.indexOf('totalQuantityOnPR') > -1 || txMandatoryColumns.indexOf('totalQuantityOnPR') > -1);
    const [totalQuantityOnQuote, updateTotalQuantityOnQuote] = useState(txColumns.indexOf('totalQuantityOnQuote') > -1 || txMandatoryColumns.indexOf('totalQuantityOnQuote') > -1);

    const [isXeroUploaded, updateIsXeroUploaded] = useState(txColumns.indexOf('isXeroUploaded') > -1 || txMandatoryColumns.indexOf('isXeroUploaded') > -1);
    const [invoiceNumber, updateInvoiceNumber] = useState(txColumns.indexOf('invoiceNumber') > -1 || txMandatoryColumns.indexOf('invoiceNumber') > -1);
    const [invoiceTotalAmount, updateInvoiceTotalAmount] = useState(txColumns.indexOf('invoiceTotalAmount') > -1 || txMandatoryColumns.indexOf('invoiceTotalAmount') > -1);
    const [totalPaymentReceived, updateTotalPaymentReceived] = useState(txColumns.indexOf('totalPaymentReceived') > -1 || txMandatoryColumns.indexOf('totalPaymentReceived') > -1);
    const [invoiceDueAmount, updateInvoiceDueAmount] = useState(txColumns.indexOf('invoiceDueAmount') > -1 || txMandatoryColumns.indexOf('invoiceDueAmount') > -1);
    const [invoiceDate, updateInvoiceDate] = useState(txColumns.indexOf('invoiceDate') > -1 || txMandatoryColumns.indexOf('invoiceDate') > -1);
    const [invoiceDueDate, updateInvoiceDueDate] = useState(txColumns.indexOf('invoiceDueDate') > -1 || txMandatoryColumns.indexOf('invoiceDueDate') > -1);
    const [isCashInvoice, updateIsCashInvoice] = useState(txColumns.indexOf('isCashInvoice') > -1 || txMandatoryColumns.indexOf('isCashInvoice') > -1);
    const [onlinePayment, updateOnlinePayment] = useState(txColumns.indexOf('onlinePayment') > -1 || txMandatoryColumns.indexOf('onlinePayment') > -1);
    const [invoiceReminder, updateInvoiceReminder] = useState(txColumns.indexOf('invoiceReminder') > -1 || txMandatoryColumns.indexOf('invoiceReminder') > -1);
    const [recurringInvoice, updateRecurringInvoice] = useState(txColumns.indexOf('recurringInvoice') > -1 || txMandatoryColumns.indexOf('recurringInvoice') > -1);

    const [quotationNumber, updateQuotationNumber] = useState(txColumns.indexOf('quotationNumber') > -1 || txMandatoryColumns.indexOf('quotationNumber') > -1);
    const [rfqNumber, updateRFQNumber] = useState(txColumns.indexOf('rfqNumber') > -1 || txMandatoryColumns.indexOf('rfqNumber') > -1);
    const [orderPriority, updateOrderPriority] = useState(txColumns.indexOf('orderPriority') > -1 || txMandatoryColumns.indexOf('orderPriority') > -1);
    const [salesQuoteDate, updateSalesQuoteDate] = useState(txColumns.indexOf('salesQuoteDate') > -1 || txMandatoryColumns.indexOf('salesQuoteDate') > -1);
    const [rfqExpirationDate, updateRfqExpirationDate] = useState(txColumns.indexOf('rfqExpirationDate') > -1 || txMandatoryColumns.indexOf('rfqExpirationDate') > -1);
    const [expirationDate, updateExpirationDate] = useState(txColumns.indexOf('expirationDate') > -1 || txMandatoryColumns.indexOf('expirationDate') > -1);
    const [inquiryDate, updateInquiryDate] = useState(txColumns.indexOf('inquiryDate') > -1 || txMandatoryColumns.indexOf('inquiryDate') > -1);
    const [isInquiryConversion, updateIsInquiryConversion] = useState(txColumns.indexOf('isInquiryConversion') > -1 || txMandatoryColumns.indexOf('isInquiryConversion') > -1);
    const [remarksInternal, updateRemarksInternal] = useState(txColumns.indexOf('remarksInternal') > -1 || txMandatoryColumns.indexOf('remarksInternal') > -1);
    const [remarksCustomer, updateRemarksCustomer] = useState(txColumns.indexOf('remarksCustomer') > -1 || txMandatoryColumns.indexOf('remarksCustomer') > -1);
    const [salesQuoteTotalAmount, updateSalesQuoteTotalAmount] = useState(txColumns.indexOf('salesQuoteTotalAmount') > -1 || txMandatoryColumns.indexOf('salesQuoteTotalAmount') > -1);

    const [paymentNumber, updatePaymentNumber] = useState(txColumns.indexOf('paymentNumber') > -1 || txMandatoryColumns.indexOf('paymentNumber') > -1);
    const [paymentAmount, updatePaymentAmount] = useState(txColumns.indexOf('paymentAmount') > -1 || txMandatoryColumns.indexOf('paymentAmount') > -1);
    const [paymentDate, updatePaymentDate] = useState(txColumns.indexOf('paymentDate') > -1 || txMandatoryColumns.indexOf('paymentDate') > -1);
    const [isGiftAidIt, updateIsGiftAidIt] = useState(txColumns.indexOf('isGiftAidIt') > -1 || txMandatoryColumns.indexOf('isGiftAidIt') > -1);

    const [description, updateDescription] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1);
    const [paymentMode, updatePaymentMode] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PAYMENT_MODE) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PAYMENT_MODE) > -1);
    const [transactionId, updateTransactionId] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.TRANSACTION_ID) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.TRANSACTION_ID) > -1);
    const [paymentSource, updatePaymentSource] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PAYMENT_SOURCE) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PAYMENT_SOURCE) > -1);

    const [creditMemoNumber, updateCreditMemoNumber] = useState(txColumns.indexOf('creditMemoNumber') > -1 || txMandatoryColumns.indexOf('creditMemoNumber') > -1);
    const [creditMemoTotalAmount, updateCreditMemoTotalAmount] = useState(txColumns.indexOf('creditMemoTotalAmount') > -1 || txMandatoryColumns.indexOf('creditMemoTotalAmount') > -1);
    const [creditMemoDate, updateCreditMemoDate] = useState(txColumns.indexOf('creditMemoDate') > -1 || txMandatoryColumns.indexOf('creditMemoDate') > -1);

    const [shipmentNumber, updateShipmentNumber] = useState(txColumns.indexOf('shipmentNumber') > -1 || txMandatoryColumns.indexOf('shipmentNumber') > -1);
    const [carrierName, updateCarrierName] = useState(txColumns.indexOf('carrierName') > -1 || txMandatoryColumns.indexOf('carrierName') > -1);
    const [trackingNumber, updateTrackingNumber] = useState(txColumns.indexOf('trackingNumber') > -1 || txMandatoryColumns.indexOf('trackingNumber') > -1);
    const [expectedDeliveryDate, updateExpectedDeliveryDate] = useState(txColumns.indexOf('expectedDeliveryDate') > -1 || txMandatoryColumns.indexOf('expectedDeliveryDate') > -1);
    const [shipmentDate, updateShipmentDate] = useState(txColumns.indexOf('shipmentDate') > -1 || txMandatoryColumns.indexOf('shipmentDate') > -1);
    const [totalPackages, updateTotalPackages] = useState(txColumns.indexOf('totalPackages') > -1 || txMandatoryColumns.indexOf('totalPackages') > -1);
    const [isStockUpdated, updateIsStockUpdated] = useState(txColumns.indexOf('isStockUpdated') > -1 || txMandatoryColumns.indexOf('isStockUpdated') > -1);

    const [comments, updateComments] = useState(txColumns.indexOf('comments') > -1 || txMandatoryColumns.indexOf('comments') > -1);
    const [consigneeName, updateConsigneeName] = useState(txColumns.indexOf('consigneeName') > -1 || txMandatoryColumns.indexOf('consigneeName') > -1);
    const [actualDeliveryDate, updateActualDeliveryDate] = useState(txColumns.indexOf('actualDeliveryDate') > -1 || txMandatoryColumns.indexOf('actualDeliveryDate') > -1);
    const [freightType, updateFreightType] = useState(txColumns.indexOf('freightType') > -1 || txMandatoryColumns.indexOf('freightType') > -1);
    const [qualityChecklistStatus, updateQualityChecklistStatus] = useState(txColumns.indexOf('qualityChecklistStatus') > -1 || txMandatoryColumns.indexOf('qualityChecklistStatus') > -1);
    const [project, updateProject] = useState(txColumns.indexOf('project') > -1 || txMandatoryColumns.indexOf('project') > -1);
    const [dueDays, updateDueDays] = useState(txColumns.indexOf('dueDays') > -1 || txMandatoryColumns.indexOf('dueDays') > -1);
    const [creditApplied, updateCreditApplied] = useState(txColumns.indexOf('creditApplied') > -1 || txMandatoryColumns.indexOf('creditApplied') > -1);
    const [salesOrderMasterId, updateSalesOrderMasterId] = useState(txColumns.indexOf('salesOrderMasterId') > -1 || txMandatoryColumns.indexOf('salesOrderMasterId') > -1);
    const [soSourceName, updateSOOrderSource] = useState(txColumns.indexOf('soSourceName') > -1 || txMandatoryColumns.indexOf('soSourceName') > -1);
    const [shippingLabel, updateShippingLabel] = useState(txColumns.indexOf('shippingLabel') > -1 || txMandatoryColumns.indexOf('shippingLabel') > -1);
    const [trackShipment, updateTrackShipment] = useState(txColumns.indexOf('trackShipment') > -1 || txMandatoryColumns.indexOf('trackShipment') > -1);
    const [onlineOrderNumber, updateOnlineOrderNumber] = useState(txColumns.indexOf('onlineOrderNumber') > -1 || txMandatoryColumns.indexOf('onlineOrderNumber') > -1);

    const savePreferences = () => {

        let columnList = [];

        if (salesComplaintNumber) columnList.push('salesComplaintNumber');
        if (assignedTo) columnList.push('assignedTo');
        if (complaintDetails) columnList.push('complaintDetails');
        if (complaintType) columnList.push('complaintType');
        if (dueDate) columnList.push('dueDate');
        if (priority) columnList.push('priority');
        if (complaintClosedDate) columnList.push('complaintClosedDate');
        if (dateCreated) columnList.push('dateCreated');
        if (salesPersonFullName) columnList.push('salesPersonFullName');

        if (soNumber) columnList.push('soNumber');
        if (customerName) columnList.push('customerName');
        if (salesOrderTotalAmount) columnList.push('salesOrderTotalAmount');
        if (status) columnList.push('status');
        if (referenceNumber) columnList.push('referenceNumber');
        if (salesOrderDate) columnList.push('salesOrderDate');
        if (totalQtyInvoiced) columnList.push('totalQtyInvoiced');
        if (totalQtyPacked) columnList.push('totalQtyPacked');
        if (totalQtyShipped) columnList.push('totalQtyShipped');
        if (totalQtyOnPR) columnList.push('totalQtyOnPR');
        if (totalQuantityOnPR) columnList.push('totalQuantityOnPR');
        if (totalQuantityOnQuote) columnList.push('totalQuantityOnQuote');
        if (deliveryDate) columnList.push('deliveryDate');
        if (systemStatus) columnList.push('systemStatus');
        if (userStatus) columnList.push('userStatus');
        if (statusPRMessage) columnList.push('statusPRMessage');

        if (isXeroUploaded) columnList.push('isXeroUploaded');
        if (invoiceNumber) columnList.push('invoiceNumber');
        if (invoiceTotalAmount) columnList.push('invoiceTotalAmount');
        if (invoiceDueAmount) columnList.push('invoiceDueAmount');
        if (totalPaymentReceived) columnList.push('totalPaymentReceived');
        if (salesQuoteTotalAmount) columnList.push('salesQuoteTotalAmount');
        if (invoiceDate) columnList.push('invoiceDate');
        if (invoiceDueDate) columnList.push('invoiceDueDate');
        if (isCashInvoice) columnList.push('isCashInvoice');
        if (isProformaConversion) columnList.push('isProformaConversion');
        if (onlinePayment) columnList.push('onlinePayment');
        if (invoiceReminder) columnList.push('invoiceReminder');
        if (recurringInvoice) columnList.push('recurringInvoice');
        
        if (quotationNumber) columnList.push('quotationNumber');
        if (rfqNumber) columnList.push('rfqNumber');
        if (orderPriority) columnList.push('orderPriority');
        if (salesQuoteDate) columnList.push('salesQuoteDate');
        if (rfqExpirationDate) columnList.push('rfqExpirationDate');
        if (expirationDate) columnList.push('expirationDate');
        if (inquiryDate) columnList.push('inquiryDate');
        if (isInquiryConversion) columnList.push('isInquiryConversion');
        if (inquiryNumber) columnList.push('inquiryNumber');
        if (remarksInternal) columnList.push('remarksInternal');
        if (remarksCustomer) columnList.push('remarksCustomer');
        if (qtyOnSalesOrder) columnList.push('qtyOnSalesOrder');
        if (salesQuoteTotalAmount) columnList.push('salesQuoteTotalAmount');

        if (paymentNumber) columnList.push('paymentNumber');
        if (paymentAmount) columnList.push('paymentAmount');
        if (paymentDate) columnList.push('paymentDate');
        if (paymentMode) columnList.push('paymentMode');
        if (transactionId) columnList.push('transactionId');
        if (paymentSource) columnList.push('paymentSource');
        if (isGiftAidIt) columnList.push('isGiftAidIt');

        if (customerInquiryNumber) columnList.push('customerInquiryNumber');
        if (customerRFQNumber) columnList.push('customerRFQNumber');
        if(irnNumber) columnList.push('irnNumber')
            if(eWayBillNo) columnList.push('eWayBillNo')
        if (customerPONumber) columnList.push('customerPONumber');

        if (creditMemoNumber) columnList.push('creditMemoNumber');
        if (creditMemoTotalAmount) columnList.push('creditMemoTotalAmount');
        if (creditMemoDate) columnList.push('creditMemoDate');

        if (customerType) columnList.push('customerType');
        if (companyName) columnList.push('companyName');
        if (contact) columnList.push('contact');
        if (totalAmountDue) columnList.push('totalAmountDue');
        if (openedOrdersCount) columnList.push('openedOrdersCount');
        if (paidInvoicesCount) columnList.push('paidInvoicesCount');
        if (unpaidInvoicesCount) columnList.push('unpaidInvoicesCount');
        if (paymentTermName) columnList.push('paymentTermName');

        if (shipmentNumber) columnList.push('shipmentNumber');
        if (carrierName) columnList.push('carrierName');
        if (trackingNumber) columnList.push('trackingNumber');
        if (expectedDeliveryDate) columnList.push('expectedDeliveryDate');
        if (shipmentDate) columnList.push('shipmentDate');
        if (totalPackages) columnList.push('totalPackages');
        if (isStockUpdated) columnList.push('isStockUpdated');
        if (comments) columnList.push('comments');
        if (consigneeName) columnList.push('consigneeName');
        if (actualDeliveryDate) columnList.push('actualDeliveryDate');
        if (freightType) columnList.push('freightType');
        if (qualityChecklistStatus) columnList.push('qualityChecklistStatus');
        if (project) columnList.push('project');
        if (description) columnList.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        if (dueDays) columnList.push('dueDays');
        if (creditApplied) columnList.push('creditApplied');
        if (salesOrderMasterId) columnList.push('salesOrderMasterId');
        if (soSourceName) columnList.push('soSourceName');
        if (shippingLabel) columnList.push('shippingLabel');
        if (trackShipment) columnList.push('trackShipment');
        if (onlineOrderNumber) columnList.push('onlineOrderNumber');


        props.updateState({ txColumns: columnList, tableDataReRendered: true });
        let data = props.txPreferences || {};
        data.relationshipId = (props.companyInfo || {}).relationshipId;
        data.clientUserAccountId = (props.userInfo || {}).userId;
        if (data.tablePreference) {
            let obj = JSON.parse(data.tablePreference);
            obj.columnList = columnList;
            data.tablePreference = JSON.stringify(obj);
        } else {
            data.tablePreference = JSON.stringify({ columnList: columnList, columnSetting: props.txColumnSetting });
        }
        data.preferenceId = props.preferenceId;
        data.gridPreference = props.gridPreference;
        props.saveUserPrefrences(data);
    }
    return (

        <>
        <div className="add-remove-column">
            {txAvailableColumn.creditMemoNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('creditMemoNumber') > -1}
                    checked={creditMemoNumber} onChange={(e) => {
                        updateCreditMemoNumber(e.target.checked);
                    } }> Credit Memo #</Checkbox>
            </div>}
            {txAvailableColumn.paymentNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('paymentNumber') > -1}
                    checked={paymentNumber} onChange={(e) => {
                        updatePaymentNumber(e.target.checked);
                    } }> Payment #</Checkbox>
            </div>}
            {txAvailableColumn.quotationNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('quotationNumber') > -1}
                    checked={quotationNumber} onChange={(e) => {
                        updateQuotationNumber(e.target.checked);
                    } }> Quote #</Checkbox>
            </div>}
            {txAvailableColumn.rfqNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('rfqNumber') > -1}
                    checked={rfqNumber} onChange={(e) => {
                        updateRFQNumber(e.target.checked);
                    } }> RFQ #</Checkbox>
            </div>}
            {txAvailableColumn.isXeroUploaded && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('isXeroUploaded') > -1}
                    checked={isXeroUploaded} onChange={(e) => {
                        updateIsXeroUploaded(e.target.checked);
                    } }> Xero</Checkbox>
            </div>}
            {txAvailableColumn.invoiceNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('invoiceNumber') > -1}
                    checked={invoiceNumber} onChange={(e) => {
                        updateInvoiceNumber(e.target.checked);
                    } }> Invoice #</Checkbox>
            </div>}
            {txAvailableColumn.soNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('soNumber') > -1}
                    checked={soNumber} onChange={(e) => {
                        updateSoNumber(e.target.checked);
                    } }> SO #</Checkbox>
            </div>}
            {txAvailableColumn.customerName && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('customerName') > -1}
                    checked={customerName} onChange={(e) => {
                        updateCustomerName(e.target.checked);
                    } }> Customer</Checkbox>
            </div>}
            {txAvailableColumn.description && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1}
                    checked={description} onChange={(e) => {
                        updateDescription(e.target.checked);
                    } }> <FormattedMessage id='package.productDetail.description' defaultMessage='' /></Checkbox>
            </div>}
            {txAvailableColumn.salesOrderTotalAmount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('salesOrderTotalAmount') > -1}
                    checked={salesOrderTotalAmount} onChange={(e) => {
                        updateSalesOrderTotalAmount(e.target.checked);
                    } }> Amount</Checkbox>
            </div>}

            {txAvailableColumn.dueDays && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('dueDays') > -1}
                    checked={dueDays} onChange={(e) => {
                        updateDueDays(e.target.checked);
                    } }> <FormattedMessage id='dueDays.text' defaultMessage='' /></Checkbox>
            </div>}
            {txAvailableColumn.status && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('status') > -1}
                    checked={status} onChange={(e) => {
                        updateStatus(e.target.checked);
                    } }> System Status</Checkbox>
            </div>}
            {txAvailableColumn.referenceNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('referenceNumber') > -1}
                    checked={referenceNumber} onChange={(e) => {
                        updateReferenceNumber(e.target.checked);
                    } }> Ref #</Checkbox>
            </div>}
            {txAvailableColumn.salesOrderDate &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('salesOrderDate') > -1}
                        checked={salesOrderDate} onChange={(e) => {
                            updateSalesOrderDate(e.target.checked);
                        } }> Date</Checkbox>
                </div>}
            {(txAvailableColumn.totalQtyInvoiced) &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('totalQtyInvoiced') > -1}
                        checked={totalQtyInvoiced} onChange={(e) => {
                            updateTotalQtyInvoiced(e.target.checked);
                        } }> Invoiced</Checkbox>
                </div>}
            {txAvailableColumn.totalQtyPacked &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('totalQtyPacked') > -1}
                        checked={totalQtyPacked} onChange={(e) => {
                            updateTotalQtyPacked(e.target.checked);
                        } }> Packed</Checkbox>
                </div>}
            {txAvailableColumn.totalQtyShipped && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('totalQtyShipped') > -1}
                    checked={totalQtyShipped} onChange={(e) => {
                        updateTotalQtyShipped(e.target.checked);
                    } }> Shipped</Checkbox>
            </div>}
            {txAvailableColumn.totalQtyOnPR && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('totalQtyOnPR') > -1}
                    checked={totalQtyOnPR} onChange={(e) => {
                        updateTotalQtyOnPR(e.target.checked);
                    } }> Backorders</Checkbox>
            </div>}
            {txAvailableColumn.totalQuantityOnPR && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('totalQuantityOnPR') > -1}
                    checked={totalQuantityOnPR} onChange={(e) => {
                        updateTotalQuantityOnPR(e.target.checked);
                    } }> Backorders</Checkbox>
            </div>}
            {txAvailableColumn.totalQuantityOnQuote && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('totalQuantityOnQuote') > -1}
                    checked={totalQuantityOnQuote} onChange={(e) => {
                        updateTotalQuantityOnQuote(e.target.checked);
                    } }> Qty on Quote</Checkbox>
            </div>}

            {txAvailableColumn.deliveryDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('deliveryDate') > -1}
                    checked={deliveryDate} onChange={(e) => {
                        updateDeliveryDate(e.target.checked);
                    } }> Delivery Date </Checkbox>
            </div>}
            {txAvailableColumn.systemStatus && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('systemStatus') > -1}
                    checked={systemStatus} onChange={(e) => {
                        updateSystemStatus(e.target.checked);
                    } }>System Status </Checkbox>
            </div>}
            {txAvailableColumn.userStatus && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('userStatus') > -1}
                    checked={userStatus} onChange={(e) => {
                        updateUserStatus(e.target.checked);
                    } }> Status </Checkbox>
            </div>}

            {txAvailableColumn.statusPRMessage && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('statusPRMessage') > -1}
                    checked={statusPRMessage} onChange={(e) => {
                        updateStatusPRMessage(e.target.checked);
                    } }> PR Status </Checkbox>
            </div>}

            {txAvailableColumn.salesPersonFullName && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('salesPersonFullName') > -1}
                    checked={salesPersonFullName} onChange={(e) => {
                        updateSalesPersonFullName(e.target.checked);
                    } }> Sales Person </Checkbox>
            </div>}

            {txAvailableColumn.invoiceTotalAmount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('invoiceTotalAmount') > -1}
                    checked={invoiceTotalAmount} onChange={(e) => {
                        updateInvoiceTotalAmount(e.target.checked);
                    } }> Invoice Amount</Checkbox>
            </div>}
            {txAvailableColumn.totalPaymentReceived && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('totalPaymentReceived') > -1}
                    checked={totalPaymentReceived} onChange={(e) => {
                        updateTotalPaymentReceived(e.target.checked);
                    } }> Paid</Checkbox>
            </div>}
            {txAvailableColumn.invoiceDueAmount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('invoiceDueAmount') > -1}
                    checked={invoiceDueAmount} onChange={(e) => {
                        updateInvoiceDueAmount(e.target.checked);
                    } }> Due Amount</Checkbox>
            </div>}
            {txAvailableColumn.invoiceDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('invoiceDate') > -1}
                    checked={invoiceDate} onChange={(e) => {
                        updateInvoiceDate(e.target.checked);
                    } }> Invoice Date</Checkbox>
            </div>}
            {txAvailableColumn.invoiceDueDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('invoiceDueDate') > -1}
                    checked={invoiceDueDate} onChange={(e) => {
                        updateInvoiceDueDate(e.target.checked);
                    } }> Invoice Due Date</Checkbox>
            </div>}
            {txAvailableColumn.isCashInvoice && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('isCashInvoice') > -1}
                    checked={isCashInvoice} onChange={(e) => {
                        updateIsCashInvoice(e.target.checked);
                    } }> Cash Invoice</Checkbox>
            </div>}
            {txAvailableColumn.isProformaConversion && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('isProformaConversion') > -1}
                    checked={isProformaConversion} onChange={(e) => {
                        updateIsProformaConversion(e.target.checked);
                    } }> Invoice Converted</Checkbox>
            </div>}


            {txAvailableColumn.orderPriority && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('orderPriority') > -1}
                    checked={orderPriority} onChange={(e) => {
                        updateOrderPriority(e.target.checked);
                    } }> Order Priority</Checkbox>
            </div>}
            {txAvailableColumn.salesQuoteDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('salesQuoteDate') > -1}
                    checked={salesQuoteDate} onChange={(e) => {
                        updateSalesQuoteDate(e.target.checked);
                    } }> Date Created</Checkbox>
            </div>}
            {txAvailableColumn.salesQuoteTotalAmount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('salesQuoteTotalAmount') > -1}
                    checked={salesQuoteTotalAmount} onChange={(e) => {
                        updateSalesQuoteTotalAmount(e.target.checked);
                    } }> Amount</Checkbox>
            </div>}
            {txAvailableColumn.expirationDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('expirationDate') > -1}
                    checked={expirationDate} onChange={(e) => {
                        updateExpirationDate(e.target.checked);
                    } }> Expiration Date</Checkbox>
            </div>}
            {txAvailableColumn.inquiryDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('inquiryDate') > -1}
                    checked={inquiryDate} onChange={(e) => {
                        updateInquiryDate(e.target.checked);
                    } }> Inquiry Date</Checkbox>
            </div>}
            {txAvailableColumn.rfqExpirationDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('rfqExpirationDate') > -1}
                    checked={rfqExpirationDate} onChange={(e) => {
                        updateRfqExpirationDate(e.target.checked);
                    } }> Expiration Date</Checkbox>
            </div>}
            {txAvailableColumn.isInquiryConversion && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('isInquiryConversion') > -1}
                    checked={isInquiryConversion} onChange={(e) => {
                        updateIsInquiryConversion(e.target.checked);
                    } }> Inquiry Conversion</Checkbox>
            </div>}
            {txAvailableColumn.remarksInternal && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('remarksInternal') > -1}
                    checked={remarksInternal} onChange={(e) => {
                        updateRemarksInternal(e.target.checked);
                    } }> Remarks (Internal)</Checkbox>
            </div>}
            {txAvailableColumn.remarksCustomer && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('remarksCustomer') > -1}
                    checked={remarksCustomer} onChange={(e) => {
                        updateRemarksCustomer(e.target.checked);
                    } }> Remarks</Checkbox>
            </div>}

            {txAvailableColumn.paymentAmount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('paymentAmount') > -1}
                    checked={paymentAmount} onChange={(e) => {
                        updatePaymentAmount(e.target.checked);
                    } }> Amount</Checkbox>
            </div>}

            {txAvailableColumn.paymentDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('paymentDate') > -1}
                    checked={paymentDate} onChange={(e) => {
                        updatePaymentDate(e.target.checked);
                    } }> Date</Checkbox>
            </div>}

            {txAvailableColumn.paymentMode && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('paymentMode') > -1}
                    checked={paymentMode} onChange={(e) => {
                        updatePaymentMode(e.target.checked);
                    } }> Payment Mode</Checkbox>
            </div>}

            {txAvailableColumn.paymentSource && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('paymentSource') > -1}
                    checked={paymentSource} onChange={(e) => {
                        updatePaymentSource(e.target.checked);
                    } }> Payment Source</Checkbox>
            </div>}

            {txAvailableColumn.isGiftAidIt && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('isGiftAidIt') > -1}
                    checked={isGiftAidIt} onChange={(e) => {
                        updateIsGiftAidIt(e.target.checked);
                    } }> Gift Aid</Checkbox>
            </div>}

            {txAvailableColumn.transactionId && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('transactionId') > -1}
                    checked={transactionId} onChange={(e) => {
                        updateTransactionId(e.target.checked);
                    } }> Transaction Id</Checkbox>
            </div>}

            {txAvailableColumn.creditMemoTotalAmount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('creditMemoTotalAmount') > -1}
                    checked={creditMemoTotalAmount} onChange={(e) => {
                        updateCreditMemoTotalAmount(e.target.checked);
                    } }> Amount</Checkbox>
            </div>}

            {txAvailableColumn.creditMemoDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('creditMemoDate') > -1}
                    checked={creditMemoDate} onChange={(e) => {
                        updateCreditMemoDate(e.target.checked);
                    } }> Date</Checkbox>
            </div>}

            {txAvailableColumn.qtyOnSalesOrder && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('qtyOnSalesOrder') > -1}
                    checked={qtyOnSalesOrder} onChange={(e) => {
                        updateQtyOnSalesOrder(e.target.checked);
                    } }> Qty on SO</Checkbox>
            </div>}

            {/* listing */}
            {txAvailableColumn.customerType && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('customerType') > -1}
                    checked={customerType} onChange={(e) => {
                        updateCustomerType(e.target.checked);
                    } }> Type </Checkbox>
            </div>}
            {txAvailableColumn.companyName && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('companyName') > -1}
                    checked={companyName} onChange={(e) => {
                        updateCompanyName(e.target.checked);
                    } }> Company Name</Checkbox>
            </div>}

            {txAvailableColumn.contact && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('contact') > -1}
                    checked={contact} onChange={(e) => {
                        updateContact(e.target.checked);
                    } }> Contact</Checkbox>
            </div>}

            {txAvailableColumn.totalAmountDue && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('totalAmountDue') > -1}
                    checked={totalAmountDue} onChange={(e) => {
                        updateTotalAmountDue(e.target.checked);
                    } }> Total Amount Due</Checkbox>
            </div>}

            {txAvailableColumn.openedOrdersCount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('openedOrdersCount') > -1}
                    checked={openedOrdersCount} onChange={(e) => {
                        updateOpenedOrdersCount(e.target.checked);
                    } }> Opened Orders</Checkbox>
            </div>}

            {txAvailableColumn.paidInvoicesCount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('paidInvoicesCount') > -1}
                    checked={paidInvoicesCount} onChange={(e) => {
                        updatePaidInvoicesCount(e.target.checked);
                    } }> Paid Invoices</Checkbox>
            </div>}

            {txAvailableColumn.paymentTermName && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('paymentTermName') > -1}
                    checked={paymentTermName} onChange={(e) => {
                        updatePaymentTermName(e.target.checked);
                    } }> Payment Term</Checkbox>
            </div>}

            {txAvailableColumn.unpaidInvoicesCount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('unpaidInvoicesCount') > -1}
                    checked={unpaidInvoicesCount} onChange={(e) => {
                        updateUnpaidInvoicesCount(e.target.checked);
                    } }> Unpaid Invoice</Checkbox>
            </div>}


            {txAvailableColumn.shipmentNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('shipmentNumber') > -1}
                    checked={shipmentNumber} onChange={(e) => {
                        updateShipmentNumber(e.target.checked);
                    } }> Shipment #</Checkbox>
            </div>}

            {txAvailableColumn.carrierName && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('carrierName') > -1}
                    checked={carrierName} onChange={(e) => {
                        updateCarrierName(e.target.checked);
                    } }> Carrier Name</Checkbox>
            </div>}

            {txAvailableColumn.trackingNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('trackingNumber') > -1}
                    checked={trackingNumber} onChange={(e) => {
                        updateTrackingNumber(e.target.checked);
                    } }> Tracking Number</Checkbox>
            </div>}

            {txAvailableColumn.expectedDeliveryDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('expectedDeliveryDate') > -1}
                    checked={expectedDeliveryDate} onChange={(e) => {
                        updateExpectedDeliveryDate(e.target.checked);
                    } }> Expected Delivery</Checkbox>
            </div>}

            {txAvailableColumn.shipmentDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('shipmentDate') > -1}
                    checked={shipmentDate} onChange={(e) => {
                        updateShipmentDate(e.target.checked);
                    } }> Shipment Date</Checkbox>
            </div>}

            {txAvailableColumn.totalPackages && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('totalPackages') > -1}
                    checked={totalPackages} onChange={(e) => {
                        updateTotalPackages(e.target.checked);
                    } }> Total Package(s)</Checkbox>
            </div>}

            {txAvailableColumn.isStockUpdated && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('isStockUpdated') > -1}
                    checked={isStockUpdated} onChange={(e) => {
                        updateIsStockUpdated(e.target.checked);
                    } }> Stock Update</Checkbox>
            </div>}

            {txAvailableColumn.comments && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('comments') > -1}
                    checked={comments} onChange={(e) => {
                        updateComments(e.target.checked);
                    } }> Comments</Checkbox>
            </div>}
            {txAvailableColumn.consigneeName && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('consigneeName') > -1}
                    checked={consigneeName} onChange={(e) => {
                        updateConsigneeName(e.target.checked);
                    } }> Consignee Name</Checkbox>
            </div>}

            {txAvailableColumn.actualDeliveryDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('actualDeliveryDate') > -1}
                    checked={actualDeliveryDate} onChange={(e) => {
                        updateActualDeliveryDate(e.target.checked);
                    } }> Actual Delivery Date</Checkbox>
            </div>}
            {txAvailableColumn.freightType && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('freightType') > -1}
                    checked={freightType} onChange={(e) => {
                        updateFreightType(e.target.checked);
                    } }> Freight Type</Checkbox>
            </div>}

            {txAvailableColumn.qualityChecklistStatus && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('qualityChecklistStatus') > -1}
                    checked={qualityChecklistStatus} onChange={(e) => {
                        updateQualityChecklistStatus(e.target.checked);
                    } }> QC Status</Checkbox>
            </div>}

            {txAvailableColumn.inquiryNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('inquiryNumber') > -1}
                    checked={inquiryNumber} onChange={(e) => {
                        updateInquiryNumber(e.target.checked);
                    } }> Inquiry Number</Checkbox>
            </div>}

            {txAvailableColumn.salesComplaintNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('salesComplaintNumber') > -1}
                    checked={salesComplaintNumber} onChange={(e) => {
                        updateSalesComplaintNumber(e.target.checked);
                    } }> Complaint Number</Checkbox>
            </div>}
            {txAvailableColumn.assignedTo && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('assignedTo') > -1}
                    checked={assignedTo} onChange={(e) => {
                        updateAssignedTo(e.target.checked);
                    } }> Assigned To</Checkbox>
            </div>}
            {txAvailableColumn.complaintDetails && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('complaintDetails') > -1}
                    checked={complaintDetails} onChange={(e) => {
                        updateComplaintDetails(e.target.checked);
                    } }>Complaint Details</Checkbox>
            </div>}
            {txAvailableColumn.complaintType && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('complaintType') > -1}
                    checked={complaintType} onChange={(e) => {
                        updateComplaintType(e.target.checked);
                    } }> Complaint Type</Checkbox>
            </div>}
            {txAvailableColumn.dueDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('dueDate') > -1}
                    checked={dueDate} onChange={(e) => {
                        updateDueDate(e.target.checked);
                    } }> Due Date</Checkbox>
            </div>}
            {txAvailableColumn.dateCreated && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('dateCreated') > -1}
                    checked={dateCreated} onChange={(e) => {
                        updateDateCreated(e.target.checked);
                    } }> Date Created</Checkbox>
            </div>}

            {txAvailableColumn.customerInquiryNumber &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateCustomerInquiryNumber(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('customerInquiryNumber') > -1}
                        checked={customerInquiryNumber}> Customer Inquiry #</Checkbox>
                </div>}

            {txAvailableColumn.customerRFQNumber &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateCustomerRFQ(e.target.checked);
                    } } checked={customerRFQNumber}> Customer RFQ #</Checkbox>
                </div>}

                {txAvailableColumn.irnNumber &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateIRNValue(e.target.checked);
                    } } checked={irnNumber}> IRN #</Checkbox>
                </div>}
                {txAvailableColumn.eWayBillNo &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateEwayBill(e.target.checked);
                    } } checked={eWayBillNo}> e-Way Bill #</Checkbox>
                </div>}


            {txAvailableColumn.customerPONumber &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateCustomerPONumber(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('customerPONumber') > -1}
                        checked={customerPONumber}> Customer PO #</Checkbox>
                </div>}

            {txAvailableColumn.priority && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('priority') > -1}
                    checked={priority} onChange={(e) => {
                        updatePriority(e.target.checked);
                    } }> Priority</Checkbox>
            </div>}
            {txAvailableColumn.complaintClosedDate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('complaintClosedDate') > -1}
                    checked={complaintClosedDate} onChange={(e) => {
                        updateComplaintClosedDate(e.target.checked);
                    } }> Complaint Closed Date</Checkbox>
            </div>}
            {txAvailableColumn.project && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('project') > -1}
                    checked={project} onChange={(e) => {
                        updateProject(e.target.checked);
                    } }> Project</Checkbox>
            </div>}

            {txAvailableColumn.creditApplied && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('creditApplied') > -1}
                    checked={creditApplied} onChange={(e) => {
                        updateCreditApplied(e.target.checked);
                    } }> Credit Applied</Checkbox>
            </div>}

            {txAvailableColumn.onlinePayment && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('onlinePayment') > -1}
                    checked={onlinePayment} onChange={(e) => {
                        updateOnlinePayment(e.target.checked);
                    } }> Online Payment</Checkbox>
            </div>}

            {txAvailableColumn.invoiceReminder && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('invoiceReminder') > -1}
                    checked={invoiceReminder} onChange={(e) => {
                        updateInvoiceReminder(e.target.checked);
                    } }> Invoice Reminder</Checkbox>
            </div>}

            {txAvailableColumn.recurringInvoice && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('recurringInvoice') > -1}
                    checked={recurringInvoice} onChange={(e) => {
                        updateRecurringInvoice(e.target.checked);
                    } }> Recurring Invoice</Checkbox>
            </div>}
            {txAvailableColumn.salesOrderMasterId && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('salesOrderMasterId') > -1}
                    checked={salesOrderMasterId} onChange={(e) => {
                        updateSalesOrderMasterId(e.target.checked);
                    } }> Order #</Checkbox>
            </div>}
            {txAvailableColumn.soSourceName && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('soSourceName') > -1}
                    checked={soSourceName} onChange={(e) => {
                        updateSOOrderSource(e.target.checked);
                    } }> Sales Channel</Checkbox>
            </div>}

            {txAvailableColumn.shippingLabel && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('shippingLabel') > -1}
                    checked={shippingLabel} onChange={(e) => {
                        updateShippingLabel(e.target.checked);
                    } }>Shipping Label</Checkbox>
            </div>}

            {txAvailableColumn.trackShipment && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('trackShipment') > -1}
                    checked={trackShipment} onChange={(e) => {
                        updateTrackShipment(e.target.checked);
                    } }>Track Shipment</Checkbox>
            </div>}

            {txAvailableColumn.onlineOrderNumber && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf('onlineOrderNumber') > -1}
                    checked={onlineOrderNumber} onChange={(e) => {
                        updateOnlineOrderNumber(e.target.checked);
                    } }>Online Order #</Checkbox>
            </div>}

            {/* <div className="button-action">
        <Button type="primary" onClick={() => {
            props.updateState({showColPop: false});
            savePreferences();
        }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
    </div> */}

        </div>
        <div className="button-action">
                <Button type="primary" onClick={() => {
                    props.updateState({ showColPop: false });
                    savePreferences();
                } }><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
            </div>
            </>


    );

}

