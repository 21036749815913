import React from "react";
import { Steps, Button, Col, Row, Divider, Skeleton } from 'antd';
import './style.scss';
import { FormattedMessage, } from 'react-intl';
import ShippingInfo from "./ShippingInfo";
import ShippingPrices from "./ShippingPrices";
import ShippingOrderInfo from "./ShippingOrderInfo";
import AWBAssignInfo from "./AWBAssignInfo";
import ShipmentPickupInfo from "./ShipmentPickupInfo";
import LabelDownloadComponent from "./LabelDownload";

const GenerateShippingLabelShiprocket = (props) => {

    const { currentStep=0 } = props;


    const Step = Steps.Step;

    const steps = [
        {
            title: "Select",
            key: 0
        },
        {
            title: <FormattedMessage id='carriarsPrice.text' />,
            key: 1
        },
        {
            title: "AWB Assign",
            key: 2
        },
        {
            title: "Pickup Information",
            key: 3
        },
        {
            title: <FormattedMessage id='downloadShippingLabel.text' />,
            key: 4
        }
    ];

    
    return (<>
        <ShippingInfo {...props} />

        <Divider className="mt10 mb20" />

        <Steps current={currentStep} status={props.stepError ? 'error' : 'process'}>
            {steps.map(item => <Step key={item.key} title={item.title} />)}
        </Steps>

        <div className="mt20" />

        {props.stepError ? <div className="required">{props.stepError}</div> : ''}
        {/* <Button onClick={()=>{
            props.updateState({currentStep: props.currentStep + 1})
        }}>Next</Button> */}

        {
            currentStep === 0 ? <ShippingOrderInfo {...props} /> : ''
        }
        
        { 
            currentStep === 1 ? <ShippingPrices {...props} /> : '' 
        }

        {
            currentStep === 2 ? <AWBAssignInfo {...props} /> : ''
        }

        {
            currentStep === 3 ? <ShipmentPickupInfo {...props} /> : ''
        }

        {
            currentStep === 4 ? <LabelDownloadComponent {...props} /> : ''
        }
    </>
    );
}

export default GenerateShippingLabelShiprocket;