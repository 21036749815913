import React from 'react';
// import { AgGridReact } from 'ag-grid-react';
import { Pagination, Skeleton } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import PODetail from '../../../../../containers/modal/modalBody/supplier/PurchaseOrderDetail';
import { CONSTANTS } from '../../../../../static/constants';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { fetchPaginationDataIfNeeded, fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../../../utils';


const TotalOrdersComponent = (props) => {
    const { totalOrderList, totalOrderListCount, companyInfo, updateState, pageNumber } = props;

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const getRowHeight = () => {
    //     return 28;
    // }

    // const onFirstDataRendered = (params) => {
    //     params.api.sizeColumnsToFit();
    // }

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchTotalOrders', 'totalOrderList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchTotalOrders(payload);
       // props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    const openPoDetails = (data) => {
        const poPayload = {
            supplierId: data.supplierId,
            poMasterId: data.poMasterId
        }
        props.pushModalToStack({
            modalBody: <PODetail {...props} poPayload={poPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const showTotal = (total) => {
        return props.intl.formatMessage({id: 'pagination.text.total', defaultMessage: ''}) + ` ${ total } `+ props.intl.formatMessage({id: 'pagination.text.items', defaultMessage: ''});
    };

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.listing.totalOrder.header.poNumber' defaultMessage='' />;
    //         },
    //         field: "poNumber",
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openPoDetails(link.data)
    //         }}>{link.value}</div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='supplier.listing.totalOrder.header.poAmount' defaultMessage='' /></div>;
    //         },
    //         field: "totalPOAmount",
    //         valueFormatter: (data) => {
    //             data.value = data.value ? fixedDecimalNumber(data.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    //             return data.value;
    //         },
    //         cellRendererFramework: (params) => {
    //             return <div> <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span> </div>
    //         },
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "status",
    //         cellRendererFramework: (params) => {
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /></span> </div>
    //         },
    //         resizable: true,
    //     },
       
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.listing.totalOrder.header.poDate' defaultMessage='' />;
    //         },
    //         field: "poDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}>
    //                     {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true,
    //     }
    // ];

    return <>
        
            {/* 
            <div className='agGridWrapper'>

            <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={totalOrderList[pageNumber] || []}
                    rowDragManaged={true}
                    domLayout={"autoHeight"}
                    animateRows={true}
                    onColumnResized ={(params)=>{
                        params.api.resetRowHeights();
                    }}
                    defaultColDef={{
                        flex:1,
                        autoHeight: true,
                        wrapText: true,
                    }}
                    //getRowHeight={getRowHeight}
                    onGridSizeChanged={onFirstDataRendered}
                >
                </AgGridReact>
            </div> 
        </div>
        */}
            <Skeleton loading={props.modalLoading } active paragraph={{ rows: 9 }}>
                <div className="table-container" style={{height: '500px'}}>
                    <table id='supplier-table'>
                        <thead>
                            <tr>
                                <th><FormattedMessage id='supplier.listing.totalOrder.header.poNumber' defaultMessage='' /></th>
                                <th><FormattedMessage id="supplier.listing.totalOrder.header.poAmount" defaultMessage="" /></th>
                                <th><FormattedMessage id='common.status' defaultMessage='' /></th>
                                <th width='6%'><FormattedMessage id='supplier.listing.totalOrder.header.poDate' defaultMessage='' /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(totalOrderList[pageNumber] || []).length ? (totalOrderList[pageNumber] || []).map((rowData, i) => {
                                return <tr key={i}>
                                    <td>
                                        <div className="cursor-pointer" onClick={() => {
                                            openPoDetails(rowData);
                                        }}>
                                            {rowData.poNumber}
                                        </div>
                                    </td>
                                    <td>
                                        <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(rowData.totalPOAmount)}</span>
                                    </td>

                                    <td>
                                        {rowData.status ? <div className="status-code" style={{ background: rowData.userStatusColor }}>
                                        <FormattedMessage id={rowData.status} defaultMessage="" /></div> : ''}
                                    </td>
                                    <td>
                                        {(rowData.poDate && getMomentDateForUIReadOnly({ date: rowData.poDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                    </td>

                                </tr>;
                            }) : 'No data'}

                        </tbody>
                    </table>
                </div>
            </Skeleton>
            <Skeleton loading={props.headerLoading} active paragraph={false}>
                <Pagination
                    size="small"
                    total={totalOrderListCount}
                    showTotal={showTotal}
                    defaultPageSize={100} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                    // showQuickJumper
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({ pageSize: pageSize, pageNumber: pageNumber });
                    } }
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber, pageSize, props);
                    } } 
                />
            </Skeleton>
        </>
             
    
    };

export default injectIntl(TotalOrdersComponent);
