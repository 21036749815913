import React, {useMemo, useState} from 'react';
import '@ant-design/compatible/assets/index.css';
import { injectIntl } from 'react-intl';
import "./index.scss";
import {Row,Col, Button, Divider, Skeleton} from "antd";
import EasypostIcon from "../../../assets/images/icon/eazypostIcon.png";
import ShiprocketIcon from "../../../assets/images/icon/shipRocketIcon.png";
import EasyPost from "../../../containers/shipping/easyPost";
import { TextBox } from '../../general/TextBox';
import { ErrorMsg } from '../../general/ErrorMessage';
import { MODULE_CODED_VALUES } from '../../../static/constants';
import { getPermissionData } from '../../../utils';
import { LOGISTICS_PROVIDER } from '../../../static/shipmentConstant';

function ShippingComp(props) {

  const primaryPerm = (props.permissions && props.permissions.primary) || [];

  const shippingPermission = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.INTEGRATION_SHIPPING )
  },[]);

  const easypostIntegrations = useMemo(()=>(props.integratedLogistics || []).filter(Obj=>Obj.plateformName === LOGISTICS_PROVIDER.EASYPOST));
  const shiprocketIntegrations = useMemo(()=>(props.integratedLogistics || []).filter(Obj=>Obj.plateformName === LOGISTICS_PROVIDER.SHIPROCKET));

  const ShipRocketModal = (props) =>{
    const [userDefinedName, setUserDefinedName] = useState(props.userDefinedName || '');
    const [apiUser, setApiUser] = useState(props.apiUser || '');
    const [apiKeySecret, setApiKeySecret] = useState(props.apiKeySecret || '');
    const [submittedOnce, setSubmittedOnce] = useState(false);
  
    const handleConnect = () => {
      setSubmittedOnce(true);
  
      if (!apiUser || !apiKeySecret) {
        return;
      }
      const payload = {
        relationshipId: props.companyInfo?.relationshipId,
        userDefinedName: userDefinedName,
        apiUser: apiUser,
        plateformName: LOGISTICS_PROVIDER.SHIPROCKET,
        secretKey: apiKeySecret,
      };
  
      props.saveLogisticsConfiguration(payload, props);
    };

    return (
      <>
      <Row gutter={[16, 16]}>

        <Col span={12}>
          <div className="i-label">Name</div>
          <TextBox
            type="text"
            placeholder="User Defined Name"
            value={userDefinedName}
            onChange={(e) => setUserDefinedName(e.target.value)}
          />
          <ErrorMsg
            validator={() => !submittedOnce || userDefinedName}
            message="This field is required"
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Username/Email</div>
          <TextBox
            type="text"
            placeholder="userName"
            value={apiUser}
            onChange={(e) => setApiUser(e.target.value)}
          />
          <ErrorMsg
            validator={() => !submittedOnce || apiUser}
            message="This field is required"
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Password</div>
          <TextBox
            type="text"
            value={apiKeySecret}
            onChange={(e) => setApiKeySecret(e.target.value)}
            placeholder="API user password"
          />
          <ErrorMsg
            validator={() => !submittedOnce || apiKeySecret}
            message="This field is required"
          />
        </Col>
      </Row>
      <Divider className='mt10 mb10' />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => props.popModalFromStack()}>Cancel</Button>
        <Button loading={props.saveLoading} type="primary" onClick={handleConnect}>
          Connect
        </Button>
      </div>
    </>
    )
  }

  const getLogisticsIcon = (plateformName) =>{
    switch(plateformName){
      case LOGISTICS_PROVIDER.EASYPOST:
        return <img src={EasypostIcon} alt="Easypost Icon" />;
      case LOGISTICS_PROVIDER.SHIPROCKET:
        return <img src={ShiprocketIcon} alt="Shiprocket Icon" />;
      default:
        return;
    }
  }


    return (
      <div className="logistics_integration_container">
        <Row>
          <Col span={24}>
            <div className="header">
              <h2>Shipping</h2>
              <p>Manage your company shipping</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={20} xl={12} xxl={8}>
            <div className="plateform_tile_container">
              <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
                {(easypostIntegrations || []).map((item, ind)=>{
                  return <div key={ind+"logisticsTile"} className='logistics_tile'>
                    <div className='nameArea'>
                      {getLogisticsIcon(item.plateformName)}
                      <h2>{item.userDefinedName || ""}</h2>
                      <div>{item.plateformName}</div>
                    </div>
                    <div className='buttonArea'>
                      {shippingPermission.delete ? 
                        <Button 
                          type='primary'
                          onClick={()=>{

                          }}
                        >
                          Disconnect
                        </Button> 
                        :
                        ""
                      }
                    </div>
                    {/* <div className='notActive'></div> */}
                  </div>
                })}

                <div className='logistics_tile'>
                  <div className='nameArea'>
                    <img src={EasypostIcon} alt="" />
                    <h2>Easypost</h2>
                  </div>
                  <div className='buttonArea'>
                    {shippingPermission.create ? <Button 
                      type='primary'
                      onClick={()=>{
                        props.pushModalToStack({
                          title:"Shipping Channel EasyPost",
                          modalBody: <EasyPost {...props} />,
                          width: '100%',
                          hideFooter: true,
                          wrapClassName: 'new-transaction-wrapper'
                        })
                      }}
                      // className='notActiveButton'
                    >
                      Connect
                    </Button> 
                    : 
                    ""}
                  {/* <div className='notActive'></div> */}
                  </div>
                </div>

                {/* shiprocket tile */}
                {(shiprocketIntegrations || []).map((item, ind)=>{
                  return <div className='logistics_tile' key={ind+'logShip'}>
                    <div className='nameArea'>
                      {getLogisticsIcon(item.plateformName)}
                      <div>
                        <h2>{item.userDefinedName}</h2>
                        <div>{item.plateformName}</div>
                      </div>
                    </div>
                    <div className='buttonArea'>
                      {shippingPermission.delete ? <Button 
                        type='primary'
                        loading={props.saveLoading}
                        onClick={()=>{
                          props.disconnectLogisticsConfiguration({
                            relationshipId: item.relationshipId,
                            logisticsIntegrationId: item.logisticsIntegrationId
                          });
                        }}
                      >
                        Diconnect
                      </Button>:""}
                    </div>
                    {/* <div className='notActive'></div> */}
                  </div>
                  }
                )}
                <div className='logistics_tile'>
                  <div className='nameArea'>
                    <img src={ShiprocketIcon} alt="" />
                    <h2>Shiprocket</h2>
                  </div>
                  <div className='buttonArea'>
                    {shippingPermission.create ? <Button 
                      type='primary'
                      loading={props.saveLoading}
                      onClick={()=>{
                        props.pushModalToStack({
                          title:"Shipping Channel ShipRocket",
                          modalBody: <ShipRocketModal {...props} />,
                          width: '60%',
                          hideFooter: true,
                        })
                      }}
                    >
                      Connect
                    </Button>:""}
                  </div>
                  {/* <div className='notActive'></div> */}
                </div>
              </Skeleton>
            </div>
          </Col>
        </Row>
      </div>
    );

}

export default injectIntl(ShippingComp);