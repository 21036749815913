import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as find from "lodash.find";
import * as filter from "lodash.filter";

import StandardRFQ from "../../../../components/supplier/rfq/StandardRFQ";
import {
  fetchSuppliers,
  resetPaginatedData,
} from "../../../../containers/supplier/Listing/action";
import { fetchCustomers } from "../../../../containers/customer/Listing/action";
import RFQDetail from "../../../modal/modalBody/supplier/RFQDetail";
import {
  fetchProjects,
  fetchProducts,
  fetchCustomerDetailsByCustomerId,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchAllDepartments,
  addDepartment,
  deleteDepartment,
  deleteProject,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchAllEmployees,
  deleteEmployee,
  fetchRelationshipTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  deleteSupplierTaxIdentifications,
  fetchTaxes,
  getRelationshipAddress,
  deleteAddress,
  fetchSupplierDetailsBySupplierId,
} from "../../../../actions/commonActions";
import {
  createRFQ,
  draftRFQ,
  fetchSQDataForSO,
  resetSalesQuoteSOData,
  fetchRfqDataForPr,
  resetRfqPrData,
  fetchRfqData,
  resetRfqData,
  getNextTxNumber,
  fetchInquiryDetail,
  resetEnquiryDetail,
  deleteDocument
} from "./action";
import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
} from "../../../../utils";
import { GRID_PREFERENCE_CONSTANTS } from "../../../../static/agGridConstants";
import {
  CONSTANTS,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
  TRANSACTION_TYPES
} from "../../../../static/constants";
import { deleteCustomers } from "../../../customer/Listing/action";
import { deleteSuppliers } from "../../Listing/action";
import { fetchAllRFQ } from "../Listing/action";
import {
  fetchRemarksTemplate,
  resetRemarksData,
} from "../../../modal/modalBody/settings/RemarksTemplate/action";
import { downloadFileFromBucket} from "../../../../actions/downloadFileAction";
import { deleteAttectedFile } from "../../../../actions/commonActions";
import SettingPurchaseDrawer from "../../../drawer/supplier/settingPurchaseDrawer";
class PurchaseInvoice extends Component {
  constructor(props) {
    super(props);
    const allRelationshipBillingAddress =
      filter(props.companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
    const relationshipAddress =
      allRelationshipBillingAddress.find((x) => x.isDefault) || {};
    const linkProps = (props.location && props.location.state) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNameRFQ",
      viewName: "RFQ",
      docName:"RFQ",
      tabDocumentOptions: 
      { txType: CONSTANTS_TRANSACTIONS.RFQ, label: 'RFQ', numberExample: 'RFQ-1', numberInfo: 'RFQ-1' },
      settingPurchaseDrawerVisible:false,

      customer: props.customer || linkProps.customer,
      customerName: (props.customer || linkProps.customer || {}).companyName,
      operationType: props.operationType || linkProps.operationType,
      isClone: props.clone || linkProps.clone,
      allRelationshipBillingAddress,
      relationshipAddress,
      selectedSuppliers: [],
      oldRfqDetailData: {},
      supplier: undefined,
      rfqDate: new Date(),
      expirationDate: new Date(),
      priceListName: undefined,
      pageSize: 100,
      pageNumber: 1,
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "rfq", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.UOM,
        "sno",
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        description: true,
        uom: true,
        // hsn: true,
        // specialDiscount: true,
        // discount: true,
        // tax: true
      },
      isLoded: props.rfqParentDraftId ? false : true,
    };
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true
    // });
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      rfqDate: new Date(),
      supplierIdArr: this.props.supplierIdArr || linkProps.supplierIdArr,
      salesQuotationMasterId:
        this.props.salesQuotationMasterId || linkProps.salesQuotationMasterId,
      purchaseRequestMasterId:
        this.props.purchaseRequestMasterId || linkProps.purchaseRequestMasterId,
      customerId: this.props.customerId || linkProps.customerId,
      inquiryMasterId: this.props.inquiryMasterId || linkProps.inquiryMasterId,
      userId: (this.props.userInfo || {}).userId,
      rfqMasterId: this.props.rfqMasterId || linkProps.rfqMasterId,
      pageNumber: 1,
      pageSize: 10,
    };
    this.props.resetSalesQuoteSOData();
    this.props.resetRfqPrData();
    this.props.fetchAllRFQ(payload);
    this.props.resetPaginatedData();
    payload.salesQuotationMasterId &&
      fetchDataIfNeeded(
        "fetchSQDataForSO",
        "sqSoData",
        this.props,
        payload,
        true
      );
    payload.purchaseRequestMasterId &&
      fetchDataIfNeeded(
        "fetchRfqDataForPr",
        "rfqPrData",
        this.props,
        payload,
        true
      );
    payload.inquiryMasterId && this.props.fetchInquiryDetail(payload);
    payload.customerId &&
      fetchDataIfNeeded(
        "fetchCustomerDetailsByCustomerId",
        "customerDetail",
        this.props,
        payload,
        true
      );
    payload.rfqMasterId &&
      fetchDataIfNeeded("fetchRfqData", "rfqDetail", this.props, payload, true);
    fetchPaginationDataIfNeeded(
      "fetchSuppliers",
      "suppliers",
      this.props,
      payload,
      true
    );
    fetchPaginationDataIfNeeded(
      "fetchCustomers",
      "customers",
      this.props,
      payload,
      true
    );
    fetchDataIfNeeded(
      "fetchAllDepartments",
      "departments",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchProducts", "products", this.props, payload, true);
    fetchDataIfNeeded("fetchProjects", "projectList", this.props, payload);
    fetchDataIfNeeded("fetchUOM", "uomList", this.props, payload);
    fetchDataIfNeeded("fetchTaxes", "taxes", this.props, payload);
    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded("getNextTxNumber", "nextTxNumber", this.props, payload);
    }
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchAllEmployees", "employees", this.props, payload);
    this.props.fetchRemarksTemplate({
      relationshipId: companyInfo.relationshipId,
      docName: "Request for Quote",
    });
    fetchDataIfNeeded(
      "fetchRelationshipTaxIdentifications",
      "allRelationshipTaxIdentificatins",
      this.props,
      payload
    );
    if(this.props.rfqPrData?.purchaseRequestMasterId){
      this.props.fetchSupplierDetailsBySupplierId(this.props.rfqPrData)
    }
  }

  openRFQDetails = (data) => {
    const rfqPayload = {
      supplierId: data.supplierId,
      rfqMasterId: data.rfqMasterId,
    };
    this.props.pushModalToStack({
      modalBody: <RFQDetail 
      // {...this.props} 
      rfqPayload={rfqPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  componentWillReceiveProps(props) {
    const linkProps = (props.location && props.location.state) || {};
    const rfqDraftData =
      !this.state.isDraftDataLoaded &&
      props.rfqData &&
      typeof props.rfqData === "string"
        ? JSON.parse(props.rfqData)
        : null;
    if (rfqDraftData && props.rfqParentDraftId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
      };
      rfqDraftData.rfqDetailsList.map((ele) => {
        _emptyColumnCheck(ele);
        return detailList.push({
          version: ele.version,
          rfqDetailsId: ele.rfqDetailsId,
          isMaster: true,
          selectedProductValue: [ele.itemId, ele.itemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          parentDetailsId: ele.parentDetailsId,
        });
      });

      const customerName = rfqDraftData.customerName;
      const departmentName = rfqDraftData.requestingDepartment;
      const orderPriority = rfqDraftData.orderPriority;
      const selectedSuppliers = [];
      const selectedSupplierObjects = [];
      const selectedSupplierNames = [];
      // rfqDraftData.rfqBiddingSuppliersList.forEach((d) => {
      //   if (selectedSuppliers.indexOf(d.supplierId) === -1) {
      //     selectedSuppliers.push(d.supplierId);
      //   }
      // });

      rfqDraftData.rfqBiddingSuppliersList.forEach((d) => {
        if (selectedSuppliers.indexOf(d.supplierId) === -1) {
          const sup = find(this.props.suppliers[this.state.pageNumber], {
            supplierId: d.supplierId,
          });
          if (sup && sup.supplierId) {
            selectedSuppliers.push(d.supplierId);
          } else {
            selectedSuppliers.push(d.supplierName);
          }
          selectedSupplierObjects.push({
            relationshipId: (this.props || {}).companyInfo.relationshipId,
            supplierId: d.supplierId,
            supplierName: d.supplierName,
            supplierStoreName: d.supplierName,
          });
          if (d.supplierName) selectedSupplierNames.push(d.supplierName);
        }
      });

      const relationshipAddress =
        find(rfqDraftData.boLocationRFQList, {
          locationType: "RelationshipBillingAddress",
        }) || {};
      const customerBillingAddress =
        find(rfqDraftData.boLocationRFQList, {
          locationType: "BillingAddress",
        }) || {};

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      this.setState({
        relationshipAddress,
        version: props.rfqDataVersion || linkProps.rfqDataVersion,
        rfqParentDraftId: props.rfqParentDraftId || linkProps.rfqParentDraftId,
        customerBillingAddress,
        isDraftDataLoaded: true,
        selectedSuppliers,
        selectedSupplierObjects,
        selectedSupplierNames,
        rfqDate: rfqDraftData.rfqDate,
        expirationDate: rfqDraftData.rfqExpirationDate,
        salesPersonName: rfqDraftData.salesPersonFullName,
        orderPriority,
        docName: rfqDraftData.documentName,
        customerName,
        customerId: rfqDraftData.customerId,
        customer: { customerId: rfqDraftData.customerId, customerName },
        departmentName,
        internalRemarks: rfqDraftData.remarksInternal,
        supplierRemarks: rfqDraftData.remarksSupplier,
        footer: rfqDraftData.footer,
        customerRFQNumber: rfqDraftData.customerRFQNumber,
        refNumber: rfqDraftData.refNumber,
        rfqNumber: rfqDraftData.rfqNumber,
        projectName: rfqDraftData.projectName,
        projectNumber: rfqDraftData.projectNumber,
        rFQDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        tableDataReRendered: true,
        isLoded: true,
      });
      // if (linkProps.update) {
      //   this.setState({ nextTxNumber: rfqDraftData.rfqNumber });
      // }
    }

    if (
      !this.state.selectedRelationshipTaxIdentifications &&
      props.allRelationshipTaxIdentificatins &&
      props.allRelationshipTaxIdentificatins.length
    ) {
      const selectedList =
        (props.allRelationshipTaxIdentificatins &&
          filter(props.allRelationshipTaxIdentificatins, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedRelationshipTaxIdentifications = selectedList.map((obj) => {
        return obj.relationshipTaxIdentificationId;
      });
      this.setState({
        selectedRelationshipTaxIdentifications:
          selectedRelationshipTaxIdentifications,
      });
    }
    if (
      props.documentList &&
      props.documentList.length &&
      !this.state.docName
    ) {
      props.documentList.forEach((document) => {
        if (
          document.txType ===
            CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.RFQ] &&
          document.isDefault
        ) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName,
          });
        }
      });
    }

    if (
      !this.state.supplierRemarks &&
      props.remarksData &&
      props.remarksData.isFetched &&
      !this.state.isRemarksSet
    ) {
      this.setState({
        supplierRemarks: props.remarksData.content || "",
        isRemarksSet: true,
        footer: props.remarksData.footer || "",
      });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500);
    }
    if (props.enquiryDetail && props.enquiryDetail.inquiryMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
      };
      props.enquiryDetail.inquiryDetailsList.map((ele) => {
        _emptyColumnCheck(ele);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.itemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
        });
      });

      const customerName = props.enquiryDetail.customerName;
      const departmentName = props.enquiryDetail.requestingDepartment;
      const orderPriority = props.enquiryDetail.priority;

      const relationshipAddress =
        find(props.enquiryDetail.boLocationInquiryList, {
          locationType: "RelationshipBillingAddress",
        }) || {};
      const customerBillingAddress =
        find(props.enquiryDetail.boLocationInquiryList, {
          locationType: "BillingAddress",
        }) || {};

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      this.setState({
        version: props.enquiryDetail.version,
        relationshipAddress,
        customerBillingAddress,
        inquiryMasterId: props.enquiryDetail.inquiryMasterId,
        isInquiryConversion: 1,
        salesPersonName: props.enquiryDetail.salesPersonFullName,
        orderPriority,
        expirationDate: props.enquiryDetail.expirationDate,
        // docName: props.enquiryDetail.documentName,
        customerName,
        customerId: props.enquiryDetail.customerId,
        customer: { customerId: props.enquiryDetail.customerId, customerName },
        departmentName,
        internalRemarks: props.enquiryDetail.remarksInternal,
        supplierRemarks: props.enquiryDetail.remarksSupplier,
        footer: props.enquiryDetail.footer,
        customerRFQNumber: props.enquiryDetail.customerRFQNumber,
        refNumber: props.enquiryDetail.refNumber,
        projectName: props.enquiryDetail.projectName,
        projectNumber: props.enquiryDetail.projectNumber,
        rFQDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
      });

      props.resetEnquiryDetail();
    }
    if (
      (this.props.suppliers[this.state.pageNumber] || []).length &&
      props.rfqDetail &&
      props.rfqDetail.rfqMasterId
    ) {
      const detailList = [];
      const additionalInfoList = props.rfqDetail.additionalInfoList[0] || {};
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
      };
      props.rfqDetail.rfqDetailsList.map((ele) => {
        _emptyColumnCheck(ele);
        return detailList.push({
          version: ele.version,
          rfqDetailsId: ele.rfqDetailsId,
          isMaster: true,
          selectedProductValue: [ele.itemId, ele.itemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          parentDetailsId: ele.parentDetailsId,
          purchaseRequestMasterId: ele.purchaseRequestMasterId,
          prNumber: ele.purchaseRequestNumber,
        });
      });

      const customerName = props.rfqDetail.customerName;
      const departmentName = props.rfqDetail.requestingDepartment;
      const orderPriority = props.rfqDetail.orderPriority;
      const selectedSuppliers = [];
      const selectedSupplierObjects = [];
      const selectedSupplierNames = [];
      props.rfqDetail.rfqBiddingSuppliersList.forEach((d) => {
        if (selectedSuppliers.indexOf(d.supplierId) === -1) {
          const sup = find(this.props.suppliers[this.state.pageNumber], {
            supplierId: d.supplierId,
          });
          if (sup && sup.supplierId) {
            selectedSuppliers.push(d.supplierId);
          } else {
            selectedSuppliers.push(d.supplierName);
          }
          selectedSupplierObjects.push({
            relationshipId: (this.props || {}).companyInfo.relationshipId,
            supplierId: d.supplierId,
            supplierName: d.supplierName,
            supplierStoreName: d.supplierName,
          });
          if (d.supplierName) selectedSupplierNames.push(d.supplierName);
        }
      });

      const relationshipAddress =
        find(props.rfqDetail.boLocationRFQList, {
          locationType: "RelationshipBillingAddress",
        }) || {};
      const customerBillingAddress =
        find(props.rfqDetail.boLocationRFQList, {
          locationType: "BillingAddress",
        }) || {};

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      this.setState({
        relationshipAddress,
        customerBillingAddress,
        selectedSuppliers,
        selectedSupplierObjects,
        selectedSupplierNames,
        version: props.rfqDetail.version,
        rfqBiddingSuppliersList: props.rfqDetail.rfqBiddingSuppliersList,
        oldRfqDetailData: props.rfqDetail,
        salesPersonName: props.rfqDetail.salesPersonFullName,
        orderPriority,
        rfqDate: props.update ? props.rfqDetail.rfqDate : new Date(),
        expirationDate: props.update
          ? props.rfqDetail.rfqExpirationDate
          : new Date(),
        docName: props.rfqDetail.documentName,
        customerName,
        customerId: props.rfqDetail.customerId,
        customer: { customerId: props.rfqDetail.customerId, customerName },
        departmentName,
        internalRemarks: props.rfqDetail.remarksInternal,
        supplierRemarks: props.rfqDetail.remarksSupplier,
        footer: props.rfqDetail.footer,
        customerRFQNumber: props.rfqDetail.customerRFQNumber,
        refNumber: props.rfqDetail.refNumber,
        rfqNumber: props.rfqDetail.rfqNumber,
        projectName: props.rfqDetail.projectName,
        projectNumber: props.rfqDetail.projectNumber,
        rfqMasterId: props.rfqDetail.rfqMasterId,
        rfqId: props.rfqDetail.rfqId,
        rFQDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        purchaseRequestMasterId: props.rfqDetail.purchaseRequestMasterId,
        docDetailList: props.rfqDetail.docDetailList,
        prNumber: props.rfqDetail.purchaseRequestNumber,
        isConvertedFromPR: props.rfqDetail.isPRCconversion,
        selectedRelationshipTaxIdentifications:
          additionalInfoList.relationshipTaxIdentifications
            ? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(
                (obj) => {
                  return obj.relationshipTaxIdentificationId;
                }
              )
            : this.state.selectedRelationshipTaxIdentifications,
      });
      if (props.update) {
        this.setState({ nextTxNumber: props.rfqDetail.rfqNumber });
      }
      props.resetRfqData();
    }

    if (props.sqSoData && props.sqSoData.salesQuotationMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
      };

      props.sqSoData.customerSalesQuotationDetailsList.map((ele) => {
        _emptyColumnCheck(ele);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product:
            ele.itemName +
            ((ele.itemVariantName && " (" + ele.itemVariantName + ")") || ""),
          productObj: {
            itemName: ele.itemName,
            itemId: ele.itemId,
            sku: ele.sku,
          },
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
        });
      });

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      this.setState({
        isConvertedFromSQ: true,
        relationshipAddress: find(props.sqSoData.boLocationSQList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }),
        supplierBillingAddress: find(props.sqSoData.boLocationSQList, {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }),
        refNumber: props.sqSoData.referenceNumber,
        orderPriority: props.sqSoData.orderPriority,
        customerName: props.sqSoData.customerName,
        supplierRemarks: props.sqSoData.remarksCustomer,
        internalRemarks: props.sqSoData.remarksInternal,
        footer: props.sqSoData.footer,
        customerInquiryNumber: props.sqSoData.customerInquiryNumber,
        customer: props.customerDetail,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        rFQDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
      });
      props.resetSalesQuoteSOData();
    }

    if (props.rfqPrData && props.rfqPrData.purchaseRequestMasterId) {
      const detailList = [];

      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
      };
      props.rfqPrData.purchaseRequestDetailsList.map((ele) => {
        _emptyColumnCheck(ele);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          parentDetailsId: ele.purchaseRequestDetailsId,
        });
      });
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      let selectedSuppliers= [];
      let selectedSupplierNames= [];
      let selectedSupplierObjects= [];
      let rfqBiddingSuppliersList= [];
      selectedSuppliers.push(props.rfqPrData?.supplierName)
      selectedSupplierNames.push(props.rfqPrData?.supplierName)
      selectedSupplierObjects.push({
        relationshipId: (this.props.companyInfo || {})
          .relationshipId,
        supplierId: props.rfqPrData?.supplierId,
        supplierName: props.rfqPrData?.supplierName,
        supplierStoreName:props.rfqPrData?.supplierName,
      });

      rfqBiddingSuppliersList.push({
        relationshipId: (this.props.companyInfo || {})
          .relationshipId,
        supplierId: props.rfqPrData?.supplierId,
        supplierName: props.rfqPrData?.supplierName,
        supplierStoreName:props.rfqPrData?.supplierName,
      });
      this.setState({
        isConvertedFromPR: true,
        purchaseRequestMasterId: props.rfqPrData.purchaseRequestMasterId,
        prRequestedBy: props.rfqPrData.prRequestedBy,
        orderPriority: props.rfqPrData.orderPriority,
        // supplierRemarks: props.rfqPrData.remarksEmployee,
        internalRemarks: props.rfqPrData.remarksEmployee,
        footer: props.rfqPrData.footer,
        isRFQFromPr: true,
        selectedSupplierNames,
        selectedSupplierObjects,
        rfqBiddingSuppliersList,
        selectedSuppliers,
        supplierId:props.rfqPrData.supplierId,
        prNumber: props.rfqPrData.purchaseRequestNumber,
        customerInquiryNumber: props.rfqPrData.customerInquiryNumber,
        customerRFQNumber: props.rfqPrData.customerRFQNumber,
        customerPONumber: props.rfqPrData.customerPONumber,
        departmentName: props.rfqPrData.departmentName,
        rFQDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        refNumber: props.rfqPrData.reference,
        projectName: props.rfqPrData.projectName,
        projectNumber: props.rfqPrData.projectNumber,
        expirationDate:
          props.rfqPrData.prExpirationDate &&
          new Date(props.rfqPrData.prExpirationDate),
        purchaseRequestDate:
          props.rfqPrData.purchaseRequestDate &&
          new Date(props.rfqPrData.purchaseRequestDate),
        customerName: props.rfqPrData.customerName,
        customerId: props.rfqPrData.customerId,
        customer: {
          customerId: props.rfqPrData.customerId,
          customerName: props.rfqPrData.customerName,
        },
      });
      props.resetRfqPrData();
    }
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences =
        find(props.allPreferences, {
          txType: "rfq",
          templateName: "standard",
        }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
              "product",
              "description"
            );
        const txColumnSetting = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnSetting || {}
          : {};
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }

    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      const allRelationshipBillingAddress = [];
      const allRelationshipShippingAddress = [];
      props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === "RelationshipBillingAddress") {
          allRelationshipBillingAddress.push(e);
        } else {
          allRelationshipShippingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });
    } else {
      const allRelationshipBillingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || [];
      const allRelationshipShippingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
        }) || [];
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });
    }
  }

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching,
    };
    this.props.fetchProducts(payload);
  }

  render() {
    return (
      <>
        {this.state.settingPurchaseDrawerVisible && 
          <SettingPurchaseDrawer
            {...this.state}
            {...this.props}
            docName={TRANSACTION_TYPES.RFQ}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
            />
        }
        <StandardRFQ
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          openRFQDetails={this.openRFQDetails}
          updateState={(data) => {
            this.setState(data);
          }}
          updateDrawerState={(data) => { this.setState(data) }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suppliers: state.supplier.suppliers,
    customers: state.customer.customers,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    permissions: state.auth.permissions,
    uomList: state.common.uomList,
    projectList: state.common.projectList,
    sqSoData: state.so.sqSoData,
    departments: state.common.departmentList,
    taxes: state.common.taxes,
    supplierDetail: state.common.supplierDetail,
    rfqDetail: state.rfq.rfqDetail,
    rfqPrData: state.purchaseRequest.rfqPrData,
    products: state.salesInvoice.products,
    customerDetail: state.common.customerDetail,
    documentList: state.common.documentList,
    nextTxNumber: state.rfq.nextRFQNumber,
    allPreferences: state.common.allPreferences,
    employees: state.common.employees,
    remarksData: state.settings.remarksData,
    enquiryDetail: state.enquiry.enquiryDetail,
    allRelationshipTaxIdentificatins:
    state.common.allRelationshipTaxIdentificatins,
    boRelationshipAddress: state.common.boRelationshipAddress,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createRFQ,
      fetchSQDataForSO,
      fetchRfqDataForPr,
      resetSalesQuoteSOData,
      resetRfqPrData,
      fetchCustomerDetailsByCustomerId,
      fetchSuppliers,
      fetchCustomers,
      fetchProjects,
      resetRemarksData,
      fetchUOM,
      fetchProducts,
      fetchAllDepartments,
      showModal,
      draftRFQ,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      deleteCustomers,
      deleteSuppliers,
      addUOM,
      deleteUOM,
      addDepartment,
      deleteDepartment,
      fetchRfqData,
      resetRfqData,
      deleteProject,
      fetchDocuments,
      addDocumentName,
      deleteDocumentName,
      deleteContact,
      fetchUserPreferences,
      saveUserPrefrences,
      getNextTxNumber,
      fetchAllEmployees,
      deleteEmployee,
      fetchRemarksTemplate,
      fetchInquiryDetail,
      resetEnquiryDetail,
      fetchTaxes,
      resetPaginatedData,
      fetchRelationshipTaxIdentifications,
      deleteRelationshipTaxIdentifications,
      deleteSupplierTaxIdentifications,
      getRelationshipAddress,
      deleteAddress,
      fetchAllRFQ,
      deleteAttectedFile,
      downloadFileFromBucket,
      fetchSupplierDetailsBySupplierId,
      deleteDocument
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoice);
