import React, { Fragment ,useMemo} from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Pagination, Skeleton, Dropdown, Menu, Button, Empty } from "antd";
import { fetchPaginationDataIfNeeded, getUserDefinedName, sortTable, getPermissionData } from "../../../utils";
import PageBreadcrumb from "../../PageBreadcrumb";
import { ICONS ,MODULE_CODED_VALUES} from "../../../static/constants";
import PageHeader from "../../Common/pageHeader";

const Department = (props) => {
  const { departmentList, updateState, companyInfo, departmentCount, pageNumber,permissions, dir=0, } = props;
  // departmentList[pageNumber].sort((a, b)=> a.dateCreated < b.dateCreated ? 1 : -1);
  
  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.curriculum' defaultMessage='' />,
    },
    {
      name: getUserDefinedName('sidebar.menuItem.program',props),
    }
  ];
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL )
  },[])
  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Action
          </Menu.Item>

          <Menu.Divider />

        {permissionsData.update ?  <Menu.Item key="1" value="edit" onClick={()=>{
            newDepartment(e);
            }}
          >
            <i className={ICONS.EDIT}/>
            Edit
          </Menu.Item> : "" }

          {permissionsData.delete ?  <Menu.Item key="2" onClick={()=>
            {
             props.showModal({
                modalBody: "Are you sure you want to delete?",
                handleSubmit: () => {
                  props.deleteDepartment(e, props);
                }
              })
            }
          }>
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item> :""}

        </Menu >
    )
  };

  const sortColumn = (e) => {
    sortTable(document.getElementById('department-list'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getDepartmentList", "departmentList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getDepartmentList(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const newDepartment= (deptData) => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addDepartmentDrawerVisible: true,
      },
      deptData: deptData
    });
  };

  return (
    <>
      <PageHeader {...props}
        pageName={getUserDefinedName('sidebar.menuItem.program', props)}
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={newDepartment}
        moduleCode = {MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL}
      />
      <div className="view-container">
          <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} active>

            <div className="left-actions">
              <div className="table-heading">
              {getUserDefinedName('schoolManagement.department.table.heading',props)}
              </div>
              <div className="vertical-sep" />
              {
                departmentCount ? 
                  <div>{departmentCount}</div>
                :0
              }
            </div>
            <div className="right-actions">
            </div>
          </Skeleton>
          </div>
          <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>
            <div className="table-container">
              <table id='department-list'>
                <thead>
                  <tr>
                    <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                    <th onClick={sortColumn}>{getUserDefinedName('modal.common.project.departmentName',props)} <i className={ICONS.SORT} /></th>
                    <th onClick={sortColumn}>{getUserDefinedName('departmentCode.text',props)} <i className={ICONS.SORT} /></th>
                    {permissionsData.delete || permissionsData.update ?  <th width='6%' className="text-center"><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> : "" }
                  </tr>
                </thead>
                <tbody>
                  {
                    (departmentList[pageNumber] || [])?.length ? (  (departmentList[pageNumber] || []).map((e, i) => {
                      return <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{e.deptName||<div className="empty-data-box"/>}</td>
                        <td>{e.deptCode||<div className="empty-data-box"/>}</td>
                        {permissionsData.delete || permissionsData.update ? <td className="text-center"> 
                          <Dropdown overlay={actionMenu(e)} trigger={['click']}>
                            <i className={ICONS.MORE} />
                          </Dropdown>
                        </td> :"" }
                      </tr>
                    })):(<tr key="empty">
                      <td colSpan={"100%"}>
                        <Empty />
                      </td>
                    </tr>)
                  
                  }
                </tbody>
              </table>
            </div>
        </Skeleton>
        <div className="footer">
          <Skeleton loading={props.headerLoading} paragraph={false} active>
            <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={departmentCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </Skeleton>
        </div>
      </div>
    </>
  );
};

export default injectIntl(Department);
