import { Button, Radio, Input } from 'antd';
import React, { useState } from 'react';
import ImagePlaceholder from "../../../assets/images/img-placeholder-alpide.jpg";
import { FormattedMessage } from "react-intl";
import { getAWSFileURL } from '../../../utils';
import config from '../../../config/environmentConfig';
const { Search } = Input;

const CategorySingleSelectComp = (props) => {
  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(
    props.inventoryItemCategoryRefs?.[0]?.inventoryItemCategoryId || null
  );

  const handleCategorySelect = () => {
    const val = props.productCategories?.find(i=>i.inventoryItemCategoryId == selectedCategory);
    props.callBack(val);
    props.popModalFromStack();
  };

  const getSubcategories = (subcategories, depth = 1) => {
    return subcategories.map((childSubcategory, i) => (
      <div key={childSubcategory.inventoryItemCategoryId + i}>
        <div
          className="radio-button-Wrapper"
          style={{ paddingLeft: `${depth * 12}px` }}
        >
          <Radio value={childSubcategory?.inventoryItemCategoryId}>
            <div className="parent-category-sub-wrapper">
              <div className="category-img">
                {childSubcategory.awsKeyName ? (
                  <img
                    src={getAWSFileURL(
                      childSubcategory.awsKeyName,
                      config.BUCKET_NAME.BO_INVENTORY
                    )}
                    alt="U"
                  />
                ) : (
                  <img style={{ height: "30px" }} src={ImagePlaceholder} alt="U" />
                )}
              </div>
              <div>
                <h2>{childSubcategory.categoryName}</h2>
                <p>{childSubcategory.totalProducts}</p>
              </div>
            </div>
          </Radio>
        </div>
        {(childSubcategory.subcategories || []).length
          ? getSubcategories(childSubcategory.subcategories, depth + 1)
          : ""}
      </div>
    ));
  };

  return (
    <>
      <div className="cat-search-wrapper">
        <Search
          placeholder="Search categories or subcategories..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="parent-category-list">
        <Radio.Group
          onChange={(e) => setSelectedCategory(e.target.value)}
          value={selectedCategory}
        >
          {(searchText?.length
            ? props.productCategories.filter((category) =>
                category.categoryName?.toLowerCase().includes(searchText.toLowerCase())
              )
            : props.productCategories
          )
            .filter((rowData) => rowData.parentId === 0)
            .map((category) => (
              <div key={category.inventoryItemCategoryId}>
                <div className="radio-button-Wrapper">
                  <Radio value={category.inventoryItemCategoryId}>
                    <div className="parent-category-sub-wrapper">
                      <div className="category-img">
                        {category.category ? (
                          <img
                            src={getAWSFileURL(
                              category.category,
                              config.BUCKET_NAME.BO_INVENTORY
                            )}
                            alt="U"
                          />
                        ) : (
                          <img
                            style={{ height: "30px" }}
                            src={ImagePlaceholder}
                            alt="U"
                          />
                        )}
                      </div>
                      <div className="parent-category-details">
                        <h2>{category.categoryName}</h2>
                        <p>{`${category.inventoryItemCategoryRefs?.length || 0} products`}</p>
                      </div>
                    </div>
                  </Radio>
                </div>
                {(category.subcategories || []).length
                  ? getSubcategories(category.subcategories)
                  : ""}
              </div>
            ))}
        </Radio.Group>
        <div className="custom-footer-parent-category">
          <Button type="primary" onClick={handleCategorySelect}>
            <FormattedMessage id="button.select.label" defaultMessage="Select" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default CategorySingleSelectComp;
