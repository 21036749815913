import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createOrUpdateProject = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.PROJECT_BASE_URL}${config.API_VERSION}/${config.rootContextProject}/projectMaster/createOrUpdateProject`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Project saved successfully' });
        props.closeDrawer && props.closeDrawer();
        (props && props.getAllProjectWithSubProject) && props.getAllProjectWithSubProject(payload);
        (props && props.fetchProjects) && props.fetchProjects(payload);
      })
      .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {})] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}