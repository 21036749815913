import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SUPPLIER_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchLandedCostList = (payload, history) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/landedCost/getAllLandedCost?relationshipId=${payload.relationshipId}`)
        .then(res => {
          const data = {
            pageNo: payload.pageNumber,
            list: res.data
          }
          dispatch({ type: SUPPLIER_ACTION_LIST.LANDED_COST_LIST, data});
        })
        .catch((err) => {
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          }else{
            err.actionToCall = SUPPLIER_ACTION_LIST.LANDED_COST_LIST;
            err.data = {
              pageNo: payload.pageNumber,
              list: []
            };
          }
  }).finally(()=>{
    dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
  })
    }
  }


  export const deleteLandedCostItems = (payload, props) => {
    return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
        .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/landedCost/deleteLandedCost`, payload)
        .then(res => {
            showToasterMessage({ messageType: 'success', description: 'Landed Cost deleted successfully' });
            const payload = {
              relationshipId: props.companyInfo.relationshipId,
              userId: (props.userInfo || {}).userId,
              pageNumber: 1,
              pageSize: 25
            };
            props.fetchLandedCostList(payload);
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          // if (!err.__isRetryRequest) {
          //     showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          // } else {
          // }
        })
    }
  }