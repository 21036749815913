import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, INVENTORY_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const fetchStockTransferDetail = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/git/getGitData?relationshipId=${payload.relationshipId}&gitMasterId=${payload.gitMasterId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: INVENTORY_ACTION_LIST.STOCK_TRANSFER_DETAIL, data });
          })
          .catch((err) => {

              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          
          })
      }
  }