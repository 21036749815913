import React, { useMemo } from "react";
import { ICONS, MODULE_CODED_VALUES } from "../../../static/constants";
import { FormattedMessage, injectIntl } from "react-intl";
import { Menu, Button, Empty, Pagination ,Skeleton} from "antd";
import DropdownAction from "antd/lib/dropdown";
import { fetchPaginationDataIfNeeded, getPermissionData } from "../../../utils";

function TeamComp(props) {
    const { companyInfo, teamList, pageNumber, pageSize ,permissions} = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = useMemo(()=>{
        return getPermissionData(primaryPerm , MODULE_CODED_VALUES.HRMS_EMPLOYEE );
      },[])
    
    const showTotal = (total) => {
        return (
            props.intl.formatMessage({
                id: "pagination.text.total",
                defaultMessage: "",
            })
            +
            ` ${total} ` +
            props.intl.formatMessage({
                id: "pagination.text.items",
                defaultMessage: "",
            })
        );
    };
    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        fetchPaginationDataIfNeeded('getTeam', 'teamList', props, payload);

    };

    const handlePageSizeChange = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        // props.resetPaginatedData();
        props.getTeam(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    };
    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Actions
                </Menu.Item>
                <Menu.Divider />
{
    permissionsData?.update ? <Menu.Item
    key="1"
    value="edit"
    onClick={() => {
        props.updateState({
            teamDrawer: true,
            teamData: e,
        })
    }}
>
    <i className={ICONS.EDIT} /> Edit
</Menu.Item>: ""
}
                
                {
                    permissionsData?.delete ? <Menu.Item
                    key="2"
                    value="delete"
                    onClick={() => {
                        props.showModal({
                            modalBody: "Are you sure want to delete?",
                            handleSubmit: () => {
                                props.deleteTeam(e, props)
                            },
                        });
                    }}
                >
                    <i className={ICONS.DELETE} /> Delete
                </Menu.Item>: ""
                }
                
            </Menu>
        );
    }
    return (
        <><div className="view-container">
            <div className="view-container-actions">
            <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

                <div className="left-actions">
                    <div className="table-heading">
                        <FormattedMessage
                            id="employeeMeta.team.pageHeading"
                            defaultMessage="" />
                        <div className="table-sub-heading">
                            Manage Team meta here
                        </div>
                    </div>
                </div>

                <div className="right-actions">
                    {
                        permissionsData?.create ?   <Button
                        style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
                        className="create-action-btn"
                        onClick={() => {
                            props.updateState({ teamDrawer: true,teamData:{} });
                        }}
                    >
                        <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
                        <FormattedMessage id="button.create.label" defaultMessage="" />
                    </Button>: ""
                    }
                  
                </div>
                </Skeleton>
            </div>

            <div className="table-container">
            <Skeleton loading={props.listLoading} paragraph={{ rows: 12 }} active>
                <table id="team-table">
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage id="serial.no" defaultMessage=" " />
                            </th>
                            <th>
                                Team Name
                            </th>
                            <th>Team Code</th>
                            <th>Branch</th>
                            <th>Department</th>
                            {
                                 (permissionsData?.update || permissionsData?.delete) ?  <th width="6%">Action</th>: ""
                            }
                           
                        </tr>
                    </thead>
                    <tbody>
                        {teamList && teamList[pageNumber]?.length
                            ? teamList[pageNumber]
                                .map((rowData, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{(pageNumber - 1) * pageSize + (i + 1)}</td>
                                            <td>{rowData.teamName || "-"}</td>
                                            <td>{rowData.teamCode || "-"}</td>
                                            <td>{rowData.warehouseName || "-"}</td>
                                            <td>{rowData.departmentName || "-"}</td>
                                            {
                                                 (permissionsData?.update || permissionsData?.delete) ?  <td className="text-center">
                                                <DropdownAction

                                                    overlay={actionMenu(rowData)}
                                                    trigger={["click"]}
                                                >
                                                    <i className={ICONS.MORE} />
                                                </DropdownAction>
                                            </td>: ""
                                            }
                                           
                                        </tr>
                                    );
                                })
                            :
                            <tr key="empty-data-box">
                                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                    <Empty />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                </Skeleton>
            </div>
            <div className="footer">
            <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

                <div className="f-left"></div>
                <div className="f-right">
                    <Pagination
                        size="small"
                        total={props.teamCount}
                        showTotal={showTotal}
                        defaultPageSize={props.pageSize}
                        showSizeChanger
                        pageSizeOptions={[10, 25, 50, 100, 200]}
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber || 1, pageSize, props);
                        }}

                    />
                </div>
            </Skeleton>
            </div>
        </div>
        </>
    )
}
export default injectIntl(TeamComp);