import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushModalToStack, popModalFromStack,fetchJobRoles,fetchJobTitles,fetchAllEmployees } from './../../../../actions/commonActions';
import { saveAttendance } from './action';
import { getEmpAttendances } from '../../../company/EmployeeAttendance/action';
import EmployeeAttendanceComp from "../../../../components/drawer/company/EmployeeAttendance";
import {getAllBranches} from "../../../company/EmployeeMeta/Branch/action";

class EmployeeAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 1000,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      hierarchyLevel: this.state.hierarchyLevel ? this.state.hierarchyLevel : 0 ,
      userId: (this.props.userInfo || {}).userId
    };
   
    this.props.fetchJobRoles(payload);
    this.props.fetchJobTitles(payload);
    this.props.fetchAllEmployees(payload);
    this.props.getAllBranches(payload);
    
    
  }


  render() {
    return (
      <EmployeeAttendanceComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
        updateDrawerState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.common.userInfo,
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    allEmployees : state.common.employees,
    jobRoleList: state.common.jobRoleList,
    jobTitleList: state.common.jobTitleList,
    branchList: state.employee.branchList
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
    
      saveAttendance,
      pushModalToStack,
      popModalFromStack,
      fetchJobRoles,
      fetchJobTitles,
      fetchAllEmployees,
      getEmpAttendances,
      getAllBranches,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAttendance);
