import React,{useMemo, useState} from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import PageBreadcrumb from "../../PageBreadcrumb";
import { getMomentDateForUIReadOnly, getPermissionData, sortTable } from "../../../utils";
import { Menu, Switch, Button, Skeleton } from "antd";
import DropdownAction from "antd/lib/dropdown";
import { ICONS, MODULE_CODED_VALUES } from "../../../static/constants";

const LeadSubSourceList = (props) => {
  const { dir = 0  , permissions} = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.LEAD_FROM_SETTING);
  },[])
  // const breadCrumbList = [
  //   {
  //     name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
  //   },
  //   {
  //     name: <FormattedMessage id="heading.lead.leadForm" defaultMessage="" />,
  //   },
  //   {
  //     name: (
  //       <FormattedMessage id="sidebar.menuItems.FormMeta" defaultMessage="" />
  //     ),
  //   },
  //   {
  //     name: <FormattedMessage id="lead.leadSource.label" defaultMessage="" />,
  //   },
  // ];

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("leadSource-table"), cellIndex, 1 - dir);
  };

  const actionMenu = (item,parent) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
      {
        permissionsData?.update ? <Menu.Item
        key="1"
        value="edit"
        onClick={() => {
          props.updateState({
            leadSourceListDrawer: true,
            parentLeadStageItem : parent,
            ...item,
          });
        }}
      >
        <i className={ICONS.EDIT} /> Edit
      </Menu.Item>: ""
      }
        
        {
          permissionsData?.delete ? <Menu.Item
          key="2"
          value="delete"
          onClick={() => {
            props.showModal({
              modalBody: "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteCrmLeadSource(item, props);
              },
            });
          }}
        >
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item>: ""
        }
        
      </Menu>
    );
  };
  const [childOnList, setChildOnList] = useState([]);

  const isChildOn = (id) => childOnList.includes(id);

  const toggleChild = (id) => {
      setChildOnList((prevList) =>
          prevList.includes(id) ? prevList.filter((itemId) => itemId !== id) : [...prevList, id]
      );
  };
  const getSubcategories = (subcategories = [], parentIndex = '0',item) => {
    return subcategories.map((subcategory, i) => {
        const index = `${parentIndex}.${i + 1}`;
        const isSubcategoryOpen = isChildOn(subcategory.crmLeadSourceId);

        const nestingLevel = parentIndex.toString().split('.').length;
        const marginLeft = 30 + (nestingLevel) * 10;

        return (
            <React.Fragment key={index}>
                <tr>
                <td> {parentIndex}.{i+1}</td>

                    <td>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: `${marginLeft}px` }}>
                            {subcategory?.subLeadSource && subcategory.subLeadSource.length > 0 && (
                                <span

                                    onClick={() => toggleChild(subcategory.crmLeadSourceId)}
                                    style={{ marginRight: '10px', marginLeft: '-20px' }}
                                >
                                    {isSubcategoryOpen ? <i className="fi fi-br-minus"></i> : <i className="fi fi-br-plus"></i>}
                                </span>
                            )}
                            
                        </div>
                    </td>
                    {/* <td className="text-center">
                        <span>{(subcategory?.subLeadSource || [])?.length}</span>
                    </td> */}
                    <td className="text-center" >
                      {getMomentDateForUIReadOnly({
                              date: subcategory.dateCreated,
                            })}
                        {/* {moment(subcategory?.createdDate).format('DD/MM/YYYY')} */}
                    </td>
                <td>
                {/* <Switch
                            checked={subcategory.isActive === 1}
                            onClick={(checked, e) => {
                              props.createLeadStage(
                                { ...subcategory, isActive: checked ? 1 : 0 },
                                props
                              );
                            }}
                          /> */}
                </td>
                
                {
                  (permissionsData?.delete || permissionsData?.update ) ?   <td className="text-center">
                  <div className="action-icon">
                      <DropdownAction
                          overlay={actionMenu(subcategory,item)}
                          trigger={["click"]}
                      >
                          <i className={ICONS.MORE} />
                      </DropdownAction>
                  </div>

              </td>: ""
                }
                  


                </tr>
                {isSubcategoryOpen && getSubcategories(subcategory.subLeadSource, index,subcategories)}
            </React.Fragment>
        );
    });
};
  return (
    // <div className="lead-list">

    <div className="view-container">
      <div className="view-container-actions">
        <div className="left-actions">
          <div className="table-heading">
            <FormattedMessage
              id="leads.leadForm.formMeta.leadSource.table.heading"
              defaultMessage=""
            />
          </div>
        </div>

        <div className="right-actions">
          {
            permissionsData?.create ? <Button
            style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
            className="create-action-btn"
            onClick={() => {
              // handleAddUpdateActivity({});
              props.updateState({ leadSourceListDrawer: true });
            }}
          >
            <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
            <FormattedMessage id="button.create.label" defaultMessage="" />
          </Button>: ""
          }
          
        </div>
      </div>
      <div className="table-container" style={{ height: "calc(100% - 59px)" }}>
      <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
        <table id="leadSource-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th onClick={sortColumn}>
                Source Name <i className={ICONS.SORT}></i>
              </th>
              {/* <th onClick={sortColumn}>
                      Source Code <i className={ICONS.SORT}></i>
                    </th> */}
              <th onClick={sortColumn}>
                Date Created <i className={ICONS.SORT}></i>
              </th>

              <th onClick={sortColumn}>
                Status <i className={ICONS.SORT}></i>
              </th>
              {
                (permissionsData?.delete || permissionsData?.update ) ?  <th width="6%">Action</th>: ""
              }
             
            </tr>
          </thead>

          <tbody>
            {(props.crmLeadSourceList || []).map((rowData, i) => {
              return (<>
                <tr key={"crm" + rowData.crmLeadSourceId}>
                  <td>{i + 1}</td>
                  <td>
                    <div className="plus-minus-icon">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {rowData.subLeadSource && rowData.subLeadSource.length > 0 ? (
                                <>
                                    <span

                                        onClick={() => toggleChild(rowData.crmLeadSourceId)}
                                    >
                                        {isChildOn(rowData.crmLeadSourceId) ? <i class="fi fi-br-minus"></i> : <i class="fi fi-br-plus"></i>}
                                    </span>
                                    <div style={{ marginLeft: "10px" }}>{rowData?.leadSourceName}</div>
                                </>

                            )
                                :
                                <div style={{ marginLeft: "22px" }}>{rowData?.leadSourceName}</div>
                            }

                        </div>
                    </div>
                    </td>
                 
                  {/* <td>{e.source_code || "-"}</td> */}
                  <td>
                    {rowData.dateCreated
                      ? getMomentDateForUIReadOnly({
                          date: rowData.dateCreated,
                        })
                      : "-"}
                  </td>
                  <td>
                    <div className="status-toggle">
                      <Switch
                        checked={rowData.isActive === 1}
                        disabled ={!(permissionsData?.update)}
                        onClick={(checked, e) => {
                          // props.updateState({
                          //   isActive: checked ? 1 : 0,
                          // });
                          props.createLeadSource(
                            { ...rowData, isActive: checked ? 1 : 0 },
                            props
                          );
                        }}
                      />
                    </div>
                  </td>
                  {
                    (permissionsData?.update || permissionsData?.delete) ? <td style={{ paddingLeft: "50px" }}>
                    <DropdownAction
                      overlay={actionMenu(rowData)}
                      trigger={["click"]}
                    >
                      <i className={ICONS.MORE} />
                    </DropdownAction>
                  </td>: ""
                  }
                  
                </tr>
                {isChildOn(rowData.crmLeadSourceId) && getSubcategories(rowData.subLeadSource, i + 1,rowData)}

              </>);
              // })
            })}
          </tbody>
        </table>
      </Skeleton>
      </div>
    </div>
  );
};

export default injectIntl(LeadSubSourceList);
