import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createOrUpdateExpense = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/expenseType/createExpenseType`, payload)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.EXPENSE_LIST_ADDITION, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.EXPENSE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
        }
      })
  }
}

export const getExpenseLedgerAccounts = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/getExpenseLedgerAccounts?relationshipId=${payload.relationshipId}&accountingEntry=${payload.accountingEntry}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.EXPENSE_LEDGER_LIST, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.EXPENSE_LEDGER_LIST;
        }
      })
  }
}