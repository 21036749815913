import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TeamDrawerComp from '../../../../../components/drawer/company/EmployeeMeta/Team';
import { showModal, hideModal,pushModalToStack } from '../../../../../actions/commonActions';
import { createTeam } from '../../../../company/EmployeeMeta/Team/action';
import { getDepartment, getTotalDepartmentCount } from '../../../../company/EmployeeMeta/Department/action';
import { getAllBranches } from '../../../../company/EmployeeMeta/Branch/action';

class TeamDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(this.props.teamData || {})
        };
    }

    componentDidMount(){
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 500,
        };
      this.props.getDepartment(payload);
      this.props.getAllBranches(payload);
    }


    render() {
        return <TeamDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        departmentList: state.employee.departmentList,
        drawerLoading: state.common.drawerLoading,
        branchList: state.employee.branchList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    createTeam,
    getDepartment,
    getAllBranches,
    pushModalToStack,
    getTotalDepartmentCount
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TeamDrawer);