import React, { Component, Fragment } from 'react';
import { Menu, Empty } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ICONS } from '../../../../../static/constants';
import DropdownAction from "antd/lib/dropdown";
import { MoreOutlined } from '@ant-design/icons';
import BankAccount from '../../../../../containers/modal/modalBody/common/BankAccount';
import { getUserDefinedBankName } from '../../../../../utils';

class BankInfo extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { permissionsData } = this.props;
        const bankDetailList = this.props.companyInfoData.bankDetails || [];

        const handleBankAccount = (data) => {
            let modalData = {
                title: data.bankDetailId ? "Edit Bank Account" : "Adding Bank Account",
                modalBody: <BankAccount {...this.props} bankDetails={data} />,
                wrapClassName: "pay-invoice-modal",
                width: 500,
                hideFooter: true,
                keyboard: false,
                onCancel: () => {
                    this.props.pushModalToStack({
                        modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage="" />,
                        footer: { submit: { intlId: "button.close.label" } },
                        handleSubmit: () => {
                            this.props.hideModal();
                        }
                    })
                }
            };
            this.props.showModal(modalData);
        }

        const actionMenu = (e) => {
            return (
                <Menu className="row-action-dropdown">
                    <Menu.Item key="0" value="title">
                        Action
                    </Menu.Item>

                    <Menu.Divider />
                    {
                        permissionsData.update ? <Menu.Item
                            key="1"
                            onClick={() => {
                                handleBankAccount(e);
                            }}
                        >
                            <i className={ICONS.EDIT} />
                            Edit
                        </Menu.Item> : ""
                    }

                    {
                        permissionsData.delete ? <Menu.Item
                            key="2"
                            value="delete"
                            onClick={() => {
                                this.props.showModal({
                                    modalBody: "Are you sure want to delete?",
                                    handleSubmit: () => {
                                        this.props.deleteBankDetail(e, this.props)
                                    },
                                });
                            }}
                        >
                            <i className={ICONS.DELETE} /> Delete
                        </Menu.Item> : ""
                    }

                </Menu>
            );
        };
        return (
            <div>
                <div className="view-top-bar">
                    <div className="top-view-left"></div>
                    <div className="top-view-right">
                        {
                            permissionsData.create ? <button
                                className="create-action-btn"
                                onClick={handleBankAccount}
                            >
                                <i className={`${ICONS.ADD} mr5`} />
                                <FormattedMessage id="button.create.label" defaultMessage="" />
                            </button> : ""
                        }

                    </div>
                </div>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="serial.no" defaultMessage="" />
                                </th>
                                <th>
                                    {/* <FormattedMessage id="modal.settings.companyInfo.bankInfo.bankName" defaultMessage="" /> */}
                                    {getUserDefinedBankName("bankName",this.props)}
                                </th>
                                <th>
                                    {/* <FormattedMessage
                                        id="modal.settings.companyInfo.bankInfo.payeeName"
                                        defaultMessage=""
                                    /> */}
                                    {getUserDefinedBankName("payeeName",this.props)}

                                </th>
                                <th>
                                    {/* <FormattedMessage
                                        id="modal.settings.companyInfo.bankInfo.accountNumber"
                                        defaultMessage=""
                                    /> */}
                                    {getUserDefinedBankName("accountNumber",this.props)}

                                </th>
                                <th>
                                    {/* <FormattedMessage
                                        id="modal.settings.companyInfo.bankInfo.ifsc"
                                        defaultMessage=""
                                    /> */}
                                    {getUserDefinedBankName("iFSC",this.props)}

                                </th>
                                <th>
                                    {/* <FormattedMessage id="modal.settings.companyInfo.bankInfo.routingNumber" defaultMessage="" /> */}
                                    {getUserDefinedBankName("routingNumber",this.props)}
                                    
                                </th>

                                <th>
                                {getUserDefinedBankName("branchName",this.props)}

                                {/* Branch Name */}
                                </th>

                                {
                                    (permissionsData.update || permissionsData.delete) ? <th>
                                        <FormattedMessage id="Action" defaultMessage="" />
                                    </th> : ""
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {(bankDetailList || []).length ?
                                bankDetailList.map((rowData, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{rowData.name}</td>
                                            <td>
                                                {rowData.accountHolderName}
                                            </td>
                                            <td>{rowData.accountNumber}</td>
                                            <td>{rowData.ifscCode}</td>
                                            <td>{rowData.routingNumber}</td>
                                            <td>{rowData.branchName}</td>

                                            {
                                                (permissionsData.update || permissionsData.delete) ? <td className="text-center">
                                                    <DropdownAction
                                                        overlay={actionMenu(rowData)}
                                                        trigger={["click"]}
                                                        overlayClassName="action-listing-dropdown"
                                                    >
                                                        <i className={ICONS.MORE_P} />
                                                    </DropdownAction>

                                                </td>
                                                    :
                                                    ""
                                            }

                                        </tr>
                                    );
                                })
                                :
                                <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default BankInfo;
