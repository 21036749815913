import axios from "axios";
import config from "../../../config/environmentConfig";
import { MEMBERSHIP_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getFees = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
        }/fees/getFees?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
        }&pageNumber=${(payload.pageNumber || 1) - 1}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: MEMBERSHIP_ACTION_LIST.TERM_AND_FEES_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getFeeCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fees/countFees?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: MEMBERSHIP_ACTION_LIST.TERM_AND_FEES_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const saveFees = (payload) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fees/saveOrUpdate`,
        payload
      )
      .then((response) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteTermAndFee = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fees/delete`, payload)
      .then(res => {
        // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // props.getFees && props.getFees(props);
        getFees(props)(dispatch);
        props.getFeeCount && props.getFeeCount(props);
        showToasterMessage({ messageType: 'success', description: 'Term & Fee deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: MEMBERSHIP_ACTION_LIST.TERM_AND_FEE_LIST_PAGE_RESET });
  };
};
