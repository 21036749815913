import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, TX_FILE_UPLOAD_ACTION } from '../../../static/constants'
import { showToasterMessage, getFinancialYear } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchTransactionDataByRID = (payload, endpointUrl) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });

    return axios
      // .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getAllSalesOrders?relationshipId=${payload.relationshipId}&pageSize=25&pageNumber=0`)
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${endpointUrl.fetchUrl}?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${payload.pageNumber - 1 || 0}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        // const { data } = res
        dispatch({ type: TX_FILE_UPLOAD_ACTION.TRANSACTION_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = TX_FILE_UPLOAD_ACTION.TRANSACTION_LIST;
        }
      })
  }
}

export const getTransactionCountByRID = (payload, endpointUrl) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
    .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${endpointUrl.countUrl}?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: TX_FILE_UPLOAD_ACTION.TRANSACTION_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = TX_FILE_UPLOAD_ACTION.TRANSACTION_COUNT;
        }
      })
  }
}

export const verifyCredentials = (payload, props) => {
  var loginData;
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/user/login`, {email: payload.username, password: payload.password}
      )
      .then(res => {
        loginData = res.data;
        // dispatch({ type: TX_FILE_UPLOAD_ACTION.TXT_USER_LOGIN, loginData });
        props.updateState({activePage : 'transactiontypes', loginData, relationshipId : loginData.defaultCompany.relationshipId })
        // return  showToasterMessage({ messageType: 'success', description: 'Logged in Successfully' })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
  }
}

export const UploadFiles = (payload, props) => {
  const endpointUrl = payload.endpointUrl;
  const { txNumber, relationshipId, txId} = payload
 // payload = { txNumber, relationshipId, txId}
  var formData = new FormData();
  //formData.append("payload", JSON.stringify(payload));
  formData.append("txNumber", txNumber);
  formData.append("relationshipId", relationshipId);
  formData.append("txId", txId);
  if (payload.files) {
    formData.append("files", payload.files);
  }

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      // .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignment/createAssignment`,
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${endpointUrl}`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
      .then(res => {
        showToasterMessage({ messageType: "success", description: lang[(res.data || "")] || "Saved succesfully" });
        // props.popModalFromStack();
      })
      .catch(err => {
        if (lang[((err.response || {}).data || "")]) {
          showToasterMessage({ messageType: "error", description: lang[((err.response || {}).data || "")] || "Some error occurred." });
        } else {
          showToasterMessage({ messageType: "error", description: "Some error occurred." });
          // props.popModalFromStack();
        }
      })
  }
}


export const getTransactionByCustomerId = (payload, endpointUrl, customerId) => {
  let FY = getFinancialYear((payload.loginData || {}).defaultCompany || {});
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let idType = 'customerId';
    if(payload.selectedItem === 'Inbound Delivery'){
      idType = 'supplierId';
    }

    return axios
      // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${endpointURL}?relationshipId=${payload.relationshipId}&pageSize=25&pageNumber=0`)
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${endpointUrl.fetchUrl}?relationshipId=${payload.relationshipId}&${idType}=${customerId || 0}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1 || 0}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: TX_FILE_UPLOAD_ACTION.TRANSACTION_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = TX_FILE_UPLOAD_ACTION.TRANSACTION_LIST;
        }
      })
  }
}

export const getTransactionCountByCustomerId = (payload, endpointUrl, customerId) => {
  let FY = getFinancialYear((payload.loginData || {}).defaultCompany || {});
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let idType = 'customerId';
    if(payload.selectedItem === 'Inbound Delivery'){
      idType = 'supplierId';
    }

    return axios
      // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${endpointURL}?relationshipId=${payload.relationshipId}&pageSize=25&pageNumber=0`)
      // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${endpointUrl.countUrl}?relationshipId=${payload.relationshipId}&pageSize=25&pageNumber=0`)
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${endpointUrl.countUrl}?relationshipId=${payload.relationshipId || 0}&${idType}=${customerId || 0}&pageSize=${payload.pageSize || 25}&pageNumber=${payload.pageNumber - 1 || 0}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)

      .then(res => {
        dispatch({ type: TX_FILE_UPLOAD_ACTION.TRANSACTION_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = TX_FILE_UPLOAD_ACTION.TRANSACTION_COUNT;
        }
      })
  }
}

export const fetchSuppliers = (payload) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/suppliers/getSupplierSummary?relationshipId=${payload.relationshipId}&pageSize=100&pageNumber=0${searchedTextQuery}`)
      .then(res => {
        const data = {
          pageNo: 0,
          list: res.data
        }
        dispatch({ type: TX_FILE_UPLOAD_ACTION.TRANSACTION_SUPPLIER_LIST, data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = TX_FILE_UPLOAD_ACTION.TRANSACTION_SUPPLIER_LIST;
        }
      })
  }
} 