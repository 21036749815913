import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SALES_INVOICE_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const saveInvoiceReminder = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });

    if (props.isBulkInvoice) {
      return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/reminderSalesInvoice/saveInBatch`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (res.data && res.data.length) {
            showToasterMessage({
              description: 'No primery contact available for following invoices - ' + res.data.join(),
              messageType: 'error'
            });
          } else {
            showToasterMessage({
              description: (res || {}).message || 'Save successfully.',
              messageType: 'success'
            });
          }
         // getInvoiceReminderSetupByInvoiceMasterId(payload)(dispatch);
          props.fetchInvoicesReminder && props.fetchInvoicesReminder(props);
          props.popModalFromStack && props.popModalFromStack();
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
          }
        })
    } else {
      return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/reminderSalesInvoice/saveReminderSalesInvoices`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
          getInvoiceReminderSetupByInvoiceMasterId(payload)(dispatch);
          props.fetchInvoicesReminder && props.fetchInvoicesReminder(props);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
          }
        })
    }

  }

}

export const getInvoiceReminderSetupByInvoiceMasterId = (payload, props) => {
  return dispatch => {
    //dispatch({ type: SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_SETUP_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/reminderSalesInvoice/getReminderSalesInvoiceData?invoiceMasterId=${payload.invoiceMasterId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_SETUP, data: res.data });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
        }
      })
  }
}

export const resetInvoiceReminderSetup = () => {
  return dispatch => {
    dispatch({ type: SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_SETUP_RESET });
  }
}
