import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createBranch = (payload, props) => {
    payload.isBranch = 1;
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
      return axios
        .post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/warehouseMaster/createBranch`, payload)
        .then(res => {
          showToasterMessage({ messageType: 'success', description: 'Branch saved successfully' });
          props.updateState({
            branchDrawer: false,
          })
          props.getAllBranches({
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: props.pageNumber || 1,
            pageSize: props.pageSize || 25,
          })
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        })
        .finally(()=>{
            dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER })
        })
    }
  }