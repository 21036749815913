import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Testimonials from "../../../components/eStore/testimonials";
import TestimonialDrawer from "../../drawer/eStore/testimonials";
import { fetchAllTestimonialList,countTestimonialList,deleteTestimonial } from "./action";
import { createTestimonial } from "../../drawer/eStore/testimonials/action";
import { pushModalToStack, showModal } from "../../../actions/commonActions";



class TestimonialCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
      blogCreateDrawerVisible: false,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25
    };
   this.props.fetchAllTestimonialList(payload);
   this.props.countTestimonialList(payload);
  }

  componentWillReceiveProps(props){

  }

  render() {
    return (
        <>
        
        {this.state.testimonialCreateDrawerVisible && <TestimonialDrawer  {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <Testimonials {...this.state} {...this.props}  updateState={(data) => {this.setState(data) }}/>
        </>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    testimonialList:state.eStore.testimonialList,
    testimonialListCount:state.eStore.testimonialListCount,
    listLoading:state.common.listLoading,
    permissions: state.auth.permissions,
    
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllTestimonialList,
  countTestimonialList,deleteTestimonial,createTestimonial,
  pushModalToStack,showModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialCon);
