import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchTaxIdentifications, addTaxIdentification, deleteTaxIdentifications } from '../../../actions/commonActions';
import { createTaxIdentification } from './action';
import {fetchSupplierTaxIdentifications} from "../../supplier/detail/action"
import TaxIdDrawer from '../../../components/drawer/TaxIdentification'
import { fetchSupplierDetailsBySupplierId } from '../../../actions/commonActions';
import { checktaxIdentificationNumber } from './action';
class TaxIdentificationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxIdentificationNumberDublicate:false,
      preValue :props.formData?.taxIdentificationNumber,
      ...props.formData
    }
  }
  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId
    }
    this.props.fetchTaxIdentifications(payload);
  }
  render() {
    return (
      <TaxIdDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }}   />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    allTaxIdentificatinType: state.common.allTaxIdentificatinType,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchTaxIdentifications,
  createTaxIdentification,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  addTaxIdentification, 
  deleteTaxIdentifications,
  fetchSupplierDetailsBySupplierId,
  checktaxIdentificationNumber,fetchSupplierTaxIdentifications
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaxIdentificationContainer);
