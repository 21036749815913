import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMPANY_ACTION_LIST,COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createJobRole = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobRole/createJobRole`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getJobRole(payload);
        getTotalJobRoleCount(payload);
      })
      .catch((err = {}) => {
          showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
      })
  }
}

export const getJobRole = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });

      return axios.get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobRole/getJobRolesByPage?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}`)
            
      .then(response => {
        const data = {
          pageNo:payload.pageNumber,
          list: response.data
        }
          dispatch({ type: COMPANY_ACTION_LIST.JOB_ROLE_LIST, data });
        })
        .catch(err => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    }
}

export const getTotalJobRoleCount = (payload) => {
    return dispatch => {
      return axios
        .get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobRole/getJobRoleCount?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: COMPANY_ACTION_LIST.JOB_ROLE_LIST_COUNT, data: res.data });
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        })
    }
  }
  export const deleteJobRole = (payload, props) => {
    return dispatch => {
      return axios
        .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobRole/delete`, payload)
        .then(res => {
          showToasterMessage({
            description: 'Deleted successfully.',
            messageType: 'success'
          })
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: props.pageSize || 0,
            pageSize: props.pageNumber || 25,
          }
          props.getJobRole && props.getJobRole(payload);
          props.getTotalJobRoleCount && props.getTotalJobRoleCount(payload);

        })
        .catch((err = {}) => {
            showToasterMessage({
                description: lang[((err.response || {}).data || {})] || 'Some error occurred'
            })
        })
    }
  }
  export const resetPaginatedData = () => {
    return dispatch => {
      dispatch({ type: COMPANY_ACTION_LIST.JOB_ROLE_LIST_RESET });
    }
  }
  export const setJobRoleStatus = (payload,props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.patch(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobRole/setStatus?relationshipId=${payload.relationshipId}&jobRoleId=${payload.jobRoleId}&status=${(payload.status)}`,)
        .then(response => {
          showToasterMessage({ messageType: 'success', description: `Status updated` });
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25,
          }
          props.getJobRole(payload);
        })
        .catch(err => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        })
    }
  }