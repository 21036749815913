import React, { Fragment,useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Skeleton } from "antd";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import ReactHtmlParser from "react-html-parser";
import config from "../../../../../config/environmentConfig";
import { CONSTANTS } from "../../../../../static/constants";
import {
  base64Toblob,
  getAWSFileURL,
  fixedDecimalNumber,
  getMomentDateForUIReadOnly,
  numberToWordsWithDecimals,
  capitalizeFirstLetter,
  fixedDecimalQty,
} from "../../../../../utils";
import { ImageCropper } from "../../../../../components/general/ImageCropper";
import { CustomUploader } from "../../../../../components/general/CustomUploader";
// import { LogoComponent } from '../../../../general/LogoComponent';
import { AWSFileTable } from "../../../../general/AWSFileTable";

const Template2 = (props) => {
  const { sqDetail, companyInfo } = props;
  const { customerSalesQuotationDetailsList } = sqDetail;
  const relationshipAddress =
    find(sqDetail.boLocationSQList, {
      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
    }) || {};
  const billingAddress =
    find(sqDetail.boLocationSQList, {
      locationType: CONSTANTS.BILLING_ADDRESS,
    }) || {};
  const shippingAddress =
    find(sqDetail.boLocationSQList, {
      locationType: CONSTANTS.SHIPPING_ADDRESS,
    }) || {};
  let isPlaceOfSupplySameAsRelationship = false;
  isPlaceOfSupplySameAsRelationship =
    (sqDetail.placeOfSupply &&
      relationshipAddress.stateName === sqDetail.placeOfSupply) ||
    false;
  let itemLevelTaxes = [];
  if (customerSalesQuotationDetailsList) {
    itemLevelTaxes = calculateTaxRateList(
      customerSalesQuotationDetailsList,
      isPlaceOfSupplySameAsRelationship
    );
  }
  let isColumnEmpty = {
    product: true,
    service: true,
    variant: true,
    description: true,
    qty: true,
    uomName: true,
    rate: true,
    hsn: true,
    discount: true,
    tax: true,
    specialDiscount: true,
    documentDetails: true,
  };
  if (sqDetail) {
    isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, sqDetail, props);
  }

  const customProps = {
    type: CONSTANTS.TYPE_IMAGE,
    imgUrl:
      getAWSFileURL(
        (sqDetail || {}).stampAwsKey,
        config.BUCKET_NAME.BO_SALES_QUOTE
      ) || "",
    isSignature: true,
    height: 60,
    width: 60,
    name: "file",
    action: null,
    buttonName: "Upload Customer PO",
    onChange: (file) => {
      if (file[0]?.originFileObj) {
        var reader = new FileReader();

        reader.onload = function (e) {
          props.showModal({
            title: (
              <Fragment>
                <div className="ant-modal-title">Crop Image</div>
              </Fragment>
            ),
            //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
            modalBody: (
              <ImageCropper
                onClick={(e) => { }}
                handleSubmit={(newUrl) => {
                  props.uploadStamp({
                    customerId: sqDetail.customerId,
                    salesQuotationMasterId: sqDetail.salesQuotationMasterId,
                    rid: sqDetail.relationshipId,
                    objectKey: sqDetail.stampAwsKey,
                    bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                    file: base64Toblob(newUrl),
                  });
                }}
                {...props}
                url={e.target.result}
              />
            ),
            width: 800,
            hideFooter: true,
            wrapClassName: "Upload-profile-modal",
          });
        };

        reader.readAsDataURL(file[0]?.originFileObj); // convert to base64 string
      }
    },
  };
  const [showComp, setShowComp] = useState(false);
  useEffect(()=>{
    const delay = setTimeout(()=>{
      setShowComp(true);
    }, 400);
    return ()=> clearTimeout(delay);
  }, []);
  return (
    <div className={"custom-modal show"}>
      <i className="close-icon">
        <svg
          width="17"
          height="17"
          onClick={() => {
            props.popModalFromStack();
          }}
        >
          <title>
            <FormattedMessage id="button.close.label" defaultMessage="" />
          </title>
          <path
            fill="#67757d"
            fillRule="evenodd"
            d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"
          />
        </svg>
      </i>
      <div className="modal-dialog txn-modal-dialog">
        <div className="modal-content full-width  vertical-view-data">
      
         
         {showComp ? 
          <><div className="modal-header"></div><div id="sq-readonly" className="inner-content">
              <div className="brand-logo">
              </div>
              <div className="doc-title">
                {sqDetail.documentName || "Sales Quote"}
              </div>

              <Row>
                <Col span={16}>
                  <div className="read-only-content pt20">
                    <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>

                      <div className="title lh-adjust underline-text pb5">
                        <FormattedMessage
                          id="modal.txDetails.common.billTo"
                          defaultMessage="" />
                      </div>
                      <div>
                                
                                <div className='title'> {sqDetail.customerName}</div>
                                                                     
                                 <div>
                                     {getAddressInfoFragment(billingAddress, (sqDetail.additionalInfoList ? sqDetail.additionalInfoList[0] || {} : {}))}
                                     {getTaxIdentificationsFragment(((sqDetail.additionalInfoList || [])[0] || {}).taxIdentifications)}
                                 </div>
                                 <div>
                                 {(sqDetail.additionalInfoList ? sqDetail.additionalInfoList[0] || {} : {})?.customerEmail}
                                     {(sqDetail.additionalInfoList ? sqDetail.additionalInfoList[0] || {} : {})?.customerPhone}
                                 </div>
                                 </div>
                                
                                 <div className="">
                                     {getContactNameFragment((sqDetail.additionalInfoList ? sqDetail.additionalInfoList[0] || {} : {}))}
                                    
                                 </div>
                    </Skeleton>
                  </div>
                  {shippingAddress.streetAddress1 ? (
                    <div className="read-only-content pt10">
                      <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>

                        <div className="title lh-adjust underline-text pb5">
                          <FormattedMessage
                            id="modal.txDetails.common.shipTo"
                            defaultMessage="" />
                        </div>
                        <div className="title">{sqDetail.customerName}</div>
                        <div>{getAddressInfoFragment(shippingAddress, {})}</div>
                      </Skeleton>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={4}>
                  <div className="read-only-content pt20">
                    <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                      <div className="pb5">
                        <div className="title">
                          Quote #
                        </div>

                        <div>{sqDetail.quotationNumber}</div>
                      </div>

                      {sqDetail.rfqNumber && (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.salesQuote.rfqNumber"
                              defaultMessage="" />
                          </div>

                          <div>{sqDetail.rfqNumber}</div>
                        </div>
                      )}

                      <div className="pb5">
                        <div className="title">
                          <FormattedMessage
                            id="modal.txDetails.salesQuote.quoteDate"
                            defaultMessage="" />
                        </div>

                        <div>
                          {sqDetail.salesQuoteDate &&
                            getMomentDateForUIReadOnly({
                              date: sqDetail.salesQuoteDate,
                              format: CONSTANTS.DISPLAY_DATE_FORMAT,
                            })}
                        </div>
                      </div>

                      {sqDetail.salesQuoteExpiryDate && (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="expirationDate.text"
                              defaultMessage="" />
                          </div>

                          <div>
                            {sqDetail.salesQuoteExpiryDate &&
                              getMomentDateForUIReadOnly({
                                date: sqDetail.salesQuoteExpiryDate,
                                format: CONSTANTS.DISPLAY_DATE_FORMAT,
                              })}
                          </div>
                        </div>
                      )}

                      {!!sqDetail.isMultiCurrency && (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.common.foreignCurrency"
                              defaultMessage="" />
                          </div>

                          <div>{sqDetail.foreignCurrency}</div>
                        </div>
                      )}

                      {!!sqDetail.isMultiCurrency && (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.common.exchangeRate"
                              defaultMessage="" />
                          </div>

                          <div>{sqDetail.exchangeRate}</div>
                        </div>
                      )}
                      {sqDetail.projectMasterId ? (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.common.project"
                              defaultMessage="" />
                          </div>
                          <div>
                            {/* //{sqDetail.projectMasterId}- */}
                            <abbr>{sqDetail.projectName}</abbr>
                          </div>
                        </div>
                      ) : ''}
                      {sqDetail.customerRfqFileName && (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.salesQuote.customerRFQ"
                              defaultMessage="" />
                          </div>
                          <div>
                            <abbr>{sqDetail.customerRfqFileName}</abbr>
                          </div>
                        </div>
                      )}
                      {sqDetail.incotermName && (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="package.packageDetail.incoterm"
                              defaultMessage="" />
                          </div>
                          <div>
                            <abbr>{sqDetail.incotermName}</abbr>
                          </div>
                        </div>
                      )}
                    </Skeleton>
                  </div>
                </Col>
                <Col span={4}>
                  <div className="d-inline-flex w-100">
                    <div className="read-only-content pt20 pl10">
                      <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                        <div className="title lh-adjust pb5">
                          {props.companyInfo.storeName}
                        </div>
                        <div>
                          {getAddressInfoFragment(relationshipAddress, companyInfo)}
                          <div>
                            {getTaxIdentificationsFragment(
                              ((sqDetail.additionalInfoList || [])[0] || {})
                                .relationshipTaxIdentifications
                            )}
                          </div>
                        </div>
                      </Skeleton>
                    </div>
                  </div>
                </Col>
              </Row><Skeleton loading={props.detailLoading} paragraph={{ rows: 7 }} active>

                <div className="modal-table-container vertical-space">
                  <table>
                    <thead>
                      <tr>
                        {sqDetail.deliveryMethod && (
                          <th>
                            <FormattedMessage
                              id="modal.txDetails.salesQuote.deliveryMethod"
                              defaultMessage="" />
                          </th>
                        )}
                        <th>
                          <FormattedMessage
                            id="modal.txDetails.salesQuote.customerRFQNumber"
                            defaultMessage="" />
                        </th>
                        {sqDetail.customerInquiryNumber && (
                          <th>
                            <FormattedMessage
                              id="modal.txDetails.inquiry.customerInquiryNumber"
                              defaultMessage="" />
                          </th>
                        )}
                        <th>
                          <FormattedMessage
                            id="modal.txDetails.common.refNumber"
                            defaultMessage="" />
                        </th>
                        <th>
                          <FormattedMessage
                            id="modal.txDetails.common.priority"
                            defaultMessage="" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {sqDetail.deliveryMethod && (
                          <td>{sqDetail.deliveryMethod || "-"}</td>
                        )}

                        <td>{sqDetail.customerRFQNumber || "-"}</td>
                        {sqDetail.customerInquiryNumber && (
                          <td>{sqDetail.customerInquiryNumber || "-"}</td>
                        )}
                        <td>{sqDetail.referenceNumber || "-"}</td>
                        <td>{sqDetail.orderPriority || "-"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="modal-table-container mb15">
                  <table className="transaction-details-table">
                    <thead>
                      <tr>
                        <th className="cell-size-50">#</th>
                        {(!isColumnEmpty.product || !isColumnEmpty.description) && (
                          <th>
                            {!isColumnEmpty.product && (
                              <span>
                                {" "}
                                <FormattedMessage
                                  id="modal.txDetails.common.product"
                                  defaultMessage="" />
                              </span>
                            )}
                            {!isColumnEmpty.product &&
                              !isColumnEmpty.description && <span> & </span>}
                            {!isColumnEmpty.description && (
                              <span>
                                {" "}
                                <FormattedMessage
                                  id="modal.txDetails.common.description"
                                  defaultMessage="" />
                              </span>
                            )}
                          </th>
                        )}
                        {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                        {!isColumnEmpty.qty && (
                          <th className="text-center">
                            <FormattedMessage
                              id="modal.txDetails.common.qty"
                              defaultMessage="" />
                          </th>
                        )}
                        {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                        {!isColumnEmpty.specialDiscount && (
                          <th className="text-center">
                            <FormattedMessage
                              id="modal.txDetails.common.basePrice"
                              defaultMessage="" />
                          </th>
                        )}
                        {!isColumnEmpty.specialDiscount && (
                          <th className="text-right">
                            <FormattedMessage
                              id="modal.txDetails.common.specialDiscount"
                              defaultMessage="" />
                          </th>
                        )}
                        {!isColumnEmpty.rate && (
                          <th className="text-center">
                            <FormattedMessage
                              id="modal.txDetails.common.rate"
                              defaultMessage="" />
                          </th>
                        )}
                        {!isColumnEmpty.hsn && (
                          <th className="text-center">
                            <FormattedMessage
                              id="modal.txDetails.common.hsn"
                              defaultMessage="" />
                          </th>
                        )}
                        {!isColumnEmpty.discount && (
                          <th className="text-right">
                            <FormattedMessage
                              id="modal.txDetails.common.discount"
                              defaultMessage="" />
                          </th>
                        )}
                        {!isColumnEmpty.tax && (
                          <th className="text-right">
                            <FormattedMessage
                              id="modal.txDetails.common.tax"
                              defaultMessage="" />
                          </th>
                        )}
                        <th className="text-right">
                          Taxable Amount
                        </th>
                        <th className="text-right">
                          <FormattedMessage
                            id="modal.txDetails.common.netAmount"
                            defaultMessage="" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(customerSalesQuotationDetailsList || []).sort((a, b) => Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="cell-size-50">{i + 1}</td>
                            {(!isColumnEmpty.product ||
                              !isColumnEmpty.description) && (
                                <td>
                                  <strong>{getVariantFragment(item)}</strong>
                                  {!isColumnEmpty.description && (
                                    <div className="description-cell">
                                      {/* <pre>{ReactHtmlParser(item.description || '-')}</pre> */}
                                      {getDescriptionFragment(item)}
                                    </div>
                                  )}
                                </td>
                              )}
                            {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                            {!isColumnEmpty.qty && (
                              <td className="text-center">
                                {fixedDecimalQty(item.quantity || 0) +
                                  (item.uomName ? " " + item.uomName : "")}
                              </td>
                            )}
                            {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                            {!isColumnEmpty.specialDiscount && (
                              <td className="text-center">
                                {fixedDecimalNumber(item.basePrice || 0) || "-"}
                              </td>
                            )}
                            {!isColumnEmpty.specialDiscount && (
                              <td className="text-right">
                                {getSpecialDiscount(item, "amountPercent")}
                              </td>
                            )}
                            {!isColumnEmpty.rate && (
                              <td className="text-center">
                                {fixedDecimalNumber(item.anItemSalePrice || 0) ||
                                  "-"}
                              </td>
                            )}
                            {!isColumnEmpty.hsn && (
                              <td className="text-center">{item.hsnCode || "-"}</td>
                            )}
                            {!isColumnEmpty.discount && (
                              <td className="text-right">
                                {getTxTypeData(item, "discount", "amount")}{" "}
                                {getTxTypeData(item, "discount", "amountPercent")}
                              </td>
                            )}
                            {!isColumnEmpty.tax && (
                              <td className="text-right">
                                {getTotalTaxAmount(item, isPlaceOfSupplySameAsRelationship)}{" "}
                                {getTotalTaxPercent(item, "amountPercent", isPlaceOfSupplySameAsRelationship)}
                              </td>
                            )}
                            <td className="text-right">
                              {getNetAmount(item, isPlaceOfSupplySameAsRelationship, true) || "-"}
                            </td>
                            <td className="text-right">
                              {getNetAmount(item, isPlaceOfSupplySameAsRelationship) || "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Col span={24} className="">
                    {sqDetail.foreignCurrency ? (
                      <span>
                        {" "}
                        {sqDetail.foreignCurrencyIcon ? (
                          <i className={sqDetail.foreignCurrencyIcon}></i>
                        ) : (
                          sqDetail.foreignCurrency
                        )}{" "}
                      </span>
                    ) : (
                      <span>
                        {" "}
                        {props.companyInfo.currencyCode ? (
                          props.companyInfo.currencyCode
                        ) : (
                          props.companyInfo.currencyCode + " "
                        )}{" "}
                      </span>
                    )}
                    {(sqDetail.salesQuoteTotalAmount &&
                      numberToWordsWithDecimals(fixedDecimalNumber(sqDetail.salesQuoteTotalAmount))) ||
                      ""}
                  </Col>
                </div>

                <Row>
                  <Col className="read-only-content" span={9}>
                    {sqDetail.docDetailList && sqDetail.docDetailList.length ? (
                      <Row>
                        <Col span={24} className="title">
                          <FormattedMessage
                            id="modal.txDetails.common.attachments"
                            defaultMessage="" />
                        </Col>
                        <Col span={12}>
                          <AWSFileTable
                            {...props}
                            docDetailList={sqDetail.docDetailList}
                            fetchTxDetail={(data) => {
                              props.fetchSQDetail({
                                ...data,
                                customerId: sqDetail.customerId,
                                salesQuotationMasterId: sqDetail.salesQuotationMasterId,
                                relationshipId: sqDetail.relationshipId,
                              });
                            } } />
                          {/* {
    sqDetail.docDetailList.map((doc) => {
        return <span className={"cursor-pointer"} onClick={()=>{
            props.downloadFileFromBucket({
                bucketName: doc.bucketName,
                awsKeyName: doc.awsKeyName,
                fileName: doc.fileName
            })
        }}>{doc.fileName} &nbsp;</span>
    })
} */}
                        </Col>
                      </Row>
                    ) : null}
                    <br />

                    {sqDetail.remarksInternal ? (
                      <div>
                        <Row gutter={[10, 0]}>
                          <Col span={15} className="title">
                            <FormattedMessage
                              id="modal.txDetails.common.internalRemarks"
                              defaultMessage="" />
                          </Col>

                        </Row>
                        <Row>
                          <Col span={15}>
                            {ReactHtmlParser(sqDetail.remarksInternal)}
                          </Col>
                        </Row>

                      </div>

                    ) : ''}
                    <br></br>
                    {sqDetail.remarksCustomer ? (
                      <div>
                        <Row className="pt5">
                          <Col span={15} className="title">
                            <FormattedMessage
                              id="modal.txDetails.common.customerRemarks"
                              defaultMessage="" />
                          </Col>

                        </Row>
                        <Row>
                          <Col span={15}>
                            {ReactHtmlParser(sqDetail.remarksCustomer)}
                          </Col>
                        </Row>
                      </div>

                    ) : ''}
                  </Col>

                  <Col offset={8} span={7}>
                    <div className="bill-summary mt5">
                      <Row>
                        <Col span={14}>
                          <div className="right-txt">
                            <FormattedMessage
                              id="modal.txDetails.common.subTotal"
                              defaultMessage="" />
                          </div>
                        </Col>

                        <Col span={10} className="text-right ">
                          {sqDetail.foreignCurrency ? (
                            <span>
                              {" "}
                              {sqDetail.foreignCurrencyIcon ? (
                                <i className={sqDetail.foreignCurrencyIcon}></i>
                              ) : (
                                sqDetail.foreignCurrency
                              )}{" "}
                            </span>
                          ) : (
                            <span>
                              {" "}
                              {props.companyInfo.currencyIcon ? (
                                <i className={props.companyInfo.currencyIcon}></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}{" "}
                            </span>
                          )}
                          {getSubTotalOrTaxOrDiscountAmount(sqDetail, "SubTotal")}
                        </Col>
                      </Row>
                      {getSubTotalOrTaxOrDiscountAmount(sqDetail, "Discount") && (
                        <div className="pt10">
                          <Row>
                            <Col span={14}>
                              <div className="right-txt">
                                <FormattedMessage
                                  id="modal.txDetails.common.discount"
                                  defaultMessage="" />
                              </div>
                            </Col>

                            <Col span={10} className="text-right ">
                              {sqDetail.foreignCurrency ? (
                                <span>
                                  {" "}
                                  {sqDetail.foreignCurrencyIcon ? (
                                    <i className={sqDetail.foreignCurrencyIcon}></i>
                                  ) : (
                                    sqDetail.foreignCurrency
                                  )}{" "}
                                </span>
                              ) : (
                                <span>
                                  {" "}
                                  {props.companyInfo.currencyIcon ? (
                                    <i
                                      className={props.companyInfo.currencyIcon}
                                    ></i>
                                  ) : (
                                    props.companyInfo.currencyCode + " "
                                  )}{" "}
                                </span>
                              )}{" "}
                              {getSubTotalOrTaxOrDiscountAmount(
                                sqDetail,
                                "Discount"
                              )}
                            </Col>
                          </Row>
                        </div>
                      )}
                      {itemLevelTaxes &&
                        itemLevelTaxes.length !== 0 &&
                        itemLevelTaxes.map((taxObj, i) => {
                          return props.companyInfo.countryName === "India" ? (
                            isPlaceOfSupplySameAsRelationship ? (
                              <div key={i}>
                                <div className="pt10">
                                  <Row>
                                    <Col span={14}>
                                      <div className="right-txt">
                                        CGST @ {taxObj.taxPercent}% on{" "}
                                        {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                      </div>
                                    </Col>
                                    <Col span={10} className="text-right ">
                                      {sqDetail.foreignCurrency ? (
                                        <span>
                                          {" "}
                                          {sqDetail.foreignCurrencyIcon ? (
                                            <i
                                              className={sqDetail.foreignCurrencyIcon}
                                            ></i>
                                          ) : (
                                            sqDetail.foreignCurrency
                                          )}{" "}
                                        </span>
                                      ) : (
                                        <span>
                                          {" "}
                                          {props.companyInfo.currencyIcon ? (
                                            <i
                                              className={props.companyInfo.currencyIcon}
                                            ></i>
                                          ) : (
                                            props.companyInfo.currencyCode + " "
                                          )}{" "}
                                        </span>
                                      )}
                                      {fixedDecimalNumber(taxObj.taxAmount)}
                                    </Col>
                                  </Row>
                                </div>

                                <div className="pt10">
                                  <Row>
                                    <Col span={14}>
                                      <div className="right-txt">
                                        SGST @ {taxObj.taxPercent}% on{" "}
                                        {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                      </div>
                                    </Col>
                                    <Col span={10} className="text-right ">
                                      {sqDetail.foreignCurrency ? (
                                        <span>
                                          {" "}
                                          {sqDetail.foreignCurrencyIcon ? (
                                            <i
                                              className={sqDetail.foreignCurrencyIcon}
                                            ></i>
                                          ) : (
                                            sqDetail.foreignCurrency
                                          )}{" "}
                                        </span>
                                      ) : (
                                        <span>
                                          {" "}
                                          {props.companyInfo.currencyIcon ? (
                                            <i
                                              className={props.companyInfo.currencyIcon}
                                            ></i>
                                          ) : (
                                            props.companyInfo.currencyCode + " "
                                          )}{" "}
                                        </span>
                                      )}
                                      {fixedDecimalNumber(taxObj.taxAmount)}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ) : (
                              <div key={i}>
                                <div className="pt10">
                                  <Row>
                                    <Col span={14}>
                                      <div className="right-txt">
                                        {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                                        {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                      </div>
                                    </Col>
                                    <Col span={10} className="text-right ">
                                      {sqDetail.foreignCurrency ? (
                                        <span>
                                          {" "}
                                          {sqDetail.foreignCurrencyIcon ? (
                                            <i
                                              className={sqDetail.foreignCurrencyIcon}
                                            ></i>
                                          ) : (
                                            sqDetail.foreignCurrency
                                          )}{" "}
                                        </span>
                                      ) : (
                                        <span>
                                          {" "}
                                          {props.companyInfo.currencyIcon ? (
                                            <i
                                              className={props.companyInfo.currencyIcon}
                                            ></i>
                                          ) : (
                                            props.companyInfo.currencyCode + " "
                                          )}{" "}
                                        </span>
                                      )}
                                      {fixedDecimalNumber(taxObj.taxAmount)}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            )
                          ) : (
                            <div key={i}>
                              <div className="pt10">
                                <Row>
                                  <Col span={14}>
                                    <div className="right-txt">
                                      {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                                      {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                    </div>
                                  </Col>
                                  <Col span={10} className="text-right ">
                                    {sqDetail.foreignCurrency ? (
                                      <span>
                                        {" "}
                                        {sqDetail.foreignCurrencyIcon ? (
                                          <i
                                            className={sqDetail.foreignCurrencyIcon}
                                          ></i>
                                        ) : (
                                          sqDetail.foreignCurrency
                                        )}{" "}
                                      </span>
                                    ) : (
                                      <span>
                                        {" "}
                                        {props.companyInfo.currencyIcon ? (
                                          <i
                                            className={props.companyInfo.currencyIcon}
                                          ></i>
                                        ) : (
                                          props.companyInfo.currencyCode + " "
                                        )}{" "}
                                      </span>
                                    )}
                                    {fixedDecimalNumber(taxObj.taxAmount)}
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          );
                        })}

                      <div className="pt10">
                        <Row>
                          <Col span={14}>
                            <div className="right-txt bold">
                              <FormattedMessage
                                id="modal.txDetails.common.grandTotal"
                                defaultMessage="" />
                            </div>
                          </Col>

                          <Col span={10} className="text-right">
                            {sqDetail.foreignCurrency ? (
                              <span>
                                {" "}
                                {sqDetail.foreignCurrencyIcon ? (
                                  <i className={sqDetail.foreignCurrencyIcon}></i>
                                ) : (
                                  sqDetail.foreignCurrency
                                )}{" "}
                              </span>
                            ) : (
                              <span>
                                {" "}
                                {props.companyInfo.currencyIcon ? (
                                  <i className={props.companyInfo.currencyIcon}></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}{" "}
                              </span>
                            )}
                            {(sqDetail.salesQuoteTotalAmount &&
                              fixedDecimalNumber(sqDetail.salesQuoteTotalAmount)) ||
                              ""}
                          </Col>

                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col span={6} offset={18}>
                    <div
                      className="upload-link new-pic"
                      title={props.intl.formatMessage({
                        id: "uploadSignature.tooltip",
                      })}
                      style={{ width: "50px", height: "50px" }}
                    >
                      <CustomUploader {...customProps} />
                      {sqDetail.stampAwsKey && (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            props.deleteStamp(
                              {
                                customerId: sqDetail.customerId,
                                salesQuotationMasterId: sqDetail.salesQuotationMasterId,
                                relationshipId: sqDetail.relationshipId,
                                objectKey: sqDetail.stampAwsKey,
                              },
                              props
                            );
                          } }
                        >
                          {" "}
                          <FormattedMessage
                            id="modal.txDetails.common.deleteStamp"
                            defaultMessage="" />{" "}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="contact-details">
                  {sqDetail.footer && (
                    <Fragment>{ReactHtmlParser(sqDetail.footer)}</Fragment>
                  )}
                </div>
              </Skeleton>
            </div></>
         :""}
        </div>
      </div>
    </div>
  );
};


const getSpecialDiscount = (row, toReturn) => {
  if (!row) return;
  let specialDiscount = row.basePrice - row.anItemSalePrice;
  if (specialDiscount && row.basePrice) {
    if (toReturn === "amountPercent") {
      let percent = fixedDecimalNumber((specialDiscount * 100) / row.basePrice);
      let amount = fixedDecimalNumber(specialDiscount);
      return amount + " (" + percent + ")%";
    } else if (toReturn === "amount") {
      return fixedDecimalNumber(specialDiscount);
    }
  } else if (!row && toReturn === "amount") {
    return 0;
  } else {
    return "-";
  }
};

const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
  let total = 0;
  data &&
    data.customerSalesQuotationDetailsList &&
    data.customerSalesQuotationDetailsList.forEach(function (d) {
      if (type === "SubTotal") {
        total = total + d.anItemSalePrice * d.quantity;
      }
      if (type === "Discount") {
        let discountObject = d.customerSalesQuotationCOATxList.find(
          (tx) => tx.txType === "discount"
        );
        if (discountObject) {
          total = total + parseFloat(discountObject.amount);
        }
      }
    });
  if (type === "Discount" && data && data.customerSalesQuotationCOATxList) {
    let discount = data.customerSalesQuotationCOATxList.find(
      (tx) => tx.txType === "discount" && tx.accountingEntry === "credit"
    );
    if (discount) {
      total = total + parseFloat(discount.amount);
    }
  }
  return total ?fixedDecimalNumber(total):null ;
};

const getTxTypeData = (row, type, toReturn) => {
  if (!row) return;
  let data = row.customerSalesQuotationCOATxList.find(
    (tx) => tx.txType === type
  );
  if (data) {
    if (data[toReturn] && toReturn === "amountPercent") {
      if (type === "VAT") {
        return "VAT(" + data[toReturn] + "%)";
      }
      return "(" + data[toReturn] + "%)";
    } else if (data[toReturn] && toReturn === "amount") {
      return fixedDecimalNumber(data[toReturn]);
    } else {
      return "";
    }
  } else if (!data && toReturn === "amount") {
    return "";
  } else {
    return "-";
  }
};
const getTotalTaxAmount = (row, isPlaceOfSupplySameAsRelationship) => {
  let tax = getTxTypeData(row, "tax", "amount", true) || 0;
  let totalTax = Number(tax);
  if (isPlaceOfSupplySameAsRelationship) {
    return fixedDecimalNumber(totalTax * 2 || 0);
  } else {
    return fixedDecimalNumber(totalTax || 0);
  }
};

const getTotalTaxPercent = (row, toReturn, isPlaceOfSupplySameAsRelationship) => {
  let tax =
    row.customerSalesQuotationCOATxList.find((tx) => tx.txType === "tax") || {};
  let totalPercent =fixedDecimalNumber(Number(tax.amountPercent || 0));
  if (toReturn === "amountPercent") {
    if (isPlaceOfSupplySameAsRelationship) {

      return totalPercent ? "(" + totalPercent * 2 + "%)" : "";
    } else {
      return totalPercent ? "(" + totalPercent + "%)" : "";
    }
  } else {
    return totalPercent;
  }
};

const getNetAmount = (row, isPlaceOfSupplySameAsRelationship, withoutTax) => {
  let tax = getTotalTaxAmount(row) || 0;
  let taxValue = isPlaceOfSupplySameAsRelationship ? tax * 2 : tax
  let discount = getTxTypeData(row, "discount", "amount", true) || 0;
  let netAmountWithoutTax = Number(row.quantity * row.anItemSalePrice) - Number(discount);
  let netAmountItem = Number(row.quantity * row.anItemSalePrice) + Number(taxValue) - Number(discount);
  return withoutTax ? fixedDecimalNumber(netAmountWithoutTax) : fixedDecimalNumber(netAmountItem);
};

const calculateTaxRateList = (
  customerSalesQuotationDetailsList,
  isPlaceOfSupplySameAsRelationship
) => {
  let subtotal = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let itemLevelTaxes = [];

  customerSalesQuotationDetailsList.forEach(function (rowdata, index) {
    let totalAmountOfColumn = 0;
    if (rowdata.quantity && rowdata.anItemSalePrice) {
      let taxObj = {
        subtotal: 0,
        taxPercent: 0,
        taxAmount: 0,
      };
      totalAmountOfColumn = rowdata.quantity * rowdata.anItemSalePrice;
      let discount = getTxTypeData(rowdata, "discount", "amount");
      if (discount) {
        totalAmountOfColumn = totalAmountOfColumn - discount;
        totalDiscount = totalDiscount + discount;
      }

      let tax1 =
        rowdata.customerSalesQuotationCOATxList.find(
          (tx) => tx.txType === "tax"
        ) || {};
      taxObj.taxPercent = Number(tax1.amountPercent);
      taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
      taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount));
      totalTax = totalTax + taxObj.taxAmount;
      taxObj.taxName = tax1.taxName;
      // let tax2 = rowdata.customerSalesQuotationCOATxList.find(tx => (tx.txType === 'sgst'));
      // let tax3 = rowdata.customerSalesQuotationCOATxList.find(tx => (tx.txType === 'igst'));
      // let tax4 = rowdata.customerSalesQuotationCOATxList.find(tx => (tx.txType === 'vat'));
      // if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
      //     taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
      //     taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
      //     totalTax = totalTax + taxObj.taxAmount;
      // }
      // else if (!isPlaceOfSupplySameAsRelationship && tax3) {
      //     taxObj.taxPercent = Number(tax3.amountPercent);
      //     taxObj.taxAmount = Number(tax3.amount);
      //     totalTax = totalTax + taxObj.taxAmount;
      // } else if (tax4) {
      //     taxObj.taxPercent = Number(tax4.amountPercent);
      //     taxObj.taxAmount = Number(tax4.amount);
      //     totalTax = totalTax + taxObj.taxAmount;
      // }
      if (taxObj.taxPercent) {
        taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
        const availableTaxObj = find(itemLevelTaxes, {
          taxPercent: taxObj.taxPercent,
        });
        if (availableTaxObj) {
          itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
            if (ele.taxPercent === taxObj.taxPercent) {
              ele.subtotal = ele.subtotal + taxObj.subtotal;
              ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
            }
            return true;
          });
        } else {
          taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
          itemLevelTaxes.push(taxObj);
        }
      }
      subtotal = subtotal + totalAmountOfColumn;
    }
  });
  return itemLevelTaxes;
};

const updateColumnEmptyCheck = (isColumnEmpty, sqDetail, props) => {
  if (!sqDetail) return isColumnEmpty;
  for (let i in sqDetail.customerSalesQuotationDetailsList) {
    let row = sqDetail.customerSalesQuotationDetailsList[i];
    if (row.itemName) {
      isColumnEmpty.product = false;
    }
    if (row.serviceName) {
      isColumnEmpty.service = false;
    }
    if (row.itemVariantName) {
      isColumnEmpty.variant = false;
    }
    if (row.description) {
      isColumnEmpty.description = false;
    }
    if (row.quantity) {
      isColumnEmpty.qty = false;
    }
    if (row.uomName) {
      isColumnEmpty.uomName = false;
    }
    if (row.anItemSalePrice) {
      isColumnEmpty.rate = false;
    }
    if (row.specialDiscount) {
      isColumnEmpty.specialDiscount = false;
    }
    if (row.hsnCode && props.companyInfo.countryName === "India") {
      isColumnEmpty.hsn = false;
    }
    if (!isTxTypeEmpty(row, "discount")) {
      isColumnEmpty.discount = false;
    }
    if (!isTxTypeEmpty(row, "tax")) {
      isColumnEmpty.tax = false;
    }
    //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
    //if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
    //if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
    if (row.documentDetails && row.documentDetails.length) {
      isColumnEmpty.documentDetails = false;
    }
  }
  return isColumnEmpty;
};

const isTxTypeEmpty = (row, type) => {
  if (!row) return true;
  let data = row.customerSalesQuotationCOATxList.find(
    (tx) => tx.txType === type
  );
  if (data && data.amount) {
    return false;
  } else {
    return true;
  }
};

const getAddressInfoFragment = (obj, additionInfo) => {
  const fragmentObj = (
    <Fragment>
 
 <div className="billing-address first-caps">
      {obj.streetAddress1 || obj.streetAddress2 ? (
        
          <div>
            {obj.streetAddress1} {obj.streetAddress2}{", "}
          </div>
        
      ) : ""}
      {obj.cityName || obj.stateName || obj.zipCode ? (
        
          <div>
            {obj.cityName} {capitalizeFirstLetter(obj.stateName)}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
          </div>
        
      ) : ""}
       {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
    </div>
      {additionInfo.workPhone ? (
        <div className="billing-address">
          <FormattedMessage id="phone" defaultMessage="" /> -{" "}
          {additionInfo.workPhone}
        </div>
      ) : (
        ""
      )}
      {additionInfo.email ? (
        <div className="billing-address">
          <FormattedMessage id="email" defaultMessage="" /> -{" "}
          {additionInfo.email}
        </div>
      ) : (
        ""
      )}
      {/* {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        } */}
    </Fragment>
  );

  return fragmentObj;
};



const getTaxIdentificationsFragment = (taxIdentifications) => {
  if (!taxIdentifications) return <Fragment />;
  let taxIdentificationsList = [];
  if (taxIdentifications) {
    taxIdentificationsList = JSON.parse(taxIdentifications);
  }
  const fragmentObj = (
    <Fragment>
      {taxIdentificationsList.map((obj, i) => {
        return (
          <div className="billing-address">
            {obj.taxIdentificationType || ""} - {obj.taxIdentificationNumber}
          </div>
        );
      })}
    </Fragment>
  );

  return fragmentObj;
};

const getContactNameFragment = (additionInfo) => {
  if (!additionInfo) return <Fragment />;
  let contactName = "";
  if (additionInfo.salutationName)
    contactName += additionInfo.salutationName + " ";
  if (additionInfo.firstName) contactName += additionInfo.firstName;
  if (additionInfo.middleName) contactName += " " + additionInfo.middleName;
  if (additionInfo.lastName) contactName += " " + additionInfo.lastName;
  const fragmentObj = (
    <Fragment>
      {contactName && contactName.length ? (
        <div className="title">{contactName}</div>
      ) : (
        ""
      )}
        <div>{additionInfo?.EmailAddress||''}</div>
      <div>{additionInfo?.workPhone||additionInfo?.cellPhone||''}</div>
    </Fragment>
  );
  return fragmentObj;
};

const getVariantFragment = (item) => {
  let itemName = null;
  if (item.itemName) {
    itemName = item.itemName;
  }
  if (item.itemVariantName) {
    itemName = itemName + " (" + item.itemVariantName + ")";
  }
  return itemName ? itemName : "";
};

const getDescriptionFragment = (item) => {
  if (!item) return <Fragment />;
  const fragmentObj = (
    <Fragment>
      {item.materialNumber ? (
        <div>
          <span className="title"> Material # : </span>
          {item.materialNumber}
          <br></br>
          <br></br>
        </div>
      ) : (
        ""
      )}
      {item.stockNumber ? (
        <div>
          <span className="title"> Stock # : </span>
          {item.stockNumber}
          <br></br>
          <br></br>
        </div>
      ) : (
        ""
      )}
      {item.partNumber ? (
        <div>
          <span className="title"> Part # : </span>
          {item.partNumber}
          <br></br>
          <br></br>
        </div>
      ) : (
        ""
      )}
      {item.description ? (
        <div>{ReactHtmlParser(item.description || "")}</div>
      ) : (
        ""
      )}
      {item.origin ? (
        <div>
          <br></br>
          <span className="title"> Origin : </span>
          {item.origin}
        </div>
      ) : (
        ""
      )}
      {item.hsCode ? (
        <div>
          <span className="title"> HS Code : </span>
          {item.hsCode}
          <br></br>
          <br></br>
        </div>
      ) : (
        ""
      )}
      {item.warehouse ? (
        <div>
          <span className="title"> Warehouse Name : </span>{" "}
          <span style={{ display: "inline-block" }}>
            {ReactHtmlParser(item.warehouse || "")}{" "}
          </span>
          <br></br>
          <br></br>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
  return fragmentObj;
};

export default Template2;
