import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SHIPMENT_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';

const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const getShiprocketIntegratedChannel = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER, data: true });
    
    const headers = {
      "relationshipId": payload.relationshipId,
      "Content-Type": "application/json"
    };

    return axios.get(`${config.LOGISTICS_BASE_URL}/${config.rootContextLogistics}${config.LOGISTICS_API_VERSION}/channels/getChannels`, {headers})
      .then(response => {
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPROCKET_CHANNELS, data: response.data || [] });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER, data: false });
      })
  }
}

export const getShiprocketAccountAddress = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER, data: true });
    
    const headers = {
      "relationshipId": payload.relationshipId,
      "Content-Type": "application/json"
    };

    return axios.get(`${config.LOGISTICS_BASE_URL}/${config.rootContextLogistics}${config.LOGISTICS_API_VERSION}/settings/getAccountAddress`, {headers})
      .then(response => {
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPROCKET_ADDRESS, data: response.data['shipping_address'] || [] });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER, data: false });
      })
  }
}

export const createShiprocketShipmentOrder = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId,
      "Content-Type": "application/json"
    };

    return axios.post(`${config.LOGISTICS_BASE_URL}/${config.rootContextLogistics}${config.LOGISTICS_API_VERSION}/orders/createCustomOrder`, payload, {headers})
      .then(res => {
        let data = res.data;
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_LOGISTIC_INFO, data });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const getCarrierPricesShiprocket = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER, data: true });
    
    const headers = {
      "relationshipId": payload.relationshipId,
      "Content-Type": "application/json"
    };

    return axios.post(`${config.LOGISTICS_BASE_URL}/${config.rootContextLogistics}${config.LOGISTICS_API_VERSION}/couriers/getRates`, payload, {headers})
      .then(response => {
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPROCKET_CARRIER_DETAILS, data: response.data || {} });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER, data: false });
      })
  }
}

export const generateAwbForShipment = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId || "",
      "Content-Type": "application/json"
    };

    return axios.post(`${config.LOGISTICS_BASE_URL}/${config.rootContextLogistics}${config.LOGISTICS_API_VERSION}/couriers/generateAwbForShipment`, payload, {headers})
      .then(res => {
        props.updateState({currentStep: props.currentStep + 1, stepError: undefined});
        let logisticsInfo = res?.data?.logisticsInfo || {};
        let awbAssignedDetails = res?.data?.awbAssignedDetails || {};
        if(logisticsInfo){
          dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_LOGISTIC_INFO, data: logisticsInfo });
        }
        if(awbAssignedDetails){
          dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_AWB_ASSIGN_DETAIL, data: awbAssignedDetails });
          if(awbAssignedDetails.awb_assign_status === 1){
            props.updateState({currentStep: props.currentStep + 1, stepError: undefined});
          }
        }
      })
      .catch(err => {
        props.updateState({stepError: err.message})
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const scheduleCourierPickup = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId,
      "Content-Type": "application/json"
    };

    return axios.post(`${config.LOGISTICS_BASE_URL}/${config.rootContextLogistics}${config.LOGISTICS_API_VERSION}/couriers/schedulePickupDate`, payload, {headers})
      .then(res => {
        props.updateState({ShippingPickupInfo: res.data, currentStep: props.currentStep + 1, stepError: undefined});

        let logisticsInfo = res?.data?.logisticsInfo || {};
        let shipmentPickupDetail = res?.data?.shipmentPickupDetail || {};
        if(logisticsInfo){
          dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_LOGISTIC_INFO, data: logisticsInfo });
        }
        if(shipmentPickupDetail){
          dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_PICKUP_DETAIL, data: shipmentPickupDetail });
        }
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const generateManifest = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId,
      "Content-Type": "application/json"
    };

    return axios.post(`${config.LOGISTICS_BASE_URL}/${config.rootContextLogistics}${config.LOGISTICS_API_VERSION}/shipment/generateManifest`, payload, {headers})
      .then(res => {
        props.updateState({ stepError: undefined})
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_LOGISTIC_INFO, data: res.data });
      })
      .catch(err => {
        props.updateState({stepError: err.message})
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const generateShippingLabel = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId,
      "Content-Type": "application/json"
    };

    return axios.post(`${config.LOGISTICS_BASE_URL}/${config.rootContextLogistics}${config.LOGISTICS_API_VERSION}/shipment/generateLabel`, payload, {headers})
      .then(res => {
        props.updateState({stepError: undefined})
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_LOGISTIC_INFO, data: res.data });
      })
      .catch(err => {
        props.updateState({stepError: err.message})
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const getTrackingByOrderId = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER, data: true });
    
    const headers = {
      "relationshipId": payload.relationshipId,
      "Content-Type": "application/json"
    };

    return axios.get(`${config.LOGISTICS_BASE_URL}/${config.rootContextLogistics}${config.LOGISTICS_API_VERSION}/shipment/getTrackingByOrderId`, {params : {orderId: payload.orderId}, headers: headers})
      .then(res => {
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_TRACKING_DETAILS, data: res.data?.tracking_data || {} });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER, data: false });
      })
  }
}