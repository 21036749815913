import React from "react";
import { Drawer, Row, Col, DatePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getMomentDateForUI , getCurrentDateForBackend, showToasterMessage } from '../../../../utils';
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { CONSTANTS } from '../../../../static/constants';
import { FilterOutlined, UndoOutlined,} from '@ant-design/icons';



const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;




const RFQFiltersComp = (props) => {
    const { updateState, companyInfo } = props;

    const closeDrawer = () =>{
        props.updateState({
            rfqFilterDrawerVisible: false
        })
    }

    const applyFilter = () => {
        // if(!props.selectedRfqNum && !props.startDate && !props.endDate && !props.bidStartDate && !props.bidEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedInvStatusName && !props.selectedPriority && !props.selectedSalesPersonId && !props.selectedRefNum && !props.selectedProjectMasterId ){
        if(!props.selectedRfqNum && !props.startDate && !props.endDate && !props.bidStartDate && !props.bidEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedSalesPersonId && !props.selectedRefNum && !props.selectedProjectMasterId ){

            return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
        }
        const payload = {
            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
            filters: {
                rfqNumber:props.selectedRfqNum || null,
                startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                bidStartDate: props.bidStartDate ? getCurrentDateForBackend(props.bidStartDate) : null,
                bidEndDate: props.bidEndDate ? getCurrentDateForBackend(props.bidEndDate) : null,
                status: props.selectedUserStatusName || null,
                //status: props.selectedStatus || null,
                // invoiceStatus: props.selectedInvStatusName || null,
                // invoiceStatusId : props.selectedInvStatusId || null,
                priority: props.selectedPriority || null,
                salesPersonId: props.selectedSalesPersonId || 0,
                salesPersonName: props.selectedSalesPersonName || null,
                reference: props.selectedRefNum || null,
                projectMasterId: props.selectedProjectMasterId || 0,
            }
        };
        props.fetchAllRFQByFilter(payload);
        props.getRFQCountByFilter(payload);
        closeDrawer();
    }

    const clearFilter = () =>{
        props.updateState({
            selectedRfqNum: null,
            startDate:null, endDate:null,
            bidStartDate:null, bidEndDate:null,
            selectedUserStatus: null, selectedUserStatusName: null,
            selectedStatus: null, selectedStatusName: null,
            selectedPriority: null,
            selectedRefNum: null,
            // selectedInvStatusName: null, selectedInvStatusId: null,
            selectedSalesPersonName: null, selectedSalesPersonId: null,
            selectedProjectMasterId: null, selectedProjectName: null
        });
        const payload = {
            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
            filters: {}
        };
        props.fetchAllRFQ(payload);
        props.getRFQCount(payload);
        closeDrawer();
    }

    const rowGutter = [24, 16];

    return (
        <>
            <Drawer
                title={
                    // <div className="drawer-head-txt">
                    //     <FormattedMessage id='supplier.rfq.filter.drawer.title' />
                    // </div>
                    "Filters"
                }
                width={720}
                zIndex={1}
                maskClosable={false}
                destroyOnClose={true}
                onClose={closeDrawer}
                className="custom-drawer"
                visible={props.rfqFilterDrawerVisible}
                footer={<>
                    <CustomButton
                        intlId='confirmation.cancel'
                        defaultMessage=''
                        type='default'
                        key={0}
                        onClick={closeDrawer}
                      />
                      <div>
                        <CustomButton
                            intlId='common.clearFilter.txt'
                            defaultMessage=''
                            type='default'
                            key={0}
                            onClick={clearFilter}
                            btnIcon={<><UndoOutlined /> &nbsp;</>}
                            style={{marginRight: '10px'}}
                        />
                        <CustomButton
                            intlId='common.applyFilter.txt'
                            defaultMessage=''
                            htmlType='submit'
                            onClick={applyFilter}
                            key={1}
                            btnIcon = {<><FilterOutlined /> &nbsp;</>}
                        />
                      </div>
                      </>}
            >

                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <div className="i-label">RFQ #</div>
                        <TextBox 
                            placeholder="RFQ #"
                            type='text'
                            maxLength={20}
                            countStyle={{top:"40px"}}
                            value={props.selectedRfqNum}
                            onChange={(e) => {
                                props.updateState({ selectedRfqNum: e.target.value });
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <div className="i-label">RFQ Date</div> 
                        <RangePicker
                            allowEmpty={true}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <div className="i-label">Bidding Date</div>
                        <RangePicker
                            allowEmpty={true}
                            format={format}
                            placeholder={['Bid start date', 'Bid end date']}
                            value={[props.bidStartDate ? getMomentDateForUI({ date: new Date(props.bidStartDate), format }) : null, props.bidEndDate ? getMomentDateForUI({ date: new Date(props.bidEndDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ bidStartDate: dates[0], bidEndDate: dates[1] });
                                } else {
                                    props.updateState({ bidStartDate: null, bidEndDate: null });
                                }
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <div className="i-label"><FormattedMessage id='common.status' /></div>
                        <Dropdown
                            items={props.rfqStatusList || []}
                            valueKeyName='statusName'
                            optionKeyName='rfqStatusId'
                            statusColorKey='statusColor'
                            value={props.selectedUserStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.status' })}
                            onSelect={(selectedValue, option) => {
                                updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <div className="i-label"><FormattedMessage id='customer.filter.priority.placeholder' /></div>
                        <Dropdown
                            items={[{ name: 'Low', value: 'low' }, { name: 'Medium', value: 'medium' }, { name: 'High', value: 'high' }, { name: 'Urgent', value: 'urgent' }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={props.selectedPriority}
                            placeholder={props.intl.formatMessage({ id: 'customer.filter.priority.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedPriority: option.name })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedPriority: undefined })
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <div className="i-label"><FormattedMessage id='supplier.filter.salePerson.placeholder' /></div>
                        <Dropdown
                            items={props.salesPersonList || []}
                            sortItems={true}
                            sortKey={"fullName"}
                            sortDirection="asc"
                            valueKeyName='fullName'
                            optionKeyName='relationshipEmployeeId'
                            value={props.selectedSalesPersonName}
                            placeholder={props.intl.formatMessage({ id: 'supplier.filter.salePerson.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedSalesPersonName: option.name, selectedSalesPersonId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedSalesPersonName: undefined, selectedSalesPersonId: undefined })
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <div className="i-label"><FormattedMessage id='supplier.filter.refNum.placeholder' /></div>
                        <TextBox 
                            placeholder={props.intl.formatMessage({
                                id: 'supplier.filter.refNum.placeholder', defaultMessage: ''
                            })}
                            type='text'
                            maxLength={20}
                            countStyle={{top:"38px"}}
                            value={props.selectedRefNum}
                            onChange={(e) => {
                                props.updateState({ selectedRefNum: e.target.value });
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <div className="i-label"><FormattedMessage id='salesInvoice.project.placeholder' /></div>
                        <Dropdown
                            items={props.projectList}
                            sortItems={true}
                            sortKey={"projectName"}
                            sortDirection="asc"
                            valueKeyName='projectName'
                            optionKeyName='projectMasterId'
                            // projectIdKey='projectMasterId'
                            value={props.selectedProjectName}
                            placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                            }}
                        />
                    </Col>
                                        {/* <Dropdown
                        // style={{ width: '140px'}}
                        className='drop-fileds'
                        items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partiallyFullfilled' }, { name: 'Fullfilled', value: 'fullFilled' }]}
                        valueKeyName='name'
                        optionKeyName='value'
                        value={props.selectedStatusName}
                        placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                        onSelect={(status, option) => {
                            updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                        }}
                        allowClear={true}
                        onClear={() => {
                            updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                        }}
                    /> */}
                    {/* <Dropdown
                        // style={{ width: '140px'}}
                        className='drop-fileds1'
                        items={[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]}
                        valueKeyName='name'
                        optionKeyName='value'
                        value={props.selectedInvStatusName}
                        placeholder='Inv Status'
                        onSelect={(status, option) => {
                            updateState({ selectedInvStatusName: option.name, selectedInvStatusId: option.value })
                        }}
                        allowClear={true}
                        onClear={() => {
                            updateState({ selectedInvStatusName: undefined, selectedInvStatusId: undefined })
                        }}
                    /> */}
                </Row>
            </Drawer>
        </>
    )
};

export default RFQFiltersComp;
