import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { MARKETPLACE_SHIPPING_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
// const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const getAllShippingChoices = (payload) => {
    return dispatch => {
        return axios
            .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceShippingChoice/getAll`)
            .then(res => {

                dispatch({ type: MARKETPLACE_SHIPPING_ACTION_LIST.SHIPPING_CHOICES, data: res.data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch(err => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}

export const getAllShippingServices = (payload) => {
    return dispatch => {
        return axios
            .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceShippingServices/getAll?relationshipId=${payload.relationshipId}`)
            .then(res => {

                dispatch({ type: MARKETPLACE_SHIPPING_ACTION_LIST.SHIPPING_SERVICES, data: res.data });
            })
            .catch(err => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}

export const saveUpdateShippingRule = (payload, props) => {

    return dispatch => {
        // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceShippingRule/saveOrUpdate`, payload)
            .then(res => {
                showToasterMessage({ messageType: 'success', description: 'Shipping Rule Successfully saved.' });
                props.getCountryShippingRule(props);
            })
            .catch((err = {}) => {

            })
    }
}

export const getCountryShippingRule = (payload) => {

    return dispatch => {
        return axios
            .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceShippingRule/getAllMarketplaceShippingRules?relationshipId=${payload.relationshipId}`)
            .then(res => {

                let rule = {};
                for (let i = 0; i < res.data.length; i++) {
                    let obj = res.data[i];
                    if (obj.marketplaceShippingCountryId === payload.marketplaceShippingCountryId) {
                        rule = obj;
                        rule.priceRange = JSON.parse(rule.priceRange);
                        rule.weightRange = JSON.parse(rule.weightRange);
                        rule.carrierService = JSON.parse(rule.carrierService);
                        break;
                    }
                }

                if (!rule.priceRange || rule.priceRange.length === 0) {
                    rule.priceRange = [];
                    rule.priceRange.push({ startPrice: 0, endPrice: 0, rate: 0 });
                    rule.priceRange.push({ startPrice: 0, endPrice: 0, rate: 0 });
                }

                if (!rule.weightRange || rule.weightRange.length === 0 || rule.weightRange === "0") {
                    rule.weightRange = [];
                    rule.weightRange.push({ startWeight: 0, endWeight: 0, rate: 0 });
                    rule.weightRange.push({ startWeight: 0, endWeight: 0, rate: 0 });
                }

                const d = {
                    marketplaceShippingCountryId: payload.marketplaceShippingCountryId,
                    shippingData: rule
                };


                dispatch({ type: MARKETPLACE_SHIPPING_ACTION_LIST.COUNTRY_SHIPPING_RULE, data: d });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch(err => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}