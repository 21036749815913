import axios from "axios";
import { COMMON_ACTIONS, FINANCE_ACTION_LIST } from "../../../static/constants";
import config from "../../../config/environmentConfig";
import { getFinancialYear, showToasterMessage } from "../../../utils";
// const { lang } = require('../../../../translations' + (sessionStorage.getItem('language') || 'en') + '.js');
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchBankBookList = (payload, companyInfo) => {
  let FY = getFinancialYear(companyInfo);
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios 
        .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/paymentTransaction/getBankbookTransactions?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber - 1 || 0}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=${payload.filterStartDate || ''}&filterEndDate=${payload.filterEndDate|| ''}`)
        .then(res => {
          const data = {
            list: res.data,
            pageNo: payload.pageNumber,
          }
          dispatch({ type: FINANCE_ACTION_LIST.BANK_BOOK_LIST, data });
        })
        .catch((err) => {
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          } else {
            err.actionToCall = FINANCE_ACTION_LIST.BANK_BOOK_LIST;
          }
        })
    }
}



export const getBankBookCount = (payload, companyInfo) => {
  let FY = getFinancialYear(companyInfo);
  return (dispatch) => {
    return axios
    .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/paymentTransaction/getBankbookTransactionsCount?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber-1 || 0}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=${payload.filterStartDate || ''}&filterEndDate=${payload.filterEndDate|| ''}`)
      .then((res) => {
        dispatch({
          type: FINANCE_ACTION_LIST.BANK_BOOK_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: FINANCE_ACTION_LIST.BANK_BOOK_LIST_PAGE_RESET });
  };
};