import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppRoute from '../route';
import CustomModal from '../components/modal/Modal';
import { StackModal } from '../components/modal/StackModal';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../actions/commonActions';
import { Spin } from 'antd';
import './Main.scss';

class Main extends Component {
  render() {
    const { common: { isLoadingTemp }, modal, modalStack } = this.props;
    const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;
    window.document.title = sessionStorage.getItem('logged_in_entity') ? (JSON.parse(sessionStorage.getItem('logged_in_entity')).defaultCompany?.storeName || 'Alpide ERP') : 'Alpide ERP'
    return (
      <BrowserRouter basename="/app/">
       <Spin indicator={antIcon} className="spinner2" tip="Loading..." spinning={isLoadingTemp} />
          <CustomModal {...modal} {...this.props}/>
          <StackModal modalStack={modalStack} {...this.props} />
     
          <AppRoute />
        
        {/* <Spin indicator={antIcon} className="spinner" wrapperClassName="spinner" tip="Loading..." spinning={true} >
          <AppRoute />
        </Spin>
        <Spin indicator={antIcon} className="spinner2" wrapperClassName="spinner2" tip="Loading..." spinning={false} >
          <CustomModal {...modal} {...this.props}/>
          <StackModal modalStack={modalStack} {...this.props} />
        </Spin> */}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  common: state.common,
  modal: state.modal,
  modalStack: state.stackModal.modalStack
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Main);