import axios from "axios";
import config from "../../../config/environmentConfig";
import { MEMBERSHIP_ACTION_LIST, DONATION_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const getAllMembershipForm = (payload) => {
    return (dispatch) => {
        return axios.get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/getAllForms?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber || 0}&registrationType=membership`)
            .then((res) => {
                dispatch({
                    type: MEMBERSHIP_ACTION_LIST.MEMBERSHIP_FORM_LIST,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || "")] ||
                            "Some error occurred",
                    });
                }
            })
            .finally(() => {
              dispatch({ type: DONATION_ACTION_LIST.DONATION_SETTING_LOADING, data: {isMembershipFormListLoaded : true}});
            });
    };
};

export const deleteRegistrationForm= (payload, props) => {
    return dispatch => {
        return axios
          .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/delete`,payload)
          .then(res => {
            showToasterMessage({messageType: 'success',  description: lang[res.data.message || 'common.delete.success'] });
            props.getAllMembershipForm({...payload, pageNumber: 0, pageSize:100});
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
            }else{
              err.actionToCall = MEMBERSHIP_ACTION_LIST.MEMBERSHIP_FORM_LIST;
            }
          })
      }
  }
