import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Tooltip, Skeleton, Pagination, Checkbox, Popover, Menu, Empty } from "antd";

import {
  fetchPaginationDataIfNeeded,
  showToasterMessage,
  getMomentDateForUIReadOnly,
  exportExcel,
  fixedDecimalAndFormateNumber,
  sortTable,
} from "../../../../utils";
//import SODetail from '../../../../containers/modal/modalBody/customer/SODetail';
import SQDetail from "../../../../containers/modal/modalBody/customer/SQDetail";
import DropdownAction from "antd/lib/dropdown";
// import RFQDetail from '../../../../containers/modal/modalBody/supplier/RFQDetail';
// import RFQSplitDetail from '../../../../containers/modal/modalBody/supplier/RFQSplitDetail';
import XeroInvoice from "../../../../containers/modal/modalBody/common/xero/Invoice";
import EInvoiceDetail from "../../../../containers/modal/modalBody/customer/EInvoiceDetail";
import SalesInvoiceDetails from "../../../../containers/modal/modalBody/customer/SalesInvoiceDetail";
import SalesInvoicePaymentList from "../../../../containers/modal/modalBody/customer/SalesInvoicePaymentList";
// import { AG_GRID_CLASS_CONSTANTS } from "../../../../static/cssClassConstants";
// import { AG_GRID_CONSTANTS } from "../../../../static/agGridConstants";
// import { Dropdown } from "../../../general/Dropdown";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  FilterOutlined,
  InsertRowRightOutlined,
  DownOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileExcelOutlined,
  DownloadOutlined,
  CloudUploadOutlined,
  MailOutlined,
  ClockCircleOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
// import '../../../../assets/stylesheets/global'
// import '../../../../assets/stylesheets/common'
// import { FilterOutlined, UndoOutlined, } from '@ant-design/icons';

import "./index.scss";
//import PageBreadcrumb from '../../../PageBreadcrumb';
//import PayInvoice from '../../../../containers/customer/invoice/PayInvoice';
import XeroIcon from "../../../../assets/images/xero_logo.svg";
import { CONSTANTS, ICONS } from "../../../../static/constants";
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
import find from "lodash.find";
import RecurringInvoiceSetup from "../../../../containers/modal/modalBody/customer/RecurringInvoiceSetup";
import ReminderSetupComponent from "../../../../containers/modal/modalBody/customer/ReminderSetup";
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'



// import RegistrationInvoiceSetting from "../../../../containers/modal/modalBody/settings/RegistrationInvoiceSetting";
// const { lang } = require("../../../../translations/" +
//   (sessionStorage.getItem("language") || "en") +
//   ".js");
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
// const { RangePicker } = DatePicker;

const SalesInvoiceListingComp = (props) => {
  // const [isAllChecked, toggleAllChecked] = useState(false);
  const {
    salesInvoiceList,
    updateState,
    companyInfo,
    salesInvoiceCount,
    pageNumber,
    selectedItems,
    dir = 0,
  } = props;


  // function sortTable(table, col, reverse) {
  //     var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
  //         tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
  //         i;
  //     reverse = -((+reverse) || -1);
  //     tr = tr.sort(function (a, b) { // sort rows
  //         return reverse                          // `-1 *` if want opposite order
  //             * (a.cells[col].textContent.trim() // using `.textContent.trim()` for test
  //                 .localeCompare(b.cells[col].textContent.trim())
  //                );
  //     });
  //     for(i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
  // }

  // function makeSortable(table) {
  //     var th = table.tHead, i;
  //     th && (th = th.rows[0]) && (th = th.cells);
  //     if (th) i = th.length;
  //     else return; // if no `<thead>` then do nothing
  //     while (--i >= 0) (function (i) {
  //         var dir = 1;
  //         th[i].addEventListener('click', function () {sortTable(table, i, (dir = 1 - dir))});
  //     }(i));
  // }

  // function makeAllSortable(parent) {
  //     parent = parent || document.body;
  //     while (--i >= 0) makeSortable(t[i]);
  // }
  // setTimeout(() => {
  //     makeAllSortable()
  // }, 1000);

  const sortColumn = (e) => {
    //sortTable(document.getElementById('invoiceTable'), e.target.parentElement.cellIndex, 1 - dir);
    sortTable(
      document.getElementById("invoiceTable"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };

  if (!selectedItems) {
    props.updateState({ selectedItems: [] });
  }

  // useEffect( () => {
  //     props.updateState({
  //         gridApi: null,
  //         gridColumnApi: null
  //     })
  // } );

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      filters: {
        customerId: props.selectedCustomerId,
        status: props.selectedStatus,
      },
    };
    fetchPaginationDataIfNeeded(
      "fetchSalesInvoices",
      "salesInvoiceList",
      props,
      payload
    );
  };
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      filters: {
        customerId: props.selectedCustomerId,
        status: props.selectedStatus,
      },
    };
    props.resetPaginatedData();
    props.fetchSalesInvoices(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };
  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // }
  // const openSoDetails = (data) => {
  //     const soPayload = {
  //         customerId: data.customerId,
  //         salesOrderMasterId: data.soMasterId
  //     }
  //     props.pushModalToStack({
  //         modalBody: <SODetail {...props} soPayload={soPayload} />,
  //         width: '100%',
  //         hideFooter: true,
  //         wrapClassName: 'modal-custom-detail'
  //     })
  // }
  // const openAuditDrawer = (props, data) => {
  //     props.updateState({
  //         title: <div className="drawer-title">
  //             Audit Trail
  //         </div>,
  //         auditDrawerVisible: true,
  //         deletApiUrl: 'deleteAuditTrailSalesInvoice',
  //         masterKey: 'invoiceMasterId',
  //         masterKeyValue: data.invoiceMasterId,
  //         apiUrl: 'saveAuditTrailSalesInvoice',
  //         customerId: data.customerId,
  //         trailList: data.auditTrailSalesInvoiceList,
  //         updateTrailListInTransaction: (list) => {
  //             data.auditTrailSalesInvoiceList = list;
  //         }
  //     });
  // }

  const exportExcelData = (selectedInvoiceList, props) => {
    const dataList = (selectedInvoiceList || []).map((item) => {
      return {
        "Invoice #": item.invoiceNumber || "",
        "Invoice Date": getMomentDateForUIReadOnly({
          date: new Date(item.invoiceDate),
          format,
        }),
        "Invoice Due Date": getMomentDateForUIReadOnly({
          date: new Date(item.invoiceDueDate),
          format,
        }),
        "Customer Name": item.customerName || "",
        "Invoice Amount": Number(item.invoiceTotalAmount || 0).toFixed(2),
        "Paid Amount": Number(item.totalPaymentReceived || 0).toFixed(2),
        "Due Amount": Number(
          item.invoiceTotalAmount -
            (item.totalPaymentReceived + item.creditApplied)
        ).toFixed(2),
        "Credit Applied": Number(item.creditApplied).toFixed(2),
        "System Status": item.status,
        "Payment Term": item.paymentTermName || "",
        "Project Name": item.projectName || "",
        "Past Due":
          item.status === "void" || item.status === "paid"
            ? "-"
            : item.dueDays < 0
            ? Math.abs(item.dueDays || 0) + " Days"
            : "Not yet due",
      };
    });
    exportExcel(dataList, "Sales Invoice");
  };

  const openSQDetails = (data) => {
    const sqPayload = {
      customerId: data.customerId,
      salesQuotationMasterId: data.salesQuotationMasterId,
    };
    props.pushModalToStack({
      modalBody: <SQDetail {...props} sqPayload={sqPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  // const _getSoMasterId = (data) => {
  //     let soId = [];
  //     data.customerInvoiceDetailsList.forEach((e) => {
  //         if (e.salesOrderMasterId && soId.indexOf(e.salesOrderMasterId) === -1) {
  //             soId.push(e.salesOrderMasterId)
  //         }
  //     })
  //     return soId.toString();
  // }

  // const openRFQDetails = (data) => {
  //     const rfqPayload = {
  //         rfqMasterId: data.rfqMasterId,
  //         rfqChildMasterId: data.rfqChildMasterId
  //     }
  //     props.pushModalToStack({
  //         modalBody: data.rfqChildMasterId ? <RFQSplitDetail {...props} rfqPayload={rfqPayload} /> : <RFQDetail {...props} rfqPayload={rfqPayload} />,
  //         width: '100%',
  //         hideFooter: true,
  //         wrapClassName: 'modal-custom-detail'
  //     })
  // }

  // const onGridReady = (params) => {
  //   if (props.isColumnFitTable) {
  //     params.api.sizeColumnsToFit();
  //   }
  //   props.updateState({
  //     gridApi: params.api,
  //     gridColumnApi: params.columnApi,
  //   });
  //   onDataRendered(params);
  // };

  const openSalesInvoiceDetails = (data) => {
    const invoicePayload = {
      customerId: data.customerId,
      invoiceMasterId: data.invoiceMasterId,
    };
    props.pushModalToStack({
      modalBody: (
        <SalesInvoiceDetails {...props} invoicePayload={invoicePayload} />
      ),
      width: "100%",
      hideFooter: true,
      keyboard: true,
      wrapClassName: "modal-custom-detail",
    });
  };
  const openEInvoiceDetails = (data) => {
    const invoicePayload = {
      customerId: data.customerId,
      invoiceMasterId: data.invoiceMasterId,
    };
    props.pushModalToStack({
      modalBody: (
        <EInvoiceDetail {...props} invoicePayload={invoicePayload} />
      ),
      width: "100%",
      hideFooter: true,
      keyboard: true,
      wrapClassName: "modal-custom-detail",
    });
  };
  const openSalesInvoicePaymentList = (data) => {
    const invoicePayload = {
      customerId: data.customerId,
      invoiceMasterId: data.invoiceMasterId,
    };
    props.pushModalToStack({
      title: <div className='ant-modal-title'><FormattedMessage id='heading.sales.salesPayment' defaultMessage='' /></div>,
      modalBody: (
        <SalesInvoicePaymentList {...props} invoicePayload={invoicePayload} />
      ),
      width: 1200,
      hideFooter: true,
      maskClosable: true,
      innerHeight: 900,
      wrapClassName: "remark-modalms",
    });
  };

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  // const getActionMenuPermissionData = () => {
  //     const primaryPerm = permissions.primary || [];
  //     const permittedModules = {
  //         firstSection: {
  //             invoicePayment: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_PAYMENT, PERMISSION_VALUES.CREATE),
  //             cloneInvoice: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_INVOICE, PERMISSION_VALUES.CREATE),
  //             updateInvoice: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_INVOICE, PERMISSION_VALUES.UPDATE),
  //             deleteInvoice: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_INVOICE, PERMISSION_VALUES.DELETE)
  //         }
  //     };
  //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
  //         if (permittedModules.firstSection[moduleName] === false) {
  //             delete permittedModules.firstSection[moduleName];
  //         }
  //     })
  //     return permittedModules;
  // }

  // const addItemConfirmationModal = (props, text, txData) => {
  //     const { showModal, companyInfo } = props;
  //     const labelName = <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />;
  //     const modalBody = <span>
  //         <FormattedMessage
  //             id='setItem.confirmation'
  //             values={{ value: `${text} to ` }}
  //             defaultMessage={``}
  //         />
  //         {labelName} ?
  //   </span>;

  //     let payload = {
  //         userStatus: text,
  //         relationshipId: companyInfo.relationshipId,
  //         invoiceMasterId: txData.invoiceMasterId,
  //         customerId: txData.customerId
  //     };

  //     const modalData = {
  //         modalBody,
  //         handleSubmit: () => {
  //             props.updateUserStatus(payload, props);
  //             props.hideModal();
  //             props.popModalFromStack();
  //         },
  //     };
  //     showModal(modalData);
  // }

  // const updateUserStatus = (props, txData) => {
  //     let payload = { formData: {} };
  //     const formData = payload.formData;
  //     const { pushModalToStack } = props;

  //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
  //         addItemConfirmationModal(props, formData.textBoxValue, txData);
  //     } else {
  //         const data = {
  //             title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
  //             formData,
  //             hideFooter: true,
  //             modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SALES_INVOICE },
  //             handleSubmit: (formData = {}) => {
  //                 if (formData.textBoxValue && formData.textBoxValue.length) {
  //                     addItemConfirmationModal(props, formData.textBoxValue, txData);
  //                 } else {
  //                     data.formData.submittedOnce = true;
  //                     pushModalToStack(data);
  //                 }
  //             }
  //         };
  //         pushModalToStack(data);
  //     }
  // }

  // const actionMenu = (param, permittedModules) => (
  //     <Menu className="cell-action-dropdown">
  //         {/* {
  //             permittedModules.firstSection.invoicePayment && param.data.status !== 'Pending Approval' && param.data.status !== 'Rejected' && param.data.status !== 'Void'
  //                 ? (
  //                     <Menu.Item key="1" value="edit" onClick={() => {
  //                         const modalData = {
  //                             title: 'Sales Payment',
  //                             modalBody: <PayInvoice invoiceData={param.data} />,
  //                             wrapClassName: 'pay-invoice-modal',
  //                             width: 500,
  //                             hideFooter: true,
  //                         };
  //                         props.showModal(modalData);
  //                     }}>
  //                         <i className="fa fa-credit-card" />
  //                         <span>Pay Invoice</span>
  //                     </Menu.Item>
  //                 )
  //                 : <Fragment />
  //         } */}
  //         {/* <Menu.Item key="2" onClick={() => {
  //             openAuditDrawer(props, param.data);
  //         }}>
  //             <i className="fa fa-history" />
  //                  View Audit Trail
  //                 </Menu.Item> */}
  //         {permittedModules.firstSection.updateInvoice && param.data.status !== 'void' ?
  //             <Menu.Item key="0" value="edit">
  //                 <i className="fa fa-credit-card" />
  //                 <Link
  //                     to={
  //                         {
  //                             pathname: '/admin/sales-invoice-update',
  //                             state: {
  //                                 customerId: param.data.customerId,
  //                                 invoiceMasterId: param.data.invoiceMasterId,
  //                                 soMasterIdArr: _getSoMasterId(param.data),
  //                                 update: true
  //                             }
  //                         }}
  //                 ><FormattedMessage id='updateInvoice.text' defaultMessage='' /></Link>
  //             </Menu.Item> : ''
  //         }
  //         {permittedModules.firstSection.cloneInvoice && <Menu.Item key="1" value="edit">
  //             <i className="fa fa-credit-card" />
  //             <Link
  //                 to={
  //                     {
  //                         pathname: '/admin/sales-invoice-update',
  //                         state: {
  //                             customerId: param.data.customerId,
  //                             invoiceMasterId: param.data.invoiceMasterId,
  //                             clone: true
  //                         }
  //                     }}
  //             >Clone  Invoice</Link>
  //         </Menu.Item>}
  //         {permittedModules.firstSection.deleteInvoice && ((param.data.status === 'unpaid' || param.data.status === 'open') && !param.data.isCashInvoice) ?
  //             <Menu.Item key="2">
  //                 <i className="fa fa-trash" />
  //                 <Link onClick={() => {
  //                     props.showModal({
  //                         modalBody: <FormattedMessage id='modal.txDetails.common.void.confirmation' defaultMessage='Are you sure you want to void this transaction?' />,
  //                         handleSubmit: () => {
  //                             props.deleteSalesInvoice({
  //                                 customerId: param.data.customerId,
  //                                 invoiceMasterId: param.data.invoiceMasterId,
  //                                 relationshipId: param.data.relationshipId,
  //                                 soMasterIdArr: _getSoMasterId(param.data),
  //                             }, props)
  //                         }
  //                     })

  //                 }}><FormattedMessage id='void.invoice' defaultMessage='' /></Link>
  //             </Menu.Item> : ''
  //         }
  //         <Menu.Item key="3" onClick={() => {
  //             updateUserStatus(props, param.data);
  //         }}>
  //             <i className="fa fa-pencil" />
  //             <FormattedMessage id='update.user.status' defaultMessage='' />
  //         </Menu.Item>
  //     </Menu>
  // );

  // const saveColumnIndex = (columnState) => {
  //   let data = props.txPreferences || {};
  //   let tablePreference = {};
  //   data.relationshipId = (props.companyInfo || {}).relationshipId;
  //   data.clientUserAccountId = (props.userInfo || {}).userId;
  //   if (data.tablePreference) {
  //     tablePreference = JSON.parse(data.tablePreference);
  //   }
  //   tablePreference.columnSetting = columnState;
  //   data.tablePreference = JSON.stringify(tablePreference);
  //   data.preferenceId = props.preferenceId;
  //   data.gridPreference = props.gridPreference;
  //   props.saveUserPrefrences(data);
  // };

  // const saveColumnWidth = (columnState, width) => {
  //   let data = props.txPreferences || {};
  //   let tablePreference = {};
  //   data.relationshipId = (props.companyInfo || {}).relationshipId;
  //   data.clientUserAccountId = (props.userInfo || {}).userId;
  //   if (data.tablePreference) {
  //     tablePreference = JSON.parse(data.tablePreference);
  //   }
  //   tablePreference.columnSetting = columnState;
  //   tablePreference.columnList = props.txColumns;
  //   data.tablePreference = JSON.stringify(tablePreference);
  //   data.preferenceId = props.preferenceId;
  //   data.gridPreference = props.gridPreference;
  //   props.saveUserPrefrences(data, props);
  // };

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //   if (event.column) {
  //     moveEvent = event;
  //   }
  //   if (
  //     isDragStopped &&
  //     moveEvent.column &&
  //     moveEvent.column.colDef &&
  //     moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API
  //   ) {
  //     saveColumnIndex(moveEvent.columnApi.getColumnState());
  //   }
  // };

  // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //   const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //   event.api.resetRowHeights();
  //   if (
  //     event.type === "columnResized" &&
  //     event.source !== "sizeColumnsToFit" &&
  //     event.source !== "api" &&
  //     event.finished === true
  //   ) {
  //     event.api.resetRowHeights();
  //     if (
  //       gridColumnApi &&
  //       gridColumnApi.columnController.bodyWidth <
  //         gridColumnApi.columnController.scrollWidth
  //     ) {
  //       props.gridApi.sizeColumnsToFit();
  //       setTimeout(() => {
  //         const columnArray = event.columnApi.getColumnState();
  //         saveColumnWidth(columnArray);
  //       }, 1000);
  //     } else {
  //       saveColumnWidth(event.columnApi.getColumnState());
  //     }
  //   }
  // };

  //const permittedModules = getActionMenuPermissionData();
  // const columnDefs = [
  //     {
  //         field: 'isChecked',
  //         colId: 'isChecked_1',
  //         headerComponentFramework: () => <Fragment />,
  //         cellRendererFramework: (params) => {
  //             return <Checkbox
  //                 onChange={(e) => {
  //                     params.node.setDataValue('isChecked_1', e.target.checked);
  //                     params.node.data.isChecked = e.target.checked;
  //                 }}
  //                 checked={params.data.isChecked} />
  //         },
  //         minWidth: 70,
  //         maxWidth: 70,
  //         rowDrag: true,
  //         pinned: 'left',
  //         suppressMovable: true,
  //         resizable: false
  //     },
  //     {
  //         field: 'isXeroUploaded',
  //         headerComponentFramework: () => <Tooltip trigger="click" placement="top" title={props.intl.formatMessage(
  //             { id: 'tooltip.uploadToXero', defaultMessage: '' }
  //         )}>
  //             Xero <i className="fa fa-question-circle-o pl5 cursor-pointer" />
  //         </Tooltip>,
  //         cellRendererFramework: (params) => {
  //             return params.data.isXeroUploaded ? <img src={XeroIcon} alt="" style={{ height: "25px" }} /> : "";
  //         },
  //         hide: _isColumnToHide('isXeroUploaded'),
  //         colId: 'isXeroUploaded_1',
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         resizable: true
  //     },
  //     {
  //         headerName: lang["customer.salesInvoice.listing.header.invoiceNo"],
  //         field: "invoiceNumber",
  //         resizable: true,
  //         sortable: true,
  //         hide: _isColumnToHide('invoiceNumber'),
  //         colId: 'invoiceNumber_1',
  //         minWidth: 80,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openSalesInvoiceDetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='customer.salesInvoice.listing.header.soNo' defaultMessage='' />;
  //     //     },
  //     //     field: "soNumber",
  //     //     resizable: true,
  //     //     hide: _isColumnToHide('soNumber'),
  //     //     colId: 'soNumber_1',
  //     //     minWidth: 120,
  //     //     maxWidth: 400,
  //     //     suppressMovable: true,
  //     //     cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //     //         openSoDetails(link.data)
  //     //     }}>{link.value}</div>,
  //     // },
  //     {
  //         // headerComponentFramework: () => {
  //         //     return <FormattedMessage id='customer.salesInvoice.listing.header.quoteNumber' defaultMessage='' />;
  //         // },
  //         headerName: lang["customer.salesInvoice.listing.header.quoteNumber"],
  //         field: "quotationNumber",
  //         hide: _isColumnToHide('quotationNumber'),
  //         colId: 'quotationNumber_1',
  //         minWidth: 70,
  //         sortable: true,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer circles-ling" onClick={() => {
  //             openSQDetails(link.data)
  //         }}>{link.value}</div>,
  //         resizable: true
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='customer.salesInvoice.listing.header.rfqNumber' defaultMessage='' />;
  //     //     },
  //     //     field: "rfqNumber",
  //     //     hide: _isColumnToHide('rfqNumber'),
  //     //     width: (props.txColumnSetting['rfqNumber'] || {}).width,
  //     //     cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //     //         openRFQDetails(link.data)
  //     //     }}>{link.value}</div>,
  //     //     resizable: true
  //     // },
  //     {
  //         // headerComponentFramework: () => {
  //         //     return <FormattedMessage id='customer.salesInvoice.listing.header.customer' defaultMessage='' />;
  //         // },
  //         headerName: lang["customer.salesInvoice.listing.header.customer"],
  //         field: "customerName",
  //         resizable: true,
  //         sortable: true,
  //         hide: _isColumnToHide('customerName'),
  //         colId: 'customerName_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer circles-ling">
  //             <div onClick={() => {
  //                 props.history.push('customers/details', {
  //                     customerId: link.data.customerId,
  //                     customerName: link.data.customerName,
  //                 })
  //             }}>{link.data.customerName}</div>
  //         </div>,
  //     },
  //     {
  //         // headerComponentFramework: () => {
  //         //     return <div><FormattedMessage id='customer.salesInvoice.listing.header.invoiceAmount' defaultMessage='' />   </div>;
  //         // },
  //         headerName: lang["customer.salesInvoice.listing.header.invoiceAmount"],
  //         field: "invoiceTotalAmount",
  //         colId: 'invoiceTotalAmount_1',
  //         hide: _isColumnToHide('invoiceTotalAmount'),
  //         minWidth: 70,
  //         sortable: true,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             //return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
  //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(link.value)} </span> : "-"

  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {

  //         headerName: "Inquiry #",
  //         field: 'customerInquiryNumber',
  //         hide: _isColumnToHide('customerInquiryNumber'),
  //         colId: "customerInquiryNumber_1",
  //         resizable: true,
  //         sortable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerInquiryNumber || '-'}
  //         </div>,
  //     },

  //     {

  //         headerName: "Customer RFQ #",
  //         field: 'customerRFQNumber',
  //         hide: _isColumnToHide('customerRFQNumber'),
  //         colId: "customerRFQNumber_1",
  //         resizable: true,
  //         sortable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerRFQNumber || '-'}
  //         </div>,
  //     },
  //     {
  //         headerName: "Cutomer PO #",
  //         field: 'customerPONumber',
  //         hide: _isColumnToHide('customerPONumber'),
  //         colId: "customerPONumber_1",
  //         resizable: true,
  //         sortable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerPONumber || '-'}
  //         </div>,
  //     },
  //     {

  //         headerName: lang["customer.salesInvoice.listing.header.paymentAmount"],
  //         field: "totalPaymentReceived",
  //         hide: _isColumnToHide('totalPaymentReceived'),
  //         colId: 'totalPaymentReceived_1',
  //         minWidth: 70,
  //         sortable: true,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             // return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value} </span> : "-"
  //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(link.value)} </span> : "-"
  //         },
  //         valueGetter: (link) => {
  //             return link.data.totalPaymentReceived ? Number(link.data.totalPaymentReceived).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {

  //         headerName: lang["creditApplied.text"],
  //         field: "creditApplied",
  //         hide: _isColumnToHide('creditApplied'),
  //         colId: 'creditApplied_1',
  //         sortable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value} </span> : "-"
  //         },
  //         valueGetter: (link) => {
  //             return link.data.creditApplied ? (Number(link.data.creditApplied || 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {

  //         headerName: lang["customer.salesInvoice.listing.header.dueAmount"],
  //         field: "invoiceDueAmount",
  //         hide: _isColumnToHide('invoiceDueAmount'),
  //         colId: 'invoiceDueAmount_1',
  //         sortable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             // return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{ link.value} </span> : "-"
  //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(link.value)} </span> : "-"
  //         },
  //         valueGetter: (link) => {
  //             return (link.data.invoiceTotalAmount - (link.data.totalPaymentReceived + link.data.creditApplied)) ? (Number(link.data.invoiceTotalAmount - (link.data.totalPaymentReceived + link.data.creditApplied))).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesInvoice.listing.header.paymentTerm' defaultMessage='' />;
  //         },
  //         headerName: lang["customer.salesInvoice.listing.header.paymentTerm"],
  //         field: "paymentTermName",
  //         colId: 'paymentTermName_1',
  //         hide: _isColumnToHide('paymentTermName'),
  //         sortable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true
  //     },
  //     {
  //         headerName: lang["customer.salesInvoice.listing.header.invoiceDate"],
  //         resizable: true,
  //         field: "invoiceDate",
  //         hide: _isColumnToHide('invoiceDate'),
  //         colId: 'invoiceDate_1',
  //         sortable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => <div>
  //             <Tooltip placement="topLeft" title={(link.data.invoiceDate && getMomentDateForUIReadOnly({ date: link.data.invoiceDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
  //                 {getMomentDateForUIReadOnly({ date: new Date(link.data.invoiceDate), format })}
  //             </Tooltip>
  //         </div>,
  //     },
  //     {

  //         headerName: lang["customer.salesInvoice.listing.header.invoiceDueDate"],
  //         resizable: true,
  //         field: "invoiceDueDate",
  //         hide: _isColumnToHide('invoiceDueDate'),
  //         colId: 'invoiceDueDate_1',
  //         sortable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => <div>
  //             <Tooltip placement="topLeft" title={(link.data.invoiceDueDate && getMomentDateForUIReadOnly({ date: link.data.invoiceDueDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
  //                 {link.data.invoiceDueDate && getMomentDateForUIReadOnly({ date: new Date(link.data.invoiceDueDate), format })}
  //             </Tooltip>
  //         </div>,
  //     },
  //     {
  //         headerName: lang["dueDays.text"],
  //         field: "dueDays",
  //         hide: _isColumnToHide('dueDays'),
  //         colId: 'dueDays_1',
  //         minWidth: 70,
  //         sortable: true,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (params) => {
  //             return (params.data.status === 'void' || params.data.status === 'paid') ? <div> - </div> : <div>{(params.value < 0) ?
  //                 <div><span className="status-code dueDays">{Math.abs(params.value || 0)} <FormattedMessage id='drawer.customer.days' /></span>
  //                 </div> :
  //                 // <div> - </div>
  //                 <div><span className="status-code paid"><FormattedMessage id='notDue.text' /></span></div>
  //             }
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesInvoice.listing.header.systemStatus' defaultMessage='' />;
  //         },
  //         field: "status",
  //         hide: _isColumnToHide('status'),
  //         colId: 'status_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (params) => {
  //             return (params.value === 'Rejected' && params.data.rejectionReason) ?
  //                 <div>
  //                     <Tooltip placement="topLeft" title={params.data.rejectionReason}>
  //                         <span className={["status-code", (params.value || "").toLowerCase()].join(' ')}><FormattedMessage id={params.value || "open"} /></span>
  //                     </Tooltip>
  //                 </div> :
  //                 <div> <span className={["status-code", (params.value || "").toLowerCase()].join(' ')}><FormattedMessage id={params.value || "open"} /></span> </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.status' defaultMessage='' />;
  //         },
  //         field: "userStatus",
  //         hide: _isColumnToHide('userStatus'),
  //         colId: "userStatus_1",
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.value || ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.project' defaultMessage='' />;
  //         },
  //         field: "project",
  //         hide: _isColumnToHide('project'),
  //         colId: "project_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.data.projectMasterId ? <span>{link.data.projectMasterId + '-' +link.data.projectName }</span> : ''}

  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='customer.salesInvoice.listing.header.isCashInvoice' defaultMessage='' />   </div>;
  //         },
  //         field: "isCashInvoice",
  //         colId: 'isCashInvoice_1',
  //         cellRendererFramework: (params) => {
  //             return params.node.data.isCashInvoice ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
  //         },
  //         hide: _isColumnToHide('isCashInvoice'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.refNumber' defaultMessage='' />;
  //         },
  //         field: "referenceNumber",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         hide: _isColumnToHide('referenceNumber'),
  //         colId: "referenceNumber_1",
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='onlinePayment.text' />;
  //         },
  //         field: "paymentGateway",
  //         hide: _isColumnToHide('onlinePayment'),
  //         colId: "onlinePayment_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (params) => {
  //             return params.node.data.paymentGateway ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='invoiceReminder.text' defaultMessage='' />;
  //         },
  //         field: "reminderCount",
  //         hide: _isColumnToHide('invoiceReminder'),
  //         colId: "invoiceReminder_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.data.reminderCount || 0}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='recurringInvoice.text' />;
  //         },
  //         field: "recurringCount",
  //         hide: _isColumnToHide('recurringInvoice'),
  //         colId: "recurringInvoice_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.data.recurringCount ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}
  //             </div>
  //         },
  //         resizable: true
  //     },
  // ]

  // if (Object.keys(permittedModules.firstSection).length) {
  //     columnDefs.push({
  //         headerComponentFramework: () => {
  //             return <div className="cursor-pointer">
  //                 <Popover
  //                     content={<AddAndRemoveCustomerListingColumn {...props} />}
  //                     title={<div className="text-center">Add/Remove </div>}
  //                     placement="left"
  //                     trigger="click"
  //                 >
  //                     <i className='fa fa-bars' />
  //                 </Popover>
  //             </div>;
  //         },
  //         field: "action",
  //         colId: 'action_1',
  //         pinned: "right",
  //         // cellRendererFramework: (param) => {
  //         //     return <Dropdown overlay={actionMenu(param, permittedModules)} trigger={['click']}>
  //         //         <Button className="action-btn small">
  //         //             <i className="fa fa-ellipsis-v" />
  //         //         </Button>
  //         //     </Dropdown>
  //         // },
  //         resizable: true,
  //         minWidth: 52,
  //         maxWidth: 52,
  //         lockPosition: true,
  //         suppressMovable: true,
  //     });
  // }

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  // const breadCrumbList = [
  //     {
  //         name: 'Dashboard',
  //         link: 'dashboard'
  //     },
  //     {
  //         name: 'Customer',
  //         link: 'customers'
  //     }, {
  //         name: 'Sales Invoice',
  //     }, {
  //         name: 'List'
  //     }
  // ];

  // const onModelUpdated = (params) => {
  //   if ((salesInvoiceList[pageNumber] || []).length && !props.isRowHeightSet) {
  //     setTimeout(() => {
  //       params.api.resetRowHeights();
  //     }, 1000);
  //     props.updateState({
  //       isRowHeightSet: true,
  //     });
  //   }
  //   if (props.tableDataReRendered) {
  //     //params.api.resetRowHeights();
  //     props.updateState({
  //       tableDataReRendered: false,
  //     });
  //     let txColumnSetting =
  //       props.txColumnSetting && props.txColumnSetting.length
  //         ? props.txColumnSetting
  //         : params.columnApi.getColumnState();
  //     const columnToBeSet = [];
  //     const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_INVOICE_LISTING;

  //     indexObjArr.forEach((e) => {
  //       let colObj = find(txColumnSetting, { colId: e + "_1" });
  //       if (colObj) {
  //         columnToBeSet.push(colObj);
  //       }
  //     });

  //     txColumnSetting = txColumnSetting.filter((ele) => {
  //       let columnName = ele.colId.split("_")[0];
  //       return indexObjArr.indexOf(columnName) === -1;
  //     });
  //     txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //     txColumnSetting.forEach((e) => {
  //       let columnName = e.colId.split("_")[0];
  //       if (columnName !== "action") {
  //         if (columnName === "isChecked") {
  //           e.hide = false;
  //         } else {
  //           e.hide = _isColumnToHide(columnName);
  //         }
  //       }
  //       if (columnName === "action") {
  //         e.hide = false;
  //         e.pinned = "right";
  //       }
  //     });
  //     params.columnApi.setColumnState(txColumnSetting);
  //     setTimeout(() => {
  //       if (
  //         props.gridColumnApi &&
  //         props.gridColumnApi.columnController.bodyWidth <
  //           props.gridColumnApi.columnController.scrollWidth
  //       ) {
  //         params.api.sizeColumnsToFit();
  //       }
  //     }, 500);
  //   }
  // };

  // const onDataRendered = (params) => {
  //   let txColumnSetting =
  //     props.txColumnSetting && props.txColumnSetting.length
  //       ? props.txColumnSetting
  //       : params.columnApi.getColumnState();
  //   if (Object.keys(txColumnSetting).length) {
  //     const columnToBeSet = [];
  //     const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_INVOICE_LISTING;

  //     indexObjArr.forEach((e) => {
  //       let colObj = find(txColumnSetting, { colId: e + "_1" });
  //       if (colObj) {
  //         columnToBeSet.push(colObj);
  //       }
  //     });

  //     txColumnSetting = txColumnSetting.filter((ele) => {
  //       let columnName = ele.colId.split("_")[0];
  //       return indexObjArr.indexOf(columnName) === -1;
  //     });
  //     txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //     txColumnSetting.forEach((e) => {
  //       let columnName = e.colId.split("_")[0];
  //       if (columnName !== "action") {
  //         if (columnName === "isChecked") {
  //           e.hide = false;
  //         } else {
  //           e.hide = _isColumnToHide(columnName);
  //         }
  //       }
  //       if (columnName === "action") {
  //         e.hide = false;
  //         e.width = 52;
  //         e.pinned = "right";
  //       }
  //     });
  //     params.columnApi.setColumnState(txColumnSetting);
  //     setTimeout(() => {
  //       if (
  //         params.columnApi &&
  //         params.columnApi.columnController.bodyWidth <
  //           params.columnApi.columnController.scrollWidth
  //       ) {
  //         params.api.sizeColumnsToFit();
  //       }
  //     }, 500);
  //   } else {
  //     params.api.sizeColumnsToFit();
  //   }
  // };

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      invoiceMasterId: Number(item.invoiceMasterId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.invoiceMasterId !== item.invoiceMasterId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < salesInvoiceList[pageNumber].length; i++) {
      let item = salesInvoiceList[pageNumber][i];
      let selectItem = find(selectedItems, {
        invoiceMasterId: Number(item.invoiceMasterId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
  };

  const isCheckedAll = () => {
    if (
      !salesInvoiceList ||
      !salesInvoiceList[pageNumber] ||
      salesInvoiceList[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < salesInvoiceList[pageNumber].length; i++) {
      let usr = salesInvoiceList[pageNumber][i];
      let selectItem = find(selectedItems, {
        invoiceMasterId: Number(usr.invoiceMasterId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };
  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <CheckCircleOutlined />
          Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <CloseCircleOutlined />
          Unselect all
        </Menu.Item>

        <Menu.Item
          key="3"
          onClick={() => {
            const invoiceList = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         invoiceList.push(obj.data);
            //     }
            // });
            selectedItems.forEach((obj) => {
              invoiceList.push(obj);
            });

            const modalData = {
              title: "Xero Invoice Upload",
              modalBody: <XeroInvoice {...props} invoiceList={invoiceList} />,
              width: 1100,
              hideFooter: true,
            };
            let isXeroUploaded = false;
            invoiceList.forEach((data) => {
              if (data.isXeroUploaded) {
                isXeroUploaded = true;
              }
            });

            if (isXeroUploaded) {
              return showToasterMessage({
                messageType: "error",
                description:
                  "Selected invoice has already been uploaded. Please remove and try again",
              });
            }

            if (invoiceList.length) {
              props.showModal(modalData);
            } else {
              showToasterMessage({
                messageType: "error",
                description: " Please select invoice before uploading",
              });
            }
          }}
        >
          <CloudUploadOutlined />
          Upload to Zero
        </Menu.Item>

        <Menu.Item
          key="4"
          onClick={() => {
            const invoiceList = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         invoiceList.push({
            //             invoiceMasterId: obj.data.invoiceMasterId,
            //             customerId: obj.data.customerId,
            //             relationshipId: obj.data.relationshipId,
            //             customerName: obj.data.customerName,
            //             invoiceNumber: obj.data.invoiceNumber,
            //         });
            //     }
            // });
            selectedItems.forEach((obj) => {
              invoiceList.push({
                invoiceMasterId: obj.invoiceMasterId,
                customerId: obj.customerId,
                relationshipId: obj.relationshipId,
                customerName: obj.customerName,
                invoiceNumber: obj.invoiceNumber,
              });
            });

            const modalData = {
              title: (
                <FormattedMessage
                  id="popup.recurringInvoice.title"
                  defaultMessage=""
                />
              ),
              modalBody: (
                <RecurringInvoiceSetup
                  {...props}
                  invoiceList={invoiceList}
                  isBulkInvoice={true}
                />
              ),
              width: "50%",
              hideFooter: true,
            };

            if (invoiceList.length) {
              props.pushModalToStack(modalData);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <RetweetOutlined />
          Make Recurring
        </Menu.Item>

        <Menu.Item
          key="5"
          onClick={() => {
            const invoiceList = [];

            selectedItems.forEach((obj) => {
              invoiceList.push({
                invoiceMasterId: obj.invoiceMasterId,
                customerId: obj.customerId,
                relationshipId: obj.relationshipId,
                customerName: obj.customerName,
                invoiceNumber: obj.invoiceNumber,
                contactId: obj.contactId || 0,
              });
            });
            if (invoiceList.length) {
              props.updateState({
                emailDetailDrawerVisible: true,
                isSendBulkInvoice: true,
                isBulkInvoice: true,
                selectedInvoiceList: invoiceList,
              });
              // props.pushModalToStack(modalData);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <MailOutlined />
          Send Email
        </Menu.Item>
        <Menu.Item
          key="6"
          onClick={() => {
            const invoiceList = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         invoiceList.push({
            //             invoiceMasterId: obj.data.invoiceMasterId,
            //             customerId: obj.data.customerId,
            //             relationshipId: obj.data.relationshipId,
            //             customerName: obj.data.customerName,
            //             invoiceNumber: obj.data.invoiceNumber,
            //         });
            //     }
            // });

            selectedItems.forEach((obj) => {
              invoiceList.push({
                invoiceMasterId: obj.invoiceMasterId,
                customerId: obj.customerId,
                relationshipId: obj.relationshipId,
                customerName: obj.customerName,
                invoiceNumber: obj.invoiceNumber,
              });
            });

            const modalData = {
              title: <FormattedMessage id="reminder.text" defaultMessage="" />,
              modalBody: (
                <ReminderSetupComponent
                  {...props}
                  invoiceList={invoiceList}
                  isBulkInvoice={true}
                />
              ),
              width: "50%",
              hideFooter: true,
            };

            if (invoiceList.length) {
              props.pushModalToStack(modalData);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <ClockCircleOutlined />
          Add Reminder
        </Menu.Item>
      </Menu>
    );
  };
  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="excel"
          onClick={() => {
            const invoiceList = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         invoiceList.push(obj.data);
            //     }
            // });
            selectedItems.forEach((obj) => {
              invoiceList.push(obj);
            });

            if (invoiceList.length) {
              exportExcelData(invoiceList);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <FileExcelOutlined />
          Excel File
        </Menu.Item>

        {/* <Menu.Item key="2" value="pdf" onClick={()=>{
                        const customerList = [];
                        selectedItems.forEach(data => {
                            // let data = obj.data;
                            let address = find(data.boLocationList, { isDefault: 1, locationType: "BillingAddress" }) || {};
                            let contact = (data.boContactList && find(data.boContactList, { isPrimaryContact: 1 }));
                            customerList.push({ ...data, defaultAddress: address, defaultContact: contact });
                        })
                        if (customerList.length) {
                            props.generatePDF({
                                companyInfo: props.companyInfo,
                                fileName: 'Customers.pdf',
                                transactionName: 'customerListing',
                                customerList: customerList
                            })
                        } else {
                            const modalData = {
                                title:
                                    <div className="title">
                                        <i className="fa fa-ban mr15" />
                                        Validation Failed
                                    </div>,
                                modalBody:
                                    <Fragment>
                                        <div className="warning-message">
                                            No customer selected. Please select customer before downloading.
                                        </div>
                                        <div className="button-action">
                                            <Button className="ant-btn-default" onClick={() => props.popModalFromStack()}>OK</Button>
                                        </div>
                                    </Fragment>,
                                hideFooter: true,
                                wrapClassName: 'validation-failed-modal'
                            };
                            props.pushModalToStack(modalData);
                        }
                        }}
                    >
                      <FilePdfOutlined />
                      PDF File
                    </Menu.Item> */}
      </Menu>
    );
  };

  return (
    <Fragment>
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id={
                  (props.companyInfo?.industryName || "").toLowerCase() ===
                  "education"
                    ? "heading.fees.feesInvoiceList"
                    : "heading.sales.salesInvoice"
                }
                defaultMessage=""
              />
            </div>
            {salesInvoiceCount ? (
              <>
                <div className="vertical-sep" />
                <div>{salesInvoiceCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          {/* <button className="all-checkbox-control">
                            <Checkbox
                                checked={isAllChecked}
                                onChange={(e) => {
                                    toggleAllChecked(e.target.checked);
                                    props.gridApi.forEachNode(obj => {
                                        obj.setDataValue('isChecked_1', e.target.checked);
                                    })
                                }}
                            />
                        </button> */}
          {/* <div className="cursor-pointer">
                        <Tooltip placement='left' title={props.intl.formatMessage({ id: 'tooltip.addRemoveColumn.msg' })} trigger='hover'>
                            <span className="top-button"> */}
          {/* <div></div> */}

          {/* </span>
                        </Tooltip>
                    </div> */}

          {/* <button className="action-btn" onClick={() => {
                            const invoiceList = [];
                            // props.gridApi.forEachNode(obj => {
                            //     if (obj.data.isChecked) {
                            //         invoiceList.push(obj.data);
                            //     }
                            // });
                            selectedItems.forEach(obj => {
                                invoiceList.push(obj);
                            })

                            const modalData = {
                                title: 'Xero Invoice Upload',
                                modalBody: <XeroInvoice {...props} invoiceList={invoiceList} />,
                                width: 1100,
                                hideFooter: true,

                            };
                            let isXeroUploaded = false;
                            invoiceList.forEach((data) => {
                                if (data.isXeroUploaded) {
                                    isXeroUploaded = true;
                                }

                            });

                            if (isXeroUploaded) {
                                return showToasterMessage({ messageType: "error", description: "Selected invoice has already been uploaded. Please remove and try again" })
                            }

                            if (invoiceList.length) {
                                props.showModal(modalData);
                            } else {
                                showToasterMessage({ messageType: "error", description: " Please select invoice before uploading" })
                            }

                        }}>
                           
                            
                            <Tooltip placement="right" title={props.intl.formatMessage(
                                { id: 'tooltip.uploadToXero', defaultMessage: '' }
                            )}>
                               <FilterOutlined /> &nbsp; 
                                {/* <img src={XeroIcon} alt="" style={{ height: "20px" }} /> <i className="fa fa-upload" /> */}
          {/* </Tooltip>Uplode to Zero
                        </button> */}

          {/* <button className="action-btn"
                            
                            title="Download Excel">
                            {/* <i className="fa fa-file-excel-o" /> */}
          {/* <FilterOutlined /> &nbsp;
                            Download File */}
          {/* </button> */}

          {/* <button className="action-btn" title={props.intl.formatMessage({ id: 'makeRecurring.text' })}
                            onClick={() => {
                                const invoiceList = [];
                                // props.gridApi.forEachNode(obj => {
                                //     if (obj.data.isChecked) {
                                //         invoiceList.push({
                                //             invoiceMasterId: obj.data.invoiceMasterId,
                                //             customerId: obj.data.customerId,
                                //             relationshipId: obj.data.relationshipId,
                                //             customerName: obj.data.customerName,
                                //             invoiceNumber: obj.data.invoiceNumber,
                                //         });
                                //     }
                                // });
                                selectedItems.forEach(obj => {
                                    invoiceList.push({
                                        invoiceMasterId: obj.invoiceMasterId,
                                        customerId: obj.customerId,
                                        relationshipId: obj.relationshipId,
                                        customerName: obj.customerName,
                                        invoiceNumber: obj.invoiceNumber,
                                    });
                                })

                                const modalData = {
                                    title: <FormattedMessage id='popup.recurringInvoice.title' defaultMessage='' />,
                                    modalBody: <RecurringInvoiceSetup {...props} invoiceList={invoiceList} isBulkInvoice={true} />,
                                    width: '50%',
                                    hideFooter: true,
                                };

                                if (invoiceList.length) {
                                    props.pushModalToStack(modalData);
                                } else {
                                    showToasterMessage({ messageType: "error", description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding' }) })
                                }

                            }}>
                                <FilterOutlined /> &nbsp;
                            <FormattedMessage id='makeRecurring.text' />
                        </button> */}
          {/* <tooltip> */}
          {/* <button className="action-btn" title={props.intl.formatMessage({ id: 'addReminder.text' })}
                            >
                                <FilterOutlined /> &nbsp;
                            <FormattedMessage id='addReminder.text' />
                        </button> */}
          {/* </tooltip> */}

          {/* <button className="action-btn" title={props.intl.formatMessage({ id: 'forgot.btn.submit' })}
                            >
                            {/* <FilterOutlined /> &nbsp; */}
          {/* <FormattedMessage id='forgot.btn.submit' /> */}
          {/* </button> */}

          {/* <div className="right-action">

            <button className="action-btn" onClick={() => {
              props.updateState({
                salesInvoiceFilterDrawerVisible: true
              })
            }}>
              <FilterOutlined onClick={() => {

              }} /> &nbsp; <FormattedMessage id='filter.text' defaultMessage='' />
            </button>
           
           
            <button className="action-btn">
              <Popover
                content={<AddAndRemoveCustomerListingColumn {...props} />}
                title={<div className="text-center">Add/Remove</div>}
                placement="left"
                trigger="click"
                arrow="right"
                // visible={props.showColPop}
              >
                <button onClick={() => { props.updateState({ showColPop: !props.showColPop }) }}>
                  <InsertRowRightOutlined />&nbsp; Columns
                </button>              </Popover>


            </button>
            <button className="action-btn">
              <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
                <span>
                  <DownloadOutlined /> &nbsp;{" "}
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>
            </button>

          </div> */}

          <div className="right-actions">
            <button
              onClick={() => {
                props.updateState({
                  salesInvoiceFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined onClick={() => {}} /> &nbsp;{" "}
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>

            <Popover
              content={<AddAndRemoveCustomerListingColumn {...props} />}
              title={<div className="text-center">Add/Remove</div>}
              placement="leftTop"
              trigger="click"
              arrow="right"
              // visible={props.showColPop}
            >
              <button
                onClick={() => {
                  props.updateState({ showColPop: !props.showColPop });
                }}
              >
                <InsertRowRightOutlined />
                &nbsp; Columns
              </button>
            </Popover>

            {/* <button className="action-btn"> */}
            <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
              <span>
                <DownloadOutlined /> &nbsp;{" "}
                <FormattedMessage
                  id="common.action.dropdown.downloadAs"
                  defaultMessage=""
                />
              </span>
            </DropdownAction>
            {/* </button> */}
          </div>
          </Skeleton>
        </div>
        {/* <div className="table-action-container"> */}
        {/* <div> */}
        {/* <Dropdown
                            style={{ marginTop: '-20px', width: '200px', marginRight: '5px' }}
                            items={props.customers[pageNumber] || []}
                            valueKeyName='companyName'
                            optionKeyName='customerId'
                            value={props.selectedCustomerName}
                            onSearch={(searchedText) => {
                                props.fetchCustomers({
                                    isCompact: true,
                                    searchedText: searchedText,
                                    pageNumber: 1,
                                    hideSpinner: true,
                                    pageSize: 100,
                                    relationshipId: (props.companyInfo || {}).relationshipId
                                })
                            }}
                            placeholder={props.intl.formatMessage({
                                id: 'customer.salesInvoice.form.customer.placeholder', defaultMessage: ''
                            })}

                            onSelect={
                                (customerId, option) => {
                                    updateState({
                                        selectedCustomerId: option.value,
                                        selectedCustomerName: option.name,
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    selectedCustomerId: undefined,
                                    selectedCustomerName: undefined,
                                })
                            }}
                        /> */}

        {/*             <Dropdown
                            style={{ marginTop: '-20px', width: '180px', marginRight: '5px' }}
                            items={[{ name: 'Paid', value: 'paid' },{ name: 'Partially Paid', value: 'partially.paid' }, { name: 'Unpaid', value: 'unpaid' }, { name: 'Void', value: 'void' }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={props.selectedStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.status' })}
                            onSelect={(status, option) => {
                                updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                            }}
                        />
                        {props.module === "school" ?
                            <Dropdown
                                style={{ marginTop: '-20px', width: '200px', marginRight: '5px' }}
                                items={props.studentFormList || []}
                                valueKeyName='formShortName'
                                optionKeyName='registrationFormSettingId'
                                value={props.selectedformName}
                                placeholder={props.intl.formatMessage({ id: 'setting.student.registration.list.heading' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedformName: option.name, selectedformId: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedformName: undefined, selectedformId: undefined })
                                }}
                            />
                            :
                            <Dropdown
                                style={{ marginTop: '-20px', width: '180px', marginRight: '5px' }}
                                items={props.projectList}
                                valueKeyName='projectName'
                                optionKeyName='projectMasterId'
                                projectIdKey='projectMasterId'
                                value={props.selectedProjectName}
                                placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                                }}
                            /> 
                              }

        {/* <DatePicker
                            style={{ marginTop: '-20px', width: '200px', marginRight: '5px' }}
                            allowClear={false}
                            format={format}
                            key='startDate'
                            placeholder='Start Date'
                            defaultValue={props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null}
                            onChange={(selectedDate) => {
                                props.updateState({ startDate: selectedDate })
                            }}
                        />

                        <DatePicker
                            style={{ marginTop: '-20px', width: '200px' }}
                            allowClear={false}
                            format={format}
                            placeholder='End Date'
                            key='endDate'
                            defaultValue={props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null}
                            onChange={(selectedDate) => {
                                props.updateState({ endDate: selectedDate })
                            }}
                        /> */}
        {/* <RangePicker
                            style={{ marginTop: '-0px', width: '300px', marginRight: '5px', top:'2px' }}
                            allowEmpty={true}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        /> */}

        {/* <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            if ((props.startDate && !props.endDate) || (!props.startDate && props.endDate)) {
                                return showToasterMessage({ messageType: "error", description: props.intl.formatMessage({ id: 'invalid.dateRange.message' }) })
                            }
                            const payload = {
                                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                filters: {
                                    customerId: props.selectedCustomerId,
                                    status: props.selectedStatus,
                                    projectMasterId: props.selectedProjectMasterId,
                                    startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                                    endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                                    formId: props.selectedformId,
                                    module: props.module,
                                }
                            };
                            fetchPaginationDataIfNeeded('fetchSalesInvoices', 'salesInvoiceList', props, payload);
                            fetchDataIfNeeded('getSalesInvoiceCount', 'salesInvoiceCount', props, payload);
                        }}>
                            {<FilterOutlined />}
                        </button> */}
        {/* </div> */}
        {/* </div> */}

        {/* {props.txColumns.length ? <div> */}
        {/* <div className="cursor-pointer">
                        <Tooltip placement='left' title={props.intl.formatMessage({ id: 'tooltip.addRemoveColumn.msg' })} trigger='hover'>
                            <span className="top-button">
                                <Popover
                                    content={<AddAndRemoveCustomerListingColumn {...props} />}
                                    title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' />  </div>}
                                    placement="left"
                                    trigger="click"
                            >
                                    <i className='fa fa-bars' />
                                </Popover>
                            </span>
                        </Tooltip>
                    </div> */}
        {/* <AgGridReact
                        columnDefs={columnDefs}
                        defaultColDef={{
                            flex: 1,
                            autoHeight: true,
                            wrapText: true,
                        }}
                        rowData={salesInvoiceList[pageNumber] || []}
                        rowDragManaged={true}
                        domLayout={"autoHeight"}
                        animateRows={true}
                        //getRowHeight={getRowHeight}
                        onGridReady={onGridReady}
                        onModelUpdated={onModelUpdated}
                        onColumnResized={onColumnResized}
                        onDragStopped={() => {
                            onColumnMoved({}, true);
                        }}
                        //onColumnMoved={onColumnMoved}
                    >
                    </AgGridReact> */}
          <Skeleton loading={props.listLoading} paragraph={{ rows: 12 }} active>

        <div className="table-container">
          <table id="invoiceTable">
            <thead>
              <tr>
                <th>
                  <div className="check-cell">
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                    <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                      <DownOutlined />
                    </DropdownAction>
                  </div>
                </th>
                {!_isColumnToHide("isXeroUploaded") ? (
                  <th>
                    <Tooltip
                      trigger="click"
                      placement="top"
                      title={props.intl.formatMessage({
                        id: "tooltip.uploadToXero",
                        defaultMessage: "",
                      })}
                    >
                      Xero <i className={`${ICONS.HELP} cursor-pointer`} />
                    </Tooltip>
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("invoiceNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceNo"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}
                {/* <th onClick={sortColumn}>Invoice Title<i className={ICONS.SORT} /></th>  */}
                {!_isColumnToHide("quotationNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.quoteNumber"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("customerName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.customer"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("invoiceTotalAmount") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceAmount"
                      defaultMessage=""
                    />{" "}
                    <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

{/* {!_isColumnToHide("eInvoiceGenerated") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="E-Invoice"
                      defaultMessage=""
                    />{" "}
                    <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}
{!_isColumnToHide("documentNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="Document #"
                      defaultMessage=""
                    />{" "}
                    <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )} */}
                {!_isColumnToHide("customerInquiryNumber") ? (
                  <th onClick={sortColumn}>
                    Inquiry # <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("customerRFQNumber") ? (
                  <th onClick={sortColumn}>
                    Customer RFQ # <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("customerPONumber") ? (
                  <th onClick={sortColumn}>
                    Cutomer PO # <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("totalPaymentReceived") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.paymentAmount"
                      defaultMessage=""
                    />{" "}
                    <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("creditApplied") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="creditApplied.text"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("invoiceDueAmount") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.dueAmount"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("paymentTermName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.paymentTerm"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("invoiceDate") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceDate"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("invoiceDueDate") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceDueDate"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("dueDays") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="dueDays.text" defaultMessage="" /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("status") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.systemStatus"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("userStatus") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="common.status" defaultMessage="" /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("project") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="common.project" defaultMessage="" /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("isCashInvoice") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.isCashInvoice"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("referenceNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.refNumber"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("onlinePayment") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="onlinePayment.text"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("invoiceReminder") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="invoiceReminder.text"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}

                {!_isColumnToHide("recurringInvoice") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="recurringInvoice.text"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                ) : (
                  null
                )}
                {/* <th width='6%'>
                                    <FormattedMessage id='actions.text' defaultMessage='' />
                                    </th> */}
              </tr>
            </thead>
            <tbody>
              {salesInvoiceList && salesInvoiceList[pageNumber]?.length
                ? salesInvoiceList[pageNumber].map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Checkbox
                            onClick={() => {
                              itemSelection(item);
                            }}
                            checked={
                              find(selectedItems, {
                                invoiceMasterId: Number(item.invoiceMasterId),
                              })
                                ? true
                                : false
                            }
                          />
                        </td>
                        {!_isColumnToHide("isXeroUploaded") ? (
                          <td>
                            {item.isXeroUploaded ? (
                              <img
                                src={XeroIcon}
                                alt=""
                                style={{ height: "25px" }}
                              />
                            ) : (
                              ""
                            )}{" "}
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("invoiceNumber") ? (
                          <td>
                            <div
                              className="table-link-text"
                              onClick={() => {
                                openSalesInvoiceDetails(item);
                              }}
                            >
                              {item.invoiceNumber}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {/* <th>{item.invoiceTitle || ''}</th> */}

                        {!_isColumnToHide("quotationNumber") ? (
                          <td>
                            <div
                              className="table-link-text"
                              onClick={() => {
                                openSQDetails(item);
                              }}
                            >
                              {item.quotationNumber}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("customerName") ? (
                          <td className="one-line-view">
                            <div className="table-link-text">
                              <div
                                // onClick={() => {
                                //   props.history.push(
                                //     "/admin/customers/details",
                                //     {
                                //       customerId: item.customerId,
                                //       customerName: item.customerName,
                                //     }
                                //   );
                                onClick={() => {
                                  props.pushModalToStack({  
                            
                                    modalBody: <CustomerProfile {...props}
                                    customerId= {item.customerId}
                                  //   customerName={rowData.customerName}
                                  //   customerDetails={rowData}
                                />,
                                width: '100%',
                                hideTitle: true,  
                                hideFooter: true,
                                wrapClassName: "new-transaction-wrapper",
                                onCancel: () => {
                                  props.showModal({
                                    modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                                    footer:{submit:{intlId:"button.close.label"}},
                                    handleSubmit: () => {
                                      const linkProps = (props.location && props.location.state) || {};
                                      props.fetchSalesInvoices({
                                        companyInfo,
                                        relationshipId: companyInfo.relationshipId,
                                        userId: (props.userInfo || {}).userId,
                                        relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
                                        pageNumber: 1,
                                        pageSize: 200,
                                        filters: {
                                          module: linkProps.module, 
                                          formId: linkProps.module === "school" ? props.defaultSchoolForm : 0,
                                        }
                                      })
                                      props.popModalFromStack();
                                    }
                                  })
                                }
                                })
                                }}
                              >
                                {item.customerName || <div class="empty-data"></div>}
                              </div>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("invoiceTotalAmount") ? (
                          <td className="one-line-view">
                            {/* {item.invoiceTotalAmount && item.invoiceTotalAmount !== '-' ? <span> {item.foreignCurrencyIcon ? <i className={item.foreignCurrencyIcon}></i> : (item.foreignCurrency ? <span>{item.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{item.invoiceTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"} */}
                            {item.invoiceTotalAmount &&
                            item.invoiceTotalAmount !== "-" ? (
                              <div className="amount-TextRight">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalAndFormateNumber(
                                  item.invoiceTotalAmount
                                )}{" "}
                              </div>
                            ) : (
                              <div class="empty-data"></div>
                            )}
                          </td>
                        ) : (
                          ""
                        )}

{/* {!_isColumnToHide("eInvoiceGenerated") ? (
                          <td className="one-line-view">
                            {item.isEInvoiceGenerated &&
                            item.isEInvoiceGenerated  ? (
                              <span>
                               Yes
                              </span>
                            ) : (
                              <span>
                               No
                              </span>
                            )}
                          </td>
                        ) : (
                          ""
                        )}

{!_isColumnToHide("documentNumber") ? (
                          <td className="one-line-view">
                            <div
                              className="table-link-text"
                              onClick={() => {
                                openEInvoiceDetails(item);
                              }}
                            >
                              {item.documentNumber||"link"}
                            </div>
                          </td>
                        ) : (
                          ""
                        )} */}

                        {/* {<td>
                                                <div>
                                                    {item.salesOrderMasterId || '-'}
                                                </div>
                                            </td>} */}

                        {!_isColumnToHide("customerInquiryNumber") ? (
                          <td>
                            <div>{item.customerInquiryNumber || <div class="empty-data"></div>}</div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("customerRFQNumber") ? (
                          <td>
                            <div>{item.customerRFQNumber || <div class="empty-data"></div>}</div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("customerPONumber") ? (
                          <td>
                            <div>{item.customerPONumber || <div class="empty-data"></div>}</div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("totalPaymentReceived") ? (
                          <td className="one-line-view" style={{textAlign:'center'}}>
                            {/* {item.totalPaymentReceived && item.totalPaymentReceived !== '-' ? <span> {item.foreignCurrencyIcon ? <i className={item.foreignCurrencyIcon}></i> : (item.foreignCurrency ? <span>{item.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{item.totalPaymentReceived.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"} */}
                            {item.totalPaymentReceived &&
                            item.totalPaymentReceived !== "-" ? (
                              <span>
                                <span className="cursor-pointer" onClick={()=>{openSalesInvoicePaymentList(item)}}>{item.paymentCount}</span> {item.paymentCount > 1 ? 'Payments': 'Payment'} of
                                <br/>
                              <span>
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalAndFormateNumber(
                                  item.totalPaymentReceived
                                )}{" "}
                              </span>
                              </span>
                            ) : (
                              <div class="empty-data"></div>
                            )}
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("creditApplied") ? (
                          <td>
                            {/* {item.creditApplied && item.creditApplied !== '-' ? <span> {item.foreignCurrencyIcon ? <i className={item.foreignCurrencyIcon}></i> : (item.foreignCurrency ? <span>{item.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{item.creditApplied.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"} */}
                            {item.creditApplied &&
                            item.creditApplied !== "-" ? (
                              <span>
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalAndFormateNumber(
                                  item.creditApplied
                                )}{" "}
                              </span>
                            ) : (
                              <div class="empty-data"></div>
                            )}
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("invoiceDueAmount") ? (
                          <td>
                            {/* {(item.invoiceTotalAmount - (item.totalPaymentReceived + item.creditApplied)) ? <span> {item.foreignCurrencyIcon ? <i className={item.foreignCurrencyIcon}></i> : (item.foreignCurrency ? <span>{item.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{(Number(item.invoiceTotalAmount - (item.totalPaymentReceived + item.creditApplied))).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"} */}
                            {item.invoiceTotalAmount -
                            (item.totalPaymentReceived + item.creditApplied) ? (
                              <span>
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalAndFormateNumber(
                                  Number(
                                    item.invoiceTotalAmount -
                                      (item.totalPaymentReceived +
                                        item.creditApplied)
                                  )
                                )}{" "}
                              </span>
                            ) : (
                              <div class="empty-data"></div>
                            )}
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("paymentTermName") ? (
                          <td>{item.paymentTermName} </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("invoiceDate") ? (
                          <td>
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={
                                  item.invoiceDate &&
                                  getMomentDateForUIReadOnly({
                                    date: item.invoiceDate,
                                    format: CONSTANTS.TABLE_DATE_FORMAT,
                                  })
                                }
                              >
                                {item.invoiceDate && getMomentDateForUIReadOnly({
                                  date: new Date(item.invoiceDate),
                                  format: CONSTANTS.TABLE_DATE_FORMAT,
                                })}
                              </Tooltip>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("invoiceDueDate") ? (
                          <td className="one-line-view">
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={
                                  item.invoiceDueDate &&
                                  getMomentDateForUIReadOnly({
                                    date: item.invoiceDueDate,
                                    format:
                                      CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                  })
                                }
                              >
                                {item.invoiceDueDate &&
                                  getMomentDateForUIReadOnly({
                                    date: new Date(item.invoiceDueDate),
                                    format,
                                  })}
                              </Tooltip>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("dueDays") ? (
                          <td className="one-line-view">
                            {item.status === "void" ||
                            item.status === "paid" ? (
                              <div> - </div>
                            ) : (
                              <div>
                                {item.dueDays < 0 ? (
                                  <div className="status-code dueDays">
                                      {Math.abs(item.dueDays || 0)}{" "}
                                      <FormattedMessage id="drawer.customer.days" />{Math.abs(item.dueDays) == 1 ?"":"s"}
                                  </div>
                                ) : (
                                  <div className="status-code paid">
                                      <FormattedMessage id="notDue.text" />
                                  </div>
                                )}
                              </div>
                            )}
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("status") ? (
                          <td>
                            {item.status === "Rejected" &&
                            item.rejectionReason ? (
                              <div>
                                <Tooltip
                                  placement="topLeft"
                                  title={item.rejectionReason}
                                >
                                  <div
                                    className={[
                                      "status-code",
                                      (item.status || "").toLowerCase(),
                                    ].join(" ")}
                                  >
                                    <FormattedMessage
                                      id={item.status || "open"}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            ) : (
                              <div>
                                {" "}
                                <div
                                  className={[
                                    "status-code",
                                    (item.status || "unpaid").toLowerCase(),
                                  ].join(" ")}
                                >
                                  <FormattedMessage
                                    id={item.status || "unpaid"}
                                  />
                                </div>{" "}
                              </div>
                            )}
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("userStatus") ? (
                          <td>
                            <div>
                              {item.userStatus || <div class="empty-data"></div>}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("project") ? (
                          <td>
                            <div>
                              {/* {item.projectMasterId ? (
                              <span>
                                {item.projectMasterId +
                                  "-" +
                                  item.projectName}
                              </span>
                            ) : (
                              ""
                            )} */}

                              {item.projectMasterId ? (
                                <span>{item.projectName}</span>
                              ) : (
                                <div class="empty-data"></div>
                              )}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("isCashInvoice") ? (
                          <td>{item.isCashInvoice ? "Yes" : "No"}</td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("referenceNumber") ? (
                          <td>{item.referenceNumber ? <div>{item.referenceNumber}</div> : <div class="empty-data"></div> } </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("onlinePayment") ? (
                          <td>{item.paymentGateway ? "Yes" : "No"}</td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("invoiceReminder") ? (
                          <td>
                            <div>{item.reminderCount || 0}</div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("recurringInvoice") ? (
                          <td>
                            <div>{item.recurringCount ? "Yes" : "No"}</div>
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })
                :  <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>}
            </tbody>
          </table>
        </div>

        {/* </div> : null} */}
      </Skeleton>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={salesInvoiceCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(SalesInvoiceListingComp);
