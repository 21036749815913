import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SO_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchSalesOrderShipment = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/getSOShipmentBySOMasterId?relationshipId=${payload.relationshipId}&salesOrderMasterId=${payload.salesOrderMasterId}&customerId=${payload.customerId}&status=Shipped`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SO_SHIPMENT_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = SO_ACTION_LIST.SO_SHIPMENT_LIST;
        }
      })
  }
}

export const updateActualDeliveryDate = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/updateActualDeliveryDate?relationshipId=${payload.relationshipId}&actualDeliveryDate=${payload.actualDeliveryDate}&shipmentMasterId=${payload.shipmentMasterId}`)
      .then(res => {
        showToasterMessage({messageType: "success",  description: lang[(res || {}).data] || 'Actual delivery date is updated successfully.' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (props && props.fetchAllShipment) {
          const companyInfo = props.companyInfo || {};
          const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25
          };
          props.fetchAllShipment(payload);
        }
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = SO_ACTION_LIST.SO_SHIPMENT_LIST;
        }
      })
  }
}

export const markAsDelivered = (payload, props)=>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/updateStatus?status=${payload.status}&statusColor=${payload.lableColor}&relationshipId=${payload.relationshipId}&actualDeliveryDate=${payload.actualDeliveryDate}&shipmentMasterId=${payload.shipmentMasterId}&customerId=${payload.customerId}&createdByEmpId=${payload.createdByEmpId}`)
      .then(res => {
        if (props.fetchAllShipment) {
          props.fetchAllShipment({
            companyInfo: (props.companyInfo || {}),
            relationshipId: (props.companyInfo || {}).relationshipId,
            pageNumber: props.pageNumber || 1,
            pageSize: props.pageSize || 25
          })
        } else {
          props.fetchSalesOrderShipment({
            customerId: (props.soData || {}).customerId,
            salesOrderMasterId: (props.soData || {}).salesOrderMasterId,
            relationshipId: (props.companyInfo || {}).relationshipId
          })
        }
        showToasterMessage({messageType: "success",  description: lang[((res || {}).data || "")] || 'Marked delivered successfully.' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = SO_ACTION_LIST.SO_SHIPMENT_LIST;
        }
      })
  }
}

export const updateInventory = (payload, props)=>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/updateInventory?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&shipmentMasterId=${payload.shipmentMasterId}`)
      .then(res => {
        showToasterMessage({messageType: "success",  description: lang[(res || {}).data] || 'Update successfully.' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = SO_ACTION_LIST.SO_SHIPMENT_LIST;
        }
      })
  }
}