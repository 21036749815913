import axios from "axios";
import config from "../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../utils";
import { COMMON_ACTIONS ,PRODUCT_ACTION_LIST} from "../../../../static/constants";

const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const updateFeedback = (payload, files, props) => {
  return dispatch => {
    let formData = new FormData();
    if (files) {
      formData.append('files', files)
    }

    formData.append('ecomFeedback', JSON.stringify(payload || {}));

    return axios.post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecomFeedback/createFeedback `,
      formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    )
      .then(response => {

        const val = {
          pageNumber: props.pageNumber,
          pageSize: props.pageSize,
          relationshipId: payload.relationshipId,
          isPublished: payload.isPublished
        }

        props.activeKey === "1" ? props.fetchPublishAndUnpublishList({ ...payload, isPublished: 1 }) : props.fetchPublishAndUnpublishList({ ...payload, isPublished: 0 })
        props.activeKey === "1" ? props.countPublishAndUnpublish({ ...payload, isPublished: 1 }) : props.countPublishAndUnpublish({ ...payload, isPublished: 0 })


        showToasterMessage({
          messageType: "success",
          description: "Updated Successfully.",
        });

      })
      .catch(err => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      })
  }
}
export const deleteFeedbackImgaes = (payload,props)=>{

  return dispatch => {
    return axios.post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecomFeedback/deleteFeedBackImage`,payload
    )
        .then(response => {
          const payload1 = {
            relationshipId: (props.companyInfo || {}).relationshipId,
            feedbackId: (props.feedbackData || {})?.feedbackId

          }
          props.getReviewData(payload1)
          showToasterMessage({
            description: (response || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
         
        })
        .catch(err => {
          showToasterMessage({
              messageType: "error",
              description: "Some error occured",
            });
          //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
  }
}
export const getReviewData= (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecomFeedback/getByFeedbackId?relationshipId=${payload.relationshipId}&feedbackId=${payload.feedbackId}`)
      .then(response => {
        
        dispatch({ type: PRODUCT_ACTION_LIST.RATING_REVIEW_DATA, data:response.data  });
       
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
       
      })
      .finally(()=>{
          dispatch({
              type: COMMON_ACTIONS.HIDE_LIST_LOADER,
            });
      }

      )
  }
}