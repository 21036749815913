import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_ACTION_LIST, SUPPLIER_ACTION_LIST } from '../../../static/constants';
import { getPaths, showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createContact = (payload, formData,props) => {
  let actionType = payload.customerId ? CUSTOMER_ACTION_LIST.ADD_CUSTOMER_BO_CONTACT : SUPPLIER_ACTION_LIST.ADD_SUPPLIER_BO_CONTACT;
  const paths = getPaths(payload);

  let url  =`${paths.API_BASE_URL}${config.API_VERSION}/${paths.rootContext}/boContact/createContact`;
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(url, payload)
      .then(res => {
        if(paths.rootContext === 'purchase'){
          props.fetchSupplierContacts(payload, props);
        }else{
          props.fetchAllContacts(payload, props);
        }
        if (!formData?.isFundraiser) {
          dispatch({ type: actionType, data: res.data, formData });
        }
        if (formData?.funcToCall) {
          formData.funcToCall(formData.funcToCallPayload)
        }
        if (formData?.callback) {
          formData.callback(res.data)
        }
        showToasterMessage({ messageType: 'success', description: 'Contact saved successfully' });
      })
      .catch((err) => {
          showToasterMessage({ messageType: 'error', description: err.response?.data || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}