import React from 'react';
// import { AgGridReact } from 'ag-grid-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Empty, Pagination, Skeleton, Tooltip } from 'antd';
import { fetchPaginationDataIfNeeded, fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../../../utils';
import PurchaseInvoceDetail from '../../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail';
import { CONSTANTS } from '../../../../../static/constants';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';


const PaidInvoicesComponent = (props) => {
    const { paidInvoiceList, paidInvoiceListCount, companyInfo, updateState, pageNumber } = props;
    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const getRowHeight = () => {
    //     return 28;
    // }

    // const onFirstDataRendered = (params) => {
    //     params.api.sizeColumnsToFit();
    // }

    const loadPageData = (pageNumber, pageSize) => {
        const payload = {companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.fetchPaidInvoices(payload,props)
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchPaidInvoices(payload,props);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    const openInvoiceDetails = (data) => {
        const invoicePayload = {
            supplierId: data.supplierId,
            invoiceMasterId: data.invoiceMasterId
        }
        props.pushModalToStack({
            modalBody: <PurchaseInvoceDetail {...props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const getPaymentAmount = (data) => {
        let totalPayment = 0;
        if (data && data.supplierPaymentsList && data.supplierPaymentsList.length) {
            data.supplierPaymentsList.map((e) => {
                totalPayment += e.paymentAmount;
                return fixedDecimalNumber(totalPayment);
            })

        }
        return totalPayment;
    }

    const showTotal = (total) => {
        return props.intl.formatMessage({id: 'pagination.text.total', defaultMessage: ''}) + ` ${ total } `+ props.intl.formatMessage({id: 'pagination.text.items', defaultMessage: ''});
    };

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.listing.paidInvoice.header.invoiceNumber' defaultMessage='' />;
    //         },
    //         field: "invoiceNumber",
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openInvoiceDetails(link.data)
    //         }}>{link.value}</div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.listing.paidInvoice.header.invoiceDate' defaultMessage='' />;
    //         },
    //         field: "invoiceDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}>
    //                     {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.listing.paidInvoice.header.invoiceDueDate' defaultMessage='' />;
    //         },
    //         field: "invoiceDueDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}>
    //                     {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div>Purchase Amount  </div>;
    //         },
    //         field: "invoiceTotalAmount",
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span>;
    //         },
    //         valueFormatter: (data) => {
    //             data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    //             return data.value;
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='supplier.listing.paidInvoice.header.paymentAmount' defaultMessage='' />  </div>;
    //         },
    //         field: "paymentAmount",
    //         cellRendererFramework: (params) => {
    //             let totalPayment = 0;
    //             if (params.data && params.data.supplierPaymentsList.length) {
    //                 params.data.supplierPaymentsList.map((e) => {
    //                      totalPayment += e.paymentAmount;
    //                      return fixedDecimalNumber(totalPayment);
    //                 })

    //             }
    //             return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(fixedDecimalNumber(totalPayment)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>;
    //         },
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
       
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "status",
    //         cellRendererFramework: (params) => {
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /></span> </div>
    //         },
    //         resizable: true,
    //     }
    // ];

    return (
        // <div className='agGridWrapper'>
        //     <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
        //         <AgGridReact
        //             columnDefs={columnDefs}
        //             rowData={paidInvoiceList[pageNumber] || []}
        //             rowDragManaged={true}
        //             domLayout={"autoHeight"}
        //             animateRows={true}
        //             onColumnResized ={(params)=>{
        //                 params.api.resetRowHeights();
        //             }}
        //             defaultColDef={{
        //                 flex:1,
        //                 autoHeight: true,
        //                 wrapText: true,
        //             }}
        //             //getRowHeight={getRowHeight}
        //             onGridSizeChanged={onFirstDataRendered}
        //         >
        //         </AgGridReact>
        //         </div>
        // </div>
        <Skeleton loading={props.modalLoading} active paragraph={{ rows: 9 }}>
            <div className="table-container" style={{height: '500px'}}>
                <table id='supplier-table'>
                    <thead>
                        <tr>
                            <th><FormattedMessage id='supplier.listing.unPaidInvoice.header.invoiceNumber' defaultMessage='' /></th>
                            <th><FormattedMessage id='supplier.listing.unPaidInvoice.header.invoiceAmount' defaultMessage='' /></th>
                            <th><FormattedMessage id='supplier.listing.unPaidInvoice.header.paymentAmount' defaultMessage='' /></th>
                            <th><FormattedMessage id='supplier.listing.unPaidInvoice.header.dueAmount' defaultMessage='' /></th>
                            <th><FormattedMessage id='supplier.listing.unPaidInvoice.header.invoiceDate' defaultMessage='' /></th>    
                            <th><FormattedMessage id='supplier.listing.unPaidInvoice.header.invoiceDueDate' defaultMessage='' /></th>
                            <th><FormattedMessage id='common.status' defaultMessage='' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(paidInvoiceList[pageNumber] || []).length ? (paidInvoiceList[pageNumber] || []).map((rowData, i) => {
                            return <tr key={i}>
                                <td><div className="cursor-pointer" onClick={() => {
                                    openInvoiceDetails(rowData);
                                } }>{rowData.invoiceNumber}</div></td>
                                 <td>
                                    {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> 
                                    :
                                    props.companyInfo.currencyCode + " "}{fixedDecimalNumber(rowData.invoiceTotalAmount)}
                                </td>
                                <td>
                                    {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> 
                                    :
                                    props.companyInfo.currencyCode + " "}{fixedDecimalNumber(getPaymentAmount(rowData))}
                                </td>
                             <td>{fixedDecimalNumber(Number(rowData.invoiceTotalAmount) - Number(fixedDecimalNumber(getPaymentAmount(rowData))))}</td>
                                <td>
                                    {(rowData.invoiceDate && getMomentDateForUIReadOnly({ date: rowData.invoiceDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                </td>
                                <td>
                                    {(rowData.invoiceDueDate && getMomentDateForUIReadOnly({ date: rowData.invoiceDueDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                </td>
                               
                    
                                {/* <td>
                            {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> 
                            :
                                props.companyInfo.currencyCode + " "}{fixedDecimalNumber(rowData.invoiceDueAmount)}
                                </td> */}
                                <td>
                                    {rowData.status ? <div className={`status-code ${rowData.statusColorForUICell || ""}`} style={{ background: rowData.userStatusColor }}>
                                        <FormattedMessage id={rowData.status} defaultMessage='' /></div> : ''}    
                                </td>
                            </tr>;
                        }) 
                        : 
                        <tr key="empty-data-box">
                            <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                <Empty />
                            </td>
                        </tr>
                        }

                    </tbody>
                </table>
            </div>
            {/* {!props.supplierId && !props.supplierData?.supplierId? ( */}

            <Pagination
                size="small"
                total={paidInvoiceListCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}  showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber })
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber, pageSize, props)
                }}
            />
        </Skeleton>
    );
};

export default injectIntl(PaidInvoicesComponent);
