import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, PURCHASE_INVOICE_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAvailableDebitsList = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/debitMemo/getDebitMemosBySupplierId?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&pageSize=500&pageNumber=0&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.AVAILABLE_DEBITS_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_INVOICE_ACTION_LIST.AVAILABLE_DEBITS_LIST;
          err.data = [];
        }
      })
  }
}

  export const resetAvailableDebitsList = () => {
    return dispatch => {
      dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.AVAILABLE_DEBITS_LIST_RESET });
    }
  }

  
export const applyDebits = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/debitMemo/applyDebits`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const payload = {
          companyInfo: props.companyInfo,
          relationshipId: props.companyInfo.relationshipId,
          userId: (props.userInfo || {}).userId,
          pageNumber: 1,
          pageSize: 25
        };
        props.fetchPurchaseInvoices(payload);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}