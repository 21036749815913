import React, { useEffect } from "react";
import './index.scss';
import { Spin } from "antd";
import { RazorpayLogo } from "../../../assets/images";
import config from "../../../config/environmentConfig";


const RazorpayPaymentsComp = (props) =>{

    const { razorpayPaymentOrderDetail={}, detailLoading, proformaDetails={} } = props;

    useEffect(() => {
        if (Object.keys(razorpayPaymentOrderDetail).length > 0 && razorpayPaymentOrderDetail.status === 'created') {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => initRazorpayPayment();
            script.onerror = () => console.log('Razorpay SDK failed to load.');
            document.body.appendChild(script);
        }
      }, [razorpayPaymentOrderDetail]);



    const getCustomerInfo = () =>{
        let primaryContact = (props.customerDetail?.boContactList || []).find(obj=>obj.primaryContactForInvoicing);
        if(!primaryContact){
            primaryContact = props.customerDetail?.boContactList ? props.customerDetail?.boContactList[0] : {};
        }
        return {
            name: (primaryContact?.firstName + " " + primaryContact?.lastName) || 'User',
            email: primaryContact?.emailAddress,
            contact: primaryContact?.cellPhone,
        }
    }


    const initRazorpayPayment = () => {
        const options = {
          key: config.RAZORPAY_KEYS ? config.RAZORPAY_KEYS[Number(props.relationshipId)] : '',
          amount: razorpayPaymentOrderDetail.amount || 0,
          currency: razorpayPaymentOrderDetail.currency || 'USD',
          name: 'Alpide',
          description: 'Payment Transaction',
          image: 'https://media.licdn.com/dms/image/D560BAQHuFFqO9bZp3g/company-logo_200_200/0/1713188151620/alpide_logo?e=2147483647&v=beta&t=g7BpomW7ewhmUTAsDT9QYbdTTK5eq-JmXOJY84KpN-Q',
          order_id: razorpayPaymentOrderDetail.id,
          handler: function (response) {
            // sample response 
            // {
            //     "razorpay_payment_id": "pay_ORqedMvyxPZFjp",
            //     "razorpay_order_id": "order_ORqeAzZzzAhysU",
            //     "razorpay_signature": "6ba076c27780a68e632ea5d2e583911fcd60a1a576a6274888f9cc6649d794d6"
            // }
            props.updateState({responsePaymentId: response.razorpay_payment_id})
            props.postPaymentProcess(response);
          },
          prefill: getCustomerInfo(),
          notes: {
            additionalInfo: 'No info',
          },
          theme: {
            color: '#0075ff',
          },
        };
    
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    return <div className="razorpay-btn-container">
        {detailLoading ? <div className="loader"><Spin size="large" spinning={detailLoading} tip={'Please Wait...'} /></div> 
        : 
        <div>
            <div>Pay with</div>
            <div
                className="razorpay_btn"
                onClick={
                    ()=>{
                        const {invoiceTotalAmount, currencyCode} = proformaDetails?.invoiceData || {};

                        if(!detailLoading){
                            props.createRazorpayPaymentOrder(
                                {
                                    relationshipId: Number(props.relationshipId || '0'),
                                    customerId: props.customerDetail?.customerId || 0,
                                    id: proformaDetails?.invoiceProformaId || 0,
                                    actionType: "proforma_to_invoice",
                                    amount: invoiceTotalAmount ? Number(invoiceTotalAmount.toFixed(2))*100 : 0,
                                    currency: currencyCode || props.customerDetail?.currencyCode,
                                    paymentGatewayId: proformaDetails?.invoiceData?.additionalInfoList?.length ? proformaDetails?.invoiceData?.additionalInfoList[0].paymentGatewayId : 0
                                }
                                // {
                                //     "relationshipId": 73,
                                //     "amount": 100,
                                //     "currency": "INR",
                                //     "customerId": 101,
                                //     "id": 120,
                                //     "actionType": "rt",
                                //     "paymentGatewayId": 1
                                // }
                            );
                        }
                    }
                }
            >
                <img src={RazorpayLogo} alt="razorpay" />
            </div>
        </div>
}
    </div>
}

export default RazorpayPaymentsComp;
