import axios from "axios";
import config from "../../../config/environmentConfig";
import {
  COMMON_ACTIONS,
  PURCHASE_ORDER_TYPE,
  INBOUND_DELIVERY_ACTION_LIST,
} from "../../../static/constants";
import {
  getCurrentDateForBackend,
  formatDateForBackend,
  showToasterMessage,
  getFinancialYear,
} from "../../../utils";
import {
  LEDGER_TYPE,
  ACCOUNTING_ENTRY_TYPE,
  TX_STATUS,
} from "../../../static/constants";
import {
  resetPaginatedData,
  getPurchaseOrdersCount,
} from "../purchaseOrder/Listing/action";
import {
  getInboundDeliveriesCount,
  resetDraftPaginatedData,
  getInboundDeliveriesDraftCount,
} from "./Listing/action";
import * as filter from "lodash.filter";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const createInboundDelivery = (payloadObject) => {
  const props = payloadObject.props;
  const poData = _getPOData(payloadObject);
  let formData = new FormData();
  if (props.fileList && props.fileList.length) {
    props.fileList.forEach((file) => {
      formData.append("files", file, file.name);
    });
  }
  formData.append("poData", JSON.stringify(poData, true));

  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
        // TODO need to review and remove
        dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER });
    return axios
      .post(
        `${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/inboundDelivery/createInboundDelivery`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        props.openIDDetails(res.data);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({
          type: INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_LIST_PAGE_RESET,
        });
        resetPaginatedData()(dispatch);
        getPurchaseOrdersCount({
          relationshipId: props.companyInfo.relationshipId,
        })(dispatch);
        getInboundDeliveriesCount({
          relationshipId: props.companyInfo.relationshipId,
        })(dispatch);
        resetDraftPaginatedData()(dispatch);
        getInboundDeliveriesDraftCount({
          relationshipId: props.companyInfo.relationshipId,
        })(dispatch);
     
        props.fetchPurchaseOrders({ relationshipId: props.companyInfo.relationshipId });
        showToasterMessage({
          messageType: "success",
          description: "GRN created successfully",
        });
        const payload2 = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 10,
        };

        props.fetchAllInboundDeliveries(payload2);
        // props.history.push('/admin/purchase-order-list');
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      }).finally(()=>{
            // TODO need to review and remove
        dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER_STOP });
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  };
};

const _getPOData = (payload) => {
  const props = payload.props;
  const state = payload.state;
  const gridObject = payload.gridObject;
  const isPlaceOfSupplySameAsRelationship =
    payload.isPlaceOfSupplySameAsRelationship;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const financialYear = getFinancialYear(companyInfo);
  const oldPayload = props.update ? props.oldPayload || {} : {};
  let poMaster = {
    ...oldPayload,
    version: props.update ? props.version : 0,
    inboundDeliveryDraftId: props.inboundDeliveryDraftId || 0,
    supplierId: props.supplier && props.supplier.id,
    customerInquiryNumber: props.customerInquiryNumber,
    customerPONumber: props.customerPONumber,
    customerRFQNumber: props.customerRFQNumber,
    supplierPONumber: props.supplierPONumber,
    fyStartDate:
      financialYear.fyStartDate &&
      getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate:
      financialYear.fyEndDate &&
      getCurrentDateForBackend(financialYear.fyEndDate),
    projectName: props.projectName,
    rfqChildMasterId: props.rfqChildMasterId,
    projectMasterId: props.projectMasterId,
    projectNumber: props.projectNumber,
    totalPOAmount: state.total,
    txType: PURCHASE_ORDER_TYPE.STANDARD,
    supplierName: props.supplier && props.supplier.name,
    relationshipName: companyInfo && companyInfo.storeName,
    supplierGstNumber: props.supplier && props.supplier.gstNumber,
    supplierPanNumber: props.supplier && props.supplier.panNumber,
    placeOfSupply: props.placeOfSupply,
    status: TX_STATUS.COMPLETED,
    inboundDeliveryDate:
      props.inboundDeliveryDate &&
      formatDateForBackend(props.inboundDeliveryDate),
    poDueDate: props.poDueDate && formatDateForBackend(props.poDueDate),
    relationshipId: companyInfo.relationshipId,
    supplierPoNumber: props.supplierPo,
    reference: props.reference,
    remarksSupplier: props.supplierRemarks,
    remarksInternal: props.internalRemarks,
    paymentTermId:
      props.supplierPaymentTerm && props.supplierPaymentTerm.paymentTermId,
    paymentTermName:
      props.supplierPaymentTerm && props.supplierPaymentTerm.termName,
    paymentTermDays: props.paymentTermDays,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    footer: props.footer,
    totalDiscountGiven: state.totalDiscount,
    expenseId: props.expenseId,
    expenseName: props.expenseName,
    supplierIDCOATxList: props.supplierIDCOATxList || [],
    inboundDeliveryDetailsList: [],
    boLocationInboundDeliveryList: [],
    documentName: props.docName,
    isRFQCconversion: props.isRFQConversion ? 1 : 0,
    poMasterId: props.poMasterId,
    poNumber: props.poNumber,
    exchangeRate: props.exchangeRate,
    foreignCurrency: props.foreignCurrency,
    rfqMasterId: props.rfqMasterId,
    rfqNumber: props.rfqNumber,
    purchaseRequestNumber: props.purchaseRequestNumber,
    expectedDeliveryDate:
      props.expectedDeliveryDate &&
      formatDateForBackend(props.expectedDeliveryDate),
    shippingInstructions: props.shippingInstructions,
    currencyCode: companyInfo.currencyCode,
    inboundDeliveryNumber: props.update ? props.inboundDeliveryNumber : 0,
    inboundDeliveryMasterId: props.update ? props.inboundDeliveryMasterId : "",
    conversionRefList: props.update ? props.conversionRefList : [],
    supplierQuoteNumber: props.supplierQuoteNumber,
    customerId: props.customerId,
    customerName: props.customerName,
  };

  // poMaster.supplierIDCOATxList.push({
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipName: companyInfo.storeName,
  //     fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
  //     fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
  //     supplierId: props.supplier && props.supplier.id,
  //     dateCreated: getCurrentDateForBackend(new Date()),
  //     createdBy: userInfo.userId,
  //     amount: state.subtotalWithoutDiscount,
  //     txDate: getCurrentDateForBackend(new Date()),
  //     txType: LEDGER_TYPE.TYPE_PURCHASE,
  //     accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
  //     ledgerAccountId: props.purchaseCreditorsLedgerId,
  //     supplierName: props.supplier && props.supplier.name
  // });

  // poMaster.supplierIDCOATxList.push({
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipName: companyInfo.storeName,
  //     fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
  //     fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
  //     supplierId: props.supplier && props.supplier.id,
  //     dateCreated: getCurrentDateForBackend(new Date()),
  //     createdBy: userInfo.userId,
  //     amount: state.subtotalWithoutDiscount,
  //     txDate: getCurrentDateForBackend(new Date()),
  //     txType: LEDGER_TYPE.TYPE_PURCHASE,
  //     accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
  //     ledgerAccountId: props.purchaseLedgerId,
  //     supplierName: props.supplier && props.supplier.name
  // });

  // if (state.rootDiscountAmount) {
  //     poMaster.supplierIDCOATxList.push({
  //         relationshipId: companyInfo.relationshipId,
  //         relationshipName: companyInfo.storeName,
  //         fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
  //         fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
  //         supplierId: props.supplier && props.supplier.id,
  //         dateCreated: getCurrentDateForBackend(new Date()),
  //         createdBy: userInfo.userId,
  //         amount: state.rootDiscountAmount,
  //         amountPercent: state.rootDiscountPercent,
  //         txDate: getCurrentDateForBackend(new Date()),
  //         txType: LEDGER_TYPE.TYPE_DISCOUNT,
  //         accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
  //         ledgerAccountId: props.purchaseDiscountLedgerId,
  //         supplierName: props.supplier && props.supplier.name
  //     });

  //     poMaster.supplierIDCOATxList.push({
  //         relationshipId: companyInfo.relationshipId,
  //         relationshipName: companyInfo.storeName,
  //         fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
  //         fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
  //         supplierId: props.supplier && props.supplier.id,
  //         dateCreated: getCurrentDateForBackend(new Date()),
  //         createdBy: userInfo.userId,
  //         amount: state.rootDiscountAmount,
  //         amountPercent: state.rootDiscountPercent,
  //         txDate: getCurrentDateForBackend(new Date()),
  //         txType: LEDGER_TYPE.TYPE_DISCOUNT,
  //         accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
  //         ledgerAccountId: props.discountCreditorLedgerId,
  //         supplierName: props.supplier && props.supplier.name
  //     });
  // }

  // if (state.expenseAmount) {
  //     poMaster.supplierIDCOATxList.push({
  //         relationshipId: companyInfo.relationshipId,
  //         relationshipName: companyInfo.storeName,
  //         fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
  //         fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
  //         supplierId: props.supplier && props.supplier.id,
  //         dateCreated: getCurrentDateForBackend(new Date()),
  //         createdBy: userInfo.userId,
  //         amount: state.expenseAmount,
  //         txDate: getCurrentDateForBackend(new Date()),
  //         txType: LEDGER_TYPE.TYPE_EXPENSE,
  //         accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
  //         ledgerAccountId: props.purchaseExpenseLedgerId,
  //         supplierName: props.supplier && props.supplier.name
  //     });

  //     poMaster.supplierIDCOATxList.push({
  //         relationshipId: companyInfo.relationshipId,
  //         relationshipName: companyInfo.storeName,
  //         fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
  //         fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
  //         supplierId: props.supplier && props.supplier.id,
  //         dateCreated: getCurrentDateForBackend(new Date()),
  //         createdBy: userInfo.userId,
  //         amount: state.expenseAmount,
  //         txDate: getCurrentDateForBackend(new Date()),
  //         txType: LEDGER_TYPE.TYPE_EXPENSE,
  //         accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
  //         ledgerAccountId: props.purchaseExpenseCreditorLedgerId,
  //         supplierName: props.supplier && props.supplier.name
  //     });
  //}

  if (
    props.relationshipBillingAddress &&
    props.relationshipBillingAddress.streetAddress1
  ) {
    if (!props.update) {
      delete props.relationshipBillingAddress.locationInboundDeliveryId;
      // props.relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
      // props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      // props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    poMaster.boLocationInboundDeliveryList.push(
      props.relationshipBillingAddress
    );
  }

  if (
    props.relationshipShippingAddress &&
    props.relationshipShippingAddress.streetAddress1
  ) {
    if (!props.update) {
      delete props.relationshipShippingAddress.locationInboundDeliveryId;
      // props.relationshipShippingAddress.dateCreated = getCurrentDateForBackend(new Date());
      // props.relationshipShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      // props.relationshipShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    poMaster.boLocationInboundDeliveryList.push(
      props.relationshipShippingAddress
    );
  }

  if (
    props.supplierBillingAddress &&
    props.supplierBillingAddress.streetAddress1
  ) {
    if (!props.update) {
      delete props.supplierBillingAddress.locationInboundDeliveryId;
      // props.supplierBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
      //props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      // props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    poMaster.boLocationInboundDeliveryList.push(props.supplierBillingAddress);
  }

  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    if (!data.itemId && !data.description && !data.qty && !data.rate) {
      return;
    }
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    // if(!itemId) return;
    let variantId =
      data.selectedProductValue && data.selectedProductValue.length === 2
        ? data.selectedProductValue[1]
        : 0;
    if (data.productObj && !data.productObj.hasVariant && !variantId) {
      variantId = data.productObj.inventoryItemVariantList
        ? data.productObj.inventoryItemVariantList[0].variantId
        : data.inventoryItemVariantId;
    }
    let product = {};
    let itemLevelCoaTxList = [];
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }
    let itemLevelAmount =
      ((data || {}).rate || 0) * ((data || {}).quantity || 0);

    if (data.discount) {
      const discountOnItem = itemLevelAmount
        ? data.discount * 0.01 * itemLevelAmount
        : 0;
      itemLevelAmount = itemLevelAmount - discountOnItem;
      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: props.purchaseDiscountLedgerId,
        supplierName: props.supplier && props.supplier.name,
      });

      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        ledgerAccountId: props.discountCreditorLedgerId,
        supplierName: props.supplier && props.supplier.name,
      });
    }

    if (data.tax) {
      if (companyInfo.countryName !== "India") {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          txName: data.tax,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.salesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName,
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          txName: data.tax,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.taxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName,
        });
      } else if (isPlaceOfSupplySameAsRelationship) {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.taxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.purchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.taxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.purchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });
      } else {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.taxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.purchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });
      }
    }
    poMaster.inboundDeliveryDetailsList.push({
      version: props.update ? data.version : 0,
      relationshipId: companyInfo && companyInfo.relationshipId,
      supplierId: props.supplier && props.supplier.id,
      qtyReceived: Number(data.quantity),
      qtyFromPO: Number(data.qtyFromPO),
      qtyBeforeUpdate: props.update ? Number(data.qtyBeforeUpdate) : 0,
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      basePrice: Number(data.baseRate),
      specialDiscount: Number(data.specialDiscount),
      txDate: formatDateForBackend(props.invoiceDate),
      invoiceDate: formatDateForBackend(props.invoiceDate),
      discountPercentage: Number(data.discount),
      itemName: product.itemName,
      itemId: product.itemId,
      anItemPurchasePrice: Number(data.rate),
      description: data.description,
      rowHeight: data.scrollHeight,
      hsnCode: data.hsnCode,
      inventoryItemVariantId: variantId,
      supplierIDCOATxList: itemLevelCoaTxList,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      poMasterId: props.poMasterId,
      poNumber: props.poNumber,
      parentDetailsId: data.parentDetailsId,
      inboundDeliveryDetailsId: data.inboundDeliveryDetailsId,
      inboundDeliveryMasterId: props.update ? props.inboundDeliveryMasterId : 0,
    });
  });

  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact };
    // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
    //  additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    //  additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.supplier) {
    additionalInfo.supplierId = props.supplier.id;
    //  additionalInfo.gstNumber = props.supplier.gstNumber;
    //  additionalInfo.panNumber = props.supplier.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
    delete additionalInfo.additionalInfoId;
  }

  if (
    props.selectedSupplierTaxIdentifications &&
    props.selectedSupplierTaxIdentifications.length
  ) {
    let selectedSupplierTaxIdentifications = filter(
      props.supplier.supplierTaxIdentificationsList,
      (taxObj) => {
        return (
          props.selectedSupplierTaxIdentifications.indexOf(
            taxObj.supplierTaxIdentificationId
          ) > -1
        );
      }
    );
    additionalInfo.taxIdentifications = JSON.stringify(
      selectedSupplierTaxIdentifications
    );
  }
  if (
    props.selectedRelationshipTaxIdentifications &&
    props.selectedRelationshipTaxIdentifications.length
  ) {
    let selectedRelTaxIdentifications = filter(
      props.allRelationshipTaxIdentificatins,
      (taxObj) => {
        return (
          props.selectedRelationshipTaxIdentifications.indexOf(
            taxObj.relationshipTaxIdentificationId
          ) > -1
        );
      }
    );
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(
      selectedRelTaxIdentifications
    );
  }
  if (!props.update) delete additionalInfo.additionalInfoId;
  poMaster.deliveryAdditionalInfoList = [additionalInfo];

  return poMaster;
};

export const fetchIDDataForPo = (payload) => {
  return (dispatch) => {
    let url = `${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseOrder/getPODataForInboundDelivery?poMasterId=${payload.poMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`;
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: INBOUND_DELIVERY_ACTION_LIST.ID_PO_DATA,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = INBOUND_DELIVERY_ACTION_LIST.ID_PO_DATA;
        }
      });
  };
};

export const resetIDPoData = () => {
  return (dispatch) => {
    dispatch({ type: INBOUND_DELIVERY_ACTION_LIST.RESET_ID_PO_DATA });
  };
};

export const getNextTxNumber = (payload) => {
  let date = payload.date
    ? getCurrentDateForBackend(payload.date)
    : getCurrentDateForBackend(new Date());
  return (dispatch) => {
    dispatch({ type: INBOUND_DELIVERY_ACTION_LIST.NEXT_ID_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });

    return axios
      .get(
        `${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/inboundDelivery/getNextInboundDeliveryNumber?date=${date}&relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: INBOUND_DELIVERY_ACTION_LIST.NEXT_ID_NUMBER,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = INBOUND_DELIVERY_ACTION_LIST.NEXT_ID_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  };
};
export const createInboundDeliveryDraft = (payloadObject, propsList) => {
  const props = payloadObject.props;
  const inboundDeliveryData = _getPOData(payloadObject);
  return (dispatch) => {
    const data = {
      inboundDeliveryDraftId: props.inboundDeliveryDraftId || 0,
      inboundDeliveryData: JSON.stringify(inboundDeliveryData),
      relationshipId: inboundDeliveryData.relationshipId,
      supplierId: inboundDeliveryData.supplierId,
    };

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/inboundDelivery/saveOrUpdateDraft`,
        data
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "GRN Delivery saved in draft",
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.history.push({
          pathname: "/admin/inbound-delivery-list",
          state: { activeTab: "Draft" },
        });
        const payload2 = {
          relationshipId: propsList.companyInfo.relationshipId,
          pageNumber: (propsList.pageNumber || 1) - 1,
          pageSize: propsList.pageSize || 10,
        };

        props.fetchInboundDeliveriesDrafts(payload2);
        props.getInboundDeliveriesDraftCount(payload2)
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchAllPOForInbound = (payload) => {
  return (dispatch) => {
    let url = `${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseOrder/getPOForInboundDelivery?relationshipId=${payload.relationshipId}&pageNumber=0&pageSize=100`;
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_PO_LIST,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          //err.actionToCall = INBOUND_DELIVERY_ACTION_LIST.ID_PO_DATA;
        }
      });
  };
};
