import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Menu, Skeleton } from "antd";
import { CONSTANTS,ICONS } from "../../../../static/constants";
import { getMomentDateForUIReadOnly} from "../../../../utils";
import DropdownAction from "antd/lib/dropdown";


const Organization = (props) => {
  const { pageNumber,pageSize } = props;
//   sectionList[pageNumber].sort((a, b)=> a.dateCreated < b.dateCreated ? 1 : -1);

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  // const breadCrumbList = [
  //   {
  //     name:  <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />
  //   },
  //   {
  //     name: getUserDefinedName('sidebar.menuItem.class',props),
  //   },
  //   {
  //     name: getUserDefinedName('sidebar.menuItem.section',props),
  //   }
  // ];
  // const showTotal = (total) => {
  //   return (
  //     props.intl.formatMessage({
  //       id: "pagination.text.total",
  //       defaultMessage: "",
  //     }) +
  //     ` ${total || 0} ` +
  //     props.intl.formatMessage({
  //       id: "pagination.text.items",
  //       defaultMessage: "",
  //     })
  //   );
  // };

  // const loadPageData = (pageNumber, pageSize) => {
  //   const payload = {
  //     companyInfo,
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
  //     pageNumber: pageNumber,
  //     pageSize: pageSize,
  //   };
  //   fetchPaginationDataIfNeeded("getSection", "sectionList", props, payload);
  // };

  // const handlePageSizeChange = (pageNumber, pageSize, props) => {
  //   const payload = {
  //     companyInfo,
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
  //     pageNumber: pageNumber,
  //     pageSize: pageSize,
  //   };
  //   props.resetPaginatedData();
  //   props.getSection(payload);
  //   props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  // };


  // const sortColumn = (e) => {
  //   sortTable(document.getElementById('section-table'), e.target.cellIndex, 1 - dir);
  //   updateState({ dir: 1 - dir })
  // }

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="edit" onClick={()=>{
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

          <Menu.Item key="2" onClick={()=>{
            return
          }
          }>
            <i className={ICONS.DELETE}/>
            Delete
          </Menu.Item>
        </Menu >
    )
  };

  return (
    <Fragment>
       {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
          {getUserDefinedName('sidebar.menuItem.class',props)}
          </div>
         
        </div>
        <div className="top-view-right">
          <button className="create-action-btn" onClick={()=>{
            newSection();
          }}>
              <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div>  */}
      <div className="view-container">
        
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              Organization
            </div>

          </div> 
          <div className="right-actions">
            <button 
              style={{backgroundColor:"#1cb961",color:"#ffffff",fontSize:"14px"}}
              onClick={()=>{
              }}
            >
              <i className={`${ICONS.ADD} mr5`} /> <FormattedMessage id="add" defaultMessage="" />
            </button>
          </div>
        </div>

        <Skeleton loading={props.isLoading}>
          <div className="table-container">
            <table id="section-table">
              <thead>
                <tr>
                  <th>{'S.No.'}</th>
                  <th>Type </th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Contact</th>
                  <th>Date Created</th>
                  <th>Tax Id</th>
                  <th className="flex justify-center">Action</th>


                  {/* <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.section.name',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.section.code',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.class.name',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}><FormattedMessage id="schoolManagement.school.dateCreated" defaultMessage="" /> <i class="fi fi-rr-sort-alt"></i></th>
                  <th><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> */}
                </tr>
              </thead>

              <tbody>
                {
                //   ().map((e, i) => {
                    ( []).map((e, i) => {

                    return <tr key={i}>
                      
                      <td>{(pageNumber-1)*pageSize+(i+1)}</td>
                      <td>{e.sectionName}</td>
                      <td>{e.sectionCode}</td>
                      <td>{e.className}</td>
                      <td>
                          {(e.dateCreated && getMomentDateForUIReadOnly({ date: e.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY }))}
                      </td>
                      <td style={{paddingLeft: '50px'}}> 
                        <div className="action-icon">
                          <DropdownAction overlay={actionMenu(e)} trigger={['click']}>
                            <i className={ICONS.MORE} />
                          </DropdownAction>
                        </div>
                      </td>
                    </tr>
                  })
                }
              </tbody>

            </table>
          </div>
        </Skeleton>
        {/* </Skeleton> */}
        {/* <div className="footer">
          <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={sectionCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </div> */}
        </div>
    </Fragment>
  );
};

export default injectIntl(Organization);
