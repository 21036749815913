import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SQ_ACTION_LIST, PURCHASE_ORDER_ACTION_LIST, LEDGER_TYPE, ACCOUNTING_ENTRY_TYPE, TX_STATUS } from '../../../../static/constants';
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils'
import { resetPaginatedData, getSalesQuoteCount } from '../Listing/action';
import * as filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createSalesQuote = (payloadObject) => {
  const props = payloadObject.props;
  const salesQuotation = _getQuoteData(payloadObject);
  let formData = new FormData();
  if (props.fileList && props.fileList.length) {
    props.fileList.forEach((file) => {
      formData.append('files', file, file.name);
    })
  }
  formData.append('salesQuotation', JSON.stringify(salesQuotation, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesQuotation/saveOrUpdateSalesQuotation`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        props.openSQDetails(res.data);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        resetPaginatedData()(dispatch);
        getSalesQuoteCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Sales quote created successfully' })
        props.history.push('/admin/sq-list');
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
  }
}

const _getQuoteData = (payload) => {
  const props = payload.props;
  const state = payload.state;
  const gridObject = payload.gridObject;
  const isPlaceOfSupplySameAsRelationship = payload.isPlaceOfSupplySameAsRelationship;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const financialYear = getFinancialYear(companyInfo);
  const oldPayload = props.update ? props.oldPayload || {} : {};
  let quoteMaster = {
    ...oldPayload,
    version: props.update ? props.version : 0,
    quoatationDraftId: props.quoatationDraftId || 0,
    salesQuotationMasterId: props.isUpdate ? props.salesQuotationMasterId : 0,
    isInquiryConversion: (props.isInquiryConversion && props.isUpdate) ? 1: 0,
    customerId: props.customer && props.customer.customerId,
    foreignCurrency: props.currencyCode + " ",
    foreignCurrencyIcon: props.currencyIcon,
    isMultiCurrency: 1,
    exchangeRate: props.exchangeRate,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    salesQuoteTotalAmount: Number(state.total),
    subTotal: Number(state.subtotalWithoutDiscount),
    customerName: props.customer && props.customer.companyName,
    relationshipName: companyInfo && companyInfo.storeName,
    customerGstNumber: props.customer && props.customer.gstNumber,
    customerPanNumber: props.customer && props.customer.panNumber,
    status: TX_STATUS.OPEN,
    orderPriority: props.priority,
    salesQuoteDate: props.quoteDate && formatDateForBackend(props.quoteDate),
    salesQuoteExpiryDate: props.quoteExpiryDate && formatDateForBackend(props.quoteExpiryDate),
    relationshipId: companyInfo.relationshipId,
    rfqNumber: props.rfqNumber,
    rfqMasterId: props.rfqMasterId,
    isRFQCconversion: props.isRFQConversion ? 1 : 0,
    rfqDate: props.rfqDate && formatDateForBackend(props.rfqDate),
    rfqExpirationDate: props.rfqExpirationDate && formatDateForBackend(props.rfqExpirationDate),
    customerRfq: props.customerRfq,
    referenceNumber: props.customerRef,
    remarksCustomer: props.customerRemarks,
    remarksInternal: props.internalRemarks,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    footer: props.footer,
    totalDiscountGiven: Number(state.totalDiscount || 0),
    customerSalesQuotationCOATxList: [],
    customerSalesQuotationDetailsList: [],
    boLocationSQList: [],
    documentName: props.docName,
    salesPersonId: props.salesPersonId,
    salesPersonFullName: props.salesPersonName,
    inctermId: props.inctermId, 
    incotermName: props.incotermName,
    deliveryMethod: props.deliveryMethod,
    projectName: props.projectName,
    projectNumber: props.projectNumber, 
    projectMasterId: props.projectMasterId,
  };


  if (props.relationshipBillingAddress && (props.relationshipBillingAddress.locationSQId || props.relationshipBillingAddress.locationId)) {
    let relationshipBillingAddress = props.relationshipBillingAddress;
    relationshipBillingAddress.salesQuotationMasterId = props.salesQuotationMasterId || 0
    relationshipBillingAddress.locationSQId = props.update ? relationshipBillingAddress.locationSQId : 0;
    if (props.update) {
     // relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
     // relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
      //relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } 
    quoteMaster.boLocationSQList.push(relationshipBillingAddress);
  }

  if (props.customerBillingAddress && (props.customerBillingAddress.locationSQId || props.customerBillingAddress.locationId)) {
    let customerBillingAddress = props.customerBillingAddress;
    customerBillingAddress.salesQuotationMasterId = props.salesQuotationMasterId || 0;
    customerBillingAddress.locationSQId = props.update ? customerBillingAddress.locationSQId : 0;
    if (props.update) {
     // customerBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
     // customerBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
     // customerBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    quoteMaster.boLocationSQList.push(customerBillingAddress);
  }

  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    if(!data.itemId && !data.description && !data.qty && !data.rate){
      return
    }
    // if(!itemId) return;
    let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
    if(data.productObj && !data.productObj.hasVariant && !variantId){
      variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId: data.inventoryItemVariantId;
    }
    
    let product = {};
    let variant = {};
    let itemLevelCoaTxList = [];
    if (itemId) {
      product = data.productObj || {};
    }
    if (variantId) {
      variant = data.variant || {};
    }

    let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);

    if (data.discount) {
      const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
      itemLevelAmount = itemLevelAmount - discountOnItem;
      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        exchangeRate: props.exchangeRate,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        customerName: props.customer && props.customer.companyName
      });

      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        exchangeRate: props.exchangeRate,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        customerName: props.customer && props.customer.companyName
      })
    }

    if (data.tax) {
      if (companyInfo.countryName !== 'India') {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_VAT,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.salesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_VAT,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.taxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });
      }
      else if (isPlaceOfSupplySameAsRelationship) {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          exchangeRate: props.exchangeRate,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          customerName: props.customer && props.customer.companyName
        });


        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          customerName: props.customer && props.customer.companyName
        });

      } else {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          customerName: props.customer && props.customer.companyName
        });
      }
    }

    quoteMaster.customerSalesQuotationDetailsList.push({
      parentDetailsId: data.parentDetailsId,
      version: props.update ? data.version : 0,
      salesQuotationDetailsId: data.salesQuotationDetailsId || 0,
      salesQuotationMasterId: data.salesQuotationMasterId || 0,
      relationshipId: companyInfo && companyInfo.relationshipId,
      customerId: props.customer && props.customer.customerId,
      quantity: Number(data.qty),
      qtyBeforeUpdate: Number(data.qtyBeforeUpdate || 0),
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      basePrice: Number(data.baseRate || 0),
      specialDiscount: Number(data.specialDiscount || 0),
      txDate: formatDateForBackend(props.quoteDate),
      discountPercentage: Number(data.discount || 0),
      itemName: product.itemName,
      itemId: product.itemId,
      anItemSalePrice: Number(data.rate || 0),
      rowHeight: data.scrollHeight,
      description: data.description,
      hsnCode: data.hsnCode,
      inventoryItemVariantId: variantId,
      customerSalesQuotationCOATxList: itemLevelCoaTxList,
      warehouse: data.comment,
      stockNumber: data.stockNumber,
      partNumber: data.partNumber,
      origin: data.origin,
      hsCode: data.hsCode,
      materialNumber: data.materialNumber,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3
    });
  });

  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact }
    // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
    // additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    // additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.customer) {
    additionalInfo.customerId = props.customer && props.customer.customerId;
    // additionalInfo.gstNumber = props.customer.gstNumber;
    // additionalInfo.panNumber = props.customer.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
    additionalInfo.additionalInfoId = props.isRFQConversion ? 0 : additionalInfo.additionalInfoId;
  }

  if (props.selectedCustomerTaxIdentifications && props.selectedCustomerTaxIdentifications.length) {
    let selectedCustomerTaxIdentifications = filter(props.customer.customerTaxIdentificationsList, (taxObj) => {
      return props.selectedCustomerTaxIdentifications.indexOf(taxObj.customerTaxIdentificationId) > -1;
    });
    additionalInfo.taxIdentifications = JSON.stringify(selectedCustomerTaxIdentifications);
  }

  if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
    let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
      return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
    });
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
  }

  quoteMaster.additionalInfoList = [additionalInfo];

  return quoteMaster;
}


export const fetchRfqData = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/rfqRest/getRFQsByRFQId?rfqMasterId=${payload.rfqMasterId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.RFQ_PO_DATA, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.RFQ_PO_DATA;
        }
      })
  }
}



export const fetchSQDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesQuotation/getSalesQuotationDataBySalesQuotationMasterId?salesQuotationMasterId=${payload.salesQuotationMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SQ_ACTION_LIST.SQ_DETAIL, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SQ_ACTION_LIST.SQ_DETAIL;
        }
      })
  }
}

export const resetSQDetails = () => {
  return dispatch => {
    dispatch({ type: SQ_ACTION_LIST.RESET_SQ_DATA });
  }
}

export const resetRfqPoData = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_ORDER_ACTION_LIST.RESET_RFQ_PO_DATA });
  }
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: SQ_ACTION_LIST.NEXT_SQ_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesQuotation/getNextQuotationNumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SQ_ACTION_LIST.NEXT_SQ_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SQ_ACTION_LIST.NEXT_SQ_NUMBER;
        }
      })
  }
}


export const createSalesQuoteDraft = (payloadObject) => {
  const props = payloadObject.props;
  return dispatch => {
    const salesQuotationeData = _getQuoteData(payloadObject);
    const data = {
      quoatationDraftId: props.quoatationDraftId || 0,
      salesQuotationeData: JSON.stringify(salesQuotationeData),
      relationshipId: salesQuotationeData.relationshipId,
      customerId: salesQuotationeData.customerId
    }

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesQuotation/saveOrUpdateDraft`, data)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Sales Quote saved in draft' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.history.push({
          pathname: '/admin/sq-list',
          state: { activeTab: 'Draft' }
        })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}