import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
//import moment from 'moment-timezone';
//import * as find from 'lodash.find';
// import { CustomAgGrid } from '../../../general/CustomAgGrid';
import { ICONS, MODAL_TYPE,MODULE_CODED_VALUES } from "../../../../static/constants";
// import checkIcon from "../../../../assets/images/check.png";
// import crossIcon from "../../../../assets/images/cross.png";
import { Menu, Dropdown, Empty, Skeleton } from "antd";
import { MoreOutlined, DeleteOutlined } from "@ant-design/icons";
import { getPermissionData } from "../../../../utils";

class TaxIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }
  state = {
    buttonDropDown: false,
  };

  toggleDropdown = () => {
    const { buttonDropDown } = this.state;
    this.setState({
      buttonDropDown: !buttonDropDown,
    });
  };

  render() {
    const { tax,permissions, supplierTaxIdentifications=[] } = this.props;
    const supplierObj = this.props.supplier || {};
    const primaryPerm = (permissions && permissions.primary) || [];

    const permissionData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_COMMUNICATION )
    // const agGridStyle = {
    //   height: '100%',
    //   width: '100%'
    // };

    // const onFirstDataRendered = (params) => {
    //   params.api.sizeColumnsToFit();
    // }
    // const getRowHeight = (params) => {
    //   return 36;
    // }

    const self = this;
    const callback = function (payload) {
      self.props.fetchSupplierTaxIdentifications(payload);
      return;
    };
    const deleteTaxIdentifications = (payload) => {
      const modalBody = <FormattedMessage id="common.delete.confirmation" />;
      const modalData = {
        modalBody,
        handleSubmit: () => {
          this.props.deleteSupplierTaxIdentifications(
            {
              id: payload.supplierTaxIdentificationId,
              relationshipId: payload.relationshipId,
              supplierId: payload.supplierId,
            },
            callback
          );
          this.props.hideModal();
        },
      };
      this.props.showModal(modalData);
    };

   

    const handleNewTaxAddition = (tax) => {
      this.props.updateHeaderState({
        tax: {
          taxDrawer: true,
          formData: {
            isUpdate:tax? true:false,
            ...tax,
            supplierId: this.props.supplierId,
          },
        },
      });
    };

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />
          <Menu.Item
            key="1"
            onClick={() => {
              handleNewTaxAddition(e);
            }}
          >
            <i className={ICONS.EDIT} ></i>
            Edit
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => {
              deleteTaxIdentifications(e);
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        </Menu>
      );
    };
    return (
      <>
       
        <div className="view-container">
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">Tax Identification </div>
              {supplierTaxIdentifications ? (
                <>
                  <div className="vertical-sep" />
                  <div>{supplierTaxIdentifications.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              <button
                className="create-action-btn"
                style={{
                  backgroundColor: "#1cb961",
                  color: "#ffffff",
                  fontSize: "14px",
                }}
                onClick={() => {
                  handleNewTaxAddition();
                }}
              >
                <i className={`${ICONS.ADD} mr5`} />
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>
            </div>
          </div>

          <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 6 }}>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>S.No</th>
                    <th>
                      <FormattedMessage
                        id="modal.common.taxIdentification.taxType"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="modal.common.taxIdentification.number"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="modal.common.taxIdentification.populateOnTransaction"
                        defaultMessage=""
                      />
                    </th>
                    <th style={{textAlign:'center'}}>
                      <FormattedMessage
                        id="customer.listing.header.action"
                        defaultMessage=""
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(supplierTaxIdentifications || []).length > 0
                    ? supplierTaxIdentifications.sort((a, b) => (a.supplierTaxIdentificationId < b.supplierTaxIdentificationId ? 1 : -1)).map((rowData, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{rowData.taxIdentificationType}</td>
                          <td>{rowData.taxIdentificationNumber}</td>
                          <td>
                            {rowData.populateOnTransaction ? "Yes" : "No"}
                          </td>
                          <td style={{textAlign:'center'}}>
                            <div className="action-icon">
                              <Dropdown
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE_P} />
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))
                    :  
                    <tr key="empty-data">
                      <td colSpan={"100%"} style={{borderBottom:"none"}}>
                        <Empty />
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </Skeleton>
        </div>
      </>
    );
  }
}

export default TaxIdentification;
