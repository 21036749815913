import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import {  Menu,Skeleton } from "antd";
import { MODAL_TYPE, ICONS } from "../../../../../static/constants";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import DropdownAction from "antd/lib/dropdown";
import { PlusOutlined } from "@ant-design/icons";

class TaxInformation extends Component {
  addItemConfirmationModal = (props, text, modalType, params) => {
    const { pushModalToStack, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );


    let addFunc;


    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        addFunc = props.addTaxIdentification;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }


    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.popModalFromStack();
        // setTimeout(() => {
        //   params.api.stopEditing();
        //   this.gridApi.getDisplayedRowAtIndex(
        //     params.node.rowIndex
        //   ).data.isToEdit = false;
        //   this.gridApi.refreshCells({ force: true });
        //   this.gridApi.getDisplayedRowAtIndex(
        //     params.node.rowIndex
        //   ).data.isToEdit = true;
        //   this.gridApi.refreshCells({ columns: ["action"], force: true });
        //   params.api.startEditingCell({
        //     rowIndex: params.node.rowIndex,
        //     colKey: params.column.getId(),
        //   });
        // }, 2000);
      },
    };
    pushModalToStack(modalData);
  };


  getModalTitle = (modalType) => {
    let title;


    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        title = (
          <FormattedMessage
            id="addItem.text.taxIdentification"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        title = (
          <FormattedMessage
            id="addItem.text.taxIdentification"
            defaultMessage=""
          />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };


  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { pushModalToStack } = props;
    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            // pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };


  deleteClickHandler = (props, modalType, payload, params) => {
    const { pushModalToStack, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );


    let deleteFunc;


    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        deleteFunc = props.deleteTaxIdentifications;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }
    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
        setTimeout(() => {
          params.api.stopEditing();
          this.gridApi.getDisplayedRowAtIndex(
            params.node.rowIndex
          ).data.isToEdit = false;
          this.gridApi.refreshCells({ force: true });
          this.gridApi.getDisplayedRowAtIndex(
            params.node.rowIndex
          ).data.isToEdit = true;
          this.gridApi.refreshCells({ columns: ["action"], force: true });
          params.api.startEditingCell({
            rowIndex: params.node.rowIndex,
            colKey: params.column.getId(),
          });
        }, 2000);
      },
    };
    pushModalToStack(modalData);
  };


  handleNewTaxIdentificationAddition = (props, payload, modalType) => {
    const formData = { ...payload };
    const { showModal, companyInfo } = props;
    formData.relationshipId = (companyInfo || {})?.relationshipId || 0;
    formData.callback = function (taxIdentification) {
      if (props.customer && props.customer.customerTaxIdentificationsList) {
        let list = props.customer.customerTaxIdentificationsList || [];
        list.push(taxIdentification);
        props.updateState({
          customer: { ...props.customer, customerTaxIdentificationsList: list },
        });
      }
    };
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: {formData},
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        
        props.popModalFromStack(data);
      },
    };
    this.props.pushModalToStack(data);
  };


  render() {

    const actionMenu = (param) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Item
            key="1"
            onClick={() => {
              this.handleNewTaxIdentificationAddition(
                this.props,
                param,
                MODAL_TYPE.NEW_TAX_IDENTIFICATION
              );
            }}
          >
            <i className={ICONS.EDIT} /> Edit
          </Menu.Item>


          <Menu.Item
            key="2"
            value="delete"
            onClick={() => {
              const modalData = {
                modalBody: "Are you sure you want to delete?",
                handleSubmit: () => {
                  this.props.deleteRelationshipTaxIdentifications({
                    id: param.relationshipTaxIdentificationId,
                    relationshipId: (this.props.companyInfo || {})
                      .relationshipId,
                  });
                },
              };
              this.props.pushModalToStack(modalData);
            }}
          >
            <i className={`${ICONS.DELETE} mr5`} /> Delete
          </Menu.Item>
        </Menu>
      );
    };


    // const handleNewTaxIdentificationAddition = (props, payload, modalType) => {
    //   const formData = payload.formData;
    //   const { companyInfo } = props;
      
    //   const data = {
    //     title: this.getModalTitle(payload, modalType),
    //     formData,
    //     hideFooter: true,
    //     modalData: {
    //       modalType: modalType,
    //       data: payload,
    //     },
    //   //   handleSubmit: (formData = {}) => {

    //   // //     data.formData.submittedOnce = true;
    //   // //   },
    //   };
    //   props.pushModalToStack(data);
    // };




    // const columnDefs = [
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="modal.settings.companyInfo.taxInfo.taxType"
    //             defaultMessage=""
    //           />{" "}
    //         </div>
    //       );
    //     },
    //     resizable: true,
    //     field: "taxIdentificationType",
    //     editable: true,
    //     sortable: true,
    //     width: 300,
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.allTaxIdentificatinType,
    //         optionKeyName: "taxIdentificationId",
    //         valueKeyName: "type",
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(
    //             this.props,
    //             MODAL_TYPE.TAX_IDENTIFICATION,
    //             payload
    //           );
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             textBoxValue: payload.textEntered,
    //             submittedOnce: false,
    //           };
    //           this.handleNewItemAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.TAX_IDENTIFICATION,
    //             obj
    //           );
    //         },
    //         onSelect: (selectedValue, option) => {
    //           obj.node.data.taxIdentificationType = selectedValue;
    //         },
    //       };
    //     },
    //     cellClass: "variant-column",
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="modal.settings.companyInfo.taxInfo.number"
    //             defaultMessage=""
    //           />{" "}
    //         </div>
    //       );
    //     },
    //     field: "taxIdentificationNumber",
    //     editable: true,
    //     sortable: true,
    //     resizable: true,
    //     cellEditor: "customTextEditor",
    //     width: 310,
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           obj.node.data.taxIdentificationNumber = value;
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="modal.settings.companyInfo.taxInfo.populateOnTransaction"
    //             defaultMessage=""
    //           />
    //           <Tooltip
    //             placement="top"
    //             title={
    //               <FormattedMessage
    //                 id="modal.settings.companyInfo.taxInfo.populateOnTransaction.tooltip"
    //                 defaultMessage=""
    //               />
    //             }
    //             trigger="click"
    //           >
    //             <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //     width: 210,
    //     field: "populateOnTransaction",
    //     resizable: true,
    //     editable: true,
    //     cellRendererFramework: function (link) {
    //       return <div>{link.value ? "Yes" : "No"}</div>;
    //     },
    //     cellEditor: "customCheckboxEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           obj.node.data.populateOnTransaction = value ? 1 : 0;
    //         },
    //       };
    //     },
    //   },
    //   {
    //     field: "action",
    //     pinned: "right",
    //     width: 115,
    //     cellRendererFramework: (params) => (
    //       <div>
    //         {params.data.origination !== "System" ? (
    //           <div>
    //             <div>
    //               {params.node.data.isToEdit && (
    //                 <div className="table-cell-action">
    //                   <Link
    //                     onClick={() => {
    //                       let data =
    //                         this.gridApi.getDisplayedRowAtIndex(
    //                           params.node.rowIndex
    //                         ).data || {};
    //                       if (
    //                         data.taxIdentificationType &&
    //                         data.taxIdentificationNumber
    //                       ) {
    //                         data.relationshipId = (
    //                           this.props.companyInfo || {}
    //                         ).relationshipId;
    //                         this.gridApi.getDisplayedRowAtIndex(
    //                           params.node.rowIndex
    //                         ).data.isToEdit = false;
    //                         this.props.addRelationshipTaxIdentification(data);
    //                       } else {
    //                         showToasterMessage({
    //                           description: "Tax Type and Number is required",
    //                         });
    //                       }
    //                       this.gridApi.refreshCells({ force: true });
    //                     }}
    //                   >
    //                     <i className="fa fa-save" />
    //                   </Link>
    //                   <Link
    //                     onClick={() => {
    //                       params.api.stopEditing(true);
    //                       this.gridApi.getDisplayedRowAtIndex(
    //                         params.node.rowIndex
    //                       ).data.isToEdit = false;
    //                       this.gridApi.refreshCells({ force: true });
    //                     }}
    //                   >
    //                     <i className="fa fa-ban" />
    //                   </Link>
    //                 </div>
    //               )}


    //               {!params.node.data.isToEdit && (
    //                 <div className="table-cell-action">
    //                   <Link
    //                     onClick={() => {
    //                       this.gridApi.forEachNode((rowNode, index) => {
    //                         this.gridApi.getDisplayedRowAtIndex(
    //                           index
    //                         ).data.isToEdit = false;
    //                       });
    //                       this.gridApi.getDisplayedRowAtIndex(
    //                         params.node.rowIndex
    //                       ).data.isToEdit = true;
    //                       this.gridApi.refreshCells({
    //                         columns: ["action"],
    //                         force: true,
    //                       });
    //                       var startEditingParams = {
    //                         rowIndex: params.node.rowIndex,
    //                         colKey: params.column.getId(),
    //                       };
    //                       params.api.startEditingCell(startEditingParams);
    //                     }}
    //                   >
    //                     <i className="fa fa-edit" />
    //                   </Link>
    //                   <Link
    //                     onClick={() => {
    //                       const modalData = {
    //                         modalBody: "Are you sure you want to delete?",
    //                         handleSubmit: () => {
    //                           this.props.deleteRelationshipTaxIdentifications({
    //                             id: params.node.data
    //                               .relationshipTaxIdentificationId,
    //                             relationshipId: (this.props.companyInfo || {})
    //                               .relationshipId,
    //                           });
    //                           this.gridApi.updateRowData({
    //                             remove: [
    //                               this.gridApi.getDisplayedRowAtIndex(
    //                                 params.node.rowIndex
    //                               ).data,
    //                             ],
    //                           });
    //                         },
    //                       };
    //                       this.props.pushModalToStack(modalData);
    //                     }}
    //                   >
    //                     <i className="fa fa-remove" />
    //                   </Link>
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //         ) : (
    //           <div></div>
    //         )}
    //       </div>
    //     ),
    //   },
    // ];


    const onGridReady = (params) => {
      this.gridApi = params.api;
      params.api.sizeColumnsToFit();
    };
    return (
      <Fragment>
        {/* <div className="ag-theme-balham" style={agGridStyle}>
          <CustomAgGrid
            columnDefs={columnDefs}
            rowData={this.props.allRelationshipTaxIdentificatins || []}
            rowDragManaged={true}
            disableFocusOnClickOutside={true}
            suppressClickEdit={true}
            noSingleClickEdit={true}
            domLayout={"autoHeight"}
            animateRows={true}
            onGridSizeChanged={onFirstDataRendered}
            getRowHeight={getRowHeight}
            editType={"fullRow"}
            onGridReady={onGridReady}
          ></CustomAgGrid>
        </div> */}
        <div className="view-top-bar">
          <div className="top-view-left"></div>
          <div className="top-view-right">
            <button
              className="create-action-btn"
              onClick={(param) => {
               this.handleNewTaxIdentificationAddition(
                  this.props,
                  param,
                  MODAL_TYPE.NEW_TAX_IDENTIFICATION
                );
              }}
            >
              <i className={ICONS.ADD} />
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>


        <div className="table-container pt20" style={{height: 'auto'}}>
        <Skeleton loading={this.props.listLoading} paragraph={{ rows: 8 }} active>
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="modal.settings.companyInfo.taxInfo.taxType"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="modal.settings.companyInfo.taxInfo.number"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="modal.settings.companyInfo.taxInfo.populateOnTransaction"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage id="actions.text" defaultMessage="" />
                </th>
              </tr>
            </thead>
            <tbody>
              {(this.props.allRelationshipTaxIdentificatins || []).map(
                (rowData, index) => {
                  return (
                    <tr key={index}>
                      <td>{rowData.taxIdentificationType}</td>
                      <td>{rowData.taxIdentificationNumber}</td>
                      <td>
                        {rowData.populateOnTransaction === 1 ? "Yes" : "No"}
                      </td>
                      <td>
                        <DropdownAction
                          overlay={actionMenu(rowData)}
                          trigger={["click"]}
                        >
                          <i className={ICONS.MORE_P} />
                        </DropdownAction>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </Skeleton>
        </div>


        {/* <Button
          className="add-new-record"
          onClick={() => {
            let newRowIndex = -1;
            this.gridApi.forEachNode((rowNode, index) => {
              newRowIndex = index;
              this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
            });
            this.gridApi.stopEditing();
            this.gridApi.updateRowData({ add: [{ isToEdit: true }] });
            this.gridApi.startEditingCell({
              rowIndex: newRowIndex + 1,
              colKey: "taxIdentificationType",
            });
            this.gridApi.refreshCells({ force: true });
          }}
        >
          <FormattedMessage
            id="modal.settings.companyInfo.taxInfo.addLine"
            defaultMessage=""
          />
        </Button> */}
      </Fragment>
    );
  }
}


export default TaxInformation;



