import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, DatePicker, Checkbox, Tooltip, Input, InputNumber, Skeleton, Select } from "antd";
import * as filter from "lodash.filter";
import * as find from "lodash.find";
import { Dropdown, DropdownRef } from "../../../general/Dropdown";
import { TextBox } from "../../../general/TextBox";
// import config from '../../../../config/environmentConfig';
import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
  ICONS,
  LIST_CONSTANTS,
  MODULE_CODED_VALUES,
} from "../../../../static/constants";
import { showToasterMessage, getMomentDateForUI } from "../../../../utils";
import { MultipleSelectDropdown } from "../../../general/MultipleSelectDropdown";
import { QuestionCircleTwoTone, EditOutlined } from "@ant-design/icons";


let companyInfo = {};
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class ShipmentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 0,
    };
    companyInfo = props.companyInfo;
  }

  handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    if (
      modalType !== MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS &&
      (!formData || !formData.customerId)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: this.props.intl.formatMessage({
          id: "missingCustomer.errorMsg",
          defaultMessage: "",
        }),
      });
    }
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    payload.formData.callback = function (address) {
      props.hideModal(data);
      if (
        address.locationType === "BillingAddress" ||
        address.locationType === "ShippingAddress"
      ) {
        props.fetchAddresses({
          customerId: address.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          locationType: address.locationType,
        });
      } else {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }

    };
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      keyboard:false,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact) => {
      props.hideModal(data);
      if (contact.customerId) {
        props.fetchAllContacts({
          customerId: contact.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const data = {
      title: (
        <div>
          <FormattedMessage id="addItem.text.contact" defaultMessage="" />
          <div style={{ "font-size": "12px" }}>
            {" "}
            {formData.customerName || ""}
          </div>
        </div>
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.CARRIER_NAME: {
        title = (
          <FormattedMessage id="addItem.text.carrierName" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.FREIGHT_TYPE: {
        title = (
          <FormattedMessage id="addItem.text.freightType" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{newTextObj.formData.customerName || ""} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.SHIPPING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.shippingAddress"
              defaultMessage=""
            />
            <h6>{newTextObj.formData.customerName || ""} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.companyInfo || {}).storeName} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        title = (
          <FormattedMessage id="addItem.text.documentName" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = this.getModalTitle(null, modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };

    switch (modalType) {
      case MODAL_TYPE.CARRIER_NAME: {
        addFunc = props.addCarrierName;
        break;
      }
      case MODAL_TYPE.FREIGHT_TYPE: {
        addFunc = props.addFreightType;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        addFunc = props.addDocumentName;
        payload.txType =
          CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SHIPMENT];
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;
    let callback = function (data) {
      if (
        modalType === MODAL_TYPE.CONTACT &&
        (props.customerDetail || {}).customerId
      ) {
        props.fetchAllContacts({
          customerId: props.customerDetail.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
      if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    switch (modalType) {
      case MODAL_TYPE.CARRIER_NAME: {
        deleteFunc = props.deleteCarrierName;
        break;
      }
      case MODAL_TYPE.FREIGHT_TYPE: {
        deleteFunc = props.deleteFreightType;
        break;
      }
      case MODAL_TYPE.CONTACT: {
        deleteFunc = props.deleteContact;
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS:
      case MODAL_TYPE.SHIPPING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        deleteFunc = props.deleteAddress;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        deleteFunc = props.deleteDocumentName;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          customerId: (props.customerDetail || {}).customerId,
          callback,
        });
        if (
          modalType === MODAL_TYPE.DOCUMENT_NAME &&
          payload.id === this.props.documentNameId
        ) {
          const selectedObj =
            find(this.props.documentList || [], {
              txType:
                CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SHIPMENT],
              isDefault: 1,
            }) || {};
          this.props.updateState({
            documentNameId: selectedObj.documentNameId,
            docName: selectedObj.docName,
          });
        }
      },
    };
    showModal(modalData);
  };

  render() {
    const { size } = this.state;
    const { intl } = this.props;
    const allShippingAddress = this.props.allShippingAddress;
    const allBillingAddress = this.props.allBillingAddress;

    //let shippingAddress = this.props.shippingAddress || {};
    // let billingAddress = this.props.billingAddress || {};
    // if (!this.props.shippingAddress) {
    //   shippingAddress = find(allShippingAddress, { isDefault: 1 });
    // }
    // if (!this.props.billingAddress) {
    //   billingAddress = find(allBillingAddress, { isDefault: 1 });
    // }
    // const packageList = filter(this.props.soUnShippedList, (element) => {
    //   return this.props.selectedPackages?.indexOf(element.packageNumber) !== -1;
    // });

    const { Option } = Select;

    const selectAfter = (
      <Select defaultValue="Ounces (oz)" onSelect={(val)=>{
        let weightVal = this.props.weightVal;
        if(!val){
          switch(val){
            case 'ounces': weightVal = val*1;
              break;
            case 'pounds': weightVal = val * 16;
              break;
            case 'kilograms': weightVal = val * 35.274;
              break;
            case 'grams': weightVal = val * 0.035274;
              break;
            default: weightVal = val*1;
          }
        }
        this.props.updateState({
          weight: weightVal,
          weightUnit: val,
        })

      }}>
        <Option value="ounces">Ounces (oz)</Option>
        <Option value="pounds">Pounds (lb)</Option>
        <Option value="kilograms">Kilograms (kg)</Option>
        <Option value="grams">Grams (g)</Option>
      </Select>
    );

    return (
      <Fragment>
        <Row>
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Organization Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.name"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="deatils-field-value">
                  {companyInfo.storeName}
                </div>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.adress"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.companyBillingAddress",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <span>
                  {!this.props.addressHide &&
                  this.props.relationshipBillingAddress ? (
                    <>
                      <div className="detail-address-value">
                        <div style={{ height: "96px", paddingTop: "10px" }}>
                          {getAddressInfoFragment(
                            this.props.relationshipBillingAddress || {},
                            {},
                            this.props.companyInfo,
                            this.props
                          )}
                        </div>
                        <div
                          onClick={() => {
                            this.props.updateState({
                              addressHide: true,
                            });
                          }}
                        >
                          <EditOutlined />
                        </div>
                      </div>
                    </>
                  ) : (
                    <Dropdown
                      style={{ width: "100%" }}
                      items={this.props.relationshipBillingAddresses || []}
                      value={
                        (this.props.relationshipBillingAddress || {})
                          .streetAddress1
                      }
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                      onSelect={(selectedValue, optionObj) => {
                        this.props.updateState({
                          relationshipBillingAddress: (
                            this.props.relationshipBillingAddresses || []
                          ).find((x) => +x.locationId === +optionObj.key),
                          addressHide: false,
                        });
                      }}
                    />
                  )}
                </span>
              </Col>
            </Row>
            </Skeleton>

            {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
            {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + companyInfo.logoAwsObjectKey + "?" + Date.now()} /> */}
          </Col>

          <Col span={1}></Col>

          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Customer Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesOrder.form.customer.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                {this.props.type === "new" || !this.props.update ? (
                  <Dropdown
                    style={{ width: "100%" }}
                    items={
                      (this.props.customers[this.props.pageNumber] || []).sort((a, b) => (a.companyName||'').localeCompare(b.companyName||''))
                     
                    }
                    valueKeyName="companyName"
                    optionKeyName="customerId"
                    value={(this.props.customerDetail || {} || {}).companyName}
                    onSearch={(searchedText) => {
                      this.props.fetchCustomers({
                        isCompact: true,
                        searchedText: searchedText,
                        pageNumber: 1,
                        hideSpinner: true,
                        pageSize: 100,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                      });
                    }}
                    placeholder={intl.formatMessage({
                      id: "customer.salesOrder.form.customer.placeholder",
                      defaultMessage: "",
                    })}
                    onSelect={(customerId, data) => {
                      this.props.resetAllContacts();
                      this.props.resetCustomerDetail();
                      if (this.props.soUnShippedList?.length) {
                        this.props.updateState({
                          selectedPackages: [],
                        });
                      }
                      this.props.updateState({
                        customerId,
                        customerName: data.name,
                        shippingAddress: null,
                        billingAddress: null,
                      });
                      this.props.fetchCustomerDetailsByCustomerId({
                        customerId,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                      });
                      this.props.fetchAddresses({
                        customerId,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                        locationType: CONSTANTS.SHIPPING_ADDRESS,
                      });
                      this.props.fetchAddresses({
                        customerId,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                        locationType: CONSTANTS.BILLING_ADDRESS,
                      });
                      this.props.fetchSoDataForShipment({
                        customerId,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                      });

                      this.props.fetchSoDataForShipmentData({
                        customerId,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                      });
                      this.props.fetchAllContacts({
                        customerId,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                      });
                    this.props.updateState({
                      boContactList:[]
                    })
                    }}
                  />
                ) : (
                  <span>
                    {(this.props.customerDetail || {}).companyName || ""}
                  </span>
                )}
              </Col>
              {this.props.customerName ||
              (this.props.customerDetail || {}).companyName ? (
                <>
                  {/* { this.props.type === 'new' ? 
                  <Dropdown
                    style={{ width: '90%' }}
                    items={this.props.customers[this.props.pageNumber || 1] || []}
                    valueKeyName='companyName'
                    optionKeyName='customerId'
                    value={(this.props.customerDetail || {} || {}).companyName}
                    onSearch={(searchedText) => {
                      this.props.fetchCustomers({
                        isCompact: true,
                        searchedText: searchedText,
                        pageNumber: 1,
                        hideSpinner: true,
                        pageSize: 100,
                        relationshipId: (this.props.companyInfo || {}).relationshipId
                      })
                    }}
                    placeholder={intl.formatMessage({
                      id: 'customer.salesOrder.form.customer.placeholder', defaultMessage: ''
                    })}
                    onSelect={
                      (customerId, data) => {
                        this.props.updateState({
                          customerId,
                          customerName: data.name,
                          shippingAddress: null,
                          billingAddress: null
                        });
                        this.props.fetchCustomerDetailsByCustomerId({
                          customerId,
                          relationshipId: (this.props.companyInfo || {}).relationshipId
                        });
                        this.props.fetchAddresses({customerId, relationshipId: (this.props.companyInfo || {}).relationshipId, locationType: CONSTANTS.SHIPPING_ADDRESS});
                        this.props.fetchAddresses({customerId, relationshipId: (this.props.companyInfo || {}).relationshipId, locationType: CONSTANTS.BILLING_ADDRESS});
                        this.props.fetchSoDataForShipment({
                          customerId,
                          relationshipId: (this.props.companyInfo || {}).relationshipId
                        });
                        this.props.fetchAllContacts({ customerId, relationshipId: (this.props.companyInfo || {}).relationshipId });
                      }
                    }
                  /> : <span>{ (this.props.customerDetail || {}).companyName || ''}</span>
                   } */}

                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage id="contact" defaultMessage="" />
                    </div>
                  </Col>
                  <Col span={24}>
                    <Dropdown
                      style={{ width: "100%" }}
                      value={
                        ((this.props.selectedContact || {}).firstName || "") +
                        " " +
                        ((this.props.selectedContact || {}).lastName || "")
                      }
                      optionKeyName="contactId"
                      items={this.props.boContactList}
                      valueKeyName="firstName"
                      lastNameKey="lastName"
                      isFavouriteKeyName="isPrimaryContact"
                      placeholder="Please select contact name"
                  moduleName={MODULE_CODED_VALUES.CUSTOMER_CONTACTS}

                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.CONTACT,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          firstName: payload.textEntered,
                          submittedOnce: false,
                          customerId: (this.props.customerDetail || {})
                            .customerId,
                          pageNumber: this.props.pageNumber || 1,
                          customerName:
                            (this.props.customerDetail || {}).companyName || "",
                        };
                        this.handleNewContactAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.CONTACT
                        );
                      }}
                      onClear={() => {
                        this.props.updateState({
                          selectedContact: undefined,
                        });
                      }}
                      onSelect={(contactId) => {
                        let contact =
                          find(this.props.boContactList || [], {
                            contactId: +contactId,
                          }) || {};
                        this.props.updateState({
                          selectedContact: contact,
                        });
                      }}
                    />
                  </Col>

                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage id="billTo" defaultMessage="" />
                    </div>
                  </Col>
                  <Col span={24}>
                    <Dropdown
                      style={{ width: "100%" }}
                      items={allBillingAddress || []}
                      value={(this.props.billingAddress || {}).streetAddress1}
                      placeholder="Billing Address"
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                  moduleName={MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS}
                      
                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.BILLING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.BILLING_ADDRESS,
                          customerId: (this.props.customerDetail || {})
                            .customerId,
                          pageNumber: this.props.pageNumber,
                          customerName:
                            (this.props.customerDetail || {}).companyName || "",
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.BILLING_ADDRESS
                        );
                      }}
                      onClear={() => {
                        this.props.updateState({
                          billingAddress: undefined,
                        });
                      }}
                      onSelect={(selectedValue, optionObj) => {
                        this.props.updateState({
                          billingAddress: (allBillingAddress || []).find(
                            (x) => +x.locationId === +optionObj.key
                          ),
                        });
                      }}
                    />
                  </Col>

                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage id="shipTo" defaultMessage="" />
                    </div>
                  </Col>
                  <Col span={24}>
                    <Dropdown
                      style={{ width: "100%" }}
                      items={allShippingAddress || []}
                      value={(this.props.shippingAddress || {}).streetAddress1}
                      placeholder="Shipping Address"
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                  moduleName={MODULE_CODED_VALUES.CUSTOMER_ADDRESS}
                      
                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.SHIPPING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.SHIPPING_ADDRESS,
                          customerId: (this.props.customerDetail || {})
                            .customerId,
                          pageNumber: this.props.pageNumber,
                          customerName:
                            (this.props.customerDetail || {}).companyName || "",
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.SHIPPING_ADDRESS
                        );
                      }}
                      onClear={() => {
                        this.props.updateState({
                          shippingAddress: undefined,
                        });
                      }}
                      onSelect={(selectedValue, optionObj) => {
                        this.props.updateState({
                          shippingAddress: (allShippingAddress || []).find(
                            (x) => +x.locationId === +optionObj.key
                          ),
                        });
                      }}
                    />
                  </Col>
                </>
              ) : (
                "Please select customer for details"
              )}
            </Row>
            </Skeleton>
          </Col>

          <Col span={1}></Col>

          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Shipment information
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="modal.txDetails.shipment.consignee"
                    defaultMessage=""
                  />

                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.consigneePerson",
                      defaultMessage: "",
                    })}
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <TextBox
                  type="text"
                  value={this.props.consigneeName}
                  onChange={(e) => {
                    this.props.updateState({
                      consigneeName: e.target.value,
                    });
                  }}
                  placeholder={intl.formatMessage({
                    id: "so.consignee",
                    defaultMessage: "",
                  })}
                />
              </Col>

              {this.props.customerName ||
              (this.props.customerDetail || {}).companyName ? (
                <>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="modal.txDetails.shipment.packageNumber"
                        defaultMessage=""
                      />
                    </div>
                  </Col>
                  <Col span={15}>
                    <MultipleSelectDropdown
                      style={{ width: "100%" }}
                      mode={"multiple"}
                      onDeSelect={(value) => {
                        let { selectedPackages } = this.props;
                        selectedPackages = filter(selectedPackages, (pck) => {
                          return pck !== value;
                        });
                        if(selectedPackages.length === 0){
                          this.props.updateState({ 
                            weight:null,
                            width:null,
                            length:null,
                            height:null
                           });
                        }
                        if(selectedPackages.length === 1){
                        let packagesObj = (this.props.soUnShippedList || []).find(x => x.packageNumber === selectedPackages[0]);

                          this.props.updateState({ 
                            weight:packagesObj.weight,
                            width:packagesObj.width,
                            length:packagesObj.length,
                            height:packagesObj.height
                           });
                        }
                        if (
                          this.props.shipmentDetailList &&
                          this.props.shipmentDetailList.length
                        ) {
                          const shipList = filter(
                            this.props.shipmentDetailList,
                            (e) => {
                              return value !== e.packageNumber;
                            }
                          );

                          return this.props.updateState({
                            selectedPackages,
                            shipmentDetailList: shipList,
                          });
                        }
                        this.props.updateState({ selectedPackages });
                      }}
                      items={this.props.soUnShippedList || []}
                      selectedValue={this.props.selectedPackages}
                      placeholder="Select"
                      valueKeyName="packageNumber"
                      dateKeyName1="packageDate"
                      dateKeyName2="salesOrderDate"
                      className="extra-width"
                      optionKeyName="packageNumber"
                      optionClassAddress="custom-address-dropdown"
                      addressLineKey1="salesOrderNumber"
                      onFocus={() => {
                        if (
                          !this.props.customerDetail ||
                          !this.props.customerDetail.companyName
                        ) {
                          return showToasterMessage({
                            messageType: "error",
                            description:
                              "Please select a Customer to see Package(s)",
                          });
                        }
                      }}
                      onSelect={(selectedValue) => {
                        const { selectedPackages } = this.props;
                       
                        let packagesObj = (this.props.soUnShippedList || []).find(x => x.packageNumber === selectedValue);
                        //const shipList = (this.props.shipmentDetailList || []).concat(packagesObj.customerSOPackageDetailsList);
                        selectedPackages.push(selectedValue);
                        if(selectedPackages.length === 1){
                          this.props.updateState({ 
                            weight:packagesObj.weight,
                            width:packagesObj.width,
                            length:packagesObj.length,
                            height:packagesObj.height
                           });
  
                          }else{
                            this.props.updateState({ 
                              weight:null,
                              width:null,
                              length:null,
                              height:null
                             });
                          }
                        this.props.updateState({ selectedPackages });
                      }}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}

              <Col span={1}></Col>
              <Col span={6}>
                <FormattedMessage id="showAllPackages.text" defaultMessage="" />
                <Tooltip
                  trigger="click"
                  placement="right"
                  title={this.props.intl.formatMessage({
                    id: "tooltip.checkedPackage",
                    defaultMessage: "",
                  })}
                >
                  <i className={`${ICONS.HELP} cursor-pointer`} />
                </Tooltip>
              </Col>
              <Col span={1}></Col>
              <Col span={1}>
                <Checkbox
                  checked={this.props.isToShowAllPackage}
                  onChange={(e) => {
                    this.props.updateState({
                      isToShowAllPackage: e.target.checked,
                    });
                    if (e.target.checked) {
                      this.props.fetchSoDataForShipment({
                        customerId: this.props.customerId,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                      });

                      this.props.fetchSoDataForShipmentData({
                        customerId: this.props.customerId,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                      })
                    } else {
                      this.props.fetchSoDataForShipment({
                        customerId: this.props.customerId,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                        soMasterId: this.props.soMasterId,
                      });

                      this.props.fetchSoDataForShipmentData({
                        customerId: this.props.customerId,
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                        soMasterId: this.props.soMasterId,
                      })
                    }
                  }}
                />
              </Col>

              <Col span={24} className="text-right">
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="modal.txDetails.shipment.referenceNumber"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <TextBox
                  type="text"
                  value={this.props.referenceNumber}
                  onChange={(e) => {
                    this.props.updateState({
                      referenceNumber: e.target.value,
                    });
                  }}
                  placeholder={intl.formatMessage({
                    id: "referenceNumber.text",
                    defaultMessage: "",
                  })}
                />
              </Col>

              <Col span={24} className="text-right">
                <div className="details-heading-subheading">
                  <FormattedMessage id="dimensions.new.text" />
                </div>
              </Col>
              <Col span={24}>
                <Input.Group compact>
                  <span className="shipment_dimension_unit">
                    <InputNumber
                      className="measure_input"
                      placeholder="Length"
                      value={this.props.length}
                      onChange={(num) => {
                        this.props.updateState({ length: num });
                      }}
                    />

                    {/* <i className="fi fi-br-cross"/> */}

                    <InputNumber
                      placeholder="Width"
                      className="measure_input"
                      value={this.props.width}
                      onChange={(num) => {
                        this.props.updateState({ width: num });
                      }}
                    />

                    {/* <i className="fi fi-br-cross"/> */}

                    <InputNumber
                      placeholder="Height"
                      className="measure_input"
                      value={this.props.height}
                      onChange={(num) => {
                        this.props.updateState({ height: num });
                      }}
                    />

                    <DropdownRef 
                      placeholder="Select" 
                      className="measure_unit_select"
                      value={this.props.dimensionUnit}
                      items={LIST_CONSTANTS.DIMENSION_UNITS}
                      valueKeyName="label"
                      optionKeyName="value"
                      onSelect={(unitVal)=>{
                        this.props.updateState({
                          dimensionUnit: unitVal,
                        });
                      }}
                    />
                  </span>
                </Input.Group>
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="package.packageDetail.weight"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
              <InputNumber
                  className="measure_input"
                  style={{width: '80%', maxWidth: 'calc(100% - 100px)', marginLeft: '-2px'}}
                  placeholder="Weight"
                  value={this.props.weight}
                  onChange={(e) => {
                     this.props.updateState({
                        weight:e,
                      });
                    
                  }}
                />
                <DropdownRef
                  className="measure_unit_select"
                  style={{width: '20%', minWidth: '100px'}}
                  placeholder="Select" 
                  value={this.props.weightUnit}
                  items={LIST_CONSTANTS.WEIGHT_UNITS}
                  valueKeyName="label"
                  optionKeyName="value"
                  onSelect={(unitVal)=>{
                    this.props.updateState({
                      weightUnit: unitVal,
                    });
                  }}
                />
              </Col>
            </Row>
            </Skeleton>
          </Col>

          <Col span={1}></Col>

          <Col span={6} className="order-value-info">
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Order Information
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="modal.txDetails.shipment.carrier"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={(this.props.carrierList || []).sort((a, b) => a.carrierName.localeCompare(b.carrierName))}
                  value={this.props.selectedCarrier}
                  placeholder="Select"
                  valueKeyName="carrierName"
                  optionKeyName="carrierId"
                 
                  
                  allowClear={true}
                  canAddNew={true}
                  onFocus={() => {
                    if (!(this.props.customerDetail || {} || {}).companyName) {
                      return showToasterMessage({
                        messageType: "error",
                        description: "Select a Customer to see Package",
                      });
                    }
                  }}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.CARRIER_NAME,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      // textBoxValue: payload.textEntered,
                      textBoxValue: "",
                      submittedOnce: false,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.CARRIER_NAME
                    );
                  }}
                  onSelect={(selectedValue, option) => {
                    this.props.updateState({
                      selectedCarrier: selectedValue,
                      carrierId: option.key,
                    });
                  }}
                  onClear={() => {
                    this.props.updateState({
                      selectedCarrier: null,
                      carrierId: null,
                    });
                  }}
                />
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="modal.txDetails.shipment.freightType"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%", marginLeft: 0 }}
                  items={(this.props.freightList || []).sort((a, b) => a.freightTypeName.localeCompare(b.freightTypeName))}
                  value={this.props.selectedFreighType}
                  placeholder="Select"
                  valueKeyName="freightTypeName"
                  optionKeyName="freightTypeId"
                  allowClear={true}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.FREIGHT_TYPE,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.FREIGHT_TYPE
                    );
                  }}
                  onSelect={(selectedValue, option) => {
                    this.props.updateState({
                      selectedFreighType: selectedValue,
                      freightTypeId: option.key,
                    });
                  }}
                  onClear={() => {
                    this.props.updateState({
                      selectedFreighType: null,
                      freightTypeId: null,
                    });
                  }}
                />
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="modal.txDetails.shipment.trackingNumber"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <TextBox
                  type="text"
                  value={this.props.trackingNumber}
                  onChange={(e) => {
                    this.props.updateState({
                      trackingNumber: e.target.value,
                    });
                  }}
                  placeholder={intl.formatMessage({
                    id: "so.trackingNumber",
                    defaultMessage: "",
                  })}
                />
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="modal.txDetails.shipment.shipmentDate"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  format={format}
                  allowClear={false}
                  //key={`${getMomentDateForUI({date: new Date(), format})}`}
                  defaultValue={getMomentDateForUI({
                    date: new Date(),
                    format,
                  })}
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      shipmentDate: selectedDate,
                    });
                  }}
                  size={size}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="modal.txDetails.shipment.expectedDelivery"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  // key={`${getMomentDateForUI({date: new Date(), format})}`}
                  defaultValue={getMomentDateForUI({
                    date: new Date(),
                    format,
                  })}
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      expectedDate: selectedDate,
                    });
                  }}
                  size={size}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesOrder.form.documentDropdown.label"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "documentTitle.tooltip",
                      defaultMessage: "",
                    })}
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24} className="documentTitleDropdown">
                <Dropdown
                  items={filter(this.props.documentList || [], (document) => {
                    return (
                      document.txType ===
                      CONSTANTS_TRANSACTIONS_KEY[
                        CONSTANTS_TRANSACTIONS.SHIPMENT
                      ]
                    );
                  })}
                  placeholder="Document name"
                  value={this.props.docName || ""}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.DOCUMENT_NAME,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.DOCUMENT_NAME
                    );
                  }}
                  onSelect={(selectedValue) => {
                    const selectedObj = find(this.props.documentList || [], {
                      documentNameId: Number(selectedValue),
                    });
                    this.props.updateState({
                      documentNameId: selectedObj.documentNameId,
                      docName: selectedObj.docName,
                    });
                  }}
                  // allowClear={true}
                  // onClear={() => {
                  //   this.props.updateState({
                  //     documentNameId: undefined,
                  //     docName: undefined
                  //   })
                  // }}
                  valueKeyName="docName"
                  optionKeyName="documentNameId"
                  isLockKeyName="isSystemGenerated"
                />
              </Col>
            </Row>
          </Skeleton>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const getAddressInfoFragment = (obj) => {
  if (!obj || !Object.keys(obj).length) {
    return "";
  }

  const fragmentObj = (
    <Fragment>
   <div className="billing-address first-caps">
    {obj.streetAddress1 || obj.streetAddress2 ? (    
        <div>
          {obj.streetAddress1} {obj.streetAddress2}{", "}
        </div>    
    ) : ""}
    {obj.cityName || obj.stateName || obj.zipCode ? (      
        <div>
          {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
        </div>      
    ) : ""}
    {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
  </div>
    </Fragment>
  );

  return fragmentObj;
};

export default injectIntl(ShipmentDetail);
