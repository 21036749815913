import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, FINANCIAL_YEAR_ACTIONS } from '../../../../../static/constants'
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchFinancialYears = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/financialYear/getFinancialYear?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: FINANCIAL_YEAR_ACTIONS.FINANCIAL_YEAR_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
  }
}

export const updateFinancialYear = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/financialYear/saveOrUpdate?relationshipId=${payload.relationshipId}`, payload)
        .then(res => {
          dispatch({ type: FINANCIAL_YEAR_ACTIONS.UPDATE_FINANCIAL_YEAR, data: res.data });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({ messageType: 'success', description: 'Update Successfully' });
          props.fetchFinancialYears(payload);
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        })
    }
}

export const deleteFY = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/financialYear/delete?relationshipId=${payload.relationshipId}`, payload)
        .then(res => {
          showToasterMessage({ messageType: 'success', description: "Data deleted successfully" })
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        })
    }
}