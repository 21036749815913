import React from "react";
import { Button, Col, Row, Skeleton, Radio } from 'antd';
import './style.scss';
import { CONSTANTS } from '../../../../../static/constants';
import { showToasterMessage, } from '../../../../../utils';
import * as find from 'lodash.find';
import moment from "moment-timezone";
import { Dropdown } from "../../../../general/Dropdown";
import { TextArea } from "../../../../general/TextAreaComponent";

const ShippingOrderInfo = (props) => {

    const { soDetail={}, shipmentLogisticsInfo={} } = props;
    const {txData={}} = props.data;

    const getRatesForCarriers = (orderId) =>{
        const {deliveyNotesData} = props;
        const relationshipAddress = find(deliveyNotesData.boLocationShipmentList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
        const shippingAddress = find(deliveyNotesData.boLocationShipmentList || [], { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};

        const payloadShipRocket = {
          relationshipId: props.companyInfo?.relationshipId || 0,
          pickup_postcode: relationshipAddress.zipCode,
          delivery_postcode: shippingAddress.zipCode,
          cod: 0,
          weight: deliveyNotesData.weight,
          length: deliveyNotesData.length,
          breadth: deliveyNotesData.width,
          height: deliveyNotesData.height,
          order_id: orderId,
        }

        props.getCarrierPricesShiprocket(payloadShipRocket);
    }

    const createShipmentOrderInShiprocket = ()=>{
        if(!props.selectedAddress){
            return showToasterMessage({ description: 'Please select pickup address' });
        }
        const billingAddress = props.deliveyNotesData?.boLocationShipmentList?.find(obj=>obj.locationType === CONSTANTS.BILLING_ADDRESS)
        const shippingAddress = props.deliveyNotesData?.boLocationShipmentList?.find(obj=>obj.locationType === CONSTANTS.SHIPPING_ADDRESS)
        const payload = {
            relationshipId: props.companyInfo?.relationshipId || 0,
            customerId: props.soDetail?.customerId || 0,
            logisticsInfoId: shipmentLogisticsInfo?.logisticsInfoId,
            shipmentMasterId: props.deliveyNotesData?.shipmentMasterId,
            order_id: shipmentLogisticsInfo.statusCode !== 5 ? `${props.companyInfo?.relationshipId}_${props.deliveyNotesData.shipmentMasterId}_${Date.now()}` : shipmentLogisticsInfo.orderRefId,
            order_date: moment().format(CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT),
            pickup_location: props.selectedAddress,
            comment: props.comments || '',
            billing_customer_name: txData.customerName,
            billing_last_name: "",
            billing_address: billingAddress?.streetAddress1,
            billing_address_2: billingAddress?.streetAddress2,
            billing_city: billingAddress?.cityName,
            billing_pincode: billingAddress?.zipCode,
            billing_state: billingAddress?.stateName,
            billing_country: billingAddress?.countryName,
            billing_email: soDetail?.additionalInfoList?.length ? (soDetail?.additionalInfoList[0]?.customerEmail || soDetail?.additionalInfoList[0]?.emailAddress) : "",
            billing_phone: soDetail?.additionalInfoList?.length ? (soDetail?.additionalInfoList[0]?.customerPhone || soDetail?.additionalInfoList[0]?.cellPhone) : "",
            shipping_is_billing: billingAddress.zipCode === shippingAddress.zipCode ? 1 : 0,
            shipping_customer_name: txData.customerName,
            shipping_last_name: "",
            shipping_address: shippingAddress.streetAddress1,
            shipping_address_2: shippingAddress.streetAddress2,
            shipping_city: shippingAddress.cityName,
            shipping_pincode: shippingAddress.zipCode,
            shipping_country: shippingAddress.countryName,
            shipping_state: shippingAddress.stateName,
            shipping_email: soDetail?.additionalInfoList?.length ? (soDetail?.additionalInfoList[0]?.customerEmail || soDetail?.additionalInfoList[0]?.emailAddress) : "",
            shipping_phone: soDetail?.additionalInfoList?.length ? (soDetail?.additionalInfoList[0]?.customerPhone || soDetail?.additionalInfoList[0]?.cellPhone) : "",
            order_items: (soDetail?.customerSalesOrderDetailsList || []).map(
                (product)=> {
                    let tax = (product?.customerSalesOrderCOATxList || [])?.find((tax)=>tax.accountingEntry === "credit") || {};
                    let taxAmount = (tax?.taxName === "CGST" || tax?.taxName === "SGST") ? tax?.amount *2 :tax?.amount 
                    return {
                        name: `${product.itemName} ${product.itemVariantName ? `- ${product.itemVariantName}` : ''}`,
                        sku: product.sku || '',
                        units: product.quantity || 0,
                        selling_price: product.anItemSalePrice || 0,
                        discount: 0,
                        tax:taxAmount || "0.00",
                        hsn: product.hsnCode || "123456"
                    }
                }),
            payment_method: props.selectedPaymentMethod || 'Prepaid',
            shipping_charges: "0.00",
            giftwrap_charges: "0.00",
            transaction_charges: "0.00",
            total_discount: props.selectedPaymentMethod === "Prepaid"? (soDetail?.totalDiscount || "0.00"):(soDetail?.totalPaymentReceived || "0.00"),
            sub_total: soDetail?.salesOrderTotalAmount || "0.00",
            length: props.deliveyNotesData?.length,
            breadth: props.deliveyNotesData?.width,
            height: props.deliveyNotesData?.height,
            weight: props.deliveyNotesData?.weight
        }

        props.createShiprocketShipmentOrder(payload, props, getRatesForCarriers);
    }

    return (
        <>
            <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>
                <Row gutter={[16, 16]}>
                    <Col span={10} offset={2} className="shipping_info_label">
                        Select Channel
                    </Col>
                    <Col span={10}>
                        <Dropdown
                            items={props.shiprocketIntgratedChannels || []}
                            valueKeyName='name'
                            optionKeyName='id'
                            value={''}
                            placeholder='Select Channel'
                            onSelect={(optionValue, option) => {
                                let selectedChannel = find(props.shiprocketIntgratedChannels || [], { channel_id: option.value }) || {};
                                props.updateState({
                                    selected: selectedChannel,
                                })
                            }}
                        />
                    </Col>

                    <Col span={10} offset={2} className="shipping_info_label">
                        Pickup Address<span className="required">*</span>
                    </Col>
                    <Col span={10}>
                        <Dropdown
                            items={props.shiprocketAccountAddress || []}
                            optionKeyName='pickup_location'
                            valueKeyName='address'
                            valueKeyName2='pickup_location'
                            addressLineKey1='address_2'
                            addressLineKey2='city'
                            addressLineKey3='state'
                            addressLineKey4='pin_code'
                            optionClassAddress='custom-address-dropdown'
                            value={props.selectedAddress || ''}
                            placeholder='Select Pickup Address'
                            onSelect={(optionValue, option) => {
                                props.updateState({
                                    selectedAddress: optionValue,
                                })
                            }}
                        />
                    </Col>
                    <Col span={10} offset={2} className="shipping_info_label">
                        Payment Method<span className="required">*</span>
                    </Col>
                    <Col span={10}>
                        <Radio.Group 
                            disabled
                            onChange={(e)=>{
                                props.updateState({selectedPaymentMethod: e.target.value})
                            }} 
                            value={props.selectedPaymentMethod || (props.soDetail?.paymentTermName === "COD" ? "COD" : 'Prepaid')}
                        >
                            <Radio value={'Prepaid'}>Prepaid</Radio>
                            <Radio value={'COD'}>COD(Cash On Delivery)</Radio>
                        </Radio.Group>
                    </Col>

                    <Col span={10} offset={2} className="shipping_info_label">
                        Comments(if any)
                    </Col>
                    <Col span={10}>
                        <TextArea
                            type='text'
                            style = {{width: '100%', minHeight: '100px', padding: '5px'}}
                            value={props.comments}
                            onChange={(e) => {
                                props.updateState({
                                    comments: e.target.value,
                                });
                            }}
                            placeholder={'Comments'}
                        />
                    </Col>
                </Row>
                {shipmentLogisticsInfo?.logisticsInfoId && shipmentLogisticsInfo.statusCode !== 5 ? <Row>
                    <Col span={24}>
                        <div className="success_text"> Order created with shiprocket, you can proceed with shipment pickup</div>
                    </Col>
                </Row> 
                : ''
                }
            </Skeleton>
            <div className="fot-fix" style={{ maxWidth: '100%' }}>
                <div className="text-center">
                    {shipmentLogisticsInfo?.logisticsInfoId && shipmentLogisticsInfo.statusCode != 5 ? 
                        <Button 
                            loading={props.saveLoading}
                            type="primary"
                            onClick={()=>{
                                props.updateState({currentStep: 1});
                                getRatesForCarriers(shipmentLogisticsInfo?.plateformOrderId);
                            }}
                        > 
                            Get Rates For Courier
                        </Button>
                        :
                        <Button 
                            loading={props.saveLoading}
                            type="primary"
                            onClick={()=>{
                                createShipmentOrderInShiprocket();
                            }}
                        > 
                            Create Order 
                        </Button>
                    }
                </div>
            </div>
        </>
    );
}

export default ShippingOrderInfo;