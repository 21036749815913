import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, DatePicker, Tooltip, Input } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';
import { MultipleSelectDropdown } from '../../../../general/MultipleSelectDropdown';
import { Dropdown } from '../../../../general/Dropdown';
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { CONSTANTS, MODAL_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY, MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../../../static/constants';
import { AG_GRID_CONSTANTS } from '../../../../../static/agGridConstants';
import { showToasterMessage, getMomentDateForUI } from '../../../../../utils';
// import LoadingIcon from '../../../../../assets/images/loading.gif';
import { QuestionCircleTwoTone ,EditOutlined} from '@ant-design/icons';
import { TextBox } from '../../../../general/TextBox';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const priorities = CONSTANTS.PRIORITIES;

class QuoteDetails extends React.Component {

    constructor(props) {
        super();
        this.state = {
            size: 0
        }


    }

    handleSizeChange = e => {
        this.setState({ size: e.target.value });
    };

    handleNewTaxIdentificationAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = function (taxIdentification) {
            if (props.customer && props.customer.customerTaxIdentificationsList) {
                let list = props.customer.customerTaxIdentificationsList || [];
                list.push(taxIdentification)
                props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
            }
        }
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewProjectAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PROJECT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewEmployeeAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.employee' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.EMPLOYEE,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewContactAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = (contact) => {
            props.hideModal(data);
            if (contact.customerId) {
                props.fetchAllContacts({
                    customerId: contact.customerId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
        }
        const data = {
            title: <div>
                <FormattedMessage id='addItem.text.contact' defaultMessage='' />
                <div style={{ 'font-size': '12px' }}> {formData.customerName || ''}</div>
            </div>,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.CONTACT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewAddressAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        if (modalType !== MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS && (!formData || !formData.customerId)) {
            return showToasterMessage({ messageType: 'error', description: 'Please Select Customer' });
        }
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.BILLING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
                    <h6>{this.props.customerName || ''} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.SHIPPING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.shippingAddress' defaultMessage='' />
                    <h6>{this.props.customerName || ''} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                title = <div>
                    <FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
                    <h6>{(this.props.companyInfo || {}).storeName} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                title = <FormattedMessage id='addItem.text.documentName' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
                title = <div>
                    <FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />
                </div>
                break;
            }
            case MODAL_TYPE.INCOTERM: {
                title = <div>
                    <FormattedMessage id='addItem.text.incoterm' defaultMessage='' />
                </div>
                break;
            }
            case MODAL_TYPE.FREIGHT_TYPE: {
                title = <FormattedMessage id='addItem.text.freightType' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = this.getModalTitle(null, modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
        </span>;

        let addFunc;
        let payload = {
            text: text,
            relationshipId: companyInfo.relationshipId
        }

        switch (modalType) {
            case MODAL_TYPE.DOCUMENT_NAME: {
                addFunc = props.addDocumentName;
                payload.txType = CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_QUOTE];
                break;
            }
            case MODAL_TYPE.INCOTERM: {
                addFunc = props.addIncoterm;
                payload.txType = CONSTANTS_TRANSACTIONS.INCOTERM;
                break;
            }
            case MODAL_TYPE.FREIGHT_TYPE: {
                addFunc = props.addFreightType;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc(payload);;
                this.gridApi && this.gridApi.stopEditing();
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;
        let callback = function (data) {
            if (modalType === MODAL_TYPE.CONTACT && props.customer.customerId) {
                props.fetchAllContacts({
                    customerId: props.customer.customerId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
            if(modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS){
                props.getRelationshipAddress({
                  relationshipId: (props.companyInfo || {}).relationshipId
                });
              }
        }

        switch (modalType) {
            case MODAL_TYPE.BILLING_ADDRESS:
            case MODAL_TYPE.SHIPPING_ADDRESS:
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                deleteFunc = props.deleteAddress;
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                deleteFunc = props.deleteDocumentName;
                break;
            }
            case MODAL_TYPE.CONTACT: {
                deleteFunc = props.deleteContact;
                break;
            }
            case MODAL_TYPE.PROJECT: {
                deleteFunc = props.deleteProject;
                break;
            }
            case MODAL_TYPE.EMPLOYEE: {
                deleteFunc = props.deleteEmployee;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
                deleteFunc = props.deleteRelationshipTaxIdentifications;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER: {
                deleteFunc = props.deleteCustomerTaxIdentifications;
                break;
            }
            case MODAL_TYPE.INCOTERM: {
                deleteFunc = props.deleteIncoterm;
                break;
            }
            case MODAL_TYPE.FREIGHT_TYPE: {
                deleteFunc = props.deleteFreightType;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.getRelationshipAddress({
                    relationshipId: (props.companyInfo || {}).relationshipId,
                  });
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId, customerId: (props.customer || {}).customerId, callback });
                if (modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER && props.customer && props.customer.customerTaxIdentificationsList) {
                    let list = (props.customer.customerTaxIdentificationsList || []).filter(x => x.customerTaxIdentificationId !== payload.id);
                    props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
                }
                if (modalType === MODAL_TYPE.DOCUMENT_NAME && payload.id === this.props.documentNameId) {
                    const selectedObj = find((this.props.documentList || []), { txType: CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_QUOTE], isDefault: 1 }) || {};
                    this.props.updateState({
                        documentNameId: selectedObj.documentNameId,
                        docName: selectedObj.docName
                    });
                }
            },
        };
        showModal(modalData);
    }

    render() {
        const { size } = this.state;
        const relationshipBillingAddress = this.props.relationshipBillingAddress || {};
        const customerBillingAddress = this.props.customerBillingAddress || {};
        const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
        if (rowNode) {
            rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS_FIELD, (customerBillingAddress.streetAddress1 || ''));
            rowNode.setDataValue('priority', (this.props.priority || ''));
            rowNode.setDataValue('ref', (this.props.customerRef || ''));
            rowNode.setDataValue('customerRfq', (this.props.customerRfq || ''));
            rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT, this.props.projectName);
            this.gridApi.sizeColumnsToFit();
        }

        // const columnDefs = [
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='customer.salesOrder.form.gridHeader.deliveryMethod' defaultMessage='' />;
        //         },
        //         field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.DELIVERY_METHOD,
        //         resizable: true,
        //         editable: true,
        //         cellEditor: 'customDropDownEditor',
        //         pinned: 'left',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,

        //                 items: this.props.freightList || [],
        //                 value: this.props.deliveryMethod,
        //                 placeholder: 'Select Packages',
        //                 valueKeyName: 'freightTypeName',
        //                 optionKeyName: 'freightTypeId',
        //                 canAddNew: true,
        //                 canDelete: true,
        //                 deleteClickHandler: (payload) => {
        //                     this.deleteClickHandler(this.props, MODAL_TYPE.FREIGHT_TYPE, payload);
        //                 },
        //                 onAddNewClick: (payload) => {
        //                     payload.formData = {
        //                         textBoxValue: payload.textEntered,
        //                         submittedOnce: false
        //                     }
        //                     this.handleNewItemAddition(this.props, payload, MODAL_TYPE.FREIGHT_TYPE)
        //                 },
        //                 onSelect: (selectedValue, option) => {
        //                     this.props.updateState({ deliveryMethod: selectedValue, deliveryMethodId: option.key })
        //                 },
        //                 allowClear: true,
        //                 onClear: () => {
        //                     //obj.node.setDataValue(obj.colDef.field, undefined);
        //                     this.props.updateState({
        //                         deliveryMethod: undefined,
        //                         deliveryMethodId: undefined
        //                     });
        //                 },
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div>
        //                 <FormattedMessage id='customer.salesQuote.form.gridHeader.customerRFQ' defaultMessage='' />
        //                 <Tooltip placement="top" title={this.props.intl.formatMessage(
        //                     {
        //                         id: 'tooltip.rfqNoToLocate',
        //                         defaultMessage: ''
        //                     }
        //                 )} trigger="click">
        //                     <i className="fa fa-question-circle-o pl5 cursor-pointer" />
        //                 </Tooltip>
        //             </div>
        //         },
        //         field: 'customerRfq',
        //         resizable: true,
        //         editable: true,
        //         cellEditor: 'customTextEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 onChange: (val) => {
        //                     this.props.updateState({
        //                         customerRfq: val
        //                     });
        //                 }
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div>
        //                 <FormattedMessage id='customer.salesQuote.form.gridHeader.refNumber' defaultMessage='' />
        //                 <Tooltip placement="top" title={this.props.intl.formatMessage(
        //                     {
        //                         id: 'tooltip.referenceNumberAssign',
        //                         defaultMessage: ''
        //                     }
        //                 )} trigger="click">
        //                     <i className="fa fa-question-circle-o pl5 cursor-pointer" />
        //                 </Tooltip>
        //             </div>
        //         },
        //         field: 'ref',
        //         editable: true,
        //         cellEditor: 'customTextEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 onChange: (val) => {
        //                     this.props.updateState({
        //                         customerRef: val
        //                     });
        //                 }
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='customer.salesQuote.form.gridHeader.salesPerson' defaultMessage='' />;
        //         },
        //         field: 'salesPerson',
        //         editable: true,
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: this.props.employees || [],
        //                 value:this.props.salesPersonName,
        //                 optionKeyName: 'relationshipEmployeeId',
        //                 valueKeyName: 'fullName',
        //                 canAddNew: true,
        //                 canDelete: true,
        //                 deleteClickHandler: (payload) => {
        //                     this.deleteClickHandler(this.props, MODAL_TYPE.EMPLOYEE, payload);
        //                 },
        //                 onAddNewClick: (payload) => {
        //                     // payload.formData = {
        //                     //     firstName: payload.textEntered,
        //                     //     submittedOnce: false
        //                     // }
        //                     // this.handleNewEmployeeAddition(this.props, payload, MODAL_TYPE.EMPLOYEE)
        //                     this.props.updateHeaderState({
        //                         company: {
        //                             ...this.props.company,
        //                             employeeDrawerVisible: true,
        //                         }
        //                     })
        //                 },
        //                 onSelect: (selectedValue, option) => {
        //                     //obj.node.setDataValue(obj.colDef.field, selectedValue);
        //                     this.props.updateState({
        //                         salesPersonId: option.key, salesPersonName: selectedValue
        //                     });
        //                 },
        //                 allowClear: true,
        //                 onClear: () => {
        //                     //obj.node.setDataValue(obj.colDef.field, undefined);
        //                     this.props.updateState({
        //                         salesPersonId: undefined,
        //                         salesPersonName: undefined
        //                     });
        //                 },
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='customer.salesQuote.form.gridHeader.priority' defaultMessage='' />;
        //         },
        //         field: 'priority',
        //         editable: true,
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: priorities,
        //                 //canAddNew: true,
        //                 //canDelete: true,
        //                 onSelect: (selectedValue, optionObj) => {
        //                     obj.node.data[obj.colDef.field] = selectedValue;
        //                     this.props.updateState({
        //                         priority: selectedValue
        //                     });
        //                 },
        //                 allowClear: true,
        //                 onClear: () => {
        //                     //obj.node.setDataValue(obj.colDef.field, undefined);
        //                     this.props.updateState({
        //                         priority: undefined
        //                     });
        //                 },
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='common.billing.address' defaultMessage='' />;
        //         },
        //         field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS_FIELD,
        //         editable: true,
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             const allBillingAddresses = (this.props.customer && filter(this.props.customer.boLocationList, { locationType: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS })) || [];
        //             return {
        //                 //TODO need to use the variable declared above
        //                 lastColumnCell: false,
        //                 items: allBillingAddresses,
        //                 optionKeyName: 'locationId',
        //                 valueKeyName: 'streetAddress1',
        //                 addressLineKey1: 'streetAddress2',
        //                 addressLineKey2: 'cityName',
        //                 addressLineKey3: 'stateName',
        //                 addressLineKey4: 'zipCode',
        //                 isFavouriteKeyName: 'isDefault',
        //                 optionClassAddress: 'custom-address-dropdown',
        //                 canAddNew: true,
        //                 canDelete: true,
        //                 deleteClickHandler: (payload) => {
        //                     this.deleteClickHandler(this.props, MODAL_TYPE.BILLING_ADDRESS, payload);
        //                 },
        //                 onAddNewClick: (payload) => {
        //                     payload.formData = {
        //                         locationType: CONSTANTS.BILLING_ADDRESS,
        //                         customerId: (this.props.customer || {}).customerId,
        //                         pageNumber: this.props.pageNumber,
        //                     }
        //                     this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.BILLING_ADDRESS)
        //                 },
        //                 onSelect: (selectedValue, optionObj) => {
        //                     const customerBillingAddress = find(allBillingAddresses, { locationId: Number(optionObj.key) });
        //                     this.props.updateState({
        //                         customerBillingAddress
        //                     })
        //                     //obj.node.setDataValue(obj.colDef.field, selectedValue)
        //                 },
        //                 allowClear: true,
        //                 onClear: () => {
        //                     //obj.node.setDataValue(obj.colDef.field, undefined);
        //                     this.props.updateState({
        //                         customerBillingAddress: undefined
        //                     });
        //                 },
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='customer.salesQuote.form.gridHeader.project' defaultMessage='' />;
        //         },
        //         field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT,
        //         editable: true,
        //         pinned: 'right',
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: this.props.projectList,
        //                 valueKeyName: 'projectName',
        //                 value: this.props.projectName,
        //                 optionKeyName: 'projectMasterId',
        //                 projectIdKey: 'projectMasterId',
        //                 canAddNew: true,
        //                 canDelete: true,
        //                 deleteClickHandler: (payload) => {
        //                     this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
        //                 },
        //                 onAddNewClick: (payload) => {
        //                     payload.formData = {
        //                         projectName: payload.projectName,
        //                     }
        //                     this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
        //                 },
        //                 onSelect: (selectedValue, optionObj) => {
        //                     const selectedProject = find(this.props.projectList, { projectMasterId: Number(optionObj.key) }) || {};
        //                     this.props.updateState({
        //                         projectMasterId: selectedProject.projectMasterId,
        //                         projectName: selectedProject.projectName,
        //                         projectNumber: selectedProject.projectNumber
        //                     });
        //                     //obj.node.setDataValue(obj.colDef.field, selectedValue);
        //                 },
        //                 allowClear: true,
        //                 onClear: () => {
        //                     //obj.node.setDataValue(obj.colDef.field, undefined);
        //                     this.props.updateState({
        //                         projectMasterId: undefined,
        //                         projectName: undefined,
        //                         projectNumber: undefined,
        //                     });
        //                 },
        //             }
        //         }
        //     }
        // ]

        // const onGridReady = (params) => {
        //     this.gridApi = params.api;
        //     this.gridColumnApi = params.columnApi;
        //     params.api.sizeColumnsToFit();
        // }

        return (
            <Fragment>
                <Row>
                <Col span={5}>
                        <Row gutter={[0,3]}>

                        {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                            {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> */}
                            <Col span={24} className='details-heading'>
                               Organization Information
                            </Col>
                            <Col span={24}>
                                <div className='details-heading-subheading'>

                                <FormattedMessage id="sales.order.create.organization.name" defaultMessage=''/>
                                </div>
                           </Col>
                           <Col span={24}>
                                <div className='deatils-field-value'>
                                    {(this.props.companyInfo || {}).storeName}
                                </div>
                           </Col>
                           <Col span={24}>
                <div className='details-heading-subheading'>

                <FormattedMessage id="sales.order.create.organization.adress" defaultMessage=''/>
                <Tooltip placement="right" title={this.props.intl.formatMessage(
                    {
                      id: 'tooltip.companyBillingAddress',
                      defaultMessage: ''
                    }
                  )} trigger="click">
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
                </Col>
                <Col span={24}>
                 
                <span>{
                  !this.props.addressHide && this.props.relationshipBillingAddress ?
                  <>
                  
                  <div className='detail-address-value'>

                     <div style={{height:'96px',paddingTop:'10px'}}>
                      {

                  getAddressInfoFragment(this.props.relationshipBillingAddress || {}, {}, this.props.companyInfo)
                      }
                     </div>
                     <div onClick={()=>{
                        this.props.updateState({
                          addressHide: true,
                        })
                      }}>
                        <EditOutlined />
                     </div>
                  </div>
                  </> 
                  : <Dropdown
                  style={{ width: '100%'}}
                  items={this.props.allRelationshipBillingAddress}
                  value={relationshipBillingAddress.streetAddress1}
                  moduleName={MODULE_CODED_VALUES.SETTINGS_COMPANY_INFORMATION}

                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                      this.deleteClickHandler(this.props, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS, payload);
                  }}
                  onAddNewClick={(payload) => {
                      payload.formData = {
                          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS
                      }
                      this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS)
                  }}
                  onSelect={(value) => {
                      const selectedRelationshipAddress = find(this.props.allRelationshipBillingAddress, { locationId: Number(value) });
                      this.props.updateState({
                          relationshipBillingAddress: selectedRelationshipAddress,
                          addressHide: false
                      });
                  }}
                  valueKeyName='streetAddress1'
                  addressLineKey1='streetAddress2'
                  addressLineKey2='cityName'
                  addressLineKey3='stateName'
                  addressLineKey4='zipCode'
                  optionClassAddress='custom-address-dropdown'
                  optionKeyName='locationId'
                  isFavouriteKeyName='isDefault'
                  allowClear={true}
                  onClear={() => {
                      this.props.updateState({
                          placeOfSupply: relationshipBillingAddress.stateName,
                          relationshipBillingAddress: undefined
                      })
                  }}
              />
                  }
                 
                </span>

                           </Col>
                            
                                
                            <Col span={24}>
                                <div className='details-heading-subheading'>

                                <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                                <Tooltip placement="right" title={this.props.intl.formatMessage(
                                {
                                    id: 'tooltip.taIdentification',
                                    defaultMessage: ''
                                }
                                )} trigger="click">
                                <QuestionCircleTwoTone />
                                </Tooltip>
                                </div>
                            </Col>      
                            <Col span={24}>
                                <MultipleSelectDropdown
                                    style={{ width: '100%'}}
                                    items={this.props.allRelationshipTaxIdentificatins || []}
                                    placeholder="Tax Identification(s)"
                                    mode='multiple'
                                    onDeSelect={(value) => {
                                        let { selectedRelationshipTaxIdentifications } = this.props
                                        selectedRelationshipTaxIdentifications = filter(selectedRelationshipTaxIdentifications, (taxId) => {
                                            return taxId !== value;
                                        });
                                        this.props.updateState({ selectedRelationshipTaxIdentifications });
                                    }}
                                    valueKeyName='taxIdentificationType'
                                    valueKeyName2='taxIdentificationNumber'
                                    selectedValue={(this.props.allRelationshipTaxIdentificatins || []).length ? (this.props.selectedRelationshipTaxIdentifications || []) : []}
                                    optionKeyName='relationshipTaxIdentificationId'
                                    onSelect={(relationshipTaxIdentificationId) => {
                                        let selectedRelationshipTaxIdentifications = this.props.selectedRelationshipTaxIdentifications || [];
                                        if (selectedRelationshipTaxIdentifications.indexOf(relationshipTaxIdentificationId) === -1) {
                                            selectedRelationshipTaxIdentifications.push(relationshipTaxIdentificationId);
                                        } else {
                                            selectedRelationshipTaxIdentifications = selectedRelationshipTaxIdentifications.filter((el) => {
                                                return el !== selectedRelationshipTaxIdentifications
                                            });
                                        }
                                        this.props.updateState({
                                            selectedRelationshipTaxIdentifications
                                        });
                                    }}
                                    canAddNew={true}
                                    canDelete={true}
                                    deleteClickHandler={(payload) => {
                                        this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION, payload);
                                    }}
                                    onAddNewClick={(payload) => {
                                        payload.formData = {
                                            taxIdentificationNumber: payload.textEntered,
                                            submittedOnce: false,
                                        }
                                        this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                                    }}
                                />
                            </Col>     

                         
                        </Row>
                    </Col>
      <Col span={1}></Col>
                    <Col span={5}>
                        <Row gutter={[0,3]}>
                        <Col span={24} className='details-heading'>
                          Customer Information
                        </Col>
                        {
                            this.props.customerName
                                ? <>
                                    
                                        <Col span={24}  >
                                            <div className="details-heading-subheading">
                                                <FormattedMessage id='company' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="deatils-field-value">
                                                {this.props.customerName}
                                            </div>
                                        </Col>
                                   
                                    
                                        <Col span={24}>
                                            <div className="details-heading-subheading">
                                                <FormattedMessage id='contact' defaultMessage='' />
                                                <Tooltip placement="right" title={this.props.intl.formatMessage(
                                                {
                                                    id: 'tooltip.customerContact',
                                                    defaultMessage: ''
                                                }
                                            )} trigger="click">
                                                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                            </Tooltip>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <Dropdown
                                                style={{ width: '100%' }}
                                                value={((this.props.selectedContact || {}).firstName || "") + " " + ((this.props.selectedContact || {}).lastName || "")}
                                                optionKeyName="contactId"
                                                items={this.props.boContactList}
                                                valueKeyName='firstName'
                                                lastNameKey='lastName'
                                                placeholder='Please select contact name'
                                                isFavouriteKeyName='isPrimaryContact'
                                                moduleName={MODULE_CODED_VALUES.CUSTOMER_CONTACTS}

                                                canAddNew={true}
                                                canDelete={true}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.CONTACT, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        firstName: payload.textEntered,
                                                        submittedOnce: false,
                                                        customerId: (this.props.customer || {}).customerId,
                                                        pageNumber: this.props.pageNumber || 1,
                                                        customerName: this.props.customerName
                                                    }
                                                    this.handleNewContactAddition(this.props, payload, MODAL_TYPE.CONTACT)
                                                }}
                                                onSelect={(contactId) => {
                                                    let contact = find((this.props.boContactList || []), { contactId: +contactId }) || {};
                                                    this.props.updateState({
                                                        selectedContact: contact
                                                    })
                                                }}
                                                allowClear={true}
                                                onClear={() => {
                                                    this.props.updateState({
                                                        selectedContact: undefined
                                                    })
                                                }}
                                            />
                                            
                                            
                                        </Col>
                                  

                                
                                      
                                            <Col span={24} >
                                                <div className="details-heading-subheading">

                                                <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                                                <Tooltip placement="right" title={this.props.intl.formatMessage(
                                                    {
                                                        id: 'taxIdentification.tooltip',
                                                        defaultMessage: ''
                                                    }
                                                )} trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <MultipleSelectDropdown
                                                    style={{ width: '100%' }}
                                                    items={this.props.customer.customerTaxIdentificationsList || []}
                                                    placeholder="Tax Identification(s)"
                                                    mode='multiple'
                                                    onDeSelect={(value) => {
                                                        let { selectedCustomerTaxIdentifications } = this.props
                                                        selectedCustomerTaxIdentifications = filter(selectedCustomerTaxIdentifications, (taxId) => {
                                                            return taxId !== value;
                                                        });
                                                        this.props.updateState({ selectedCustomerTaxIdentifications });
                                                    }}
                                                    valueKeyName='taxIdentificationType'
                                                    valueKeyName2='taxIdentificationNumber'
                                                    selectedValue={(this.props.customer.customerTaxIdentificationsList || []).length ? (this.props.selectedCustomerTaxIdentifications || []) : []}
                                                    optionKeyName='customerTaxIdentificationId'
                                                    onSelect={(customerTaxIdentificationId) => {
                                                        let selectedCustomerTaxIdentifications = this.props.selectedCustomerTaxIdentifications || [];
                                                        if (selectedCustomerTaxIdentifications.indexOf(customerTaxIdentificationId) === -1) {
                                                            selectedCustomerTaxIdentifications.push(customerTaxIdentificationId);
                                                        } else {
                                                            selectedCustomerTaxIdentifications = selectedCustomerTaxIdentifications.filter((el) => {
                                                                return el !== customerTaxIdentificationId
                                                            });
                                                        }
                                                        this.props.updateState({
                                                            selectedCustomerTaxIdentifications
                                                        });
                                                    }}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            taxIdentificationNumber: payload.textEntered,
                                                            submittedOnce: false,
                                                            customerId: (this.props.customer || {}).customerId,
                                                            pageNumber: this.props.pageNumber || 1,
                                                            customerName: this.props.customerName
                                                        }
                                                        this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                                                    }}
                                                />

                                            </Col>
                                     
                                   
                                </>
                                : ''
                        }
                        </Row>
                    </Col>
                    <Col span={8}></Col>

                    <Col span={5}>
                        <Row gutter={[0,3]}>
                           
                                {/*
                                    this.props.customerName &&
                                    <div>
                                         <Row>
                                            <Col span={12}>
                                                <div className="title">
                                                    <FormattedMessage id='customer.salesQuote.form.customerName.label' defaultMessage='' />
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className="value">
                                                    {this.props.customerName}
                                                </div>
                                            </Col>
                                        </Row> */}
                               <Col  span={24} className='details-heading'>
                                    Order Information
                                </Col>
                                     
                                   
                               
                                {
                                    this.props.rfqNumber &&
                                    <>
                                        <Col span={24} >
                                            <div className="details-heading-subheading">
                                                <FormattedMessage id='customer.salesQuote.form.rfqNumber.label' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="deatils-field-value">
                                                {this.props.rfqNumber}
                                            </div>
                                        </Col>
                                    </>
                                }

                                {/* {
                                            this.props.rfqDate &&
                                            <Row>
                                                <Col span={12} className="text-right">
                                                    <div className="title">
                                                        <FormattedMessage id='customer.salesQuote.form.rfqDate.label' defaultMessage='' />
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <div className="value">
                                                        {moment(this.props.rfqDate).format(format)}
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        {
                                            this.props.rfqExpirationDate &&
                                            <Row>
                                                <Col span={12} className="text-right">
                                                    <div className="title">
                                                        <FormattedMessage id='customer.salesQuote.form.rfqExpirationDate.label' defaultMessage='' />
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <div className="value">
                                                        {moment(this.props.rfqExpirationDate).format(format)}
                                                    </div>
                                                </Col>
                                            </Row>
                                        } */}

                                
                                
                                        <Col span={24}>
                                            <div className="details-heading-subheading">
                                                <FormattedMessage id='customer.salesQuote.form.sqDate.label' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="value">
                                                <DatePicker
                                                    allowClear={false}
                                                    format={format}
                                                    key={`${this.props.quoteDate ? getMomentDateForUI({date: new Date(this.props.quoteDate), format}) : getMomentDateForUI({date: new Date(), format})}`}
                                                    defaultValue={this.props.quoteDate ? getMomentDateForUI({date: new Date(this.props.quoteDate), format}) : getMomentDateForUI({date: new Date(), format})}
                                                    onChange={(selectedDate) => {
                                                        this.props.updateState({
                                                            quoteDate: selectedDate
                                                        })
                                                        //this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                                                    }}
                                                    size={size}
                                                />
                                            </div>
                                        </Col>
                                   
                            

                                
                                 
                                        <Col span={24}>
                                            <div className='details-heading-subheading' >
                                                Currency
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            {this.props.currencyCode ?
                                                <span className="currency-icon w20">
                                                    {this.props.currencyIcon ?
                                                        <i className={this.props.currencyIcon} /> : this.props.currencyCode + " "}</span> :
                                                <span className="currency-icon w20">-</span>
                                            }
                                            <Dropdown
                                                items={filter((this.props.countries || []), (country) => { return country.countryName !== (this.props.companyInfo || {}).countryName; })}
                                                placeholder='Select Currency'
                                                style={{ width: "80%"}}
                                                value={this.props.currencyCode && this.props.currencyCode + " "}
                                                onSelect={(selectedValue) => {
                                                    const selectedCountry = find(this.props.countries, { currencyCode: selectedValue });
                                                    this.props.updateState({
                                                        currencyCode: selectedValue,
                                                        currencyIcon: selectedCountry.currencyIcon
                                                    })
                                                }}
                                                valueKeyName='currencyCode'
                                                valueKeyName2='countryName'
                                                optionKeyName='currencyCode'
                                                allowClear={true}
                                                onClear={() => {
                                                    this.props.updateState({
                                                        currencyCode: undefined,
                                                        currencyIcon: undefined
                                                    })
                                                }}
                                            />
                                        </Col>
                                
                                
                              
                                        <Col span={24}>
                                            <div className='details-heading-subheading' >
                                                Exchange Rate
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <span className="currency-icon w20">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon} /> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                            <Input
                                                type='number'
                                                style={{ width: "80%" }}
                                                placeholder="0.00"
                                                value={this.props.exchangeRate || ""}
                                                onChange={(e) => {
                                                    this.props.updateState({
                                                        exchangeRate: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                   
                                


                                
                             
                                        <Col span={24}>
                                            <div className="details-heading-subheading">
                                                   
                                                        <FormattedMessage id='customer.salesOrder.form.documentDropdown.label' defaultMessage='' />
                                                <Tooltip placement="right" title={this.props.intl.formatMessage(
                                                    {
                                                        id: 'documentTitle.tooltip',
                                                        defaultMessage: ''
                                                    }
                                                )}>
                                                   <QuestionCircleTwoTone/>
                                                </Tooltip>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <Dropdown
                                                style={{ width: '100%' }}
                                                items={filter((this.props.documentList || []), (document) => { return document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_QUOTE]; })}
                                                placeholder='Document name'
                                                value={this.props.docName && this.props.documentNameId}
                                                canAddNew={true}
                                                canDelete={true}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.DOCUMENT_NAME, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        textBoxValue: payload.textEntered,
                                                        submittedOnce: false,
                                                        maxLength: 30
                                                    }
                                                    this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DOCUMENT_NAME)
                                                }}
                                                onSelect={(selectedValue) => {
                                                    const selectedObj = find((this.props.documentList || []), { documentNameId: Number(selectedValue) });
                                                    this.props.updateState({
                                                        documentNameId: selectedValue,
                                                        docName: selectedObj.docName
                                                    })
                                                }}
                                                valueKeyName='docName'
                                                optionKeyName='documentNameId'
                                                isLockKeyName='isSystemGenerated'
                                            // allowClear={true}
                                            // onClear={() => {
                                            //     this.props.updateState({
                                            //         documentNameId: undefined,
                                            //         docName: undefined
                                            //     })
                                            // }}
                                            />
                                        </Col>
                                 
                                
                             
                                        <Col span={24}>
                                                <div className="details-heading-subheading">
                                                    <FormattedMessage id='customer.salesOrder.form.gridHeader.incoterm' defaultMessage='' />
                                            <Tooltip placement="right" title={this.props.intl.formatMessage(
                                                    {
                                                        id: 'tooltip.internationalCommercial',
                                                        defaultMessage: ''
                                                    }
                                                )}>
                                                    <QuestionCircleTwoTone/>
                                            </Tooltip>
                                                </div>
                                        </Col>
                                        <Col span={24}>
                                            
                                                <Dropdown
                                                    items={this.props.incoterms || []}
                                                    optionKeyName={'inctermId'}
                                                    valueKeyName={'incotermName'}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.INCOTERM, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            textBoxValue: payload.textEntered,
                                                            submittedOnce: false
                                                        }
                                                        this.handleNewItemAddition(this.props, payload, MODAL_TYPE.INCOTERM)
                                                    }}
                                                    onSelect={(selectedValue, option) => {
                                                        this.props.updateState({
                                                            inctermId: option.key, incotermName: option.name
                                                        });
                                                    }}
                                                    allowClear={true}
                                                    onClear={() => {
                                                        this.props.updateState({
                                                            inctermId: undefined,
                                                            incotermName: undefined
                                                        });
                                                    }
                                                    }></Dropdown>
                                           

                                        </Col>
                               
                                 
                            
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="txn-table">
                            <table>
                <thead>
                  <tr>
                    <th><FormattedMessage id='customer.salesOrder.form.gridHeader.deliveryMethod' defaultMessage='' /></th>
                    <th><FormattedMessage id='customer.salesQuote.form.gridHeader.customerRFQ' defaultMessage='' /></th>
                    <th><FormattedMessage id='customer.salesQuote.form.gridHeader.refNumber' defaultMessage='' /></th>
                    <th><FormattedMessage id='customer.salesQuote.form.gridHeader.salesPerson' defaultMessage='' /></th>
                    <th><FormattedMessage id='customer.salesQuote.form.gridHeader.priority' defaultMessage='' /></th>
                    <th><FormattedMessage id='common.billing.address' defaultMessage='' /></th>
                    <th><FormattedMessage id='customer.salesQuote.form.gridHeader.project' defaultMessage='' /></th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Dropdown
                             lastColumnCell= {false}

                             items= {this.props.freightList || []}
                             value= {this.props.deliveryMethod}
                             placeholder= 'Select Packages'
                             valueKeyName= 'freightTypeName'
                             optionKeyName= 'freightTypeId'
                             canAddNew= {true}
                             canDelete= {true}
                             deleteClickHandler= {(payload) => {
                                 this.deleteClickHandler(this.props, MODAL_TYPE.FREIGHT_TYPE, payload);
                             }}
                             onAddNewClick= {(payload) => {
                                 payload.formData = {
                                     textBoxValue: payload.textEntered,
                                     submittedOnce: false
                                 }
                                 this.handleNewItemAddition(this.props, payload, MODAL_TYPE.FREIGHT_TYPE)
                             }}
                             onSelect= {(selectedValue, option) => {
                                 this.props.updateState({ deliveryMethod: selectedValue, deliveryMethodId: option.key })
                             }}
                             allowClear= {true}
                             onClear= {() => {
                                 //obj.node.setDataValue(obj.colDef.field, undefined);
                                 this.props.updateState({
                                     deliveryMethod: undefined,
                                     deliveryMethodId: undefined
                                 });
                             }}
                            />
                        </td>
                       <td>
                       <TextBox
                       value={this.props.customerRfq}
                         onChange= {(val) => {
                            this.props.updateState({
                                customerRfq: val.target.value
                            });
                        }}
                      />
                        </td> 
                        <td>
                        <TextBox
                        value={this.props.customerRef}
                         onChange= { (val) => {
                            this.props.updateState({
                                customerRef: val.target.value
                            });
                        }}
                      />
                        </td>
                        <td>
                            <Dropdown
                          lastColumnCell= {false}
                          items= {this.props.employees || []}
                          optionKeyName= 'relationshipEmployeeId'
                          valueKeyName= 'fullName'
                        moduleName={MODULE_CODED_VALUES.HRMS_EMPLOYEE}

                          canAddNew={true}
                          canDelete= {true}
                          value={this.props.salesPersonName}
                          deleteClickHandler= {(payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.EMPLOYEE, payload);
                        }}
                        onAddNewClick={ (payload) => {
                            //   payload.formData = {
                            //     firstName: payload.textEntered,
                            //     submittedOnce: false
                            //   }
                            //   this.handleNewEmployeeAddition(this.props, payload, MODAL_TYPE.EMPLOYEE)
                            this.props.updateHeaderState({
                                company: {
                                    ...this.props.company,
                                    employeeDrawer: true,
                                    empInfo: {}
                                }
                            })
                        }}
                        onSelect= {(selectedValue, option) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            this.props.updateState({
                                salesPersonId: option.key, salesPersonName: selectedValue
                            });
                        }}
                        allowClear= {true}
                        onClear= {() => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                            this.props.updateState({
                                salesPersonId: undefined,
                                salesPersonName: undefined
                            })
                        }}

                            />
                        </td>
                        <td>
                            <Dropdown
                            lastColumnCell= {false}
                            items= {priorities}
                            //canAddNew: true,
                            //canDelete: true,
                            onSelect= {(selectedValue, optionObj) => {
                             
                                this.props.updateState({
                                    priority: selectedValue
                                });
                            }}
                            allowClear= {true}
                            onClear= {() => {
                                //obj.node.setDataValue(obj.colDef.field, undefined);
                                this.props.updateState({
                                    priority: undefined
                                })
                            }}
                            />
                                
                         
                        </td>
                        <td>
                      <Dropdown
                        items= {this.props.allBillingAddress}
                        value={(this.props.customerBillingAddress || {}).streetAddress1}
                        optionKeyName= 'locationId'
                        valueKeyName= 'streetAddress1'
                        addressLineKey1= 'streetAddress2'
                        addressLineKey2= 'cityName'
                        addressLineKey3= 'stateName'
                        addressLineKey4= 'zipCode'
                        optionClassAddress= 'custom-address-dropdown'
            

                        canAddNew= {true}
                        canDelete= {true}
                        isFavouriteKeyName= 'isDefault'
                        permissions= {this.props.permissions}
                        moduleName= {MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS}
                        operations= {[PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE]}
                        deleteClickHandler= {(payload) => {
                          this.deleteClickHandler(this.props, MODAL_TYPE.BILLING_ADDRESS, payload);
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            locationType: CONSTANTS.BILLING_ADDRESS,
                            customerId: (this.props.customer || {}).customerId,
                            pageNumber: this.props.pageNumber
                          }
                          this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.BILLING_ADDRESS)
                        }}
                        onSelect= {(selectedValue, optionObj) => {
                          const customerBillingAddress = find(this.props.allBillingAddress, { locationId: Number(optionObj.key) });
                          this.props.updateState({
                            customerBillingAddress
                          })
                        }}
                        allowClear= {true}
                        onClear={() => {
                          this.props.updateState({
                            customerBillingAddress: undefined
                          });
                        }}
                      />
                    </td>
                    <td>
                        <Dropdown
                         lastColumnCell={ false}
                         items= {this.props.projectList}
                         valueKeyName= 'projectName'
                         value= {this.props.projectName}
                         optionKeyName= 'projectMasterId'
                         projectIdKey= 'projectMasterId'
                  moduleName={MODULE_CODED_VALUES.PROJECT}

                         canAddNew= {true}
                         canDelete= {true}
                         deleteClickHandler= {(payload) => {
                             this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                         }}
                         onAddNewClick= {(payload) => {
                             payload.formData = {
                                 projectName: payload.projectName,
                             }
                             this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
                         }}
                         onSelect= {(selectedValue, optionObj) => {
                             const selectedProject = find(this.props.projectList, { projectMasterId: Number(optionObj.key) }) || {};
                             this.props.updateState({
                                 projectMasterId: selectedProject.projectMasterId,
                                 projectName: selectedProject.projectName,
                                 projectNumber: selectedProject.projectNumber
                             });
                             //obj.node.setDataValue(obj.colDef.field, selectedValue);
                         }}
                         allowClear= {true}
                         onClear= {() => {
                             //obj.node.setDataValue(obj.colDef.field, undefined);
                             this.props.updateState({
                                 projectMasterId: undefined,
                                 projectName: undefined,
                                 projectNumber: undefined
                             })
                         }}
                        
                        />
                    </td>
                    </tr>
                    </tbody> 
                            </table>
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        disableFocusOnClickOutside={true}
                        rowData={[{}]}
                        gridStyle={{
                            width: '100%',
                            height: '100px'
                        }} />
                </Row> */}

            </Fragment>
        );
    }
}
const getAddressInfoFragment = (obj, customerContactObj, customerObj) => {
    // if (!obj || !Object.keys(obj).length) {
    //     return '';
    // }


    let contactName = "";
    if (customerContactObj.salutationName) contactName += customerContactObj.salutationName + ' ';
    if (customerContactObj.firstName) contactName += customerContactObj.firstName;
    if (customerContactObj.middleName) contactName += ' ' + customerContactObj.middleName;
    if (customerContactObj.lastName) contactName += ' ' + customerContactObj.lastName;
    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <div className='billing-address'>{obj.streetAddress1}
                </div>
                : ''
        }
        {
            obj.streetAddress2
                ? <div className='billing-address'>{obj.streetAddress2}
                </div>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {
            customerContactObj.firstName
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        }
        {
            customerContactObj.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.phone.label' defaultMessage='' /> - {customerContactObj.workPhone}
                </div> :
                ''
        }
        {
            customerContactObj.email
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.email.label' defaultMessage='' /> - {customerContactObj.email}
                </div> :
                ''
        }
        {/* {
            customerObj.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.gstIn.label' defaultMessage='' /> - {customerObj.gstNumber}
                </div> :
                ''
        }
        {
            customerObj.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.pan.label' defaultMessage='' /> - {customerObj.panNumber}
                </div> :
                ''
        } */}

    </Fragment>

    return fragmentObj;
}

export default injectIntl(QuoteDetails);
