import React, { Fragment } from "react";
import { Skeleton, Pagination, DatePicker, Empty } from 'antd';
import { CONSTANTS, ICONS } from '../../../../static/constants';
import { fetchPaginationDataIfNeeded, getMomentDateForUI, getCurrentDateForBackend, getMomentDateForUIReadOnly, fixedDecimalAndFormateNumber, sortTable } from '../../../../utils';
import { injectIntl, FormattedMessage } from 'react-intl';
import SalesInvoiceDetail from '../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
import SODetail from '../../../../containers/modal/modalBody/customer/SODetail';
import CustomerPaymentDetails from '../../../../containers/modal/modalBody/customer/PaymentDetail';
import { EmptyTableData } from "../../../general/EmptyTableData";
import { EmptyBox } from "../../../general/EmptyBox";
// import { AG_GRID_CLASS_CONSTANTS } from "../../../../static/cssClassConstants";
// import { FilterOutlined, UndoOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const openTransactionDetail = (data, props, type) => {
    const txType = type || data.txType;
    switch (txType) {
        case 'Standard Sales Order':
        case 'Sales Order': {
            const soPayload = {
                customerId: data.customerId,
                soMasterId: data.soMasterId
            }
            props.pushModalToStack({
                modalBody: <SODetail {...props} soPayload={soPayload} />,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            })
            break;
        }
        case 'Invoice': {
            const invoicePayload = {
                customerId: data.customerId,
                invoiceMasterId: data.invoiceMasterId
            }
            props.pushModalToStack({
                modalBody: <SalesInvoiceDetail {...props} invoicePayload={invoicePayload} />,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            })
            break;
        }
        case 'Payment': {
            const paymentPayload = {
                customerId: data.customerId,
                customerPaymentId: data.customerPaymentId || (data.customerTxId || {}).customerTxPaymentId
            }
            const modalData = {
                title: <div className='ant-modal-title'>Purchase Payment</div>,
                modalBody: <CustomerPaymentDetails paymentPayload={paymentPayload} />,
                width: 500,
                hideFooter: true
            };
            props.showModal(modalData);
            break;
        }
        default: {
            break;
        }
    }
};


const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = { ...props.payload, pageNumber: pageNumber, pageSize: pageSize };
    props.resetInvoiceTransactionListData();
    props.fetchCustomerInvoiceList(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
}


const loadPageData = (pageNumber, pageSize, props) => {
    const payload = { ...props.payload, pageNumber: pageNumber, pageSize: pageSize };
    fetchPaginationDataIfNeeded('fetchCustomerInvoiceList', 'invoiceList', props, payload);
}

const Invoice = (props) => {
    const { companyInfo } = props;
    const _getInvoiceList = (dataList) => {
        dataList && dataList.forEach((dt, dtIndex) => {
            if (dt.customerPaymentsList && dt.customerPaymentsList.length)
                dt.children = dt.customerPaymentsList;
            dt.children && dt.children.forEach((dt2) => {
                dt2.invoiceNumber = dt2.paymentNumber;
                dt2.invoiceTotalAmount = dt.invoiceTotalAmount;
                dt2.paymentTermName = dt.paymentTermName;
            });
        });

        return dataList || [];
    }

    const invoiceList = _getInvoiceList(props.invoiceList[props.pageNumber || 1]);
    const invoiceListCount = props.invoiceListCount;
   
    const handleSortColumn = (index) => {
        
        const currentSortDirection = props.sortStates[index] || "none";
        const nextSortDirection =
          currentSortDirection === "none" ? "asc" :
          currentSortDirection === "asc" ? "desc" : "none";
      
        // Update sorting state using props.updateState
        props.updateState({
          sortStates: {
            // ...props.sortStates,
            [index]: nextSortDirection,
          },
        });
      
        // Call your sorting function
        const direction = nextSortDirection === "desc" ? 1 : 0;
        sortTable(document.getElementById("invoiceList-table"), index, direction);
      };
      const getSortIcon = (index) => {
          const sortState = props.sortStates[index];
          if (sortState === "asc") return <i className={ICONS.SORT_UP} />;
          if (sortState === "desc") return <i className={ICONS.SORT_DOWN} />;
          return (
            <i
              className={`${ICONS.SORT} ${
                props.hoveredColumn === index ? "opopacity1" : "opopacity0"
              } `}
            />
          );
      };

    return (
        <Fragment>
            <div className="view-container">

                <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">Invoice List</div>
                        {invoiceListCount ? <>
                            <div className="vertical-sep" />
                            <div>{invoiceListCount}</div>
                        </> : ''}
                    </div>
                    <div className="right-actions">
                        <RangePicker
                            allowEmpty={true}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if ((dates || []).length === 2) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });

                                    const payload = {
                                        customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 100,
                                        filters: {
                                            startDate: getCurrentDateForBackend(dates[0]),
                                            endDate: getCurrentDateForBackend(dates[1]),
                                        }
                                    };
                                    props.resetInvoiceTransactionListData();
                                    props.fetchCustomerInvoiceList(payload);
                                    props.fetchCustomerInvoiceCount(payload);

                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                    const payload = {
                                        customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                        filters: {}
                                    };
                                    props.resetInvoiceTransactionListData();
                                    props.fetchCustomerInvoiceList(payload);
                                    props.fetchCustomerInvoiceCount(payload);
                                }
                            }}
                        />
                        {/* <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                                if( !props.startDate && !props.endDate ){
                                    return showToasterMessage({ messageType: 'error', description: 'Please select date range' });
                                }
                                const payload = {
                                    customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 100,
                                    filters: {
                                        startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                                        endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                                    }
                                };
                                props.resetInvoiceTransactionListData();
                                props.fetchCustomerInvoiceList(payload);
                                props.fetchCustomerInvoiceCount(payload);
                            }}>
                                {<FilterOutlined />} Apply Filter
                            </button>
                            <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                                props.updateState({
                                    startDate: null, endDate: null,
                                });
                                const payload = {
                                    customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                    filters: {}
                                };
                                props.resetInvoiceTransactionListData();
                                props.fetchCustomerInvoiceList(payload);
                                props.fetchCustomerInvoiceCount(payload);
                                }}>
                                    {<UndoOutlined />} Clear Filter
                            </button> */}

                    </div>
                </div>
                <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>

                    <div className="table-container table-h-profile">
                        <table id="invoiceList-table">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(1)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 1 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-start">
                                            <FormattedMessage id='customer.detail.transaction.gridHeader.transaction' defaultMessage='' />
                                            {getSortIcon(1)}
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(2)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 2 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-start">
                                            <FormattedMessage id='customer.detail.transaction.gridHeader.invoiceNumber' defaultMessage='' />
                                            {getSortIcon(2)}
                                        </div>
                                        
                                    </th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(3)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 3 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            style={{display:"flex",alignItems:"center", justifyContent:"flex-end",gap:"5px", cursor:"pointer"}}>
                                            <FormattedMessage id='customer.detail.transaction.gridHeader.amount' defaultMessage='' />
                                            {getSortIcon(3)}
                                        </div> 
                                    </th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(4)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 4 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-center">
                                            <FormattedMessage id='customer.detail.paymentDueDate' defaultMessage='' />
                                            {getSortIcon(4)}
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(5)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 5 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-start">
                                            <FormattedMessage id='customer.detail.transaction.gridHeader.paymentTerm' defaultMessage='' />
                                            {getSortIcon(5)}
                                        </div> 
                                    </th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(6)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 6 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-start">
                                            <FormattedMessage id='customer.detail.transaction.gridHeader.date' defaultMessage='' />
                                            {getSortIcon(6)}
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            onClick={() => handleSortColumn(7)}
                                            onMouseEnter={() => props.updateState({ hoveredColumn: 7 })}
                                            onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                                            className="sortTable-Container justify-center">
                                            <FormattedMessage id='common.status' defaultMessage='' />
                                            {getSortIcon(7)}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceList && invoiceList.length ? invoiceList.map((rowData, i) => {
                                    return <tr key={'invSumm' + i}>
                                        <td>{i + 1}</td>
                                        <td>{rowData.txType ? rowData.txType : <EmptyBox />}</td>
                                        <td>
                                            {
                                                rowData.invoiceNumber
                                                    ?
                                                    <div className='cursor-pointer' onClick={() => openTransactionDetail(rowData, props)}>{rowData.invoiceNumber}</div>
                                                    :
                                                    <EmptyBox />
                                            }

                                        </td>
                                        <td className="amount-TextRight">
                                            {rowData.invoiceTotalAmount ?
                                                <span> {rowData.foreignCurrencyIcon ? <i className={rowData.foreignCurrencyIcon} /> : (rowData.foreignCurrency ? <span>{rowData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(rowData.invoiceTotalAmount)} </span>
                                                : 
                                                <EmptyBox />
                                            }
                                        </td>
                                        <td className="flex justify-center">
                                            {rowData.invoiceDueDate ? getMomentDateForUIReadOnly({
                                                    date: rowData.invoiceDueDate,
                                                    format: CONSTANTS.TABLE_DATE_FORMAT,
                                                }) : <EmptyBox />}
                                        </td>
                                        <td>{rowData.paymentTermName || <EmptyBox />}</td>
                                        <td>
                                            {rowData.dateCreated
                                                ? getMomentDateForUIReadOnly({
                                                    date: rowData.dateCreated,
                                                    format: CONSTANTS.TABLE_DATE_FORMAT,
                                                })
                                                : 
                                                <EmptyBox />
                                                }
                                        </td>
                                        <td>
                                            <div className={["status-code", (rowData.status || '').toLowerCase()].join(' ')}>
                                                <FormattedMessage id={rowData.status} defaultMessage={rowData.status} />
                                            </div>
                                        </td>
                                    </tr>
                                })
                                    :
                                    <tr key="empty-data">
                                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                            <EmptyTableData />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        {/* <Table pagination={false} columns={columnDefs}  dataSource={invoiceList || []} className="customer-transactions-table" /> */}
                    </div>
                </Skeleton>
                <div className="footer">
                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={invoiceListCount}
                            // showTotal={(total) => {
                            //     return props.intl.formatMessage({id: 'pagination.text.total', defaultMessage: ''}) + ` ${ total } `+ props.intl.formatMessage({id: 'pagination.text.items', defaultMessage: ''});
                            // }}
                            defaultPageSize={100}
                            showSizeChanger
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize, props);
                                props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber, pageSize, props);
                            }}
                        />
                    </div>
                </div>

            </div>
        </Fragment >
    )
};

export default injectIntl(Invoice);