import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Drawer, Col, Row, Tooltip, Checkbox, Select } from "antd";
import { CustomButton } from "../../general/CustomButton";
import { ErrorMsg } from "../../general/ErrorMessage";
import ContactComponent from "../../../components/modal/modalBody/common/Contact";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import { CONSTANTS, ICONS, MODAL_TYPE } from "../../../static/constants";
import { TextBox } from "../../general/TextBox";
import * as find from "lodash.find";
import { getCurrentDateForBackend, validateEmail } from "../../../utils";
import { getLabelName } from "../../modal/modalBody/customerInfo";

function ContactDrawer(props) {
  const { Option } = Select;
  const { getFieldDecorator } = props.form;

  const closeDrawer = () => {
    props.updateState({
      contact: {
        contactDrawer: false,
      },
    });
  };
  const _getLocationType = () => {
    if (props.formData.addressType === "relationship") {
      return [
        {
          name: "Shipping Address",
          value: "RelationshipShippingAddress",
        },
        {
          name: "Billing Address",
          value: "RelationshipBillingAddress",
        },
      ];
    } else {
      return [
        {
          name: "Billing Address",
          value: "BillingAddress",
        },
        {
          name: "Shipping Address",
          value: "ShippingAddress",
        },
      ];
    }
  };
  const {
    salutationName,
    salutationId,
    firstName,
    contactId,
    middleName,
    lastName,
    preferCallName,
    workPhone,
    cellPhone,
    faxNumber,
    emailAddress,
    jobTitle,
    department,
    isPrimaryContact,
    dateCreated,
    contactType,
    isLoaded
  } = (props || {})?.formData  || {};

  const isDGContact = ((props.data || {}).formData || {}).isDGContact || false;
  const countryCallingCode = props.formData?.countryCallingCode || (props.companyInfo || {}).countryCallingCode
    ? "+" + (props.companyInfo || {}).countryCallingCode
    : "";
  const isTeacher = (props.formData || {}).isTeacher;

  const state = {
    titleName: salutationName,
    salutationId,
    firstName,
    contactId,
    middleName,
    lastName,
    preferCallName,
    workPhone,
    cellPhone,
    faxNumber,
    emailAddress,
    jobTitle,
    department,
    isPrimaryContact: isPrimaryContact ? true : false,
    dateCreated,
    contactType,
    isDGContact,
    countryCallingCode,
    isTeacher,
    isLoaded
  };

  const handleNewItemAddition = (payload, modalType) => {
    const formData = payload.formData;
    // const { showModal } = props;

    if (
      props.formData.textBoxValue &&
      props.formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        // title: getModalTitle(modalType),
        title: "Create Salutation",

        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            props.popModalFromStack();
            props.pushModalToStack(data);
          }
        },
      };
      props.pushModalToStack(data);
    }
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { companyInfo,showModal } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload?.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        deleteFunc = props.deleteSalutation;
        break;
      } 
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          
        });
        props.hideModal();
      },
    };
    // props.pushModalToStack(modalData);
    
    props.showModal(modalData);
  };
  //  const state = {
  //     titleName: (((props.data || {}).formData || {}).contactData || {})
  //       .salutationName,
  //     salutationId: (((props.data || {}).formData || {}).contactData || {})
  //       .salutationId,
  //     firstName: (((props.data || {}).formData || {}).contactData || {})
  //       .firstName,
  //     contactId: (((props.data || {}).formData || {}).contactData || {})
  //       .contactId,
  //     middleName: (((props.data || {}).formData || {}).contactData || {})
  //       .middleName,
  //     lastName: (((props.data || {}).formData || {}).contactData || {})
  //       .lastName,
  //     preferCallName: (((props.data || {}).formData || {}).contactData || {})
  //       .preferCallName,
  //     workPhone: (((props.data || {}).formData || {}).contactData || {})
  //       .workPhone,
  //     cellPhone: (((props.data || {}).formData || {}).contactData || {})
  //       .cellPhone,
  //     faxNumber: (((props.data || {}).formData || {}).contactData || {})
  //       .faxNumber,
  //     email: (((props.data || {}).formData || {}).contactData || {})
  //       .emailAddress,
  //     jobTitle: (((props.data || {}).formData || {}).contactData || {})
  //       .jobTitle,
  //     department: (((props.data || {}).formData || {}).contactData || {})
  //       .department,
  //     isPrimaryContact: (((props.data || {}).formData || {}).contactData || {})
  //       .isPrimaryContact
  //       ? true
  //       : false,
  //     dateCreated: (((props.data || {}).formData || {}).contactData || {})
  //       .dateCreated,
  //     contactType: (((props.data || {}).formData || {}).contactData || {})
  //       .contactType,
  //     isDGContact: ((props.data || {}).formData || {}).isDGContact || false,
  //     countryCallingCode: (props.companyInfo || {}).countryCallingCode
  //       ? "+" + (props.companyInfo || {}).countryCallingCode
  //       : "",
  //     isTeacher: ((props.data || {}).formData || {}).isTeacher,
  //   };

  const { intl } = props;

  const addItemConfirmationModal = (props, text, modalType) => {
    const { companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        addFunc = props.addSalutation;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.popModalFromStack();
      },
    };
    props.pushModalToStack(modalData);
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };
  const handleSubmit = (e) => {
    // e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      props.updateState({
        // isLoaded:true,
        submittedOnce: true,
        invalidEmailClass: validateEmail(
          values.emailAddress || state.emailAddress
        )
          ? ""
          : "input-text-error",
      });
      if (!err && validateEmail(values.emailAddress)) {
        const payload = {
          salutationName: values.titleName,
          salutationId: values.salutationId,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          preferCallName: values.preferCallName,
          workPhone: values.workPhone ? values.workPhone : null,
          contactId: state.contactId,
          cellPhone: values.cellPhone ? values.cellPhone : null,
          faxNumber: values.faxNumber,
          emailAddress: values.emailAddress,
          jobTitle: values.jobTitle,
          department: values.department,
          relationshipId: (props.companyInfo || {}).relationshipId,
          dateCreated:
            state.dateCreated || getCurrentDateForBackend(new Date()),
          isPrimaryContact:(values.isPrimaryContact || state.isPrimaryContact) ? 1:0,
          countryCallingCode: values.countryCallingCode,
          contactType: state.contactType,
        };
        if (
          props &&
          ((props.formData && props.formData.customerId) || props.isDGContact)
        ) {
          payload.customerId = (props.formData || {}).customerId;
          payload.contactType = CONSTANTS.CONTACT_TYPE_CUSTOMER;
        }
        if (props && props.formData && props.formData.supplierId) {
          payload.supplierId = props.formData.supplierId;
          payload.contactType = CONSTANTS.CONTACT_TYPE_SUPPLIER;
        }
        if (props && props.formData && props.formData.isFundraiser) {
          payload.contactType = CONSTANTS.CONTACT_TYPE_FUNDRAISER;
          payload.teamMasterId = props.formData.teamMasterId;
        }
        if (props && props.formData && props.formData.isTeacher) {
          payload.role = "teacher";
        }
        const modalData = {
          modalBody:props.formData.isUpdate?"Are you sure you want to save ?": "Are you sure you want to submit ?",
          handleSubmit: () => {
            props.createContact(payload, (props || {}).formData,props);
            // handleSubmit && handleSubmit();
            // props.popModalFromStack();
            closeDrawer();
          },
        };
        props.pushModalToStack(modalData);
      }
    });
  };

  return (
    <Drawer
      title={ props.formData.isUpdate ? "Edit Contact" : "Create Contact"}
      width={720}
      zIndex={1}
      maskClosable={false}
      keyboard={false}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props?.contactDrawer}
      footer={
        <>
          <CustomButton
            intlId={"modal.contact.button.cancel"}
            defaultMessage={""}
            type={"default"}
            key={1}
            onClick={() => {
              closeDrawer();
            }}
          />
          <CustomButton
            intlId={"confirmation.submit"}
            defaultMessage={""}
            htmlType="submit"
            onClick={() => handleSubmit(props)}
            key={2}
          />
        </>
      }
    >
      <Form
        layout="vertical"
        labelAlign="left"
        onSubmit={handleSubmit}
        hideRequiredMark={true}
        {...props}
      >
        <>
          <Row gutter={16}>
            <Col span={12}>
              {!state.isTeacher ? (
                <Form.Item
                  label={
                    <>
                      <span className="mr5">
                        <FormattedMessage
                          id="contact.primary"
                          defaultMessage=""
                        />
                      </span>
                      <Tooltip placement="bottom" title="Select Contact Type">
                        <i className={`cursor-pointer ${ICONS.HELP_P} `} />
                      </Tooltip>
                    </>
                  }
                  colon={false}
                >
                  {getFieldDecorator("isPrimaryContact", {
                    initialValue: props.formData.isPrimaryContact,
                    rules: [
                      {
                        required: true,
                        message: (
                          "Enter the value"
                        ),
                      },
                    ],
                  })(
                    <Select
                      placeholder="Select"
                      value={props.isPrimaryContact}
                      onChange={(e) => {
                        props.form.setFieldsValue({
                          isPrimaryContact: e,
                        });
                        props.updateState({
                          isPrimaryContact: e ,
                        });
                      }}
                      disabled={props.formData?.isPrimaryContact === 1}
                    >
                      <Option value ={1}>Yes</Option>
                      <Option value={0}>No</Option>
                    </Select>
                  )}
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
            <Col span={12}>
              <Row>
                <Col span={6}>
                  <Form.Item
                    label={
                      <>
                        <FormattedMessage
                          id="contact.firstName"
                          defaultMessage=""
                        />
                        <span className="required">*</span>
                      </>
                    }
                    colon={false}
                  >
                    {getFieldDecorator("titleName", {
                      initialValue: state.titleName || "",
                    })(
                      <Dropdown
                        items={props.salutations || []}
                        optionKeyName="salutationId"
                        valueKeyName="titleName"
                        canAddNew={true}
                        canDelete={true}
                        placeholder="Select Title"
                        deleteClickHandler={(payload) => {
                          deleteClickHandler(props,MODAL_TYPE.SALUTATION, payload);
                        }}               
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            textBoxValue: payload.textEntered,
                            submittedOnce: false,
                          };
                          handleNewItemAddition(
                            payload,
                            MODAL_TYPE.SALUTATION
                          );
                        }}
                        onSelect={(selectedValue, option) => {
                          props.updateState({
                            titleName: option.name,
                          });
                          props.form.setFieldsValue({
                            salutationId: selectedValue,
                          });
                          props.form.setFieldsValue({
                            titleName: option.name,
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item label={<span> </span>} colon={false}>
                    {getFieldDecorator("firstName", {
                      initialValue: state.firstName || "",
                      rules: [
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="contact.firstName.message" />
                          ),
                        },
                      ],
                    })(
                      <TextBox
                        maxLength={50}
                        countStyle={{ top: "30px"}}
                        placeholder={props.intl.formatMessage({
                          id: "contact.firstName.placeholder",
                          defaultMessage: "",
                        })}
                        style={{
                          marginTop: "22px",
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* <Col span={12}>
          
          <Form.Item
            label={<FormattedMessage id="contact.title" defaultMessage="" />}
            colon={false}
          >
            {getFieldDecorator("titleName", {
              initialValue: state.titleName || "",
            })(
              <DropdownRef
                items={props.salutations || []}
                optionKeyName="salutationId"
                valueKeyName="titleName"
                canAddNew={true}
                canDelete={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(
                    
                    MODAL_TYPE.SALUTATION,
                    payload
                  );
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false,
                  };
                  handleNewItemAddition(
                    
                    payload,
                    MODAL_TYPE.SALUTATION
                  );
                }}
                onSelect={(selectedValue, option) => {
                  props.updateState({
                    titleName: option.name,
                  });
                  props.form.setFieldsValue({
                    salutationId: selectedValue,
                  });
                  props.form.setFieldsValue({ titleName: option.name });
                }}
              />
            )}
          </Form.Item>

          </Col> */}
          </Row>

          <Row gutter={16}>
            {/* <Col span={12}>
          <Form.Item
            label={
              <span>
                <FormattedMessage id="contact.firstName" defaultMessage="" />
                <span className="required">*</span>{" "}
              </span>
            }
            colon={false}
          >
            {getFieldDecorator("firstName", {
              initialValue: state.firstName || "",
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage id="contact.firstName.message" />
                  ),
                },
              ],
            })(
              <TextBox
                maxLength={25}
                placeholder="firstName"
              />
            )}
          </Form.Item>

          </Col>
          */}

            <Col span={12}>
              <Form.Item
                label={
                  <span>
                  <FormattedMessage id="contact.lastName" defaultMessage="" />
                  <span className="required">*</span>{" "}
                </span>
                }
                colon={false}
              >
                {getFieldDecorator("lastName", {
                  initialValue: state.lastName || "",
                  rules: [
                    {
                      required: true,
                      message: (
                        "Last name is required"
                      ),
                    },
                  ],
                })(
                  <TextBox
                    maxLength={50}
                    countStyle={{ top: "10px"}}
                    placeholder={props.intl.formatMessage({
                      id: "contact.lastName.placeholder",
                      defaultMessage: "",
                    })}
                    // {props.intl.formatMessage({
                    //   id: "contact.lastName.placeholder",
                    //   defaultMessage: "",
                    // })}
                  />
                )}
              </Form.Item>
            </Col>

            {/* <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="countryCallingCode.text"
                    defaultMessage=""
                  />
                }
                colon={false}
              >
                {getFieldDecorator("countryCallingCode", {
                  initialValue: state.countryCallingCode || "",
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="countryCallingCode.validationMessage" />
                      ),
                    },
                  ],
                })(
                  <DropdownRef
                    items={
                      (props.countries || []).filter((el) => {
                        return el.countryCallingCode != null;
                      }) || []
                    }
                    optionKeyName="countryId"
                    valueKeyName="countryCallingCode"
                    valueKeyName2="countryName"
                    className="currency-tags"
                    onSelect={(selectedValue, option) => {
                      const selectedCountry = find(props.countries, {
                        countryId: selectedValue,
                      });
                      props.updateState({
                        countryCallingCode:
                          selectedCountry.countryCallingCode,
                      });
                      props.form.setFieldsValue({
                        countryId: selectedValue,
                      });
                      props.form.setFieldsValue({
                        countryCallingCode:
                          selectedCountry.countryCallingCode,
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Col> */}
        

        
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <FormattedMessage id="contact.mobile" defaultMessage="" />
                  </span>
                }
                colon={false}
              >
                  <div style={{ display: "flex" }}>

                 {getFieldDecorator("countryCallingCode", {
                  initialValue: props.formData?.countryCallingCode || state.countryCallingCode || "",
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="countryCallingCode.validationMessage" />
                      ),
                    },
                  ],
                })(
                  <DropdownRef
                  style={{ width: "25%" }}
                    items={
                      (props.countries || []).filter((el) => {
                        return el.countryCallingCode != null;
                      }) || []
                    }
                    optionKeyName="countryId"
                    valueKeyName="countryCallingCode"
                    // valueKeyName2="countryName"
                    className="currency-tags"
                    onSelect={(selectedValue, option) => {
                      const selectedCountry = find(props.countries, {
                        countryId: selectedValue,
                      });
                      props.updateState({
                        countryCallingCode:
                          selectedCountry.countryCallingCode,
                      });
                      props.form.setFieldsValue({
                        countryId: selectedValue,
                      });
                      props.form.setFieldsValue({
                        countryCallingCode:
                          selectedCountry.countryCallingCode,
                      });
                    }}
                  />
                )}
                
                {getFieldDecorator("cellPhone", {
                  initialValue: state.cellPhone || "",
                  rules: [
                    {
                      require:true,
                      pattern: /^[0-9\b]+$/,
                      message: "Please enter number only",
                    },
                  ],
                })(
                  <TextBox
                  maxLength={10}
                  placeholder="Mobile"
                  className="textbox-pd"
                  // prefix={state.countryCallingCode
                  //   ? state.countryCallingCode
                  //   : " "}
                  // {props.intl.formatMessage({
                    //   id: "contact.mobile.placeholder",
                    //   defaultMessage: "",
                    // })}
                    />
                    
                    )}
                    
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <FormattedMessage
                      id="contact.workPhone"
                      defaultMessage=""
                    />
                  </span>
                }
                colon={false}
              >
                  <div style={{ display: "flex" }}>

                  {getFieldDecorator("countryCallingCode", {
                  initialValue: props.formData?.countryCallingCode || state.countryCallingCode || "",
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="countryCallingCode.validationMessage" />
                      ),
                    },
                  ],
                })(
                  <DropdownRef
                  style={{ width: "25%" }}
                    items={
                      (props.countries || []).filter((el) => {
                        return el.countryCallingCode != null;
                      }) || []
                    }
                    optionKeyName="countryId"
                    valueKeyName="countryCallingCode"
                    // valueKeyName2="countryName"
                    className="currency-tags"
                    onSelect={(selectedValue, option) => {
                      const selectedCountry = find(props.countries, {
                        countryId: selectedValue,
                      });
                      props.updateState({
                        countryCallingCode:
                          selectedCountry.countryCallingCode,
                      });
                      props.form.setFieldsValue({
                        countryId: selectedValue,
                      });
                      props.form.setFieldsValue({
                        countryCallingCode:
                          selectedCountry.countryCallingCode,
                      });
                    }}
                  />
                )}
                {getFieldDecorator("workPhone", {
                  initialValue: state.workPhone || "",
                  rules: [
                    {
                      require:true,
                      pattern: /^[0-9\b]+$/,
                      message: "Please enter number only",
                    },
                  ],
                })(
                  <TextBox
                    maxLength={10}
                    placeholder="Work Phone"
                    className="textbox-pd"
                    // prefix={state.countryCallingCode
                    //   ? state.countryCallingCode
                    //   : " "}

                    // {props.intl.formatMessage({
                    //   id: "contact.mobile.placeholder",
                    //   defaultMessage: "",
                    // })}
                  />
                )}
                </div>
              </Form.Item>
            </Col>
        
        
            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="contact.faxNumber"
                    defaultMessage=""
                  />
                }
                colon={false}
              >
                {getFieldDecorator("faxNumber", {
                  initialValue: state.faxNumber || "",
                  
                })(
                  <TextBox
                    maxLength={15}
                    placeholder="Fax #"
                    countStyle={{ top: "10px"}}

                    // {props.intl.formatMessage({
                    //   id: "contact.faxNumber",
                    //   defaultMessage: "",
                    // })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <FormattedMessage id="contact.email" defaultMessage="" />
                    <span className="required">*</span>{" "}
                  </span>
                }
                colon={false}
              >
                {getFieldDecorator("emailAddress", 
                  {
                    initialValue: state.emailAddress || "",
                    rules: [
                      {
                        required: true,
                        message: (
                          "Email is required"
                        ),
                      },
                    ],
                  }
              )(
                  <TextBox
                    maxLength={50}
                    countStyle={{ top: "10px"}}
                    className={(state || {}).invalidEmailClass}
                    placeholder="Email"
                    // {props.intl.formatMessage({
                    //   id: "contact.email",
                    //   defaultMessage: "",
                    // })}
                    onChange={(e) => {
                      props.updateState({
                        emailAddress: e.target.value,
                        invalidEmailClass: validateEmail(e.target.value)
                          ? ""
                          : "input-text-error",
                      });
                    }}
                  />
                )}
                <ErrorMsg
                  validator={() => {
                    return (
                      !(state || {}).submittedOnce || !!(state || {}).emailAddress
                    );
                  }}
                  message="Email is required."
                  // {props.intl.formatMessage({
                  //   id: "signup.validation.email",
                  //   defaultMessage: "",
                  // })}
                />
              </Form.Item>
            </Col>
            </Row>
        </>
      </Form>

      {/* <ContactComponent {...props}/> */}
    </Drawer>
  );
}

export default Form.create({ name: "New_contact_component" })(
  injectIntl(ContactDrawer)
);
