import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, PURCHASE_REQUEST_ACTION_LIST, RFQ_TYPE, SO_ACTION_LIST, TX_TYPE, RFQ_ACTION_LIST, INQUIRY_ACTION_LIST, CONSTANTS } from '../../../../static/constants'
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils'
import * as find from 'lodash.find';
import { resetPaginatedData, getRFQCount } from '../Listing/action';
import * as filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createRFQ = (payloadObject) => {
  const props = payloadObject.props;
  const rfqData = _getRFQData(payloadObject);

  let formData = new FormData();
  if (props.fileList && props.fileList.length) {
    props.fileList.forEach((file) => {
      formData.append('files', file, file.name);
    })
  }
  formData.append('rfqData', JSON.stringify(rfqData, true));
  formData.append('bucketName' ,props.companyInfo?.bucketName)

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
        // TODO need to review and remove
        dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/rfqRest/createRFQ`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        dispatch({ type: RFQ_ACTION_LIST.NEXT_RFQ_NUMBER_RESET });
        resetPaginatedData()(dispatch);
        getRFQCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        showToasterMessage({ messageType: 'success', description: lang['saved.successfully'] });
        props.history.push('/admin/rfq-list');

        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          rfqDate: new Date(),
          supplierIdArr : props.supplierIdArr,
          salesQuotationMasterId: props.salesQuotationMasterId ,
          purchaseRequestMasterId: props.purchaseRequestMasterId,
          customerId: props.customerId  ,
          inquiryMasterId: props.inquiryMasterId,
          userId: (props.userInfo || {}).userId,
          rfqMasterId: props.rfqMasterId || props.rfqMasterId,
          pageNumber: 1,
          pageSize: 10
        };
        props.fetchAllRFQ(payload);
        props.popModalFromStack()
        props.openRFQDetails(res.data);
        if (props.isPRCconversion || props.isConvertedFromPR) {
          dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_LIST_PAGE_RESET });
          props.history.push('/admin/purchase-request-list');
        }
        props.updateState({
          prNumber:null
        })
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' });
      }).finally(()=>{
            // TODO need to review and remove
    dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER_STOP });
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}


export const draftRFQ = (payloadObject) => {
  const props = payloadObject.props;
  const rfqData = _getRFQData(payloadObject);

  // let formData = new FormData();
  //   if (props.fileList && props.fileList.length) {
  //     props.fileList.forEach((file)=>{
  //       formData.append('files', file, file.name);
  //     })
  //   }
  //formData.append('rfqData', JSON.stringify(rfqData, true));
  const draftData = {
    rfqData: JSON.stringify(rfqData),
    rfqParentDraftId: props.rfqParentDraftId,
    relationshipId: rfqData.relationshipId,
    version: props.operationType === 'clone'? 0 : (props.version || 0)
    //dateCreated: getCurrentDateForBackend(new Date())
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/rfqRest/saveOrUpdateDraft`, draftData)
      .then(res => {
        getRFQCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        showToasterMessage({ messageType: 'success', description: 'RFQ draft saved successfully' });
        props.history.push('/admin/rfq-list');
      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

const _getRFQData = (payload) => {
  const props = payload.props;
  const gridObject = payload.gridObject;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const rfqBiddingSuppliersList = [];
  // if(props.supplierDetail && props.selectedSuppliers?.length){
  //   rfqBiddingSuppliersList.push({
  //     supplierId:  props.supplierDetail?.supplierId,
  //     statusName: CONSTANTS.STATUS_TYPES.OPEN,
  //     relationshipId: companyInfo.relationshipId,
  //     supplierName: props.supplierDetail?.supplierStoreName
  //   });
  // }
  props.selectedSuppliers && props.selectedSuppliers.map((e) => {

  
    if (typeof e === 'number') {
      const supplier = find(props.selectedSupplierObjects, { supplierId: Number(e) }) || {};
      return rfqBiddingSuppliersList.push({
        supplierId: supplier.supplierId || props.rfqPrData?.supplierId,
        statusName: CONSTANTS.STATUS_TYPES.OPEN,
        relationshipId: companyInfo.relationshipId,
        supplierName: supplier.supplierName || props.rfqPrData?.supplierName
      });
    } else {

      const supplier = find(props.rfqBiddingSuppliersList, { supplierName: e }) || {};
      return rfqBiddingSuppliersList.push({
        supplierId: supplier.supplierId || props.rfqPrData?.supplierId,
        relationshipId: companyInfo.relationshipId,
        statusName: CONSTANTS.STATUS_TYPES.OPEN,
        supplierName: supplier.supplierName || props.rfqPrData?.supplierName
      });
    }

  })
  const financialYear = getFinancialYear(companyInfo);
  const oldRfqDetailData = props.operationType === 'clone' ? {} : props.oldRfqDetailData;

  let prMaster = {
    ...oldRfqDetailData,
    version: props.operationType === 'clone' || !props.rfqMasterId ? 0 : props.version,
    rfqParentDraftId: props.rfqParentDraftId,
    customerInquiryNumber: props.customerInquiryNumber,
    customerPONumber: props.customerPONumber,
    customerRFQNumber: props.customerRFQNumber,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    relationshipName: companyInfo && companyInfo.storeName,
    relationshipId: companyInfo.relationshipId,
    rfqDate: props.rfqDate && formatDateForBackend(props.rfqDate),
    rfqExpirationDate: props.expirationDate && formatDateForBackend(props.expirationDate),
    status: null,
    isInquiryConversion: (props.isInquiryConversion && !props.isClone) ? 1 : 0,
    inquiryMasterId: props.inquiryMasterId,
    rfqMasterId: props.operationType === 'clone' ? 0 : props.rfqMasterId,
    txType: RFQ_TYPE.STANDARD,
    remarksInternal: props.internalRemarks,
    remarksSupplier: props.supplierRemarks,
    footer: props.footer,
    requestingDepartment: props.departmentName,
    prRequestedBy: props.prRequestedBy,
    purchaseType: TX_TYPE.PO,
    projectNumber: props.projectNumber,
    projectName: props.projectName,
    projectMasterId: props.projectMasterId,
    docDetailList: props.rfqData?.docDetailList,
    isPRCconversion: ((props.isPRCconversion || props.isConvertedFromPR) && !props.isClone) ? 1 : 0,
    orderPriority: props.orderPriority,
    boLocationRFQList: [],
    customerId: (props.customer || {}).customerId,
    customerName: props.customerName,
    rfqBiddingSuppliersList: rfqBiddingSuppliersList,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    createdByUserId: userInfo.userId,
    rfqDetailsList: [],
    documentName: props.docName,
    refNumber: props.refNumber,
    salesPersonId: props.salesPersonId,
    salesPersonFullName: props.salesPersonName,
    purchaseRequestMasterId: props.purchaseRequestMasterId,
    purchaseRequestNumber: props.prNumber,
    purchaseRequestDate: props.purchaseRequestDate && formatDateForBackend(props.purchaseRequestDate),
    prExpirationDate: props.prExpirationDate && formatDateForBackend(props.prExpirationDate),
  };

  if (props.relationshipAddress && props.relationshipAddress.streetAddress1) {
    const relationshipAddress = props.relationshipAddress;
    if (props.operationType === 'clone') {
      delete relationshipAddress.locationRFQId;
    }
   // relationshipAddress.dateCreated = getCurrentDateForBackend(new Date());
    //relationshipAddress.dateUpdated = getCurrentDateForBackend(new Date());
    prMaster.boLocationRFQList.push(relationshipAddress);
  }

  if (props.customerBillingAddress && props.customerBillingAddress.streetAddress1) {
    const customerBillingAddress = props.customerBillingAddress;
    if (props.operationType === 'clone') {
      delete customerBillingAddress.locationRFQId;
    }
   //customerBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
   // customerBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    prMaster.boLocationRFQList.push(customerBillingAddress);
  }

  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    if(!data.itemId && !data.description && !data.qty && !data.rate){
      return
    }
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    //if (!itemId) return;
    let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
    if(data.productObj && !data.productObj.hasVariant && !variantId){
      variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId: data.inventoryItemVariantId;
    }
    let product = {};
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }
    prMaster.rfqDetailsList.push({
      rfqDetailsId: props.operationType === 'clone' ? 0 : data.rfqDetailsId,
      rfqMasterId: props.operationType === 'clone' ? 0 : props.rfqMasterId,
      relationshipId: companyInfo && companyInfo.relationshipId,
      version: props.operationType === 'clone'? 0 : data.version,
      quantity: Number(data.qty),
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      productTaxId: data.productTaxId,
      txDate: formatDateForBackend(props.invoiceDate),
      itemName: product.itemName,
      itemId: product.itemId,
      description: data.description,
      rowHeight: data.scrollHeight,
      hsnCode: data.hsnCode,
      itemVariantId: variantId,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      inquiryMasterId: props.inquiryMasterId,
      purchaseRequestMasterId: props.purchaseRequestMasterId || data.purchaseRequestMasterId,
      purchaseRequestNumber: props.prNumber || data.prNumber,
      parentDetailsId: data.parentDetailsId
    });
  });

  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact }
    // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
    //  additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    // additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.customer) {
    additionalInfo.customerId = props.customer && props.customer.customerId;
    //additionalInfo.gstNumber = props.customer.gstNumber;
    // additionalInfo.panNumber = props.customer.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
  }

  if (props.selectedSupplierTaxIdentifications && props.selectedSupplierTaxIdentifications.length) {
    let selectedSupplierTaxIdentifications = filter(props.supplier.supplierTaxIdentificationsList, (taxObj) => {
      return props.selectedSupplierTaxIdentifications.indexOf(taxObj.supplierTaxIdentificationId) > -1;
    });
    additionalInfo.taxIdentifications = JSON.stringify(selectedSupplierTaxIdentifications);
  }
  if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
    let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
      return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
    });
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
  }
  prMaster.additionalInfoList = [additionalInfo];
  return prMaster;
}


export const deleteDocument = (payload,props) => {
  return dispatch => {
    let formData = new FormData();
    formData.append("relationshipId", payload.relationshipId);
    formData.append('bucketName', props.companyInfo.bucketName);
    formData.append('objectKey', payload.awsKeyName);
    formData.append('folderName', payload.folderName);
    formData.append('rfqMasterId', payload.rfqMasterId);
   
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/rfqRest/deleteRfqDocument`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchSupplierRFQDetail(payload);
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}


export const fetchSQDataForSO = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesQuotation/getSalesQuotationDataBySalesQuotationMasterId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&salesQuotationMasterId=${payload.salesQuotationMasterId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SQ_SO_DATA, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.SQ_SO_DATA;
        }
      })
  }
}

export const resetSalesQuoteSOData = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.RESET_SQ_SO_DATA });
  }
}




export const fetchRfqDataForPr = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/getPurchaseRequestsForRFQ?prMasterId=${payload.purchaseRequestMasterId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.RFQ_PR_DATA, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = PURCHASE_REQUEST_ACTION_LIST.RFQ_PR_DATA;
        }
      })
  }
}

export const resetRfqPrData = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.RESET_RFQ_PR_DATA });
  }
}

export const getNextTxNumber = (payload) => {
  let date = payload.rfqDate ? getCurrentDateForBackend(payload.rfqDate) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: RFQ_ACTION_LIST.NEXT_RFQ_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/rfqRest/getNextRFQNumber?rfqDate=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: RFQ_ACTION_LIST.NEXT_RFQ_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = RFQ_ACTION_LIST.NEXT_RFQ_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}

export const fetchInquiryDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/inquiry/getInquiryDataForRFQ?inquiryMasterId=${payload.inquiryMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`;
    return axios
      .get(url)
      .then(res => {
        const { data } = res
        dispatch({ type: INQUIRY_ACTION_LIST.ENQUIRY_DETAIL, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = INQUIRY_ACTION_LIST.ENQUIRY_DETAIL;
        }
      })
  }
}

export const resetEnquiryDetail = () => {
  return dispatch => {
    dispatch({ type: INQUIRY_ACTION_LIST.RESET_ENQUIRY_DETAIL });
  }
}


export const fetchRfqData = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/rfqRest/getRFQsByRFQId?rfqMasterId=${payload.rfqMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: RFQ_ACTION_LIST.RFQ_DETAIL, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = RFQ_ACTION_LIST.RFQ_DETAIL;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}


export const resetRfqData = (payload) => {
  return dispatch => {
    dispatch({ type: RFQ_ACTION_LIST.RESET_RFQ_DETAIL });
  }
}