import React, { useMemo } from "react";
import { ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";
import { FormattedMessage, injectIntl } from "react-intl";
import { Switch, Menu, Button, Empty, Skeleton, Pagination } from "antd";
import DropdownAction from "antd/lib/dropdown";
import { checkACLPermission, fetchPaginationDataIfNeeded, getPermissionData } from "../../../utils";


function DesignationComp(props) {
    const { companyInfo, designationList, pageNumber, pageSize ,permissions } = props
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = useMemo(()=>{
        return getPermissionData(primaryPerm , MODULE_CODED_VALUES.HRMS_EMPLOYEE);
      },[])

    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Actions
                </Menu.Item>
                <Menu.Divider />
{
    permissionsData?.update ?  <Menu.Item
    key="1"
    value="edit"
    onClick={() => {
        props.updateState({
            designationDrawer: true,
            designationItem: e,
        })
    }}
>
    <i className={ICONS.EDIT} /> Edit
</Menu.Item>:""
}
               
{
    permissionsData?.delete ?   <Menu.Item
    key="2"
    value="delete"
    onClick={() => {
        props.showModal({
            modalBody: "Are you sure want to delete?",
            handleSubmit: () => {
                props.deleteDesignation(e, props)

            },
        });
    }}
>
    <i className={ICONS.DELETE} /> Delete
</Menu.Item>:""
}
              
            </Menu>
        );
    }
    const showTotal = (total) => {
        return (
            props.intl.formatMessage({
                id: "pagination.text.total",
                defaultMessage: "",
            })
            +
            ` ${total} ` +
            props.intl.formatMessage({
                id: "pagination.text.items",
                defaultMessage: "",
            })
        );
    };
    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        fetchPaginationDataIfNeeded('getDesignation', 'designationList', props, payload);

    };
    const handlePageSizeChange = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        // props.resetPaginatedData();
        props.getDesignation(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    };
    return (
        <><div className="view-container">
            <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage
                                id="employeeMeta.designation.pageHeading"
                                defaultMessage="" />
                            <div className="table-sub-heading">
                                Manage designation meta here
                            </div>
                        </div>
                        <div className="vertical-line" />
                        <div className="table-count">{props.designationListCount}</div>
                    </div>

                    <div className="right-actions">
                        {
                            permissionsData?.create ? <Button
                            style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
                            className="create-action-btn"
                            onClick={() => {
                                props.updateState({ designationDrawer: true, designationItem: {} });
                            }}
                        >
                            <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
                            <FormattedMessage id="button.create.label" defaultMessage="" />
                        </Button>:""
                        }
                        
                    </div>
                </Skeleton>
            </div>

            <div className="table-container" >
                <Skeleton loading={props.listLoading} paragraph={{ rows: 18 }} active>
                    <table id="designation-table">
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="serial.no" defaultMessage=" " />

                                </th>
                                {/* <th>
                                    Department
                                </th> */}
                                <th>Designation</th>
                                <th>
                                    Designation Code
                                </th>

                                <th>
                                    Status
                                </th>
                                {
                                     (permissionsData?.update || permissionsData?.delete) ?  <th width="6%">Action</th>: ""
                                }
                               
                            </tr>
                        </thead><tbody>
                            {designationList?.[pageNumber]?.length > 0 ?
                                designationList[pageNumber].map((rowData, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{(pageNumber - 1) * pageSize + (i + 1)}</td>
                                            <td>{rowData.designationName || "-"}</td>
                                            <td>{rowData.designationCode}</td>
                                           
                                            <td>
                                                <div className="status-toggle">
                                                    <Switch
                                                    disabled={!(permissionsData?.update)}
                                                        checked={rowData.status === 1}
                                                        onClick={(checked, e) => {
                                                            props.setStatus(
                                                                {
                                                                    designationId: rowData.designationId,
                                                                    relationshipId: rowData.relationshipId,
                                                                    status: checked ? 1 : 0
                                                                }, props
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            {
                                                 (permissionsData?.update || permissionsData?.delete) ? <td className="text-center">
                                                <DropdownAction

                                                    overlay={actionMenu(rowData)}
                                                    trigger={["click"]}
                                                >
                                                    <i className={ICONS.MORE} />
                                                </DropdownAction>
                                            </td>: ""
                                            }
                                            
                                        </tr>
                                    );
                                }) :
                                <tr key="empty-data-box">
                                    <td colSpan={5} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                            }




                        </tbody>
                    </table>
                </Skeleton>
            </div>
            <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={props.designationListCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize}
                            showSizeChanger
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props);
                            }}

                        />
                    </div>
                </Skeleton>
            </div>
        </div>
        </>
    )
}
export default injectIntl(DesignationComp);