import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  FileTextOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Switch,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Radio,
  Tabs,
  Divider,
} from "antd";
import { Dropdown } from "../../../../general/Dropdown";
import { TextBox } from "../../../../general/TextBox";
import {
  showToasterMessage,
  getMomentDateForUI,
  getMonthsBetweenDates,
  getUserDefinedName,
  getCurrencySymbol,
  getLocalDateFromUtcStr,
  getMomentMonthCountBetweenDates,
} from "../../../../../utils";
import "material-ui-rc-color-picker/assets/index.css";
import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
  ICONS,
  MODULE_CODED_VALUES,
} from "../../../../../static/constants";
import "./index.scss";
import RegistrationFormPreviewComponent from "../../../../../components/modal/modalBody/settings/RegistrationFormSettingPreview/inPagePreview";
import * as filter from "lodash.filter";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION_FORM2 } from "../../../../../static/ckEditorConfigConstants";
import * as find from "lodash.filter";
// import RegistrationInvoiceSetting from "../../../../../containers/modal/modalBody/settings/RegistrationInvoiceSetting";
import RegistrationPaymentPlan from "../../../../../containers/modal/modalBody/settings/RegistrationPaymentPlan";
import ReminderSetupComponent from "../../../../../containers/modal/modalBody/settings/ReminderSetupForForm";
import EmailTemplate from "../../../../../containers/modal/modalBody/settings/EmailTemplate";
import DiscountRuleCompo from "../../../../../containers/modal/modalBody/settings/RegistrationForm/DiscountRule";
import FeeStructureComp from "../../../../../containers/modal/modalBody/settings/RegistrationForm/FeeStructure";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { Input } from "antd";
import _ from 'lodash';
// import { Form } from '@ant-design/compatible';
// import { MultipleSelectDropdown } from '../../../../general/MultipleSelectDropdown';
// import { TextArea } from "../../../../general/TextAreaComponent";
// import RegistrationFormComponent from '../../../../../containers/modal/modalBody/settings/RegistrationFormSettingPreview';
// import ColorPicker from 'material-ui-rc-color-picker';
// import MobleLogo from "../../../../../assets/images/phone-icon.png";
// import TableLogo from "../../../../../assets/images/tablet-icon.png";
// import NewsLetterShare from '../../../../modal/modalBody/communication/NewsLetterShare';
// import MobilePreview from '../../../../../components/modal/modalBody/settings/RegistrationFormSettingPreview/mobilePreview';
// import TabPreview from '../../../../../components/modal/modalBody/settings/RegistrationFormSettingPreview/tabPreview';
// import RecurringInvoiceSetup from '../../../../../containers/modal/modalBody/settings/RecurringInvoiceSetupForForm';
// import SetupSubcourseAndFees from '../../../../../containers/modal/modalBody/settings/RegistrationForm/SetupSubcourseAndFees';
const { TextArea } = Input;
const { TabPane } = Tabs;

class RegistrationFormSettingComponent extends Component {

  render() {
    const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    const { updateState, classFees = [{}], classList } = this.props;
    const updatedClassFees = (this.props.classFees || [])?.map(classFee => ({
      ...classFee,
      feeStructList: [] 
    }));

    const removeQuestion = (props, index) => {
      const modalData = {
        modalBody: (
          <FormattedMessage
            id="deleteItem.confirmation"
            values={{ value: `question ${index + 1}` }}
          />
        ),
        handleSubmit: () => {
          let questionList = props.questionList;
          questionList.splice(index, 1);
          props.updateState({ questionList });
        },
      };
      props.pushModalToStack(modalData);
    };

    const getFormSettingPermissionList = (props) => {
      let permList = [
        {
          relationshipId: (props.companyInfo || {}).relationshipId,
          registrationFormSettingId: props.registrationFormSettingId,
          relEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
        },
      ];
      // props.userPermissions.forEach(function (userId) {
      //     if (userId !== (props.userInfo || {}).relationshipEmployeeId) {
      //         permList.push({
      //             relationshipId: (props.companyInfo || {}).relationshipId,
      //             registrationFormSettingId: props.registrationFormSettingId,
      //             relEmployeeId: userId,
      //         })
      //     }
      // });
      return props.registrationFormSettingId
        ? props.orgRegFormSettingPermissionList
        : permList;
    };

    const getFormSetting = (props) => {
     
      return {
        formName: props.formName,
        formShortName: props.formShortName,

        showFatherInfo: props.showFatherInfo || 0,
        fatherInfoRequired: props.fatherInfoRequired || 0,

        showMotherInfo: props.showMotherInfo || 0,
        motherInfoRequired: props.motherInfoRequired || 0,

        emergencyContactDisplay: props.emergencyContactDisplay || 0,
        emergencyContactRequired: props.emergencyContactRequired || 0,
        emergencyPhoneDisplay: props.emergencyPhoneDisplay || 0,
        emergencyPhoneRequired: props.emergencyPhoneRequired || 0,

        addressDisplay: props.addressDisplay,
        addressRequired: props.addressRequired,

        paymentTermName: (props.paymentTerm || {}).termName,
        paymentTermId: (props.paymentTerm || {}).paymentTermId,
        numberOfDays: (props.paymentTerm || {}).numberOfDays,

        textColor: props.textColor,
        bgColor: props.bgColor,

        questionList: props.questionList,
        collectStudentInfo: props.collectStudentInfo,
        schoolLevelValidation: props.schoolLevelValidation,
        maxStudent: props.schoolLevelValidation ? props.maxStudent : 0,
        gradeLevelValidation: props.gradeLevelValidation,
        gradeValidations: {},
        totalStudentCountByGrade: {},
        collectOnlinePayment: props.collectOnlinePayment,
        classFees: props.collectOnlinePayment ? (props.classFees || []) : updatedClassFees,
        paymentGateway: props.selectedPaymentGateway,
        paymentGatewayId: props.selectedPaymentGateway?.alpidePaymentGatewayId || 0,
        userDefinedGatewayName: props.selectedPaymentGateway?.userDefinedGatewayName,
        paymentGatewayName: props.selectedPaymentGateway?.paymentGatewayName,

        subHeading: this.props.subHeading,
        inactiveMessage: props.inactiveMessage,
        registrationType: props.registrationType,

        phoneDisplay: props.phoneDisplay,
        phoneRequired: props.phoneRequired,
        genderDisplay: props.genderDisplay,
        genderRequired: props.genderRequired,
        dobDisplay: props.dobDisplay,
        dobRequired: props.dobRequired,
        emailDisplay: props.emailDisplay,
        emailRequired: props.emailRequired,

        formDescription: props.formDescription,
        footerMessage: props.footerMessage,
        feeAmount: props.collectOnlinePayment ? props.feeAmount : 0,

        familyDoctorNameDisplay: props.familyDoctorNameDisplay || 0,
        familyDoctorNameRequired: props.familyDoctorNameRequired || 0,
        familyDoctorPhoneDisplay: props.familyDoctorPhoneDisplay || 0,
        familyDoctorPhoneRequired: props.familyDoctorPhoneRequired || 0,

        primaryContactForInvoicing: props.primaryContactForInvoicing,

        sessionStart: props.sessionStart,
        sessionEnd: props.sessionEnd,
        totalMonthSchoolSession: getMonthsBetweenDates(
          props.sessionStart,
          props.sessionEnd
        ),
        isDisplayYearlyFee: props.isDisplayYearlyFee
          ? props.isDisplayYearlyFee
          : 0,

        isStopRegistrationAfterMaxStudent: props.isStopRegistrationAfterMaxStudent || 0,
        registrationStopMessage: props.registrationStopMessage,

        acceptRegistrationAfterMaxStudent: props.acceptRegistrationAfterMaxStudent,
        warningMessage: props.warningMessage,
        
        invalidGradeMessage: props.invalidGradeMessage,
        askFinanacialAssistance: props.askFinanacialAssistance,
        askOrgMember: props.askOrgMember,
        askFinanacialAssistanceRequired: props.askFinanacialAssistanceRequired,
        askOrgMemberRequired: props.askOrgMemberRequired,
        documentNameId: props.documentNameId,
        docName: props.docName,
        invoiceTitle: props.invoiceTitle,
        invoiceTitleOnetime: props.invoiceTitleOnetime,
        askAddTeacher: props.askAddTeacher,
        askAddTeacherRequired: props.askAddTeacherRequired,
        askAddVolunteer: props.askAddVolunteer,
        askAddVolunteerRequired: props.askAddVolunteerRequired,
        isApplyDiscountToOnetimeInvoice: props.isApplyDiscountToOnetimeInvoice
          ? props.isApplyDiscountToOnetimeInvoice
          : 0,
        educationHistory: props.educationHistory || 0,
        educationHistoryRequired: props.educationHistoryRequired || 0,
        dobType: this.props.dobType || 1,
      };
    };

    const validateTab1 = (props) => {

      if (!props.formHeading) {
        return "registration.formHeading.required";
      }

      if (
        props.registrationType === "student" &&
        (!props.sessionStart || !props.sessionEnd)
      ) {
        return "registation.validation.schoolSession";
      }

      return false;
    };

    const validateTab2 = (props) => {
      return false;
    };

    const validateTab3 = (props) => {
      const isValidClassWithFee = classFees.reduce((acc, item) => {
        return (
          acc &&
          item.className &&
          item.classId &&
          (item?.feeStructList || []).length > 0 &&
          !item.feeStructList.some((fee) => (fee?.amount || 0) <= 0)
        );
      }, true);

      const isValidClass = classFees.every(
        (obj) => obj.hasOwnProperty("classId") && obj.classId
      );

      if ((classFees || []).length === 0) {
        return "registration.class.selected.atleastOne";
      }

      if (!isValidClass) {
        return "registration.class.invalid";
      }
      if (!isValidClassWithFee) {
        return "registration.class.feeStruct.feeZero";
      }
      // if (props.collectOnlinePayment && !isValidClassWithFee) {
      //   return "registration.class.feeStruct.feeZero";
      // }

      return false;
    };

    const validateTab4 = (props) => {
      if (props.collectOnlinePayment && !props.selectedPaymentGateway) {
        return "paymentGateway.notAvailable.message";
      }
      if (props.collectOnlinePayment && !props.ledgerAccountIdInvoice) {
        return "registration.fees.ledger.validationMessage";
      }
      if (props.collectOnlinePayment && !props.invoiceItemDescription) {
        return "registration.fees.ledgerDescription.validationMessage";
      }
      if (props.collectOnlinePayment && !props.paymentTerm) {
        return "registration.fees.paymentTerm.validationMessage";
      }
      // if (!props.paymentTerm || !props.paymentTerm.termName) {
      //     return 'registration.paymentTerm.required'
      // }

      // if (props.isGenerateInvoice === 1 && !props.invoiceItemDescription) {
      //     return 'invoiceItemDescription.validationMessage';
      // }
      // if (props.isGenerateInvoice && !props.ledgerAccountIdInvoice) {
      //     return 'invoiceLedgerAccount.notSelected.message';
      // }

      if (props.collectOnlinePayment && (!props.paymentPlan || !props.paymentPlan.length)) {
          return 'registration.parentPaymentPlan.required';
      }
      // if (props.isGenerateInvoice && (!props.isAllowParentPaymentPlan && props.invoiceCycle !== 'monthly' && props.invoiceCycle !== 'oneTime')) {
      //     return 'registration.feeSetup.required';
      // }
      // if (props.isGenerateInvoice && (props.isAllowParentPaymentPlan || props.invoiceCycle === 'monthly') && (!props.templateData || !props.templateData.settingEmailTemplateId)) {
      //     return 'recurringInvoiceEmailTemplate.validation';
      // }
      return false;
    };

    const validateTab5 = (props) => {
      return false;
    };

    const validateTab6 = (props) => {
      return false;
    };

    const validateTab = (props) => {
      let errorCode = "";
      switch (this.props.activeTabKey) {
        case "1":
          errorCode = validateTab1(props);
          if (errorCode) {
            return showToasterMessage({
              description: props.intl.formatMessage({ id: errorCode }),
              messageType: "error",
            });
          } else
            props.updateState({
              activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
            });
          break;
        case "2":
          errorCode = validateTab2(props);
          if (errorCode)
            return showToasterMessage({
              description: props.intl.formatMessage({ id: errorCode }),
              messageType: "error",
            });
          else
            props.updateState({
              activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
            });
          break;
        case "3":
          errorCode = validateTab3(props);
          if (errorCode)
            return showToasterMessage({
              description: props.intl.formatMessage({ id: errorCode }),
              messageType: "error",
            });
          else
            props.updateState({
              activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
            });
          break;
        case "4":
          errorCode = validateTab4(props);
          if (errorCode)
            return showToasterMessage({
              description: props.intl.formatMessage({ id: errorCode }),
              messageType: "error",
            });
          else
            props.updateState({
              activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
            });
          break;
        case "5":
          errorCode = validateTab5(props);
          if (errorCode)
            return showToasterMessage({
              description: props.intl.formatMessage({ id: errorCode }),
              messageType: "error",
            });
          else
            props.updateState({
              activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
            });
          break;
        case "6":
          errorCode =
            validateTab1(props) ||
            validateTab2(props) ||
            validateTab3(props) ||
            validateTab4(props) ||
            validateTab5(props) ||
            validateTab6(props);
          if (errorCode) {
            showToasterMessage({
              description: props.intl.formatMessage({ id: errorCode }),
              messageType: "error",
            });
            return true;
          } else return false;
        // break;
        default:
          break;
      }
    };

    const saveSettings = (props) => {
     
      if (validateTab(props)) {
        return;
      }

      const modalData = {
        modalBody: <FormattedMessage id="save.confirmation" />,
        handleSubmit: () => {
          let formSetting = getFormSetting(props);
          let payload = {
            formShortName: props.formShortName,
            relationshipId: (props.companyInfo || {}).relationshipId,
            registrationFormSettingId: props.registrationFormSettingId,
            updatedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            createdByEmpId:
              props.createdByEmpId ||
              (props.userInfo || {}).relationshipEmployeeId,
            version: props.version,
            registrationType: props.registrationType || "student",
            departmentId: props.departmentId,
            programName: props.formHeading,
            formType: props.formType,
            formSetting: JSON.stringify(formSetting),
            isSendEmail: props.isSendEmail,
            successMessage: props.successMessage,
            emailMessage: props.isSendEmail
              ? (props.emailDetails || {}).message
              : "",
            emailSubject: props.isSendEmail
              ? (props.emailDetails || {}).subject
              : "",
            isFormActive: props.isFormActive,
            ledgerAccountIdPayment: props.ledgerAccountIdPayment,
            classFees: props.collectOnlinePayment ? JSON.stringify(props.classFees || []) : JSON.stringify(updatedClassFees || []),
            isGenerateInvoice: props.collectOnlinePayment ? 1 : 0,
            isCollectPaymentAtRegistration: props.isCollectPaymentAtRegistration ? 1 : 0,
            ledgerAccountIdInvoice: props.ledgerAccountIdInvoice,
            invoiceCycle: props.invoiceCycle,
            isGenerateOnetimeInvoice: props.invoiceCycle === "oneTime" ? 1 : 0,
            isGenerateRecurringInvoice:
              props.invoiceCycle === "monthly" &&
              !props.isAllowParentPaymentPlan
                ? 1
                : 0,
            isAllowParentPaymentPlan: props.isGenerateInvoice
              ? props.isAllowParentPaymentPlan
              : 0,
            // isAllowParentPaymentPlan:props.isAllowParentPaymentPlan,
            recurringInvoiceSettings:
              props.invoiceCycle === "monthly"
                ? JSON.stringify(props.recurringInvoiceSettings || {})
                : "{}",
            addEnrolmentToDG: props.addEnrolmentToDG,
            invoiceItemDescription: props.invoiceItemDescription,
            onetimeInvoiceItem: JSON.stringify(props.invoiceItem || []),
            paymentPlan: JSON.stringify(props.paymentPlan || []),
            courseId: props.courseId,
            invoiceReminder: JSON.stringify(props.reminderList || []),
            isPublishParentPortal: props.isPublishParentPortal,
            invoiceDiscountRule: JSON.stringify(props.discountRuleList || []),
            schoolSessionId: props.schoolSessionId,
            orgRegFormSettingPermissionList: getFormSettingPermissionList(props),
            registrationFormSettingDraftId: this.props.registrationFormSettingDraftId || 0
          };

          props.saveRegistrationFormSetting(payload, props);
        },
      };
      props.pushModalToStack(modalData);
    };

    // const previewForm = (props) => {
    //     let formSetting = getFormSetting(props);
    //     this.props.pushModalToStack({
    //         modalBody: <RegistrationFormComponent formSetting={formSetting} registrationFormSettingId={props.registrationFormSettingId} relationshipId={(this.props.companyInfo || {}).relationshipId} modalType='preview' />,
    //         title: <FormattedMessage id='preview.text' />,
    //         width: '600',
    //         hideFooter: true,
    //         wrapClassName: 'modal-custom-detail '
    //     })
    // }

    // const mobilePreviewPopup = (props) => {
    //     let formSetting = getFormSetting(props);
    //     this.props.pushModalToStack({
    //         modalBody: <MobilePreview {...this.props} formSetting={formSetting} registrationFormSettingId={props.registrationFormSettingId} relationshipId={(this.props.companyInfo || {}).relationshipId} modalType='preview' />,
    //         // title: <FormattedMessage id='preview.text' />,
    //         title: "Mobile Preview",
    //         width: '300px',
    //         hideFooter: true,
    //         wrapClassName: 'modal-custom-detail-new '
    //     })
    // }
    // const tabPreviewPopup = (props) => {
    //     let formSetting = getFormSetting(props);
    //     this.props.pushModalToStack({
    //         modalBody: <TabPreview {...this.props} formSetting={formSetting} registrationFormSettingId={props.registrationFormSettingId} relationshipId={(this.props.companyInfo || {}).relationshipId} modalType='preview' />,
    //         // title: <FormattedMessage id='preview.text' />,
    //         title: "Tablet Preview",
    //         width: '450px',
    //         hideFooter: true,
    //         wrapClassName: 'modal-custom-detail-tab '
    //     })
    // }

    const openEmailTemplates = (props) => {
      const modalProps = { ...props };
      const modalData = {
        title: (
          <FormattedMessage
            id="setting.emailTemplates.label"
            defaultMessage=""
          />
        ),
        modalBody: (
          <EmailTemplate
          {...modalProps}
            docName="Recurring Sales Invoice"
            isFormSetting={true}
          />
        
        ),
        width: 800,
        hideFooter: true,
        wrapClassName: "show-setting-email-template",
      };
      props.pushModalToStack(modalData);
    };
   

    // const openRecurringSetting = (props) => {
    //     const modalData = {
    //         title: <FormattedMessage id='popup.recurringInvoice.title' defaultMessage='' />,
    //         modalBody: <RecurringInvoiceSetup {...props} isBulkInvoice={true} isFormSetting={true} addRecurringInvoiceSettings={(data) => { props.updateState({ recurringInvoiceSettings: data }) }} />,
    //         width: '50%',
    //         hideFooter: true,
    //     };

    //     props.pushModalToStack(modalData);
    // }

    // const getOneTimeChargesTotalAmount = (props) => {
    //   if (!props.invoiceItem || !props.invoiceItem.length) {
    //     return 0.0;
    //   }
    //   let total = 0;
    //   props.invoiceItem.forEach(function (item) {
    //     if (item.amount) {
    //       total += Number(item.amount);
    //     }
    //   });
    //   return total.toFixed(2);
    // };

    // const openRegistrationInvoiceSetting = (props) => {
    //   const modalData = {
    //     title: (
    //       <div>
    //         <FormattedMessage id="invoiceDetails.text" defaultMessage="" />
    //         <div style={{ fontSize: "12px" }}> Onetime invoice charges</div>
    //       </div>
    //     ),
    //     modalBody: (
    //       <RegistrationInvoiceSetting
    //         {...props}
    //         isBulkInvoice={true}
    //         isFormSetting={true}
    //         addInvoiceItemList={(data, invoiceTitleOnetime) => {
    //           props.updateState({
    //             invoiceItem: data,
    //             invoiceTitleOnetime: invoiceTitleOnetime,
    //           });
    //         }}
    //       />
    //     ),
    //     width: "50%",
    //     hideFooter: true,
    //   };

    //   props.pushModalToStack(modalData);
    // };

    const openAddPaymentPlan = (props) => {
      if (!props.sessionStart || !props.sessionEnd) {
        return showToasterMessage({
          description: props.intl.formatMessage({
            id: "registation.validation.schoolSession",
          }),
          messageType: "error",
        });
      }
      const modalData = {
        title: (
          <div>
            <FormattedMessage id="addPaymentPlan.text" defaultMessage="" />
          </div>
        ),
        modalBody: (
          <RegistrationPaymentPlan
            {...props}
            isBulkInvoice={true}
            isFormSetting={true}
            addPaymentPlans={(data) => {
              props.updateState({ paymentPlan: data });
            }}
          />
        ),
        width: "50%",
        hideFooter: true,
      };

      props.pushModalToStack(modalData);
    };

    const openAddDiscountRule = (props) => {
      const modalData = {
        title: <FormattedMessage id="discountRule.text" defaultMessage="" />,
        modalBody: (
          <DiscountRuleCompo
            {...props}
            addDiscountRuleList={(data, isApplyDiscountToOnetimeInvoice) => {
              props.updateState({
                discountRuleList: data,
                isApplyDiscountToOnetimeInvoice:
                  isApplyDiscountToOnetimeInvoice,
              });
            }}
          />
        ),
        width: "60%",
        hideFooter: true,
      };

      props.pushModalToStack(modalData);
    };

    const openAddReminder = (props) => {
      props.pushModalToStack({
        title: (
          <div>
            <FormattedMessage id="dueFeeReminder.text" defaultMessage="" />
          </div>
        ),
        modalBody: (
          <ReminderSetupComponent
            {...props}
            isFormSetting={true}
            addReminderSetting={(data) => {
              // let reminderList = props.reminderList || [];
              // reminderList.push(data);
              props.updateState({ reminderList: data });
            }}
          />
        ),
        width: "50%",
        hideFooter: true,
      });
    };
   const handleNewPaymentTermAddition = (props, payload) => {
      const formData = payload.formData;
      const { showModal, hideModal, companyInfo } = props;
      formData.relationshipId = companyInfo.relationshipId;
      const data = {
        title: (
          <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
        ),
        formData,
        hideFooter: true,
        modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
        handleSubmit: (formData = {}) => {
          if (
            formData.termName &&
            formData.termName.length &&
            formData.days !== 0 &&
            formData.days
          ) {
            hideModal();
            formData.relationshipId = (props.companyInfo || {}).relationshipId;
            props.addPaymentTerm(formData);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    };
    // const handleNewProjectAddition = (props, payload) => {
    //     const formData = payload.formData;
    //     const { showModal, companyInfo } = props;
    //     formData.relationshipId = companyInfo.relationshipId;
    //     const data = {
    //         title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
    //         formData,
    //         hideFooter: true,
    //         modalData: {
    //             modalType: MODAL_TYPE.PROJECT,
    //             data: payload,

    //         },
    //         handleSubmit: (formData = {}) => {
    //             data.formData.submittedOnce = true;
    //             showModal(data);
    //         }
    //     };
    //     showModal(data);
    // }

    const handleNewSessionAddition = (props, payload) => {
      const formData = payload.formData;
      const { showModal, companyInfo } = props;
      formData.relationshipId = companyInfo.relationshipId;
      const data = {
        title: (
          <FormattedMessage id="title.schoolSession.text" defaultMessage="" />
        ),
        formData,
        hideFooter: true,
        width: "40%",
        modalData: {
          modalType: MODAL_TYPE.SCHOOL_SESSION,
          data: payload,
        },
        handleSubmit: (formData = {}) => {
          data.formData.submittedOnce = true;
          showModal(data);
        },
      };
      showModal(data);
    };

    const addItemConfirmationModal = (props, text, modalType) => {
      const { showModal, companyInfo } = props;
      const labelName = getLabelName(modalType);
      const modalBody = (
        <span>
          <FormattedMessage
            id="addItem.confirmation"
            values={{ value: `${text} to ` }}
            defaultMessage=""
          />
          {labelName} ?
        </span>
      );

      let addFunc;
      let payload = {
        text: text,
        relationshipId: companyInfo.relationshipId,
      };
      switch (modalType) {
        case MODAL_TYPE.DOCUMENT_NAME: {
          addFunc = props.addDocumentName;
          payload.txType =
            CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE];
          break;
        }
        default: {
          addFunc = () => {};
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          addFunc(payload);
          props.hideModal();
        },
      };
      showModal(modalData);
    };

    const getModalTitle = (newTextObj, modalType) => {
      let title;
      switch (modalType) {
        case MODAL_TYPE.DOCUMENT_NAME: {
          title = (
            <FormattedMessage id="invoiceDocumentName.text" defaultMessage="" />
          );
          break;
        }

        default:
          title = (
            <FormattedMessage
              id="signup.confirmation.title"
              defaultMessage=""
            />
          );
      }
      return title;
    };

    const handleNewItemAddition = (props, payload, modalType) => {
      const formData = payload.formData;
      const { showModal } = props;

      if (
        formData.textBoxValue &&
        formData.textBoxValue.length &&
        !payload.existsInData
      ) {
        addItemConfirmationModal(props, formData.textBoxValue, modalType);
      } else {
        const data = {
          title: getModalTitle(payload, modalType),
          formData,
          modalData: { modalType, data: payload },
          handleSubmit: (formData = {}) => {
            if (formData.textBoxValue && formData.textBoxValue.length) {
              addItemConfirmationModal(props, formData.textBoxValue, modalType);
            } else {
              data.formData.submittedOnce = true;
              showModal(data);
            }
          },
        };
        showModal(data);
      }
    };
   
    const deleteClickHandler = (props, modalType, payload) => {
      const { showModal, companyInfo } = props;
      const modalBody = (
        <FormattedMessage
          id="deleteItem.confirmation"
          defaultMessage=""
          values={{ value: payload.text }}
        />
      );

      let deleteFunc;
      let callback = function (data) {};
      switch (modalType) {
        case MODAL_TYPE.PAYMENT_TERM: {
          deleteFunc = props.deletePaymentTerm;
          break;
        }
        case MODAL_TYPE.PROJECT: {
          deleteFunc = props.deleteProject;
          break;
        }
        case MODAL_TYPE.SCHOOL_SESSION: {
          deleteFunc = props.deleteSchoolSession;
          break;
        }
        case MODAL_TYPE.DOCUMENT_NAME: {
          deleteFunc = props.deleteDocumentName;
          break;
        }
        default: {
          deleteFunc = () => {};
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          deleteFunc({
            id: payload.id,
            relationshipId: companyInfo.relationshipId,
            customerId: (props.customer || {}).customerId,
            callback,
          });
          if (
            modalType === MODAL_TYPE.DOCUMENT_NAME &&
            payload.id === this.props.documentNameId
          ) {
            const selectedObj =
              find(this.props.documentList || [], {
                txType:
                  CONSTANTS_TRANSACTIONS_KEY[
                    CONSTANTS_TRANSACTIONS.SALES_INVOICE
                  ],
                isDefault: 1,
              }) || {};
            this.props.updateState({
              documentNameId: selectedObj.documentNameId,
              docName: selectedObj.docName,
            });
          }
        },
      };
      showModal(modalData);
    };

    const removeFormClassItem = (index) => {
      if (index > -1 && classFees.length > 0) {
        let newArr = classFees;
        newArr.splice(index, 1);
        updateState({ classFees: newArr });
      }
    };

    const openFeeStructure = (rowData, i) => {
      const modalData = {
        title: 
          <>
            <FormattedMessage id="feeStructure.text" defaultMessage="" /> for{" "}
            {rowData?.className || "Class"}
          </>,
        modalBody:
          <FeeStructureComp
            {...this.props}
            feeStructList={rowData?.feeStructList}
            structIdx={i}
          />,
        width: "65%",
        wrapClassName: "back-blur",
        hideFooter: true,
      };

      this.props.pushModalToStack(modalData);
    };

    const iconStyle = {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    };

    return (
      <>
        <Row gutter={8}>
          <Col span={12}>
            <div className="reg-box">
              <div className="reg-heading">
                <FormattedMessage
                  id="setting.student.registration.form.label"
                  defaultMessage=""
                />
              </div>

              <Tabs
                type="line"
                className="primary-tab-container"
                activeKey={this.props.activeTabKey || "1"}
                onChange={(key) => {
                  this.props.updateState({ activeTabKey: key });
                }}
              >
                <TabPane tab="01. Design" key="1">
                  <div className="tab-box">
                    <div className="tab-heading">
                      <FormattedMessage id="setting.studentReg.tabHeading.formInformation" />
                    </div>

                    <Row gutter={[24, 24]}>

                      <Col span={12}>
                        {getUserDefinedName('Department', this.props)}
                        <span className="required">*</span>
                        <Tooltip
                          placement="bottom"
                          title={getUserDefinedName("tooltip.programName", this.props)}
                          trigger={"click"}
                        >
                          <i className="fi fi-rr-interrogation tooltip-icon"></i>
                        </Tooltip>
                        <div className="mt5">
                          <Dropdown
                            items={(this.props?.departmentList||{})[1] || []}
                            valueKeyName="deptName"
                            optionKeyName="deptId"
                            value={this.props.departmentId}
                            placeholder={getUserDefinedName('department.text', this.props)}
                            onSelect={(optionValue, options) => {
                              this.props.updateState({
                                departmentId: optionValue,
                              });
                            }}
                          />
                        </div>
                      </Col>

                      <Col span={12}>
                        <FormattedMessage id="formName.text" />
                        <span className="required">*</span>
                        <div className="mt5">
                          <TextBox
                            maxLength={20}
                            placeholder={"Form Name"}
                            value={this.props.formShortName}
                            onChange={(e) => {
                              this.props.updateState({
                                formShortName: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>

                      {/* <Col span={12}>
                        <FormattedMessage id="formType.text" />
                        <span className="required">*</span>
                        <div className="mt5">
                          <Dropdown
                            items={[
                              TEXT_CONSTANTS.REGISTRATION_FORM,
                              TEXT_CONSTANTS.ENROLEMENT_FORM,
                              "Other",
                            ]}
                            placeholder={"Select Form Type"}
                            value={this.props.formType}
                            onSelect={(val) => {
                              this.props.updateState({ formType: val });
                            }}
                          />
                        </div>
                      </Col> */}

                      <Col span={12}>
                        <FormattedMessage id="formHeading.text" />
                        <span className="required">*</span>
                        <div className="mt5">
                          <TextBox
                            maxLength={45}
                            placeholder={this.props.intl.formatMessage({
                              id: "formHeading.text",
                            })}
                            value={this.props.formHeading}
                            onChange={(e) => {
                              this.props.updateState({
                                formHeading: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>

                      <Col span={12}>
                        <FormattedMessage id="formSubHeading.text" />
                        <div className="mt5">
                          <TextBox
                            maxLength={1000}
                            placeholder={this.props.intl.formatMessage({
                              id: "formSubHeading.text",
                            })}
                            value={this.props.subHeading}
                            onChange={(e) => {
                              this.props.updateState({
                                subHeading: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>

                      <Col span={12}>
                        <FormattedMessage id="schoolSession.text" /><span className="required">*</span>
                        <Tooltip
                          placement="bottom"
                          title={this.props.intl.formatMessage({
                            id: "tooltip.schoolSession",
                          })}
                          trigger={"click"}
                        >
                          <i className="fi fi-rr-interrogation tooltip-icon"></i>
                        </Tooltip>
                        <div className="mt5">
                          <Dropdown
                            items={this.props.schoolSessionList || []}
                            placeholder={this.props.intl.formatMessage({
                              id: "schoolSession.text",
                            })}
                            valueKeyName=" "
                            dateKeyName1="startDate"
                            dateKeyName2="endDate"
                            optionKeyName="schoolSessionId"
                            value={this.props.selectedSessionName}
                            onSelect={(optionValue) => {
                              let selectedSession =
                                this.props.schoolSessionList.find(
                                  (ind) => ind.schoolSessionId === optionValue
                                ) || {};
                              this.props.updateState({
                                selectedSessionName:
                                  getLocalDateFromUtcStr({
                                    date: selectedSession.startDate,
                                    format,
                                  }) +
                                  " - " +
                                  getLocalDateFromUtcStr({
                                    date: selectedSession.endDate,
                                    format,
                                  }),
                                schoolSessionId:
                                  selectedSession.schoolSessionId,
                                sessionStart: selectedSession.startDate,
                                sessionEnd: selectedSession.endDate,
                                paymentPlan: [], //payment plan reset on session change
                              });
                            }}
                            canAddNew={true}
                            canDelete={true}
                            moduleName={MODULE_CODED_VALUES.SCHOOL_FORM}
                            deleteClickHandler={(payload) => {
                              deleteClickHandler(
                                this.props,
                                MODAL_TYPE.SCHOOL_SESSION,
                                payload
                              );
                            }}
                            onAddNewClick={(payload) => {
                              payload.formData = {};
                              handleNewSessionAddition(
                                this.props,
                                payload,
                                MODAL_TYPE.SCHOOL_SESSION
                              );
                            }}
                          />
                          {this.props.sessionStart && this.props.sessionEnd ? 
                            <div style={{ height: "0px" }}>
                              {" "}
                              Total months -{" "}
                              {getMomentMonthCountBetweenDates(
                                this.props.sessionStart,
                                this.props.sessionEnd
                              )}
                            </div>
                           : 
                            ""
                          }
                        </div>
                      </Col>

                    </Row>

                    <Divider />

                    <div className="tab-heading">
                      <FormattedMessage id="setting.studentReg.tabHeading.studentInformation" />
                    </div>

                    <Row gutter={[24, 24]}>
                      <Col span={12}>
                        <FormattedMessage id="setting.studentReg.studentInfo.name" />
                        <span className="required">*</span>
                        <div className="mt5">
                          <TextBox
                            maxLength={45}
                            disabled
                            placeholder="Full Name"
                            value={this.props.studentName}
                            onChange={(e) => {
                              this.props.updateState({
                                studentName: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>

                      <Col span={12} style={{ height: "85px" }}>
                        <Radio.Group
                          name="radiogroup"
                          value={this.props.dobType || 1}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            this.props.updateState({ dobType: e.target.value });
                          }}
                        >
                          <Row gutter={8} style={{ marginTop: "-5px" }}>
                            <Col span={12}>
                              <Radio value={1}>
                                <FormattedMessage id="setting.studentReg.studentInfo.dob" />
                                <div className="mt5">
                                  <DatePicker
                                    disabled
                                    placeholder="MM/DD/YYYY"
                                    key={`studentDob`}
                                    value={
                                      this.props.studentDob
                                        ? getMomentDateForUI({
                                            date: this.props.studentDob,
                                            format: "MM/DD/YYYY",
                                          })
                                        : ""
                                    }
                                    onChange={(selectedDate) => {
                                      this.props.updateState({
                                        studentDob: selectedDate,
                                      });
                                    }}
                                  />
                                </div>
                              </Radio>
                            </Col>
                            <Col span={12}>
                              <Radio value={2}>
                                <FormattedMessage id="common.age.txt" />
                                <div className="mt5">
                                  <TextBox
                                    disabled
                                    placeholder={this.props.intl.formatMessage({
                                      id: "common.age.txt",
                                    })}
                                    value={this.props.age}
                                    onChange={(e) => {
                                      this.props.updateState({
                                        age: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </Col>

                      <Col span={12}>
                        {/* <FormattedMessage id='setting.studentReg.studentInfo.grade' /><span className="required">*</span> */}
                        {getUserDefinedName("class", this.props)}
                        <span className="required">*</span>
                        <div className="mt5">
                          <TextBox
                            maxLength={45}
                            disabled
                            // placeholder={this.props.intl.formatMessage({ id: 'setting.studentReg.studentInfo.grade' })}
                            placeholder={getUserDefinedName(
                              "class",
                              this.props
                            )}
                            value={this.props.studentGrade}
                            onChange={(e) => {
                              this.props.updateState({
                                studentGrade: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>

                      <Col span={12}>
                        <FormattedMessage id="setting.studentReg.studentInfo.gender" />
                        <span className="required">*</span>
                        <div className="mt5">
                          <Dropdown
                            disabled
                            items={[
                              { key: "male", value: "Male" },
                              { key: "female", value: "Female" },
                              { key: "not to say", value: "Rather not to say" },
                            ]}
                            optionKeyName="key"
                            valueKeyName="value"
                            // placeholder={this.props.intl.formatMessage({ id: 'setting.studentReg.studentInfo.gender' })}
                            placeholder="Male/Female/Rather not to say"
                            value={this.props.studentGender}
                            onSelect={(e) => {
                              this.props.updateState({ studentGender: e.key });
                            }}
                          />
                        </div>
                      </Col>

                      <Col span={12}>
                        <FormattedMessage id="setting.studentReg.studentInfo.allergies" />
                        <span className="required">*</span>
                        <div className="mt5">
                          <TextBox
                            maxLength={45}
                            disabled
                            placeholder="Yes or No"
                            value={this.props.studentAllergies}
                            onChange={(e) => {
                              this.props.updateState({
                                studentAllergies: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>

                      <Col span={12}></Col>
                    </Row>

                    <Row gutter={[24, 24]}>
                      <Col span={12}>
                        <div style={{ fontSize: "12px", fontWeight: "700" }}>
                          <FormattedMessage id="setting.studentReg.studentInfo.address" />
                        </div>
                        <div className="mt5">
                          <Switch
                            checked={this.props.addressDisplay === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                addressDisplay: checked ? 1 : 0,
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col span={12}></Col>
                    </Row>
                  </div>
                </TabPane>

                <TabPane tab="02. Components" key="2">
                  <div className="tab-box">
                    <div className="tab-heading">
                      <Row>
                        <Col span={12}>Form Item</Col>
                        <Col span={6}>Show</Col>
                        <Col span={6}>Required</Col>
                      </Row>
                    </div>

                    <div className="form-box">
                      <Row gutter={[24, 24]}>
                        <Col span={12}>
                          <FormattedMessage
                            id="educationHistory.text"
                            defaultMessage=""
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.educationHistory === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                educationHistory: checked ? 1 : 0,
                                educationHistoryRequired: checked
                                  ? this.props.educationHistoryRequired
                                  : 0,
                              });
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.educationHistoryRequired === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                educationHistoryRequired: checked ? 1 : 0,
                                educationHistory: checked
                                  ? 1
                                  : this.props.educationHistory,
                              });
                            }}
                          />
                        </Col>

                        <Col span={12}>
                          <FormattedMessage
                            id="familyDoctor.NameNumber.text"
                            defaultMessage=""
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.familyDoctorNameDisplay === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                familyDoctorNameDisplay: checked ? 1 : 0,
                                familyDoctorNameRequired: checked
                                  ? this.props.familyDoctorNameRequired
                                  : 0,
                              });
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.familyDoctorNameRequired === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                familyDoctorNameRequired: checked ? 1 : 0,
                                familyDoctorNameDisplay: checked
                                  ? 1
                                  : this.props.familyDoctorNameDisplay,
                              });
                            }}
                          />
                        </Col>

                        <Col span={12}>
                          <FormattedMessage
                            id="setting.student.registration.form.emergencyContactNameNumber"
                            defaultMessage=""
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.emergencyContactDisplay === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                emergencyContactDisplay: checked ? 1 : 0,
                                emergencyContactRequired: checked
                                  ? this.props.emergencyContactRequired
                                  : 0,
                              });
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.emergencyContactRequired === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                emergencyContactRequired: checked ? 1 : 0,
                                emergencyContactDisplay: checked
                                  ? 1
                                  : this.props.emergencyContactDisplay,
                              });
                            }}
                          />
                        </Col>

                        <Col span={12}>Teacher</Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.askAddTeacher === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                askAddTeacher: checked ? 1 : 0,
                                askAddTeacherRequired: checked
                                  ? this.props.askAddTeacherRequired
                                  : 0,
                              });
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.askAddTeacherRequired === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                askAddTeacherRequired: checked ? 1 : 0,
                                askAddTeacher: checked
                                  ? 1
                                  : this.props.askAddTeacher,
                              });
                            }}
                          />
                        </Col>

                        <Col span={12}>Volunteer</Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.askAddVolunteer === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                askAddVolunteer: checked ? 1 : 0,
                                askAddVolunteerRequired: checked
                                  ? this.props.askAddVolunteerRequired
                                  : 0,
                              });
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.askAddVolunteerRequired === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                askAddVolunteerRequired: checked ? 1 : 0,
                                askAddVolunteer: checked
                                  ? 1
                                  : this.props.askAddVolunteer,
                              });
                            }}
                          />
                        </Col>

                        {/* <Col span={12}>Organization Member</Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.askOrgMember === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                askOrgMember: checked ? 1 : 0,
                                askOrgMemberRequired: checked
                                  ? this.props.askOrgMemberRequired
                                  : 0,
                              });
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.askOrgMemberRequired === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                askOrgMemberRequired: checked ? 1 : 0,
                                askOrgMember: checked
                                  ? 1
                                  : this.props.askOrgMember,
                              });
                            }}
                          />
                        </Col> */}

                        <Col span={12}>Financial Assistance</Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.askFinanacialAssistance === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                askFinanacialAssistance: checked ? 1 : 0,
                                askFinanacialAssistanceRequired: checked
                                  ? this.props.askFinanacialAssistanceRequired
                                  : 0,
                              });
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={
                              this.props.askFinanacialAssistanceRequired === 1
                            }
                            onClick={(checked, e) => {
                              this.props.updateState({
                                askFinanacialAssistanceRequired: checked
                                  ? 1
                                  : 0,
                                askFinanacialAssistance: checked
                                  ? 1
                                  : this.props.askFinanacialAssistance,
                              });
                            }}
                          />
                        </Col>

                        <Col span={12}>Father Info</Col>
                        <Col span={6}>
                          <Switch
                            disabled
                            checked={this.props.showFatherInfo === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                showFatherInfo: checked ? 1 : 0,
                              });
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            // disabled
                            checked={this.props.fatherInfoRequired === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                fatherInfoRequired: checked ? 1 : 0,
                              });
                            }}
                          />
                        </Col>

                        <Col span={12}>Mother Info</Col>
                        <Col span={6}>
                          <Switch
                            disabled
                            checked={this.props.showMotherInfo === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                showMotherInfo: checked ? 1 : 0,
                              });
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Switch
                            // disabled
                            checked={this.props.motherInfoRequired === 1}
                            onClick={(checked, e) => {
                              this.props.updateState({
                                motherInfoRequired: checked ? 1 : 0,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  tab={`03. ${getUserDefinedName("class", this.props)}`}
                  key="3"
                >
                  <div className="tab-box">
                    <div className="form-box">
                      <Row gutter={[24, 24]}>

                        <Col span={24}>
                          <Row
                            gutter={[16, 16]}
                            style={{ borderBottom: "solid 1px #e4e4e4" }}
                          >
                            <Col span={8}>
                              <b>{getUserDefinedName("class", this.props)}</b>
                            </Col>
                            <Col span={10}>
                              <b>
                                {this.props.intl.formatMessage({
                                  id: "feeStructure.text",
                                })}
                              </b>
                            </Col>

                            <Col span={3} style={iconStyle}>
                              <b>Action</b>
                              {(classFees || []).length === 0 ? (
                                <i
                                  className={`${ICONS.ADD_P}`}
                                  onClick={() =>
                                    updateState({
                                      classFees: [...classFees, {}],
                                    })
                                  }
                                />
                              ) : (
                                <i style={{ width: "12px" }} />
                              )}
                            </Col>
                          </Row>

                          {classFees?.length
                            ? classFees.map((e, i) => {
                                return (
                                  <Row
                                    gutter={[16, 16]}
                                    key={i}
                                    style={{
                                      borderBottom: "solid 1px #e4e4e4",
                                    }}
                                  >
                                    <Col span={8}>
                                      <Dropdown
                                        items={classList[1]}
                                        optionKeyName="classId"
                                        valueKeyName="className"
                                        valueKeyName2={'courseName'}
                                        value={e.className}
                                        placeholder={"Select Class"}
                                        onSelect={(optionValue, option) => {
                                          const selectedClass = classList[1].find(obj=>obj.classId === Number(optionValue))
                                          let classArr = classFees;
                                          classArr[i].classId = optionValue;
                                          classArr[i].className = option.name;
                                          classArr[i].courseId = selectedClass.courseId;

                                          updateState({
                                            classFees: classArr,
                                          });
                                        }}
                                        canAddNew={true}
                                        moduleName={MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL}
                                        onAddNewClick={() => {
                                          this.props.updateHeaderState({
                                            schoolDrawers: {
                                              ...this.props.schoolDrawers,
                                              addClassDrawerVisible: true,
                                              userInfo: this.props.userInfo,
                                            },
                                          });
                                        }}
                                      />
                                    </Col>

                                    <Col span={10}>
                                      <Row gutter={16}>
                                        <Col span={16}>
                                          <TextBox
                                            labelValue={getCurrencySymbol(
                                              this.props.companyInfo
                                                ?.currencyCode || "-"
                                            )}
                                            prefix={getCurrencySymbol(
                                              this.props.companyInfo
                                                ?.currencyCode || "-"
                                            )}
                                            type="number"
                                            disabled={true}
                                            placeholder="0.00"
                                            value={(e.feeStructList || []).length ? (e?.feeStructList || []).reduce(
                                              (amt, feeObj) =>
                                                amt + feeObj.amount,
                                              0
                                            ) : 0}
                                          />
                                        </Col>
                                        <Col span={4} style={iconStyle}>
                                          <span
                                            className="cursor-pointer"
                                            onClick={() =>
                                              openFeeStructure(e, i)
                                            }
                                          >
                                            {" "}
                                            {(e.feeStructList || []).length
                                              ? "Edit"
                                              : "Add"}{" "}
                                          </span>
                                        </Col>
                                        {(e.feeStructList || []).length ? (
                                          <Col span={4} style={iconStyle}>
                                            <Tooltip
                                              placement="top"
                                              title={
                                                "Copy fees structure for another class"
                                              }
                                              trigger="hover"
                                            >
                                              <i
                                                className={ICONS.COPY_P}
                                                onClick={() => {
                                                  let temp = {
                                                    classId: "",
                                                    className: "",
                                                    feeStructList: _.cloneDeep(e.feeStructList || []),
                                                  };
                                                  this.props.updateState({
                                                    classFees: [
                                                      ...classFees,
                                                      {...temp},
                                                    ],
                                                  });
                                                }}
                                              />
                                            </Tooltip>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                      </Row>
                                    </Col>

                                    <Col span={3} style={iconStyle}>
                                      <i
                                        className={ICONS.DELETE_P}
                                        onClick={() => {
                                          removeFormClassItem(i);
                                        }}
                                      />
                                      {(classFees || []).length === i + 1 ? 
                                        <i
                                          className={`${ICONS.ADD_P}`}
                                          onClick={() =>
                                            updateState({
                                              classFees: [...classFees, {}],
                                            })
                                          }
                                        />
                                        :
                                        <i style={{ width: "12px" }} />
                                      }
                                    </Col>
                                  </Row>
                                );
                              })
                            : 
                            ""
                          }
                        </Col>

                        <Col span={18}>
                          Stop Registration after Maximum Limit ? 
                          <Tooltip 
                            placement="right" 
                            title={"Stop Registration after Maximum Limit"}
                            className="ml5 cursor-pointer"
                          >
                            <i className={ICONS.HELP_P} />
                          </Tooltip>
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.isStopRegistrationAfterMaxStudent}
                            onClick={(checked, e) => {
                              if (checked) {
                                this.props.updateState({
                                  isStopRegistrationAfterMaxStudent: 1,
                                  acceptRegistrationAfterMaxStudent: 0,
                                });
                              } else {
                                this.props.updateState({
                                  isStopRegistrationAfterMaxStudent: 0,
                                });
                              }
                            }}
                          />
                        </Col>
                        {this.props.isStopRegistrationAfterMaxStudent ?
                          <Col span={24}>
                            Registration stop message
                            <CKEditor
                              data={this.props.registrationStopMessage}
                              onInit={(editor) => {
                                this.editor = editor;
                              }}
                              onChange={(event) => {
                                const data = event.editor.getData();
                                this.props.updateState({
                                  registrationStopMessage: data,
                                });
                              }}
                              config={{
                                ...CUSTOM_CONFIGURATION_FORM2,
                                wordcount: {},
                                placeholder: 'Stop Message',
                                height: "150px",
                              }}
                            />
                          </Col>:''
                        }

                        <Col span={18}>
                          Accept registration after max limit reached ? 
                          <Tooltip 
                            placement="right" 
                            title={"Accept registration after max limit reached"}
                            className="ml5 cursor-pointer"
                          >
                            <i className={ICONS.HELP_P} />
                          </Tooltip>
                        </Col>
                        <Col span={6}>
                          <Switch
                            checked={this.props.acceptRegistrationAfterMaxStudent}
                            onClick={(checked, e) => {
                              if (checked) {
                                this.props.updateState({
                                  acceptRegistrationAfterMaxStudent: 1,
                                  isStopRegistrationAfterMaxStudent: 0,
                                });
                              } else {
                                this.props.updateState({
                                  acceptRegistrationAfterMaxStudent: 0,
                                });
                              }
                            }}
                          />
                        </Col>
                        {this.props.acceptRegistrationAfterMaxStudent ? 
                          <Col span={24}>
                            Warning Message
                            <CKEditor
                              data={this.props.warningMessage}
                              onInit={(editor) => {
                                this.editor = editor;
                              }}
                              onChange={(event) => {
                                const data = event.editor.getData();
                                this.props.updateState({
                                  warningMessage: data,
                                });
                              }}
                              config={{
                                ...CUSTOM_CONFIGURATION_FORM2,
                                wordcount: {},
                                placeholder: 'Warning Messsage',
                                height: "150px",
                              }}
                            />
                          </Col>
                          :''
                        }
                      </Row>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  tab="04. Payment Info"
                  key="4"
                >
                  <div className="tab-box">
                    <div className="form-box">
                      
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            Payment Required
                          </Col>
                          <Col span={12}>
                            <Switch
                              checked={this.props.collectOnlinePayment}
                              onClick={(checked, e) => {
                                this.props.updateState({
                                  collectOnlinePayment: checked ? 1 : 0,
                                  isCollectPaymentAtRegistration: checked ? this.props.isCollectPaymentAtRegistration : 0,
                                  isAllowParentPaymentPlan:checked ? 1 : 0
                                });
                                if(!checked){
                                  this.props.updateState({
                                    selectedPaymentGateway:null,
                                    invoiceItemDescription:null,
                                    documentNameId:null
                                  })
                                }

                              }}
                            />
                          </Col>
                          </Row>
                          {this.props.collectOnlinePayment === 1 ?
                          <><Row gutter={[16, 16]}>

                          <Col span={12}>
                            Pay on Registration
                            <Tooltip
                              placement="right"
                              title={"If Yes, Payment will be collected at the time of registration else after approval from admin"}
                              className="ml5 cursor-pointer"
                            >
                              <i className={ICONS.HELP_P} />
                            </Tooltip>
                          </Col>
                          <Col span={12}>
                            <Switch
                              disabled={!this.props.collectOnlinePayment}
                              checked={this.props.isCollectPaymentAtRegistration}
                              onClick={(checked, e) => {
                                this.props.updateState({
                                  isCollectPaymentAtRegistration: checked ? 1 : 0,
                                });
                              } } />
                          </Col>

                          <Col span={12}>
                            {this.props.intl.formatMessage({
                              id: "paymentGateway.available",
                            })}
                            <span className="required">*</span>
                          </Col>
                          <Col span={12}>
                            {this.props.availablePaymentGateways?.length ? (
                              <Dropdown
                                items={this.props.availablePaymentGateways || []}
                                optionKeyName="alpidePaymentGatewayId"
                                valueKeyName="userDefinedGatewayName"
                                value={(this.props.selectedPaymentGateway || {}).userDefinedGatewayName}
                                onSelect={(optionValue, option) => {
                                  let selectedPaymentGateway = find(
                                    this.props.availablePaymentGateways || [],
                                    {
                                      alpidePaymentGatewayId: option.value,
                                    }
                                  ) || [];
                                  this.props.updateState({
                                    selectedPaymentGateway: selectedPaymentGateway[0],
                                  });
                                } } />
                            ) : (
                              <span>
                                {this.props.intl.formatMessage({
                                  id: "paymentGateway.notavailable",
                                })}
                              </span>
                            )}
                          </Col>

                          <Col span={12}>
                            <FormattedMessage id="discountRule.text" />
                          </Col>
                          <Col span={12}>
                            {this.props.discountRuleList?.length ?
                              <div>
                                {this.props.discountRuleList?.length} Discount Rule{this.props.discountRuleList.length > 1 ? "s " : " "} Added
                              </div>
                              :
                              ""}
                            <div>
                              <Button
                                onClick={() => {
                                  openAddDiscountRule(this.props);
                                } }
                              >
                                Add
                              </Button>
                            </div>
                          </Col>





                          <Col span={12}>
                            <FormattedMessage id="parent.paymentPlan.text" />
                            <Tooltip
                              placement="bottom"
                              title={this.props.intl.formatMessage({
                                id: "setting.student.registration.feesPaymentPlan.helpMessage",
                              })}
                              trigger={"click"}
                            >
                              <i className="fi fi-rr-interrogation tooltip-icon"></i>
                            </Tooltip>
                          </Col>
                          <Col span={12}>
                            <Switch
                              disabled={!this.props.collectOnlinePayment}
                              checked={this.props.isAllowParentPaymentPlan === 1}
                              onClick={(checked, e) => {
                                this.props.updateState({
                                  isAllowParentPaymentPlan: checked
                                    ? 1
                                    : 0,
                                });
                              } } />
                          </Col>
                        </Row><Row>

                            {this.props.isAllowParentPaymentPlan ? (
                              <Row gutter={[16, 16]}>
                                <Col span={12}>
                                  <FormattedMessage id="paymentPlan.text" />
                                </Col>
                                <Col span={12}>
                                  {this.props.paymentPlan?.length ?
                                    <div>
                                      {this.props.paymentPlan.length} Payments plan{this.props.paymentPlan.length > 1 ? "s " : " "} added
                                    </div>
                                    :
                                    ""}
                                  <Button
                                    onClick={() => {
                                      openAddPaymentPlan(this.props);
                                    } }
                                  >
                                    Add
                                  </Button>
                                </Col>

                                <Col span={12}>
                                  <FormattedMessage id="registration.recurringInvoiceEmailTemplate.text" />
                                </Col>
                                <Col span={12}>
                                  <Button
                                    onClick={() => {
                                      openEmailTemplates(this.props);
                                    } }
                                  >
                                    Add
                                  </Button>
                                </Col>

                                <Col span={12}>
                                  <FormattedMessage id="dueFeeReminder.text" />
                                  <Tooltip
                                    placement="bottom"
                                    title={this.props.intl.formatMessage({
                                      id: "dueFeeReminder.helpMessage",
                                    })}
                                    trigger={"click"}
                                  >
                                    <i className="fi fi-rr-interrogation tooltip-icon"></i>
                                  </Tooltip>
                                </Col>
                                <Col span={12}>
                                  {this.props.reminderList &&
                                    this.props.reminderList.length ? (
                                    <div className="ml10">
                                      {this.props.reminderList.length}{" "}
                                      {this.props.reminderList.length > 1
                                        ? "Reminders Added"
                                        : "Reminder Added"}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div>
                                    <Button
                                      onClick={() => {
                                        openAddReminder(this.props);
                                      } }
                                    >
                                      Add
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                          </Row><Row gutter={[16, 16]}>
                            <Col span={12}>
                              {this.props.intl.formatMessage({
                                id: "invoiceLedger.label",
                              })}
                              <span className="required">*</span>
                              <Tooltip
                                placement="bottom"
                                title={this.props.intl.formatMessage({
                                  id: "tooltip.invoiceLedger",
                                })}
                                trigger={"click"}
                              >
                                <i className="fi fi-rr-interrogation tooltip-icon"></i>
                              </Tooltip>
                            </Col>
                            <Col span={12}>
                              <Dropdown
                                items={this.props.allLedgerAccounts}
                                valueKeyName="ledgerAccountName"
                                optionKeyName="chartOfAccountDetailsId"
                                value={this.props.ledgerAccountIdInvoice}
                                placeholder={this.props.intl.formatMessage({
                                  id: "invoiceLedger.label",
                                })}
                                onSelect={(optionValue, option) => {
                                  let selectedLedger = find(this.props.allLedgerAccounts || [], {
                                    chartOfAccountDetailsId: option.value,
                                  }) || {};
                                  this.props.updateState({
                                    selectedLedger: selectedLedger,
                                    ledgerAccountIdInvoice: option.value,
                                  });
                                } } />
                            </Col>
                          </Row><Row gutter={[16, 16]}>
                            <Col span={12}>
                              <FormattedMessage id="invoiceItemDescription.text" />
                              <span className="required">*</span>
                              <Tooltip
                                placement="bottom"
                                title={this.props.intl.formatMessage({
                                  id: "invoiceItemDescription.helpMessage",
                                })}
                                trigger={"click"}
                              >
                                <i className="fi fi-rr-interrogation tooltip-icon"></i>
                              </Tooltip>
                            </Col>
                            <Col span={12}>
                              <TextBox
                                maxLength={100}
                                rows={1}
                                style={{ width: "100%", padding: "5px" }}
                                placeholder={this.props.intl.formatMessage({
                                  id: "invoiceItemDescription.text",
                                })}
                                value={this.props.invoiceItemDescription}
                                onChange={(e) => {
                                  this.props.updateState({
                                    invoiceItemDescription: e.target.value,
                                  });
                                } } />
                            </Col>
                          </Row><Row gutter={[16, 16]}>
                            <Col span={12}>
                              <FormattedMessage id="invoiceDocumentName.text" />
                            </Col>
                            <Col span={12}>
                              <Dropdown
                                items={filter(
                                  this.props.documentList || [],
                                  (document) => {
                                    return (
                                      document?.txType ===
                                      CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE]
                                    );
                                  }
                                )}
                                placeholder={this.props.intl.formatMessage({
                                  id: "invoiceDocumentName.text",
                                })}
                                valueKeyName="docName"
                                optionKeyName="documentNameId"
                                value={this.props.documentNameId}
                                onSelect={(optionValue) => {
                                  let selectedObj = (this.props.documentList || []).find(
                                    (ind) => ind.documentNameId === optionValue
                                  ) || {};
                                  this.props.updateState({
                                    docName: selectedObj.docName,
                                    documentNameId: selectedObj.documentNameId,
                                  });
                                } }
                                canAddNew={true}
                                canDelete={true}
                                deleteClickHandler={(payload) => {
                                  deleteClickHandler(
                                    this.props,
                                    MODAL_TYPE.DOCUMENT_NAME,
                                    payload
                                  );
                                } }
                                onAddNewClick={(payload) => {
                                  payload.formData = {
                                    textBoxValue: payload.textEntered,
                                    submittedOnce: false,
                                    maxLength: 30,
                                  };
                                  handleNewItemAddition(
                                    this.props,
                                    payload,
                                    MODAL_TYPE.DOCUMENT_NAME
                                  );
                                } } />
                            </Col>
                          </Row><Row gutter={[16, 16]}>
                            <Col span={12}>
                              <FormattedMessage id="drawer.customer.paymentTerm" />
                              <span className="required">*</span>
                              <Tooltip
                                placement="bottom"
                                title={this.props.intl.formatMessage({
                                  id: "registration.paymentTerm.helpMessage",
                                })}
                                trigger={"click"}
                              >
                                <i className="fi fi-rr-interrogation tooltip-icon"></i>
                              </Tooltip>
                            </Col>
                            <Col span={12}>
                              <Dropdown
                                items={this.props.paymentTerms}
                                placeholder={this.props.intl.formatMessage({
                                  id: "drawer.customer.paymentTerm.placeholder",
                                })}
                                valueKeyName="termName"
                                optionKeyName="paymentTermId"
                                canAddNew={true}
                                canDelete={true}
                                value={(this.props.paymentTerm || {}).termName}
                                deleteClickHandler={(payload) => {
                                  deleteClickHandler(
                                    this.props,
                                    MODAL_TYPE.PAYMENT_TERM,
                                    payload
                                  );
                                }}
                                onAddNewClick={(payload) => {
                                  payload.formData = {
                                    termName: payload.textEntered,
                                    days: null,
                                    description: "",
                                    isDefaultPayment: false,
                                    submittedOnce: false,
                                  };
                                  handleNewPaymentTermAddition(
                                    this.props,
                                    payload,
                                    MODAL_TYPE.PAYMENT_TERM
                                  );
                                }}
                                onSelect={(optionValue) => {
                                  let paymentTerm = this.props.paymentTerms.find(
                                    (ind) => ind.paymentTermId === optionValue
                                  ) || {};
                                  this.props.updateState({ paymentTerm });
                                } } />
                            </Col>
                          </Row></>
                    
: "" }
                      
                 
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  tab="05. Q&A"
                  key="5"
                >
                  <div className="tab-box">
                    {(this.props.questionList || []).map((queObj, i) => {
                      return (
                        <>
                          <div key={"ques" + i} className="ques-box">
                            <div className="side-border" />
                            <div className="ques-body">
                              <Row gutter={[8, 16]}>
                                {/* <Col span={5}><b>Question {i + 1}</b></Col> */}
                                <Col span={16}>
                                  <TextArea
                                    maxLength={1000}
                                    autoSize={{
                                      minRows: 1,
                                      maxRows: 4,
                                    }}
                                    // placeholder={this.props.intl.formatMessage({ id: 'questionText.placeholder' })}
                                    placeholder={`Enter Question ${i + 1}`}
                                    value={queObj.questionText}
                                    onChange={(e) => {
                                      queObj.questionText = e.target.value;
                                      let questionList =
                                        this.props.questionList;
                                      questionList.splice(i, 1, queObj);
                                      this.props.updateState({ questionList });
                                    }}
                                  />
                                </Col>
                                <Col span={8}>
                                  <Dropdown
                                    items={[
                                      {
                                        optionText: (
                                          <span>
                                            <i
                                              className="fa fa-dot-circle-o"
                                              aria-hidden="true"
                                            />{" "}
                                            Single Choice
                                          </span>
                                        ),
                                        optionKey: "singleChoice",
                                      },
                                      {
                                        optionText: (
                                          <span>
                                            <i
                                              className="fa fa-check-square-o"
                                              aria-hidden="true"
                                            />{" "}
                                            Multiple Choice
                                          </span>
                                        ),
                                        optionKey: "multiChoice",
                                      },
                                      {
                                        optionText: (
                                          <span>
                                            <FileTextOutlined /> Short Answer
                                          </span>
                                        ),
                                        optionKey: "shortAnswer",
                                      },
                                    ]}
                                    optionKeyName="optionKey"
                                    valueKeyName="optionText"
                                    placeholder="Answer Type"
                                    defaultValue="singleChoice"
                                    value={queObj.answerType}
                                    onSelect={(optionValue) => {
                                      queObj.answerType = optionValue;
                                      if (optionValue === "shortAnswer") {
                                        queObj.optionList = [];
                                      }
                                      let questionList =
                                        this.props.questionList;
                                      questionList.splice(i, 1, queObj);
                                      this.props.updateState({ questionList });
                                    }}
                                  />
                                </Col>
                              </Row>

                              {(queObj.optionList || []).map((optObj, j) => {
                                return (
                                  <div key={"que" + j}>
                                    <Row
                                      gutter={[8, 8]}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Col
                                        span={1}
                                        style={{ fontSize: "18px" }}
                                      >
                                        {queObj.answerType === "multiChoice" ? (
                                          <i
                                            className="fa fa-square-o"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <i
                                            className="fa fa-circle-o"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </Col>
                                      <Col span={22}>
                                        <TextArea
                                          maxLength={200}
                                          autoSize={{
                                            minRows: 1,
                                            maxRows: 3,
                                          }}
                                          // placeholder={this.props.intl.formatMessage({ id: 'optionText.placeholder' })}
                                          placeholder={`Option ${j + 1}`}
                                          value={optObj.optText}
                                          onChange={(e) => {
                                            optObj.optText = e.target.value;
                                            let optionList = queObj.optionList;
                                            optionList.splice(j, 1, optObj);
                                            queObj.optionList = optionList;
                                            let questionList =
                                              this.props.questionList;
                                            questionList.splice(i, 1, queObj);
                                            this.props.updateState({
                                              questionList,
                                            });
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        span={1}
                                        style={{ fontSize: "18px" }}
                                      >
                                        <Tooltip
                                          placement="left"
                                          title={"Delete answer"}
                                          trigger="hover"
                                        >
                                          <div
                                            onClick={() => {
                                              let optionList =
                                                queObj.optionList;
                                              optionList.splice(j, 1);
                                              queObj.optionList = optionList;
                                              let questionList =
                                                this.props.questionList;
                                              questionList.splice(i, 1, queObj);
                                              this.props.updateState({
                                                questionList,
                                              });
                                            }}
                                          >
                                            <CloseOutlined />
                                          </div>
                                        </Tooltip>
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              })}

                              {/* add options */}
                              {queObj.answerType !== "shortAnswer" ? (
                                <Row
                                  gutter={[8, 8]}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col span={1} style={{ fontSize: "18px" }}>
                                    {queObj.answerType === "multiChoice" ? (
                                      <i
                                        className="fa fa-square-o"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <i
                                        className="fa fa-circle-o"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </Col>
                                  <Col span={22}>
                                    <span
                                      className="add-link"
                                      onClick={() => {
                                        let optionList = queObj.optionList;
                                        optionList.push({});
                                        queObj.optionList = optionList;
                                        let questionList =
                                          this.props.questionList;
                                        questionList.splice(i, 1, queObj);
                                        this.props.updateState({
                                          questionList,
                                        });
                                      }}
                                    >
                                      Add
                                    </span>{" "}
                                    Option
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}

                              <Divider style={{ margin: "10px 0px" }} />

                              <div className="ques-footer">
                                <span
                                  span={1}
                                  style={{
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    removeQuestion(this.props, i);
                                  }}
                                >
                                  <DeleteOutlined />
                                </span>
                                <span className="sep" />
                                <span>Required</span>
                                <div style={{ width: "50px" }}>
                                  <Switch
                                    checked={queObj.isRequired === 1}
                                    onClick={(checked, e) => {
                                      queObj.isRequired = checked ? 1 : 0;
                                      let questionList =
                                        this.props.questionList;
                                      questionList.splice(i, 1, queObj);
                                      this.props.updateState({ questionList });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        className="addQues-btn mt10 mb15"
                        type="primary"
                        onClick={() => {
                          let questionList = this.props.questionList;
                          questionList.push({ optionList: [{}] });
                          this.props.updateState({ questionList });
                        }}
                      >
                        <span style={{ color: "#58596C" }}>
                          <PlusOutlined />
                        </span>
                        <FormattedMessage id="addQuestion.text" />
                      </Button>
                    </div>
                  </div>
                </TabPane>

                <TabPane
                  tab={"06. Settings"}
                  key="6"
                >
                  <div className="tab-box">
                    <div className="form-box">
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <FormattedMessage id="formActive.text" />
                          <div className="mt5">
                            <Switch
                              checked={this.props.isFormActive === 1}
                              onClick={(checked, e) => {
                                this.props.updateState({
                                  isFormActive: checked ? 1 : 0,
                                });
                              }}
                            />
                          </div>
                        </Col>

                        <Col span={12}>
                          {/* <FormattedMessage id="publishParentPortal.text" />
                          <div className="mt5">
                            <Switch
                              checked={this.props.isPublishParentPortal === 1}
                              onClick={(checked, e) => {
                                this.props.updateState({
                                  isPublishParentPortal: checked ? 1 : 0,
                                });
                              }}
                            />
                          </div> */}
                        </Col>

                        <Col span={24}>
                          <FormattedMessage id="inactiveMessage.text" />
                          <Tooltip
                            placement="bottom"
                            title={this.props.intl.formatMessage({
                              id: "registration.inactiveMessage.helpMessage",
                            })}
                          >
                            <i className="fi fi-rr-interrogation tooltip-icon"></i>
                          </Tooltip>
                          <div>
                            <CKEditor
                              data={this.props.inactiveMessage}
                              onInit={(editor) => {
                                this.editor = editor;
                              }}
                              onChange={(event) => {
                                const data = event.editor.getData();
                                this.props.updateState({
                                  inactiveMessage: data,
                                });
                              }}
                              config={{
                                ...CUSTOM_CONFIGURATION_FORM2,
                                wordcount: {},
                                placeholder: this.props.intl.formatMessage({
                                  id: "registration.inactiveMessage.helpMessage",
                                }),
                                height: "150px",
                              }}
                            />
                          </div>
                        </Col>

                        <Col span={24}>
                          <FormattedMessage id="formDescription.text" />
                          <div className="mt5">
                            <CKEditor
                              data={this.props.formDescription}
                              onInit={(editor) => {
                                this.editor = editor;
                              }}
                              onChange={(event) => {
                                const data = event.editor.getData();
                                this.props.updateState({
                                  formDescription: data,
                                });
                              }}
                              config={{
                                ...CUSTOM_CONFIGURATION_FORM2,
                                wordcount: {},
                                placeholder: "Enter form description",
                                height: "150px",
                              }}
                            />
                          </div>
                        </Col>

                        <Col span={24}>
                          <FormattedMessage id="footerMessage.text" />
                          <div className="mt5">
                            <CKEditor
                              data={this.props.footerMessage}
                              onInit={(editor) => {
                                this.editor = editor;
                              }}
                              onChange={(event) => {
                                const data = event.editor.getData();
                                this.props.updateState({ footerMessage: data });
                              }}
                              config={{
                                ...CUSTOM_CONFIGURATION_FORM2,
                                wordcount: {},
                                placeholder: "Enter form description",
                                height: "150px",
                              }}
                            />
                          </div>
                        </Col>

                        <Col span={24}>
                          <FormattedMessage id="registration.successMessage" />
                          <Tooltip
                            placement="bottom"
                            title={this.props.intl.formatMessage({
                              id: "registration.successMessage.helpMessage",
                            })}
                          >
                            <i className="fi fi-rr-interrogation tooltip-icon"></i>
                          </Tooltip>
                          <div className="mt5">
                            <CKEditor
                              data={this.props.successMessage}
                              onInit={(editor) => {
                                this.editor = editor;
                              }}
                              onChange={(event) => {
                                const data = event.editor.getData();
                                this.props.updateState({
                                  successMessage: data,
                                });
                              }}
                              config={{
                                ...CUSTOM_CONFIGURATION_FORM2,
                                wordcount: {},
                                placeholder: "Enter success message",
                                height: "150px",
                              }}
                            />
                          </div>
                        </Col>

                        <Col span={8}>
                          <FormattedMessage id="registration.isSendEmail" />
                          <Tooltip
                            placement="bottom"
                            title={this.props.intl.formatMessage({
                              id: "tooltip.isSendEmail",
                            })}
                          >
                            <i className="fi fi-rr-interrogation tooltip-icon"></i>
                          </Tooltip>

                          <div className="mt5">
                            <Switch
                            checked={this.props.isSendEmail}
                              onClick={(checked, e) => {
                                this.props.updateState({
                                  isFormSetting: true,
                                  isSendEmail: checked ? 1 : 0,
                                  emailDetailDrawerVisible: checked ? 1 : 0,
                                  docName: "registration",
                                  emailContext: "registrationEmail",
                                });
                              }}
                            />
                          </div>
                        </Col>

                        <Col span={6}>
                          <div className="mt20">
                            {this.props.emailDetails ? (
                              <Button
                                icon={<i className={`${ICONS.MAIL} mr5`} />}
                                onClick={() => {
                                  this.props.updateState({
                                    isFormSetting: true,
                                    emailDetailDrawerVisible: true,
                                    docName: "registration",
                                    emailContext: "registrationEmail",
                                  });
                                }}
                              >
                               {" "} Show Email
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
              </Tabs>

              <div className="footer">
                <div>
                  <Button
                    disabled= {this.props.saveLoading}
                    onClick={() => {
                      const modalData = {
                        modalBody: this.props.intl.formatMessage({
                          id: "close.confirmation",
                        }),
                        footer: {
                          cancel: { intlId: "no" },
                          submit: { intlId: "yes" },
                        },
                        handleSubmit: () => {
                          this.props.popModalFromStack();
                        },
                      };
                      this.props.pushModalToStack(modalData);
                    }}
                    type="default"
                  >
                    <FormattedMessage id="button.close.label" defaultMessage="" />
                  </Button>

 
                  {!this.props.registrationFormSettingId ? 
                   <Button
                    disabled= {this.props.saveLoading}
                    className="ml10"
                    style={{width: '120px'}}
                    onClick={() => {
                      if(!this.props.departmentId  || !this.props.formHeading){
                        return showToasterMessage({
                          messageType: 'error', description: "Department, Form Type and Form Heading required to save as Draft"
                        });
                      }
                      const modalData = {
                        modalBody: this.props.intl.formatMessage({
                          id: "close.confirmation",
                        }),
                        footer: {
                          cancel: { intlId: "no" },
                          submit: { intlId: "yes" },
                        },
                        handleSubmit: () => {
                          // code for saving draft
                          this.props.saveAsDraftRegistrationForm(this.props);
                        },
                      };
                      this.props.pushModalToStack(modalData);
                    }}
                    type="default"
                  >
                    <FormattedMessage id="button.draft.label" defaultMessage="" />
                  </Button>
                  : ""}
                </div>

                <div>
                  {!this.props.activeTabKey ||
                  this.props.activeTabKey !== "1" ? (
                    <Button
                      disabled= {this.props.saveLoading}
                      className=" mr10"
                      onClick={() => {
                        this.props.updateState({
                          activeTabKey: `${Number(this.props.activeTabKey) - 1}`,
                        });
                      }}
                      type="default"
                    >
                      Back
                    </Button>
                  ) : (
                    ""
                  )}

                  {this.props.activeTabKey === "6" ? (
                    <Button
                      type="primary"
                      loading= {this.props.saveLoading}
                      onClick={() => {
                        saveSettings(this.props);
                      }}
                    >
                      <FormattedMessage
                        id="button.save.label"
                        defaultMessage=""
                      />
                    </Button>
                  ) : (
                    <Button
                      disabled= {this.props.saveLoading}
                      type="primary"
                      onClick={() => {
                        validateTab(this.props);
                        // this.props.updateState({activeTabKey: `${Number(this.props.activeTabKey || 1) + 1}`});
                      }}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Col>

          <Col span={12}>
            <div className="tab-box">
              <RegistrationFormPreviewComponent
                {...this.props}
                formSetting={getFormSetting(this.props)}
                registrationFormSettingId={this.props.registrationFormSettingId}
                relationshipId={(this.props.companyInfo || {}).relationshipId}
                modalType="preview"
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default injectIntl(RegistrationFormSettingComponent);