import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import CompanyHeirarchyComponent from '../../../components/company/CompanyHierarchy';
import { fetchCompanyHierarchy, deleteHierarchy } from '../../CompanyHierarchy/action';
// import { showModal, hideModal } from '../../../actions/commonActions';
import HierarchyComp from '../../../../components/company/EmployeeMeta/Hierarchy';
import { showModal, hideModal } from '../../../../actions/commonActions';
import HierarchyDrawer from '../../../drawer/company/EmployeeMeta/Hierarchy';


class CompanyHierarchy extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const companyInfo = (this.props.companyInfo || {})
    const payload= {
      relationshipId: companyInfo.relationshipId 
    }
    this.props.fetchCompanyHierarchy(payload);
  }

  render() {
    return <>
    
    <HierarchyComp  {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
{this.state.hierarchyDrawer && <HierarchyDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}

    </>
    
  }

}

const mapStateToProps = (state) => {
  return {
    companyHierarchyList: state.hierarchy.hierarchyList,
    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    permissions: state.auth.permissions,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCompanyHierarchy,
  deleteHierarchy,
  showModal,
  hideModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyHierarchy);
