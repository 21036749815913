import React from 'react';
import { Route, BrowserRouter, Switch, Redirect, withRouter } from 'react-router-dom';

import { AuthContext } from "../context/auth";
import Login from '../containers/Login';
import Signup from '../containers/Signup';
import PricingComponent from '../components/signup/PricingComponent';
import Forgot from '../containers/Forgot';
import Header from '../components/header/header';
import CompanySetup from "../containers/CompanySetup";

import pathNotFound from '../components/errors/PathNotFound';
import { axiosInterceptorConfig } from '../customAxios';
import { fetchFromLocalStorage } from '../utils';
import { LOCAL_STORAGE_KEYS } from '../static/constants';
import ActivationPanding from '../containers/ActivationPanding';
import InvoicePreview from '../containers/InvoicePreview';
import Logout from "../containers/Logout";
import Register from "../containers/B2B/Register";
import Register1 from "../containers/B2B/Register1";
import Donation from "../containers/B2B/Donation";
import RFQSubmission from "../containers/B2B/RFQSubmission";
import NewLetterPreview from "../containers/B2B/NewLetterPreview";
import PaymentSetup from "../containers/B2B/PaymentSetup";
import RSVP from "../containers/B2B/RSVP";
import DonationWeb from "../containers/B2B/DonationWeb";
import CreatePledge from "../containers/B2B/CreatePledge";
import Streaming from "../containers/B2B/Streaming";
import Unsubscribe from "../containers/B2B/Unsubscribe";
import CardConnectPayment from "../containers/B2B/CardConnectPayment";
import PaymentSetupStripe from "../containers/B2B/PaymentSetupStripe";
import PaymentPlan from "../containers/B2B/PaymentPlan";
import TrasactionFileUpload from '../containers/B2B/TrasactionFileUpload';
import ClientOnboarding from '../containers/B2B/clientOnboarding';
import ProductCard from '../containers/B2B/ProductCard';
import ResetPassword from '../containers/ResetPassword';
import PricingSetupContainer from '../containers/PricingSetup';

import PricingPlans from '../containers/B2B/PlanPricing';
import CheckoutPlan from '../containers/B2B/CheckoutPlan';
import OrderPreview from '../containers/orderPreview';
import ProformaPayment from '../containers/B2B/ProformaPayment';
import PaymentStatus from '../components/B2B/PaymentGateways/PaymentStatus';

function AppRoute(prop) {
    const history = new BrowserRouter().history;
    const isAuthenticated = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    const loggedInEntityInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};

    const companyInfo = loggedInEntityInfo.defaultCompany;
    const isCompanySetupRequired = companyInfo?.isCompanySetupRequired || 0;
    const isPlanSelectionPending = (companyInfo?.planCode && companyInfo?.planCode.length) ?  false : true ;
   //const isPlanSelectionPending = false;
    //enable interceptor for auth
    axiosInterceptorConfig(history);

    const routes = [
        {
            path: '/',
            component: Login
        },
        {
            path: '/signup',
            component: Signup
        },
        {
            path: '/pricing',
            component: PricingComponent
        },
        {
            path: '/forgotpassword',
            component: Forgot
        },
        {
            path: '/company-setup',
            component: CompanySetup
        },
        {
            path: '404',
            component: pathNotFound
        },
        {
            path: '/logout',
            component: Logout
        },
        {
            path: '/activation-pending',
            component: ActivationPanding
        },
        {
            path: '/invoice',
            component: InvoicePreview
        },
        {
            path: '/register',
            component: Register
        },
        {
            path: '/register1',
            component: Register1
        },
        {
            path: '/donation',
            component: Donation
        },
        {
            path: '/rfqSubmission',
            component: RFQSubmission
        },
        {
            path: '/newsletter-v',
            component: NewLetterPreview
        },
        {
            path: '/campaign-v',
            component: NewLetterPreview
        },
        {
            path: '/event-v',
            component: NewLetterPreview
        },
        {
            path: '/payment-setup',
            component: PaymentSetup
        },
        {
            path: '/rsvp',
            component: RSVP
        },
        {
            path: '/web-donation',
            component: DonationWeb
        },
        {
            path: '/create-pledge',
            component: CreatePledge
        },
        {
            path: '/campaign-streaming',
            component: Streaming
        }, 
        {
            path: '/dg-unsubscribe',
            component: Unsubscribe
        },
        {
            path: '/card-connect',
            component: CardConnectPayment
        }, {
            path: '/stripe',
            component: PaymentSetupStripe
        },
        {
            path: '/payment-plan',
            component: PaymentPlan
        },
        {
            path: '/transaction-file-upload',
            component: TrasactionFileUpload
        },
        {
            path: '/client-onboarding',
            component: ClientOnboarding
        },
        {
            path: '/product-card',
            component: ProductCard
        },
        {
            path: '/order-preview',
            component: OrderPreview
        },
        {
            path: '/resetpassword',
            component: ResetPassword
        },
        // {

        //     path:'/alpide-pricing-plans',
        //     component: PricingPlans,
        // },
        {

            path:'/checkout-plans',
            component: CheckoutPlan
        },
        {
            path: '/pricingsetup',
            component: PricingSetupContainer,
        },
        {
            path: '/proforma-payment',
            component: ProformaPayment
        },
        {
            path: '/payment-status/:paymentGateway',
            component: PaymentStatus,
            exact: false
        }
      
        
    ];

    const routeComponents = routes.map(({ path, component: Component }, key) => {
        return (
            <Route
                exact
                path={path}
                key={key}
                render={(props) =>
                    (!isAuthenticated || isCompanySetupRequired ||isPlanSelectionPending ) ? (
                        <Component {...props} />
                    ) : (
                            // <Redirect to={(companyInfo?.industryName || '').toLowerCase() === 'education' ? "/admin/school-management/dashboard" : "/admin/dashboard"} />
                            <Redirect to="/admin/dashboard" />
                        )
                }
            />
        )
    });

    return (
        <AuthContext.Provider value={false}>
            <Switch>
                {routeComponents}
                <Route path='/admin/:id' history={history} props={prop} component={Header} />
            </Switch>
        </AuthContext.Provider >
    )
}

export default withRouter(AppRoute);
