import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, INVENTORY_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const saveSingleTaxRate = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/taxModule/saveSingleTaxRate`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: COMMON_ACTIONS.TAX_LIST_ADDITION, data: res.data });
        if(payload.taxType === 'VAT'){
          dispatch({ type: INVENTORY_ACTION_LIST.GLOBLE_TAXES_ADDITION, data: res.data });
        }
        showToasterMessage({ messageType: 'success', description: 'Tax saved successfully' });
        props.fetchTaxes({relationshipId: payload.relationshipId});
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else {
          err.actionToCall = COMMON_ACTIONS.TAX_LIST_ADDITION;
        }
      })
  }
}