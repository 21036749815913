import React, { Fragment ,useEffect,useState} from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col,Skeleton } from 'antd'
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';
import config from '../../../../../config/environmentConfig';
import { CONSTANTS, LEDGER_TYPE } from '../../../../../static/constants';
import { base64Toblob, getAWSFileURL, fixedDecimalNumber, getMomentDateForUIReadOnly, getUserDefinedName, numberToWordsWithDecimals, getCurrencySymbol } from '../../../../../utils';
import { ImageCropper } from '../../../../../components/general/ImageCropper';
import { CustomUploader } from "../../../../../components/general/CustomUploader";
// import { LogoComponent } from '../../../../general/LogoComponent';
import { AWSFileTable } from '../../../../general/AWSFileTable';

const Template2 = (props) => {
    const { salesInvoiceDetail, companyInfo } = props;

    const { customerInvoiceDetailsList } = salesInvoiceDetail;
    const relationshipAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (salesInvoiceDetail.placeOfSupply && relationshipAddress.stateName === salesInvoiceDetail.placeOfSupply) || false;
    let itemLevelTaxes = [];
    let couponData = getTxTypeData(props.salesInvoiceDetail, 'Coupon', 'coupon');
    let itemLevelTaxesByHsn = [];
    let uomList = [];
    if (customerInvoiceDetailsList) {
        itemLevelTaxes = calculateTaxRateList(customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship,salesInvoiceDetail,props);
        itemLevelTaxesByHsn = calculateTaxRateListByHsn(customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship,salesInvoiceDetail,props);
        uomList = calculateUomData(customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true,
        comment: true,
        studentName: true,
        studentGrade: true,
        studentClassName: true,
    };
    if (salesInvoiceDetail) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, salesInvoiceDetail, props);
    }

    const customProps = {
        type: CONSTANTS.TYPE_IMAGE,
        imgUrl: getAWSFileURL((salesInvoiceDetail || {}).stampAwsKey, config.BUCKET_NAME.BO_CUSTOMER_INVOICE) || '',
        isSignature: true,
        height: 60,
        width: 60,
        name: 'file',
        action: null,
        buttonName: 'Upload Customer PO',
        onChange: (file) => {
            if (file[0]?.originFileObj) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    props.showModal({
                        title: <Fragment>
                            <div className='ant-modal-title'>Crop Image</div>
                        </Fragment>,
                        //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
                        modalBody: <ImageCropper onClick={(e) => {
                        }} handleSubmit={(newUrl) => {
                            props.uploadStamp({
                                customerId: salesInvoiceDetail.customerId,
                                invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                rid: salesInvoiceDetail.relationshipId,
                                objectKey: salesInvoiceDetail.stampAwsKey,
                                bucketName: props.companyInfo?.bucketName,
                                file: base64Toblob(newUrl)
                            })
                        }}{...props} url={e.target.result} />,
                        width: 800,
                        hideFooter: true,
                        wrapClassName: 'Upload-profile-modal'
                    })
                }

                reader.readAsDataURL(file[0]?.originFileObj); // convert to base64 string
            }
        }
    }

    const [showComp, setShowComp] = useState(false);
    useEffect(()=>{
      const delay = setTimeout(()=>{
        setShowComp(true);
      }, 400);
      return ()=> clearTimeout(delay);
    }, []);
    return (
        <div className={"custom-modal show "}>
            <i className="close-icon" onClick={() => { props.popModalFromStack(); props.hideModal(); }}>
                <svg width="17" height="17">
                    <title><FormattedMessage id='button.close.label' /></title>
                    <path fill="#67757d" fillRule="evenodd"
                        d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
                </svg>
            </i>
            <div className="modal-dialog txn-modal-dialog">
                <div className="modal-content full-width  vertical-view-data">
                    
                    <div className="modal-header">

                    </div>
                    {showComp ? 
                    <div id="so-readonly" className="inner-content foot-bottoms">
                        <div className="brand-logo">
                        </div>
                        <div className="doc-title">
                            {salesInvoiceDetail.documentName || 'Sales Invoice'}
                        </div>
                        <Row>

                            <Col span={16}>
                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 3 }} active>
                                <div className="read-only-content pt20">
                                    <div className="title lh-adjust underline-text pb5">
                                        <FormattedMessage id='modal.txDetails.common.billTo' defaultMessage='' />
                                    </div>
                                    <div>
                                
                                   <div className='title'> {salesInvoiceDetail.customerName}</div>
                                        
                                    
                                    <div>
                                        {getAddressInfoFragment(billingAddress, (salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] || {} : {}))}
                                        {getTaxIdentificationsFragment(((salesInvoiceDetail.additionalInfoList || [])[0] || {}).taxIdentifications)}
                                    </div>
                                    <div>
                                   {(salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] || {} : {})?.customerPhone}
                                        <br/>
                                        {(salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] || {} : {})?.customerEmail}
                                   
                                        
                                    </div>
                                    </div>
                                   
                                    <div className="">
                                        <div className='title'> {billingAddress?.fullName}</div>
                                     <div className=''> {billingAddress?.emailAddress}</div> 
                                     <div className=''> {billingAddress?.cellPhone}</div>
                                        {salesInvoiceDetail?.soNumber?.includes("SOO") ?"": getContactNameFragment((salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] || {} : {}))}
                                    </div>
                                   
                                </div>
                                {shippingAddress.streetAddress1 ? <div className="read-only-content pt10">
                                    <div className="title lh-adjust underline-text pb5">
                                        <FormattedMessage id='modal.txDetails.common.shipTo' defaultMessage='' />
                                    </div>
                                    <div className="title">
                                     {shippingAddress?.fullName}
                                    
                                    </div>
                                    <div>
                                        {getAddressInfoFragment(shippingAddress, {})}
                                    </div>
                                    <div className=''> {shippingAddress?.emailAddress}</div> 
                                     <div className=''> {shippingAddress?.cellPhone}</div>
                                </div> : ''}
                            </Skeleton>
                            </Col>
                            <Col span={4}>
                            <Skeleton loading={props.detailLoading} paragraph={{ rows: 3 }} active>

                                <div className="read-only-content pt20">
                                    <div className="pb5">
                                        <div className="title">
                                            <FormattedMessage id='common.invoiceNumber' defaultMessage='' />
                                        </div>

                                        <div>
                                            {salesInvoiceDetail.manualInvoiceNumber ? salesInvoiceDetail.manualInvoiceNumber : salesInvoiceDetail.invoiceNumber}
                                        </div>
                                    </div>

                                    {
                                        salesInvoiceDetail.soNumber ?
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='modal.txDetails.salesOrder.soNumber' defaultMessage='' />
                                                </div>
                                                <div>
                                                    <abbr>{salesInvoiceDetail.soNumber}</abbr>
                                                </div>
                                            </div> : ""
                                    }

                                    <div className="pb5">
                                        <div className="title">
                                            <FormattedMessage id='common.dateCreated' defaultMessage='' />
                                        </div>

                                        <div>
                                            {(salesInvoiceDetail.invoiceDate ? getMomentDateForUIReadOnly({ date: salesInvoiceDetail.invoiceDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : "")}
                                        </div>
                                    </div>

                                    {
                                        salesInvoiceDetail.invoiceDueDate ?
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='common.expirationDate' defaultMessage='' />
                                                </div>

                                                <div>
                                                    {(salesInvoiceDetail.invoiceDueDate && getMomentDateForUIReadOnly({ date: salesInvoiceDetail.invoiceDueDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                </div>
                                            </div> : ""
                                    }

                                    {
                                        !!salesInvoiceDetail.isMultiCurrency ?
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='modal.txDetails.common.foreignCurrency' defaultMessage='' />
                                                </div>

                                                <div>
                                                    {salesInvoiceDetail.foreignCurrency}
                                                </div>
                                            </div> : ""
                                    }

                                    {
                                        !!salesInvoiceDetail.isMultiCurrency ?
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='modal.txDetails.common.exchangeRate' defaultMessage='' />
                                                </div>

                                                <div>
                                                    {salesInvoiceDetail.exchangeRate}
                                                </div>
                                            </div> : ""
                                    }
                                    {
                                        (salesInvoiceDetail.templateCode !== 'student' && salesInvoiceDetail.projectMasterId) ?
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='modal.txDetails.common.project' defaultMessage='' />
                                                </div>
                                                <div>
                                                    <abbr>{salesInvoiceDetail.projectMasterId}-{salesInvoiceDetail.projectName}</abbr>
                                                </div>
                                            </div> : ""
                                    }
                                    {
                                        (salesInvoiceDetail.templateCode === 'student' && salesInvoiceDetail.formName) ?
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='program.text' defaultMessage='' />
                                                </div>
                                                <div>
                                                    <abbr>{salesInvoiceDetail.formName}</abbr>
                                                </div>
                                            </div> : ""
                                    }

                                    <div className="pb5">
                                        <div className="title">
                                            <FormattedMessage id='customer.salesInvoice.listing.header.systemStatus' />
                                        </div>
                                        <div>
                                            <FormattedMessage id={salesInvoiceDetail.status || "open"} />
                                        </div>
                                    </div>

                                </div>
                                </Skeleton>
                            </Col>
                            <Col span={4}>
                            <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                                <div className="d-inline-flex w-100">

                                    <div className="read-only-content pt20 pl10">
                                        <div className="title lh-adjust pb5">
                                            {props.companyInfo.storeName}
                                        </div>
                                        <div>
                                            {getAddressInfoFragment(relationshipAddress, companyInfo)}
                                            <div>
                                            {(salesInvoiceDetail.additionalInfoList?.length ? salesInvoiceDetail.additionalInfoList[0] || {} : {})?.relationshipPhoneNumber} 
                                            </div>
                                            <div>
                                                {getTaxIdentificationsFragment(((salesInvoiceDetail.additionalInfoList || [])[0] || {}).relationshipTaxIdentifications)}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                </Skeleton>
                            </Col>
                        </Row>
                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                        <div className="modal-table-container vertical-space">
                            <table>
                                <thead>
                                    <tr>
                                        {(props.companyInfo.countryName === 'India' && salesInvoiceDetail.templateCode !== 'student') && <th><FormattedMessage id='modal.txDetails.common.placeOfSupply' defaultMessage='' /></th>}
                                        {salesInvoiceDetail.templateCode !== 'student' && <th><FormattedMessage id='common.customer.poNumber' defaultMessage='' /></th>}
                                        <th><FormattedMessage id='modal.txDetails.common.refNumber' defaultMessage='' /></th>
                                        <th><FormattedMessage id='modal.txDetails.common.paymentTerm' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {(props.companyInfo.countryName === 'India' && salesInvoiceDetail.templateCode !== 'student') && <td>{salesInvoiceDetail.placeOfSupply || '-'}</td>}
                                        {salesInvoiceDetail.templateCode !== 'student' && <td>{salesInvoiceDetail.customerPONumber || '-'}</td>}
                                        <td>{salesInvoiceDetail.referenceNumber || '-'}</td>
                                        <td>{salesInvoiceDetail.paymentTermName || ''}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-table-container mb15">
                            <table className="transaction-details-table">
                                <thead>
                                    <tr>
                                        <th className="cell-size-50">#</th>
                                        {/* {!isColumnEmpty.studentName && <th className="text-center"><FormattedMessage id='student.text' defaultMessage='' /></th>} */}
                                        {/* {!isColumnEmpty.studentGrade && <th className="text-center"><FormattedMessage id='grade.text' defaultMessage='' /></th>} */}
                                        {/* {!isColumnEmpty.studentClassName && <th className="text-center">{getUserDefinedName('Class', props)}</th>} */}
                                        {(!isColumnEmpty.product || !isColumnEmpty.description) && <th className="text-center">
                                            {!isColumnEmpty.product && <span>  <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                            {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                            {!isColumnEmpty.description && <span>  <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                        </th>}
                                        {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                                        {(!isColumnEmpty.qty && isColumnEmpty.studentName) && <th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                                        {!isColumnEmpty.specialDiscount && <th className="text-center"><FormattedMessage id='modal.txDetails.common.basePrice' defaultMessage='' /></th>}
                                        {!isColumnEmpty.specialDiscount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.specialDiscount' defaultMessage='' /></th>}
                                        {!isColumnEmpty.rate && <th className="text-center">{salesInvoiceDetail.templateCode === 'student' ? <FormattedMessage id='fee.text' /> : <FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' />}</th>}
                                        {!isColumnEmpty.hsn && <th className="text-center"><FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></th>}
                                        {!isColumnEmpty.discount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' /></th>}
                                        {!isColumnEmpty.discount && <th className="text-right"><FormattedMessage id='discountType.text' defaultMessage='' /></th>}
                                        {!isColumnEmpty.tax && <th className="text-right"><FormattedMessage id='modal.txDetails.common.tax' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.comment && <th>Warehouse</th>} */}
                                        {<th className="text-right">Taxable Amount</th>}
                                        <th className="text-right"><FormattedMessage id='modal.txDetails.common.netAmount' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(customerInvoiceDetailsList || []).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="cell-size-50">{i + 1}</td>
                                                {/* {!isColumnEmpty.studentName && <td className="text-center">{item.studentName || '-'}</td>} */}
                                                {/* {!isColumnEmpty.studentGrade && <td className="text-center">{item.studentGrade  || '-'}</td>} */}
                                                {/* {!isColumnEmpty.studentClassName && <td className="text-center">{item.studentClassName || '-'}</td>} */}
                                                {(!isColumnEmpty.product || !isColumnEmpty.description) && <td>
                                                    <strong>{getVariantFragment(item)}</strong>
                                                    {!isColumnEmpty.description && <div className="text-center">
                                                        {/* <pre>{ReactHtmlParser(item.description || '-')}</pre> */}
                                                        {getDescriptionFragment(salesInvoiceDetail, item, i + 1)}
                                                    </div>}
                                                </td>}
                                                {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                                                {(!isColumnEmpty.qty && isColumnEmpty.studentName) && <td className="text-center">{item.quantityToInvoice + (item.uomName ? ' ' + item.uomName : '')}</td>}
                                                {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                                                {!isColumnEmpty.specialDiscount && <td className="text-center">{fixedDecimalNumber(item.basePrice || 0) || '-'}</td>}
                                                {!isColumnEmpty.specialDiscount && <td className="text-right">{getSpecialDiscount(item, 'amountPercent')}</td>}
                                                {!isColumnEmpty.rate && <td className="text-center">{fixedDecimalNumber(item.anItemSalePrice || 0) || '-'}</td>}
                                                {!isColumnEmpty.hsn && <td className="text-center">{item.hsnCode || '-'}</td>}
                                                {!isColumnEmpty.discount && <td className="text-right">{getDiscountAmount(item, 'amount')} {getDiscountAmount(item, 'amountPercent')}</td>}
                                                {!isColumnEmpty.discount && <td className="text-right">{getDiscountType(item, 'discount')}</td>}
                                                {!isColumnEmpty.tax && <td className="text-right">{getTotalTaxAmount(item, isPlaceOfSupplySameAsRelationship)} {getTotalTaxPercent(item, 'amountPercent', isPlaceOfSupplySameAsRelationship)}</td>}
                                              
                                            {<td className="text-right">{getNetAmount(item,isPlaceOfSupplySameAsRelationship,true)}</td>}
                                                <td className="text-right">{getNetAmount(item, isPlaceOfSupplySameAsRelationship) || '-'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                          
                        </div>

                        <Row>
                            <Col span={9} className="read-only-content">
                               
                            <Row>
                                <Col span={24}>
                                {getUomFragment(uomList, isPlaceOfSupplySameAsRelationship)}
                               </Col>
                           </Row>
                                {salesInvoiceDetail.docDetailList && salesInvoiceDetail.docDetailList.length ? <Row>
                                    <Col span={24} className="title">
                                        <FormattedMessage id='modal.txDetails.common.attachments' defaultMessage='' />
                                    </Col>
                                    <Col span={12}>
                                        <AWSFileTable {...props} docDetailList={salesInvoiceDetail.docDetailList} fetchTxDetail={(data) => {
                                            props.fetchSalesInvoiceDetail({
                                                customerId: salesInvoiceDetail.customerId,
                                                invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                                relationshipId: salesInvoiceDetail.relationshipId
                                            })
                                        }} />
                                        {/* {
                                            salesInvoiceDetail.docDetailList.map((doc) => {
                                                return <span className={"cursor-pointer"} onClick={()=>{
                                                    props.downloadFileFromBucket({
                                                        bucketName: doc.bucketName,
                                                        awsKeyName: doc.awsKeyName,
                                                        fileName: doc.fileName
                                                    })
                                                }}>{doc.fileName} &nbsp;</span>
                                            })
                                        } */}
                                    </Col>
                                </Row> : null}
                                {(salesInvoiceDetail.additionalInfoList && salesInvoiceDetail.additionalInfoList.length && salesInvoiceDetail.additionalInfoList[0].bankDetails) ?
                                    <Row>
                                        <Col span={7} className="title">
                                            <FormattedMessage id='customer.salesInvoice.form.bankDetails.label' defaultMessage='' />
                                        </Col>
                                        <Col span={17}>
                                            {getBankDetailsFragment(((salesInvoiceDetail.additionalInfoList || [])[0] || {}).bankDetails)}
                                        </Col>
                                    </Row> : ""
                                }
                                {(props.selectedLedgers && (Object.keys(props.selectedLedgers)).length) ?
                                    <div>
                                        <br />
                                        <Row>
                                            <Col span={7} className="title">
                                                <FormattedMessage id='drawer.ledger.title' defaultMessage='' />
                                            </Col>
                                            <Col span={17}>
                                                {getLedgerDetailFragment(props.selectedLedgers)}
                                            </Col>
                                        </Row>
                                    </div> : ""
                                }
                                <br />
                                {salesInvoiceDetail.remarksInternal ?
                                    <Row>
                                        <Col span={7} className="title">
                                            <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage='' />
                                        </Col>
                                        <Col span={17}>
                                            {ReactHtmlParser(salesInvoiceDetail.remarksInternal)}
                                        </Col>
                                    </Row> : ""
                                }

                                {salesInvoiceDetail.remarksCustomer ?
                                    <Row className="pt5">
                                        <Col span={7} className="title">
                                            <FormattedMessage id='modal.txDetails.common.customerRemarks' defaultMessage='' />
                                        </Col>
                                        <Col span={17}>
                                            {ReactHtmlParser(salesInvoiceDetail.remarksCustomer)}
                                        </Col>
                                    </Row> : ""
                                }
                                {(salesInvoiceDetail.additionalInfoList && salesInvoiceDetail.additionalInfoList.length && salesInvoiceDetail.additionalInfoList[0].paymentGatewayName) ?
                                    <div>
                                        <Row className="pt5">
                                            <Col span={7} className="title">
                                                <FormattedMessage id='payment.gateway' defaultMessage='' />
                                            </Col>
                                            <Col span={17}>
                                                <b>YES</b>
                                            </Col>
                                        </Row>
                                        <Row className="pt5">
                                            <Col span={7} className="title">
                                                <FormattedMessage id='paymentGateway.available' defaultMessage='' />
                                            </Col>
                                            <Col span={17}>
                                                {salesInvoiceDetail.additionalInfoList[0].paymentGatewayName}
                                            </Col>
                                        </Row>
                                    </div> : ""
                                }
                            </Col>

                            <Col offset={7} span={8}>
                                <div className="bill-summary mt5">
                                    <Row>
                                        <Col span={14}>
                                            <div className="right-txt">
                                                <FormattedMessage id='modal.txDetails.common.subTotal' defaultMessage='' />
                                            </div>
                                        </Col>

                                        <Col span={10} className="text-right ">
                                            {salesInvoiceDetail.isMultiCurrency ?
                                                <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                            }
                                            {getSubTotalOrTaxOrDiscountAmount(salesInvoiceDetail, "SubTotal")}
                                        </Col>
                                    </Row>

                                    {getTxTypeData(salesInvoiceDetail, 'Coupon', 'amount') ? <div className="pt10">
                                                    <Row>
                                                        <Col span={14}>
                                                            <div className="right-txt">
                                                                <FormattedMessage id='modal.txDetails.common.coupon' defaultMessage='' />
                                                              {" "}
                                                                ({couponData.taxName})
                                                            </div>
                                                        </Col>

                                                        <Col span={10} className="text-right ">
                                                            {salesInvoiceDetail.isMultiCurrency ?
                                                                <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                            {getTxTypeData(salesInvoiceDetail, 'Coupon', 'amount')}
                                                        </Col>
                                                    </Row>
                                                </div>:""}
                                    {Number(getSubTotalOrTaxOrDiscountAmount(salesInvoiceDetail, "Discount")) ?
                                        <div className="pt10">
                                            <Row>
                                                <Col span={14}>
                                                    <div className="right-txt">
                                                        <FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' />
                                                    </div>
                                                </Col>

                                                <Col span={10} className="text-right ">
                                                    {salesInvoiceDetail.isMultiCurrency ?
                                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                    }
                                                    {getSubTotalOrTaxOrDiscountAmount(salesInvoiceDetail, "Discount")}
                                                </Col>
                                            </Row>
                                        </div> : ''}

                                    {/* <div>
                                        {(filter(salesInvoiceDetail.customerInvoiceCOATxList || [], (ele) => {
                                            return ele.txType === LEDGER_TYPE.TYPE_EXPENSE && ele.accountingEntry === ACCOUNTING_ENTRY_TYPE.CREDIT;
                                        })).map((expenseObj) => {
                                            return (
                                                <div className="pt10">
                                                    <Row>
                                                        <Col span={12}>
                                                            <div className="right-txt">
                                                                {expenseObj.expenseTypeName}
                                                            </div>
                                                        </Col>

                                                        <Col span={12} className="text-right ">
                                                            {salesInvoiceDetail.isMultiCurrency ?
                                                                <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                            }
                                                            {expenseObj.amount}
                                                            <div style={{fontSize: '10px'}}>{salesInvoiceDetail.isMultiCurrency ?
                                                                <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                            }{expenseObj.expenseTaxAmount} {expenseObj.displayTaxName} </div>
                                                        </Col>
                                                    </Row>
                                                </div>)
                                        })}
                                    </div> */}
                                    {
                                        (salesInvoiceDetail.customerInvoiceCOATxList && salesInvoiceDetail.customerInvoiceCOATxList.length) && (filter(salesInvoiceDetail.customerInvoiceCOATxList || [],
                                            { txType: 'expense', accountingEntry: 'debit' }
                                        )).map((expenseObject, i) => {

                                            return (<div key={'exp' + i}>
                                                <Row className="pt10">
                                                    <Col span={14}>
                                                        <div className="right-txt"><FormattedMessage id='modal.txDetails.common.expense' defaultMessage='' />({(expenseObject.ledgerAccountName || '')})</div>
                                                    </Col>
                                                    <Col span={10} className="text-right">
                                                        {salesInvoiceDetail.isMultiCurrency ?
                                                            <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                            : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                        }
                                                        {fixedDecimalNumber(expenseObject.amount)}
                                                    </Col>
                                                </Row>
                                            </div>
                                            )
                                        })
                                    }
                                    {getTxTypeData(salesInvoiceDetail, 'expense', 'amount') ?
                                        <div className="pt10">
                                            <Row>
                                                <Col span={14}>
                                                    <div className="right-txt">
                                                        <FormattedMessage id='total.text' defaultMessage='' /> <FormattedMessage id='modal.txDetails.common.expense' defaultMessage='' />
                                                    </div>
                                                </Col>

                                                <Col span={10} className="text-right">
                                                    {salesInvoiceDetail.isMultiCurrency ?
                                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                    }
                                                    {/* {getTxTypeData(invoiceData, 'expense', 'amount')} */}
                                                    {getSubTotalOrTaxOrDiscountAmount(salesInvoiceDetail, "expense")}
                                                </Col>
                                            </Row>
                                        </div> : ''
                                    }
                                    {/* { getTxTypeData(salesInvoiceDetail, 'expense', 'amount') ?
                                        <div className="pt10">
                                            <Row>
                                                <Col span={12}>
                                                    <div className="right-txt">
                                                    <FormattedMessage id='modal.txDetails.common.expense' defaultMessage='' /> 
                                                </div>
                                                </Col>

                                                <Col span={12} className="text-right ">
                                                    {salesInvoiceDetail.isMultiCurrency ?
                                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                    }
                                                    {getTxTypeData(salesInvoiceDetail, 'expense', 'amount')}
                                                </Col>
                                            </Row>
                                        </div> : ''
                                    } */}

                                    {
                                        (itemLevelTaxes && itemLevelTaxes.length !== 0) && itemLevelTaxes.map((taxObj, i) => {

                                            return (
                                                props.companyInfo.countryName === 'India' ? 
                                                (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                    <div className="pt10">
                                                        <Row>
                                                            <Col span={14}>
                                                                <div className="right-txt">
                                                                     CGST @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)}
                                                                </div>
                                                            </Col>
                                                            <Col span={10} className="text-right ">
                                                                {salesInvoiceDetail.isMultiCurrency ?
                                                                    <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                }
                                                                {fixedDecimalNumber(taxObj.taxAmount)}
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="pt10">
                                                        <Row>
                                                            <Col span={14}>
                                                                <div className="right-txt">  SGST @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                            </Col>
                                                            <Col span={10} className="text-right ">
                                                                {salesInvoiceDetail.isMultiCurrency ?
                                                                    <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                }
                                                                {fixedDecimalNumber(taxObj.taxAmount)}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div> : <div key={i}>
                                                    <div className="pt10">
                                                        <Row>
                                                            <Col span={14}>
                                                                <div className="right-txt">  {taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                            </Col>
                                                            <Col span={10} className="text-right ">
                                                                {salesInvoiceDetail.isMultiCurrency ?
                                                                    <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                }
                                                                {fixedDecimalNumber(taxObj.taxAmount)}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                ) :
                                                    <div key={i}>
                                                        <div className="pt10">
                                                            <Row>
                                                                <Col span={14}>
                                                                    <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                </Col>
                                                                <Col span={10} className="text-right ">
                                                                    {salesInvoiceDetail.isMultiCurrency ?
                                                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                    }
                                                                    {fixedDecimalNumber(taxObj.taxAmount)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                            )
                                        })
                                    }
                                            {getTxTypeData(salesInvoiceDetail, LEDGER_TYPE.TYPE_ROUNDING_OFF, 'amount') ?
                                                <div className="pt10">
                                                    <Row>
                                                        <Col span={12}>
                                                            <div className="right-txt">
                                                                <FormattedMessage id='common.salesRoundingOff.label' defaultMessage='' />
                                                            </div>
                                                        </Col>

                                                        <Col span={12} className="text-right ">
                                                            {salesInvoiceDetail.isMultiCurrency ?
                                                                <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                            }
                                                            {getTxTypeData(salesInvoiceDetail, LEDGER_TYPE.TYPE_ROUNDING_OFF, 'amount')}
                                                        </Col>
                                                    </Row>
                                                </div> : ''
                                            }           
                                    <div className="pt10">
                                        <Row>
                                            <Col span={14}>
                                                <div className="right-txt bold">
                                                    <FormattedMessage id='modal.txDetails.common.grandTotal' defaultMessage='' />
                                                </div>
                                            </Col>

                                            <Col span={10} className="text-right ">
                                                {salesInvoiceDetail.isMultiCurrency ?
                                                    <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                }
                                                {salesInvoiceDetail.invoiceTotalAmount && fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount)}
                                            </Col>
                                            <Col span={24} className="">
                                                {salesInvoiceDetail.isMultiCurrency ?
                                                <span> {salesInvoiceDetail.foreignCurrency + ": "} </span>
                                                : <span> { props.companyInfo.currencyCode + ": "} </span>
                                                }    {(salesInvoiceDetail.invoiceTotalAmount) && numberToWordsWithDecimals(fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount),props.companyInfo.currencyCode === "INR"?"Paise":"Cent") || ''}
                                            </Col>
                                        </Row>
                                    </div>

                                    <Row>
                                        <Col span={24}>
                                            {props.companyInfo.countryName === 'India' ?getHsnFragment(itemLevelTaxesByHsn, isPlaceOfSupplySameAsRelationship):''}
                                        </Col>
                                    </Row>
                                </div>

                                <div className="upload-link new-pic" title={props.intl.formatMessage({ id: 'uploadSignature.tooltip' })} style={{ width: "50px", height: "50px" }}>
                                    <CustomUploader {...customProps} />
                                    {salesInvoiceDetail.stampAwsKey && <div className="cursor-pointer" onClick={() => {
                                        props.deleteStamp({
                                            customerId: salesInvoiceDetail.customerId,
                                            invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                            relationshipId: salesInvoiceDetail.relationshipId,
                                            objectKey: salesInvoiceDetail.stampAwsKey,
                                            folderName: salesInvoiceDetail?.folderName,
                                            bucketName: props?.companyInfo?.bucketName,
                                        }, props)
                                    }}> <FormattedMessage id='modal.txDetails.common.deleteStamp' defaultMessage='' /> </div>}
                                </div>
                            </Col>
                        </Row>

                        <div className="contact-details foot-fix">
                            {salesInvoiceDetail.footer &&
                                <Fragment>
                                    {ReactHtmlParser(salesInvoiceDetail.footer)}
                                </Fragment>
                            }
                        </div>
                        </Skeleton>
                    </div>
                    :""}
                </div>
            </div>
        </div>
    );

}

const getSpecialDiscount = (row, toReturn) => {
    if (!row) return;
    let specialDiscount = row.basePrice - row.anItemSalePrice;
    if (specialDiscount && row.basePrice) {
        if (toReturn === 'amountPercent') {
            let percent = fixedDecimalNumber((specialDiscount * 100) / row.basePrice);
            let amount = fixedDecimalNumber(specialDiscount)
            return amount + " (" + percent + ")%";
        } else if (toReturn === 'amount') {
            return fixedDecimalNumber(specialDiscount)
        }

    } else if (!row && toReturn === 'amount') {
        return 0;
    }
    else {
        return "-";
    }
}

const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
    let total = 0;
    data && data.customerInvoiceDetailsList && data.customerInvoiceDetailsList.forEach(function (d) {
        // total = 0;
        if (type === 'SubTotal') {
            total = total + (d.anItemSalePrice * d.quantityToInvoice);
        }
        // if (type === 'Discount') {
        //     let discountObject = d.customerInvoiceCOATxList.find(tx => (tx.txType === 'discount'));
        //     if (discountObject) {
        //         total = total + parseFloat(discountObject.amount);
        //     }
        // }
        if (type === "Discount") {
        
            let discountObj = d.discountAmount
            if (discountObj) {
              total = total + parseFloat(discountObj);
            }
          }
    });
    if (type === 'expense') {
        data && data.customerInvoiceCOATxList && data.customerInvoiceCOATxList.forEach(function (discountObject) {
            if (discountObject.txType === 'expense' && discountObject.accountingEntry === 'debit') {
                total = total + parseFloat(discountObject.amount);
            }
        });
    }
    if (type === 'Discount' && data && data.customerInvoiceCOATxList) {
        let discount = data.customerInvoiceCOATxList.filter(tx => (tx.txType === 'discount' && tx.accountingEntry === 'credit'));
        if (discount) {
            discount.map((obj) => {

                total = total + parseFloat(obj.amount);
            })
        }
    }
    return fixedDecimalNumber(total);
}
const getDiscountAmount = (row, toReturn) => {
    if (!row || !row.discountAmount) return;
    if (row?.discountAmount) {
      return  toReturn === "amount"? fixedDecimalNumber(row?.discountAmount) :  "(" + fixedDecimalNumber(row?.discountPercentage) + "%)"
      }
  };
const getDiscountType = (row, type) => {
    if (!row || !row.customerInvoiceCOATxList) return;
    let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type)) || {};
    if (!data || !data.discountType) {
        return "-";
    }
    else {
        return data.discountType;
    }
}

const getTxTypeData = (row, type, toReturn) => {
    if (!row || !row.customerInvoiceCOATxList) return;
    let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type));
    if (data) {
        if (data[toReturn] && toReturn === 'amountPercent') {
            if (type === 'VAT') {
                return "VAT(" + fixedDecimalNumber(data[toReturn]) + "%)";
            }
            return "(" + fixedDecimalNumber(data[toReturn]) + "%)";
        } else if (data[toReturn] && toReturn === 'amount') {
            return fixedDecimalNumber(data[toReturn]);
        }
        else if(toReturn === "coupon"){
            return data;
        }
        else {
            return ""
        }

    } else if (!data && toReturn === 'amount') {
        return "";
    }
    else {
        return "-";
    }
}

const getTotalTaxAmount = (row, isPlaceOfSupplySameAsRelationship) => {
    let tax = getTxTypeData(row, 'tax', 'amount', true) || 0;
    let totalTax = fixedDecimalNumber(Number(tax));
    if (isPlaceOfSupplySameAsRelationship) {

        return fixedDecimalNumber(totalTax * 2);
    } else {
        return fixedDecimalNumber(totalTax)
    }

}

const getTotalTaxPercent = (row, toReturn, isPlaceOfSupplySameAsRelationship) => {
    let tax1 = row.customerInvoiceCOATxList.find(tx => (tx.txType === 'tax')) || {};
    let totalPercent = Number(tax1.amountPercent || 0);
    if (toReturn === 'amountPercent') {
        return isPlaceOfSupplySameAsRelationship ? totalPercent ? ('(' + fixedDecimalNumber(totalPercent * 2) + "%)") : '' : totalPercent ? ('(' + fixedDecimalNumber(totalPercent) + "%)") : '';
    }
    else {
        return isPlaceOfSupplySameAsRelationship ? fixedDecimalNumber(totalPercent) : fixedDecimalNumber(totalPercent);
    }
}

// const getNetAmount = (row, isPlaceOfSupplySameAsRelationship) => {
//     let tax = getTotalTaxAmount(row) || 0;
//     let taxValue = isPlaceOfSupplySameAsRelationship ? tax * 2 : tax
//     let discount = getTxTypeData(row, 'discount', 'amount', true) || 0;
//     let netAmountItem =
//         (Number(row.quantityToInvoice * row.anItemSalePrice) + Number(taxValue) - Number(discount));
//     return fixedDecimalNumber(netAmountItem);
// }
const getNetAmount = (row,isPlaceOfSupplySameAsRelationship,withoutTax) => {
    let tax = getTotalTaxAmount(row) || 0;
    let taxValue = isPlaceOfSupplySameAsRelationship ? tax*2:tax
    let discount = getDiscountAmount(row,'amount') || 0;
    let netAmountItemwithoutTax = (Number(row.quantityToInvoice * row.anItemSalePrice) - Number(discount));
  
    let netAmountItem = (Number(row.quantityToInvoice * row.anItemSalePrice)+Number(taxValue) - Number(discount));
    if(withoutTax){
      return fixedDecimalNumber(netAmountItemwithoutTax)
    }else{
        return fixedDecimalNumber(netAmountItem);
    }
  
  }


  const calculateTaxRateList = (customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship,salesInvoiceDetail,props) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerInvoiceDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0,

            }
            totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
            let discount = getDiscountAmount(rowdata, "amount");
            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'tax')) || {};
            taxObj.taxPercent = tax1.amountPercent;
            taxObj.taxAmount = tax1.amount;
            taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
            totalTax = totalTax + tax1.amount;
            taxObj.taxName = tax1.taxName;
            //  let tax2 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'sgst'));
            // let tax3 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'igst'));
            // let tax4 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'vat'));
            //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
            //       taxObj.taxPercent = tax1.amountPercent + tax2.amountPercent;
            //       taxObj.taxAmount = tax1.amount + tax2.amount;
            //       totalTax = totalTax + taxObj.taxAmount;
            //   }
            //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
            //       taxObj.taxPercent = tax3.amountPercent;
            //       taxObj.taxAmount = tax3.amount;
            //       totalTax = totalTax + tax3.amount;
            //   }
            //   else if (tax4) {
            //       taxObj.taxPercent = tax4.amountPercent;
            //       taxObj.taxAmount = tax4.amount;
            //       totalTax = totalTax + tax4.amount;
            //   }
            let couponData = getTxTypeData(salesInvoiceDetail, 'Coupon', 'coupon');
            let setting = JSON.parse(props.companyInfo.couponSetting || "{}");

           if(setting?.isCoupanAppliedBeforeTax && couponData){

               if(couponData?.amountPercent){
                totalAmountOfColumn = totalAmountOfColumn *(couponData?.amountPercent/100);

               }
           }
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
              
                  if (availableTaxObj ) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.taxPercent === taxObj.taxPercent) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                  taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0))
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });

    let dataAfterExpenseTax = calculteExpenseTax(itemLevelTaxes, salesInvoiceDetail,isPlaceOfSupplySameAsRelationship)

    return dataAfterExpenseTax;
}
const calculteExpenseTax = (itemLevelTaxes, salesInvoiceDetail,isPlaceOfSupplySameAsRelationship) => {
    
  
    let expenseTax = salesInvoiceDetail?.customerInvoiceCOATxList?.filter(
      (item) => item.txType === "expense" && item.accountingEntry === "credit"
    );
  
    let v = expenseTax?.forEach((rowdata) => {
      if (rowdata.taxId > 0) {

        let taxObj = {
          subtotal: rowdata.amount,
          taxPercent: isPlaceOfSupplySameAsRelationship ? rowdata.taxId/2 :rowdata.taxId,
          taxAmount: ((isPlaceOfSupplySameAsRelationship ? rowdata.taxId/2 :rowdata.taxId) * rowdata.amount) / 100,
          taxName : rowdata.taxName
        };
  
        let existingTax = itemLevelTaxes.find(
          (tax) => tax.taxPercent === (isPlaceOfSupplySameAsRelationship ? rowdata.taxId/2 :rowdata.taxId)
        );
  
        if (existingTax) {
          existingTax.subtotal += taxObj.subtotal;
          existingTax.taxAmount += taxObj.taxAmount;
        } else {
          itemLevelTaxes.push(taxObj);
        }
      }
    });
  
    return itemLevelTaxes;
  };
const calculateTaxRateListByHsn = (customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship,salesInvoiceDetail,props) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerInvoiceDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0,
              hsnCode:null

            }
            totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
            let discount = getDiscountAmount(rowdata, "amount");

            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'tax')) || {};
            taxObj.taxPercent = tax1.amountPercent;
            taxObj.taxAmount = tax1.amount;
            taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
            totalTax = totalTax + tax1.amount;
            taxObj.taxName = tax1.taxName;
          taxObj.hsnCode = rowdata.hsnCode
            
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                const availableHsnObj = find(itemLevelTaxes, {
                    hsnCode: taxObj.hsnCode,
                  });
                  if (availableTaxObj  && availableHsnObj) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.hsnCode === taxObj.hsnCode) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                  taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0))
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });
    
    let dataAfterExpenseTax = calculteExpenseTaxByHsn(itemLevelTaxes, salesInvoiceDetail,props,isPlaceOfSupplySameAsRelationship)
    return dataAfterExpenseTax;
}
const calculteExpenseTaxByHsn = (itemLevelTaxes, salesInvoiceDetail,props,isPlaceOfSupplySameAsRelationship) => {
    let finalVal = [];
  
    let expenseTax = salesInvoiceDetail?.customerInvoiceCOATxList?.filter(
      (item) => item.txType === "expense" && item.accountingEntry === "credit"
    );
    let v = expenseTax?.forEach((rowdata) => {
      if (rowdata.taxId > 0) {
        let taxObj = {
          subtotal: rowdata.amount,
          taxPercent: isPlaceOfSupplySameAsRelationship ? rowdata.taxId/2 :rowdata.taxId,
          taxAmount: ((isPlaceOfSupplySameAsRelationship ? rowdata.taxId/2 :rowdata.taxId) * rowdata.amount) / 100,
          taxName : rowdata.taxName
        };
        let obj = Number((find(props.allGstRates, { financeGstGoodsRates: rowdata.taxSingleRate }) || {})?.hsnCode || null);
        let existingTax = itemLevelTaxes.find(
          (tax) => tax.hsnCode === obj
        );

        taxObj.hsnCode = obj?obj:null;
        if (existingTax) {
          existingTax.subtotal += taxObj.subtotal;
          existingTax.taxAmount += taxObj.taxAmount;
        } else {
          itemLevelTaxes.push(taxObj);
        }
      }
    });
  
    return itemLevelTaxes;
  };
const updateColumnEmptyCheck = (isColumnEmpty, invoiceData, props) => {
    if (!invoiceData) return isColumnEmpty;
    for (let i in invoiceData.customerInvoiceDetailsList) {
        let row = invoiceData.customerInvoiceDetailsList[i];
        if (row.studentName) { isColumnEmpty.studentName = false; };
        if (row.studentGrade) { isColumnEmpty.studentGrade = false; };
        if (row.studentClassName) { isColumnEmpty.studentClassName = false; };
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantityToInvoice) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.anItemSalePrice) { isColumnEmpty.rate = false; }
        if (row.specialDiscount) { isColumnEmpty.specialDiscount = false; }
        if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
        if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
        if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
        if (row.documentDetails && row.documentDetails.length) { isColumnEmpty.documentDetails = false; }
        if (row.comment) { isColumnEmpty.comment = false; }
    }
    return isColumnEmpty;
}

const isTxTypeEmpty = (row, type) => {
    if (!row) return true;
  
    let data = row.customerInvoiceCOATxList?.find((tx) => tx.txType === type);
    if (type === "tax") {
  
      if (data && data.amount) {
        return false
      } else {
        return true
      }
    }
    if (row && row.discountPercentage) {
      return false;
    } else {
      return true;
    }
  };

const getAddressInfoFragment = (obj, additionInfo) => {
    const fragmentObj = <Fragment>
       <div className="billing-address first-caps">
            {obj.streetAddress1 || obj.streetAddress2 ? (

                <div>
                    {obj.streetAddress1} {obj.streetAddress2}{", "}
                </div>

            ) : ""}
            {obj.cityName || obj.stateName || obj.zipCode ? (

                <div>
                    {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                </div>

            ) : ""}
             {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
        </div>
        {/* {
            additionInfo.relationshipPhoneNumber
                ? <div className='billing-address'>
                   {additionInfo.relationshipPhoneNumber}
                </div> :
                ''
        } */}
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                </div> :
                ''
        }
        {/* {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        } */}

    </Fragment>

    return fragmentObj;
}

const getTaxIdentificationsFragment = (taxIdentifications) => {
    if (!taxIdentifications) return <Fragment />;
    let taxIdentificationsList = []
    if (taxIdentifications) {
        taxIdentificationsList = JSON.parse(taxIdentifications);
    }
    const fragmentObj = <Fragment>
        {taxIdentificationsList.map((obj, i) => {
            return <div className='billing-address'>
                {obj.taxIdentificationType || ''} - {obj.taxIdentificationNumber}
            </div>
        })}
    </Fragment>

    return fragmentObj;
}

const getLedgerDetailFragment = (selectedLedgers) => {
    const fragmentObj = <Fragment>

        <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid gray', textAlign: 'center' }}>
            <thead>
                <tr>
                    <th style={{ border: '1px solid gray' }}>Transaction</th>
                    <th style={{ border: '1px solid gray' }}>Credit</th>
                    <th style={{ border: '1px solid gray' }}>Debit</th>
                </tr>
            </thead>
            <tbody>
                {selectedLedgers.salesLedgerId ? <tr >
                    <td style={{ border: '1px solid gray' }}>Sales</td>
                    <td style={{ border: '1px solid gray' }}>{selectedLedgers.salesLedgerName}</td>
                    <td style={{ border: '1px solid gray' }}>{selectedLedgers.salesDebtorLedgerName}</td>
                </tr> : ''}
                {selectedLedgers.discountDebtorLedgerId ? <tr >
                    <td style={{ border: '1px solid gray' }}>Discount</td>
                    <td style={{ border: '1px solid gray' }}>{selectedLedgers.discountDebtorLedgerName}</td>
                    <td style={{ border: '1px solid gray' }}>{selectedLedgers.salesDiscountLedger}</td>
                </tr> : ''}
                {selectedLedgers.salesOutputTaxLedgerId ? <tr >
                    <td style={{ border: '1px solid gray' }}>Tax</td>
                    <td style={{ border: '1px solid gray' }}>{selectedLedgers.salesOutputTaxLedgerName}</td>
                    <td style={{ border: '1px solid gray' }}>{selectedLedgers.taxDebtorLedgerName}</td>
                </tr> : ''}
                {selectedLedgers.expenseLedgerId ? <tr >
                    <td style={{ border: '1px solid gray' }}>Expense</td>
                    <td style={{ border: '1px solid gray' }}>{selectedLedgers.expenseLedgerName}</td>
                    <td style={{ border: '1px solid gray' }}>{selectedLedgers.expenseDebtorLedgerName}</td>
                </tr> : ''}

            </tbody>
        </table>
    </Fragment>

    return fragmentObj;
}
const getHsnFragment = (itemLevelTaxes, isPlaceOfSupplySameAsRelationship) => {
  const hsnData = itemLevelTaxes?.filter(item=>item.hsnCode > 0)
    const fragmentObj = isPlaceOfSupplySameAsRelationship ?
        <Fragment>
            <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid gray', textAlign: 'center' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid gray' }}>HSN</th>
                        <th style={{ border: '1px solid gray' }}>SGST</th>
                        <th style={{ border: '1px solid gray' }}>CGST</th>
                        <th style={{ border: '1px solid gray' }}>Amount</th>
                    </tr>
                </thead>
                <tbody>

                    {hsnData?.map((itemLevelTaxe, index) => {
                        return (
                            <tr>
                                <td style={{ border: '1px solid gray' }}>{itemLevelTaxe.hsnCode}</td>
                                <td style={{ border: '1px solid gray' }}>
                                    {itemLevelTaxe.taxPercent}% on {fixedDecimalNumber(itemLevelTaxe.subtotal)} = {fixedDecimalNumber(itemLevelTaxe.taxAmount)}
                                </td>
                                <td style={{ border: '1px solid gray' }}>
                                    {itemLevelTaxe.taxPercent}% on {fixedDecimalNumber(itemLevelTaxe.subtotal)} = {fixedDecimalNumber(itemLevelTaxe.taxAmount)}
                                </td>
                                <td style={{ border: '1px solid gray' }}>
                                    {fixedDecimalNumber(itemLevelTaxe.taxAmount*2)}
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
        </Fragment> :

        <Fragment>

            <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid gray', textAlign: 'center' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid gray' }}>HSN</th>
                        <th style={{ border: '1px solid gray' }}>IGST</th>
                        <th style={{ border: '1px solid gray' }}>Amount</th>
                    </tr>
                </thead>
                <tbody>

                    {hsnData?.map((itemLevelTaxe, index) => {
                        return (
                            <tr>
                                <td style={{ border: '1px solid gray' }}>{itemLevelTaxe.hsnCode}</td>
                                <td style={{ border: '1px solid gray' }}>
                                    {itemLevelTaxe.taxPercent}% on {fixedDecimalNumber(itemLevelTaxe.subtotal)} = {fixedDecimalNumber(itemLevelTaxe.taxAmount)}
                                </td>
                                <td style={{ border: '1px solid gray' }}>
                                    {fixedDecimalNumber(itemLevelTaxe.taxAmount)}
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
        </Fragment>

    return hsnData?.length ?fragmentObj:<div></div>;
}



const getBankDetailsFragment = (bankDetails) => {
    if (!bankDetails) return <Fragment />;
    let bankDetailsList = []
    if (bankDetails) {
        bankDetailsList = JSON.parse(bankDetails);
    }
    const fragmentObj = <Fragment>

        <table style={{ width: "100%" }}>
            <thead>
                <tr>
                    <th>Bank Name</th>
                    <th>Account #</th>
                    <th>IFSC Code</th>
                </tr>
            </thead>
            <tbody>
                {(bankDetailsList || []).map((item, i) => {
                    return (
                        <tr key={i}>
                            <td>{item.name}</td>
                            <td>{item.accountNumber}</td>
                            <td>{item.ifscCode}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </Fragment>

    return fragmentObj;
}

const getContactNameFragment = (additionInfo) => {
    if (!additionInfo) return <Fragment />;
    let contactName = "";
    if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
    if (additionInfo.firstName) contactName += additionInfo.firstName;
    if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
    if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
    const fragmentObj = <Fragment>
        {
            (contactName && contactName.length)
                ? <div className='title'>
                    {contactName}
                </div> :
                ''
                
        }
        <div className=''>
            {additionInfo.emailAddress||''}
        </div>
        <div className=''>
            {additionInfo.workPhone|| additionInfo.cellPhone || ''}
        </div>
    </Fragment>
    return fragmentObj;
}

const getVariantFragment = (item) => {
    let itemName = null;
    if (item.itemName) {
        itemName = item.itemName;
    }
    if (item.itemVariantName) {
        itemName = itemName + ' (' + item.itemVariantName + ')'
    }
    return itemName ? itemName : '';
}
const calculateUomData = (
    customerInvoiceDetailsList,
    isPlaceOfSupplySameAsRelationship
  ) => {
  
    let itemLevelUom = [];
    customerInvoiceDetailsList.forEach(function (rowdata, index) {
      if (rowdata.quantityToInvoice && rowdata.uomName) {
        let uomObj = {
          uomName: null,
          uomQty: null,
        };
  
        uomObj.uomName = rowdata.uomName;
        uomObj.uomQty = rowdata.quantityToInvoice
        if (uomObj.uomName) {
          const availableUomObj = find(itemLevelUom, {
            uomName: uomObj.uomName,
          });
          if (availableUomObj) {
            itemLevelUom = filter(itemLevelUom, (ele) => {
              if (ele.uomName === uomObj.uomName) {
                ele.uomQty = ele.uomQty + uomObj.uomQty;
              }
              return true;
            });
          }
          else {
            itemLevelUom.push(uomObj);
          }
        }
      }
    });
  
    return itemLevelUom;
  };

  const getUomFragment = (uomList, isPlaceOfSupplySameAsRelationship) => {
    const uomData = uomList?.filter(item => item.uomName?.length)
  
    const fragmentObj =
      <Fragment>
        <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid gray', textAlign: 'center' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid gray' }}>UOM</th>
              <th style={{ border: '1px solid gray' }}>Quantity</th>
  
            </tr>
          </thead>
          <tbody>
  
            {uomData?.map((uomObj, index) => {
              return (
                <tr>
                  <td style={{ border: '1px solid gray' }}>{uomObj.uomName}</td>
                  <td style={{ border: '1px solid gray' }}>{uomObj.uomQty}</td>
                </tr>
              )
            })}
  
          </tbody>
        </table>
      </Fragment>
  
    return uomData.length ? fragmentObj : <div></div>;
}
const getDescriptionFragment = (salesInvoiceDetail, item, index) => {
    if (!item) return <Fragment />;
    const fragmentObj = <Fragment>
        {
            item.materialNumber
                ? <div>
                    <span className="title"> Material # : </span>{item.materialNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.stockNumber
                ? <div>
                    <span className="title"> Stock # : </span>{item.stockNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.partNumber
                ? <div>
                    <span className="title"> Part # : </span>{item.partNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.description
                ? <div>
                    {ReactHtmlParser(item.description || '')}
                </div> :
                ''
        }
        {
            item.origin
                ? <div>
                    <br></br>
                    <span className="title"> Origin : </span>{item.origin}
                </div> :
                ''
        }
        {
            item.hsCode
                ? <div>
                    <span className="title"> HS Code : </span>{item.hsCode}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.warehouse
                ? <div>
                    <span className="title"> Warehouse Name : </span> <span style={{ 'display': 'inline-block' }}>{ReactHtmlParser(item.warehouse || '')} </span>
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            (salesInvoiceDetail.customerPONumber)
                ? <div> (
                    {salesInvoiceDetail.customerPONumber ? <span className="title">PO {salesInvoiceDetail.customerPONumber} - </span> : ''}
                    {index ? <span className="title"> Line {index}</span> : ''}
                    )
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}

export default Template2;
