import React from "react";
import { Drawer, Row, Col, DatePicker } from "antd";
import {
  getMomentDateForUI,
  getCurrentDateForBackend,
  showToasterMessage,
  fetchPaginationDataIfNeeded,
  fetchDataIfNeeded,
} from "../../../../utils";
import { CustomButton } from "../../../general/CustomButton";
import { Dropdown } from "../../../general/Dropdown";
import { CONSTANTS } from "../../../../static/constants";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { TextBox } from "../../../general/TextBox";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;

const SalesInvoiceFilterComp = (props) => {
  const { updateState, companyInfo } = props;

  const closeDrawer = () => {
    props.updateState({
      salesInvoiceFilterDrawerVisible: false,
    });
  };

  const applyFilter = () => {
    // if(!props.prNum && !props.selectedRequesterName && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedRefNum && !props.selectedProjectMasterId ){
      if ((props.startDate && !props.endDate) || (!props.startDate && props.endDate)) {
        return showToasterMessage({ messageType: "error", description: props.intl.formatMessage({ id: 'invalid.dateRange.message' }) })
    }
    const payload = {
        companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
        filters: {
            customerId: props.selectedCustomerId,
            status: props.selectedStatus,
            projectMasterId: props.selectedProjectMasterId,
            startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
            endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
            formId: props.selectedformId,
            invoiceNumber:props.invoiceNumber,
            module: props.module,
        }
    };
    fetchPaginationDataIfNeeded('fetchSalesInvoices', 'salesInvoiceList', props, payload);
    fetchDataIfNeeded('getSalesInvoiceCount', 'salesInvoiceCount', props, payload);
    closeDrawer();
  };

  const clearFilter = () => {
    props.updateState({
      selectedCustomerId: null,
      selectedStatus:null,
      selectedProjectMasterId: null,
      startDate: null,
      endDate:  null,
      selectedformId: null,
      module:null,
      invoiceNumber:null
    });
    const payload = {
        companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize,
        filters: {formId: 0}
    };
    fetchPaginationDataIfNeeded('fetchSalesInvoices', 'salesInvoiceList', props, payload);
    fetchDataIfNeeded('getSalesInvoiceCount', 'salesInvoiceCount', props, payload);
    closeDrawer();
  };

  const rowGutter = [24, 16];

  return (
    <>
      <Drawer
        title={<div className="drawer-head-txt">Filters</div>}
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.salesInvoiceFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={clearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={applyFilter}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
        <Col span={12}>
            <div className="i-label">Invoice #</div>
           <TextBox

           onChange={(event)=>{
                     props.updateState({
                      invoiceNumber:event.target.value
                     })
           }}
           placeholder="Invoice #"/>
          </Col>
          <Col span={12}>
            <div className="i-label">Customer</div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}

              items={props.customers[props.pageNumber] || []}
              valueKeyName="companyName"
              optionKeyName="customerId"
              value={props.selectedCustomerName}
              onSearch={(searchedText) => {
                props.fetchCustomers({
                  isCompact: true,
                  searchedText: searchedText,
                  pageNumber: 1,
                  hideSpinner: true,
                  pageSize: 100,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                });
              }}
              placeholder={props.intl.formatMessage({
                id: "customer.salesInvoice.form.customer.placeholder",
                defaultMessage: "",
              })}
              onSelect={(customerId, option) => {
                updateState({
                  selectedCustomerId: option.value,
                  selectedCustomerName: option.name,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedCustomerId: undefined,
                  selectedCustomerName: undefined,
                });
              }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">System Status</div>
            <Dropdown
                items={[{ name: 'Paid', value: 'paid' },{ name: 'Partially Paid', value: 'partially.paid' }, { name: 'Unpaid', value: 'unpaid' }, { name: 'Void', value: 'void' }]}
                valueKeyName='name'
                optionKeyName='value'
                value={props.selectedStatusName}
                placeholder={props.intl.formatMessage({ id: 'common.status' })}
                onSelect={(status, option) => {
                    updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                }}
                allowClear={true}
                onClear={() => {
                    updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                }}
            />
          </Col>
      
          <Col span={12}>
            {props.module === "school"?<div className="i-label">Form Short Name</div>:<div className="i-label">Projects</div> }
            {props.module === "school" ?
                            <Dropdown
                                items={props.studentFormList || []}
                                valueKeyName='formShortName'
                                optionKeyName='registrationFormSettingId'
                                value={props.selectedformName}
                                placeholder={props.intl.formatMessage({ id: 'setting.student.registration.list.heading' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedformName: option.name,
                                       selectedformId: option.value
                                       })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedformName: undefined, 
                                      selectedformId: undefined 
                                    })
                                }}
                            />
                            :
                            <Dropdown
                                items={props.projectList}
                                valueKeyName='projectName'
                                optionKeyName='projectMasterId'
                                // projectIdKey='projectMasterId'
                                value={props.selectedProjectName}
                                placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedProjectName: option.name,
                                       selectedProjectMasterId: option.value 
                                      })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedProjectName: undefined, 
                                      selectedProjectMasterId: undefined 
                                    })
                                }}
                            /> 
                              }
          </Col>
         
              {/* <Col span={12}>
              <div className="i-label">Start Date</div>

              <DatePicker
                  allowClear={false}
                  format={format}
                  key='startDate'
                  placeholder='Start Date'
                  defaultValue={props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null}
                  onChange={(selectedDate) => {
                      props.updateState({ startDate: selectedDate })
                  }}
              />
              </Col> */}
             
       
      
       
        {/* <Col span={12}>
                <div className="i-label">End Date</div>

                <DatePicker
                    allowClear={false}
                    format={format}
                    placeholder='End Date'
                    key='endDate'
                    defaultValue={props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null}
                    onChange={(selectedDate) => {
                        props.updateState({ endDate: selectedDate })
                    }}
                />
              </Col> */}
        <Col span={12}>
            <div className="i-label">Invoice Date</div>
            <RangePicker
                            allowEmpty={true}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        /> 
          </Col>
         
        </Row>

   

        <Row gutter={rowGutter}>
         
        
     
         
        
        </Row>
      </Drawer>
    </>
  );
};

export default SalesInvoiceFilterComp;
