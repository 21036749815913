
import axios from "axios";
import config from "../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../utils";
import { COMMON_ACTIONS, E_STORE } from "../../../../static/constants";


export const createBannerSetting = (payload, props) => {
    return (dispatch) => {
      let formData = new FormData();
  
      // Handle single file upload or multiple files
      if (payload?.file) {
        const files = Array.isArray(payload.file) ? payload.file : [payload.file];
        files.forEach(file => {
          if (file.originFileObj) {
            formData.append('fileList', file.originFileObj, file.originFileObj.name);
          }
        });
      }
  
      // Prepare banner setting
      const value = { ...payload?.setting };
      formData.append('bannerSetting', JSON.stringify(value|| {}));
  
      // Optional: Add bucket name if needed
      if (payload?.bucketName) {
        formData.append('bucketName', payload.bucketName);
      }
  
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  
      return axios.post(
        `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_banner_setting/createBannerSetting`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
      .then((res) => {
        // Fetch updated e-store data
        if (payload.ecomSettingSave?.relationshipId) {
          props.fetchBannerSetting({
            relationshipId: payload.ecomSettingSave.relationshipId
          });
        }
  
        // Show success toast
        showToasterMessage({
          messageType: "success",
          description: "Data saved successfully."
        });
  
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        return res.data;
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
  
        // More detailed error handling
        const errorMessage = err.response?.data?.message || "Error occurred while saving banner";
        showToasterMessage({
          messageType: "error",
          description: errorMessage
        });
  
        throw err;
      });
    };
  };
  export const fetchBannerSetting = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
        return axios.get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_banner_setting/getEcomBannerSetting?relationshipId=${payload.relationshipId}`)
          .then(response => {
            // const data = {
            //   pageNo: payload.pageNumber,
            //   list: response.data,
            // };
            dispatch({ type: E_STORE.BANNER_SETTING, data:response.data });
          })
          .catch(err => {
            console.error(err);
          }).finally(()=>{
            dispatch({
              type: COMMON_ACTIONS.HIDE_LIST_LOADER,
            });
          })
      }
};

export const updateImageStatus = (payload,props) => {
    return (dispatch) => {
   
  
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    //   @RequestParam Long ecomBannerSettingId,
    //   @RequestParam Long ecomBannerImageId,
    //   @RequestParam String type,
    //   @RequestParam int isActive
      return axios
        .get(
          `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_banner_setting/activeBanner?relationshipId=${payload.relationshipId}&ecomBannerSettingId=${payload?.ecomBannerSettingId}&ecomBannerImageId=${payload?.ecomBannerImageId}&type=${payload?.type}&isActive=${payload.isActive}`,
        )
        .then((res) => {
          const pay ={
            relationshipId: payload?.relationshipId,
          }
          props.fetchBannerSetting(pay);
          showToasterMessage({
            messageType: "success",
            description: ` data save successfully.`,
          });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            messageType: "fail",
            description: " Error occured",
          });
        });
    };
  };


  export const deleteBanner = (payload,props) => {
    return (dispatch) => {
   
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(
          `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_banner_setting/deleteBanner`,payload
        )
        .then((res) => {
          const pay ={
            relationshipId: payload?.relationshipId,
          }
          props.fetchBannerSetting(pay);
          showToasterMessage({
            messageType: "success",
            description: ` data deleted successfully.`,
          });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            messageType: "fail",
            description: " Error occured",
          });
        });
    };
  };