import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { STUDENT_REGISTRATION_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const getRegisterParentsData = (payload, history) => {
    //let FY = getFinancialYear(payload.companyInfo);
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataForExcel?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&formId=${payload.formId || 0}`)
        .then(res => {
          const data = {
            pageNo: payload.pageNumber,
            list: res.data
          }
            dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.REGISTER_PARENTS_DATA, data });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          }else{
            err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.REGISTER_PARENTS_DATA;
          }
        })
    }
  }


export const resetRegisterParentsData = () => {
  return dispatch => {
    dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.RESET_REGISTER_PARENTS_DATA });
  }
}
