import React from "react";
import ReactApexChart from "react-apexcharts"

class PurchaseChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      //     series: [{
      //       name: `Paid in ${(this.props.companyInfo || {}).currencyCode + " "}`,
      //       data: Object.values(this.props.purchaseData || {})?.map((e) => {

      //         return e?.paidAmount?Math.round(e?.paidAmount):0;
      //       })
      //   },{
      //       name: "Due",
      //       data: Object.values(this.props.purchaseData || {})?.map((e) => {

      //         return -Math.round( (e?.salesAmount) - (e?.paidAmount))  
      //       })

      //   },{
      //     name: "Total Amount",
      //     data: Object.values(this.props.purchaseData || {})?.map((e) => {

      //       return Math.round( (e?.salesAmount))  
      //     })

      // }],
      //     options: {
      //       chart: {
      //         height: 350,
      //         type: 'bar',
      //         events: {
      //           click: function(chart, w, e) {
      //           }
      //         },
      //         toolbar: {
      //           show: false
      //         },
      //       },
      //       colors: ['#1CB980','#ED5F56','#3150B9' ],
      //       plotOptions: {
      //         enabled:'false',
      //         bar: {
      //           columnWidth: '80%',
      //           // distributed: true,
      //         }
      //       },
      //       dataLabels: {
      //         enabled: false
      //       },
      //       legend: {
      //         show: false
      //       },
      //       xaxis: {
      //         categories: Object.keys(this.props.purchaseData || {}),
      //       },

      //     },


    };;
  }



  render() {

    const series = [{
      name: `Paid in ${(this.props.companyInfo || {}).currencyCode + " "}`,
      data: Object.values(this.props.purchaseData || {})?.map((e) => {

        return e?.paidAmount ? Math.round(e?.paidAmount) : 0;
      })
    }, {
      name: "Due",
      data: Object.values(this.props.purchaseData || {})?.map((e) => {

        return -Math.round((e?.salesAmount || 0) - (e?.paidAmount|| 0))
      })

    }, {
      name: "Total Amount",
      data: Object.values(this.props.purchaseData || {})?.map((e) => {

        return Math.round((e?.salesAmount || 0))
      })

    }];
    const options = {
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {
          }
        },
        toolbar: {
          show: false
        },
      },
      colors: ['#1CB980', '#ED5F56', '#3150B9'],
      plotOptions: {
        enabled: 'false',
        bar: {
          columnWidth: '80%',
          // distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: Object.keys(this.props.purchaseData || {}),
      },

    };
  
    return (
      <div id="chartPurchaseData">
        {(this.props.purchaseData && Object.keys(this.props.purchaseData)) ?
        <ReactApexChart options={options} series={series} type="bar" height={260} />
        :''}
      </div>


    );
  }
}
export default PurchaseChart;