import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, TRANSACTION_TYPES } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const rejectApproval = (payload) => {
  let url = '/salesOrder/rejectSO';
  let rootUrl = config.rootContext;
  switch(payload.txType){
    case TRANSACTION_TYPES.SALES_QUOTE: {
      rootUrl = config.rootContextSales;
      url = '/salesQuotation/rejectQuote';
      break;
    }
    case TRANSACTION_TYPES.SALES_ORDER: {
      url = '/salesOrder/rejectSO';
      rootUrl = config.rootContextSales;
      break;
    }
    case TRANSACTION_TYPES.SALES_INVOICE: {
      url = '/customerInvoice/rejectInvoice';
      rootUrl = config.rootContextSales;
      break;
    }
    case TRANSACTION_TYPES.PURCHASE_REQUEST: {
      url = '/purchaseRequestRest/rejectPurchaseRequest';
      break;
    }
    case TRANSACTION_TYPES.RFQ: {
      url = '/rfqRest/rejectRFQ';
      break;
    }
    case TRANSACTION_TYPES.PURCHASE_ORDER: {
      url = '/purchaseOrder/rejectPO';
      break;
    }
    case TRANSACTION_TYPES.PURCHASE_INVOICE: {
      url = '/supplierInvoice/rejectInvoice';
      rootUrl = config.rootContextPurchase;
      break;
    }
    default: {
      break;
    }
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${rootUrl}${url}`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
       
        showToasterMessage({ messageType: 'success', description: res.data || 'Rejected successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}