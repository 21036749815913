import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Drawer, Tabs } from "antd";
import Personal from "./Personal";
import Official from "./Official";
import Education from "./Education";
import Employment from "./Employment";
import Document from "./Document";
import { getCurrentDateForBackend, showToasterMessage } from "../../../../../utils";

const { TabPane } = Tabs;

const EmployeeDrawer = (props) => {
  const { intl } = props;
  const [empInfo, setEmpInfo] = useState({ ...props.empInfo, countryCallingCode: (props.companyInfo || {}).countryCallingCode, location: (props.empInfo?.locationList || [])[0] } || {});
  const closeDrawer = () => {
    props.updateState({
      employeeDrawer: false,
    });
    if (props.updateHeaderState) {
      props.updateHeaderState({
        company: {
          ...props.company,
          employeeDrawer: false,
        }
      })

    }
  };

  const validateTab1 = (props) => {
    const { firstName, lastName, emailAddress, cellNumber, gender, emergencyContactName, emergencyContactNumber } = empInfo;
    const { streetAddress1, cityName, countryName, stateName, zipCode } = empInfo?.location || {};

    // validate perasonal info
    if (!firstName || !lastName || !emailAddress || !cellNumber || !gender || !emergencyContactName || !emergencyContactNumber) {
      return true;
    }
    // validate location info
    if (!streetAddress1 || !cityName || !countryName || !stateName || !zipCode) {
      return true;
    }
    return false;
  };


  const validateTab2 = () => {
    const {
      userName, password, confirmPassword, dateOfJoining, departmentId, hierarchyId, jobRoleId, empTypeId, teamId, warehouseMasterId } = empInfo;

    if (!userName || !password || !confirmPassword || !dateOfJoining || !teamId ||
      !departmentId || !hierarchyId || !jobRoleId || !empTypeId || !warehouseMasterId) {
      return true;
    }

    return false;
  };

  const handleSubmit = (payload) => {
    if (!payload.submittedOnce) {
      payload.updateDrawerState({ submitted: true, submittedOnce: true });
    }
    empInfo.relationshipId = props.companyInfo?.relationshipId;
    empInfo.locationList = [{ ...empInfo.location, relationshipId: props.companyInfo?.relationshipId }];
    empInfo.dateOfJoining = getCurrentDateForBackend(empInfo.dateOfJoining);
    empInfo.dob = getCurrentDateForBackend(empInfo.dob);

    if (empInfo) {

      const modalData = {
        title: (
          <FormattedMessage
            id="drawer.inventory.confirmation.title"
            defaultMessage=""
          />
        ),
        modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />,
        handleSubmit: () => {

          props.createEmployee(empInfo, props);
          payload.hideModal();
          closeDrawer();

        },
      };
      payload.showModal(modalData);
    }
  }


  const validateTab = (props) => {

    let errorCode = "";

    switch (props.activeTabKey) {

      case "1":
        errorCode = validateTab1();
        if (errorCode) {
          setEmpInfo({ ...empInfo, submitted: true })
          return errorCode;
        }
        else {
          props.updateDrawerState({ activeTabKey: `${Number(props.activeTabKey || 1) + 1}` });
        }
        break;
      case "2":
        errorCode = validateTab2();
        if (errorCode) {
          setEmpInfo({ ...empInfo, submittedOnce: true })
          return errorCode;
        }
        // else
        // props.updateDrawerState({ activeTabKey: `${Number(props.activeTabKey || 1) + 1}` });

        break;
      default:
        break;
    }
  };
  return (
    <Drawer
      title="New Employee"
      width={720}
      zIndex={1}
      keyboard={false}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.employeeDrawer}
      footer={
        <>
          {props.activeTabKey === "1" ?
            <Button
              onClick={() => {
                const modalData = {
                  modalBody: <FormattedMessage id='close.confirmation' /> ,
                  footer: {
                    cancel: { intlId: "no" },
                    submit: { intlId: "yes" },
                  },
                  handleSubmit: () => {
                    closeDrawer();
                  },
                };
                props.pushModalToStack(modalData);
              }}
            >
              Cancel
            </Button>
            :
            <Button
              onClick={() => {
                props.updateDrawerState({ activeTabKey: `${Number(props.activeTabKey || 1) - 1}` });
              }}
            >
              Back
            </Button>
          }

          {props.activeTabKey === "2" ?
            <Button
              type="primary"
              onClick={() => {
                if (validateTab1() || validateTab2()) {
                  return setEmpInfo({ ...empInfo, submitted: true, submittedOnce: true })
                }
                handleSubmit(props);
              }}
            >
              <FormattedMessage
                id="button.save.label"
                defaultMessage=""
              />
            </Button>
            :
            <Button
              type="primary"
              onClick={() => {
                validateTab(props)
              }}
            >
              Next
            </Button>
          }
        </>
      }
    >

      <Tabs type="line"
        activeKey={props.activeTabKey || "1"}
        onChange={(key) => {
          props.updateDrawerState({ activeTabKey: key });
        }}
        tabBarGutter={16}
      >

        <TabPane
          tab={<FormattedMessage id='drawer.employeeMangement.tabNames.personal' />}
          key="1"
        >
          <Personal {...props} empInfo={empInfo} setEmpInfo={(data) => { setEmpInfo(data) }} />
        </TabPane>

        <TabPane
          tab={ 
          <FormattedMessage id='drawer.employeeMangement.tabNames.official' />
        }
          key="2"
        >
          <Official {...props} empInfo={empInfo} setEmpInfo={(data) => { setEmpInfo(data) }} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default EmployeeDrawer;
