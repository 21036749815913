import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmployeeAttendanceComp from "../../../components/company/EmployeeAttendance";
import {  formatDateForBackend } from "../../../utils";
import { getEmpAttendances } from "./action";
import { showModal, hideModal, pushModalToStack } from './../../../actions/commonActions';
import {saveAttendance} from '../../drawer/company/EmployeeAttendance/action';

class EmployeeAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAttendenceLoaded: false,
      pageNumber: 1,
      pageSize: 25,
      activeEmployeeIdList: [],

    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    let date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth();
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      // relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 1000,
      startDate: formatDateForBackend(new Date(y, m, 1)),
      endDate: formatDateForBackend(new Date(y, m + 1, 0)),
    };

    this.props.getEmpAttendances(payload);
  }

  componentWillReceiveProps(props) {
    if (props.classList && props.classList[1] && props.classList[1].length) {
      if (!this.state.isAttendenceLoaded) {

        // const companyInfo = this.props.companyInfo || {};
        // let date = new Date();
        // let y = date.getFullYear();
        // let m = date.getMonth();
        // const payload = {
        //   relationshipId: companyInfo.relationshipId,
        //   classId: props.classList[1][0].classId,
        //   startDate: formatDateForBackend(new Date(y, m, 1)),
        //   endDate: formatDateForBackend(new Date(y, m + 1, 0)),
        //   relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
        // };

        this.setState({
          isAttendenceLoaded: true,
          selectedClass: props.classList[1][0],
          className: props.classList[1][0].className,
          classId: props.classList[1][0].classId
        });
      }
    }
    if (props.activeStudentList && props.activeStudentList[1] && props.activeStudentList[1].length) {
      let activeStudentIdList = [];
      props.activeStudentList[1].forEach((e, i) => {
        if (e.schoolStudentId) {
          activeStudentIdList.push(e.schoolStudentId);
        }
      });
      this.setState({ activeStudentIdList });
    }
  }

  render() {
    return (
      <EmployeeAttendanceComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.common.userInfo,
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    empAttendanceList: state.company.empAttendanceList,
    permissions: state.auth.permissions,
    listLoading: state.common.listLoading

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getEmpAttendances,
      showModal,
      hideModal,
      pushModalToStack,
      saveAttendance,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAttendance);
