import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Popover, Menu, Pagination, Row, Col, Button, Switch } from 'antd';
import { InsertRowRightOutlined, MoreOutlined, UserOutlined } from '@ant-design/icons';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { getMomentDateForUIReadOnly, sortTable } from '../../../utils';
import find from 'lodash.find';
import { CONSTANTS, ICONS } from '../../../static/constants';
import { TextBox } from '../../general/TextBox';
import { Dropdown } from '../../general/Dropdown';
import { CustomButton } from '../../general/CustomButton';


const AttendanceIp = (props) => {

  const breadCrumbList = [
    {
      name: "Dashboard",
    },
    {
      name: <FormattedMessage id='sidebar.menuItems.employee' />,
    },
    {
      name: "Manage Ip Address",
    }
  ];
  const stickyStyle = {
    position: 'sticky',
    left: '0px',
    backgroundColor: '#ffffff',
    zIndex: 1
  }

  function validateIPAddress(ip) {
    // Regular expression for IPv4
    const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    // Regular expression for IPv6
    const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3,3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3,3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/;

    // Test the IP against both regex patterns
    if (ipv4Regex.test(ip)) {
        return true;
    } else if (ipv6Regex.test(ip)) {
        return true;
    } else {
        return false;
    }
}


  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {

          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>

        {e.isAccountHolder ? (
          <Menu.Item
            key="2"

          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        ) : (
          ""
        )}


      </Menu>
    );
  };

  const getDateList = (dateObj) => {
    const date = dateObj ? new Date(dateObj) : new Date();
    let dateList = [];
    let maxDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    for (let i = 1; i <= maxDate; i++) {
      dateList.push(i);
    }
    return dateList;
  };

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItems.attendanceIp"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div>

      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="company.attendanceManagement.attendanceIp.table.heading"
                defaultMessage=""
              />
            </div>
            {[]?.length ? (
              <>
                <div className="vertical-sep" />
                <div>{[]?.length}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">


          </div>
        </div>

        <div className='new-container'>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className='common-label'>Employee Self Attendance Status
              <span className="required">*</span>
              </div>
              <Switch
                checked={props.selfAttendance}
                onChange={(val) => {
                  props.updateState({
                    selfAttendance: val,
                  })
                }}
              />
            </Col>

            <Col span={12}>
              <div className='common-label'>Should Check White Listed IP Address
              <span className="required">*</span>
              </div>
              <Switch
                checked={props.whiteListedIpApplied}
                onChange={(val) => {
                  props.updateState({
                    whiteListedIpApplied: val,
                  })
                }}
              />
            </Col>

            <Col span={24}>
              <div className='common-label'>
                WhiteListed IP Addresses<span className="required">*</span>
              </div>
              {(props.ipAddessList || []).map((val, ind)=>{
                return <Row>
                  <Col span={11}>
                    <TextBox
                      disabled
                      value={val}
                    />
                </Col>
                <Col span={1}>
                  <i className={`${ICONS.CLOSE_P} ml10 mt10`} 
                    onClick={()=>{
                      let a = props.ipAddessList;
                      a.splice(ind, 1);
                      props.updateState({
                        ipAddessList: a
                      })
                    }}
                  />
                </Col>
              </Row>
              })}
            </Col>

            <Col span={11}>
              <TextBox
                placeholder="121.12.123.98"
                value={props.enteredIp}
                onChange={(e)=>{
                  props.updateState({
                    enteredIp: e.target.value
                  })
                }}
              />
              {props.invalidIpAddress ? <div className="required">
                Please enter valid IP address
              </div> : ""}
            </Col>

            <Col span={24}>
              <Button
                style={{ width: "min-content" }}
                onClick={()=>{
                  if(!validateIPAddress(props.enteredIp)){
                    props.updateState({
                      invalidIpAddress: true
                    })
                    return;
                  }
                  props.updateState({
                    ipAddessList : [...(props.ipAddessList || []), props.enteredIp],
                    enteredIp: undefined,
                    invalidIpAddress: false
                  })
                }}
              >
                Add More IP Addreess
              </Button>
            </Col>
            <Col span={4} offset={10}>
              <Button
                type='primary'
                onClick={() => {
                  }
                }
              >
                Update Settings
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};




export default injectIntl(AttendanceIp);
