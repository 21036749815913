import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, CONSTANTS, SUPPLIER_ACTION_LIST } from '../../../../static/constants'
import { LEDGER_TYPE } from '../../../../static/constants';
import { formatDateForBackend, getFinancialYear, showToasterMessage } from '../../../../utils';
import download from 'downloadjs';
import { getMomentDateForUI } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createStockQuantity = (payload, callback) => {


  const data = getStockQuantityData(payload);
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/openingQuantity/createOpeningQuantity`, data)
      .then((res) => {

        if(res.status ===  200){
          showToasterMessage({
            messageType: "success",
            description: "Save Successfully"
          })
          const val = {
            relationshipId: payload.companyInfo?.relationshipId,
            pageNumber:1,
            pageSize: 25,
          }
         
          if(callback){
            callback()
          }
          payload.fetchOpeningQuantityList(val);
          payload.fetchOpeningQuantityCount(val);
          
        }
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      }).finally(() => {
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  };

};





const getStockQuantityData = (props) => {
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const selectedProduct = props.selectedProduct || {};
  const selectedVariantProduct = props.selectedVariantProduct || {};
  const creditLedger = props.selectedCreditLedger || {};
  const debitLedger = props.selectedDebitorLedger || {};
  const financialYear = getFinancialYear(props.companyInfo);
  const openingQuantityDetail = props.openingQuantityDetail || {};

  

   const allVariantValues =(itemList)=>{
   
    const list = (itemList||[]).map((item)=>{
    
      
      return  {
        "openingQuantityDetailId": props.isUpdate ? openingQuantityDetail.openingQuantityDetailId : 0,
        "openingQuantityMasterId": props.isUpdate ? openingQuantityDetail.openingQuantityMasterId : 0,
        "version": props.isUpdate ? props.data?.version : 1,
        "itemId": item?.itemId,
        "uomName": item?.uomName,
        "supplierId": props.selectedSupplierName?.supplierId,
        "anItemPurchasePrice": props.purchasedPrice,
        "quantity": Number(props.productQuantity),
        "qtyBeforeUpdate": props.isUpdate ? props.qtyBeforeUpdate : 0,
        "relationshipId": companyInfo?.relationshipId,
        "sNo": 1,
        "inventoryItemVariantId": item?.variantId,
        "isVariant": item?.hasVariant,
        "attributeName1": item?.attributeName1,
        "attributeName2": item?.attributeName2,
        "attributeName3": item?.attributeName3,
        "attributeId1": item?.attributeId1,
        "attributeId2": item?.attributeId2,
        "attributeId3": item?.attributeId3,
        "sku": item?.sku,
       
        "attributeValue1": item?.attributeValue1,
        "attributeValue2": item?.attributeValue2,
        "attributeValue3": item?.attributeValue3,
  
        "description": item?.description,
        "itemName": item?.itemName,
        "itemVariantName": "Sample Item Variant",
        "inventoryItemOpeningQuantityCOATxList": [
          {
            "openingQuantityCoaTxId": props.isUpdate ? debitLedger?.openingQuantityCoaTxId||0 : 0,
            "version": props.isUpdate ? props.data.version : 1,
            "txType": LEDGER_TYPE.TYPE_PURCHASE,
            "relationshipId": companyInfo?.relationshipId,
            "openingQuantityDetailId": props.isUpdate ? openingQuantityDetail?.openingQuantityDetailId||0 : 0,
            "openingQuantityMasterId": props.isUpdate ? openingQuantityDetail?.openingQuantityMasterId : 0,
            "supplierId": props.selectedSupplierName?.supplierId,
            "ledgerAccountName": debitLedger?.ledgerAccountName,
            "createdBy": userInfo?.relationshipEmployeeId,
  
            "updatedBy": userInfo?.firstName + " " + userInfo?.lastName,
            // "amount": "1000.00",
            // "amountPercent": "10%",
            "fyStartDate": financialYear?.fyStartDate,
            "fyEndDate":  financialYear?.fyEndDate,
            "ledgerAccountId": debitLedger?.chartOfAccountDetailsId,
            "accountingEntry": "Debit",
            "isTaxable": debitLedger?.isLedgerTaxable
          },
          {
            "openingQuantityCoaTxId": props.isUpdate ? creditLedger?.openingQuantityCoaTxId||0: 0,
            "version": props.isUpdate ? props.data.version : 1,
            "txType": LEDGER_TYPE.TYPE_PURCHASE,
            "relationshipId": companyInfo?.relationshipId,
            "openingQuantityDetailId": props.isUpdate ? openingQuantityDetail?.openingQuantityDetailId ||0: 0,
            "openingQuantityMasterId": props.isUpdate ? openingQuantityDetail?.openingQuantityMasterId ||0 : 0,
            "supplierId": props.selectedSupplierName?.supplierId,
            "ledgerAccountName": creditLedger?.ledgerAccountName,
            "createdBy": userInfo?.relationshipEmployeeId,
            "fyStartDate": financialYear?.fyStartDate,
            "fyEndDate":  financialYear?.fyEndDate,
            "updatedBy": userInfo?.firstName + " " + userInfo?.lastName,
            // "amount": "1000.00",
            // "amountPercent": "10%",
            "ledgerAccountId": creditLedger?.chartOfAccountDetailsId,
            "accountingEntry": "Credit",
            "isTaxable":creditLedger?.isLedgerTaxable
          }
        ]
      }
    })

   
    return list;
   }
  const quantityList = props?.hasVariant ? allVariantValues([selectedVariantProduct]):
  allVariantValues([selectedProduct])
  return {
    openingQuantityMasterId: props.isUpdate ? props.data?.openingQuantityMasterId : 0,
    version: props.isUpdate ? props.data?.version : 1,



    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo && companyInfo.storeName,
    supplierId: props.selectedSupplierName?.supplierId,
    supplierName: props.selectedSupplierName?.supplierStoreName,

    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
     
    poDate: formatDateForBackend(props.purchaseOrderDate) ,
    "fyStartDate": financialYear?.fyStartDate,
    "fyEndDate":  financialYear?.fyEndDate,

    "openingQuantityDetailList": quantityList,
    "auditTrailOpeningQuantityList": []
  }
}