  import React, { Fragment, useState,useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Switch, Menu, Skeleton, Empty } from "antd";
import PageBreadcrumb from "../../PageBreadcrumb";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from '../../../static/constants';
import DropdownAction from "antd/lib/dropdown";
import "./index.scss";
import { getMomentDateForUIReadOnly, getPermissionData } from "../../../utils";
import PageHeader from "../../Common/pageHeader";

const CommunicationTemplateComp = (props) => {
  const primaryPerm = (props.permissions && props.permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.COMMUNICATION )
  },[])
  const breadCrumbList = [
    {
      name: <FormattedMessage id='breadcrum.communications' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='breadcrum.communications.communicationTemplate' defaultMessage='' />,
    }
  ];

  const sortColumn = (e) => {
    // sortTable(document.getElementById('newsletter-table'), e.target.cellIndex, 1 - dir);
    // updateState({ dir: 1 - dir })
  }
  const [showNextRow, setShowNextRow] = useState(true);
 

  
  const handleClick1 = (moduleName) => {
    setShowNextRow(moduleName)
   
  };

  

  const actionMenu = (data) => {
   
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          {permissionsData.update && <Menu.Item key="1" value="edit" 
          onClick={()=>{
            props.updateDrawerState({
              communicationTemplateDrawerVisiable: true,
              data
              
          })
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>}

          {permissionsData.delete && <Menu.Item key="2" 
          onClick={()=>{

            const modalData = {
              title: (
                <FormattedMessage
                  id="drawer.inventory.confirmation.title"
                  defaultMessage=""
                />
              ),
              modalBody: <Fragment>
              <p>
                <FormattedMessage
                  id="commons.delete.popup.confimation.msg"
                  defaultMessage=""
                />
              </p>
            </Fragment>,
              handleSubmit: () => {
                props.deleteCommunicationTemplate({relationshipId: props.companyInfo?.relationshipId , communicationsTemplateSettingId: data.communicationsTemplateSettingId} , props);
                props.hideModal();
              },
            };
            props.showModal(modalData);
           
          }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>}

        </Menu >
    )
  };

  const groupByModuleName = (data) => {
    return data.reduce((result, item) => {
      // Use moduleName as the key
      const key = item.moduleName;
  
      // Initialize the array if it doesn't exist
      if (!result[key]) {
        result[key] = [];
      }
  
      // Push the item to the corresponding moduleName array
      result[key].push(item);
  
      return result;
    }, {});
  };
  

  const groupedData = groupByModuleName(props.communicationTemplate||[]); 
  return (
    <Fragment >      
      <PageHeader {...props}
        pageName="heading.communications.communicationTemplate"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={()=>{
          props.updateDrawerState({
            communicationTemplateDrawerVisiable: true,
            data:{},
            
        })
      }}
        moduleCode={MODULE_CODED_VALUES.COMMUNICATION}
      />
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='heading.table.communicationTemplate' defaultMessage='' />
            </div>
          </div>
          <div className="right-actions">
          </div>
        </div>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 7 }} active>
          <div className="table-container">
            <table id='communicationTemplate-table' className="communicationTemplateTable">
              <thead>
                <tr>
                  <th onClick={sortColumn}><FormattedMessage id="communicationTemplate.table-heading-name.module" defaultMessage="" /></th>
                  <th onClick={sortColumn}><FormattedMessage id='communicationTemplate.table-heading-name.transaction' defaultMessage='' /></th>
                  <th onClick={sortColumn}><FormattedMessage id='communicationTemplate.table-heading-name.event-type' defaultMessage='' /></th>
                  <th ><FormattedMessage id='communicationTemplate.table-heading-name.communication' defaultMessage='' /></th>
                  <th onClick={sortColumn}><FormattedMessage id='communicationTemplate.table-heading-name.date' defaultMessage='' /></th>
                  {/* <th className="text-center"><FormattedMessage id='communicationTemplate.table-heading-name.status' defaultMessage="" /></th> */}
                {permissionsData.update || permissionsData.delete ? <th className="text-center"><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>: "" }
                </tr>
              </thead>
              <tbody>
              {Object.keys(groupedData)?.length ? Object.keys(groupedData).map((moduleName ,i) => (
                  <Fragment key={moduleName}>
                    <tr>

                      <td>
                        <div className="flexItem action-delete" onClick={()=>handleClick1(moduleName)}> 
                        <i className={(showNextRow === moduleName) ? ICONS.MINUS_CIRCLE : ICONS.PLUS_CIRCLE}></i> {moduleName}
                        </div>
                      </td>
                      <td></td>
                    <td></td>
                    <td>

                    </td>
                    <td></td>
                    <td></td>
                    
                    </tr>
                    {(showNextRow === moduleName) && (
                      <>
                        {
                          groupedData[moduleName]?.length ? groupedData[moduleName].map((item,i) => (
                            <tr>
                            <td>
                                <div className={`${(showNextRow === moduleName) ? 'mrL30' : 'mrL0'}`}>{item?.moduleName}</div>
                              </td>
                            <td>{item?.transactionName}</td>
                            <td>{item?.eventName}</td>
                            <td className="text-center">
                              <div className="iconArea">
                                <span className={`${item?.isEmailAutoTrigger ? 'activeMail':''}`}><i className={ICONS.MAIL}></i></span>
                                <span className={`${item?.isSMSAutoTrigger ? 'activeSMS':''}`}><i className={ICONS.SMS_ICON}></i></span>
                                <span className={`${item?.isWhatsAppAutoTrigger ? 'activeWhatsapp':''}`}><i className={ICONS.WHATSAPP_ICON}></i></span>
                              </div>
                            </td>
                            <td>{item.dateCreated
                                  ? getMomentDateForUIReadOnly({
                                    date: item.dateCreated,
                                    format: CONSTANTS.TABLE_DATE_FORMAT,
                                  })
                                  : "-"}</td>
                            {/* <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" onChange={onChange} /></td>
                            <td className="text-center"><span className="status-code ready">Active</span></td> */}
                            {/* <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" checked onChange={onChange} /></td> */}
                            {permissionsData.update || permissionsData.delete ? <td className="text-center">
                            <DropdownAction overlay={actionMenu(item)} trigger={['click']}>
                            <i className={ICONS.MORE_P} />
                          </DropdownAction>
                            </td>: "" }
                          </tr>
                          ))
                          :
                          <tr key="empty-data-box">
                            <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                              <Empty />
                            </td>
                          </tr>
                        }
                      </>
                    )}
                  </Fragment>
                ))
                :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
              }
              
              </tbody>
            </table>
          </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(CommunicationTemplateComp);
