import axios from "axios";
import config from "../../../../../config/environmentConfig"
import { showToasterMessage } from "../../../../../utils";

const { lang } = require("../../../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const savePromoHubOffers = (payload, props) => {
    return (dispatch) => {
    
      return axios
        .post(
          `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_setting/saveWelcomeTray`,payload)
        .then((res) => {
            if(res.status === 200){
                showToasterMessage({
                    description: (res || {}).message || 'Created successfully',
                    messageType: 'success'
                  })
            }
            else{
                showToasterMessage({
                    description: (res || {}).message || 'Some Error Occurred',
                    messageType: 'error'
                  })
            }
         
        })
        .catch((err) => {         
          showToasterMessage({
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        });
    };
  };