import axios from 'axios';
import config from '../../../../../../config/environmentConfig';
import { FINANCE_ACTION_LIST, COMMON_ACTIONS } from '../../../../../../static/constants';
import { showToasterMessage, getFinancialYear, formatDateForBackend, getCurrentDateForBackend } from '../../../../../../utils';
const { lang } = require('../../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchMatchedTransactions = (payload, history) => {
  //let FY = getFinancialYear(payload.companyInfo);
  let uri = 'getMatchingDeposits';
  if(Number(payload.Withdrawal) < 0){
    uri = 'getMatchingWithdrawals';
  }
   
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: FINANCE_ACTION_LIST.RESET_MATCHED_TX_LIST });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankReco/${uri}`, payload)
      .then(res => {
        dispatch({ type: FINANCE_ACTION_LIST.MATCHED_TX_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          // err.actionToCall = SALES_INVOICE_ACTION_LIST.SALES_INVOICE_LIST;
          // err.data = {
          //   pageNo: payload.pageNumber,
          //   list: []
          // };
        }
      })
  }
}

export const createMatchedPayment = (payloadData, props) => {
  const payload = _getPaymentData(payloadData, props);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPayment/createCustomerPayment`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.hideModal();
        (props.activeAccount && props.activeAccount.institutionId) && props.getBankTransactions({ institutionId: (props.activeAccount || {}).institutionId, relationshipId: (props.companyInfo || {}).relationshipId });
        showToasterMessage({ messageType: 'success',  description: lang['common.saved.success'] });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
  }
}

const _getPaymentData = (payloadData, props) => {
  let payload = {};
  let customerName = payloadData.customerName;
  let createdByUserId = props.userInfo.userId || 0;
  let relationshipId = props.companyInfo.relationshipId || 0;
  let finYear = getFinancialYear(props.companyInfo);

  payload.paymentDate = formatDateForBackend(new Date() );
  //payload.reference = formData.reference;
  payload.currencyCode = payloadData.currencyCode || props.companyInfo.currencyCode;
  payload.createdByEmpId = props.userInfo && props.userInfo.relationshipEmployeeId;
  payload.createdByUserId = createdByUserId;

  let txList = {
    fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    relationshipId: relationshipId,
    dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    txType: 'Payment',
    //instrumentNumber: formData.paymentModeDetail,
    //instrumentDate: formatDateForBackend(formData.paymentDate),
    txDate: formatDateForBackend(new Date() ),
    accountingEntry: 'CREDIT',
    //paymentModeId: formData.paymentModeId,
    invoiceMasterId: payloadData.invoiceMasterId,
    remarks: payloadData.remarks,
    //availableCredit: formData.availableCredit,
    amount: Math.abs(payloadData.invoiceTotalAmount || 0),
    //paymentModeDetail: formData.paymentModeDetail,
    customerId: payloadData.customerId,
    customerName: customerName,
    //bankDate: formatDateForBackend(new Date() ),
    //paymentMode: payloadData.paymentModeName,
  };

  var txList1 = {
    yStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    relationshipId: relationshipId,
    dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    txType: 'Payment',
    //instrumentNumber: formData.paymentModeDetail,
    //instrumentDate: formatDateForBackend(formData.paymentDate),
    txDate: formatDateForBackend(new Date() ),
    accountingEntry: 'DEBIT',
    //paymentModeId: formData.paymentModeId,
    invoiceMasterId: payloadData.invoiceMasterId,
    remarks: payloadData.remarks,
    //availableCredit: formData.availableCredit,
    amount: Math.abs(payloadData.invoiceTotalAmount || 0),
    //paymentModeDetail: formData.paymentModeDetail,
    customerId: payloadData.customerId,
    customerName: customerName,
    //bankDate: formatDateForBackend(new Date() ),
    //paymentMode: payloadData.paymentModeName,
  };

  var customerTxPayment = {
    relationshipId: relationshipId,
    txAmount: Math.abs(payloadData.invoiceTotalAmount || 0),
    paymentModeDetail: payloadData.paymentModeDetail,
    fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    status: 'Processed',
    statusColor: ' success',
    txDate: formatDateForBackend(new Date() ),
    txType: 'Payment',
    invoiceNumber: payloadData.invoiceNumber,
    invoiceMasterId: payloadData.invoiceMasterId,
    isMultiCurrency: payloadData.isMultiCurrency,
    exchangeRate: payloadData.exchangeRate,
    remarks: payloadData.remarks,
    invoiceDueDate: payloadData.invoiceDueDate,
    //paymentModeId: formData.paymentModeId,
    dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    customerId: payloadData.customerId,
    soMasterId: payloadData.salesOrderMasterId,
  }
  payload.customerId = payloadData.customerId;
  payload.fyStartDate = finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate);
  payload.fyEndDate = finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate);
  payload.remarks = payloadData.remarks;
  payload.soNumber = payloadData.soNumber;
  //txList.ledgerAccountId = $scope.debtorsLedgerAccount && $scope.debtorsLedgerAccount.chartOfAccountDetailsId;
  //txList1.ledgerAccountId = formData.bankledgerId || ($scope.cashInHandLedgerAccount && $scope.cashInHandLedgerAccount.chartOfAccountDetailsId);

  payload.coaTxPaymentsList = [txList, txList1];
  payload.txDate = formatDateForBackend(new Date() );
  payload.customerTxPaymentList = [customerTxPayment];

  payload.invoiceAmount = payloadData.invoiceTotalAmount;
  payload.relationshipId = relationshipId;
  payload.chartOfAccountDetailsId = payloadData.chartOfAccountDetailsId;
  //payload.currentBalanceDue = formData.dueAmount - (formData.amount || 0);
  payload.txType = 'Payment';
  //payload.paymentModeId = formData.paymentModeId;
  //payload.paymentModeName = formData.paymentModeName;
  payload.remarks = payloadData.remarks;
  payload.invoiceMasterId = payloadData.invoiceMasterId;
  payload.status = "Processed";
  payload.statusColor = " success";
  payload.invoiceNumber = payloadData.invoiceNumber;
  payload.paymentAmount = Math.abs(payloadData.invoiceTotalAmount || 0);
  //payload.paymentModeDetail = formData.paymentModeDetail;
  payload.isMultiCurrency = payloadData.isMultiCurrency;
  payload.currencyCode = payloadData.isMultiCurrency ? payloadData.foreignCurrency : props.companyInfo.currencyCode;
  payload.exchangeRate = payloadData.exchangeRate;
  payload.templateCode = payloadData.templateCode;
  payload.relationshipName = props.companyInfo.storeName;
  payload.projectNumber = payloadData.projectNumber;
  payload.projectName = payloadData.projectName;
  payload.projectMasterId = payloadData.projectMasterId;
  payload.customerName = customerName;
  payload.rfqNumber = payloadData.rfqNumber;
  payload.rfqMasterId = payloadData.rfqMasterId;
  payload.salesQuotationMasterId = payloadData.salesQuotationMasterId;
  payload.quotationNumber = payloadData.quotationNumber;
  payload.transactionId = props.transactionData.transactionId;
  payload.institutionName = props.transactionData.institutionName;
  payload.paymentChannel = props.transactionData.paymentChannel;
  payload.merchantName = props.transactionData.merchantName;

  return payload;
}

export const createMatchedPaymentForSupplier = (payloadData, props) => {
  const payload = _getPaymentDataForSupplier(payloadData, props);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierPayment/createSupplierPayment`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.hideModal();
        props.getBankTransactions({ institutionId: (props.activeAccount || {}).institutionId, relationshipId: (props.companyInfo || {}).relationshipId });
        showToasterMessage({ messageType: 'success',  description: lang['common.saved.success'] });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
  }
}

const _getPaymentDataForSupplier = (payloadData, props) => {
  let payload = {};
  let supplierName = payloadData.supplierName;
  let createdByUserId = props.userInfo.userId || 0;
  let relationshipId = props.companyInfo.relationshipId || 0;
  let finYear = getFinancialYear(props.companyInfo);

  payload.paymentDate = formatDateForBackend(new Date() );
  payload.currencyCode = payloadData.currencyCode || props.companyInfo.currencyCode;
  payload.createdByEmpId = props.userInfo && props.userInfo.relationshipEmployeeId;
  payload.createdByUserId = createdByUserId;

  let txList = {
    fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    relationshipId: relationshipId,
    dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    txType: 'Payment',
    txDate: formatDateForBackend(new Date() ),
    accountingEntry: 'CREDIT',
    invoiceMasterId: payloadData.invoiceMasterId,
    remarks: payloadData.remarks,
    amount: Math.abs(payloadData.invoiceTotalAmount || 0),
    supplierId: payloadData.supplierId,
    supplierName: supplierName,
    expenseMasterId: payloadData.expenseMasterId,
  };

  var txList1 = {
    yStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    relationshipId: relationshipId,
    dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    txType: 'Payment',
    txDate: formatDateForBackend(new Date() ),
    accountingEntry: 'DEBIT',
    invoiceMasterId: payloadData.invoiceMasterId,
    remarks: payloadData.remarks,
    amount: Math.abs(payloadData.invoiceTotalAmount || 0),
    supplierId: payloadData.supplierId,
    supplierName: supplierName,
    expenseMasterId: payloadData.expenseMasterId,
  };

  var customerTxPayment = {
    relationshipId: relationshipId,
    txAmount: Math.abs(payloadData.invoiceTotalAmount || 0),
    paymentModeDetail: payloadData.paymentModeDetail,
    fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    status: 'Processed',
    statusColor: ' success',
    txDate: formatDateForBackend(new Date() ),
    txType: 'Payment',
    txNumber: payloadData.invoiceNumber,
    invoiceMasterId: payloadData.invoiceMasterId,
    isMultiCurrency: payloadData.isMultiCurrency,
    exchangeRate: payloadData.exchangeRate,
    remarks: payloadData.remarks,
    invoiceDueDate: payloadData.invoiceDueDate,
    dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    supplierId: payloadData.supplierId,
    supplierName: supplierName,
    poMasterId: payloadData.poMasterId,
    expenseMasterId: payloadData.expenseMasterId,
  }
  payload.supplierName = payloadData.supplierName;
  payload.supplierId = payloadData.supplierId;
  payload.expenseMasterId= payloadData.expenseMasterId;
  payload.fyStartDate = finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate);
  payload.fyEndDate = finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate);
  payload.remarks = payloadData.remarks;
  payload.poNumber = payloadData.poNumber;
  payload.coaTxPaymentList = [txList, txList1];
  payload.txDate = formatDateForBackend(new Date() );
  payload.customerTxPaymentList = [customerTxPayment];

  payload.invoiceAmount = payloadData.invoiceTotalAmount;
  payload.relationshipId = relationshipId;
  payload.chartOfAccountDetailsId = payloadData.chartOfAccountDetailsId;
  payload.txType = 'Payment';
  payload.remarks = payloadData.remarks;
  payload.invoiceMasterId = payloadData.invoiceMasterId;
  payload.status = "Processed";
  payload.statusColor = " success";
  payload.txNumber = payloadData.invoiceNumber;
  payload.paymentAmount = Math.abs(payloadData.invoiceTotalAmount || 0);
  payload.isMultiCurrency = payloadData.isMultiCurrency;
  payload.currencyCode = payloadData.isMultiCurrency ? payloadData.foreignCurrency : props.companyInfo.currencyCode;
  payload.exchangeRate = payloadData.exchangeRate;
  payload.templateCode = payloadData.templateCode;
  payload.relationshipName = props.companyInfo.storeName;
  payload.projectNumber = payloadData.projectNumber;
  payload.projectName = payloadData.projectName;
  payload.projectMasterId = payloadData.projectMasterId;
  payload.rfqNumber = payloadData.rfqNumber;
  payload.rfqMasterId = payloadData.rfqMasterId;
  payload.salesQuotationMasterId = payloadData.salesQuotationMasterId;
  payload.quotationNumber = payloadData.quotationNumber;
  payload.transactionId = props.transactionData.transactionId;
  payload.institutionName = props.transactionData.institutionName;
  payload.paymentChannel = props.transactionData.paymentChannel;
  payload.merchantName = props.transactionData.merchantName;

  return payload;
}