import React from "react";
import { Button, Checkbox, Col, Row, Skeleton } from 'antd';
import './style.scss';
import { getMomentDateForUIReadOnly } from "../../../../../utils";

const AWBAssignInfo = (props) => {

    const { shipmentLogisticsInfo={}, shipmentPickupDetail={}, currentStep=0} = props;

    const pickupInfo = shipmentPickupDetail?.pickup_status === 1 ? shipmentPickupDetail?.response : {};
    
    return (<>
        <Skeleton loading={props.saveLoading} paragraph={{ rows: 5 }} active>
            {shipmentPickupDetail?.pickup_status === 1 ? 
                <Row gutter={[16, 16]}>
                    <Col span={8} offset={4} className="shipping_info_label">
                        Shiprocket Order Id
                    </Col>
                    <Col span={12}>
                        {shipmentLogisticsInfo?.plateformOrderId || "-"}
                    </Col>
                    <Col span={8} offset={4} className="shipping_info_label">
                        AWB Code
                    </Col>
                    <Col span={12}>
                        {shipmentLogisticsInfo?.awbCode || "-"}
                    </Col>

                    <Col span={8} offset={4} className="shipping_info_label">
                        Pickup Scheduled Date
                    </Col>
                    <Col span={12}>
                        {pickupInfo?.pickup_scheduled_date ? getMomentDateForUIReadOnly({date: pickupInfo?.pickup_scheduled_date}) : '-' }
                    </Col>

                    <Col span={8} offset={4} className="shipping_info_label">
                        Pickup Token Number
                    </Col>
                    <Col span={12}>
                        {pickupInfo?.pickup_token_number || '-'}
                    </Col>

                    <Col span={8} offset={4} className="shipping_info_label">
                        AWB Status
                    </Col>
                    <Col span={12}>
                        {shipmentLogisticsInfo?.shipmentStatus || '-'}
                    </Col>
                </Row>
                :
                <div className="success_text">Pickup already scheduled {shipmentLogisticsInfo?.carrierName ? `with ${shipmentLogisticsInfo.carrierName}-${shipmentLogisticsInfo.carrierService}, you can proceed to schedule pickup` : ''}</div>
            }

            {Object.keys(shipmentPickupDetail || {}) ? 
                <>
                    
                    <Checkbox.Group
                        style={{
                            width: '100%',
                            marginTop: '20px'
                        }}
                        onChange={(checkedValues)=>{
                            props.updateState({checkedValues});
                        }}
                    >
                        <Row>
                            <Col span={24} className="shipping_info_label"> Select to generate</Col>
                            <Col span={6} offset={6}>
                                <Checkbox value="Label">Generate Label</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox value="Manifest">Generate Manifest</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </>
                :
                ""
            }
        </Skeleton>
        <div className="fot-fix" style={{ maxWidth: '100%' }}>
            <div className="text-center">
                <Button 
                    type="primary"
                    disabled={!props.checkedValues?.length > 0}
                    loading={props.saveLoading}
                    onClick={()=>{
                        props.updateState({currentStep: props.currentStep+1});
                        const payload = {
                            relationshipId: shipmentLogisticsInfo.relationshipId,
                            logisticsInfoId: shipmentLogisticsInfo.logisticsInfoId
                        }
                        if(props.checkedValues?.length && props.checkedValues.includes("Manifest")) {
                            props.generateManifest(payload, props);
                        }
                        if(props.checkedValues?.length && props.checkedValues.includes("Label")){
                            props.generateShippingLabel(payload, props);
                        }
                        props.updateState({currentStep: currentStep + 1});
                    }}
                > 
                  Generate {(props.checkedValues || []).join(" & ")}  
                </Button>
            </div>
        </div>
    </>
    );
}

export default AWBAssignInfo;