import React from 'react';
// import { AgGridReact } from 'ag-grid-react';
import { Pagination, Skeleton, Button, Empty } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import SODetail from '../../../../../containers/modal/modalBody/customer/SODetail';
import { CONSTANTS, ICONS } from '../../../../../static/constants';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, capitalizeFirstLetter, fixedDecimalAndFormateNumber, sortTable } from '../../../../../utils';
import { FilePdfOutlined, PrinterOutlined, } from '@ant-design/icons';


const OpenOrdersComponent = (props) => {
  const { openOrderList, openOrderListCount, companyInfo, updateState, customerData, pageNumber } = props;
  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  // const getRowHeight = () => {
  //     return 28;
  // }

  // const onFirstDataRendered = (params) => {
  //     params.api.sizeColumnsToFit();
  // }

  const loadPageData = (pageNumber, pageSize) => {
    const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
    // if (customerData && customerData.customerId) {
    //     payload.customerId = customerData.customerId;
    // }
    fetchPaginationDataIfNeeded('fetchOpenOrders', 'openOrderList', props, payload);
  }

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
    // if (customerData && customerData.customerId) {
    //     payload.customerId = customerData.customerId;
    // }
    // props.resetPaginatedData();
    props.fetchOpenOrders(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  }

  const openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId
    }
    props.pushModalToStack({
      modalBody: <SODetail {...props} soPayload={soPayload} />,
      width: '100%',
      hideFooter: true,
      wrapClassName: 'modal-custom-detail'
    })
  }

  const showTotal = (total) => {
    return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
  };
  const getAddressInfoFragment = (obj) => {
    return (
      <>
        {obj?.streetAddress1 ? (
          <span className="billing-address">{obj?.streetAddress1}</span>
        ) : (
          ""
        )}
        {obj?.streetAddress2 ? (
          <span className="billing-address"> {obj?.streetAddress2}</span>
        ) : (
          ""
        )}
        {obj?.cityName ? (
          <div className="billing-address">
            {`${obj?.cityName || ""}${obj?.stateName ? ", " + obj?.stateName : ""
              }${obj?.zipCode ? ", " + obj?.zipCode : ""}`}
          </div>
        ) : (
          ""
        )}
        {obj?.countryName ? (
          <div
            className="billing-address"
            style={{ textTransform: "uppercase" }}
          >
            {`${capitalizeFirstLetter(obj?.countryName) || ""}`}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const getContactInfoFragment = (obj) => {
    return (
      <>
        {/* <div >{obj?.firstName || "" + " " + obj?.lastName || ""}</div>
           <div >{obj?.emailAddress || ""}</div>
           <div >{obj?.cellPhone || ""}</div> */}
        {obj?.firstName ? (
          <div >{obj.firstName + " " + obj.lastName}</div>
        ) : (
          ""
        )}
        {obj?.emailAddress ? (
          <div > {obj.emailAddress}</div>
        ) : (
          ""
        )}
        {obj?.cellPhone ? (
          <div > {obj.cellPhone}</div>
        ) : (
          ""
        )}

      </>
    );
  };
  // const columnDefs = [
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.listing.openOrder.header.soNumber' defaultMessage='' />;
  //         },
  //         field: "soNumber",
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openSoDetails(link.data)
  //         }}>{link.value}</div>,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.listing.openOrder.header.soDate' defaultMessage='' />;
  //         },
  //         field: "salesOrderDate",
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
  //                 {(link.value ? getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}) : '-')}
  //                 </Tooltip>
  //             </div>
  //         },
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='customer.listing.openOrder.header.soAmount' defaultMessage='' /> </div>;
  //         },
  //         field: "salesOrderTotalAmount",
  //         cellRendererFramework: (params) => {
  //             return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span>;
  //         },
  //         valueFormatter: (data) =>{
  //             data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): '-';
  //             return data.value;
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.status' defaultMessage='' />;
  //         },
  //         field: "status",
  //         cellRendererFramework: (params) =>{
  //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /></span> </div>
  //         },
  //         resizable: true,
  //         cellClass: 'status-cell'
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.listing.openOrder.header.priority' defaultMessage='' />;
  //         },
  //         field: "orderPriority",
  //         cellRendererFramework: (params) =>{
  //             return <div> {params.value || '-'} </div>
  //         },
  //         resizable: true,
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='customer.listing.openOrder.header.soPerson' defaultMessage='' />;
  //     //     },
  //     //     field: "soPersonFullName",
  //     //     resizable: true,
  //     // }
  // ];
  
  const handleSortColumn = (index) => {
        
    const currentSortDirection = props.sortStates[index] || "none";
    const nextSortDirection =
      currentSortDirection === "none" ? "asc" :
      currentSortDirection === "asc" ? "desc" : "none";
  
    // Update sorting state using props.updateState
    props.updateState({
      sortStates: {
        // ...props.sortStates,
        [index]: nextSortDirection,
      },
    });
  
    // Call your sorting function
    const direction = nextSortDirection === "desc" ? 1 : 0;
    sortTable(document.getElementById("openOrder-table"), index, direction);
  };
  const getSortIcon = (index) => {
      const sortState = props.sortStates[index];
      if (sortState === "asc") return <i className={ICONS.SORT_UP} />;
      if (sortState === "desc") return <i className={ICONS.SORT_DOWN} />;
      return (
        <i
          className={`${ICONS.SORT} ${
            props.hoveredColumn === index ? "opopacity1" : "opopacity0"
          } `}
        />
      );
  };
  return (
    // <div className='agGridWrapper'>
    //     <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
    //         <AgGridReact
    //             columnDefs={columnDefs}
    //             rowData={openOrderList[pageNumber] || []}
    //             rowDragManaged={true}
    //             domLayout={"autoHeight"}
    //             animateRows={true}
    //             onColumnResized ={(params)=>{
    //                 params.api.resetRowHeights();
    //             }}
    //             defaultColDef={{
    //                 flex:1,
    //                 autoHeight: true,
    //                 wrapText: true,
    //             }}
    //             //getRowHeight={getRowHeight}
    //             onGridSizeChanged={onFirstDataRendered}
    //         >
    //         </AgGridReact>
    //     </div>
    // </div>
    <>




      <Skeleton loading={props.modalLoading} active paragraph={{ rows: 9 }}>
        <div className="table-container">

          <table id='openOrder-table'>
            <thead>
              <tr>
                <th>
                  <div
                    onClick={() => handleSortColumn(1)}
                    onMouseEnter={() => props.updateState({ hoveredColumn: 1 })}
                    onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                    className="sortTable-Container justify-start">
                      <FormattedMessage id='customer.listing.totalOrder.header.soNumber' defaultMessage='' /> {getSortIcon(1)}
                  </div>
                </th>
                <th>
                  <div
                    onClick={() => handleSortColumn(2)}
                    onMouseEnter={() => props.updateState({ hoveredColumn: 2 })}
                    onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                    className="sortTable-Container justify-start">
                    <FormattedMessage id="customer.listing.totalOrder.header.soDate" defaultMessage="" /> {getSortIcon(2)}
                  </div>
                </th>
                <th>
                  <div
                    onClick={() => handleSortColumn(3)}
                    onMouseEnter={() => props.updateState({ hoveredColumn: 3 })}
                    onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                    className="sortTable-Container justify-end">
                    <FormattedMessage id="customer.listing.totalOrder.header.soAmount" defaultMessage="" /> {getSortIcon(3)}
                  </div>
                  
                </th>
                <th>
                  <div
                    onClick={() => handleSortColumn(4)}
                    onMouseEnter={() => props.updateState({ hoveredColumn: 4 })}
                    onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                    className="sortTable-Container justify-center">
                    <FormattedMessage id='common.status' defaultMessage='' /> {getSortIcon(4)}
                  </div>
                </th>
                <th width='6%' className='text-center'>
                  <div
                    onClick={() => handleSortColumn(5)}
                    onMouseEnter={() => props.updateState({ hoveredColumn: 5 })}
                    onMouseLeave={() => props.updateState({ hoveredColumn: null })}
                    className="sortTable-Container justify-center">
                    <FormattedMessage id='customer.listing.totalOrder.header.priority' defaultMessage='' /> {getSortIcon(5)}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {(openOrderList[pageNumber] || []).length > 0 ? (openOrderList[pageNumber] || []).map((rowData, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className="cursor-pointer" onClick={() => { openSoDetails(rowData) }}>{rowData.soNumber}</div>
                    </td>
                    <td>
                      <div>
                        {(rowData.salesOrderDate && getMomentDateForUIReadOnly({ date: rowData.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                      </div>
                    </td>
                    <td>
                      <div className='amount-TextRight'>
                        {(
                                    props.companyInfo.currencyIcon ? (
                                      <i
                                        className={
                                          props.companyInfo.currencyIcon
                                        }
                                      ></i>
                                    ) : (
                                      props.companyInfo.currencyCode + " "
                                    )
                                  ) }
                        {fixedDecimalAndFormateNumber(rowData.salesOrderTotalAmount||0)}
                      </div>
                    </td>
                    <td className='text-center'>
                      {rowData.status ? 
                        <div className={`status-code ${rowData.statusColor}`}>
                          <FormattedMessage id={rowData.status || ''} defaultMessage="" />
                        </div>
                      : 
                      ''}
                    </td>
                    <td className='text-center'>
                      {rowData.orderPriority? rowData.orderPriority : <div className="empty-data"></div>}
                    </td>
                  </tr>
                );
              }) :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>

              }


            </tbody>
          </table>

        </div>
      </Skeleton>
        <Pagination
          size="small"
          total={openOrderListCount}
          showTotal={showTotal}
          defaultPageSize={props.pageSize}
          showSizeChanger
          pageSizeOptions={[10, 25, 50, 100, 200]}
          // showQuickJumper
          onChange={(pageNumber, pageSize) => {
            loadPageData(pageNumber, pageSize);
            updateState({ pageSize: pageSize, pageNumber: pageNumber })
          }}
          onShowSizeChange={(pageNumber, pageSize) => {
            handlePageSizeChange(pageNumber || 1, pageSize, props)
          }}
        />
    </>
  );
};

export default injectIntl(OpenOrdersComponent);
