import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Skeleton, Menu, Empty } from 'antd';
import { ICONS } from '../../../static/constants';
import DropdownAction from "antd/lib/dropdown";
import moment from 'moment-timezone';


const ProjectListingComp = (props) => {

    const {projectWithSubProject = []} = props;

    const deleteHandle = (project) => {
        const modalBody = <FormattedMessage id='deleteItem.confirmation' defaultMessage='' values={{ value: project.name }} />;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.deleteProject({
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    id: project.projectMasterId,
                    version:project.version || 0
                }, props)
            },
        };
        props.showModal(modalData);
    }

    const projectAddOrUpdate = (props, projectData,parentItem={}) => {
        props.updateState({
            detailProjectDrawerVisible: true,
            projectData,
            parentItem
        })
    }

    const actionMenu = (e,parentItem) => {
        return (
            <Menu className="row-action-dropdown">

                <Menu.Item key="0" value="title">
                    Actions
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item key="1" value="edit" onClick={() => {
                    projectAddOrUpdate(props, e,parentItem);
                }}
                >
                    <i className={ICONS.EDIT} />
                    Edit
                </Menu.Item>

                <Menu.Item key="2" onClick={() => {
                    deleteHandle(e);
                }
                }>
                    <i className={ICONS.DELETE} />
                    Delete
                </Menu.Item>
            

            </Menu>
        )
    };

    const [childOnList, setChildOnList] = useState([]);

    const isChildOn = (id) => childOnList.includes(id);

    const toggleChild = (id) => {
        setChildOnList((prevList) =>
            prevList.includes(id) ? prevList.filter((itemId) => itemId !== id) : [...prevList, id]
        );
    };

    const getSubcategories = (subcategories = [], parentIndex = '0',item) => {
        return subcategories.map((subcategory, i) => {
            const index = `${parentIndex}.${i + 1}`;
            const isSubcategoryOpen = isChildOn(subcategory.projectMasterId);

            const nestingLevel = parentIndex.toString().split('.').length;
            const marginLeft = 30 + (nestingLevel) * 10;

            return (
                <React.Fragment key={index}>
                    <tr>
                        <td>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: `${marginLeft}px` }}>
                                {subcategory?.subProjectList && subcategory.subProjectList.length > 0 && (
                                    <span
                                        onClick={() => toggleChild(subcategory.projectMasterId)}
                                        style={{ marginRight: '10px', marginLeft: '-10px' }}
                                    >
                                        {isSubcategoryOpen ? <i className="fi fi-br-minus"/> : <i className="fi fi-br-plus"/>}
                                    </span>
                                )}
                                <div>{subcategory.projectName}</div>
                            </div>
                        </td>
                        <td className="text-center">
                            <span>{(subcategory?.subProjectList || [])?.length}</span>
                        </td>
                        <td className="text-center" >
                            {moment(subcategory?.createdDate).format('DD/MM/YYYY')}
                        </td>
                        <td className="text-center">
                            <div className="action-icon">
                                <DropdownAction
                                    overlay={actionMenu(subcategory,item)}
                                    trigger={["click"]}
                                >
                                    <i className={ICONS.MORE_P} />
                                </DropdownAction>
                            </div>

                        </td>
                    </tr>
                    {isSubcategoryOpen && getSubcategories(subcategory.subProjectList, index,subcategories)}
                </React.Fragment>
            );
        });
    };
    return (
        <>
            <div className="view-container">
                <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage
                                id="organization.project.table.heading"
                                defaultMessage=""
                            />
                        </div>
                    </div>
                </div>

                <Skeleton loading={props.listLoading}
                    active
                    paragraph={{ rows: 17 }}
                >
                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th className='text-left' width={"30%"}>Project Name</th>
                                    <th className="text-center">Count</th>
                                    <th className="text-center">Date Created</th>
                                    {/* <th className="text-center">Status</th> */}
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(projectWithSubProject || [])?.length ? projectWithSubProject.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <div className="plus-minus-icon">
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {item.subProjectList && item.subProjectList.length > 0 ? (
                                                            <>
                                                                <span
                                                                    onClick={() => toggleChild(item.projectMasterId)}
                                                                >
                                                                    {isChildOn(item.projectMasterId) ? <i class="fi fi-br-minus"></i> : <i class="fi fi-br-plus"></i>}
                                                                </span>
                                                                <div style={{ marginLeft: "10px" }}>{item?.projectName}</div>
                                                            </>

                                                        )
                                                            :
                                                            <div style={{ marginLeft: "22px" }}>{item?.projectName}</div>
                                                        }

                                                    </div>
                                                </div>
                                            </td>

                                            <td className="text-center">
                                                <span>{(item?.subProjectList || [])?.length}</span>
                                            </td>
                                            <td className="text-center">
                                                {moment(item?.createdDate).format('DD/MM/YYYY')}
                                            </td>

                
                                            <td className="text-center">
                                                <div className="action-icon">
                                                    <DropdownAction
                                                        overlay={actionMenu(item)}
                                                        trigger={["click"]}
                                                    >
                                                        <i className={ICONS.MORE} />
                                                    </DropdownAction>
                                                </div>

                                            </td>
                                        </tr>
                                        {isChildOn(item.projectMasterId) && getSubcategories(item.subProjectList, index + 1,item)}
                                    </React.Fragment>
                                ))
                                :
                                <tr key="empty-data-box">
                                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                    <Empty />
                                </td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>

                </Skeleton>
            </div>
        </>
    );
};



export default injectIntl(ProjectListingComp);
