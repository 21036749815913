import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { REPORTS_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchAgedDebtors = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/getAgedDebtorsByRid?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 20}&pageNumber=${payload.pageNumber || 0}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&customerIds=${payload.customerId || ''}&filterStartDate=&filterEndDate=`)
          .then(res => {
            const { data } = res
            dispatch({ type: REPORTS_ACTION_LIST.AGED_DEBTORS_LIST, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
            }else{
              err.actionToCall = REPORTS_ACTION_LIST.AGED_DEBTORS_LIST;
            }
          })
      }
  }
