import React, { Component, Fragment } from "react";
// import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, DatePicker, Tooltip,Skeleton } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "../../../general/Dropdown";
// import { MultipleSelectDropdown } from "../../../general/MultipleSelectDropdown";
import * as find from "lodash.find";
import moment from "moment-timezone";
import * as filter from "lodash.filter";
// import { CustomAgGrid } from "../../../../general/CustomAgGrid";
// import config from '../../../../../config/environmentConfig';
import { QuestionCircleTwoTone } from "@ant-design/icons";

import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../static/constants";
// import { AG_GRID_CONSTANTS } from "../../../../static/agGridConstants";
// import { addDaysToDate } from "../../../../utils";
// import { getLabelName } from "../../../modal/modalBody/customerInfo";
// import { showToasterMessage } from "../../../../utils";
// import { LogoComponent } from "../../../../general/LogoComponent";

const format = CONSTANTS.TABLE_DATE_FORMAT;
class StockTransitDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: "default",
      billingAddress: this.defaultBillingAddress,
    };
  }

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;

    //const supplierAddressObjs = (supplierObj.boLocationList || []).filter(x => x.locationType === CONSTANTS.BILLING_ADDRESS) || [];

    // const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
    // if (rowNode) {
    //   rowNode.data["customerInquiryNumber"] = this.props.customerInquiryNumber;
    //   rowNode.data["customerPONumber"] = this.props.customerPONumber;
    //   rowNode.data["customerRFQNumber"] = this.props.customerRFQNumber;
    //   rowNode.data[CONSTANTS.SHIPPING_ADDRESS] =
    //     (this.props.relationshipShippingAddress || {}).streetAddress1 || "";
    //   rowNode.data[CONSTANTS.SHIPPING_ADDRESS] =
    //     (this.props.relationshipShippingAddress || {}).streetAddress1 || "";
    //   rowNode.data[AG_GRID_CONSTANTS.COMMON.PLACE_OF_SUPPLY_FIELD] =
    //     this.props.placeOfSupply || "";
    //   rowNode.data[
    //     AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD
    //   ] = supplierPaymentTerm.termName || "";
    //   rowNode.data["reference"] = this.props.reference;
    //   rowNode.data[AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT] =
    //     this.props.projectName;
    //   rowNode.data["supplierInvoiceNumber"] = this.props.supplierInvoiceNumber;
    //   rowNode.data["supplierPONumber"] = this.props.supplierPONumber;
    //   rowNode.data["supplierQuoteNumber"] = this.props.supplierQuoteNumber;
    //   this.gridApi.refreshCells({ force: true });
    //   this.gridApi.sizeColumnsToFit();
    // }

    // const columnDefs = [
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.gridHeader.placeOfSupply"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: AG_GRID_CONSTANTS.COMMON.PLACE_OF_SUPPLY_FIELD,
    //     resizable: true,
    //     hide: (this.props.companyInfo || {}).countryName !== "India",
    //     editable: true,
    //     cellEditor: "customDropDownEditor",
    //     //pinned: 'left',
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.states || [],
    //         optionKeyName: "stateId",
    //         valueKeyName: "stateName",
    //         onSelect: (selectedValue) => {
    //           this.props.updateState({
    //             placeOfSupply: selectedValue,
    //           });
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //         },
    //         // allowClear: true,
    //         // onClear: () => {
    //         //     //obj.node.setDataValue(obj.colDef.field, undefined);
    //         //     this.props.updateState({
    //         //         placeOfSupply: undefined
    //         //     })
    //         // },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="modal.txDetails.inquiry.customerInquiryNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "customerInquiryNumber",
    //     hide: this.props.customerInquiryNumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage id="supplierPONumber.text" defaultMessage="" />
    //         </div>
    //       );
    //     },
    //     field: "supplierPONumber",
    //     hide: this.props.supplierPONumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage id="customerPONumber.text" defaultMessage="" />
    //         </div>
    //       );
    //     },
    //     field: "customerPONumber",
    //     hide: this.props.customerPONumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.rfq.form.gridHeader.customerRFQNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "customerRFQNumber",
    //     hide: this.props.customerRFQNumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.purchaseOrder.form.gridHeader.supplierInvoiceNumber"
    //             defaultMessage=""
    //           />
    //           <Tooltip
    //             placement="top"
    //             title={
    //               <FormattedMessage
    //                 id="common.You.can.enter.Supplier.Invoice.Number"
    //                 defaultMessage=""
    //               />
    //             }
    //             trigger="click"
    //           >
    //             <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //     field: "supplierInvoiceNumber",
    //     resizable: true,
    //     editable: true,
    //     cellEditor: "customTextEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         maxLength: 20,
    //         onChange: (val) => {
    //           this.props.updateState({
    //             supplierInvoiceNumber: val,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.rfq.form.gridHeader.supplierQuoteNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "supplierQuoteNumber",
    //     editable: true,
    //     cellEditor: "customTextEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         maxLength: 20,
    //         onChange: (val) => {
    //           this.props.updateState({
    //             supplierQuoteNumber: val,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.purchaseinvoice.form.gridHeader.refNumber"
    //             defaultMessage=""
    //           />
    //           <Tooltip
    //             placement="top"
    //             title={
    //               <FormattedMessage
    //                 id="tooltip.referenceNumberAssign"
    //                 defaultMessage=""
    //               />
    //             }
    //             trigger="click"
    //           >
    //             <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //     field: "reference",
    //     editable: true,
    //     cellEditor: "customTextEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         maxLength: 20,
    //         onChange: (val) => {
    //           this.props.updateState({
    //             reference: val,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.gridHeader.shippingAddress"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: CONSTANTS.SHIPPING_ADDRESS,
    //     editable: true,
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         value: (this.props.relationshipShippingAddress || {})
    //           .streetAddress1,
    //         items: this.props.allRelationshipShippingAddress || [],
    //         optionKeyName: "locationId",
    //         valueKeyName: "streetAddress1",
    //         addressLineKey1: "streetAddress2",
    //         addressLineKey2: "cityName",
    //         addressLineKey3: "stateName",
    //         addressLineKey4: "zipCode",
    //         isFavouriteKeyName: "isDefault",
    //         optionClassAddress: "custom-address-dropdown",
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(
    //             this.props,
    //             MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS,
    //             payload
    //           );
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
    //             addressType: "relationship",
    //           };
    //           this.handleNewAddressAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS
    //           );
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //           const relationshipShippingAddress = find(
    //             this.props.allRelationshipShippingAddress || [],
    //             { locationId: Number(optionObj.key) }
    //           );
    //           this.props.updateState({
    //             relationshipShippingAddress,
    //             placeOfSupply: (relationshipShippingAddress || {}).stateName,
    //           });
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             relationshipShippingAddress: undefined,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.gridHeader.paymentTerms"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field:
    //       AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD,
    //     editable: true,
    //     //pinned: 'right',
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.paymentTerms,
    //         optionKeyName: "paymentTermId",
    //         valueKeyName: "termName",
    //         isLockKeyName: "isSystemGenerated",
    //         //isFavouriteKeyName: 'isDefault',
    //         canAddNew: true,
    //         canDelete: true,
    //         // permissions: this.props.permissions,
    //         // moduleName: MODULE_CODED_VALUES.PAYMENT_TERM,
    //         // operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(
    //             this.props,
    //             MODAL_TYPE.PAYMENT_TERM,
    //             payload
    //           );
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             termName: payload.textEntered,
    //             days: null,
    //             description: "",
    //             isDefaultPayment: false,
    //             submittedOnce: false,
    //           };
    //           this.handleNewPaymentTermAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.PAYMENT_TERM
    //           );
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //           const supplierPaymentTerm = find(this.props.paymentTerms, {
    //             paymentTermId: Number(optionObj.key),
    //           });
    //           let invoiceDueDate = addDaysToDate(
    //             this.props.gitDate || new Date(),
    //             supplierPaymentTerm.numberOfDays
    //           );
    //           this.props.updateState({
    //             supplierPaymentTerm,
    //             invoiceDueDate,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.projectDropdown.label"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT,
    //     editable: true,
    //     //pinned: 'right',
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.projectList,
    //         valueKeyName: "projectName",
    //         value: this.props.projectName,
    //         optionKeyName: "projectMasterId",
    //         projectIdKey: "projectMasterId",
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             projectName: payload.projectName,
    //           };
    //           this.handleNewProjectAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.PROJECT
    //           );
    //         },
    //         onSelect: (selectedValue, option) => {
    //           const selectedProject =
    //             find(this.props.projectList, {
    //               projectMasterId: Number(option.key),
    //             }) || {};
    //           this.props.updateState({
    //             projectMasterId: selectedProject.projectMasterId,
    //             projectName: selectedProject.projectName,
    //             projectNumber: selectedProject.projectNumber,
    //           });
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             projectMasterId: undefined,
    //             projectName: undefined,
    //             projectNumber: undefined,
    //           });
    //         },
    //       };
    //     },
    //   },
    // ];

    // const onGridReady = (params) => {
    //   this.gridApi = params.api;
    //   this.gridApi.sizeColumnsToFit();
    //   this.gridColumnApi = params.columnApi;
    // };

    const getListBySupplier = (pList) => {
      let tempList = [];
      for (let i = 0; i < (pList || []).length; i++) {
        if (
          Number(pList[i].supplierId) === Number(this.props.selectedSupplierId)
        ) {
          tempList.push(pList[i]);
        }
      }
      return tempList;
    };

    const handleNewItemAddition = (props, payload, modalType) => {
      const formData = payload.formData;
      const { showModal } = props;

      if (
        formData.textBoxValue &&
        formData.textBoxValue.length &&
        !payload.existsInData
      ) {
        addItemConfirmationModal(props, formData.textBoxValue, modalType);
      } else {
        const data = {
          title: getModalTitle(payload, modalType),
          formData,
          modalData: { modalType, data: payload },
          handleSubmit: (formData = {}) => {
            if (formData.textBoxValue && formData.textBoxValue.length) {
              addItemConfirmationModal(props, formData.textBoxValue, modalType);
            } else {
              data.formData.submittedOnce = true;
              // showModal(data);
            }
          },
        };
        props.pushModalToStack(data);
      }
    };

    const addItemConfirmationModal = (props, text, modalType) => {
      const { showModal, companyInfo } = props;
      const labelName = getModalTitle(null, modalType);
      const modalBody = (
        <span>
          <FormattedMessage
            id="modal.commmon.submit"
            // values={{ value: `${text} to ` }}
            defaultMessage=""
          />
          {/* {labelName} ? */}
        </span>
      );

      let addFunc;
      let payload = {
        text: text,
        relationshipId: companyInfo.relationshipId,
      };

      switch (modalType) {
        case MODAL_TYPE.DOCUMENT_NAME: {
          addFunc = props.addDocumentName;
          payload.txType =
            CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.LANDED_COST];
          break;
        }

        default: {
          addFunc = () => {};
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          addFunc(payload);
          this.gridApi && this.gridApi.stopEditing();
          props.hideModal();
        props.popModalFromStack();

        },
      };
      showModal(modalData);
    };

    const getModalTitle = (newTextObj, modalType) => {
      let title;

      switch (modalType) {
        case MODAL_TYPE.DOCUMENT_NAME: {
          title = (
            <FormattedMessage
              id="addItem.text.documentName"
              defaultMessage=""
            />
          );
          break;
        }
        default:
          title = (
            <FormattedMessage
              id="signup.confirmation.title"
              defaultMessage=""
            />
          );
      }
      return title;
    };

    return (
      <Fragment>
        <Row>
          <Col span={5}>
          <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 5 }}>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Supplier Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="purchase.landindCostPage.table.heading.supplier"
                    defaultMessage=""
                  />
                  <span className="required">*</span>
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  placeholder="Select Supplier"
                  items={this.props.suppliersList[1] || []}
                  optionKeyName={"supplierId"}
                  valueKeyName={"supplierName"}
                  value={this.props.selectedSupplierName}
                  className={
                    !this.props.selectedSupplierId && this.props.submittedLc
                      ? "input-text-error"
                      : ""
                  }
                  onSelect={(selectedValue, option) => {
                    const selectedSupplier = find(
                      this.props.suppliersList[1] || [],
                      { supplierId: Number(option.key) }
                    );
                    this.props.updateState({
                      selectedSupplierId: option.key,
                      selectedSupplierName: option.name,
                      supplier: selectedSupplier,
                    });
                  }}
                />
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="purchase.landindCost.create.selectTransaction"
                    defaultMessage=""
                  />
                  <span className="required">*</span>
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  placeholder="Select Transaction"
                  items={[
                    { name: "Purchase Invoice", key: "purchaseInvoice" },
                    { name: "GRN", key: "grn" },
                  ]}
                  // items= {[{name:'Purchase Invoice', key: 'purchaseInvoice'}, {name: 'Purchase Order', key:'purchaseOrder'}, {name: 'GRN', key:'grn'}]}
                  value={this.props.selectedTxn}
                  optionKeyName={"key"}
                  valueKeyName={"name"}
                  className={
                    !this.props.selectedTxn && this.props.submittedLc
                      ? "input-text-error"
                      : ""
                  }
                  onSelect={(selectedValue, option) => {
                    this.props.updateState({
                      selectedTxn: selectedValue,
                      isDetailsUpdated: false,
                      lcItemList: [],
                    });
                    if (selectedValue === "purchaseInvoice") {
                      this.props.fetchPurchaseInvoicesForLC({
                        relationshipId: this.props.companyInfo.relationshipId,
                      });
                    } else if (selectedValue === "grn") {
                      this.props.fetchGRNInvoicesForLC({
                        relationshipId: this.props.companyInfo.relationshipId,
                      });
                    }
                  }}
                />
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage id="transactionId.text" defaultMessage="" />
                  <span className="required">*</span>
                </div>
              </Col>
              <Col span={24}>
                {this.props.selectedSupplierId && this.props.selectedTxn
                  ? ""
                  : "Please select supplier & transaction first"}

                {this.props.selectedSupplierId &&
                this.props.selectedTxn === "purchaseInvoice" ? (
                  <Dropdown
                    placeholder="Select Transaction Id"
                    items={getListBySupplier(this.props.lcPIList)}
                    optionKeyName={"invoiceMasterId"}
                    valueKeyName={"invoiceNumber"}
                    value={
                      this.props.lcPIList.filter(
                        (e) => e.invoiceMasterId === this.props.selectedTxnId
                      )[0]?.invoiceNumber
                    }
                    className={
                      !this.props.selectedTxnId && this.props.submittedLc
                        ? "input-text-error"
                        : ""
                    }
                    onSelect={(selectedValue, option) => {
                      this.props.updateState({
                        selectedTxnId: selectedValue,
                        isDetailsUpdated: false,
                      });
                      const payload = {
                        relationshipId: this.props.companyInfo.relationshipId,
                        invoiceMasterId: selectedValue,
                        supplierId: this.props.selectedSupplierId,
                      };
                      this.props.fetchLCPurchasedInvoiceDetail(payload);
                    }}
                  />
                ) : (
                  ""
                )}

                {this.props.selectedSupplierId &&
                this.props.selectedTxn === "purchaseOrder" ? (
                  <Dropdown
                    placeholder="Select Transaction Id"
                    items={getListBySupplier(this.props.lcPiList)}
                    optionKeyName={"inboundDeliveryMasterId"}
                    valueKeyName={"inboundDeliveryNumber"}
                    className={
                      !this.props.selectedTxnId && this.props.submittedLc
                        ? "input-text-error"
                        : ""
                    }
                    onSelect={(selectedValue, option) => {
                      this.props.updateState({
                        selectedTxnId: selectedValue,
                        isDetailsUpdated: false,
                        lcItemList: [],
                      });

                      const payload = {
                        relationshipId: this.props.companyInfo.relationshipId,
                        inboundDeliveryMasterId: selectedValue,
                        supplierId: this.props.selectedSupplierId,
                      };
                      this.props.fetchLcPoDetail(payload);
                    }}
                  />
                ) : (
                  ""
                )}

                {this.props.selectedSupplierId &&
                this.props.selectedTxn === "grn" ? (
                  <Dropdown
                    placeholder="Select Transaction Id"
                    items={getListBySupplier(this.props.lcGRNList)}
                    optionKeyName={"inboundDeliveryMasterId"}
                    valueKeyName={"inboundDeliveryNumber"}
                    className={
                      !this.props.selectedTxnId && this.props.submittedLc
                        ? "input-text-error"
                        : ""
                    }
                    onSelect={(selectedValue, option) => {
                      this.props.updateState({
                        selectedTxnId: selectedValue,
                        isDetailsUpdated: false,
                        lcItemList: [],
                      });

                      const payload = {
                        relationshipId: this.props.companyInfo.relationshipId,
                        inboundDeliveryMasterId: selectedValue,
                        supplierId: this.props.selectedSupplierId,
                      };
                      this.props.fetchLcInboundryDetail(payload);
                    }}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Skeleton>
          </Col>

          <Col span={13}></Col>
          <Col span={6} className="order-value-info">
          <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 5 }}>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Landed Cost Information
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">Date Created</div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  defaultValue={moment()}
                  value={this.props.selectedDate}
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      selectedDate: selectedDate,
                    });
                  }}
                  size={size}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  Expiration Date
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  defaultValue={moment()}
                  value={this.props.expiryDate}
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      expiryDate: selectedDate,
                    });
                  }}
                  size={size}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesOrder.form.documentDropdown.label"
                    defaultMessage=""
                  />

                  <Tooltip
                    placement="right"
                    title={
                      <FormattedMessage
                        id="documentTitle.tooltip"
                        defaultMessage=""
                      />
                    }
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  items={filter(this.props.documentList || [], (document) => {
                    return (
                      document.txType ===
                      CONSTANTS_TRANSACTIONS_KEY[
                        CONSTANTS_TRANSACTIONS.LANDED_COST
                      ]
                    );
                  })}
                  placeholder="Document name"
                  value={
                    this.props.docName || CONSTANTS_TRANSACTIONS.LANDED_COST
                  }
                  canAddNew={true}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                      maxLength: 30,
                    };
                    handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.DOCUMENT_NAME
                    );
                  }}
                  valueKeyName="docName"
                  optionKeyName="documentNameId"
                  onSelect={(selectedValue) => {
                    const selectedObj = find(this.props.documentList || [], {
                      documentNameId: Number(selectedValue),
                    });
                    this.props.updateState({
                      documentNameId: selectedValue,
                      docName: selectedObj.docName,
                    });
                  }}
                />
              </Col>
            </Row>
            </Skeleton>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default injectIntl(StockTransitDetail);
