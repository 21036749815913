import axios from 'axios';
import config from '../../../config/environmentConfig';
import { JOURNAL_ACTION_LIST, COMMON_ACTIONS } from '../../../static/constants'


export const fetchAllJournals = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/journalTx/getAllJournalTx?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: JOURNAL_ACTION_LIST.JOURNAL_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = JOURNAL_ACTION_LIST.JOURNAL_LIST;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}