import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SMS_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchCourseSubjectList = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/courseSubject/getSubjects?courseId=${payload.courseId}&relationshipId=${payload.relationshipId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: SMS_ACTION_LIST.COURSE_SUBJECT_LIST, data });
          })
          .catch((err) => {
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
            }else{
              err.actionToCall = SMS_ACTION_LIST.COURSE_SUBJECT_LIST;
            }
          })
      }
  }

  export const resetCourseSubjectList = () => {
    return dispatch => {
      dispatch({ type: SMS_ACTION_LIST.COURSE_SUBJECT_LIST_RESET });
    }
  }