import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SETTING_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchRemarksTemplate = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        dispatch({ type: SETTING_ACTION_LIST.RESET_REMARKS_TEMPLATE_DATA });
        return axios
          .get(`${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/thankyouEmailTemplate/getThankyouEmailTemplate?docName=${payload.docName}&relationshipId=${payload.relationshipId}`)
          .then(res => {
            const { data } = res;
            dispatch({ type: SETTING_ACTION_LIST.REMARKS_TEMPLATE_DATA, data });
          })
          .catch((err) => {
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
            }else{
              err.actionToCall = SETTING_ACTION_LIST.REMARKS_TEMPLATE_DATA;
            }
          })
      }
  }

  export const resetRemarksData = ()=>{
    return dispatch => {
      dispatch({ type: SETTING_ACTION_LIST.RESET_REMARKS_TEMPLATE_DATA });
    }
  }

  export const saveOrUpdateThankyouEmailTemplate = (payload) => {
   
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/thankyouEmailTemplate/saveOrUpdateThankyouEmailTemplate`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: SETTING_ACTION_LIST.REMARKS_TEMPLATE_DATA, data: res.data });
          showToasterMessage({ messageType: 'success', description: 'Saved successfully' });
        })
        .catch(err => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({ description: lang[((err.response || {}).data || "")] || 'Some error occurred' });
        })
    }
  }


  export const resetSQDetails = () => {
    return dispatch => {
      dispatch({ type: SETTING_ACTION_LIST.RESET_REMARKS_TEMPLATE_DATA });
    }
    }