import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchAllUsers = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/user/getAllUsers?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SETTING_ACTION_LIST.ALL_COMPANY_USERS, data: res.data });
      })
      .catch(err => {
        showToasterMessage({
          description: lang[((err.response || {}).data || "")] || 'Some error occurred'
        })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const deleteUsers = (payload, props) => {
  const requestObj = {
    userId: payload.id,
    email: payload.email,
    relationshipId: payload.relationshipId,
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/user/deleteUser`, requestObj)
      .then(res => {
        // dispatch({ type: SETTING_ACTION_LIST.DELETE_COMPANY_USERS, data: { id: payload.id } })
        showToasterMessage({
          description: (res || {}).message || 'User access revoked successfully',
          messageType: 'success'
        })
        props.fetchAllUsers && props.fetchAllUsers(payload);
      })
      .catch((err = {}) => {
          showToasterMessage({
            description: lang[((err.response || {}).data || {})] || 'Some error occurred'
          })
      })
  }
}