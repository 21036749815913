import axios from "axios";
import config from "../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../utils";
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createTestimonial = (payload,file ,props)=>{
 
  return dispatch => {
    let formData =  new FormData();
   
    if(file){
      formData.append('file', file)
    }

  formData.append('testimonial',JSON.stringify(payload,true)); 
    //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/testimonial/createTestimonial `,
      formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      )
          .then(response => {
           
            // if(response.status === 200){
             
              const val = {
                pageNumber: props.pageNumber,
                pageSize: props.pageSize,
                relationshipId: payload.relationshipId,
               }
              
                props.fetchAllTestimonialList(val);
                props.countTestimonialList(val);
                showToasterMessage({
                   messageType: "success",
                   description: "Save Successfully.",
                 });
               
            
            })

          .catch(err => {
          
            showToasterMessage({
              messageType: "error",
              description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
          })
    }
}


