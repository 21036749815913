import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TestimonialDrawer from '../../../../components/drawer/eStore/testimonials';
import {  showModal, hideModal, pushModalToStack, popModalFromStack, fetchCustomerDetailsByCustomerId ,clearCustomerDetails} from '../../../../actions/commonActions';
import { countTestimonialList, fetchAllTestimonialList } from '../../../eStore/testimonials/action';
import { createTestimonial } from './action';
import { fetchCustomers } from '../../../customer/Listing/action';



class TestimonialDrawerCon extends Component {

  constructor(props){
    super(props);
    
 

    this.state = {
      isLoading: true,
      pageNumber:0,
      pageSize:25,
 
      ...(props.testimonialData || {})
    }
}
 
  componentDidMount() {
    this.props.clearCustomerDetails();
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber:0,
      pageSize:25,
      customerId: this.props?.testimonialData?.customerId || 0,

    };
   this.props.fetchCustomers({...payload, isCompact: true});
   this.props.fetchCustomerDetailsByCustomerId(payload)
  }
  componentWillReceiveProps(props) {


    if(Object.keys(props.customerDetail || {})?.length){
      this.setState({
        customerName:props.customerDetail.companyName
      })
    }
  }

  render() {
    return (
      <TestimonialDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }}/>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.customer.customers,
    customerDetail: state.common.customerDetail,


  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
 fetchAllTestimonialList,
 countTestimonialList,
 createTestimonial,
 pushModalToStack,
 fetchCustomers,
 fetchCustomerDetailsByCustomerId,clearCustomerDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialDrawerCon);
