import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Row, Col, Tooltip, Switch, Checkbox, Tabs, InputNumber, Input } from 'antd';
import { Form } from '@ant-design/compatible';
import { showToasterMessage, sortByKey } from '../../../../../utils';
import 'material-ui-rc-color-picker/assets/index.css';
//import { CONSTANTS } from '../../../../../static/constants';
import './index.scss';
import checkIcon from '../../../../../assets/images/check.png';
import crossIcon from '../../../../../assets/images/cross.png';
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
// import { TextArea } from "../../../../general/TextAreaComponent";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../../static/ckEditorConfigConstants";
import ShareKioskUrlComponent from './shareKioskUrl';
import config from '../../../../../config/environmentConfig';
import CryptoJS from "crypto-js";
const { TabPane } = Tabs;


function callback(key, props) {
    // const payload = props.payload;
    props.updateState({activeTabKey: key})
    if (key === '1') {
    } else if (key === '2') {
        //fetchDataIfNeeded('fetchAllEmployees', 'allEmployee', props, payload);
    }
}

class DonationSettingKioskComponent extends Component {

  constructor(props){
    super(props);
    let tokenInfo={
      relationshipId: props.companyInfo.relationshipId,
      source: 'kiosk'
    }
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(tokenInfo), config.ENCRYPT_SECRET).toString();
    this.state= {
      previewUrl: config.KIOSK_BASE_URL + '?token=' + encodeURIComponent(encrypted)
    }
}

  copyCodeToClipboard = () => {
    const el = this.textArea;
    el.select();
    document.execCommand("copy");
    showToasterMessage({
      messageType: 'success', description: this.props.intl.formatMessage(
        {
          id: 'copyToClipboard.text',
          defaultMessage: ''
        }
      )
    });
  }
    render() {
        const self = this;
        // const agGridStyle = {
        //     height: '100%',
        //     width: '100%'

        // };
        const onFirstDataRendered = (params) => {
            params.api.sizeColumnsToFit();
        }
        const getRowHeight = (params) => {
            return 30;
        }

        const getFormSetting = (props) => {
            let selectedProjects = self.gridApi ?  {} : props.selectedProjects;
            let selectedFundraising = self.gridApi2 ?  {} : props.selectedFundraising;
            let selectedEvents = self.gridApiEvent ?  {} : props.selectedEvents;
            let selectedRegistrations = self.gridApiRegistration ?  {} : props.selectedRegistrations;
            let selectedSubscription = self.gridApiSubscribe ?  {} : props.selectedSubscription;
            let selectedStudentRegistrations = self.gridApiSchoolRegistration ?  {} : props.selectedStudentRegistrations;
            let selectedSponsorships = self.gridApiSponsorship ?  {} : props.selectedSponsorships;

            self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if (data.isListingAllow) {
                    selectedProjects[data.projectMasterId] = {
                        sNo: index + 1,
                        displayName: data.ListingName,
                        projectEndDate: data.projectEndDate,
                        projectId: data.projectId,
                        projectMasterId: data.projectMasterId,
                        projectName: data.projectName,
                        projectNumber: data.projectNumber,
                        isListingAllow: data.isListingAllow ? 1 : 0,
                        ledgerAccountName: data.ledgerAccountName,
                        chartOfAccountDetailsId: data.chartOfAccountDetailsId,
                        paymentGatewayName: data.paymentGatewayName,
                        paymentGatewayId: data.paymentGatewayId,
                        paymentGatewayType: data.paymentGatewayType
                    }
                }
            }); 
            self.gridApi2 && self.gridApi2.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if (data.isListingAllow) {
                    selectedFundraising[data.fundraisingCampaignId] = {
                        sNo: index + 1,
                        displayName: data.ListingName,
                        startDate: data.startDate,
                        endDate: data.endDate,
                        goal: data.goal,
                        fundraisingCampaignId: data.fundraisingCampaignId,
                        campaignName: data.campaignName,
                        projectNumber: data.projectNumber,
                        isListingAllow: data.isListingAllow ? 1 : 0,
                        ledgerAccountName: data.ledgerAccountName,
                        chartOfAccountDetailsId: data.chartOfAccountDetailsId,
                        paymentGatewayName: data.paymentGatewayName,
                        paymentGatewayId: data.paymentGatewayId,
                        paymentGatewayType: data.paymentGatewayType
                    }
                }
            });

            self.gridApiEvent && self.gridApiEvent.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if (data.isListingAllow) {
                    selectedEvents[data.alpideFormBuilderId] = {
                        sNo: index + 1,
                        displayName: data.ListingName,
                        alpideFormBuilderId: data.alpideFormBuilderId,
                        templateName: data.templateName,
                        projectNumber: data.projectNumber,
                        isListingAllow: data.isListingAllow ? 1 : 0,
                        ledgerAccountName: data.ledgerAccountName,
                        chartOfAccountDetailsId: data.chartOfAccountDetailsId,
                        paymentGatewayName: data.paymentGatewayName,
                        paymentGatewayId: data.paymentGatewayId,
                        paymentGatewayType: data.paymentGatewayType
                    }
                }
            });

            self.gridApiRegistration && self.gridApiRegistration.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                let formSetting = JSON.parse(data.formSetting || "{}");
                if (data.isListingAllow) {
                    selectedRegistrations[data.registrationFormSettingId] = {
                        sNo: index + 1,
                        displayName: data.ListingName,
                        registrationFormSettingId: data.registrationFormSettingId,
                        programName: data.programName,
                        projectNumber: data.projectMasterId,
                        isListingAllow: data.isListingAllow ? 1 : 0,
                        ledgerAccountName: data.ledgerAccountName,
                        chartOfAccountDetailsId: data.chartOfAccountDetailsId,
                        paymentGatewayName: formSetting.paymentGateway?.userDefinedGatewayName,
                        paymentGatewayId: formSetting.paymentGateway?.alpidePaymentGatewayId,
                        paymentGatewayType: data.paymentGatewayType
                    }
                }
            });

            self.gridApiSubscribe && self.gridApiSubscribe.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if (data.isListingAllow) {
                    selectedSubscription[data.distributionGroupId] = {
                        sNo: index + 1,
                        displayName: data.ListingName,
                        distributionGroupId: data.distributionGroupId,
                        dgName: data.dgName,
                        projectNumber: data.projectNumber,
                        isListingAllow: data.isListingAllow ? 1 : 0,
                        ledgerAccountName: data.ledgerAccountName,
                        chartOfAccountDetailsId: data.chartOfAccountDetailsId,
                        paymentGatewayName: data.paymentGatewayName,
                        paymentGatewayId: data.paymentGatewayId,
                        paymentGatewayType: data.paymentGatewayType
                    }
                }
            });

            self.gridApiSchoolRegistration && self.gridApiSchoolRegistration.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                // let formSetting = JSON.parse(data.formSetting || "{}");
                if (data.isListingAllow) {
                    selectedStudentRegistrations[data.registrationFormSettingId] = {
                        sNo: index + 1,
                        displayName: data.ListingName,
                        registrationFormSettingId: data.registrationFormSettingId,
                        programName: data.programName,
                        projectNumber: data.projectMasterId,
                        isListingAllow: data.isListingAllow ? 1 : 0,
                    }
                }
            });
            self.gridApiSponsorship && self.gridApiSponsorship.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if (data.isListingAllow) {
                    selectedSponsorships[data.giveSponsorshipMasterId] = {
                        sNo: index + 1,
                        displayName: data.ListingName,
                        saleStartDate: data.saleStartDate,
                        saleEndDate: data.saleEndDate,
                        giveSponsorshipMasterId: data.giveSponsorshipMasterId,
                        isListingAllow: data.isListingAllow ? 1 : 0,
                        // ledgerAccountName: data.ledgerAccountName,
                        // chartOfAccountDetailsId: data.chartOfAccountDetailsId,
                        // paymentGatewayName: data.paymentGatewayName,
                        // paymentGatewayId: data.paymentGatewayId,
                    }
                }
            }); 

            
            return {
                textColor: props.textColor,
                bgColor: props.bgColor,
                formHeading: props.formHeading,
                subHeading: props.subHeading,
                footer: props.footer,
                isShowDonationMenu: props.isShowDonationMenu,
                isShowFeePaymentMenu: props.isShowFeePaymentMenu,
                isShowOpenEventMenu: props.isShowOpenEventMenu,
                isShowRegistrationMenu: props.isShowRegistrationMenu,
                isShowFundRaising: props.isShowFundRaising,
                isShowSubscribe: props.isShowSubscribe,
                isShowSchoolRegistration: props.isShowSchoolRegistration,
                isShowPledgePaymentMenu: props.isShowPledgePaymentMenu,
                isShowSponsorshipMenu: props.isShowSponsorshipMenu,
                selectedProjects: selectedProjects,
                selectedFundraising: selectedFundraising,
                flashMessage: props.flashMessage,
                flashMessageDuration: props.flashMessageDuration,
                selectedEvents: selectedEvents,
                selectedRegistrations: selectedRegistrations,
                selectedSubscription: selectedSubscription,
                selectedSponsorships: selectedSponsorships,
                selectedStudentRegistrations: selectedStudentRegistrations,
                subscribeHeaderMessage: props.subscribeHeaderMessage,
                isTransactionFeeApply: props.isTransactionFeeApply,
                isTransactionFeeApplyByDefault: props.isTransactionFeeApplyByDefault,
                transactionFeePercent: props.transactionFeePercent,
                isTransactionFeeRequired: props.isTransactionFeeRequired,
                isDisplayNameOrEmail: props.isDisplayNameOrEmail,
                isRequiredNameOrEmail: props.isRequiredNameOrEmail
            }
        }

        const saveSettings = (props) => {
            if (props.textColor && props.bgColor && props.textColor === props.bgColor) {
                return showToasterMessage({
                    messageType: 'error', description: props.intl.formatMessage({ id: 'sameColor.validation' })
                });
            }
            // if (!props.formHeading) {
            //     return showToasterMessage({
            //         messageType: 'error', description: props.intl.formatMessage({ id: 'registration.formHeading.required' })
            //     });
            // }
            let isLedgerMissing = false;
            let isDisplayNameMissing = false;
            let isPaymentGatewayMissing = false;
            self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if (data.isListingAllow && !data.chartOfAccountDetailsId) {
                    isLedgerMissing = true;
                }
                if (data.isListingAllow && !data.ListingName) {
                    isDisplayNameMissing = true;
                }
                // if (data.isListingAllow && !data.paymentGatewayId) {
                //     isPaymentGatewayMissing = true;
                // }
            });
            if (isLedgerMissing) {
                return showToasterMessage({
                    messageType: 'error', description: props.intl.formatMessage({ id: 'ledger.info.missing' })
                });
            }
            if (isDisplayNameMissing) {
                return showToasterMessage({
                    messageType: 'error', description: props.intl.formatMessage({ id: 'displayName.missing.validation' })
                });
            }
            if (isPaymentGatewayMissing) {
                return showToasterMessage({
                    messageType: 'error', description: props.intl.formatMessage({ id: 'paymentGateway.notAvailable.message' })
                });
            }
            const modalData = {
                modalBody: <FormattedMessage id='save.confirmation' />,
                handleSubmit: () => {
                    let donationContent = getFormSetting(props);
                    let payload = {
                        relationshipId: (props.companyInfo || {}).relationshipId,
                        settingsDonationId: props.settingsDonationIdKiosk,
                        version: props.version,
                        donationContent: JSON.stringify(donationContent),
                        source: 'kiosk'
                    }
                    props.saveDonationSetting(payload, props);
                    props.hideModal();
                }
            };
            props.pushModalToStack(modalData);
        }

        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='project.label' defaultMessage='' />;
                },
                rowDrag: true,
                suppressMovable: true,
                field: 'projectName'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='displayName.text' defaultMessage='' />;
                },
                field: 'ListingName',
                editable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                width: 268,
                suppressKeyboardEvent: (params) => {
                    // const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        maxLength: 75,
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='listedForDonation.text' defaultMessage='' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'listedForDonation.help.message' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "isListingAllow",
                editable: true,
                resizable: true,
                width: 200,
                suppressKeyboardEvent: (params) => {
                    //const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        },
                    }
                },
                cellRendererFramework: (params) => {
                    return params.node.data.isListingAllow ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Default" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Default" />;
                },

            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='ledger.text' defaultMessage='' />;
                },
                field: 'ledgerAccountName',
                editable: true,
                sortable: true,
                resizable: true,
                suppressMovable: true,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.allLedgerAccounts,
                        valueKeyName: 'ledgerAccountName',
                        optionKeyName: 'chartOfAccountDetailsId',
                        onSelect: (selectedValue, option) => {
                            obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.chartOfAccountDetailsId = option.value;
                        },
                    }
                },
                cellRendererFramework: (params) => {
                    return <span>{params.node.data.ledgerAccountName}</span>
                },
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='paymentGateway.available' />;
                },
                field: 'paymentGatewayName',
                editable: true,
                sortable: true,
                resizable: true,
                suppressMovable: true,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellRendererFramework: (params) => {
                    return <span>{params.node.data.paymentGatewayName}</span>
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.availablePaymentGateways,
                        valueKeyName: 'userDefinedGatewayName',
                        optionKeyName: 'alpidePaymentGatewayId',
                        onSelect: (selectedValue, option) => {
                            let selectedGateway = this.props.availablePaymentGateways.find(gateway => (gateway.alpidePaymentGatewayId === option.value)) || {};
                            obj.node.data.paymentGatewayType = selectedGateway.paymentGatewayName;
                            obj.node.data.paymentGatewayId = option.value;
                            obj.node.setDataValue(obj.colDef.field, selectedValue);
                        },
                    }
                },
            },
        ];

        const columnDefsFundraise = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='fundraisingName.text' defaultMessage='' />;
                },
                rowDrag: true,
                suppressMovable: true,
                field: 'campaignName'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='displayName.text' defaultMessage='' />;
                },
                field: 'ListingName',
                editable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                width: 268,
                suppressKeyboardEvent: (params) => {
                    // const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        maxLength: 75,
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='listedForDonation.text' defaultMessage='' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'listedForDonation.help.message' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "isListingAllow",
                editable: true,
                resizable: true,
                width: 200,
                suppressKeyboardEvent: (params) => {
                    //const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        },
                    }
                },
                cellRendererFramework: (params) => {
                    return params.node.data.isListingAllow ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Default" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Default" />;
                },

            },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='ledger.text' defaultMessage='' />;
            //     },
            //     field: 'ledgerAccountName',
            //     editable: true,
            //     sortable: true,
            //     resizable: true,
            //     suppressMovable: true,
            //     cellEditor: 'customDropDownEditor',
            //     suppressKeyboardEvent: (params) => {
            //         const KEY_ENTER = 13;
            //         const keyCode = params.event.keyCode;
            //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
            //         return gridShouldDoNothing;
            //     },
            //     cellEditorParams: (obj) => {
            //         return {
            //             lastColumnCell: false,
            //             items: this.props.allLedgerAccounts,
            //             valueKeyName: 'ledgerAccountName',
            //             optionKeyName: 'chartOfAccountDetailsId',
            //             onSelect: (selectedValue, option) => {
            //                 obj.node.setDataValue(obj.colDef.field, selectedValue);
            //                 obj.node.data.chartOfAccountDetailsId = option.value;
            //             },
            //         }
            //     },
            //     cellRendererFramework: (params) => {
            //         return <span>{params.node.data.ledgerAccountName}</span>
            //     },
            // },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='paymentGateway.available' />;
            //     },
            //     field: 'paymentGatewayName',
            //     editable: true,
            //     sortable: true,
            //     resizable: true,
            //     suppressMovable: true,
            //     cellEditor: 'customDropDownEditor',
            //     suppressKeyboardEvent: (params) => {
            //         const KEY_ENTER = 13;
            //         const keyCode = params.event.keyCode;
            //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
            //         return gridShouldDoNothing;
            //     },
            //     cellRendererFramework: (params) => {
            //         return <span>{params.node.data.paymentGatewayName}</span>
            //     },
            //     cellEditorParams: (obj) => {
            //         return {
            //             lastColumnCell: false,
            //             items: this.props.availablePaymentGateways,
            //             valueKeyName: 'userDefinedGatewayName',
            //             optionKeyName: 'alpidePaymentGatewayId',
            //             onSelect: (selectedValue, option) => {
            //                 obj.node.setDataValue(obj.colDef.field, selectedValue);
            //                 obj.node.data.paymentGatewayId = option.value;
            //             },
            //         }
            //     },
            // },
        ];

        const columnDefEvent = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='eventName.text' defaultMessage='' />;
                },
                rowDrag: true,
                suppressMovable: true,
                field: 'templateName'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='displayName.text' defaultMessage='' />;
                },
                field: 'ListingName',
                editable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                width: 268,
                suppressKeyboardEvent: (params) => {
                    // const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        maxLength: 75,
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='listedForDonation.text' defaultMessage='' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'listedForDonation.help.message' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "isListingAllow",
                editable: true,
                resizable: true,
                width: 200,
                suppressKeyboardEvent: (params) => {
                    //const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        },
                    }
                },
                cellRendererFramework: (params) => {
                    return params.node.data.isListingAllow ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Default" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Default" />;
                },

            },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='ledger.text' defaultMessage='' />;
            //     },
            //     field: 'ledgerAccountName',
            //     editable: true,
            //     sortable: true,
            //     resizable: true,
            //     suppressMovable: true,
            //     cellEditor: 'customDropDownEditor',
            //     suppressKeyboardEvent: (params) => {
            //         const KEY_ENTER = 13;
            //         const keyCode = params.event.keyCode;
            //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
            //         return gridShouldDoNothing;
            //     },
            //     cellEditorParams: (obj) => {
            //         return {
            //             lastColumnCell: false,
            //             items: this.props.allLedgerAccounts,
            //             valueKeyName: 'ledgerAccountName',
            //             optionKeyName: 'chartOfAccountDetailsId',
            //             onSelect: (selectedValue, option) => {
            //                 obj.node.setDataValue(obj.colDef.field, selectedValue);
            //                 obj.node.data.chartOfAccountDetailsId = option.value;
            //             },
            //         }
            //     },
            //     cellRendererFramework: (params) => {
            //         return <span>{params.node.data.ledgerAccountName}</span>
            //     },
            // },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='paymentGateway.available' />;
            //     },
            //     field: 'paymentGatewayName',
            //     editable: true,
            //     sortable: true,
            //     resizable: true,
            //     suppressMovable: true,
            //     cellEditor: 'customDropDownEditor',
            //     suppressKeyboardEvent: (params) => {
            //         const KEY_ENTER = 13;
            //         const keyCode = params.event.keyCode;
            //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
            //         return gridShouldDoNothing;
            //     },
            //     cellRendererFramework: (params) => {
            //         return <span>{params.node.data.paymentGatewayName}</span>
            //     },
            //     cellEditorParams: (obj) => {
            //         return {
            //             lastColumnCell: false,
            //             items: this.props.availablePaymentGateways,
            //             valueKeyName: 'userDefinedGatewayName',
            //             optionKeyName: 'alpidePaymentGatewayId',
            //             onSelect: (selectedValue, option) => {
            //                 obj.node.setDataValue(obj.colDef.field, selectedValue);
            //                 obj.node.data.paymentGatewayId = option.value;
            //             },
            //         }
            //     },
            // },
        ];

        const columnDefRegistration = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='programName.text' defaultMessage='' />;
                },
                rowDrag: true,
                suppressMovable: true,
                field: 'programName'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='displayName.text' defaultMessage='' />;
                },
                field: 'ListingName',
                editable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                width: 268,
                suppressKeyboardEvent: (params) => {
                    // const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        maxLength: 75,
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='listedForDonation.text' defaultMessage='' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'listedForDonation.help.message' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "isListingAllow",
                editable: true,
                resizable: true,
                width: 200,
                suppressKeyboardEvent: (params) => {
                    //const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        },
                    }
                },
                cellRendererFramework: (params) => {
                    return params.node.data.isListingAllow ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Default" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Default" />;
                },

            },
            
        ];

        const columnDefSubscribe = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='dgNames.text' defaultMessage='' />;
                },
                rowDrag: true,
                suppressMovable: true,
                field: 'dgName'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='displayName.text' defaultMessage='' />;
                },
                field: 'ListingName',
                editable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                width: 268,
                suppressKeyboardEvent: (params) => {
                    // const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        maxLength: 75,
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='listedForDonation.text' defaultMessage='' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'listedForDonation.help.message' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "isListingAllow",
                editable: true,
                resizable: true,
                width: 200,
                suppressKeyboardEvent: (params) => {
                    //const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        },
                    }
                },
                cellRendererFramework: (params) => {
                    return params.node.data.isListingAllow ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Default" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Default" />;
                },

            },
           
        ];

        const columnDefSchool = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='eventName.text' defaultMessage='' />;
                },
                rowDrag: true,
                suppressMovable: true,
                field: 'programName'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='displayName.text' defaultMessage='' />;
                },
                field: 'ListingName',
                editable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                width: 268,
                suppressKeyboardEvent: (params) => {
                    // const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        maxLength: 75,
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='listedForDonation.text' defaultMessage='' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'listedForDonation.help.message' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "isListingAllow",
                editable: true,
                resizable: true,
                width: 200,
                suppressKeyboardEvent: (params) => {
                    //const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        },
                    }
                },
                cellRendererFramework: (params) => {
                    return params.node.data.isListingAllow ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Default" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Default" />;
                },

            }
        ];

        const columnDefSponsors = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='sponsorship.listing.name' defaultMessage='' />;
                },
                rowDrag: true,
                suppressMovable: true,
                field: 'name'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='displayName.text' defaultMessage='' />;
                },
                field: 'ListingName',
                editable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                width: 268,
                suppressKeyboardEvent: (params) => {
                    // const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        maxLength: 75,
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='listedForDonation.text' defaultMessage='' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'listedForDonation.help.message' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "isListingAllow",
                editable: true,
                resizable: true,
                width: 200,
                suppressKeyboardEvent: (params) => {
                    //const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        },
                    }
                },
                cellRendererFramework: (params) => {
                    return params.node.data.isListingAllow ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Default" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Default" />;
                },

            },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='ledger.text' defaultMessage='' />;
            //     },
            //     field: 'ledgerAccountName',
            //     editable: true,
            //     sortable: true,
            //     resizable: true,
            //     suppressMovable: true,
            //     cellEditor: 'customDropDownEditor',
            //     suppressKeyboardEvent: (params) => {
            //         const KEY_ENTER = 13;
            //         const keyCode = params.event.keyCode;
            //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
            //         return gridShouldDoNothing;
            //     },
            //     cellEditorParams: (obj) => {
            //         return {
            //             lastColumnCell: false,
            //             items: this.props.allLedgerAccounts,
            //             valueKeyName: 'ledgerAccountName',
            //             optionKeyName: 'chartOfAccountDetailsId',
            //             onSelect: (selectedValue, option) => {
            //                 obj.node.setDataValue(obj.colDef.field, selectedValue);
            //                 obj.node.data.chartOfAccountDetailsId = option.value;
            //             },
            //         }
            //     },
            //     cellRendererFramework: (params) => {
            //         return <span>{params.node.data.ledgerAccountName}</span>
            //     },
            // },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='paymentGateway.available' />;
            //     },
            //     field: 'paymentGatewayName',
            //     editable: true,
            //     sortable: true,
            //     resizable: true,
            //     suppressMovable: true,
            //     cellEditor: 'customDropDownEditor',
            //     suppressKeyboardEvent: (params) => {
            //         const KEY_ENTER = 13;
            //         const keyCode = params.event.keyCode;
            //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
            //         return gridShouldDoNothing;
            //     },
            //     cellRendererFramework: (params) => {
            //         return <span>{params.node.data.paymentGatewayName}</span>
            //     },
            //     cellEditorParams: (obj) => {
            //         return {
            //             lastColumnCell: false,
            //             items: this.props.availablePaymentGateways,
            //             valueKeyName: 'userDefinedGatewayName',
            //             optionKeyName: 'alpidePaymentGatewayId',
            //             onSelect: (selectedValue, option) => {
            //                 obj.node.setDataValue(obj.colDef.field, selectedValue);
            //                 obj.node.data.paymentGatewayId = option.value;
            //             },
            //         }
            //     },
            // },
        ];

        const onGridReady = (params) => {
            this.gridApi = params.api;
        }

        const onGridReadyFundraise = (params) => {
            this.gridApi2 = params.api;
        }

        const onGridReadyEvent = (params) => {
            this.gridApiEvent = params.api;
        }

        const onGridReadyRegistration = (params) => {
            this.gridApiRegistration = params.api;
        }
        const onGridReadySubscribe = (params) => {
            this.gridApiSubscribe = params.api;
        }

        const onGridReadySchoolRegistration = (params) => {
            this.gridApiSchoolRegistration = params.api;
        }

        const onGridReadySponsorship = (params) => {
            this.gridApiSponsorship = params.api;
        }

        const shareKioskUrl = (props)=> {
            this.props.pushModalToStack({
                title: <div>
                    <FormattedMessage id='shareKioskUrl.text' defaultMessage='' />
                </div>,
                modalBody: <ShareKioskUrlComponent {...props} />,
                width: '50%',
                hideFooter: true,
            })
        }

        return (
            <Fragment >
                <div className='bank-info'>

                    <div> <b> <FormattedMessage id='menuSetting.text' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'menuSetting.helpMessage' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </b> </div>


                    <Form.Item>
                        <Row>
                            <Col span={2}> <FormattedMessage id='donate.text' /> </Col>
                            <Col span={2}>
                                <Switch
                                    disabled
                                    checked={(this.props.isShowDonationMenu === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowDonationMenu: checked ? 1 : 0 })

                                    }} />
                            </Col>
                            <Col span={3}> <FormattedMessage id='schoolRegistration.text' /> </Col>
                            <Col span={2}>
                                <Switch
                                    checked={(this.props.isShowSchoolRegistration === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowSchoolRegistration: checked ? 1 : 0 })

                                    }} />
                            </Col>
                            <Col span={2}> <FormattedMessage id='openEvent.text' /> </Col>
                            <Col span={2}>
                                <Switch
                                    checked={(this.props.isShowOpenEventMenu === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowOpenEventMenu: checked ? 1 : 0 })

                                    }} />
                            </Col>
                            <Col span={3}> <FormattedMessage id='MemberRegistration.text' /> </Col>
                            <Col span={2}>
                                <Switch
                                    checked={(this.props.isShowRegistrationMenu === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowRegistrationMenu: checked ? 1 : 0 })

                                    }} />
                            </Col>
                            <Col span={2}> <FormattedMessage id='fundRaising.text' /> </Col>
                            <Col span={2}>
                                <Switch
                                    checked={(this.props.isShowFundRaising === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowFundRaising: checked ? 1 : 0 })

                                    }} />
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item>
                        <Row>
                            <Col span={2}> <FormattedMessage id='subscribe.text' /> </Col>
                            <Col span={2}>
                                <Switch
                                    checked={(this.props.isShowSubscribe === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowSubscribe: checked ? 1 : 0 })

                                    }} />
                            </Col>
                            <Col span={2}> <FormattedMessage id='pledgePayment.text' /> </Col>
                            <Col span={2}>
                                <Switch
                                    checked={(this.props.isShowPledgePaymentMenu === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowPledgePaymentMenu: checked ? 1 : 0 })

                                    }} />
                            </Col>
                            <Col span={2}> <FormattedMessage id='sponsorship.text' /> </Col>
                            <Col span={2}>
                                <Switch
                                    checked={(this.props.isShowSponsorshipMenu === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowSponsorshipMenu: checked ? 1 : 0 })

                                    }} />
                            </Col>
                        </Row>
                    </Form.Item>
                    {/* <Form.Item>
                        <Row>
                            <Col span={2}> </Col>
                            <Col span={4}> <FormattedMessage id='fundRaising.text' /> </Col>
                            <Col span={3}>
                                <Switch
                                    checked={(this.props.isShowFundRaising === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowFundRaising: checked ? 1 : 0 })

                                    }} />
                            </Col>
                        </Row>
                    </Form.Item> */}
                    <Form.Item>
                        <Row>
                            <Col span={4} onClick={() => { shareKioskUrl(this.props) }}> <FormattedMessage id='shareKioskUrl.text' defaultMessage='' /></Col>
                            <Col span={20}>
                                <div> <FormattedMessage id='shareKioskUrl.link.description' /></div>
                                
                                <div>
                                    <Input style={{ width: '90%' }}
                                        ref={(textarea) => this.textArea = textarea}
                                        value={this.state.previewUrl}
                                    />
                                    <Button title='Copy Link' type="default" style={{ paddingLeft: '5px', paddingRight: '5px' }} onClick={() => this.copyCodeToClipboard()}>
                                        <i className="fa fa-clone"></i>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form.Item>

                   

                    <br />
                    {this.props.isDataReady ?
                        <div className="mt15">
                            <Tabs activeKey={self.props.activeTabKey || "1"} className="primary-tab-container secondary-tab" onChange={(key) => {
                                callback(key, this.props);
                            }} type="card">
                                <TabPane tab={<b><FormattedMessage id='donate.text' /> <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'projectSetting.helpMessage' })} trigger="click">
                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                </Tooltip></b>} key="1">
                                    <br />
                                    <div className="ag-theme-balham "  >
                                        {/* <div className='pb10'>
                            <b><FormattedMessage id='projectSetting.text' />
                                <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'projectSetting.helpMessage' })} trigger="click">
                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                </Tooltip>
                            </b>
                        </div> */}
                                        {(this.props.projectMapedList && this.props.projectMapedList.length) ?
                                            <CustomAgGrid
                                                columnDefs={columnDefs}
                                                rowData={sortByKey(this.props.projectMapedList || [], 'sNo')}
                                                rowDragManaged={true}
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={onFirstDataRendered}
                                                getRowHeight={getRowHeight}
                                                onGridReady={onGridReady}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}
                                            >
                                            </CustomAgGrid>
                                            : ''
                                        }
                                    </div>
                                </TabPane>

                                {this.props.isShowSchoolRegistration ? <TabPane tab={<b><FormattedMessage id='schoolRegistration.text' />
                                    <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'schoolRegistration.text' })} trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip>
                                </b>} key="2">
                                    <br />
                                    <div className="ag-theme-balham kiosk-scroll"  >
                                        {(this.props.schoolRegistrationFormList && this.props.schoolRegistrationFormList.length) ?
                                            <CustomAgGrid
                                                columnDefs={columnDefSchool}
                                                rowData={sortByKey(this.props.schoolRegistrationFormList || [],'sNo')}
                                                rowDragManaged={true}
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={onFirstDataRendered}
                                                getRowHeight={getRowHeight}
                                                onGridReady={onGridReadySchoolRegistration}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}
                                            >
                                            </CustomAgGrid>
                                            : ''
                                        }
                                        <br />


                                    </div>
                                </TabPane> : ''
                                }

                                {this.props.isShowFundRaising ? <TabPane tab={<b><FormattedMessage id='fundRaising.text' />
                                    <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'fundRaising.text' })} trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip>
                                </b>} key="3">
                                    <br />
                                    <div className="ag-theme-balham kiosk-scroll"  >
                                        {/* <div className='pb10'>
                            <b><FormattedMessage id='fundraisingSetting.text' />
                                <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'fundraisingSetting.text' })} trigger="click">
                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                </Tooltip>
                            </b>
                        </div> */}
                                        {(this.props.fundraisingProjectList && this.props.fundraisingProjectList.length) ?
                                            <CustomAgGrid
                                                columnDefs={columnDefsFundraise}
                                                rowData={sortByKey(this.props.fundraisingProjectList || [], 'sNo')}
                                                rowDragManaged={true}
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={onFirstDataRendered}
                                                getRowHeight={getRowHeight}
                                                onGridReady={onGridReadyFundraise}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}
                                            >
                                            </CustomAgGrid>
                                            : ''
                                        }
                                        <br />

                                        {/* <Form.Item>
                                        <Row>
                                            <Col span={4}>
                                                <FormattedMessage id='flashMessage.text' />
                                            </Col>
                                            <Col span={7}>
                                                <TextArea
                                                    maxLength={1000}
                                                    rows={1}
                                                    style={{ width: '100%', padding: '5px' }}
                                                    placeholder={this.props.intl.formatMessage({ id: 'flashMessage.text' })}
                                                    value={this.props.flashMessage}
                                                    onChange={(e) => {
                                                        this.props.updateState({ flashMessage: e.target.value });
                                                    }}
                                                />
                                            </Col>
                                            <Col span={2}>
                                            </Col>
                                            <Col span={4}>
                                                <FormattedMessage id='membership.termAndFee.listing.header.duration' />
                                            </Col>
                                            <Col span={7}>
                                                <InputNumber
                                                    min={5}
                                                    style={{ width: '100%', padding: '5px' }}
                                                    placeholder={this.props.intl.formatMessage({ id: 'membership.termAndFee.listing.header.duration' })}
                                                    value={this.props.flashMessageDuration}
                                                    onChange={(e) => {
                                                        this.props.updateState({ flashMessageDuration: e });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Item> */}
                                    </div>
                                </TabPane> : ''
                                }

                                {this.props.isShowOpenEventMenu ? <TabPane tab={<b><FormattedMessage id='openEvent.text' />
                                    <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'openEvent.text' })} trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip>
                                </b>} key="4">
                                    <br />
                                    <div className="ag-theme-balham kiosk-scroll"  >
                                        {(this.props.eventTemplateList && this.props.eventTemplateList.length) ?
                                            <CustomAgGrid
                                                columnDefs={columnDefEvent}
                                                rowData={sortByKey(this.props.eventTemplateList || [], 'sNo')}
                                                rowDragManaged={true}
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={onFirstDataRendered}
                                                getRowHeight={getRowHeight}
                                                onGridReady={onGridReadyEvent}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}
                                            >
                                            </CustomAgGrid>
                                            : ''
                                        }
                                        <br />


                                    </div>
                                </TabPane> : ''
                                }


                                {this.props.isShowRegistrationMenu ? <TabPane tab={<b><FormattedMessage id='registration.text' />
                                    <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'registration.text' })} trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip>
                                </b>} key="5">
                                    <br />
                                    <div className="ag-theme-balham kiosk-scroll"  >
                                        {(this.props.eventTemplateList && this.props.eventTemplateList.length) ?
                                            <CustomAgGrid
                                                columnDefs={columnDefRegistration}
                                                rowData={sortByKey(this.props.membershipRegistrationFormList || [],'sNo')}
                                                rowDragManaged={true}
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={onFirstDataRendered}
                                                getRowHeight={getRowHeight}
                                                onGridReady={onGridReadyRegistration}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}
                                            >
                                            </CustomAgGrid>
                                            : ''
                                        }
                                        <br />


                                    </div>
                                </TabPane> : ''
                                }


                                {this.props.isShowSubscribe ? <TabPane tab={<b><FormattedMessage id='subscribe.text' />
                                    <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'subscribe.text' })} trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip>
                                </b>} key="6">
                                    <br />
                                    <div className="ag-theme-balham kiosk-scroll"  >
                                        {(this.props.dgFormList && this.props.dgFormList.length) ?
                                            <CustomAgGrid
                                                columnDefs={columnDefSubscribe}
                                                rowData={sortByKey(this.props.dgFormList || [], 'sNo')}
                                                rowDragManaged={true}
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={onFirstDataRendered}
                                                getRowHeight={getRowHeight}
                                                onGridReady={onGridReadySubscribe}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}
                                            >
                                            </CustomAgGrid>
                                            : ''
                                        }
                                        <br />

                                        <Form.Item>
                                            <Row>
                                                <Col span={4}>
                                                    <FormattedMessage id='subscribeHeaderMessage.text' />
                                                </Col>
                                                <Col span={18}>
                                                    {/* <TextArea
                                                    maxLength={1000}
                                                    rows={1}
                                                    style={{ width: '100%', padding: '5px' }}
                                                    placeholder={this.props.intl.formatMessage({ id: 'subscribeHeaderMessage.text' })}
                                                    value={this.props.subscribeHeaderMessage}
                                                    onChange={(e) => {
                                                        this.props.updateState({ subscribeHeaderMessage: e.target.value });
                                                    }}
                                                /> */}
                                                    <CKEditor
                                                        type="inline"
                                                        style={{ width: '100%', padding: '5px' }}
                                                        className="description-textarea"
                                                        key={`${"subscribeHeaderMessage"}`}
                                                        data={this.props.subscribeHeaderMessage}
                                                        onInit={editor => {
                                                            this.editor = editor;
                                                        }}
                                                        onChange={(event) => {
                                                            const data = event.editor.getData();
                                                            this.props.updateState({ subscribeHeaderMessage: data });
                                                        }}
                                                        config={CUSTOM_CONFIGURATION}
                                                    />
                                                </Col>

                                            </Row>
                                        </Form.Item>

                                    </div>
                                </TabPane> : ''
                                }

                                {this.props.isShowSponsorshipMenu ? <TabPane tab={<b><FormattedMessage id='sponsorship.text' />
                                    <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'sponsorship.text' })} trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip>
                                </b>} key="7">
                                    <br />
                                    <div className="ag-theme-balham kiosk-scroll"  >
                                        {(this.props.sponsorsFormList && this.props.sponsorsFormList.length) ?
                                            <CustomAgGrid
                                                columnDefs={columnDefSponsors}
                                                rowData={sortByKey(this.props.sponsorsFormList || [], 'sNo')}
                                                rowDragManaged={true}
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={onFirstDataRendered}
                                                getRowHeight={getRowHeight}
                                                onGridReady={onGridReadySponsorship}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}
                                            >
                                            </CustomAgGrid>
                                            : ''
                                        }
                                        <br />


                                    </div>
                                </TabPane> : ''
                                }

                            </Tabs>
                        </div>
                        : ''
                    }
                    <Form.Item>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col span={16}> <FormattedMessage id='transaciton.fee.charge.message' /> </Col>
                                    <Col span={2}>
                                        <Checkbox
                                            checked={(this.props.isTransactionFeeApply === 1)}
                                            onClick={(e) => {
                                                this.props.updateState({ isTransactionFeeApply: e.target.checked ? 1 : 0 })
                                            }} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={16}> <FormattedMessage id='transaciton.fee.percent.message' /> </Col>
                                    <Col span={2}>
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute', left: '90px', width: '30px', lineHeight: '30px', height: '32px', top: '4px', textAlign: 'center', background: '#e4e4e4' }}>%</div>

                                            <InputNumber
                                                min={0}
                                                max={100}
                                                value={this.props.transactionFeePercent}
                                                onChange={(e) => {
                                                    this.props.updateState({ transactionFeePercent: e });
                                                }} />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={16}> <FormattedMessage id='transaciton.fee.checkbox.label' /> </Col>
                                    <Col span={2}>
                                        <Checkbox
                                            checked={(this.props.isTransactionFeeApplyByDefault === 1)}
                                            onClick={(e) => {
                                                this.props.updateState({ isTransactionFeeApplyByDefault: e.target.checked ? 1 : 0 })
                                            }} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={16}> <FormattedMessage id='transaciton.fee.isRequired' /> </Col>
                                    <Col span={2}>
                                        <Checkbox
                                            checked={(this.props.isTransactionFeeRequired === 1)}
                                            onClick={(e) => {
                                                this.props.updateState({ isTransactionFeeRequired: e.target.checked ? 1 : 0 })
                                            }} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={12}>
                                <Row>
                                    <Col span={16}> <FormattedMessage id='transaciton.isDisplayNameOrEmail' /> </Col>
                                    <Col span={2}>
                                        <Checkbox
                                            checked={(this.props.isDisplayNameOrEmail === 1)}
                                            onClick={(e) => {
                                                this.props.updateState({ isDisplayNameOrEmail: e.target.checked ? 1 : 0 })
                                            }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16}> <FormattedMessage id='transaciton.isRequiredNameOrEmail' /> </Col>
                                    <Col span={2}>
                                        <Checkbox
                                            checked={(this.props.isRequiredNameOrEmail === 1)}
                                            onClick={(e) => {
                                                this.props.updateState({ isRequiredNameOrEmail: e.target.checked ? 1 : 0 })
                                            }} />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Form.Item>

                    


                    <div className="clearfix"></div>

                </div>
                {/* <br/> */}

                <div className="button-group-action">
                    <div className="align-footer">
                        <Button onClick={() => {
                            this.props.hideModal();
                        }}
                            type="default"
                        >
                            <FormattedMessage id='button.close.label' defaultMessage='' />
                        </Button>

                        <Button
                            type="primary"
                            onClick={() => {
                                saveSettings(this.props);
                            }}
                        >
                            <FormattedMessage id='button.save.label' defaultMessage='' />
                        </Button>
                    </div>
                </div>

            </Fragment >
        )
    }
}

export default injectIntl(DonationSettingKioskComponent);
