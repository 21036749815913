import axios from "axios";
import config from "../../../../config/environmentConfig";
import { COMMON_ACTIONS } from "../../../../static/constants";
import { showToasterMessage, getFinancialYear } from "../../../../utils";
import { formatDateForBackend } from "../../../../utils";
import * as filter from "lodash.filter";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const createStockTransfer = (payload, history) => {
  return (dispatch) => {
    const props = payload.props;
    const stockTranfer = _getGitData(payload);
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/git/generateGIT`,
        stockTranfer,
        {
          //   headers: {
          //     'content-type': 'multipart/form-data'
          //   }
        }
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "Stock transfer created successfully",
        });
        props.history.push("/admin/stock-intransit");
        props.fetchstockTransfer && props.fetchstockTransfer(props);
        props.getStockTransferCount && props.getStockTransferCount(props);
        props.popModalFromStack();
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      });
  };
};

export const createStockTransferDraft = (payload, history) => {
  // const props = payload.props;
  // return dispatch => {
  //   // const stockTranfer = _getGitData(payload);
  //   // const data = {
  //   //   gitDraftId: props.stockTranferDraftId || 0,
  //   //   gitData: stockTranfer,
  //   //   relationshipId: stockTranfer.relationshipId,
  //   // }
  // //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  // //   return axios
  // //     .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/saveOrUpdateSODraft`, data)
  // //     .then(res => {
  // //       showToasterMessage({ messageType: 'success', description: 'Sales order saved in draft' })
  // //       ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
  // //       props.history.push({
  // //         pathname: '/admin/so-list',
  // //         state: { activeTab: 'Draft' }
  // //       })
  // //     })
  // //     .catch((err) => {
  // //       ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
  // //       if (!err.__isRetryRequest) {
  // //         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
  // //       }
  // //     })
  // }
};

const _getGitData = (payloadObj) => {
  const props = payloadObj.props;
  const agGridObj = payloadObj.agGridObj;
  const companyInfo = props.companyInfo || {};
  const financialYear = getFinancialYear(companyInfo);
  const payload = {
    version: props.update ? props.version : 0,
    gitMasterId: props.gitMasterId,
    sourceWarehouseMasterId: props.selectedSourceWarehouse,
    destinationWarehouseMasterId: props.selectedDestinationWarehouse,
    // gitNumber: 0,
    gitDate: formatDateForBackend(props.gitDate || new Date()),
    fyStartDate: formatDateForBackend(financialYear.fyStartDate),
    fyEndDate: formatDateForBackend(financialYear.fyEndDate),
    gitDueDate: formatDateForBackend(props.expiryDate),
    relationshipName: props.companyInfo.storeName,
    comments: "",
    placeOfSupply: props.selectedDestinationWarehouse.stateName,
    relationshipId: props.companyInfo.relationshipId,
    status: "Pending",
    statusColorForUICell: "#b96230",
    isActive: 1,
    createdByUserId: props.createdByUserId || props.userInfo?.userId,
    updatedByUserId: props.gitMasterId ? props.userInfo?.userId : 0,
    taxId: props.selectedRelationshipTaxIdentifications,

    boLocationGITList: [props.sourceWHAddress, props.destinationWHAddress],
    gitDetailsList: [],
    documentName: "",
    createdByEmpId: props.createdByEmpId || props.userInfo?.relationshipEmployeeId,
    // approverEmpId: 0,
    rejectionReason: "",
    isRejected: 0,
    gitCOATxList: [],
  };

  agGridObj.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    if (!data.itemId && !data.description && !data.qty && !data.rate) {
      return;
    }
    let variantId =
      data.selectedProductValue && data.selectedProductValue.length > 1
        ? data.selectedProductValue[1]
        : 0;
    let variantStockId =
      data.selectedProductValue && data.selectedProductValue.length === 3
        ? data.selectedProductValue[2]
        : 0;
    if (data.productObj && !data.productObj.hasVariant && !variantId) {
      variantId = data.productObj.inventoryItemVariantList
        ? data.productObj.inventoryItemVariantList[0].variantId
        : data.inventoryItemVariantId;
    }
    let product = {};
    let itemLevelCoaTxList = [];
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }

    // let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);

    payload.gitDetailsList.push({
      gitDetailsId: data.parentDetailsId,
      version: props.update ? data.version : 0,
      gitMasterId: 0,
      itemId: product.itemId,
      itemName: product.itemName,
      uomName: "",
      itemDeletedDuringUpdate: 0,
      sNo: index + 1,
      quantity: Number(data.qty),
      itemPurchasedPrice: "",
      relationshipId: companyInfo && companyInfo.relationshipId,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      gitDate: formatDateForBackend(props.gitDate || new Date()),
      gitCOATxList: [],

      itemPurchasedPrice: Number(data.rate),
      rowHeight: data.scrollHeight,
      description: data.description,
      inventoryItemVariantId: variantId,
      customerSalesOrderCOATxList: itemLevelCoaTxList,
      warehouse: data.comment,
      stockNumber: data.stockNumber,
      partNumber: data.partNumber,
      materialNumber: data.materialNumber,
      origin: data.origin,
      salesQuotationMasterId: props.salesQuotationMasterId,
      quotationNumber: props.quotationNumber,
      // salesOrderDetailsId: data.salesOrderDetailsId,
      // salesOrderMasterId: data.salesOrderMasterId,
      itemVariantStockId: variantStockId,
    });
  });
  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact };
    // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
    // additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    // additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.customer) {
    additionalInfo.customerId = props.customer && props.customer.customerId;
    // additionalInfo.gstNumber = props.customer.gstNumber;
    // additionalInfo.panNumber = props.customer.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
    additionalInfo.additionalInfoId = props.isSQConvertions
      ? 0
      : additionalInfo.additionalInfoId;
  }

  if (
    props.selectedCustomerTaxIdentifications &&
    props.selectedCustomerTaxIdentifications.length
  ) {
    let selectedCustomerTaxIdentifications = filter(
      props.customer.customerTaxIdentificationsList,
      (taxObj) => {
        return (
          props.selectedCustomerTaxIdentifications.indexOf(
            taxObj.customerTaxIdentificationId
          ) > -1
        );
      }
    );
    additionalInfo.taxIdentifications = JSON.stringify(
      selectedCustomerTaxIdentifications
    );
  }

  if (
    props.selectedRelationshipTaxIdentifications &&
    props.selectedRelationshipTaxIdentifications.length
  ) {
    let selectedRelTaxIdentifications = filter(
      props.allRelationshipTaxIdentificatins,
      (taxObj) => {
        return (
          props.selectedRelationshipTaxIdentifications.indexOf(
            taxObj.relationshipTaxIdentificationId
          ) > -1
        );
      }
    );
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(
      selectedRelTaxIdentifications
    );
  }
  if (!props.update) delete additionalInfo.additionalInfoId;
  payload.additionalInfoList = [additionalInfo];

  if (props.qualityCheckList && props.qualityCheckList.length) {
    let checkList = [];
    props.qualityCheckList.forEach((obj, index) => {
      obj.customerId = props.customer && props.customer.customerId;
      obj.sno = index;
      obj.relationshipId = companyInfo && companyInfo.relationshipId;
      if (!props.update) delete obj.qualityChecklistSOId;
      checkList.push(obj);
    });
    payload.qualityCheckList = checkList;
  }
  return payload;
};
