import axios from "axios";
import config from "../../../config/environmentConfig";
import { showToasterMessage } from "../../../utils";
import { COMMON_ACTIONS, COMMUNICATION_ACTION_LIST } from "../../../static/constants";
import moment from "moment-timezone";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");



export const fetchCampaign = (payload) => {
  let url = `${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/email/getCampaigns?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}`;
  // add filter
  if(payload.campaignName){ url = url + `&campaignName=${payload.campaignName}`;}
  if(payload.projectId){ url = url + `&projectId=${payload.projectId}`;}

  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios.get(url)
        .then(response => {
          const data = {
            pageNo: payload.pageNumber,
            list: response.data
          }
          dispatch({ type: COMMUNICATION_ACTION_LIST.CAMPAIGN_LIST, data });
        })
        .catch(err => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        })
        .finally(() => {
          dispatch({
            type: COMMON_ACTIONS.HIDE_LIST_LOADER,
          });
        })
    }
  }

  export const getCampaignCount = (payload) => {
    let url = `${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/email/countCampaigns?relationshipId=${payload.relationshipId}`;
    // add filter
    if(payload.campaignName){ url = url + `&campaignName=${payload.campaignName}`;}
    if(payload.projectId){ url = url + `&projectId=${payload.projectId}`;}
    return dispatch => {
      return axios.get(url)
  
        .then(response => {
          dispatch({ type: COMMUNICATION_ACTION_LIST.CAMPAIGN_COUNT, data: response.data });
  
        })
        .catch(err => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        })
    }
  }

  export const fetchgAggregatedReportByRid = (payload, props) => {
    return (dispatch) => {
      return axios
        .get(
          `${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/metrics/getAggregatedReportByRid?relationshipId=${payload.relationshipId}`)
        .then((res) => {
          dispatch({ type: COMMUNICATION_ACTION_LIST.BREVO_EMAIL_AGGREGATE_REPORT, data: res.data });
        })
        .catch((err) => {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        });
    };
  };

  export const fetchCampaignEmailStatistics = (payload, props) => {
    const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
    const back30Date = moment().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss');

    return (dispatch) => {
      return axios
        .get(
          `${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/statistics/getByCampaign?campaignId=${payload.campaignId}&startDate=${payload.startDate || back30Date}&endDate=${payload.endDate || currentDate}`)
        .then((res) => {
          const data = {[payload.campaignId]: res.data?.data || {}}
          dispatch({ type: COMMUNICATION_ACTION_LIST.CAMPAIGN_EMAIL_STATISTICS, data: data });
        })
        .catch((err) => {
          // showToasterMessage({
          //   description:
          //     lang[((err.response || {}).data || "")] ||
          //     "Some error occurred",
          // });
        })
        .finally(()=>{

        })
        
    };
  };


















  export const refreshCampaignEmailData = (payload, props) => {
    let url = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextBrevo}/metrics/getCampaignEmailMetrics?relationshipId=${payload.relationshipId}&campaignId=${payload.campaignId}`;
    // add filter
    if(payload.email){ url = url + `&email=${payload.email}`;}
    props.updateState({loadingCampaignEmailMetricId: payload.campaignId})
    return dispatch => {
        return axios.get(url)
          .then(response => {
            dispatch({ type: COMMUNICATION_ACTION_LIST.CAMPAIGN_EMAIL_METRICS_DETAILS, data: response.data });
          })
          .catch(err => {
            // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          })
          .finally(() => {
            props.updateState({loadingCampaignEmailMetricId: 0})
          })
      }
    }

  export const fetchTotalMailCampaignCount = (payload, props) => {
    return (dispatch) => {
    
      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextBrevo}/metrics/countEmailMetricsByStatuses?relationshipId=${payload.relationshipId}`,["opened"])
        .then((res) => {
        
          
            dispatch({ type: COMMUNICATION_ACTION_LIST.TOTAL_MAIL_CAMPAIGN_COUNT, data: res.data });
        })
        .catch((err) => {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        });
    };
  };
  
  export const fetchFailedStatusCount = (payload, props) => {
    return (dispatch) => {
    
      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextBrevo}/metrics/countEmailMetricsByStatuses?relationshipId=${payload.relationshipId}`,["Error"])
        .then((res) => {           
            dispatch({ type: COMMUNICATION_ACTION_LIST.FAILED_STATUS_COUNT, data: res.data });
        })
        .catch((err) => {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        });
    };
  };

  export const fetchSendStatusCount = (payload, props) => {
    return (dispatch) => {
    
      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextBrevo}/metrics/countEmailMetricsByStatuses?relationshipId=${payload.relationshipId}`,["sent"])
        .then((res) => {        
            dispatch({ type: COMMUNICATION_ACTION_LIST.SEND_STATUS_COUNT, data: res.data });
        })
        .catch((err) => {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        });
    };
  };

  export const fetchTotalSuccessCount = (payload, props) => {
    return (dispatch) => {
    
      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextBrevo}/metrics/countEmailMetricsByStatuses?relationshipId=${payload.relationshipId}`,["delivered"])
        .then((res) => {
        
          
            dispatch({ type: COMMUNICATION_ACTION_LIST.DELIVERED_STATUS_COUNT, data: res.data });
        })
        .catch((err) => {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        });
    };
  };
