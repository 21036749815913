import axios from "axios";
import config from "../../../config/environmentConfig";
import { SMS_ACTION_LIST, COMMON_ACTIONS, COMMUNICATION_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const getCustomerCommunication = (payload) => {
    return (dispatch) => {
      dispatch({type: COMMON_ACTIONS.SHOW_LIST_LOADER})
        return axios.get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerCommunication/getAllCommunications?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&empId=${payload.relationshipEmployeeId}`)
          .then((res) => {
            const data = {
              pageNo: payload.pageNumber,
              list: res.data
            }
            dispatch({
              type: SMS_ACTION_LIST.SCHOOL_COMMUNICATION_LIST,
              data: data,
            });
          })
          .catch((err) => {
            showToasterMessage({
              messageType: "error",
              description:
                  lang[((err.response || {}).data || "")] ||
                  "Some error occurred",
            });
          })
          .finally(()=>{
            dispatch({type: COMMON_ACTIONS.HIDE_LIST_LOADER})
          })
    };
};



export const getCustomerCommunicationCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerCommunication/countAllCommunications?relationshipId=${payload.relationshipId}&empId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.SCHOOL_COMMUNICATION_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      });
  };
};

export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.SCHOOL_COMMUNICATION_LIST_PAGE_RESET });
  };
};


export const getCustomerEmailCommunication = (payload) => {
  return (dispatch) => {
    dispatch({type: COMMON_ACTIONS.SHOW_LIST_LOADER})
      return axios.get(`${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/customerCommunication/getEmailCommunications?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&direction=DESC&sortBy=communicationDate`)
        .then((res) => {
          dispatch({
            type: COMMUNICATION_ACTION_LIST.CUSTOMER_EMAIL_COMMUNICATIONS,
            data: res.data,
          });
        })
        .catch((err) => {
          showToasterMessage({
            messageType: "error",
            description:
                lang[((err.response || {}).data || "")] ||
                "Some error occurred",
          });
        })
        .finally(()=>{
          dispatch({type: COMMON_ACTIONS.HIDE_LIST_LOADER})
        })
  };
};

export const getSupplierEmailCommunication = (payload) => {
  return (dispatch) => {
    dispatch({type: COMMON_ACTIONS.SHOW_LIST_LOADER})
      return axios.get(`${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/supplierCommunication/getEmailCommunications?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
        .then((res) => {
          dispatch({
            type: COMMUNICATION_ACTION_LIST.SUPPLIER_EMAIL_COMMUNICATIONS,
            data: res.data,
          });
        })
        .catch((err) => {
          showToasterMessage({
            messageType: "error",
            description:
                lang[((err.response || {}).data || "")] ||
                "Some error occurred",
          });
        })
        .finally(()=>{
          dispatch({type: COMMON_ACTIONS.HIDE_LIST_LOADER})
        })
  };
};