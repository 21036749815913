import axios from "axios";
import config from "../../../../../config/environmentConfig"
import { showToasterMessage } from "../../../../../utils";
import { COMMON_ACTIONS, E_STORE } from "../../../../../static/constants";

export const getAllCoupons = (payload) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
        return axios.get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/coupon/getCoupons?rid=${payload.relationshipId}&pageNumber=${(payload.pageNumber || 1)-1}&pageSize=${payload.pageSize || 25}`)
            .then((res) => {
                let data = {
                    list: res.data,
                    pageNumber: payload.pageNumber || 1
                };
                dispatch({ type: E_STORE.GET_COUPON_LIST, data });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description: "An error occured please try again!"
                    });
                    dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                }
            })
            .finally(()=>{
                dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
            });
    };
};

export const getAllCouponsCount = (payload, props) => {
    return dispatch => {
    return axios
        .get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/coupon/getCouponsCount?relationshipId=${payload.relationshipId}`)
        .then(res => {
            dispatch({ type: E_STORE.GET_COUPON_COUNT, data: res.data });
        })
        .catch((err) => {
            if(!err.__isRetryRequest){
                showToasterMessage({messageType: 'error',  description: 'Some error occurred' })
            }
        })
    }
}

export const updateCoupon = (payload, props) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
        return axios.post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/coupon/updateCoupon`, payload)
        .then((res) => {
            if(res && res.data) {
                const pay = {
                    relationshipId: res.data?.relationshipId,
                };
                // props.getAllCoupons(pay);
                showToasterMessage({
                    messageType: "success",
                    description: " data updated successfully.",
                });
                props.updateState({
                    createCouponDrawerVisible: false,
                });
            }
        })
        .catch((err) => {
            if (!err.__isRetryRequest) {
                showToasterMessage({
                    messageType: "error",
                    description: "An error occured please try again!"
                });
                dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            }
        })
        .finally(()=>{
            dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
        })
    };
};

export const deleteCoupon = (payload, props) => {
    return (dispatch) => {
        return axios.post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/coupon/deleteCoupon`, payload)
        .then((res) => {
            if(res && res.data) {
                const payload = {
                    relationshipId: res.data?.relationshipId,
                };
                showToasterMessage({
                    messageType: "success",
                    description: " data deleted successfully.",
                });
                props.getAllCoupons(payload);
                props.updateState({
                    createCouponDrawerVisible: false,
                });
            }
        }).catch((err) => {
            if (!err.__isRetryRequest) {
                showToasterMessage({
                    messageType: "error",
                    description: "An error occured please try again!"
                });
                dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            }
        });
    };
};

export const createCoupon = (payload, props) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
        return axios.put(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/coupon/createCoupon`, payload, {
            headers: {
                'content-type': 'application/json'
            }
        }).then((res) => {
            if(res && res.data) {
                props.updateDrawerState({
                    createCouponDrawerVisible: false,
                });
                const pay ={
                    relationshipId: res.data?.relationshipId,
                }
                props.getAllCoupons(pay);
                showToasterMessage({
                    messageType: "success",
                    description: "Coupon saved successfully.",
                });
            } else {
               throw new Error();
            }
        })
        .catch((err) => {
            showToasterMessage({
                messageType: "error",
                description: " Error occured",
            });
        })
        .finally(()=>{
            dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
        })
    };
};

export const getAllCouponUsage = (payload) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
        return axios.get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/coupon/getAllCouponUsage?rid=${payload.relationshipId}&pageNumber=${(payload.pageNumber || 1)-1}&pageSize=${payload.pageSize || 25}`)
            .then((res) => {
                let data = {
                    list: res.data,
                    pageNumber: payload.pageNumber || 1
                };
                dispatch({ type: E_STORE.COUPON_USAGE_LIST, data });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description: "An error occured please try again!"
                    });
                }
            })
            .finally(()=>{
                dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
            });
    };
};

export const getCouponUsageCount = (payload, props) => {
    return dispatch => {
    return axios
        .get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/coupon/getCouponUsageCount?relationshipId=${payload.relationshipId}`)
        .then(res => {
            dispatch({ type: E_STORE.COUPON_USAGE_COUNT, data: res.data });
        })
        .catch((err) => {
        if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: 'Some error occurred' })
        }
        })
    }
}


export const applieCoupon = (payload,callback) => {
    const requestObj ={
        relationshipId:payload.relationshipId,
        customerId:payload.customerId,
        couponCode:payload.couponCode
      }
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/coupon/isValidCoupon`, requestObj)
        .then(res => {

         if(callback && res?.data?.isValid){
            callback(res?.data);
         }

          if(res?.data?.isValid){

           
          }else{
            // showToasterMessage({
            //     description: 'Invalid Coupon'
            //   })
          }
        })
        .catch((err = {}) => {
        
        
        
            showToasterMessage({
              description: 'Invalid Coupon'
            })
      
        })
    }
  }