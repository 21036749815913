import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Skeleton } from 'antd';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';
import { CONSTANTS } from '../../../../../static/constants';
import config from '../../../../../config/environmentConfig';
import { base64Toblob, getAWSFileURL, fixedDecimalNumber, getMomentDateForUIReadOnly, numberToWordsWithDecimals, getCurrencySymbol, capitalizeFirstLetter, fixedDecimalQty } from '../../../../../utils';
import { ImageCropper } from '../../../../../components/general/ImageCropper';
import { CustomUploader } from "../../../../../components/general/CustomUploader";
// import { LogoComponent } from '../../../../general/LogoComponent';
import { AWSFileTable } from '../../../../general/AWSFileTable';
import SODetail from '../../../../../containers/modal/modalBody/customer/SODetail';
import AmendSODetail from '../../../../../containers/modal/modalBody/customer/AmendSoDetails';


const Template2 = (props) => {
    const { soDetail, companyInfo } = props;

    const { customerSalesOrderDetailsList } = soDetail;
    const relationshipAddress = find(soDetail.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(soDetail.boLocationSOList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(soDetail.boLocationSOList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    const cancelItem  = JSON.parse(soDetail.cancelOrderData || "[]");
    const cancelItemList = cancelItem?.customerSalesOrderDetailsListCanceled;
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (relationshipAddress.stateName === (soDetail.placeOfSupply || shippingAddress.stateName)) || false;
    let itemLevelTaxes = [];
    let paymentMadeByCreditNote = (soDetail.customerSOPaymentList || [])
    ?.filter((item) => item.paymentSource === "creditMemo")
    ?.reduce((acc, val) => acc + val.paymentAmount, 0);
    let isPaid = (soDetail.customerSOPaymentList || [])?.reduce((acc, val) => acc + val.paymentAmount, 0) === soDetail?.salesOrderTotalAmount ? "Paid":"Unpaid";
    
    let rrItem = JSON.parse(soDetail.rrItem||"[]")?.map(item=>item.inventoryItemVariantId);
    if (customerSalesOrderDetailsList) {
        itemLevelTaxes = calculateTaxRateList(customerSalesOrderDetailsList, isPlaceOfSupplySameAsRelationship,soDetail,props);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true,
        comment: true
    };
    if (soDetail) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, soDetail, props);
    }

    const [showComp, setShowComp] = useState(false);
    useEffect(() => {
        const delay = setTimeout(() => {
            setShowComp(true);
        }, 400);
        return () => clearTimeout(delay);
    }, []);

    const customProps = {
        type: CONSTANTS.TYPE_IMAGE,
        imgUrl: getAWSFileURL((soDetail || {}).stampAwsKey, config.BUCKET_NAME.BO_SALES_ORDER) || '',
        isSignature: true,
        height: 60,
        width: 60,
        name: 'file',
        action: null,
        buttonName: 'Upload Customer PO',
        onChange: (file) => {

            if (file[0]?.originFileObj) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    props.showModal({
                        title: <Fragment>
                            <div className='ant-modal-title'>Crop Image</div>
                        </Fragment>,
                        //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
                        modalBody: <ImageCropper onClick={(e) => {
                        }} handleSubmit={(newUrl) => {
                            props.uploadStamp({
                                customerId: soDetail.customerId,
                                salesOrderMasterId: soDetail.salesOrderMasterId,
                                rid: soDetail.relationshipId,
                                objectKey: soDetail.stampAwsKey,
                                bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                                file: base64Toblob(newUrl)
                            })
                        }}{...props} url={e.target.result} />,
                        width: 800,
                        hideFooter: true,
                        wrapClassName: 'Upload-profile-modal'
                    })
                }
                reader.readAsDataURL(file[0]?.originFileObj); // convert to base64 string
            }
        }
    }


    const openSoDetails = (data) => {
        const soPayload = {
          customerId: data.customerId,
          salesOrderMasterId: data.salesOrderMasterId,
        };
        const payload = { relationshipId: companyInfo.relationshipId, customerId: data.customerId, salesOrderMasterId: data.counterOrderMasterId, gatewayName:'CardPointe' };
        props.pushModalToStack({
          modalBody: <SODetail {...props} soPayload={soPayload} />,
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
          onCancel:() => {
            props.fetchSODetail(payload);
                  
                 }
        });
      };

      const openAmendSoDetails = (data) => {
        const soPayload = {
          customerId: data.customerId,
          amendSalesOrderMasterId: data.amendSalesOrderMasterId,
        };
        const payload = { relationshipId: companyInfo.relationshipId, customerId: data.customerId, amendSalesOrderMasterId: data.amendSalesOrderMasterId, gatewayName:'CardPointe' };

        props.pushModalToStack({
          modalBody: <AmendSODetail {...props} soPayload={soPayload} />,
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
          onCancel:() => {
            props.fetchAmendSODetail(payload);
                  
                 }
        });
      };
    return (
        <div className={"custom-modal show"}>
            <i className="close-icon">
                <svg width="17" height="17" onClick={() => { props.popModalFromStack(); }}>
                    <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                    <path fill="#67757d" fillRule="evenodd"
                        d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
                </svg>
            </i>
            <div className="modal-dialog txn-modal-dialog">
                <div className="modal-content full-width vertical-view-data">
                    <div className="modal-header">

                    </div>
                    {showComp ?
                        <div id="so-readonly" className="inner-content">
                          
                            <div className="doc-title">
                                {soDetail.documentName || 'Sales Order'}
                            </div>
                            {/* {showComp ?  */}
                            <>
                                <Row>
                                    <Col span={8}>
                                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>
                                            <div className="read-only-content pt20">

                                                <div className="title lh-adjust underline-text pb5">
                                                    <FormattedMessage id='modal.txDetails.common.billTo' defaultMessage='' />
                                                </div>
                                                <div>

                                                    <div className='title'> {soDetail?.customerName}</div>
                                                 


                                                    <div>
                                                        {getAddressInfoFragment(billingAddress, (soDetail.additionalInfoList ? soDetail.additionalInfoList[0] || {} : {}))}
                                                        {getTaxIdentificationsFragment(((soDetail.additionalInfoList || [])[0] || {}).taxIdentifications)}
                                                    </div>
                                                {soDetail.additionalInfoList?.length && soDetail.additionalInfoList[0]?.customerEmail ?
                                                <>
                                                <div>
                                                    {(soDetail.additionalInfoList ? soDetail.additionalInfoList[0] || {} : {})?.customerPhone}
                                                    <br />
                                                    {(soDetail.additionalInfoList ? soDetail.additionalInfoList[0] || {} : {})?.customerEmail}
                                                </div>
                                                </>
                                                :""}
                                                </div>

                                                <div className="">
                                                 {   soDetail.customerPreOrderMasterId >0 ?
                                                 <>
                                                 <div className='title'> {billingAddress?.fullName}</div>
                                                    <div className='title'> {billingAddress?.emailAddress}</div>
                                                    <div className='title'> {billingAddress?.cellPhone}</div>
                                                 </>
                                                    :""}
                                                    {soDetail.customerPreOrderMasterId >0 ?"": getContactNameFragment((soDetail.additionalInfoList ? soDetail.additionalInfoList[0] || {} : {}))}
                                                </div>
                                           
                                            </div>
                                            {shippingAddress.streetAddress1 ?
                                                <div className="read-only-content pt10">
                                                    <div className="title lh-adjust underline-text pb5">
                                                        <FormattedMessage id='modal.txDetails.common.shipTo' defaultMessage='' />
                                                    </div>
                                                    <div className="title">
                                                 {shippingAddress?.fullName}
                                                      
                                                    </div>
                                                
                                                    <div>
                                                        {getAddressInfoFragment(shippingAddress, {})}
                                                    </div>
                                                    <div>
                                                    <div className="title">
                                                 {shippingAddress?.emailAddress}
                                                      
                                                    </div> <div className="title">
                                                 {shippingAddress?.cellPhone}
                                                      
                                                    </div>
                                                    </div>
                                              
                                                </div> : ''}
                                        </Skeleton>
                                    </Col>
                                    <Col offset={8} span={4}>
                                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>

                                            <div className="read-only-content pt20">
                                                <div className="pb5">
                                                    <div className="title">
                                                        <FormattedMessage id='modal.txDetails.salesOrder.soNumber' defaultMessage='' />
                                                    </div>

                                                    <div>
                                                        {soDetail.soNumber}
                                                    </div>
                                                </div>

                                                <div className="pb5">
                                                    <div className="title">
                                                        <FormattedMessage id='modal.txDetails.salesOrder.soDate' defaultMessage='' />
                                                    </div>

                                                    <div>
                                                        {(soDetail.salesOrderDate && getMomentDateForUIReadOnly({ date: soDetail.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                    </div>
                                                </div>
                                                {!!soDetail.isMultiCurrency ?
                                                    <div className="pb5">
                                                        <div className="title">
                                                            <FormattedMessage id='modal.txDetails.common.foreignCurrency' defaultMessage='' />
                                                        </div>

                                                        <div>
                                                            {soDetail.foreignCurrency}
                                                        </div>
                                                    </div> : ""}

                                                {!!soDetail.isMultiCurrency ?
                                                    <div className="pb5">
                                                        <div className="title">
                                                            <FormattedMessage id='modal.txDetails.common.exchangeRate' defaultMessage='' />
                                                        </div>

                                                        <div>
                                                            {soDetail.exchangeRate}
                                                        </div>
                                                    </div> : ""}
                                                {soDetail.projectName ?
                                                    <div className="pb5">
                                                        <div className="title">
                                                            <FormattedMessage id='modal.txDetails.common.project' defaultMessage='' />
                                                        </div>
                                                        <div>
                                                            {/* {soDetail.projectMasterId}- */}
                                                            <abbr>{soDetail.projectName}</abbr>
                                                        </div>
                                                    </div> : ''}
                                                {soDetail.deliveryDate ?
                                                    <div className="pb5">
                                                        <div className="title">
                                                            <FormattedMessage id='modal.txDetails.salesOrder.deliveryDate' defaultMessage='' />
                                                        </div>
                                                        <div>
                                                            {(soDetail.deliveryDate && getMomentDateForUIReadOnly({ date: soDetail.deliveryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                        </div>
                                                    </div> : ""}
                                                    {soDetail.asoNumber ?
                                                    <div className="pb5">
                                                        <div className="title">
                                                            Replace/Return Order #
                                                        </div>
                                                        <div  className='cursor-pointer' onClick={()=>{
                                                     openAmendSoDetails(soDetail);
                                                        }}>
                                                            {soDetail.asoNumber}
                                                        </div>
                                                    </div> : ""}
                                                    {soDetail.counterSoNumber ?
                                                    <div className="pb5">
                                                        <div className="title">
                                                            Replacement Order #
                                                        </div>
                                                        <div  className='cursor-pointer' onClick={()=>{
                                                            openSoDetails(soDetail);
                                                        }}>
                                                            {(soDetail.counterSoNumber)}
                                                        </div>
                                                    </div> : ""}
                                    
                                                    { <div className="pb5">
                                                        <div className="title">
                                                           Payment Status
                                                        </div>

                                                        <div>
                                                            {isPaid}
                                                        </div>
                                                    </div>}
 
                                                {soDetail.userStatus ?
                                                    <div className="pb5">
                                                        <div className="title">
                                                            <FormattedMessage id='modal.txDetails.salesOrder.userStatus' defaultMessage='' />
                                                        </div>
                                                        <div>
                                                            <abbr>{soDetail.userStatus}</abbr>
                                                        </div>
                                                    </div> : ""}{soDetail.incotermName ?
                                                        <div className="pb5">
                                                            <div className="title">
                                                                <FormattedMessage id='package.packageDetail.incoterm' defaultMessage='' />
                                                            </div>
                                                            <div>
                                                                <abbr>{soDetail.incotermName}</abbr>
                                                            </div>
                                                        </div> : ""}


                                                {(props.qualityChecklistSetting.isQualityChecklistRequired || (soDetail.qualityCheckList && soDetail.qualityCheckList.length)) ?
                                                    <div className="pb5">
                                                        <div className="title">
                                                            <FormattedMessage id='modal.txDetails.salesOrder.qualityCheck' defaultMessage='' />
                                                        </div>
                                                        <div className="cursor-pointer">
                                                            <abbr onClick={() => {
                                                                props.updateState({
                                                                    title: <div className="drawer-title"><FormattedMessage id='modal.txDetails.salesOrder.qualityCheck' defaultMessage='' /></div>,
                                                                    qualityCheckList: soDetail.qualityCheckList,
                                                                    customerId: soDetail.customerId,
                                                                    soMasterId: soDetail.salesOrderMasterId,
                                                                    qualityCheckAction: "add",
                                                                    qualityCheckDrawerVisible: true,
                                                                    addQualityCheckToSO: function (qualityCheckList, qualityCheckStatus) {
                                                                        props.updateState({
                                                                            qualityCheckList: qualityCheckList,
                                                                            qualityCheckStatus: qualityCheckStatus
                                                                        });
                                                                        let list = qualityCheckList.map(obj => {
                                                                            obj.customerId = soDetail.customerId;
                                                                            obj.salesOrderMasterId = soDetail.salesOrderMasterId;
                                                                            obj.relationshipId = soDetail.relationshipId;
                                                                            return obj;
                                                                        });
                                                                        props.updateQualityCheckList(list, props);
                                                                        props.popModalFromStack();
                                                                    }
                                                                });
                                                            }}>{(!soDetail.qualityCheckList || soDetail.qualityCheckList.length === 0) ? 'Add Quality Check' : soDetail.qualityCheckList.length}</abbr>
                                                        </div>
                                                    </div> : ''}


                                            </div>
                                        </Skeleton>
                                    </Col>
                                    <Col span={4}>
                                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                                            <div className="d-inline-flex w-100">
                                                <div className="read-only-content pt20 pl10">
                                                    <div className="title lh-adjust pb5">
                                                        {props.companyInfo.storeName}
                                                    </div>
                                                    <div>
                                                        {getAddressInfoFragment(relationshipAddress, companyInfo)}
                                                        <div>
                                                            {getTaxIdentificationsFragment(((soDetail.additionalInfoList || [])[0] || {}).relationshipTaxIdentifications)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </Skeleton>
                                    </Col>
                                </Row>
                                <Skeleton loading={props.detailLoading} paragraph={{ rows: 7 }} active>

                                { cancelItemList?.length ?
                                       <>
                                    Canceled Item
                                          <div className="modal-table-container mb15">
                                          <table className="transaction-details-table">
                                              <thead>
                                                  <tr>
                                                      <th className="cell-size-50">#</th>
                                                      {(!isColumnEmpty.product || !isColumnEmpty.description) && <th>
                                                          {!isColumnEmpty.product && <span> <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                                          {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                                          {!isColumnEmpty.description && <span> <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                                      </th>}
                                                      {!isColumnEmpty.qty && <th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                                      {!isColumnEmpty.specialDiscount && <th className="text-center"><FormattedMessage id='modal.txDetails.common.basePrice' defaultMessage='' /></th>}
                                                      {!isColumnEmpty.specialDiscount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.specialDiscount' defaultMessage='' /></th>}
                                                      {!isColumnEmpty.rate && <th className="text-center"><FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' /></th>}
                                                      {!isColumnEmpty.hsn && <th className="text-center"><FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></th>}
                                                      {!isColumnEmpty.discount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' /></th>}
                                                      {!isColumnEmpty.tax && <th className="text-right"><FormattedMessage id='modal.txDetails.common.tax' defaultMessage='' /></th>}
                                                      <th className="text-right">Taxable Amount</th>
                                                      <th className="text-right"><FormattedMessage id='modal.txDetails.common.netAmount' defaultMessage='' /></th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {(cancelItemList || []).sort((a, b) => Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                                      return (
                                                          <tr key={i}>
                                                              <td className="cell-size-50">{i + 1}</td>
                                                              {(!isColumnEmpty.product || !isColumnEmpty.description) && <td>
                                                                  <strong>{getVariantFragment(item)}</strong>
                                                                  {!isColumnEmpty.description && <div className="description-cell">
                                                                      {getDescriptionFragment(soDetail, item, i + 1)}
                                                                  </div>}
                                                              </td>}
                                                              {!isColumnEmpty.qty && <td className="text-center">{fixedDecimalQty(item.quantityToInvoice) + (item.uomName ? ' ' + item.uomName : '')}</td>}
                                                              {!isColumnEmpty.specialDiscount && <td className="text-center">{fixedDecimalNumber(item.basePrice || 0) || '-'}</td>}
                                                              {!isColumnEmpty.specialDiscount && <td className="text-right">{getSpecialDiscount(item, 'amountPercent')}</td>}
                                                              {!isColumnEmpty.rate && <td className="text-center">{fixedDecimalNumber(item.anItemSalePrice || 0) || '-'}</td>}
                                                              {!isColumnEmpty.hsn && <td className="text-center">{item.hsnCode || '-'}</td>}
                                                              {!isColumnEmpty.discount && <td className="text-right">{getTxTypeData(item, 'discount', 'amount')} {getTxTypeData(item, 'discount', 'amountPercent')}</td>}
                                                              {!isColumnEmpty.tax && <td className="text-right">{getTotalTaxAmount(item, isPlaceOfSupplySameAsRelationship)} {getTotalTaxPercent(item, 'amountPercent', isPlaceOfSupplySameAsRelationship)}</td>}
                
                                                              <td className="text-right">{getNetAmount(item, isPlaceOfSupplySameAsRelationship, true) || "-"}</td>
                                                              <td className="text-right">{getNetAmount(item, isPlaceOfSupplySameAsRelationship) || '-'}</td>
                                                          </tr>
                                                      );
                                                  })}
                                              </tbody>
                                          </table>
                                         
                                      </div>
                                      </>:""}

                                    <div className="modal-table-container vertical-space">
                                        <table>
                                            <thead>
                                                <tr>
                                                    {props.companyInfo.countryName === 'India' && <th><FormattedMessage id='modal.txDetails.common.placeOfSupply' defaultMessage='' /></th>}
                                                    <th><FormattedMessage id='modal.txDetails.salesOrder.soSource' defaultMessage='' /></th>
                                                    <th><FormattedMessage id='modal.txDetails.salesOrder.customerPO' defaultMessage='' /></th>
                                                    <th><FormattedMessage id='modal.txDetails.common.refNumber' defaultMessage='' /></th>
                                                    <th><FormattedMessage id='customerRfqNumber.text' /></th>
                                                    <th><FormattedMessage id='customerSalesEnquiryNumber.text' /></th>
                                                    <th><FormattedMessage id='common.salesPerson' defaultMessage='' /></th>
                                                    <th><FormattedMessage id='modal.txDetails.common.priority' defaultMessage='' /></th>
                                                    <th><FormattedMessage id='modal.txDetails.common.paymentTerm' defaultMessage='' /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {props.companyInfo.countryName === 'India' && <td>{soDetail.placeOfSupply || '-'}</td>}
                                                    <td>{soDetail.soSourceName || '-'}</td>
                                                    <td>{soDetail.customerPONumber || '-'}</td>
                                                    <td>{soDetail.referenceNumber || '-'}</td>
                                                    <td>{soDetail.customerRFQNumber || ''}</td>
                                                    <td>{soDetail.customerInquiryNumber || '-'}</td>
                                                    <td>{soDetail.soPersonFullName || ''}</td>
                                                    <td>{soDetail.orderPriority || ''}</td>
                                                    <td>{soDetail.paymentTermName || ''}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="modal-table-container mb15">
                                        <table className="transaction-details-table">
                                            <thead>
                                                <tr>
                                                    <th className="cell-size-50">#</th>
                                                    {(!isColumnEmpty.product || !isColumnEmpty.description) && <th>
                                                        {!isColumnEmpty.product && <span> <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                                        {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                                        {!isColumnEmpty.description && <span> <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                                    </th>}
                                                    {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                                                    {!isColumnEmpty.qty && <th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                                    {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                                                    {!isColumnEmpty.specialDiscount && <th className="text-center"><FormattedMessage id='modal.txDetails.common.basePrice' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.specialDiscount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.specialDiscount' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.rate && <th className="text-center"><FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.hsn && <th className="text-center"><FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.discount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.tax && <th className="text-right"><FormattedMessage id='modal.txDetails.common.tax' defaultMessage='' /></th>}
                                                    {/* {!isColumnEmpty.comment && <th>Warehouse</th>} */}
                                                    <th className="text-right">Taxable Amount</th>
                                                    <th className="text-right"><FormattedMessage id='modal.txDetails.common.netAmount' defaultMessage='' /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(customerSalesOrderDetailsList || []).sort((a, b) => Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="cell-size-50">{i + 1}</td>
                                                            {(!isColumnEmpty.product || !isColumnEmpty.description) && <td>
                                                                <strong>{getVariantFragment(item,rrItem)}</strong>
                                                                {!isColumnEmpty.description && <div className="description-cell">
                                                                    {getDescriptionFragment(soDetail, item, i + 1)}
                                                                </div>}
                                                            </td>}
                                                            {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                                                            {!isColumnEmpty.qty && <td className="text-center">{fixedDecimalQty(item.quantityToInvoice) + (item.uomName ? ' ' + item.uomName : '')}</td>}
                                                            {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                                                            {!isColumnEmpty.specialDiscount && <td className="text-center">{fixedDecimalNumber(item.basePrice || 0) || '-'}</td>}
                                                            {!isColumnEmpty.specialDiscount && <td className="text-right">{getSpecialDiscount(item, 'amountPercent')}</td>}
                                                            {!isColumnEmpty.rate && <td className="text-center">{fixedDecimalNumber(item.anItemSalePrice || 0) || '-'}</td>}
                                                            {!isColumnEmpty.hsn && <td className="text-center">{item.hsnCode || '-'}</td>}
                                                            {!isColumnEmpty.discount && <td className="text-right">{getTxTypeData(item, 'discount', 'amount')} {getTxTypeData(item, 'discount', 'amountPercent')}</td>}
                                                            {!isColumnEmpty.tax && <td className="text-right">{getTotalTaxAmount(item, isPlaceOfSupplySameAsRelationship)} {getTotalTaxPercent(item, 'amountPercent', isPlaceOfSupplySameAsRelationship)}</td>}
                                                            {/* {!isColumnEmpty.comment && <td>
                <div className="description-cell">
                    <pre>{ReactHtmlParser(item.comment || '-')}</pre>
                </div>
            </td>} */}
                                                            <td className="text-right">{getNetAmount(item, isPlaceOfSupplySameAsRelationship, true) || "-"}</td>
                                                            <td className="text-right">{getNetAmount(item, isPlaceOfSupplySameAsRelationship) || '-'}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <Col span={24} className="">
                                            {soDetail.currencyCode ? (
                                                <span>
                                                    {" "}
                                                    {soDetail.currencyCode ? (
                                                        soDetail.currencyCode
                                                    ) : (
                                                        soDetail.currencyCode
                                                    )}{" "}
                                                </span>
                                            ) : (
                                                <span>
                                                    {" "}
                                                    {props.companyInfo.currencyCode ? (
                                                        <i className={props.companyInfo.currencyCode}></i>
                                                    ) : (
                                                        props.companyInfo.currencyCode + " "
                                                    )}{" "}
                                                </span>
                                            )}
                                            {(soDetail.salesOrderTotalAmount &&
                                                numberToWordsWithDecimals(fixedDecimalNumber(soDetail.salesOrderTotalAmount), props.companyInfo.currencyCode === "INR" ? "Paise" : "Cent")) ||
                                                ""}
                                        </Col>
                                    </div>

                                   

                                    <Row>
                                        <Col span={9} className="read-only-content">
                                            {soDetail.docDetailList && soDetail.docDetailList.length ? <Row>
                                                <Col span={24} className="title">
                                                    <FormattedMessage id='modal.txDetails.common.attachments' defaultMessage='' />
                                                </Col>
                                                <Col span={12}>
                                                    <AWSFileTable {...props} docDetailList={soDetail.docDetailList} fetchTxDetail={(data) => {
                                                        props.fetchSODetail({
                                                            customerId: soDetail.customerId,
                                                            salesOrderMasterId: soDetail.salesOrderMasterId,
                                                            soMasterId: soDetail.salesOrderMasterId,
                                                            relationshipId: soDetail.relationshipId,
                                                        });
                                                    }} />
                                                    {/* {
            soDetail.docDetailList.map((doc) => {
                return <span className={"cursor-pointer"} onClick={()=>{
                    props.downloadFileFromBucket({
                        bucketName: doc.bucketName,
                        awsKeyName: doc.awsKeyName,
                        fileName: doc.fileName
                    })
                }}>{doc.fileName} &nbsp;</span>
            })
        } */}
                                                </Col>
                                            </Row> : null}


                                            {soDetail.remarksInternal &&
                                                <Row>
                                                    <Col span={24} className="title">
                                                        <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage='' />
                                                    </Col>
                                                    <Col span={24}>
                                                        {ReactHtmlParser(soDetail.remarksInternal)}
                                                    </Col>
                                                </Row>}

                                            {soDetail.remarksCustomer &&
                                                <Row className="pt5">
                                                    <Col span={24} className="title">
                                                        <FormattedMessage id='modal.txDetails.common.customerRemarks' defaultMessage='' />
                                                    </Col>
                                                    <Col span={24}>
                                                        {ReactHtmlParser(soDetail.remarksCustomer)}
                                                    </Col>
                                                </Row>}
                                        </Col>

                                        <Col offset={8} span={7}>
                                            <div className="bill-summary mt5">
                                                <Row>
                                                    <Col span={14}>
                                                        <div className="right-txt">
                                                            <FormattedMessage id='modal.txDetails.common.subTotal' defaultMessage='' />
                                                        </div>
                                                    </Col>

                                                    <Col span={10} className="text-right ">
                                                        {soDetail.isMultiCurrency ?
                                                            <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                            : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                        {getSubTotalOrTaxOrDiscountAmount(soDetail, "SubTotal")}
                                                    </Col>
                                                </Row>



                                                {getTxTypeData(soDetail, 'Coupon', 'amount') ? <div className="pt10">
                                                    <Row>
                                                        <Col span={14}>
                                                            <div className="right-txt">
                                                                <FormattedMessage id='modal.txDetails.common.coupon' defaultMessage='' />
                                                            </div>
                                                        </Col>

                                                        <Col span={10} className="text-right ">
                                                            {soDetail.isMultiCurrency ?
                                                                <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                            {getTxTypeData(soDetail, 'Coupon', 'amount')}
                                                        </Col>
                                                    </Row>
                                                </div>:""}

                                                <div className="pt10">
                                                    <Row>
                                                        <Col span={14}>
                                                            <div className="right-txt">
                                                                <FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' />
                                                            </div>
                                                        </Col>

                                                        <Col span={10} className="text-right ">
                                                            {soDetail.isMultiCurrency ?
                                                                <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                            {getSubTotalOrTaxOrDiscountAmount(soDetail, "Discount")}
                                                        </Col>
                                                    </Row>
                                                </div>
                                                {
                                        (soDetail.customerSalesOrderCOATxList && soDetail.customerSalesOrderCOATxList.length) && (filter(soDetail.customerSalesOrderCOATxList || [],
                                            { txType: 'expense', accountingEntry: 'debit' }
                                        )).map((expenseObject, i) => {
                                            return (<div key={'exp' + i}>
                                                <Row className="pt10">
                                                    <Col span={14}>
                                                        <div className="right-txt"><FormattedMessage id='modal.txDetails.common.expense' defaultMessage='' />({(expenseObject.ledgerAccountName || '')})</div>
                                                    </Col>
                                                    <Col span={10} className="text-right">
                                                        {soDetail.isMultiCurrency ?
                                                            <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                            : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                        }
                                                        {fixedDecimalNumber(expenseObject.amount)}
                                                    </Col>
                                                </Row>
                                            </div>
                                            )
                                        })
                                    }


                                                {getTxTypeData(soDetail, 'expense', 'amount') ?
                                                    <div className="pt10">
                                                        <Row>
                                                            <Col span={14}>
                                                                <div className="right-txt">
                                                                <FormattedMessage id='total.text' defaultMessage='' />{" "}
                                                                    <FormattedMessage id='modal.txDetails.common.expense' defaultMessage='' />
                                                                </div>
                                                            </Col>

                                                            <Col span={10} className="text-right ">
                                                                {soDetail.isMultiCurrency ?
                                                                    <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                    : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                                {/* {getTxTypeData(soDetail, 'expense', 'amount')} */}
                                                                {getSubTotalOrTaxOrDiscountAmount(soDetail, "expense")}
                                                            </Col>
                                                        </Row>
                                                    </div> : ''}
                                                {(itemLevelTaxes && itemLevelTaxes.length !== 0) && itemLevelTaxes.map((taxObj, i) => {

                                                    return (
                                                        props.companyInfo.countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                            <div className="pt10">
                                                                <Row>
                                                                    <Col span={14}>
                                                                        <div className="right-txt">CGST @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                    </Col>
                                                                    <Col span={10} className="text-right ">
                                                                        {soDetail.isMultiCurrency ?
                                                                            <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                            : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                                        {fixedDecimalNumber(taxObj.taxAmount)}
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            <div className="pt10">
                                                                <Row>
                                                                    <Col span={14}>
                                                                        <div className="right-txt">SGST @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                    </Col>
                                                                    <Col span={10} className="text-right ">
                                                                        {soDetail.isMultiCurrency ?
                                                                            <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                            : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                                        {fixedDecimalNumber(taxObj.taxAmount)}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div> : <div key={i}>
                                                            <div className="pt10">
                                                                <Row>
                                                                    <Col span={14}>
                                                                        <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                    </Col>
                                                                    <Col span={10} className="text-right ">
                                                                        {soDetail.isMultiCurrency ?
                                                                            <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                            : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                                        {fixedDecimalNumber(taxObj.taxAmount)}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                        ) :
                                                            <div key={i}>
                                                                <div className="pt10">
                                                                    <Row>
                                                                        <Col span={14}>
                                                                            <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                        </Col>
                                                                        <Col span={10} className="text-right ">
                                                                            {soDetail.isMultiCurrency ?
                                                                                <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                                : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                                            {fixedDecimalNumber(taxObj.taxAmount)}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                    );
                                                })}

                                                {paymentMadeByCreditNote  ?
                                                    <div className="pt10">
                                                        <Row>
                                                            <Col span={14}>
                                                                <div className="right-txt">
                                                                Payment Made By Credit Note
                                                                </div>
                                                            </Col>

                                                            <Col span={10} className="text-right ">
                                                            <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>
                                                            {fixedDecimalNumber(paymentMadeByCreditNote || 0)}
                                                            </Col>
                                                        </Row>
                                                    </div> : ''}
                                                <div className="pt10">
                                                    <Row>
                                                        <Col span={14}>
                                                            <div className="right-txt bold">
                                                                <FormattedMessage id='modal.txDetails.common.grandTotal' defaultMessage='' />
                                                            </div>
                                                        </Col>

                                                        <Col span={10} className="text-right ">
                                                            {soDetail.isMultiCurrency ?
                                                                <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency} </span>
                                                                : <span> {props.companyInfo.currencyIcon ? getCurrencySymbol(props.companyInfo.currencyCode) : props.companyInfo.currencyCode + " "} </span>}
                                                            {(soDetail.salesOrderTotalAmount && fixedDecimalNumber(soDetail.salesOrderTotalAmount)) || ''}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="upload-link new-pic" title={props.intl.formatMessage({ id: 'uploadSignature.tooltip' })} style={{ width: '50px', height: '50px' }}>
                                                <CustomUploader {...customProps} />
                                                {soDetail.stampAwsKey && <div className="cursor-pointer" onClick={() => {
                                                    props.deleteStamp({
                                                        customerId: soDetail.customerId,
                                                        salesOrderMasterId: soDetail.salesOrderMasterId,

                                                        relationshipId: soDetail.relationshipId,
                                                        objectKey: soDetail.stampAwsKey,
                                                        folderName: soDetail?.folderName,
                                                        bucketName: props?.companyInfo?.bucketName,
                                                    }, props);
                                                }}> <FormattedMessage id='modal.txDetails.common.deleteStamp' defaultMessage='' /> </div>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="contact-details">
                                        {soDetail.footer &&
                                            <Fragment>
                                                {ReactHtmlParser(soDetail.footer)}
                                            </Fragment>}
                                    </div>
                                </Skeleton></>
                            {/* // :
                        // "" } */}
                        </div>
                        : ""}
                </div>
            </div>
        </div>
    );

}

const getSpecialDiscount = (row, toReturn) => {
    if (!row) return;
    let specialDiscount = row.basePrice - row.anItemSalePrice;
    if (specialDiscount && row.basePrice) {
        if (toReturn === 'amountPercent') {
            let percent = fixedDecimalNumber((specialDiscount * 100) / row.basePrice);
            let amount = fixedDecimalNumber(specialDiscount);
            return amount + " (" + percent + ")%";
        } else if (toReturn === 'amount') {
            return fixedDecimalNumber(specialDiscount);
        }

    } else if (!row && toReturn === 'amount') {
        return 0;
    }
    else {
        return "-";
    }
}


const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
    let total = 0;
    data && data.customerSalesOrderDetailsList && data.customerSalesOrderDetailsList.forEach(function (d) {
        if (type === 'SubTotal') {
            total = total + d.anItemSalePrice * d.quantityToInvoice;
        }
        if (type === 'Discount') {
            let discountObject = d.customerSalesOrderCOATxList.find(tx => (tx.txType === 'discount'));
            if (discountObject) {
                total = total + parseFloat(discountObject.amount);
            }
        }
    });
    if (type === 'Discount' && data && data.customerSalesOrderCOATxList) {
        let discount = data.customerSalesOrderCOATxList.find(tx => (tx.txType === 'discount' && tx.accountingEntry === 'credit'));
        if (discount) {
            total = total + parseFloat(discount.amount);
        }
    }
    if (type === 'expense') {
        data && data.customerSalesOrderCOATxList && data.customerSalesOrderCOATxList.forEach(function (discountObject) {
            if (discountObject.txType === 'expense' && discountObject.accountingEntry === 'debit') {
                total = total + parseFloat(discountObject.amount);
            }
        });
    }
    return fixedDecimalNumber(total);
}

const getTxTypeData = (row, type, toReturn) => {
    if (!row || !row.customerSalesOrderCOATxList) return;
    let data = row.customerSalesOrderCOATxList.find(tx => (tx.txType === type));
    if (data) {
        if (data[toReturn] && toReturn === 'amountPercent') {
            if (type === 'VAT') {
                return "VAT(" + fixedDecimalNumber(data[toReturn]) + "%)";
            }
            return "(" + data[toReturn] + "%)";
        } else if (data[toReturn] && toReturn === 'amount') {
            return (fixedDecimalNumber(data[toReturn]));
        }
        else if(toReturn === "coupon"){
            return data;

        }
         else {
            return ""
        }

    } else if (!data && toReturn === 'amount') {
        return "";
    }
    else {
        return "-";
    }
}

const getTotalTaxAmount = (row, isPlaceOfSupplySameAsRelationship) => {
    let tax1 = getTxTypeData(row, 'tax', 'amount', true) || 0;
    // let tax2 = getTxTypeData(row, 'sgst', 'amount', true) || 0;
    // let tax3 = getTxTypeData(row, 'igst', 'amount', true) || 0;
    //let tax2 = getTxTypeData(row, 'vat', 'amount', true) || 0;
    let totalTax = Number(tax1);
    if (isPlaceOfSupplySameAsRelationship) {

        return fixedDecimalNumber(totalTax * 2);
    } else {
        return fixedDecimalNumber(totalTax);

    }
}

const getTotalTaxPercent = (row, toReturn, isPlaceOfSupplySameAsRelationship) => {
    let tax1 = row.customerSalesOrderCOATxList.find(tx => (tx.txType === 'tax')) || {};
    // let tax2 = row.customerSalesOrderCOATxList.find(tx => (tx.txType === 'sgst')) || {};
    // let tax3 = row.customerSalesOrderCOATxList.find(tx => (tx.txType === 'igst')) || {};
    // let tax4 = row.customerSalesOrderCOATxList.find(tx => (tx.txType === 'vat')) || {};
    let totalPercent = Number(tax1.amountPercent || 0);
    if (toReturn === 'amountPercent') {
        if (isPlaceOfSupplySameAsRelationship) {

            return fixedDecimalNumber(totalPercent) ? ('(' + totalPercent * 2 + "%)") : '';
        } else {
            return fixedDecimalNumber(totalPercent) ? ('(' + totalPercent + "%)") : '';
        }
    }
    else {
        return isPlaceOfSupplySameAsRelationship ? totalPercent * 2 : totalPercent;
    }
}

// const getNetAmount = (row) => {
//     let tax = getTotalTaxAmount(row) || 0;
//     let discount = getTxTypeData(row, 'discount', 'amount', true) || 0;
//     let netAmountItem = (Number(row.quantityToInvoice * row.anItemSalePrice) + Number(tax) - Number(discount));
//     return fixedDecimalNumber(netAmountItem);

// }
const getNetAmount = (row, isPlaceOfSupplySameAsRelationship, withoutTax) => {
    let tax = getTotalTaxAmount(row) || 0;
    let taxValue = isPlaceOfSupplySameAsRelationship ? tax * 2 : tax
    let discount = getTxTypeData(row, "discount", "amount", true) || 0;
    let netAmountWithoutTax = Number(row.quantity * row.anItemSalePrice) - Number(discount);
    let netAmountItem = Number(row.quantity * row.anItemSalePrice) + Number(taxValue) - Number(discount);
    return withoutTax ? fixedDecimalNumber(netAmountWithoutTax) : fixedDecimalNumber(netAmountItem);
};

const calculateTaxRateList = (customerSalesOrderDetailsList, isPlaceOfSupplySameAsRelationship,soDetail,props) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerSalesOrderDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0
            }
            totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
            let discount = getTxTypeData(rowdata, 'discount', 'amount');
            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }
            
            // let tax2 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'sgst'));
            //let tax3 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'igst'));
            // let tax4 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'vat'));
            // if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
            //     taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
            //     taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            // else if (!isPlaceOfSupplySameAsRelationship && tax3) {
            //     taxObj.taxPercent = Number(tax3.amountPercent);
            //     taxObj.taxAmount = Number(tax3.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            // else if (tax4) {
            //     taxObj.taxPercent = Number(tax4.amountPercent);
            //     taxObj.taxAmount = Number(tax4.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            let couponData = getTxTypeData(soDetail, 'Coupon', 'coupon');
            let setting = JSON.parse(props.companyInfo.couponSetting || "{}");
           let tax1 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'tax')) || {};

           if(setting?.isCoupanAppliedBeforeTax && couponData){

               if(couponData?.amountPercent){
                totalAmountOfColumn = totalAmountOfColumn *(couponData?.amountPercent/100);

               }
           }
            taxObj.taxPercent = Number(tax1.amountPercent);
            taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount));
            taxObj.taxName = tax1.taxName;
            totalTax = totalTax + taxObj.taxAmount;
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                if (availableTaxObj) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.taxPercent === taxObj.taxPercent) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                    taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });

    return itemLevelTaxes;
}

const updateColumnEmptyCheck = (isColumnEmpty, soDetail, props) => {
    if (!soDetail) return isColumnEmpty;
    for (let i in soDetail.customerSalesOrderDetailsList) {
        let row = soDetail.customerSalesOrderDetailsList[i];
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantityToInvoice) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.anItemSalePrice) { isColumnEmpty.rate = false; }
        if (row.specialDiscount) { isColumnEmpty.specialDiscount = false; }
        if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
        if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
        if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
        // if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
        // if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
        //  if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
        if (row.documentDetails && row.documentDetails.length) { isColumnEmpty.documentDetails = false; }
        // if (row.comment) { isColumnEmpty.comment = false; }
    }
    return isColumnEmpty;
}

const isTxTypeEmpty = (row, type) => {
    if (!row) return true;
    let data = row.customerSalesOrderCOATxList.find(tx => (tx.txType === type));
    if (data && data.amount) {
        return false;
    }
    else {
        return true;
    }
}

// const openEmailTeamplate = (props) => {
//     const soDetail = props.soDetail || {};
//     const emailPayloadProps = {
//         relationshipId: soDetail.relationshipId,
//         idType: 443,
//         customerId: soDetail.customerId,
//         type: 'emailSalesOrderPdf',
//         salesOrderMasterId: soDetail.salesOrderMasterId
//     };
//     props.pushModalToStack({
//         title: <Fragment>
//             <div className='ant-modal-title'>Email</div>
//         </Fragment>,
//         modalBody: <EmailTemplate emailPayloadProps={emailPayloadProps} sendMail={props.sendMail} />,
//         width: '100%',
//         hideFooter: true,
//         wrapClassName: 'email-template-modal'
//     })
// };

const getAddressInfoFragment = (obj, additionInfo) => {

    const fragmentObj = <Fragment>
        <div className="billing-address first-caps">
            {obj.streetAddress1 || obj.streetAddress2 ? (

                <div>
                    {obj.streetAddress1} {obj.streetAddress2}{","}
                </div>

            ) : ""}
            {obj.cityName || obj.stateName || obj.zipCode ? (

                <div>
                    {obj.cityName} {obj.stateName} {obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                </div>

            ) : ""}
            {obj.countryName ? (
                <div>{obj.countryName}</div>
            ) : ""}
        </div>
        {/* {
            additionInfo.firstName
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        } */}
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                </div> :
                ''
        }
        {/* {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        } */}

    </Fragment>

    return fragmentObj;
}

const getTaxIdentificationsFragment = (taxIdentifications) => {
    if (!taxIdentifications) return <Fragment />;
    let taxIdentificationsList = []
    if (taxIdentifications) {
        taxIdentificationsList = JSON.parse(taxIdentifications);
    }
    const fragmentObj = <Fragment>
        {taxIdentificationsList.map((obj, i) => {
            return <div className='billing-address'>
                {obj.taxIdentificationType || ''} - {obj.taxIdentificationNumber}
            </div>
        })}
    </Fragment>

    return fragmentObj;
}

const getContactNameFragment = (additionInfo) => {
    if (!additionInfo) return <Fragment />;
    let contactName = "";
    if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
    if (additionInfo.firstName) contactName += additionInfo.firstName;
    if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
    if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
    const fragmentObj = <Fragment>
        {
            (contactName && contactName.length)
                ? <div className='title'>
                    {contactName}
                </div> :
                ''
        }
        <div>{additionInfo?.EmailAddress || ''}</div>
        <div>{additionInfo?.workPhone || additionInfo?.cellPhone || ''}</div>
    </Fragment>
    return fragmentObj;
}

const getVariantFragment = (item,rrItem=[]) => {
    let isRrItem = rrItem.includes(item.inventoryItemVariantId)
    let itemName = null;
    if (item.itemName) {
        itemName = item.itemName;
    }
    if (item.itemVariantName) {
        itemName = itemName + ' (' + item.itemVariantName + ')'
    }
    return itemName ? <>{`${itemName}`} <span style={{fontWeight:700,color:"red"}}>{`${isRrItem? "Return":""}`}</span> </> : <span style={{fontWeight:700,color:"red"}}>{`${isRrItem? "Return":""}`}</span>;
}

const getDescriptionFragment = (soDetail, item, index) => {
    if (!item) return <Fragment />;
    const fragmentObj = <Fragment>
        {
            item.materialNumber
                ? <div>
                    <span className="title"> Material # : </span>{item.materialNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.stockNumber
                ? <div>
                    <span className="title"> Stock # : </span>{item.stockNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.partNumber
                ? <div>
                    <span className="title"> Part # : </span>{item.partNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.description
                ? <div>
                    {ReactHtmlParser(item.description || '')}
                </div> :
                ''
        }
        {
            item.origin
                ? <div>
                    <br></br>
                    <span className="title"> Origin : </span>{item.origin}
                </div> :
                ''
        }
        {
            item.hsCode
                ? <div>
                    <span className="title"> HS Code : </span>{item.hsCode}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.warehouse
                ? <div>
                    <span className="title"> Warehouse Name : </span> <span style={{ 'display': 'inline-block' }}>{ReactHtmlParser(item.warehouse || '')} </span>
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            (soDetail.customerPONumber)
                ? <div> (
                    {soDetail.customerPONumber ? <span className="title">PO {soDetail.customerPONumber} - </span> : ''}
                    {index ? <span className="title"> Line {index}</span> : ''}
                    )
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}

export default Template2;