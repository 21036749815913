import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, PURCHASE_ORDER_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchInboundDeliveryByPOMasterId = (payload, history) => {
  return dispatch => {
    dispatch({ type: PURCHASE_ORDER_ACTION_LIST.PO_INBOUND_DELIVERY_LIST_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/inboundDelivery/getInboundDeliveriesByPOMasterId?relationshipId=${payload.relationshipId}&poMasterId=${payload.poMasterId}&supplierId=${payload.supplierId}`)
      .then(res => {
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.PO_INBOUND_DELIVERY_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.PO_INBOUND_DELIVERY_LIST;
        }
      })
  }
}