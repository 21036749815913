import React, { Fragment, useMemo } from "react";
import { CONSTANTS, SUPPLIER_LISTING_PAGE,MODULE_CODED_VALUES } from "../../../static/constants";
import "./details.scss";
import { Tabs, Button } from "antd";
import { FormattedMessage } from "react-intl";
import Communication from "./communications";
import TotalOrders from "../../../containers/modal/modalBody/supplier/TotalOrders";
import OpenOrder from "../../../containers/modal/modalBody/supplier/OpenOrders";
import UnpaidInvoices from "../../../containers/modal/modalBody/supplier/UnpaidInvoices";
import PaidInvoices from "../../../containers/modal/modalBody/supplier/PaidInvoices";
import TaxIdentification from "./taxIdentification";
import { fetchDataIfNeeded,getPermissionData } from "../../../utils";
import { EditOutlined } from "@ant-design/icons";
import ShowAddress from "../../../containers/supplier/Listing/ShowAddress";
import PurchaseLedger from "./ledgerTransactions/purchaseLedger";
import Invoice from "./ledgerTransactions/invoice";
import { getInitialsFromString, capitalizeFirstLetter } from "../../../utils";
import Information from "./Sidenav/Information";
import ContactInfo from "./Sidenav/Contact";
import Contact from "./contacts";
import Tasks from "./tasks";

const { TabPane } = Tabs;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

function callback(key, props) {
  const payload = props.payload;

  switch (key) {
    case "1":
      props.resetPurchaseOrderTransactionsListData();
      props.fetchSupplierPurchaseOrderTransactions(payload);
      props.fetchSupplierPurchaseOrderTransactionCount(payload);
      break;

    case "4":
      fetchDataIfNeeded(
        "fetchSupplierCommunications",
        "commuincationList",
        props,
        payload
      );
      fetchDataIfNeeded("fetchAllEmployees", "allEmployee", props, payload);
      break;

    case "5":
      fetchDataIfNeeded(
        "fetchSupplierActivities",
        "activitiesList",
        props,
        payload
      );
      break;

    case "3":
      fetchDataIfNeeded("fetchSupplierContacts", "contacts", props, payload);
      break;

    case "2":
      fetchDataIfNeeded("fetchSupplierAddresses", "addressList", props, payload);
      break;

    case "6":
      props.fetchSupplierTaxIdentifications(payload);
      break;

    case "7":
      fetchDataIfNeeded("fetchSupplierToDoTasks", "taskList", props, payload);
      break;

    case "8":
      const ledgerPayload = { ...props.payload };
      ledgerPayload.filters = {
        supplierId: props.supplierId,
      };
      props.fetchSupplierLedgerTxSummaryList(ledgerPayload);
      break;

    case "9":
      const invoicePayload = { ...props.payload, pageNumber: 1, pageSize: 100 };
      invoicePayload.filters = {
        supplierId: props.supplierId,
      };
      props.resetInvoiceTransactionListData();
      props.fetchSupplierInvoiceList(invoicePayload);
      props.fetchSupplierInvoiceCount(invoicePayload);
      break;

    default:
      // No default behavior specified
      break;
  }
}

const Details = (props) => {
  const primaryPerm = (props.permissions && props.permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_ADDRESS )
  },[])

  const openModalOfSelectedTile = (data) => {
    switch (data.type) {
      case SUPPLIER_LISTING_PAGE.TOTAL_ORDERS:
        props.pushModalToStack({
          title: (
            <Fragment>
              <div className="ant-modal-title">
                {" "}
                <FormattedMessage id="totalOrder.text" defaultMessage="" />
              </div>
            </Fragment>
          ),
          modalBody: <TotalOrders {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case SUPPLIER_LISTING_PAGE.OPEN_PO:
        props.pushModalToStack({
          title: (
            <Fragment>
              <div className="ant-modal-title">
                <FormattedMessage
                  id="common.Open.Purchase.Orders"
                  defaultMessage=""
                />{" "}
              </div>
            </Fragment>
          ),
          modalBody: <OpenOrder {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case SUPPLIER_LISTING_PAGE.UNPAID_BALANCE:
        props.pushModalToStack({
          title: (
            <Fragment>
              <div className="ant-modal-title">
                <FormattedMessage
                  id="common.Unpaid.Balance"
                  defaultMessage=""
                />{" "}
              </div>
            </Fragment>
          ),
          modalBody: <UnpaidInvoices {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case SUPPLIER_LISTING_PAGE.TOTAL_PURCHASE:
        props.pushModalToStack({
          title: (
            <Fragment>
              <div className="ant-modal-title">
                <FormattedMessage
                  id="common.Total.Purchases"
                  defaultMessage=""
                />{" "}
              </div>
            </Fragment>
          ),
          modalBody: <PaidInvoices {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      default:
        break;
    }
  };

  const getAddressInfoFragment = (obj) => {
    return (
      <>
        {obj.streetAddress1 ? (
          <span className="billing-address">{obj.streetAddress1}</span>
        ) : (
          ""
        )}
        {obj.streetAddress2 ? (
          <span className="billing-address"> {obj.streetAddress2}</span>
        ) : (
          ""
        )}
        {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""}${obj.stateName ? ", " + obj.stateName : ""
              }${obj.zipCode ? ", " + obj.zipCode : ""}`}
          </div>
        ) : (
          ""
        )}
        {obj.countryName ? (
          <div
            className="billing-address"
            style={{ textTransform: "uppercase" }}
          >
            {`${capitalizeFirstLetter(obj.countryName) || ""}`}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };


  const supplierObj = props.supplier;

  return (
    <>
      <div className="txn-header">
        <div className="left-itms">
          <ul>
            <li className="title">Supplier Details </li>
          </ul>
        </div>
      </div>
      <div className="profile-container">
        <div className="sidenav">
          <div className="sidenav-top">
            <div className="top-view">
              <div className="view1">
                <div className="name-icon">
                  {supplierObj.supplierStoreName
                    ? getInitialsFromString(supplierObj.supplierStoreName)
                    : "USER"}
                </div>
                <div>
                  <div className="profile-name">
                    {supplierObj.supplierStoreName}
                  </div>
                  <div>
                    {props.primaryContact?.emailAddress ||
                     <div className="empty-data-box"></div>
                    }
                  </div>
                </div>
              </div>
              <div className="addressBar">
                <div className="addressIcon"><i className="fi fi-rs-marker" /></div>
                {(Object.keys(props.primaryAddress || {}) || []).length ? (
                  <>
                    <div className="address-data">
                      <div>{getAddressInfoFragment(props.primaryAddress)}</div>
                   {permissionsData.update ?   <Button
                        className="edit-btn-rf"
                        type="primary"
                        ghost
                        style={{ color: "#1890ff" }}
                        onClick={() => {
                          props.updateHeaderState({
                            address: {
                              addressDrawer: true,
                              formData: {
                                isUpdate:true,
                                ...props.primaryAddress,
                                // isDefault: "Yes",
                                supplierId: props.supplierId,
                              }, 
                            },
                          });
                        }}
                      >
                        <EditOutlined style={{ color: "#1890ff" }} />
                        <span style={{ color: "#1890ff" }}>Edit</span>
                      </Button> : "" }
                    </div>
                  </>
                ) : (

                  <div className="address-data">
                    <div>
                      <div className="grey-box"></div>
                      <div className="grey-box"></div>
                    </div>
             
                    {permissionsData.create ?   <Button
                      className="edit-btn-rf"
                      type="primary"
                      ghost
                      style={{ color: "#1890ff" }}
                      onClick={() => {
                        props.updateHeaderState({
                          address: {
                            addressDrawer: true,
                            formData: {

                              isUpdate:false,
                              // isDefault: "Yes",
                              supplierId: props.supplierId,
                            },
                          },
                        });
                      }}
                    >
                      <EditOutlined style={{ color: "#1890ff" }} />
                      <span style={{ color: "#1890ff" }}>Add</span>
                    </Button> : "" }

                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="sidenav-bottom">
            <div className="tabs-info">
              <Tabs type="line" className="custom-tab-st" animated={false}>
                <TabPane tab="Information" key="1">
                  <Information {...supplierObj} {...props} {...props.primaryContact} {...props.primaryAddress} />
                </TabPane>
                <TabPane tab="Contact" key="2">
                  {/* {...customerObj.primaryContact}  */}
                  <ContactInfo {...props.primaryContact} />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="leftnav">
          <div className="view-container" style={{margin: '10px 0px'}}>
          <Tabs
            className="primary-tab-container secondary-tab"
            onChange={(key) => {
              callback(key, props);
            }}
            type="line"
            animated={false}
          >
            {/* <TabPane tab="Activities" key="1">
              <Activities {...props} />
            </TabPane> */}

            <TabPane
              tab={
                <FormattedMessage
                  id="supplier.listing.actionMenu.title.address"
                  defaultMessage=""
                />
              }
              key="2"
            >
              <ShowAddress {...props} supplierId={props.supplierId} />,
            </TabPane>
            <TabPane
              tab={<FormattedMessage id="common.Contacts" defaultMessage="" />}
              key="3"
            >
              <Contact {...props} contactId={props.contactId}/>
            </TabPane>
            <TabPane tab="Tasks" key="5">
              <Tasks {...props} />
            </TabPane>
            <TabPane
              tab={<FormattedMessage id="communications" defaultMessage="" />}
              key="4"
            >
              <Communication {...props} />
            </TabPane>

            <TabPane
              tab={
                <FormattedMessage
                  id="addItem.text.taxIdentification"
                  defaultMessage=""
                />
              }
              key="6"
            >
              <TaxIdentification
                {...props}
                supplierTaxIdentificationId={props.supplierTaxIdentificationId}
              />
            </TabPane>

            <TabPane
              tab={
                <FormattedMessage id="ledgerSummary.text" defaultMessage="" />
              }
              key="8"
            >
              <PurchaseLedger {...props} />
            </TabPane>
            <TabPane
              tab="Purchase"
              key="9"
            >
              <Invoice {...props} />
            </TabPane>
          </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
