import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const importLeadFromCsv = (payload, props,callback) => {
    // var formData = new FormData();
    // formData.append("file", payload.fileToUpload);
    // formData.append("countryName", payload.countryName);
    var formData = new FormData();
    formData.append("file", payload.fileToUpload);
    formData.append("lead", payload.lead);
    formData.append(
      "mapping",
      new Blob([payload.mappedData], {
        type: "application/json",
      })
    );
    const startTime = new Date();
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadImport/importLeadFromCsv`,
          formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
        )
        .then(res => {
          showToasterMessage({ messageType: "success", description: lang[(res.data || "")] || "Please reselect the upload file." });
          let data = {
            relationshipId: props.companyInfo.relationshipId || 0,
            pageSize: 100,
            pageNumber: 1,
          }
          callback();
          let dataa = res.data?.ErrorRecords || [];
          let headers = props.hederOfCsv;
          
          // Add "remarks" to the headers
          headers.push("remarks");
          
     // Ensure data is an array
               let details = (dataa || []).map(lead => {
                   // Default to an empty object if crmLeadDetailsList is not an array
                   let detailsObject = (lead.crmLeadDetailsList || []).reduce((acc, detail) => {
                       // Make sure detail has both label and value
                       if (detail && detail.label && detail.value) {
                           acc[detail.label] = detail.value;
                       }
                       return acc;
                   }, {});

                   // Return the details object with the remarks
                   return { ...detailsObject, remarks: lead.remarks || '' }; // Default to empty string if remarks is undefined
               });

          
          // Pass the transformed data and headers to the DownloadCSV component
          
          props.updateState({
            isuploded:true,
            resData:res.data,
            detailsError:details,
            errorHeader:headers
          })
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25,
          }
          props.fetchAllLeadList(payload);
          props.getAllLeadListCount(payload);
        //   resetPaginatedData()(dispatch);
        //   fetchProducts(data)(dispatch);
        //   getProductCount(data)(dispatch);
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch(err => {
          if (lang[((err.response || {}).data || "")]) {
            showToasterMessage({ messageType: "error", description: lang[((err.response || {}).data || "")] || "Please reselect the upload file." });
          } else {
            const errorTime = new Date();
            var dif = startTime.getTime() - errorTime.getTime();
  
            var Seconds_from_T1_to_T2 = dif / 1000;
            var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
            
            if (Seconds_Between_Dates > 55){
              showToasterMessage({ messageType: "success", description: lang["productUpload.inprogress.messsage"] || "Product upload in progress you will be notified once the upload is done" });
              
            }else{
              showToasterMessage({ messageType: "error", description: "Please reselect the upload file." });
            }
              
            props.popModalFromStack();
          }
  
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    }
  }







