import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DepartmentDrawer from '../../../../../components/drawer/company/EmployeeMeta/Department';
import { hideModal, popModalFromStack, pushModalToStack, showModal } from '../../../../../actions/commonActions';
import { createDepartment, getDepartment } from '../../../../company/EmployeeMeta/Department/action';


class DepartmentDrawerCon extends Component {
    constructor(props) {
        super(props);
        const needToInit = Object.keys(props.departmentItem || {})?.length;
        const departmentObj = props.departmentItem;
        this.state = {
            departmentId: needToInit ? departmentObj.departmentId : undefined,
            version: needToInit ? departmentObj.version  : undefined,
            departmentName: needToInit ? departmentObj.departmentName  : undefined,
            departmentCode: needToInit ? departmentObj.departmentCode  : undefined,
            status: needToInit ? departmentObj.status  : undefined,
            relationshipId: needToInit ? departmentObj.relationshipId  : undefined,
            description: needToInit ? departmentObj. description : undefined,
            dateCreated: needToInit ? departmentObj.dateCreated  : undefined,
            dateUpdated: needToInit ? departmentObj.dateUpdated  : undefined,
            createdByUserName: needToInit ? departmentObj.createdByUserName : undefined,
            updatedByUserName: needToInit ? departmentObj.updatedByUserName : undefined
        };
    }

  
    render() {
        return <DepartmentDrawer {...this.props} {...this.state}  updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        drawerLoading: state.common.drawerLoading,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createDepartment,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    getDepartment

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentDrawerCon);