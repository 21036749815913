import axios from "axios";
import config from "../../../config/environmentConfig";
import { COMMUNICATION_ACTION_LIST, DONATION_ACTION_LIST, COMMON_ACTIONS } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const getAllDG = (payload) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });

        return axios
            .get( `${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/dg/getDGSummary?relationshipId=${payload.relationshipId}`)
            .then((res) => {
                dispatch({
                    type: COMMUNICATION_ACTION_LIST.DG_LIST,
                    data: res.data,
                });
            })
            .catch((err) => {
                showToasterMessage({
                    messageType: "error",
                    description:
                        lang[((err.response || {}).data || "")] ||
                        "Some error occurred",
                });
            })
            .finally(() => {
                // dispatch({ type: DONATION_ACTION_LIST.DONATION_SETTING_LOADING, data: {isDGLISTLoaded : true}});
                dispatch({
                    type: COMMON_ACTIONS.HIDE_LIST_LOADER,
                  });
              });
    };
};

export const getAllDGCount = (payload) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });

        return axios
            .get( `${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/dg/getAllDGCount?relationshipId=${payload.relationshipId}`)
            .then((res) => {
                dispatch({
                    type: COMMUNICATION_ACTION_LIST.DG_COUNT,
                    data: res.data,
                });
            })
            .catch((err) => {
                showToasterMessage({
                    messageType: "error",
                    description:
                        lang[((err.response || {}).data || "")] ||
                        "Some error occurred",
                });
            })
    };
};

export const deleteDG = (payload, props) => {
    return dispatch => {
        return axios
            .post(`${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/dg/delete`, payload)
            .then(res => {
                // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                props.getAllDG && props.getAllDG(props);
                showToasterMessage({ messageType: 'success', description: 'Distribution Group successfully Deleted' });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
                } else {
                    // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
                }
            })
    }
}



