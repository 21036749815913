import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Drawer, Row,Skeleton } from "antd";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { Dropdown } from "../../../../general/Dropdown"

const DesignationDrawer = (props) => {
  const {pageNumber} = props;
  const closeDrawer = () => {
    props.updateState({
      designationDrawer: false,
    });
  };
  const handleSubmit = () => {
    props.updateDrawerState({ submitted: true });
    if(!props.designationName ||  !props.designationCode){
      return;
    }
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      designationId: props.designationId,
      designationName: props.designationName,
      designationCode: props.designationCode,
      createdByUserId: props.createdByUserId,
      updatedByUserId: props.updatedByUserId,
      status:1,
    }
    
    const modalData = {     
      modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage=""/>,
      handleSubmit: () => {
        props.createDesignation(payload,props);
       closeDrawer();  },
    };
    props.pushModalToStack(modalData);
  }
  return (
    <Drawer
      title="Create New"
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.designationDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              handleSubmit()
            }
            }
            key={1}
          />
        </>
      }

    >
      <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

      <Row gutter={[16, 16]}>
        {/* <Col span={12}>
          <div className="i-label">Department
            <span className="required">*</span>
          </div>
          <Dropdown
            items={props.departmentList[pageNumber] || []}
            valueKeyName="departmentName"
            optionKeyName="departmentId"
            placeholder="Department"
            value={props.departmentName}
            onSelect={(optionValue, option) => {
              props.updateDrawerState({
                departmentId:optionValue.value,
                departmentName: option.name,
              });
            }}
          />
          
        </Col> */}
        <Col span={12}>
          <div className="i-label">Designation
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Designation"
            type="text"
            maxLength={50}
            value={props.designationName}
            onChange={(e) => {
              props.updateDrawerState({ designationName: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.designationName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Designation Code
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Department Code"
            type="text"
            maxLength={20}
            value={props.designationCode}
            onChange={(e) => {
              props.updateDrawerState({ designationCode: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.designationCode }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

      </Row>
      </Skeleton>
    </Drawer>
  );
};

export default DesignationDrawer;
