import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as find from "lodash.find";
import * as filter from "lodash.filter";

import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
  fixedDecimalNumber,
} from "../../../../utils";
import SalesQuoteComponent from "../../../../components/customer/salesQuote/StandardSalesQuote";
import {
  fetchProducts,
  fetchTaxes,
  fetchUOM,
  addUOM,
  deleteUOM,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  addPaymentTerm,
  deletePaymentTerm,
  deleteAddress,
  deleteTax,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  deleteContact,
  fetchProjects,
  deleteProject,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchAllEmployees,
  deleteEmployee,
  fetchCountries,
  fetchRelationshipTaxIdentifications,
  deleteCustomerTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  fetchStates,
  fetchIncoterms,
  addIncoterm,
  deleteIncoterm,
  fetchCustomerDetailsByCustomerId,
  resetCustomerDetail,
  fetchAddresses,
  getRelationshipAddress,
  resetAddresses
} from "./../../../../actions/commonActions";
import {
  fetchAllFreightType,
  addFreightType,
  deleteFreightType,
} from "../../salesOrder/shipment/action";
import { fetchAllRFQ } from "../../../../containers/supplier/rfq/Listing/action";
import { fetchCustomers,resetPaginatedData } from "../../Listing/action";
import {
  createSalesQuote,
  fetchSQDetail,
  resetSQDetails,
  fetchRfqData,
  resetRfqPoData,
  getNextTxNumber,
  fetchInquiryDetail,
  resetEnquiryDetail,
  createSalesQuoteDraft,
  deleteDocument
} from "./action";
import {
  CONSTANTS,
  LEDGER_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../static/constants";
import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../static/agGridConstants";
import SQDetail from "../../../modal/modalBody/customer/SQDetail";
import { deleteCustomers } from "../../Listing/action";
import {
  fetchRemarksTemplate,
  resetRemarksData,
} from "../../../modal/modalBody/settings/RemarksTemplate/action";
import { fetchAllContacts ,resetAllContacts } from "../../Listing/ShowConact/action";
import { fetchSalesQuotes, fetchSalesQuoteDrafts } from "../Listing/action";
import SettingSalesDrawer from "../../../drawer/customer/settingSalesDrawer";


class SalesQuote extends Component {
  constructor(props) {
    super(props);
    const companyInfo = props.companyInfo;
    const allRelationshipBillingAddress =
      filter(companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
    const relationshipBillingAddress = find(allRelationshipBillingAddress, {
      isDefault: 1,
    });

    const linkProps = (props.location && props.location.state) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNameQuote",
      viewName:"Quote",
      transaction_email_template_value:"Sales Quote",
      tabDocumentOptions: 
      { txType: CONSTANTS_TRANSACTIONS.SALES_QUOTE, label: 'Sales Quote', numberExample: 'QO-1', numberInfo: 'SQ-CustomerNumber-DDMMYY' },
      settingSalesDrawerVisible:false,
      salesQuoteDetails: [{}],
      quoatationDraftId:
        props.quoatationDraftId || linkProps.quoatationDraftId || 0,
      sqDraftData:
        props.salesQuotationeData || linkProps.salesQuotationeData || {},
      customer: props.customer || linkProps.customer || {},
      isUpdate: props.update,
      isClone: props.clone || linkProps.clone,
      customerName: (props.customer || linkProps.customer || {}).companyName,
      stateCustomerId: props.customerId || linkProps.customerId,
      pageSize: 100,
      pageNumber: 1,
      searchedText: "",
      quoteDate: new Date(),
      quoteExpiryDate: new Date(),
      relationshipBillingAddress,
      //placeOfSupply: (relationshipBillingAddress || {}).stateName,
      allRelationshipBillingAddress,
      priceTypeToApply:
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0],
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "salesQuote", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
        GRID_PREFERENCE_CONSTANTS.AMOUNT_WITHOUT_TAX,
        "sno",
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === "India",
        basePrice: true,
        specialDiscount: true,
        discount: true,
        tax: true,
        comment: true,
        stockNumber: true,
        partNumber: true,
        materialNumber: true,
        origin: true,
        taxableAmount:true,
        hsCode: true,
      },
    };
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //     collapsed: true
    // })
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      salesQuotationMasterId:
        this.props.salesQuotationMasterId || linkProps.salesQuotationMasterId,
      rfqChildMasterId:
        this.props.rfqChildMasterId || linkProps.rfqChildMasterId,
      rfqMasterId: this.props.rfqMasterId || linkProps.rfqMasterId,
      userId: (this.props.userInfo || {}).userId,
      customerId: this.props.customerId || linkProps.customerId,
      inquiryMasterId: this.props.inquiryMasterId || linkProps.inquiryMasterId,
      pageSize: 100,
      isCompact:true
    };
    this.props.resetSQDetails();
    this.props.resetRfqPoData();
    this.props.resetEnquiryDetail();
    this.props.resetAddresses();
    this.props.resetCustomerDetail();        
    // fetchPaginationDataIfNeeded(
    //   "fetchCustomers",
    //   "customers",
    //   this.props,
    //   payload,
    //   true
    // );
    this.props.resetPaginatedData();
    payload.rfqMasterId &&
      fetchDataIfNeeded("fetchRfqData", "rfqPoData", this.props, payload, true);
    payload.customerId &&
      fetchDataIfNeeded(
        "fetchCustomerDetailsByCustomerId",
        "customerDetail",
        this.props,
        payload,
        true
      );
    payload.customerId &&
      this.props.fetchAddresses({
        ...payload,
        locationType: CONSTANTS.SHIPPING_ADDRESS,
      });
    payload.customerId &&
      this.props.fetchAddresses({
        ...payload,
        locationType: CONSTANTS.BILLING_ADDRESS,
      });
    payload.inquiryMasterId && this.props.fetchInquiryDetail(payload);
    payload.salesQuotationMasterId &&
      fetchDataIfNeeded("fetchSQDetail", "sqDetail", this.props, payload, true);
    fetchDataIfNeeded("fetchProducts", "products", this.props, payload);
    fetchDataIfNeeded("fetchTaxes", "taxes", this.props, payload);
    fetchDataIfNeeded("fetchUOM", "uomList", this.props, payload);
    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded("getNextTxNumber", "nextTxNumber", this.props, payload);
    }
    fetchDataIfNeeded("fetchStates", "states", this.props, 1);
    fetchDataIfNeeded("fetchProjects", "projectList", this.props, payload);
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchAllEmployees", "employees", this.props, payload);
    fetchDataIfNeeded("fetchCountries", "countries", this.props, payload);
    this.props.fetchRemarksTemplate({
      relationshipId: companyInfo.relationshipId,
      docName: "Sales Quotation",
    });
    fetchDataIfNeeded(
      "fetchRelationshipTaxIdentifications",
      "allRelationshipTaxIdentificatins",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchIncoterms", "incoterms", this.props, payload);
    fetchDataIfNeeded(
      "fetchAllFreightType",
      "carrierList",
      this.props,
      payload
    );
  }

  openSQDetails = (data) => {
    const sqPayload = {
      customerId: data.customerId,
      salesQuotationMasterId: data.salesQuotationMasterId,
    };
    this.props.pushModalToStack({
      modalBody: <SQDetail {...this.props} sqPayload={sqPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching,
    };
    this.props.fetchProducts(payload);
  }

  componentWillReceiveProps(props) {
    if (props.addresses) {
      if (
        props.addresses.BillingAddress &&
        props.addresses.BillingAddress.length
      ) {
        this.setState({
          allBillingAddress: props.addresses.BillingAddress,
        });
      }
      if (
        props.addresses.ShippingAddress &&
        props.addresses.ShippingAddress.length
      ) {
        this.setState({
          allShippingAddress: props.addresses.ShippingAddress,
        });
      }
    }
    if (props.customerDetail && props.customerDetail.customerId) {
      const customer = props.customerDetail;
      const allBillingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
          })) ||
        [];
      const allShippingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.SHIPPING_ADDRESS,
          })) ||
        [];
      const defaultBillingAddress =
        find(allBillingAddress, { isDefault: 1 }) || {};
      const defaultShippingAddress =
        find(allShippingAddress, { isDefault: 1 }) || {};
      const selectedContact =
        (customer.boContactList || []).find((x) => x.isPrimaryContact) || {};
      const selectedList =
        (customer &&
          filter(customer.customerTaxIdentificationsList, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedCustomerTaxIdentifications = selectedList.map((obj) => {
        return obj.customerTaxIdentificationId;
      });
      this.setState({
        customer,
        customerName: customer.companyName,
        customerBillingAddress: defaultBillingAddress,
        customerShippingAddress: defaultShippingAddress,
        placeOfSupply:
          defaultShippingAddress.stateName || defaultBillingAddress.stateName,
        selectedContact,
        allBillingAddress,
        allShippingAddress,
        selectedCustomerTaxIdentifications:
          this.state.selectedCustomerTaxIdentifications ||
          selectedCustomerTaxIdentifications,
      });
      props.resetCustomerDetail();
    }

    if (
      !this.state.selectedRelationshipTaxIdentifications &&
      props.allRelationshipTaxIdentificatins &&
      props.allRelationshipTaxIdentificatins.length
    ) {
      const selectedList =
        (props.allRelationshipTaxIdentificatins &&
          filter(props.allRelationshipTaxIdentificatins, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedRelationshipTaxIdentifications = selectedList.map((obj) => {
        return obj.relationshipTaxIdentificationId;
      });
      this.setState({
        selectedRelationshipTaxIdentifications:
          selectedRelationshipTaxIdentifications,
      });
    }

    if (
      props.documentList &&
      props.documentList.length &&
      !this.state.docName
    ) {
      props.documentList.forEach((document) => {
        if (
          document.txType ===
            CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_QUOTE] &&
          document.isDefault
        ) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName,
          });
        }
      });
    }
    if (
      !this.state.customerRemarks &&
      props.remarksData &&
      props.remarksData.content &&
      !this.state.isRemarksSet
    ) {
      this.setState({
        customerRemarks: props.remarksData.content || "",
        isRemarksSet: true,
        footer: props.remarksData.footer || "",
      });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500);
    }
    if ((props.sqDetail || {}).salesQuotationMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.warehouse
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      };
      props.sqDetail.customerSalesQuotationDetailsList.map((ele) => {
        const tax =
          find(ele.customerSalesQuotationCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};
        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(
          ele.customerSalesQuotationCOATxList,
          tax.amountPercent
        );
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty:fixedDecimalNumber(ele.quantity),
          qtyBeforeUpdate: fixedDecimalNumber(ele.quantity),
          discount: (
            find(ele.customerSalesQuotationCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax:tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
          taxName: appliedTax.taxName,
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice || ele.anItemSalePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          salesQuotationDdetailsId: props.update
            ? ele.salesQuotationDdetailsId
            : 0,
          salesQuotationMasterId: props.update ? ele.salesQuotationMasterId : 0,
          customerId: ele.customerId,
          inquiryMasterId: ele.inquiryMasterId,
          comment: ele.warehouse,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          materialNumber: ele.materialNumber,
          salesQuotationDetailsId: props.update
            ? ele.salesQuotationDetailsId
            : 0,
          parentDetailsId: props.update ? ele.parentDetailsId : 0,
        });
      });
      const selectedContact =
        (props.sqDetail.additionalInfoList || [])[0] || {};
      let selectedCustomerTaxIdentifications = [];
      const additionalInfoList = props.sqDetail.additionalInfoList[0] || {};

      if (selectedContact.taxIdentifications) {
        let taxIdentificationsList = JSON.parse(
          selectedContact.taxIdentifications
        );
        selectedCustomerTaxIdentifications = taxIdentificationsList.map(
          (obj, i) => {
            return obj.customerTaxIdentificationId;
          }
        );
      }

      this.setState({
        update: props.update,
        version: props.sqDetail.version,
        selectedCustomerTaxIdentifications,
        salesQuotationMasterId: props.update
          ? props.sqDetail.salesQuotationMasterId
          : 0,
        quotationNumber: props.update ? props.sqDetail.quotationNumber : "",
        customerBillingAddress:
          find(props.sqDetail.boLocationSQList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
          }) || {},
        customerShippingAddress:
          find(props.sqDetail.boLocationSQList, {
            locationType: CONSTANTS.SHIPPING_ADDRESS,
          }) || {},
        relationshipBillingAddress:
          find(props.sqDetail.boLocationSQList, {
            locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
          }) || {},
        priority: props.sqDetail.orderPriority,
        customerRfq: props.sqDetail.customerRFQNumber,
        customerInquiryNumber: props.sqDetail.customerInquiryNumber,
        customerRef: props.sqDetail.referenceNumber,
        quoteDate: props.update
          ? new Date(props.sqDetail.salesQuoteDate)
          : new Date(),
        quoteExpiryDate:
          props.update && props.sqDetail.salesQuoteExpiryDate
            ? new Date(props.sqDetail.salesQuoteExpiryDate)
            : new Date(),
        customerId: props.sqDetail.customerId,
        customerName: props.sqDetail.customerName,
        // customer: { customerId: props.sqDetail.customerId, companyName: props.sqDetail.customerName, customerGstNumber: props.sqDetail.customerGstNumber, customerPanNumber: props.sqDetail.customerPanNumber },
        selectedContact: (props.sqDetail.additionalInfoList || [])[0] || {},
        internalRemarks: props.sqDetail.remarksInternal,
        customerRemarks: props.sqDetail.remarksCustomer,
        footer: props.sqDetail.footer,
        quotationAwsObjectKey: props.sqDetail.quotationAwsObjectKey,
        salesPersonId: props.sqDetail.salesPersonId,
        salesPersonName: props.sqDetail.salesPersonFullName,
        docName: props.sqDetail.docName,
        salesQuoteDetails: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        columnPreferences: {
          description: true,
        },
        inheritedTxColumn: inheritedTxColumn,
        tableDataReRendered: true,
        oldPayload: props.update ? props.sqDetail : {},
        projectMasterId: props.sqDetail.projectMasterId,
        projectName: props.sqDetail.projectName,
        projectNumber: props.sqDetail.projectNumber,
        docDetailList: props.sqDetail.docDetailList,
        isInquiryConversion: props.update
          ? props.sqDetail.isInquiryConversion
            ? true
            : false
          : false,
        deliveryMethod: props.sqDetail.deliveryMethod,
        inctermId: props.sqDetail.inctermId,
        incotermName: props.sqDetail.incotermName,
        selectedRelationshipTaxIdentifications:
          additionalInfoList.relationshipTaxIdentifications
            ? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(
                (obj) => {
                  return obj.relationshipTaxIdentificationId;
                }
              )
            : this.state.selectedRelationshipTaxIdentifications,
      });
      if (props.update) {
        this.setState({ nextTxNumber: props.sqDetail.quotationNumber });
      }
      props.resetSQDetails();
    }

    if (
      props.taxes &&
      props.taxes.length &&
      this.state.quoatationDraftId &&
      this.state.sqDraftData
    ) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.warehouse
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      };
      this.state.sqDraftData.customerSalesQuotationDetailsList.map((ele) => {
        //bellow row only for draft
        let variantName =
          (ele.attributeValue1 || "") +
          (ele.attributeValue2 ? " | " : "") +
          (ele.attributeValue2 || "") +
          "" +
          (ele.attributeValue3 ? " | " + ele.attributeValue3 : "");
        const tax =
          find(ele.customerSalesQuotationCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};
        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName + ((variantName && " (" + variantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: variantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: fixedDecimalNumber(ele.quantity),
          discount: (
            find(ele.customerSalesQuotationCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax: tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
          taxName: appliedTax.taxName,
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice || ele.anItemSalePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          salesQuotationDdetailsId: props.update
            ? ele.salesQuotationDdetailsId
            : 0,
          salesQuotationMasterId: props.update ? ele.salesQuotationMasterId : 0,
          customerId: ele.customerId,
          comment: ele.warehouse,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          materialNumber: ele.materialNumber,
          parentDetailsId: ele.parentDetailsId,
        });
      });

      setTimeout(() => {
        if (this.state.sqDraftData) {
          this.setState({
            salesQuotationMasterId:
              this.state.sqDraftData.salesQuotationMasterId || 0,
            quotationNumber: this.state.sqDraftData.quotationNumber || "",
            customerBillingAddress:
              find(this.state.sqDraftData.boLocationSQList, {
                locationType: CONSTANTS.BILLING_ADDRESS,
              }) || {},
            customerShippingAddress:
              find(this.state.sqDraftData.boLocationSQList, {
                locationType: CONSTANTS.SHIPPING_ADDRESS,
              }) || {},
            relationshipBillingAddress:
              find(this.state.sqDraftData.boLocationSQList, {
                locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
              }) || {},
            priority: this.state.sqDraftData.orderPriority,
            customerRfq: this.state.sqDraftData.customerRFQNumber,
            customerInquiryNumber: this.state.sqDraftData.customerInquiryNumber,
            customerRef: this.state.sqDraftData.referenceNumber,
            quoteDate: new Date(this.state.sqDraftData.salesQuoteDate || ""),
            quoteExpiryDate: this.state.sqDraftData.salesQuoteExpiryDate
              ? new Date(this.state.sqDraftData.salesQuoteExpiryDate)
              : new Date(),
            customerId: this.state.sqDraftData.customerId,
            customerName: this.state.sqDraftData.customerName,
            selectedContact:
              (this.state.sqDraftData.additionalInfoList || [])[0] || {},
            customer: {
              customerId: this.state.sqDraftData.customerId,
              companyName: this.state.sqDraftData.customerName,
              customerGstNumber: this.state.sqDraftData.customerGstNumber,
              customerPanNumber: this.state.sqDraftData.customerPanNumber,
            },
            internalRemarks: this.state.sqDraftData.remarksInternal,
            customerRemarks: this.state.sqDraftData.remarksCustomer,
            footer: this.state.sqDraftData.footer,
            quotationAwsObjectKey: this.state.sqDraftData.quotationAwsObjectKey,
            salesPersonId: this.state.sqDraftData.salesPersonId,
            salesPersonName: this.state.sqDraftData.salesPersonFullName,
            docName: this.state.sqDraftData.docName,
            salesQuoteDetails: detailList,
            columnPreferences: {
              description: true,
            },
            sqDraftData: null,
            inheritedTxColumn: inheritedTxColumn,
            tableDataReRendered: true,
            projectMasterId: this.state.sqDraftData.projectMasterId,
            projectName: this.state.sqDraftData.projectName,
            projectNumber: this.state.sqDraftData.projectNumber,
            deliveryMethod: this.state.sqDraftData.deliveryMethod,
            inctermId: this.state.sqDraftData.inctermId,
            incotermName: this.state.sqDraftData.incotermName,
          });
        }
      }, 1000);
    }

    if (
      props.enquiryDetail &&
      props.enquiryDetail.inquiryMasterId &&
      props.isProductLoaded
    ) {
      //   props.fetchProducts();
      const detailList = [];
      // let customer = { customerId: props.enquiryDetail.customerId, customerName: props.enquiryDetail.customerName };
      //let allBillingAddress = [];
      const defaultBillingAddress =
        find(props.enquiryDetail.boLocationInquiryList || [], {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }) || {};
      // const defaultShippingAddress = find((props.enquiryDetail.boLocationInquiryList || []), { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
      // if (props.customers[this.state.pageNumber] && props.customers[this.state.pageNumber].length) {
      //     customer = find(props.customers[this.state.pageNumber], { customerId: Number(props.enquiryDetail.customerId || 0) }) || {};
      //     allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
      // }
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.warehouse
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      };

      props.enquiryDetail.inquiryDetailsList &&
        props.enquiryDetail.inquiryDetailsList.map((ele) => {
          let itemCurr = find(props.products, { itemId: ele.itemId });
          let variantName =
            (ele.attributeValue1 || "") +
            (ele.attributeValue2 ? " | " : "") +
            (ele.attributeValue2 || "") +
            "" +
            (ele.attributeValue3 ? " | " + ele.attributeValue3 : "");
          const tax = itemCurr?.inventoryItemDefaultTaxList?.length ?itemCurr?.inventoryItemDefaultTaxList[0] : {};

          const appliedTax = tax.taxSingleRateId
            ? find(props.taxes, {
                taxSingleRateId: tax.taxSingleRateId,
              }) || {}
            : find(props.taxes, {
                taxPercent:
                  (tax || {}).igstRate ||
                  (tax || {}).cgstRate ||
                  (tax || {}).globleTaxRate,
              }) || {};

          const rate = itemCurr?.inventoryItemVariantList[0]
            ? fixedDecimalNumber(
                Number(itemCurr?.inventoryItemVariantList[0].retailPrice || 0)
              )
            : fixedDecimalNumber(
                Number(itemCurr?.inventoryItemVariantList[0]?.wholesalePrice || 0)
              );
          // find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
          _emptyColumnCheck(ele);
          return detailList.push({
            selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
            product: ele.itemName
              ? ele.itemName +
                ((variantName && " (" + variantName + ") ") || "")
              : "",
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : {},
            itemVariantName: variantName,
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3,
            },
            description: ele.description,
            scrollHeight: ele.rowHeight,
            qty: fixedDecimalNumber(ele.quantity),
            discount: (
              find(ele.customerSalesQuotationCOATxList, {
                txType: LEDGER_TYPE.TYPE_DISCOUNT,
              }) || {}
            ).amountPercent,
            tax: appliedTax.taxPercent || 0,
            taxName: appliedTax.taxName,
            taxApplied: appliedTax.taxNameDisplay,
            taxId: appliedTax.taxSingleRateId,
            rate: rate,
            baseRate: ele.basePrice || ele.anItemSalePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
            specialDiscount: ele.basePrice && ele.specialDiscount,
            salesQuotationDdetailsId: props.update
              ? ele.salesQuotationDdetailsId
              : 0,
            salesQuotationMasterId: props.update
              ? ele.salesQuotationMasterId
              : 0,
            customerId: ele.customerId,
            comment: ele.warehouse,
            stockNumber: ele.stockNumber,
            partNumber: ele.partNumber,
            origin: ele.origin,
            hsCode: ele.hsCode,
            materialNumber: ele.materialNumber,
            parentDetailsId: ele.inquiryDetailsId,
          });
        });

      const forceHideTxColumn = [];
      const additionalInfoList =
        props.enquiryDetail.additionalInfoList[0] || {};

      // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
      //     forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      // }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      this.setState({
        isInquiryConversion: 1,
        customerId: props.enquiryDetail.customerId,
        customerInquiryNumber: props.enquiryDetail.customerInquiryNumber,
        // customer: {customerId: props.enquiryDetail.customerId, customerName: props.enquiryDetail.customerName},
        selectedContact:
          (props.enquiryDetail.additionalInfoList || [])[0] || {},
        customerName: props.enquiryDetail.customerName,
        inquiryMasterId: props.enquiryDetail.inquiryMasterId,
        priority: props.enquiryDetail.priority,
        customerRef: props.enquiryDetail.refNumber,
        internalRemarks: props.enquiryDetail.remarksInternal,
        customerRemarks: props.enquiryDetail.remarksCustomer,
        footer: props.enquiryDetail.footer,
        rfqDate: new Date(props.enquiryDetail.inquiryDate),
        rfqExpirationDate:
          props.enquiryDetail.rfqExpirationDate &&
          new Date(props.rfqPoData.rfqExpirationDate),
        quoteExpiryDate: props.enquiryDetail.inquiryExpirationDate
          ? new Date(props.enquiryDetail.inquiryExpirationDate)
          : new Date(),
        projectMasterId: props.enquiryDetail.projectMasterId,
        projectName: props.enquiryDetail.projectName,
        projectNumber: props.enquiryDetail.projectNumber,
        
        //allBillingAddress: allBillingAddress,
        customerBillingAddress: defaultBillingAddress,
        // customerShippingAddress: defaultShippingAddress,
        salesQuoteDetails: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        salesPersonName: props.enquiryDetail.salesPersonFullName,
        selectedRelationshipTaxIdentifications:
          additionalInfoList.relationshipTaxIdentifications
            ? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(
                (obj) => {
                  return obj.relationshipTaxIdentificationId;
                }
              )
            : this.state.selectedRelationshipTaxIdentifications,
      });

      props.resetEnquiryDetail();
    }

    if (
      props.rfqPoData &&
      props.rfqPoData.rfqMasterId &&
      props.customers[1].length
    ) {
      const detailList = [];
      let customer = {
        customerId: props.rfqPoData.customerId,
        customerName: props.rfqPoData.customerName,
      };
      let allBillingAddress = [];
      const defaultBillingAddress =
        find(props.rfqPoData.boLocationRFQList || [], {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }) || {};
      const defaultShippingAddress =
        find(props.rfqPoData.boLocationRFQList || [], {
          locationType: CONSTANTS.SHIPPING_ADDRESS,
        }) || {};
      if (
        props.customers[this.state.pageNumber] &&
        props.customers[this.state.pageNumber].length
      ) {
        customer =
          find(props.customers[this.state.pageNumber], {
            customerId: Number(props.rfqPoData.customerId || 0),
          }) || {};
        allBillingAddress =
          (customer &&
            filter(customer.boLocationList, {
              locationType: CONSTANTS.BILLING_ADDRESS,
            })) ||
          [];
      }
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.warehouse
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      };

      props.rfqPoData.rfqDetailsList &&
        props.rfqPoData.rfqDetailsList.map((ele) => {
          let variantName =
            (ele.attributeValue1 || "") +
            (ele.attributeValue2 ? " | " : "") +
            (ele.attributeValue2 || "") +
            "" +
            (ele.attributeValue3 ? " | " + ele.attributeValue3 : "");
          const tax =
            find(ele.customerSalesOrderCOATxList, (ele) => {
              return ele.txType === LEDGER_TYPE.TYPE_TAX;
            }) || {};
          const appliedTax =
            find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};

          _emptyColumnCheck(ele);
          return detailList.push({
            selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
            inventoryItemVariantId: ele.inventoryItemVariantId,
            product: ele.itemName
              ? ele.itemName +
                ((variantName && " (" + variantName + " ) ") || "")
              : "",
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : "",
            itemVariantName: variantName,
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3,
            },
            description: ele.description,
            scrollHeight: ele.rowHeight,
            qty: ele.quantity,
            discount: (
              find(ele.customerSalesOrderCOATxList, {
                txType: LEDGER_TYPE.TYPE_DISCOUNT,
              }) || {}
            ).amountPercent,
            tax: tax.amountPercent || 0,
            taxApplied: appliedTax.taxNameDisplay,
            taxId: appliedTax.taxSingleRateId,
            taxName: appliedTax.taxName,
            rate: ele.anItemSalePrice,
            baseRate: ele.basePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
            specialDiscount: ele.basePrice && ele.specialDiscount,
            comment: ele.comment,
            stockNumber: ele.stockNumber,
            partNumber: ele.partNumber,
            origin: ele.origin,
            hsCode: ele.hsCode,
            materialNumber: ele.materialNumber,
          });
        });

      props.rfqPoData.rfqChildDetailList &&
        props.rfqPoData.rfqChildDetailList.map((ele) => {
          _emptyColumnCheck(ele);
          return detailList.push({
            selectedProductValue: [ele.itemId, ele.itemVariantId],
            product:
              ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") ||
                ""),
            productObj: {
              itemName: ele.itemName,
              itemId: ele.itemId,
              sku: ele.sku,
            },
            description: ele.description,
            qty: ele.quantity,
            rate: ele.anItemSalePrice,
            baseRate: ele.basePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
            specialDiscount: ele.specialDiscount,
          });
        });

      const forceHideTxColumn = [];
      // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
      //     forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      // }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      this.setState({
        isRFQConversion: true,
        customerId: props.rfqPoData.customerId,
        customer: customer,
        selectedContact: (props.rfqPoData.additionalInfoList || [])[0] || {},
        customerName: customer.companyName,
        rfqChildMasterId: props.rfqPoData.rfqChildMasterId,
        priority: props.rfqPoData.orderPriority,
        rfqNumber: props.rfqPoData.rfqNumber,
        customerRef: props.rfqPoData.refNumber,
        customerRfq: props.rfqPoData.customerRFQNumber,
        rfqMasterId: props.rfqPoData.rfqMasterId,
        internalRemarks: props.rfqPoData.remarksInternal,
        customerRemarks: props.rfqPoData.remarksSupplier,
        footer: props.rfqPoData.footer,
        rfqDate: new Date(props.rfqPoData.rfqDate),
        rfqExpirationDate:
          props.rfqPoData.rfqExpirationDate &&
          new Date(props.rfqPoData.rfqExpirationDate),
        projectMasterId: props.rfqPoData.projectMasterId,
        projectName: props.rfqPoData.projectName,
        projectNumber: props.rfqPoData.projectNumber,
        allBillingAddress: allBillingAddress,
        customerBillingAddress: defaultBillingAddress,
        customerShippingAddress: defaultShippingAddress,
        salesQuoteDetails: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        salesPersonName: props.rfqPoData.salesPersonFullName,
      });

      props.resetRfqPoData();
    }

    if (
      this.state.stateCustomerId &&
      props.customers[1] &&
      props.customers[1].length &&
      props.customerId
    ) {
      const customer =
        find(props.customers[1], {
          customerId: Number(props.customerId || 0),
        }) || {};
      const allShippingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.SHIPPING_ADDRESS,
          })) ||
        [];
      const defaultShippingAddress =
        find(allShippingAddress, { isDefault: 1 }) || {};
      const allBillingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
          })) ||
        [];
      const defaultBillingAddress =
        find(allBillingAddress, { isDefault: 1 }) || {};
      //const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
      const selectedList =
        (customer &&
          filter(customer.customerTaxIdentificationsList, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedCustomerTaxIdentifications = selectedList.map((obj) => {
        return obj.customerTaxIdentificationId;
      });
      this.setState({
        stateCustomerId: null,
        customer,
        customerName: customer.companyName,
        customerShippingAddress: defaultShippingAddress,
        customerBillingAddress: defaultBillingAddress,
        placeOfSupply:
          defaultShippingAddress && defaultShippingAddress.stateName,
        selectedCustomerTaxIdentifications,
        boContactList: customer.boContactList || [],
      });
    }

    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences =
        find(props.allPreferences, {
          txType: "salesQuote",
          templateName: "standard",
        }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnList
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
              "product",
              "description"
            );
        const txColumnSetting = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnSetting || {}
          : {};
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }

    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      const allRelationshipBillingAddress = [];
      props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === "RelationshipBillingAddress") {
          allRelationshipBillingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress,
      });
    } else {
      const allRelationshipBillingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || [];
      this.setState({
        allRelationshipBillingAddress,
      });
    }
    if (props.customerContactList && props.customerContactList.length) {
      this.setState({
        boContactList: props.customerContactList,
      });
    }
    // if (props.products?.length) {

    // }
  }

  render() {
    return (
      <div>
        {this.state.settingSalesDrawerVisible && 
          <SettingSalesDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
            />
        }
        <SalesQuoteComponent
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          openSQDetails={this.openSQDetails}
          updateState={(data) => {
            this.setState(data);
          }}
          updateDrawerState={(data) => { this.setState(data) }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.customer.customers,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    products: state.salesInvoice.products,
    uomList: state.common.uomList,
    customerDetail: state.common.customerDetail,
    taxes: state.common.taxes,
    sqDetail: state.sq.sqDetail,
    states: state.common.states,
    rfqPoData: state.purchaseOrder.rfqPoData,
    documentList: state.common.documentList,
    nextTxNumber: state.sq.nextTxNumber,
    projectList: state.common.projectList,
    allPreferences: state.common.allPreferences,
    employees: state.common.employees,
    remarksData: state.settings.remarksData,
    countries: state.common.countries,
    enquiryDetail: state.enquiry.enquiryDetail,
    permissions: state.auth.permissions,
    allRelationshipTaxIdentificatins:
      state.common.allRelationshipTaxIdentificatins,
    incoterms: state.common.incoterms,
    freightList: state.so.freightList,
    addresses: state.customer.addresses,
    boRelationshipAddress: state.common.boRelationshipAddress,
    customerContactList: state.customer.contacts,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading,
    isProductLoaded: state.common.isProductLoaded

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSalesQuote,
      resetSQDetails,
      fetchCustomers,
      fetchSQDetail,
      fetchProducts,
      fetchTaxes,
      fetchUOM,
      addUOM,
      deleteUOM,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      deleteCustomers,
      addPaymentTerm,
      deletePaymentTerm,
      deleteAddress,
      deleteTax,
      resetRfqPoData,
      fetchRfqData,
      fetchDocuments,
      addDocumentName,
      deleteDocumentName,
      getNextTxNumber,
      deleteContact,
      fetchProjects,
      deleteProject,
      fetchUserPreferences,
      saveUserPrefrences,
      fetchAllEmployees,
      deleteEmployee,
      resetRemarksData,
      fetchStates,
      fetchAllRFQ,
      fetchRemarksTemplate,
      fetchCountries,
      fetchInquiryDetail,
      resetEnquiryDetail,
      fetchRelationshipTaxIdentifications,
      deleteCustomerTaxIdentifications,
      deleteRelationshipTaxIdentifications,
      createSalesQuoteDraft,
      fetchIncoterms,
      addIncoterm,
      deleteIncoterm,
      fetchAllFreightType,
      addFreightType,
      deleteFreightType,
      fetchCustomerDetailsByCustomerId,
      resetCustomerDetail,
      fetchAddresses,
      getRelationshipAddress,
      resetAddresses,
      fetchAllContacts,
      fetchSalesQuotes,
      fetchSalesQuoteDrafts,
      resetPaginatedData,
      deleteDocument,resetAllContacts
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SalesQuote);
