import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, STOCK_SUMMARY_ACTION_LIST } from '../../../../../static/constants';
import {  showToasterMessage ,getFinancialYear} from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchInventoryItemsByWarehouse = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios 
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getInventoryItemsByWarehouse?relationshipId=${payload.relationshipId}&warehouseMasterId=${payload.warehouseMasterId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: STOCK_SUMMARY_ACTION_LIST.STOCK_SUMMARY_LIST, data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        if (!err.__isRetryRequest) {
        } else {
          err.actionToCall = STOCK_SUMMARY_ACTION_LIST.STOCK_SUMMARY_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const getInventoryItemsByWarehouseCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getInventoryItemsByWarehouseCount?relationshipId=${payload.relationshipId}&warehouseMasterId=${payload.warehouseMasterId}`)
      .then(res => {
        dispatch({ type: STOCK_SUMMARY_ACTION_LIST.STOCK_SUMMARY_COUNT, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: STOCK_SUMMARY_ACTION_LIST.STOCK_SUMMARY_PAGE_RESET });
  }
}