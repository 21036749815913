import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegistrationFormSettingComponent from '../../../../../components/modal/modalBody/settings/RegistrationFormSetting';
import { pushModalToStack, popModalFromStack, fetchPaymentTerms, fetchProjects,
fetchSchoolSession, addSchoolSession, deleteSchoolSession,
fetchDocuments, addDocumentName, deleteDocumentName,
addPaymentTerm,deletePaymentTerm } from '../../../../../actions/commonActions';
import { saveRegistrationFormSetting, fetchRegistrationFormSetting, resetRegistrationFormSetting, 
    fetchAvailablePaymentGateway, saveAsDraftRegistrationForm } from './action';
import { fetchRegistrationFormSettingList } from '../RegisterFormList/action';
import EmailComponent from '../../../../../containers/drawer/recurringInvoiceEmail';
import { getMembershipOptionListing } from '../../../../membership/membershipOption/action';
import { getAllStudentForm } from '../../../../Student/studentFormList/action';
import { CONSTANTS, LEDGER_ACTION_LIST } from '../../../../../static/constants';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { fetchAllLedgerAccounts } from '../../../../finance/LedgerAccounts/action';
import { getAllDG } from'../../../../communication/distribution-group/action';
import { getSchoolCourse } from '../../../../schoolManagement/course/action';
import { fetchEmailTemplate } from '../EmailTemplate/action';
import { getMomentDateForUIReadOnly } from '../../../../../utils';
import { fetchAllUsers } from '../../../../settings/userManagement/action';
import {getClassList } from '../../../../../containers/schoolManagement/class/action';
import { getDepartmentList } from '../../../../schoolManagement/department/action';


const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class RegistrationFormSetting extends Component {
    constructor(props) {
        super(props);
        const defaultSalesLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES }) || {};
        const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
        this.state = {
            ledgerAccountIdInvoice: defaultSalesLedger.ledgerAccountId,
            questionList: [],
            isFormActive: 1,
            primaryContactForInvoicing : props.registrationType === 'student' ? 1 : 0,
            membershipOptions: [],
            relationshipBillingAddress,
            formHeading: '',
            registrationType: 'student',
            registrationFormSettingId: props.registrationFormSettingId,

            showFatherInfo: 1,
            showMotherInfo: 1,

            activeTabKey : '1',
            classFees : [],

            // draft data
            registrationFormSettingDraftId : props.formDraftData?.registrationFormSettingDraftId,
            ...JSON.parse(props.formDraftData?.registrationFormSettingData || '{}'),
            draftVersion : props.formDraftData?.version,
            isStopRegistrationAfterMaxStudent:1
            
        }

    }
    componentDidMount() {
        this.props.resetRegistrationFormSetting();
        const companyInfo = this.props.companyInfo || {};
        
        this.props.registrationFormSettingId && this.props.fetchRegistrationFormSetting({ relationshipId: companyInfo.relationshipId, registrationFormSettingId: this.props.registrationFormSettingId });
        this.props.fetchPaymentTerms({ relationshipId: companyInfo.relationshipId });
        this.props.fetchAvailablePaymentGateway({ relationshipId: companyInfo.relationshipId });
        // this.props.fetchProjects({ relationshipId: companyInfo.relationshipId });
        // this.props.getMembershipOptionListing({ relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 500 });
        this.props.fetchAllLedgerAccounts({ relationshipId: companyInfo.relationshipId });
        this.props.registrationType === 'student' && this.props.getSchoolCourse({ relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 500 });    
        
        // this.props.fetchEmailTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Recurring Sales Invoice' });
        this.props.fetchSchoolSession({ relationshipId: companyInfo.relationshipId });
        // this.props.fetchDocuments({ relationshipId: companyInfo.relationshipId });
        this.props.fetchAllUsers({relationshipId: companyInfo.relationshipId});

        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
            pageNumber: 1,
            pageSize: 100,
            formId: this.props.defaultSchoolForm
          };
        this.props.getClassList(payload);
        this.props.getDepartmentList({...payload, pageSize: 500});
    }

    componentWillReceiveProps(props) {
        if (props.registrationFormSetting && (props.registrationFormSetting || {}).registrationFormSettingId && props.paymentTerms?.length) {
            let formSetting = JSON.parse(props.registrationFormSetting?.formSetting);
            const paymentTerm = props.paymentTerms.length ? props.paymentTerms.find(ind => (ind.paymentTermId === formSetting?.paymentTermId)) || {} : {};
            const {registrationFormSetting} = props;

            this.setState({
                ...(formSetting || {}),
                formType: registrationFormSetting.formType,
                formHeading: registrationFormSetting.programName || 'My Awesome Title',
                registrationFormSettingId: registrationFormSetting.registrationFormSettingId,
                version: registrationFormSetting.version,
                createdByEmpId: registrationFormSetting.createdByEmpId || 0,
                paymentTerm,

                successMessage: registrationFormSetting.successMessage,

                isSendEmail: registrationFormSetting.emailMessage ? 1 : 0,
                isFormSetting: 1,
                emailMessage: registrationFormSetting.emailMessage,
                emailSubject: registrationFormSetting.emailSubject,
                emailDetails: registrationFormSetting.emailMessage ? { message: registrationFormSetting.emailMessage, subject: registrationFormSetting.emailSubject} : null,
                projectName: registrationFormSetting.projectName,
                projectMasterId: registrationFormSetting.projectMasterId,
                ledgerAccountIdPayment: registrationFormSetting.ledgerAccountIdPayment,
                isFormActive: registrationFormSetting.isFormActive,
                registrationType: registrationFormSetting.registrationType || 'student',
                collectStudentInfo: registrationFormSetting.registrationType === 'student' ? 1 : 0,
                isGenerateInvoice: registrationFormSetting.isGenerateInvoice ? 1 : 0,
                isAllowParentPaymentPlan: registrationFormSetting.isAllowParentPaymentPlan ? 1: 0,
                invoiceCycle: registrationFormSetting.isGenerateRecurringInvoice === 1 ?  'monthly' : ( registrationFormSetting.isGenerateInvoice === 1 ? 'oneTime': ''),
                recurringInvoiceSettings: registrationFormSetting.recurringInvoiceSettings ? JSON.parse(registrationFormSetting.recurringInvoiceSettings) : null,
                invoiceItemDescription: registrationFormSetting.invoiceItemDescription,
                ledgerAccountIdInvoice: registrationFormSetting.ledgerAccountIdInvoice,
                invoiceItem: registrationFormSetting.onetimeInvoiceItem ? JSON.parse(registrationFormSetting.onetimeInvoiceItem) : null,
                paymentPlan: registrationFormSetting.paymentPlan ? JSON.parse(registrationFormSetting.paymentPlan) : null,
                courseId: registrationFormSetting.courseId, 
                reminderList: registrationFormSetting.invoiceReminder ? JSON.parse(registrationFormSetting.invoiceReminder) : [],
                subcourseAndFeeList: registrationFormSetting.subcourse ? JSON.parse(registrationFormSetting.subcourse) : [],
                selectedPaymentGateway: formSetting.paymentGateway,
                selectedSessionName: formSetting.sessionStart ? getMomentDateForUIReadOnly({ date: formSetting.sessionStart, format}) + ' - ' + getMomentDateForUIReadOnly({ date: formSetting.sessionEnd, format }) : null,
                isPublishParentPortal: registrationFormSetting.isPublishParentPortal ? 1: 0,
                discountRuleList: registrationFormSetting.invoiceDiscountRule ? JSON.parse(registrationFormSetting.invoiceDiscountRule) : [],
                userPermissions: registrationFormSetting.orgRegFormSettingPermissionList ? registrationFormSetting.orgRegFormSettingPermissionList.map(({ relEmployeeId }) => relEmployeeId) : [],
                schoolSessionId: registrationFormSetting.schoolSessionId,
                orgRegFormSettingPermissionList: registrationFormSetting.orgRegFormSettingPermissionList,
                departmentId: registrationFormSetting.departmentId,
                isCollectPaymentAtRegistration: registrationFormSetting.isCollectPaymentAtRegistration,
                collectOnlinePayment: formSetting.collectOnlinePayment,
                isStopRegistrationAfterMaxStudent: formSetting.isStopRegistrationAfterMaxStudent === 1 ? 1 : 0
            })
         
        };
        if (!(this.state.paymentTerm && this.state.paymentTerm.termName) && props.paymentTerms?.length) {
            let paymentTerm = (this.props.paymentTerms.find(ind => (ind.termName === 'NET 7'))) || {};
            this.setState({ paymentTerm });
        }
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const onlineThemes = JSON.parse(props.companyInfo.onlineThemes || '{}');
            this.setState({
                textColor: onlineThemes.textColor || '#ffffff',
                bgColor: onlineThemes.bgColor || '#31c0be',
            })
        }
       

    }

    render() {
        return <>
            {this.state.emailDetailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <RegistrationFormSettingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        registrationFormSetting: state.settings.registrationFormSetting,
        paymentTerms: state.common.paymentTerms,
        availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
        projectList: state.common.projectList,
        membershipOptionList: state.membershipReducer.membershipOptionList,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        dgList: state.communicationReducer.dgList,
        courseList: state.school.courseList,
        templateData: state.settings.templateData,
        schoolSessionList: state.common.schoolSessionList,
        documentList: state.common.documentList,
        allCompanyUsers: state.settings.allCompanyUsers,
        defaultSchoolForm: state.common.defaultSchoolForm,
        classList: state.school.classList,
        departmentList: state.school.departmentList,
        saveLoading: state.common.saveLoading
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveRegistrationFormSetting,
    fetchRegistrationFormSetting,
    resetRegistrationFormSetting,
    pushModalToStack,
    fetchRegistrationFormSettingList,
    popModalFromStack,
    fetchPaymentTerms,
    fetchAvailablePaymentGateway,
    fetchProjects,
    getMembershipOptionListing,
    getAllStudentForm,
    fetchAllLedgerAccounts,
    getAllDG, 
    getSchoolCourse,
    fetchEmailTemplate,
    fetchDocuments, addDocumentName, deleteDocumentName,
    fetchSchoolSession, addSchoolSession, deleteSchoolSession,
    fetchAllUsers,
    getClassList,
    getDepartmentList,
    saveAsDraftRegistrationForm,
    addPaymentTerm,
    deletePaymentTerm
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormSetting);