import React from "react";

import { Button, Col, Divider, Row  } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";



function PaymentMethod(props) {
   const addressInfo = (props.paymentAddressInfo||{})?.customer
   const cardInfo = (props.paymentAddressInfo||{})?.card
    const billingAddress= addressInfo?.billing_address;
    const maskCardNumber = (number) => {
      const masked = (number||"").slice(0, -4).replace(/\d/g, '*');
      const lastFour = (number||"").slice(-4);
      const formatted = masked + lastFour;
      // Add space after every 4 characters
      return formatted.replace(/(.{4})/g, '$1 ');
    };


    const ModalBody = (props)=>{
       const userInfo = props.address;
        return(
            <>
            
                <div>
                  <div className=" mb20">
                   
                     <p>Enter your updated billing address for future invoices and statements.</p>
                  </div>

                  <Row gutter={[16,16]} >

                     <Col span={12}>
                     <div className="i-label">First Name</div>
        <TextBox
          placeholder="Enter FirstName"
          type="text"
          value={userInfo?.first_name||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
            
          }}
        />
                     </Col>

                     <Col span={12}>
                     <div className="i-label">Last Name</div>
        <TextBox
          placeholder="Enter LastName"
          type="text"
          value={userInfo?.last_name||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
             
          }}
        />
                     </Col>

                     <Col span={24}>
                     <div className="i-label">Address 1</div>
        <TextBox
          placeholder="Enter Address 1"
          type="text"
          value={userInfo?.line1||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
             
          }}
        />
                     </Col>
                     <Col span={24}>
                     <div className="i-label">Address 2(Optional)</div>
        <TextBox
          placeholder="Enter Address 2"
          type="text"
          value={userInfo?.line2||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
             
          }}
        />
                     </Col>
                   
                     <Col span={12}>
                     <div className="i-label">City</div>
        <TextBox
          placeholder="Enter CityName"
          type="text"
          value={userInfo?.city||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
             
          }}
        />
                     </Col>

                     <Col span={12}>
                     <div className="i-label">Zipcode</div>
        <TextBox
          placeholder="Enter zipcode"
          type="number"
          value={userInfo?.zip||""}
          maxLength={8}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
             
          }}
        />
                     </Col>
                     <Col span={12}>
                     <div className="i-label">State</div>
        <TextBox
          placeholder="Enter StateName"
          type="text"
          value={userInfo?.state||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
             
          }}
        />
                     </Col>
                     <Col span={12}>
                     <div className="i-label">Country</div>
        <TextBox
          placeholder="Enter Country"
          type="text"
          value={userInfo?.country||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
             
          }}
        />
                     </Col>
                  </Row>
                </div>
           
            </>
        )
    }
    return (
        <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   

    <div>
    <div className="account-heading">
    <p className="heading">Payment Method</p>
    <p className="sub-heading">
    Update and manage your payment methods, including credit/debit cards and other payment options.   </p>

    </div>
    <div className="account-information">
    <div className="payment-info">


      <div className="add-new-card">
        <div className="payment-card">
         <div className="card-header">
            <span className="text-card">{cardInfo?.funding_type} Card</span>
            <span className="primary">PRIMARY</span>
         </div>
         <div>
            <span className="payment-logo">
            <i
class=
"fi fi-brands-visa logo"
></i> 
<span className="card-number"> {maskCardNumber(cardInfo?.masked_number)}</span>

            </span>
         </div>
         <div className="mt20">
         <Button type="" onClick={()=>{}}>Update Payment Method</Button>
         </div>
         </div>
         
      </div>

<div>
    <Button type="" onClick={()=>{}}>Add new card</Button>
</div>
</div>
    </div>
    <div className="divider mb0"></div>
   <div>
    
   <div className="account-heading">
    <p className="heading">Billing Information</p>
    <p className="sub-heading">
    View and manage your billing information, including invoices and payment methods.   </p>

    </div>

    <div className="address">
        <div>
           <span><b>{`${billingAddress?.first_name||""} ${billingAddress?.last_name||""}`}</b></span>
           <p>{`${billingAddress?.line1||""} , ${billingAddress?.city||""} ${billingAddress?.state_code||""} ${billingAddress?.zip||""} ${billingAddress?.country||""}`}</p>
        </div>
        <div>
        <Button type="" onClick={()=>{

const modalData = {
   title: "Update Billing Information",
   modalBody: <ModalBody address={billingAddress}/>,
   hideFooter:true,
   width: '734px',
   
};
props.pushModalToStack(modalData);

        }}>Update Billing Information</Button>   
        </div>
    </div>
   </div>
    </div>
   </div>
   

   
   


</div>
        </div>
        
    )
}
export default PaymentMethod;