import React, { useState } from "react";
import { TextBox } from "../../../general/TextBox";
import { Row, Col} from "antd";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";


function BrevoComp(props){
   const [userDefinedName,setUserDefinedName] = useState("");  
   const [brevoApiKey,setBrevoApiKey] = useState("");

 
    return (
        <>
            <div className="header-action-container">
                <div className="information">
                    <div className="pl30">
                        <Row>
                            <Col span={6}>
                                Username
                            </Col>
                            <Col span={16}>
                                <TextBox
                                    type="text"
                                    value={userDefinedName}
                                    onChange={(e) => {
                                        setUserDefinedName(e.target.value);
                                    }}
                                    placeholder="Username"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col span={6} > Api Key</Col>
                            <Col span={16}>
                                <TextBox
                                    placeholder="API Key"
                                    type="text"
                                    value={brevoApiKey}
                                    onChange={(e) => {
                                        setBrevoApiKey( e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                
                <div className="form-footer">
                    <CustomButton
                        intlId="confirmation.cancel"
                        defaultMessage=""
                        type="default"
                        key={0}
                        style={{ marginRight: 8 }}
                        onClick={() => {
                            props.hideModal();                           
                        }}
                    />
                    <CustomButton
                        intlId="confirmation.submit"
                        defaultMessage=""
                        htmlType="submit"
                        key={1}
                        onClick={() => {
                            props.updateState({ submittedOnce: true });

                            const payload = {
                                relationshipId: (props.companyInfo || {}).relationshipId,
                                brevoApiKey: brevoApiKey || "",
                                userDefinedName: userDefinedName || ""
                            };
                            const modalData = {
                                modalBody: (
                                    <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />
                                ),
                                handleSubmit: () => {
                                    props.saveBrevo(payload,props);
                                    props.popModalFromStack();
                                },
                            };
                            props.pushModalToStack(modalData);
                        }}
                    />
                </div>
               
            </div>
        </>
    )
};

export default BrevoComp;
