import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { CustomAgGrid } from '../../../general/CustomAgGrid';
import { CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY, MODAL_TYPE } from '../../../../static/constants';
import { getLabelName } from '../../../modal/modalBody/customerInfo';
import * as filter from 'lodash.filter';

class DocumentName extends Component {

    addItemConfirmationModal = (props, text, modalType, params) => {
        const { pushModalToStack, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={` `}
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.SALUTATION: {
                addFunc = props.addSalutation;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId })
                props.popModalFromStack();
                setTimeout(() => {
                    params.api.stopEditing();
                    this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                    this.gridApi.refreshCells({ force: true });
                    this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                    this.gridApi.refreshCells({ columns: ["action"], force: true });
                    params.api.startEditingCell({
                        rowIndex: params.node.rowIndex,
                        colKey: params.column.getId(),
                    });
                }, 2000);
            },
        };
        pushModalToStack(modalData);
    }

    getModalTitle = (modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.SALUTATION: {
                title = <FormattedMessage id='addItem.text.salutation' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType, params) => {
        const formData = payload.formData;
        const { pushModalToStack } = props;
        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType, params);
        } else {
            const data = {
                title: this.getModalTitle(modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType, params);
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                }
            };
            pushModalToStack(data);
        }
    }

    deleteClickHandler = (props, modalType, payload ,params) => {
        const { pushModalToStack, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.SALUTATION: {
                deleteFunc = props.deleteSalutation;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }
        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId });
                setTimeout(() => {
                    params.api.stopEditing();
                    this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                    this.gridApi.refreshCells({ force: true });
                    this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                    this.gridApi.refreshCells({ columns: ["action"], force: true });
                    params.api.startEditingCell({
                        rowIndex: params.node.rowIndex,
                        colKey: params.column.getId(),
                    });
                }, 2000);

            },
        };
        pushModalToStack(modalData);
    }

    render() {

        const agGridStyle = {
            height: '100%',
            width: '100%'
        };

        const onFirstDataRendered = (params) => {
            params.api.sizeColumnsToFit();
        }
        const getRowHeight = (params) => {
            return 30;
        }


        const columnDefs = [
            {
                headerName: "#",
                resizable: true,
                width: 60,
                rowDrag: true,
                cellRendererFramework: function (link) {
                    return <div>
                        {link.rowIndex + 1}
                    </div>
                }
            },
            {
                headerName: "Document Name",
                field: "docName",
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                width: 268,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {   
                        disabled: obj.node.data.isSystemGenerated,                          
                        maxLength: 30,
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        Is Default
                      <Tooltip placement="top" title="When Check this Document name will be selected in the new transaction" trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "isDefault",
                editable: true,
                resizable: true,
                width: 200,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        disabled: obj.node.data.isDefault,
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        },
                        className: obj.node.data.isToEdit ? 'chebox' : ''
                    }
                },
                cellRendererFramework: (params) => {
                    // return params.node.data.isDefault ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Default" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Default" />;
                    return params.node.data.isDefault ? 'Yes' : 'No';
                
                },
                
            },
            {
                field: 'action',  lockPosition: true,
                pinned: 'right',
                width: 120,
                cellRendererFramework: (params) => <div>
                    {(params.data.origination !== 'System') ? <div>

                        <div>
                            {params.node.data.isToEdit &&
                                <div className="table-cell-action">
                                    <Link onClick={() => {
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.relationshipId = (this.props.companyInfo || {}).relationshipId
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        this.props.updateOrCreateDocName(this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data, this.props)
                                        this.gridApi.refreshCells({ force: true });
                                    }} >
                                        <i className="fa fa-save" />
                                    </Link>
                                    <Link onClick={() => {
                                        params.api.stopEditing(true);
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        this.gridApi.refreshCells({ force: true });
                                        this.props.fetchDocuments({relationshipId : (this.props.companyInfo || {}).relationshipId})
                                    }} >
                                        <i className="fa fa-ban" />
                                    </Link>
                                </div>
                            }

                            {(!params.node.data.isToEdit) &&
                                <div className="table-cell-action" >
                                    <Link onClick={() => {
                                        this.gridApi.forEachNode((rowNode, index) => {
                                            this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                        });
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                                        this.gridApi.refreshCells({ columns: ["action"], force: true });
                                        var startEditingParams = {
                                            rowIndex: params.node.rowIndex,
                                            colKey: params.column.getId(),
                                        };
                                        params.api.startEditingCell(startEditingParams);
                                    }}>
                                        <i className="fa fa-edit" />
                                    </Link>
                                    {(params.node.data.isSystemGenerated || params.node.data.isDefault) ? <span><i style={{color: 'black', fontSize: '15px'}} title={this.props.intl.formatMessage({ id: params.node.data.isSystemGenerated ? 'cant.delete.systemGenerated' : 'cant.delete.default' })} className="fa fa-lock" /></span> :
                                        <Link onClick={() => {
                                            let rowData = this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data;
                                            this.props.deleteDocumentName({ id: rowData.documentNameId, relationshipId: rowData.relationshipId });
                                            this.gridApi.updateRowData({ remove: [rowData] });
                                        }}>
                                            <i className="fa fa-remove" />
                                        </Link>
                                    }
                                </div>}
                        </div>
                    </div>
                        : <div></div>
                    }
                </div>
            }
        ]

        const onGridReady = (params) => {
            this.gridApi = params.api;
        }
        return (
            <Fragment>
                <div className='bank-info'>
                    <div className="ag-theme-balham" style={agGridStyle} >
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={filter(this.props.documentList || [], (document) => document.txType ===  CONSTANTS_TRANSACTIONS_KEY[this.props.tabDocumentOptions.txType])}
                            rowDragManaged={true}
                            disableFocusOnClickOutside={true}
                            suppressClickEdit={true}
                            noSingleClickEdit={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            getRowHeight={getRowHeight}
                            editType={'fullRow'}
                            onGridReady={onGridReady}
                        >
                        </CustomAgGrid>

                        <Button className="add-new-record " onClick={
                        () => {
                            let newRowIndex = 0;
                            this.gridApi.forEachNode((rowNode, index) => {
                                newRowIndex = index;
                                this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                            });
                            this.gridApi.stopEditing();
                            this.gridApi.updateRowData({ add: [{ isToEdit: true, txType : this.props.docNameKey }] });
                            this.gridApi.startEditingCell({
                                rowIndex: newRowIndex + 1,
                                colKey: "docName"
                            });
                            this.gridApi.refreshCells({ force: true });
                        }
                    }>Add Line  </Button>
                    </div>
                            
                </div>
            </Fragment>
        )
    }
}

export default DocumentName;
