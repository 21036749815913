import React, { useMemo, useState, useEffect } from "react";
import { Col, Row, Tabs, Upload, Button, Switch, Modal, Card } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { TextBox } from "../../general/TextBox";
import { FormattedMessage } from "react-intl";
import { getAWSFileURL, getPermissionData } from "../../../utils";
import { CONSTANTS, MODULE_CODED_VALUES } from "../../../static/constants";

const { TabPane } = Tabs;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const BannerManagement = (props) => {
  const { permissions, bannerSetting, uploadBannerApi } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionData = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_APPEARANCE);
  }, []);

  // State for desktop banners
  const [fileList1, setFileList1] = useState([]);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [singleFileList1, setSingleFileList1] = useState([]);
  const [imageUrl1, setImageUrl1] = useState();
  const [desktopBannerLink, setDesktopBannerLink] = useState('');

  // State for mobile banners
  const [fileList, setFileList] = useState([]);
  const [inActiveFileList, setInActiveFileList] = useState([]);
  const [inActiveFileList1, setInActiveFileList1] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [singleFileList, setSingleFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [mobileBannerLink, setMobileBannerLink] = useState('');

  // State for preview
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  // Load existing banners from bannerSetting
  useEffect(() => {
    if (bannerSetting) {
      try {
        // Desktop Active Banners
        if (bannerSetting.ecomBannerImage?.filter(i => i.type === "Desktop" && i.isActive)) {
          const desktopBanners = bannerSetting.ecomBannerImage
            ?.filter(i => i.type === "Desktop" && i.isActive)
            ?.map((banner, index) => {
              const awsUrl = getAWSFileURL(
                banner.image,
                banner.bucketName,
                CONSTANTS.TYPE_IMAGE
              );
              
              // Validate URL before setting
              if (!awsUrl) {
                console.error('Invalid AWS URL for banner:', banner);
                return null;
              }

              return {
                uid: `desktop-${index}`,
                name: `desktop-banner-${index}`,
                status: 'done',
                url: awsUrl,
                thumbUrl: awsUrl, // Add thumbUrl for antd Upload preview
                data: { ...banner }
              };
            })
            .filter(Boolean); // Remove null entries

          setFileList1(desktopBanners || []);
        }

        // Mobile Active Banners
        if (bannerSetting.ecomBannerImage?.filter(i => i.type === "Mobile" && i.isActive)) {
          const mobileBanners = bannerSetting.ecomBannerImage
            ?.filter(i => i.type === "Mobile" && i.isActive)
            ?.map((banner, index) => {
              const awsUrl = getAWSFileURL(
                banner.image,
                banner.bucketName,
                CONSTANTS.TYPE_IMAGE
              );

              if (!awsUrl) {
                console.error('Invalid AWS URL for banner:', banner);
                return null;
              }

              return {
                uid: `mobile-${index}`,
                name: `mobile-banner-${index}`,
                status: 'done',
                url: awsUrl,
                thumbUrl: awsUrl,
                data: { ...banner }
              };
            })
            .filter(Boolean);

          setFileList(mobileBanners || []);
        }

        // Similar changes for inactive banners...
        // Desktop Inactive
        if (bannerSetting.ecomBannerImage?.filter(i => i.type === "Desktop" && !i.isActive)) {
          const inactiveDesktop = bannerSetting.ecomBannerImage
            ?.filter(i => i.type === "Desktop" && !i.isActive)
            ?.map((banner, index) => {
              const awsUrl = getAWSFileURL(
                banner.image,
                banner.bucketName,
                CONSTANTS.TYPE_IMAGE
              );

              if (!awsUrl) {
                console.error('Invalid AWS URL for banner:', banner);
                return null;
              }

              return {
                uid: `desktop-inactive-${index}`,
                name: `desktop-banner-inactive-${index}`,
                status: 'done',
                url: awsUrl,
                thumbUrl: awsUrl,
                data: { ...banner }
              };
            })
            .filter(Boolean);

          setInActiveFileList1(inactiveDesktop || []);
        }

        // Mobile Inactive
        if (bannerSetting.ecomBannerImage?.filter(i => i.type === "Mobile" && !i.isActive)) {
          const inactiveMobile = bannerSetting.ecomBannerImage
            ?.filter(i => i.type === "Mobile" && !i.isActive)
            ?.map((banner, index) => {
              const awsUrl = getAWSFileURL(
                banner.image,
                banner.bucketName,
                CONSTANTS.TYPE_IMAGE
              );

              if (!awsUrl) {
                console.error('Invalid AWS URL for banner:', banner);
                return null;
              }

              return {
                uid: `mobile-inactive-${index}`,
                name: `mobile-banner-inactive-${index}`,
                status: 'done',
                url: awsUrl,
                thumbUrl: awsUrl,
                data: { ...banner }
              };
            })
            .filter(Boolean);

          setInActiveFileList(inactiveMobile || []);
        }
      } catch (error) {
        console.error('Error processing banner settings:', error);
      }
    }
  }, [bannerSetting]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  // Handle desktop banner upload
  const handleModalOk1 = async () => {

    if (singleFileList1.length > 0) {

      const payload = {
        file: singleFileList1[0],
        setting: { ...props.bannerSetting, isActive: props.autoSlider ? 1 : 0, type: "Desktop", relationshipId: props.companyInfo?.relationshipId },
        bucketName: props.companyInfo?.bucketName
      }

      props.createBannerSetting(payload, props);
      // Reset modal states
      setSingleFileList1([]);
      setModalOpen1(false);
      setDesktopBannerLink('');
      setImageUrl1(null);
    }
  };

  // Handle mobile banner upload
  const handleModalOk = async () => {
    if (singleFileList.length > 0) {

      const payload = {
        file: singleFileList[0],
        setting: { ...props.bannerSetting, isActive: props.autoSlider ? 1 : 0, type: "Mobile", relationshipId: props.companyInfo?.relationshipId },
        bucketName: props.companyInfo?.bucketName
      }

      props.createBannerSetting(payload, props);
      // Reset modal states
      setSingleFileList([]);
      setModalOpen(false);
      setMobileBannerLink('');
      setImageUrl(null);

    }
  };

  return (
    <div className="store-model-container-f">
      <div className="menuEstore-Container">
        <Card className="header-structure-card">
          <div>
            <div className="heading">Promotional banners</div>
            <p>Grab your store visitor's attention by promotional banners displayed on top of your homepage.</p>
          </div>

          <div className="menuEstore-structure-card-content">
            <Row>
              <Col span={12} className="text-right">
                <FormattedMessage id="Auto Slider" defaultMessage="" />
              </Col>
              <Col span={12}>
                <Switch
                  checked={props.autoSlider}
                  onClick={(checked) => {
                    props.updateState({ autoSlider: checked });

                    const payload = {
                      setting: { ...props.bannerSetting, isActive: checked ? 1 : 0, type: "Desktop", relationshipId: props.companyInfo?.relationshipId },
                      bucketName: props.companyInfo?.bucketName

                    }

                    props.createBannerSetting(payload, props);
                  }}
                />
              </Col>
            </Row>

            {props.autoSlider && (
              <div>
                <Row>
                  {/* {(fileList.length || fileList1.length) > 0 && (
                    <Col span={12}>
                      <TextBox
                        placeholder="Auto Slider Duration in Seconds"
                        value={props.autoTimer}
                        onChange={(e) => {
                          props.updateState({ autoTimer: e.target.value });
                        }}
                      />
                    </Col>
                  )} */}
                </Row>
                <div className="banner-management">
                  <Row>
                    <Col span={24}>
                      <Tabs
                        activeKey={props.activeTabKey || "Active"}
                        onChange={(key) => {
                          props.updateState({
                            selectedItems: [],
                            activeTabKey: key,
                          });
                        }}
                        type="line"
                      >
                        <TabPane tab="Active" key="Active">
                          {/* Desktop Banner Upload Section */}
                          <Row>
                            <Col span={24}><h2>For Desktop ({fileList1?.length}/6)</h2></Col>
                            <Col span={24}>
                              <div className="desktop-banner">
                                <Upload
                                  listType="picture-card"
                                  fileList={fileList1}
                                  onPreview={handlePreview}
                                  openFileDialogOnClick={false}
                                  onRemove={(data)=>{
                                    const payload = {...data.data,isActive:0}
                                    props.updateImageStatus(payload,props)
                                  }}
                                >
                                  <button
                                    style={{ border: 0, background: 'none' }}
                                    type="button"
                                    onClick={() => setModalOpen1(true)}
                                  >
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Add Banner</div>
                                  </button>
                                </Upload>

                                <Modal
                                  visible={modalOpen1}
                                  title="Upload Image"
                                  width={400}
                                  centered={true}
                                  okText={'Add Banner'}
                                  onOk={handleModalOk1}
                                  onCancel={() => {
                                    setSingleFileList1([]);
                                    setModalOpen1(false);
                                    setDesktopBannerLink('');
                                  }}
                                >
                                  <div className="modal-banner">
                                    <Upload
                                      listType="picture-card"
                                      fileList={singleFileList1}
                                      showUploadList={false}
                                      onChange={({ fileList: newFileList }) => setSingleFileList1(newFileList)}
                                      beforeUpload={(file) => {
                                        setImageUrl1(file);
                                        return false;
                                      }}
                                    >
                                      {singleFileList1.length === 0 ? (
                                        <div>
                                          <Button type="button">
                                            <div>Upload Image</div>
                                          </Button>
                                          <div>Recommended size (360px*324px)</div>
                                        </div>
                                      ) : (
                                        <img
                                          src={URL.createObjectURL(imageUrl1)}
                                          alt="avatar"
                                          style={{ width: '100%' }}
                                        />
                                      )}
                                    </Upload>
                                  </div>

                                  <div>
                                    <FormattedMessage
                                      id="Link Banner To (Optional)"
                                      defaultMessage=""
                                    />
                                    <TextBox
                                      placeholder="http://example.com"
                                      value={desktopBannerLink}
                                      onChange={(e) => setDesktopBannerLink(e.target.value)}
                                    />
                                  </div>
                                </Modal>
                              </div>
                            </Col>
                          </Row>

                          {/* Mobile Banner Upload Section */}
                          <Row>
                            <Col span={24}><h2>For Mobile ({fileList?.length}/6)</h2></Col>
                            <Col span={24}>
                              <div>
                                <Upload
                                  listType="picture-card"
                                  fileList={fileList}
                                  onPreview={handlePreview}
                                  openFileDialogOnClick={false}
                                >
                                  <button
                                    style={{ border: 0, background: 'none' }}
                                    type="button"
                                    onClick={() => setModalOpen(true)}
                                  >
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Add Banner</div>
                                  </button>
                                </Upload>

                                <Modal
                                  visible={modalOpen}
                                  title="Upload Image"
                                  width={400}
                                  centered={true}
                                  okText={'Add Banner'}
                                  onOk={handleModalOk}
                                  onCancel={() => {
                                    setSingleFileList([]);
                                    setModalOpen(false);
                                    setMobileBannerLink('');
                                  }}
                                >
                                  <div className="modal-banner">
                                    <Upload
                                      listType="picture-card"
                                      fileList={singleFileList}
                                      showUploadList={false}
                                      onChange={({ fileList: newFileList }) => setSingleFileList(newFileList)}
                                      beforeUpload={(file) => {
                                        setImageUrl(file);
                                        return false;
                                      }}
                                    >
                                      {singleFileList.length === 0 ? (
                                        <div>
                                          <Button type="button">
                                            <div>Upload Image</div>
                                          </Button>
                                          <div>Recommended size (360px*324px)</div>
                                        </div>
                                      ) : (
                                        <img
                                          src={URL.createObjectURL(imageUrl)}
                                          alt="avatar"
                                          style={{ width: '100%' }}
                                        />
                                      )}
                                    </Upload>
                                  </div>

                                  <div>
                                    <FormattedMessage
                                      id="Link Banner To (Optional)"
                                      defaultMessage=""
                                    />
                                    <TextBox
                                      placeholder="http://example.com"
                                      value={mobileBannerLink}
                                      onChange={(e) => setMobileBannerLink(e.target.value)}
                                    />
                                  </div>
                                </Modal>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tab="Previously Used" key="Previous">
                          {/* Desktop Banner Upload Section */}
                          <Row>
                            <Col span={24}><h2>For Desktop ({inActiveFileList1?.length}/6)</h2></Col>
                            <Col span={24}>
                              <div className="desktop-banner">
                                <Upload
                                  listType="picture-card"
                                  fileList={inActiveFileList1}
                                  onPreview={handlePreview}
                                  openFileDialogOnClick={false}
                                  onRemove={(file)=>{
                                    props.deleteBanner(file?.data,props);
                                  }}
                                >
                                 
                                </Upload>



                              </div>
                            </Col>
                          </Row>

                          {/* Mobile Banner Upload Section */}
                          <Row>
                            <Col span={24}><h2>For Mobile ({inActiveFileList?.length}/6)</h2></Col>
                            <Col span={24}>
                              <div>
                                <Upload
                                  listType="picture-card"
                                  fileList={inActiveFileList}
                                  onPreview={handlePreview}
                                  openFileDialogOnClick={false}
                                  onRemove={(file)=>{
                                    props.deleteBanner(file?.data,props);
                                    
                                  }}
                                >
                                 
                                </Upload>


                              </div>
                            </Col>
                          </Row>

                        </TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>

      {/* Preview Modal */}
      <Modal
        visible={previewOpen}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default BannerManagement;