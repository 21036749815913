import axios from "axios";
import config from "../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../utils";
import { COMMON_ACTIONS } from "../../../../static/constants";
import { E_STORE } from "../../../../static/constants";



export const postCategory = (payload ,props)=>{
     
  return dispatch => {

    //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/blogCategory/addBlogCategory`,
      payload,
      )
          .then(response => {
            if(response.status === 200){
              
                 
              const payload1 ={
                pageNumber: props.pageNumber,
                pageSize: props.pageSize,
                relationshipId: payload.relationshipId
              }
              props.fetchAllCategoriesWithSubCategories(payload1)
              showToasterMessage({
                messageType: "success",
                description: "Save Succssfully.",
              });
            }
            else{
              return showToasterMessage({
                messageType: "success",
                description: response.data?.message,
              });
            }
            //props.getCategory();
          })
          .catch(err => {
            //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
    }
}
export const fetchAllCategoriesWithSubCategories = (payload,props) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios.get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/blogCategory/getBlogCategory?relationshipId=${payload.relationshipId}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`)
        .then(response => {
          const data = {
            pageNo: payload.pageNumber,
            list: response.data,
          };
          dispatch({ type: E_STORE.BLOG_CATEGORY_LIST, data });
        })
        .catch(err => {
          console.error(err);
        }).finally(()=>{
          dispatch({
            type: COMMON_ACTIONS.HIDE_LIST_LOADER,
          });
        })
    }
};
