import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
//import * as find from 'lodash.find';
import {
  CONSTANTS,
  PERMISSION_VALUES,
  MODULE_CODED_VALUES,
} from "../../../../../static/constants";
import SODetail from "../../../../../containers/modal/modalBody/customer/SODetail";
import PackageDetail from "../../../../../containers/modal/modalBody/customer/PackageDetail";
//import ShipmentDetail from '../../../../../containers/modal/modalBody/customer/ShipmentDetail';
import PackageIcon from "../../../../../assets/images/packages.gif";
import {
  checkACLPermission,
  getMomentDateForUIReadOnly,
} from "../../../../../utils";
import Package from "../../../../../containers/customer/salesOrder/package";

const PackageInformation = (props) => {
  const { permissions } = props;
  const primaryPerm = permissions.primary;

  const openSoDetails = (data) => {
    props.hideModal();
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId,
    };
    props.pushModalToStack({
      modalBody: <SODetail {...props} soPayload={soPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
      handleSubmit: () => {},
    });
  };

  const openPackageDetails = (data) => {
    props.hideModal();
    const packagePayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId,
      soPackageMasterId: data.soPackageMasterId,
    };
    props.pushModalToStack({
      modalBody: <PackageDetail {...props} packagePayload={packagePayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  // const openDeliveryNotesDetails = (data) => {
  //     props.hideModal();
  //     const shipmentData = find(props.soShipmentList, { shipmentMasterId: data.shipmentMasterId }) || {};
  //     const shipmentPayload = {
  //         customerId: data.customerId,
  //         shipmentMasterId: data.shipmentMasterId
  //     }
  //     props.pushModalToStack({
  //         modalBody: <ShipmentDetail {...props} shipmentData={shipmentData} shipmentPayload={shipmentPayload} />,
  //         width: '100%',
  //         hideFooter: true,
  //         wrapClassName: 'modal-custom-detail',
  //         handleSubmit: () => {
  //         }
  //     });
  // }

  const getPackedQuantity = (item) => {
    let quantity = 0;
    item.customerSOPackageDetailsList.map((e) => {
      return (quantity += e.quantityPacked);
    });

    return quantity;
  };

  // const createShipment = (props, packageObj) => {
  //     if (packageObj.qualityChecklistPackageList && packageObj.qualityChecklistPackageList.length && !props.qualityChecklistSetting.isGenerateShipmentWithoutQualityCheck && packageObj.qualityChecklistStatus !== 'Completed') {
  //         return showToasterMessage({ messageType: 'error', description: 'Shipment can not be generate without quality check' });
  //     }
  //     props.hideModal();
  //     props.history.push({
  //         pathname: '/admin/so/shipment',
  //         state: {
  //             customerId: props.soData.customerId,
  //             soMasterId: props.soData.salesOrderMasterId,
  //             packageNumber: packageObj.packageNumber
  //         }

  //     })
  // }
  return (
    <Fragment>
      <div className="modal-table-container">
        <div className="table-heading">
          <FormattedMessage id="salesOrderDetails.text" defaultMessage="" />
        </div>
        <table>
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id="package.packageDetail.soNo"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.listing.header.date"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.form.gridHeader.qtyOrdered"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.listing.header.qtyPacked"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="modal.txDetails.package.qtyYetToPack"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="modal.txDetails.package.soStatus"
                  defaultMessage=""
                />
              </th>
              {/* {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER_PACKAGE, PERMISSION_VALUES.CREATE) && <th><FormattedMessage id='package.productDetail.action' defaultMessage='' /></th>} */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    openSoDetails(props.soData);
                  }}
                >
                  {props.soData.soNumber}
                </div>
              </td>
              <td>
                {(props.soData.salesOrderDate || "") &&
                  getMomentDateForUIReadOnly({
                    date: props.soData.salesOrderDate,
                    format: CONSTANTS.DISPLAY_DATE_FORMAT,
                  })}
              </td>
              <td>{props.soDetail?.totalQtyOrdered}</td>
              <td>{props.soDetail?.totalQtyPacked}</td>
              <td>
                {props.soDetail?.totalQtyOrdered && props.soDetail?.totalQtyPacked
                  ? props.soDetail?.totalQtyOrdered - props.soDetail?.totalQtyPacked
                  : ""}
              </td>
              <td>
                <div
                  className={[
                    "status-code",
                    props.soData.status?.toLowerCase(),
                  ].join(" ")}
                >
                 {props.soData.status ? <FormattedMessage id= {props.soData.status} defaultMessage=""/>:""}
                </div>
              </td>
              {/* {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER_PACKAGE, PERMISSION_VALUES.CREATE) &&  <td>
                                    { props.soData.statusPackage !== 'fulfilled' ?
                                    <Link onClick={()=>{
                                        props.hideModal()
                                    }}
                                        to={
                                            {
                                                pathname: '/admin/so/package',
                                                state: {
                                                    customerId: props.soData.customerId,
                                                    soMasterId: props.soData.salesOrderMasterId
                                                }
                                            }}
                                    >
                                        <FormattedMessage id='modal.txDetails.package.createPackage' defaultMessage='' />
                                    </Link> :  <span className={["status-code", props.soData.statusPackage.toLowerCase()].join(' ')}>
                                    <FormattedMessage id={props.soData.statusPackage} defaultMessage='' />
                                        </span>}
                                    </td>} */}
            </tr>
          </tbody>
        </table>

        <div className="table-heading">
          {props.soPackageList.length ? "Package" : ""}
        </div>
        {props.soPackageList.length ? (
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="serial.no" defaultMessage="" />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.package.listing.header.packageNo"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.package.listing.header.date"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.package.listing.header.qtyPacked"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage id="common.status" defaultMessage="" />
                </th>
                {/* <th><FormattedMessage id='package.productDetail.action' defaultMessage='' /></th> */}
              </tr>
            </thead>
            <tbody>
              {(props.soPackageList || [])?.sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          openPackageDetails(item);
                        }}
                      >
                        {item.packageNumber}
                      </div>
                    </td>
                    <td>
                      {(item.packageDate || "") &&
                        getMomentDateForUIReadOnly({
                          date: props.packageDate,
                          format: CONSTANTS.DISPLAY_DATE_FORMAT,
                        })}
                    </td>
                    <td>{getPackedQuantity(item)}</td>
                    <td>
                      <div className={[
                    "status-code",
                    item.status.toLowerCase(),
                  ].join(" ")}>
                        <FormattedMessage id={item.status} defaultMessage="" />
                      </div>
                    </td>
                    {/* <td>
                                        {(props.qualityChecklistSetting && !props.qualityChecklistSetting.isGenerateShipmentWithoutQualityCheck && item.qualityChecklistPackageList && item.qualityChecklistPackageList.length && item.qualityChecklistStatus !== 'Completed') ? <div>
                                            <div className="cursor-pointer" onClick={() => {
                                                props.updateState({
                                                    title: <div className="drawer-title"><FormattedMessage id='modal.txDetails.package.qualityCheck' defaultMessage='' /></div>,
                                                    qualityCheckDrawerVisible: true,
                                                    qualityCheckAction: "check",
                                                    qualityCheckList: item.qualityChecklistPackageList,
                                                    qualityChecklistStatus: item.qualityChecklistStatus,
                                                    addQualityCheckToProps: function (qualityCheckList, status) {
                                                       
                                                        this.updateState({
                                                            qualityCheckList: qualityCheckList
                                                        });
                                                        props.updatePackageQualityCheck({ ...item, qualityChecklistPackageList: qualityCheckList, qualityChecklistStatus: status }, props)
                                                    }
                                                })
                                            }}>
                                                <FormattedMessage id='modal.txDetails.package.qualityCheck' defaultMessage='' />
                                            </div>
                                        </div>
                                            :
                                            <div>
                                                {item.status === "not.shipped" ?
                                                    <div className="cursor-pointer" onClick={() => {
                                                        props.hideModal()
                                                        createShipment(props, item);
                                                    }}

                                                    >
                                                        <FormattedMessage id='createShipment.text' defaultMessage='' />
                                </div> :
                                                    <span className={["link", item.status.toLowerCase()].join(' ')} onClick={() => {
                                                        openDeliveryNotesDetails(item)
                                                    }}>
                                                        <FormattedMessage id='viewShipment.text' defaultMessage='' />
                                        </span>}
                                            </div>
                                        }
                                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="create-data-container">
            {checkACLPermission(
              primaryPerm,
              MODULE_CODED_VALUES.SALES_ORDER_PACKAGE,
              PERMISSION_VALUES.CREATE
            ) && (
              <h4 className="progressbar-modal-heading">
                {" "}
                <FormattedMessage
                  id="package.doesnot.exist"
                  defaultMessage=""
                />
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    props.hideModal();
                    props.pushModalToStack({
                      modalBody: (
                        <Package
                          {...props}
                          customerId={props.soData.customerId}
                          soMasterId={props.soData.salesOrderMasterId}
                        />
                      ),
                      width: "100%",
                      hideTitle: true,
                      hideFooter: true,
                      wrapClassName: "new-transaction-wrapper",
                    });
                  }}
                  // to={
                  //     {
                  //         pathname: '/admin/so/package',
                  //         state: {
                  //             customerId: props.soData.customerId,
                  //             soMasterId: props.soData.salesOrderMasterId
                  //         }
                  //     }}
                >
                  <span> </span>{" "}
                  <FormattedMessage id="clickhere" defaultMessage="" />{" "}
                  <span> </span>
                </div>
                <FormattedMessage
                  id="package.doesnot.exist2"
                  defaultMessage=""
                />
              </h4>
            )}

            <img src={PackageIcon} alt="Invoice" height="275" />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default PackageInformation;
