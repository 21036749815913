import React from 'react';
import { Tabs } from 'antd';
import DefaultLedger from './DefaulLedger';
import BankInfo from './BankInfo.js';
import Address from './Address.js';
import Company from './Company.js';
//import Employee from './Employee.js';
//import CostCenter from './CostCenter.js';
import AboutCompany from './AboutCompany';
import TaxInformation from './TaxInformation';
import Settings from './Settings';
import OnlineThemes from './OnlineThemes';
import LabelConfig from './LabelConfig.js';
import './index.scss';
import { getPermissionData } from '../../../../../utils/index.js';
import { MODULE_CODED_VALUES } from '../../../../../static/constants.js';


const { TabPane } = Tabs;

const CompanyInfoComponent = (props) => {
    const { permissions } = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.SETTINGS_COMPANY_INFORMATION);

    function onTabChange(key, props) {
        const payload = { relationshipId: props.companyInfo.relationshipId, pageNumber: 1, pageSize: 100 };
        props.updateState({ pageSize: 100, pageNumber: 1, activeTabKey: key });

        switch (key) {
            case 'company_info':
                break;
            case 'about_org':
                props.fetchIndustries(payload);
                break;
            case 'address':
                props.getRelationshipAddress(payload);
                break;
            case 'default_ledger':
                props.fetchAllLedgerAccounts(payload);
                break;
            case 'bank_details':
                break;
            case 'tax_identification':
                props.fetchTaxIdentifications(payload);
                props.fetchRelationshipTaxIdentifications(payload);
                break;
            case 'settings':
                break;
            case 'online_branding':
                break;
            case 'label_config':
                break;
            default:
                break;
        }
    }

    return (
        <Tabs onChange={(key) => { onTabChange(key, props); }} type="line" animated={true}>
            <TabPane tab="Company" key="company_info">
                <Company {...props} permissionsData={permissionsData} />
            </TabPane>
            <TabPane tab="About Organization" key="about_org">
                <AboutCompany {...props} permissionsData={permissionsData} />
            </TabPane>
            {/* <TabPane tab="Employee" key="3">
                    <Employee {...props}/>
            </TabPane> */}
            <TabPane tab="Address" key="address">
                <Address {...props} permissionsData={permissionsData} />
            </TabPane>
            {/* <TabPane tab="Cost Center" key="5">
                    <CostCenter {...props}/>
            </TabPane> */}
            <TabPane tab="Default Ledger" key="default_ledger">
                <DefaultLedger {...props} permissionsData={permissionsData} />
            </TabPane>
            <TabPane tab="Bank Details" key="bank_details">
                <BankInfo {...props} permissionsData={permissionsData} />
            </TabPane>
            <TabPane tab="Tax Identification" key="tax_identification">
                <TaxInformation {...props} permissionsData={permissionsData} />
            </TabPane>
            <TabPane tab="Settings" key="settings">
                <Settings {...props} permissionsData={permissionsData} />
            </TabPane>
            <TabPane tab="Online Branding" key="online_branding">
                <OnlineThemes {...props} permissionsData={permissionsData} />
            </TabPane>
            <TabPane tab="Label Config" key="label_config">
                <LabelConfig {...props} permissionsData={permissionsData} />
            </TabPane>
        </Tabs>
    );

}


export default CompanyInfoComponent;
