import React, { Fragment } from "react";
// import { Link } from "react-router-dom";
import { CONSTANTS, TRANSACTION_STATUS } from "../../../../../static/constants";
import PODetail from "../../../../../containers/modal/modalBody/supplier/PurchaseOrderDetail";
import InboundDeliveryCreate from "../../../../../containers/supplier/InboundDelivery/index";
import { FormattedMessage } from "react-intl";
import InboundDeliveryDetail from "../../../../../containers/modal/modalBody/supplier/InboundDeliveryDetail";
import IDIcon from "../../../../../assets/images/inbound-delivery.png";
import { getMomentDateForUIReadOnly } from "../../../../../utils";

const InboundDeliveryInformation = (props) => {
  const openPODetails = (data) => {
    const poPayload = {
      supplierId: data.supplierId,
      poMasterId: data.poMasterId,
    };
    props.pushModalToStack({
      modalBody: <PODetail {...props} poPayload={poPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const openIDDetails = (data) => {
    const poPayload = {
      supplierId: data.supplierId,
      inboundDeliveryMasterId: data.inboundDeliveryMasterId,
    };
    props.pushModalToStack({
      modalBody: <InboundDeliveryDetail {...props} poPayload={poPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const getQuantityReceived = (item) => {
    let quantity = 0;
    item.inboundDeliveryDetailsList.map((e) => {
      if (e.poMasterId === props.poData.poMasterId) {
        return (quantity += e.qtyReceived);
      } else {
        return quantity;
      }
    });
    return quantity;
  };

  return (
    <Fragment>
      <div className="modal-table-container">
        <div className="table-heading">
          <FormattedMessage id="purchase.Order.Details" defaultMessage="" />
        </div>
        <table>
          <thead>
            <tr>
              <th>
                {" "}
                <FormattedMessage
                  id="supplier.purchaseInvoice.listing.header.poNo"
                  defaultMessage=""
                />{" "}
              </th>
              <th>
                <FormattedMessage
                  id="supplier.purchaseOrder.form.poDate.label"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="package.productDetail.qtyOrdered"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="supplier.purchaseOrder.form.gridHeader.qtyReceived"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage id="pO.status" defaultMessage="" />
              </th>
              <th>
                <FormattedMessage id="delivery.Status" defaultMessage="" />{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    openPODetails(props.poData);
                  }}
                >
                  {props.poData.poNumber}
                </div>
              </td>
              <td>
                {(props.poData.poDate || "") &&
                  getMomentDateForUIReadOnly({
                    date: props.poData.poDate,
                    format: CONSTANTS.DISPLAY_DATE_FORMAT,
                  })}
              </td>
              <td>{props.poData.totalQtyOrdered}</td>
              <td>{props.poData.totalQtyOnId}</td>
              <td>
                <div
                  className={[
                    "status-code",
                    props.poData.status.toLowerCase(),
                  ].join(" ")}
                >
                  <FormattedMessage
                    id={props.poData.status}
                    defaultMessage={props.poData.status}
                  />
                </div>
              </td>
              <td>
                {props.poData.statusInboundDelivery !==
                  TRANSACTION_STATUS.RECEIVED &&
                props.poData.statusInboundDelivery !==
                  TRANSACTION_STATUS.OVER_TOLERANCE ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      props.hideModal();
                      props.pushModalToStack({
                        modalBody: (
                          <InboundDeliveryCreate
                            {...props}
                            supplierId={props.poData.supplierId}
                            poMasterId={props.poData.poMasterId}
                          />
                        ),
                        width: "100%",
                        hideTitle: true,
                        hideFooter: true,
                        wrapClassName: "new-transaction-wrapper",
                      });
                    }}
                    // to={{
                    //   pathname: "/admin/inbound-delivery-create",
                    //   state: {
                    //     supplierId: props.poData.supplierId,
                    //     poMasterId: props.poData.poMasterId,
                    //   },
                    // }}
                  >
                    Create GRN
                  </div>
                ) : (
                  <div className="status-code success">
                    <FormattedMessage
                      id={props.poData.statusInboundDelivery}
                      defaultMessage={props.poData.statusInboundDelivery}
                    />
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="table-heading">
          {props.poInboundDeliveryList.length ? "Goods Receipt Note" : ""}
        </div>
        {props.poInboundDeliveryList.length ? (
          <table>
            <thead>
              <tr>
                <th> #</th>
                <th>
                  <FormattedMessage
                    id="supplier.purchaseinvoice.form.idNumber.label"
                    defaultMessage=""
                  />{" "}
                </th>
                <th>
                  <FormattedMessage
                    id="modal.txDetails.inboundDelivery.inboundDeliveryDate"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="supplier.purchaseOrder.form.gridHeader.qtyReceived"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage id="invoiceStatus.text" defaultMessage="" />
                </th>
              </tr>
            </thead>
            <tbody>
              {props.poInboundDeliveryList.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          openIDDetails(item);
                        }}
                      >
                        {item.inboundDeliveryNumber}
                      </div>
                    </td>
                    <td>
                      {(item.inboundDeliveryDate || "") &&
                        getMomentDateForUIReadOnly({
                          date: item.inboundDeliveryDate,
                          format: CONSTANTS.DISPLAY_DATE_FORMAT,
                        })}
                    </td>
                    <td>{getQuantityReceived(item)}</td>
                    <td>
                      <div
                        className={[
                          "status-code",
                          item.status.toLowerCase(),
                        ].join(" ")}
                      >
                        <FormattedMessage
                          id={item.statusInvoice}
                          defaultMessage={item.statusInvoice}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="create-data-container">
            <h4 className="progressbar-modal-heading">
              <FormattedMessage
                id="inbound.Deliver.created.PurchaseOrder"
                defaultMessage=""
              />
              <div
                className="cursor-pointer"
                onClick={() => {
                  props.hideModal();
                  props.pushModalToStack({
                    modalBody: (
                      <InboundDeliveryCreate
                        {...props}
                        supplierId={props.poData.supplierId}
                        poMasterId={props.poData.poMasterId}
                      />
                    ),
                    width: "100%",
                    hideTitle: true,
                    hideFooter: true,
                    wrapClassName: "new-transaction-wrapper",
                  });
                }}
                // to={
                //     {
                //         pathname: '/admin/inbound-delivery-create',
                //         state: {
                //             supplierId: props.poData.supplierId,
                //             poMasterId: props.poData.poMasterId
                //         }
                //     }}
              >
                <span> </span>
                <FormattedMessage id="clickhere" defaultMessage="" />{" "}
                <span> </span>
              </div>
              <FormattedMessage
                id="to.create.Inbound.Delivery"
                defaultMessage=""
              />
            </h4>

            <img src={IDIcon} alt="Invoice" height="275" />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default InboundDeliveryInformation;
