import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Drawer, Row, TimePicker } from "antd";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { formatDateForBackend } from "../../../../utils";
import moment from "moment-timezone";

const WorkShiftDrawer = (props) => {

  const closeDrawer = () => {
    props.updateState({
      workShiftDrawer: false,
    });
  };

  const handleSubmit = () => {
    props.updateDrawerState({ submitted: true });
    if(!props.shiftName ||  !props.startTime || !props.endTime || !props.lateCountTime){
      return;
    }
    const payload = {
      relationshipId: props.companyInfo?.relationshipId,
      workShiftId: props.workShiftId,
      shiftName: props.shiftName,
      startTime: moment(props.startTime).format('HH:mm:ss'),
      endTime: moment(props.endTime).format('HH:mm:ss'),
      lateCountTime: moment(props.lateCountTime).format('HH:mm:ss'),
      createdByUserId: props.createdByUserId,
      updatedByUserId: props.updatedByUserId,
      status: 1,
      version: 0

    }
    const modalData = {     
      modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage=""/>,
      handleSubmit: () => {
        props.createWorkShift(payload,props);
       closeDrawer();  },
    };
    props.pushModalToStack(modalData);
  }

  return (
    <Drawer
      title="Create New"
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.workShiftDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              handleSubmit()
            }}
            key={1}
          />
        </>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="i-label">Work Shift Name
            <span className="required">*</span>
          </div>
          <TextBox
            placeholder="Work Shift Name"
            type="text"
            maxLength={30}
            value={props.shiftName}
            onChange={(e) => {
              props.updateDrawerState({ shiftName: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.shiftName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Start Time
            <span className="required">*</span>
          </div>
          <TimePicker
            format="HH:mm"
            value={props.startTime ? moment(props.startTime, 'HH:mm') : null}
            onChange={(selectedTime) => {
              props.updateDrawerState({
                startTime: selectedTime
              })
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.startTime }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">End Time
            <span className="required">*</span>
          </div>
          <TimePicker
            format={"HH:mm"}
            value={props.endTime ? moment(props.endTime, 'HH:mm') : null}
            onChange={(selectedTime, timeString) => {
              props.updateDrawerState({ endTime: selectedTime });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.endTime }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Late Count Time
            <span className="required">*</span>
          </div>
          <TimePicker
            format={"HH:mm"}
            value={props.lateCountTime ? moment(props.lateCountTime, 'HH:mm') : null}
            onChange={(selectedTime, timeString) => {
              props.updateDrawerState({ lateCountTime: selectedTime.toDate() });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.lateCountTime }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default injectIntl(WorkShiftDrawer);
