import axios from "axios";
import config from '../../../../../config/environmentConfig';
import {COMMON_ACTIONS, COMPANY_ACTION_LIST } from "../../../../../static/constants";
import { showToasterMessage } from "../../../../../utils";
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchEmployeeDetailsByEmployeeId = (payload, history) => {
    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
      return axios
        .get(
          `${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/relEmployee/getEmployeeDetails?relationshipId=${payload.relationshipId}&employeeId=${payload.employeeId}`
        )
        .then((res) => {
          dispatch({ type: COMPANY_ACTION_LIST.EMPLOYEE_DETAIL, data: res.data });
        })
        .catch((err) => {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          
        }).finally(() => {
          dispatch({
            type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
          });
        })
    };
  };