 import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createBankDetail ,deleteBankDetail } from '../../modal/modalBody/settings/CompanyInfo/action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchTaxIdentifications, addTaxIdentification, deleteTaxIdentifications, fetchRelationshipTaxIdentifications, addRelationshipTaxIdentification, deleteRelationshipTaxIdentifications, fetchCountries, fetchAllEmployees, createEmployee, uploadImage } from '../../../actions/commonActions';
import { fetchIndustries } from '../../../actions/commonActions';
import { CONSTANTS, LEDGER_ACTION_LIST, LEDGER_TYPE } from '../../../static/constants';
import { checkClientURLExist, getSubdomain, resetCheckClientURLExist, saveClientURL, updateDefaultLedger ,updateBusinessData } from '../../modal/modalBody/settings/CompanyInfo/action';
import { fetchDataIfNeeded } from "../../../utils";
import { fetchAllLedgerAccounts } from '../../finance/LedgerAccounts/action';
import AccountSettingsComp from '../../../components/Account/AccountSettings';
import find from 'lodash.find';
class ProfileSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      activeTabKey:"General",
      employee :{},
    
      isLoading: true,
    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo;
    const payload ={
      relationshipId: companyInfo?.relationshipId,
    }
    fetchDataIfNeeded(
      "fetchAllLedgerAccounts",
      "allLedgerAccounts",
      this.props,
      payload
    );
    fetchDataIfNeeded('fetchCountries', 'countries', this.props);
    this.props.fetchAllEmployees(payload);
    this.props.fetchRelationshipTaxIdentifications(payload);
    this.props.fetchTaxIdentifications(payload)
    fetchDataIfNeeded('fetchIndustries', 'industries', this.props, payload);
    fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
    this.props.getSubdomain(payload);
    this.props.resetCheckClientURLExist();
  }

    componentWillReceiveProps(props){
      if(this.state.isLoading && (props.companyInfo?.defaultCoaLedgerAccountsList||[]).length && (props.allEmployee||[]).length){
        const defaultSalesLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, {  txType: LEDGER_ACTION_LIST.SALES, }) || {};
        const defaultExpenseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.CUSTOMER_EXPENSE, }) || {};
        const defaultSalesOutputLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.SALES_OUTPUT_TAX, }) || {};
        const defaultSalesDiscountLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.SALES_DISCOUNT, }) || {};
        const defaultDebtorsLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEBTORS, }) || {};
        const defaultPurchaseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASES, }) || {};
        const defaultTaxLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE_TAX,}) || {};
        const defaultDiscountLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.DISCOUNT_RECIEVED,}) || {};
        const defaultSupplierExpenseLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.SUPPLIER_EXPENSE,}) || {};
        const defaultCreditorLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.CREDITORS, }) || {};
        const defaultCustomerPayment =find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TX_PAYMENT_RECIEVED,}) || {};
        const defaultSupplierPayment =find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TX_PAYMENT_MADE, }) || {};
        const defaultRoundingOff = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TYPE_ROUNDING_OFF, }) || {};
        const defaultCoupon =  find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TYPE_COUPON,}) || {};
        const loginUser= (props.allEmployee||[]).find(item=>item?.isAccountHolder)

        
         this.setState({
          companyInfoData: {
            ...props.companyInfo,
            //  onlineThemes: JSON.parse(props.companyInfo?.onlineThemes || "{}"),
          //  onlineThemes: props.companyInfo.onlineThemes || "{}",
            // roundingOffSettings: JSON.parse(props.companyInfo?.roundingOffSettings || {}),
            // couponSetting: JSON.parse(props.companyInfo?.couponSetting || {})
          },
          defaultSalesLedger,
          defaultDebtorsLedger,
          defaultExpenseLedger,
          defaultSalesOutputLedger,
          defaultSalesDiscountLedger,
          defaultPurchaseLedger,
          defaultTaxLedger,
          defaultDiscountLedger,
          defaultSupplierExpenseLedger,
          defaultCreditorLedger,
          defaultCustomerPayment,
          defaultSupplierPayment,defaultCoupon,defaultRoundingOff,
          countryCallingCode: props.companyInfo.countryCallingCode,
          employee: loginUser,
          isLoading: false,
        
         })
      }
    }

  render() {
    return (
      <AccountSettingsComp  {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    );
  }

}

const mapStateToProps = (state) => {
  
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    allTaxIdentificatinType: state.common.allTaxIdentificatinType,
    allRelationshipTaxIdentificatins:
      state.common.allRelationshipTaxIdentificatins,
      industries: state.common.industries,
     
      countries: state.common.countries,
      allEmployee : state.common.employees,
      subdomain: state.common.subdomain,
    isSubdomainExist: state.common.isSubdomainExist,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  fetchAllLedgerAccounts,
  fetchTaxIdentifications,
  addTaxIdentification,
  deleteTaxIdentifications,
  fetchRelationshipTaxIdentifications,
  addRelationshipTaxIdentification,
  deleteRelationshipTaxIdentifications,
  updateDefaultLedger,
  fetchIndustries,
  fetchCountries,
  fetchAllEmployees,
  createEmployee,
  uploadImage,
  checkClientURLExist,
  getSubdomain,
  resetCheckClientURLExist,
  saveClientURL,
  updateBusinessData,
  deleteBankDetail,
  createBankDetail
 
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
