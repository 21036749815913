import React, { Fragment ,useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilePdfOutlined, MailOutlined, PrinterOutlined, EditOutlined, StopOutlined, CopyOutlined, HistoryOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown } from 'antd';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import config from '../../../../../config/environmentConfig';
import { CONSTANTS, TRANSACTION_TYPES, MODAL_TYPE, PERMISSION_VALUES, MODULE_CODED_VALUES, CONSTANTS_TRANSACTIONS } from '../../../../../static/constants';
import { fixedDecimalNumber, checkACLPermission, showToasterMessage, getPermissionData } from '../../../../../utils';
import { Dropdown as CustomDropDown } from '../../../../general/Dropdown';
import StandardTemplate from "./standard";
import Template2 from "./template2";
import Transport from "./transport";
import PayInvoice from '../../../../../containers/customer/invoice/PayInvoice';
import AvailableCreditDetails from '../../../../../containers/modal/modalBody/customer/ApplySalesCredits';
import RecurringInvoiceSetup from '../../../../../containers/modal/modalBody/customer/RecurringInvoiceSetup';
import ReminderSetupComponent from '../../../../../containers/modal/modalBody/customer/ReminderSetup';
import ShareInvoiceComponent from '../ShareInvoice';
import StudentInvoice from '../../../../../containers/customer/invoice/StudentInvoice';
import StandardSalesInvoice from '../../../../../containers/customer/invoice/StandardSalesInvoice';

const SalesInvoiceDetails = (props) => {
    const { salesInvoiceDetail, companyInfo, templateName = 'Professional', permissions } = props;
    const primaryPerm = permissions.primary;
    const saleInvoicePermissions = useMemo(()=>{
        return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SALES_QUOTE);
      },[])

      const schoolInvoicePermissions = useMemo(()=>{
        return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SALES_QUOTE);
      },[])

      const customerPaymentPermissions = useMemo(()=>{
        return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CUSTOMER_PAYMENT);
      },[])

      const schoolPaymentPermissions = useMemo(()=>{
        return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SCHOOL_PAYMENT);
      },[])
    const { customerInvoiceDetailsList } = salesInvoiceDetail;
    const relationshipAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (salesInvoiceDetail.placeOfSupply && relationshipAddress.stateName === salesInvoiceDetail.placeOfSupply) || false;
    let itemLevelTaxes = [];
    let itemLevelTaxesByHsn = [];
    let uomList = [];
    if (customerInvoiceDetailsList) {
        itemLevelTaxes = calculateTaxRateList(customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship,salesInvoiceDetail,props);
        itemLevelTaxesByHsn = calculateTaxRateListByHsn(customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship,salesInvoiceDetail,props);
        uomList = calculateUomData(customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true,
        comment: true,
        studentName: true,
        studentGrade: true,
        discountType: true,
    };
    if (salesInvoiceDetail) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, salesInvoiceDetail, props);
    }


    const getPayloadForPdf = () => {
        return {
            templateName: templateName,
           
            fileName: (salesInvoiceDetail.invoiceNumber || 'Sales Invoice') + '.pdf',
            transactionName: 'salesInvoice',
            salesInvoiceDetail: salesInvoiceDetail,
            relationshipAddress: relationshipAddress,
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            companyInfo: companyInfo,
            isColumnEmpty: isColumnEmpty,
            isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
            itemLevelTaxes: itemLevelTaxes,
            hsnTableData:itemLevelTaxesByHsn?.filter(item=>item.hsnCode > 0),
            bucketName: config.BUCKET_NAME.BO_CUSTOMER_INVOICE,
            stampAwsKey: salesInvoiceDetail.stampAwsKey,
            uomList:uomList?.filter(item=>item.uomName?.length),
        }
    }
    const getPayloadForPrintPdf =() =>{
        return {
            templateName: templateName,
            pdfPurpose:"print",
            fileName: (salesInvoiceDetail.invoiceNumber || 'Sales Invoice') + '.pdf',
            transactionName: 'salesInvoicePrint',
            salesInvoiceDetail: salesInvoiceDetail,
            relationshipAddress: relationshipAddress,
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            companyInfo: companyInfo,
            isColumnEmpty: isColumnEmpty,
            isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
            itemLevelTaxes: itemLevelTaxes,
            hsnTableData:itemLevelTaxesByHsn?.filter(item=>item.hsnCode > 0),
            bucketName: config.BUCKET_NAME.BO_CUSTOMER_INVOICE,
            stampAwsKey: salesInvoiceDetail.stampAwsKey,
        }
    }
    const getPayloadForEComPdf = () => {
        return {
            templateName: "ECom Invoice",
           
            fileName: (salesInvoiceDetail.invoiceNumber || 'ECom Invoice') + '.pdf',
            transactionName: 'salesInvoice',
            salesInvoiceDetail: salesInvoiceDetail,
            relationshipAddress: relationshipAddress,
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            companyInfo: companyInfo,
            isColumnEmpty: isColumnEmpty,
            isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
            itemLevelTaxes: itemLevelTaxes,
            hsnTableData:itemLevelTaxesByHsn?.filter(item=>item.hsnCode > 0),
            bucketName: config.BUCKET_NAME.BO_CUSTOMER_INVOICE,
            stampAwsKey: salesInvoiceDetail.stampAwsKey,
            uomList:uomList?.filter(item=>item.uomName?.length),
        }
    }
    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () =>{
        let payload = getPayloadForPrintPdf();
        props.printPDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired = true;
        payload.customerId = salesInvoiceDetail.customerId;
        payload.invoiceMasterId = salesInvoiceDetail.invoiceMasterId;
        props.updateState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.SALES_INVOICE,
        });
    };

    const approveInvoice = () => {
        const modalBody = 'Are you sure you want to approve?';
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.approveInvoice({
                    relationshipId: salesInvoiceDetail.relationshipId,
                    customerId: salesInvoiceDetail.customerId,
                    invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                    approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                }, props)
                props.popModalFromStack();
            },
        };
        props.showModal(modalData);
    }

    const handleDelegateApprover = () => {
        const formData = {
            relationshipId: salesInvoiceDetail.relationshipId,
            customerId: salesInvoiceDetail.customerId,
            invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            approverEmpId: salesInvoiceDetail.approverEmpId,
            txType: TRANSACTION_TYPES.SALES_INVOICE
        };
        const { showModal } = props;
        const data = {
            title: 'Delegate Approver',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.DELEGATE_APPROVER,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const handleRejectApproval = () => {
        const formData = {
            relationshipId: salesInvoiceDetail.relationshipId,
            customerId: salesInvoiceDetail.customerId,
            invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            txType: TRANSACTION_TYPES.SALES_INVOICE
        };
        const { showModal } = props;
        const data = {
            title: 'Reject Approval',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.REJECT_APPROVAL,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const openAuditDrawer = (props, data) => {
        props.updateState({
            title: <div className="drawer-title">
                <FormattedMessage id='modal.txDetails.common.auditTrail' defaultMessage='' />
            </div>,
            auditDrawerVisible: true,
            deletApiUrl: 'deleteAuditTrailSalesInvoice',
            masterKey: 'invoiceMasterId',
            masterKeyValue: data.invoiceMasterId,
            apiUrl: 'saveAuditTrailSalesInvoice',
            customerId: data.customerId,
            trailList: data.auditTrailSalesInvoiceList,
            serviceName:"purchase",
            updateTrailListInTransaction: (list) =>{
                data.auditTrailSalesInvoiceList = list;
            } 
        });
    }

     const addItemConfirmationModal = (props, text, color, txData) => {
        const { showModal, companyInfo } = props;
      
        const modalBody = <span>
            <FormattedMessage
                id='setItem.confirmation'
                defaultMessage={``}
            />
          
      </span>;

        let payload = {
            userStatus: text,
            userStatusColor: color,
            relationshipId: companyInfo.relationshipId,
            invoiceMasterId: txData.invoiceMasterId,
            customerId: txData.customerId
        };

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.updateUserStatus(payload, props);
                props.hideModal();
                props.popModalFromStack();
            },
        };
        showModal(modalData);
    }

    

    const updateUserStatus = (props, txData) => {
        let payload = { formData: {} };
        const formData = payload.formData;
        payload.txData = txData;
        const { pushModalToStack } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, txData);
        } else {
            const data = {
                title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
                formData,
                hideFooter: true,
                modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SALES_INVOICE },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, formData.statusColor, txData);
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                }
            };
            pushModalToStack(data);
        }
    }

    const openE_InvoiceDrawer =(props)=>{
       
        // const { pushModalToStack } = props;
        // const modalData = {
        //     title: <FormattedMessage id='addItem.text.invoice' defaultMessage='' />,
        //     modalBody:<></> ,
            
        //    hideFooter:false,
        //    handleSubmit: () => {
       
        //    const payload = {
        //     relationshipId: props.companyInfo?.relationshipId,
        //     invoiceMasterId: txData?.invoiceMasterId,
        //    }
        //     props.generateEInvoiceDocument(payload)
           
        // }
        //   };
       
        //     pushModalToStack(modalData);
        props.updateDrawerState({
            salesE_InvoiceDrawerVisisble: true,
            
        });
      
        
    }
    const openEWayBill = (props)=>{
        props.updateDrawerState({
            eWayBillDrawerVisible: true,
           
        });
    }
    const updateStatusConfirmationModal = (props, text, txData) => {
        const { showModal, companyInfo } = props;
        const labelName = <FormattedMessage id='customer.salesInvoice.listing.header.systemStatus' defaultMessage='' />;
        const modalBody = <span>
            <FormattedMessage
                id='setItem.confirmation'
                defaultMessage={``}
            />
            {labelName} ?
      </span>;

        let payload = {
            status: text,
            relationshipId: companyInfo.relationshipId,
            invoiceMasterId: txData.invoiceMasterId,
            customerId: txData.customerId
        };

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.updateSystemStatus(payload, props);
                props.hideModal();
                props.popModalFromStack();
            },
        };
        showModal(modalData);
    }
    
    const updateSystemStatus = (props, txData) => {
        let payload = { formData: {} };
        const formData = payload.formData;
        payload.txData = txData;
        const { pushModalToStack } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            updateStatusConfirmationModal(props, formData.textBoxValue, txData);
        } else {
            const data = {
                title: <FormattedMessage id='customer.salesInvoice.listing.header.systemStatus' defaultMessage='' />,
                formData,
                hideFooter: true,
                modalData: { modalType: MODAL_TYPE.SYSTEM_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SALES_INVOICE },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        updateStatusConfirmationModal(props, formData.textBoxValue, txData);
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                }
            };
            pushModalToStack(data);
        }
    }

    const _getSoMasterId = (data) =>{
        let soId = [];
        (data.customerInvoiceDetailsList||[]).forEach((e)=>{
            if(e.salesOrderMasterId && soId.indexOf(e.salesOrderMasterId) === -1){
                soId.push(e.salesOrderMasterId)
            }
        })
        return soId.toString();
    }

    const actionMenu = (param) => {
        return (
            <Menu className="cell-action-dropdown">

                {(customerPaymentPermissions?.create || schoolPaymentPermissions?.create) && (salesInvoiceDetail.status !== "paid" && salesInvoiceDetail.status !== 'void') ?
                    <Menu.Item key="1" onClick={() => {
                        if (salesInvoiceDetail.isCashInvoice) {
                            showToasterMessage({ description: props.intl.formatMessage({ id: 'payInvoice.cash.invoice.error' }) })
                        }
                        else {
                            const modalData = {
                                title: <FormattedMessage id='sidebar.salesPayment.popup.title' defaultMessage='' />,
                                modalBody: <PayInvoice {...props} invoiceData={salesInvoiceDetail} />,
                                wrapClassName: 'pay-invoice-modal',
                                width: 500,
                                hideFooter: true,
                                handleSubmit: (prm, prm2) => {
                                   
                                }
                            };
                            props.showModal(modalData);
                        }
                    }}
                    >
                        <FormattedMessage id='modal.txDetails.salesInvoice.payInvoice' defaultMessage='' />
                    </Menu.Item> : <Fragment />
                }
            {
                (saleInvoicePermissions?.create || schoolInvoicePermissions?.create) ? <Menu.Item key="2" onClick={() => {
                    props.pushModalToStack({
                        title: <div>
                            <FormattedMessage id='popup.recurringInvoice.title' defaultMessage='' />
                            <div style={{ 'fontSize': '12px' }}> {salesInvoiceDetail.customerName || ''}</div>
                            <div style={{ 'fontSize': '12px' }}>{salesInvoiceDetail.invoiceNumber} - {salesInvoiceDetail.isMultiCurrency ? <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency}</span> : <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>}{fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount)}</div>
                            </div>,
                        modalBody: <RecurringInvoiceSetup {...props} />,
                        width: '50%',
                        hideFooter: true,
                    })
                }}>
                      <FormattedMessage id='makeRecurring.text' defaultMessage='' />
                </Menu.Item> :""
            }
                
               
                {/* <Menu.Item key="3" onClick={() => {
                    props.payOnline({
                        customerId: salesInvoiceDetail.customerId,
                        invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                        relationshipId: salesInvoiceDetail.relationshipId,
                        soMasterIdArr: _getSoMasterId(salesInvoiceDetail),
                    }, props)
                }}>Pay Online
                </Menu.Item> */}

                {
                    (saleInvoicePermissions?.update || schoolInvoicePermissions?.update) ?  <Menu.Item key="3" onClick={() => {
                        updateUserStatus(props, salesInvoiceDetail);
                    }}><FormattedMessage id='modal.txDetails.common.updateStatus' defaultMessage='' />
                    </Menu.Item>: ""
                }
               {/* {
                (saleInvoicePermissions?.create || schoolInvoicePermissions?.create) ?  <Menu.Item key="6" onClick={() => {
                    updateSystemStatus(props, salesInvoiceDetail);
                }}><FormattedMessage id='modal.txDetails.common.updateSystemStatus' defaultMessage='' />
                </Menu.Item>: ""
               } */}
               
                {
                    (saleInvoicePermissions?.create || schoolInvoicePermissions?.create) ?  <Menu.Item key="4" onClick={() => {
                        if (salesInvoiceDetail.isCashInvoice) {
                            showToasterMessage({description: props.intl.formatMessage({ id: 'reminder.cash.invoice.error' }) })
                         }
                        else {
                            props.pushModalToStack({
                                title: <div>
                                    <FormattedMessage id='addReminder.text' defaultMessage='' />
                                    <div style={{ 'fontSize': '12px' }}> {salesInvoiceDetail.customerName || ''}</div>
                                    <div style={{ 'fontSize': '12px' }}>{salesInvoiceDetail.invoiceNumber} - {salesInvoiceDetail.isMultiCurrency ? <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency}</span> : <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>}{fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount)}</div>
                                </div>,
                                modalBody: <ReminderSetupComponent {...props} />,
                                width: '50%',
                                hideFooter: true,
                            })
                        }
                    }}>
                          <FormattedMessage id='reminder.text' defaultMessage='' />
                    </Menu.Item>: ""
                }
               

                <Menu.Item key="5" onClick={() => {
                    props.pushModalToStack({
                        title: <div>
                            <FormattedMessage id='shareInvoice.text' defaultMessage='' />
                            <div style={{ 'fontSize': '12px' }}> {salesInvoiceDetail.customerName || ''}</div>
                        </div>,
                        modalBody: <ShareInvoiceComponent {...props} />,
                        width: '50%',
                        hideFooter: true,
                    })
                }}><FormattedMessage id='shareInvoice.text' defaultMessage='' />
                </Menu.Item>
            </Menu >)
    }
    const actionMenuPrint = (param) => {
        return (
            <Menu className="cell-action-dropdown">

             
                <Menu.Item key="1" onClick={() => {
                  const payload = getPayloadForPdf();
                    props.printPDF(payload);

                }}>Print Standard
                </Menu.Item>
                <Menu.Item key="2" onClick={() => {
                  const payload = getPayloadForEComPdf();
                    props.printPDF(payload);

                }}>ECom Invoice
                </Menu.Item>
                <Menu.Item key="3" onClick={() => {
                 const payload = getPayloadForPrintPdf();
                  props.printPDF(payload);

                }}>Print Bill
                </Menu.Item>
            </Menu >)
    }
    return (
        
        <div className={"custom-modal show"}>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                     
                    <div>                   
                    <div className="inner-content">
                        {templateName === 'Standard' && <StandardTemplate {...props} />}
                        {templateName === 'Professional' && <Template2 {...props} />}
                        {templateName === 'GST Invoice' && <Transport {...props} />}
                    </div>
                    {salesInvoiceDetail.status !== 'Pending Approval' && salesInvoiceDetail.status !== 'Rejected'?
                        <div className="button-group-action header-control">
                            {(props.availableCredits && salesInvoiceDetail.status !== 'paid' && salesInvoiceDetail.status !== 'void') ?
                                <Button style={{height:'38px'}}
                                    onClick={() => {
                                        const modalData = {
                                            title: <div><div><FormattedMessage id='modal.customer.applySalesCredit.applyCredits' defaultMessage='' /></div>
                                            <div style={{ 'fontSize': '12px' }}> {salesInvoiceDetail.customerName || ''}</div>
                                            </div>,
                                            modalBody: <AvailableCreditDetails invoiceData={salesInvoiceDetail} />,
                                            width: 1000,
                                            hideFooter: true,
                                            handleSubmit: (prm, prm2) => {
                                            }
                                        };
                                        props.showModal(modalData);
                                    }}
                                > <span><FormattedMessage id='modal.customer.applySalesCredit.applyCredits' defaultMessage='' /> - {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} {fixedDecimalNumber(props.availableCredits)} </span> 
                                </Button> : ''
                            }
                             <div className="small-btn-group">
                                {(saleInvoicePermissions?.update || schoolInvoicePermissions.update) && salesInvoiceDetail.status !== 'void' ?
                                    <Button style={{height:'38px'}} title={props.intl.formatMessage({ id: 'modal.txDetails.common.edit', defaultMessage: '' })}
                                        icon={<EditOutlined />}
                                        onClick={() => {
                                            props.popModalFromStack();
                                            props.updateState({
                                                customerId: salesInvoiceDetail.customerId,
                                                    invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                                    update: true,
                                                    soMasterIdArr: _getSoMasterId(salesInvoiceDetail),
                                                    registrationFormSettingId: salesInvoiceDetail.registrationFormSettingId,
                                            }

                                            )
                                            salesInvoiceDetail.templateCode === "student" ?  props.pushModalToStack({
                                                modalBody: <StudentInvoice {...props} 
                                                customerId = {salesInvoiceDetail.customerId} 
                                                invoiceMasterId = {salesInvoiceDetail.invoiceMasterId}
                                                salesInvoiceEditDetail = {salesInvoiceDetail}
                                                update ={true} soMasterIdArr= {_getSoMasterId(salesInvoiceDetail)}
                                                registrationFormSettingId= {salesInvoiceDetail.registrationFormSettingId}  />,
                                                width: '100%',
                                                hideTitle: true,
                                                hideFooter: true,
                                                wrapClassName: 'new-transaction-wrapper'
                                            }) : props.pushModalToStack({
                                                modalBody: <StandardSalesInvoice
                                                 {...props} 
                                                salesInvoiceEditDetail = {salesInvoiceDetail}
                                                 
                                                 customerId = {salesInvoiceDetail.customerId} invoiceMasterId = {salesInvoiceDetail.invoiceMasterId} update ={true} soMasterIdArr= {_getSoMasterId(salesInvoiceDetail)}
                                                registrationFormSettingId= {salesInvoiceDetail.registrationFormSettingId} />,
                                                width: '100%',
                                                hideTitle: true,
                                                hideFooter: true,
                                                wrapClassName: 'new-transaction-wrapper'
                                            })
                                           
                                            // props.history.push({
                                            //     pathname: ,
                                            //     state: {
                                            //         customerId: salesInvoiceDetail.customerId,
                                            //         invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                            //         update: true,
                                            //         soMasterIdArr: _getSoMasterId(salesInvoiceDetail),
                                            //         registrationFormSettingId: salesInvoiceDetail.registrationFormSettingId,
                                            //     }
                                            // })
                                        }}
                                    >
                                    </Button> : ''
                                }
                                {(saleInvoicePermissions?.create || schoolInvoicePermissions.create) && <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.clone', defaultMessage: '' })}
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                            props.popModalFromStack();
                                            salesInvoiceDetail.templateCode === "student" ?  props.pushModalToStack({
                                                modalBody: <StudentInvoice {...props} 
                                                salesInvoiceEditDetail = {salesInvoiceDetail}
                                                customerId = {salesInvoiceDetail.customerId} 
                                                invoiceMasterId = {salesInvoiceDetail.invoiceMasterId} clone ={true} soMasterIdArr= {_getSoMasterId(salesInvoiceDetail)}
                                                registrationFormSettingId= {salesInvoiceDetail.registrationFormSettingId}  />,
                                                width: '100%',
                                                hideTitle: true,
                                                hideFooter: true,
                                                wrapClassName: 'new-transaction-wrapper'
                                            }) : props.pushModalToStack({
                                                modalBody: <StandardSalesInvoice {...props}
                                                salesInvoiceEditDetail = {salesInvoiceDetail}
                                                customerId = {salesInvoiceDetail.customerId} invoiceMasterId = {salesInvoiceDetail.invoiceMasterId} clone ={true} soMasterIdArr= {_getSoMasterId(salesInvoiceDetail)}
                                                registrationFormSettingId= {salesInvoiceDetail.registrationFormSettingId} />,
                                                width: '100%',
                                                hideTitle: true,
                                                hideFooter: true,
                                                wrapClassName: 'new-transaction-wrapper'
                                            })
                                            // props.history.push({
                                            //     pathname: salesInvoiceDetail.templateCode === "student" ?  '/admin/student-invoice' : '/admin/sales-invoice-update',
                                            //     state: {
                                            //         customerId: salesInvoiceDetail.customerId,
                                            //         invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                            //         clone: true,
                                            //         soMasterIdArr: _getSoMasterId( salesInvoiceDetail),
                                            //     }
                                            // })
                                    }}
                                >
                                </Button>}
                               {(saleInvoicePermissions?.update || schoolInvoicePermissions.update) && ((salesInvoiceDetail.status === 'unpaid' || salesInvoiceDetail.status === 'open' ) && !salesInvoiceDetail.isCashInvoice) && <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.void', defaultMessage: '' })}
                                    icon={<StopOutlined />}
                                    onClick={() => {
                                        props.pushModalToStack({
                                            modalBody: "Are you sure you want to void this transaction?",
                                            handleSubmit: () => {
                                                props.popModalFromStack();
                                                props.hideModal();
                                                props.deleteSalesInvoice(salesInvoiceDetail, props)
                                                // props.deleteSalesInvoice({
                                                //     customerId: salesInvoiceDetail.customerId,
                                                //     invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                                //     relationshipId: salesInvoiceDetail.relationshipId,
                                                //     soMasterIdArr: _getSoMasterId(salesInvoiceDetail),
                                                // }, props)
                                            }
                                        })
                                    }}
                                >
                                </Button>}
                            </div>
                            <div className="small-btn-group">

                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                    icon={<MailOutlined />}
                                    onClick={() => {
                                        openEmailDrawer(props);

                                    }}
                                >
                                </Button>

                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        generatePDF();
                                    }}
                                >
                                </Button>
                                <Dropdown overlay={actionMenuPrint()} trigger={['click']}>
                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                    icon={<PrinterOutlined />}
                                    // onClick={() => {
                                    //     printPdf();
                                    // }}
                                >
                                </Button>
                            </Dropdown>
                                {/* <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                    icon={<PrinterOutlined />}
                                    onClick={() => {
                                        printPdf();
                                    }}
                                >
                                </Button> */}
                            </div>
                            <div className="small-btn-group">
                                <Button style={{height:'38px'}} title={props.intl.formatMessage({ id: 'modal.txDetails.common.auditTrail', defaultMessage: '' })}
                                    icon={<HistoryOutlined />}
                                    onClick={() => {
                                        openAuditDrawer(props, salesInvoiceDetail);
                                    }}
                                >
                                </Button>
                            </div>

                            {/* {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_PAYMENT, PERMISSION_VALUES.DELETE) && (salesInvoiceDetail.status !== "Paid" && salesInvoiceDetail.status !== 'void' && !salesInvoiceDetail.isCashInvoice) ?
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        const modalData = {
                                            title: <FormattedMessage id='sidebar.salesPayment.popup.title' defaultMessage='' />,
                                            modalBody: <PayInvoice {...props}  invoiceData={salesInvoiceDetail} />,
                                            wrapClassName: 'pay-invoice-modal',
                                            width: 500,
                                            hideFooter: true,
                                            handleSubmit: (prm, prm2) => {
                                            }
                                        };
                                        props.showModal(modalData);
                                    }}
                                ><FormattedMessage id='modal.txDetails.salesInvoice.payInvoice' defaultMessage='' />
                                </Button> : ''
                            } */}

                            {/* {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_PAYMENT, PERMISSION_VALUES.DELETE) && (salesInvoiceDetail.status !== "Paid" && salesInvoiceDetail.status !== 'void' && !salesInvoiceDetail.isCashInvoice) ?
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        props.payOnline({
                                            customerId: salesInvoiceDetail.customerId,
                                            invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                            relationshipId: salesInvoiceDetail.relationshipId,
                                            soMasterIdArr: _getSoMasterId(salesInvoiceDetail),
                                        }, props)
                                    }}
                                >Pay Online
                                </Button> : ''
                            } */}
                            {
                                props.companyInfo?.countryName === "India" ? (  <div className="small-btn-group" style={{marginLeft: '10px'}}>
                                <Button style={{width:'auto',height:'38px'}}
                                    // disabled ={salesInvoiceDetail?.irn}
                                    onClick={() => {
                                        openE_InvoiceDrawer(props);
                                    }}
                                >
                                   
                                    <FormattedMessage id='modal.txDetails.common.eInvoice' defaultMessage='' /> 
                                </Button>
                                </div>):""
                            }
                          {
                            props.companyInfo?.countryName === "India" ?( <div className="small-btn-group" style={{marginLeft: '10px'}}>
                            <Button style={{width:'auto',height:'38px'}}
                               disabled ={salesInvoiceDetail?.ewayBillNo}

                                onClick={() => {
                                    openEWayBill(props);
                                }}
                            >
                               
                                <FormattedMessage id='modal.txDetails.common.eWayBill' defaultMessage='' /> 
                            </Button>
                            </div>):""
                          }
                           
                            <div className="">
                                <CustomDropDown style={{'width': '110px'}}
                                    placeholder="Template"
                                    items={props.companyInfo.countryName === 'India' ? ['Standard', 'Professional', 'GST Invoice'] : ['Standard', 'Professional']}
                                    onSelect={(selectedValue) => {
                                        props.updateState({
                                            templateName: selectedValue,
                                        });
                                    }}
                                ></CustomDropDown>
                            </div>
                            <div className="">
                            <Dropdown overlay={actionMenu()} trigger={['click']}>
                                <Button className="action-btn-readonly action-btn small">
                                    <i className="fa fa-ellipsis-v" />
                                </Button>
                            </Dropdown>
                        </div>
                        </div>
                        :
                        <div className="button-group-action">
                            {
                                salesInvoiceDetail.status !== 'Rejected' && (props.userInfo || {}).relationshipEmployeeId === salesInvoiceDetail.approverEmpId ? <div className="button-group-action header-control">
                                    <Button type="primary"  onClick={() => { approveInvoice() }}>
                                    <FormattedMessage id='modal.txDetails.salesInvoice.approveInvoice' defaultMessage='' /> 
                                </Button>
                                    <Button type="primary"  onClick={() => { handleDelegateApprover(); }}>
                                    <FormattedMessage id='modal.txDetails.common.delegateApprover' defaultMessage='' />
                            </Button>
                                    <Button type="danger"  onClick={() => { handleRejectApproval(); }}>
                                    <FormattedMessage id='modal.txDetails.common.reject' defaultMessage='' /> 
                            </Button>
                                </div> :
                                    <div>
                                    </div>
                            }

                        </div>
                    }
                    </div>
                    
                </div>
            </div>
        </div>
    );

}


const getTxTypeData = (row, type, toReturn) => {
    if (!row || !row.customerInvoiceCOATxList) return;
    let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type));
    if (data) {
        if (data[toReturn] && toReturn === 'amountPercent') {
            if (type === 'VAT') {
                return "VAT(" + data[toReturn] + "%)";
            }
            return "(" + data[toReturn] + "%)";
        } else if (data[toReturn] && toReturn === 'amount') {
            return fixedDecimalNumber(data[toReturn]);
        } else {
            return ""
        }

    } else if (!data && toReturn === 'amount') {
        return "";
    }
    else {
        return "-";
    }
}


  
 
  
  
const calculateTaxRateList = (customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship,salesInvoiceDetail,props) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerInvoiceDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0,

            }
            totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
            let discount = getDiscountAmount(rowdata, "amount");
            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'tax')) || {};
            taxObj.taxPercent = tax1.amountPercent;
            taxObj.taxAmount = tax1.amount;
            taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
            totalTax = totalTax + tax1.amount;
            taxObj.taxName = tax1.taxName;
            //  let tax2 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'sgst'));
            // let tax3 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'igst'));
            // let tax4 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'vat'));
            //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
            //       taxObj.taxPercent = tax1.amountPercent + tax2.amountPercent;
            //       taxObj.taxAmount = tax1.amount + tax2.amount;
            //       totalTax = totalTax + taxObj.taxAmount;
            //   }
            //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
            //       taxObj.taxPercent = tax3.amountPercent;
            //       taxObj.taxAmount = tax3.amount;
            //       totalTax = totalTax + tax3.amount;
            //   }
            //   else if (tax4) {
            //       taxObj.taxPercent = tax4.amountPercent;
            //       taxObj.taxAmount = tax4.amount;
            //       totalTax = totalTax + tax4.amount;
            //   }
            let couponData = getTxTypeData(salesInvoiceDetail, 'Coupon', 'coupon');
            let setting = JSON.parse(props.companyInfo.couponSetting || "{}");

           if(setting?.isCoupanAppliedBeforeTax && couponData){

               if(couponData?.amountPercent){
                totalAmountOfColumn = totalAmountOfColumn *(couponData?.amountPercent/100);

               }
           }
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
              
                  if (availableTaxObj ) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.taxPercent === taxObj.taxPercent) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                  taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0))
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });

    let dataAfterExpenseTax = calculteExpenseTax(itemLevelTaxes, salesInvoiceDetail,isPlaceOfSupplySameAsRelationship)

    return dataAfterExpenseTax;
}
const calculteExpenseTax = (itemLevelTaxes, salesInvoiceDetail,isPlaceOfSupplySameAsRelationship) => {
    
  
    let expenseTax = salesInvoiceDetail?.customerInvoiceCOATxList?.filter(
      (item) => item.txType === "expense" && item.accountingEntry === "credit"
    );
  
    let v = expenseTax?.forEach((rowdata) => {
      if (rowdata.taxId > 0) {

        let taxObj = {
          subtotal: rowdata.amount,
          taxPercent: isPlaceOfSupplySameAsRelationship ? rowdata.taxId/2 :rowdata.taxId,
          taxAmount: ((isPlaceOfSupplySameAsRelationship ? rowdata.taxId/2 :rowdata.taxId) * rowdata.amount) / 100,
          taxName : rowdata.taxName
        };
  
        let existingTax = itemLevelTaxes.find(
          (tax) => tax.taxPercent === (isPlaceOfSupplySameAsRelationship ? rowdata.taxId/2 :rowdata.taxId)
        );
  
        if (existingTax) {
          existingTax.subtotal += taxObj.subtotal;
          existingTax.taxAmount += taxObj.taxAmount;
        } else {
          itemLevelTaxes.push(taxObj);
        }
      }
    });
  
    return itemLevelTaxes;
  };
const calculateTaxRateListByHsn = (customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship,salesInvoiceDetail,props) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerInvoiceDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0,
              hsnCode:null

            }
            totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
            let discount = getDiscountAmount(rowdata, "amount");

            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'tax')) || {};
            taxObj.taxPercent = tax1.amountPercent;
            taxObj.taxAmount = tax1.amount;
            taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
            totalTax = totalTax + tax1.amount;
            taxObj.taxName = tax1.taxName;
          taxObj.hsnCode = rowdata.hsnCode
            
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                const availableHsnObj = find(itemLevelTaxes, {
                    hsnCode: taxObj.hsnCode,
                  });
                  if (availableTaxObj  && availableHsnObj) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.hsnCode === taxObj.hsnCode) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                  taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0))
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });
    
    let dataAfterExpenseTax = calculteExpenseTaxByHsn(itemLevelTaxes, salesInvoiceDetail,props,isPlaceOfSupplySameAsRelationship)
    return dataAfterExpenseTax;
}
const calculteExpenseTaxByHsn = (itemLevelTaxes, salesInvoiceDetail,props,isPlaceOfSupplySameAsRelationship) => {
    let finalVal = [];
  
    let expenseTax = salesInvoiceDetail?.customerInvoiceCOATxList?.filter(
      (item) => item.txType === "expense" && item.accountingEntry === "credit"
    );
    let v = expenseTax?.forEach((rowdata) => {
      if (rowdata.taxId > 0) {
        let taxObj = {
          subtotal: rowdata.amount,
          taxPercent: isPlaceOfSupplySameAsRelationship ? rowdata.taxId/2 :rowdata.taxId,
          taxAmount: ((isPlaceOfSupplySameAsRelationship ? rowdata.taxId/2 :rowdata.taxId) * rowdata.amount) / 100,
          taxName : rowdata.taxName
        };
        let obj = Number((find(props.allGstRates, { financeGstGoodsRates: rowdata.taxSingleRate }) || {})?.hsnCode || null);
        let existingTax = itemLevelTaxes.find(
          (tax) => tax.hsnCode === obj
        );

        taxObj.hsnCode = obj?obj:null;
        if (existingTax) {
          existingTax.subtotal += taxObj.subtotal;
          existingTax.taxAmount += taxObj.taxAmount;
        } else {
          itemLevelTaxes.push(taxObj);
        }
      }
    });
  
    return itemLevelTaxes;
  };
const getDiscountAmount = (row, toReturn) => {
    if (!row || !row.discountAmount) return;
    if (row?.discountAmount) {
      return  toReturn === "amount"? row?.discountAmount :  "(" + row?.discountPercentage + "%)"
      }
  };
const calculateUomData = (
    customerInvoiceDetailsList,
    isPlaceOfSupplySameAsRelationship
  ) => {
  
    let itemLevelUom = [];
    customerInvoiceDetailsList.forEach(function (rowdata, index) {
      if (rowdata.quantityToInvoice && rowdata.uomName) {
        let uomObj = {
          uomName: null,
          uomQty: null,
        };
  
        uomObj.uomName = rowdata.uomName;
        uomObj.uomQty = rowdata.quantityToInvoice
        if (uomObj.uomName) {
          const availableUomObj = find(itemLevelUom, {
            uomName: uomObj.uomName,
          });
          if (availableUomObj) {
            itemLevelUom = filter(itemLevelUom, (ele) => {
              if (ele.uomName === uomObj.uomName) {
                ele.uomQty = ele.uomQty + uomObj.uomQty;
              }
              return true;
            });
          }
          else {
            itemLevelUom.push(uomObj);
          }
        }
      }
    });
  
    return itemLevelUom;
  };

const updateColumnEmptyCheck = (isColumnEmpty, invoiceData, props) => {
    if (!invoiceData) return isColumnEmpty;
    for (let i in invoiceData.customerInvoiceDetailsList) {
        let row = invoiceData.customerInvoiceDetailsList[i];
        if (row.studentName) { isColumnEmpty.studentName = false; };
        if (row.studentGrade) { isColumnEmpty.studentGrade = false; };
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantityToInvoice) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.anItemSalePrice) { isColumnEmpty.rate = false; }
        if (row.specialDiscount) { isColumnEmpty.specialDiscount = false; }
        if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
        if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
        if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
        if (row.documentDetails && row.documentDetails.length) { isColumnEmpty.documentDetails = false; }
        if (row.comment) { isColumnEmpty.comment = false; }
    }
    return isColumnEmpty;
}

// const isTxTypeEmpty = (row, type) => {
//     if (!row) return true;
//     let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type));
//     if (data && data.amount) {
//         return false;
//     }
//     else {
//         return true;
//     }
// }
const isTxTypeEmpty = (row, type) => {
    if (!row) return true;
  
    let data = row.customerInvoiceCOATxList?.find((tx) => tx.txType === type);
    if (type === "tax") {
  
      if (data && data.amount) {
        return false
      } else {
        return true
      }
    }
    if (row && row.discountPercentage) {
      return false;
    } else {
      return true;
    }
  };

export default SalesInvoiceDetails;
