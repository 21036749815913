import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SALES_INVOICE_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const saveRecuringInvoice = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    if (props.isBulkInvoice) {
      return axios
      .post(`${config.INVOICE_SERVICE_BASE_URL}${config.API_VERSION}/${config.rootContextInvoiceService}/recurringInvoice/saveInBatch`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
          props.getRecurringInvoiceCount && props.getRecurringInvoiceCount(props);
          props.fetchRecurringInvoices && props.fetchRecurringInvoices(props);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
          }
        })
    } else {
      return axios
        .post(`${config.INVOICE_SERVICE_BASE_URL}${config.API_VERSION}/${config.rootContextInvoiceService}/recurringInvoice/saveRecurringInvoices`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
          props.getRecurringInvoiceCount && props.getRecurringInvoiceCount(props);
          props.fetchRecurringInvoices && props.fetchRecurringInvoices(props);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
          }
        })
    }
  }
}

export const getRecuringInvoiceSetupByInvoiceMasterId = (payload, props) => {
  return dispatch => {
    dispatch({ type: SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_SETUP_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVOICE_SERVICE_BASE_URL}${config.API_VERSION}/${config.rootContextInvoiceService}/recurringInvoice/getRecurringInvoiceData?invoiceMasterId=${payload.invoiceMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_SETUP, data: res.data });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
        }
      })
  }
}

export const resetRecurringInvoiceSetup = () => {
  return dispatch => {
    dispatch({ type: SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_SETUP_RESET });
  }
}

