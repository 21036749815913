import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TeamComp from '../../../../components/company/EmployeeMeta/Team';
import TeamDrawer from '../../../drawer/company/EmployeeMeta/Team';
import { createTeam, getTeam, getTotalTeamCount, deleteTeam } from './action';
import { showModal,hideModal } from '../../../../actions/commonActions';
import DepartmentDrawerCon from '../../../drawer/company/EmployeeMeta/Department';
import BranchDrawerCon from '../../../drawer/company/EmployeeMeta/Branch';

class TeamContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25
    }
  };


  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: this.state.pageNumber || 1,
      pageSize: this.state.pageSize || 25
    }
    this.props.getTeam(payload);
    this.props.getTotalTeamCount(payload);
  }

  render() {
    return <>
      <TeamComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
      {this.state.teamDrawer && <TeamDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      {this.state.departmentDrawer && <DepartmentDrawerCon {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      {this.state.branchDrawer && <BranchDrawerCon {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
    </>
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    userInfo: state.common.userInfo,
    teamList: state.employee.teamList,
    teamCount: state.employee.teamCount,
    permissions: state.auth.permissions,
    saveLoading: state.common.saveLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createTeam,
  getTeam,
  getTotalTeamCount,
  deleteTeam,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TeamContainer);
