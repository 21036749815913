import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SALES_COMPLAINT_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchSalesComplaints = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesComplaint/getAllSalesComplaints?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}
export const getSalesComplaintCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesComplaint/countSalesComplaint?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_COUNT;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_LIST_PAGE_RESET });
  }
}


export const deleteSalesComplaint = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesComplaint/deleteSalesComplaint`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchSalesComplaints(props);
        props.getSalesComplaintCount(props);
        showToasterMessage({ messageType: 'success', description: (res.data || "") || 'Sales Complaint has been deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' });
      })
  }
}
export const fetchComplaints = (payload, props) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';

  let FY = getFinancialYear(payload.companyInfo);
  let status = props?.activeTabKey === "1" ? ['Open', 'In Progress', 'On Hold'] : ["Closed"];
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesComplaint/getAllSalesComplaintsByStatusName?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&statusName=${status || []}${searchedTextQuery}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_DATA, data });

      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      }).finally(() => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
    })
  }
}

export const getComplaintsCount = (payload, props) => {
  let FY = getFinancialYear(payload.companyInfo);
  let status = props.activeTabKey === "1" ? ['Open', 'In Progress', 'On Hold'] : ["Closed"];

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesComplaint/countSalesComplaintsByStatusName?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&statusName=${status || []}`)
      .then(res => {
        dispatch({ type: SALES_COMPLAINT_ACTION_LIST.COMPLAINT_COUNT, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })

      })   .finally(() => {
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
    })
  }
}
