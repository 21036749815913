import React ,{Component, Fragment} from "react";

import { Button, Col, Divider, Row, Skeleton, Tabs, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol, showToasterMessage } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import { MODAL_TYPE } from "../../../static/constants";
import { Link } from 'react-router-dom';

import { getLabelName } from "../../modal/modalBody/customerInfo";
import { CustomAgGrid } from "../../general/CustomAgGrid";

const {TabPane} = Tabs;




class TaxIdentification extends Component {

    addItemConfirmationModal = (props, text, modalType, params) => {
        const { pushModalToStack, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.TAX_IDENTIFICATION: {
                addFunc = props.addTaxIdentification;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId })
                props.popModalFromStack();
                setTimeout(() => {
                    params.api.stopEditing();
                    this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                    this.gridApi.refreshCells({ force: true });
                    this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                    this.gridApi.refreshCells({ columns: ["action"], force: true });
                    params.api.startEditingCell({
                        rowIndex: params.node.rowIndex,
                        colKey: params.column.getId(),
                    });
                }, 2000);
            },
        };
        pushModalToStack(modalData);
    }

    getModalTitle = (modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.TAX_IDENTIFICATION: {
                title = <FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType, params) => {
        const formData = payload.formData;
        const { pushModalToStack } = props;
        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType, params);
        } else {
            const data = {
                title: this.getModalTitle(modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType, params);
                    } else {
                        data.formData.submittedOnce = true;
                        // pushModalToStack(data);
                    }
                }
            };
            pushModalToStack(data);
        }
    }

    deleteClickHandler = (props, modalType, payload ,params) => {
        const { pushModalToStack, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.TAX_IDENTIFICATION: {
                deleteFunc = props.deleteTaxIdentifications;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }
        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId });
                setTimeout(() => {
                    params.api.stopEditing();
                    this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                    this.gridApi.refreshCells({ force: true });
                    this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                    this.gridApi.refreshCells({ columns: ["action"], force: true });
                    params.api.startEditingCell({
                        rowIndex: params.node.rowIndex,
                        colKey: params.column.getId(),
                    });
                }, 2000);

            },
        };
        pushModalToStack(modalData);
    }

    render() {
        const agGridStyle = {
            height: '100%',
            width: '100%'
        };

        const onFirstDataRendered = (params) => {
            params.api.sizeColumnsToFit();
        }
        const getRowHeight = (params) => {
            return 30;
        }


        const columnDefs = [

            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='modal.settings.companyInfo.taxInfo.taxType' defaultMessage='' />  </div>;
                },
                resizable: true,
                field: 'taxIdentificationType',
                editable: true,
                sortable: true,
                width: 300,
                cellEditor: 'customDropDownEditor',
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.allTaxIdentificatinType,
                        optionKeyName: 'taxIdentificationId',
                        valueKeyName: 'type',
                        canAddNew: true,
                        canDelete: true,
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.TAX_IDENTIFICATION, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.TAX_IDENTIFICATION, obj)
                        },
                        onSelect: (selectedValue, option) => {
                            obj.node.data.taxIdentificationType = selectedValue;
                        },
                    }
                },
                cellClass: 'variant-column',
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='modal.settings.companyInfo.taxInfo.number' defaultMessage='' />  </div>;
                },
                field: 'taxIdentificationNumber',
                editable: true,
                sortable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                width: 310,
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.data.taxIdentificationNumber = value;
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='modal.settings.companyInfo.taxInfo.populateOnTransaction' defaultMessage='' />
                        <Tooltip placement="top" title={<FormattedMessage id='modal.settings.companyInfo.taxInfo.populateOnTransaction.tooltip' defaultMessage="" />} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                width: 210,
                field: "populateOnTransaction",
                resizable: true,
                editable: true,
                cellRendererFramework: function (link) {
                    return <div>
                        {link.value ? "Yes" : "No"}
                    </div>
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.data.populateOnTransaction = value ? 1 : 0;
                        }
                    }
                }
            },
            {
                field: 'action',  pinned: 'right',
                width: 115,
                cellRendererFramework: (params) => <div>
                    {(params.data.origination !== 'System') ? <div>
                        <div>
                            {params.node.data.isToEdit &&
                                <div className="table-cell-action">
                                    <Link onClick={() => {
                                    let data = this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data || {};
                                    if (data.taxIdentificationType && data.taxIdentificationNumber) {
                                        data.relationshipId = (this.props.companyInfo || {}).relationshipId
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        this.props.addRelationshipTaxIdentification(data);
                                    }else{
                                        showToasterMessage({ description: 'Tax Type and Number is required' })
                                    }
                                    this.gridApi.refreshCells({ force: true });
                                }} >
                                        <i className="fa fa-save" />
                                    </Link>
                                    <Link onClick={() => {
                                        params.api.stopEditing(true);
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        this.gridApi.refreshCells({ force: true });
                                    }} >
                                        <i className="fa fa-ban" />
                                    </Link>
                                </div>
                            }

                            {!params.node.data.isToEdit &&
                                <div className="table-cell-action" >
                                    <Link onClick={() => {
                                        this.gridApi.forEachNode((rowNode, index) => {
                                            this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                        });
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                                        this.gridApi.refreshCells({ columns: ["action"], force: true });
                                        var startEditingParams = {
                                            rowIndex: params.node.rowIndex,
                                            colKey: params.column.getId(),
                                        };
                                        params.api.startEditingCell(startEditingParams);
                                    }}>
                                        <i className="fa fa-edit" />
                                    </Link>
                                    <Link onClick={() => {
                                    const modalData = {
                                        modalBody: 'Are you sure you want to delete?',
                                        handleSubmit: () => {
                                            this.props.deleteRelationshipTaxIdentifications( {
                                                id: params.node.data.relationshipTaxIdentificationId,
                                                relationshipId: (this.props.companyInfo || {}).relationshipId,
                                              });
                                            this.gridApi.updateRowData({ remove: [this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                                        }
                                    };
                                    this.props.pushModalToStack(modalData);
                                    }}>
                                        <i className="fa fa-remove" />
                                    </Link>
                                </div>}
                        </div>
                    </div>
                        : <div></div>
                    }
                </div>
            }
        ]

        const onGridReady = (params) => {
            this.gridApi = params.api;
            params.api.sizeColumnsToFit();
        }
        return (
            <Fragment>
                <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   

    <div>
    <div className="account-heading">
    <p className="heading">Tax Identification</p>
    <p className="sub-heading">
    Input and maintain your organization's tax identification number for compliance and reporting.</p>
    </div>
    <div className="account-information">
    
    <div className="ag-theme-balham" style={agGridStyle} >
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        rowData={this.props.allRelationshipTaxIdentificatins || []}
                        rowDragManaged={true}
                        disableFocusOnClickOutside={true}
                        suppressClickEdit={true}
                        noSingleClickEdit={true}
                        domLayout={"autoHeight"}
                        animateRows={true}
                        onGridSizeChanged={onFirstDataRendered}
                        getRowHeight={getRowHeight}
                        editType={'fullRow'}
                        onGridReady={onGridReady}
                    >
                    </CustomAgGrid>
                </div>
                <Button className="add-new-record" onClick={
                    () => {
                        let newRowIndex = -1;
                        this.gridApi.forEachNode((rowNode, index) =>{
                            newRowIndex = index;
                            this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                        });
                        this.gridApi.stopEditing();
                        this.gridApi.updateRowData({ add: [{isToEdit: true}] });
                        this.gridApi.startEditingCell({
                            rowIndex: newRowIndex+1,
                            colKey: "taxIdentificationType"
                        });
                        this.gridApi.refreshCells({ force: true });
                    }
                }><FormattedMessage id='modal.settings.companyInfo.taxInfo.addLine' defaultMessage='' />  </Button>

    </div>
   
    </div>
   </div>
   

   
   


</div>
        </div>
                
            </Fragment>
        )
    }
}

export default TaxIdentification;