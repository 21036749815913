/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
//import { AgGridReact } from 'ag-grid-react';
import {
  Checkbox,
  Input,
  Menu,
  Dropdown,
  Pagination,
  Skeleton,
  Button,
  Popover,
  Tooltip,
  Switch,
} from "antd";
import * as find from "lodash.find";
import Tile from "../../tiles/tilesIndex";
import {
  fetchPaginationDataIfNeeded,
  checkACLPermission,
  exportExcel,
  fixedDecimalAndFormateNumber,
  sortTable,
  capitalizeFirstLetter,
  getMomentLoclDateForUIReadOnly,
  getPermissionData,
} from "../../../utils";
import ImportCustomer from "../../../containers/modal/modalBody/customer/Import";
import OpenOrder from "../../../containers/modal/modalBody/customer/OpenOrders";
//import LedgerList from '../../../containers/modal/modalBody/common/ledger';
import TotalOrders from "../../../containers/modal/modalBody/customer/TotalOrders";
import PaidInvoices from "../../../containers/modal/modalBody/customer/PaidInvoices";
import UnpaidInvoices from "../../../containers/modal/modalBody/customer/UnpaidInvoices";
import "./index.scss";
import ShowContact from "./../../../containers/customer/Listing/ShowConact";
import ShowAddress from "./../../../containers/customer/Listing/ShowAddress";
// import LogActivity from './../../../containers/customer/Listing/LogActivity';
// import LogCommunication from './../../../containers/customer/Listing/LogCommunication';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import * as debounce from "lodash.debounce";
import CustomerProfile from "../../../containers/modal/modalBody/customer/Profile";

//import { AG_GRID_CLASS_CONSTANTS } from '../../../static/cssClassConstants';
import {
  CUSTOMER_LISTING_PAGE,
  CONSTANTS,
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  ICONS,
} from "../../../static/constants";
//import { AG_GRID_CONSTANTS } from '../../../static/agGridConstants';
import { AddAndRemoveCustomerListingColumn } from "../../general/AddAndRemoveCustomerListingColumn";
import {
  FileExcelOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  DownOutlined,
  InsertRowRightOutlined,
} from "@ant-design/icons";
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import img1 from "../../../assets/images/Usernewstu.png";
import img2 from "../../../assets/images/Userstu.png";
import img3 from "../../../assets/images/Userdue.png";
import img4 from "../../../assets/images/Userpaid.png";
import PageHeader from "../../Common/pageHeader";
import { EmptyBox } from "../../general/EmptyBox";
import { EmptyTableData } from "../../general/EmptyTableData";

const { Search } = Input;

// const getRowHeight = (params) => {
//     let height = 35;
//     let calcHeight = 1;
//     if (params.data.boLocationList) {
//         const address = find(params.data.boLocationList, { isDefault: 1, locationType: "ShippingAddress" });
//         if (address) {
//             calcHeight = (address.streetAddress1 || address.streetAddress2) ? calcHeight + 1 : calcHeight;
//             calcHeight = (address.stateName || address.cityName || address.zipCode) ? calcHeight + 1 : calcHeight;
//         } else {
//             calcHeight = 2;
//         }
//     }
//     return height * calcHeight;
// }

// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

const CustomersListingComp = (props) => {
  // const [isAllChecked, toggleAllChecked] = useState(false);
  const {
    permissions,
    pageNumber,
    customers,
    customerCount,
    companyInfo,
    updateState,
    searchedText,
    customerOpenOrderCount,
    customerOpenSOCount,
    customerUnpaidBalance,
    customerSalesRevenue,
    selectedItems = [],
    paidInvoiceListCount,
    customerPaidBalance,
    dir = 0,
  } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const customerPermissions = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.CUSTOMERS);
  }, []);

  const customerPaymentPermissions = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_PAYMENT);
  }, []);

  const rfqPermissions = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.RFQ);
  }, []);

  const customerAddressPermissions = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_ADDRESS);
  }, []);

  const customerContactPermissions = useMemo(() => {
    return getPermissionData(
      primaryPerm,
      MODULE_CODED_VALUES.CUSTOMER_CONTACTS
    );
  }, []);

  const customerCommunicationPermissions = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_ADDRESS);
  }, []);
  const isCustomersListPage =
    props.history.location.pathname === "/admin/customers-list";
  const heightStyle = !isCustomersListPage
    ? "calc(100vh - 240px)"
    : "calc(100vh - 130px)";
  // const [isTyping, setIsTyping] = useState(false);
  // let typingTimer;

  // Function to be executed when typing stops
  // const typingStopped = () => {
  //     setIsTyping(false);
  //     handleSearchedText(props.searchedText, props)
  // };

  // // Event handler for keyup event
  // const handleKeyUp = () => {
  //     clearTimeout(typingTimer);
  //     setIsTyping(true);
  //     typingTimer = setTimeout(typingStopped, 1500); // Set the desired delay in milliseconds
  // };

  // // Event handler for keydown event
  // const handleKeyDown = () => {
  //     clearTimeout(typingTimer);
  //     setIsTyping(true);
  // };

  const handleNewAddressAddition = (customerData) => {
    let payload = {};
    let formData = {
      locationType: CONSTANTS.BILLING_ADDRESS,
      customerId: (customerData || {}).customerId,
      pageNumber: pageNumber || 1,
    };
    payload.formData = formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <FormattedMessage id="addItem.text.billingAddress" defaultMessage="" />
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.BILLING_ADDRESS,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    props.pushModalToStack(data);
  };

  const handleNewContactAddition = (customerData) => {
    const { showModal, hideModal, companyInfo } = props;
    let payload = {};
    let formData = {
      customerId: (customerData || {}).customerId,
      pageNumber: pageNumber || 1,
      relationshipId: companyInfo.relationshipId,
    };
    payload.formData = formData;
    const data = {
      title: <FormattedMessage id="addItem.text.contact" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        setTimeout(() => {
          hideModal(data);
          props.fetchCustomers({ relationshipId: companyInfo.relationshipId });
        }, 2000);
      },
    };
    showModal(data);
  };
  // const handleNewEmployeeAddition = (customerData) => {
  //     let payload = {};
  //     let formData = {
  //         firstName: '',
  //         customerId: (customerData || {}).customerId
  //     }
  //     payload.formData = formData;
  //     const { showModal, hideModal, companyInfo } = props;
  //     formData.relationshipId = companyInfo.relationshipId;
  //     const data = {
  //         title: <FormattedMessage id='addItem.text.employee' defaultMessage='' />,
  //         formData,
  //         hideFooter: true,
  //         modalData: {
  //             modalType: MODAL_TYPE.EMPLOYEE,
  //             data: payload,

  //         },
  //         handleSubmit: (formData = {}) => {
  //             data.formData.submittedOnce = true;
  //             showModal(data);
  //         }
  //     };
  //     showModal(data);
  // }

  // const addContact = (customerData) => {
  //     const modalData = {
  //         title: <React.Fragment>
  //             <div className='ant-modal-title'>Contact</div>
  //         </React.Fragment>,
  //         modalBody: <ShowContact customerId={customerData.customerId} />,
  //         width: 1100,
  //         hideFooter: true
  //     };
  //     props.pushModalToStack(modalData);
  // }

  const getCustomerDetailsCell = (data) => {
    // let companyInfo = "<Link to='details' class='company-name'>" + data.companyName + "</Link><br>";
    // if (data.boLocationList && data.boLocationList.length) {
    //     const address = find(data.boLocationList, { isDefault: 1, locationType: "ShippingAddress" }) || {};
    //     if (address.streetAddress1) {
    //         companyInfo = companyInfo + (address.streetAddress1 || "")
    //             + "   <span>" + (address.streetAddress2 || "")
    //             + "</span><br><span>"
    //             + (address.cityName || "")
    //             + "</span> <span>" + (address.stateName || "") + "</span><br>"
    //             + " <span>" + (address.zipCode || "") + "</span>"
    //     }
    // }
    const address =
      find(data.boLocationList, {
        isDefault: 1,
        locationType: "BillingAddress",
      }) || {};
    return address?.streetAddress1 ? (
      <div>
        <Link
          to={{
            pathname: "/admin/customers/details",
            state: {
              customerId: data.customerId,
              customerName: data.companyName,
              customer: data,
            },
          }}
          className="company-name"
        >
          <div className="table-link-text">{data.companyName}</div>
        </Link>

        <div className="light-font">{address?.streetAddress1}</div>
        <div className="light-font">{address?.streetAddress2}</div>
        <div className="light-font">
          <span className="pr5">{address.cityName || ""} </span>
          <span>{address.stateName || ""} - </span>
          <span>{address.zipCode || ""}</span>
        </div>
      </div>
    ) : (
      <div>
        <Link
          to={{
            pathname: "/admin/customers/details",
            state: {
              customerId: data.customerId,
              customerName: data.companyName,
              customer: data,
            },
          }}
          className="company-name"
        >
          <div className="table-link-text">{data.companyName}</div>
        </Link>
        <div>
          {/* <Link onClick={() => handleNewAddressAddition(data)}> Add Address</Link>  */}
          <div
            className="table-link-text"
            onClick={() => handleNewAddressAddition(data)}
          >
            <FormattedMessage id="addAddress.text" defaultMessage="" />
          </div>
        </div>
      </div>
    );
  };

  const getCustomerContactCell = (data) => {
    const contact =
      data.boContactList && find(data.boContactList, { isPrimaryContact: 1 });
    return contact ? (
      <div>
        <div>
          {(contact.salutationName || "") +
            " " +
            (contact.firstName || "") +
            " " +
            (contact.lastName || "")}{" "}
        </div>
        <div>{(contact.cellPhone || "") && "Ph: " + contact.cellPhone} </div>
      </div>
    ) : (
      <div>
        <Link onClick={() => handleNewContactAddition(data)}>
          {" "}
          <FormattedMessage id="addContact.text" defaultMessage="" />
        </Link>
      </div>
    );
  };

  const tilesData = [
    {
      footer: (
        <FormattedMessage
          id="customer.listing.tiles.tatalOrders.label"
          defaultMessage=""
        />
      ),
      count: props.totalOrderListCount,
      type: CUSTOMER_LISTING_PAGE.TOTAL_ORDERS,
      moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
      // icon: 'fa fa-bullseye',
      antIcon: <img src={img1} alt="" />,
      // antIcon: <BookTwoTone />,
      color: "tile2",
    },
    {
      footer: (
        <FormattedMessage
          id="customer.listing.tiles.openSalesOrder.label"
          defaultMessage=""
        />
      ),
      count: customerOpenSOCount,
      // icon: 'fa fa-file-text-o',
      antIcon: <img src={img2} alt="" />,
      // antIcon: <ContainerTwoTone />,
      type: CUSTOMER_LISTING_PAGE.OPEN_SO,
      moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
      color: "tile1",
    },
    {
      footer: (
        <FormattedMessage
          id="customer.listing.tiles.unpaidBalance.label"
          defaultMessage=""
        />
      ),
      count: customerUnpaidBalance,
      // icon: companyInfo.currencyIcon,
      // antIcon: <EuroCircleTwoTone />,
      antIcon: <img src={img3} alt="" />,
      currencyIcon: companyInfo.currencyIcon,
      type: CUSTOMER_LISTING_PAGE.UNPAID_BALANCE,
      moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
      color: "tile4",
    },
    {
      footer: (
        <FormattedMessage
          id="customer.listing.tiles.salesRevenue.label"
          defaultMessage=""
        />
      ),
      count: customerPaidBalance,
      // icon: companyInfo.currencyIcon,
      currencyIcon: companyInfo.currencyIcon,
      antIcon: <img src={img4} alt="" />,
      // antIcon: <DollarCircleTwoTone />,
      type: CUSTOMER_LISTING_PAGE.PAID_BALANCE,
      moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
      color: "tile3",
    },
  ];

  const newCustomer = () => {
    props.updateHeaderState({
      customer: {
        ...props.customer,
        customerDrawerVisible: true,
      },
    });
  };

  const editCustomer = (customerData) => {
    props.updateHeaderState({
      customer: {
        ...props.customer,
        customerDrawerVisible: true,
        isUpdate: true,
        customerData: customerData,
      },
    });
  };

  const cloneCustomer = (customerData) => {
    props.updateHeaderState({
      customer: {
        ...props.customer,
        customerDrawerVisible: true,
        isClone: true,
        customerData: customerData,
      },
    });
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>
        <Menu.Divider />
        {/* {
                    permittedModules.firstSection.salesInquiryCreate
                        ? (
                            <Menu.Item key="0">
                                <i className="fa fa-file-text-o" />
                                <Link to={
                                    {
                                        pathname: 'sales-inquiry-create',
                                        state: {
                                            customerId: param.data.customerId,
                                            customer: param.data,
                                            customerName: param.data.companyName,
                                            pageNumber: props.pageNumber
                                        }
                                    }}><FormattedMessage id='createInquiry.text' defaultMessage='' /></Link>
                            </Menu.Item>
                        )
                        : <Fragment />
                } */}
        {/* {
                    permittedModules.firstSection.rfqCreate
                        ? (
                            <Menu.Item key="1">
                                <i className="fa fa-file-text-o" />
                                <Link to={
                                    {
                                        pathname: 'rfq-create',
                                        state: {
                                            customerId: param.data.customerId,
                                            customer: param.data,
                                            customerName: param.data.companyName,
                                            pageNumber: props.pageNumber
                                        }
                                    }}>Create RFQ</Link>
                            </Menu.Item>
                        )
                        : <Fragment />
                } */}
        {/* {
                    permittedModules.firstSection.salesQuoteCreate
                        ? (
                            <Menu.Item key="2">
                                <i className="fa fa-file-text-o" />
                                <Link to={
                                    {
                                        pathname: 'create-sales-quote',
                                        state: {
                                            customerId: param.data.customerId,
                                            customerName: param.data.companyName,
                                            pageNumber: props.pageNumber
                                        }
                                    }}><FormattedMessage id='createInquiry.text' defaultMessage='' /></Link>
                            </Menu.Item>
                        )
                        : <Fragment />
                } */}
        {/* {
                    permittedModules.firstSection.salesOrderCreate
                        ? (
                            <Menu.Item key="3">
                                <i className="fa fa-file-text-o" />
                                <Link to={
                                    {
                                        pathname: 'so-create',
                                        state: {
                                            customer: param.data,
                                            customerId: param.data.customerId,
                                            customerName: param.data.companyName,
                                            pageNumber: props.pageNumber
                                        }
                                    }}><FormattedMessage id='createSalesOrder.text' defaultMessage='' /></Link>
                            </Menu.Item>
                        )
                        : <Fragment />
                } */}
        {/* {
                    permittedModules.firstSection.salesInvoiceCreate
                        ? (
                            <Menu.Item key="4">
                                <i className="fa fa-file-o" />
                                <Link to={
                                    {
                                        pathname: 'sales-invoice',
                                        state: {
                                            customer: param.data,
                                            customerId: param.data.customerId,
                                            customerName: param.data.companyName,
                                            pageNumber: props.pageNumber
                                        }
                                    }}><FormattedMessage id='createInvoice.text' defaultMessage='' /></Link>
                            </Menu.Item>
                        )
                        : <Fragment />
                }
                {
                    permittedModules.firstSection.creditMemoCreate
                        ? (
                            <Menu.Item key="5">
                                <i className="fa fa-file-text-o" />
                                <Link
                                    to={
                                        {
                                            pathname: 'create-credit-memo',
                                            state: {
                                                customer: param.data,
                                                customerId: param.data.customerId,
                                                customerName: param.data.companyName,
                                                pageNumber: props.pageNumber
                                            }
                                        }}
                                >
                                    <FormattedMessage id='createCreditMemo.text' defaultMessage='' />
                                </Link>
                            </Menu.Item>
                        )
                        : <Fragment />
                } */}
        {/* {
                    permittedModules.firstSection.customerPayment
                        ? (
                            <Menu.Item key="6">
                                <i className="fa fa-money" />
                                <Link>Accept Payment</Link>
                            </Menu.Item>
                        )
                        : <Fragment />
                } */}

        {/* {
                    Object.values(permittedModules.firstSection).length
                        ? <Menu.Divider />
                        : <Fragment />
                } */}

        {customerPermissions?.update ? (
          <Menu.Item key="7" value="edit" onClick={() => editCustomer(item)}>
            <i className={ICONS.EDIT} /> Edit
          </Menu.Item>
        ) : (
          <Fragment />
        )}

        {customerPermissions?.create ? (
          <Menu.Item onClick={() => cloneCustomer(item)} key="8">
            <i className={ICONS.COPY} /> Clone
          </Menu.Item>
        ) : (
          ""
        )}

        {customerPermissions?.delete ? (
          <Menu.Item
            key="9"
            onClick={() => {
              const ids = [];
              if (item && item.customerId) {
                ids.push(item.customerId);
              }
              if (ids.length) {
                const modalData = {
                  modalBody: "Are you sure you want to delete customer?",
                  handleSubmit: () => {
                    props.deleteCustomers({
                      relationshipId: props.companyInfo.relationshipId,
                      groupCustomerIds: ids,
                      pageNumber: props.pageNumber,
                      pageSize: props.pageSize,
                    });
                  },
                };
                props.pushModalToStack(modalData);
              } else {
                const modalData = {
                  title: (
                    <div className="title">
                      <i className="fa fa-ban mr15" />
                      Validation Failed
                    </div>
                  ),
                  modalBody: (
                    <Fragment>
                      <div className="warning-message">
                        No customer selected. Please select
                        customerTransaction(s) before deleting.
                      </div>
                      <div className="button-action">
                        <Button
                          className="ant-btn-default"
                          onClick={() => props.popModalFromStack()}
                        >
                          OK
                        </Button>
                      </div>
                    </Fragment>
                  ),
                  hideFooter: true,
                  wrapClassName: "validation-failed-modal",
                };
                props.pushModalToStack(modalData);
              }
            }}
          >
            <i className={ICONS.DELETE} /> Delete
          </Menu.Item>
        ) : (
          <Fragment />
        )}

        {/* {(customerAddressPermissions?.read || customerContactPermissions?.read) ? (
          <Menu.Divider />
        ) : (
          <Fragment />
        )}

        {customerAddressPermissions?.read ? (
          <Menu.Item
            key="10"
            onClick={() => {
              const modalData = {
                title: (
                  <div>
                    <div className="ant-modal-title">Address</div>
                    <div className="light-txt"> {item.companyName}</div>
                  </div>
                ),
                modalBody: (
                  <ShowAddress {...props} customerId={item.customerId} />
                ),
                width: 1100,
                hideFooter: true,
              };
              props.pushModalToStack(modalData);
            }}
          >
            <i className="fi fi-rs-house-chimney" /> Show Address
          </Menu.Item>
        ) : (
          ""
        )}

        {customerContactPermissions?.read ? (
          <Menu.Item
            key="11"
            onClick={() => {
              const modalData = {
                title: (
                  <div>
                    <div className="ant-modal-title">Contact</div>
                    <div>{item.companyName}</div>
                  </div>
                ),
                modalBody: (
                  <ShowContact {...props} customerId={item.customerId} />
                ),
                width: 1100,
                hideFooter: true,
              };
              props.pushModalToStack(modalData);
            }}
          >
            <i className="fi fi-rr-address-book" /> Show Contact
          </Menu.Item>
        ) : (
          ""
        )} */}

        {/* {
                    Object.values(permittedModules.thirdSection).length
                        ? <Menu.Divider />
                        : <Fragment />
                } */}

        {/* {
                    permittedModules.fourthSection.logActivity
                        ? (
                            <Menu.Item key="12">
                                <i className="fa fa-pencil-square-o" />
                                <a onClick={() => {
                                    const modalData = {
                                        title: <React.Fragment>
                                            <div className='modal-title'>
                                                New Activity
                            </div>
                                            <div className="modal-sub-title">
                                                {param.data.companyName}
                                            </div>
                                        </React.Fragment>,
                                        modalBody: <LogActivity
                                            customerId={param.data.customerId}
                                            customerName={param.data.companyName}
                                        />,
                                        wrapClassName: 'log-activity-modal customer-listing',
                                        width: 500,
                                        hideFooter: true
                                    };
                                    props.pushModalToStack(modalData);
                                }}
                                >Log Activity
                            </Menu.Item>
                        )
                        : <Fragment />
                }

                {
                    permittedModules.fourthSection.logCommunication
                        ? (
                            <Menu.Item key="13">
                                <i className="fa fa-bolt" />
                                <a onClick={() => {
                                    const modalData = {
                                        title: <React.Fragment>
                                            <div className='modal-title'>
                                                New Communication
                            </div>
                                            <div className="modal-sub-title">
                                                {param.data.companyName}
                                            </div>
                                        </React.Fragment>,
                                        modalBody: <LogCommunication
                                            customerId={param.data.customerId}
                                            customerName={param.data.companyName}
                                        />,
                                        width: 500,
                                        hideFooter: true,
                                        wrapClassName: 'log-communication-modal',
                                    };
                                    props.pushModalToStack(modalData);
                                }}
                                >Log Communication
                            </Menu.Item>
                        )
                        : <Fragment />
                } */}

        {/* <Menu.Item key="14">
                    <i className="fa fa-file-pdf-o" />
                    <a onClick={() => {
                        const relationshipAddress = (props.companyInfo.boLocationList || []).find(x => x.isDefault && x.locationType === "RelationshipBillingAddress") || {};
                        props.showModal({
                            title: <Fragment>
                                <div className='ant-modal-title'>PARTY LEDGER - {param.data.companyName}</div>
                            </Fragment>,
                            modalBody: <LedgerList customerObj={param.data} relationshipAddress={relationshipAddress} {...props} />,
                            width: 1000,
                            hideFooter: true,
                            wrapClassName: 'tiles-modal'
                        })
                        //props.exportLedgerPdf({ customerId: param.data.customerId, customerObj: param.data, relationshipAddress: relationshipAddress }, props)
                    }}
                    >Download Ledger Statement
                </Menu.Item> */}
      </Menu>
    );
  };

  const openModalOfSelectedTile = (data) => {
    switch (data.type) {
      case CUSTOMER_LISTING_PAGE.TOTAL_ORDERS:
        props.showModal({
          title: <div className="ant-modal-title">Total Orders</div>,
          modalBody: <TotalOrders {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case CUSTOMER_LISTING_PAGE.OPEN_SO:
        props.showModal({
          title: <div className="ant-modal-title">Open Sales Orders</div>,
          modalBody: <OpenOrder {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case CUSTOMER_LISTING_PAGE.UNPAID_BALANCE:
        props.showModal({
          title: <div className="ant-modal-title">Unpaid Balance</div>,
          modalBody: <UnpaidInvoices {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case CUSTOMER_LISTING_PAGE.PAID_BALANCE:
        props.showModal({
          title: <div className="ant-modal-title">Sales Revenue</div>,
          modalBody: <PaidInvoices {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      default:
        break;
    }
  };

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  // const columnDefs = [
  //     {
  //         field: 'isChecked',
  //         colId: 'isChecked_1',
  //         headerComponentFramework: () => <Fragment />,
  //         cellRendererFramework: (params) => {
  //             return <Checkbox
  //                 onClick={() => {
  //                     params.node.setDataValue('isChecked_1', !params.data.isChecked)
  //                 }}
  //                 checked={params.data.isChecked} />
  //         },
  //         minWidth: 70,
  //         maxWidth: 70,
  //         pinned: 'left',
  //         suppressMovable: true,
  //         lockPosition: true,
  //         width: 60,
  //         resizable: false
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.listing.header.customerDetails' defaultMessage='' />;
  //         },
  //         field: "companyName",
  //         hide: _isColumnToHide('companyName'),
  //         colId: "companyName_1",
  //         cellRendererFramework: function (param) {
  //             return <div>{getCustomerDetailsCell(param.data)}</div>;
  //         },
  //         resizable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.listing.header.contact' defaultMessage='' />;
  //         },
  //         field: "contact",
  //         hide: _isColumnToHide('contact'),
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         colId: "contact_1",
  //         cellRendererFramework: function (param) {
  //             return getCustomerContactCell(param.data);
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='customer.listing.header.dueAmount' defaultMessage='' />   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</div>;
  //         },
  //         field: "totalAmountDue",
  //         hide: _isColumnToHide('totalAmountDue'),
  //         colId: "totalAmountDue_1",
  //         valueFormatter: (data) => {
  //             data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
  //             return data.value;
  //         },
  //         resizable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.listing.header.openOrder' defaultMessage='' />;
  //         },
  //         field: "openedOrdersCount",
  //         hide: _isColumnToHide('openedOrdersCount'),
  //         colId: "openedOrdersCount_1",
  //         cellRendererFramework: function (param) {
  //             return <div> <p onClick={() => {
  //                 props.showModal(
  //                     {
  //                         title: <Fragment>
  //                             <div className='ant-modal-title'>Open Orders</div>
  //                             <div className="ant-modal-subtitle extra-sm">{param.data.companyName}</div>
  //                         </Fragment>,
  //                         modalBody: <OpenOrder {...props} customerData={param.data} />,
  //                         width: 1000,
  //                         hideFooter: true,
  //                         wrapClassName: 'tiles-modal'
  //                     }
  //                 )
  //             }} className={param.data.openedOrdersCount ? 'cursor-pointer' : ''}> {param.data.openedOrdersCount}</p>
  //                 <p> {param.data.openedOrdersTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(param.data.openedOrdersTotalAmount || '') && param.data.openedOrdersTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
  //             </div>;
  //         },
  //         resizable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.listing.header.unpaidInvoices' defaultMessage='' />;
  //         },
  //         field: "unpaidInvoicesCount",
  //         hide: _isColumnToHide('unpaidInvoicesCount'),
  //         colId: "unpaidInvoicesCount_1",
  //         cellRendererFramework: function (param) {
  //             return <div> <p onClick={() => {
  //                 props.showModal(
  //                     {
  //                         title: <Fragment>
  //                             <div className='ant-modal-title'>Unpaid Invoices</div>
  //                             <div className="ant-modal-subtitle extra-sm">{param.data.companyName}</div>
  //                         </Fragment>,
  //                         modalBody: <UnpaidInvoices customerData={param.data} {...props} />,
  //                         width: 1000,
  //                         hideFooter: true,
  //                         wrapClassName: 'tiles-modal'
  //                     }
  //                 )
  //             }} className={param.data.unpaidInvoicesCount ? 'cursor-pointer' : ''} > {param.data.unpaidInvoicesCount}</p>
  //                 <p> {param.data.unpaidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(param.data.unpaidInvoicesTotalAmount || '') && param.data.unpaidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
  //             </div>;
  //         },
  //         resizable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.listing.header.paidInvoices' defaultMessage='' />;
  //         },
  //         field: "paidInvoicesCount",
  //         hide: _isColumnToHide('paidInvoicesCount'),
  //         colId: "paidInvoicesCount_1",
  //         cellRendererFramework: function (param) {
  //             return <div> <p onClick={() => {
  //                 props.showModal(
  //                     {
  //                         title: <Fragment>
  //                             <div className='ant-modal-title'>Paid Invoices</div>
  //                             <div className="ant-modal-subtitle extra-sm">{param.data.companyName}</div>
  //                         </Fragment>,
  //                         modalBody: <PaidInvoices customerData={param.data} {...props} />,
  //                         width: 900,
  //                         hideFooter: true,
  //                         wrapClassName: 'tiles-modal paid-invoice'
  //                     }
  //                 )
  //             }} className={param.data.paidInvoicesCount ? 'cursor-pointer' : ''}> {param.data.paidInvoicesCount}</p>
  //                 <p> {param.data.paidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(param.data.paidInvoicesTotalAmount || '') && param.data.paidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
  //             </div>;
  //         },
  //         resizable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.listing.header.paymentTerm' defaultMessage='' />;
  //         },
  //         field: "paymentTermName",
  //         hide: _isColumnToHide('paymentTermName'),
  //         colId: "paymentTermName_1",
  //         resizable: true
  //     }
  // ];

  // if (Object.keys(permittedModules.firstSection).length || Object.keys(permittedModules.firstSection).length
  //     || Object.keys(permittedModules.firstSection).length || Object.keys(permittedModules.firstSection).length) {
  //     columnDefs.push({
  //         headerComponentFramework: () => {
  //             return <div className="cursor-pointer">
  //                 <Popover
  //                     content={<AddAndRemoveCustomerListingColumn {...props} />}
  //                     title={<div className="text-center">Add/Remove</div>}
  //                     placement="left"
  //                     trigger="click"
  //                 >
  //                     <i className='fa fa-bars' />
  //                 </Popover>
  //             </div>;
  //         },
  //         cellRendererFramework: (param) => {
  //             return <Dropdown overlay={actionMenu(param, permittedModules)} trigger={['click']}>
  //                 <Button className="action-btn small">
  //                     <i className="fa fa-ellipsis-v" />
  //                 </Button>
  //             </Dropdown>
  //         },
  //         resizable: true,
  //         pinned: 'right',
  //         field: 'action',
  //         colId: "action_1",
  //         minWidth: 52,
  //         maxWidth: 52,
  //         suppressMovable: true,
  //         cellClass: 'lastActionCell'
  //     });
  // }

  // const onFirstDataRendered = (params) => {
  //     //params.api.sizeColumnsToFit();
  // }

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchCustomers({ ...payload, hideLoad: true });
  };

  const handlePageSizeChange = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    // props.resetPaginatedData();
    props.fetchCustomers({ ...payload, hideLoad: true });
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const handleMenuClick = (e) => {
    // switch (e.key) {
    //   case "upload_customer":

    //     break;

    //   default:
    //     break;
    const modalData = {
      title: (
        <div className="ant-modal-title">
          <FormattedMessage
            id="customer.listing.importCustomer.modal.title"
            defaultMessage=""
          />
        </div>
      ),
      modalBody: <ImportCustomer {...props} />,
      hideFooter: true,
      width: "100%",
      handleSubmit: () => {
        //props.createOrUpdateCustomer(payload);
        //props.hideModal();
      },
      wrapClassName: "import-details-modal file-header",
    };
    props.pushModalToStack(modalData);
  };
  const getAddressInfoFragment = (obj = {}) => {
    return (
      <>
        <div className="billing-address first-caps">
          {obj?.streetAddress1 || obj?.streetAddress2 ? (
            <div>
              {obj.streetAddress1} {obj.streetAddress2}
            </div>
          ) : (
            ""
          )}
          {obj.cityName || obj.stateName || obj.zipCode ? (
            <div>
              {obj.cityName} {obj.stateName}
              {obj.stateName && obj.zipCode ? ", " : ""}
              {obj.zipCode}
            </div>
          ) : (
            ""
          )}
          {obj.countryName ? <div>{obj.countryName}</div> : ""}
        </div>

        {/* {
                obj.streetAddress1 ? <span className='billing-address'>{obj.streetAddress1}</span>: ''
            }
            {
                obj.streetAddress2 ? <span className='billing-address'> {obj.streetAddress2}</span>: ''
            } */}
        {/* {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""}${obj.stateName ? ", " + capitalizeFirstLetter(obj.stateName) : ""
              }`}
          </div>
        ) : (
          ""
        )}
        {obj.countryName ? (
          <div
            className="billing-address"
            style={{ textTransform: "uppercase" }}
          >
            {`${capitalizeFirstLetter(obj.countryName) || ""}`}
          </div>
        ) : (
          ""
        )} */}
        {/* {
                additionInfo.workPhone
                    ? <div className='billing-address'>
                        <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
                    </div> :
                    ''
            }
            {
                additionInfo.email
                    ? <div className='billing-address'>
                        <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
                    </div> :
                    ''
            } */}
      </>
    );
  };
  // const menu = (
  //   <Menu className="row-action-dropdown">
  //     <Menu.Item key="0" value="title">
  //       Import
  //     </Menu.Item>
  //     <Menu.Divider />
  //     <Menu.Item
  //       key="upload_customer"
  //       value="upload_customer"
  //       onClick={handleMenuClick}
  //     >
  //       <DownloadOutlined />
  //       <FormattedMessage
  //         id="customer.listing.buttonMenu.importCustomer"
  //         defaultMessage=""
  //       />
  //     </Menu.Item>
  //   </Menu>
  // );

  // const onGridReady = (params) => {
  //     if (props.isColumnFitTable) {
  //         params.api.sizeColumnsToFit();
  //     }
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi,
  //     });
  //     onDataRendered(params);
  // }

  // const saveColumnIndex = (columnState) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     data.tablePreference = JSON.stringify(tablePreference);

  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data);
  // }

  // const saveColumnWidth = (columnState, width) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data, props);
  // }

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //     if (event.column) {
  //         moveEvent = event;
  //     }
  //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
  //         saveColumnIndex(moveEvent.columnApi.getColumnState());
  //     }
  // }

  // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //     event.api.resetRowHeights();
  //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
  //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
  //             props.gridApi.sizeColumnsToFit();
  //             setTimeout(() => {
  //                 const columnArray = event.columnApi.getColumnState();
  //                 saveColumnWidth(columnArray);
  //             }, 1000);
  //         } else {
  //             saveColumnWidth(event.columnApi.getColumnState());
  //         }
  //     }
  // }

  // const onModelUpdated = (params) => {
  //     if((customers[pageNumber] || []).length &&  !props.isRowHeightSet){
  //         setTimeout(()=>{
  //             params.api.resetRowHeights();
  //         }, 1000)
  //         props.updateState({
  //             isRowHeightSet: true
  //         })
  //     }
  //     if (props.tableDataReRendered) {
  //         //params.api.resetRowHeights();
  //         props.updateState({
  //             tableDataReRendered: false
  //         })
  //         const txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     }
  // }

  // const onDataRendered = (params) => {
  //     const txColumnSetting = props.txColumnSetting;
  //     if (Object.keys(txColumnSetting).length) {
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.width = 52;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     } else {
  //         params.api.sizeColumnsToFit();
  //     }
  // }

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      customerId: Number(item.customerId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.customerId !== item.customerId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < customers[pageNumber].length; i++) {
      let item = customers[pageNumber][i];
      let selectItem = find(selectedItems, {
        customerId: Number(item.customerId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  };

  const isCheckedAll = () => {
    if (
      !customers ||
      !customers[pageNumber] ||
      customers[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < customers[pageNumber].length; i++) {
      let usr = customers[pageNumber][i];
      let selectItem = find(selectedItems, {
        customerId: Number(usr.customerId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };
  const breadCrumbList = [
    // {
    //   name: <FormattedMessage id="sidebar.menuItem.crm" defaultMessage="" />,
    // },

    // {
    //   name: (
    //     <FormattedMessage
    //       id="header.menuItem.customer.label"
    //       defaultMessage=""
    //     />
    //   ),
    // },
    {
      name: "Sales",
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.customers" defaultMessage="" />
      ),
    },
  ];

  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="excel"
          onClick={() => {
            if (selectedItems.length) {
              exportExcelData(selectedItems);
            } else {
              const modalData = {
                title: (
                  <div className="title">
                    <i className="fa fa-ban mr15" />
                    Validation Failed
                  </div>
                ),
                modalBody: (
                  <Fragment>
                    <div className="warning-message">
                      No customer selected. Please select customer before
                      downloading.
                    </div>
                    <div className="button-action">
                      <Button
                        className="ant-btn-default"
                        onClick={() => props.popModalFromStack()}
                      >
                        OK
                      </Button>
                    </div>
                  </Fragment>
                ),
                hideFooter: true,
                wrapClassName: "validation-failed-modal",
              };
              props.pushModalToStack(modalData);
            }
          }}
        >
          <FileExcelOutlined />
          Excel File
        </Menu.Item>

        <Menu.Item
          key="2"
          value="pdf"
          onClick={() => {
            const customerList = [];
            selectedItems.forEach((data) => {
              // let data = obj.data;
              let address =
                find(data.boLocationList, {
                  isDefault: 1,
                  locationType: "BillingAddress",
                }) || {};
              let contact =
                data.boContactList &&
                find(data.boContactList, { isPrimaryContact: 1 });
              customerList.push({
                ...data,
                defaultAddress: address,
                defaultContact: contact,
              });
            });
            if (customerList.length) {
              props.generatePDF({
                companyInfo: props.companyInfo,
                fileName: "Customers.pdf",
                transactionName: "customerListing",
                customerList: customerList,
              });
            } else {
              const modalData = {
                title: (
                  <div className="title">
                    <i className="fa fa-ban mr15" />
                    Validation Failed
                  </div>
                ),
                modalBody: (
                  <Fragment>
                    <div className="warning-message">
                      No customer selected. Please select customer before
                      downloading.
                    </div>
                    <div className="button-action">
                      <Button
                        className="ant-btn-default"
                        onClick={() => props.popModalFromStack()}
                      >
                        OK
                      </Button>
                    </div>
                  </Fragment>
                ),
                hideFooter: true,
                wrapClassName: "validation-failed-modal",
              };
              props.pushModalToStack(modalData);
            }
          }}
        >
          <FilePdfOutlined />
          PDF File
        </Menu.Item>
      </Menu>
    );
  };

  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <i className={ICONS.SELECT_ALL} /> Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <i className={ICONS.UNSELECT_ALL} /> Unselect all
        </Menu.Item>

        {customerPermissions?.delete && (
          <Menu.Item
            key="3"
            onClick={() => {
              const ids = [];
              selectedItems.forEach((obj) => {
                ids.push(obj.customerId);
              });
              if (ids.length) {
                const modalData = {
                  modalBody: "Are you sure you want to delete customer?",
                  handleSubmit: () => {
                    props.resetPaginatedData();
                    props.deleteCustomers({
                      relationshipId: props.companyInfo.relationshipId,
                      groupCustomerIds: ids,
                    });
                    props.fetchCustomers({
                      relationshipId: props.companyInfo.relationshipId,
                      pageNumber: props.pageNumber,
                      pageSize: props.pageSize,
                    });
                  },
                };
                props.pushModalToStack(modalData);
              } else {
                const modalData = {
                  title: (
                    <div className="title">
                      <i className="fa fa-ban mr15" />
                      Validation Failed
                    </div>
                  ),
                  modalBody: (
                    <Fragment>
                      <div className="warning-message">
                        No customer selected. Please select
                        customerTransaction(s) before deleting.
                      </div>
                      <div className="button-action">
                        <Button
                          className="ant-btn-default"
                          onClick={() => props.popModalFromStack()}
                        >
                          OK
                        </Button>
                      </div>
                    </Fragment>
                  ),
                  hideFooter: true,
                  wrapClassName: "validation-failed-modal",
                };
                props.pushModalToStack(modalData);
              }
            }}
          >
            <i className={ICONS.DELETE} /> Delete
          </Menu.Item>
        )}
      </Menu>
    );
  };

  // const sortColumn = (e) => {
  //   sortTable(
  //     document.getElementById("customers-table"),
  //     e.target.cellIndex,
  //     1 - dir
  //   );
  //   updateState({ dir: 1 - dir });
  // };

  const getPrimaryContact = (rowData) => {
    let primaryContact =
      (rowData.boContactList || []).find((obj) => obj.isPrimaryContact) || {};
    return (
      <>
        <div className="flex mb5">
          {primaryContact.cellPhone || rowData?.customerPhone ? (
            <>
              <i className={`${ICONS.CALL} mr5`} /> +
              {primaryContact.countryCallingCode || rowData.countryCallingCode}{" "}
              {primaryContact.cellPhone || rowData?.customerPhone}
            </>
          ) : (
            <EmptyBox boxSize={"small"} />
          )}
        </div>

        <div className="flex">
          {primaryContact.emailAddress || rowData?.customerEmail ? (
            <>
              <i className={`${ICONS.MAIL} mr5`} />{" "}
              {primaryContact.emailAddress || rowData?.customerEmail}
            </>
          ) : (
            <EmptyBox boxSize={"small"} />
          )}
        </div>
      </>
    );
  };

  const openCustomerProfile = (rowData, props) => {
    const modalData = {
      title: (
        <div className="flex justify-space-between">
          <div>Customer profile</div>
        </div>
      ),
      modalBody: (
        <CustomerProfile
          {...props}
          customerId={rowData.customerId}
          customer={rowData}
        />
      ),
      alertCancel: true,
      onCancel: () => {
        props.popModalFromStack();
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          userId: (props.userInfo || {}).userId,
          pageNumber: 1,
          pageSize: 25,
        };
        props.fetchCustomers(payload);
        props.getCustomerCount(payload);
        return;
      },
      hideFooter: true,
      hideTitle: true,
      width: "100%",
      height: "100%",
      maskClosable: true,
      keyboard: false,
      wrapClassName: "new-transaction-wrapper",
    };
    props.pushModalToStack(modalData);
  };

  const handleSortColumn = (index) => {
    const currentSortDirection = props.sortStates[index] || "none";
    const nextSortDirection =
      currentSortDirection === "none"
        ? "asc"
        : currentSortDirection === "asc"
        ? "desc"
        : "none";

    // Update sorting state using props.updateState
    props.updateState({
      sortStates: {
        // ...props.sortStates,
        [index]: nextSortDirection,
      },
    });

    // Call your sorting function
    const direction = nextSortDirection === "desc" ? 1 : 0;
    sortTable(document.getElementById("customer-table"), index, direction);
  };
  const getSortIcon = (index) => {
    const sortState = props.sortStates[index];
    if (sortState === "asc") return <i className={ICONS.SORT_UP} />;
    if (sortState === "desc") return <i className={ICONS.SORT_DOWN} />;
    return (
      <i
        className={`${ICONS.SORT} ${
          props.hoveredColumn === index ? "opopacity1" : "opopacity0"
        } `}
      />
    );
  };

  return (
    <>
      <PageHeader
        {...props}
        pageName="expense.customers"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={newCustomer}
        moduleCode={MODULE_CODED_VALUES.CUSTOMERS}
      />

      {!(props.history.location.pathname == "/admin/customers-list") ? (
        <Tile
          tilesData={tilesData}
          {...props}
          getSelectedTile={openModalOfSelectedTile}
          className="p10"
        />
      ) : (
        ""
      )}

      <div className="view-container" style={{ height: heightStyle }}>
        <div className="view-container-actions">
          <div className="left-actions">
            <Skeleton loading={props.headerLoading} paragraph={false} active>
              <div className="table-heading">
                <FormattedMessage
                  id="sales.customerList.table.heading"
                  defaultMessage=""
                />
              </div>
              {customerCount}
            </Skeleton>
          </div>
          <div className="right-actions">
            <Search
              placeholder="Enter 3 characters customer name"
              value={searchedText}
              onChange={(e) => {
                updateState({ searchedText: e.target.value, pageNumber: 1 });
                onSearchedInputChange(e.target.value, props);
              }}
              onSearch={(searchedText) => {
                updateState({ searchedText, pageNumber: 1 });
                onSearchedInputChange(searchedText, props);
                handleSearchedText(searchedText, props);
              }}
            />
            <Popover
              content={<AddAndRemoveCustomerListingColumn {...props} />}
              title={<div className="text-center">Add/Remove</div>}
              placement="left"
              trigger="click"
            >
              <button>
                <InsertRowRightOutlined className="mr5" /> Columns
              </button>
            </Popover>
            <Dropdown overlay={downloadMenu()} trigger={["click"]}>
              <span>
                <DownloadOutlined /> &nbsp;{" "}
                <FormattedMessage
                  id="common.action.dropdown.downloadAs"
                  defaultMessage=""
                />
              </span>
            </Dropdown>
            {customerPermissions?.create ? (
              <button onClick={handleMenuClick}>
                <DownloadOutlined /> Import
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="table-container">
          <Skeleton loading={props.listLoading} paragraph={{ rows: 11 }} active>
            <table id="customer-table">
              <thead>
                <tr>
                  <th>
                    <div className="check-cell">
                      <Checkbox
                        onChange={(e) => {
                          selectAll(e.target.checked);
                        }}
                        checked={isCheckedAll()}
                      />
                      <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                        <DownOutlined />
                      </DropdownAction>
                    </div>
                  </th>
                  {!_isColumnToHide("customerType") && (
                    <th>
                      <div
                        onClick={() => handleSortColumn(1)}
                        onMouseEnter={() =>
                          props.updateState({ hoveredColumn: 1 })
                        }
                        onMouseLeave={() =>
                          props.updateState({ hoveredColumn: null })
                        }
                        className="sortTable-Container justify-start"
                      >
                        Type
                        {getSortIcon(1)}
                      </div>
                    </th>
                  )}
                  <th>
                    <div
                      onClick={() => handleSortColumn(2)}
                      onMouseEnter={() =>
                        props.updateState({ hoveredColumn: 2 })
                      }
                      onMouseLeave={() =>
                        props.updateState({ hoveredColumn: null })
                      }
                      className="sortTable-Container justify-start"
                    >
                      Customer Name
                      {getSortIcon(2)}
                    </div>
                  </th>
                  <th>
                    <div
                      onClick={() => handleSortColumn(3)}
                      onMouseEnter={() =>
                        props.updateState({ hoveredColumn: 3 })
                      }
                      onMouseLeave={() =>
                        props.updateState({ hoveredColumn: null })
                      }
                      className="sortTable-Container justify-start"
                    >
                      Contact
                      {getSortIcon(3)}
                    </div>
                  </th>
                  <th>
                    <div
                      onClick={() => handleSortColumn(4)}
                      onMouseEnter={() =>
                        props.updateState({ hoveredColumn: 4 })
                      }
                      onMouseLeave={() =>
                        props.updateState({ hoveredColumn: null })
                      }
                      className="sortTable-Container justify-start"
                    >
                      Address {getSortIcon(4)}
                    </div>
                  </th>
                  <th>
                    <div
                      onClick={() => handleSortColumn(5)}
                      onMouseEnter={() =>
                        props.updateState({ hoveredColumn: 5 })
                      }
                      onMouseLeave={() =>
                        props.updateState({ hoveredColumn: null })
                      }
                      className="sortTable-Container justify-end"
                    >
                      <FormattedMessage
                        id="customer.listing.header.openOrder"
                        defaultMessage=""
                      />{" "}
                      {getSortIcon(5)}
                    </div>
                  </th>
                  <th className="amount-TextRight">
                    <div
                      onClick={() => handleSortColumn(6)}
                      onMouseEnter={() =>
                        props.updateState({ hoveredColumn: 6 })
                      }
                      onMouseLeave={() =>
                        props.updateState({ hoveredColumn: null })
                      }
                      className="sortTable-Container justify-end"
                    >
                      <FormattedMessage
                        id="customer.listing.header.unpaidInvoices"
                        defaultMessage=""
                      />{" "}
                      {getSortIcon(6)}
                    </div>
                  </th>
                  {!_isColumnToHide("paidInvoicesCount") && (
                    <th className="amount-TextRight">
                      <div
                        onClick={() => handleSortColumn(7)}
                        onMouseEnter={() =>
                          props.updateState({ hoveredColumn: 7 })
                        }
                        onMouseLeave={() =>
                          props.updateState({ hoveredColumn: null })
                        }
                        className="sortTable-Container justify-end"
                      >
                        <FormattedMessage
                          id="customer.listing.header.paidInvoices"
                          defaultMessage=""
                        />{" "}
                        {getSortIcon(7)}
                      </div>
                    </th>
                  )}

                  <th>
                    <div
                      onClick={() => handleSortColumn(8)}
                      onMouseEnter={() =>
                        props.updateState({ hoveredColumn: 8 })
                      }
                      onMouseLeave={() =>
                        props.updateState({ hoveredColumn: null })
                      }
                      className="sortTable-Container justify-center"
                    >
                      Source {getSortIcon(8)}
                    </div>
                  </th>
                  <th>
                    <div
                      onClick={() => handleSortColumn(9)}
                      onMouseEnter={() =>
                        props.updateState({ hoveredColumn: 9 })
                      }
                      onMouseLeave={() =>
                        props.updateState({ hoveredColumn: null })
                      }
                      className="sortTable-Container justify-start"
                    >
                      Date Created {getSortIcon(9)}
                    </div>
                  </th>
                  <th className="text-center">Status {getSortIcon(10)}</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              <tbody>
                {customers && (customers[pageNumber] || []).length ? (
                  customers[pageNumber].map((rowData, i) => {
                    return (
                      <tr key={`cus` + i}>
                        <td>
                          <Checkbox
                            onClick={() => {
                              itemSelection(rowData);
                            }}
                            checked={
                              find(selectedItems, {
                                customerId: Number(rowData.customerId),
                              })
                                ? true
                                : false
                            }
                          />
                        </td>
                        {!_isColumnToHide("customerType") && (
                          <td>
                            {rowData.customerType === "individual" ? (
                              <i
                                className="fi fi-rr-user"
                                style={{ fontSize: "21px" }}
                              />
                            ) : (
                              <i
                                className="fi fi-rr-users-alt"
                                style={{ fontSize: "21px" }}
                              />
                            )}
                          </td>
                        )}
                        <td>
                          <div className="link">
                            <div
                              onClick={() => {
                                openCustomerProfile(rowData, props);
                              }}
                            >
                              {rowData.companyName || <EmptyBox boxSize={"small"} />}
                            </div>
                            <div className="light-txt">
                              ID - {rowData.customerId || <EmptyBox boxSize={"small"} />}
                            </div>
                          </div>
                        </td>
                        <td>{getPrimaryContact(rowData)}</td>
                        <td>
                          {(rowData.boLocationList || []).length ? (
                            <div>
                              {getAddressInfoFragment(
                                (rowData.boLocationList || []).find(
                                  (obj) => obj.isDefault
                                )
                              )}
                            </div>
                          ) : (
                            <EmptyBox />
                          )}
                        </td>
                        <td>
                          {
                            <div className="amount-TextRight">
                              {rowData.openedOrdersCount &&
                              rowData.openedOrdersCount === 1
                                ? "Order "
                                : rowData.openedOrdersCount === 0
                                ? ""
                                : "Orders "}
                              <span
                                onClick={() => {
                                  if (rowData.openedOrdersCount !== 0) {
                                    props.showModal({
                                      title: (
                                        <Fragment>
                                          <div>
                                            <div className="ant-modal-title">
                                              Open Orders
                                            </div>

                                            <div className="ant-modal-subtitle extra-sm">
                                              {rowData.companyName}
                                            </div>
                                          </div>
                                        </Fragment>
                                      ),
                                      modalBody: (
                                        <OpenOrder
                                          {...props}
                                          customerData={rowData}
                                        />
                                      ),
                                      width: 1000,
                                      hideFooter: true,
                                      wrapClassName: "tiles-modal",
                                    });
                                  }
                                }}
                                className={
                                  rowData.openedOrdersCount ? (
                                    "link-text"
                                  ) : (
                                    <div className="empty-data"></div>
                                  )
                                }
                              >
                                {rowData.openedOrdersCount}
                              </span>
                              {/* <p> {rowData.openedOrdersTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(rowData.openedOrdersTotalAmount || '') && rowData.openedOrdersTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                              <p>
                                {" "}
                                {rowData.openedOrdersTotalAmount ? (
                                  props.companyInfo.currencyIcon ? (
                                    <i
                                      className={props.companyInfo.currencyIcon}
                                    ></i>
                                  ) : (
                                    props.companyInfo.currencyCode + " "
                                  )
                                ) : (
                                  ""
                                )}
                                {(rowData.openedOrdersTotalAmount || "") &&
                                  fixedDecimalAndFormateNumber(
                                    rowData.openedOrdersTotalAmount
                                  )}
                              </p>
                            </div>
                          }
                        </td>
                        <td>
                          <div className="amount-TextRight">
                            {rowData.unpaidInvoicesCount &&
                            rowData.unpaidInvoicesCount === 1
                              ? "Invoice "
                              : rowData.unpaidInvoicesCount === 0
                              ? ""
                              : "Invoices "}
                            <span
                              onClick={() => {
                                if (rowData.unpaidInvoicesCount !== 0) {
                                  props.showModal({
                                    title: (
                                      <Fragment>
                                        <div>
                                          <div className="ant-modal-title">
                                            Unpaid Invoices
                                          </div>
                                          <div className="ant-modal-subtitle extra-sm">
                                            {rowData.companyName}
                                          </div>
                                        </div>
                                      </Fragment>
                                    ),
                                    modalBody: (
                                      <UnpaidInvoices
                                        customerData={rowData}
                                        {...props}
                                      />
                                    ),
                                    width: 1000,
                                    hideFooter: true,
                                    wrapClassName: "tiles-modal",
                                  });
                                }
                              }}
                              className={
                                rowData.unpaidInvoicesCount ? (
                                  "link-text"
                                ) : (
                                  <div className="empty-data"></div>
                                )
                              }
                            >
                              {rowData.unpaidInvoicesCount}
                            </span>
                            {/* <p> {rowData.unpaidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(rowData.unpaidInvoicesTotalAmount || '') && rowData.unpaidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                            <p>
                              {rowData.unpaidInvoicesTotalAmount ? (
                                props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )
                              ) : (
                                ""
                              )}
                              {(rowData.unpaidInvoicesTotalAmount || "") &&
                                fixedDecimalAndFormateNumber(
                                  rowData.unpaidInvoicesTotalAmount
                                )}
                            </p>
                          </div>
                        </td>
                        {!_isColumnToHide("paidInvoicesCount") && (
                          <td>
                            <div className="amount-TextRight">
                              {rowData.paidInvoicesCount &&
                              rowData.paidInvoicesCount === 1
                                ? "Invoice "
                                : rowData.paidInvoicesCount === 0
                                ? ""
                                : "Invoices "}
                              <span
                                onClick={() => {
                                  if (rowData.paidInvoicesCount !== 0) {
                                    props.showModal({
                                      title: (
                                        <Fragment>
                                          <div>
                                            <div className="ant-modal-title">
                                              Paid Invoices
                                            </div>
                                            <div className="ant-modal-subtitle extra-sm">
                                              {rowData.companyName}
                                            </div>
                                          </div>
                                        </Fragment>
                                      ),
                                      modalBody: (
                                        <PaidInvoices
                                          customerData={rowData}
                                          {...props}
                                        />
                                      ),
                                      width: 1000,
                                      hideFooter: true,
                                      wrapClassName: "tiles-modal paid-invoice",
                                    });
                                  }
                                }}
                                className={
                                  rowData.paidInvoicesCount ? (
                                    "link-text"
                                  ) : (
                                    <div className="empty-data"></div>
                                  )
                                }
                              >
                                {" "}
                                {rowData.paidInvoicesCount}
                              </span>
                              <p>
                                {" "}
                                {rowData.paidInvoicesTotalAmount ? (
                                  props.companyInfo.currencyIcon ? (
                                    <i
                                      className={props.companyInfo.currencyIcon}
                                    ></i>
                                  ) : (
                                    props.companyInfo.currencyCode + " "
                                  )
                                ) : (
                                  ""
                                )}
                                {(rowData.paidInvoicesTotalAmount || "") &&
                                  fixedDecimalAndFormateNumber(
                                    rowData.paidInvoicesTotalAmount
                                  )}
                              </p>
                            </div>
                          </td>
                        )}
                        <td>
                          <div className="flex justify-center">
                            {rowData?.customerSourceName || 
                              <EmptyBox />}
                          </div>
                        </td>
                        <td>
                          {rowData.dateCreated ? (
                            getMomentLoclDateForUIReadOnly({
                              date: rowData.dateCreated,
                            })
                          ) : (
                            <EmptyBox />
                          )}
                        </td>

                        {/* <td>{rowData.status || "-"}</td> */}
                        <td>
                          <div className="status-toggle">
                            <Switch
                              // checked={rowData.isActive === 1 || true}
                              checked={rowData.isActive === 1 ? true : false}
                              disabled={!customerPermissions?.update}
                              onClick={(checked, e) => {
                                props.updateStatus(
                                  {
                                    customerId: rowData.customerId,
                                    relationshipId: rowData.relationshipId,
                                    active: checked ? 1 : 0,
                                  },
                                  props
                                );
                                // props.createLeadSource(
                                //   { ...rowData, isActive: checked ? 1 : 0 },
                                //   props
                                // );
                              }}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <DropdownAction
                            overlay={actionMenu(rowData)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE_P} />
                          </DropdownAction>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr key="empty-data-box">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                      <EmptyTableData />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Skeleton>
        </div>

        <div className="footer">
          <Skeleton
            loading={props.headerLoading}
            paragraph={false}
            rows={1}
            active
          >
            <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={customerCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(1, pageSize, props);
                }}
                //     onShowSizeChange={(pageNumber, pageSize) => {

                //   handlePageSizeChange(props.pageNumber || 1, pageSize);

                // }}
              />
            </div>
          </Skeleton>
        </div>
      </div>
    </>
  );
};

const onSearchedInputChange = debounce((searchedText, props) => {
  handleSearchedText(searchedText, props);
}, 100);

const handleSearchedText = (searchedText, props) => {
  const payload = {
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: 1,
    pageSize: props.pageSize,
    searchedText: searchedText,
    companyInfo: props.companyInfo,
  };
  if (searchedText.length) {
    // if (searchedText.length >= 3 && !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())) {
    if (searchedText.length >= 3) {
      props.resetPaginatedData();
      props.getCustomerCount(payload);
      props.fetchCustomers(payload);
    }
  } else {
    props.resetPaginatedData();
    props.getCustomerCount(payload);
    props.fetchCustomers(payload);
  }
};

const getAddressText = (data) => {
  let address = find(data.boLocationList, {
    isDefault: 1,
    locationType: "BillingAddress",
  });
  if (address) {
    return (
      (address?.streetAddress1 || "") +
      " " +
      (address.streetAddress2 || "") +
      (address.cityName || "") +
      " " +
      (address.streetAddress2 || "") +
      " " +
      (address.zipCode || "")
    );
  } else {
    return "";
  }
};

const getContactText = (data) => {
  let contact =
    data.boContactList && find(data.boContactList, { isPrimaryContact: 1 });
  if (contact) {
    return (
      (contact.salutationName ? contact.salutationName + " " : "") +
      (contact.firstName || "") +
      " " +
      (contact.lastName || "") +
      " " +
      (contact.cellPhone ? "Ph: " + contact.cellPhone : "")
    );
  } else {
    return "";
  }
};

const exportExcelData = (selectedustomerList, props) => {
  const dataList = (selectedustomerList || []).map((item) => {
    return {
      "Customer Name": item.companyName || "",
      Address: getAddressText(item),
      Contact: getContactText(item),
      "Due Amount": fixedDecimalAndFormateNumber(
        Number(item.totalAmountDue || 0)
      ),
      "Open Order": fixedDecimalAndFormateNumber(
        Number(item.openedOrdersTotalAmount || 0)
      ),
      "Unpaid Invoice": fixedDecimalAndFormateNumber(
        Number(item.unpaidInvoicesTotalAmount || 0)
      ),
      "Paid Invoice": fixedDecimalAndFormateNumber(
        Number(item.paidInvoicesTotalAmount || 0)
      ),
      "Payment Term": item.paymentTermName || "",
    };
  });
  exportExcel(dataList, "Customers");
};

export default injectIntl(CustomersListingComp);
