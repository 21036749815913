import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { generateCommunicationTemplate } from './action';
import CommunicationTemplate from '../../../../components/drawer/communication/communicationTemplate';
import { fetchSenderEmailsFromRID } from '../../../modal/modalBody/settings/EmailTemplate/TxnEmailTemplate/action';
import { pushModalToStack ,popModalFromStack,showModal,hideModal } from '../../../../actions/commonActions';
import Item from 'antd/lib/list/Item';
class communicationTemplateContainer extends Component {


  constructor(props) {
    super(props);
    
    this.state = {
        moduleTemplateName:[
          {
          id:1,
          moduleName:"Purchase",
          transactionList:[{
            id:11,
            transactionName:'Purchase Request',
            eventName:["Create" ,"Edit"],
          },
          {
            id:12,
            transactionName:'Purchase Order',
            eventName:["Create" ,"Edit" ,"Cancel"],
          },
          {
            id:13,
            transactionName:'Purchase Invoice',
            eventName:["Create" ,"Edit" ,"Cancel"],
          },
          {
            id:14,
            transactionName:'Supplier Payment',
            eventName:["Create" ,"Edit"],
          },
          {
            id:15,
            transactionName:'Debit Memo',
            eventName:["Create" ,"Edit"],
          },
          {
            id:16,
            transactionName:'RFQ',
            eventName:["Create" ,"Edit"],
          },
          {
            id:17,
            transactionName:'Goods Receipt  Note(GRN)',
            eventName:["Create" ,"Edit"],
          }],
          
        },
        {
          id:2,
          moduleName:"Sales",
          transactionList:[{
            id:21,
            transactionName:'Sales Inquiry',
            eventName:["Create" ,"Edit"],
          },
          {
            id:22,
            transactionName:'Sales Quote',
            eventName:["Create" ,"Edit"],
          },
          {
            id:23,
            transactionName:'Sales Order',
            eventName:["Create","Edit","Cancel"],
          },
          {
            id:24,
            transactionName:'Ecom Order',
            eventName:["Create" ,"Edit" ,"Cancel" ,"Return","Replace"],
          },
          {
            id:25,
            transactionName:'Sales Invoice',
            eventName:["Create" ,"Edit","Cancel"],
          },
          {
            id:26,
            transactionName:'Sales Package',
            eventName:["Create" ,"Edit"],
          },
          {
            id:27,
            transactionName:'Sales Shipment',
            eventName:["Create" ,"Edit"],
          },
          {
            id:28,
            transactionName:'Customer Payment',
            eventName:["Create" ,"Edit"],
          },
          {
            id:29,
            transactionName:'Credit Memo',
            eventName:["Create" ,"Edit"],
        },
        {
          id:30,
          transactionName:'Sales Support',
          eventName:["Create" ,"Edit" ,"Status-Updated","Status-Closed"],
      }],
          
        },
        {
          id:3,
          moduleName:"Education",
          transactionList:[
            {
            id:31,
            transactionName:'School Student',
            eventName:["Create" ,"Registered","Enrollment","Assign Class" ,"Roll Number" ],
          },
          {
            id:32,
            transactionName:'School Teacher',
            eventName:["Create" ],
          },
          {
            id:33,
            transactionName:'School Invoice',
            eventName:["Create" ],
          },
          {
            id:34,
            transactionName:'School Assignments',
            eventName:["Create" ],
          },
          {
            id:35,
            transactionName:'School Payment',
            eventName:["Create"],
          },
        ]
          
        },

        {
          id:4,
          moduleName:"HRMS",
          transactionList:[{
            id:41,
            transactionName:'HRMS Employee',
            eventName:["Create" ],
          },
          {
            id:42,
            transactionName:'HRMS Employee Attendance',
            eventName:["Absent","Leave"],
          },
          {
            id:43,
            transactionName:'HRMS Leave',
            eventName:["Create" ],
          },
          {
            id:44,
            transactionName:'HRMS Payroll',
            eventName:["Create" ],
          },
          {
            id:45,
            transactionName:'HRMS Employee Services',
            eventName:["Create" ],
          },
          {
            id:46,
            transactionName:'HRMS Employee Training',
            eventName:["Create" ],
          },
          {
            id:47,
            transactionName:'HRMS Asset',
            eventName:[],
          }],
          
        },
        {
          id:5,
          moduleName:"CRM",
          transactionList:[{
            id:51,
            transactionName:'CRM Lead Stage',
            eventName:["Move" ],
          },
          {
            id:52,
            transactionName:'CRM Customer',
            eventName:["Create"],
          },
          
          ],
          
        },
      ],
      messageProvider:["Twilio","Brevo","Amazon SES","Gupshup"],
      
      isLoading: true,
      selectedModuleName:{},
      selectedTransactionName:{},
      selectedEventName:'',
      emailAutoTrigger:false,
      whatsappAutoTrigger:false,
      smsAutoTrigger:false,
      notificationAutoTrigger:false,

      emailContent:{
        emailProvider:'Amazon SES',
        emailId:'',
        emailSubject:'',
        emailMessage: '<p>Dear&nbsp;~customerName~<br></p><p>I am writing to inform you that your sales order ~soNumber~ has been successfully processed.<br><br>Attached to this email, you will find the sales order for your records. If you have any questions or need further information, please do not hesitate to contact me directly.<br><br>We value your continued business and look forward to fulfilling your order promptly.<br><br>~orgEmail~<br>PS: This is a system-generated email. Please refrain from replying to it</p><p><br></p',
       

      },
      smsContent:{
         smsProvider:'Amazon SES',
         smsId:'',
         smsMessage:'',

      },

      whatsappContent:{
         whatsaapProvider:'Amazon SES',
         whatsappId:'',
         whatsappMessage:'',
      },

      notification:{
        title:"",
        description:""
      }




    }
  }

  componentDidMount(nextProps) {
    
      const companyInfo = this.props.companyInfo || {};
      const payload = { relationshipId: companyInfo.relationshipId, docName: this.props.docName};
      this.props.fetchSenderEmailsFromRID(payload)
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.senderEmails.length){
      this.setState({senderEmails: nextProps.senderEmails});
  }
  if(this.state.isLoading && nextProps.data && Object.keys(nextProps.data||{})?.length){
   const data = nextProps.data;
    const val = (this.state.moduleTemplateName||[]).find(item => item?.moduleName === data.moduleName)
    const transactionValue = (val?.transactionList||[]).find(item => item?.transactionName ===data.transactionName)
    const eventType = (transactionValue?.eventName||[]).find(item=> item === data.eventName)
    this.setState({
      selectedModuleName: val,
      selectedTransactionName: transactionValue,
      selectedEventName: eventType,
      emailAutoTrigger: data.isEmailAutoTrigger === 1 ,
      whatsappAutoTrigger: data.isWhatsAppAutoTrigger === 1 ,
      smsAutoTrigger: data.isSMSAutoTrigger === 1 ,
      emailContent: JSON.parse(data.emailSettings||'{}'),
      whatsappContent: JSON.parse(data.whatsAppSettings||'{}'),
      smsContent: JSON.parse(data.smsSettings||'{}'),
      isLoading: false,

    })
  }
  }


  render() {
    return <div>
      <CommunicationTemplate 
    //   initCalls={() => {
    //     this.initCalls();
    //   }} 
      {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      userStatus: state.common.userStatus,
      senderEmails: state.settings.senderEmails,
      permissions: state.auth.permissions,

    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
   
    pushModalToStack,
    popModalFromStack,
    showModal,
    hideModal,
    fetchSenderEmailsFromRID,
    generateCommunicationTemplate,
    // addRelationshipTaxIdentification,
    // fetchRelationshipTaxIdentifications,
    // generateEInvoiceDocument,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(communicationTemplateContainer);