const configuration = {
     API_BASE_URL: 'https://service.alpidedev.com',
    API_VERSION: '',
    LOGISTICS_API_VERSION: '/api/v1',
    AWS_BASE_URL:'https://api-in.alpidepay.com',
    PDF_API_BASE_URL: 'https://doc.alpide.com/api/',
    INVENTORY_BASE_URL: 'https://api-in.alpidepay.com',
    SALES_BASE_URL: 'https://api-in.alpidepay.com',
    USERS_BASE_URL:'https://api-in.alpidepay.com',
    ACCOUNTING_BASE_URL:'https://api-in.alpidepay.com',
    PURCHASE_BASE_URL:"https://api-in.alpidepay.com",
    CRM_BASE_URL:"https://api-in.alpidepay.com",
    LOOKUP_BASE_URL:"https://api-in.alpidepay.com",
    EDUCATION_BASE_URL: "https://api-in.alpidepay.com",
    // EDUCATION_BASE_URL: "http://localhost:8060",
    LOGISTICS_BASE_URL: "https://api-in.alpidepay.com",
    HRMS_BASE_URL: "https://api-in.alpidepay.com",
    ECOM_BASE_URL: "https://api-in.alpidepay.com",
    COUPON_BASE_URL: "https://api-in.alpidepay.com",
    KIOSK_BASE_URL: 'https://us-donate.alpide.com',
    COMMUNICATION_BASE_URL: "https://api-in.alpidepay.com",
    PDF_API_VERSION: 'v1',
    REDIRECT_URI_FOR_AMAZON: 'https://uat.alpide.com/app/admin/sales-channels',
    INVOICE_SERVICE_BASE_URL: 'https://service.alpidedev.com',
    PAYMENT_GATEWAY_BASE_URL: 'https://api-in.alpidepay.com',
    PAYMENT_BASE_URL: 'https://api-in.alpidepay.com',
    rootContext: 'alpide',
    rootContextTwilio: 'alpide-tcom',
    rootContextInvoiceService: 'alpide-invoice',
    rootContextPaymentGateway: 'alpide-paypal',
    rootContextPlaid: 'alpide-plaid',
    rootContextMWS: 'alpide-mws',
    rootContextSMS: 'alpide-school',
    rootContextBrevo:'brevo-sms-app',
    rootContextCC:'alpide-cc',
    rootContextSupport:'support',
    rootContextRazorpay:'alpide-razorpay',
    rootContextStripe: 'alpide-stripe',
    rootContextCMS: 'alpide-cms',
    rootContextInventory: 'inventory',
    rootContextPurchase: 'purchase',
    rootContextSales:'sales',
    rootContextUsers:'users',
    rootContextAccounting:'accounting',
    rootContextLookup:'lookup',
    rootContextCRM:'crm',
    rootContextEducation: 'education',
    rootContextLogistics: 'logistics',
    rootContextECOM: 'commerce', 
    rootContextCoupon: 'coupon',
    rootContextCommunication:'communication',
    rootContextProject:'project',
    rootContextHRMS:'hrms',
    rootContextAws:'aws',
    rootContextPayment: 'payment',
    PROJECT_BASE_URL: 'https://api-in.alpidepay.com',
    API_VERSION_CMS:'api/v1',
    CMS_BASE_URL: 'https://api-in.alpidepay.com',
    MWS_BASE_URL: 'https://service.alpidedev.com',
    PLAID_BASE_URL: 'https://service.alpidedev.com',
    UI_BASE_URL: 'https://us-app.alpide.com/app/',
    COUPON_CONTEXT: 'alpide-comm',
    CALLBACK_URI_AUTH_NET: 'https://us-app.alpide.com/app/admin/payment-integration',
    PAYMENT_SERVER_BASE_URL: 'https://uat-app.alpide.com',

    CALLBACK_URI_MARKTPLACE: 'https://us-app.alpide.com/app/admin/sales-channels',
    CALLBACK_URI_MARKTPLACE_AMAZON: 'https://us-app.alpide.com/app/admin/sales-channels-amazon',
    CALLBACK_URI_MARKTPLACE_EBAY: 'https://us-app.alpide.com/app/admin/sales-channels-ebay',
    PAYMENT_FORM_ACTION: 'https://accept.authorize.net/payment/payment',
    CMS_BASE_URL: 'https://cms-service.alpide.com',
    CB_PUBLIC_KEY: 'test_9KdLB3laV1fzmNupMYMQyQRwUioTPYVL',
    CC_TOKENIZER_URL: 'https://fts.cardconnect.com/itoke/ajax-tokenizer.html',
    SUPPORT_BASE_URL: 'https://api-in.alpidepay.com',
    SSO_URL: 'https://accounts.alpide.com',
    WP_DOMAIN: 'https://login.alpide.com',
    ROOT_DOMAIN: 'alpide.com',
    ENCRYPT_SECRET: 'Unl0ck!!!',
    STRIPE_PUBLISHABLE_KEY: 'pk_live_51PsiasP9XnPbaJwwJIsqxadMslrXVm2MOVY1yd3SzQTb1HnY2OQYQKCCvahgFRC1vOm6XoZKCVYKZr07G2mGkvoO00j6qayfDe',
    BUCKET_NAME: {
        BO_RELATIONSHIP: 'alpide-prd-us1-relationship',
        BO_SALES_INQUIRY: "alpide-prd-us1-inquiry",
        BO_SALES_QUOTE: "alpide-prd-us1-sales-quote",
        BO_SALES_ORDER: 'alpide-prd-us1-sales-order',
        BO_CUSTOMER_INVOICE: "alpide-prd-us1-sales-invoice",
        BO_CREDIT_MEMO: "alpide-prd-us1-credit-memo",
        BO_PURCHASE_REQUEST: "alpide-prd-us1-purchase-request",
        BO_RFQ: "alpide-prd-us1-supplier-rfq",
        BO_PURCHASE_ORDER: "alpide-prd-us1-purchase-order",
        BO_INBOUND_DELIVERY: "alpide-prd-us1-inbound-delivery",
        BO_SUPPLIER_INVOICE: "alpide-prd-us1-purchase-invoice",
        BO_DEBIT_MEMO: "alpide-prd-us1-debit-memo",
        BO_INVENTORY: "alpide-prd-us1-inventory"
    },
    ERP_THEMES:{
        64: "Vogue",
        37: "Dapper",
        41: "Matrikala"
    },
    RAZORPAY_KEYS: {
        56: "rzp_live_ib6x5GoFPaYWei",
    }

};

export default configuration;
