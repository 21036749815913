import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Drawer, Row,Skeleton } from "antd";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";

const DepartmentDrawer = (props) => {
  const closeDrawer = () => {
    props.updateState({
      ...props,
      departmentDrawer: false,
    });
  };
  const handleSubmit = () => {
    props.updateDrawerState({ submitted: true });
    if(!props.departmentName ||  !props.departmentCode){
      return;
    }
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      departmentId: props.departmentId,
      departmentName: props.departmentName,
      departmentCode: props.departmentCode,
      createdByUserId: props.createdByUserId,
      updatedByUserId: props.updatedByUserId,
      status: 1,
      version: 0
    }
    
    const modalData = {     
      modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage=""/>,
      handleSubmit: () => {
        props.createDepartment(payload,props);
       closeDrawer();  },
    };
    props.pushModalToStack(modalData);
  }

  const getCreateConfirmationModalBody = (payload) => {
    return (
      <>
        <p>
          <FormattedMessage
            id="drawer.inventory.confirmation.text"
            defaultMessage=""
          />
        </p>
      </>
    );
  };
  return (
    <Drawer
      title="Create New"
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.departmentDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
         
           <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              handleSubmit()
            }
            }
            key={1}
          />
        </>
      }

    >
      <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="i-label">Department Name
              <span className="required">*</span>

            </div>
            <TextBox
              placeholder="Department Name"
              type="text"
              maxLength={50}
              value={props.departmentName}
              onChange={(e) => {
                props.updateDrawerState({ departmentName: e.target.value });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.departmentName }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Department Code
              <span className="required">*</span>

            </div>
            <TextBox
              placeholder="Department Code"
              type="text"
              maxLength={30}
              value={props.departmentCode}
              onChange={(e) => {
                props.updateDrawerState({ departmentCode: e.target.value });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.departmentCode }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>

        </Row>
      </Skeleton>
    </Drawer>
  );
};

export default DepartmentDrawer;
