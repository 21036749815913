import { SETTING_ACTION_LIST } from '../static/constants';
const initialState = {
  templateData: {},
  remarksData: {},
  allCompanyUsers: [],
  userForAlpideAccess: [],
  documentNumbers: {},
  docApprovalSettings: [],
  qualityChecklistSetting: {},
  registrationFormSetting: {},
  registrationFormList: [],
  relationshipData: {},
  senderEmails: [],
  clientOnboardingList: [],
  documentPrefix:{},
  registrationFormData:{},
  salesDocumentNumbers: {},
  salesDocumentPrefix:{},
  purchaseDocumentNumbers: {},
  purchaseDocumentPrefix:{},
}

const SettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTING_ACTION_LIST.EMAIL_TEMPLATE_DATA:
      return { ...state, templateData: action.data };
    case SETTING_ACTION_LIST.ALL_COMPANY_USERS:
      return { ...state, allCompanyUsers: action.data };
    case SETTING_ACTION_LIST.DELETE_COMPANY_USERS:
      return { ...state, allCompanyUsers: state.allCompanyUsers.filter(x => x.userId !== action.data.id) }
    case SETTING_ACTION_LIST.RESET_EMAIL_TEMPLATE_DATA:
      return { ...state, templateData: {} };
    case SETTING_ACTION_LIST.REMARKS_TEMPLATE_DATA:
      return { ...state, remarksData: { ...action.data, isFetched: true } };
    case SETTING_ACTION_LIST.RESET_REMARKS_TEMPLATE_DATA:
      return { ...state, remarksData: {} };
    case SETTING_ACTION_LIST.USER_FOR_ALPIDE_ACCESS:
      return { ...state, userForAlpideAccess: action.data };
    // case SETTING_ACTION_LIST.DOCUMNET_NUMBERS: {
    //   state.documentNumbers[action.data.txType] = action.data.data;
    //   state.documentNumbers['isLast'] = action.data.isLast;
    //   return { ...state, documentNumbers: { ...state.documentNumbers } };
    // }
    case SETTING_ACTION_LIST.DOCUMNET_NUMBERS: {
      return { ...state, documentNumbers: action.data };
    }
    case SETTING_ACTION_LIST.RESET_DOCUMENT_NUMBERS:
      return { ...state, documentNumbers: {} };
    case SETTING_ACTION_LIST.APPROVAL_SETTINGS:
      return { ...state, docApprovalSettings: action.data };
    case SETTING_ACTION_LIST.QUALITY_CHECK_LIST_SETTING:
      return { ...state, qualityChecklistSetting: action.data };
    case SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING:
      return { ...state, registrationFormSetting: action.data };
    case SETTING_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING:
      return { ...state, registrationFormSetting: initialState.registrationFormSetting };
    case SETTING_ACTION_LIST.REGISTRATION_FORM_LIST:
      return { ...state, registrationFormList: action.data };
    case SETTING_ACTION_LIST.RELATIONSHIP_DATA:
      return { ...state, relationshipData: action.data };
    case SETTING_ACTION_LIST.SENDER_EMAILS:
      return { ...state, senderEmails: action.data };
    case SETTING_ACTION_LIST.CLIENT_ONBOARDING_DATA:
      return { ...state, clientOnboardingList: action.data };
    case SETTING_ACTION_LIST.DOCUMNET_PREFIXS: {
      return { ...state, documentPrefix: action.data };
    }
    case SETTING_ACTION_LIST.REGISTRATION_FORM_DATA: {
      return { ...state, registrationFormData: action.data };
    }
    case SETTING_ACTION_LIST.SALES_DOCUMENT_NUMBERS: {
      return { ...state, salesDocumentNumbers: action.data };
    }
    case SETTING_ACTION_LIST.SALES_DOCUMENT_PREFIXS: {
      return { ...state, salesDocumentPrefix: action.data };
    }
    case SETTING_ACTION_LIST.PURCHASE_DOCUMENT_NUMBERS: {
      return { ...state, purchaseDocumentNumbers: action.data };
    }
    case SETTING_ACTION_LIST.PURCHASE_DOCUMENT_PREFIXS: {
      return { ...state, purchaseDocumentPrefix: action.data };
    }
    default:
      return state;
  }
};

export default SettingReducer;