import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { injectIntl,FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import PurchaseInvoiceDetail from '../../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail';
import { CONSTANTS } from '../../../../../static/constants';
import { getMomentDateForUIReadOnly } from '../../../../../utils';

const AgedDebtorsComponent = (props) => {

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    // const getRowHeight = () => {
    //     return 35;
    // }

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    const openPurchaseInvoiceDetails = (data) => {
        const invoicePayload = {
            supplierId: data.supplierId,
            invoiceMasterId: data.invoiceMasterId
        }
        props.pushModalToStack({
            modalBody: <PurchaseInvoiceDetail {...props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.txDetails.payment.supplierName' defaultMessage='' />;
            },
            field: "supplierName",
            //cellRendererFramework: (link) => <div className="cursor-pointer">{link.value}</div>,
            rowDrag: true,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.txDetails.payment.invoiceNumber' defaultMessage='' />;
            },
            field: "invoiceNumber",
            cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
                openPurchaseInvoiceDetails(link.data)
            }}>{link.value}</div>,
            resizable: true,
        },
        {

            headerComponentFramework: () => {
                return <FormattedMessage id='modal.customer.applySalesCredit.invoiceDate' defaultMessage='' />;
            },
            field: "invoiceDate",
            cellRendererFramework: (link) => {
                return <div>
                    <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
                        {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                    </Tooltip>
                </div>
            },
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.customer.applySalesCredit.invoiceAmount' defaultMessage='' />;
            },
            field: "invoiceAmount",
            valueFormatter: (data) => {
                data.value = data.value ? (Number(data.value)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                return data.value;
            },
            cellRendererFramework: (params) => {
                return <div> <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} {params.value}</span> </div>
            },
            resizable: true,
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {

            headerComponentFramework: () => {
                return <FormattedMessage id='common.status' defaultMessage='' />;
            },
            field: "status",
            cellRendererFramework: (params) => {
                return <div className={["status-code", (params.value || '').toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /> </div>
            },
            resizable: true,
        },
    ];

    return (
        <div className='agGridWrapper'>
            <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
               <AgGridReact
                    columnDefs={columnDefs}
                    rowData={props.detailsList || []}
                    rowDragManaged={true}
                    domLayout={"autoHeight"}
                    animateRows={true}
                    onColumnResized ={(params)=>{
                        params.api.resetRowHeights();
                    }}
                    defaultColDef={{
                        flex:1,
                        autoHeight: true,
                        wrapText: true,
                    }}
                    //getRowHeight={getRowHeight}
                    onGridSizeChanged={onFirstDataRendered}
                >
                </AgGridReact>
            </div>
        </div>
    );
};

export default injectIntl(AgedDebtorsComponent);
