import axios from 'axios';
import config from '../../../config/environmentConfig';
import { MARKETPLACE_SHIPPING_ACTION_LIST, TAXES_SETTINGS_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const getMarketplaceShippingCountries = (payload) => {
    return dispatch => {
        return axios
            .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceShippingCountry/getAllMarketplaceShippingCountries?relationshipId=${payload.relationshipId}`)
            .then(res => {
                if(res.data.data){
                    dispatch({ type: MARKETPLACE_SHIPPING_ACTION_LIST.SHIPPING_COUNTRIES_LIST, data: res.data });

                }
                else{
          
                    showToasterMessage({
                      description: lang[(res || {}).message] || "Some Error Occured",
                      messageType: "error",
                    })
                   }
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch(err => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}

export const saveUpdateShippingMarketplaceCountry = (payload, props) => {

    return dispatch => {
        // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceShippingCountry/saveOrUpdate`, payload)
            .then(res => {

                props.getMarketplaceShippingCountries(payload);
            })
            .catch((err = {}) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                // if (err.__isRetryRequest) {
                //   err.actionToCall = SETTING_ACTION_LIST.DELETE_COMPANY_USERS;
                // } else {
                //   showToasterMessage({
                //     description: lang[((err.response || {}).data || "")] || 'Some error occurred'
                //   })
                // }
            })
    }
}

export const deleteShippingMarketplaceCountry = (payload, props) => {

    return dispatch => {
        // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceShippingCountry/delete`, payload)
            .then(res => {
                props.getMarketplaceShippingCountries(props);
            })
            .catch((err = {}) => {
            })
    }
}

export const getAllCountries = (payload) => {
    return dispatch => {
        return axios
            .get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/country/getCountries`)
            .then(res => {
                dispatch({ type: TAXES_SETTINGS_ACTION_LIST.COUNTRIES_LIST, data: res.data });
            })
            .catch(err => {
                showToasterMessage({
                    description: lang[(err || {}).message] || "Some Error Occured",
                    messageType: "error",
                  })
            })
    }
}

export const getAllShippingChoices = (payload) => {
    return dispatch => {
        return axios
            .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceShippingChoice/getAll`)
            .then(res => {

                dispatch({ type: MARKETPLACE_SHIPPING_ACTION_LIST.SHIPPING_CHOICES, data: res.data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch(err => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}
