import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShippingComp from "../../../components/integrations/ShippingTab";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions'
import { saveLogisticsConfiguration, disconnectLogisticsConfiguration, fetchLogisticsConfiguration } from './action';


class Shipping extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount(){
        this.props.fetchLogisticsConfiguration({relationshipId: this.props.companyInfo?.relationshipId});
    }

    render() {
        return <ShippingComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}

const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.common.companyInfo,
        permissions: state.auth.permissions,
        integratedLogistics: state.integration.integratedLogistics,
        saveLoading: state.common.saveLoading,
        listLoading: state.common.listLoading
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    saveLogisticsConfiguration,
    disconnectLogisticsConfiguration, 
    fetchLogisticsConfiguration
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Shipping);
