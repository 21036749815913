import React, { useState } from 'react';
import { Skeleton, Row, Col, Form } from 'antd';
import { TextBox } from '../../../../general/TextBox';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CustomButton } from '../../../../general/CustomButton';
import { ErrorMsg } from '../../../../general/ErrorMessage';

function BankAccount(props) {
  const [value, setValue] = useState({ ...props.bankDetails });
  const [submittedOnce, setSubmitted] = useState(false);

  return <>
    <div className="header-action-container">

      <Form>
        <div
          className="information"
          style={{ height: "400px" }}
        >
          <div className="pl30">

            <Row>
              <Col span={6}>

                <FormattedMessage
                  id="modal.settings.companyInfo.bankInfo.bankName"
                  defaultMessage=""
                />
                <span className='required'>*</span>
              </Col>
              <Col span={16}>
                <TextBox
                  type="text"
                  value={value.name}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      name: e.target.value,
                    });
                  }}
                  placeholder={props.intl.formatMessage({
                    id: "modal.settings.companyInfo.bankInfo.bankName",
                    defaultMessage: "",
                  })}
                />
                <ErrorMsg
                  validator={() => {
                    return !submittedOnce || !!value.name;
                  }}
                  message={
                    <FormattedMessage
                      id="requiredField.missing.msg"
                      defaultMessage=""
                    />
                  }
                />
              </Col>
            </Row>


            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.settings.companyInfo.bankInfo.payeeName"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>

                <TextBox
                  type="text"
                  value={value.accountHolderName}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      accountHolderName: e.target.value,
                    });
                  }}
                  placeholder={props.intl.formatMessage({
                    id: "modal.settings.companyInfo.bankInfo.payeeName",
                    defaultMessage: "",
                  })}
                />
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.settings.companyInfo.bankInfo.accountNumber"
                  defaultMessage=""
                />
                <span className='required'>*</span>
              </Col>
              <Col span={16}>
                <TextBox
                  type="text"
                  value={value.accountNumber}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      accountNumber: e.target.value,
                    });
                  }}
                  placeholder={props.intl.formatMessage({
                    id: "modal.settings.companyInfo.bankInfo.accountNumber",
                    defaultMessage: "",
                  })}
                />
                <ErrorMsg
                  validator={() => {
                    return !submittedOnce || !!value.accountNumber;
                  }}
                  message={
                    <FormattedMessage
                      id="requiredField.missing.msg"
                      defaultMessage=""
                    />
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.settings.companyInfo.bankInfo.ifsc"
                  defaultMessage=""
                />
                <span className='required'>*</span>
              </Col>
              <Col span={16}>
                <TextBox
                  type="text"
                  value={value.ifscCode}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      ifscCode: e.target.value,
                    });
                  }}
                  placeholder={props.intl.formatMessage({
                    id: "modal.settings.companyInfo.bankInfo.ifsc",
                    defaultMessage: "",
                  })}
                />
                <ErrorMsg
                  validator={() => {
                    return !submittedOnce || !!value.ifscCode;
                  }}
                  message={
                    <FormattedMessage
                      id="requiredField.missing.msg"
                      defaultMessage=""
                    />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.settings.companyInfo.bankInfo.routingNumber"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
                <TextBox
                  type="text"
                  value={value.routingNumber}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      routingNumber: e.target.value,
                    });
                  }}
                  placeholder={props.intl.formatMessage({
                    id: "modal.settings.companyInfo.bankInfo.routingNumber",
                    defaultMessage: "",
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                Branch Name
              </Col>
              <Col span={16}>
                <TextBox
                  type="text"
                  value={value.branchName}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      branchName: e.target.value,
                    });
                  }}
                  placeholder={"Branch Name"}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Form>
      <div className="form-footer">
        <CustomButton
          intlId="confirmation.cancel"
          defaultMessage=""
          type="default"
          key={0}
          style={{ marginRight: 8 }}
          onClick={() => {
            props.pushModalToStack({
              modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage="" />,
              footer: { submit: { intlId: "button.close.label" } },
              handleSubmit: () => {
                props.hideModal();
              }
            })
          }}
        />
        <CustomButton
          intlId="confirmation.submit"
          defaultMessage=""
          htmlType="submit"
          key={1}
          onClick={() => {
            setSubmitted(true);

            if (
              !value.name ||
              !value.accountNumber ||
              !value.ifscCode
            ) {
              return;
            }
            const payload = {
              relationshipId: (props.companyInfo || {}).relationshipId,
              name: value.name,
              accountNumber: value.accountNumber,
              accountHolderName: value.accountHolderName,
              ifscCode: value.ifscCode,
              routingNumber: value.routingNumber,
              isToEdit: value.bankDetailId ? true : false,
              bankDetailId: value.bankDetailId || 0,
              branchName:value.branchName || "",
              version: value.version || 0
            }
            const modalData = {
              modalBody: (
                <FormattedMessage
                  id="modal.commmon.submit"
                  defaultMessage=""
                />
              ),
              handleSubmit: () => {
                props.createBankDetail(payload, props);
                props.hideModal();
              },
            };
            props.pushModalToStack(modalData);
          }}
        />
      </div>

    </div>
  </>
}
export default injectIntl(BankAccount);