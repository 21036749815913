import React, { Fragment, useState } from "react";
import { Row, Col, Drawer, Upload, Button, Card } from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { FormattedMessage, } from "react-intl";
import { TextBox } from "../../../general/TextBox";
import storeDef from "../../../../assets/images/store-def.jpg";
import {
  getAWSFileURL,
  showToasterMessage,
} from "../../../../utils";
import { CustomButton } from "../../../general/CustomButton";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown";
import TextArea from "antd/lib/input/TextArea";


const TestimonialDrawer = (props) => {
  const { customers, pageNumber, customer, intl } = props
  const closeDrawer = () => {
    props.updateState({
      testimonialCreateDrawerVisible: false
    })
  }
  const logo = getAWSFileURL(props.testimonialData?.awsKeyName, props.companyInfo.bucketName, props.testimonialData?.folderName)
  const [fileList, setFileList] = useState([]);
  const handleRemoveLogo = () => {
    props.updateDrawerState({
      testimonialData: {
        ...props.testimonialData,
        awsKeyName: null,
        folderName: null,
        image: null,
        fileName: null,
        fileSize: null
      }
    })
  };

  const handleRemove = () => {
    setFileList([]);
  };
  const handleSubmit = () => {
    props.updateDrawerState({ submitted: true });
    if (!props.customerId || !props.title || !props.message || !props.description) {
      return;
    }
    const payload = {
      ...props.testimonialData,
      relationshipId: props.companyInfo.relationshipId,
      description: props.description,
      title: props.title,
      message: props.message,
      testimonialsId: props.testimonialsId,
      customerId: props.customerId,
      customerName:props.customerName
    };

    const modalData = {
      modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />,
      handleSubmit: () => {
        props.createTestimonial(payload, fileList[0], props);
        closeDrawer();
      },
    };
    props.pushModalToStack(modalData);
  }

  return (
    <Fragment>

      <Drawer
        title={

          <FormattedMessage id='Create Testimonial' defaultMessage='' />

        }
        width={720}
        destroyOnClose={true}
        onClose={closeDrawer}
        keyboard={false}
        maskClosable={false}
        className="custom-drawer"
        afterVisibleChange={() => {
          // props.initCalls();
        }}
        visible={props.testimonialCreateDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              style={{ marginRight: 8 }}
              onClick={closeDrawer}
            />

            <CustomButton
              intlId="confirmation.submit"
              defaultMessage=""
              htmlType="submit"
              onClick={() => {
                handleSubmit()
              }
              }
              key={1}
            />
          </>
        }
      >

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="i-label">
              Name
              <span className="required">*</span>
            </div>

            <Dropdown
              className={
                props.submittedOnce && !props.customerName
                  ? "input-text-error"
                  : ""
              }
              items={customers[1] || []}
              valueKeyName="companyName"
              optionKeyName="customerId"
              value={props.customerName}
              placeholder={intl.formatMessage({
                id: "modal.customer.payInvoice.selectCustomer.placeholder",
                defaultMessage: "",
              })}
              onSelect={(optionValue) => {
                let customer = (customers[1] || []).find(
                  (customer) => customer.customerId === optionValue
                );
                props.updateDrawerState({
                  customerName: customer.companyName,
                  customerId: optionValue,
                });


              }}
              allowClear={true}
              onClear={() => {
                props.updateDrawerState({
                  customerName: undefined,
                  customerId: undefined,
                });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.customerId }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">
              Position
              <span className="required">*</span>
            </div>
            <TextBox

              placeholder="Enter Position"
              value={props.description}
              onChange={(e) => {
                props.updateDrawerState({ description: e.target.value });
              }}

            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.description }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>
          <Col span={24}>
            <div className="i-label">
              Title
              <span className="required">*</span>
            </div>
            <TextArea
            className="custom-height"
              placeholder="Enter Title"
              value={props.title}
              onChange={(e) => {
                props.updateDrawerState({ title: e.target.value });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.title }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />

          </Col>
          <Col span={24}>
            <div className="i-label">
              Testimonial
              <span className="required">*</span>
            </div>
            <TextArea
            className="custom-height"
              placeholder="Enter Testimonial"
              value={props.message}
              onChange={(e) => {
                props.updateDrawerState({ message: e.target.value });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.description }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>


        </Row>
        <Row gutter={[16, 16]}>

          <Col span={8}>
            <label>Add Company Logo</label>

            {/* <div className="upload-profile-img">
              {fileList.length > 0 ? (
                <Card className="card-img-prev">
                  <img src={URL.createObjectURL(fileList[0])} alt="Preview" />
                  <Button className="close-btn-pre" onClick={handleRemove}>
                    <CloseOutlined />
                  </Button>
                </Card>
              ) : (
                <Card
                  className="card-img-prev"
                  cover={<img src={logo ? logo : storeDef} alt="Placeholder" />}
                />
              )}
              <Upload
                fileList={fileList}
                name="file"
                beforeUpload={(file) => {
                  setFileList([file]);
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>
            </div> */}
            <div className="upload-profile-img">
              {fileList.length > 0 ? (
                <Card className="card-img-prev">
                  <img src={URL.createObjectURL(fileList[0])} alt="Preview" />
                  <Button className="close-btn-pre" onClick={handleRemove}>
                    <CloseOutlined />
                  </Button>
                </Card>
              ) : logo ? (
                <Card className="card-img-prev">
                  <img src={logo} alt="Logo" />
                  <Button className="close-btn-pre" onClick={handleRemoveLogo}>
                    <CloseOutlined />
                  </Button>
                </Card>
              ) : (
                <Card className="card-img-prev">
                  <img src={storeDef} alt="Placeholder" />
                </Card>
              )}
              {/* <Upload
        fileList={fileList}
        name="file"
        beforeUpload={(file) => {
          setFileList([file]);
          return false; // Prevent automatic upload
        }}
      >
        <Button icon={<UploadOutlined />}>Upload Image</Button>
      </Upload> */}
              <Upload
                fileList={fileList}
                name="file"
                accept=".png, .jpg, .jpeg, .svg"
                beforeUpload={(file) => {
                  const isValidImageType = ['image/png', 'image/jpeg', 'image/svg+xml'].includes(file.type);
                  if (!isValidImageType) {
                    showToasterMessage({
                      description: 'You can only upload PNG, JPG, SVG'
                    })
                    return Upload.LIST_IGNORE;
                  }
                  setFileList([file]);
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>

            </div>
            <ErrorMsg
              validator={() => { return !props.submitted || fileList }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>
        </Row>
      </Drawer>

    </Fragment>
  );
}

export default TestimonialDrawer