import { COMMUNICATION_ACTION_LIST } from "../static/constants";

const initialState = {
    dgList: [],
    dgDetails: {},
    contactList: {
        1: [],
    },
    contactListCount: 0,
    subAccount: {},
    formBuilderList: [],
    accountConfigPh: {},
    accountConfigWhatsApp: {},
    newsletterTemplateList: [],
    eventTemplateList: [],
    dgCount: 0,
    campaignList: {
        1: []
    },
    campaignCount: 0,
    campaignEmailMetrics: [],
    sendStatusDetails: [],
    sendStatusCount: 0,
    deliveredStatusDetails: [],
    failedStatusDetails: [],
    totalMailCampaignDetails: [],
    totalMailCampaignCount: 0,
    failedStatusCount: 0,
    deliveredStatusCount: 0,
    aggregateReport: [],
    campaignEmailStatistics: {},
    communicationTemplateSettings:[],
    customerEmailCommunications: {},
    supplierEmailCommunications: {}


};

const CommunicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMMUNICATION_ACTION_LIST.DG_LIST:
            return {
                ...state,
                dgList: action.data,
            };
        case COMMUNICATION_ACTION_LIST.DG_DETAILS:
            return {
                ...state,
                dgDetails: {
                    ...state.dgDetails,
                    [action.data.distributionGroupId]: action.data.details
                }
            };
        case COMMUNICATION_ACTION_LIST.CONTACT_LIST:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    [action.data.pageNo || 1]: action.data.list,
                },
            };
        case COMMUNICATION_ACTION_LIST.CONTACT_LIST_COUNT:
            return { ...state, contactListCount: action.data };
        case COMMUNICATION_ACTION_LIST.CONTACT_LIST_PAGE_RESET:
            return { ...state, contactList: initialState.contactList };
        case COMMUNICATION_ACTION_LIST.SUB_ACCOUNT:
            return { ...state, subAccount: action.data };
        case COMMUNICATION_ACTION_LIST.FORM_BUILDER_LIST:
            return { ...state, formBuilderList: action.data };
        case COMMUNICATION_ACTION_LIST.ACCOUNT_CONFIG_PH:
            return { ...state, accountConfigPh: action.data };
        case COMMUNICATION_ACTION_LIST.ACCOUNT_CONFIG_WHATS_APP:
            return { ...state, accountConfigWhatsApp: action.data };

        case COMMUNICATION_ACTION_LIST.NEWSLETTER_TEMPLATE_LIST:
            return { ...state, newsletterTemplateList: action.data };

        case COMMUNICATION_ACTION_LIST.EVENT_TEMPLATE_LIST:
            return { ...state, eventTemplateList: action.data };

        case COMMUNICATION_ACTION_LIST.DG_COUNT:
            return { ...state, dgCount: action.data };

        case COMMUNICATION_ACTION_LIST.CAMPAIGN_LIST:
            return {
                ...state,
                campaignList: {
                    ...state.campaignList,
                    [action.data.pageNo || 1]: action.data.list,
                },
            };
        case COMMUNICATION_ACTION_LIST.CAMPAIGN_COUNT:
            return { ...state, campaignCount: action.data };
        case COMMUNICATION_ACTION_LIST.CAMPAIGN_EMAIL_METRICS_DETAILS:
            return { ...state, campaignEmailMetrics: action.data };
        case COMMUNICATION_ACTION_LIST.SEND_STATUS_DETAILS:
            return { ...state, sendStatusDetails: action.data };
        case COMMUNICATION_ACTION_LIST.SEND_STATUS_COUNT:
            return { ...state, sendStatusCount: action.data };
        case COMMUNICATION_ACTION_LIST.DELIVERED_STATUS_DETAILS:
            return { ...state, deliveredStatusDetails: action.data };
        case COMMUNICATION_ACTION_LIST.DELIVERED_STATUS_DETAILS:
            return { ...state, deliveredStatusDetails: action.data };
        case COMMUNICATION_ACTION_LIST.FAILED_STATUS_DETAILS:
            return { ...state, failedStatusDetails: action.data };
        case COMMUNICATION_ACTION_LIST.TOTAL_MAIL_CAMPAIGN_DETAILS:
            return { ...state, totalMailCampaignDetails: action.data };
        case COMMUNICATION_ACTION_LIST.TOTAL_MAIL_CAMPAIGN_COUNT:
            return { ...state, totalMailCampaignCount: action.data };
        case COMMUNICATION_ACTION_LIST.FAILED_STATUS_COUNT:
            return { ...state, failedStatusCount: action.data };
        case COMMUNICATION_ACTION_LIST.DELIVERED_STATUS_COUNT:
            return { ...state, deliveredStatusCount: action.data };
        case COMMUNICATION_ACTION_LIST.BREVO_EMAIL_AGGREGATE_REPORT:
            return { ...state, aggregateReport: action.data };
        case COMMUNICATION_ACTION_LIST.CAMPAIGN_EMAIL_STATISTICS:
            return { ...state, campaignEmailStatistics: {...state.campaignEmailStatistics, ...(action.data || {})} };
        case COMMUNICATION_ACTION_LIST.COMMUNICATION_TEMPLATE_LIST:
            return { ...state, communicationTemplateSettings: action.data };
        case COMMUNICATION_ACTION_LIST.CUSTOMER_EMAIL_COMMUNICATIONS:
            return { ...state, customerEmailCommunications: action.data };
        case COMMUNICATION_ACTION_LIST.SUPPLIER_EMAIL_COMMUNICATIONS:
            return { ...state, supplierEmailCommunications: action.data };
        default:
            return state;
    }
};

export default CommunicationReducer;
