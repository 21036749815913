import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
//import moment from 'moment-timezone';
//import * as find from 'lodash.find';
// import { CustomAgGrid } from '../../../general/CustomAgGrid';
import { ICONS, MODAL_TYPE } from "../../../../static/constants";
import { Menu, Dropdown, Button, Skeleton, Empty } from "antd";
import { connect } from "react-redux";
import { sortTable } from "../../../../utils";
import { EmptyBox } from "../../../general/EmptyBox";
import { EmptyTableData } from "../../../general/EmptyTableData";

class TaxIdentification extends Component {

  constructor(props){
    super(props)
  }
  // state = {
  //   buttonDropDown: false
  // };

  // toggleDropdown = () => {
  //   const { buttonDropDown } = this.state;
  //   this.setState({
  //     buttonDropDown: !buttonDropDown
  //   })
  // };

  render() {
    // const agGridStyle = {
    //   height: '100%',
    //   width: '100%'
    // };

    // const onFirstDataRendered = (params) => {
    //   params.api.sizeColumnsToFit();
    // }
    // const getRowHeight = (params) => {
    //   return 36;
    // }
    const self = this;
    const callback = function (payload) {
      self.props.fetchCustomerDetailsByCustomerId(payload);
      return;
    };
    const deleteTaxIdentifications = (payload) => {
      const modalBody = <FormattedMessage id="common.delete.confirmation" />;
      const modalData = {
        modalBody,
        handleSubmit: () => {
          this.props.deleteCustomerTaxIdentifications(
            payload,
            callback
          );
          this.props.hideModal();
        },
      };
      this.props.showModal(modalData);
    };

    // const columnDefs = [
    //   {
    //     headerName: "#",
    //     resizable: true,
    //     width: 40,
    //     cellRendererFramework: function (link) {
    //       return <div>
    //         {link.rowIndex + 1}
    //       </div>
    //     }
    //   },

    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='modal.common.taxIdentification.taxType' defaultMessage='' />;
    //     },
    //     field: "taxIdentificationType",
    //     resizable: true,
    //     width: 130,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='modal.common.taxIdentification.number' defaultMessage='' />;
    //     },
    //     field: "taxIdentificationNumber",
    //     resizable: true,
    //     width: 300,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='modal.common.taxIdentification.populateOnTransaction' defaultMessage='' />;
    //     },
    //     field: "populateOnTransaction",
    //     resizable: true,
    //     width: 110,
    //     cellRendererFramework: (params) => {
    //       return params.node.data.populateOnTransaction ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
    //   },
    //   },
    //   {
    //     field: 'action',
    //     lockPosition: true,
    //     pinned: 'right',
    //     width: 70,
    //     cellRendererFramework: (params) => <div>
    //       <span className='cursor-pointer' onClick={() => deleteTaxIdentifications(params.data)}>
    //         <i className="fa fa-trash" />
    //       </span>
    //     </div>
    //   }
    // ]

    // const columnDefs = [
    //   {
    //     title: "#",
    //     dataIndex: "rowIndex",
    //     key: "rowIndex",
    //     render:(text, record, index)=> <div>
    //         {index + 1}
    //       </div>,
    //     width: 40,
    //   },
    //   {
    //     title: <FormattedMessage id='modal.common.taxIdentification.taxType' defaultMessage='' />,
    //     dataIndex: "taxIdentificationType",
    //     key: "taxIdentificationType",
    //     width: 130,
    //   },
    //   {
    //     title: <FormattedMessage id='modal.common.taxIdentification.number' defaultMessage='' />,
    //     dataIndex: "taxIdentificationNumber",
    //     key: "taxIdentificationNumber",
    //     width: 300,
    //   },
    //   {
    //     title: <FormattedMessage id='modal.common.taxIdentification.populateOnTransaction' defaultMessage='' />,
    //     dataIndex: "populateOnTransaction",
    //     key: "populateOnTransaction",
    //     width: 110,
    //     render: (populateOnTransaction) => {
    //       return populateOnTransaction ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
    //     },
    //   },
    //   {
    //     title: <FormattedMessage id='customer.listing.header.action' defaultMessage='' />,
    //     dataIndex: 'action',
    //     key: 'action',
    //     width: 70,
    //     render: (text, record, index) => <div>
    //       <span className='cursor-pointer' onClick={() => deleteTaxIdentifications(record)}>
    //         <i className="fa fa-trash" />
    //       </span>
    //     </div>
    //   }
    // ]

    // const { buttonDropDown } = this.state;
    // const { toggleDropdown } = this;

    // const onGridReady = (params) => {
    //   this.gridApi = params.api;
    // }

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Action
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            key="edit"
            onClick={() => {
              handelAddnewTransaction(e);
            }}
          >
            <i className={ICONS.EDIT} /> Edit
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => {
              deleteTaxIdentifications(e);
            }}
          >
            <i className={ICONS.DELETE} /> Delete
          </Menu.Item>
        </Menu>
      );
    };
const handelAddnewTransaction = (e)=>{
  let self = this;
  const formData = {
    customerId: Number(this.props.customerId),
    ...e
  };
  const { showModal, companyInfo } = this.props;
  formData.relationshipId = companyInfo.relationshipId;
  formData.callback = function (taxIdentification) {
    self.props.fetchCustomerDetailsByCustomerId(formData);
    return;
  };
  const data = {
    title: (
      <FormattedMessage id="addItem.text.taxIdentification" />
    ),
    formData,
    hideFooter: true,
    modalData: {
      modalType: MODAL_TYPE.NEW_TAX_IDENTIFICATION,
      data: { formData },
    },
    handleSubmit: (formData = {}) => {
      data.formData.submittedOnce = true;
      this.props.hideModal(data);
    },
  };
  showModal(data);
}

const handleSortColumn = (index) => {
        
  const currentSortDirection = this.props.sortStates[index] || "none";
  const nextSortDirection =
    currentSortDirection === "none" ? "asc" :
    currentSortDirection === "asc" ? "desc" : "none";

  // Update sorting state using props.updateState
  this.props.updateState({
    sortStates: {
      // ...props.sortStates,
      [index]: nextSortDirection,
    },
  });

  // Call your sorting function
  const direction = nextSortDirection === "desc" ? 1 : 0;
  sortTable(document.getElementById("taskIdentificationList-table"), index, direction);
};
const getSortIcon = (index) => {
    const sortState = this.props.sortStates[index];
    if (sortState === "asc") return <i className={ICONS.SORT_UP} />;
    if (sortState === "desc") return <i className={ICONS.SORT_DOWN} />;
    return this.props.hoveredColumn === index ? <i style={{opacity:1}} className={ICONS.SORT} /> : <i style={{opacity:0}} className={ICONS.SORT} />; 
};

    return (
      <Fragment>
        {false ?
          <div className="view-top-bar">
            <div className="top-view-left">
              <div className="page-heading">Tax Identification</div>
              {/* <div className="vertical-sep" /> */}
            </div>
            <div className="top-view-right">
              <button
                className="create-action-btn"
                onClick={() => {
                  let self = this;
                  const formData = {
                    customerId: Number(this.props.customerId),
                  };
                  const { showModal, companyInfo } = this.props;
                  formData.relationshipId = companyInfo.relationshipId;
                  formData.callback = function (taxIdentification) {
                    self.props.fetchCustomerDetailsByCustomerId(formData);
                    return;
                  };
                  const data = {
                    title: (
                      <FormattedMessage id="addItem.text.taxIdentification" />
                    ),
                    formData,
                    hideFooter: true,
                    modalData: {
                      modalType: MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                      data: { formData },
                    },
                    handleSubmit: (formData = {}) => {
                      data.formData.submittedOnce = true;
                      this.props.hideModal(data);
                    },
                  };
                  showModal(data);
                }}
              >
                <FormattedMessage id="new" />{" "}
                <FormattedMessage id="addItem.text.taxIdentification" />
              </button>
            </div>
          </div>
          :
          ''
        }

        {/* <div className='agGridWrapper'>
            <div className="ag-theme-balham" style={agGridStyle} >
              <CustomAgGrid
                columnDefs={columnDefs}
                rowData={(this.props.customer || {}).customerTaxIdentificationsList || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                animateRows={true}
                onGridSizeChanged={onFirstDataRendered}
                getRowHeight={getRowHeight}
                editType={'fullRow'}
                onGridReady={onGridReady}
              >
              </CustomAgGrid>
            </div>
          </div> */}
        {/* <Table pagination={false} columns={columnDefs} dataSource={this.props.customer.customerTaxIdentificationsList || []} className="customer-transactions-table" /> */}
        <div className="view-container">
          <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
            Tax Identification List
            </div>
              {
                (this.props.customer.customerTaxIdentificationsList || []).length ? <>
                  <div className="vertical-sep" />
                  <div>{this.props.customer.customerTaxIdentificationsList?.length}</div>
                </> : ''
              }
            </div>
        
          <div className="right-actions">
            
          <Button
                  className="create-action-btn"
              style={{backgroundColor:"#1cb961", color: '#ffffff'}}

                  onClick={() => {
                    handelAddnewTransaction();
                  }
                }
                >
                  <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
          </Button>
            
          </div>
        </div>
  
        <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 16 }}>

            {/* <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">Tax Identification List</div>
                {(this.props.customer?.customerTaxIdentificationsList || []).length ? <>
                  <div className="vertical-sep" />
                  <div>{this.props.customer?.customerTaxIdentificationsList.length}</div>
                </>
                :''}
              </div>
              <div className="right-actions">
                <Button
                  className="create-action-btn"
                  style={{backgroundColor:"#1cb961", color: '#ffffff'}}
                  onClick={() => {
                    let self = this;
                    const formData = {
                      customerId: Number(this.props.customerId),
                    };
                    const { showModal, companyInfo } = this.props;
                    formData.relationshipId = companyInfo.relationshipId;
                    formData.callback = function (taxIdentification) {
                      self.props.fetchCustomerDetailsByCustomerId(formData);
                      return;
                    };
                    const data = {
                      title: (
                        <FormattedMessage id="addItem.text.taxIdentification" />
                      ),
                      formData,
                      hideFooter: true,
                      modalData: {
                        modalType: MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                        data: { formData },
                      },
                      handleSubmit: (formData = {}) => {
                        data.formData.submittedOnce = true;
                        this.props.hideModal(data);
                      },
                    };
                    showModal(data);
                  }}
                >
                  <i className={ICONS.ADD} style={{margin: '4px 5px 0px 0px'}}/> <FormattedMessage id="button.create.label" defaultMessage="" />
                </Button>
              </div>
            </div> */}
            <div className="table-container table-h-profile">
              <table id="taskIdentificationList-table">
                <thead>
                  <tr>
                    <th width= "6%">S.No.</th>
                    <th>
                      <div
                        onClick={() => handleSortColumn(1)}
                        onMouseEnter={() => this.props.updateState({ hoveredColumn: 1 })}
                        onMouseLeave={() => this.props.updateState({ hoveredColumn: null })}
                        className="sortTable-Container justify-start">
                        <FormattedMessage
                          id="modal.common.taxIdentification.taxType"
                          defaultMessage=""
                        />
                        {getSortIcon(1)}
                      </div>
                    </th>
                    <th>
                      <div
                        onClick={() => handleSortColumn(2)}
                        onMouseEnter={() => this.props.updateState({ hoveredColumn: 2 })}
                        onMouseLeave={() => this.props.updateState({ hoveredColumn: null })}
                        className="sortTable-Container justify-start">
                        <FormattedMessage
                          id="modal.common.taxIdentification.number"
                          defaultMessage=""
                        />
                        {getSortIcon(2)}
                      </div>
                    </th>
                    <th>
                      <div
                          onClick={() => handleSortColumn(3)}
                          onMouseEnter={() => this.props.updateState({ hoveredColumn: 3 })}
                          onMouseLeave={() => this.props.updateState({ hoveredColumn: null })}
                          className="sortTable-Container justify-start">
                          <FormattedMessage
                            id="modal.common.taxIdentification.populateOnTransaction"
                            defaultMessage=""
                          />
                          {getSortIcon(3)}
                        </div>
                    </th>
                    <th className="text-center">
                      <FormattedMessage
                        id="customer.listing.header.action"
                        defaultMessage=""
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.customer.customerTaxIdentificationsList?.length > 0
                    ? this.props.customer.customerTaxIdentificationsList.map(
                        (rowData, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{rowData.taxIdentificationType || <EmptyBox boxSize={"small"} />}</td>
                            <td>{rowData.taxIdentificationNumber || <EmptyBox boxSize={"small"} />}</td>
                            <td>
                              {rowData.populateOnTransaction ? "Yes" : "No"}
                            </td>

                            <td className="text-center">
                              <div className="action-icon">
                                <Dropdown
                                  overlay={actionMenu(rowData)}
                                  trigger={["click"]}
                                >
                                  <i className={ICONS.MORE} />
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    :
                    <tr key="empty-data">
                      <td colSpan={"100%"} style={{borderBottom:"none"}}>
                        <EmptyTableData />
                      </td>
                    </tr> 
                    }
                </tbody>
              </table>
            </div>
            <div className="footer">
              <div className="f-left"></div>
              <div className="f-right"></div>
            </div>
          </Skeleton>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.common.customerDetail
  }
}

export default connect(mapStateToProps) (TaxIdentification);

