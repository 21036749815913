import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCrmLeadFormList } from '../../../../leads/LeadForm/action';
import {  getAllLeadSources, getAllLeadStatus } from '../createLead/action';
import { fetchCrmLeadCampaign } from '../../../../leads/LeadCampaigns/action';
import { getTeam } from '../../../../company/EmployeeMeta/Team/action';
import { getProjectById ,resetProjectById,getAllProjectWithSubProject} from '../../../../company/Project/action';
import ImportLead from "../../../../../components/modal/modalBody/leads/Import"
import { importLeadFromCsv } from './action';
import {fetchAllLeadList,getAllLeadListCount} from "../../../../leads/AllLeads/action"
class ImportLeadCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            isuploded: false,
            getProjectdata:false
        };

    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
   this.props.resetProjectById()
        const payload = {
          relationshipId: companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 1000,
        }
        this.props.fetchCrmLeadFormList(payload);
        this.props.getAllLeadSources(payload);
        this.props.getAllLeadStatus(payload);
        this.props.fetchCrmLeadCampaign(payload);
        this.props.getAllProjectWithSubProject(payload);

        this.props.getTeam(payload);
    }

    componentWillReceiveProps(props){
         if(Object.keys(props.projectdetail)?.length){
             
             let team = props.projectdetail.crmLeadTeamAssignedList?.map((item)=>{
                 delete item.projectMasterId  
                 delete item.lastName  
                 delete item.firstName  
                 delete item.crmLeadEmpAssignedId  
                 return {...item}
                })
                let emp = props.projectdetail.crmLeadEmpAssignedList.map((item)=>{
                    delete item.projectMasterId  
                    delete item.lastName  
                    delete item.firstName  
                    delete item.crmLeadEmpAssignedId  
                    return {...item}
                })
            this.setState({
                crmLeadEmpAssignedList:emp,
                crmLeadTeamAssignedList:team
            })

            props.resetProjectById();
         }

    }

    render() {
        return <ImportLead {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        crmLeadFormList: state.crm.crmLeadFormList[1],
        crmLeadStatusList: state.crm.crmLeadStatusList,
        crmLeadSourceList: state.crm.crmLeadSourceList,
        crmCampaignList: state.crm.crmCampaignList,
        teamList: state.employee.teamList,
        employeeByTeamName:state.crm.employeeByTeamName,
        projectdetail:state.project.projectdetail,
        projectWithSubProject: state.project.projectWithSubProject,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCrmLeadFormList,
   
    getAllLeadSources, 
    getAllLeadStatus,
    fetchCrmLeadCampaign,getTeam,getProjectById,resetProjectById,getAllProjectWithSubProject,importLeadFromCsv,fetchAllLeadList,getAllLeadListCount

 
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImportLeadCon);
