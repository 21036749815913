import axios from "axios";
import config from "../../../config/environmentConfig";
import { SMS_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getOnlineUsers = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/onlineUser/getOnlineUsers?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.ONLINE_USER_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getOnlineUsersCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/onlineUser/countOnlineUsers?relationshipId=${payload.relationshipId}&createdByEmpId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.ONLINE_USER_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const saveSchoolCourse = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/course/saveOrUpdate`,
        payload
      )
      .then((response) => {
        props.updateHeaderState({
          schoolDrawers: {
            ...props.schoolDrawers,
            addCourseDrawerVisible: false
          }
        });
        props.getSchoolCourse(response.data);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.ONLINE_USER_LIST_PAGE_RESET });
  };
};



export const getAccessToken = (payload, props) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/onlineUser/getOnlineUserById?relationshipId=${payload.relationshipId}&clientOnlineUserAccountId=${payload.clientOnlineUserAccountId}`
      )
      .then((res) => {
        let url = `${props.subdomainName}/portal?token=${payload.clientOnlineUserAccountId}-${res.data.accountActivationToken}&at=${payload.relationshipId}_${payload.relationshipEmployeeId}`;
        Object.assign(document.createElement('a'), {
          target: '_blank',
          rel:"noopener noreferrer",
          href: url,
        }).click();
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      });
  };
};