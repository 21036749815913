import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SO_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage, formatDateForBackend } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchShippingInvoiceDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/getShippingInvoiceByShipmentId?shipmentMasterId=${payload.shipmentMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        const { data } = res;
        const dataToSet  =JSON.parse(data.invoiceData);
        dataToSet.shippingInvoiceId = data.shippingInvoiceId;
        dataToSet.shipmentMasterId = data.shipmentMasterId;
        dispatch({ type: SO_ACTION_LIST.SO_SHIPPING_INVOICE_DETAIL, data: dataToSet });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
  }
}



export const createShippingInvoice = (data, props) => {
  return dispatch => {
    const payload = {
      relationshipId: (props.companyInfo || {}).relationshipId,
      customerId: data.customerId,
      version: props.version,
      invoiceData: JSON.stringify(data),
      shippingInvoiceId: data.shippingInvoiceId,
      shipmentMasterId: data.shipmentMasterId,
      dateCreated: formatDateForBackend(new Date() )
    }
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/saveShippingInvoice`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Shipping invoice created successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        }
      })
  }
}
