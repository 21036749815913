import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProformaPaymentComp from '../../../components/B2B/ProformaPayment';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import queryString from 'query-string';
import { fetchCustomerProformaList, fetchProformaDetails, processRazorpayPayment } from './action';
import { fetchCustomerDetailsByCustomerId, createRazorpayPaymentOrder } from '../../../actions/commonActions';
import PaymentResult from '../../../components/B2B/PaymentGateways/PaymentStatus';
import { Button, Result } from 'antd';
import { generatePDF,printPDF } from '../../../actions/downloadFileAction';
import { saveRegistrationFound } from '../Register/action';


class ProformaPayment extends Component {
  
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(decodeURIComponent(this.props.location.search));

    let {rid=0, proformaId=0, customerId=0} = linkProps;

    this.state = {
      relationshipId: Number(rid || 0),
      proformaId: Number(proformaId || 0),
      customerId: Number(customerId || 0),
      invoiceDataLoaded: false,
      paymentGateways: [],
    }
  }

  componentDidMount() {
    if(this.state.proformaId){
      const payload = {
        relationshipId: this.state.relationshipId,
        proformaId: this.state.proformaId,
        customerId: this.state.customerId
      }
      this.props.fetchProformaDetails(payload);
      this.props.fetchCustomerDetailsByCustomerId(payload);
      this.setState({activeTab: this.state?.paymentGateways[0]});
    }
  }

  componentWillReceiveProps(){
    if(!this.state.invoiceDataLoaded && Object.keys(this.props.proformaDetails || []).length && Object.keys(this.props.customerDetail || []).length){
      const invoiceData= JSON.parse(this.props?.proformaDetails?.invoiceData || '{}')
      this.setState({
        proformaDetails: {...this.props.proformaDetails, invoiceData: invoiceData},
        customerDetail: {
          ...this.props.customerDetail, 
          customerName: `${this.props.customerDetail.boContactList[0]?.firstName} ${this.props.customerDetail.boContactList[0]?.lastName}`,
          cellPhone: this.props.customerDetail.boContactList[0]?.cellPhone
        },

        invoiceDataLoaded: true
      });

     this.setState({
       paymentGateways: (invoiceData.additionalInfoList || [])?.map(obj=>obj.paymentGatewayName),
       activeTab: ((invoiceData.additionalInfoList || [])?.map(obj=>obj.paymentGatewayName))[0]
     })
   }

    
  }


  render() {
    return (
      this.state.paymentProcessed ?   
        <PaymentResult {...this.props} {...this.state} />
        :
        this.state.proformaId ? 
          <ProformaPaymentComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        :
        <Result
          status="404"
          title="Something is missing"
          subTitle="It seems that something is missing, please reopen this page or contact admin"
          extra={
            <Button type="primary" onClick={()=>{
              window.close();
            }}>
              Close
            </Button>
          }
        />
    );
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.settings.relationshipData,
    customerProformaList: state.salesInvoice.customerProformaList,
    proformaDetails: state.salesInvoice.proformaDetails,
    customerDetail: state.common.customerDetail,
    razorpayPaymentOrderDetail: state.common.razorpayPaymentOrderDetail,
    detailLoading: state.common.detailLoading,
    saveLoading: state.common.saveLoading,
    paymentData:state.salesInvoice.paymentData,
    registrationFormData: state.settings.registrationFormData,
    listLoading: state.common.listLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  fetchCustomerProformaList,
  fetchProformaDetails,
  fetchCustomerDetailsByCustomerId,
  createRazorpayPaymentOrder,
  processRazorpayPayment,
  generatePDF,
  printPDF,
  saveRegistrationFound
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProformaPayment);
