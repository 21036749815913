import axios from "axios";
import config from "../../../../config/environmentConfig";
import { MEMBERSHIP_ACTION_LIST } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
const { lang } = require("../../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const saveMembershipOption = (payload, props) => {
    return (dispatch) => {
        return axios
            .post(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/membershipOption/saveOrUpdate`,
                payload
            )
            .then((response) => {
                if (props) {
                    props.updateHeaderState({
                        membership: {
                            addMembershipOptionDrawerVisible: false
                        }
                    });
                    props.openMembershipOptionDrawerFromListing && props.getMembershipOptionListing && props.getMembershipOptionListing(props);
                    props.openMembershipOptionDrawerFromListing && props.getMembershipOptionCount && props.getMembershipOptionCount(props);
                }
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || "")] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const getAllFees = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
                }/fees/getFees?relationshipId=${payload.relationshipId}&pageSize=1000&pageNumber=0`
            )
            .then((res) => {
                dispatch({
                    type: MEMBERSHIP_ACTION_LIST.ALL_TERM_AND_FEES,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || "")] ||
                            "Some error occurred",
                    });
                }
            });
    };
};



