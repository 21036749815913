import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMPANY_ACTION_LIST, COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createAllowance = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DRAWER_LOADER });
    return axios
      .post(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/empAllowance/saveOrUpdate`, payload)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_DRAWER_LOADER });

        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })

        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getAllowance(payload);
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || "")] || 'Some error occurred'
        })
    dispatch({ type: COMMON_ACTIONS.HIDE_DRAWER_LOADER });

      })
  }
}


export const getAllowance = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchedStr=${payload.searchedText}` : '';

    return axios.get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/empAllowance/getAllowanceByPage?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}${searchedTextQuery}`)

      .then(response => {
        const data = {
          pageNo: payload.pageNumber,
          list: response.data
        }
        dispatch({ type: COMPANY_ACTION_LIST.ALLOWANCE_LIST_PAGE, data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        
      })
  }
}

export const getTotalAllowanceCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchedStr=${payload.searchedText}` : '';

    return axios
      .get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/empAllowance/getAllowanceCount?relationshipId=${payload.relationshipId}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: COMPANY_ACTION_LIST.ALLOWANCE_LIST_PAGE_COUNT, data: res.data });
    dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });

      })
      .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
    dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
       
      })
  }
}
export const deleteAllowance = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .post(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/empAllowance/delete`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });

        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 0,
          pageSize: 25,
        }
        props.getAllowance(payload);
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || "")] || 'Some error occurred'
        })
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });

      })
  }
}

export const searchAllowance = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchedStr=${payload.searchedText}` : '';
    return axios.get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/empAllowance/getAllowanceBySearch?relationshipId=${payload.relationshipId}${searchedTextQuery}`)

      .then(response => {
        const data = {
          pageNo: payload.pageNumber,
          list: response.data
        }
        dispatch({ type: COMPANY_ACTION_LIST.ALLOWANCE_SEARCH_PAGE, data });
      })
      .catch(err => {

        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = COMPANY_ACTION_LIST.ALLOWANCE_SEARCH_PAGE;
        }
      })
  }
}

