import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchAllEmployees } from './../../../actions/commonActions';
// import CommunicationContact from "../../../components/customer/details/contacts/"
import CommContact from "../../../components/communication/Contacts";
import { fetchPaginationDataIfNeeded } from "../../../utils";
import { fetchAllContacts, deleteContact, fetchCustomerContacts, fetchSupplierContacts } from "../../customer/Listing/ShowConact/action";
import { fetchCustomers, resetPaginatedData } from "../../customer/Listing/action";
import { generatePDF } from "../../../actions/downloadFileAction";
import { fetchSubscribers } from "../../drawer/communication/distribution-group/action";
import { saveOrUpdateLeads } from "../../modal/modalBody/leads/createLead/action";
import {fetchAllLeadList,getAllLeadListCount} from "../../leads/AllLeads/action";


class CommunicationContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDGContact: true,
            pageSize: 25,
            pageNumber: 1,
            converted:[]
        }
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            companyInfo,
            contactId: companyInfo.contactId,
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25
        };
        // fetchPaginationDataIfNeeded('fetchAllContacts', 'contacts', this.props, payload, true);
        // this.props.fetchAllContacts(payload);
        this.props.fetchCustomerContacts(payload);
    }

    render() {
        return (
            <CommContact  {...this.props}
                {...this.state}
                updateState={(data) => {
                    this.setState(data);
                }}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        contacts: state.customer.contacts,
        customers: state.customer.customers,
        customerContacts: state.customer.customerContacts,
        supplierContacts: state.customer.supplierContacts,
        allEmployee: state.common.employees,
        listLoading: state.common.listLoading,
        subscribers: state.customer.subscribers,
        permissions: state.auth.permissions,

    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showModal,
            hideModal,
            fetchAllContacts,
            deleteContact,
            pushModalToStack,
            popModalFromStack,
            fetchCustomers,
            fetchCustomerContacts,
            fetchSupplierContacts,
            fetchAllEmployees,
            resetPaginatedData,
            generatePDF,
            fetchSubscribers,saveOrUpdateLeads,fetchAllLeadList,getAllLeadListCount
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationContact);
