import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Input } from 'antd';
import { DropdownRef } from '../../../../general/Dropdown';
import { CustomButton } from '../../../../general/CustomButton';
import * as find from 'lodash.find';

class WarehouseComponent extends Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formData } = this.props;
    const warehouseAddress = (formData && formData.locationList && formData.locationList[0]) ? formData.locationList[0] : {};
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    return (
      <Fragment>
        <Form labelAlign='left' {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content warehouse-modal-control">
          <Form.Item label={"Is Primary"} colon={false}>
              {getFieldDecorator('isPrimary', {
                initialValue: formData.isPrimary || false,
              })(<Checkbox 
              // checked={formData.isPrimary}
              onChange={(e)=>{
                this.props.form.setFieldsValue({ 'isPrimary': e.target.checked});
              }}
              />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='modal.common.warehouse.warehouseName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('warehouseName', {
                initialValue: formData.warehouseName || '',
                rules: [
                  {
                    required: true,
                    message: 'Warehouse Name is required.',
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'modal.common.warehouse.warehouseName', defaultMessage: '' } )} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.streetName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('streetAddress1', {
                initialValue: warehouseAddress.streetAddress1 || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'address.streetName', defaultMessage: '' } )}  />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.streetNameContd' defaultMessage='' />} colon={false}>
              {getFieldDecorator('streetAddress2', {
                initialValue: warehouseAddress.streetAddress2 || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'address.streetNameContd', defaultMessage: '' } )} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.city' defaultMessage='' />} colon={false}>
              {getFieldDecorator('cityName', {
                initialValue: warehouseAddress.cityName || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'address.city', defaultMessage: '' } )} />)}
            </Form.Item>
           
            <Form.Item label='Country' className='hide' colon={false} >
              {getFieldDecorator('countryName', {
                initialValue: warehouseAddress.countryName || ''
              })
                (<span />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.country' defaultMessage='' />} colon={false}>
              {getFieldDecorator('countryId', {
                initialValue: warehouseAddress.countryId || ''
              })
                (<DropdownRef
                  items={this.props.countries || []}
                  optionKeyName='countryId'
                  valueKeyName='countryName'
                  placeholder='Select'
                  onSelect={(selectedValue, option) => {
                    let selectedCountry = find(this.props.countries || [], { countryId: Number(selectedValue || 0) }) || {};
                    this.props.form.setFieldsValue({ 'countryId': selectedValue });
                    this.props.form.setFieldsValue({ 'countryName': option.props.name });
                    this.props.form.setFieldsValue({ 'currencyCode': selectedCountry.currencyCode});
                    this.props.fetchStates(selectedValue)
                  }}
                />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='signup.currencyCode.text' defaultMessage='' />} colon={false} >
              {getFieldDecorator('currencyCode', {
                initialValue: this.props.currencyCode || warehouseAddress.currencyCode
              })
                (<strong className="country-code">{this.props.form.getFieldValue('currencyCode') || '-'}</strong>)}
            </Form.Item>
          
            <Form.Item label={<FormattedMessage id='address.state' defaultMessage='' />} className='hide' colon={false}>
              {getFieldDecorator('stateId', {
                initialValue: warehouseAddress.stateId || ''
              })
                (<span />)}
            </Form.Item>
            {(this.props.form.getFieldValue('countryName') === 'India' || this.props.form.getFieldValue('countryName') === 'United States' || this.props.form.getFieldValue('countryName') === 'United States (USA)')?
              <Form.Item label={<FormattedMessage id='address.state' defaultMessage='' />} colon={false}>
                {getFieldDecorator('stateName', {
                  initialValue: warehouseAddress.stateName || ''
                })
                  (<DropdownRef
                    items={this.props.states || []}
                    optionKeyName='stateId'
                    valueKeyName='stateName'
                    onSelect={(selectedValue, option) => {
                      this.props.form.setFieldsValue({ 'stateId': selectedValue });
                      this.props.form.setFieldsValue({ 'stateName': option.props.name });
                    }}
                  />)}
              </Form.Item>
              :
              <Form.Item label={<FormattedMessage id='address.state' defaultMessage='' />} colon={false}>
                {getFieldDecorator('stateName', {
                  initialValue: warehouseAddress.stateName || ''
                })
                  (<Input placeholder={this.props.intl.formatMessage({ id: 'address.state', defaultMessage: '' } )} />)}
              </Form.Item>
            }
            <Form.Item label={<FormattedMessage id='address.zip' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('zipCode', {
                initialValue: warehouseAddress.zipCode || ''
              })
                (<span />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='address.zip' defaultMessage='' />} colon={false}>
              {getFieldDecorator('zipCode', {
                initialValue: warehouseAddress.zipCode || ''
              })
              (<Input placeholder={this.props.intl.formatMessage({ id: 'address.zip', defaultMessage: '' } )} />)}
            </Form.Item>
            </div>
            <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.warehouse.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.hideModal() }}
              />
              <CustomButton
                intlId={(formData && formData.warehouseMasterId) ? 'modal.warehouse.button.updateWarehouse': 'modal.warehouse.button.createWarehouse'}
                defaultMessage=''
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let warehouseAddress = {
          streetAddress1: values.streetAddress1,
          streetAddress2: values.streetAddress2,
          cityName: values.cityName,
          countryId: values.countryId,
          countryName: values.countryName,
          stateName: (values.stateName||"")?.trim(),
          zipCode: values.zipCode,
          locationType: "WarehouseAddress"
        }
        const payload = {
          warehouseName: values.warehouseName,
          isPrimary: values.isPrimary ? 1:0,
          locationList: [],
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          inventoryWarehouseDetailList: []
        }

        if(this.props.formData && this.props.formData.warehouseMasterId){
          payload.warehouseMasterId = this.props.formData.warehouseMasterId;
          warehouseAddress.warehouseMasterId = this.props.formData.warehouseMasterId;
        }

        if(warehouseAddress && warehouseAddress.streetAddress1){
          payload.locationList.push(warehouseAddress);
        }  

        const modalData = {
          modalBody: `Are you sure you want to ${!this.props.formData.warehouseMasterId ? 'Submit' :'Update?'}`,
          handleSubmit: () => {
            this.props.createUpdateWarehouse(payload, this.props);
            this.props.hideModal();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'warehouse_component' })(injectIntl(WarehouseComponent));
