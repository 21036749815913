import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DonationKioskSettingComponent from '../../../../../components/modal/modalBody/settings/DonationKioskSetting';
import { pushModalToStack, popModalFromStack, showModal, hideModal, fetchPaymentTerms, fetchProjects } from '../../../../../actions/commonActions';
import { saveDonationSetting, fetchDonationSetting, resetDonationSetting, resetDonationLoading } from './action';
import { fetchAvailablePaymentGateway } from '../../../../integrations/Payment/action';
import { fetchAllLedgerAccounts } from '../../../../finance/LedgerAccounts/action';
import { getFundraisingCampaign } from '../../../../fundraising/campaign/action';

import { getAllDG } from '../../../../communication/distribution-group/action';
import { getAllEventTemplate } from '../../../../communication/EventListing/action';
import { getAllMembershipForm } from '../../../../membership/membershipFormList/action';
import { getAllStudentForm } from '../../../../Student/studentFormList/action';
import { getSponsorships } from '../../../../fundraising/sponsorship/action';
//import EmailComponent from '../../../../../containers/drawer/recurringInvoiceEmail';
class DonationSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowDonationMenu: 1,
            isCampaignListLoaded: false,
            isDGLISTLoaded: false,
            isEventTemplateListLoaded: false,
            isMembershipFormListLoaded: false,
            isStudentFormListLoaded: false,
            activeTabKey: props.formFundraising ? '3' : '1'
        }
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        let payload = {
            relationshipId: companyInfo.relationshipId,
            pageSize: 1000,
            pageNumber: 0,
            relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId
        };
        this.props.resetDonationLoading(payload);
       // this.props.getFundraisingCampaign(payload);
       // this.props.getAllDG(payload);
       // this.props.getAllEventTemplate({...payload, templateType: 'event' });
       // this.props.getAllMembershipForm({...payload, registrationType: 'membership'});
       // this.props.getAllStudentForm(payload);
        
        this.props.fetchDonationSetting(payload, this.props);
        this.props.fetchPaymentTerms(payload);
        this.props.fetchAvailablePaymentGateway(payload);
        this.props.fetchAllLedgerAccounts(payload);
        this.props.fetchProjects(payload);
       // this.props.getSponsorships(payload);
    }

    componentWillReceiveProps(props) {
        if (props.donationSetting && props.donationSetting.settingsDonationId && props.projectList && props.projectList.length && props.donationSettingLoading && props.donationSettingLoading.isCampaignListLoaded && props.donationSettingLoading.isDGLISTLoaded && props.donationSettingLoading.isEventTemplateListLoaded && props.donationSettingLoading.isMembershipFormListLoaded && props.donationSettingLoading.isStudentFormListLoaded && props.donationSettingLoading.isSponsorshipListLoaded) {
            let donationContent = JSON.parse(props.donationSetting.donationContent);
            let selectedProjects = donationContent.selectedProjects || {};
            let selectedFundraising = donationContent.selectedFundraising || {};

            let selectedEvents = donationContent.selectedEvents || {};
            let selectedRegistrations = donationContent.selectedRegistrations || {};
            let selectedSubscription = donationContent.selectedSubscription || {};
            let selectedStudentRegistrations = donationContent.selectedStudentRegistrations|| {};
            let selectedSponsorships = donationContent.selectedSponsorships|| {};

            let projectMapedList = props.projectList.map(obj => {
                obj.isListingAllow = (selectedProjects[obj.projectMasterId] || {}).isListingAllow;
                obj.ListingName = (selectedProjects[obj.projectMasterId] || {}).displayName || obj.projectName;
                obj.chartOfAccountDetailsId = (selectedProjects[obj.projectMasterId] || {}).chartOfAccountDetailsId;
                obj.ledgerAccountName = (selectedProjects[obj.projectMasterId] || {}).ledgerAccountName;
                obj.paymentGatewayName = (selectedProjects[obj.projectMasterId] || {}).paymentGatewayName;
                obj.paymentGatewayId = (selectedProjects[obj.projectMasterId] || {}).paymentGatewayId;
                obj.sNo = (selectedProjects[obj.projectMasterId] || {}).sNo;
                return obj;
            });

             let fundraisingProjectList = props.campaignList[1].map(obj => {
                obj.isListingAllow = (selectedFundraising[obj.fundraisingCampaignId] || {}).isListingAllow;
                obj.ListingName = (selectedFundraising[obj.fundraisingCampaignId] || {}).displayName || obj.campaignName;
                obj.chartOfAccountDetailsId = (selectedFundraising[obj.fundraisingCampaignId] || {}).chartOfAccountDetailsId;
                obj.ledgerAccountName = (selectedFundraising[obj.fundraisingCampaignId] || {}).ledgerAccountName;
                obj.paymentGatewayName = (selectedFundraising[obj.fundraisingCampaignId] || {}).paymentGatewayName;
                obj.paymentGatewayId = (selectedFundraising[obj.fundraisingCampaignId] || {}).paymentGatewayId;
                obj.sNo = (selectedFundraising[obj.fundraisingCampaignId] || {}).sNo;
                return obj;
            });

             let eventTemplateList = (props.eventList && props.eventList.length) ? props.eventList.map(obj => {
                obj.isListingAllow = (selectedEvents[obj.alpideFormBuilderId] || {}).isListingAllow;
                obj.ListingName = (selectedEvents[obj.alpideFormBuilderId] || {}).displayName || obj.templateName; 
                obj.sNo = (selectedEvents[obj.alpideFormBuilderId] || {}).sNo;
                return obj;
            }) : null;

            let dgFormList = (props.dgList && props.dgList.length) ? props.dgList.map(obj => {
                obj.isListingAllow = (selectedSubscription[obj.distributionGroupId] || {}).isListingAllow;
                obj.ListingName = (selectedSubscription[obj.distributionGroupId] || {}).displayName || obj.dgName;
                obj.sNo = (selectedSubscription[obj.distributionGroupId] || {}).sNo;
                return obj;
            }) : null;

            let membershipRegistrationFormList = (props.membershipFormList && props.membershipFormList.length) ? props.membershipFormList.map(obj => {
                obj.isListingAllow = (selectedRegistrations[obj.registrationFormSettingId] || {}).isListingAllow;
                obj.ListingName = (selectedRegistrations[obj.registrationFormSettingId] || {}).displayName || obj.programName;
                obj.sNo = (selectedRegistrations[obj.registrationFormSettingId] || {}).sNo;
                return obj;
            }) : null;

            let schoolRegistrationFormList = (props.studentFormList && props.studentFormList.length) ? props.studentFormList.map(obj => {
                obj.isListingAllow = (selectedStudentRegistrations[obj.registrationFormSettingId] || {}).isListingAllow;
                obj.ListingName = (selectedStudentRegistrations[obj.registrationFormSettingId] || {}).displayName || obj.programName;
                obj.sNo = (selectedStudentRegistrations[obj.registrationFormSettingId] || {}).sNo;
                return obj;
            }) : null;

            let sponsorsFormList = props.sponsorshipList[1].map(obj => {
                obj.isListingAllow = (selectedSponsorships[obj.giveSponsorshipMasterId] || {}).isListingAllow;
                obj.ListingName = (selectedSponsorships[obj.giveSponsorshipMasterId] || {}).displayName || obj.name;
                // obj.chartOfAccountDetailsId = (selectedSponsorships[obj.giveSponsorshipMasterId] || {}).chartOfAccountDetailsId;
                // obj.ledgerAccountName = (selectedSponsorships[obj.giveSponsorshipMasterId] || {}).ledgerAccountName;
                // obj.paymentGatewayName = (selectedSponsorships[obj.giveSponsorshipMasterId] || {}).paymentGatewayName;
                // obj.paymentGatewayId = (selectedSponsorships[obj.giveSponsorshipMasterId] || {}).paymentGatewayId;
                obj.sNo = (selectedSponsorships[obj.giveSponsorshipMasterId] || {}).sNo;
                return obj;
            });

            this.setState({
                ...(donationContent || {}),
                settingsDonationIdKiosk: props.donationSetting.settingsDonationId || 0,
                version: props.donationSetting.version,
                projectMapedList,
                fundraisingProjectList,
                eventTemplateList,
                membershipRegistrationFormList,
                schoolRegistrationFormList,
                dgFormList,
                sponsorsFormList,
                isDataReady: true
            })
            this.props.resetDonationSetting();
            this.props.resetDonationLoading();
        }
        
        if(!this.state.projectMapedList && props.projectList && props.projectList.length ){
            let projectMapedList = props.projectList.map(obj => {
                obj.ListingName = obj.projectName;
                return obj;
            })
            this.setState({
                projectMapedList,
            })
        }
        if(!this.state.fundraisingProjectList && props.campaignList && props.campaignList[1] && props.campaignList[1].length ){
            let fundraisingProjectList = props.campaignList[1].map(obj => {
                obj.ListingName = obj.campaignName;
                return obj;
            })
            this.setState({
                fundraisingProjectList: fundraisingProjectList
            })
        }
        if(!this.state.eventTemplateList && props.eventList && props.eventList.length ){
            let eventTemplateList = props.eventList.map(obj => {
                obj.ListingName = obj.templateName;
                return obj;
            })
            this.setState({
                eventTemplateList: eventTemplateList
            })
        }

        if(!this.state.membershipRegistrationFormList && props.membershipFormList && props.membershipFormList.length ){
            let membershipRegistrationFormList = props.membershipFormList.map(obj => {
                obj.ListingName = obj.programName;
                return obj;
            })
            this.setState({
                membershipRegistrationFormList: membershipRegistrationFormList
            })
        }

        if(!this.state.schoolRegistrationFormList && props.studentFormList && props.studentFormList.length ){
            let schoolRegistrationFormList = props.membershipFormList.map(obj => {
                obj.ListingName = obj.programName;
                return obj;
            })
            this.setState({
                schoolRegistrationFormList: schoolRegistrationFormList
            })
        }

        if(!this.state.dgFormList && props.dgList && props.dgList.length ){
            let dgFormList = props.dgList.map(obj => {
                obj.ListingName = obj.dgName;
                return obj;
            })
            this.setState({
                dgFormList: dgFormList
            })
        }
        if(!this.state.sponsorsFormList && props.sponsorshipList && props.sponsorshipList[1] && props.sponsorshipList[1].length ){
            let sponsorsFormList = props.sponsorshipList[1].map(obj => {
                obj.ListingName = obj.name;
                return obj;
            })
            this.setState({
                sponsorsFormList: sponsorsFormList
            })
        }
        // if (props.projectList && props.projectList.length && props.donationSettingLoading && props.donationSettingLoading.isCampaignListLoaded && props.donationSettingLoading.isDGLISTLoaded && props.donationSettingLoading.isEventTemplateListLoaded && props.donationSettingLoading.isMembershipFormListLoaded && props.donationSettingLoading.isStudentFormListLoaded && props.donationSettingLoading.isSponsorshipListLoaded) {
        //     this.setState({
        //         isDataReady: true
        //     })
        // }
        if (props.projectList && props.projectList.length && props.donationSettingLoading && props.donationSettingLoading.isDonationSettingLoaded ) {
            this.setState({
                isDataReady: true
            })
        }

    }

    render() {
        return <div>
             <DonationKioskSettingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> 
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        donationSetting: state.donation.donationSetting,
        availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
        projectList: state.common.projectList,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        campaignList: state.fundraisingReducer.campaignList,
        // kioskDonationSetting: state.donation.kioskDonationSetting,
        eventList: state.communicationReducer.eventTemplateList,
        membershipFormList: state.membershipReducer.membershipFormList,
        studentFormList: state.membershipReducer.studentFormList,
        dgList: state.communicationReducer.dgList,
        donationSettingLoading: state.donation.donationSettingLoading,
        sponsorshipList: state.fundraisingReducer.sponsorshipList,
        permissions: state.auth.permissions,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveDonationSetting,
    fetchDonationSetting,
    resetDonationSetting,
    pushModalToStack,
    popModalFromStack,
    showModal,
    hideModal,
    fetchPaymentTerms,
    fetchAvailablePaymentGateway,
    fetchProjects,
    fetchAllLedgerAccounts,
    getFundraisingCampaign,
    getAllDG,
    getAllEventTemplate,
    getAllMembershipForm,
    getAllStudentForm,
    resetDonationLoading,
    getSponsorships
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DonationSetting);