import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchXeroAuthURL = (history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}alpide-xero/xeroRelationshipConfig/generateOauthUrl`)
      .then(res => {
        window.location = res.data;
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || "")] || 'Some error occurred'
        })
      })
  }
}





export const getXeroConfiguration = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}alpide-xero/xeroRelationshipConfig/getXeroConfiguration?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({
          type: COMMON_ACTIONS.XERO_CONFIG_RELATIONSHIP_DATA,
          data: res.data
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || "")] || 'Some error occurred'
        })
      })
  }
}


export const disconnectConfiguration = (payload, props) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}alpide-xero/xeroRelationshipConfig/disconnectXeroConfiguration?relationshipId=${payload.relationshipId}`)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'successs', description: ((res || {}).data || "") || 'Xero integration disconnectedSuccessfully'});
        props.getXeroConfiguration(payload);
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || "")] || 'Some error occurred'
        })
      })
  }
}


export const generateToken = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}alpide-xero/xeroRelationshipConfig/generateToken?code=${payload.code}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        props.history.push({
          pathname: '/admin/accounting-integration',
          search: '',
          state: { }
        });
        window.location.reload();
        showToasterMessage({
          messageType:"success",
          description: 'Now you can publish your transaction to xero'
        })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
         
          description: lang[((err.response || {}).data || "")] || 'Some error occurred'
        })
      })
  }
}