import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import {  COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
//const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



  export const changePassword = (payload) => {
   
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/user/changePassword`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({description: (res || {}).message || 'Password Changed Successfully.', messageType: 'success' })
        })
        .catch(err => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({ description: ((err.response || {}).data || "") || 'Some error occurred', messageType: 'error' })
        })
    }
  }