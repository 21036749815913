import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Skeleton, Pagination, Popover, Tooltip,Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  getMomentDateForUIReadOnly,
  sortTable,
} from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./index.scss";
import PODetail from "../../../../containers/modal/modalBody/supplier/PurchaseOrderDetail";
import InboundDeliveryDetail from "../../../../containers/modal/modalBody/supplier/InboundDeliveryDetail";
import { CONSTANTS } from "../../../../static/constants";
import { AddAndRemoveSupplierListingColumn } from "../../../general/AddAndRemoveSupplierListingColumn";
import { FilterOutlined, InsertRowRightOutlined } from "@ant-design/icons";

import SupplierDetails from '../../../../containers/supplier/detail'
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const InboundDeliveryListingComp = (props) => {
  const {
    inboundDeliveryList,
    updateState,
    companyInfo,
    inboundDeliveryCount,
    pageNumber,
    dir = 0,
  } = props;

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchAllInboundDeliveries",
      "inboundDeliveryList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchAllInboundDeliveries(payload);
    // props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
  };
  // const getRowHeight = (params) => {
  //     let height = 28;
  //     return height;
  // }

  const openIDDetails = (data) => {
    const poPayload = {
      supplierId: data.supplierId,
      inboundDeliveryMasterId: data.inboundDeliveryMasterId,
    };
    props.pushModalToStack({
      modalBody: <InboundDeliveryDetail {...props} poPayload={poPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const openPODetails = (data) => {
    const poPayload = {
      supplierId: data.supplierId,
      poMasterId: data.poMasterId,
    };

    props.pushModalToStack({
      modalBody: <PODetail {...props} poPayload={poPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const openRFQDetails = (data) => {
    // const rfqPayload = {
    //     rfqMasterId: data.rfqMasterId,
    //     rfqChildMasterId: data.rfqChildMasterId
    // }
    // props.pushModalToStack({
    //     modalBody: data.rfqChildMasterId ? <RFQSplitDetail {...props} rfqPayload={rfqPayload} /> : <RFQDetail {...props} rfqPayload={rfqPayload} />,
    //     width: '100%',
    //     hideFooter: true,
    //     wrapClassName: 'modal-custom-detail'
    // })
  };

  // const getRecievedQuantity = (data) => {
  //     return <div className="cursor-pointer">
  //         {(data.statusInvoice === 'Invoiced' || data.statusInvoice === 'invoiced')? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}
  //         </div>;
  //         // <Progress className={data.totalQtyInvoiced >= data.totalQtyReceived ? "complete" : "incomplete"} format={() => {
  //         //     return `${data.totalQtyInvoiced}/${data.totalQtyReceived}`
  //         // }} percent={(data.totalQtyInvoiced / data.totalQtyReceived) * 100} /></div>;
  // }

  // const getInboundDeliveryQuantity = (data) => {
  //     return <div onClick={() => {
  //         if(data.status === 'Pending Approval' || data.status ===  'Rejected') return;
  //         props.showModal(
  //             {
  //                 title: <Fragment>
  //                     <div className='ant-modal-title'>Inbound Delivery Information</div>
  //                     <div className="ant-modal-subtitle extra-sm">{data.supplierName}</div>
  //                 </Fragment>,
  //                 modalBody: <InboundDeliveryInformation poData={data} {...props} />,
  //                 width: 900,
  //                 hideFooter: true
  //             }
  //         );
  //     }}><Progress className={data.totalQtyOnId >= data.totalQtyOrdered ? "complete" : "incomplete"} format={() => {
  //         return `${data.totalQtyOnId}/${data.totalQtyOrdered}`
  //     }} percent={(data.totalQtyOnId / data.totalQtyOrdered) * 100} /></div>;
  // }

  // const saveColumnIndex = (columnState) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data);
  // }

  // const saveColumnWidth = (columnState, width) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data, props);
  // }

  // let moveEvent = {};
  // // const onColumnMoved = (event, isDragStopped) => {
  // //     if (event.column) {
  // //         moveEvent = event;
  // //     }
  // //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
  // //         saveColumnIndex(moveEvent.columnApi.getColumnState());
  // //     }
  // // }

  // // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  // //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  // //     event.api.resetRowHeights();
  // //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
  // //         //event.api.resetRowHeights();
  // //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
  // //             props.gridApi.sizeColumnsToFit();
  // //             setTimeout(() => {
  // //                 const columnArray = event.columnApi.getColumnState();
  // //                 saveColumnWidth(columnArray);
  // //             }, 1000);
  // //         } else {
  // //             saveColumnWidth(event.columnApi.getColumnState());
  // //         }
  // //     }
  // // }

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  // const openAuditDrawer = (props, data) => {
  //     props.updateState({
  //         title: <div className="drawer-title">
  //             Audit Trail
  //         </div>,
  //         auditDrawerVisible: true,
  //         masterKey: 'inboundDeliveryMasterId',
  //         masterKeyValue: data.inboundDeliveryMasterId,
  //         apiUrl: 'saveAuditTrailInboundDelivery',
  //         supplierId: data.supplierId,
  //         trailList: data.auditTrailPurchaseOrderList || [],
  //     });
  // }

  // const getActionMenuPermissionData = () => {
  //     const primaryPerm = permissions.primary || [];
  //     const permittedModules = {
  //         firstSection: {
  //             updateInboundDelivery: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_ORDER, PERMISSION_VALUES.UPDATE),
  //             cloneInboundDelivery: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_ORDER, PERMISSION_VALUES.CREATE)
  //         }
  //     };
  //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
  //         if (permittedModules.firstSection[moduleName] === false) {
  //             delete permittedModules.firstSection[moduleName];
  //         }
  //     })
  //     return permittedModules;
  // }

  // const addItemConfirmationModal = (props, text, txData) => {
  //     const { showModal, companyInfo } = props;
  //     const labelName = <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />;
  //     const modalBody = <span>
  //         <FormattedMessage
  //             id='setItem.confirmation'
  //             defaultMessage={``}
  //         />
  //         {labelName} ?
  //   </span>;

  //     let payload = {
  //         userStatus: text,
  //         relationshipId: companyInfo.relationshipId,
  //         inboundDeliveryMasterId: txData.inboundDeliveryMasterId,
  //         supplierId: txData.supplierId
  //     };

  //     const modalData = {
  //         modalBody,
  //         handleSubmit: () => {
  //             props.updateUserStatus(payload, props);
  //             props.hideModal();
  //             props.popModalFromStack();
  //         },
  //     };
  //     showModal(modalData);
  // }

  // const updateUserStatus = (props, txData) => {
  //     let payload = { formData: {} };
  //     const formData = payload.formData;
  //     const { pushModalToStack } = props;

  //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
  //         addItemConfirmationModal(props, formData.textBoxValue, txData);
  //     } else {
  //         const data = {
  //             title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
  //             formData,
  //             hideFooter: true,
  //             modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY },
  //             handleSubmit: (formData = {}) => {
  //                 if (formData.textBoxValue && formData.textBoxValue.length) {
  //                     addItemConfirmationModal(props, formData.textBoxValue, txData);
  //                 } else {
  //                     data.formData.submittedOnce = true;
  //                     pushModalToStack(data);
  //                 }
  //             }
  //         };
  //         pushModalToStack(data);
  //     }
  // }

  // const actionMenu = (param) => (
  //     <Menu className="cell-action-dropdown">
  //         {/* <Menu.Item key="8" onClick={() => {
  //             openAuditDrawer(props, param.data);
  //         }}>
  //             <i className="fa fa-history" />
  //                  View Audit Trail
  //                 </Menu.Item> */}
  //         {
  //             permittedModules.firstSection.updateInboundDelivery
  //                 ? (
  //                     <Menu.Item key="0">
  //                         <i className="fa fa-pencil" />
  //                         <Link
  //                             to={
  //                                 {
  //                                     pathname: '/admin/inbound-delivery-update',
  //                                     state: {
  //                                         supplierId: param.data.supplierId,
  //                                         inboundDeliveryMasterId: param.data.inboundDeliveryMasterId,
  //                                         update: true
  //                                     }
  //                                 }}
  //                         >Update Inbound Delivery</Link>
  //                     </Menu.Item>
  //                 )
  //                 : <Fragment />
  //         }

  //         {/* {
  //             permittedModules.firstSection.cloneInboundDelivery
  //                 ? (
  //                     <Menu.Item key="1">
  //                         <i className="fa fa-clone" />
  //                         <Link
  //                             to={
  //                                 {
  //                                     pathname: '/admin/inbound-delivery-create',
  //                                     state: {
  //                                         supplierId: param.data.supplierId,
  //                                         inboundDeliveryMasterId: param.data.inboundDeliveryMasterId,
  //                                         clone: true
  //                                     }
  //                                 }}
  //                         >Clone Inbound Delivery</Link>
  //                     </Menu.Item>
  //                 )
  //                 : <Fragment />
  //         } */}
  //         <Menu.Item key="2" onClick={() => {
  //                 updateUserStatus(props, param.data);
  //             }}>
  //                 <i className="fa fa-pencil" />
  //                  Update User Status
  //                 </Menu.Item>
  //     </Menu>
  // );
  //const permittedModules = getActionMenuPermissionData();

  // const columnDefs = [
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseinvoice.form.idNumber.label' defaultMessage='' />;
  //         },
  //         colId: 'inboundDeliveryNumber_1',
  //         field: "inboundDeliveryNumber",
  //         hide: _isColumnToHide('inboundDeliveryNumber'),
  //         suppressMovable: true,
  //         minWidth: 150,
  //         maxWidth: 250,
  //         rowDrag: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openIDDetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.poNo' defaultMessage='' />;
  //         },
  //         colId: 'poNumber_1',
  //         field: "poNumber",
  //         hide: _isColumnToHide('poNumber'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 250,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openPODetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.rfqNumber' defaultMessage='RFQ #' />;
  //         },
  //         field: "customerRFQNumber",
  //         colId: "customerRFQNumber_1",
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 300,
  //         hide: _isColumnToHide('customerRFQNumber'),
  //         //width: (props.txColumnSetting['rfqNumber'] || {}).width,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             // openRFQDetails(link.data)
  //         }}>{link.value}</div>,
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.supplier' defaultMessage='' />;
  //         },
  //         field: "supplierName",
  //         colId: "supplierName_1",
  //         hide: _isColumnToHide('supplierName'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         resizable: true,
  //         cellRendererFramework: (link) =>  <div className="cursor-pointer" >
  //         <div onClick={() => {
  //             props.history.push('suppliers/details', {
  //                 supplierId: link.data.supplierId,
  //                 supplierName: link.data.supplierStoreName,
  //                 // supplier: data
  //             })
  //         }}>{link.value}</div>
  //     </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.refNumber' defaultMessage='' />;
  //         },
  //         field: "reference",
  //         colId: "reference_1",
  //         hide: _isColumnToHide('reference'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 300,
  //         resizable: true,
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='supplier.purchaseOrder.listing.header.projectName' defaultMessage='' />;
  //     //     },
  //     //     field: "projectName",
  //     //     colId: "projectName_1",
  //     //     suppressMovable: true,
  //     //     minWidth: 70,
  //     //     maxWidth: 300,
  //     //     hide: _isColumnToHide('projectName'),
  //     //     //width: (props.txColumnSetting['projectName'] || {}).width,
  //     //     resizable: true,
  //     //     cellRendererFramework: (link) => <div>
  //     //         {link.data.projectNumber ? link.data.projectNumber + ' (' + link.data.projectName + ')' : ''}
  //     //     </div>,
  //     // },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='supplier.purchaseOrder.listing.header.rfqConversion' defaultMessage='Is RFQ Cconversion' />;
  //     //     },
  //     //     field: "isRFQCconversion",
  //     //     colId: "isRFQCconversion_1",
  //     //     suppressMovable: true,
  //     //     minWidth: 70,
  //     //     maxWidth: 300,
  //     //     cellRendererFramework: (params) =>{
  //     //         return params.node.data.isRFQCconversion ? <img src={checkIcon}  style={{ 'height': '15px', 'width': '15px' }} alt="Invoice"/> : <img src={crossIcon}  style={{ 'height': '10px', 'width': '10px' }} alt="Invoice"/>;
  //     //     },
  //     //     hide: _isColumnToHide('isRFQCconversion'),
  //     //     //width: (props.txColumnSetting['isRFQCconversion'] || {}).width,
  //     //     resizable: true,
  //     // },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.date' defaultMessage='' />;
  //         },
  //         field: "inboundDeliveryDate",
  //         colId: "inboundDeliveryDate_1",
  //         suppressMovable: true,
  //         minWidth: 70,
  //         hide: _isColumnToHide('inboundDeliveryDate'),
  //         //width: (props.txColumnSetting['poDate'] || {}).width,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div>
  //             <Tooltip placement="topLeft" title={(link.data.inboundDeliveryDate && getMomentDateForUIReadOnly({date: link.data.inboundDeliveryDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
  //             {getMomentDateForUIReadOnly({date: link.data.inboundDeliveryDate, format})}
  //             </Tooltip>
  //         </div>,
  //     },

  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='supplier.purchaseOrder.listing.header.statusInvoice' defaultMessage='Invoice Status' />;
  //     //     },
  //     //     cellRendererFramework: (params) => {
  //     //         return <div> <span className={["status-code", (params.value || '').toLowerCase()].join(' ')}>{params.value}</span> </div>
  //     //     },
  //     //     field: "statusInvoice",
  //     //     colId: "statusInvoice_1",
  //     //     hide: _isColumnToHide('statusInvoice'),
  //     //     suppressMovable: true,
  //     //     minWidth: 70,
  //     //     maxWidth: 300,
  //     //     //width: (props.txColumnSetting['status'] || {}).width,
  //     //     resizable: true,
  //     //     cellClass: 'status-cell'
  //     // },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.qtyInvoiced' defaultMessage='' />;
  //         },
  //         cellRendererFramework: function (param) {
  //             return getRecievedQuantity(param.data);
  //         },
  //         field: "totalQtyReceived",
  //         colId: "totalQtyReceived_1",
  //         hide: _isColumnToHide('totalQtyReceived'),
  //         suppressMovable: true,
  //         minWidth: 70,
  //         //width: (props.txColumnSetting['totalQtyReceived'] || {}).width,
  //         resizable: true,
  //         cellClass: 'progressbar-cell'
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='supplier.purchaseOrder.listing.header.prNumber' defaultMessage='PR #' />;
  //     //     },
  //     //     field: "purchaseRequestNumber",
  //     //     colId: "purchaseRequestNumber_1",
  //     //     hide: _isColumnToHide('purchaseRequestNumber'),
  //     //     suppressMovable: true,
  //     //     minWidth: 70,
  //     //     maxWidth: 300,
  //     //     //width: (props.txColumnSetting['rfqNumber'] || {}).width,
  //     //     cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //     //         openRFQDetails(link.data)
  //     //     }}>{link.value || ''}</div>,
  //     //     resizable: true
  //     // },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='supplier.purchaseOrder.listing.header.InquiryNumber' defaultMessage='Inquiry #' />;
  //     //     },
  //     //     field: "inquiryNumber",
  //     //     colId: "inquiryNumber_1",
  //     //     hide: _isColumnToHide('inquiryNumber'),
  //     //     suppressMovable: true,
  //     //     minWidth: 70,
  //     //     maxWidth: 300,
  //     //     //width: (props.txColumnSetting['rfqNumber'] || {}).width,
  //     //     cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //     //         openRFQDetails(link.data)
  //     //     }}>{link.value || ''}</div>,
  //     //     resizable: true
  //     // },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='supplier.purchaseOrder.listing.header.soNumber' defaultMessage='SO #' />;
  //     //     },
  //     //     field: "soNumber",
  //     //     colId: "soNumber_1",
  //     //     hide: _isColumnToHide('soNumber'),
  //     //     suppressMovable: true,
  //     //     minWidth: 70,
  //     //     maxWidth: 300,
  //     //     //width: (props.txColumnSetting['rfqNumber'] || {}).width,
  //     //     cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //     //         openRFQDetails(link.data)
  //     //     }}>{link.value || ''}</div>,
  //     //     resizable: true
  //     // },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.status' defaultMessage='' />;
  //         },
  //         field: "userStatus",
  //         hide: _isColumnToHide('userStatus'),
  //         colId: "userStatus_1",
  //         suppressMovable: true,
  //         minWidth: 70,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.value? <span className="status-code" style={{background: link.data.userStatusColor}}>{link.value || ''}</span> : ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.project' defaultMessage='' />;
  //         },
  //         field: "project",
  //         hide: _isColumnToHide('project'),
  //         colId: "project_1",
  //         minWidth: 70,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                  {link.data.projectMasterId ? <span>{link.data.projectMasterId + '-' +link.data.projectName }</span> : ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <div className="cursor-pointer">
  //     //             <Popover
  //     //                 content={<AddAndRemoveSupplierListingColumn {...props} />}
  //     //                 title={<div className="text-center">Add/Remove </div>}
  //     //                 placement="left"
  //     //                 trigger="click"
  //     //             >
  //     //                 <i className='fa fa-bars' />
  //     //             </Popover>
  //     //         </div>;
  //     //     },
  //     //     cellRendererFramework: (param) => {
  //     //         return <Dropdown overlay={actionMenu(param)} trigger={['click']}>
  //     //             <Button className="action-btn small">
  //     //                 <i className="fa fa-ellipsis-v" />
  //     //             </Button>
  //     //         </Dropdown>
  //     //     },
  //     //     field: "action",
  //     //     colId: "action_1",
  //     //     pinned: 'right',
  //     //     suppressMovable: true,
  //     //     minWidth: 52,
  //     //     maxWidth: 52,
  //     // }
  // ];

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  // const breadCrumbList = [
  //     {
  //         name: 'Supplier',
  //         link: 'suppliers'
  //     }, {
  //         name: 'Inbound Delivery',
  //     }, {
  //         name: 'List'
  //     }
  // ];

  // const onGridReady = (params) => {
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi
  //     });
  //     onDataRendered(params);
  // }

  // const onModelUpdated = (params) => {
  //     if ((inboundDeliveryList[pageNumber] || []).length && !props.isRowHeightSet) {
  //         setTimeout(() => {
  //             params.api.resetRowHeights();
  //         }, 1000)
  //         props.updateState({
  //             isRowHeightSet: true
  //         })
  //     }
  //     if (props.tableDataReRendered) {
  //         //params.api.resetRowHeights();
  //         props.updateState({
  //             tableDataReRendered: false
  //         })
  //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.ID_LISTING;

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         });
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     }
  // }

  // const onDataRendered = (params) => {
  //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //     if (Object.keys(txColumnSetting).length) {
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.ID_LISTING

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });
  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.width = 52;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     } else {
  //         params.api.sizeColumnsToFit();
  //     }
  // }

  const sortColumn = (e) => {
    sortTable(
      document.getElementById("grn-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };
  return (
    <Fragment>
      {/* <div className="invoice-header">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}

        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="purchase.grn.table.heading"
                defaultMessage=""
              />
            </div>
           
                <div className="vertical-sep" />
                <div>{props.inboundDeliveryCount}</div>
              
          </div>
          <div className="right-actions">
            <button
              className="action-btn"
              onClick={() => {
                props.updateState({
                  inboundDeliveryFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined /> &nbsp;
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>
              <Popover
              content={<AddAndRemoveSupplierListingColumn {...props} />}
              title={
                <div className="text-center">
                  <FormattedMessage id="common.addRemove" defaultMessage="" />{" "}
                </div>
              }
              placement="leftTop"
              trigger="click"
              arrow="right"
              // visible={props.showColPop}
            >
              <button>
                <InsertRowRightOutlined className="mr5" /> Columns
              </button>
            </Popover>
          </div>
          </Skeleton>
        </div>
        {/* <Collapse activeKey={collapseActiveKey} onChange  = {()=>{
                    setCollapseActiveKey(collapseActiveKey ? '' : '1')
                }}>
                    <Panel header="Apply Filter" key="1"  style={{ width : '100%' }}>
                    <div className='qute-sect1'>
                        <Dropdown
                            className='drop-fileds1'
                            items={props.suppliers[pageNumber] || []}
                            valueKeyName='supplierStoreName'
                            optionKeyName='supplierId'
                            value={props.selectedSupplierName}
                            onSearch={(searchedText) => {
                                props.fetchSuppliers({
                                    isCompact: true,
                                    searchedText: searchedText,
                                    pageNumber: 1, 
                                    hideSpinner: true,
                                    pageSize: 100,
                                    relationshipId: (props.companyInfo || {}).relationshipId
                                })
                            }}
                            placeholder={props.intl.formatMessage({
                                id: 'supplier.filter.name.placeholder', defaultMessage: ''
                            })}

                            onSelect={
                                (supplierId, option) => {
                                    updateState({
                                        selectedSupplierId: option.value,
                                        selectedSupplierName: option.name,
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    selectedSupplierId: undefined,
                                    selectedSupplierName: undefined,
                                })
                            }}
                        />

                        <TextBox 
                            className='drop-fileds'
                            placeholder='GRN #'
                            type='text'
                            maxLength={20}
                            value={props.selectedGrnNum}
                            onChange={(e) => {
                                props.updateState({ selectedGrnNum : e.target.value });
                            }}
                        />
                        <RangePicker                            
                            allowEmpty={true}
                            format={format}
                            placeholder={['GRN start date', 'GRN end date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        />
                        <TextBox 
                            placeholder='PO #'
                            type='text'
                            maxLength={20}
                            value={props.selectedPoNum}
                            onChange={(e) => {
                                props.updateState({ selectedPoNum : e.target.value });
                            }}
                        />
                        {/* <Dropdown
                            style={{ width: '160px'}}
                            items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partiallyFullfilled' }, { name: 'Fullfilled', value: 'fullFilled' }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={props.selectedStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                            onSelect={(status, option) => {
                                updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                            }}
                        /> */}

        {/* <Dropdown
                            items={props.userStatus || []}
                            valueKeyName='statusName'
                            optionKeyName='inboundDeliveryStatusId'
                            statusColorKey='statusColor'
                            value={props.selectedUserStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.status' })}
                            onSelect={(selectedValue, option) => {
                                updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                            }}
                        />
                        <div className='cler'></div>
                         </div>
                         <div className='cler'></div>
                        <div className='qute-sect1'>
                        <Dropdown
                            className='drop-fileds'
                            items={[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={props.selectedIsInvoiced}
                            placeholder='Is Invoiced?'
                            onSelect={(status, option) => {
                                updateState({ selectedIsInvoiced: option.name, selectedIsInvoicedValue: option.value  })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedIsInvoiced: 'Yes', selectedIsInvoicedValue: 1 })
                            }}
                        />

                        <TextBox 
                            className='drop-fileds'
                            placeholder={props.intl.formatMessage({
                                id: 'supplier.filter.refNum.placeholder', defaultMessage: ''
                            })}
                            type='text'
                            maxLength={20}
                            value={props.selectedRefNum}
                            onChange={(e) => {
                                props.updateState({ selectedRefNum: e.target.value });
                            }}
                        />
                       
                        <Dropdown
                            className='drop-fileds2'
                            items={props.projectList}
                            valueKeyName='projectName'
                            optionKeyName='projectMasterId'
                            projectIdKey='projectMasterId'
                            value={props.selectedProjectName}
                            placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                            }}
                        />
                        <div className='cler'></div>
</div> 
<div className='cler'></div>
<div className='qute-sect1'> */}
        {/* <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            if(!props.selectedSupplierName && !props.selectedGrnNum && !props.selectedPoNum && !props.startDate && !props.endDate && !props.selectedUserStatus && !props.selectedIsInvoiced && !props.selectedRefNum && !props.selectedProjectMasterId ){
                                return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                            }
                            const payload = {
                                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                filters: {
                                    supplierId: props.selectedSupplierId,
                                    grnNumber: props.selectedGrnNum,
                                    startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                                    endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                                    poNumber: props.selectedPoNum,
                                    userStatus: props.selectedUserStatusName,
                                    isInvoiced: props.selectedIsInvoicedValue,
                                    reference: props.selectedRefNum,
                                    projectMasterId: props.selectedProjectMasterId,
                                }
                            };
                            props.fetchAllInboundDeliveriesByFilter(payload);
                            props.getInboundDeliveriesCountByFilter(payload);

                        }}>
                            {<FilterOutlined />} Apply Filter
                        </button> */}
        {/* <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            props.updateState({
                                selectedSupplierName: null, selectedSupplierId: null,
                                selectedGrnNum: null,
                                selectedPoNum: null,
                                startDate:null, endDate:null,
                                selectedUserStatus: null, selectedUserStatusName: null,
                                selectedIsInvoicedValue: null, selectedIsInvoiced: null,
                                selectedRefNum: null,
                                selectedProjectMasterId: null, selectedProjectName: null
                            });
                            const payload = {
                                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                filters: {}
                            };
                            props.fetchAllInboundDeliveries(payload);
                            props.getInboundDeliveriesCount(payload);
                            }}>
                                {<UndoOutlined />} Clear Filter
                        </button>
                        </div>
                    </Panel>
                </Collapse> 
                 */}

        {/* <div className='agGridWrapper'>
                    {props.txColumns.length ?  <div className="ag-theme-balham" style={agGridStyle} >
                        <div className="cursor-pointer">
                                <span className="top-button">
                                    <Popover
                                        content={<AddAndRemoveSupplierListingColumn {...props} />}
                                        title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' />  </div>}
                                        placement="left"
                                        trigger="click"
                                    >
                                        <i className='fa fa-bars' />
                                    </Popover>
                                </span>
                                <div className="clearfix"></div>
                            </div>
                             {/* <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={inboundDeliveryList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                                onModelUpdated={onModelUpdated}
                                onColumnResized={onColumnResized}
                                onDragStopped={() => {
                                    onColumnMoved({}, true);
                                }}
                                //onColumnMoved={onColumnMoved}
                            >
                            </AgGridReact> */}
        {/* </div> : null }
                    </div> */}
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 13 }}>

        <div className="table-container">
          <table id="grn-table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="serial.no" />
                </th>
                {!_isColumnToHide("inboundDeliveryNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseinvoice.form.idNumber.label"
                      defaultMessage=""
                    />{" "}
                    <i class="fi fi-rr-sort-alt"></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("poNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseOrder.listing.header.poNo"
                      defaultMessage=""
                    />{" "}
                    <i class="fi fi-rr-sort-alt"></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerRFQNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseOrder.listing.header.rfqNumber"
                      defaultMessage=""
                    />{" "}
                    <i class="fi fi-rr-sort-alt"></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("supplierName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseOrder.listing.header.supplier"
                      defaultMessage=""
                    />{" "}
                    <i class="fi fi-rr-sort-alt"></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("reference") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseOrder.listing.header.refNumber"
                      defaultMessage=""
                    />{" "}
                    <i class="fi fi-rr-sort-alt"></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("inboundDeliveryDate") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseOrder.listing.header.date"
                      defaultMessage=""
                    />{" "}
                    <i class="fi fi-rr-sort-alt"></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQtyReceived") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="supplier.purchaseOrder.listing.header.qtyInvoiced"
                      defaultMessage=""
                    />{" "}
                    <i class="fi fi-rr-sort-alt"></i>
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("userStatus") ? (
                  <th onClick={sortColumn} className="text-center">
                    <FormattedMessage id="common.status" defaultMessage="" />{" "}
                    <i class="fi fi-rr-sort-alt"></i>
                  </th>
                ) : (
                  ""
                )}
               
                <th onClick={sortColumn} className="text-center">
                  User Status{" "}
                  <i class="fi fi-rr-sort-alt"></i>
                </th>
               
                {!_isColumnToHide("project") ? (
                  <th onClick={sortColumn} className="text-center">
                    <FormattedMessage id="common.project" defaultMessage="" />{" "}
                    <i class="fi fi-rr-sort-alt"></i>
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {(inboundDeliveryList[pageNumber] || []).length ?
                inboundDeliveryList[pageNumber].map((rowData, index) => {
                  return (
                    <tr key={index}>
                      <td>{(pageNumber - 1) * props.pageSize + (index + 1)}</td>

                      {/* <td><span>{Number(((pageNumber-1) * props.pageSize) + index + 1)}</span></td> */}
                      {!_isColumnToHide("inboundDeliveryNumber") ? (
                        <td>
                          {" "}
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openIDDetails(rowData);
                            }}
                          >
                            {rowData.inboundDeliveryNumber}
                          </div>{" "}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("poNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openPODetails(rowData);
                            }}
                          >
                            {rowData.poNumber}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("customerRFQNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openRFQDetails(rowData);
                            }}
                          >
                            {rowData.customerRFQNumber ? rowData.customerRFQNumber : <div className="empty-data"></div>}
                          </div>{" "}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("supplierName") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              props.pushModalToStack({  
                               
                                modalBody: <SupplierDetails {...props}
                                supplierId= {rowData.supplierId}
                                supplierName={rowData.supplierStoreName}
                            />,
                            width: '100%',
                            hideTitle: true,
                            hideFooter: true,
                            wrapClassName: "new-transaction-wrapper",
                            })
                           }}
                            // onClick={() => {
                            //   props.history.push("suppliers/details", {
                            //     supplierId: rowData.supplierId,
                            //     supplierName: rowData.supplierStoreName,
                            //   });
                            // }}
                          >
                            {rowData.supplierName}
                          </div>{" "}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("reference") ? (
                        <td>{rowData.reference ? rowData.reference : <div className="empty-data"></div>} </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("inboundDeliveryDate") ? (
                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.inboundDeliveryDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.inboundDeliveryDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {getMomentDateForUIReadOnly({
                                date: rowData.inboundDeliveryDate,
                                format,
                              })}
                            </Tooltip>
                          </div>{" "}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("totalQtyReceived") ? (
                        <td>
                          <div className="">
                            {/* {(rowData.statusInvoice === 'Invoiced' || rowData.statusInvoice === 'invoiced')? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />} */}
                            {rowData.statusInvoice === "Invoiced" ||
                            rowData.statusInvoice === "invoiced"
                              ? "Yes"
                              : "No"}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("userStatus") ? (
                        <td className="text-center">
                          {" "}
                          <div>
                            {rowData.status ? (
                              <div
                                className="status-code"
                                style={{ background: rowData.statusColor }}
                              >
                                {rowData.status || ""}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      <td className="text-center">
                      {rowData.userStatus ?
                      <div
                                                        className="status-code cursor-pointer"
                                                        style={{
                                                          background: rowData.userStatus ? rowData.userStatusColor : 'transparent' 
                                                        }}
                                                      
                                                    >
                                                        {rowData.userStatus}
                      </div>
                      :
                      <div className="empty-data"></div>
                      }
                      </td>
                      {!_isColumnToHide("project") ? (
                        <td className="text-center">
                          <div>
                            {rowData.projectName ? (
                              <span>{rowData.projectName}</span>
                            ) : (
                              <div className="empty-data"></div>
                            )}
                          </div>{" "}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                }):
                <tr key="empty-data">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
            </tbody>
          </table>
        </div>
      </Skeleton>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={inboundDeliveryCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber, pageSize, props);
            }}
          />
        </div>
      </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(InboundDeliveryListingComp);
