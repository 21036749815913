import React, { Fragment, useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Pagination, Tooltip, Skeleton, Menu, Dropdown } from "antd";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, sortTable,getPermissionData} from "../../../utils";
import { CONSTANTS, ICONS,MODULE_CODED_VALUES } from '../../../static/constants';
// import checkIcon from '../../../assets/images/check.png';
import crossIcon from '../../../assets/images/cross.png';
import PageBreadcrumb from "../../PageBreadcrumb";
import { MoreOutlined, SortAscendingOutlined, SortDescendingOutlined, RedoOutlined} from '@ant-design/icons';
import PageHeader from "../../Common/pageHeader";


const Pledges = (props) => {
  const { pledgeList, updateState, companyInfo, pledgeListCount, pageNumber, pageSize,permissions, dir=0 } = props;
  const primaryPerm = (props.permissions && props.permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.FUNDRAISING )
  },[])
  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.fundraising' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.pledges' defaultMessage='' />,
    }
  ];

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getPledge", "pledgeList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getPledge(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const deleteHandle = (pledge) => {
    const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deletePledge(pledge, props);
      },
    };
    props.showModal(modalData);
  }

  // const columnDefs = [
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="fundraising.pledge.listing.header.donar"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "donorFullName",
  //     cellRendererFramework: (params) => {
  //       return <div><span>{params.data.firstName || ''}</span>  <span>{params.data.lastName || ''}</span></div>;
  //     },
  //     resizable: true,
  //     minWidth: 110,
  //     maxWidth: 400,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage id='signup.phoneNumber.text' defaultMessage='' />
  //       );
  //     },
  //     field: "phoneNumber",
  //     cellRendererFramework: (params) => {
  //       return <div><span>{params.data.cellPhone || ''}</span> </div>;
  //     },
  //     resizable: true,
  //     minWidth: 110,
  //     maxWidth: 400,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage id='email' defaultMessage='' />
  //       );
  //     },
  //     field: "emailAddress",
  //     cellRendererFramework: (params) => {
  //       return <div><span>{params.data.emailAddress || ''}</span> </div>;
  //     },
  //     resizable: true,
  //     minWidth: 110,
  //     maxWidth: 400,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="fundraising.pledge.listing.header.amount"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     cellRendererFramework: (link) => {
  //       return link.value && link.value !== '-' ? <span> {(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
  //     },
  //     field: "amount",
  //     minWidth: 70,
  //     maxWidth: 300,
  //     suppressMovable: true,
  //     type: 'rightAligned'
  //   },
  //   // {
  //   //   headerComponentFramework: () => {
  //   //     return (
  //   //       <div>
  //   //         <FormattedMessage
  //   //           id="fundraising.pledge.listing.header.startDate"
  //   //           defaultMessage=""
  //   //         />{" "}
  //   //       </div>
  //   //     );
  //   //   },
  //   //   cellRendererFramework: (link) => {
  //   //     return <div>
  //   //       <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
  //   //         {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
  //   //       </Tooltip>
  //   //     </div>
  //   //   },
  //   //   field: "startDate",
  //   //   resizable: true,
  //   //   minWidth: 110,
  //   //   maxWidth: 300,
  //   // },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="fundraising.pledge.listing.header.status"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     // cellRendererFramework: (link) => {
  //     //   return link.value ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
  //     // },
  //     field: "status",
  //     resizable: true,
  //     minWidth: 124,
  //     maxWidth: 300,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="fundraising.pledge.listing.header.campaign"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "campaignName",
  //     minWidth: 70,
  //     maxWidth: 300,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="fundraising.campaign.addDrawer.field.campaignCode"
  //         />
  //       );
  //     },
  //     field: "campaignCode",
  //     //resizable: true,
  //     minWidth: 70,
  //     maxWidth: 250,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="fundraising.pledge.listing.header.recurring"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     cellRendererFramework: (link) => {
  //       return link.value ?
  //         // <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
  //         <div>{`${link.data.totalOccurrences} ${link.data.isDaily ? 'daily' : link.data.isWeekly ? 'weekly' : link.data.isMonthly ? 'monthly' : 'yearly'} Payments starting from ${getMomentDateForUIReadOnly({ date: link.data.startDate, format: CONSTANTS.DISPLAY_DATE_FORMAT })}`}</div> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />
  //     },
  //     field: "isRecurring",
  //     minWidth: 122,
  //     maxWidth: 300,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="paymentType.text"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     // cellRendererFramework: (link) => {
  //     //   return link.value ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;

  //     // },
  //     field: "paymentMode",
  //     minWidth: 122,
  //     maxWidth: 300,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='common.dateCreated' defaultMessage='' />;
  //     },
  //     field: "dateCreated",
  //     minWidth: 122,
  //     maxWidth: 300,
  //     suppressMovable: true,
  //     // hide: _isColumnToHide('salesOrderDate'),
  //     colId: "salesOrderDate_1",
  //     cellRendererFramework: (link) => {
  //       return <div>
  //         <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
  //           {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
  //         </Tooltip>
  //       </div>
  //     },
  //     resizable: true
  //   },
  //   {
  //     field: "action",
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="common.listing.header.action"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     colId: "action_1",
  //     pinned: "right",
  //     lockPosition: true,
  //     cellRendererFramework: (params) => {
  //       return <div>
  //         <span className="cursor-pointer" title={props.intl.formatMessage(
  //           {
  //             id: 'commons.listing.edit.tooltip.msg',
  //             defaultMessage: ''
  //           })
  //         } onClick={
  //           () => {
  //             props.updateHeaderState({
  //               fundraising: {
  //                 ...props.fundraising,
  //                 pledgeRowData: { ...params.data },
  //                 addPledgeDrawerVisible: true,
  //                 openPledgeDrawerFromListing: true,
  //               }
  //             })
  //           }}>
  //           <i className='fa fa-pencil' />
  //         </span>

  //         <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage(
  //           {
  //             id: 'commons.listing.delete.tooltip.msg',
  //             defaultMessage: ''
  //           })
  //         } onClick={
  //           () => {
  //             deleteHandle(params.data);
  //           }}>
  //           <i className='fa fa-trash' />
  //         </span>

  //       </div>;
  //     },
  //     minWidth: 70,
  //     maxWidth: 70,
  //     suppressMovable: true,
  //   }
  // ];

  const openAddPledgeDrawer = () => {
    props.updateHeaderState({
      fundraising: {
        ...props.fundraising,
        addPledgeDrawerVisible: true,
        openPledgeDrawerFromListing: true,
      },
    });
  };

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="pledgeRemainder" onClick={()=>{
              let payload;
              props.updateHeaderState({
                emailDrawerVisible: true,
                txData: payload,
                type: 'Pledge Reminder',
              });
            }}
          >
            <RedoOutlined />
            Pleade Remainder
          </Menu.Item>

        {permissionsData.update ?  <Menu.Item key="2" value="edit" onClick={()=>{
              props.updateHeaderState({
                fundraising: {
                  ...props.fundraising,
                  pledgeRowData: { ...e },
                  addPledgeDrawerVisible: true,
                  openPledgeDrawerFromListing: true,
                }
              })
            }}
          >
            <i className={ICONS.EDIT}/>
            Edit
          </Menu.Item>: "" }

          {permissionsData.delete ? <Menu.Item key="3" onClick={()=>
            {
              deleteHandle(e);
            }
          }>
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>: ""}

        </Menu >
    )
  };

  const sortColumn = (e) => {
    sortTable(document.getElementById('pledge-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }


  return (
    <Fragment>
      <PageHeader {...props}
        pageName="heading.fundRaising.pledge"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openAddPledgeDrawer}
        moduleCode={MODULE_CODED_VALUES.FUNDRAISING}
      />
      <div className="view-container">
      <Skeleton loading={props.isLoading}>
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='fundraising.pledge.table.heading' defaultMessage='' />
              </div>
              {
                pageSize ? <>
                  <div className="vertical-sep" />
                  <div>{pageSize}</div>
                </>:''
              }
            </div>
            <div className="right-actions">
            </div>
          </div>
          <div className="table-container">
            <table id='pledge-table'>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th onClick={sortColumn}><FormattedMessage id="fundraising.pledge.listing.header.donar" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id='signup.phoneNumber.text' defaultMessage='' /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id='email' defaultMessage='' /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id="fundraising.pledge.listing.header.amount" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id="company.companySetup.ledgerAccounts.paymentRecievedLedger.title" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id="fundraising.pledge.listing.header.status" defaultMessage="" />
                  <Tooltip placement="top" title={props.intl.formatMessage({ id: 'pladgeStatus.helpMessage' })} trigger="click">
                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                  </Tooltip>  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th width={'12%'} onClick={sortColumn}> <FormattedMessage id="fundraising.pledge.listing.header.campaign" defaultMessage="" /> 
                  <Tooltip placement="top" title={props.intl.formatMessage({ id: 'campaignName.helpMessag' })} trigger="click">
                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                  </Tooltip>  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}> <FormattedMessage id="donorRequest.text" /><Tooltip placement="top" title={props.intl.formatMessage({ id: 'donorRequest.helpMessage' })} trigger="click">
                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                  </Tooltip>  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}> <FormattedMessage id="fundraising.pledge.listing.header.recurring" defaultMessage="" />   {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}> <FormattedMessage id="paymentType.text" defaultMessage="" />  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}> <FormattedMessage id='common.dateCreated' defaultMessage='' />   {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}> <FormattedMessage id="source.text" defaultMessage="" />  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                {permissionsData.update || permissionsData.delete ?  <th width={'5%'}> <FormattedMessage id="common.listing.header.action" defaultMessage="" /> </th> : "" }
                </tr>
              </thead>
              <tbody>
                {(pledgeList[pageNumber] || []).map((e, i) => {
                  return <tr key={i}>
                   <td>{(pageNumber-1)*pageSize+(i+1)}</td>
                    <td> {e.firstName || ''} {' '}  {e.lastName || ''} </td>
                    <td>{e.cellPhone || ''}</td>
                    <td> {e.emailAddress || ''}</td>
                    <td style={{textAlign: 'end'}}>{e.amount && e.amount !== '-' ? <span> {(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{e.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"}</td>
                    <td style={{textAlign: 'end'}}>{e.totalPaymentReceived && e.totalPaymentReceived !== '-' ? <span> {(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{e.totalPaymentReceived.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"}</td>
                    <td><div className={["status-code", (e.status || "").toLowerCase()].join(' ')}> {e.status || ''}</div></td>
                    <td>{e.campaignCode ? e.campaignCode+ '-' : ''}{e.campaignName}</td>
                    <td>{e.donorRequest}</td>
                    <td> {e.isRecurring ?
                      `${e.totalOccurrences} ${e.isDaily ? 'daily' : e.isWeekly ? 'weekly' : e.isMonthly ? 'monthly' : 'yearly'} Payments starting from ${getMomentDateForUIReadOnly({ date: e.startDate, format: CONSTANTS.DISPLAY_DATE_FORMAT })}` : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />
                    }</td>
                    <td>{e.paymentMode}</td>
                    <td>
                      <div>
                        <Tooltip placement="topLeft" title={(e.dateCreated && getMomentDateForUIReadOnly({ date: e.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                          {(e.dateCreated && getMomentDateForUIReadOnly({ date: e.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                        </Tooltip>
                      </div>
                    </td>
                    <td>{e.source}</td>
                    
                      {/* <div>
                      <span className="cursor-pointer" title={props.intl.formatMessage({ id: 'pledgeReminder.text'})} onClick={
                          () => {
                            let payload;
                            props.updateHeaderState({
                              emailDrawerVisible: true,
                              txData: payload,
                              type: 'Pledge Reminder',
                            });
                          }}>
                          <i className='fa fa-envelope' />
                        </span>

                        <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage(
                          {
                            id: 'commons.listing.edit.tooltip.msg',
                            defaultMessage: ''
                          })
                        } onClick={
                          () => {
                            props.updateHeaderState({
                              fundraising: {
                                ...props.fundraising,
                                pledgeRowData: { ...e },
                                addPledgeDrawerVisible: true,
                                openPledgeDrawerFromListing: true,
                              }
                            })
                          }}>
                          <i className='fa fa-pencil' />
                        </span>

                        <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage(
                          {
                            id: 'commons.listing.delete.tooltip.msg',
                            defaultMessage: ''
                          })
                        } onClick={
                          () => {
                            deleteHandle(e);
                          }}>
                          <i className='fa fa-trash' />
                        </span>

                      </div> */}

                    {permissionsData.update || permissionsData.delete ? <td style={{ paddingLeft: '50px' }}>
                      <div className="action-icon">
                        <Dropdown overlay={actionMenu(e)} trigger={['click']}>
                          <MoreOutlined />
                        </Dropdown>
                      </div>
                    </td> : ""}
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={pledgeListCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
        {/* <div className='team-and-free'>
          <div className="agGridWrapper">
          <div className="ag-theme-balham" style={agGridStyle}>
            <AgGridReact
              // onGridReady={onGridReady}
              columnDefs={columnDefs}
              defaultColDef={{
                flex: 1,
                autoHeight: true,
                wrapText: true,
              }}
              rowData={pledgeList[pageNumber] || []}
              rowDragManaged={true}
              domLayout={"autoHeight"}
              //getRowHeight={getRowHeight}
              suppressDragLeaveHidesColumns={true}
              animateRows={true}
            // onModelUpdated={onModelUpdated}
            // onColumnResized={onColumnResized}
            // onColumnVisible={(params) => {
            //   params.api.resetRowHeights();
            // }}
            // onDragStopped={() => {
            //   onColumnMoved({}, true);
            // }}
            //onColumnMoved={onColumnMoved}
            ></AgGridReact>
          </div>
        </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default injectIntl(Pledges);
