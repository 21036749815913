import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QualityCheckComponent from '../../../../../components/modal/modalBody/settings/QualityCheck';
import { pushModalToStack, getQualityChecklistSettings } from '../../../../../actions/commonActions';
import { fetchDataIfNeeded } from '../../../../../utils';
import { updateQualityChecklistSettings } from './action';
class QualityCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabDocumentOptions: [
                'Quality Checklist Required',
                'Generate Shipment Without Quility Check'
            ]
        }
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId};
        fetchDataIfNeeded('getQualityChecklistSettings', 'qualityChecklistSetting', this.props, payload);
    }

    render() {
        return <QualityCheckComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        qualityChecklistSetting: state.settings.qualityChecklistSetting,
        permissions: state.auth.permissions,
        detailLoading: state.common.detailLoading

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateQualityChecklistSettings, 
    getQualityChecklistSettings,
    pushModalToStack 
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QualityCheck);