import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { JOURNAL_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchJournalDetail = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/journalEntryMaster/getJournalMasterByMasterId?journalsEntryMasterId=${payload.journalId}&relationshipId=${payload.relationshipId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: JOURNAL_ACTION_LIST.JOURNAL_DETAILS_DATA, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
            }else{
              err.actionToCall = JOURNAL_ACTION_LIST.JOURNAL_DETAILS_DATA;
            }
          })
      }
  }