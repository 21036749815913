import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { Link } from "react-router-dom";
// import { AgGridReact } from "ag-grid-react";
import { Skeleton, Pagination, Tooltip, Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  getMomentDateForUIReadOnly,
} from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./index.scss";
import SODetail from "../../../../containers/modal/modalBody/customer/SODetail";
import PackageDetail from "../../../../containers/modal/modalBody/customer/PackageDetail";
import { CONSTANTS } from "../../../../static/constants";
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'

// import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
// import { AG_GRID_CONSTANTS } from "../../../../static/agGridConstants";
// import * as filter from "lodash.filter";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const SOPackageListingComp = (props) => {
  const { packageList, updateState, companyInfo, packageCount, pageNumber } =
    props;

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchAllPackage",
      "packageList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchAllPackage(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
  };

  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // }

  const openPackageDetails = (data) => {
    const packagePayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId,
      soPackageMasterId: data.soPackageMasterId,
    };
    props.pushModalToStack({
      modalBody: <PackageDetail {...props} packagePayload={packagePayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId,
    };
    props.pushModalToStack({
      modalBody: <SODetail {...props} soPayload={soPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  // const saveColumnIndex = (columnState) => {
  //   let data = props.txPreferences || {};
  //   let tablePreference = {};
  //   data.relationshipId = (props.companyInfo || {}).relationshipId;
  //   data.clientUserAccountId = (props.userInfo || {}).userId;
  //   if (data.tablePreference) {
  //     tablePreference = JSON.parse(data.tablePreference);
  //   }
  //   tablePreference.columnSetting = columnState;
  //   tablePreference.columnList = props.txColumns;
  //   data.tablePreference = JSON.stringify(tablePreference);
  //   data.preferenceId = props.preferenceId;
  //   data.gridPreference = props.gridPreference;
  //   props.saveUserPrefrences(data);
  // };

  // const saveColumnWidth = (columnState, width) => {
  //   let data = props.txPreferences || {};
  //   let tablePreference = {};
  //   data.relationshipId = (props.companyInfo || {}).relationshipId;
  //   data.clientUserAccountId = (props.userInfo || {}).userId;
  //   if (data.tablePreference) {
  //     tablePreference = JSON.parse(data.tablePreference);
  //   }
  //   tablePreference.columnSetting = columnState;
  //   tablePreference.columnList = props.txColumns;
  //   data.tablePreference = JSON.stringify(tablePreference);
  //   data.preferenceId = props.preferenceId;
  //   data.gridPreference = props.gridPreference;
  //   props.saveUserPrefrences(data, props);
  // };

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //   if (event.column) {
  //     moveEvent = event;
  //   }
  //   if (
  //     isDragStopped &&
  //     moveEvent.column &&
  //     moveEvent.column.colDef &&
  //     moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API
  //   ) {
  //     saveColumnIndex(moveEvent.columnApi.getColumnState());
  //   }
  // };

  // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //   const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //   event.api.resetRowHeights();
  //   if (
  //     event.type === "columnResized" &&
  //     event.source !== "sizeColumnsToFit" &&
  //     event.source !== "api" &&
  //     event.finished === true
  //   ) {
  //     if (
  //       gridColumnApi &&
  //       gridColumnApi.columnController.bodyWidth <
  //         gridColumnApi.columnController.scrollWidth
  //     ) {
  //       props.gridApi.sizeColumnsToFit();
  //       setTimeout(() => {
  //         const columnArray = event.columnApi.getColumnState();
  //         saveColumnWidth(columnArray);
  //       }, 1000);
  //     } else {
  //       saveColumnWidth(event.columnApi.getColumnState());
  //     }
  //   }
  // };

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  // const getPackedQuantity = (item) =>{
  //     let quantity = 0;
  //     item.customerSOPackageDetailsList.map((e) =>{
  //         return quantity += e.quantityPacked;
  //     });

  //     return quantity;
  // }

  // const columnDefs = [
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.package.listing.header.packageNo"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "packageNumber",
  //     hide: _isColumnToHide("packageNumber"),
  //     colId: "packageNumber_1",
  //     // width: (props.txColumnSetting['packageNumber'] || {}).width,
  //     minWidth: 160,
  //     maxWidth: 400,
  //     rowDrag: true,
  //     resizable: true,
  //     cellRendererFramework: (link) => (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           openPackageDetails(link.data);
  //         }}
  //       >
  //         {link.value}
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.package.listing.header.soNo"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "salesOrderNumber",
  //     hide: _isColumnToHide("soNumber"),
  //     colId: "soNumber_1",
  //     width: (props.txColumnSetting["soNumber"] || {}).width,
  //     resizable: true,
  //     cellRendererFramework: (link) => (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           openSoDetails(link.data);
  //         }}
  //       >
  //         {link.value}
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.package.listing.header.customer"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "companyName",
  //     hide: _isColumnToHide("customerName"),
  //     colId: "customerName_1",
  //     width: (props.txColumnSetting["customerName"] || {}).width,
  //     resizable: true,
  //     cellRendererFramework: (link) => (
  //       <div className="cursor-pointer">
  //         <Link
  //           to={{
  //             pathname: "customers/details",
  //             state: {
  //               customerId: link.data.customerId,
  //               customerName: link.data.companyName,
  //             },
  //           }}
  //           className="company-name"
  //         >
  //           {link.data.companyName}
  //         </Link>
  //       </div>
  //     ),
  //   },
  //   // {
  //   //     headerComponentFramework: () => {
  //   //         return <FormattedMessage id='customer.package.listing.header.qtyPacked' defaultMessage='' />;
  //   //     },
  //   //     field: "qtyPacked",
  //   //     hide: _isColumnToHide('qtyPacked'),
  //   //     colId: 'qtyPacked_1',
  //   //     width: (props.txColumnSetting['qtyPacked'] || {}).width,
  //   //     resizable: true,
  //   //     cellRendererFramework: (link) => <div>
  //   //         {getPackedQuantity(link.data)}
  //   //     </div>,
  //   // },

  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.package.listing.header.date"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "packageDate",
  //     hide: _isColumnToHide("packageDate"),
  //     colId: "packageDate_1",
  //     width: (props.txColumnSetting["packageDate"] || {}).width,
  //     resizable: true,
  //     cellRendererFramework: (link) => (
  //       <div>
  //         <Tooltip
  //           placement="topLeft"
  //           title={
  //             link.data.packageDate &&
  //             getMomentDateForUIReadOnly({
  //               date: link.data.packageDate,
  //               format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
  //             })
  //           }
  //         >
  //           {getMomentDateForUIReadOnly({
  //             date: new Date(link.data.packageDate),
  //             format,
  //           })}
  //         </Tooltip>
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="common.ref" defaultMessage="" />;
  //     },
  //     field: "referenceNumber",
  //     hide: _isColumnToHide("referenceNumber"),
  //     colId: "referenceNumber_1",
  //     width: (props.txColumnSetting["referenceNumber"] || {}).width,
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="common.status" defaultMessage="" />;
  //     },
  //     field: "status",
  //     hide: _isColumnToHide("status"),
  //     colId: "status_1",
  //     width: (props.txColumnSetting["status"] || {}).width,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <div>
  //           <span
  //             className={["status-code", params.value.toLowerCase()].join(" ")}
  //           >
  //             <FormattedMessage
  //               id={params.value}
  //               defaultMessage={params.value}
  //             />
  //           </span>{" "}
  //         </div>
  //       );
  //     },
  //     resizable: true,
  //     cellClass: "status-cell payment",
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div className="cursor-pointer">
  //           <Popover
  //             content={<AddAndRemoveCustomerListingColumn {...props} />}
  //             title={
  //               <div className="text-center">
  //                 <FormattedMessage id="common.addRemove" defaultMessage="" />{" "}
  //               </div>
  //             }
  //             placement="left"
  //             trigger="click"
  //           >
  //             <i className="fa fa-bars" />
  //           </Popover>
  //         </div>
  //       );
  //     },
  //     resizable: true,
  //     colId: "action_1",
  //     field: "action",
  //     pinned: "right",
  //     lockPosition: true,
  //     width: 82,
  //   },
  // ];

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  // const onGridReady = (params) => {
  //   props.updateState({
  //     gridApi: params.api,
  //     gridColumnApi: params.columnApi,
  //   });
  //   onDataRendered(params);
  //   params.api.sizeColumnsToFit();
  // };

  // const onModelUpdated = (params) => {
  //   if (
  //     (filter(packageList[pageNumber] || [], { status: "Not Shipped" }) || [])
  //       .length &&
  //     !props.isRowHeightSet
  //   ) {
  //     setTimeout(() => {
  //       params.api.resetRowHeights();
  //     }, 1000);
  //     props.updateState({
  //       isRowHeightSet: true,
  //     });
  //   }
  //   if (props.tableDataReRendered) {
  //     //params.api.resetRowHeights();
  //     props.updateState({
  //       tableDataReRendered: false,
  //     });
  //     const txColumnSetting =
  //       props.txColumnSetting && props.txColumnSetting.length
  //         ? props.txColumnSetting
  //         : params.columnApi.getColumnState();
  //     txColumnSetting.forEach((e) => {
  //       let columnName = e.colId.split("_")[0];
  //       if (columnName !== "action") {
  //         if (columnName === "isChecked") {
  //           e.hide = false;
  //         } else {
  //           e.hide = _isColumnToHide(columnName);
  //         }
  //       }
  //       if (columnName === "action") {
  //         e.hide = false;
  //         e.pinned = "right";
  //       }
  //     });
  //     params.columnApi.setColumnState(txColumnSetting);
  //     setTimeout(() => {
  //       if (
  //         props.gridColumnApi &&
  //         props.gridColumnApi.columnController.bodyWidth <
  //           props.gridColumnApi.columnController.scrollWidth
  //       ) {
  //         params.api.sizeColumnsToFit();
  //       }
  //     }, 500);
  //   }
  // };

  // const onDataRendered = (params) => {
  //   const txColumnSetting = props.txColumnSetting;
  //   if (Object.keys(txColumnSetting).length) {
  //     txColumnSetting.forEach((e) => {
  //       let columnName = e.colId.split("_")[0];
  //       if (columnName !== "action") {
  //         if (columnName === "isChecked") {
  //           e.hide = false;
  //         } else {
  //           e.hide = _isColumnToHide(columnName);
  //         }
  //       }
  //       if (columnName === "action") {
  //         e.hide = false;
  //         e.width = 52;
  //         e.pinned = "right";
  //       }
  //     });
  //     params.columnApi.setColumnState(txColumnSetting);
  //     setTimeout(() => {
  //       if (
  //         params.columnApi &&
  //         params.columnApi.columnController.bodyWidth <
  //           params.columnApi.columnController.scrollWidth
  //       ) {
  //         params.api.sizeColumnsToFit();
  //       }
  //     }, 500);
  //   } else {
  //     params.api.sizeColumnsToFit();
  //   }
  // };

  return (
    <Fragment>
     
        {/* <div className="invoice-header">
            </div> */}
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
            <FormattedMessage id='sales.salesPackage.table.heading' defaultMessage='' />
            </div>         
                <div className="vertical-sep" />
                <div>{props.packageCount}</div>     
          </div>
          </Skeleton>
        </div>
        {/* <div className="table-container"> */}
        <Skeleton loading={props.listLoading} paragraph={{ rows: 16 }} active>

        <div className="table-container">
          <table id="not-shipped-list">
            <thead>
              <tr>
                {!_isColumnToHide("packageNumber") ? (
                  <th>
                    <FormattedMessage
                      id="customer.package.listing.header.packageNo"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("customerName") ? (
                  <th>
                    <FormattedMessage
                      id="customer.package.listing.header.customer"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("soNumber") ? (
                  <th>
                    <FormattedMessage
                      id="customer.package.listing.header.soNo"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("packageDate") ? (
                  <th>
                    <FormattedMessage
                      id="customer.package.listing.header.date"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("referenceNumber") ? (
                  <th>
                    <FormattedMessage id="common.ref" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("userStatus") ? (
                  <th>
                    <FormattedMessage id="common.status" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {packageList && packageList[pageNumber]
                ? packageList[pageNumber].map((rowData, index) => (
                    <tr key={index}>
                      {!_isColumnToHide("packageNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openPackageDetails(rowData);
                            }}
                          >
                            {rowData.packageNumber||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("customerName") ? (
                        <td>
                          <div className="cursor-pointer" >
                                                    <div 
                                                    // onClick={() => {
                                                    //     props.history.push("customers/details", {
                                                    //         customerId: rowData.customerId,
                                                    //         customerName: rowData.companyName,
                                                    //     })
                                                    onClick={() => {
                                                        props.pushModalToStack({  
                                                  
                                                          modalBody: <CustomerProfile {...props}
                                                          customerId= {rowData.customerId}
                                                        //   customerName={rowData.customerName}
                                                        //   customerDetails={rowData}
                                                      />,
                                                      width: '100%',
                                                      hideTitle: true,  
                                                      hideFooter: true,
                                                      wrapClassName: "new-transaction-wrapper",
                                                      })
                                                    }}>{rowData.companyName||<div className="empty-data"></div>}</div>
                                                </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("soNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openSoDetails(rowData);
                            }}
                          >
                            {rowData.salesOrderNumber||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("packageDate") ? (
                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.packageDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.packageDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {getMomentDateForUIReadOnly({
                                date: new Date(rowData.packageDate),
                                format,
                              })}
                            </Tooltip>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("referenceNumber") ? (
                        <td>{rowData.referenceNumber || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("userStatus") ? (
                        <td>
                          {rowData.userStatus ? (
                            <div
                              className="status-code"
                              style={{ background: rowData.userStatusColor }}
                            >
                              {rowData.userStatus || <div className="empty-data"></div>}
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))
                :  <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>}
            </tbody>
          </table>
        </div>
        {/* <div className="table-container">
            <div className="ag-theme-balham" style={agGridStyle}>
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={packageList[pageNumber] || []}
                rowDragManaged={true}
                // onGridReady ={(params) => {
                //     gridApi = params.api;
                // }}
                domLayout={"autoHeight"}
                animateRows={true}
                defaultColDef={{
                  flex: 1,
                  autoHeight: true,
                  wrapText: true,
                }}
                //getRowHeight={getRowHeight}
                onModelUpdated={onModelUpdated}
                onColumnResized={onColumnResized}
                onDragStopped={() => {
                  onColumnMoved({}, true);
                }}
                //onColumnMoved={onColumnMoved}
              ></AgGridReact>
            </div>
          </div> */}
      </Skeleton>

      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

                <div className="f-left"></div>
                <div className="f-right">
                    <Pagination
                        size="small"
                        total={packageCount}
                        showTotal={showTotal}
                        defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber || 1, pageSize, props)
                        }}
                    />
                </div>
                </Skeleton>
            </div>
    </Fragment>
  );
};

export default injectIntl(SOPackageListingComp);
