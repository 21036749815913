import axios from "axios";
import config from "../../../config/environmentConfig";
import {
  MEMBERSHIP_ACTION_LIST,
  DONATION_ACTION_LIST,
  COMMON_ACTIONS,
  SMS_ACTION_LIST,
} from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getAllStudentForm = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/getFormSummary?relationshipId=${
          payload.relationshipId
        }&pageSize=${payload.pageSize || 10}&pageNumber=${
          (payload.pageNumber || 1) - 1
        }&registrationType=student&relEmployeeId=${payload.relationshipEmployeeId || 0}`
      )
      .then((res) => {
        dispatch({
          type: MEMBERSHIP_ACTION_LIST.STUDENT_FORM_LIST,
          data: res.data,
        });
        // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      })
      .finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
          // type: DONATION_ACTION_LIST.DONATION_SETTING_LOADING,
          // data: { isStudentFormListLoaded: true },
        });
      });
  };
};

export const deleteRegistrationForm = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/delete`,
        payload
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          messageType: "success",
          description: lang[res.data.message || "common.delete.success"],
        });
        props.getAllStudentForm({ ...payload, pageNumber: 0, pageSize: 100 });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall = MEMBERSHIP_ACTION_LIST.MEMBERSHIP_FORM_LIST;
        }
      });
  };
};

export const updateFormStatus = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/updateFormStatus?relationshipId=${payload.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}&isFormActive=${payload.isFormActive}`
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "Status updated successfully!",
        });
        props.getAllStudentForm({
          relationshipId: props.companyInfo.relationshipId,
          relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
          pageNumber: 0,
          pageSize: 100,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      });
  };
};

export const fetchRegistrationFormSettingDraftList = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/getAllFormSettingDraft?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber - 1}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber || 1,
          list: res.data
        }
        dispatch({ type: SMS_ACTION_LIST.SCHOOL_FORM_DRAFT_LIST, data });
      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
    }
}

export const countSchoolFormSettingDraft = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/countDraftForms?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.SCHOOL_FORM_DRAFT_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      })
  };
};

export const deleteRegistrationFormDraft = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/deleteFormDraft`, payload)
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          messageType: "success",
          description: lang[res.data.message || "common.delete.success"],
        });
        props.fetchRegistrationFormSettingDraftList({ ...payload, pageNumber: 0, pageSize: 25 });
        props.countSchoolFormSettingDraft(payload);
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall = MEMBERSHIP_ACTION_LIST.MEMBERSHIP_FORM_LIST;
        }
      });
  };
};