import React, { Fragment, } from "react";
import { Skeleton, Checkbox, Menu, Empty } from 'antd';
// import * as debounce from 'lodash.debounce';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { AG_GRID_CLASS_CONSTANTS } from "../../../static/cssClassConstants";
// import NewLedgerComponent from '../../../containers/modal/modalBody/finance/LedgerAccount';
import LedgerDetails from '../../../containers/modal/modalBody/finance/LedgerAccount/LedgerDetails'
// import { CONSTANTS } from '../../../static/constants';
import { showToasterMessage, exportExcel, sortTable } from '../../../utils';
// import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { SortAscendingOutlined, SortDescendingOutlined, FilterOutlined, CheckCircleOutlined, CloseCircleOutlined, DownOutlined, LockOutlined, DeleteOutlined, FileExcelOutlined, DownloadOutlined } from '@ant-design/icons';
import * as find from 'lodash.find';
import { ICONS } from "../../../static/constants";
import DropdownAction from "antd/lib/dropdown";
import { MoreOutlined } from '@ant-design/icons';


// const { Search } = Input;

// let gridApi = {};


// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };


const LedgerAccountComp = (props) => {
  const { allLedgerAccounts, dir = 0, selectedItems = [] } = props;
  // const [isAllChecked, toggleAllChecked] = useState(false);
  // const format = CONSTANTS.DISPLAY_DATE_FORMAT;

  // const menu = (
  //     <Menu>
  //         <Menu.Item key="upload_customer">
  //             <i className="fa fa-file-pdf-o" />
  //             &nbsp;Download PDF
  //         </Menu.Item>
  //     </Menu>
  // );

  // const onGridReady = (params) => {
  //     gridApi = params.api;
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi,
  //     });
  // }

  // const getActionPermissionData = () => {
  //     const primaryPerm = permissions.primary || [];
  //     const permittedAction = {
  //         create: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.LEDGER_ACCOUNTS, PERMISSION_VALUES.CREATE)
  //     };
  //     Object.keys(permittedAction).forEach((operation) => {
  //         if (permittedAction[operation] === false) {
  //             delete permittedAction[operation];
  //         }
  //     })
  //     return permittedAction;
  // }

  // const permittedActions = getActionPermissionData();


  // const columnDefs = [
  //     {
  //         field: 'isChecked',
  //         colId: 'isChecked_1',
  //         headerComponentFramework: () => <Fragment />,
  //         cellRendererFramework: (params) => {
  //             return <Checkbox
  //                 onChange={(e) => {
  //                     params.node.setDataValue('isChecked_1', e.target.checked);
  //                     params.node.data.isChecked = e.target.checked;
  //                 }}
  //                 checked={params.data.isChecked} />
  //         },
  //         minWidth: 40,
  //         maxWidth: 40,
  //         pinned: 'left',
  //         suppressMovable: true,
  //         resizable: false
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip    tip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.accNNumber',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'>Account #</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "accountNumber",
  //         resizable: true,
  //         minWidth: 100,
  //         maxWidth: 100,
  //         rowDrag: true,
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <Tooltip placement="top" title={props.intl.formatMessage(
  //     //             {
  //     //               id: 'tooltip.ledgerAccNumber',
  //     //               defaultMessage: ''
  //     //             }
  //     //           )}>
  //     //             <div className='table-header-column-container'>
  //     //                 <span className='table-header-column'>
  //     //                     <i className="fa fa-info-circle mr5" />
  //     //                     Ref Account #
  //     //                 </span>
  //     //             </div>
  //     //         </Tooltip>;
  //     //     },
  //     //     field: "refAccountNumber",
  //     //     resizable: true,
  //     //     valueGetter: function (params) {
  //     //         return params.data.refAccountNumber;
  //     //     },
  //     //     valueSetter: function (params) {
  //     //         if (Number(params.newValue || 0) !== Number(params.oldValue || 0)) {
  //     //             params.data[params.colDef.field] = Number(params.newValue);
  //     //             params.data.relationshipId = props.companyInfo.relationshipId;
  //     //             params.data.ledgerAccountId = params.data.chartOfAccountDetailsId
  //     //             props.updateLedger(params.data);
  //     //         }
  //     //         return true;
  //     //     },
  //     //     editable: true,
  //     //     sortable: true,
  //     //     cellEditor: 'customNumberEditor',
  //     // },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <Tooltip placement="top" title={props.intl.formatMessage(
  //     //             {
  //     //               id: 'tooltip.ledgerNo',
  //     //               defaultMessage: ''
  //     //             }
  //     //           )}>
  //     //             <div className='table-header-column-container'>
  //     //                 <span className='table-header-column'> Ledger #</span>
  //     //             </div>
  //     //         </Tooltip>;
  //     //     },
  //     //     field: "chartOfAccountDetailsId",
  //     //     resizable: true,
  //     // },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.ledgerAccount',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'> Ledger Account</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "ledgerAccountName",
  //         resizable: true,
  //         minWidth: 150,
  //         maxWidth: 150,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.totalAmount',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'> Total Amount   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "balanceAmount",
  //         minWidth: 130,
  //         maxWidth: 130,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //         resizable: true,
  //         cellRendererFramework: (data) => {
  //             const value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
  //             return <div  onClick={
  //                 (e) => {
  //                     const payload = {
  //                         ledgerAccountId: (data.data || {}).chartOfAccountDetailsId
  //                     }
  //                     props.showModal(
  //                         {
  //                             title: <Fragment>
  //                                 <div className='ant-modal-title'>
  //                                     Ledger Account  Information
  //                                 </div>
  //                             </Fragment>,
  //                             modalBody: <LedgerDetails {...props} {...payload} />,
  //                             width: 1000,
  //                             hideFooter: true,
  //                             wrapClassName: 'ledger-account-list-modal'
  //                         }
  //                     )
  //                 }
  //             } className="ant-breadcrumb-link cursor-pointer">{value}</div>
  //         }
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'entooltip.opingBalance',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'> Opening Balance   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "openingBalance",
  //         resizable: true,
  //         minWidth: 130,
  //         maxWidth: 130,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //         valueFormatter: (data) => {
  //             data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
  //             return data.value;
  //         }
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.debitAmount',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'>Debit Amount   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "debitAmount",
  //         resizable: true,
  //         minWidth: 130,
  //         maxWidth: 130,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //         valueFormatter: (data) => {
  //             data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
  //             return data.value;
  //         }
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.debitAmount',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'>Credit Amount   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "creditAmount",
  //         resizable: true,
  //         minWidth: 130,
  //         maxWidth: 130,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //         valueFormatter: (data) => {
  //             data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
  //             return data.value;
  //         }
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.amountDifference',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'>Amount Difference   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "amountDifference",
  //         resizable: true,
  //         minWidth: 130,
  //         maxWidth: 130,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //         valueFormatter: (data) => {
  //             data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
  //             return data.value;
  //         }
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.amountDifference',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'>Nature</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "nature",
  //         resizable: true,
  //         minWidth: 80,
  //         maxWidth: 80,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.ledgerAccGrp',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'>Ledger Account Group</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "ledgerAccountGroupName",
  //         minWidth: 150,
  //         maxWidth: 150,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.category',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'>Category</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "categoryName",
  //         minWidth: 150,
  //         maxWidth: 160,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.categoryGrp',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'>Category Group</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "categoryGroupName",
  //         minWidth: 150,
  //         maxWidth: 150,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <Tooltip placement="top" title={props.intl.formatMessage(
  //                 {
  //                   id: 'tooltip.accountEntry',
  //                   defaultMessage: ''
  //                 }
  //               )}>
  //                 <div className='table-header-column-container'>
  //                     <span className='table-header-column'>Accounting Entry</span>
  //                 </div>
  //             </Tooltip>;
  //         },
  //         field: "accountingEntry",
  //         resizable: true,
  //         minWidth: 80,
  //         maxWidth: 80,
  //     }
  //     ,
  //     {
  //         field: "action",
  //         colId: "action_1",
  //         pinned: "right",
  //         lockPosition: true,
  //         cellRendererFramework: (params) => {
  //             return <div>
  //                 { params.data.inBuilt ? <span style={{ fontSize: '20px' }} title={props.intl.formatMessage({ id:'cant.delete.systemGenerated'})} >
  //                 <i className="fa fa-lock" />
  //                 </span>
  //                 :
  //                     <span style={{ fontSize: '20px' }} className="cursor-pointer" title={props.intl.formatMessage({ id: 'modal.txDetails.common.delete' })} onClick={
  //                         () => {
  //                             props.showModal({
  //                                 modalBody: <FormattedMessage id='common.delete.confirmation' defaultMessage='' />,
  //                                 handleSubmit: () => {
  //                                     props.deleteLedgerAccount(params.data, props);
  //                                 }
  //                             })

  //                         }}>
  //                         <i className='fa fa-trash' />
  //                     </span>
  //                 }
  //             </div>;
  //         },
  //         minWidth: 70,
  //         maxWidth: 70,
  //         suppressMovable: true,
  //     }
  // ]

  // const onSearchedInputChange = debounce((searchedText, props) => {
  //     handleSearchedText(searchedText, props)
  // }, 2000);

  // const handleSearchedText = (searchedText, props) => {
  //     gridApi.setQuickFilter(searchedText || '');
  // }

  // {/* <strong><FormattedMessage id='modal.reports.partyLedger.startDate.label' defaultMessage="" /></strong> */}
  //   {/* <DatePicker style={{ height: 35 }} onChange={(dateObj) => {
  //                         if (dateObj) {
  //                             props.updateState({
  //                                 startDate: dateObj.format(CONSTANTS.BACKEND_FORMAT)
  //                             });
  //                         } else {
  //                             props.updateState({
  //                                 startDate: undefined
  //                             });
  //                         }
  //                     }} format={format} placeholder = {props.intl.formatMessage({ id: 'modal.common.project.startDate' }) }
  //                     key={`${props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : ''}`}
  //                     defaultValue={props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : ''}
  //                 /> */}
  //   {/* </Col> */}
  //   {/* <Col span={5}>
  //   < style={{ paddingLeft: "15px" }}>
  //   {/* <strong ><FormattedMessage id='modal.reports.partyLedger.endDate.label' defaultMessage="" /></strong> */}
  //   {/* <DatePicker style={{ height: 35 }} onChange={(dateObj) => {
  //       if (dateObj) {
  //           props.updateState({
  //               endDate: dateObj.format(CONSTANTS.BACKEND_FORMAT)
  //             });
  //                             } else {
  //                                 props.updateState({
  //                                     endDate: undefined
  //                                 });
  //                             }
  //                         }} format={format} placeholder = {props.intl.formatMessage({ id: 'modal.common.project.endDate' }) }
  //                         key={`${props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : ''}`}
  //                         defaultValue={props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : ''}
  //                         />
  //                     </div> */}
  //   {/* </Col> */}
  //   {/* <Col span={1}>
  //   <Button className="ant-btn ant-btn-default vab ml15 ml10" title={props.intl.formatMessage({ id: 'filter.text' }) } style={{ height: 35 }} onClick={() => {
  //       if (!props.startDate || !props.endDate) {
  //           showToasterMessage({
  //               description: 'Please select date range.',
  //               messageType: 'error'
  //             })
  //         } else if(!isValidDateRange(props.startDate, props.endDate)){
  //             showToasterMessage({
  //                 description: 'Please select valid date range',
  //                 messageType: 'error'
  //             })
  //         }else {
  //             const companyInfo = props.companyInfo || {};
  //             props.fetchAllLedgerAccounts({
  //                 startDate: formatDateFilterForBackend(props.startDate, CONSTANTS.FILTER_DATE_TYPE.START),
  //                 endDate: formatDateFilterForBackend(props.endDate, CONSTANTS.FILTER_DATE_TYPE.END),
  //                 relationshipId: companyInfo.relationshipId,
  //                 // pageNumber: 1,
  //                 // pageSize: 25
  //             }, props);
  //         }
  //                     }}>
  //                         {<FilterOutlined />}
  //                         </Button>
  //                     </Col> */}
  //   {/* <Col span={1}>
  //   <Button className="ant-btn ant-btn-default vab ml15 ml10" title={props.intl.formatMessage({ id: 'reset.text' }) } style={{ height: 35 }} onClick={() => {
  //       const companyInfo = props.companyInfo || {};
  //       const finacialYear = getFinancialYear(companyInfo);
  //       let startDate = getMomentDateForUI({ date: new Date(finacialYear.fyStartDate), format: CONSTANTS.BACKEND_FORMAT });
  //       let endDate =  getMomentDateForUI({ date: new Date(), format: CONSTANTS.BACKEND_FORMAT });
  //       props.updateState({
  //           startDate: startDate,
  //           endDate: endDate,
  //         })

  //         props.fetchAllLedgerAccounts({
  //             startDate: formatDateFilterForBackend(startDate, CONSTANTS.FILTER_DATE_TYPE.START),
  //             endDate: formatDateFilterForBackend(endDate, CONSTANTS.FILTER_DATE_TYPE.END),
  //             relationshipId: companyInfo.relationshipId,
  //             // pageNumber: 1,
  //             // pageSize: 25
  //         }, props);
  //     }}>
  //     {<UndoOutlined />}
  //     </Button>
  // </Col> */}

  //   {/* <Col className='align-end' span={6}>
  //   {
  //       // permittedActions.create && (
  //           // <Dropdown overlay={menu}>
  //           //     <div className="action-dropdown-btn">
  //           //         <button onClick={
  //               //             () => {
  //                   //                 props.showModal({
  //                       //                     title: <Fragment>
  //                       //                         <div className='ant-modal-title'>New Chart of Account</div>
  //                       //                     </Fragment>,
  //                       //                     modalBody: <NewLedgerComponent {...props} />,
  //                       //                     width: 1100,
  //                       //                     hideFooter: true
  //                       //                 })
  //                       //             }
  //                       //         }>
  //                       //             <i className="fa fa-user" />New Ledger
  //                       // <i className="fa fa-angle-down ml5" />
  //                       //         </button>
  //                       //     </div>
  //                       // </Dropdown>
  //                       // <div className="action-dropdown-btn">
  //                             //     <button onClick={
  //                                 //         () => {
  //                                     //             props.showModal({
  //                                         //                 title: <Fragment>
  //                                         //                     <div className='ant-modal-title'>New Chart of Account</div>
  //                                         //                 </Fragment>,
  //                                         //                 modalBody: <NewLedgerComponent {...props} />,
  //                                         //                 width: 1100,
  //                                         //                 hideFooter: true
  //                                         //             })
  //                                         //         }
  //                                         //     }>
  //                                         //         <i className="fa fa-user" />New Ledger
  //                                         //         </button>
  //                                         // </div>
  //                                         // )
  //                                     }
  //                                 </Col> */}
  //   {/* </Row> */}


  const downloadMenu = (data) => {
    return <Menu className="row-action-dropdown">
      <Menu.Item key="0" value="title">
        Download as
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="1" value="excel" onClick={() => {
        const ledgerList = [];
        selectedItems.forEach((obj) => {
          ledgerList.push({
            "Account #": obj.accountNumber,
            "Ledger Account": obj.ledgerAccountName || "",
            "Total Amount": obj.balanceAmount,
            "Opening Balance": obj.openingBalance,
            "Debit Amount": obj.debitAmount,
            "Credit Amount": obj.creditAmount,
            "Amount Difference": obj.amountDifference,
            Nature: obj.nature || "",
            "Ledger Account Group":
              obj.ledgerAccountGroupName || "",
            Category: obj.categoryName || "",
            "Category Group": obj.categoryGroupName || "",
            "Accounting Entry": obj.accountingEntry || "",
          });
        });
        if (ledgerList.length) {
          exportExcel(ledgerList, "Ledger-reports");
        } else {
          showToasterMessage({
            messageType: "error",
            description:
              "No record selected. Please select the record(s) before proceeding.",
          });
        }
      }}>
        <FileExcelOutlined />
        Excel File
      </Menu.Item>
    </Menu>;
  };

  const sortColumn = (e) => {
    sortTable(document.getElementById("ledger-account"), e.target.cellIndex, 1 - dir);
    props.updateState({ dir: 1 - dir })
  }

  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <CheckCircleOutlined />
          Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <CloseCircleOutlined />
          Unselect all
        </Menu.Item>
      </Menu>
    )
  }

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      chartOfAccountDetailsId: Number(item.chartOfAccountDetailsId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.chartOfAccountDetailsId !== item.chartOfAccountDetailsId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < allLedgerAccounts.length; i++) {
      let item = allLedgerAccounts[i];
      let selectItem = find(selectedItems, {
        chartOfAccountDetailsId: Number(item.chartOfAccountDetailsId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  };

  const isCheckedAll = () => {
    if (
      !allLedgerAccounts ||
      allLedgerAccounts.length === 0
    ) {
      return false;
    }
    for (let i = 0; i < allLedgerAccounts.length; i++) {
      let usr = allLedgerAccounts[i];
      let selectItem = find(selectedItems, {
        chartOfAccountDetailsId: Number(usr.chartOfAccountDetailsId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };
  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">

        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="1" value="edit"  onClick={() => {
            props.updateDrawerState({ledgerAccountsDrawerVisible: true,
           data:e })
          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>
        {e.inBuilt ? (
          ""
        ) : (
          <Menu.Item key="2" onClick={() => {
            props.showModal({
              modalBody: (
                <FormattedMessage
                  id="common.delete.confirmation"
                  defaultMessage=""
                />
              ),
              handleSubmit: () => {
                props.deleteLedgerAccount(
                  e,
                  props
                );
              },
            });
          }}>
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        )}


      </Menu >
    )
  };
  return (
    <Fragment>
      
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="heading.finance.ledgerAccounts.table.heading"
                defaultMessage=""
              />
            </div>
            {props.pageSize ? (
              <>
                <div className="vertical-sep" />
                <div>{props.pageSize}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">


            <button onClick={() => { }}>
              <FilterOutlined /> &nbsp;
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>

            <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
              <span>
                <DownloadOutlined />&nbsp;
                <FormattedMessage
                  id="common.action.dropdown.downloadAs"
                  defaultMessage=""
                />
              </span>
            </DropdownAction>

            {/* <button
                 className="action-icon-btn"
                 onClick={() => {
                   const invoiceList = [];
                   props.gridApi.forEachNode((obj) => {
                     if (obj.data.isChecked) {
                       invoiceList.push({
                         "Account #": obj.data.accountNumber,
                         "Ledger Account": obj.data.ledgerAccountName || "",
                         "Total Amount": obj.data.balanceAmount,
                         "Opening Balance": obj.data.openingBalance,
                         "Debit Amount": obj.data.debitAmount,
                         "Credit Amount": obj.data.creditAmount,
                         "Amount Difference": obj.data.amountDifference,
                         Nature: obj.data.nature || "",
                         "Ledger Account Group":
                           obj.data.ledgerAccountGroupName || "",
                         Category: obj.data.categoryName || "",
                         "Category Group": obj.data.categoryGroupName || "",
                         "Accounting Entry": obj.data.accountingEntry || "",
                       });
                     }
                   });
                   if (invoiceList.length) {
                     exportExcel(invoiceList, "Ledger-reports");
                   } else {
                     showToasterMessage({
                       messageType: "error",
                       description:
                         "No record selected. Please select the record(s) before proceeding.",
                     });
                   }
                 }}
               >
                 <Tooltip
                   placement="right"
                   title={props.intl.formatMessage({
                     id: "activity.exportExcel",
                   })}
                 >
                   <i className="fa fa-file-excel-o" />
                 </Tooltip>
               </button> */}
          </div>
        </div>

        {/* <div className="agGridWrapper">
            <div className="ag-theme-balham" style={agGridStyle}>
              <AgGridReact
                columnDefs={columnDefs}
                rowData={allLedgerAccounts || []}
                rowDragManaged={true}
                onGridReady={onGridReady}
                domLayout={"autoHeight"}
                animateRows={true}
                //onGridSizeChanged={onFirstDataRendered}
                //getRowHeight={getRowHeight}
                defaultColDef={{
                  flex: 1,
                  autoHeight: true,
                  wrapText: true,
                }}
                enableCellTextSelection={true}
              />
            </div>
          </div> */}

        <div className="table-container">
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>
          <table id="ledger-account">
            <thead>
              <tr>
                <th>
                  <div className="check-cell">
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                    <DropdownAction
                      overlay={checkMenu()}
                      trigger={["click"]}
                    >
                      <DownOutlined />
                    </DropdownAction>
                  </div>
                </th>
                <th>
                  <FormattedMessage
                    id="tooltip.accNNumber"
                    defaultMessage=" "
                  />
                  {dir ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )}
                </th>
                <th onClick={sortColumn}>
                  <FormattedMessage
                    id="tooltip.ledgerAccount"
                    defaultMessage=" "
                  />
                  {dir ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )}
                </th>
                <th onClick={sortColumn}>
                  <FormattedMessage
                    id="tooltip.totalAmount"
                    defaultMessage=" "
                  />
                  {dir ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )}
                </th>
                <th onClick={sortColumn}>
                  <FormattedMessage
                    id="entooltip.opingBalance"
                    defaultMessage=" "
                  />
                  {dir ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )}
                </th>
                <th onClick={sortColumn}>
                  <FormattedMessage
                    id="tooltip.debitAmount"
                    defaultMessage=" "
                  />
                  {dir ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )}
                </th>
                <th onClick={sortColumn}>
                  <FormattedMessage
                    id="tooltip.creditAmount"
                    defaultMessage=" "
                  />
                  {dir ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )}
                </th>
                <th onClick={sortColumn}>
                  <FormattedMessage
                    id="tooltip.amountDifference"
                    defaultMessage=" "
                  />
                  {dir ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )}
                </th>
                <th onClick={sortColumn}>
                  <FormattedMessage id="tooltip.nature" defaultMessage=" " />
                  {dir ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )}
                </th>
                <th onClick={sortColumn}>
                  <FormattedMessage
                    id="tooltip.ledgerAccGrp"
                    defaultMessage=" "
                  />
                  {dir ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )}
                </th>
                <th>
                  <FormattedMessage
                    id="common.listing.header.action"
                    defaultMessage=" "
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {(allLedgerAccounts || [])?.length ?
                allLedgerAccounts.map((rowData, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {
                          <Checkbox
                            onClick={() => {
                              itemSelection(rowData);
                            }}
                            checked={find(selectedItems, { chartOfAccountDetailsId: Number(rowData.chartOfAccountDetailsId) }) ? true : false}
                          />
                        }
                      </td>
                      <td>{rowData.accountNumber}</td>
                      <td>{rowData.ledgerAccountName}</td>
                      <td>
                        <div
                          className="table-link-text amount-TextRight"
                          onClick={(e) => {
                            const payload = {
                              ledgerAccountId: (rowData || {})
                                .chartOfAccountDetailsId,
                            };
                            props.showModal({
                              title: (
                                <Fragment>
                                  <div className="ant-modal-title dis-col">
                                    Ledger Account Information
                                  <div className="font-14">{rowData.ledgerAccountName}</div>
                                  </div>
                                </Fragment>
                              ),
                              modalBody: (
                                <LedgerDetails {...props} {...payload} />
                              ),
                              width: 1000,
                              hideFooter: true,
                              wrapClassName: "ledger-account-list-modal",
                            });
                          }}
                        >
                          {(rowData.balanceAmount || 0).toFixed(2)}
                        </div>
                      </td>
                      <td>
                        <div className="amount-TextRight">
                          {(rowData.openingBalance || 0).toFixed(2)}
                        </div>
                      </td>
                      <td>
                        <div className="amount-TextRight">
                          {(rowData.debitAmount || 0).toFixed(2)}
                        </div>
                      </td>
                      <td>
                        <div className="amount-TextRight">
                          {(rowData.creditAmount || 0).toFixed(2)}
                        </div>
                      </td>
                      <td>
                        <div className="amount-TextRight">
                          {(rowData.amountDifference || 0).toFixed(2)}
                        </div>
                      </td>
                      <td>{rowData.nature}</td>
                      <td>{rowData.ledgerAccountGroupName}</td>

                      <td style={{ textAlign: 'center' }}>
                        {rowData.inBuilt?<LockOutlined/>:<div className="action-icon">
                          <DropdownAction overlay={actionMenu(rowData)} trigger={['click']}>
                            <MoreOutlined />
                          </DropdownAction>
                        </div>}
                      </td>
                    </tr>
                  );
                }):
                <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>
                }
            </tbody>
          </table>
      </Skeleton>
        </div>
      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right"></div>
      </div>
      {/* </div> */}
    </Fragment>
  );
};

export default injectIntl(LedgerAccountComp);
