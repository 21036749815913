import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SMS_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const importSubjectsCsv = (payload, props) => {
    var formData = new FormData();
    formData.append("file", payload.fileToUpload);
    formData.append(
      "mapping",
      new Blob([payload.mappedData], {
        type: "application/json",
      })
    );
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/subject/importFromCSV`,
          formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          showToasterMessage({ messageType: "success", description: lang[(res.data || "")] || "Upload completed succesfully" });
          props.updateModalState({
            isuploded:true,
            resData:res.data,
            current: props.current + 1
          })
        })
        .catch(err => {
          showToasterMessage({messageType: "error", description: lang[((err.response || {}).data || "")] || "Please reselect the upload file."});
        })
    }
}


export const getSubjectImportHeader = (relationshipId, props) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/subject/getSubjectHeaders?relationshipId=${relationshipId}`)
            .then((res) => {
                dispatch({
                    type: SMS_ACTION_LIST.SCHOOL_SUBJECT_HEADERS,
                    data: res.data,
                });
            })
            .catch((err) => {
                showToasterMessage({
                    messageType: "error",
                    description: lang[((err.response || {}).data || "")] || "Some Error Occurs",
                });
            });
    };
};


export const downloadExelFile = (payload, props) => {
return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
    .post(
        `${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierExcel/download-error-excel`,
        payload,
        { responseType: 'arraybuffer' } // Specify responseType as 'arraybuffer' to get a byte array
    )
    .then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' }); // Adjust the MIME type according to your file type
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = 'sample.xls'; // Set the desired file name with .xls extension
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        // props.popModalFromStack()
    })
    .catch((err) => {
        if (lang[((err.response || {}).data || "")]) {
        showToasterMessage({
            messageType: 'error',
            description:
            lang[((err.response || {}).data || "")] || 'Some Error Occurs',
        });
        } else {
        showToasterMessage({
            messageType: 'error',
            description: 'Some Error Occurs',
        });
        props.popModalFromStack();
        }
    });
};
};