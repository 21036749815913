import axios from "axios";
import config from "../../../../config/environmentConfig";
import { COMMON_ACTIONS } from "../../../../static/constants";
import { showToasterMessage, formatDateForBackend } from "../../../../utils";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const saveAttendance = (payload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/attendance/saveCol`,
        payload
      )
      .then((response) => {
        if (props) {
          props.updateHeaderState({
            schoolDrawers: {
              ...props.schoolDrawers,
              addAttendenceDrawerVisible: false
            }
          });
          showToasterMessage({
            messageType: 'success',
            description: lang[((response || {}).data || "")] || "Saved Successfully"
          })
          // let classObj = (props.classList[1] || [])[0] || {};
          let date = props.selectedDate ? new Date(props.selectedDate) : new Date();
          let y = date.getFullYear();
          let m = date.getMonth();
          const payloadObj = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 1000,
            classId: payload.classId,
            startDate: formatDateForBackend(new Date(y, m, 1)),
            endDate: formatDateForBackend(new Date(y, m + 1, 0)),
            relationshipEmployeeId: payload.createdByEmpId,
          };
          const payloadObj2 = {
            ...payloadObj,
            companyInfo: props.companyInfo,
            relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
            formId: props.defaultSchoolForm || 0
          };
          props.getClassList && props.getClassList(payloadObj2);
          props.getSchoolAttendanceByClassId && props.getSchoolAttendanceByClassId(payloadObj);
        }
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || "")] ||
              "Some error occurred",
          });
        }
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  };
};


