import React, { useState } from 'react';
import { Steps, Button, Upload, Row, Col } from 'antd';
import { injectIntl } from 'react-intl';
import './index.scss';
import { Dropdown } from '../../../../general/Dropdown';
import config from '../../../../../config/environmentConfig';
import * as XLSX from "xlsx";
import frameImport from "../../../../../assets/images/Frame-import.png";
import successImg from "../../../../../assets/images/Framesuccess.png";
import failImg from "../../../../../assets/images/Groupfail.png";
import { ICONS } from '../../../../../static/constants';
import { getUserDefinedName } from '../../../../../utils';

const { Dragger } = Upload;
const { Step } = Steps;

function ImportCourses(props) {

    const { companyInfo, current=0, headerValues=[] } = props;
    const [dropdownValues, setDropdownValues] = useState(headerValues);

    const firstStep = () => {

        const uploadProps = {
            name: 'file',
            multiple: false,
            accept:".csv, application/vnd.ms-excel",
            action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
            beforeUpload: (abc)=>{},
            onChange(info) {
                const { status } = info.file;
                if (status !== 'uploading') {
                    handleFileInputChange(info?.file?.originFileObj)
                }
                if (status === 'done') {
                    handleFileInputChange(info?.file?.originFileObj)

                } else if (status === 'error') {
                    handleFileInputChange(info?.file?.originFileObj)

                }
            },
            onDrop(e) {
                handleFileInputChange(e.dataTransfer.files)
            },
        };

        const handleFileRead = (e) => {
            const content = e.target.result;
            const workbook = XLSX.read(content, { type: "binary" });

            const sheetName = workbook?.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            props.updateModalState({
                fileHeader: jsonData[0],
                totaData: jsonData.length
            });
            const ans = jsonData[0]?.map((item) => {
                if ((props.headerValues || []).includes(item)) {
                    return item;
                }
            });

            const val = ans.map((item, i) => { return item ? true : false})
            props.updateModalState({
                headerMandatoryValues: val
            })
            setDropdownValues(ans);
        };

        const handleFileInputChange = (e) => {
            const file = e;
            props.updateModalState({
                fileToUpload: file,
            });
            if (file) {
                const reader = new FileReader();
                reader.onloadend = handleFileRead;
                reader.readAsBinaryString(file);
            }
        };

        return (<>
            <div className='step_head'>Upload File</div>
            <div className='step_inner_body'>
                <div className='file_dnd_container'>
                    <Dragger 
                        {...uploadProps}
                        className={props.uploadedOnce && !props.fileToUpload ? 'error' : ''}
                    >
                        <p><i className="fi fi-rr-cloud-upload"  style={{ fontSize: "28px", color: '#4ca185' }} /></p>
                        <p className="ant-upload-text">Click or drag CSV file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
                        </p>
                        <p>{props.uploadedOnce && !props.fileToUpload ? <div className='required'>File upload is required</div> : ''}</p>
                    </Dragger>
                    
                    <div>
                        <p><b>Instructions</b></p>
                        <ul>
                            <li><b>*</b> - marked below names are mandatory in CSV file to be entered.</li>
                            <li>{getUserDefinedName("Course Name*, Course Code*", props)} - To be created.</li>
                            <li>{getUserDefinedName("Department Name*, Department Code*", props)} - Existing ones can be entered or new will be created.</li>
                            <li>{getUserDefinedName("Term Name*, Term Code*", props)} - Existing ones can be entered or new will be created.</li>
                            <li>Is Certitificate Awarded, Certificate Name, Certificate Message - Related to specific {getUserDefinedName("term", props)}</li>
                            <li>{getUserDefinedName("Subject Name*, Subject Code*, Subject Credit (Number)*, Subject Type(Core/Elective)*", props)} - New ones will be created if not exists</li>
                        </ul>
                    </div>
                </div>
                <div className='file-download-container'>
                    <div className='step_head'>Download Sample CSV File</div>
                    <img src={frameImport} alt="" />
                    <p>Download a sample CSV template to see an example of the format required.</p>
                    <a href={`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/course/downloadSchoolCourseTemplate?relationshipId=${companyInfo.relationshipId}`}>
                        <i className={ICONS.CSV} />
                        <span className="ml5">Download Template</span>
                    </a>
                </div>
            </div>
        </>
        );
    }

    const secondStep = () => {
        const handleChange = (index, selectedValue) => {
            // update dropdown Arr
            const tempArr = dropdownValues || [];
            tempArr[index] = selectedValue;
            setDropdownValues(tempArr);

            // update madatory field Arr
            const tempMandateArr = props.headerMandatoryValues || []
            tempMandateArr[index] = false
            props.updateModalState({
                headerMandatoryValues: tempMandateArr
            })
        };

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={8} offset={4} className='step_head'>File Header</Col>
                    <Col span={8} className='step_head'>Mapping value</Col>
                </Row>
                
                {(props.fileHeader || []).map((head, i) => (
                    <Row gutter={[16, 16]} key={i}>
                        <Col span={8} offset={4}>{head}</Col>
                        <Col span={8}>
                            <Dropdown
                                items={headerValues}
                                onSelect={(selectedValue) => {
                                    handleChange(i, selectedValue);
                                }}
                                value={dropdownValues[i]}
                                placeholder={"Select Fields"}
                            />
                        </Col>

                        <Col span={4} className="required">
                            {props.headerMandatoryValues[i] && props.submitOnce ? "Please select the value" : ""}
                        </Col>
                    </Row>
                ))}
            </>
        );
    };

    const thirdStep = () => {
        return (
            <div className='error-page'>
                {props.isuploded && (props.resData?.SuccessRecords || []).length ?
                    <>
                        {(props.resData?.ErrorRecords || [])?.length ? 
                            <div className='part-1'>We've successfully uploaded a specific number of records. For any data with errors, you can download and re-upload them to ensure accuracy.</div>
                            :
                            ''
                        }
                        <div className='part-2'>
                            <div className='error-page-img'>
                                <img src={successImg} alt="" />
                            </div>
                            <div className='error-info'>
                                <div className='heading-count'>{props.resData?.SuccessRecords?.length}</div>
                                <p>
                                    <b>Great news!</b> Out of the <b>{props.totaData - 1}</b> entries you submitted, <b>{props.resData?.SuccessRecords?.length}</b> have been successfully uploaded. Congratulations on a job well done!
                                </p>
                            </div>
                        </div>
                        {(props.resData?.ErrorRecords || [])?.length ? 
                        
                            <div className='part-3'>
                                <div className='error-page-img'>
                                    <img className='' src={failImg} alt="" />
                                </div>
                                <div className='error-info'>
                                    <div className='heading-count'>{(props.resData?.ErrorRecords || [])?.length}</div>
                                    <p>
                                        Out of <b>{props.totaData - 1}</b>  entries, {(props.resData?.ErrorRecords || []).length} require your attention. No problem! We've compiled the details of these entries for you to review and correct. Simply download the file, make the necessary adjustments, and re upload.
                                    </p>
                                </div>
                                <div className='error-record-btn'>
                                    <button onClick={() => {
                                        props.downloadExelFile({ errorDataList: props.resData?.ErrorRecords, relationshipId: props.companyInfo.relationshipId }, props)
                                    }}>Download Error File</button>
                                </div>
                            </div>
                            :
                            ''
                        }
                    </>
                     : 
                    <>
                        <div className='part-3'>
                            <div className='error-page-img'>
                                <img className='' src={failImg} alt="" />
                            </div>
                            <div className='error-info'>
                                <div className='heading-count'>Opps!</div>
                                <p>
                                    It seems that there was an issue with the entire dataset. No worries, though! Please download the file provided, make the necessary corrections, and re-upload it to ensure accurate data.
                                </p>
                            </div>
                            <div className='error-record-btn'>
                                <button onClick={() => {
                                    props.downloadExelFile({ errorDataList: props.resData?.ErrorRecords, relationshipId: props.companyInfo.relationshipId }, props)
                                }}>Download Error File</button>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }

    const steps = [
        {
            title: "Upload File",
            description:"Upload Your Excel File",
            content: firstStep(),
        },
        {
            title: "Seamless Data Integration",
            description:"Map Your Fields",
            content: secondStep(),
        },
        {
            title: "Review & Confirm",
            description:"Your Data, Your Way",
            content: thirdStep(),
        },
    ];


    return (
        <div className="imports_modal">
            <Steps current={current}>
                {steps.map((item) => (<Step key={item.title} title={item.title} description={item.description} />))}
            </Steps>
            <div className='import_step_content'>{steps[current]?.content}</div>
            <div className="steps-action">
                {current !== 0 && (
                    <Button className='mr10' onClick={() => props.updateModalState({ current: current-1 })}>
                        Previous
                    </Button>
                )}
                {current < steps.length - 1 ? 
                    <Button type="primary" onClick={() => {
                        if (props.fileToUpload) {
                            if (!(current === steps.length - 2)) {
                                props.updateModalState({ current: current+1})
                            }

                            if (current === steps.length - 2) {
                                if (!(props.headerMandatoryValues || []).includes(true)) {
                                    const resultObject = props.fileHeader?.reduce(
                                        (acc, key, index) => {
                                            acc[key] = dropdownValues[index];
                                            return acc;
                                        },
                                        {}
                                    );
                                    const mapedData = {
                                        relationshipId: companyInfo.relationshipId,
                                        mapping: resultObject,
                                    };
                                    props.importCourseCsv(
                                        {
                                            mappedData: JSON.stringify(mapedData || ""),
                                            fileToUpload: props.fileToUpload,
                                        },
                                        props
                                    );
                                } else {
                                    props.updateModalState({
                                        submitOnce: true
                                    })
                                }
                            }

                        } else {
                            props.updateModalState({uploadedOnce: true})
                        }
                    }}>
                        Next
                    </Button>
                    :
                    <Button
                        type="primary"
                        onClick={() => {
                            props.popModalFromStack();
                            const payload = {
                                relationshipId: props.companyInfo.relationshipId,
                                pageNumber: 1,
                                pageSize: props.pageSize || 25
                            }
                            props.getSchoolCourse(payload);
                            props.getSchoolCourseCount(payload);
                        }}
                    >
                        Done
                    </Button>
                }
            </div>
        </div>
    );
}

export default injectIntl(ImportCourses);
