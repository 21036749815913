import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SO_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchSOPackegeDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getSOPackageData?salesOrderMasterId=${payload.salesOrderMasterId || payload.salesOrderMasterIdFromPackage}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&soPackageMasterId=${payload.soPackageMasterId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SO_ACTION_LIST.SO_PACKAGE_DETAIL, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.SO_PACKAGE_DETAIL;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}

export const fetchAmendSOPackegeDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getAmendSOPackageData?amendSalesOrderMasterId=${payload.amendSalesOrderMasterId||0}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&soPackageMasterId=${payload.soPackageMasterId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SO_ACTION_LIST.SO_PACKAGE_DETAIL, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.SO_PACKAGE_DETAIL;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}

export const resetSOPackegeDetail = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.RESET_SO_PACKAGE_DETAIL });
  }
}
export const resetSOPackegeData = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.SO_PACKAGE_DATA_RESET });
  }
}