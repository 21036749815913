import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js";
import { Button } from "antd";
import './stripe.scss';

const CheckoutForm = (props) => {
  
  const {customerDetail={}, proformaDetails={}} = props;

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
      if (!stripe) {
        return;
      }
  
      const clientSecret = props.clientSecret || new URLSearchParams(window.location.search).get("payment_intent_client_secret");

      if (!clientSecret) {
        return;
      }
  
      stripe.retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }) => {
          switch (paymentIntent.status) {
            case "succeeded":
              setMessage("Payment succeeded!");
              break;
            case "processing":
              setMessage("Your payment is processing.");
              break;
            case "requires_payment_method":
              setMessage("Your payment was not successful, please try again.");
              break;
            default:
              setMessage("");
              break;
          }
        });
    }, [stripe]
  );

  
  const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (!stripe || !elements) {
        return;
      }

      setIsLoading(true);

      // const { error } = await stripe.confirmPayment({
      //     elements,
      //     confirmParams: {
      //     // Make sure to change this to your payment completion page
      //     return_url: "https://google.com",
      //     },
      // });

      // // This point will only be reached if there is an immediate error when
      // // confirming the payment. Otherwise, your customer will be redirected to
      // // your `return_url`. For some payment methods like iDEAL, your customer will
      // // be redirected to an intermediate site first to authorize the payment, then
      // // redirected to the `return_url`.
      // if (error.type === "card_error" || error.type === "validation_error") {
      //     setMessage(error.message);
      // } else {
      //     setMessage("An unexpected error occurred.");
      // }

      // setIsLoading(false);

      try {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${window.location.origin}/app/payment-status/stripe`,
          },
        });

        if (error) {
          console.error("Error in confirmPayment:", error);
          if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
          } else {
            setMessage("An unexpected error occurred.");
          }
        }
      } catch (err) {
        console.error("Exception in handleSubmit:", err);
        setMessage("An unexpected error occurred.");
      } finally {
        setIsLoading(false);
      }
  };

  const paymentElementOptions = {
      layout: "tabs",
      defaultValues: {
          billingDetails: {
            name: customerDetail?.companyName,
            email: customerDetail?.boContactList?.find(obj=>obj.isPrimaryContact === 1)?.emailAddress || "johndoe@example.com",
          },
        },
        fields: {
          // billingDetails: 'never',
          billingDetails: {
              name: 'auto',
              email: 'auto',
            }
      },
  }

  return (
    <div className="stripe_form_container">
      <form id="payment-form" onSubmit={handleSubmit}>

        <PaymentElement id="payment-element" options={paymentElementOptions} />

        <Button 
          type="primary" 
          disabled={isLoading || !stripe || !elements} 
          id="submit" 
          className="mt20"
          style={{width: '100%', height: '38px'}} 
          onClick={handleSubmit}
        >
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </Button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}

      </form>
    </div>
  );
};

export default CheckoutForm;