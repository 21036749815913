import axios from "axios";
import config from "../../../../config/environmentConfig";
import {
  COMMON_ACTIONS,
  CUSTOMER_PAYMENT_ACTION_LIST,
  CONSTANTS,
} from "../../../../static/constants";
import {
  showToasterMessage,
  getCurrentDateForBackend,
  formatDateForBackend,
  getFinancialYear,
} from "../../../../utils";
import { fetchSalesInvoices, resetPaginatedData } from "../Listing/action";
import { fetchCustomerPayments } from "../../payment/Listing/action";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const createCustomerPayment = (payloadObject) => {
  const history = payloadObject.history;
  const payload = _getPaymentData(payloadObject);
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPayment/createCustomerPayment`,
        payload
      )
      .then((res) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        payloadObject.hideModal();
        let data = {
          relationshipId: payloadObject.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        };
        fetchCustomerPayments(data)(dispatch);
        if (payload.templateCode && payload.templateCode === "student") {
          history.replace({
            pathname: "/admin/school-management/invoice-list",
            state: {},
          });
        } else {
          history.push("/admin/customer-payment-list");
        }

        showToasterMessage({
          messageType: "success",
          description: "Payment saved successfully",
        });
        resetPaginatedData()(dispatch);
        fetchSalesInvoices(data)(dispatch);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        payloadObject.hideModal();
        payloadObject.hideModal();
        let data = {
          relationshipId: payloadObject.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        };
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || "")] ||
            "Some error occurred",
        });
        resetPaginatedData()(dispatch);
        fetchSalesInvoices(data)(dispatch);
      });
  };
};

const _getPaymentData = (formData) => {
  let payload = {};

  let customerName = formData.customerName;
  let createdByUserId = formData.userInfo.userId || 0;
  let relationshipId = formData.companyInfo.relationshipId || 0;
  let finYear = getFinancialYear(formData.companyInfo);

  payload.paymentDate = formatDateForBackend(formData.paymentDate);
  payload.reference = formData.reference;
  payload.currencyCode = formData.companyInfo.currencyCode;
  payload.createdByEmpId =
    formData.userInfo && formData.userInfo.relationshipEmployeeId;

  let txList = {
    fyStartDate:
      finYear &&
      finYear.fyStartDate &&
      formatDateForBackend(finYear.fyStartDate),
    fyEndDate:
      finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    relationshipId: relationshipId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    txType: "Payment",
    version: formData.version,
    instrumentNumber: formData.paymentModeDetails,
    instrumentDate: formatDateForBackend(formData.paymentDate),
    txDate: formatDateForBackend(formData.paymentDate),
    accountingEntry: "CREDIT",
    paymentModeId: formData.paymentModeId,
    invoiceMasterId: formData.invoiceMasterId,
    remarks: formData.remarks,
    availableCredit: formData.availableCredit,
    amount: Number(formData.amount).toFixed(2),
    paymentModeDetail: formData.paymentModeDetails,
    customerId: formData.customerId,
    customerName: customerName,
    bankDate: formatDateForBackend(new Date()),
    paymentMode: formData.paymentModeName,
    ledgerAccountId: (formData.debtorsLedger || {}).ledgerAccountId,
  };

  var txList1 = {
    relationshipId: relationshipId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    version: formData.version,
    fyStartDate:
      finYear &&
      finYear.fyStartDate &&
      formatDateForBackend(finYear.fyStartDate),
    fyEndDate:
      finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    txType: "Payment",
    instrumentNumber: formData.paymentModeDetails,
    instrumentDate: formatDateForBackend(formData.paymentDate),
    txDate: formatDateForBackend(formData.paymentDate),
    accountingEntry: "DEBIT",
    paymentModeId: formData.paymentModeId,
    remarks: formData.remarks,
    invoiceMasterId: formData.invoiceMasterId,
    availableCredit: formData.availableCredit,
    amount: Number(formData.amount).toFixed(2),
    paymentModeDetail: formData.paymentModeDetails,
    customerName: customerName,
    paymentMode: formData.paymentModeName,
    customerId: formData.customerId,
    bankDate: formatDateForBackend(new Date()),
    ledgerAccountId: formData.chartOfAccountDetailsId,
  };

  var customerTxPayment = {
    relationshipId: relationshipId,
    txAmount: Number(formData.amount).toFixed(2),
    paymentModeDetail: formData.paymentModeDetails,
    fyStartDate:
      finYear &&
      finYear.fyStartDate &&
      formatDateForBackend(finYear.fyStartDate),
    fyEndDate:
      finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    status: "Processed",
    version: formData.version,
    statusColor: " success",
    txDate: formatDateForBackend(formData.paymentDate),
    txType: "Payment",
    invoiceNumber: formData.invoiceData.invoiceNumber,
    invoiceMasterId: formData.invoiceMasterId,
    isMultiCurrency: formData.invoiceData.isMultiCurrency,
    exchangeRate: formData.exchangeRate,
    remarks: formData.remarks,
    invoiceDueDate: formData.invoiceData.invoiceDueDate,
    paymentModeId: formData.paymentModeId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    customerId: formData.customerId,
    soMasterId: formData.invoiceData.salesOrderMasterId,
  };
  payload.customerId = formData.customerId;
  payload.fyStartDate =
    finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate);
  payload.fyEndDate =
    finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate);
  payload.remarks = formData.remarks;
  payload.soNumber = formData.invoiceData.soNumber;
  //txList.ledgerAccountId = $scope.debtorsLedgerAccount && $scope.debtorsLedgerAccount.chartOfAccountDetailsId;
  //txList1.ledgerAccountId = formData.bankledgerId || ($scope.cashInHandLedgerAccount && $scope.cashInHandLedgerAccount.chartOfAccountDetailsId);

  payload.coaTxPaymentsList = [txList, txList1];
  payload.txDate = formatDateForBackend(formData.paymentDate);
  payload.customerTxPaymentList = [customerTxPayment];

  payload.invoiceAmount = formData.invoiceData.invoiceTotalAmount;
  payload.relationshipId = relationshipId;
  payload.chartOfAccountDetailsId = formData.chartOfAccountDetailsId;
  payload.currentBalanceDue = formData.dueAmount - (formData.amount || 0);
  payload.txType = "Payment";
  payload.paymentModeId = formData.paymentModeId;
  payload.paymentModeName = formData.paymentModeName;
  payload.remarks = formData.remarks;
  payload.invoiceMasterId = formData.invoiceMasterId;
  payload.status = "Processed";
  payload.statusColor = " success";
  payload.invoiceNumber = formData.invoiceData.invoiceNumber;
  payload.paymentAmount = Number(formData.amount).toFixed(2);
  payload.paymentModeDetail = formData.paymentModeDetails;
  payload.isMultiCurrency = formData.invoiceData.isMultiCurrency;
  payload.currencyCode = formData.invoiceData.isMultiCurrency
    ? formData.invoiceData.foreignCurrency
    : formData.companyInfo.currencyCode;
  payload.exchangeRate = formData.exchangeRate;
  payload.templateCode = formData.invoiceData.templateCode;
  payload.relationshipName = formData.companyInfo.storeName;
  payload.projectNumber = formData.invoiceData.projectNumber;
  payload.projectName = formData.invoiceData.projectName;
  payload.projectMasterId = formData.invoiceData.projectMasterId;
  payload.customerName = customerName;
  payload.rfqNumber = formData.invoiceData.rfqNumber;
  payload.rfqMasterId = formData.invoiceData.rfqMasterId;
  payload.salesQuotationMasterId = formData.invoiceData.salesQuotationMasterId;
  payload.quotationNumber = formData.invoiceData.quotationNumber;
  payload.paymentSource = CONSTANTS.PAYMENT_SOURCE.MANUAL;
  payload.formId = formData.invoiceData.registrationFormSettingId;
  payload.module = formData.invoiceData.module;

  return payload;
};

export const getNextTxNumber = (payload) => {
  let date = payload.date
    ? getCurrentDateForBackend(payload.date)
    : getCurrentDateForBackend(new Date());
  return (dispatch) => {
    dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.NEXT_PAYMENT_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(
        `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPayment/getNextPaymentNumber?date=${date}&relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: CUSTOMER_PAYMENT_ACTION_LIST.NEXT_PAYMENT_NUMBER,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.NEXT_PAYMENT_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  };
};
