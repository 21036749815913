import React from "react";
import { Drawer, Row, Col, DatePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getMomentDateForUI , getCurrentDateForBackend, showToasterMessage } from '../../../../utils';
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { CONSTANTS } from '../../../../static/constants';
import { FilterOutlined, UndoOutlined,} from '@ant-design/icons';



const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;




const InboundDeliveryFilters = (props) => {
    const { updateState, companyInfo } = props;
    const closeDrawer = () =>{
        props.updateState({
            inboundDeliveryFilterDrawerVisible:false
        })
    }

    const applyFilter = () => {
        // if(!props.selectedRfqNum && !props.startDate && !props.endDate && !props.bidStartDate && !props.bidEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedInvStatusName && !props.selectedPriority && !props.selectedSalesPersonId && !props.selectedRefNum && !props.selectedProjectMasterId ){
            if(!props.selectedSupplierName && !props.selectedGrnNum && !props.selectedPoNum && !props.startDate && !props.endDate && !props.selectedUserStatus && !props.selectedIsInvoiced && !props.selectedRefNum && !props.selectedProjectMasterId ){
                return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
            }
            const payload = {
                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                filters: {
                    supplierId: props.selectedSupplierId,
                    grnNumber: props.selectedGrnNum,
                    startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                    endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                    poNumber: props.selectedPoNum,
                    // status: props.selectedStatus,
                    userStatus: props.selectedUserStatusName,
                    isInvoiced: props.selectedIsInvoicedValue,
                    reference: props.selectedRefNum,
                    projectMasterId: props.selectedProjectMasterId,
                }
            };
            props.fetchAllInboundDeliveriesByFilter(payload);
            props.getInboundDeliveriesCountByFilter(payload);
            closeDrawer();

    }

    const clearFilter = () =>{
        props.updateState({
            selectedSupplierName: null, selectedSupplierId: null,
            selectedGrnNum: null,
            selectedPoNum: null,
            startDate:null, endDate:null,
            // selectedStatus: null, selectedStatusName: null,
            selectedUserStatus: null, selectedUserStatusName: null,
            selectedIsInvoicedValue: null, selectedIsInvoiced: null,
            selectedRefNum: null,
            selectedProjectMasterId: null, selectedProjectName: null
        });
        const payload = {
            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
            filters: {}
        };
        props.fetchAllInboundDeliveries(payload);
        props.getInboundDeliveriesCount(payload);
        closeDrawer();
    }

    const rowGutter = [24, 16];

    return (
        <>
            <Drawer
                title={
                    // <div className="drawer-head-txt">
                    //     <FormattedMessage id='supplier.rfq.filter.drawer.title' />
                    // </div>
                    "Filters"
                }
                width={720}
                zIndex={1}
                maskClosable={false}
                destroyOnClose={true}
                onClose={closeDrawer}
                className="custom-drawer"
                visible={props.inboundDeliveryFilterDrawerVisible}
                footer={<>
                    <CustomButton
                        intlId='confirmation.cancel'
                        defaultMessage=''
                        type='default'
                        key={0}
                        onClick={closeDrawer}
                      />
                      <div>
                        <CustomButton
                            intlId='common.clearFilter.txt'
                            defaultMessage=''
                            type='default'
                            key={0}
                            onClick={clearFilter}
                            btnIcon={<><UndoOutlined /> &nbsp;</>}
                            style={{marginRight: '10px'}}
                        />
                        <CustomButton
                            intlId='common.applyFilter.txt'
                            defaultMessage=''
                            htmlType='submit'
                            onClick={applyFilter}
                            key={1}
                            btnIcon = {<><FilterOutlined /> &nbsp;</>}
                        />
                      </div>
                      </>}
            >

                <Row gutter={rowGutter}>
                <Col span={12}>
                    <div className="i-label">
                        GRN #
                        </div>

                    <TextBox                             
                            placeholder='GRN #'
                            type='text'
                            maxLength={20}
                            countStyle={{top:"40px"}}
                            value={props.selectedGrnNum}
                            onChange={(e) => {
                                props.updateState({ selectedGrnNum : e.target.value });
                            }}
                        />

                    </Col>
                    <Col span={12}>
                        <div className="i-label">Supplier</div>
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}
                            items={props.suppliers[props.pageNumber] || []}
                            sortItems={true}
                            valueKeyName='supplierStoreName'
                            optionKeyName='supplierId'
                            value={props.selectedSupplierName}
                            onSearch={(searchedText) => {
                                props.fetchSuppliers({
                                    isCompact: true,
                                    searchedText: searchedText,
                                    pageNumber: 1, 
                                    hideSpinner: true,
                                    pageSize: 100,
                                    relationshipId: (props.companyInfo || {}).relationshipId
                                })
                            }}
                            placeholder={props.intl.formatMessage({
                                id: 'supplier.filter.name.placeholder', defaultMessage: ''
                            })}

                            onSelect={
                                (supplierId, option) => {
                                    updateState({
                                        selectedSupplierId: option.value,
                                        selectedSupplierName: option.name,
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    selectedSupplierId: undefined,
                                    selectedSupplierName: undefined,
                                })
                            }}
                        />
                        
                    </Col>
                    <Col span={12}>
                        <div className="i-label">GRN Date</div> 
                        <RangePicker                            
                            allowEmpty={true}
                            format={format}
                            placeholder={['GRN Start Date', 'GRN End Date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        />
                    </Col>
                {/* </Row>
                <Row gutter={rowGutter}> */}
                    <Col span={12}>
                        <div className="i-label">PO #</div>
                        <TextBox 
                            placeholder='PO #'
                            type='text'
                            maxLength={20}
                            countStyle={{top:"40px"}}
                            value={props.selectedPoNum}
                            onChange={(e) => {
                                props.updateState({ selectedPoNum : e.target.value });
                            }}
                        />
                    </Col>
                    {/* <Col span={12}>
                        <div className="i-label"><FormattedMessage id='common.status' /></div>
                        <Dropdown
                            items={props.userStatus || []}
                        
                            valueKeyName='statusName'
                            optionKeyName='inboundDeliveryStatusId'
                            statusColorKey='statusColor'
                            value={props.selectedUserStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.status' })}
                            onSelect={(selectedValue, option) => {
                                updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                                
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                            }}
                        />
                    </Col> */}
                {/* </Row>
                <Row gutter={rowGutter}> */}
                    {/* <Col span={12}>
                        <div className="i-label">
                            Invoiced
                            </div>
                        <Dropdown
                            items={[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={props.selectedIsInvoiced}
                            placeholder='Is Invoiced?'
                            onSelect={(status, option) => {
                                updateState({ selectedIsInvoiced: option.name, selectedIsInvoicedValue: option.value  })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedIsInvoiced: 'Yes', selectedIsInvoicedValue: 1 })
                            }}
                        />
                    </Col> */}
                    <Col span={12}>
                        <div className="i-label">
                            {/* <FormattedMessage id='supplier.filter.salePerson.placeholder' /> */}
                            Ref #
                            </div>
                        <TextBox 
                            placeholder={props.intl.formatMessage({
                                id: 'supplier.filter.refNum.placeholder', defaultMessage: ''
                            })}
                            type='text'
                            maxLength={20}
                            countStyle={{top:"40px"}}
                            value={props.selectedRefNum}
                            onChange={(e) => {
                                props.updateState({ selectedRefNum: e.target.value });
                            }}
                        />
                    </Col>
                {/* </Row>
                <Row gutter={rowGutter}> */}
                    <Col span={12}>
                        <div className="i-label">
                            Projects
                            </div>
                        <Dropdown
                            items={props.projectList}
                            sortItems={true}
                            valueKeyName='projectName'
                            optionKeyName='projectMasterId'
                            // projectIdKey='projectMasterId'
                            value={props.selectedProjectName}
                            placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                            }}
                        />
                    </Col>
                   
                                        
                </Row>
            </Drawer>
        </>
    )
};

export default InboundDeliveryFilters;
