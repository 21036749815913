import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SO_ACTION_LIST, PACKAGE_ACTION_LIST, SHIPMENT_ACTION_LIST } from '../../../../static/constants'
import { showToasterMessage, formatDateForBackend, getCurrentDateForBackend } from '../../../../utils';
import * as filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const getShipmentByShipmentId = (payload, history) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/getShipmentByShipmentId?relationshipId=${payload.relationshipId}&shipmentMasterId=${payload.shipmentMasterId}&customerId=${payload.customerId}`)
      .then(res => {
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_DATA, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}

export const resetSoUnShiped = (payload, history) =>{
  return dispatch => {
        dispatch({ type: SO_ACTION_LIST.SO_UN_SHIPPPED_LIST_RESET});
  }
}


export const resetShipmentData = () => {
  return dispatch => {
    dispatch({ type: SHIPMENT_ACTION_LIST.RESET_SHIPMENT_DATA });
  }
}
export const resetCustomerDetail = () => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.CUSTOMER_DETAIL_RESET });
  }
}

export const resetDataTry = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.SO_UNSHIPPED_LIST_PAGE_RESET });
  }
}

export const fetchSoDataForShipment = (payload, history) => {
  return dispatch => {
    let url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getUnshippedPackages?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&status=Packed&pageSize=100&pageNumber=0`;
    if(!payload.customerId){
      url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getUnshippedPackages?relationshipId=${payload.relationshipId}&pageSize=100&pageNumber=0`;
    }
    if(payload.packageMasterIdArr && payload.packageMasterIdArr.length){
      url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getUnshippedPackages?relationshipId=${payload.relationshipId}&pageSize=100&customerId=${payload.customerId}&pageNumber=0&packageMasterIdArr=${payload.packageMasterIdArr}`;
    }
    if(payload.soMasterId){
      url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getUnshippedPackages?relationshipId=${payload.relationshipId}&salesOrderMasterId=${payload.soMasterId}&customerId=${payload.customerId}&status=Packed&pageSize=100&pageNumber=0`
    }
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(url)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SO_UN_SHIPPPED_LIST, data: res.data });
      })
      .catch((err) => {

      })
  }
}

export const fetchSoDataForShipmentData = (payload, history) => {
  return dispatch => {
    let url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getUnshippedPackages?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&status=Packed&pageSize=100&pageNumber=0`;
    if(!payload.customerId){
      url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getUnshippedPackages?relationshipId=${payload.relationshipId}&pageSize=100&pageNumber=0`;
    }
    if(payload.packageMasterIdArr && payload.packageMasterIdArr.length){
      url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getUnshippedPackages?relationshipId=${payload.relationshipId}&pageSize=100&customerId=${payload.customerId}&pageNumber=0&packageMasterIdArr=${payload.packageMasterIdArr}`;
    }
    if(payload.soMasterId){
      url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getUnshippedPackages?relationshipId=${payload.relationshipId}&salesOrderMasterId=${payload.soMasterId}&customerId=${payload.customerId}&status=Packed&pageSize=100&pageNumber=0`
    }
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(url)
      .then(res => {
        if(payload.callBack){
          payload.callBack(res.data);
        }
        dispatch({ type: SO_ACTION_LIST.SO_DRAFT_LIST_PAGE_TRY, data: res.data });
      })
      .catch((err) => {

      })
  }
}

export const fetchAllCarriers = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/shipmentCarrier/getAllCarriers?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.CARRIER_LIST, data: res.data });
      })
      .catch((err) => {

      })
  }
}

export const deleteCarrierName = (payload) => {
  const requestObj = {
    carrierId: payload.id,
    relationshipId: payload.relationshipId,
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/shipmentCarrier/deleteCarrier`, requestObj)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.DELETE_CARRIER_NAME, data: { id: payload.id } })
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        if (err.__isRetryRequest) {
          err.actionToCall = SO_ACTION_LIST.DELETE_CARRIER_NAME;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
        }
      })
  }
}

export const addCarrierName = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    carrierName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/shipmentCarrier/insertCarrier`, requestObj)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.ADD_CARRIER_NAME, data: res.data })
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        if (err.__isRetryRequest) {
          err.actionToCall = SO_ACTION_LIST.INDUSTRY_LIST_ADDITION;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
        }
      })
  }
}


export const fetchAllFreightType = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/freightType/getAllFreightTypes?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.FRIEGHT_LIST, data: res.data });
      })
      .catch((err) => {
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const deleteFreightType = (payload) => {
  const requestObj = {
    freightTypeId: payload.id,
    relationshipId: payload.relationshipId,
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/freightType/deleteFreightType`, requestObj)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.DELETE_FRIEGHT_TYPE, data: { id: payload.id } })
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        if (err.__isRetryRequest) {
          err.actionToCall = SO_ACTION_LIST.DELETE_FRIEGHT_TYPE;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
        }
      })
  }
}

export const addFreightType = (payload,props) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    freightTypeName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/freightType/insertFreightType`, requestObj)
      .then(res => {
        
        dispatch({ type: SO_ACTION_LIST.ADD_FRIEGHT_TYPE, data: res.data })

        const payload = {
         
          relationshipId: requestObj.relationshipId,
         
        };
        fetchAllFreightType(payload)
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        if (err.__isRetryRequest) {
          err.actionToCall = SO_ACTION_LIST.ADD_FRIEGHT_TYPE;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || "")] || 'Some error occurred'
          })
        }
      })
  }
}


export const createShipment = (payload, history) => {
  return dispatch => {
    const props = payload.props;
    const saleOrderShipmentData = _getShipmentData(props);
    let formData = new FormData();
    if (props.fileList && props.fileList.length) {
      props.fileList.forEach((file)=>{
        formData.append('files', file, file.name);
      })
    }
    formData.append('shipment', JSON.stringify(saleOrderShipmentData, true));
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
        // TODO need to review and remove
        dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER });
    let url = saleOrderShipmentData.amendSalesOrderMasterId ?`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/createAmendShipment`:`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/createShipment`
    return axios
      .post(url, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        showToasterMessage({ messageType: 'success', description: lang['common.saved.success'] })
        dispatch({ type: SO_ACTION_LIST.SO_LIST_PAGE_RESET });
        dispatch({ type: PACKAGE_ACTION_LIST.PACKAGE_LIST_PAGE_RESET });
        
        // props.history.push('/admin/so-list');
        // props.fetchAllShipment(props)
        props.popModalFromStack();
        props.openDeliveryNotesDetails(res.data);
        props.fetchSalesOrders(props);
      })
      .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
      })
      .finally(()=>{
            // TODO need to review and remove
            dispatch({ type: COMMON_ACTIONS.TEMP_TXN_LODER_STOP });
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}


const _getShipmentData = (props) => {
  
  let freightObj = (props.freightList.find(freight => (freight.freightTypeId === props.selectedFreighType))) || {};
  let carrierObj = (props.carrierList.find(carrier => (carrier.carrierId === props.selectedCarrier))) || {};
  let customerSOShipmentDetailsList =  [];
  const selectedPackageData =props.shipmentMasterId ?props.packageListById?.filter((e)=>props.selectedPackages.indexOf(e.packageNumber) !== -1):  props.dataTry?.filter((e)=>props.selectedPackages.indexOf(e.packageNumber) !== -1);
  
  if (selectedPackageData) {
    selectedPackageData.forEach((obj) => {
      (obj.customerSOPackageDetailsList || []).forEach((detailsList) => {
        detailsList.salesOrderNumber = obj.salesOrderNumber;
        detailsList.amendSalesOrderNumber = obj.amendSalesOrderNumber;
        detailsList.packageNumber = obj.packageNumber;
        detailsList.shipmentMasterId = obj.shipmentMasterId;
        detailsList.version= props.version;
        detailsList.packageMasterId = obj.soPackageMasterId;
        detailsList.relationshipId = (props.companyInfo || {}).relationshipId;
        customerSOShipmentDetailsList.push(detailsList);
      });
    });
  }
  customerSOShipmentDetailsList.forEach((e) =>{
    e.customerShipmentCOATxList = e.customerShipmentCOATxList || e.customerPackageCOATxList;
    delete e.customerPackageCOATxList;
  });
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const payload = {
    shipmentMasterId: props.shipmentMasterId,
    shipmentNumber: props.shipmentNumber,
    version: props.version,
    carrierName: carrierObj.carrierName,
    consigneeName : props.consigneeName ,
    freightTypeId: props.freightTypeId,
    freightType: freightObj.freightTypeName,
    carrierId: props.carrierId,
    trackingNumber: props.trackingNumber,
    relationshipId: (props.companyInfo || {}).relationshipId,
    boLocationShipmentList: [],
    packageMasterId: props.soPackageMasterId,
    // soMasterId: props.soMasterId,
    customerInquiryNumber:props.soPackageDetail?.customerInquiryNumber,
    customerRFQNumber:props.soPackageDetail?.customerRFQNumber,
    customerPONumber:props.soPackageDetail?.customerPONumber,
    customerId: props.customerId,
    shipmentDate: props.shipmentDate && formatDateForBackend(props.shipmentDate),
    expectedDeliveryDate: props.expectedDate && formatDateForBackend(props.expectedDate),
    remarksInternal: props.remarksInternal,
    remarksCustomer: props.remarksCustomer,
    isStockUpdated: props.isStockUpdated,
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    customerName: (props.customerDetail || {}).companyName,
    documentName: props.docName, 
    customerSOShipmentDetailsList,
    length: props.length,
    width: props.width,
    height: props.height,
    dimensionUnit: props.dimensionUnit,
    weight: props.weight,
    weightUnit: props.weightUnit,
    measurementUnit: props.measurementUnit,
    soMasterId: props.isToShowAllPackage ? null : props.soMasterId,
    amendSalesOrderMasterId: props.isToShowAllPackage ? null : props.amendSalesOrderMasterId,
    referenceNumber: props.referenceNumber,
  };
  if (props.billingAddress && props.billingAddress.streetAddress1) {
   // props.billingAddress.dateCreated = getCurrentDateForBackend(new Date());
   delete props.billingAddress.version;
    payload.boLocationShipmentList.push(props.billingAddress);
  } 
  if (props.shippingAddress && props.shippingAddress.streetAddress1) {
    delete props.shippingAddress.version;
   // props.shippingAddress.dateCreated = getCurrentDateForBackend(new Date());
    payload.boLocationShipmentList.push(props.shippingAddress);
  } 
  if (props.relationshipBillingAddress && props.relationshipBillingAddress.streetAddress1) {
    delete props.relationshipBillingAddress.version;
   // props.relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
    payload.boLocationShipmentList.push(props.relationshipBillingAddress);
  }

  let additionalInfo = {};
  
  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact }
  }
  if (props.customer) {
    additionalInfo.customerId = props.customer && props.customer.customerId;
    additionalInfo.customerPhone = props.customer && props.customer.customerPhone;
    additionalInfo.customerEmail = props.customer && props.customer.customerEmail;
    additionalInfo.gstNumber = props.customer.gstNumber;
    additionalInfo.panNumber = props.customer.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
  }

  delete additionalInfo.additionalInfoId;
  payload.additionalInfoList = [additionalInfo];

  if (props.qualityCheckList && props.qualityCheckList.length) {
    let checkList = [];
    props.qualityCheckList.forEach((obj) => {
      obj.customerId =  props.customerId;
      obj.relationshipId = companyInfo && companyInfo.relationshipId;
      obj.soMasterId = props.soMasterId;
      checkList.push(obj);
    });
    payload.qualityCheckList = checkList;
  }

  return payload;
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: SHIPMENT_ACTION_LIST.NEXT_SHIPMENT_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/getNextShipmentNumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SHIPMENT_ACTION_LIST.NEXT_SHIPMENT_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          err.actionToCall = SHIPMENT_ACTION_LIST.NEXT_SHIPMENT_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}

export const getPackagedataByIds = (payload, history) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getByRelationshipIdAndSoPackageMasterIds?relationshipId=${payload.relationshipId}&soPackageMasterId=${payload.packageMasterIdArr}`)
      .then(res => {
        dispatch({ type: PACKAGE_ACTION_LIST.PACKAGE_DATA_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}

export const reetPackagedataByIds = (payload, history) =>{
  return dispatch => {

        dispatch({ type: PACKAGE_ACTION_LIST.RESET_PACKAGE_DATA_LIST, data: [] });
 
  }
}