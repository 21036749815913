import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import  PaymentComponent from '../../../components/integrations/Payment'
import { getPaypalConfiguration, getStripeConfiguration, saveConfiguration, 
    disconnectConfiguration, generatePlaidLinkToken, setAccessToken, 
    getStripeAccountOnboardingLink, savePaypalToken, generatePaypalAuthLink, 
    saveStripeAccount, fetchAvailablePaymentGateway, updateName, saveCardPointConfiguration, getCardConnectConfiguration, saveStripeConfiguration,
    saveRazorpayConfiguration, generateRazorpayAuthLink, createConnectedAccount, createAccountLink} from './action'
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import queryString from 'query-string';
import * as filter from 'lodash.filter';

class Payment extends Component {
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const linkProps = queryString.parse(this.props.location?.search) || {}
        const payload = {
            code: this.props.code || linkProps.code,
            relationshipId: companyInfo.relationshipId,
            accountId: this.props.accountId || linkProps.accountId,
            state: this.props.state || linkProps.state,
        };
        this.props.fetchAvailablePaymentGateway(payload);
        // payload.code && this.props.savePaypalToken(payload, this.props);
        // this.props.getPaypalConfiguration({...payload, gatewayName: 'paypal'});
        // this.props.getPaypalConfiguration({...payload, gatewayName: 'paypal-kiosk'});
        // this.props.generatePlaidLinkToken();
         this.props.getStripeConfiguration({...payload, gatewayName: 'Stripe'});
         this.props.getCardConnectConfiguration({...payload, gatewayName: 'CardPointe'});
        (linkProps.gatewayName==='stripe' && payload.accountId) && this.props.saveStripeAccount(payload, this.props);
        (linkProps.code && linkProps.state && linkProps.state === "razorpay") && this.props.saveRazorpayConfiguration(payload, this.props);
    }
    componentWillReceiveProps(props) {
        const cardPointGatewayList = (props.availablePaymentGateways && filter(props.availablePaymentGateways, { paymentGatewayName: 'CardPointe' })) || [];
        const AuthorizeNetGatewayList = (props.availablePaymentGateways && filter(props.availablePaymentGateways, { paymentGatewayName: "Authorize.net" })) || [];
        const RazorpayGatewayList = (props.availablePaymentGateways && filter(props.availablePaymentGateways, { paymentGatewayName: "Razorpay" })) || [];
        const StripeGatewayList = (props.availablePaymentGateways && filter(props.availablePaymentGateways, { paymentGatewayName: "Stripe" })) || [];
        this.setState({ 
            cardPointGatewayList, 
            AuthorizeNetGatewayList,
            RazorpayGatewayList,
            StripeGatewayList
        });

    
    }

    render() {
        return <PaymentComponent {...this.props} {...this.state} updateState = {(data)=>{this.setState(data)}}/>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        paypalConfigData: state.common.paypalConfigData,
        paypalDirectConfigData: state.common.paypalDirectConfigData,
        plaidLinkToken: state.integration.plaidLinkToken,
        stripeConfigData: state.common.stripeConfigData,
        availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
        cardConnectConfigData: state.common.cardConnectConfigData,
        saveLoading: state.common.saveLoading,
        permissions: state.auth.permissions,
        listLoading: state.common.listLoading,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPaypalConfiguration, 
    saveConfiguration, 
    disconnectConfiguration,
    generatePlaidLinkToken,
    getStripeConfiguration,
    setAccessToken,
    getStripeAccountOnboardingLink,
    savePaypalToken,
    generatePaypalAuthLink,
    saveStripeAccount,
    fetchAvailablePaymentGateway,
    updateName,
    showModal, hideModal, pushModalToStack, popModalFromStack,
    getCardConnectConfiguration,
    saveCardPointConfiguration,
    saveStripeConfiguration,
    saveRazorpayConfiguration,
    generateRazorpayAuthLink,
    createConnectedAccount,
    createAccountLink
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
