import { Card, Col, Row, Tabs, Collapse, Checkbox, Button, Input } from "antd";
import React, { Fragment, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
import "./menus.scss";
import ArrowDown from "../../../assets/images/arrow-down.png";
import ArrowUp from "../../../assets/images/arrow-up.png";
import EqualIcon from "../../../assets/images/equal.png";
import * as find from "lodash.find";
import { v4 as uuidv4 } from "uuid";
import { MODULE_CODED_VALUES } from "../../../static/constants";
import { getPermissionData } from "../../../utils";
import CuratedMenu from "./curatedMenuModal";
import UploadImageModal from "../CommonComponents/UploadImageModal";
import ImageUploadComp from "../../../containers/eStore/CommonComponent/ImageUploadComp";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const FooterItems = (props) => {
  const { pageNumber, allPageListData = [] } = props;

  const { permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionData = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_APPEARANCE);
  }, [])



  const [, setUpdatedDisplayName] = useState("");
  const [customLinkData, setCustomLinkData] = useState({
    customlink: "",
    customLinkName: "",
  });

  const [childOnList, setChildOn] = useState([]);

  const isChildOn = (id) => {
    if (childOnList.includes(id)) {
      return true;
    }
    return false;
  };
  const setChildOff = (data) => {
    // const itemIndex = childOnList.indexOf(data);
    // const newArr = childOnList.splice(itemIndex, 1);
  };

  const handleRemove = (itemToRemove) => {
    const updatedSelectedItems = props.selectedFooterCategoryItems.filter(
      (item) => item !== itemToRemove
    );
    props.updateState({
      selectedFooterCategoryItems: updatedSelectedItems,
    });
  };


  const updateCategoryName = (categoryId, newName) => {
    return props.selectedFooterCategoryItems.map((item) =>
      item.inventoryItemCategoryId === categoryId || item.itemId === categoryId || item.ecomHeaderPageId === categoryId
        ? { ...item, itemName: newName }
        : item
    );
  };

  // Update the category name and selectedFooterCategoryItems
  const handleUpdateName = (categoryId, displayName) => {

    if (displayName) {
      const updatedItems = updateCategoryName(categoryId, displayName);
      props.updateState({
        selectedFooterCategoryItems: updatedItems,
      });
      setUpdatedDisplayName("");
    }
  };
  const handleSave = () => {

    const selectedItems = props.selectedFooterCategoryItems;
    const pages = selectedItems.filter((item) => item.type === "Pages");
    const products = selectedItems.filter((item) => item.type === "Products");
    const categories = selectedItems.filter((item) => item.type === "Categories");
    const subcategories = selectedItems.filter((item) => item.type === "Sub Category");
    const customLinks = selectedItems.filter((item) => item.type === "Custom Link");
    const extraSection = selectedItems.filter((item) => item.type === "blog");
    const flags = selectedItems.filter((item) => item.type === "Flags");

    const categoryFooterGroup = props.categoryFooterGroup;

    const items = {
      "products": products,
      "categories": categories,
      "flags": flags,
      "sub_categories": subcategories,
      "pages": pages,
      "custom_links": customLinks,
      "additional_menu": extraSection,
      categoryFooterGroup: categoryFooterGroup
    }
    const payload =
    {
      ecomSettingSave: {
        relationshipId: props.companyInfo.relationshipId,
        isActive: 1,
        ecomSettingId: props.storeValues.ecomSettingId,
        version: props.storeValues.version,
        headerMenu: props.storeValues?.headerMenu,
        footerMenu: JSON.stringify({
          "footerMenu": items
        })
      }


    }


    props.updateOrCreateEcomSettings(payload, props);
  };

  const handleInputChange = (e, field) => {
    const updatedData = { ...customLinkData, [field]: e.target.value };
    setCustomLinkData(updatedData);
  };

  const handleAddToMenu = () => {
    customLinkData.type = "Custom Link";
    customLinkData.id = uuidv4();

    props.updateState({
      selectedFooterCategoryItems: [...props?.selectedFooterCategoryItems || [], customLinkData],
    });
  };

  const getsubcategories = (subcategories, index) => {
    return (
      subcategories &&
      subcategories.map((childRowData) => {
        return (
          <>
            <Col
              span={24}
              key={childRowData.inventoryItemCategoryId}
              style={{ display: "flex", marginLeft: "20px" }}
            >
              <Checkbox
                onChange={(e) => {
                  handleCheckCategory(e);
                }}
                value={childRowData.inventoryItemCategoryId}
              >
                <div className="category-name-style">
                  {childRowData.categoryName}
                </div>
              </Checkbox>
              <div onClick={subCategoryListHandler}>
                {childRowData.subcategories &&
                  childRowData.subcategories.length ? (
                  isChildOn(childRowData.inventoryItemCategoryId) ? (
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setChildOff(childRowData.inventoryItemCategoryId);
                      }}
                    >
                      <img
                        className="img-Icon"
                        src={ArrowUp}
                        alt="arrow"
                      />
                    </span>
                  ) : (
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setChildOn([
                          ...childOnList,
                          childRowData.inventoryItemCategoryId,
                        ]);
                      }}
                    >
                      <img
                        className="img-Icon"
                        src={ArrowDown}
                        alt="arrow"
                      />
                    </span>
                  )
                ) : (
                  ""
                )}
              </div>
            </Col>
            {childRowData.subcategories &&
              childRowData.subcategories.length &&
              isChildOn(childRowData.inventoryItemCategoryId)
              ? getsubcategories(childRowData.subcategories, index + 1)
              : ""}
          </>
        );
      })
    );
  };
  const handleCheckAdditionalSection = (e) => {

    let selectedObjects = props.selectedFooterCategoryItems || [];
    if (e.target.checked) {
      const blog = find(props.AdditionalSection || [], {
        blogId: Number(e.target.value),
      });
      blog.type = "blog";
      selectedObjects.push(blog);
    } else {
      let indexOfObject = selectedObjects.findIndex((object) => {
        return object?.blogId === Number(e.target.value);
      });
      selectedObjects.splice(indexOfObject, 1);
    }
    props.updateState({
      selectedFooterCategoryItems: selectedObjects,
    });

  }
  const handleCheckProduct = (e) => {
    let selectedObjects = props.selectedFooterCategoryItems || [];
    if (e.target.checked) {
      const prod = find(props.products[pageNumber] || [], {
        itemId: Number(e.target.value),
      });
      prod.type = "Products";
      selectedObjects.push(prod);
    } else {
      let indexOfObject = selectedObjects.findIndex((object) => {
        return object?.itemId === Number(e.target.value);
      });
      selectedObjects.splice(indexOfObject, 1);
    }
    props.updateState({
      selectedFooterCategoryItems: selectedObjects,
    });

  };

  const handleCheckPage = (e) => {
    let selectedObjects = props.selectedFooterCategoryItems || [];


    if (e.target.checked) {
      const prod = find(allPageListData || [], {
        ecomHeaderPageId: Number(e.target.value),
      });

      prod.type = "Pages";
      selectedObjects.push(prod);
    }
    else {
      let indexOfObject = selectedObjects.findIndex((object) => {
        return object?.ecomHeaderPageId === Number(e.target.value);
      });
      selectedObjects.splice(indexOfObject, 1);
    }
    props.updateState({
      selectedFooterCategoryItems: selectedObjects,
    });
  }

  const handleCheckCategory = (e) => {
    let selectedObjects = props.selectedFooterCategoryItems || [];
    if (e.target.checked) {
      let cat = find(props.productCategories || [], {
        inventoryItemCategoryId: Number(e.target.value),
      });
      cat.type = "Categories";
      selectedObjects.push(cat);
    } else {
      let indexOfObject = selectedObjects.findIndex((object) => {
        return object.inventoryItemCategoryId === Number(e.target.value);
      });
      selectedObjects.splice(indexOfObject, 1);
    }
    props.updateState({
      selectedFooterCategoryItems: selectedObjects,
    });
  };

  const handleCheckFlags = (e) => {

    let selectedObjects = props.selectedFooterCategoryItems || [];


    if (e.target.checked) {
      let flag = find(props.footerFilters || [], {
        flagId: Number(e.target.value),
      });


      flag.type = "Flags"
      flag.isVisible = true;
      selectedObjects.push(flag);
    }
    else {
      let indexOfObject = selectedObjects.findIndex((object) => {
        return object.flagId === Number(e.target.value);
      });
      selectedObjects.splice(indexOfObject, 1);
    }

    props.updateState({
      selectedFooterCategoryItems: selectedObjects,
    });
  }
  function onTabChange(key, props) {
    switch (key) {
      case "1":
        props.updateState({ menuFooterType: "standard", activeTabKey: "1" });
        break;
      case "2":
        props.updateState({ menuFooterType: "mega", activeTabKey: "2" });
        break;
      default:
        break;
    }
  }

  const handleAddToMenuCategoryGroup = () => {
    const groupList = [...props.categoryFooterGroup]; // Create a copy of the group list
    groupList.push({ ...props.categoryFooterData, type: "Category Group" }); // Add the new data to the copied list


    props.updateState({
      categoryFooterGroup: groupList,
      categoryData: {}
    });
  };
  const subCategoryListHandler = () => { };
  return (
    <div className="store-model-container-f">
      <Tabs
        activeKey={props.activeTabKey || "1"} onChange={(key) => { onTabChange(key, props); }}
        type="line"
      >

        <TabPane tab={"Standard"} key="1">

        </TabPane>

        <TabPane tab={"Mega"} key="2">

        </TabPane>
      </Tabs>

      <div className="menuEstore-Container" style={{ position: "relative" }}>
        <Row>
          <Col lg={6} xxl={4}>
            <Card className="menu-list-card">
              <div className="card-header">
                <h2>Footer items</h2>
              </div>

              <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={["0"]}
                className="collapse-container"
              >
                <Panel header={"Pages"} key="1">
                  <div className="menu-list-card-inner-wrapper">
                    <div>
                      <Checkbox.Group
                        style={{
                          width: "100%",
                          height: "210px",
                          overflowY: "scroll",
                        }}

                      >
                        <Row >
                          {allPageListData.length > 0 && allPageListData.map((list, index) => {
                            return (
                              <>
                                <Col
                                  span={24}
                                  key={index}

                                >
                                  <Checkbox
                                    value={list.ecomHeaderPageId}

                                    onChange={(e) => {
                                      handleCheckPage(e);
                                    }}
                                  >
                                    <div className="category-name-style">
                                      <a href={list.pageLink} style={{ color: "black" }}> {list.pageTitle}</a>

                                    </div>
                                  </Checkbox>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                      </Checkbox.Group>
                    </div>
                  </div>
                </Panel>
                <Panel header={"Custom link"} key="2">
                  <Row>
                    <Col span={24}>
                      <div className="i-label">
                        Link
                        <span className="required">*</span>
                      </div>
                    </Col>
                    <Col span={24}>
                      <Input
                        onChange={(e) =>
                          handleInputChange(e, "customlink")
                        }
                        value={customLinkData.customlink}
                        placeholder="Enter Name"
                      />
                    </Col>

                    <Col span={24} className="mt10">
                      <div className="i-label">
                        Name
                        <span className="required">*</span>
                      </div>
                    </Col>
                    <Col span={24}>
                      <Input
                        onChange={(e) =>
                          handleInputChange(e, "customLinkName")
                        }
                        value={customLinkData.customLinkName}
                        placeholder="Enter Name"
                      />
                    </Col>
                  </Row>

                  <Row className="mt10">
                    <Button
                      onClick={() => {
                        handleAddToMenu();
                      }}
                      type="primary"
                    >
                      Add to Menu
                    </Button>
                  </Row>
                </Panel>
                <Panel header={"Products"} key="3">
                  <div className="menu-list-card-inner-wrapper">
                    <div>
                      <Checkbox.Group
                        style={{
                          width: "100%",
                          height: "210px",
                          overflowY: "scroll",
                        }}
                      //  onChange={hanleCheckOnChangeProducts}
                      >
                        <Row gutter={[0, 5]}>
                          {(
                            (props.products[pageNumber] &&
                              props.products[pageNumber]) ||
                            []
                          ).map((product, index) => {
                            return (
                              <>
                                <Col
                                  span={24}
                                  key={product.itemId}
                                  style={{ display: "flex" }}
                                >
                                  <Checkbox
                                    value={product.itemId}
                                    onChange={(e) => {
                                      handleCheckProduct(e);
                                    }}
                                  >
                                    <div className="category-name-style">
                                      {product.itemName}
                                    </div>
                                  </Checkbox>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                      </Checkbox.Group>
                    </div>
                  </div>
                </Panel>
                <Panel header={"Categories"} key="4">
                  <div className="menu-list-card-inner-wrapper">
                    <div>
                      <Checkbox.Group
                        style={{
                          width: "100%",
                          height: "210px",
                          overflowY: "scroll",
                        }}
                      >
                        <Row gutter={[0, 5]}>
                          {(props?.productCategories || [])
                            .filter((rowData) => !rowData.parentId)
                            .map((category, index) => (
                              <>
                                <Col
                                  span={24}
                                  key={category.inventoryItemCategoryId}
                                  style={{ display: "flex" }}
                                >
                                  <Checkbox
                                    value={
                                      category.inventoryItemCategoryId
                                    }
                                    onChange={(e) => {
                                      handleCheckCategory(e);
                                    }}
                                  >
                                    <div className="category-name-style">
                                      {category.categoryName}
                                    </div>
                                  </Checkbox>
                                  <div onClick={subCategoryListHandler}>
                                    {category.subcategories &&
                                      category.subcategories.length ? (
                                      isChildOn(
                                        category.inventoryItemCategoryId
                                      ) ? (
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setChildOff(
                                              category.inventoryItemCategoryId
                                            );
                                          }}
                                        >
                                          <img
                                            className="img-Icon"
                                            src={ArrowUp}
                                            alt="arrow"
                                          />
                                        </span>
                                      ) : (
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setChildOn([
                                              ...childOnList,
                                              category.inventoryItemCategoryId,
                                            ]);
                                          }}
                                        >
                                          <img
                                            className="img-Icon"
                                            src={ArrowDown}
                                            alt="arrow"
                                          />
                                        </span>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </Col>
                                {category.subcategories &&
                                  category.subcategories.length &&
                                  isChildOn(
                                    category.inventoryItemCategoryId
                                  )
                                  ? getsubcategories(
                                    category.subcategories,
                                    index + 1
                                  )
                                  : ""}
                              </>
                            ))}
                        </Row>
                      </Checkbox.Group>
                    </div>
                  </div>
                </Panel>
                <Panel header={"Flag"} key="5">
                  <div className="menu-list-card-inner-wrapper">
                    <div>
                      {

                      }
                      <Row>
                        {props.footerFilters?.map((item, i) => {
                          return <Col key={i}
                            span={24}
                          >
                            <Checkbox
                              checked={item.isVisible}
                              value={item.flagId}
                              onChange={(e) => {
                                const arr = props.footerFilters.map(val => (val.flagId === e.target.value) ? { ...val, isVisible: !(val.isVisible) } : val)
                                props.updateState({ footerFilters: arr })

                                handleCheckFlags(e);
                              }}

                            >
                              <div className="category-name-style">
                                <span>{item.label}</span>
                              </div>
                            </Checkbox>
                          </Col>
                        })}


                      </Row>

                    </div>
                  </div>
                </Panel>
                <Panel header={"Additional Links"} key="6">
                  <div className="menu-list-card-inner-wrapper">
                    <div>
                      {

                      }
                      <Row>
                        {props.AdditionalSection?.map((item, i) => {
                          return <Col key={i}
                            span={24}
                          >
                            <Checkbox
                              value={item.blogId}

                              onChange={(e) => {
                                handleCheckAdditionalSection(e);
                              }}

                            >

                              <div className="category-name-style">
                                <span>{item.label}</span>
                              </div>
                            </Checkbox>
                          </Col>
                        })}


                      </Row>

                    </div>
                  </div>
                </Panel>
                {props.menuFooterType == "mega" ? (
                  <Panel header={"Section"} key="7">
                    <Row>
                      <Col span={24}>
                        <div className="i-label">
                          Group Name
                          <span className="required">*</span>
                        </div>
                      </Col>
                      <Col span={24}>
                        <Input
                          onChange={(e) =>
                            props.updateState({
                              categoryFooterData: {
                                ...props.categoryFooterData,
                                groupName: e.target.value,
                              },
                            })
                          }
                          value={props.categoryFooterData?.groupName || ""}
                          placeholder="Enter Group Name"
                        />
                      </Col>

                      {props.categoryFooterData?.list?.length > 0 &&
                        props.categoryFooterData.list.map((menuItem, index) => (
                          <React.Fragment key={index}>
                            <Col span={24}>
                              <div className="i-label">
                                Sub Menu Heading
                                <span className="required">*</span>
                              </div>
                            </Col>
                            <Col span={24} className="mt10">
                              <Input
                                onChange={(e) => {
                                  const updatedList = [...props.categoryFooterData.list];
                                  updatedList[index] = {
                                    ...updatedList[index],
                                    subMenuHeading: e.target.value,
                                  };
                                  props.updateState({
                                    categoryFooterData: { ...props.categoryFooterData, list: updatedList },
                                  });
                                }}
                                value={menuItem.subMenuHeading || ""}
                                placeholder="Enter Sub Menu Heading"
                              />
                            </Col>
                            <Col span={24}>
                              {"Curated Menu"}
                            </Col>
                            <Col span={24}>
                              {menuItem.curatedMenu?.length ? (
                                <Input
                                  onFocus={() => {
                                    props.pushModalToStack({
                                      modalBody: (
                                        <CuratedMenu
                                          {...props}
                                          data={menuItem.curatedMenu}
                                          callBack={(data) => {
                                            const updatedList = [...props.categoryFooterData.list];
                                            updatedList[index] = {
                                              ...updatedList[index],
                                              curatedMenu: data,
                                            };
                                            props.updateState({
                                              categoryFooterData: { ...props.categoryFooterData, list: updatedList },
                                            });
                                          }}
                                        />
                                      ),
                                      title: "Curated Menu",
                                      width: "70%",
                                      wrapClassName: "cat-modal-custom",
                                      hideTitle: false,
                                      hideFooter: true,
                                    });
                                  }}
                                  value={menuItem.curatedMenu
                                    .map(
                                      (item) =>
                                        item.displayName ||
                                        item.itemName ||
                                        item.categoryName ||
                                        item.customLinkName ||
                                        item.pageTitle ||
                                        item.label
                                    )
                                    .join(", ")}
                                />
                              ) : (
                                <Button
                                  onClick={() => {
                                    props.pushModalToStack({
                                      modalBody: (
                                        <CuratedMenu
                                          {...props}
                                          callBack={(data) => {
                                            const updatedList = [...props.categoryFooterData.list];
                                            updatedList[index] = {
                                              ...updatedList[index],
                                              curatedMenu: data,
                                            };
                                            props.updateState({
                                              categoryFooterData: { ...props.categoryFooterData, list: updatedList },
                                            });
                                          }}
                                        />
                                      ),
                                      title: "Curated Menu",
                                      width: "70%",
                                      wrapClassName: "cat-modal-custom",
                                      hideTitle: false,
                                      hideFooter: true,
                                    });
                                  }}
                                  type="secondary"
                                >
                                  Add Curated Menu
                                </Button>
                              )}
                            </Col>
                          </React.Fragment>
                        ))}

                      <Col
                        span={12}
                        className="cursor-pointer"
                        onClick={() => {
                          const updatedList = props.categoryFooterData?.list || [];
                          updatedList.push({});
                          props.updateState({
                            categoryFooterData: { ...props.categoryFooterData, list: updatedList },
                          });
                        }}
                      >
                        Add
                      </Col>
                      <Col
                        span={12}
                        className="cursor-pointer"
                        onClick={() => {
                          const updatedList = props.categoryFooterData?.list || [];
                          updatedList.pop();
                          props.updateState({
                            categoryFooterData: { ...props.categoryFooterData, list: updatedList },
                          });
                        }}
                      >
                        Remove
                      </Col>
                    </Row>

                    <Row className="mt10">
                      <Button
                        onClick={() => {
                          handleAddToMenuCategoryGroup();
                        }}
                        type="primary"
                      >
                        Add Group To Menu
                      </Button>
                    </Row>
                  </Panel>
                ) : (
                  ""
                )}
              </Collapse>
            </Card>
          </Col>
          <Col lg={18} xxl={20}>
            <Card className="header-structure-card">
              <div className="card-header">
                <h2>Footer items</h2>
              </div>

              <div className="menuEstore-structure-card-content">
                {props.selectedFooterCategoryItems?.length ? (props.selectedFooterCategoryItems || []).map(
                  (selectedItems, index) => {
                    return (
                      <div>
                        {
                          (!(selectedItems.type === "Flags") || selectedItems?.isVisible) &&
                          (<Row style={{ marginTop: "10px" }}>
                            <Col lg={16} xxl={10}>
                              <Collapse
                                accordion
                                expandIconPosition="right"
                                activeKey={props.activeKey || 0}
                                // defaultActiveKey={["0"]}
                                onChange={(key) => {
                                  props.updateState({
                                    activeKey: key
                                  })
                                }}
                              // className="collapse-container"
                              >
                                <Panel
                                  key={index}
                                  header={
                                    <div className="header-structure-card-collapse-header">
                                      <div className="header-structure-card-collapse-header-child">
                                        <span>
                                          <img
                                            className="img-Icon"
                                            src={EqualIcon}
                                            alt="icon"
                                          />
                                        </span>
                                        <span className="collapse-header-right">
                                          <span className="">
                                            {
                                              selectedItems.itemName || selectedItems.categoryName ||
                                              selectedItems.customLinkName ||
                                              selectedItems.pageTitle || selectedItems.label
                                            }
                                          </span>
                                        </span>
                                      </div>

                                      <div>
                                        {selectedItems?.type}
                                      </div>
                                    </div>
                                  }
                                >
                                  <Row>
                                    {selectedItems?.customlink && (
                                      <Col span={24} className="mb10">
                                        <Col span={24}>
                                          <div className="i-label">
                                            Link
                                            <span className="required">
                                              *
                                            </span>
                                          </div>
                                        </Col>
                                        <Input
                                          onChange={(e) => {
                                            const value = {
                                              ...selectedItems,
                                              customlink: e.target.value,
                                            };
                                            props.selectedFooterCategoryItems[index] = { ...value };
                                            props.updateState({
                                              selectedFooterCategoryItems: [
                                                ...props.selectedFooterCategoryItems,
                                              ],
                                            });
                                          }}
                                          value={selectedItems?.customlink}
                                          placeholder="Link"
                                        />
                                      </Col>
                                    )}

                                    <Col span={24}>
                                      <div className="i-label">
                                        Name
                                        <span className="required">*</span>
                                      </div>
                                    </Col>
                                    <Col span={24}>
                                      <Input
                                        onChange={(e) => {
                                          const value = {
                                            ...selectedItems,
                                            displayName: e.target.value,
                                          };
                                          props.selectedFooterCategoryItems[index] = { ...value };
                                          props.updateState({
                                            selectedFooterCategoryItems: [
                                              ...props.selectedFooterCategoryItems,
                                            ],
                                          });
                                        }}
                                        value={
                                          selectedItems?.displayName ||
                                          selectedItems.itemName || selectedItems?.categoryName ||
                                          selectedItems?.customLinkName || selectedItems.label
                                        }
                                        placeholder="Basic usage"
                                      />
                                    </Col>
                                    <Col span={24}>
                                      <div className="header-structure-card-buttons-wapper mt10 ">
                                        <div className="buttons-inner-wapper">
                                          <span
                                            className="cursor-pointer remove-btn"
                                            onClick={() =>
                                              handleRemove(selectedItems)
                                            }
                                          >
                                            Remove
                                          </span>
                                          <span className="cursor-pointer cancel-btn">
                                            Cancel
                                          </span>
                                        </div>
                                        <div>
                                          <Button
                                            onClick={() =>
                                              handleUpdateName(
                                                selectedItems.inventoryItemCategoryId || selectedItems.ecomHeaderPageId || selectedItems.itemId || selectedItems.flagId,
                                                selectedItems.displayName || selectedItems.blogId
                                              )
                                            }
                                            type="primary"
                                          >
                                            Update
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Panel>
                              </Collapse>
                            </Col>
                          </Row>)
                        }

                      </div>

                    );
                  }
                ) : <div className="empty-list">
                  <p>Start adding menu elements from footer items</p>
                </div>}

                {(props.categoryFooterGroup || []).length > 0 ? (
                  props.categoryFooterGroup.map((selectedItems, index) => (
                    <div key={index}>
                      <Row style={{ marginTop: "10px" }}>
                        <Col lg={16} xxl={10}>
                          <Collapse
                            accordion
                            expandIconPosition="right"
                            activeKey={props.activeKeyCategory || 0}
                            onChange={(key) => props.updateState({ activeKeyCategory: key })}
                          >
                            <Panel
                              key={index}
                              header={
                                <div className="header-structure-card-collapse-header">
                                  <div className="header-structure-card-collapse-header-child">
                                    <span>
                                      <img className="img-Icon" src={EqualIcon} alt="icon" />
                                    </span>
                                    <span className="collapse-header-right">
                                      {selectedItems.groupName || "Unnamed Group"}
                                    </span>
                                  </div>
                                  <div>{"Category Group"}</div>
                                </div>
                              }
                            >
                              <Row>
                                <Col span={24}>
                                  <div className="i-label">
                                    Name <span className="required">*</span>
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <Input
                                    onChange={(e) => {
                                      const updatedGroup = {
                                        ...selectedItems,
                                        groupName: e.target.value,
                                      };
                                      const updatedCategoryGroup = [...props.categoryFooterGroup];
                                      updatedCategoryGroup[index] = updatedGroup;
                                      props.updateState({ categoryFooterGroup: updatedCategoryGroup });
                                    }}
                                    value={selectedItems?.groupName || ""}
                                    placeholder={`Rename ${selectedItems?.type?.slice(0, -1)} Name`}
                                  />
                                </Col>

                                {/* Image Upload for Category Group */}
                                <Col span={24}>
                                <ImageUploadComp/>
                                </Col>

                                {/* Submenu Logic */}
                                {selectedItems.list?.length > 0 &&
                                  selectedItems.list.map((listItem, i) => (
                                    <div
                                      key={i}
                                      style={{
                                        border: "1px solid #d9d9d9",
                                        borderRadius: "8px",
                                        padding: "10px",
                                        margin: "10px 0",
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                      }}
                                    >
                                      <Col span={24}>
                                        <div className="i-label">
                                          Sub Menu Heading <span className="required">*</span>
                                        </div>
                                      </Col>
                                      <Col span={24}>
                                        <Input
                                          onChange={(e) => {
                                            const updatedList = [...selectedItems.list];
                                            updatedList[i] = {
                                              ...listItem,
                                              subMenuHeading: e.target.value,
                                            };
                                            const updatedGroup = { ...selectedItems, list: updatedList };
                                            const updatedCategoryGroup = [...props.categoryFooterGroup];
                                            updatedCategoryGroup[index] = updatedGroup;
                                            props.updateState({ categoryFooterGroup: updatedCategoryGroup });
                                          }}
                                          value={listItem.subMenuHeading || ""}
                                          placeholder="Enter Sub Menu Heading"
                                        />
                                      </Col>
                                      <Col span={24}>
                                        {"Curated Menu"}
                                      </Col>
                                      <Col span={24}>
                                        {listItem.curatedMenu?.length > 0 ? (
                                          <Input
                                            onFocus={() =>
                                              props.pushModalToStack({
                                                modalBody: (
                                                  <CuratedMenu
                                                    {...props}
                                                    data={listItem.curatedMenu}
                                                    callBack={(data) => {
                                                      const updatedList = [...selectedItems.list];
                                                      updatedList[i] = {
                                                        ...listItem,
                                                        curatedMenu: data,
                                                      };
                                                      const updatedGroup = { ...selectedItems, list: updatedList };
                                                      const updatedCategoryGroup = [...props.categoryFooterGroup];
                                                      updatedCategoryGroup[index] = updatedGroup
                                                      props.updateState({
                                                        categoryFooterGroup: updatedCategoryGroup,
                                                      });
                                                    }}
                                                  />
                                                ),
                                                title: "Curated Menu",
                                                width: "70%",
                                                wrapClassName: "cat-modal-custom",
                                                hideTitle: false,
                                                hideFooter: true,
                                              })
                                            }
                                            value={listItem.curatedMenu
                                              .map(
                                                (item) =>
                                                  item.displayName ||
                                                  item.itemName ||
                                                  item.categoryName ||
                                                  item.customLinkName ||
                                                  item.pageTitle ||
                                                  item.label
                                              )
                                              .join(", ")}
                                          />
                                        ) : (
                                          <Button
                                            onClick={() =>
                                              props.pushModalToStack({
                                                modalBody: (
                                                  <CuratedMenu
                                                    {...props}
                                                    callBack={(data) => {
                                                      const updatedList = [...selectedItems.list];
                                                      updatedList[i] = {
                                                        ...listItem,
                                                        curatedMenu: data,
                                                      };
                                                      const updatedGroup = { ...selectedItems, list: updatedList };
                                                      const updatedCategoryGroup = [...props.categoryFooterGroup];
                                                      updatedCategoryGroup[index] = updatedGroup;
                                                      props.updateState({
                                                        categoryFooterGroup: updatedCategoryGroup,
                                                      });
                                                    }}
                                                  />
                                                ),
                                                title: "Curated Menu",
                                                width: "70%",
                                                wrapClassName: "cat-modal-custom",
                                                hideTitle: false,
                                                hideFooter: true,
                                              })
                                            }
                                            type="secondary"
                                          >
                                            Add Curated Menu
                                          </Button>
                                        )}
                                      </Col>
                                    </div>
                                  ))}

                                {/* Add/Remove Submenu Buttons */}
                                <Col span={24} style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
                                  <Button
                                    type="dashed"
                                    onClick={() => {
                                      const updatedList = selectedItems.list || [];
                                      updatedList.push({ subMenuHeading: "", curatedMenu: [] });
                                      const updatedGroup = { ...selectedItems, list: updatedList };
                                      const updatedCategoryGroup = [...props.categoryFooterGroup];
                                      updatedCategoryGroup[index] = updatedGroup;
                                      props.updateState({ categoryFooterGroup: updatedCategoryGroup });
                                    }}
                                  >
                                    Add Submenu
                                  </Button>
                                  <Button
                                    danger
                                    onClick={() => {
                                      const updatedList = selectedItems.list || [];
                                      updatedList.pop();
                                      const updatedGroup = { ...selectedItems, list: updatedList };
                                      const updatedCategoryGroup = [...props.categoryFooterGroup];
                                      updatedCategoryGroup[index] = updatedGroup;
                                      props.updateState({ categoryFooterGroup: updatedCategoryGroup });
                                    }}
                                  >
                                    Remove Submenu
                                  </Button>
                                </Col>
                                <Col span={24}>
                                  <div className="header-structure-card-buttons-wapper mt10">
                                    <div className="buttons-inner-wapper">
                                      <span
                                        className="cursor-pointer remove-btn"
                                        onClick={() => {
                                          const updatedCategoryGroup = [...props.categoryFooterGroup];
                                          updatedCategoryGroup.splice(index, 1);
                                          props.updateState({ categoryFooterGroup: updatedCategoryGroup });
                                        }}
                                      >
                                        Remove Category Group
                                      </span>
                                      <span className="cursor-pointer cancel-btn">Cancel</span>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Panel>
                          </Collapse>
                        </Col>
                      </Row>
                    </div>
                  ))
                ) : (
                  <div>No Category Groups Available</div>
                )}
              </div>
              <div className="footer-btn">
                <Button
                  disabled={!(permissionData.update)}
                  onClick={() => {
                    handleSave();
                  }}
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            </Card>

          </Col>
        </Row>
      </div>

    </div>
  );
};

export default FooterItems;