import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Tabs } from 'antd';
import CustomerContact from './customer';
import SupplierContact from './supplier';
import EmployeeContact from './employee';
import AllContacts from './allContacts';
import PageBreadcrumb from '../../PageBreadcrumb';
import Subscriber from './subscriber';
import InquiryListComp from './inquiry';
import { getPermissionData } from '../../../utils';
import { MODULE_CODED_VALUES } from '../../../static/constants';


const { TabPane } = Tabs;

class CommContact extends Component {
  state = {
    buttonDropDown: false
  };

  toggleDropdown = () => {
    const { buttonDropDown } = this.state;
    this.setState({
      buttonDropDown: !buttonDropDown
    })
  };

  render() {
    const primaryPerm = (this.props.permissions && this.props.permissions.primary) || [];

    const permissionsData =  getPermissionData(primaryPerm, MODULE_CODED_VALUES.ALL_CONTACT )
 
    const breadCrumbList = [
      {
        name: <FormattedMessage id="sidebar.menuItem.communication" defaultMessage='' />
      },
      {
        name: <FormattedMessage id="sidebar.menuItem.contact" defaultMessage='' />
      }
    ];


    const callTabApi = (key, props) => {
      const payload = {
        relationshipId: props.companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: 25,
      };

      switch (key) {
        case '1':
          break;
      
        case '2':
          props.fetchCustomerContacts(payload);
          break;
      
        case '3':
          props.fetchSupplierContacts(payload);
          break;
      
        case '4':
          props.fetchAllEmployees(payload);
          break;
      
        case '5':
          props.fetchSubscribers(payload);
          break;
      
        default:
        
          break;
      }
      


    }


    return (
      <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              Contacts
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
          </div>
        </div>
        <div className="view-container">
          <Tabs type="line"
            onChange={(key) => {
              callTabApi(key, this.props);
            }}
          >
           {/* {permissionsData.read && <TabPane
              tab="All"
              key="1"
            >
              <AllContacts {...this.props} />
            </TabPane>} */}
            {permissionsData.read && <TabPane
              tab="Customer"
              key="2"
            >
              <CustomerContact {...this.props} />
            </TabPane>}
            {permissionsData.read &&  <TabPane
              tab="Supplier"
              key="3"
            >
              <SupplierContact {...this.props} />
            </TabPane>}
            {permissionsData.read &&  <TabPane
              tab="Team"
              key="4"
            >
              <EmployeeContact {...this.props} />
            </TabPane>}
           {permissionsData.read &&  <TabPane
              tab="Subscriber"
              key="5"
            >
              <Subscriber {...this.props} />
            </TabPane>}

             {permissionsData.read &&   <TabPane
              tab="Inquiry"
              key="6"
            >
              <InquiryListComp {...this.props} />
            </TabPane>} 
          </Tabs>
        </div>
      </>
    )
  }
}

export default injectIntl(CommContact);
