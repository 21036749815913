import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as find from "lodash.find";
import StandardPurchaseRequest from "../../../../components/supplier/purchaseRequest/StandardPurchaseRequest";
import { fetchSuppliers,resetPaginatedData } from "../../../../containers/supplier/Listing/action";
import {
  fetchTaxes,
  fetchExpenseTypes,
  fetchProducts,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchAllDepartments,
  addDepartment,
  deleteDepartment,
  fetchAllEmployees,
  deleteEmployee,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchProjects,
  deleteProject,
  fetchSupplierSummary,
  fetchSupplierDetailsBySupplierId,
  fetchRelationshipTaxIdentifications,
  deleteTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  deleteTax
} from "../../../../actions/commonActions";
import {
  createPurchaseRequest,
  getNextTxNumber,
  fetchInquiryDetail,
  resetEnquiryDetail,
  fetchSODetail,
  resetSODetails,
  createPurchaseRequestDraft,
  deleteDocument
} from "./action";
import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
} from "../../../../utils";
import { GRID_PREFERENCE_CONSTANTS } from "../../../../static/agGridConstants";
import PRDetail from "../../../modal/modalBody/supplier/PRDetail";
import {
  CONSTANTS,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
  TRANSACTION_TYPES
} from "../../../../static/constants";
import {
  fetchPurchaseRequestDetail,
  resetPurchaseRequestDetail,
} from "../../../modal/modalBody/supplier/PRDetail/action";
import filter from "lodash.filter";
import { fetchPurchaseRequestDrafts, fetchPurchaseRequests,getPurchaseRequestDraftCount} from "../Listing/action";
import { downloadFileFromBucket} from "../../../../actions/downloadFileAction";
import { deleteAttectedFile } from "../../../../actions/commonActions";
import SettingPurchaseDrawer from "../../../drawer/supplier/settingPurchaseDrawer";
class PurchaseInvoice extends Component {
  constructor(props) {
    super(props);
    const allRelationshipBillingAddress =
      filter(props.companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
const relationshipBillingAddress = find(props.companyInfo.boLocationList, {
  locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
  isDefault:1
}) || {};
    // const linkProps = (props.location && props.location.state) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNamePurchaseRequest",
      viewName: "PuchaseRequest",
      docName:"Purchase Request",
      tabDocumentOptions: 
      { txType: CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST, label: 'Purchase Request', numberExample: 'PR-1', numberInfo: 'PO-SupplierNumber-DDMMYY' },
      settingPurchaseDrawerVisible:false,


      allRelationshipBillingAddress,
      prDraftData: props.prDataa,
      purchaseRequestDraftId: props.purchaseRequestDraftId,
      isClone: props.clone,
      purchaseRequestMasterId: props.update ? props.prMasterId : 0,
      supplier: undefined,
      priceListName: undefined,
      relationshipBillingAddress,
      purchaseRequestDate: new Date(),
      prExpirationDate: '',
      pageSize: 100,
      pageNumber: 1,
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "purchaseRequest", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.AMOUNT_WITHOUT_TAX,
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        description: true,
        uom: true,
        hsn: true,
        // specialDiscount: true,
        rate: true,
        // discount: true,
        tax: true,
        taxableAmount:true,
        
      },
      
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      inquiryMasterId: this.props.inquiryMasterId || linkProps.inquiryMasterId,
      salesOrderMasterId:
        this.props.salesOrderMasterId || this.props.salesOrderMasterId,
      supplierId: this.props.supplierId || (this.props.prData || {}).supplierId,
      customerId: this.props.soDetail?.customerId,
      userId: (this.props.userInfo || {}).userId,
      prMasterId: this.props.prMasterId,
      pageNumber: 1,
      pageSize: 100,
    };
    payload.supplierId && this.props.fetchSupplierDetailsBySupplierId(payload);
    this.props.resetEnquiryDetail();
    payload.inquiryMasterId && this.props.fetchInquiryDetail(payload);
    this.props.resetSODetails();
    payload.salesOrderMasterId && this.props.fetchSODetail(payload);
    this.props.resetPurchaseRequestDetail();
    payload.prMasterId && this.props.fetchPurchaseRequestDetail(payload);
    // fetchPaginationDataIfNeeded(
    //   "fetchSuppliers",
    //   "suppliers",
    //   this.props,
    //   payload,
    //   true
    // );
    this.props.resetPaginatedData();
    fetchDataIfNeeded("fetchTaxes", "taxes", this.props, payload);
    fetchDataIfNeeded("fetchUOM", "uomList", this.props, payload);
    fetchDataIfNeeded(
      "fetchAllDepartments",
      "departments",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchExpenseTypes", "expenseTypes", this.props, payload);
    fetchDataIfNeeded("fetchProducts", "products", this.props, payload);
    fetchDataIfNeeded("fetchAllEmployees", "employees", this.props, payload);
    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded("getNextTxNumber", "nextTxNumber", this.props, payload);
    }
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    // fetchDataIfNeeded("fetchProjects", "projectList", this.props, payload);
    this.props.fetchProjects(payload);
    if (
      this.props.boRelationshipAddress &&
      this.props.boRelationshipAddress.length
    ) {
      const allRelationshipBillingAddress = [];
      const allRelationshipShippingAddress = [];
      this.props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === "RelationshipBillingAddress") {
          allRelationshipBillingAddress.push(e);
        } else {
          allRelationshipShippingAddress.push(e);
        }
      });

      this.props.fetchPurchaseRequests(payload);
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });
    } else {
      const allRelationshipBillingAddress =
        filter(this.props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || [];
      const allRelationshipShippingAddress =
        filter(this.props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
        }) || [];
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });
    }
    this.props.fetchRelationshipTaxIdentifications(payload);
  }

  componentWillReceiveProps(props) {
    // const linkProps = (this.props.location && this.props.location.state) || {};
    if (
      props.documentList &&
      props.documentList.length &&
      !this.state.docName
    ) {
      props.documentList.forEach((document) => {
        if (
          document.txType ===
            CONSTANTS_TRANSACTIONS_KEY[
              CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST
            ] &&
          document.isDefault
        ) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName,
          });
        }
      });
    }
    if (
      !this.state.selectedRelationshipTaxIdentifications &&
      props.allRelationshipTaxIdentificatins &&
      props.allRelationshipTaxIdentificatins.length
    ) {
      const selectedList =
        (props.allRelationshipTaxIdentificatins &&
          filter(props.allRelationshipTaxIdentificatins, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedRelationshipTaxIdentifications = selectedList.map((obj) => {
        return obj.relationshipTaxIdentificationId;
      });
      this.setState({
        selectedRelationshipTaxIdentifications:
          selectedRelationshipTaxIdentifications,
      });
    }
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences =
        find(props.allPreferences, {
          txType: "purchaseRequest",
          templateName: "standard",
        }) || this.state.txPreferences;
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
              "product",
              "description"
            );
        const txColumnSetting = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnSetting || {}
          : {};
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }
    if (props.enquiryDetail && props.enquiryDetail.inquiryMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
      };
      props.enquiryDetail.inquiryDetailsList.map((ele) => {
        _emptyColumnCheck(ele);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.itemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          parentDetailsId: ele.inquiryDetailsId,
        });
      });

      const customerName = props.enquiryDetail.customerName;
      const departmentName = props.enquiryDetail.requestingDepartment;
      const orderPriority = props.enquiryDetail.priority;
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      this.setState({
        version: props.enquiryDetail.version,
        inquiryMasterId: props.enquiryDetail.inquiryMasterId,
        customerInquiryNumber: props.enquiryDetail.customerInquiryNumber,
        isInquiryConversion: 1,
        salesPersonName: props.enquiryDetail.salesPersonFullName,
        priority: orderPriority,
        expirationDate: props.enquiryDetail.inquiryExpirationDate,
        prExpirationDate: props.enquiryDetail.inquiryExpirationDate,
        customerId: props.enquiryDetail.customerId,
        customerName: props.enquiryDetail.customerName,
        customer: { customerId: props.enquiryDetail.customerId, customerName },
        departmentName,
        internalRemarks: props.enquiryDetail.remarksInternal,
        supplierRemarks: props.enquiryDetail.remarksSupplier,
        footer: props.enquiryDetail.footer,
        reference: props.enquiryDetail.refNumber,
        projectName: props.enquiryDetail.projectName,
        projectNumber: props.enquiryDetail.projectNumber,
        projectMasterId: props.enquiryDetail.projectMasterId,
        prDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
      });

      props.resetEnquiryDetail();
    }

    if (props.soPrData && props.soPrData.salesOrderMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
      };
      props.soPrData.customerSalesOrderDetailsList.map((ele) => {
        _emptyColumnCheck(ele);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          parentDetailsId: ele.salesOrderDetailsId,
        });
      });

      const customerName = props.soPrData.customerName;
      const departmentName = props.soPrData.requestingDepartment;
      const orderPriority = props.soPrData.priority;
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      this.setState({
        version: props.soPrData.version,
        salesOrderMasterId: props.soPrData.salesOrderMasterId,
        customerPONumber: props.soPrData.customerPONumber,
        customerInquiryNumber: props.soPrData.customerInquiryNumber,
        customerRFQNumber: props.soPrData.customerRFQNumber,
        soNumber: props.soPrData.soNumber,
        isSOConversion: 1,
        salesPersonName: props.soPrData.salesPersonFullName,
        priority: orderPriority,
        expirationDate: props.soPrData.expirationDate,
        prExpirationDate: props.soPrData.expirationDate,
        customerId: props.soPrData.customerId,
        customerName: props.soPrData.customerName,
        customer: { customerId: props.soPrData.customerId, customerName },
        departmentName,
        internalRemarks: props.soPrData.remarksInternal,
        supplierRemarks: props.soPrData.remarksSupplier,
      

        footer: props.soPrData.footer,
        reference: props.soPrData.referenceNumber,
        projectName: props.soPrData.projectName,
        projectNumber: props.soPrData.projectNumber,
        projectMasterId: props.soPrData.projectMasterId,
        prDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
      });

      props.resetSODetails();
    }

    if (props.prData && props.prData.purchaseRequestMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemId
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
      };
      props.prData.purchaseRequestDetailsList.map((ele) => {
        _emptyColumnCheck(ele);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          qtyBeforeUpdate: ele.quantity,
          //rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          parentDetailsId: ele.parentDetailsId,
          salesOrderMasterId: ele.salesOrderMasterId,
          inquiryMasterId: ele.inquiryMasterId,
          purchaseRequestDetailsId: props.update
            ? ele.purchaseRequestDetailsId
            : 0,
          rate: Number(ele.anItemPurchasePrice || 0),
          discount: Number(ele.discountPercent || 0),
          tax: Number(ele.taxPercent || 0),
        });
      });

      const customerName = props.prData.customerName;
      const departmentName = props.prData.department;
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      const supplierObj = {supplierStoreName:props.prData.supplierName , supplierId:props.prData.supplierId};

      this.setState({
        inquiryMasterId: props.prData.inquiryMasterId,
        parentDetailsId: props.prData.parentDetailsId,
        update: props.update,
        version: props.prData.version,
        purchaseRequestMasterId: props.prData.purchaseRequestMasterId,
        purchaseRequestNumber: props.update
          ? props.prData.purchaseRequestNumber
          : "",
        salesOrderMasterId: props.prData.salesOrderMasterId,
        soNumber: props.prData.soNumber,
        salesPersonName: props.prData.salesPersonFullName,
        priority: props.prData.orderPriority,
        owner: props.prData.prOwnerName,
        purchaseRequestDate: props.update
          ? new Date(props.prData.purchaseRequestDate)
          : new Date(),
        prExpirationDate: props.update
          ? new Date(props.prData.prExpirationDate)
          : '',
        docName: props.prData.documentName,
        customerId: props.prData.customerId,
        customer: { customerId: props.prData.customerId, customerName },
        departmentName,
        internalRemarks: props.prData.remarksInternal,
        supplierRemarks: props.prData.remarksSupplier,
        footer: props.prData.footer,
        reference: props.prData.reference,
        projectName: props.prData.projectName,
        projectNumber: props.prData.projectNumber,
        projectMasterId: props.prData.projectMasterId,
        prDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        reason: props.prData.reason,
        remarksEmployee: props.prData.remarksEmployee,
        oldPayload: props.update ? props.prData : {},
        emailIfRFQOrPOIsGenerated: props.prData.emailIfRFQOrPOIsGenerated
          ? true
          : false,
        isSOConversion: props.prData.isSOConversion ? true : false,
        isInquiryConversion: props.prData.isInquiryConversion ? true : false,
        supplierName: props.prData.supplierName,
        docDetailList: props.prData.docDetailList,
        supplierObj: supplierObj,
        stateSupplierId: supplierObj?.supplierId,
        relationshipBillingAddress: (props.prData?.boLocationPRList || []).find(obj=>obj.purchaseRequestMasterId === props.prData.purchaseRequestMasterId),
        selectedRelationshipTaxIdentifications: JSON.parse((props.prData?.additionalInfoList || []).find(obj=>obj.purchaseRequestMasterId === props.prData.purchaseRequestMasterId)?.relationshipTaxIdentifications || "[]").map(obj=>obj.relationshipTaxIdentificationId)
      });

      (this.props.prData || {}).supplierId || this.props.supplierId && props.fetchSupplierDetailsBySupplierId({
        relationshipId: props.companyInfo.relationshipId,
        supplierId: this.props.supplierId || (this.props.prData || {}).supplierId,
        pageNumber: props.pageNumber || 1,
        pageSize: props.pageSize || 100
      });
      if (props.update) {
        this.setState({ nextTxNumber: props.prData.purchaseRequestNumber });
      }
      props.resetPurchaseRequestDetail();
    }

    if (this.state.purchaseRequestDraftId && this.state.prDraftData) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemId
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
      };
      this.state.prDraftData.purchaseRequestDetailsList.map((ele) => {
        _emptyColumnCheck(ele);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          parentDetailsId: ele.salesOrderDetailsId,
        });
      });

      const customerName = this.state.prDraftData.customerName;
      const departmentName = this.state.prDraftData.department;
      const orderPriority = this.state.prDraftData.orderPriority;
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      setTimeout(() => {
        if (this.state.prDraftData) {
          this.setState({
            inquiryMasterId: this.state.prDraftData.inquiryMasterId,
            parentDetailsId: this.state.prDraftData.parentDetailsId,
            purchaseRequestMasterId:
              this.state.prDraftData.purchaseRequestMasterId,
            purchaseRequestNumber: this.state.prDraftData.purchaseRequestNumber,
            purchaseRequestDate: new Date(
              this.state.prDraftData.purchaseRequestDate
            ),
            prExpirationDate: new Date(this.state.prDraftData.prExpirationDate),
            salesOrderMasterId: this.state.prDraftData.salesOrderMasterId,
            soNumber: this.state.prDraftData.soNumber,
            isSOConversion: this.state.prDraftData.isSOConversion,
            isInquiryConversion: this.state.prDraftData.isInquiryConversion,
            salesPersonName: this.state.prDraftData.salesPersonFullName,
            priority: orderPriority,
            owner: this.state.prDraftData.prOwnerName,
            expirationDate: this.state.prDraftData.expirationDate,
            docName: this.state.prDraftData.documentName,
            customerId: this.state.prDraftData.customerId,
           supplierObj : {supplierStoreName:this.state.prDraftData.supplierName , supplierId:this.state.prDraftData.supplierId},
           supplierId:this.state.prDraftData.supplierId,
            customer: {
              customerId: this.state.prDraftData.customerId,
              customerName,
            },
            departmentName,
            internalRemarks: this.state.prDraftData.remarksInternal,
            supplierRemarks: this.state.prDraftData.remarksSupplier,
            footer: this.state.prDraftData.footer,
            reference: this.state.prDraftData.reference,
            projectName: this.state.prDraftData.projectName,
            projectNumber: this.state.prDraftData.projectNumber,
            projectMasterId: this.state.prDraftData.projectMasterId,
            prDetailsList: detailList,
            inheritedTxColumn: inheritedTxColumn,
            forceHideTxColumn: forceHideTxColumn,
            tableDataReRendered: true,
            reason: this.state.prDraftData.reason,
            remarksEmployee: this.state.prDraftData.remarksEmployee,
            prDraftData: null,
            emailIfRFQOrPOIsGenerated: props.prData.emailIfRFQOrPOIsGenerated
              ? true
              : false,
          });
        }
      }, 1000);
    }

  }

  openPRDetails = (data) => {
    const prPayload = {
      supplierId: data.supplierId,
      prMasterId: data.purchaseRequestMasterId,
    };
    this.props.pushModalToStack({
      modalBody: <PRDetail {...this.props} prPayload={prPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching,
    };
    this.props.fetchProducts(payload);
  }

  render() {
    return (
      <Fragment>
        {this.state.settingPurchaseDrawerVisible && 
          <SettingPurchaseDrawer
            {...this.state}
            {...this.props}
            docName={TRANSACTION_TYPES.PURCHASE_REQUEST}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
            />
        }

        <StandardPurchaseRequest
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          openPRDetails={this.openPRDetails}
          updateState={(data) => {
            this.setState(data);
          }}
          updateDrawerState={(data) => { this.setState(data) }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suppliers: state.supplier.suppliers,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    supplierAddresses: state.supplier.supplierAddresses,
    uomList: state.common.uomList,
    departments: state.common.departmentList,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    products: state.salesInvoice.products,
    employees: state.common.employees,
    documentList: state.common.documentList,
    nextTxNumber: state.purchaseRequest.nextTxNumber,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    enquiryDetail: state.enquiry.enquiryDetail,
    soPrData: state.purchaseRequest.soPrData,
    prData: state.purchaseRequest.prDetail,
    projectList: state.common.projectList,
    supplierSummary: state.supplier.supplierSummary,
    allRelationshipTaxIdentificatins:
    state.common.allRelationshipTaxIdentificatins,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading,
    permissions: state.auth.permissions,

 
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSuppliers,
      fetchProducts,
      fetchTaxes,
      fetchUOM,
      fetchExpenseTypes,
      fetchAllDepartments,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      createPurchaseRequest,
      fetchAllEmployees,
      deleteEmployee,
      addUOM,
      deleteUOM,
      addDepartment,
      deleteDepartment,
      fetchDocuments,
      addDocumentName,
      deleteDocumentName,
      getNextTxNumber,
      deleteContact,
      fetchUserPreferences,
      saveUserPrefrences,
      fetchInquiryDetail,
      resetEnquiryDetail,
      fetchSODetail,
      resetSODetails,
      fetchPurchaseRequestDetail,
      resetPurchaseRequestDetail,
      createPurchaseRequestDraft,
      fetchProjects,
      deleteProject,
      fetchSupplierSummary,
      fetchSupplierDetailsBySupplierId,
      fetchPurchaseRequests,
      deleteAttectedFile,
      downloadFileFromBucket,
      fetchRelationshipTaxIdentifications,
      resetPaginatedData,
      deleteDocument,fetchPurchaseRequestDrafts,
      fetchPurchaseRequestDrafts,
      getPurchaseRequestDraftCount,
      deleteTaxIdentifications,
      deleteRelationshipTaxIdentifications,
      deleteTax
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoice);
