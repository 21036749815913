import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { E_STORE } from "../../../../../static/constants";

import { COMMON_ACTIONS } from "../../../../../static/constants";
import { showToasterMessage } from "../../../../../utils";


export const updateAdditionalValue = (payload,props)=>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_setting/addition-values`,
        payload
      
      )
          .then(response => {

            showToasterMessage({
              messageType: "success",
              description: "Save Succssfully.",
            });
        const companyInfo = props.userInfo || {};

            const val = {
                relationshipId: companyInfo.relationshipId,
                
            };
            props.fetchE_StoreData(val)
          })
          .catch(err => {
              dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
  }
  }