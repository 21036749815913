import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';
import { Row, Col, Button, Tabs } from 'antd'
import { Dropdown } from "../../../../general/Dropdown";
import { CONSTANTS, LIST_CONSTANTS } from "../../../../../static/constants";
import { TextBox } from "../../../../general/TextBox";
import './index.scss';
import { getAddressFieldByCountry, getBankFieldByCountry, getUserDefinedAddressName } from "../../../../../utils";
const { TabPane } = Tabs;


const LabelConfig = (props) => {

    const { labelConfig, permissionsData } = props;

    const [educationLabel, setEducationLabel] = useState(labelConfig.educationLabel || {});
    const [addressLabel, setAddressLabel] = useState(labelConfig.addressLabel || {});
    const [bankLabel, setBankLabel] = useState(labelConfig.bankLabel || {});

    const handleTextBox = (field, value) => {
        setEducationLabel(pre => ({
            ...pre,
            [field]: value
        }));
    }

    const handleTextBoxAddress = (field, value) => {
        setAddressLabel(pre => ({
            ...pre,
            [field]: value
        }));
    }

    const handleTextBoxBank = (field, value) => {
        setBankLabel(pre => ({
            ...pre,
            [field]: value
        }));
    }
    function onTabChange(key, props) {
        props.updateState({ pageSize: 100, pageNumber: 1, activeTabKey: key });
    }
    const handleSubmit = () => {

        const payload = {
            "eduType": labelConfig.eduType,
            "educationLabel": {
                "department": educationLabel?.department,
                "subject": educationLabel?.subject,
                "term": educationLabel?.term,
                "course": educationLabel?.course,
                "section": educationLabel?.section,
                "class": educationLabel?.class
            }
            ,
            "addressLabel": {
                "street1": addressLabel?.street1,
                "street2": addressLabel?.street2,
                "state": addressLabel?.state,
                "city": addressLabel?.city,
                "zip": addressLabel?.zip,
                "country": addressLabel?.country
            },
            "bankLabel": {
                "bankName": bankLabel.bankName,
                "payeeName": bankLabel.payeeName,
                "accountNumber": bankLabel.accountNumber,
                "iFSC": bankLabel.iFSC,
                "routingNumber": bankLabel.routingNumber,
                "branchName": bankLabel.branchName,
            }
        }
        props.saveEducationLabelInfo(payload, props)
    }
    console.log(getUserDefinedAddressName("Street1", props), "hello");

    return (
        <>
            <Tabs onChange={(key) => { onTabChange(key, props); }} type="line" style={{height:"450px !important"}} animated={true}>
                {props.companyInfo?.industryName === "Education" ?
                    <TabPane tab="Education Label" key="education_label">
                        <div className="">

                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <div className="i-label">
                                        Education Type
                                    </div>
                                    <Dropdown
                                        items={LIST_CONSTANTS.EDUCATION_TYPES}
                                        placeholder="Education Type"
                                        value={labelConfig.eduType || ''}
                                        onSelect={(val) => {
                                            setEducationLabel(pre => ({
                                                ...pre,
                                                eduType: val
                                            }));
                                        }}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div className="i-label">
                                        Class
                                    </div>
                                    <TextBox
                                        value={educationLabel.class || ''}
                                        maxLength={20}
                                        countStyle={{ top: "38px" }}
                                        placeholder="e.g. Play Wing, Pre-Nursery…"
                                        onChange={(e) => handleTextBox('class', e.target.value)}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div className="i-label">
                                        Course
                                    </div>
                                    <TextBox
                                        value={educationLabel.course || ''}
                                        maxLength={20}
                                        countStyle={{ top: "38px" }}
                                        placeholder="e.g. Play Wing, Pre-Nursery…"
                                        onChange={(e) => handleTextBox('course', e.target.value)}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div className="i-label">
                                        Department
                                    </div>
                                    <TextBox
                                        value={educationLabel.department || ''}
                                        maxLength={20}
                                        countStyle={{ top: "38px" }}
                                        placeholder="eg. Department"
                                        onChange={(e) => handleTextBox('department', e.target.value)}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div className="i-label">
                                        Section
                                    </div>
                                    <TextBox
                                        value={educationLabel.section || ''}
                                        maxLength={20}
                                        countStyle={{ top: "38px" }}
                                        placeholder="e.g. Mighty Kids, Rainbow etc.."
                                        onChange={(e) => handleTextBox('section', e.target.value)}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div className="i-label">
                                        Subject
                                    </div>
                                    <TextBox
                                        value={educationLabel.subject || ''}
                                        maxLength={20}
                                        countStyle={{ top: "38px" }}
                                        placeholder="e.g. Hindi, English, GK etc.."
                                        onChange={(e) => handleTextBox('subject', e.target.value)}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div className="i-label">
                                        Term
                                    </div>
                                    <TextBox
                                        value={educationLabel.term || ''}
                                        maxLength={20}
                                        countStyle={{ top: "38px" }}
                                        placeholder="e.g. 1 Year Session..."
                                        onChange={(e) => handleTextBox('term', e.target.value)}
                                    />
                                </Col>
                            </Row>
                            {props.isLabelsUpdated ?
                                <div className="required">
                                    <i className="fi fi-rr-triangle-warning mr10" />
                                    <span>
                                        <FormattedMessage id="labelupdate.warning.message" defaultMessage="" />
                                    </span>
                                </div>
                                :
                                ''
                            }

                            <div className="popup-btn">
                                {/* <Button>
                                Reset
                            </Button> */}
                                <Button
                                    type="primary"
                                    disabled={!(permissionsData.update)}

                                    onClick={handleSubmit}
                                >
                                    <FormattedMessage id='button.save.label' defaultMessage='' />
                                </Button>
                            </div>
                        </div>
                    </TabPane> : ""}
                <TabPane tab="Address Label" key="address_label">
                    <div className="">

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <div className="i-label">
                                    {getAddressFieldByCountry("street1")}
                                </div>
                                <TextBox
                                    value={addressLabel.street1 || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="Enter Value"
                                    onChange={(e) => handleTextBoxAddress('street1', e.target.value)}
                                />
                            </Col>

                            <Col span={12}>
                                <div className="i-label">
                                    {getAddressFieldByCountry("street2")}
                                </div>
                                <TextBox
                                    value={addressLabel.street2 || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="Enter Value"
                                    onChange={(e) => handleTextBoxAddress('street2', e.target.value)}
                                />
                            </Col>

                            <Col span={12}>
                                <div className="i-label">
                                    {getAddressFieldByCountry("state")}
                                </div>
                                <TextBox
                                    value={addressLabel.state || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="Enter State"
                                    onChange={(e) => handleTextBoxAddress('state', e.target.value)}
                                />
                            </Col>

                            <Col span={12}>
                                <div className="i-label">
                                    {getAddressFieldByCountry("city")}
                                </div>
                                <TextBox
                                    value={addressLabel.city || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="e.g. Mighty Kids, Rainbow etc.."
                                    onChange={(e) => handleTextBoxAddress('city', e.target.value)}
                                />
                            </Col>

                            <Col span={12}>
                                <div className="i-label">
                                    {getAddressFieldByCountry("zip")}
                                </div>
                                <TextBox
                                    value={addressLabel.zip || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="e.g. 1234 etc.."
                                    onChange={(e) => handleTextBoxAddress('zip', e.target.value)}
                                />
                            </Col>

                            <Col span={12}>
                                <div className="i-label">
                                    {getAddressFieldByCountry("country")}
                                </div>
                                <TextBox
                                    value={addressLabel.country || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="Enter Country"
                                    onChange={(e) => handleTextBoxAddress('country', e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div className="popup-btn">
                            {/* <Button>
                                Reset
                            </Button> */}
                            <Button
                                type="primary"
                                disabled={!(permissionsData.update)}

                                onClick={handleSubmit}
                            >
                                <FormattedMessage id='button.save.label' defaultMessage='' />
                            </Button>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="Bank Details" key="bank_details_label">
                    <div className="">
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <div className="i-label">
                                    {getBankFieldByCountry("bankName")}
                                </div>
                                <TextBox
                                    value={bankLabel.bankName || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="e.g. Play Wing, Pre-Nursery…"
                                    onChange={(e) => handleTextBoxBank('bankName', e.target.value)}
                                />
                            </Col>

                            <Col span={12}>
                                <div className="i-label">
                                    {getBankFieldByCountry("payeeName")}
                                </div>
                                <TextBox
                                    value={bankLabel.payeeName || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="e.g. Play Wing, Pre-Nursery…"
                                    onChange={(e) => handleTextBoxBank('payeeName', e.target.value)}
                                />
                            </Col>

                            <Col span={12}>
                                <div className="i-label">
                                    {getBankFieldByCountry("accountNumber")}
                                </div>
                                <TextBox
                                    value={bankLabel.accountNumber || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="eg. Department"
                                    onChange={(e) => handleTextBoxBank('accountNumber', e.target.value)}
                                />
                            </Col>

                            <Col span={12}>
                                <div className="i-label">
                                    {getBankFieldByCountry("iFSC")}
                                </div>
                                <TextBox
                                    value={bankLabel.iFSC || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="e.g. Mighty Kids, Rainbow etc.."
                                    onChange={(e) => handleTextBoxBank('iFSC', e.target.value)}
                                />
                            </Col>

                            <Col span={12}>
                                <div className="i-label">
                                    {getBankFieldByCountry("routingNumber")}
                                </div>
                                <TextBox
                                    value={bankLabel.routingNumber || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="e.g. Hindi, English, GK etc.."
                                    onChange={(e) => handleTextBoxBank('routingNumber', e.target.value)}
                                />
                            </Col>

                            <Col span={12}>
                                <div className="i-label">
                                    {getBankFieldByCountry("branchName")}
                                </div>
                                <TextBox
                                    value={bankLabel.branchName || ''}
                                    maxLength={20}
                                    countStyle={{ top: "38px" }}
                                    placeholder="e.g. 1 Year Session..."
                                    onChange={(e) => handleTextBoxBank('branchName', e.target.value)}
                                />
                            </Col>
                        </Row>
                        <div className="popup-btn">
                            {/* <Button>
                                Reset
                            </Button> */}
                            <Button
                                type="primary"
                                disabled={!(permissionsData.update)}

                                onClick={handleSubmit}
                            >
                                <FormattedMessage id='button.save.label' defaultMessage='' />
                            </Button>
                        </div>
                    </div>
                </TabPane>
            </Tabs>

        </>
    )
};

export default LabelConfig;