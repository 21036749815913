import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, DatePicker, Tooltip, Input } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';
import { Dropdown } from '../../../general/Dropdown';
import { MultipleSelectDropdown } from '../../../general/MultipleSelectDropdown';
import { CONSTANTS, MODAL_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY, MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../../static/constants'
import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants'
import { getLabelName } from '../../../modal/modalBody/customerInfo';
import { showToasterMessage, getMomentDateForUI } from '../../../../utils';
import { QuestionCircleTwoTone ,EditOutlined} from '@ant-design/icons';
import { TextBox } from '../../../general/TextBox';

let companyInfo = {};
// let allRelationshipBillingAddress = [];
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class SoDetails extends React.Component {

  constructor(props) {
    super();
    this.state = {
      size: 0
    }

    companyInfo = props.companyInfo;
    // let allRelationshipBillingAddress = filter(companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
  }

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    if (modalType !== MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS && (!formData || !formData.customerId)) {
      return showToasterMessage({ messageType: 'error', description: this.props.intl.formatMessage({id : 'missingCustomer.errorMsg', defaultMessage : ''}) });
    }
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (address) {
      props.fetchAddresses({
        customerId: address.customerId,
        relationshipId: (props.companyInfo || {}).relationshipId,
        locationType: address.locationType
      });
      // if (address.isDefault && address.locationType === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_SHIPPING_ADDRESS) {
      //   const currentLocations = props.customer.boLocationList;
      //   currentLocations.forEach((loc) => {
      //     if (loc.locationType === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_SHIPPING_ADDRESS && loc.locationId === address.locationId) {
      //       loc.isDefault = 1;
      //     } else if (loc.locationType === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_SHIPPING_ADDRESS) {
      //       loc.isDefault = 0;
      //     }
      //   });

      //   props.updateState({
      //     customer: {
      //       ...props.customer, boLocationList: currentLocations
      //     }

      //   })
      // }

      // if (address.isDefault && address.locationType === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS) {
      //   const currentLocations = props.customer.boLocationList;
      //   currentLocations.forEach((loc) => {
      //     if (loc.locationType === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS && loc.locationId === address.locationId) {
      //       loc.isDefault = 1;
      //     } else if (loc.locationType === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS) {
      //       loc.isDefault = 0;
      //     }
      //   });

      //   props.updateState({
      //     customer: {
      //       ...props.customer, boLocationList: currentLocations
      //     }

      //   })
      // }
    }
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }

  handleNewEmployeeAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id='addItem.text.employee' defaultMessage='' />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.EMPLOYEE,
        data: payload,

      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }

  handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact) => {
      props.hideModal(data);
      if (contact.customerId) {
          props.fetchAllContacts({
              customerId: contact.customerId,
              relationshipId: (props.companyInfo || {}).relationshipId,
          });
      }
    }
    const data = {
      title: <div>
        <FormattedMessage id='addItem.text.contact' defaultMessage='' />
        <div style={{ 'font-size': '12px' }}> {formData.customerName || ''}</div>
      </div>,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,

      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }

  handleNewTaxIdentificationAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (taxIdentification) {
      if (props.customer && props.customer.customerTaxIdentificationsList) {
        let list = props.customer.customerTaxIdentificationsList || [];
        list.push(taxIdentification)
        props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
      }
    }
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }

  handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PROJECT,
        data: payload,

      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      }
    };
    showModal(data);
  }

  handleNewPaymentTermAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, hideModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id='addItem.text.paymentTerm' defaultMessage='' />,
      formData,
      modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
      handleSubmit: (formData = {}) => {
        if (formData.termName && formData.termName.length
          && formData.days !== 0 && formData.days) {
          hideModal();
          props.addPaymentTerm(formData);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      }
    };
    showModal(data);
  }

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = <span>
      <FormattedMessage
        id='addItem.confirmation'
        values={{ value: `${text} to ` }}
        defaultMessage=''
      />
      {labelName} ?
  </span>;

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };

    switch (modalType) {
      case MODAL_TYPE.SO_SOURCE: {
        addFunc = props.addSalesOrderSources;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        addFunc = props.addDocumentName;
        payload.txType = CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_ORDER];
        break;
      }
      case MODAL_TYPE.INCOTERM: {
        addFunc = props.addIncoterm;
        payload.txType = CONSTANTS_TRANSACTIONS.INCOTERM;
        break;
      }
      default: {
        addFunc = () => { }
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload)
        props.hideModal();
      },
    };
    showModal(modalData);
  }

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        title = <FormattedMessage id='addItem.text.paymentTerm' defaultMessage='' />;
        break;
      }
      case MODAL_TYPE.SO_SOURCE: {
        title = <FormattedMessage id='addItem.text.soSource' defaultMessage='' />;
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS: {
        title = <div><FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
          <h6>{this.props.customerName || ''} </h6>
        </div>
        break;
      }
      case MODAL_TYPE.SHIPPING_ADDRESS: {
        title = <div><FormattedMessage id='addItem.text.shippingAddress' defaultMessage='' />
          <h6>{this.props.customerName || ''} </h6>
        </div>
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        title = <div>
          <FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
          <h6>{(this.props.companyInfo || {}).storeName} </h6>
        </div>
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        title = <FormattedMessage id='addItem.text.documentName' defaultMessage='' />;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        title = <div>
          <FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />
        </div>
        break;
      }
      case MODAL_TYPE.INCOTERM: {
        title = <div>
          <FormattedMessage id='addItem.text.incoterm' defaultMessage='' />
        </div>
        break;
      }
      default:
        title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
    }
    return title;
  }

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        }
      };
      showModal(data);
    }
  }

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody =
      <FormattedMessage
        id='deleteItem.confirmation'
        defaultMessage=''
        values={{ value: payload.text }}
      />;

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        deleteFunc = props.deletePaymentTerm;
        break;
      }
      case MODAL_TYPE.SO_SOURCE: {
        deleteFunc = props.deleteSalesOrderSources;
        break;
      }
      case MODAL_TYPE.PROJECT: {
        deleteFunc = props.deleteProject;
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS:
      case MODAL_TYPE.SHIPPING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        deleteFunc = props.deleteAddress;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        deleteFunc = props.deleteDocumentName;
        break;
      }
      case MODAL_TYPE.EMPLOYEE: {
        deleteFunc = props.deleteEmployee;
        break;
      }
      case MODAL_TYPE.CONTACT: {
        deleteFunc = props.deleteContact;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        deleteFunc = props.deleteRelationshipTaxIdentifications;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER: {
        deleteFunc = props.deleteCustomerTaxIdentifications;
        break;
      }
      case MODAL_TYPE.INCOTERM: {
        deleteFunc = props.deleteIncoterm;
        break;
      }
      default: {
        deleteFunc = () => { }
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId, customerId: (props.customer || {}).customerId });
        if (modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER && props.customer && props.customer.customerTaxIdentificationsList) {
          let list = (props.customer.customerTaxIdentificationsList || []).filter(x => x.customerTaxIdentificationId !== payload.id);
          props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
        }
        if (modalType === MODAL_TYPE.DOCUMENT_NAME && payload.id === this.props.documentNameId) {
          const selectedObj = find((this.props.documentList || []), { txType: CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_ORDER], isDefault: 1 }) || {};
          this.props.updateState({
            documentNameId: selectedObj.documentNameId,
            docName: selectedObj.docName
          });
        }
      },
    };
    showModal(modalData);
  }

  render() {
    const { size } = this.state;
    const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
    if (rowNode) {
      rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_SHIPPING_ADDRESS_FIELD, ((this.props.shippingAddress || {}).streetAddress1 || ''));
      rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS_FIELD, ((this.props.billingAddress || {}).streetAddress1 || ''));
      //   rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD, (this.props.paymentTermName || ''));
      rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.CUSTOMER_PO_FIELD, this.props.customerPO);
      rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.REFERENCE_NUMBER, this.props.referenceNumber);
      //  rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRIORITY, this.props.priority);
      //  rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.SALES_PERSON, this.props.salesPersonName);
      this.gridApi.sizeColumnsToFit();
    }

    // const columnDefs = [
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='customer.salesOrder.form.gridHeader.soSource' defaultMessage='' />;
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.SOURCE,
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.sources || [],
    //         optionKeyName: 'soSourceId',
    //         valueKeyName: 'soSourceName',
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.SO_SOURCE, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             textBoxValue: payload.textEntered,
    //             submittedOnce: false
    //           }
    //           this.handleNewItemAddition(this.props, payload, MODAL_TYPE.SO_SOURCE)
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //           this.props.updateState({ soSourceName: selectedValue, soSourceId: optionObj.key });
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             soSourceName: undefined,
    //             soSourceId: undefined
    //           });
    //         },
    //       }
    //     }
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='customer.salesOrder.form.gridHeader.customerPO' defaultMessage='' />;
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.CUSTOMER_PO_FIELD,
    //     resizable: true,
    //     editable: true, cellEditor: 'customTextEditor',
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           //obj.node.setDataValue(obj.colDef.field, value);
    //           this.props.updateState({ customerPO: value });
    //         },
    //       }
    //     }
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <div>
    //         <FormattedMessage id='customer.salesOrder.form.gridHeader.refNumber' defaultMessage='' />
    //         <Tooltip placement="top" title={this.props.intl.formatMessage(
    //           {
    //             id: 'tooltip.referenceNumberAssign',
    //             defaultMessage: ''
    //           })
    //         } trigger="click">
    //           <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //         </Tooltip>
    //       </div>
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.REFERENCE_NUMBER,
    //     editable: true,
    //     cellEditor: 'customTextEditor',
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           //obj.node.setDataValue(obj.colDef.field, value);
    //           this.props.updateState({ referenceNumber: value });
    //         },
    //       }
    //     }
    //   },
    //   // {
    //   //   headerComponentFramework: () => {
    //   //     return <FormattedMessage id='customer.salesOrder.form.gridHeader.salesPerson' defaultMessage='' />;
    //   //   },
    //   //   field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.SALES_PERSON,
    //   //   editable: true,
    //   //   cellEditor: 'customDropDownEditor',
    //   //   cellEditorParams: (obj) => {
    //   //     return {
    //   //       lastColumnCell: false,
    //   //       items: this.props.employees || [],
    //   //       optionKeyName: 'relationshipEmployeeId',
    //   //       valueKeyName: 'firstName',
    //   //       lastNameKey:'lastName',
    //   //       canAddNew: true,
    //   //       canDelete: true,
    //   //       deleteClickHandler: (payload) => {
    //   //         this.deleteClickHandler(this.props, MODAL_TYPE.EMPLOYEE, payload);
    //   //       },
    //   //       onAddNewClick: (payload) => {
    //   //         // payload.formData = {
    //   //         //   firstName: payload.textEntered,
    //   //         //   submittedOnce: false
    //   //         // }
    //   //         // this.handleNewEmployeeAddition(this.props, payload, MODAL_TYPE.EMPLOYEE)
    //   //         this.props.updateHeaderState({
    //   //           company: {
    //   //               ...this.props.company,
    //   //               employeeDrawerVisible: true,
    //   //           }
    //   //       })
    //   //       },
    //   //       onSelect: (selectedValue, option) => {
    //   //         //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //   //         this.props.updateState({
    //   //           salesPersonId: option.key, salesPersonName: selectedValue
    //   //         });
    //   //       },
    //   //       allowClear: true,
    //   //       onClear: () => {
    //   //         //obj.node.setDataValue(obj.colDef.field, undefined);
    //   //         this.props.updateState({
    //   //           salesPersonId: undefined,
    //   //           salesPersonName: undefined
    //   //         });
    //   //       },
    //   //     }
    //   //   }
    //   // },
    //   // {
    //   //   headerComponentFramework: () => {
    //   //     return <FormattedMessage id='customer.salesOrder.form.gridHeader.priority' defaultMessage='' />;
    //   //   },
    //   //   field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRIORITY,
    //   //   editable: true,
    //   //   cellEditor: 'customDropDownEditor',
    //   //   cellEditorParams: (obj) => {
    //   //     return {
    //   //       lastColumnCell: false,
    //   //       items: ['Low', 'Medium', 'Urgent'],
    //   //       onSelect: (selectedValue) => {
    //   //         //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //   //         this.props.updateState({ priority: selectedValue });
    //   //       },
    //   //       allowClear: true,
    //   //       onClear: () => {
    //   //         //obj.node.setDataValue(obj.colDef.field, undefined);
    //   //         this.props.updateState({
    //   //           priority: undefined
    //   //         });
    //   //       },
    //   //     }
    //   //   }
    //   // },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='customer.salesOrder.form.gridHeader.billingAddress' defaultMessage='' />;
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS_FIELD,
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     cellEditorParams: (obj) => {
    //       const allBillingAddresses = this.props.allBillingAddress;
    //       return {
    //         //TODO need to use the variable declared above
    //         lastColumnCell: false,
    //         items: allBillingAddresses,
    //         optionKeyName: 'locationId',
    //         valueKeyName: 'streetAddress1',
    //         addressLineKey1: 'streetAddress2',
    //         addressLineKey2: 'cityName',
    //         addressLineKey3: 'stateName',
    //         addressLineKey4: 'zipCode',
    //         isFavouriteKeyName: 'isDefault',
    //         optionClassAddress: 'custom-address-dropdown',
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.BILLING_ADDRESS, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             locationType: CONSTANTS.BILLING_ADDRESS,
    //             customerId: (this.props.customer || {}).customerId,
    //             pageNumber: this.props.pageNumber
    //           }
    //           this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.BILLING_ADDRESS)
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           const billingAddress = find(allBillingAddresses, { locationId: Number(optionObj.key) });
    //           this.props.updateState({
    //             billingAddress
    //           })
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             billingAddress: undefined
    //           });
    //         },
    //       }
    //     }
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='customer.salesOrder.form.gridHeader.shippingAddress' defaultMessage='' />;
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_SHIPPING_ADDRESS_FIELD,
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     cellEditorParams: (obj) => {
    //       const allShippingAddresses = this.props.allShippingAddress;
    //       return {
    //         //TODO need to use the variable declared above
    //         lastColumnCell: false,
    //         items: allShippingAddresses,
    //         optionKeyName: 'locationId',
    //         valueKeyName: 'streetAddress1',
    //         addressLineKey1: 'streetAddress2',
    //         addressLineKey2: 'cityName',
    //         addressLineKey3: 'stateName',
    //         addressLineKey4: 'zipCode',
    //         optionClassAddress: 'custom-address-dropdown',
    //         isFavouriteKeyName: 'isDefault',
    //         permissions: this.props.permissions,
    //         moduleName: MODULE_CODED_VALUES.CUSTOMER_ADDRESS,
    //         operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.SHIPPING_ADDRESS, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             locationType: CONSTANTS.SHIPPING_ADDRESS,
    //             customerId: (this.props.customer || {}).customerId,
    //             pageNumber: this.props.pageNumber,
    //           }
    //           this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.SHIPPING_ADDRESS)
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           const shippingAddress = find(allShippingAddresses, { locationId: Number(optionObj.key) });
    //           this.props.updateState({
    //             shippingAddress
    //           })
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             shippingAddress: undefined
    //           });
    //         },
    //       }
    //     }
    //   },
    //   // {
    //   //   headerComponentFramework: () => {
    //   //     return <FormattedMessage id='customer.salesOrder.form.gridHeader.paymentTerms' defaultMessage='' />;
    //   //   },
    //   //   field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD,
    //   //   editable: true,
    //   //   pinned: 'right',
    //   //   cellEditor: 'customDropDownEditor',
    //   //   cellEditorParams: (obj) => {
    //   //     return {
    //   //       lastColumnCell: false,
    //   //       items: this.props.paymentTerms,
    //   //       optionKeyName: 'paymentTermId',
    //   //       valueKeyName: 'termName',
    //   //       canAddNew: true,
    //   //       canDelete: true,
    //   //       deleteClickHandler: (payload) => {
    //   //         this.deleteClickHandler(this.props, MODAL_TYPE.PAYMENT_TERM, payload);
    //   //       },
    //   //       onAddNewClick: (payload) => {
    //   //         payload.formData = {
    //   //           termName: payload.textEntered,
    //   //           days: null,
    //   //           description: '',
    //   //           isDefaultPayment: false,
    //   //           submittedOnce: false
    //   //         }
    //   //         this.handleNewPaymentTermAddition(this.props, payload, MODAL_TYPE.PAYMENT_TERM)
    //   //       },
    //   //       onSelect: (selectedValue, option) => {
    //   //         //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //   //         this.props.updateState({
    //   //           paymentTermId: option.key, paymentTermName: selectedValue
    //   //         });
    //   //       },
    //   //       allowClear: true,
    //   //       onClear: () => {
    //   //         //obj.node.setDataValue(obj.colDef.field, undefined);
    //   //         this.props.updateState({
    //   //           paymentTermId: undefined,
    //   //           paymentTermName: undefined
    //   //         });
    //   //       },
    //   //     }
    //   //   }
    //   // }
    // ]

    // const onGridReady = (params) => {
    //   this.gridApi = params.api;
    //   this.gridColumnApi = params.columnApi;
    //   params.api.sizeColumnsToFit();
    // }


    return (
      <Fragment>
        <Row type='flex'>
        <Col span={5} >
            <Row gutter={[0,3]} >
              <Col span={24} className='details-heading'>
                Organization Information
              </Col>
              <Col span={24}>
                <div className='details-heading-subheading'>

                <FormattedMessage id="sales.order.create.organization.name" defaultMessage=''/>
                </div>
                </Col>
                <Col span={24}>
               <div className='deatils-field-value'>

                {companyInfo.storeName}
               </div>
              </Col>
              <Col span={24}>
                <div className='details-heading-subheading'>

                <FormattedMessage id="sales.order.create.organization.adress" defaultMessage=''/>
                <Tooltip placement="right" title={this.props.intl.formatMessage(
                    {
                      id: 'tooltip.companyBillingAddress',
                      defaultMessage: ''
                    }
                  )} trigger="click">
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
                </Col>
                <Col span={24}>
                <span>{
                  !this.props.addressHide && this.props.relationshipBillingAddress ?
                  <>
                  
                  <div className='detail-address-value'>

                     <div style={{height:'96px',paddingTop:'10px'}}>
                      {

                  getAddressInfoFragment(this.props.relationshipBillingAddress || {}, {}, this.props.companyInfo,this.props)
                      }
                     </div>
                     <div onClick={()=>{
                        this.props.updateState({
                          addressHide: true,
                        })
                      }}>
                        <EditOutlined />
                     </div>
                  </div>
                  </> 
                  : <Dropdown
                  style={{width: '100%'}}
                  items={this.props.allRelationshipBillingAddress}
                  value={(this.props.relationshipBillingAddress || {}).streetAddress1}
                  moduleName={MODULE_CODED_VALUES.SETTINGS_COMPANY_INFORMATION}
                  canAddNew={true}
                  canDelete={true}
                  visible={false}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(this.props, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS, payload);
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS
                    }
                    this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS)
                  }}
                  valueKeyName='streetAddress1'
                  addressLineKey1='streetAddress2'
                  addressLineKey2='cityName'
                  addressLineKey3='stateName'
                  addressLineKey4='zipCode'
                  optionClassAddress='custom-address-dropdown'
                  optionKeyName='locationId'
                  isFavouriteKeyName='isDefault'
                  onSelect={(value) => {
                    const selectedRelationshipAddress = find(this.props.allRelationshipBillingAddress, { locationId: Number(value) });
                    this.props.updateState({
                      placeOfSupply: selectedRelationshipAddress.stateName,
                      relationshipBillingAddress: selectedRelationshipAddress,
                      addressHide: false
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      relationshipBillingAddress: undefined,
                    })
                  }}
                  />
                  }
                 
                </span>

              </Col>
              {/* <Col span={24} className='address-container mt5 mb5'>
                {
                  getAddressInfoFragment(this.props.relationshipBillingAddress || {}, {}, this.props.companyInfo)
                }
              </Col> */}
              <Col span={24}>
                <div className='details-heading-subheading'>

                <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                <Tooltip placement="right" title={this.props.intl.formatMessage(
                  {
                    id: 'tooltip.taIdentification',
                    defaultMessage: ''
                  }
                )} trigger="click">
                  <QuestionCircleTwoTone />
                </Tooltip>
                </div>
                
                <MultipleSelectDropdown
                  items={this.props.allRelationshipTaxIdentificatins || []}
                  placeholder="Tax Identification(s)"
                  mode='multiple'
                
                  onDeSelect={(value) => {
                    let { selectedRelationshipTaxIdentifications } = this.props
                    selectedRelationshipTaxIdentifications = filter(selectedRelationshipTaxIdentifications, (taxId) => {
                      return taxId !== value;
                    });
                    this.props.updateState({ selectedRelationshipTaxIdentifications });
                  }}
                  valueKeyName='taxIdentificationType'
                  valueKeyName2='taxIdentificationNumber'
                  selectedValue={(this.props.allRelationshipTaxIdentificatins || []).length ? (this.props.selectedRelationshipTaxIdentifications || []) : []}
                  optionKeyName='relationshipTaxIdentificationId'
                  onSelect={(relationshipTaxIdentificationId) => {
                    let selectedRelationshipTaxIdentifications = this.props.selectedRelationshipTaxIdentifications || [];
                    if (selectedRelationshipTaxIdentifications.indexOf(relationshipTaxIdentificationId) === -1) {
                      selectedRelationshipTaxIdentifications.push(relationshipTaxIdentificationId);
                    } else {
                      selectedRelationshipTaxIdentifications = selectedRelationshipTaxIdentifications.filter((el) => {
                        return el !== selectedRelationshipTaxIdentifications
                      });
                    }
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications
                    });
                  }}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION, payload);
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      taxIdentificationNumber: payload.textEntered,
                      submittedOnce: false,
                    }
                    this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                  }}
                />

              </Col>
            </Row>
          </Col>
          <Col  span={1}></Col>

          <Col span={5}>
            <Row gutter={[0,3]}>
              <Col span={24} className='details-heading'>
                Customer Information
              </Col>
              {
                this.props.customerName
                  ? <>
                      <Col  span={24}>
                        <div className='details-heading-subheading'>

                        <FormattedMessage id='customer.salesOrder.form.customer.label' defaultMessage='' />
                        </div>
                      </Col>
                      <Col  span={24}>
                        <div className='deatils-field-value'>
                          {this.props.customerName} 
                        </div>
                      </Col>
                      <Col   span={24}>
                        <div className='details-heading-subheading'>

                        <FormattedMessage id='contact' defaultMessage='' />
                        <Tooltip placement="right" title={this.props.intl.formatMessage(
                          {
                            id: 'tooltip.customerContact',
                            defaultMessage: ''
                          }
                        )} trigger="click">
                          <QuestionCircleTwoTone />
                        </Tooltip>
                        </div>
                      </Col>
                      <Col  span={24}>
                        <Dropdown
                          style={{width: '100%'}}
                          value={((this.props.selectedContact || {}).firstName || '') + " " + ((this.props.selectedContact || {}).middleName || '') + " " + ((this.props.selectedContact || {}).lastName || '')}
                          items={this.props.boContactList}
                          valueKeyName='firstName'
                          lastNameKey='lastName'
                          optionKeyName='contactId'
                          placeholder='Please select contact name'
                  moduleName={MODULE_CODED_VALUES.CUSTOMER_CONTACTS}

                          canAddNew={true}
                          canDelete={true}
                          isFavouriteKeyName='isPrimaryContact'
                          deleteClickHandler={(payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.CONTACT, payload);
                          }}
                          onAddNewClick={(payload) => {
                            payload.formData = {
                              firstName: payload.textEntered,
                              submittedOnce: false,
                              customerId: (this.props.customer || {}).customerId,
                              pageNumber: this.props.pageNumber || 1,
                              customerName: this.props.customerName,
                            }
                            this.handleNewContactAddition(this.props, payload, MODAL_TYPE.CONTACT)
                          }}
                          onSelect={(contactId) => {
                            let contact = find((this.props.boContactList || []), { contactId: +contactId }) || {};
                            this.props.updateState({
                              selectedContact: contact
                            })
                          }}
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                              selectedContact: undefined,
                            })
                          }}
                        />

                        {/* {
                          getAddressInfoFragment({}, this.props.selectedContact || {}, this.props.customer)
                        } */}
                      </Col>
                      <Col   span={24}>
                        <div className='details-heading-subheading'>

                        <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                        <Tooltip placement="right" title={this.props.intl.formatMessage(
                          {
                            id: 'taxIdentification.tooltip',
                            defaultMessage: ''
                          }
                        )} trigger="click">
                          <QuestionCircleTwoTone />
                        </Tooltip>
                        </div>
                      </Col>
                      <Col  span={24}>
                        <MultipleSelectDropdown
                          style={{ width: '100%' }}
                          items={this.props.customer.customerTaxIdentificationsList || []}
                          placeholder="Tax Identification(s)"
                          mode='multiple'
                          onDeSelect={(value) => {
                            let { selectedCustomerTaxIdentifications } = this.props
                            selectedCustomerTaxIdentifications = filter(selectedCustomerTaxIdentifications, (taxId) => {
                              return taxId !== value;
                            });
                            this.props.updateState({ selectedCustomerTaxIdentifications });
                          }}
                          valueKeyName='taxIdentificationType'
                          valueKeyName2='taxIdentificationNumber'
                          selectedValue={(this.props.customer.customerTaxIdentificationsList || []).length ? (this.props.selectedCustomerTaxIdentifications || []) : []}
                          optionKeyName='customerTaxIdentificationId'
                          onSelect={(customerTaxIdentificationId) => {
                            let selectedCustomerTaxIdentifications = this.props.selectedCustomerTaxIdentifications || [];
                            if (selectedCustomerTaxIdentifications.indexOf(customerTaxIdentificationId) === -1) {
                              selectedCustomerTaxIdentifications.push(customerTaxIdentificationId);
                            } else {
                              selectedCustomerTaxIdentifications = selectedCustomerTaxIdentifications.filter((el) => {
                                return el !== customerTaxIdentificationId
                              });
                            }
                            this.props.updateState({
                              selectedCustomerTaxIdentifications
                            });
                          }}
                          canAddNew={true}
                          canDelete={true}
                          deleteClickHandler={(payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER, payload);
                          }}
                          onAddNewClick={(payload) => {
                            payload.formData = {
                              firstName: payload.textEntered,
                              submittedOnce: false,
                              customerId: (this.props.customer || {}).customerId,
                              pageNumber: this.props.pageNumber || 1,
                              customerName: this.props.customerName
                            }
                            this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                          }}
                        />
                      </Col>
                  </>
                  : 'Please select customer for details'
              }
            </Row>
          </Col>
          <Col  span={1}></Col>

          <Col span={5}>
          
           <Row gutter={[0,3]}>
           <Col span={24} className='details-heading'>
                Delivery Information
              </Col>

                <Col span={24}>
                  <div className="details-heading-subheading">
                    <FormattedMessage id='customer.salesOrder.form.deliveryDate.label' defaultMessage='' />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="value">
                    <DatePicker
                      allowClear={false}
                      format={format}
                      key={`${this.props.deliveryDate ? getMomentDateForUI({date: new Date(this.props.deliveryDate), format}) : getMomentDateForUI({date: new Date(), format})}`}
                      defaultValue={this.props.deliveryDate ? getMomentDateForUI({date: new Date(this.props.deliveryDate), format}) : getMomentDateForUI({date: new Date(), format})}
                      onChange={(selectedDate) => {
                        this.props.updateState({
                          deliveryDate: selectedDate
                        })
                      }}
                      size={size}
                    />
                  </div>
                </Col>
              
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage id='customer.salesOrder.form.gridHeader.paymentTerms' defaultMessage='' />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="value">
                      <Dropdown
                        items={this.props.paymentTerms}
                        optionKeyName='paymentTermId'
                        valueKeyName='termName'
                        //isFavouriteKeyName='isDefault'
                        canAddNew={true}
                        canDelete={true}
                        isLockKeyName='isSystemGenerated'
                        value={this.props.paymentTermName || ""}
                        //permissions={this.props.permissions}
                        //moduleName={[MODULE_CODED_VALUES.PAYMENT_TERM]}
                        //operations={[PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE]}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(this.props, MODAL_TYPE.PAYMENT_TERM, payload);
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            termName: payload.textEntered,
                            days: null,
                            description: '',
                            isDefaultPayment: false,
                            submittedOnce: false
                          }
                          this.handleNewPaymentTermAddition(this.props, payload, MODAL_TYPE.PAYMENT_TERM)
                        }}
                        onSelect={(selectedValue, option) => {
                          this.props.updateState({
                            paymentTermId: option.key, paymentTermName: selectedValue
                          });
                        }}></Dropdown>
                    </div>
                  </Col>
               
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage id='customer.salesOrder.form.gridHeader.priority' defaultMessage='' />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="value">
                      <Dropdown
                        items={['Low', 'Medium', 'Urgent']}
                        onSelect={(selectedValue, option) => {
                          this.props.updateState({
                            priority: selectedValue
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            priority: undefined
                          });
                        }
                        }></Dropdown>
                    </div>
                  </Col>
                
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage id='customer.salesOrder.form.gridHeader.salesPerson' defaultMessage='' />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="value">
                      <Dropdown
                        items={this.props.employees || []}
                        optionKeyName={'relationshipEmployeeId'}
                        valueKeyName={'firstName'}
                        lastNameKey={'lastName'}
                        canAddNew={true}
                  moduleName={MODULE_CODED_VALUES.HRMS_EMPLOYEE}

                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(this.props, MODAL_TYPE.EMPLOYEE, payload);
                        }}
                        onAddNewClick={(payload) => {
                          this.props.updateHeaderState({
                            company: {
                              ...this.props.company,
                              employeeDrawer: true,
                              empInfo: {}
                            }
                          })
                        }}
                        onSelect={(selectedValue, option) => {
                          this.props.updateState({
                            salesPersonId: option.key, salesPersonName: option.name
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            salesPersonId: undefined,
                            salesPersonName: undefined
                          });
                        }
                        }></Dropdown>
                    </div>

                  </Col>
               
                  <Col span={24}>
                      <div className="details-heading-subheading">
                        <FormattedMessage id='customer.salesOrder.form.gridHeader.incoterm' defaultMessage='' />
                    <Tooltip placement="bottom" title={this.props.intl.formatMessage(
                          {
                            id: 'tooltip.internationalCommercial',
                            defaultMessage: ''
                          })
                        }>
                    </Tooltip>
                      </div>
                  </Col>
                  <Col span={24}>
                    <div className="value">
                      <Dropdown
                        items={this.props.incoterms || []}
                        optionKeyName={'inctermId'}
                        valueKeyName={'incotermName'}
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(this.props, MODAL_TYPE.INCOTERM, payload);
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            textBoxValue: payload.textEntered,
                            submittedOnce: false
                          }
                          this.handleNewItemAddition(this.props, payload, MODAL_TYPE.INCOTERM)
                        }}
                        onSelect={(selectedValue, option) => {
                          this.props.updateState({
                            inctermId: option.key, incotermName: option.name
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            inctermId: undefined,
                            incotermName: undefined
                          });
                        }
                        }></Dropdown>
                    </div>

                  </Col>
           
       
           </Row>
          
          </Col>
          <Col  span={1}></Col>
          <Col span={6} className='order-value-info'>
          
              <Row gutter={[0,3]}>

              <Col  span={24} className='details-heading'>
                Order Information
              </Col>
               
                {
                  this.props.quotationNumber &&
                  <>
                    <Col span={24}>
                      <div className="details-heading-subheading">
                        <FormattedMessage id='customer.salesOrder.form.quotationNumber.label' defaultMessage='' />
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="deatils-field-value">
                        {this.props.quotationNumber}
                      </div>
                    </Col>
                  </>
                }
 
                    <Col span={24}>
                      <div className="details-heading-subheading">
                        <FormattedMessage id='customer.salesOrder.form.soDate.label' defaultMessage='' />
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="value">
                        <DatePicker
                          allowClear={false}
                          format={format}
                          key={`${this.props.salesOrderDate ? getMomentDateForUI({date: new Date(this.props.salesOrderDate), format}) : getMomentDateForUI({date: new Date(), format})}`}
                          defaultValue={this.props.salesOrderDate ? getMomentDateForUI({date: new Date(this.props.salesOrderDate), format}) : getMomentDateForUI({date: new Date(), format})}
                          onChange={(selectedDate) => {
                            this.props.updateState({
                              salesOrderDate: selectedDate
                            })
                            //this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                          }}
                          size={size}
                        />
                      </div>
                    </Col>
                 
                    <Col span={24}>
                      <div className='details-heading-subheading' >
                        Currency
                        </div>
                    </Col>
                    <Col span={24}>
                      {this.props.currencyCode ? <span className="currency-icon w20">{this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : (this.props.currencyCode || "-") + " "}</span> : <span className="currency-icon w20">-</span>}
                      <Dropdown
                         items={filter((this.props.countries || []), (country) => { return country.countryName !== (this.props.companyInfo || {}).countryName; })}
                        placeholder='Select Currency'
                        style={{ width: "80%"}}
                        value={this.props.currencyCode && (this.props.currencyCode || "") + " "}
                        onSelect={(selectedValue) => {
                          const selectedCountry = find(this.props.countries, { currencyCode: selectedValue });
                          this.props.updateState({
                            currencyCode: selectedValue,
                            currencyIcon: selectedCountry.currencyIcon
                          })
                        }}
                        valueKeyName='currencyCode'
                        valueKeyName2='countryName'
                        optionKeyName='currencyCode'
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            currencyCode: undefined,
                            currencyIcon: undefined
                          })
                        }}
                      />
                    </Col>
                  
                    <Col span={24}>
                      <div className='details-heading-subheading' >
                        Exchange Rate
                      </div>
                    </Col>
                    <Col span={24}>
                      <span className="currency-icon w20">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon} /> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                      <Input
                        type='number'
                        style={{ width: "80%" }}
                        placeholder="0.00"
                        value={this.props.exchangeRate || ""}
                        onChange={(e) => {
                          this.props.updateState({
                            exchangeRate: e.target.value
                          });
                        }}
                      />
                    </Col>
                  
                    <Col span={24}>
                      <div className="details-heading-subheading">
                        <FormattedMessage id='customer.salesOrder.form.projectDropdown.label' defaultMessage='' />
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="value">
                        <Dropdown
                          items={this.props.projectList}
                          valueKeyName='projectName'
                          value={this.props.projectName}
                          optionKeyName='projectMasterId'
                  moduleName={MODULE_CODED_VALUES.PROJECT}

                          canAddNew={true}
                          canDelete={true}
                          deleteClickHandler={(payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                          }}
                          onAddNewClick={(payload) => {
                            payload.formData = {
                              projectName: payload.projectName,
                            }
                            this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
                          }}
                          onSelect={(projectMasterId, option) => {
                            const selectedProject = find(this.props.projectList, { projectMasterId: Number(option.key) }) || {};
                            this.props.updateState({
                              projectMasterId: selectedProject.projectMasterId,
                              projectName: selectedProject.projectName,
                              projectNumber: selectedProject.projectNumber
                            });
                          }}
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                              projectMasterId: undefined,
                              projectName: undefined,
                              projectNumber: undefined
                            })
                          }}
                        />
                      </div>
                    </Col>
                 
                    <Col span={24}>
                        <div className="details-heading-subheading">
                          <FormattedMessage id='customer.salesOrder.form.documentDropdown.label' defaultMessage='' />
                      <Tooltip placement="bottom" title={this.props.intl.formatMessage(
                          {
                            id: 'documentTitle.tooltip',
                            defaultMessage: ''
                          })
                        }>
                      </Tooltip>
                        </div>
                    </Col>
                    <Col span={24}>
                      <Dropdown
                        items={filter((this.props.documentList || []), (document) => { return document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_ORDER]; })}
                        placeholder='Document name'
                        value={this.props.docName && this.props.documentNameId}
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(this.props, MODAL_TYPE.DOCUMENT_NAME, payload);
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            textBoxValue: payload.textEntered,
                            submittedOnce: false,
                            maxLength: 30
                          }
                          this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DOCUMENT_NAME)
                        }}
                        onSelect={(selectedValue) => {
                          const selectedObj = find((this.props.documentList || []), { documentNameId: Number(selectedValue) });
                          this.props.updateState({
                            documentNameId: selectedValue,
                            docName: selectedObj.docName
                          })
                        }}
                        valueKeyName='docName'
                        optionKeyName='documentNameId'
                        isLockKeyName='isSystemGenerated'
                      // allowClear={true}
                      // onClear={() => {
                      //   this.props.updateState({
                      //     documentNameId: undefined,
                      //     docName: undefined
                      //   })
                      // }}
                      />
                    </Col>
                 
               
              </Row>
            
          </Col>
        </Row>

        {/* <Row>
          <CustomAgGrid
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            disableFocusOnClickOutside={true}
            rowData={[{}]}
            gridStyle={{
              width: '100%',
              height: '100px'
            }} />
        </Row> */}
        <Row>
          <Col span={24}>
            <div className='txn-table'>
              <table>
                <thead>
                  <tr>
                    {/* <th><FormattedMessage id='customer.salesOrder.form.gridHeader.placeOfSupply' defaultMessage='' /></th> */}
                    <th width="10%"><FormattedMessage id='customer.salesOrder.form.gridHeader.soSource' defaultMessage='' /></th>
                    <th width="10%"><FormattedMessage id='customer.salesOrder.form.gridHeader.customerPO' defaultMessage='' /></th>
                    <th width="10%"><FormattedMessage id='customer.salesOrder.form.gridHeader.refNumber' defaultMessage='' /></th>
                    <th width="10%"><FormattedMessage id='customer.salesOrder.form.gridHeader.billingAddress' defaultMessage='' /></th>
                    <th><FormattedMessage id='customer.salesOrder.form.gridHeader.shippingAddress' defaultMessage='' /></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* <td>
                      <Dropdown
                        items= {this.props.states || []}
                        optionKeyName= 'stateId'
                        valueKeyName= 'stateName'
                        onSelect={ (selectedValue) => {
                          this.props.updateState({ placeOfSupply: selectedValue });
                        }}
                      
                      />
                    </td> */}
                 <td>
                    <Dropdown
                     lastColumnCell= {false}
                     items= {this.props.sources || []}
                     value={this.props.soSourceName}
                     optionKeyName= 'soSourceId'
                     valueKeyName= 'soSourceName'
                     canAddNew= {true}
                     canDelete= {true}
                     deleteClickHandler= {(payload) => {
                       this.deleteClickHandler(this.props, MODAL_TYPE.SO_SOURCE, payload);
                     }}
                     onAddNewClick= {(payload) => {
                       payload.formData = {
                         textBoxValue: payload.textEntered,
                         submittedOnce: false
                       }
                       this.handleNewItemAddition(this.props, payload, MODAL_TYPE.SO_SOURCE)
                     }}
                     onSelect= {(selectedValue, optionObj) => {
                       //obj.node.setDataValue(obj.colDef.field, selectedValue);
                       this.props.updateState({ soSourceName: selectedValue, soSourceId: optionObj.key });
                     }}
                     allowClear= {true}
                     onClear= {() => {
                       //obj.node.setDataValue(obj.colDef.field, undefined);
                       this.props.updateState({
                         soSourceName: undefined,
                         soSourceId: undefined
                       });
                     }}
                    />
                 </td>
                    <td>
                      <TextBox
                      value={this.props.customerPO}

                          onChange= {(value) => {
                            this.props.updateState({ customerPO: value.target.value });
                          }
                        }
                      />
                    </td>
                    <td>
                      <TextBox
                      value={this.props.referenceNumber}
                          onChange= {(value) => {
                            this.props.updateState({ referenceNumber: value.target.value });
                          }
                        }
                      />
                    </td>
                  
                 
               
                    <td>
                      <Dropdown
                        items= {this.props.allBillingAddress}
                        optionKeyName= 'locationId'
                        value={(this.props.billingAddress || {}).streetAddress1}
                        valueKeyName= 'streetAddress1'
                        addressLineKey1= 'streetAddress2'
                        addressLineKey2= 'cityName'
                        addressLineKey3= 'stateName'
                        addressLineKey4= 'zipCode'
                        optionClassAddress= 'custom-address-dropdown'
                        canAddNew= {true}
                        canDelete= {true}
                        isFavouriteKeyName= 'isDefault'
                        permissions= {this.props.permissions}
                        moduleName= {MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS}
                        operations= {[PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE]}
                        deleteClickHandler= {(payload) => {
                          this.deleteClickHandler(this.props, MODAL_TYPE.BILLING_ADDRESS, payload);
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            locationType: CONSTANTS.BILLING_ADDRESS,
                            customerId: (this.props.customer || {}).customerId,
                            pageNumber: this.props.pageNumber
                          }
                          this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.BILLING_ADDRESS)
                        }}
                        onSelect= {(selectedValue, optionObj) => {
                          const billingAddress = find(this.props.allBillingAddresses, { locationId: Number(optionObj.key) });
                          this.props.updateState({
                            billingAddress
                          })
                        }}
                        allowClear= {true}
                        onClear={() => {
                          this.props.updateState({
                            billingAddress: undefined
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown
                        items= {this.props.allShippingAddress}
                        optionKeyName= 'locationId'
                        value={(this.props.shippingAddress || {}).streetAddress1}
                        valueKeyName= 'streetAddress1'
                        addressLineKey1= 'streetAddress2'
                        addressLineKey2= 'cityName'
                        addressLineKey3= 'stateName'
                        addressLineKey4= 'zipCode'
                        isFavouriteKeyName= 'isDefault'
                        optionClassAddress= 'custom-address-dropdown'
                        canAddNew= {true}
                        permissions= {this.props.permissions}
                        moduleName= {MODULE_CODED_VALUES.CUSTOMER_ADDRESS}
                        operations= {[PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE]}
                        canDelete= {true}
                        deleteClickHandler= {(payload) => {
                          this.deleteClickHandler(this.props, MODAL_TYPE.SHIPPING_ADDRESS, payload);
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            locationType: CONSTANTS.SHIPPING_ADDRESS,
                            customerId: (this.props.customer || {}).customerId,
                            pageNumber: this.props.pageNumber,
                          }
                          this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.SHIPPING_ADDRESS)
                        }}
                        onSelect={(selectedValue, optionObj) => {
                          const shippingAddress = find(this.props.allShippingAddresses, { locationId: Number(optionObj.key) });
                          this.props.updateState({
                            shippingAddress,
                            placeOfSupply: shippingAddress.stateName
                          })
                        }}
                        allowClear= {true}
                        onClear={ () => {
                          this.props.updateState({
                            shippingAddress: undefined
                          });
                        }}
                      />
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>

      </Fragment>
    );
  }
}

const getAddressInfoFragment = (obj, customerContactObj, customerObj) => {
  // if (!obj || !Object.keys(obj).length) {
  //     return '';
  // }


  let contactName = "";
  if (customerContactObj.salutationName) contactName += customerContactObj.salutationName + ' ';
  if (customerContactObj.firstName) contactName += customerContactObj.firstName;
  if (customerContactObj.middleName) contactName += ' ' + customerContactObj.middleName;
  if (customerContactObj.lastName) contactName += ' ' + customerContactObj.lastName;
  const fragmentObj = <Fragment>
    {
      obj.streetAddress1
        ? <div className='billing-address'>{obj.streetAddress1}
        </div>
        : ''
    }
    {
      obj.streetAddress2
        ? <div className='billing-address'>{obj.streetAddress2}
        </div>
        : ''
    }
    {
      obj.cityName
        ? <div className='billing-address'>
          {
            `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
          }
        </div>
        : ''
    }
    {
      customerContactObj.firstName
        ? <div className='billing-address'>
          {contactName}
        </div> :
        ''
    }
    {
      customerContactObj.workPhone
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.phone.label' defaultMessage='' /> - {customerContactObj.workPhone}
        </div> :
        ''
    }
    {
      customerContactObj.email
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.email.label' defaultMessage='' /> - {customerContactObj.email}
        </div> :
        ''
    }
    {/* {
      customerObj.gstNumber
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.gstIn.label' defaultMessage='' /> - {customerObj.gstNumber}
        </div> :
        ''
    }
    {
      customerObj.panNumber
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.pan.label' defaultMessage='' /> - {customerObj.panNumber}
        </div> :
        ''
    } */}

  </Fragment>

  return fragmentObj;
}

export default injectIntl(SoDetails);
