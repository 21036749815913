import axios from "axios";
import config from "../../../config/environmentConfig";
import { FUNDRAISING_ACTION_LIST, DONATION_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getFundraisingCampaign = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
        }/fundraisingCampaign/getFundraisingCampaign?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
        }&pageNumber=${(payload.pageNumber || 1) - 1}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: FUNDRAISING_ACTION_LIST.CAMPAIGN_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          // showToasterMessage({
          //   messageType: "error",
          //   description:
          //     lang[((err.response || {}).data || "")] ||
          //     "Some error occurred",
          // });
        }
      })
      .finally(() => {
        dispatch({ type: DONATION_ACTION_LIST.DONATION_SETTING_LOADING, data: {isCampaignListLoaded : true}});
      });
  };
};

export const getFundraisingCampaignCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fundraisingCampaign/countFundraisingCampaign?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: FUNDRAISING_ACTION_LIST.CAMPAIGN_LIST_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          // showToasterMessage({
          //   messageType: "error",
          //   description:
          //     lang[((err.response || {}).data || "")] ||
          //     "Some error occurred",
          // });
        }
      });
  };
};

// export const saveFees = (payload) => {
//   return (dispatch) => {
//     return axios
//       .post(
//         `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fees/saveOrUpdate`,
//         payload
//       )
//       .then((response) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({
//             messageType: "error",
//             description:
//               lang[((err.response || {}).data || "")] ||
//               "Some error occurred",
//           });
//         }
//       });
//   };
// };

export const deleteFundraisingCampaign = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fundraisingCampaign/delete`, payload)
      .then(res => {
        // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // props.getFees && props.getFees(props);
        getFundraisingCampaign(props)(dispatch);
        getFundraisingCampaignCount(props)(dispatch);
        showToasterMessage({ messageType: 'success', description: 'Fundraising Campaign deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
         // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: FUNDRAISING_ACTION_LIST.CAMPAIGN_LIST_PAGE_RESET });
  };
};
