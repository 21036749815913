import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegisterComponent from "../../../components/B2B/Register";
import {
    fetchRegistrationFormSetting, saveRegistration, fetchRelationshipDataByRID, getTotalStudentcCount, 
    getTotalStudentcCountByGrade, fetchRelationshipByDomainName, fetchStudentFatherEmail, 
    saveRegistrationFound, sendOtpToUser, verifyOtp, getRegistrationDataByEmail, fetchOrgInvoiceProforma, resetOrgInvoiceProforma,
    getRegStudentLimitCount, saveStudentRegistration
} from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchCountries, fetchStates, fetchPaymentTerms, fetchCustomerDetailsByCustomerId } from '../../../actions/commonActions';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../static/constants';
// import RegistrationSuccessComp from "../../../components/B2B/Register/RegistrationResult";
import { Result, Skeleton } from 'antd';
import { processCCPayment } from '../../InvoicePreview/action';
import { fetchPaymentDetail } from "../../../containers/modal/modalBody/customer/PaymentDetail/action";
import { getClassList } from '../../schoolManagement/class/action';

class Register extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(this.props.location.search) || {};
        this.state = {
            formSetting: {},
            status: linkProps.status,
            relationshipId: linkProps.relationshipId,
            relationshipBillingAddress: {},
            ledgerAccountId: 0,
            regDataLoaded: false,
            preRegistrationProfromaLoaded: false,
            studentList: [{}],
            flashVisible: true,
            incorrectOtp: false,
            // primaryContact: 'father',
        }
    }
    componentDidMount() {
        const linkProps = queryString.parse(this.props.location.search) || {};
        this.props.fetchRelationshipByDomainName({relationshipId: linkProps.relationshipId, domainName: window.location.host, registrationFormSettingId: linkProps.formId }, this.props);
        this.props.getClassList({
            relationshipId: linkProps.relationshipId,
            pageNumber: 1,
            pageSize: 100
        })
    }

    componentWillReceiveProps(props) {
        
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
            const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
            this.setState({
                relationshipBillingAddress,
            })
        }

        if (props.countries !== this.state.countries && props.countries && !this.state.countryName) {
            let obj = props.countries.find(country => (country.countryName === (props.companyInfo).countryName)) || {};
            if ((obj.countryName === 'United States' || obj.countryName === 'United States (USA)' || obj.countryName === 'India') && !props.states.length) {
              this.props.fetchStates(obj.countryId);
            }
            this.setState({ countryName: obj.countryName || '', countryId: obj.countryId});
        }

        if (props.registrationFormSetting && props.registrationFormSetting.registrationFormSettingId && !this.state.regDataLoaded) {
            let formSetting = JSON.parse(props.registrationFormSetting.formSetting);
            this.setState({
                successMessage: props.registrationFormSetting.successMessage,
                relationshipId: props.registrationFormSetting.relationshipId,
                registrationFormSettingId: props.registrationFormSetting.registrationFormSettingId,
                courseId: props.registrationFormSetting.courseId,
                formSetting: formSetting,
                programName: props.registrationFormSetting.programName,
                status: props.registrationFormSetting.isFormActive ? undefined : 'inactive',
                registrationType: props.registrationFormSetting.registrationType,
                ledgerAccountId: props.registrationFormSetting.ledgerAccountId,
                projectMasterId: props.registrationFormSetting.projectMasterId,
                maxStudentCountByGrade: formSetting.totalStudentCountByGrade || {},
                paymentPlan: JSON.parse(props.registrationFormSetting.paymentPlan || '[]'),
                isAllowParentPaymentPlan: props.registrationFormSetting.isAllowParentPaymentPlan || JSON.parse(props.registrationFormSetting.paymentPlan || '[]')?.length ? 1 : 0,
                subcourseAndFeeList: props.registrationFormSetting.subcourse ? JSON.parse(props.registrationFormSetting.subcourse) : [],
                onetimeInvoiceItem: JSON.parse(props.registrationFormSetting.onetimeInvoiceItem || '[]'),
                classFees: formSetting.classFees,  
                questionList: formSetting.questionList || [],
                regDataLoaded: true,
                
            })
        }

        // get registration data on otp verification
        if(!this.state.getRegDataOnce && props.otpVerifyRes?.otpVerified){
            this.props.getRegistrationDataByEmail({
                email: this.state.primaryEmail,
                relationshipId: this.state.relationshipId,
                registrationFormSettingId: this.state.registrationFormSettingId, //userData id user already exists or newly created.
            });

            this.props.fetchCustomerDetailsByCustomerId({
                relationshipId: this.state.relationshipId,
                customerId: props.otpSentRes.customerId || 0,
            })

            this.setState({flashVisible : false, getRegDataOnce: true, userData: props.otpVerifyRes.userData});
        }

        // set loaded org registration data
        if(!this.state?.regDataUpdatedOnce && Object.keys(props.orgRegistrationData || {}).length && Object.keys(props.customerDetail)?.length){
            const fatherContact = props.customerDetail?.boContactList?.find(obj=>obj.familyRelationship === 'father') || {};
            const motherContact = props.customerDetail?.boContactList?.find(obj=>obj.familyRelationship === 'mother') || {};


            this.setState({
                ...props.orgRegistrationData, 
                regDataUpdatedOnce: true, 
                studentList: [{}],
                // studentOptionList: props.orgRegistrationData?.studentList || [],
                primaryContact: fatherContact?.isPrimaryContact ? 'father' : motherContact?.isPrimaryContact ? 'mother' : '',
                primaryEmail: this.state.primaryEmail,
                address: props.customerDetail?.boLocationList[0] || {},
                existingStudentList: props.orgRegistrationData?.studentList,

                fatherContactId: fatherContact.contactId || 0,
                fatherName: Object.keys(fatherContact)?.length ? `${fatherContact.firstName || ''} ${fatherContact.lastName || ''}` : '',
                fatherMobileNumber: fatherContact.cellPhone,
                fatherEmail: fatherContact.emailAddress || '',

                motherContactId: motherContact.contactId || 0,
                motherName:  Object.keys(motherContact)?.length ? `${motherContact.firstName || ''} ${motherContact.lastName || ''}` : '',
                motherMobileNumber: motherContact.cellPhone,
                motherEmail: motherContact.emailAddress || '',

                isSiblingRegistration: (Object.keys(motherContact).length || Object.keys(fatherContact).length) ? true : false,
                
                // father mother info disable
                isfatherNameDisabled: !!(fatherContact.firstName || fatherContact.lastName),
                isFatherMobileNumberDisabled: !!fatherContact.cellPhone,
                isFatherEmailDisabled: !!fatherContact.emailAddress,

                isMotherNameDisabled: !!(motherContact.firstName || motherContact.lastName),
                isMotherMobileNumberDisabled: !!motherContact.cellPhone,
                isMotherEmailDisabled: !!motherContact.emailAddress,

                classFees: JSON.parse(props.registrationFormSetting.formSetting)?.classFees,
                questionList: JSON.parse(props.registrationFormSetting.formSetting)?.questionList,
                selectedPlan:"{}"


            })
            
            // gettting proforma of all registrations
            if(props.orgRegistrationData.orgRegistrationId){
                this.props.fetchOrgInvoiceProforma({
                    relationshipId: this.state.relationshipId,
                    orgRegistrationId: props.orgRegistrationData?.orgRegistrationId,
                    getRegisteredProforma: true
                });
            }
        }

        if(!this.state.preRegistrationProfromaLoaded && props.existingRegInvoiceProforma?.length){

            let earliestExpiryProforma = !props.existingRegInvoiceProforma[0]?.isInvoiceConverted ? props.existingRegInvoiceProforma[0] : {}; 

            this.setState({
                isFeesPending: Object.keys(earliestExpiryProforma)?.length ? true : false,
                preRegistrationProfromaLoaded: true,
                pendingFeeProforma: earliestExpiryProforma || {}
            });
        }
          
    }
    render() {
        window.document.title= this.props?.companyInfo?.storeName || '';
        return <>
            <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 15}} active>
                {Object.keys(this.props.registrationFormSetting).length ? 
                    <RegisterComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
                    :
                    <Result
                        status="500"
                        title="500"
                        subTitle="Sorry, something went wrong. Please contact admin"
                        // extra={<Button type="primary">Back Home</Button>}
                    />
                }
            </Skeleton>
        </> 
    }
}
const mapStateToProps = (state) => {
    return {
        registrationFormSetting: state.settings.registrationFormSetting,
        countries: state.common.countries,
        states: state.common.states,
        companyInfo: state.settings.relationshipData,
        totalStudentCount: state.studentRegistration.totalStudentCount,
        totalStudentCountByGrade: state.studentRegistration.totalStudentCountByGrade,
        otpSentRes: state.studentRegistration.otpSentRes,
        otpVerifyRes: state.studentRegistration.otpVerifyRes,
        orgRegistrationData: state.studentRegistration.orgRegistrationData,
        paymentDetail: state.customerPayment.customerPaymentDetail,
        detailLoading: state.common.detailLoading,
        registrationInvoiceProforma: state.studentRegistration.registrationInvoiceProforma,
        existingRegInvoiceProforma: state.studentRegistration.existingRegInvoiceProforma,
        saveLoading: state.common.saveLoading,
        classList: state.school.classList,
        registrationFormData: state.settings.registrationFormData,
        customerDetail: state.common.customerDetail,
        studentLimitCount: state.studentRegistration.studentLimitCount,

    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveRegistration,
    fetchRegistrationFormSetting,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    fetchCountries,
    fetchStates,
    fetchPaymentTerms,
    fetchRelationshipDataByRID,
    getTotalStudentcCount,
    getTotalStudentcCountByGrade,
    fetchRelationshipByDomainName,
    fetchStudentFatherEmail,
    saveRegistrationFound,
    sendOtpToUser,
    verifyOtp,
    getRegistrationDataByEmail,
    processCCPayment,
    fetchPaymentDetail,
    fetchOrgInvoiceProforma,
    resetOrgInvoiceProforma,
    getClassList,
    fetchCustomerDetailsByCustomerId,
    getRegStudentLimitCount,
    saveStudentRegistration,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Register);
