import React from "react";
import { Col, Row, Skeleton } from 'antd';
import './style.scss';
import { CONSTANTS } from '../../../../../static/constants';
import { fixedDecimalAndFormateNumber, getCurrencySymbol, getMomentDateForUIReadOnly } from '../../../../../utils';
import * as find from 'lodash.find';

const ShippingInfo = (props) => {

    const {txData={}} = props.data;

    const getShippingAddress = (obj={}) => {
        return <>
            {
                obj.streetAddress1
                    ? <span className='billing-address'>{obj.streetAddress1}
                    </span>
                    : ''
            }
            {
                obj.streetAddress2
                    ? <span className='billing-address'> {obj.streetAddress2}
                    </span>
                    : ''
            }
            {
                obj.cityName
                    ? <div className='billing-address'>
                        {
                            `${obj.cityName || ''}${obj.stateName ? `,  ${obj.stateName}` : ''}${obj.zipCode ? `, ${obj.zipCode}` : ''}`
                        }
                    </div>
                    : ''
            }
            {
                obj.countryName
                    ? <div className='billing-address'>
                        {
                            `${obj.countryName || ''}`
                        }
                    </div>
                    : ''
            }
        </>
    }

    return (
        <Row>
            <Col span={8}>
                <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>
                    <Row>
                        <Col span={12}>
                            Customer Name
                        </Col>
                        <Col span={12}>
                            {txData.customerName || '-'}
                        </Col>

                        <Col span={12}>
                            Shipment Size
                        </Col>
                        <Col span={12}>
                            {txData?.length && txData?.width && txData?.height ? `${txData?.length} x ${txData?.width} x ${txData?.height}` : '-'} {txData?.dimensionUnit ? txData.dimensionUnit : 'Inches'}
                        </Col>

                        <Col span={12}>
                            Shipment Weight
                        </Col>
                        <Col span={12}>
                            {txData.weight || 0} {txData.weightUnit || 'Ounces'}
                        </Col>
                    </Row>
                </Skeleton>
            </Col>

            <Col span={8}>
                <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>
                    <Row>
                        <Col span={12}>
                            Order Number
                        </Col>
                        <Col span={12}>
                            {txData.shipmentNumber || '-'}
                        </Col>

                        <Col span={12}>
                            Order Date
                        </Col>
                        <Col span={12}>
                            {txData?.dateCreated ? getMomentDateForUIReadOnly(txData.dateCreated) : "-"}
                        </Col>

                        <Col span={12}>
                            Order Amount
                        </Col>
                        <Col span={12}>
                            {getCurrencySymbol(props?.companyInfo?.currencyCode)}{fixedDecimalAndFormateNumber(props.soDetail?.salesOrderTotalAmount || 0)}
                        </Col>
                    </Row>
                </Skeleton>
            </Col>

            <Col span={8}>
                <Skeleton loading={props.detailLoading || props.isLoadingCarriarList} paragraph={{ rows: 2 }} active>
                    <Row gutter={[0, 8]}>
                        <Col span={12}>
                           Ship to
                        </Col>
                        <Col span={12} style={{lineHeight: '1.3em'}}>
                            {getShippingAddress((props.deliveyNotesData.boLocationShipmentList || []).find(obj=> obj.locationType === CONSTANTS.SHIPPING_ADDRESS) || {})}
                        </Col>
                    </Row>
                </Skeleton>
            </Col>
        </Row>
    );
}

export default ShippingInfo;