import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Tabs } from 'antd';
import { ICONS, MODAL_TYPE } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
import ProjectList from './projectList';

const { TabPane } = Tabs;
const ProjectListingComp = (props) => {

    const projectAddOrUpdate = ({}, projectData) =>{

      if(props.activeTab === "Fundraising"){
        props.updateState({
          projectDrawerVisible:true,
          projectData
        }) 

      }else if (props.activeTab === "Project") {
        props.updateState({
          detailProjectDrawerVisible:true,
          projectData
        }) 
      }
    }
    const breadCrumbList = [
       {
            name: <FormattedMessage id='sidebar.menuItem.hrms' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='sidebar.menuItem.project' defaultMessage='' />,
        }
    ];

    return (
      <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="sidebar.menuItem.project"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
            <button
              className="create-action-btn"
              onClick={() => {
                projectAddOrUpdate(props);
              }}
            >
              <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>
        <div className="view-container">
          <ProjectList {...props} />
        </div>
      </>
    );
};



export default injectIntl(ProjectListingComp);
