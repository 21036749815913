import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDataIfNeeded } from '../../../utils';
import { CONSTANTS } from '../../../static/constants';
import SupplierDrawer from '../../../components/drawer/supplier/SupplierDrawer';
import {
  fetchCountries, fetchStates, fetchBusinessTypes, fetchIndustries, fetchSalutation, fetchSupplierSources,
  fetchPaymentTerms, showModal, hideModal, addSupplierSource, deleteSupplierSource, addPaymentTerm, deletePaymentTerm,  addIndustry, deleteIndustry, addBusinessType, deleteBusinessType,
  fetchTaxIdentifications, addTaxIdentification, deleteTaxIdentifications,
  addSalutation, deleteSalutation
} from '../../../actions/commonActions';
import { createOrUpdateSupplier, isDuplicateSupplier } from './action';
import { checktaxIdentificationNumber } from '../TaxIdentification/action';
class Supplier extends Component {
  constructor(props) {
    super(props);
    const {isUpdate, supplierData, supplierName, isClone} = props;
    const needToInIt = ((isUpdate || isClone) && supplierData) ? true : false;
    const companyInfo = this.props.companyInfo || {};

    this.state = {
      isUpdate,
      isClone,
      supplierId: needToInIt ? supplierData.supplierId: undefined,
      version: needToInIt ? supplierData.version || 0 : undefined,
      supplierStoreName: needToInIt ? supplierData.supplierStoreName : (supplierName ? supplierName : undefined),
      preSupplierStoreName: needToInIt ? supplierData.supplierStoreName : (supplierName ? supplierName : undefined),
      paymentTermName: needToInIt ? supplierData.paymentTermName : undefined,
      paymentTermId: needToInIt ? Number(supplierData.paymentTermId || 0) || undefined  : undefined,
      supplierType: needToInIt ? supplierData.supplierTypeName || undefined : undefined,
      supplierTypeId: needToInIt ? supplierData.supplierTypeId || undefined : undefined,
      relationshipSince: (needToInIt && supplierData.relationshipSince) ? new Date(supplierData.relationshipSince) : undefined,
      webSite: needToInIt ? supplierData.webSite : undefined,
      supplierSource: undefined,
      supplierSourceId: needToInIt ? supplierData.supplierSourceId || undefined : undefined,
      industryName: needToInIt ? supplierData.industryName : undefined,
      businessIndustryId: needToInIt ? supplierData.industryId || undefined : undefined,
      shippingAddressSameAsBilling: 0,
      gstMechanism: needToInIt ? supplierData.gstMechanism : undefined,
      gstNumber: needToInIt ? supplierData.gstNumber : undefined,
      panNumber: needToInIt ? supplierData.panNumber : undefined,
      locatedInSez: needToInIt ? supplierData.locatedInSez : 0,
      supplierTaxIdentificationsList: needToInIt ? supplierData.supplierTaxIdentificationsList : [{}],
      supplierEmail: needToInIt ? supplierData.supplierEmail || undefined : undefined,
      supplierPhone: needToInIt ? supplierData.supplierPhone || undefined : undefined,
      debitLimit: needToInIt ? supplierData.debitLimit || undefined : undefined,

      billingAddress: isClone && supplierData.boLocationList?.length ? 
      supplierData.boLocationList?.find(obj=>obj.locationType === "BillingAddress" && obj.isDefault === 1) 
      :
      {
        streetAddress1: undefined,
        streetAddress2: undefined,
        cityName: undefined,
        stateName: undefined,
        zipCode: undefined,
        locationName: undefined,
        countryName: undefined
      },
      shippingAddress: isClone && supplierData.boLocationList?.length ? 
        supplierData.boLocationList?.find(obj=>obj.locationType === "ShippingAddress" && obj.isDefault === 1)
        :
        {
        streetAddress1: undefined,
        streetAddress2: undefined,
        cityName: undefined,
        stateName: undefined,
        zipCode: undefined,
        locationName: undefined,
        countryName: undefined
      },
      contact: isClone && supplierData.boContactList?.length ? 
        supplierData.boContactList?.find(obj=>obj.isPrimaryContact)
      :
      {
        salutationId: undefined,
        firstName: undefined,
        middleName: undefined,
        lastName: undefined,
        preferCallName: undefined,
        workPhone: undefined,
        cellPhone: undefined,
        faxNumber: undefined,
        emailAddress: undefined,
        contactType: undefined,
        salutationName: undefined,
        countryCallingCode: companyInfo.countryCallingCode,
      },
      fileToUpload: null,
      countryCallingCode: companyInfo.countryCallingCode,
    }
  }
  

  initCalls() {
    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId };
    fetchDataIfNeeded('fetchCountries', 'countries', this.props);
    fetchDataIfNeeded('fetchSupplierSources', 'supplierSources', this.props, payload);
    fetchDataIfNeeded('fetchBusinessTypes', 'businessTypes', this.props, payload);
    fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
    fetchDataIfNeeded('fetchIndustries', 'industries', this.props, payload);
    fetchDataIfNeeded('fetchSalutation', 'salutations', this.props, payload);
    fetchDataIfNeeded('fetchTaxIdentifications', 'allTaxIdentificatinType', this.props, payload);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.countries !== this.props.countries && nextProps.countries) {
      let obj = nextProps.countries.find(country => (country.countryName === (this.props.companyInfo).countryName)) || {};
      this.setState({ 
        shippingAddress : { ...this.state.shippingAddress , countryName: obj.countryName || '', countryId: obj.countryId},
        billingAddress : { ...this.state.billingAddress , countryName: obj.countryName || '', countryId: obj.countryId},
        countryCallingCode:obj.countryCallingCode,
        countryId:obj.countryId
      });
      if (obj.countryId === CONSTANTS.INDIA_COUNTRY_ID || obj.countryName === 'United States' || obj.countryName === 'United States (USA)') {
        this.props.fetchStates(obj.countryId);
      }
    }

    if(this.props.isUpdate && this.props.businessTypes?.length){
      let supplierType = this.props.businessTypes.find((item)=>item.businessTypeId == this.state.supplierTypeId)
      this.setState({
        supplierType:supplierType?.businessType,
        supplierTypeId:supplierType?.businessTypeId
      })
    }
  }

  render() {
    return <SupplierDrawer 
              initCalls={() => {
                this.initCalls();
              }}
              {...this.props}
              {...this.state} 
              updateDrawerState={(data) => { this.setState(data) }} 
            />
  }

}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countries,
    states: state.common.states,
    supplierSources: state.common.supplierSources,
    businessTypes: state.common.businessTypes,
    paymentTerms: state.common.paymentTerms,
    industries: state.common.industries,
    salutations: state.common.salutations,
    companyInfo: state.common.companyInfo,
    allTaxIdentificatinType: state.common.allTaxIdentificatinType,
    drawerLoading: state.common.drawerLoading,
    listLoading: state.common.listLoading,
    saveLoading: state.common.saveLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCountries,
  fetchStates,
  fetchSupplierSources,
  fetchBusinessTypes,
  fetchSalutation,
  createOrUpdateSupplier,
  fetchPaymentTerms,
  addPaymentTerm,
  deletePaymentTerm,
  fetchIndustries,
  addIndustry,
  deleteIndustry,
  addBusinessType,
  deleteBusinessType,
  addSupplierSource,
  deleteSupplierSource,
  showModal,
  hideModal,
  fetchTaxIdentifications, 
  addTaxIdentification, 
  deleteTaxIdentifications,
  addSalutation, 
  deleteSalutation,
  isDuplicateSupplier,checktaxIdentificationNumber
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
