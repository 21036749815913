import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StoreSettingsComp from "../../../components/eStore/storeSettings";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import { updateAnalytics } from "../menus/action";
import queryString from 'query-string';

class StoreSettings extends Component {
  constructor(props) {
    
    super(props);
    const linkProps = queryString.parse(decodeURIComponent(props.location?.search||"")) || {}
    this.state = {
        pageNumber: 1,
        pageSize: 25,
        selectedMenuItem : linkProps.active || "Login Flow",
        ...props
    };
  }

  render() {
    return (
        <StoreSettingsComp
            {...this.props}
            {...this.state}
            updateState={(data) => {
            this.setState(data);
            }}
        ></StoreSettingsComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    schoolList: state.school.schoolList,
    schoolCount: state.school.schoolCount,
    permissions: state.auth.permissions,

  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,updateAnalytics
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StoreSettings);
