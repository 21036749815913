import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Skeleton, Menu, Empty, Button,} from "antd";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import { CustomAgGrid } from '../../general/CustomAgGrid';
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from '../../../static/constants';
import ManageUser from "../../../containers/modal/modalBody/settings/ManageUser";
import { getMomentDateForUIReadOnly, getPermissionData } from '../../../utils';
import DropdownAction from "antd/lib/dropdown";
import PageBreadcrumb from '../../PageBreadcrumb';
import PageHeader from '../../Common/pageHeader';



function TeamAndRolesComp(props) {
    const {allCompanyUsers ,permissions} = props
    const primaryPerm = (permissions && permissions.primary) || [];
      const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.SETTINGS_USER_MANAGEMENT);
      const deleteUser = (user) => {
          const modalBody = "Are you sure you want to delete user?";
          const modalData = {
              modalBody,
              handleSubmit: () => {
                  props.deleteUsers({ id: user.userId, relationshipId: props.companyInfo.relationshipId, email: user.email });
                  props.hideModal();
              },
          };
          props.showModal(modalData);
      }
      const breadCrumbList = [
        {
            name: 'Dashboard',
            link: 'dashboard'
        }, {
            name: 'Settings',
            link: '/admin/show-settings'
        },
        {
          name: 'User Management',
         
      }
    ];
      
      const actionMenu = (e) => {
        return (
          <Menu className="row-action-dropdown">
  
            <Menu.Item key="0" value="title">
              Actions
            </Menu.Item>
  
            <Menu.Divider />
           {
            permissionsData.update ?  <Menu.Item key="1" value="edit" onClick={() => {
              props.showModal({
                  title: <div>
                          <div className='b-text' style={{fontSize: '14px'}}><FormattedMessage id='setting.userManagement.updatePermissions.label' defaultMessage='' /></div>
                          <div style={{lineHeight: '1.2em'}} className='mt5'>
                            <div className='b-text'>{e.firstName || ''} {e.lastName? e.lastName : ''}</div>
                            <div className='light-txt'>{e.email || ''}</div>
                          </div>
                      </div>,
                  modalBody: <ManageUser toDisable={e.userType === 'admin'} {...props} userData={e} />,
                  width: 1000,
                  hideFooter: true,
                  wrapClassName: "manage-user-modal"
              })
            }}>
              <i className={ICONS.UPDATE} /> Update Permission
            </Menu.Item>: ""
           }
           
  
            {(permissionsData.delete && e.userType !== 'admin') ? 
            
              <Menu.Item key="2" onClick={()=>
                {
                  deleteUser(e);
                }
              }>
                <i className={ICONS.DELETE} />
                Delete
              </Menu.Item>
              :
              ''
            }
          </Menu>
        )
        };
       
        const createNewUser = ()=>{
          props.showModal({
            title: <FormattedMessage id='setting.userManagement.addUser.label' defaultMessage='' />,
            modalBody: <ManageUser {...props} />,
            width: 1000,
            hideFooter: true,
            wrapClassName: "manage-user-modal"
          })
        }
      return (
          <>
          
              
  
          <div className="view-container">
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="setting.userManagement.label.table.heading"
                    defaultMessage=""
                  />
                </div>
                {(allCompanyUsers || []).length ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{allCompanyUsers.length}</div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="right-actions">
                        {
                            permissionsData?.create ?  <Button
                            style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
                            className="create-action-btn"
                            onClick={() => {
                                createNewUser();
                            }}
                        >
                            <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
                            <FormattedMessage id="button.create.label" defaultMessage="" />
                        </Button>: ""
                        }
                       
                    </div>
            </div>
            <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>
              <div className="table-container no-pagination">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="serial.no" defaultMessage="" />
                      </th>
                      {/* <th><FormattedMessage id='setting.userManagement.listing.title' defaultMessage=''/></th> */}
                      <th>
                        <FormattedMessage
                          id="cardConnect.userName.text"
                          defaultMessage=""
                        />
                      </th>
                      {/* <th><FormattedMessage id='setting.userManagement.listing.middleName' defaultMessage=''/></th> */}
                      {/* <th><FormattedMessage id='setting.userManagement.listing.lastName' defaultMessage=''/></th> */}
                      <th>
                        <FormattedMessage
                          id="setting.userManagement.listing.email"
                          defaultMessage=""
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="setting.userManagement.listing.phone"
                          defaultMessage=""
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="setting.userManagement.listing.mobile"
                          defaultMessage=""
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="setting.userManagement.listing.createdDate"
                          defaultMessage=""
                        />
                      </th>
                      <th>
                        <FormattedMessage id="common.status" defaultMessage="" />
                      </th>
                      {
                        (permissionsData.update || permissionsData.delete) ? <th>
                        <FormattedMessage
                          id="common.listing.header.action"
                          defaultMessage=""
                        />
                      </th>: ""
                      }
                      
                    </tr>
                  </thead>
                  <tbody>
                    {(allCompanyUsers || []).length ? (
                      allCompanyUsers.map((rowData, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {rowData.userType === "admin" || true ? (
                                <i className="fi fi-rr-user-lock" />
                              ) : (
                                ""
                              )}{" "}
                              {rowData.firstName || ""} {rowData.middleName || ""}{" "}
                              {rowData.lastName || ""}
                            </td>
                            <td>{rowData.email}</td>
                            <td>{rowData.workPhone}</td>
                            <td>{rowData.cellPhone}</td>
                            <td>
                              {rowData.dateCreated ?
                                getMomentDateForUIReadOnly({
                                  date: rowData.dateCreated,
                                  format: CONSTANTS.TABLE_DATE_FORMAT,
                                })
                                : 
                                ''
                              }
                            </td>
                            <td>
                              {rowData.status ? 
                              <div className={`status-code ${rowData.status.toLowerCase()}`}>
                                {rowData.status}
                              </div>:''}
                            </td>
  
                            {
                              (permissionsData.update || permissionsData.delete) ?<td style={{ paddingLeft: "50px" }}>
                              <DropdownAction
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE} />
                              </DropdownAction>
                            </td> : ""
                            }
                            
                          </tr>
                        );
                      })
                    ) : (
                      <tr key="empty-data-box">
                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Skeleton>
            {/* <div className='agGridWrapper'>
                      <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
                          <CustomAgGrid
                              columnDefs={columnDefs}
                              rowData={props.allCompanyUsers || []}
                              rowDragManaged={true}
                              domLayout={"autoHeight"}
                              animateRows={true}
                              onFirstDataRendered={onFirstDataRendered}
                              getRowHeight={getRowHeight}
                          >
                          </CustomAgGrid>
                      </div>
                  </div> */}
          </div>
        </>
      );
}
export default TeamAndRolesComp;