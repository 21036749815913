import React from "react";
import { Skeleton, DatePicker, Empty } from "antd";
import {
  fixedDecimalAndFormateNumber,
  getMomentDateForUI,
  getCurrentDateForBackend,
  sortTable,
} from "../../../../utils";
import { injectIntl, FormattedMessage } from "react-intl";
import InvoicesListing from "../../../../containers/modal/modalBody/customer/AllInvoicesByLedger";
import { CONSTANTS, ICONS } from "../../../../static/constants";
import { EmptyTableData } from "../../../general/EmptyTableData";
import { EmptyBox } from "../../../general/EmptyBox";

const { RangePicker } = DatePicker;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const salesOrder = (props) => {
  const { companyInfo } = props;

  const handleSortColumn = (index) => {
    const currentSortDirection = props.sortStates[index] || "none";
    const nextSortDirection =
      currentSortDirection === "none"
        ? "asc"
        : currentSortDirection === "asc"
        ? "desc"
        : "none";

    // Update sorting state using props.updateState
    props.updateState({
      sortStates: {
        // ...props.sortStates,
        [index]: nextSortDirection,
      },
    });

    // Call your sorting function
    const direction = nextSortDirection === "desc" ? 1 : 0;
    sortTable(
      document.getElementById("salesLedgerList-table"),
      index,
      direction
    );
  };
  const getSortIcon = (index) => {
    const sortState = props.sortStates[index];
    if (sortState === "asc") return <i className={ICONS.SORT_UP} />;
    if (sortState === "desc") return <i className={ICONS.SORT_DOWN} />;
    return props.hoveredColumn === index ? (
      <i style={{ opacity: 1 }} className={ICONS.SORT} />
    ) : (
      <i style={{ opacity: 0 }} className={ICONS.SORT} />
    );
  };

  return (
    <div className="view-container">
      <div className="view-container-actions">
        <div className="left-actions">
          <div className="table-heading">Ledger Summary</div>
          {(props.customerInvoiceLedgerSummaryList || []).length ? (
            <>
              <div className="vertical-sep" />
              <div>{props.customerInvoiceLedgerSummaryList.length}</div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="right-actions">
          {/* <RangePicker
                            allowEmpty={true}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            value={[props.legderStartDate ? getMomentDateForUI({ date: new Date(props.legderStartDate), format }) : null, props.legderEndDate ? getMomentDateForUI({ date: new Date(props.legderEndDate), format }) : null]}
                            onChange={(dates) => {
                                if ((dates || []).length === 2) {
                                    props.updateState({ legderStartDate: dates[0], legderEndDate: dates[1] });
                                    const payload = {
                                        customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                        filters: {
                                            startDate: getCurrentDateForBackend(dates[0]),
                                            endDate: getCurrentDateForBackend(dates[1]),
                                        }
                                    };
                                    props.fetchCustomerLedgerTxSummaryList(payload);
                                } else {
                                    props.updateState({ legderStartDate: null, legderEndDate: null });
                                    const payload = {
                                        customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                        filters: {}
                                    };
                                    props.fetchCustomerLedgerTxSummaryList(payload);
                                }
                            }}
                            allowClear={true}
                        /> */}
          {/* <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            if( !props.legderStartDate && !props.legderEndDate ){
                                return showToasterMessage({ messageType: 'error', description: 'Please select date range' });
                            }
                            const payload = {
                                customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                filters: {
                                    startDate: props.legderStartDate ? getCurrentDateForBackend(props.legderStartDate) : null,
                                    endDate: props.legderEndDate ? getCurrentDateForBackend(props.legderEndDate) : null,
                                }
                            };
                            props.fetchCustomerLedgerTxSummaryList(payload);
                        }}>
                            {<FilterOutlined />} Apply Filter
                        </button>
                        <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            props.updateState({
                                legderStartDate: null, legderEndDate: null,
                            });
                            const payload = {
                                customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                filters: {}
                            };
                            props.fetchCustomerLedgerTxSummaryList(payload);
                            }}>
                                {<UndoOutlined />} Clear Filter
                        </button> */}
        </div>
      </div>
      <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>
        <div className="table-container table-h-profile">
          {
            <table id="salesLedgerList-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th>
                    <div
                      onClick={() => handleSortColumn(1)}
                      onMouseEnter={() =>
                        props.updateState({ hoveredColumn: 1 })
                      }
                      onMouseLeave={() =>
                        props.updateState({ hoveredColumn: null })
                      }
                      className="sortTable-Container justify-start"
                    >
                      Ledger
                      {getSortIcon(1)}
                    </div>
                  </th>
                  <th>
                    <div
                      onClick={() => handleSortColumn(2)}
                      onMouseEnter={() =>
                        props.updateState({ hoveredColumn: 2 })
                      }
                      onMouseLeave={() =>
                        props.updateState({ hoveredColumn: null })
                      }
                      className="sortTable-Container justify-end"
                    >
                      Amount
                      {getSortIcon(2)}
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.customerInvoiceLedgerSummaryList.length > 0 ? (
                  (props.customerInvoiceLedgerSummaryList || []).map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {e.ledgerAccountName ? (
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                const invoicePayload = {
                                  customerId: e.customerId,
                                  ledgerAccountId: e.ledgerAccountId,
                                };
                                props.pushModalToStack({
                                  title: (
                                    <div className="ant-modal-title">
                                      {" "}
                                      <FormattedMessage
                                        id="sidebar.menuItem.invoice"
                                        defaultMessage=""
                                      />
                                    </div>
                                  ),
                                  modalBody: (
                                    <InvoicesListing
                                      {...props}
                                      {...invoicePayload}
                                      invoicePayload={invoicePayload}
                                    />
                                  ),
                                  width: 1000,
                                  hideFooter: true,
                                  wrapClassName: "modal-custom-detail",
                                });
                              }}
                            >
                              {e.ledgerAccountName}{" "}
                            </span>
                          ) : (
                            <EmptyBox />
                          )}
                        </td>
                        <td className="amount-TextRight">
                          {e.totalAmount ? (
                            <span>
                              {fixedDecimalAndFormateNumber(e.totalAmount)}
                            </span>
                          ) : (
                            <EmptyBox />
                          )}
                        </td>
                        <td width={"40%"}></td>
                      </tr>
                    );
                  })
                ) : (
                  <tr key="empty-data-box">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                      <EmptyTableData />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          }
        </div>
      </Skeleton>
      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right"></div>
      </div>
    </div>
  );
};

export default injectIntl(salesOrder);
