import React, { Fragment } from 'react'
import MyProfile from '../../containers/Account/MyProfile';
import ProfileSetting from '../../containers/Account/ProfileSetting';
import InPageTabMenu from '../Common/InPageTabMenu';
import { getPermissionData } from '../../utils';
import PageHeader from '../Common/pageHeader';
import TeamRoles from '../../containers/Account/TeamRoles';
import Subscription from '../../containers/Account/Subscription';
import { MODULE_CODED_VALUES } from '../../static/constants';
import { FormattedMessage } from 'react-intl';



const AccountComponent = (props) => {
    const {permissions} = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_SETTING);
    const breadCrumbList = [
        {
          name: <FormattedMessage id='sidebar.menuItem.dashboard' defaultMessage='' />,
        },
        {
          name: <FormattedMessage id='account.myprofile.breadcrumb.account' defaultMessage='' />,
        }
    ];
    const handleActiveTab = (index) => {
        props.updateState({
            selectedMenuItem: index
        })
    };
     
    const activeTab = () => {
        switch (props.selectedMenuItem) {
            case "Profile":
                return  <MyProfile {...props}/> ;
            case "Settings":
                return  <ProfileSetting {...props}/> ;   
            case "TeamRole":
              return  <TeamRoles {...props}/>
  
            case "Subscription":
              return  <Subscription  {...props}/> 
  
          
            default:
                return null;
        }
    };
    const menuList = [{     
        icon: "fi fi-rr-user",
        name: "sidebar.menuItem.account.myProfile",
        moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
        key:"Profile"
    },
        
        {     
          icon: "ffi fi-rs-settings",
          name: "sidebar.menuItem.account.orgSettings",
          moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
         key:"Settings"
    
      },
      {
          icon: "fi fi-br-users-alt",
          name: "sidebar.menuItem.account.teamRole",
          moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
        key:"TeamRole"
    
      },
      {
        icon: "fi fi-rr-rectangle-list",
        name: "sidebar.menuItem.account.subscription",
        moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
      key:"Subscription"
  
    },
   
      ]
   
  return (
    <>
         <PageHeader {...props} 
        pageName= "account.myprofile.breadcrumb.account"
        breadCrumbList={breadCrumbList}
       
     
      />
       

       <div className="account-container">
              <div className="account-sidenav">
                  <InPageTabMenu menuList={menuList} onItemClick={handleActiveTab} {...props}/>
              </div>
              <div className="account-main-body">
                  {activeTab()}           
              </div>
          </div>

</>
  )
}

export default AccountComponent