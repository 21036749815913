import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { CREDIT_MEMO_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchCreditMemoDetail = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
      return axios
          .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/creditMemo/getCreditMemoData?creditMemoMasterId=${payload.creditMemoMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: CREDIT_MEMO_ACTION_LIST.CREDIT_MEMO_DETAIL, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
            }else{
              err.actionToCall = CREDIT_MEMO_ACTION_LIST.CREDIT_MEMO_DETAIL;
            }
          }).finally(() => {
            dispatch({
              type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
            });
          })
      }
  }

  export const resetCreditMemoDetail  = () => {
    return dispatch => {
      dispatch({ type: CREDIT_MEMO_ACTION_LIST.CREDIT_MEMO_DETAIL_RESET });
    }
  }

  export const uploadStamp = (payload) => {
    return dispatch => {
      let formData = new FormData();
      formData.append("file", payload.file);
      formData.append('objectKey', payload.objectKey);
      formData.append('rid', payload.rid);
      formData.append('customerId', payload.customerId);
      formData.append('creditMemoMasterId', payload.creditMemoMasterId);
      formData.append("contentType", "multipart/form-data;");
      formData.append("contentLength", "1234");
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/creditMemo/saveOrUpdateStamp`,
          formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: CREDIT_MEMO_ACTION_LIST.CREDIT_MEMO_DETAIL, data: res.data });
        })
        .catch(err => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    }
  }
  
  export const deleteStamp = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/creditMemo/deleteStamp?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&creditMemoMasterId=${payload.creditMemoMasterId}`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchCreditMemoDetail(payload);
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
          }
        })
    }
  }