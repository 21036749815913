import { PACKAGE_ACTION_LIST } from '../static/constants';
const initialState = {
  packageList: {
    1: []
  },
  packageCount: 0,
  packageListById:[]
}

const PackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case PACKAGE_ACTION_LIST.PACKAGE_LIST:
      return {
        ...state,
        packageList: {
          ...state.packageList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PACKAGE_ACTION_LIST.PACKAGE_COUNT:
      return { ...state, packageCount: action.data };
    case PACKAGE_ACTION_LIST.PACKAGE_LIST_PAGE_RESET:
      return { ...state, packageList: initialState.packageList };
    case PACKAGE_ACTION_LIST.NEXT_PACKAGE_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case PACKAGE_ACTION_LIST.NEXT_PACKAGE_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case PACKAGE_ACTION_LIST.PACKAGE_DATA_LIST:
      return { ...state, packageListById: action.data  };
    case PACKAGE_ACTION_LIST.RESET_PACKAGE_DATA_LIST:
      return { ...state, packageListById: action.data};
    default:
      return state;
  }
};

export default PackageReducer;