import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
import { popModalFromStack } from '../../../../../actions/commonActions';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createLandingCostSetup = (props, payload) => {
    const payloadObj = _getPayloadObj(props, payload);
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/test/testCeate`, payloadObj)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Created successfully.',
            messageType: 'success'
          });
          popModalFromStack();
        })
        .catch((err = {}) => {
            showToasterMessage({
              description: lang[((err.response || {}).data || "")] || 'Some error occurred'
            })
        })
    }
}

const _getPayloadObj = (props, payload) =>{
    let payloadList = [];

    for(let i=0; i< payload.length; i++){
      let item = payload[i];

      payloadList.push(
        {
          supplierId: item.supplierId,
          supplierName: item.supplierName,
          foreignCurrencyIcon: props.currencyObj.currencyIcon,
          foreignCurrency: props.currencyObj.currencyName,
          exchangeRate: props.exchangeRate,
          foreignCurrencyAmount: item.amountForeign,
          currencyCode: props.currencyObj.currencyCode,
          isMultiCurrency: !props.exchangeRate || props.exchangeRate === 1 ? 1 : 0,
          amount: item.totalAmount,
          taxId: item.taxSingleRateId,
          taxName: item.taxNameDisplay,
          taxRate: item.taxPercent,
          distributionBy: item.distributeName,
          ledgerAccountIdCredit: item.ledgerCrId,
          ledgerAccountNameCredit: item.ledgerCrName,
          ledgerAccountIdDebit: item.ledgerDrId,
          ledgerAccountNameDebit: item.ledgerDrName,
        }
      )
    }

    return payloadList;
}


