import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, HIERARCHY_ACTION_LIST } from '../../../../static/constants'
import { showToasterMessage } from '../../../../utils';
//import {  formatDateForBackend } from '../../../utils';


export const createCompanyHierarchy = (payload, props) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
        return axios.post(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/companyHierarchy/saveOrUpdateCompanyHierarchy`, payload
        )
        .then(response => {
            showToasterMessage({messageType: 'success', description: 'Company hierarchy created successfully'});
            props.updateState({
                company: {
                    hierarchyDrawerVisible: false
                }
                });
                props.fetchCompanyHierarchy({relationshipId: payload.relationshipId});
        })
        .catch(err => {
        })
        .finally(()=>{
            dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    }
}

export const fetchChildCompanyHierarchiesByLevel = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
        return axios.get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/companyHierarchy/getChildCompanyHierarchies?relationshipId=${payload.relationshipId}&hierarchyLevel=${payload.hierarchyLevel}`
        )
        .then(response => {
            dispatch({ type: HIERARCHY_ACTION_LIST.CHILD_HIERARCHY_LIST_BY_LEVEL, data: response.data });
        })
        .catch(err => {
        })
        .finally(()=>{
            dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    }
}

export const fetchParentCompanyHierarchiesByLevel = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
        return axios.get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/companyHierarchy/getParentCompanyHierarchies?relationshipId=${payload.relationshipId}&hierarchyLevel=${payload.hierarchyLevel}`
        )
        .then(response => {
            dispatch({ type: HIERARCHY_ACTION_LIST.PARENT_HIERARCHY_LIST_BY_LEVEL, data: response.data });
        })
        .catch(err => {
        })
        .finally(()=>{
            dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    }
}

export const resetParentCompanyHierarchyByLevel = () => {
    return dispatch => {
      dispatch({ type: HIERARCHY_ACTION_LIST.RESET_PARENT_HIERARCHY_LIST_BY_LEVEL });
    }
  }

  export const resetChildCompanyHierarchyByLevel = () => {
    return dispatch => {
      dispatch({ type: HIERARCHY_ACTION_LIST.RESET_CHILD_HIERARCHY_LIST_BY_LEVEL });
    }
  }