import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../../utils";
const { lang } = require("../../../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const brodcastTextSMS = (payload, props) => {

    return (dispatch) => {
        return axios
            .post(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextTwilio}/message/sendSMS`,
                payload
            )
            .then((response) => {
                if (props) {
                    props.updateHeaderState({
                        communication: {
                            addBrodcastSMSDrawerVisible: false,
                            message: '',
                            previewUrl: '',
                            brodcastSMSType: '',
                        }
                    });
                }
                showToasterMessage({ messageType: 'success', description: 'Message has been sent successfully' });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || "")] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const brodcastWhatsappSMS = (payload, props) => {
    return (dispatch) => {
        return axios
            .post(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextTwilio}/message/sendWhatsappMessage`,
                payload
            )
            .then((response) => {
                if (props) {
                    props.updateHeaderState({
                        communication: {
                            addBrodcastSMSDrawerVisible: false,
                            message: '',
                            previewUrl: '',
                            brodcastSMSType: '',
                        }
                    });
                }
                showToasterMessage({ messageType: 'success', description: 'Message has been sent successfully' });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || "")] ||
                            "Some error occurred",
                    });
                }
            });
    };
};





