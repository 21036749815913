import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { STUDENT_REGISTRATION_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getOrgRegistrationByCustomerId= (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration//getOrgRegistrationByCustomerId?formId=${payload.formId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
        .then(res => {
          dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.CUSTOMER_REGISTRATION_LIST, data: res.data});
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
          } else {
            err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.CUSTOMER_REGISTRATION_LIST;
          }
        })
    }
  }
  