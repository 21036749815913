import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const importContact = (payload, props) => {
  var formData = new FormData();
  formData.append("file", payload.fileToUpload);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerExcel/importContact?relationshipId=${payload.relationshipId}`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        showToasterMessage({ messageType: "success", description: lang[(res.data || "")] || "Upload completed succesfully" });
        props.popModalFromStack();
        props.getAllContacts && props.getAllContacts({ relationshipId: payload.relationshipId, pageSize: 25, pageNumber: 0 });
        props.getContactListCount && props.getContactListCount({ relationshipId: payload.relationshipId });
        props.fetchAllContacts && props.fetchAllContacts({ relationshipId: payload.relationshipId });

        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        if(lang[((err.response || {}).data || "")]){
          showToasterMessage({messageType: "error", description: lang[((err.response || {}).data || "")] || "Please reselect the upload file."});
        }else{
          showToasterMessage({messageType: "error", description: "Please reselect the upload file."});
          props.popModalFromStack();
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}