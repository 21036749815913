import React, {useRef, useEffect,  useState} from 'react';
import { Link } from 'react-router-dom';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import { addDaysToDate, getPermissionData } from '../../../../../utils';
import { DropdownPriceList } from '../../../../general/MarkupDropdown';
import { MODAL_TYPE, MODULE_CODED_VALUES } from '../../../../../static/constants';
import LoadingIcon from '../../../../../assets/images/loading.gif';
import StandardPurchaseOrder from '../../../../../containers/supplier/purchaseOrder/StandardPurchaseOrder';
import MultiCurrencyPurchaseOrder from '../../../../../containers/supplier/purchaseOrder/MultiCurrencyPurchaseOrder';
import { DetailDropdown } from '../../../../general/DetailDropdown';

const HeaderAction = (props) => {
    const [templateDropdown, updateTemplateDropdown] = useState(false);
    const {
        intl,
        pageNumber,
        updateState,
        priceListName,
        priceList,
        showModal,
        companyInfo,
        supplierSummary,
        permissions
    } = props;
    const primaryPerm = (permissions && permissions.primary) || [];

    const supplierPermissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIERS )
    const handleNewPriceListAddition = (props, payload) => {
        const formData = payload.formData;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.priceList' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PRICE_LIST,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.PRICE_LIST: {
                deleteFunc = props.deletePriceList;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }
        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }



    const useOuterClick = (callback) => {
        const innerRef = useRef();
        const callbackRef = useRef();
    
        // set current callback in ref, before second useEffect uses it
        useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });
    
        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);
    
            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep
    
        return innerRef; // return ref; client can omit `useRef`
    }
    
    const innerRef = useOuterClick(e => {
       updateTemplateDropdown(!templateDropdown)
    });


    return (
        < >
        <div className='left-itms'>
            <ul>
                <li className='title'>
                Purchase Order
                </li>
                <li className='sep' />
                <li>
                <FormattedMessage id='supplier.purchaseOrder.form.poNumber.label' defaultMessage='' /> &nbsp; - &nbsp;
                {props.nextTxNumber ?
                    <span>{getFormatedNextTxNumber(props)}</span>
                    //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
                    :
                    <img src={LoadingIcon} alt="Invoice" style={{ 'height': '50px' }} />
                }
                </li>
            </ul>
        </div>
            <div className='right-itms'>
                <DropdownPriceList
            style={{ width: "200px" }}

                    items={priceList}
                    valueKeyName='priceListName'
                    optionKeyName='priceListId'
                    value={priceListName}
                    canAddNew={true}
                    canDelete={true}
                    allowClear={true}
                    deleteClickHandler={(payload) => {
                        deleteClickHandler(props, MODAL_TYPE.PRICE_LIST, payload);
                    }}
                    onAddNewClick={(payload) => {
                        payload.formData = {
                            priceListName: payload.textEntered,
                            submittedOnce: false
                        }
                        handleNewPriceListAddition(props, payload, MODAL_TYPE.PRICE_LIST)
                    }}
                    placeholder={intl.formatMessage({
                        id: 'supplier.purchaseOrder.form.priceListDropdown.placeholder', defaultMessage: ''
                    })}
                    onSelect={
                        (priceListId) => {
                          const priceListData = find(priceList, {priceListId }) || {};
                          updateState({ toUpdatePriceList: true, priceListId, priceListName: priceListData.priceListName, priceListType: priceListData.type, priceListPercentage: priceListData.percentage });
                        }
                      }
                      onClear={() => {
                        updateState({toUpdatePriceList: true, priceListId: undefined, priceListName: undefined, priceListType: undefined, priceListPercentage: undefined})
                      }}
                />

                {/* <FormattedMessage id='supplier.purchaseOrder.form.supplierName.label' defaultMessage='' /> */}
                <DetailDropdown
            style={{ width: "200px" }}

                    items={props.suppliers[pageNumber] || []}
                    sortItems={true}
                    valueKeyName='supplierStoreName'
                    optionKeyName='supplierId'
                    className="detailDropdown"
                    value={(props.supplierDetail || {}).supplierStoreName}
                    placeholder={intl.formatMessage({
                        id: 'supplier.purchaseOrder.form.supplierName.placeholder', defaultMessage: ''
                    })}
                    moduleName={MODULE_CODED_VALUES.SUPPLIERS}
                    canAddNew={true}
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            boContactList: undefined,
                            selectedContact: undefined,
                            supplierAddresses: undefined,
                            supplier: undefined,
                            supplierPaymentTerm: undefined,
                            supplierBillingAddress: undefined,
                            poDueDate: undefined
                        })
                    }}
                    onSearch= {(searchedText)=>{
                        props.fetchSuppliers({
                            searchedText: searchedText,
                            pageNumber: 1,
                            hideSpinner: true,
                            pageSize: 100,
                            companyInfo:companyInfo,
                            filterData:true,
                            relationshipId: (props.companyInfo || {}).relationshipId
                        })
                    }}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                        props.deleteSuppliers({ groupSupplierIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
                    }}
                    onAddNewClick={(payload) => {
                        props.updateHeaderState({
                            supplier: {
                                supplierDrawerVisible: true,
                                supplierName: payload.textEntered,
                            }
                        })
                    }}
                    onSelect={
                        (supplierId, optionObj) => {
                            const supplierObj = find(props.suppliers[pageNumber], { supplierId: supplierId }) || {};
                            const supplierPaymentTerm = find(props.paymentTerms, { paymentTermId: Number(supplierObj.paymentTermId) }) || {};
                            const poDueDate = addDaysToDate((props.poDate || new Date()), supplierPaymentTerm.numberOfDays);
                            
                            updateState({
                                supplierPaymentTerm,
                                poDueDate: poDueDate,
                                stateSupplierId: supplierId,
                                supplier:{...supplierObj,name:supplierObj?.supplierStoreName}

                            });

                            const callBackFn = (value)=>{
                               

                            }
                            props.fetchSupplierDetailsBySupplierId({
                                relationshipId: companyInfo.relationshipId,
                                supplierId: supplierId,
                                pageNumber: props.pageNumber || 1,
                                pageSize: props.pageSize || 100,
                                callback:callBackFn
                              });
                        }
                    }
                />
            
                <Button style={{ height: "38px" }}  onClick={() => {
                    props.updateState({
                        ledgerDrawerVisible: true
                    })
                }} >
                    <i className='fa fa-book fa-lg'> </i>
                </Button>

                <div  className="currency-converter-modal">
                    <span style={{ height: "38px" }} className="ant-btn ant-btn-icon-only icon" onClick={() => updateTemplateDropdown(!templateDropdown)}>
                        <i className="fa fa-exchange" />
                    </span>

                    {templateDropdown &&
                        <div ref={innerRef} className="content">
                            <Row className="mb10">
                                <Link onClick={()=>{
                                    updateTemplateDropdown(false)
                                    props.popModalFromStack()
                                    props.pushModalToStack({
                                        modalBody: <StandardPurchaseOrder {...props}/>,
                                        width: '100%',
                                        hideTitle: true,
                                        keyboard: false,
                                        hideFooter: true,
                                        wrapClassName: 'new-transaction-wrapper'
                                    })
                                }}
                                // to="/admin/po-create"
                                ><FormattedMessage id='common.standard.template' defaultMessage='' /></Link>
                            </Row>

                            <Row>
                                <Link onClick={()=>{
                                    updateTemplateDropdown(false)
                                    props.popModalFromStack()
                                    props.pushModalToStack({
                                        modalBody: <MultiCurrencyPurchaseOrder {...props}/>,
                                        width: '100%',
                                        hideTitle: true,
                                        keyboard: false,
                                        hideFooter: true,
                                        wrapClassName: 'new-transaction-wrapper'
                                    })
                                }}
                                // to="/admin/po-create-multi-currency"
                                > <FormattedMessage id='common.Multi.currency.template' defaultMessage='' /></Link>
                            </Row>
                        </div>
                    }
                </div>
                <Button onClick={() => {
              props.updateDrawerState({
                  settingPurchaseDrawerVisible: true
              })
          }} >
              <i class="fa fa-cog" aria-hidden="true"></i>
          </Button>
                {/* <div className="space-lr">
                    <Button icon={<SwapOutlined />} />
                </div> */}
            </div>
        </>
    );
};
const getFormatedNextTxNumber = (props) => {
    // const { supplier } = props;
    // if (!supplier || !supplier.supplierId) {
    //     return <span>{props.nextTxNumber}</span>;
    // }
    // return <span>{props.nextTxNumber.replace('PO-00', 'PO-' + (supplier.supplierId < 10 ? ('0' + supplier.supplierId) : supplier.supplierId))}</span>
    return <span>{props.nextTxNumber}</span>;
}

export default injectIntl(HeaderAction);
