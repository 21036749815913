import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { FUNDRAISING_ACTION_LIST } from "../../../../../static/constants";
import { showToasterMessage } from "../../../../../utils";
const { lang } = require("../../../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const getAllTeamLead = (props) => {
    return dispatch => {
        let url = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllFundraisers?relationshipId=${props.relationshipId}&pageNumber=${props.pageNumber || 0}&pageSize=${props.pageSize || 100}`;
        if(props.searchName){
            url = url+`&searchName=${props.searchName}`;
        }
        return axios
            .get(url)
            .then(res => {
                const { data } = res
                dispatch({ type: FUNDRAISING_ACTION_LIST.TEAM_LEAD_LIST, data });
            })
            .catch((err) => {
                showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
            })
    }
}


export const resetTeamLeadData = () => {
    return dispatch => {
      dispatch({ type: FUNDRAISING_ACTION_LIST.TEAM_LEAD_LIST_RESET });
    }
  }


export const saveTeamMaster = (payload, props) => {
    return dispatch => {
        return axios
            .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/teamMaster/saveOrUpdate`, payload)
            .then(res => {
                showToasterMessage({ messageType: 'success', description: lang[res.message] || 'Team Added to campaingn successfully.' });
                props.updateHeaderState({
                    fundraising: {
                      addTeamDrawerVisible: false,
                      teamMaster: null
                    }
                  });
                  props.getTeamMaster(payload);
            })
            .catch((err) => {
                showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || "")] || 'Some error occurred' })
            })
    }
}




