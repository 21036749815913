import axios from "axios";
import config from "../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../utils";
import { COMMON_ACTIONS } from "../../../../static/constants";
import { E_STORE } from "../../../../static/constants";

export const fetchAllCategoriesWithSubCategories = (payload,props) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios.get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/blogCategory/getBlogCategory?relationshipId=${payload.relationshipId}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`)
        .then(response => {
          const data = {
            pageNo: payload.pageNumber,
            list: response.data,
          };
          dispatch({ type: E_STORE.BLOG_CATEGORY_LIST, data });
        })
        .catch(err => {
          console.error(err);
        }).finally(()=>{
          dispatch({
            type: COMMON_ACTIONS.HIDE_LIST_LOADER,
          });
        })
    }
};



export const updateCategoryPublishStatus = (payload,props)=>{
  const data ={
    relationshipId: props.companyInfo.relationshipId,
   
 isPublished:payload.isPublished,
  }
  return dispatch => {
      //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios.post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/blogCategory/updateBlogStatus?blogCategoryIds=${payload.categoryId}`, data
        )
            .then(response => {
              if(response.status === 200){
                 const payload ={
                  relationshipId: props.companyInfo.relationshipId,
                  pageNumber: props.pageNumber||0,
                  pageSize: props.pageSize
                 }
                   
                   props.fetchAllCategoriesWithSubCategories(payload);
                  return showToasterMessage({
                       messageType: "success",
                       description: "Status updated Succssfully.",
                     });
              }
              else{
                return showToasterMessage({
                  messageType: "error",
                  description: response.message,
                });
              }
             
              // window.location.reload();
            })
            .catch(err => {
              showToasterMessage({
                  messageType: "error",
                  description: "Some error occured",
                });
              //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
      }
}
export const deleteCategory = (payload,props)=>{
  const data ={
    relationshipId: props.companyInfo.relationshipId,
   
    blogCategoryId:payload.categoryId,
  }
  return dispatch => {
      //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios.post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/blogCategory/deleteBlogCategory`, data
        )
            .then(response => {
              if(response.status === 200){
                 const payload ={
                  relationshipId: props.companyInfo.relationshipId,
                  pageNumber: props.pageNumber,
                  pageSize: props.pageSize
                 }
                   
                   props.fetchAllCategoriesWithSubCategories(payload);
                  return showToasterMessage({
                       messageType: "success",
                       description: "Deleted Succssfully.",
                     });
              }
              else{
                return showToasterMessage({
                  messageType: "success",
                  description: response.data?.message,
                });
              }
             
              // window.location.reload();
            })
            .catch(err => {
              showToasterMessage({
                  messageType: "error",
                  description: "Some error occured",
                });
              //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
      }
}
export const countCatgeoryList = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(
        `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/blogCategory/countAllBlogCategory?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: E_STORE.CATEGORY_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            "Some error occurred",
        });
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  };
};

